import { ButtonContainer } from '../components/Containers';
import { PrimaryButton, SecondaryButton } from 'library';
import React from 'react';

const MainButtons = ({
    onWelcomeBackClick,
    onBanClick,
}: {
    onWelcomeBackClick: () => void;
    onBanClick: () => void;
}) => (
    <ButtonContainer>
        <SecondaryButton buttonStyle={{ width: 'unset' }} onClick={onWelcomeBackClick}>
            {`No, they're welcome back!`}
        </SecondaryButton>
        <PrimaryButton buttonStyle={{ width: 'unset' }} onClick={onBanClick}>
            Yes, please block
        </PrimaryButton>
    </ButtonContainer>
);

export default MainButtons;
