import React, { useState, useEffect } from 'react';
import { Grid, Typography } from '@material-ui/core';
import Button from '../reusableComponents/button';
import { CardElement, injectStripe, Elements, StripeProvider } from 'react-stripe-elements';
import apiClient from '../shared/ApiClient';
import { StripeKey } from 'environmentVariables';

const _StripeCheckout = (props) => {
    const { stripe, elements } = props;
    const [secret, setSecret] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const cardElement = elements.getElement('card');

    useEffect(() => {
        if (stripe && secret) {
            confirmStripePayment();
        }
    }, [secret]);

    const submitPayment = async () => {
        props.paymentStarted();
        apiClient('api/cart/payment-intent/')
            .then((result) => {
                setSecret(result.client_secret);
            })
            .catch((error) => {
                console.log(error);
                props.paymentFailed({ message: 'Payment failed. Please try again.' });
            });
    };

    const confirmStripePayment = async () => {
        stripe
            .confirmCardPayment(secret, {
                payment_method: {
                    card: cardElement,
                },
            })
            .then(({ paymentIntent, error }) => {
                if (paymentIntent) {
                    props.paymentSuccessful(paymentIntent);
                } else if (error) {
                    console.log(error);
                    props.paymentFailed(error);
                }
            });
    };

    const cardNumberChanged = ({ error }) => {
        if (error) {
            setErrorMessage(error.message);
        } else {
            setErrorMessage('');
        }
    };

    return (
        <Grid container direction="column">
            <Grid style={styles.cardContainer}>
                <CardElement style={styles.cardElement} onChange={cardNumberChanged} />
            </Grid>
            {errorMessage && <Typography style={styles.error}>{errorMessage}</Typography>}
            <Button disabled={props.disabled} onClick={submitPayment}>
                Submit Payment
            </Button>
        </Grid>
    );
};

const InjectedStripeCheckout = injectStripe(_StripeCheckout);

const StripeCheckout = (props) => {
    return (
        <StripeProvider apiKey={StripeKey}>
            <Elements>
                <Grid container>
                    <Typography variant="subtitle2" align="left" style={{ marginBottom: 15 }}>
                        Tandem uses{' '}
                        <a target="_blank" href="https://stripe.com/" rel="noreferrer">
                            <b>Stripe</b>
                        </a>{' '}
                        to complete all credit card processing. Stripe charges an additional fee for handling each
                        credit card transaction.
                    </Typography>

                    <InjectedStripeCheckout {...props} />
                </Grid>
            </Elements>
        </StripeProvider>
    );
};

const styles = {
    cardContainer: {
        padding: 8,
        backgroundColor: 'white',
        marginBottom: 10,
        borderRadius: 5,
        border: 'solid 1px gray',
    },
    cardElement: {
        base: {
            color: 'gray',
            backgroundColor: 'white',
            fontFamily: 'Work Sans',
            fontSize: '16px',
            fontSmoothing: 'antialiased',
        },
        invalid: {
            color: 'red',
        },
    },
    error: {
        fontSize: 14,
        color: 'red',
        marginBottom: 10,
    },
};
export default StripeCheckout;
