import { IPayScale } from 'parent-portal/forms/JobRequestTypes';
import { BASE_EDUACTION_RECOMMENDATION, EDUCATION_PAY_RATE } from './recommendationConstants';

// eslint-disable-next-line no-comments/disallowComments
/**
 * Because there can be multiple entries for each rate
 * this function accept an array of them
 *
 * It goes highest to lowest and returns early so only one rule is returned for each
 */
export function getIllinoisRecommendations(payScales: IPayScale[]): string[] {
    const recs: string[] = [];

    payScales.forEach((x) => {
        if (x.rate < EDUCATION_PAY_RATE) {
            if (x.qualifications.some((q) => q.name.toLowerCase().includes('degree'))) {
                recs.push(BASE_EDUACTION_RECOMMENDATION);
                return;
            }
        }

        if (x.rate < 12 && x.qualifications.some((q) => q.name.toLowerCase().includes('cpr'))) {
            recs.push('Remove First aid/CPR or increase the pay to $12/hr');
            return;
        }
    });

    return recs;
}
