import React from 'react';
import { styled } from '@mui/material';
import { SecondaryButton, Text } from 'library';

export function BannedButton({
    bannedAll,
    bannedLocations,
    setOpenBanModal,
    buttonTextStyle,
    buttonStyle,
    style,
}: {
    bannedAll: boolean;
    bannedLocations: number;
    setOpenBanModal: (open: boolean) => void;
    buttonTextStyle?: React.CSSProperties;
    buttonStyle?: React.CSSProperties;
    style?: React.CSSProperties;
}) {
    const phrase = bannedLocations === 1 && !bannedAll ? 'location' : 'locations';

    return (
        <ButtonContainer style={style}>
            <SecondaryButton
                buttonStyle={buttonStyle}
                onClick={() => setOpenBanModal(true)}
                textStyle={buttonTextStyle}
            >
                Block this Worker
            </SecondaryButton>
            <Text variant="caption">
                Currently blocked at {bannedAll ? 'all' : bannedLocations ?? 0} {phrase}
            </Text>
        </ButtonContainer>
    );
}

const ButtonContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
});
