import { Grid } from '@mui/material';
import React from 'react';
import { Text } from 'library';
import { IBusinessJobDetailsModel, TimeOfDay } from '../models';
import { formatDate, to12HourTime } from 'shared/Dates';

export default function Schedule({ job }: { job: IBusinessJobDetailsModel }) {
    function formatDateRange() {
        return job.start_date && job.end_date
            ? `${formatDate(job.start_date)}${
                  job.business_job_type !== 'TRIAL_RUN' ? ` - ${formatDate(job.end_date)}` : ''
              }`
            : job.start_month;
    }
    return (
        <Grid xs={12} md={4} container item direction="column">
            <Grid container item style={{ gap: 10 }} alignContent="flex-start">
                <Grid xs={12} container item direction="row">
                    <Text variant="h1" textStyle={{ width: undefined }}>
                        Schedule
                    </Text>
                </Grid>
                <Grid item container direction="column" style={{ gap: 5 }}>
                    <Text bold>{formatDateRange()}</Text>

                    {Object.keys(job.times_of_day).map((day: string) => {
                        const currentDay = job.times_of_day[day as keyof TimeOfDay];
                        return (
                            currentDay.selected && (
                                <Text key={day}>
                                    {day} {to12HourTime(currentDay.start)} - {to12HourTime(currentDay.end)}
                                </Text>
                            )
                        );
                    })}
                </Grid>
            </Grid>
        </Grid>
    );
}
