import React, { useState, CSSProperties, useMemo, useCallback } from 'react';
import { BugReport as BugReportIcon } from '@mui/icons-material';
import { AdminProviderListUser, BugReport } from 'models';
import { BasicDialog, IconButton } from 'library';
import BugReportsModal from './BugReportsModal';
import { client, consoleLogInDev } from 'shared';
import DateFnsAdapter from '@date-io/date-fns';

export default function BugReportsDialog({
    user,
    refresh,
    style,
}: {
    user: AdminProviderListUser;
    refresh?: () => void;
    style?: CSSProperties;
}) {
    const styles = {
        iconWrap: {
            ...style,
            marginLeft: 10,
        },
    };
    const dateFns = useMemo(() => new DateFnsAdapter(), []);
    const [open, setOpen] = useState(false);
    const [bugReports, setBugReports] = useState<BugReport[]>([]);

    const handleOpen = () => {
        setOpen(true);
        getBugReports();
    };

    const getBugReports = () => {
        client(`api/bug-report/?user=${user.id}`)
            .then((res) => {
                setBugReports(orderBugsByAddedDate(res));
            })
            .catch(consoleLogInDev);
    };

    const orderBugsByAddedDate = useCallback(
        (bugReports: BugReport[]): BugReport[] => {
            return bugReports.sort((reportA, reportB) => {
                const dateA = new Date(reportA.added_at);
                const dateB = new Date(reportB.added_at);
                return dateFns.isAfter(dateA, dateB) ? -1 : dateFns.isBefore(dateA, dateB) ? 1 : 0;
            });
        },
        [dateFns],
    );

    return (
        <>
            <span style={styles.iconWrap}>
                <IconButton tooltip="Add Compliance Bug Report" icon={BugReportIcon} onClick={handleOpen} />
            </span>
            <BasicDialog
                dialogTitle={`${user.first_name} ${user.last_name} Bug Reports`}
                closeButton
                onClose={() => {
                    setOpen(false);
                    refresh && refresh();
                }}
                isOpen={open}
            >
                <BugReportsModal user={user} bugReports={bugReports} getBugReports={getBugReports} />
            </BasicDialog>
        </>
    );
}
