import React, { useEffect, useState } from 'react';
import { useBusinessLocationOverview } from '../../api';
import { LoadingSpinner, Text, DropDown, PrimaryButton, Colors, OutlinedTextInput, SecondaryButton } from 'library';
import { Grid, styled } from '@mui/material';
import { consoleLogInDev } from 'shared';
import { saveCallHistory, listOptions } from 'api/CallHistoryApi';
import { useUserContext } from 'UserContext';
import DateTimePicker from 'internal/shared/DateTimePicker';
import type { CallHistory, CallStatus, SaveCallResponse } from 'models/CallHistory';
// eslint-disable-next-line import/named
import moment, { Moment } from 'moment';

export default function CallHistoryForm({
    businessLocationId,
    callToEdit,
    onSave,
    onCancelEdit,
}: {
    businessLocationId: number;
    callToEdit?: CallHistory;
    onSave?: (callResponse: SaveCallResponse) => void;
    onCancelEdit?: () => void;
}) {
    const { status, error, data: businessLocation } = useBusinessLocationOverview(businessLocationId);
    const [callStatuses, setCallStatuses] = useState<CallStatus[]>([]);
    const [selectedContact, setSelectedContact] = useState('');
    const [callTime, setCallTime] = useState<Date | Moment | null>(new Date());
    const [duration, setDuration] = useState('');
    const [callStatus, setCallStatus] = useState('');
    const [direction, setDirection] = useState('Outbound');
    const [note, setNote] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const { user } = useUserContext();

    useEffect(() => {
        getOptions();
    }, []);

    useEffect(() => {
        if (!callToEdit) {
            return;
        }

        setCallTime(moment(callToEdit.call_time));
        setSelectedContact(
            (callToEdit.is_inbound
                ? callToEdit.call_from_user?.id?.toString()
                : callToEdit.call_to_user?.id?.toString()) || '',
        );
        setDirection(callToEdit.is_inbound ? 'Inbound' : 'Outbound');
        setCallStatus(callToEdit.call_status?.id?.toString() || '');
        setDuration(callToEdit.duration ? callToEdit.duration.toString() : '');
        setNote(callToEdit.note);
    }, [callToEdit]);

    function getOptions() {
        listOptions()
            .then((response) => {
                setCallStatuses(response);
            })
            .catch(consoleLogInDev);
    }

    function onCancelEditClick() {
        onCancelEdit?.();
        resetForm();
    }

    function resetForm() {
        setSelectedContact('');
        setCallStatus('');
        setDuration('');
        setNote('');
    }

    function onAddClick() {
        if (!callTime) {
            return;
        }

        setErrorMessage('');
        saveCallHistory(
            businessLocationId,
            callTime.toISOString(),
            parseInt(duration),
            direction.toLowerCase() === 'inbound',
            parseInt(callStatus),
            note,
            user?.id,
            selectedContact ? parseInt(selectedContact) : undefined,
            callToEdit?.id,
        )
            .then((response: SaveCallResponse) => {
                resetForm();
                onSave?.(response);
            })
            .catch((error) => {
                consoleLogInDev(error);
                setErrorMessage(error.message || 'An error occurred');
            });
    }

    function isFormValid() {
        if ((!callStatus && direction.toLocaleLowerCase() === 'outbound') || !callTime) {
            return false;
        }

        return true;
    }

    if (status === 'loading') return <LoadingSpinner />;
    if (status === 'error') return <Text>{error}</Text>;
    if (!businessLocation) return <Text>Location not found.</Text>;

    const contacts = [
        {
            key: '',
            value: 'None',
        },
        {
            key: businessLocation.business.user.id,
            value: `${businessLocation.business.user.first_name} ${businessLocation.business.user.last_name}`,
        },
        ...businessLocation.business_contacts.map((x) => {
            return {
                key: x.user.id,
                value: `${x.user.first_name} ${x.user.last_name}`,
            };
        }),
    ];

    const callStatusOptions = [
        {
            key: '',
            value: 'None',
        },
        ...callStatuses.map((x) => {
            return { key: x.id, value: x.name };
        }),
    ];
    return (
        <Container container direction="column" gap={1}>
            <Text bold>Log Call</Text>
            <Grid container alignItems="center">
                <Text textStyle={{ marginRight: 5 }}>Call Time:</Text>
                <DateTimePicker value={callTime} onSelectDate={(e) => setCallTime(e)} />
            </Grid>
            <DropDown
                selected={selectedContact}
                placeholder="Chose Option"
                title="Contact"
                fields={contacts}
                onChange={(e) => setSelectedContact(e.target.value)}
                style={{ maxWidth: 450 }}
            />
            <DropDown
                selected={callStatus}
                placeholder="Outcome"
                title="Outcome"
                fields={callStatusOptions}
                onChange={(e) => setCallStatus(e.target.value)}
                style={{ maxWidth: 450 }}
            />
            <DropDown
                selected={direction}
                placeholder="Call Direction"
                title="Call Direction*"
                fields={[
                    { key: 'Outbound', value: 'Outbound' },
                    { key: 'Inbound', value: 'Inbound' },
                ]}
                onChange={(e) => setDirection(e.target.value)}
                style={{ maxWidth: 450 }}
            />
            <OutlinedTextInput
                label="Duration in Minutes"
                value={duration}
                onChange={(e) => setDuration(e.target.value)}
                style={{ maxWidth: 120 }}
            />
            <OutlinedTextInput
                className="notDraggable"
                label="Notes"
                value={note}
                onChange={(e) => setNote(e.target.value)}
                multiline={true}
            />
            <Grid container gap={2}>
                {callToEdit && (
                    <SecondaryButton onClick={onCancelEditClick} buttonStyle={{ width: undefined }}>
                        Cancel
                    </SecondaryButton>
                )}
                <PrimaryButton disabled={!isFormValid()} onClick={onAddClick} buttonStyle={{ width: undefined }}>
                    {callToEdit ? 'Save' : 'Add'}
                </PrimaryButton>
            </Grid>
            <Text textStyle={{ color: Colors.error }}>{errorMessage}</Text>
        </Container>
    );
}

const Container = styled(Grid)({
    padding: 20,
});
