import { Grid } from '@mui/material';
import React from 'react';
import AboutUsHeader from './components/AboutUsHeader';
import Timeline from './components/Timeline';

export default function AboutUs() {
    return (
        <Grid xs={12}>
            <AboutUsHeader />
            <Timeline />
        </Grid>
    );
}
