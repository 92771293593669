import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import { Text, PrimaryButton, BasicDialog, TextButton, Chip, OutlinedTextInput, DropDown } from 'library';
import { client } from 'shared';

export default function MessageSuggestions({
    messageSuggestions,
    setNewMessage,
    onRefresh,
}: {
    messageSuggestions: { message: string; region: string }[];
    setNewMessage: (message: string) => void;
    onRefresh: () => void;
}) {
    const [messagesModalOpen, setMessagesModalOpen] = useState<boolean>(false);
    const [messageAddModalOpen, setMessageAddModalOpen] = useState<boolean>(false);
    const [message, setMessage] = useState<string>('');
    const [type, setType] = useState<string>('PSF Response Options');
    const types = ['PSF Response Options', 'Matching Response Options', 'Indeed Response Options'];

    function save() {
        client('api/message-copy/', {
            method: 'POST',
            body: { message: message, type: type },
        })
            .then(onRefresh)
            .finally(() => setMessageAddModalOpen(false));
    }

    return (
        <Grid container item>
            <TextButton onClick={() => setMessagesModalOpen(true)} textStyle={{ paddingBottom: 30 }}>
                <Text variant="h2">Message Suggestions</Text>
            </TextButton>
            <BasicDialog onClose={() => setMessagesModalOpen(false)} closeButton isOpen={messagesModalOpen}>
                <Grid container item direction="row">
                    {messageSuggestions?.map((message) => (
                        <Grid item xs={12} key={message.message}>
                            <Chip
                                key={message.message}
                                style={{ marginBottom: 8 }}
                                label={`${message.region === 'Florida' ? 'Florida: ' : ''} ${message.message}`}
                                onClick={() => {
                                    setMessagesModalOpen(false);
                                    setNewMessage(message.message);
                                }}
                            />
                        </Grid>
                    ))}
                    <PrimaryButton
                        onClick={() => setMessageAddModalOpen(true)}
                        buttonStyle={{ maxWidth: '50%', alignSelf: 'flex-end' }}
                    >
                        Add Message Suggestion
                    </PrimaryButton>
                    <BasicDialog onClose={() => setMessageAddModalOpen(false)} closeButton isOpen={messageAddModalOpen}>
                        <Grid item>
                            <Text variant="h2">Add Message Suggestion</Text>
                            <Text variant="body1">Select Type</Text>
                            <DropDown selected={type} fields={types} onChange={(e) => setType(e.target.value)} />
                            <OutlinedTextInput
                                multiline
                                value={message}
                                style={{ backgroundColor: 'white', padding: 10, borderRadius: 15 }}
                                placeholder=""
                                rows={4}
                                fullWidth
                                onChange={(e: any) => setMessage(e.target.value)}
                            />
                            <PrimaryButton onClick={save} buttonStyle={{ maxWidth: 200 }}>
                                Save
                            </PrimaryButton>
                        </Grid>
                    </BasicDialog>
                </Grid>
            </BasicDialog>
        </Grid>
    );
}
