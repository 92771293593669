import React from 'react';
import { Grid } from '@mui/material';
import { Colors, PrimaryButton, Text } from 'library';
import DashboardSection from '../components/DashboardSection';
import { useModalsContext } from 'ModalsContext';
import useMicrodepositVerificationInfo from '../paymentVerificationWarning/useMicrodepositVerficationInfo';

export default function PostJob() {
    const { openJobForm } = useModalsContext();
    const { blockJobPostingOnVerification } = useMicrodepositVerificationInfo();

    const onPostShiftClick = () => {
        openJobForm('Business Dashboard');
    };

    return (
        <DashboardSection
            header="Post a Job"
            description="Get your need posted and you'll be notified as soon as we fill them."
        >
            <Grid container justifyContent="flex-end" style={{ marginTop: 10 }}>
                <PrimaryButton
                    disabled={blockJobPostingOnVerification}
                    buttonStyle={{ maxWidth: 200 }}
                    onClick={onPostShiftClick}
                >
                    Post Job
                </PrimaryButton>
            </Grid>

            {blockJobPostingOnVerification && (
                <Grid container justifyContent="flex-end">
                    <Text variant="body1" color={Colors.error}>
                        Micro deposits needed before posting a job.
                    </Text>
                </Grid>
            )}
        </DashboardSection>
    );
}
