import React from 'react';
import { TextArea } from 'library';
import { useFormStyles } from '../../constants';
import { WorkerReferenceForm, ErrorState } from '../../models';

export default function TextAreaInputField({
    fieldName,
    fieldValue,
    form,
    errorState,
    placeholder,
    handleOnChange,
}: {
    fieldName: string;
    fieldValue: string | undefined;
    form: WorkerReferenceForm;
    errorState: ErrorState;
    placeholder: string;
    handleOnChange: (formArg: WorkerReferenceForm, key: string, value: string | number | undefined) => void;
}) {
    const styles = useFormStyles();
    return (
        <TextArea
            rows={6}
            value={fieldValue}
            inputProps={{
                style: styles.textAreaInputProps,
                // NOTE: re-evaluate if necessary: maxLength: 500,
            }}
            style={
                errorState[fieldName as keyof ErrorState].inErrorState
                    ? styles.textAreaInputError
                    : styles.textAreaInput
            }
            // NOTE: re-evaluate if necessary: helperText="500 Character limit"
            placeholder={placeholder}
            onChange={(e) => handleOnChange(form, fieldName, e.target.value)}
            variant="standard"
        />
    );
}
