import React, { ReactNode } from 'react';
import { useWidth } from 'reusableComponents/useWidth';

import { Text } from 'library';

interface GradientHeaderProps {
    children?: ReactNode;
}

export default function GradientHeader({ children }: GradientHeaderProps) {
    const breakpoints = useWidth();
    const smallScreen = ['xs', 'sm'].includes(breakpoints);

    return (
        <div
            style={{
                background: smallScreen ? '' : 'linear-gradient(300deg,#f3ffd0,#a1dbd3)',
                padding: 10,
            }}
        >
            <Text
                textStyle={{
                    textAlign: 'center',
                    marginTop: smallScreen ? 10 : 40,
                    marginBottom: smallScreen ? 5 : 25,
                }}
                variant="display"
            >
                {children}
            </Text>
        </div>
    );
}
