import { Avatar, styled } from '@mui/material';
import { Checkbox, Colors, Text } from 'library';
import { useJobFormContext } from 'parent-portal/forms/JobFormContext';
import React from 'react';

export default function TeacherShowcaseInput() {
    const { form } = useJobFormContext();

    function updateChipText(text: string) {
        const vals = text.split(': ');
        const isYearsExperience = text.includes('experience');

        return isYearsExperience ? `${vals[1]} years of experience` : vals[1];
    }

    const { selectedSpotlightWorkers, allSpotlightWorkers } = form.fieldStates;
    if (allSpotlightWorkers.value.length === 0) return null;

    return (
        <div>
            <Text variant="body1" bold textStyle={{ marginTop: 20 }}>
                Teacher Showcase
            </Text>
            <div>
                {allSpotlightWorkers.value.map((worker, index) => (
                    <div key={index} style={{ display: 'flex' }}>
                        <WorkerAvatar src={worker.pictureUrl} />
                        <Checkbox
                            checked={selectedSpotlightWorkers.value.some(
                                (selectedWorker) => selectedWorker.id === worker.id,
                            )}
                            onChange={() => {
                                const selected = selectedSpotlightWorkers.value;
                                if (selected.some((selectedWorker) => selectedWorker.id === worker.id)) {
                                    form.fieldStates.selectedSpotlightWorkers.setValue(
                                        selected.filter((selectedWorker) => selectedWorker.id !== worker.id),
                                    );
                                } else {
                                    form.fieldStates.selectedSpotlightWorkers.setValue([...selected, worker]);
                                }
                            }}
                            label={`${worker.name} - ${Array.from(new Set(worker.credentials.map(updateChipText))).join(', ')}`}
                            style={{ width: undefined, display: 'inline-flex' }}
                        />
                    </div>
                ))}
            </div>
        </div>
    );
}

const WorkerAvatar = styled(Avatar)({
    color: Colors.white,
    backgroundColor: Colors.darkNavy,
    width: 35,
    height: 35,
    borderRadius: '50%',
    marginTop: 10,
    marginBottom: 10,
    display: 'inline-flex',
    marginRight: 10,
});
