import React, { useState } from 'react';
import { Typography, Card } from '@material-ui/core';
import { SimpleTextDialog } from '../reusableComponents/dialogsSimple';
import { useStyles } from './CartStyles';
import { CartContextProvider } from './CartContext';
import Cart from './Cart';
import BackgroundCheckCheckout from './BackgroundCheckCheckout';

const SitterPayment = () => {
    const cartStyles = useStyles();
    const [success, setSuccess] = useState(false);
    const [failed, setFailed] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [failureMessage, setFailureMessage] = useState('');

    const onSuccess = (message: string) => {
        setSuccess(true);
        setSuccessMessage(message);
    };

    const onFail = (message: string) => {
        setFailed(true);
        setFailureMessage(message);
    };

    return (
        <CartContextProvider>
            <Card className={cartStyles.background}>
                <Typography className={cartStyles.cartHeader}>Checkout</Typography>
                <Cart />
                <BackgroundCheckCheckout onSuccess={onSuccess} onFail={onFail} />
                <SimpleTextDialog
                    dialogText={successMessage}
                    dialogTitle="Success"
                    buttonText="OK"
                    isOpen={success}
                    onClose={() => setSuccess(false)}
                    onSubmit={() => setSuccess(false)}
                />
                <SimpleTextDialog
                    dialogText={failureMessage}
                    dialogTitle="Unsuccessful"
                    buttonText="OK"
                    isOpen={failed}
                    onClose={() => setFailed(false)}
                    onSubmit={() => setFailed(false)}
                />
            </Card>
        </CartContextProvider>
    );
};

export default SitterPayment;
