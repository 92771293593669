import React from 'react';
import { Text, Colors } from 'library';
import { Grid } from '@material-ui/core';
import { styled } from '@mui/material';
import CheckBoxOutlined from '@mui/icons-material/CheckCircle';

export default function CheckRow({ text }: { text: string }) {
    return (
        <CheckRowContainer container direction="row" alignItems="center">
            <CheckBoxOutlined htmlColor={Colors.turquoise} />
            <Text>{text}</Text>
        </CheckRowContainer>
    );
}

const CheckRowContainer = styled(Grid)({
    padding: '5px 0',
});
