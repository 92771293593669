import { Chip, Text, Colors } from 'library';
import { ProviderQualifications } from 'parent-portal/provider-profile/providerProfileModels';
import React from 'react';
import Highlight from './Highlight';

export default function ExperienceHighlight({ qualifications }: { qualifications: ProviderQualifications }) {
    return (
        <Highlight title="Experience">
            <div style={{ display: 'flex', flexDirection: 'row' }}>
                {qualifications.years_childcare_experience > 0 ? (
                    <div style={{ width: '40%' }}>
                        <Text variant="h2" bold textStyle={{ marginTop: 8 }}>
                            Years of Experience
                        </Text>
                        <Text variant="caption" textStyle={{ marginTop: 4 }}>
                            (Child Care Center Specific)
                        </Text>
                        <Text variant="body1" textStyle={{ marginTop: 8 }}>
                            {qualifications.years_childcare_experience} year
                            {qualifications.years_childcare_experience === 1 ? '' : 's'}
                        </Text>
                    </div>
                ) : null}
                {qualifications.age_group_experience.length > 0 ? (
                    <div style={{ width: '60%' }}>
                        <Text variant="h2" bold textStyle={{ marginTop: 8, marginBottom: 8 }}>
                            Worked with Age Groups
                        </Text>
                        {qualifications.age_group_experience.map((age) => (
                            <Chip
                                key={age}
                                label={age}
                                backgroundColor={Colors.white}
                                style={{ boxShadow: '0px 3px 6px #00000029', margin: 4 }}
                            />
                        ))}
                    </div>
                ) : null}
            </div>
        </Highlight>
    );
}
