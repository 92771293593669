import React, { useEffect, useState } from 'react';
import ProviderProfileTitledContainer from '../../ProviderProfileTitledContainer';
import { useProviderProfileContext } from '../../ProviderProfileContext';
import PastExperienceEntry from './PastExperience';
import { PastExperience } from 'models';
import CustomPagination from 'reusableComponents/customPagination';
import DownloadResumeAction from './DownloadResumeAction';
import ProfileDivider from 'parent-portal/provider-profile/ProfileDivider';
import ChildcareCenterExperience from './childcare-experience/ChildcareCenterExperience';
import { isLoggedIn } from 'api/UserApi';

export default function ProfileProfileExperienceTab() {
    const { pastExperience, provider, childcareCenterQualifications } = useProviderProfileContext();
    const [visibleExperience, setVisibleExperience] = useState<PastExperience[]>([]);
    const [childcareCenterExperience, setChildcareCenterExperience] = useState<PastExperience[]>([]);
    const [pastJobs, setPastJobs] = useState<PastExperience[]>([]);
    const [pageCount, setPageCount] = useState(0);
    const pageSize = 3;

    const showNewContent = (content: PastExperience[]) => {
        setVisibleExperience(content);
    };

    useEffect(() => {
        setPageCount(Math.floor((pastExperience.length + pageSize - 1) / pageSize));
        setChildcareCenterExperience(
            pastExperience.filter((experience) => experience.experience_type === 'CHILDCARE_CENTER'),
        );
        setPastJobs(pastExperience.filter((experience) => !experience.experience_type));
        setVisibleExperience(pastExperience.filter((experience) => !experience.experience_type).slice(0, pageSize));
    }, [pastExperience]);

    return (
        <>
            {pastJobs.length > 0 && isLoggedIn() ? (
                <ProviderProfileTitledContainer title="Past Jobs">
                    {visibleExperience.map((exp: PastExperience, index) => (
                        <>
                            <PastExperienceEntry experience={exp} />
                            <ProfileDivider index={index} pageSize={visibleExperience.length} />
                        </>
                    ))}
                    {pastExperience.length > pageSize && (
                        <CustomPagination
                            pageCount={pageCount}
                            content={pastExperience}
                            pageSize={pageSize}
                            showNewContent={showNewContent}
                        />
                    )}
                </ProviderProfileTitledContainer>
            ) : null}
            {provider.user.resume && isLoggedIn() ? (
                <ProviderProfileTitledContainer title="Resume">
                    <DownloadResumeAction provider={provider} />
                </ProviderProfileTitledContainer>
            ) : null}
        </>
    );
}
