import React, { useState, useEffect, useRef } from 'react';
import { Grid, Menu, MenuItem, TextField } from '@material-ui/core';
import { styled } from '@mui/material';
import { Colors, Checkbox, Text, PrimaryButton, SecondaryButton, TextButton } from 'library';
import client from 'shared/ApiClient';
import { useUserChatwootContact } from 'shared/commonHooks/chatwoot';
import OutreachConversationRow from './OutreachConversationRow';
import { formatPhoneNumber, consoleLogInDev } from '../../../shared/Utils';
import { ConversationProps, ConversationData } from './OutreachModels';
import { refreshConversation } from './OutreachApi';
import OtherThreadModal from './OtherThreadModal';
import SubAvailabilityDialog from 'internal/substitutes/SubAvailabilityDialog';
import MessageSuggestions from './MessageSuggestions';
import { TimestampModal } from 'internal/payments/HourReportTable/TimestampModal';
import { HourReportTimestamp } from 'internal/payments/HourReportModels';
import { ApiBaseWSUrl, ChatwootUrl, ChatwootAccountId } from 'environmentVariables';
import useWebSocket from 'react-use-websocket';
import markUnread from './MarkUnread';
import { socketConfig } from 'internal/shared/sockets';

const config = socketConfig({});

export default function OutreachConversation({
    externalNumber,
    tandemNumber,
    displayName,
    userId: passedUserId,
    thread_id,
    region,
    medStatus,
    onMarkUnread,
}: ConversationProps) {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const toSnooze = true;
    const [headerText, setHeaderText] = useState<string | null>('');
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [selectedTimestamps, setSelectedTimestamps] = useState<HourReportTimestamp[]>();
    const [conversation, setConversation] = useState<ConversationData[]>([]);
    const [newMessage, setNewMessage] = useState<string>('');
    const messagesEndRef = useRef<HTMLDivElement>(null);
    const [messageSuggestions, setMessageSuggestions] = useState<{ message: string; region: string }[]>([]);
    const [userId, setUserId] = useState<number | undefined>(passedUserId);
    const [disabled, setDisabled] = useState<boolean>(false);
    const [chatwootConversationId, setChatwootConversationId] = useState<number>();
    const [error, setError] = useState<string | null>(null);
    const [isManageShiftOpen, setIsManageShiftOpen] = useState(false);
    const { lastMessage } = useWebSocket(`${ApiBaseWSUrl}/ws/thread/${thread_id}/`, config);

    const { data, send, status } = useUserChatwootContact({ userId, options: { sendImmediately: false } });
    const { enabled: chatwootEnabled } = status === 200 && data ? data : { enabled: false };

    function getMessageSuggestions() {
        client(`api/message-copy/${tandemNumber === '+16146183792' ? `?name=Indeed Response Options` : ''}`).then(
            setMessageSuggestions,
        );
    }
    useEffect(() => {
        const realUserId = passedUserId || conversation[0]?.user_id;

        setUserId(realUserId);
    }, [passedUserId, conversation]);

    useEffect(() => {
        if (userId && !data) {
            send();
        }
    }, [userId]);

    useEffect(() => {
        if (chatwootEnabled) {
            setDisabled(true);
        }
    }, [chatwootEnabled]);

    useEffect(getMessageSuggestions, [tandemNumber]);

    useEffect(() => {
        if (!lastMessage) return;
        const { message_id, message_text, message_sender_number } = JSON.parse(lastMessage.data).message;

        setConversation((prev: ConversationData[]) => {
            if (message_sender_number !== externalNumber) {
                return prev;
            }
            return [
                ...prev,
                {
                    id: message_id,
                    message: message_text,
                    sender_phone_number: externalNumber,
                    receiver_phone_number: tandemNumber,
                    added_at: new Date().toISOString(),
                    viewed_at: new Date().toISOString(),
                },
            ];
        });
    }, [lastMessage, externalNumber, tandemNumber]);

    useEffect(() => {
        if (conversation.length > 0) {
            setTimeout(() => {
                messagesEndRef.current?.scrollIntoView();
            }, 0);
        }
    }, [conversation.length, userId]);

    useEffect(() => {
        if (externalNumber === '') {
            setHeaderText('No conversation selected');
            setConversation([]);
        } else {
            if (displayName) {
                setHeaderText(`${formatPhoneNumber(externalNumber)}  ☎️  ${displayName}`);
            } else {
                setHeaderText(formatPhoneNumber(externalNumber));
            }
            updateConversation(true);
        }
    }, [externalNumber, displayName]);

    function updateConversation(mark_as_read: boolean) {
        refreshConversation(
            tandemNumber,
            externalNumber,
            setConversation,
            mark_as_read,
            () => setNewMessage(''),
            thread_id,
        );
    }

    function onOpenChatwootClick() {
        const url = (id?: number) => `${ChatwootUrl}/app/accounts/${ChatwootAccountId}/conversations/${id}`;

        if (chatwootConversationId) window.open(url(chatwootConversationId));

        client(`support/admin/chatwoot-conversation/most-recent-open-conversation/?user_id=${userId}`)
            .then((response) => {
                if (response) {
                    setChatwootConversationId(response.id);
                    window.open(url(response.id));
                } else {
                    client(`support/admin/chatwoot-conversation/`, {
                        method: 'POST',
                        body: { user_id: userId },
                    })
                        .then((response) => {
                            if (response) {
                                setChatwootConversationId(response.id);
                                window.open(url(response.id));
                            }
                        })
                        .catch((error) => setError(error));
                }
            })
            .catch((error) => setError(error));
    }

    const sendMessage = async () => {
        await client('tracking/api/external-message/send/', {
            body: {
                receiver_phone_number: externalNumber,
                sender_phone_number: tandemNumber,
                message: newMessage,
                override_chatwoot: !disabled,
            },
            method: 'POST',
        }).catch(consoleLogInDev);
        updateConversation(false);
    };

    const handleMarkUnread = (toSnooze = false) => {
        const requestBody = {
            receiver_phone_number: tandemNumber,
            sender_phone_number: externalNumber,
            to_snooze: toSnooze,
        };
        markUnread(requestBody)
            .then(() => onMarkUnread?.())
            .catch(consoleLogInDev);
    };

    return (
        <Grid
            item
            style={{
                marginLeft: 20,
                marginRight: 20,
                borderRadius: 18,
                padding: 10,
                maxHeight: '80vh',
            }}
        >
            <Grid container direction="column" style={{ marginTop: 10 }}>
                <Grid item container justify="space-around" style={{ marginBottom: 20 }}>
                    {region ? (
                        <Text variant="caption" bold color="red">
                            This provider is in: {region}
                        </Text>
                    ) : null}

                    <Text variant="h2">{headerText} </Text>

                    <Text
                        variant="body1"
                        textStyle={{ textDecorationLine: 'underline', cursor: 'pointer' }}
                        onClick={() => setModalOpen(true)}
                    >
                        See other threads
                    </Text>
                    {medStatus ? <Text>Med Status: {medStatus}</Text> : null}
                </Grid>
                <Grid item container style={{ marginBottom: 10 }}>
                    <SubAvailabilityDialog userId={userId} />
                </Grid>
                <Grid container item style={{ flexGrow: 1, overflow: 'scroll', maxHeight: '90vh' }}>
                    {conversation.map((item) => {
                        return <OutreachConversationRow key={item.id} data={item} externalNumber={externalNumber} />;
                    })}
                    <Grid container item direction="row" style={{ gap: 20 }}>
                        <Text>
                            {displayName} {userId ? `(${userId})` : ''}
                        </Text>
                        {userId ? (
                            <TextButton
                                buttonStyle={{ width: 150 }}
                                onClick={() => {
                                    setIsManageShiftOpen(true);
                                    setSelectedTimestamps([]);
                                }}
                            >
                                Manage Shift
                            </TextButton>
                        ) : null}
                        <TimestampModal
                            isOpen={isManageShiftOpen}
                            onClose={() => setIsManageShiftOpen(false)}
                            providerUserId={userId}
                            selectedTimestamps={selectedTimestamps}
                            setSelectedTimestamps={setSelectedTimestamps}
                            onComplete={() => setSelectedTimestamps(undefined)}
                        />
                    </Grid>
                    {chatwootEnabled && (
                        <ChatwootContainer container item direction="row">
                            <div>
                                <Text
                                    textStyle={{
                                        backgroundColor: Colors.white,
                                        borderRadius: 8,
                                        color: Colors.error,
                                        padding: 5,
                                    }}
                                >
                                    This user has Chatwoot enabled - all communication should go through there. The
                                    above conversation is displayed to show the conversation history and should not be
                                    added to.
                                </Text>
                                <ActionsContainer>
                                    <Checkbox
                                        checked={!disabled}
                                        onChange={() => setDisabled(!disabled)}
                                        label="I REALLY need to send them a message here"
                                    />
                                    <Text
                                        onClick={onOpenChatwootClick}
                                        variant="body2"
                                        textStyle={{
                                            cursor: 'pointer',
                                            textDecorationLine: 'underline',
                                            whiteSpace: 'nowrap',
                                            alignSelf: 'center',
                                        }}
                                        bold
                                    >
                                        Open Chatwoot
                                    </Text>
                                </ActionsContainer>
                            </div>
                            {error && (
                                <Text
                                    textStyle={{
                                        backgroundColor: Colors.white,
                                        borderRadius: 8,
                                        color: Colors.error,
                                        padding: 5,
                                    }}
                                    variant="caption"
                                >
                                    Somehting went wrong: {error}
                                </Text>
                            )}
                        </ChatwootContainer>
                    )}
                    {externalNumber ? (
                        <Grid item container justify="flex-end" alignItems="center">
                            <Grid item xs={9}>
                                <TextField
                                    multiline
                                    rows={3}
                                    fullWidth
                                    variant="outlined"
                                    label="New message"
                                    value={newMessage}
                                    onChange={(event) => setNewMessage(event.target.value)}
                                />
                            </Grid>
                            <Grid style={{ paddingLeft: 10 }} item alignContent="center" direction="row" xs={3}>
                                <Grid item style={{ paddingBottom: 10 }}>
                                    <PrimaryButton
                                        buttonStyle={{ minWidth: 100, width: undefined }}
                                        disabled={!newMessage || disabled}
                                        onClick={sendMessage}
                                    >
                                        Send
                                    </PrimaryButton>
                                </Grid>
                                <Grid item>
                                    <SecondaryButton onClick={handleClick}>Mark Unread</SecondaryButton>
                                    <Menu
                                        id="basic-menu"
                                        anchorEl={anchorEl}
                                        open={open}
                                        onClose={handleClose}
                                        MenuListProps={{
                                            'aria-labelledby': 'basic-button',
                                        }}
                                    >
                                        <MenuItem onClick={() => handleMarkUnread()}>Mark Unread</MenuItem>
                                        <MenuItem onClick={() => handleMarkUnread(toSnooze)}>2 Day Snooze</MenuItem>
                                    </Menu>
                                </Grid>
                            </Grid>
                        </Grid>
                    ) : null}

                    {messageSuggestions && messageSuggestions.length > 0 ? (
                        <MessageSuggestions
                            messageSuggestions={messageSuggestions}
                            setNewMessage={setNewMessage}
                            onRefresh={getMessageSuggestions}
                        />
                    ) : null}

                    <div ref={messagesEndRef} />
                </Grid>
            </Grid>

            <OtherThreadModal
                tandemNumber={tandemNumber}
                externalNumber={externalNumber}
                open={modalOpen}
                closeModal={() => setModalOpen(false)}
            />
        </Grid>
    );
}

const ChatwootContainer = styled(Grid)({
    gap: 10,
    borderRadius: 8,
    border: `1px solid ${Colors.error}`,
    marginTop: 10,
    marginBottom: 10,
    padding: 5,
});

const ActionsContainer = styled('div')({
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
});
