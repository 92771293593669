import client from './ApiClient';

const userAttributesApiPath = 'api/user-attributes/';

async function exists(name: string) {
    const userAttributes = await client(userAttributesApiPath);
    return userAttributes.some((attribute: any) => attribute.name === name);
}

function create(name: string, userId?: number) {
    return client(userAttributesApiPath, { body: { name, user: userId } });
}

function remove(id: number) {
    return client(`${userAttributesApiPath}${id}/`, { method: 'DELETE' });
}

const defaultExport = {
    exists,
    create,
    remove,
};

export default defaultExport;
