import React from 'react';
import { BusinessJobTemplateDetailsDTO, BusinessJobTemplateListDTO } from 'parent-portal/forms/JobTemplateModels';
import { Colors } from 'library';
import { styled, Grid } from '@mui/material';
import JobTemplateOptionJobDetails from './JobTemplateOptionJobDetails';
import JobTemplateOptionScheduleInfo from './JobTemplateOptionScheduleInfo';
import { useJobFormContext } from 'parent-portal/forms/JobFormContext';
import { Check } from '@mui/icons-material';

export default function JobTemplateOption({
    jobTemplate,
    showCheckbox = true,
}: {
    jobTemplate: BusinessJobTemplateListDTO | BusinessJobTemplateDetailsDTO;
    showCheckbox?: boolean;
}) {
    const { form, setDatesConfirmed } = useJobFormContext();
    const { templateJobId } = form.fieldStates;
    const handleSelectJobTemplate = () => {
        templateJobId?.setValue(jobTemplate.id);
        setDatesConfirmed(false);
    };

    return (
        <Container onClick={handleSelectJobTemplate} selected={templateJobId?.value === jobTemplate.id}>
            <DetailsContainer>
                <CheckBoxDetailsContainer>
                    {showCheckbox && (
                        <CheckBoxContainer>
                            {templateJobId?.value === jobTemplate.id && <Check htmlColor={Colors.darkNavy} />}
                        </CheckBoxContainer>
                    )}
                    <JobTemplateOptionJobDetails jobTemplate={jobTemplate} />
                </CheckBoxDetailsContainer>
                <JobTemplateOptionScheduleInfo jobTemplate={jobTemplate} />
            </DetailsContainer>
            <Divider />
        </Container>
    );
}

const Container = styled(Grid)(({ selected }: { selected: boolean }) => ({
    '&:hover': {
        backgroundColor: Colors.lightGrey,
    },
    flexDirection: 'column',
    backgroundColor: selected ? Colors.lightTurq : Colors.white,
    padding: '8px 0 0',
}));

const CheckBoxDetailsContainer = styled('div')({
    display: 'flex',
    flexDirection: 'row',
});

const CheckBoxContainer = styled('div')({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 24,
    height: 24,
    borderRadius: 20,
    border: `1px solid ${Colors.darkNavy}`,
    marginRight: 4,
    padding: 14,
    alignSelf: 'center',
    boxShadow: '0px 1px 10px rgba(0,1,1,0.15)',
    backgroundColor: Colors.white,
});

const DetailsContainer = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 4,
});

const Divider = styled('div')({
    width: '100%',
    height: 1,
    backgroundColor: Colors.mediumGrey,
    margin: '8px 0 0',
});
