import { NotificationType } from 'models';
import { History } from 'history';
import { client, consoleLogInDev } from 'shared';

export function handleNotificationAction(
    notification: NotificationType,
    history: History<any>,
    setActionMessage: (message: string | undefined) => void,
    openModal: (name: string) => void,
) {
    let webAction = notification.actions?.find((action) => action.action_type === 'web');
    if (webAction?.action === 'navigate') {
        history.push(webAction.path);
    } else if (webAction?.action === 'call_api') {
        let jsonData = webAction.extra_data as any;
        client(webAction.path, jsonData ? { method: jsonData.method, body: jsonData.body } : undefined)
            .then(() => {
                jsonData?.success_message && setActionMessage(jsonData.success_message);
            })
            .catch((error) => {
                consoleLogInDev(error);
                jsonData?.failureMessage && setActionMessage(jsonData.failure_message);
            });
    } else if (webAction?.action === 'open_modal') {
        openModal(webAction.path);
    }
}
