import React, { useState, useMemo, KeyboardEvent } from 'react';
import { BugReport, BugReportRes, AdminProviderListUser } from 'models';
import BugReportView from './BugReportView';
import { IconButton, OutlinedTextInput, AlertBar, Colors, Text } from 'library';
import { client, consoleLogInDev } from 'shared';
import { Grid } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Send, HourglassTop } from '@mui/icons-material';

const styles = {
    outlinedTextInput: { padding: 10 },
    alertBarCloseIconWrap: { right: 20, cursor: 'pointer' },
    alertBarCloseIcon: { color: Colors.darkNavy },
};

export default function BugReportsModal({
    user,
    bugReports,
    getBugReports,
}: {
    user: AdminProviderListUser;
    bugReports: BugReport[];
    getBugReports: () => void;
}) {
    const [bugDescription, setBugDescription] = useState<string>('');
    const [openAlert, setOpenAlert] = useState<boolean>(false);
    const [alertText, setAlertText] = useState<string>();
    const [loading, setLoading] = useState<boolean>(false);

    const onCloseAlert = () => {
        setOpenAlert(false);
        setAlertText('');
    };

    const map = useMemo(() => {
        return {
            Enter: false,
            Meta: false,
        };
    }, []);

    function onKeyEvent(event: KeyboardEvent) {
        if (event.key === 'Enter' || event.key === 'Meta') {
            map[event.key] = event.type === 'keydown';
        }
        if (map['Enter'] && map['Meta'] && bugDescription && bugDescription.trim().length > 0) {
            submit();
            map['Enter'] = false;
            map['Meta'] = false;
        }
    }

    const submit = () => {
        setLoading(true);
        if (bugDescription) {
            client(`api/bug-report/`, {
                body: { bug_description: bugDescription, user: user.id },
            })
                .then((res: BugReportRes) => {
                    setBugDescription('');
                    getBugReports();
                    if (res.db_res !== 201 || res.monday_res_post !== 200 || res.monday_res_update !== 200) {
                        handleError(res);
                    }
                })
                .catch(handleError)
                .finally(() => {
                    setLoading(false);
                });
        }
    };

    const handleError = (res?: BugReportRes) => {
        const alertText = `Error feeding bug report to Monday.com ${
            res
                ? `- DB response: ${res.db_res}; Monday creation response: ${res.monday_res_post}; Monday update response: ${res.monday_res_update}`
                : ''
        }`;
        setAlertText(alertText);
        setOpenAlert(true);
        consoleLogInDev(alertText);
    };

    return (
        <>
            <OutlinedTextInput
                style={styles.outlinedTextInput}
                multiline
                fullWidth
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setBugDescription(e.target.value)}
                value={bugDescription}
                InputProps={{
                    endAdornment: (
                        <IconButton
                            icon={loading ? HourglassTop : Send}
                            onClick={
                                !loading && bugDescription && bugDescription.trim().length > 0 ? submit : () => null
                            }
                        />
                    ),
                }}
                onKeyDown={(event: KeyboardEvent) => onKeyEvent(event)}
                onKeyUp={(event: KeyboardEvent) => onKeyEvent(event)}
            />
            {bugReports.map((bugReport) => (
                <BugReportView
                    key={bugReport.id}
                    bugReport={bugReport}
                    refresh={getBugReports}
                    handleError={handleError}
                />
            ))}
            <AlertBar open={openAlert} onClose={onCloseAlert} variant="error">
                <Grid container justify="space-between" direction="row">
                    <Grid item>
                        <Text variant="body1">{alertText}</Text>
                    </Grid>
                    <Grid item style={styles.alertBarCloseIconWrap}>
                        <CloseIcon onClick={onCloseAlert} style={styles.alertBarCloseIcon} />
                    </Grid>
                </Grid>
            </AlertBar>
        </>
    );
}
