import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import { Text, BasicDialog, PrimaryButton } from 'library';
import { Label } from 'models';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { FilterCheckboxSection } from './FilterCheckboxSection';
import { FilterLabelSection } from './FilterLabelSection';
import RegionMultiSelect from 'internal/shared/RegionMultiSelect';

interface IFilterSearch {
    providerLabels: number[];
    excludedProviderLabels: number[];
    state?: string[];
}

interface IFilters {
    open: boolean;
    onClose: () => void;
    onSearch: (searchObj: IFilterSearch) => void;
}
const stateOptions = ['Ohio', 'Florida', 'Illinois', 'Kentucky', 'Indiana'];

export default function Filters({ open, onClose, onSearch }: IFilters) {
    const [providerLabels, setProviderLabels] = useState<Label[]>([]);
    const [excludedProviderLabels, setExcludedProviderLabels] = useState<Label[]>([]);
    const [stateList, setStateList] = useState<string[]>([]);
    const [regions, setRegions] = useState<number[]>([]);
    const [newToChildcare, setNewToChildcare] = useState<string[]>([]);

    const onSearchClick = () => {
        const searchObject = {
            providerLabels: providerLabels.map((x) => x.id),
            excludedProviderLabels: excludedProviderLabels.map((x) => x.id),
            state: stateList,
            regions: regions,
            newToChildcare: newToChildcare.length > 0 ? newToChildcare[0] : undefined,
        };
        onSearch(searchObject);
    };

    const sections = [
        {
            title: 'State',
            content: (
                <FilterCheckboxSection
                    options={stateOptions}
                    selectedOptions={stateList}
                    setSelectedOptions={setStateList}
                />
            ),
        },
        {
            title: 'Regions',
            content: <RegionMultiSelect regions={regions} setRegions={setRegions} autoPopulate={false} />,
        },
        {
            title: 'Provider Labels',
            content: <FilterLabelSection selectedLabels={providerLabels} setSelectedLabels={setProviderLabels} />,
        },
        {
            title: 'Exclude Provider Labels',
            content: (
                <FilterLabelSection
                    selectedLabels={excludedProviderLabels}
                    setSelectedLabels={setExcludedProviderLabels}
                />
            ),
        },
        {
            title: 'New to Childcare',
            content: (
                <FilterCheckboxSection
                    options={['Yes', 'No']}
                    selectedOptions={newToChildcare}
                    setSelectedOptions={setNewToChildcare}
                    singleSelect={true}
                />
            ),
        },
    ];

    return (
        <BasicDialog closeButton isOpen={open} onClose={onClose}>
            <Grid container item style={{ marginTop: '5%' }} direction="column">
                {sections.map((section, index) => (
                    <Accordion key={index}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Text variant="body1" bold>
                                {section.title}
                            </Text>
                        </AccordionSummary>
                        <AccordionDetails>{section.content}</AccordionDetails>
                    </Accordion>
                ))}
            </Grid>
            <Grid container item style={{ marginTop: '5%' }}>
                <PrimaryButton onClick={onSearchClick}>Search</PrimaryButton>
            </Grid>
        </BasicDialog>
    );
}
