import { Grid } from '@mui/material';
import PaymentInformation from 'models/PaymentInformation';
import React, { useEffect, useState } from 'react';
import { useUserContext } from 'UserContext';
import { PayBackgroundPaper } from './PayPapers';
import PaySectionHeader from './PaySectionHeader';
import { Text, OutlinedDropdown } from 'library';
import ManualConfirmation from '../setup/ManualConfirmation';

export default function MicrodepositsEntry() {
    const [selectedAccount, setSelectedAccount] = useState('');

    const { user } = useUserContext();
    const paymentSetup = user?.payment_information;

    const accountsToVerify = paymentSetup?.filter((x: PaymentInformation) => x.status === 'NEW');
    const accountOptions = accountsToVerify?.map((x) => {
        return { key: x.id.toString(), value: x.last_four || '' };
    });

    const options = [{ key: '0', value: 'Select Account' }, ...(accountOptions || [])];

    useEffect(() => {
        if (accountOptions?.length) {
            setSelectedAccount(accountOptions[0].key);
        }
    }, [paymentSetup]);

    return accountsToVerify?.length ? (
        <Grid item md={12}>
            <PayBackgroundPaper>
                <PaySectionHeader>Manual Account Verification</PaySectionHeader>
                <Text variant="body1">
                    You&apos;ll receive two deposits and withdrawals, each less than $1, on your bank statement over the
                    next three days. Please verify the amounts to complete your account setup.
                </Text>
                <Grid container direction="row" alignItems="center" style={{ padding: 5 }}>
                    <Text variant="body2" textStyle={{ marginRight: 5 }}>
                        Account Ending In:
                    </Text>
                    <OutlinedDropdown
                        fields={options}
                        value={selectedAccount || ''}
                        onChange={(x) => setSelectedAccount(x.target.value)}
                        displayEmpty
                    />
                </Grid>
                <ManualConfirmation paymentInfoId={parseInt(selectedAccount)} />
            </PayBackgroundPaper>
        </Grid>
    ) : null;
}
