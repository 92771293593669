import React, { useState } from 'react';
import { Text } from 'library';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useHistory } from 'react-router-dom';
import { MenuItem, Menu } from '@mui/material';

interface IProps {
    options: IOption[];
    onClose?: () => void;
}

interface IOption {
    text: string;
    to?: string;
    onClick?: () => void;
    icon?: React.ReactNode;
}

export const Options = ({ options, onClose }: IProps) => {
    const [open, setOpen] = useState(false);
    const history = useHistory();

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
        setOpen(!open);
    };

    function closeOnNavigation() {
        setOpen(false);
        onClose?.();
    }

    return (
        <>
            <div onClick={handleClick} id="more-job-card-options">
                <MoreVertIcon style={{ cursor: 'pointer' }} />
            </div>
            <Menu
                sx={{
                    '& .MuiMenu-paper': {
                        borderRadius: '8px',
                    },
                    '& .MuiMenu-list': {
                        padding: '8px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                    },
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={() => setOpen(false)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                {options.map((option) => (
                    <MenuItem
                        autoFocus={false}
                        sx={{ '&.MuiButtonBase-root': { minHeight: '36px' } }}
                        key={option.text}
                        onClick={() => {
                            if (option.to) history.push(option.to);
                            else option?.onClick?.();
                            closeOnNavigation();
                        }}
                    >
                        <Text>
                            {option.icon} {option.text}
                        </Text>
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
};
