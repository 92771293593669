import { Grid } from '@mui/material';
import React from 'react';
import { Text, Colors, Highlight } from 'library';
import ErrorIcon from '@mui/icons-material/Error';

export default function TrialRunSection() {
    const trialRunDetails = [
        {
            title: 'Post your Trial Run with your schedule, requirements, and preferences.',
            content:
                "You'll be able to specify any credentials you require the worker to have. This may include a certain level of education, years of experience, etc.",
            opacity: 1,
        },
        {
            title: 'Tandem pairs you with a worker that is the best possible match.',
            content:
                "To find you the best possible match, our Matching Specialists and technology-based solution will match your job's schedule and requirements to a worker that fulfills the criteria.",
            opacity: 0.75,
        },
        {
            title: 'Once confirmed, the 120 hour Trial Run will begin.',
            content:
                "You will be notified once we have made a match and you will receive the schedule and worker's compliance documents via email. The worker will then start their 120 hour Trial Run.",
            extra: (
                <Text bold textStyle={{ marginTop: 10 }}>
                    <ErrorIcon
                        style={{
                            backgroundColor: `rgba(237,106,90,.5)`,
                            color: Colors.terraCotta,
                            borderRadius: 100,
                            padding: 0,
                            marginRight: 10,
                        }}
                    />
                    You can end the Trial Run with the worker at any point if it isn't the right fit.
                </Text>
            ),
            opacity: 0.5,
        },
        {
            title: 'At the end of the Trial Run, you can onboard the worker to your staff.',
            content:
                'Once the worker completes the 120 hours, you can onboard them to your staff as a permanent employee. You will not pay Tandem any additional fee to onboard the worker.',
            opacity: 0.25,
        },
    ];
    return (
        <Grid
            xs={12}
            item
            container
            style={{ padding: 40, gap: 40, backgroundColor: Colors.lightTurq }}
            flexDirection="column"
            alignItems="center"
        >
            <Text variant="h1" textStyle={{ textAlign: 'center' }}>
                A new approach to permanent hiring:
            </Text>
            <Text variant="display" textStyle={{ textAlign: 'center' }}>
                <Highlight color={Colors.lightTerraCotta}>Trial Runs</Highlight>
            </Text>
            <Grid
                xs={12}
                md={8}
                style={{
                    borderRadius: 18,
                    boxShadow: '0px 3px 6px #00000029',
                    gap: 20,
                    padding: 20,
                    backgroundColor: Colors.white,
                }}
                item
                container
                flexDirection="column"
            >
                {trialRunDetails.map((section, index) => (
                    <Grid
                        key={index}
                        item
                        container
                        flexDirection="row"
                        style={{
                            borderLeft: `20px solid rgba(237,106,90,${section.opacity})`,
                            padding: 20,
                        }}
                    >
                        <Grid item container xs={12} flexDirection="column">
                            <Grid
                                item
                                container
                                style={{
                                    height: 60,
                                    width: 60,
                                    borderRadius: 100,
                                    backgroundColor: `rgba(237,106,90,${section.opacity})`,
                                }}
                                justifyContent="center"
                                alignItems="center"
                            >
                                <Text
                                    variant="h2"
                                    textStyle={{
                                        color: section.opacity > 0.5 ? Colors.white : Colors.terraCotta,
                                        width: 'unset',
                                        textAlign: 'center',
                                        opacity: section.opacity > 0.5 ? 1 : 1.5 - section.opacity,
                                    }}
                                >
                                    {index + 1}
                                </Text>
                            </Grid>

                            <Text variant="h1" textStyle={{ marginTop: 5 }}>
                                {section.title}
                            </Text>
                            <Text>{section.content}</Text>
                            {section.extra && section.extra}
                        </Grid>
                    </Grid>
                ))}
            </Grid>
        </Grid>
    );
}
