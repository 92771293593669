import React from 'react';
import { Notifications } from '@material-ui/icons';
import { Badge } from '@material-ui/core';
import { useHistory } from 'react-router';
import { track } from 'shared';
import AccountMenuItem from './AccountMenuItem';
import { Colors } from 'library';
import { useNotificationContext } from 'parent-portal/notifications/NotificationContext/NotificationContext';
import usePublicSessionTracking, { PublicSessionEventType } from 'parent-portal/shared/PublicSessionTracking';

export default function NotificationMenuItem() {
    const history = useHistory();
    const { unacknowledgedCount, acknowledgeNotifications } = useNotificationContext();
    const { trackSession } = usePublicSessionTracking('Top Navigation - Profile Picture - Item');

    const notificationsClicked = () => {
        track(`Profile dropdown clicked: Notifications`);
        trackSession('CLICK', 'Notifications', PublicSessionEventType.User);
        acknowledgeNotifications();
        history.push('/notifications');
    };

    return (
        <AccountMenuItem
            icon={
                <Badge
                    invisible={!unacknowledgedCount}
                    overlap="circle"
                    badgeContent={
                        <div
                            style={{ backgroundColor: Colors.mediumRed, width: 12, height: 12, borderRadius: '50%' }}
                        />
                    }
                >
                    <Notifications color="primary" />
                </Badge>
            }
            text="Notifications"
            action={notificationsClicked}
        />
    );
}
