import { IComplianceWarnings } from 'models';
import { Grid } from '@material-ui/core';
import { Colors, Text } from 'library';
import React from 'react';
import ActionButton from './ActionButton';

export default function ComplianceWarnings({
    warnings,
    refreshProvider,
}: {
    warnings?: IComplianceWarnings[];
    refreshProvider: () => void;
}) {
    const colorsByType: { [key: string]: string } = {
        error: Colors.red,
        description: Colors.darkNavy,
    };

    if (!warnings || warnings.length === 0) return null;

    return (
        <Grid item container style={{ marginBottom: 20 }}>
            <Text variant="h2" bold textStyle={{ margin: 10, color: Colors.turquoise }}>
                Compliance Warnings
            </Text>
            <Grid item container style={{ gap: 20 }} xs={12}>
                {warnings.map((warning, index) => (
                    <Grid
                        key={index}
                        xs={12}
                        container
                        item
                        md={5}
                        lg={3}
                        direction="column"
                        style={{ padding: 10, borderRadius: 18, backgroundColor: Colors.lightTurq, flexShrink: 1 }}
                    >
                        <Text variant="body1" bold textStyle={{ margin: 10 }}>
                            {warning.title}
                        </Text>
                        {warning.paragraphs.map((p) => (
                            <>
                                <Text
                                    key={p.title || p.message}
                                    variant="body2"
                                    textStyle={{
                                        margin: 5,
                                        marginLeft: 10,
                                        color: colorsByType[p.type] || Colors.darkNavy,
                                    }}
                                >
                                    {p.title ? (
                                        <Text
                                            variant="body2"
                                            bold
                                            inline
                                            textStyle={{ color: colorsByType[p.type] || Colors.darkNavy }}
                                        >
                                            {p.title}{' '}
                                        </Text>
                                    ) : null}
                                    {p.message} <ActionButton action={p.action} refreshProvider={refreshProvider} />
                                </Text>
                            </>
                        ))}
                    </Grid>
                ))}
            </Grid>
        </Grid>
    );
}
