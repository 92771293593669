import React from 'react';
import theme from 'theme';
import { SizeableRoundedDialog as Dialog } from 'library';
import { useMediaQuery } from '@mui/material';

import { JobFormContextProvider } from 'parent-portal/forms/JobFormContext';

import { AutoHireWorkerInnerDialog } from './AutoHireWorkerInnerDialog';

export function AutoHireWorkerDialog({
    open,
    setOpen,
    addressId,
    defaultDay,
}: {
    open: boolean;
    setOpen: (open: boolean) => void;
    addressId?: number;
    defaultDay?: Date;
}) {
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const xs = useMediaQuery(theme.breakpoints.only('xs'));

    return (
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
            closeButton
            style={{ width: isMobile ? undefined : '60%', maxWidth: 643, maxHeight: '95vh' }}
            contentStyle={{ marginTop: 20 }}
            fullScreen={xs}
        >
            <JobFormContextProvider>
                <AutoHireWorkerInnerDialog setOpen={setOpen} addressId={addressId} defaultDay={defaultDay} />
            </JobFormContextProvider>
        </Dialog>
    );
}
