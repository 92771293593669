import styled from 'styled-components';
import { Grid } from '@material-ui/core';
import { OutlinedTextInput, Text } from 'library';

export const StyledGrid = styled(Grid)({
    width: '90%',
});

export const StyledTextInputRow = styled(OutlinedTextInput)({
    padding: '4px 10px',
    width: '100%',
});

export const StyledSubmitRow = styled(Grid)({
    alignItems: 'center',
    display: 'flex',
    padding: '10px 10px 0px 10px',
});

export const StyledErrorText = styled(Text)({
    alignItems: 'center',
    color: 'red',
    display: 'flex',
    maxWidth: '60%',
    paddingLeft: 10,
});
