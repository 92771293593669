import React from 'react';
import Label from 'reusableComponents/checkboxLabel';
import { Colors } from '../../constants';
import { Text } from 'library';
import { Checkbox, Link } from '@material-ui/core';

export default function ConsentCheckbox({
    consent,
    setConsent,
}: {
    consent: boolean;
    setConsent: (b: boolean) => void;
}) {
    return (
        <Label
            position="end"
            label={
                <Text variant="body3" textStyle={{ color: Colors.grey }}>
                    By signing up, I agree to Tandem's{' '}
                    <Link href="https://www.join-tandem.com/tandem-terms-of-use/center" underline="always">
                        Terms of Use
                    </Link>{' '}
                    and{' '}
                    <Link href="https://www.join-tandem.com/privacy-policy" underline="always">
                        Privacy Policy.
                    </Link>
                </Text>
            }
            control={
                <Checkbox
                    id="signup-consent-checkbox"
                    color="primary"
                    checked={consent}
                    onChange={onValueChanged}
                    value="primary"
                />
            }
        />
    );

    function onValueChanged(event: any) {
        setConsent(event.target.checked);
    }
}
