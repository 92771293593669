import React, { useState } from 'react';
import { BasicDialog, Text, Link, Checkbox, PrimaryButton } from 'library';
import { styled } from '@mui/material/styles';
import { client, consoleLogInDev } from 'shared';
import { LeanUserWithPhone, SubJobAppointment } from 'models';
import { Moment } from 'moment';
import { formatMonthDay, format12HourTime } from 'shared/Dates';
import { differenceInHours } from 'date-fns';
import RemoveProviderMessageModal from './RemoveProviderMessageModal';

export default function RemoveProviderModal({
    providerUser,
    open,
    onClose,
    appointment,
    refreshJobs,
    deleteSlot = false,
}: {
    providerUser?: LeanUserWithPhone;
    open: boolean;
    onClose: () => void;
    appointment: SubJobAppointment;
    refreshJobs: () => void;
    deleteSlot?: boolean;
}) {
    const startingProviderMessage = [
        `You have been removed on ${formatMonthDay(appointment.start_date)} @ ${format12HourTime(
            appointment.start_date,
        )}.`,
        'You no longer need to report to this shift.',
        'Please take a look in the app and apply to additional shifts you are able to work.',
    ].join(' ');

    const lastMinutCancel = differenceInHours(new Date(appointment.start_date), new Date()) <= 2;

    const [providerCancelled, setProviderCancelled] = useState(false);
    const [chargeBusiness, setChargeBusiness] = useState(lastMinutCancel);
    const [noShow, setNoShow] = useState(false);
    const [notifyBusiness, setNotifyBusiness] = useState(false);
    const [notifyProvider, setNotifyProvider] = useState(true);
    const [openNotifyProviderModal, setOpenNotifyProviderModal] = useState(false);
    const [notifyProviderMessage, setNotifyProviderMessage] = useState<string>(startingProviderMessage);
    const [loading, setLoading] = useState(false);

    function removeProvider() {
        if (providerUser) {
            setLoading(true);
            client(`api/admin-appointment/remove-provider/`, {
                body: {
                    user_id: providerUser?.id,
                    appointment_id: appointment.id,
                    provider_cancelled: providerCancelled,
                    provider_no_show: noShow,
                    notify_business: notifyBusiness,
                    notify_provider: notifyProvider,
                    notify_provider_message: notifyProviderMessage,
                    charge_business: chargeBusiness,
                    delete_slot: deleteSlot,
                },
            })
                .then(() => {
                    refreshJobs();
                    onClose();
                })
                .catch(consoleLogInDev)
                .finally(() => setLoading(false));
        }
    }

    return (
        <BasicDialog onClose={onClose} isOpen={open} closeButton>
            <Text variant="h1">Remove {providerUser?.first_name}</Text>
            <Checkbox
                label="Provider Cancelled"
                checked={providerCancelled}
                onChange={() => setProviderCancelled(!providerCancelled)}
            />
            <Checkbox label="Provider No Show" checked={noShow} onChange={() => setNoShow(!noShow)} />
            <Checkbox
                label="Notify Business"
                checked={notifyBusiness}
                onChange={() => setNotifyBusiness(!notifyBusiness)}
            />
            <StyledWrapper>
                <Checkbox
                    label="Notify Provider"
                    checked={notifyProvider}
                    onChange={() => setNotifyProvider(!notifyProvider)}
                />
                <Link style={{ whiteSpace: 'nowrap' }} onClick={() => setOpenNotifyProviderModal(true)}>
                    Edit Message
                </Link>
                <RemoveProviderMessageModal
                    open={openNotifyProviderModal}
                    onClose={() => setOpenNotifyProviderModal(false)}
                    message={notifyProviderMessage}
                    setMessage={setNotifyProviderMessage}
                />
            </StyledWrapper>
            {lastMinutCancel && (
                <Checkbox
                    label="Charge Business"
                    checked={chargeBusiness}
                    onChange={() => setChargeBusiness(!chargeBusiness)}
                />
            )}
            <PrimaryButton disabled={loading} onClick={removeProvider}>
                Submit
            </PrimaryButton>
        </BasicDialog>
    );
}

const StyledWrapper = styled('div')({
    display: 'flex',
});
