import React from 'react';
import { Chip, Colors, Text } from 'library';
import { Grid } from '@material-ui/core';
import { JobQualificationValue, Qualification } from 'models';

export default function Jobimportequirements({
    qualification_requirements,
    jobQualificationOptions,
}: {
    qualification_requirements: JobQualificationValue[];
    jobQualificationOptions: Qualification[];
}) {
    if (!jobQualificationOptions || !(qualification_requirements?.length > 0)) return <></>;

    function values() {
        return qualification_requirements.reduce((vals: string[], d: JobQualificationValue) => {
            let qualification = jobQualificationOptions.find((q: any) => q.id === d.qualification);
            if (qualification?.value_type === 'NUMBER') {
                console.log(qualification);
                return [...vals, `${d.value} ${qualification.name}`];
            }
            if (qualification?.value_type === 'BOOLEAN' && d.value === 'true') {
                return [...vals, qualification.name];
            }
            let option = qualification?.options.find((o: any) => o.id === d.option);
            return option && qualification
                ? [
                      ...vals,
                      `${qualification.web_view_options.header ?? qualification.web_view_options.sub_header} ${
                          option.value
                      }`,
                  ]
                : vals;
        }, []);
    }

    return (
        <Grid xs={12} item container>
            <Grid item container direction="column" style={{ gap: 5 }}>
                <Text variant="body1" bold>
                    Requirements:
                </Text>
                <Grid item container style={{ gap: 5 }}>
                    {values().map((n: string) => (
                        <Chip
                            key={n}
                            label={n}
                            style={{
                                textTransform: 'capitalize',
                                width: 'unset',
                            }}
                            backgroundColor={Colors.lightTurq}
                        />
                    ))}
                </Grid>
            </Grid>
        </Grid>
    );
}
