import React from 'react';
import { SubJobAppointment } from 'models';
import { Colors, Text } from 'library';
import ProviderForSubJob from './ProviderForSubJob';
import AppointmentMenu from './AppointmentMenu';
import { hasHired } from './appointmentFunctions';
import { Grid } from '@mui/material';
import SubJobAppointmentBaseCard from './Table/components/SubJobAppointmentBaseCard';

export default function SubJobAppointmentEntry({
    appt,
    businessLocationId,
    updateFlag,
    refreshJobs,
}: {
    appt: SubJobAppointment;
    businessLocationId?: number;
    updateFlag?: (staffId: number, businessLocationId: number) => void;
    refreshJobs: () => void;
}) {
    const approved = hasHired(appt);
    const hasMatches = (appt.matches ?? []).length > 0;

    function cardColor() {
        if (hasMatches) return Colors.pink;
        if (approved) return Colors.turquoise;
        return Colors.mediumRed;
    }

    const textColor = hasMatches ? Colors.darkNavy : Colors.white;

    return (
        <SubJobAppointmentBaseCard
            additionalHeaderContent={
                hasMatches ? (
                    <Grid>
                        <Text variant="body2" bold color={textColor}>
                            Exclusive Matches:
                        </Text>
                        {appt.matches?.map((m) => (
                            <Text
                                key={m.matched_worker}
                                bold
                                color={textColor}
                                textStyle={{ textTransform: 'capitalize' }}
                                variant="caption"
                            >
                                {m.matched_worker}
                            </Text>
                        ))}
                    </Grid>
                ) : undefined
            }
            menu={<AppointmentMenu appt={appt} refreshJobs={refreshJobs} />}
            additionalCardContent={
                <ProviderForSubJob
                    appt={appt}
                    businessLocationId={businessLocationId}
                    updateFlag={updateFlag}
                    refreshJobs={refreshJobs}
                />
            }
            cardColor={cardColor()}
            textColor={textColor}
            appt={appt}
        />
    );
}
