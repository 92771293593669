import React, { useEffect, useState } from 'react';
import { getMondayForThisWeek } from 'internal/substitutes/weekdayConstants';
import { OutlinedDropdown, Text } from 'library';
import { formatDateFromDateObject } from 'shared/Dates';

const thisMonday = getMondayForThisWeek();

export default function WeekSelection({
    week,
    setWeek,
    style,
}: {
    week?: Date;
    setWeek: (week: Date) => void;
    style?: React.CSSProperties;
}) {
    const [allWeeks, setAllWeeks] = useState<{ key: string; value: string }[]>([]);

    useEffect(() => {
        const weeks = [-12, -11, -10, -9, -8, -7, -6, -5, -4, -3, -2, -1, 0, 1, 2, 3, 4].map((offset) => {
            const newMonday = new Date(thisMonday);
            newMonday.setDate(thisMonday.getDate() + offset * 7);
            return {
                key: formatDateFromDateObject(newMonday),
                value: formatDateFromDateObject(newMonday),
            };
        });
        setAllWeeks(weeks);
        if (!week) {
            setWeek(thisMonday);
        }
    }, []);

    return (
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', ...style }}>
            <Text variant="body2" bold textStyle={{ marginRight: 8 }}>
                Week:
            </Text>
            <OutlinedDropdown
                id="week-selection"
                value={formatDateFromDateObject(week || thisMonday)}
                onChange={(e) => setWeek(new Date(e.target.value + 'T00:00:00'))}
                fields={allWeeks}
            />
        </div>
    );
}
