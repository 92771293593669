import React from 'react';
import { SvgIcon } from '@material-ui/core';
import { Colors } from '../../../constants';
import CloseIcon from '@material-ui/icons/Close';
import { CSSProperties } from '@material-ui/styles';

interface CloseButtonProps {
    onClose: () => void;
    id?: string;
    style?: CSSProperties;
    buttonStyle?: CSSProperties;
}

export default function CloseButton({ onClose, id, style, buttonStyle }: CloseButtonProps) {
    return (
        <button
            id={id ?? ''}
            style={{
                position: 'absolute',
                cursor: 'pointer',
                top: 23,
                right: 23,
                zIndex: 999,
                backgroundColor: 'transparent',
                border: 'none',
                padding: 0,
                ...style,
            }}
            onClick={onClose}
        >
            <SvgIcon
                component={CloseIcon}
                viewBox="0 0 26 26"
                style={{
                    color: Colors.darkNavy,
                    ...buttonStyle,
                }}
            />
        </button>
    );
}
