import React from 'react';
import { Paper, Text, Colors, Highlight } from 'library';
import { Grid, useMediaQuery } from '@mui/material';
import Teal from 'assets/images/marketing-site/trial-runs/teal-arrow.svg';
import Orange from 'assets/images/marketing-site/trial-runs/orange-arrow.svg';
import theme from 'theme';

const tandem = [
    'No upfront costs or contracts',
    'Pay only when the confirmed worker works',
    `Tandem qualifies candidates based on your job's requirements and schedule`,
    'All workers are compliant before you meet them',
];

const other = [
    'Pay upfront to post jobs',
    'Pay per applicant',
    'Manage end-to-end hiring process from qualifying to interviewing to hiring',
    'Required to help candidates complete compliance steps',
];

export default function TrialSummary() {
    const smDown = useMediaQuery(theme.breakpoints.down('sm'));
    const textStyle = {
        marginTop: 50,
        marginBottom: 30,
        marginLeft: smDown ? 10 : 0,
    };

    function getBullets(bullets: string[], tandem?: boolean) {
        return bullets.map((bullet, index) => (
            <Grid container item xs={12} key={index} alignItems="center" style={{ marginTop: 8 }}>
                <Grid item xs={1}>
                    {smDown ? <Text> - </Text> : tandem ? <Teal height={20} /> : <Orange height={20} />}
                </Grid>
                <Grid item xs={11}>
                    <Text variant={smDown ? 'caption' : 'body1'}>{bullet}</Text>
                </Grid>
            </Grid>
        ));
    }

    return (
        <Grid
            container
            item
            justifyContent="center"
            style={{ paddingBottom: 100, backgroundColor: Colors.white, paddingTop: 40 }}
            direction="column"
        >
            <Grid container item justifyContent="center">
                <Text variant="display" textStyle={textStyle}>
                    There are <Highlight color={Colors.lightTurq}>key differences</Highlight> between other tools and
                    Tandem.
                </Text>
            </Grid>
            <Grid container item justifyContent="center">
                <Paper
                    style={{
                        backgroundColor: Colors.white,
                        margin: smDown ? 10 : 80,
                        width: smDown ? undefined : '70vw',
                        borderRadius: smDown ? 54 : 71,
                        paddingTop: smDown ? 40 : 60,
                        paddingBottom: smDown ? 40 : 60,
                        paddingLeft: smDown ? 40 : 60,
                        paddingRight: smDown ? 20 : 40,
                    }}
                >
                    <div>
                        <Grid container spacing={2}>
                            <Grid container item xs={12}>
                                <Grid item xs={5}>
                                    <Text variant={smDown ? 'body1' : 'h1'} bold>
                                        {' '}
                                        Other Job Posting Sites
                                    </Text>
                                </Grid>
                                <Grid container item xs={1} justifyContent="center">
                                    <Text variant={smDown ? 'body1' : 'h1'} bold color={Colors.turquoise}>
                                        VS
                                    </Text>
                                </Grid>
                                <Grid item xs={5}>
                                    <Text variant={smDown ? 'body1' : 'h1'} bold textStyle={{ marginLeft: 15 }}>
                                        {' '}
                                        Tandem's Trial Run
                                    </Text>
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} justifyContent="center">
                                <div
                                    style={{
                                        borderTop: smDown
                                            ? `1px dashed ${Colors.darkNavy}`
                                            : `3px dashed ${Colors.darkNavy}`,
                                        width: '100%',
                                    }}
                                />
                            </Grid>
                            <Grid container item xs={12}>
                                <Grid item xs={5}>
                                    <Grid container direction="column" alignItems="center">
                                        {getBullets(other)}
                                    </Grid>
                                </Grid>
                                <Grid container item xs={1} justifyContent="center">
                                    <div
                                        style={{
                                            borderLeft: smDown
                                                ? `1px dashed ${Colors.darkNavy}`
                                                : `3px dashed ${Colors.darkNavy}`,
                                            height: '125%',
                                            marginTop: -42,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={5}>
                                    <Grid container direction="column" alignItems="center">
                                        {getBullets(tandem, true)}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                </Paper>
            </Grid>
        </Grid>
    );
}
