import { client } from 'shared';

export interface JobQualifiedRateDTO {
    id: number;
    worker_id: number;
    qualified_rate: number;
    provider_rate: number;
}

export function getJobQualifiedRate(jobId: number, workerId: number): Promise<JobQualifiedRateDTO> {
    return client(`api/job-qualified-rate/${jobId}/?worker=${workerId}`);
}
