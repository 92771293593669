import React, { useState } from 'react';
import Calendar from 'assets/icons/tandempay/calendar.svg';
import { Job, Provider } from 'models';
import ModalPage from 'parent-portal/payments/Components/ConnectPaymentModal/ModalPage';
import PageHeader from 'parent-portal/payments/Components/ConnectPaymentModal/PageHeader';
import { Text, Colors, PrimaryButton } from 'library';
import { Grid, useMediaQuery } from '@material-ui/core';
import { client, consoleLogInDev } from 'shared';
import BackButton from 'parent-portal/payments/Components/ConnectPaymentModal/BackButton';
import { OngoingDatePicker } from 'parent-portal/jobs/ViewScheduling';
import moment from 'moment';
import SetPaySchedule from '../components/SetPaySchedule';
import JobDays from '../JobDays';
import { IDateRange } from '../HireProviderModal';
import { PayWindow, PaymentSchedule } from 'parent-portal/forms/JobRequestTypes';
import theme from 'theme';

interface Props {
    onContinue: () => void;
    onBack: () => void;
    update: (dateRange: IDateRange) => void;
    job: Job;
    provider: Provider;
    daysAppliedTo: string[];
    unfilledDays: string[];
    flexible: boolean;
}

export default function SetDetails({
    onContinue,
    update,
    job,
    onBack,
    unfilledDays,
    daysAppliedTo,
    provider,
    flexible,
}: Props) {
    const smDown = useMediaQuery(theme.breakpoints.down('sm'));
    const jobStartDate = job.start_date;
    const defaultDateRange = {
        validDateRange: true,
        startDate: new Date(),
        endDate: moment(new Date()).add(28, 'days'),
    };

    const [hireDisabled, setHireDisabled] = useState(false);
    const [hireDateRange, setHireDateRange] = useState(defaultDateRange);
    const [payWindow, setPayWindow] = useState(job.pay_window);
    const [payDay, setPayDay] = useState(job.pay_day || 'Monday');

    const handleDateChange = (name: string, value: any, validRange: boolean) => {
        if (name === 'startDate') {
            hireDateRange.startDate = value;
        } else {
            hireDateRange.endDate = value;
        }
        hireDateRange.validDateRange = validRange;
        setHireDisabled(!validRange);
        setHireDateRange(hireDateRange);
        update(hireDateRange);
    };

    function updatePayWindow(payWindow: string) {
        setPayWindow(payWindow);
    }

    function updatePayDay(payDay: string) {
        setPayDay(payDay);
    }

    const submitPaySchedule = async () => {
        const schedule = {
            id: job.id,
            pay_window: payWindow,
            pay_day: payWindow === `WEEKLY` && payDay ? payDay : null,
        };

        await client(`api/ongoing/${job.id}/payment-schedule/`, {
            method: 'PATCH',
            body: schedule,
        }).catch((error) => {
            consoleLogInDev(error);
        });
    };

    return (
        <ModalPage>
            <PageHeader
                iconSource={<Calendar />}
                headerText="You're almost done."
                subtitleText={'Just finishing up the details.'}
            />
            <Grid container direction="column" justify="space-between" style={{ flexGrow: 1 }}>
                <Grid
                    container
                    item
                    direction="row"
                    justify="flex-start"
                    style={{
                        marginBottom: smDown ? 10 : 30,
                        paddingLeft: smDown ? 10 : 50,
                        paddingRight: smDown ? 10 : 50,
                    }}
                >
                    <JobDays
                        isOnetimeJob={!job.ongoing}
                        provider={provider}
                        daysAppliedTo={flexible ? job.days_of_week : daysAppliedTo}
                        totalDays={job.days_of_week?.length}
                        unfilledDays={unfilledDays}
                    />
                    <Grid container item direction="row" justify="center" xs={10}>
                        {jobStartDate === null ? (
                            <>
                                <Grid container item direction="row" style={{ marginTop: smDown ? 10 : 40 }}>
                                    <Text variant="h1">
                                        {jobStartDate === null ? '1. ' : ''} Set the start and end date
                                    </Text>
                                </Grid>
                                <OngoingDatePicker
                                    ongoingStartDate={jobStartDate}
                                    onDateChange={(a: string, b: any, c: boolean) => handleDateChange(a, b, c)}
                                />
                            </>
                        ) : null}
                        <Grid
                            container
                            item
                            direction="row"
                            style={{ marginTop: smDown ? 10 : 30, marginBottom: smDown ? 10 : 30 }}
                        >
                            <Grid container item direction="row">
                                <Text variant="h2">{jobStartDate === null ? '2. ' : ''}Set a payment schedule</Text>
                            </Grid>
                            <Grid container item direction="row">
                                <Text variant="body1" color={Colors.darkGrey}>
                                    Automate your payments or set them up to be paid weekly.
                                </Text>
                            </Grid>
                        </Grid>
                        <SetPaySchedule
                            initialPayWindow={payWindow as PayWindow}
                            initialPayDay={payDay as PaymentSchedule['payDay']}
                            updatePayWindow={updatePayWindow}
                            updatePayDay={updatePayDay}
                            style={{ margin: 10 }}
                        />
                    </Grid>
                </Grid>
                <Grid
                    container
                    item
                    direction="row"
                    justify="space-between"
                    alignItems="flex-end"
                    style={{ position: 'absolute', bottom: 10, padding: 10, paddingBottom: 4 }}
                >
                    <BackButton onClick={onBack} />
                    <Grid item>
                        <PrimaryButton
                            disabled={hireDisabled}
                            onClick={() => {
                                onContinue();
                                submitPaySchedule();
                            }}
                        >
                            Confirm Hire
                        </PrimaryButton>
                    </Grid>
                </Grid>
            </Grid>
        </ModalPage>
    );
}
