import React from 'react';
import { Grid } from '@material-ui/core';
import { Chip, Colors, Text } from 'library';
import { JobDetail, JobDetailOption, JobDetailValueWithOption } from 'models/Job';

export default function JobDetailOptions({
    jobDetailOptions,
    details,
}: {
    jobDetailOptions: JobDetail[];
    details: JobDetailValueWithOption[];
}) {
    if (!jobDetailOptions && !details?.length) return <></>;

    const values = details.reduce((values: { [key: string]: string[] }, d: JobDetailValueWithOption) => {
        const jobDetail = jobDetailOptions.find((j: JobDetail) => j.id === d.detail);
        if (jobDetail) {
            let currentValue = values[jobDetail.name] ?? [];
            const optionValue = jobDetail.options.find((o: JobDetailOption) => o.id === d.option?.id)?.value ?? '';
            currentValue = d.value
                ? [...currentValue, d.value]
                : optionValue
                  ? [...currentValue, optionValue]
                  : currentValue;
            values[jobDetail.name] = currentValue;
        }
        return values;
    }, {});

    return (
        <Grid xs={12} direction="column" item container style={{ gap: 20 }}>
            {Object.keys(values)
                .reverse()
                .map((k) => (
                    <Grid key={k} item container direction="row" alignItems="center" style={{ gap: 5, width: 'unset' }}>
                        <Text variant="body1" bold>
                            {k}:
                        </Text>
                        {values[k].map((n: string) => (
                            <Grid key={n} item container style={{ width: 'unset' }}>
                                <Chip
                                    label={n}
                                    style={{
                                        textTransform: 'capitalize',
                                    }}
                                    backgroundColor={Colors.lightTurq}
                                />
                            </Grid>
                        ))}
                    </Grid>
                ))}
        </Grid>
    );
}
