import { useEffect, useReducer, useState } from 'react';
import { client, consoleLogInDev, queryStringifyObject } from 'shared';
import {
    InitialState,
    InitialStateParam,
    ActionType,
    LocationJobsResponse,
    LocationJobsState,
    LocationReviewResponse,
    LocationReviewState,
    LocationWorkerResponse,
    LocationWorkerState,
    ResponseDataTypes,
    LocationOverviewState,
} from './models';
import { IBusinessLocationWithNotes } from 'models';

function useDataReducer(initialState: InitialStateParam) {
    return useReducer((state: InitialStateParam, action: ActionType): InitialStateParam => {
        switch (action.type) {
            case 'loading':
                return { ...initialState, status: 'loading' };
            case 'success':
                return { ...initialState, status: 'success', data: action.data };
            case 'error':
                return { ...initialState, status: 'error', error: action.error };
            case 'updated':
                return { ...initialState, status: 'updated', data: action.data };
            default:
                return state;
        }
    }, initialState);
}

export function useBusinessLocationOverview(businessLocationId: number) {
    const [cancelRequest, setCancelRequest] = useState(false);

    const initialState: InitialState = {
        status: 'idle',
        refresh: fetchBusinessLocationOverview,
        updateData: (d: ResponseDataTypes) => dispatch({ type: 'updated', data: d as IBusinessLocationWithNotes }),
    };

    const [state, dispatch] = useDataReducer(initialState) as [LocationOverviewState, React.Dispatch<ActionType>];

    async function fetchBusinessLocationOverview(page?: number, selectedHistoryTypes?: string[]) {
        const params =
            selectedHistoryTypes && !selectedHistoryTypes.includes('All')
                ? `?${queryStringifyObject({ history_types: selectedHistoryTypes.join(',') })}`
                : '';
        try {
            const response = await client(`api/business-location-overview/${businessLocationId}/${params}`);
            if (cancelRequest) return;
            dispatch({ type: 'success', data: response });
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error: any) {
            const message = error.message || 'Something went wrong!';
            dispatch({ type: 'error', error: message });
            consoleLogInDev(error);
        }
    }

    useEffect(() => {
        setCancelRequest(false);
        fetchBusinessLocationOverview();

        return function cleanup() {
            setCancelRequest(true);
        };
    }, [businessLocationId]);

    return state;
}

export function useBusinessLocationJobs(businessLocationId: number, includeClosed?: boolean) {
    const [cancelRequest, setCancelRequest] = useState(false);

    const initialState: InitialState = {
        status: 'idle',
        refresh: fetchBusinessLocationJobs,
        updateData: (d: ResponseDataTypes) => dispatch({ type: 'updated', data: d as LocationJobsResponse }),
    };

    const [state, dispatch] = useDataReducer(initialState) as [LocationJobsState, React.Dispatch<ActionType>];

    async function fetchBusinessLocationJobs() {
        try {
            const response = await client(
                `api/business-location-overview/${businessLocationId}/location_info?closed=${includeClosed}`,
            );
            if (cancelRequest) return;
            dispatch({ type: 'success', data: response });
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error: any) {
            const message = error.message || 'Something went wrong!';
            dispatch({ type: 'error', error: message });
            consoleLogInDev(error);
        }
    }

    useEffect(() => {
        setCancelRequest(false);
        fetchBusinessLocationJobs();

        return function cleanup() {
            setCancelRequest(true);
        };
    }, [businessLocationId, includeClosed]);

    return state;
}

export function useBusinessLocationWorker(businessLocationId?: number) {
    const [cancelRequest, setCancelRequest] = useState(false);

    const initialState: InitialState = {
        status: 'idle',
        refresh: fetchBusinessLocationOverview,
        updateData: (d: ResponseDataTypes) => dispatch({ type: 'updated', data: d as LocationWorkerResponse }),
    };

    const [state, dispatch] = useDataReducer(initialState) as [LocationWorkerState, React.Dispatch<ActionType>];

    async function fetchBusinessLocationOverview(page?: number, selectedHistoryTypes?: string[], showAll?: boolean) {
        try {
            const response = await client(
                `api/business-location-overview/${businessLocationId}/workers/?show_all=${showAll}`,
            );
            if (cancelRequest) return;
            dispatch({ type: 'success', data: response });
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error: any) {
            const message = error.message || 'Something went wrong!';
            dispatch({ type: 'error', error: message });
            consoleLogInDev(error);
        }
    }

    useEffect(() => {
        setCancelRequest(false);
        if (businessLocationId) {
            fetchBusinessLocationOverview();
        }

        return function cleanup() {
            setCancelRequest(true);
        };
    }, [businessLocationId]);

    return state;
}

export function useBusinessLocationReviews(businessLocationId: number) {
    const [cancelRequest, setCancelRequest] = useState(false);

    const initialState: InitialState = {
        status: 'idle',
        refresh: fetchBusinessLocationReviews,
        updateData: (d: ResponseDataTypes) => dispatch({ type: 'updated', data: d as LocationReviewResponse }),
    };

    const [state, dispatch] = useDataReducer(initialState) as [LocationReviewState, React.Dispatch<ActionType>];

    async function fetchBusinessLocationReviews() {
        try {
            const response = await client(`api/business-location-overview/${businessLocationId}/reviews`);
            if (cancelRequest) return;
            dispatch({ type: 'success', data: response });
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error: any) {
            const message = error?.message || 'Something went wrong!';
            dispatch({ type: 'error', error: message });
            consoleLogInDev(error);
        }
    }

    useEffect(() => {
        setCancelRequest(false);
        fetchBusinessLocationReviews();

        return function cleanup() {
            setCancelRequest(true);
        };
    }, [businessLocationId]);

    return state;
}
