import { client, consoleLogInDev } from 'shared';

export async function getMessageCount(all: boolean) {
    try {
        const queryParams = all ? '?all=True' : '?contacts_only=True';
        const result = await client(`count-unread-threads/${queryParams}`);
        return result.count;
    } catch (error) {
        consoleLogInDev(error);
        return 0;
    }
}
