import { BusinessSlot, ShiftProviderUser } from 'models';
import React, { useState } from 'react';
import { TextButton } from 'library';
import { EventBusy } from '@mui/icons-material';
import CancelModal from './CancelModal';

interface CancelShiftModalTriggerProps {
    provider: ShiftProviderUser;
    slot: BusinessSlot;
    refresh: () => void;
    textStyle?: React.CSSProperties;
    buttonStyle?: React.CSSProperties;
    providerId?: number;
}

const CancelShiftModalTrigger = ({
    provider,
    slot,
    refresh,
    textStyle,
    buttonStyle,
    providerId,
}: CancelShiftModalTriggerProps) => {
    const [cancelOpen, setCancelOpen] = useState(false);

    return (
        <>
            <TextButton onClick={() => setCancelOpen(true)} textStyle={textStyle} buttonStyle={buttonStyle}>
                <EventBusy /> Cancel this shift
            </TextButton>
            <CancelModal
                open={cancelOpen}
                onClose={() => setCancelOpen(false)}
                slot={slot}
                providerName={provider?.first_name}
                refresh={refresh}
                providerId={providerId}
            />
        </>
    );
};

export default CancelShiftModalTrigger;
