import { faPersonRunning } from '@fortawesome/free-solid-svg-icons';
import { useMediaQuery } from '@mui/material';
import AdminButton from 'internal/shared/AdminButton';
import { Colors, SizeableRoundedDialog } from 'library';
import { AdminListProvider } from 'models';
import React, { useState } from 'react';
import theme from 'theme';
import ReverseMatchingTabs from './components/ReverseMatchingTabs';

export default function ReverseMatching({
    provider,
    updateProvider,
    initialTabIndex,
    initialWeek,
}: {
    provider: AdminListProvider;
    updateProvider?: (providerId: number, fields: Partial<AdminListProvider>) => void;
    initialTabIndex?: number;
    initialWeek?: Date;
}) {
    const [applicantsOpen, setApplicantsOpen] = useState(false);
    const smDown = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <>
            <AdminButton
                onClick={() => {
                    setApplicantsOpen(!applicantsOpen);
                }}
                FontAwesomeImg={faPersonRunning}
                color={Colors.lightBlue}
                borderColor={Colors.darkNavy}
                text="Matching"
            />
            <SizeableRoundedDialog
                open={applicantsOpen}
                style={{ padding: 10, backgroundColor: Colors.white, width: smDown ? '95%' : '80%' }}
                onClose={() => setApplicantsOpen(false)}
                closeButton
            >
                <ReverseMatchingTabs
                    provider={provider}
                    updateProvider={updateProvider}
                    initialTabIndex={initialTabIndex}
                    initialWeek={initialWeek}
                />
            </SizeableRoundedDialog>
        </>
    );
}
