import { Grid, FormControlLabel, Radio, RadioGroup, TableCell, styled } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { client, consoleLogInDev } from 'shared';
import { PointHistory } from '../types';
import { PagingResult } from 'models';
import { Colors, LoadingIndicator, PrimaryButton, Text, TextArea } from 'library';
import PaginatedTable from '../../tables/PaginatedAdminTable';
import Row from '../../tables/Row';
import { format } from 'date-fns';
import { useCategories, useCreate } from '../hooks';

type PointHistoryProps = {
    userId: number;
    totalPoints: number;
    suspendedBy: string | null;
    ninetyDayProbationCount: number;
};

export default function PointHistoryList({
    userId,
    totalPoints,
    suspendedBy,
    ninetyDayProbationCount,
}: PointHistoryProps) {
    const [page, setPage] = useState(1);
    const [history, setHistory] = useState<PointHistory[]>([]);
    const [data, setData] = useState<PagingResult<PointHistory>>();
    const [loading, setLoading] = useState(false);
    const [revertId, setRevertId] = useState<number | null>(null);
    const [revertNoShowData, setRevertNoShowData] = useState<{ category: string; comment: string }>({
        category: '',
        comment: '',
    });

    const { data: categoriesData, loading: categoriesLoading } = useCategories();
    const {
        data: createData,
        loading: createLoading,
        error,
        status,
        send,
    } = useCreate({
        revertData: { ...revertNoShowData, point_history_id: revertId },
        options: { sendImmediately: false },
    });

    function getPointHistory() {
        setLoading(true);
        client(`api/point-history/${userId}/?page=${page}`)
            .then((res) => {
                setData(res);
                setHistory(res.results ?? []);
            })
            .catch(consoleLogInDev)
            .finally(() => setLoading(false));
    }

    useEffect(getPointHistory, [userId, page]);

    useEffect(() => {
        if (!createLoading && status === 200) {
            getPointHistory();
            setRevertId(null);
            setRevertNoShowData({ category: '', comment: '' });
        }
    }, [createLoading, status]);

    if (!data) return <Text>No History Found</Text>;

    const columns = ['Date', 'Name', 'Description', 'Value'];

    type HeaderTextArguments = Pick<PointHistoryProps, 'totalPoints' | 'suspendedBy' | 'ninetyDayProbationCount'>;
    function getHeaderText({ totalPoints, suspendedBy, ninetyDayProbationCount }: HeaderTextArguments) {
        const suspendedByText = suspendedBy ? `Suspended by: ${suspendedBy}` : '';
        const probationText =
            ninetyDayProbationCount > 0 ? `Ninety Day Probation Count: ${ninetyDayProbationCount}` : '';
        const totalPointsText = totalPoints ? `Current Points: ${totalPoints}` : 'PROBATION';

        return [totalPointsText, probationText, suspendedByText].filter(Boolean).join(' | ');
    }

    const selectedCategory = categoriesData?.categories?.find(
        (category) => category.name === revertNoShowData?.category,
    );
    const commentError = selectedCategory?.require_comment && !revertNoShowData?.comment;

    return (
        <Grid container item style={{ marginTop: 20, gap: 20 }}>
            <PaginatedTable
                data={data}
                page={page}
                updatePage={setPage}
                columns={columns}
                loading={loading}
                header={getHeaderText({ totalPoints, suspendedBy, ninetyDayProbationCount })}
            >
                {history.map((h) => (
                    <Row key={h.id} oddRowColor={Colors.lightTurq}>
                        <TableCell>{format(new Date(h.added_at), 'MM/dd/yyyy p')}</TableCell>
                        <TableCell>{h.point_value.name}</TableCell>
                        <TableCell>{h.point_value.description}</TableCell>
                        <TableCell>{h.point_value.value}</TableCell>
                        <TableCell>
                            {h.point_value.name === 'NO_SHOW' && (
                                <>
                                    <PrimaryButton
                                        onClick={() => (revertId === h.id ? setRevertId(null) : setRevertId(h.id))}
                                        buttonStyle={{ width: 'fit-content' }}
                                    >
                                        Revert
                                    </PrimaryButton>
                                    {revertId === h.id && (
                                        <div>
                                            {categoriesLoading || !categoriesData ? (
                                                <LoadingIndicator />
                                            ) : (
                                                <StyledForm>
                                                    <Text>Select a no show revert reason</Text>
                                                    <RadioGroup
                                                        name="Select a no show revert reason"
                                                        value={revertNoShowData?.category}
                                                        onChange={(e) =>
                                                            setRevertNoShowData({
                                                                ...revertNoShowData,
                                                                category: e.target.value,
                                                            })
                                                        }
                                                        style={{ flexDirection: 'column' }}
                                                    >
                                                        {categoriesData?.categories?.map(
                                                            (category: { name: string; value: string }) => (
                                                                <FormControlLabel
                                                                    key={category.name}
                                                                    value={category.name}
                                                                    control={
                                                                        <Radio
                                                                            checked={
                                                                                revertNoShowData?.category ===
                                                                                category.name
                                                                            }
                                                                            color="primary"
                                                                            disableRipple
                                                                            disableFocusRipple
                                                                        />
                                                                    }
                                                                    label={
                                                                        <Text variant="body2">
                                                                            {category.value.capitalize()}
                                                                        </Text>
                                                                    }
                                                                />
                                                            ),
                                                        )}
                                                    </RadioGroup>
                                                    <Text>Comment</Text>
                                                    <TextArea
                                                        onChange={(e) =>
                                                            setRevertNoShowData({
                                                                ...revertNoShowData,
                                                                comment: e.target.value,
                                                            })
                                                        }
                                                        value={revertNoShowData?.comment}
                                                        error={commentError}
                                                    />
                                                    <StyledButtonContainer>
                                                        <PrimaryButton
                                                            onClick={() =>
                                                                send({
                                                                    body: {
                                                                        ...revertNoShowData,
                                                                        point_history_id: revertId,
                                                                    },
                                                                })
                                                            }
                                                            buttonStyle={{
                                                                width: 'fit-content',
                                                            }}
                                                            disabled={commentError || !revertNoShowData?.category}
                                                            loading={createLoading}
                                                        >
                                                            Submit
                                                        </PrimaryButton>
                                                    </StyledButtonContainer>
                                                    {!!error && (
                                                        <Text
                                                            variant="caption"
                                                            textStyle={{ color: Colors.error, paddingTop: 5 }}
                                                        >
                                                            Error: {createData?.message}
                                                        </Text>
                                                    )}
                                                </StyledForm>
                                            )}
                                        </div>
                                    )}
                                </>
                            )}
                        </TableCell>
                    </Row>
                ))}
            </PaginatedTable>
        </Grid>
    );
}

const StyledForm = styled('div')({
    backgroundColor: Colors.white,
    borderRadius: 8,
    marginTop: 10,
    padding: 10,
});

const StyledButtonContainer = styled('div')({
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: 10,
});
