import React, { useEffect, useState } from 'react';
import DashboardSection from '../components/DashboardSection';
import { ISpotlightWorker, IWorkerSpotlight } from '../Models/DashboardData';
import { Grid } from '@mui/material';
import { client, consoleLogInDev } from 'shared';
import WorkerSpotlightCard from '../components/WorkerSpotlightCard';
import { PrimaryButton } from 'library';
import { useModalsContext } from 'ModalsContext';

export default function WorkersSpotlight() {
    const [workers, setWorkers] = useState<ISpotlightWorker[]>([]);
    const [selectedWorkers, setSelectedWorkers] = useState<ISpotlightWorker[]>([]);
    const { openJobForm } = useModalsContext();

    useEffect(() => {
        client(`api/dashboard/worker-spotlight/`)
            .then((response: IWorkerSpotlight) => {
                setWorkers(response.workers);
            })
            .catch(consoleLogInDev);
    }, []);

    function toggleSelected(worker: ISpotlightWorker) {
        if (selectedWorkers.some((x) => x.id === worker.id)) {
            setSelectedWorkers(selectedWorkers.filter((x) => x.id !== worker.id));
        } else {
            setSelectedWorkers([...selectedWorkers, worker]);
        }
    }

    function request() {
        openJobForm('Teacher Showcase', { selectedSpotlightWorkers: selectedWorkers, allSpotlightWorkers: workers });
    }

    if (!workers?.length) {
        return <></>;
    }

    return (
        <DashboardSection
            header="Tandem Teacher Showcase"
            description="Meet some of our top ready-to-work, credentialed workers in your area available for booking!"
        >
            <Grid container>
                {workers.map((x, index) => (
                    <WorkerSpotlightCard
                        key={index}
                        worker={x}
                        selected={selectedWorkers.some((worker) => x.id === worker.id)}
                        toggleSelected={toggleSelected}
                    />
                ))}
            </Grid>
            <PrimaryButton onClick={request} rightAlign>
                Request Selected
            </PrimaryButton>
        </DashboardSection>
    );
}
