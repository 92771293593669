import { Colors, Text } from 'library';
import React from 'react';

export default function SubAvailabilityLegend() {
    function LegendEntry({ color, text }: { color: Colors; text: string }) {
        return (
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <div style={{ width: 20, height: 12, backgroundColor: color, marginRight: 8 }} />
                <Text variant="caption">{text}</Text>
            </div>
        );
    }
    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <LegendEntry color={Colors.lightBlue} text="Saved" />
            <LegendEntry color={Colors.lightGreen} text="Unsaved changes" />
            <LegendEntry color={Colors.lightGrey} text="Not available, Click + to add this day" />
        </div>
    );
}
