import React from 'react';
import moment from 'moment';
import { Text, Colors } from 'library';
import { AdminProvider } from 'models';

export function WorkerBasicInfo({ provider }: { provider: AdminProvider }) {
    return (
        <>
            <Text variant="h2" bold textStyle={{ margin: 10, color: Colors.turquoise }}>
                Basic Info
            </Text>
            {provider.compliance_hold && (
                <Text variant="body1" bold textStyle={{ margin: 10, color: Colors.error }}>
                    ON COMPLIANCE HOLD
                </Text>
            )}
            <Text variant="body1" bold textStyle={{ margin: 10 }}>
                {provider.user.address?.street} {provider.user.address?.city}, {provider.user.address?.state}{' '}
                {provider.user.address?.zip_code}
            </Text>
            <Text variant="body2" textStyle={{ margin: 10 }}>
                {provider.bio}
            </Text>
            <Text variant="body1" textStyle={{ margin: 10 }}>
                <b>Age:</b> {moment().diff(provider.birthday, 'years')}
            </Text>
            <Text variant="body1" textStyle={{ margin: 10 }}>
                <b>Working State:</b> {provider.user.compliance_state}
            </Text>
            <div style={{ margin: 10 }}>
                {provider.extra_attributes.map((attr) => (
                    <Text key={attr.value} variant="body2">
                        <b>{attr.input}</b>: {attr.value}
                    </Text>
                ))}
            </div>
        </>
    );
}
