import React, { useEffect, useState } from 'react';
import { Grid, Typography, Radio, RadioGroup } from '@material-ui/core';
import Label from 'reusableComponents/checkboxLabel';
import PaySlider from '../../PaySlider';
import theme from 'theme';
import 'react-infinite-calendar/styles.css';
import FixedPayInput from '../../FixedPayInput';
import { SimpleDialog } from 'reusableComponents/dialogsSimple';
import { FormFieldProps } from '../../FormTypes';
import { IPayFormFields } from '../../../JobRequestTypes';
import IconRow from './IconRow';
import { TooltipIconButton, Text } from 'library';
import Divider from '../../Divider';
import SetupPaymentLink from './SetupPaymentLink';

interface IOnetimeRequestPayProps {
    pay: FormFieldProps<IPayFormFields>;
    isQuickFill: boolean | null;
    isAllDay?: boolean | null;
}

const OnetimeRequestPay: React.FC<IOnetimeRequestPayProps> = ({ pay, isQuickFill, isAllDay = false }) => {
    const [displayPayTypeHelp, setDisplayPayTypeHelp] = useState(false);
    const [payMin, setPayMin] = useState(pay.value.payMin);
    const [payMax, setPayMax] = useState(pay.value.payMax);
    const [payFixed, setPayFixed] = useState(pay.value.payFixed);
    const [isFixedPay, setIsFixedPay] = useState(pay.value.isFixedPay || isAllDay);

    useEffect(() => {
        const { isFixedPay, payFixed } = pay.value;
        const valid = isFixedPay !== null && ((isFixedPay && payFixed) || !isFixedPay);
        pay.setValid(!!valid);
    }, [pay.value]);

    useEffect(() => {
        pay.setValue({
            payMin,
            payMax,
            payFixed,
            isFixedPay,
        });
    }, [payMin, payMax, payFixed, isFixedPay, isQuickFill]);

    function onPayRateChange(event: any, newValue: number | number[]) {
        if (typeof newValue === 'number') {
            setPayMin(newValue);
            setPayMax(newValue);
        } else {
            setPayMin(newValue[0]);
            setPayMax(newValue[1]);
        }
    }

    function onFixedPayChange(value: number) {
        setPayFixed(value);
    }

    function onPayTypeChange(value: string) {
        setIsFixedPay(value === 'fixed');
    }

    function onHelpClicked(event: { preventDefault: () => void }) {
        setDisplayPayTypeHelp(true);
        event.preventDefault();
    }

    const getPayTypeHelpText = () => {
        return (
            <Grid container>
                <Typography style={{ color: theme.palette.primary.main }}>
                    Fixed pay is the total amount for the job instead of an hourly rate. All day jobs and jobs where
                    times aren't applicable are always paid with a fixed rate.
                </Typography>
            </Grid>
        );
    };

    return (
        <>
            <RadioGroup name="pay_type" value={isFixedPay} onChange={(e) => onPayTypeChange(e.target.value)}>
                {!isAllDay && (
                    <Label
                        value={'hourly'}
                        control={
                            <Radio
                                checked={isFixedPay !== null && !isFixedPay}
                                color="primary"
                                disableRipple
                                disableFocusRipple
                            />
                        }
                        label={<Typography variant="subtitle1">Hourly</Typography>}
                    />
                )}
                <Label
                    value={'fixed'}
                    control={
                        <Radio
                            checked={isFixedPay !== null && isFixedPay}
                            color="primary"
                            disableRipple
                            disableFocusRipple
                        />
                    }
                    label={
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <Typography variant="subtitle1">Fixed</Typography>
                            <TooltipIconButton onClick={onHelpClicked} />
                        </div>
                    }
                />
            </RadioGroup>
            <SimpleDialog
                isOpen={displayPayTypeHelp}
                dialogTitle={'Pay Type Tip'}
                children={getPayTypeHelpText()}
                onClose={() => setDisplayPayTypeHelp(false)}
            />
            <div style={{ marginTop: 15 }} />
            <IconRow text="Pay on Tandem is always adjustable." />
            <Divider />
            <div style={{ marginTop: 30 }} />
            {isFixedPay ? (
                <FixedPayInput pay={payFixed} onChange={(e: any, value: number) => onFixedPayChange(value)} />
            ) : (
                <PaySlider
                    value={isQuickFill ? payMin : [payMin, payMax]}
                    onPayRateChange={onPayRateChange}
                    payRateTip={
                        '$17 per hour is the average pay rate. Make your job competitive with our suggested pay range.'
                    }
                    isRange={!isQuickFill}
                />
            )}
            <div style={{ marginTop: 30 }} />
            <IconRow text="Tandem takes no cut. Your provider earns 100%." />
            <div style={{ marginTop: 15 }} />
            <SetupPaymentLink />
        </>
    );
};
export default OnetimeRequestPay;
