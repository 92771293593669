import React from 'react';
import { PrimaryButton, Colors } from 'library';
import { OnboardingStep, useOnboardingContext } from '../OnboardingContext';
import { KeyboardDoubleArrowLeft } from '@mui/icons-material';

export default function GoBack({ step }: { step: OnboardingStep }) {
    const buttonText = 'Go Back';
    const { goToStep } = useOnboardingContext();

    const goBack = () => {
        goToStep(step, buttonText);
    };
    return (
        <PrimaryButton onClick={goBack} buttonStyle={{ width: 160, marginTop: 20 }}>
            <KeyboardDoubleArrowLeft htmlColor={Colors.white} /> {buttonText}
        </PrimaryButton>
    );
}
