import { useState } from 'react';

export interface IEditAddress {
    street: string;
    city: string;
    state: string;
    zip_code: string;
    id: number;
}

export default function useAddress(address: IEditAddress = { id: -1, street: '', city: '', state: '', zip_code: '' }) {
    const [mainAddress, setMainAddress] = useState<IEditAddress>({ ...address });

    function resetAddress(newAddress?: IEditAddress) {
        setMainAddress(newAddress ? { ...newAddress } : { ...address });
    }

    function updateMainAddress(key: keyof IEditAddress, value: string) {
        if (mainAddress) setMainAddress({ ...mainAddress, [key]: value });
    }

    const addressInfo = [
        {
            title: 'Street',
            onChange: (v: string) => updateMainAddress('street', v),
            value: mainAddress.street,
            error: !mainAddress.street,
        },
        {
            title: 'City',
            onChange: (v: string) => updateMainAddress('city', v),
            value: mainAddress.city,
            error: !mainAddress.city,
        },
        {
            title: 'State',
            onChange: (v: string) => updateMainAddress('state', v),
            value: mainAddress.state,
            error: !mainAddress.state,
        },
        {
            title: 'Zip Code',
            onChange: (v: string) => updateMainAddress('zip_code', v),
            value: mainAddress.zip_code,
            error: !mainAddress.zip_code,
        },
    ];

    function addressUpdated() {
        return (
            (mainAddress?.street !== address.street && mainAddress.street.length > 0) ||
            (mainAddress?.city !== address.city && mainAddress.city.length > 0) ||
            (mainAddress?.state !== address.state && mainAddress.state.length > 0) ||
            (mainAddress?.zip_code !== address.zip_code && mainAddress.zip_code.length > 0)
        );
    }

    return {
        addressInfo,
        setMainAddress,
        mainAddress,
        resetAddress,
        updateMainAddress,
        addressUpdated,
        submissionData: {
            street: mainAddress?.street === address.street ? undefined : mainAddress?.street,
            city: mainAddress?.city === address.city ? undefined : mainAddress?.city,
            state: mainAddress?.street === address.state ? undefined : mainAddress?.state,
            zip_code: mainAddress?.zip_code === address.zip_code ? undefined : mainAddress?.zip_code,
        },
    };
}
