import { Grid, styled } from '@mui/material';
import React, { useEffect } from 'react';
import { Colors, Text, Checkbox } from 'library';
import { getOhioRecommendations } from '../recommendations/OhioRecommendations';
import { getFloridaRecommendations } from '../recommendations/FloridaRecommendations';
import { getIllinoisRecommendations } from '../recommendations/IllinoisRecommendations';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { useUserContext } from 'UserContext';
import { useJobFormContext } from 'parent-portal/forms/JobFormContext';

export default function PayRecommendation() {
    const { user } = useUserContext();
    const { recommendations, setRecommendations, ignoreRecommendations, setIgnoreRecommendations, form } =
        useJobFormContext();
    const { payScales, pay } = form.fieldStates;
    useEffect(() => {
        setRecommendations(getPayScaleRecomendations());
    }, [pay.value.payMin, payScales.value]);

    function getPayScaleRecomendations() {
        const address = user?.address[0];
        const state = address?.state;

        if (!state) {
            return [];
        }

        const minRates = payScales.value.filter((x) => x.rate == pay.value.payMin);
        if (state.toLowerCase() === 'ohio') {
            return getOhioRecommendations(minRates);
        } else if (state.toLowerCase() === 'florida') {
            return getFloridaRecommendations(minRates);
        } else if (state.toLowerCase() === 'illinois') {
            return getIllinoisRecommendations(minRates);
        }

        return [];
    }

    if (!recommendations.length) {
        return null;
    }

    return (
        <RecommendationsContainer>
            <RecommendationsTitleContainer container direction="row">
                <Text variant="body1">
                    <FontAwesomeIcon
                        icon={faTriangleExclamation}
                        style={{
                            width: 20,
                            height: 20,
                            borderRadius: 30,
                            marginRight: 5,
                        }}
                        color={Colors.error}
                    />
                    There is a low likelihood that Tandem can fill this job. This is due to the{' '}
                    <b>base pay and qualifications</b>.
                </Text>
            </RecommendationsTitleContainer>
            <ul>
                {recommendations.map((x) => (
                    <li key={x}>
                        <Text variant="body2">{x}</Text>
                    </li>
                ))}
            </ul>
            <Checkbox
                label={'Ignore recommendations and submit'}
                checked={ignoreRecommendations}
                onChange={setIgnoreRecommendations}
            />
        </RecommendationsContainer>
    );
}

const RecommendationsContainer = styled(Grid)({
    marginTop: 10,
});

const RecommendationsTitleContainer = styled(Grid)({
    marginBottom: 5,
    padding: 10,
});
