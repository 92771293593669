import React from 'react';
import NoteView from 'internal/shared/tables/NoteView';
import { Text, Colors } from 'library';
import { AdminProvider } from 'models';

export function WorkerNotes({ provider, refreshProvider }: { provider: AdminProvider; refreshProvider: () => void }) {
    return (
        <>
            {provider.pinned_notes.length > 0 && (
                <Text variant="h2" bold textStyle={{ margin: 10, color: Colors.turquoise }}>
                    Pinned Notes
                </Text>
            )}
            {provider.pinned_notes.map((note) => (
                <NoteView key={note.id} note={note} refresh={refreshProvider} />
            ))}
        </>
    );
}
