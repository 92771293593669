import React from 'react';
import { Grid } from '@material-ui/core';
import { Colors } from 'library';
import { DayOfWeek } from 'parent-portal/forms/JobRequestTypes';

interface DayButtonProps {
    dayName: string;
    onClick?: () => void;
    day?: DayOfWeek;
    style?: React.CSSProperties;
}

const dayAcronyms = [
    { day: 'Monday', acronym: 'Mon' },
    { day: 'Tuesday', acronym: 'Tues' },
    { day: 'Wednesday', acronym: 'Wed' },
    { day: 'Thursday', acronym: 'Thu' },
    { day: 'Friday', acronym: 'Fri' },
    { day: 'Saturday', acronym: 'Sat' },
    { day: 'Sunday', acronym: 'Sun' },
];

export default function DayButton({ dayName, onClick = undefined, day = undefined, style }: DayButtonProps) {
    function getDayAcronym(day: string) {
        return dayAcronyms.find((filterDay) => filterDay.day === day)?.acronym;
    }

    return (
        <Grid
            item
            container
            style={{
                ...style,
                borderWidth: 2,
                borderRadius: '50%',
                borderStyle: 'solid',
                borderColor: Colors.darkNavy,
                width: 50,
                height: 50,
                justifyContent: 'center',
                alignItems: 'center',
                fontWeight: 'bold',
                backgroundColor: day?.selected ? Colors.aqua : 'transparent',
                cursor: 'pointer',
            }}
            onClick={onClick}
            id={`day-button-${dayName}`}
        >
            {getDayAcronym(dayName)}
        </Grid>
    );
}
