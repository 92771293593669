import React from 'react';
import { Grid } from '@material-ui/core';
import { isMobile } from 'react-device-detect';
import 'react-infinite-calendar/styles.css';
import AttachMoneyIcon from '@material-ui/icons/Payment';
import PaySlider from '../../../forms/components/PaySlider';
import FixedPayInput from '../../../forms/components/FixedPayInput';

interface IProps {
    requestType: string;
    hasHiredProvider: boolean;
    isQuickFill?: boolean;
    payMin?: number;
    payMax?: number;
    payFixed?: number;
    isPayFixed: boolean;
    onPaySingleRateChange: (event: IEventNumber, newValue: number[]) => void;
    onPayRangeRateChange?: (event: IEventNumber, newValue: number[]) => void;
    onCurrencyInputChange?: (value: number) => void;
}

interface IEventNumber {
    target: { value: number };
}

const EditPay = ({
    requestType,
    isQuickFill,
    hasHiredProvider,
    payMin,
    payMax,
    payFixed,
    isPayFixed,
    onPaySingleRateChange,
    onPayRangeRateChange,
    onCurrencyInputChange,
}: IProps) => {
    const getHelperText = () => {
        return '$17/hour is the average pay rate. Make your job competitive with our suggested pay range';
    };

    const getPaySlider = () => {
        if (hasHiredProvider || isQuickFill) {
            return (
                <PaySlider
                    value={[payMin]}
                    onPayRateChange={onPaySingleRateChange}
                    payRateTip={getHelperText()}
                    isRange={false}
                />
            );
        }
        return (
            <PaySlider
                value={[payMin, payMax]}
                onPayRateChange={onPayRangeRateChange}
                payRateTip={getHelperText()}
                isRange={true}
            />
        );
    };

    const getFixedPayInput = () => {
        return (
            isPayFixed &&
            onCurrencyInputChange && (
                <FixedPayInput pay={payFixed} onChange={(e: any, value: number) => onCurrencyInputChange(value)} />
            )
        );
    };

    const getPayComponent = () => {
        if (isPayFixed) {
            return getFixedPayInput();
        }
        return getPaySlider();
    };

    return (
        <Grid container direction="row" justify="space-between" style={{ marginTop: isMobile ? 15 : 8 }}>
            <Grid container xs={1} justify="center" alignItems="center">
                <Grid item style={{ paddingTop: 15 }}>
                    <AttachMoneyIcon color="primary" />
                </Grid>
            </Grid>
            <Grid container xs={11} alignItems="center">
                <Grid item xs={12}>
                    {getPayComponent()}
                </Grid>
            </Grid>
        </Grid>
    );
};

export default EditPay;
