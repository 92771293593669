import React, { useEffect, useState } from 'react';
import { JobDetail, JobDetailValueWithOption } from 'models';
import { Slider } from 'library';
import MultiSelect from '../BusinessJobRequirementsPage/MultiSelect';
import SingleSelect from '../BusinessJobRequirementsPage/SingleSelect';
import { Grid } from '@mui/material';
import { addOrRemoveDetail, addOrUpdateDetail } from 'parent-portal/forms/jobDetailMethods';
import { FormFieldProps } from '../../FormTypes';

export default function JobDetailInput({
    detail,
    jobDetails,
}: {
    detail: JobDetail;
    jobDetails: FormFieldProps<JobDetailValueWithOption[]>;
}) {
    const [selectedOptions, setSelectedOptions] = useState<JobDetailValueWithOption[]>([]);
    const [selectedValue, setSelectedValue] = useState<string>();
    const { value: values } = jobDetails;

    function toNumber(detail: JobDetail) {
        const val = getValue(detail);
        return val ? parseInt(val) : undefined;
    }

    function setNumber(value?: number | number[]) {
        addOrUpdateDetail({ detail: detail.id, value: value?.toString() }, jobDetails);
    }

    function getValue(detail: JobDetail) {
        return values.find((val) => val.detail === detail.id)?.value;
    }

    function getOption(detail: JobDetail) {
        const selected = values.find((val) => detail.id === val.detail);
        const option = detail.options.find((option) => option.id === selected?.option_id)?.value;
        return option;
    }

    function setOption(value?: string) {
        addOrUpdateDetail(
            {
                detail: detail.id,
                option_id: detail.options.find((option) => option.value === value)?.id,
            },
            jobDetails,
        );
    }

    useEffect(() => {
        if (detail.value_type === 'MULTI_SELECT') {
            const selected = values.filter((val) => val.detail === detail.id);
            setSelectedOptions(selected);
        } else if (detail.value_type === 'SINGLE_SELECT') {
            setSelectedValue(getOption(detail));
        }
    }, [values, detail]);

    return detail.value_type === 'NUMBER' ? (
        <Grid container item style={{ width: '100%', padding: 20 }}>
            <Slider
                marks={[
                    { value: 0, label: 'None Required' },
                    { value: 10, label: '10+' },
                ]}
                min={0}
                max={10}
                value={toNumber(detail)}
                onChange={(event, value) => setNumber(value)}
            />
        </Grid>
    ) : detail.value_type === 'MULTI_SELECT' ? (
        <MultiSelect
            options={detail.options}
            selectedOptions={selectedOptions}
            onSelect={(optionId) => addOrRemoveDetail(jobDetails, detail, optionId)}
        />
    ) : detail.value_type === 'SINGLE_SELECT' ? (
        <SingleSelect value={selectedValue} onChange={setOption} options={detail.options} />
    ) : null;
}
