import { ChevronRight } from '@material-ui/icons';
import { Colors } from 'library';
import React, { CSSProperties, MouseEventHandler } from 'react';
import { ReactNode } from 'react';
import './OptionRow.css';

interface OptionRowProps {
    children?: ReactNode | ReactNode[];
    style?: CSSProperties;
    onClick?: MouseEventHandler<HTMLDivElement>;
}

export default function OptionRow({ children, style, onClick }: OptionRowProps) {
    return (
        <div className="option-row" style={style} onClick={onClick}>
            <div style={{ flexGrow: 1 }}>{children}</div>
            <ChevronRight htmlColor={Colors.darkNavy} />
        </div>
    );
}
