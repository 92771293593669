import React, { useRef, useState, useEffect, useCallback } from 'react';
import ToastCalendar from '@toast-ui/react-calendar';
import '@toast-ui/calendar/dist/toastui-calendar.min.css';
import { UpcomingJobModel } from 'parent-portal/jobs/nestedJobList/jobListModels';
import { Text } from 'library';
import { ExpandMore } from '@material-ui/icons';
import { Accordion, AccordionDetails, AccordionSummary, Grid, withStyles } from '@material-ui/core';

export default function Event({ header, title, chip, details }: any) {
    const StyledSummary = withStyles({
        content: {
            justifyContent: 'space-between',
        },
    })(AccordionSummary);
    return (
        <Accordion>
            <StyledSummary expandIcon={<ExpandMore />}>
                <Grid>
                    <Text variant="caption" textStyle={{ width: '100%' }}>
                        {header}
                    </Text>
                    <Text variant="body1" bold textStyle={{ width: '100%', overflowWrap: 'break-word' }}>
                        {title}
                    </Text>
                </Grid>
                {chip}
            </StyledSummary>
            <AccordionDetails>{details}</AccordionDetails>
        </Accordion>
    );
}
