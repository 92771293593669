import React, { Component } from 'react';
import styled from 'styled-components';
import theme from '../../../theme';
import Grid from '@material-ui/core/Grid';
import { Checkbox } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DisplayEditButton from './../partials/DisplayEditButton';
import { MixPanelKey } from 'environmentVariables';

var mixpanel = require('mixpanel-browser');
mixpanel.init(MixPanelKey);

const InfoSection = styled.div`
    border-radius: 6px;
    background-color: #f7f7f7;
    margin: 10px;
    padding: 10px;
    color: ${theme.palette.primary.main};
`;

const Category = styled.div`
    margin: 0px;
`;

const CategoryTitle = styled.p`
    color: ${theme.palette.primary.main};
    font-size: 12;
    font-weight: bold;
    margin-left: 20px;
    margin-top: 15px;
`;

const CategoryInfo = styled.div`
    background-color: #ffffff;
    border-radius: 6px;
    font-size: 12px;
    font-weight: regular;
    margin-top: 15px;
    margin-bottom: 15px;
    margin-left: 18px;
    margin-right: 18px;
    padding: 15;
`;

const CategorySeparator = styled.hr`
    width: 95%;
    size: 4;
    color: #f7f7f7;
    margin-top: 25px;
    margin-bottom: 10px;
`;

export default class PreferenceDisplay extends Component {
    render() {
        const { pageHandler, certificationPreferences, experiences, certificates, experiencePreferences } = this.props;

        return (
            <div>
                <DisplayEditButton
                    onClickHandler={() => {
                        pageHandler(8);
                    }}
                />
                <Grid container justify="center">
                    <Grid item sm={6} container>
                        <InfoSection id="family-info" style={{ marginBottom: 100, minWidth: '90%' }}>
                            <Category>
                                <Grid container item>
                                    <CategoryTitle>I want a sitter with these certifications:</CategoryTitle>
                                    <CategoryInfo style={{ padding: 15, minWidth: '90%' }}>
                                        <Grid item>
                                            <FormControl component="fieldset">
                                                <FormGroup>
                                                    {Object.entries(certificates).map((cert) => (
                                                        <FormControlLabel
                                                            key={cert[0]}
                                                            control={
                                                                <Checkbox
                                                                    disableRipple
                                                                    color="primary"
                                                                    checked={certificationPreferences.includes(cert[0])}
                                                                />
                                                            }
                                                            label={cert[1]}
                                                        />
                                                    ))}
                                                </FormGroup>
                                            </FormControl>
                                        </Grid>
                                    </CategoryInfo>
                                    <CategorySeparator />
                                    <CategoryTitle>I want a sitter with these experiences:</CategoryTitle>
                                    <CategoryInfo style={{ padding: 15, minWidth: '90%' }}>
                                        <Grid item>
                                            <FormControl component="fieldset">
                                                <FormGroup>
                                                    {Object.entries(experiences).map((cert) => (
                                                        <FormControlLabel
                                                            key={cert[0]}
                                                            control={
                                                                <Checkbox
                                                                    disableRipple
                                                                    color="primary"
                                                                    checked={experiencePreferences.includes(cert[0])}
                                                                />
                                                            }
                                                            label={cert[1]}
                                                        />
                                                    ))}
                                                </FormGroup>
                                            </FormControl>
                                        </Grid>
                                    </CategoryInfo>
                                </Grid>
                            </Category>
                        </InfoSection>
                    </Grid>
                </Grid>
            </div>
        );
    }
}
