import React, { useEffect, useState } from 'react';
import JobTitleInput from './JobTitleInput';
import JobDescriptionInput from './JobDescriptionInput';
import FormField from '../../FormField';
import JobDetailInput from './JobDetailInput';
import { client, consoleLogInDev } from 'shared';
import { JobDetail, JobDetailValueWithOption } from 'models';
import { FormFieldProps } from '../../FormTypes';
import { BusinessJobTypes } from 'parent-portal/forms/JobRequestTypes';
import { OutlinedTextInput } from 'library';

export default function BusinessJobDetails({
    headline,
    comments,
    jobDetails,
    trialRunBenefits,
    businessJobType,
}: {
    headline: FormFieldProps<string>;
    comments: FormFieldProps<string>;
    jobDetails: FormFieldProps<JobDetailValueWithOption[]>;
    trialRunBenefits: FormFieldProps<string | undefined>;
    businessJobType: FormFieldProps<BusinessJobTypes | undefined>;
}) {
    const [details, setDetails] = useState<JobDetail[]>([]);
    const getJobDetails = () => {
        client('api/job-detail/').then(setDetails).catch(consoleLogInDev);
    };
    useEffect(getJobDetails, []);

    return (
        <>
            <JobTitleInput title={headline} business />
            {details.map((detail) => (
                <FormField title={detail.name} key={detail.id}>
                    <JobDetailInput detail={detail} jobDetails={jobDetails} />
                </FormField>
            ))}
            <JobDescriptionInput description={comments} business />
            {businessJobType.value === 'TRIAL_RUN' && (
                <FormField title={`What benefits do you offer to permanent staff?`}>
                    <OutlinedTextInput
                        fullWidth
                        placeholder="Ex. PTO, Paid Holidays, Continued Education CDA, etc."
                        value={trialRunBenefits.value}
                        onChange={(e) => trialRunBenefits.setValue(e.target.value)}
                        multiline
                        rows={3}
                        InputLabelProps={{ shrink: true }}
                        id="job-form-description-input"
                    />
                </FormField>
            )}
        </>
    );
}
