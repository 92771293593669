import React, { useEffect, useState } from 'react';
import { Grid, useMediaQuery } from '@mui/material';
import { PrimaryButton, Text, SizeableRoundedDialog, OutlinedTextInput, Checkbox } from 'library';
import theme from 'theme';
import { client } from 'shared';
import { ShiftProviderUser } from 'models';

export default function EditModal({
    open,
    onClose,
    provider,
    ongoingRequestId,
    initialContinueValue,
}: {
    open: boolean;
    onClose: () => void;
    provider: ShiftProviderUser;
    ongoingRequestId: number;
    initialContinueValue: boolean;
}) {
    const small = useMediaQuery(theme.breakpoints.down('sm'));
    const [submitted, setSubmitted] = useState(false);
    const [comment, setComment] = useState<string>();
    const [continueNextWeek, setContinueNextWeek] = useState(initialContinueValue);

    useEffect(() => {
        setContinueNextWeek(initialContinueValue);
    }, [initialContinueValue]);

    function submit() {
        client(`api/business-job/${ongoingRequestId}/trial-run-feedback/`, {
            method: 'POST',
            body: { user_id: provider.id, comment: comment, continue: continueNextWeek },
        }).then(() => {
            setSubmitted(true);
        });
    }

    function complete() {
        setSubmitted(false);
        onClose();
    }

    return (
        <SizeableRoundedDialog
            open={open}
            onClose={complete}
            fullScreen={small}
            closeButton
            maxWidth="md"
            style={{ maxWidth: 500 }}
        >
            {submitted ? (
                <Grid container item direction="column" style={{ padding: 20, gap: 10 }}>
                    <Text variant="h1">Thank you for your feedback!</Text>
                    <Text>
                        {continueNextWeek
                            ? `We're glad to hear it's going well! We'll confirm with ${provider.first_name} and let you know when we have them scheduled for next week.`
                            : `We're sorry to hear it's not working out. We will reopen your job and start looking for new candidates for next week. ${provider.first_name} is still scheduled to work the rest of this week. If you need to remove them, please do so by removing them from the specific shift(s) on the calendar.`}
                    </Text>
                    <PrimaryButton onClick={complete} buttonStyle={{ width: undefined, alignSelf: 'flex-end' }}>
                        Done
                    </PrimaryButton>
                </Grid>
            ) : (
                <Grid container item direction="column" style={{ padding: 20, gap: 10 }}>
                    <Text variant="h1">How is your trial run going?</Text>
                    <Text>
                        If you would like to submit any feedback to Tandem, we'd love to hear how the trial run with{' '}
                        {provider.first_name} is going.
                    </Text>
                    <OutlinedTextInput
                        label="Comments for Tandem"
                        style={{ padding: 10 }}
                        multiline
                        fullWidth
                        onChange={(e: any) => setComment(e.target.value)}
                        value={comment}
                    />
                    <Checkbox label={'Continue next week?'} checked={continueNextWeek} onChange={setContinueNextWeek} />
                    <Text>
                        Select continue to keep {provider.first_name} on your schedule for next week. If you don't
                        select continue, {provider.first_name} will remain on your schedule this week, but we will not
                        reschedule them for next week.
                    </Text>
                    <PrimaryButton
                        disabled={submitted}
                        onClick={submit}
                        buttonStyle={{ width: undefined, alignSelf: 'flex-end' }}
                    >
                        Submit
                    </PrimaryButton>
                </Grid>
            )}
        </SizeableRoundedDialog>
    );
}
