import React from 'react';
import AppStore from 'assets/icons/app_store_black.svg';
import usePublicSessionTracking, { PublicSessionEventType } from '../shared/PublicSessionTracking';
import useAppStoreNavigation from './AppStoreNavigation';

export const appLink = `https://apps.apple.com/app/apple-store/id1491607600?pt=120819140&ct=InviteFriends&mt=8`;

export default function AppStoreButton({
    tracking,
    height,
    width,
    onClick,
}: {
    tracking?: string;
    height?: number | string;
    width?: number | string;
    onClick?: () => void;
}) {
    const { trackSession } = usePublicSessionTracking('App Store Button');

    const goToAppStore = useAppStoreNavigation(trackSession, tracking);

    return (
        <AppStore
            style={{ cursor: 'pointer' }}
            onClick={!!onClick ? onClick : goToAppStore}
            width={width ? width : '119.66407'}
            height={height ? height : '40'}
        />
    );
}
