import { ApiBaseUrl } from 'environmentVariables';

function addTrailingSlash(endpoint: string) {
    if (endpoint.includes('?')) {
        return endpoint;
    } else if (endpoint.charAt(endpoint.length - 1) !== '/') {
        return `${endpoint}${'/'}`;
    }
    return endpoint;
}

function formatAction(action: string) {
    return action.replace(' ', '').toLowerCase();
}

interface StreamRequest {
    endpoint: string;
    token?: string;
    action?: string;
    onYield: (chunk: string) => void;
    onError: (error: Error) => void;
    onComplete: () => void;
}

const streamRequest = ({ endpoint, token, action, onYield, onError, onComplete }: StreamRequest) => {
    const updatedEndpoint = `${ApiBaseUrl}/${addTrailingSlash(endpoint)}`;
    const url = action ? `${updatedEndpoint}?action=${formatAction(action)}` : updatedEndpoint;
    const finalToken = token || localStorage.getItem('token') || '';
    return fetch(url, {
        method: 'GET',
        headers: { 'content-type': 'text/event-stream', Authorization: `Token ${finalToken}` },
    })
        .then((response) => {
            const reader = response?.body?.getReader();
            const decoder = new TextDecoder();

            const readStream = () => {
                reader
                    ?.read()
                    .then(({ done, value }) => {
                        if (done) {
                            onComplete();
                            reader.cancel();
                            return;
                        }
                        const valAsStr = value ? decoder.decode(value, { stream: true }) : '';
                        if (valAsStr.includes('Stream complete')) {
                            onComplete();
                            reader.cancel();
                            return;
                        } else {
                            onYield(valAsStr);
                            readStream();
                        }
                    })
                    .catch((error) => {
                        onError(error);
                        reader.cancel();
                    });
            };
            readStream();
        })
        .catch((error) => {
            onError(error);
        });
};

export default streamRequest;
