import React from 'react';
import { useWidth } from 'reusableComponents/useWidth';
import { Link } from 'react-router-dom';
import WhiteLogo from 'assets/images/WhiteLogo.png';

export default function Header() {
    const breakpoint = useWidth();
    const breakLayout = ['xs', 'sm'].includes(breakpoint);
    const isElderCare = window.location.href.includes('facilities');

    function handleStyle() {
        if (breakLayout) {
            return { maxWidth: 150, maxHeight: 50, margin: 0, padding: 0 };
        }
        return { maxWidth: 150, maxHeight: 50, margin: 0, padding: 0 };
    }

    return (
        <Link to={isElderCare ? '/facilities' : '/'}>
            <img alt="tandem logo" src={WhiteLogo} style={handleStyle()} />
        </Link>
    );
}
