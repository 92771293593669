import React from 'react';
import { Badge } from '@mui/material';
import { Colors } from 'library';
import { parseISO, differenceInHours, isAfter } from 'date-fns';

type Job = {
    id: number;
    last_applied_to?: string;
};

interface WithBadgeProps {
    job: Job;
    children: React.ReactNode;
}

const jobHasRecentApplication = (job: Job) => {
    if (!job.last_applied_to) {
        return false;
    }

    const currentDateTime = new Date();
    const lastAppliedDate = parseISO(job.last_applied_to);

    const viewedJobs = JSON.parse(localStorage.getItem('viewedJobs') || '{}');
    const jobFromStorage = viewedJobs[job.id];

    if (!jobFromStorage) {
        return differenceInHours(currentDateTime, lastAppliedDate) <= 1;
    }

    const jobExpiryDate = parseISO(jobFromStorage.expiresAt);

    if (isAfter(lastAppliedDate, jobExpiryDate)) {
        return differenceInHours(currentDateTime, lastAppliedDate) <= 1;
    }

    return false;
};

const WithBadge = ({ job, children }: WithBadgeProps) => {
    const showBadge = jobHasRecentApplication(job);

    return showBadge ? (
        <Badge
            showZero={false}
            sx={{ '.MuiBadge-badge': { backgroundColor: Colors.mediumRed, color: Colors.white } }}
            badgeContent={'New'}
            overlap="circular"
        >
            {children}
        </Badge>
    ) : (
        <>{children}</>
    );
};

export default WithBadge;
