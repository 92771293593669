import React from 'react';
import moment from 'moment';
import { Text, Colors } from 'library';
import { AdminProvider } from 'models';
import { formatMonthDayYear } from 'shared/Dates';

export default function OutreachSuspendedAlert({ provider }: { provider: AdminProvider }) {
    const user = provider.user;
    const suspensionReasons = provider.suspension_reasons
        .map((x) => `${x.reason}: ${x.details} - ${formatMonthDayYear(x.added_at)}.`)
        .join(' ');

    return user.suspended_at ? (
        <Text variant="body2" color={Colors.error} textStyle={{ marginBottom: 5 }}>
            Suspended on {moment(user.suspended_at).format('ddd, MMM Do YYYY')} (
            {moment().diff(user.suspended_at, 'days')} days ago).
            <br />
            {suspensionReasons}
        </Text>
    ) : null;
}
