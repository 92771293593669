import React from 'react';
import { Grid, SvgIcon } from '@material-ui/core';

import 'react-infinite-calendar/styles.css';
import { useHistory } from 'react-router-dom';
import { Text } from 'library';
import { UpcomingAppointment, IJob } from './EditJobModels';

export default function EditIndividualDays({
    job,
    individualAppointments,
}: {
    job?: IJob;
    individualAppointments: UpcomingAppointment[];
}) {
    const history = useHistory();
    return individualAppointments.length > 0 ? (
        <>
            <Grid>
                <Text variant="h1" textStyle={{ marginTop: 20 }}>
                    Edit individual days of the job
                </Text>
            </Grid>
            <div style={{ marginTop: 20 }}>
                {individualAppointments.map((d: any) => (
                    <Grid container item xs={12} direction="row" justify="space-between" style={{ marginBottom: 10 }}>
                        <Grid container xs={1} justify="center" alignItems="center">
                            <Grid item>
                                <SvgIcon component={d.icon} viewBox="0 0 700 750" />
                            </Grid>
                        </Grid>
                        <Grid container item xs={11}>
                            <Text
                                variant="body1"
                                textStyle={{
                                    cursor: 'pointer',
                                    textDecoration: 'underline',
                                    marginTop: 10,
                                    marginBottom: 10,
                                }}
                                onClick={() => {
                                    history.push('/jobs/upcoming/edit/' + d.id);
                                }}
                            >
                                Edit or Skip {d.days_of_week}, {String(d.date)}
                            </Text>
                        </Grid>
                    </Grid>
                ))}
            </div>
        </>
    ) : null;
}
