import React, { useState, useEffect } from 'react';
import JobFormPage from '../JobFormPage';
import { Grid } from '@material-ui/core';
import { Text } from 'library';
import { useJobFormContext } from 'parent-portal/forms/JobFormContext';
import { useUserContext } from 'UserContext';
import FormField from '../../FormField';

interface PaySetupPageProps {
    setShowTandemPaySetup: (show: boolean) => void;
    name: string;
}

export default function PaySetupPage({ setShowTandemPaySetup, name }: PaySetupPageProps) {
    const { goToNext } = useJobFormContext();
    const { user } = useUserContext();
    const [valid, setValid] = useState(false);

    useEffect(() => {
        const paymentSetup = !!user?.payment_information && user?.payment_information?.length > 0;
        setValid(paymentSetup);
    }, [user?.payment_information]);
    return (
        <JobFormPage
            title={'Time to connect payment.'}
            onNextClicked={() => goToNext(name)}
            nextText={'Next'}
            nextDisabled={!valid}
            id={name}
        >
            <FormField title="To continue, you'll need to set up payment." style={{ marginTop: 30 }}>
                <Text variant="body1" textStyle={{ marginTop: 10 }}>
                    You’ll be charged on a weekly basis for the hours that a provider works at your center including
                    fees. You will have an opportunity to review hour reports on a daily basis and view all charges on
                    the payment page at any time.
                </Text>
                <Grid onClick={() => setShowTandemPaySetup(true)} style={{ marginTop: 20 }}>
                    <Text variant="body2" bold textStyle={{ textDecoration: 'underline', cursor: 'pointer' }}>
                        Setup TandemPay {'>'}
                    </Text>
                </Grid>
            </FormField>
        </JobFormPage>
    );
}
