import { useState } from 'react';
import { formatE164USPhoneNumber, isValidEmail, isValidPhone } from 'shared';
import { IContactInfo } from '../models';

const defaultNewContact = {
    firstName: '',
    lastName: '',
    phoneNumber: '',
    email: '',
    contactPreference: 'EMAIL',
};

export default function useContact(currentContact: IContactInfo = defaultNewContact, optional?: boolean) {
    const [mainContactInfo, setMainContactInfo] = useState<IContactInfo>(currentContact);

    function populateCurrentContactInfo(contact?: IContactInfo) {
        setMainContactInfo(contact ? { ...contact } : currentContact);
    }

    function updateMainContactInfo(key: keyof IContactInfo, value: string) {
        if (mainContactInfo) setMainContactInfo({ ...mainContactInfo, [key]: value });
    }

    function isNewContactValid() {
        return (
            (!Object.keys(mainContactInfo)
                .filter((k) => k !== 'contactPreference')
                .find((v) => !!mainContactInfo[v as keyof IContactInfo]) &&
                optional) ||
            (mainContactInfo.firstName &&
                mainContactInfo.lastName &&
                isValidEmail(mainContactInfo.email) &&
                (!mainContactInfo.phoneNumber || isValidPhone(mainContactInfo.phoneNumber)))
        );
    }

    return {
        isNewContactValid,
        mainContactInfo,
        updateMainContactInfo,
        populateCurrentContactInfo,
        submissionData: {
            first_name: !mainContactInfo?.firstName ? undefined : mainContactInfo?.firstName,
            last_name: !mainContactInfo?.lastName ? undefined : mainContactInfo?.lastName,
            email: mainContactInfo?.email === currentContact.email ? undefined : mainContactInfo?.email,
            phone_number:
                mainContactInfo?.phoneNumber === currentContact.phoneNumber
                    ? undefined
                    : formatE164USPhoneNumber(mainContactInfo?.phoneNumber),
            business_name:
                mainContactInfo?.businessName === currentContact.businessName
                    ? undefined
                    : mainContactInfo?.businessName,
            communication_preference:
                mainContactInfo?.contactPreference === currentContact.contactPreference
                    ? undefined
                    : mainContactInfo?.contactPreference,
        },
    };
}
