import React, { CSSProperties } from 'react';
import { DialogContent, DialogProps } from '@mui/material';
import DialogCloseButton from './DialogCloseButton';
import RoundedDialog from './RoundedDialog';

interface IProps extends DialogProps {
    open: boolean;
    children?: React.ReactNode | React.ReactNode[];
    onClose: () => void;
    closeButton?: boolean;
    style?: CSSProperties;
    contentStyle?: CSSProperties;
}
export default function SizeableRoundedDialog({
    open,
    children,
    onClose,
    closeButton,
    style,
    contentStyle = styles.card,
    ...dialogProps
}: IProps) {
    return (
        <RoundedDialog open={open} style={{ maxWidth: 'unset', ...style }} {...dialogProps}>
            {closeButton ? (
                <DialogCloseButton style={{ marginBottom: 10 }} onClose={onClose} id="dialog-close-button" />
            ) : null}
            <DialogContent style={contentStyle}>{children}</DialogContent>
        </RoundedDialog>
    );
}

const styles = {
    card: {
        paddingLeft: 0,
        paddingRight: 0,
        paddingTop: '40px',
        paddingBottom: '40px',
    },
};
