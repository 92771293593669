import React from 'react';
import { List } from '@material-ui/core';
import { useUserContext } from '../../../UserContext';
import PairingListItem from './PairingListItem';
import ProviderRequestListItem from './ProviderRequestListItem';
import { PairingModel, RequestModel, PairingListProps } from '../Models/PairingList';

const PairingList = ({
    pairings,
    requests,
    onPayOpen,
    onSubmitPayment,
    onCancel,
    discount,
    loading,
    errorMessage,
    errorCount,
    onSurchargeDialogClose,
    onSurchargeDialogOpen,
    isSurchargeDialogOpen,
    onPayValueChange,
    onEdit,
    onToggleTooltip,
    onPaySetupClose,
}: PairingListProps) => {
    const { user } = useUserContext();

    const defaultPayIsCreditCard = () => {
        const defaultCreditCard = (element: any) => element.payment_type === 'CREDIT_CARD' && element.is_default;
        return user?.payment_information?.some(defaultCreditCard) || false;
    };

    const isCreditCard = defaultPayIsCreditCard();

    return (
        <List>
            {requests.map((x: RequestModel, i: number) => {
                if (x.itemType === 'REIMBURSEMENT' || x.itemType === 'JOB') {
                    return (
                        <ProviderRequestListItem
                            key={x.id}
                            request={x}
                            onPayOpen={onPayOpen}
                            onSubmitPayment={onSubmitPayment}
                            onCancel={onCancel}
                            discount={discount}
                            loading={loading}
                            errorMessage={errorMessage}
                            errorCount={errorCount}
                            defaultPayIsCreditCard={isCreditCard}
                            onSurchargeDialogClose={onSurchargeDialogClose}
                            onSurchargeDialogOpen={onSurchargeDialogOpen}
                            isSurchargeDialogOpen={isSurchargeDialogOpen}
                            onPaySetupClose={onPaySetupClose}
                        />
                    );
                }
            })}
            {pairings.map((x: PairingModel, i: number) => {
                if (x.itemType === 'DAILY' || x.itemType === 'GROUP') {
                    return (
                        <PairingListItem
                            key={x.id}
                            pairing={x}
                            onPayOpen={onPayOpen}
                            onPayValueChange={onPayValueChange}
                            onSubmitPayment={onSubmitPayment}
                            onEdit={onEdit}
                            onCancel={onCancel}
                            onToggleTooltip={onToggleTooltip}
                            discount={discount}
                            loading={loading}
                            errorMessage={errorMessage}
                            errorCount={errorCount}
                            defaultPayIsCreditCard={isCreditCard}
                            onSurchargeDialogClose={onSurchargeDialogClose}
                            onSurchargeDialogOpen={onSurchargeDialogOpen}
                            isSurchargeDialogOpen={isSurchargeDialogOpen}
                            onPaySetupClose={onPaySetupClose}
                        />
                    );
                }
            })}
        </List>
    );
};

export default PairingList;
