import React from 'react';
import { SecondaryButton } from 'library';
import PaymentChoices from 'parent-portal/payments/PaymentChoices';
import { CardItemType } from 'parent-portal/payments/PaymentOptions';
import { Collapse } from '@mui/material';
import TemplateRadioGroup from './TemplateRadioGroup';

export default function HorizontalOptionButton({
    title,
    highlight,
    subtitle,
    icon,
    action,
    id,
    style,
    listItems,
    cardItems,
    selected,
}: {
    title: string;
    highlight?: string;
    subtitle?: string;
    icon?: React.ReactNode;
    listItems?: string[];
    cardItems?: CardItemType[];
    action: () => void;
    id?: string;
    style?: React.CSSProperties;
    selected?: boolean;
}) {
    return (
        <>
            <SecondaryButton disableRipple onClick={action} buttonStyle={{ ...styles.button, ...style }}>
                <PaymentChoices
                    title={title}
                    subtitle={subtitle}
                    highlight={highlight}
                    listItems={listItems}
                    id={id}
                    icon={icon}
                    cardItems={cardItems}
                />
            </SecondaryButton>
            <Collapse in={selected}>
                <TemplateRadioGroup />
            </Collapse>
        </>
    );
}

const styles = {
    button: {
        borderRadius: 18,
        padding: 10,
        justifyContent: 'flex-start',
        minHeight: 160,
    },
};
