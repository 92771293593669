import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import { Paper } from '@material-ui/core';
import theme from '../theme';

const StyledPaper = withStyles({
    root: (props) => ({
        background: props.altBackground ? theme.palette.alternate.light : 'white',
        padding: '3%',
        paddingRight: '5%',
        borderRadius: 10,
        height: props.height ? props.height : '100%',
        width: '100%',
        borderColor: props.altBackground
            ? theme.palette.alternate.light
            : props.borderColor
            ? theme.palette.primary.main
            : 'white',
        borderWidth: 1,
        borderStyle: 'solid',
    }),
})(Paper);

export default function WhitePaper(props) {
    return (
        <StyledPaper
            elevation={0}
            borderColor={props.borderColor}
            altBackground={props.altBackground}
            height={props.height}
        >
            {props.children}
        </StyledPaper>
    );
}
