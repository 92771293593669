import React from 'react';
import { Grid, useMediaQuery } from '@mui/material';
import { Text, Colors, PrimaryButton } from 'library';
import useSignUpAction from 'parent-portal/login/SignUpAction';
import { useHistory } from 'react-router-dom';
import Class from 'assets/images/on-demand/empty-classroom.png';
import MobileClass from 'assets/images/on-demand/empty-classroom-mobile.png';
import CentersBullets from './CentersBullets';
import theme from 'theme';

const title = 'Child Care Centers';
const subtitle = 'Daycares, preschools, camps, and before/after school programs';

export default function CentersSection() {
    const mdDown = useMediaQuery(theme.breakpoints.down('md'));
    const history = useHistory();
    const { SignUpWrapper, signUp } = useSignUpAction(undefined, () => history.push('/get-started'));
    const styles = {
        container: {
            padding: mdDown ? 20 : 80,
            backgroundColor: mdDown ? Colors.lightTurq : Colors.white,
            marginTop: 40,
        },
        button: {
            marginTop: 20,
            width: undefined,
        },
    };

    function GetStartedButton() {
        return (
            <PrimaryButton
                buttonStyle={styles.button}
                onClick={() => signUp(`On Demand: Centers Section`, undefined, `Create your business account.`)}
            >
                Get Started
            </PrimaryButton>
        );
    }

    return (
        <SignUpWrapper businessSignup={true}>
            <Grid container direction="row" style={styles.container}>
                {mdDown ? (
                    <Grid container style={{ paddingTop: 30 }}>
                        <Grid item xs={4}>
                            <img src={MobileClass} alt="classroom" width={'85%'} style={{ maxWidth: 240 }} />
                        </Grid>
                        <Grid item xs={8} style={{ padding: 10 }}>
                            <Text variant="display" textStyle={{ fontSize: 28 }}>
                                {title}
                            </Text>
                            <Text variant="body2" textStyle={{ marginTop: 10 }}>
                                {subtitle}
                            </Text>
                        </Grid>
                    </Grid>
                ) : null}
                <div
                    style={{
                        width: mdDown ? '100%' : '50%',
                        padding: mdDown ? 20 : 40,
                        paddingRight: mdDown ? 20 : 100,
                    }}
                >
                    {mdDown ? null : <Text variant="display">Credentialed workers ready when you need them.</Text>}
                    <Text
                        variant={mdDown ? 'body1' : 'h2'}
                        textStyle={{ marginTop: mdDown ? 10 : 30, marginBottom: 20 }}
                    >
                        Book workers ahead of time or when you’re in a bind. Use short-term shifts to form long-lasting
                        relationships with candidates.
                    </Text>
                    <CentersBullets smDown={mdDown} />
                    {mdDown ? <GetStartedButton /> : null}
                </div>
                {mdDown ? null : (
                    <div style={{ width: '50%', position: 'relative', maxWidth: 500, marginTop: -50 }}>
                        <img
                            src={Class}
                            alt="classroom"
                            width="100%"
                            height="auto"
                            style={{ position: 'absolute', objectFit: 'cover' }}
                        />
                        <div
                            style={{
                                backgroundColor: 'transparent',
                                zIndex: 999,
                                position: 'absolute',
                                left: 100,
                                bottom: 100,
                                maxWidth: 360,
                            }}
                        >
                            <Text variant="display">{title}</Text>
                            <Text variant="body1" textStyle={{ marginTop: 10 }}>
                                {subtitle}
                            </Text>
                            <GetStartedButton />
                        </div>
                    </div>
                )}
            </Grid>
        </SignUpWrapper>
    );
}
