import { Typography, withStyles } from '@material-ui/core';
import theme from '../../../theme';

const ErrorText = withStyles({
    root: {
        color: theme.palette.error.main,
    },
})(Typography);

export default ErrorText;
