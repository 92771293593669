import React from 'react';
import { styled } from '@mui/material';
import { Colors, Text } from 'library';

import BusinessPayInputRow from './BusinessPayInputRow';
import { IManagedScale } from './BusinessPayInputs';

export default function BusinessPayInputSection({
    dollarAmount,
    isMinimumRate,
    managedScalesByRate,
    hasSeenTrainingsModal,
    updateHasSeenTrainingsModal,
}: {
    dollarAmount: number | string;
    isMinimumRate: boolean;
    managedScalesByRate: IManagedScale[];
    hasSeenTrainingsModal: boolean;
    updateHasSeenTrainingsModal: () => void;
}) {
    const prefix = isMinimumRate ? 'Base Qualification - ' : '';

    return (
        <Container isMinimumRate={isMinimumRate}>
            <Text variant="h1" textStyle={{ marginBottom: 10, marginTop: 10 }}>
                {prefix}${dollarAmount}
            </Text>
            {isMinimumRate && (
                <Text variant="body2" textStyle={{ margin: 10, color: Colors.turquoise }}>
                    Workers who do not meet 100% of these requirements will not be considered for this job.
                </Text>
            )}
            {managedScalesByRate.map((managedScale) => (
                <BusinessPayInputRow
                    key={managedScale.id}
                    singularRow={managedScalesByRate.length === 1}
                    managedScale={managedScale}
                    hasSeenTrainingsModal={hasSeenTrainingsModal}
                    updateHasSeenTrainingsModal={updateHasSeenTrainingsModal}
                    isMinimumRate={isMinimumRate}
                />
            ))}
        </Container>
    );
}

const Container = styled('div')(({ isMinimumRate }: { isMinimumRate: boolean }) => ({
    backgroundColor: isMinimumRate ? Colors.lightTurq : 'transparent',
    paddingBottom: isMinimumRate ? 4 : 0,
    paddingLeft: isMinimumRate ? 8 : 0,
    paddingRight: isMinimumRate ? 8 : 0,
    paddingTop: isMinimumRate ? 4 : 0,
    borderRadius: 18,
}));
