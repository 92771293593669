import React from 'react';
import NewAddressForm from './NewAddressForm';
import { Checkbox } from 'library';
import { useUserContext } from 'UserContext';
import { formatAddress } from 'shared';
import { useJobFormContext } from 'parent-portal/forms/JobFormContext';
import { setBreakMinutes } from 'parent-portal/forms/businessPreferenceMethods';

export default function BusinessAddressSelection({
    isAddingAddress,
    setIsAddingAddress,
}: {
    isAddingAddress: boolean;
    setIsAddingAddress: (value: boolean) => void;
}) {
    const { user } = useUserContext();
    const user_addresses = user?.address;
    const { form, businessPreferences } = useJobFormContext();
    const { address, breakRequired, breakLength } = form.fieldStates;

    function onAddressChange(addressId: number, businessLocationId?: number) {
        const addressValue = address.value || [];

        if (addressValue.some((x) => x.id === addressId)) {
            address.setValue(addressValue.filter((x) => x.id !== addressId));
        } else {
            address.setValue([...addressValue, { id: addressId, businessLocationId }]);
            setBreakMinutes(businessPreferences, breakRequired, breakLength, addressId);
        }
    }

    return (
        <>
            {!isAddingAddress
                ? user_addresses?.map((x) => (
                      <Checkbox
                          key={x.id}
                          label={formatAddress(x)}
                          checked={address.value.some((val) => val.id === x.id)}
                          onChange={() => onAddressChange(x.id, x.business_location?.id)}
                      />
                  ))
                : null}

            <NewAddressForm isAddingAddress={isAddingAddress} setIsAddingAddress={setIsAddingAddress} />
        </>
    );
}
