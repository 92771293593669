import React, { useRef, useState } from 'react';
import { Grid, Typography, Popper, IconButton } from '@material-ui/core';
import CurrencyInput from '../../../reusableComponents/currencyInput';
import { Cancel as CancelIcon, HelpOutline } from '@material-ui/icons';
import ProfileImage from '../../jobs/upcoming/ProfileImage';
import theme from '../../../theme';
import { calculatePay } from '../../../shared/Utils';
import { formatMonthYearWith12HourTime, format12HourTime } from '../../../shared/Dates';
import PayButton from './PayButton';
import AnchorButton from './unpaid/AnchorButton';
import BorderPopover from './unpaid/BorderPopover';
import DiscountLabel from './unpaid/DiscountLabel';
import ErrorMessage from './unpaid/ErrorMessage';
import LinkButton from './unpaid/LinkButton';
import StyledListItem from './unpaid/StyledListItem';
import SurchargeItem from './unpaid/SurchargeItem';
import TotalLabel from './unpaid/TotalLabel';
import UnpaidStyles from './unpaid/UnpaidStyles';
import { PairingListItemProps, PairingModel } from '../Models/PairingList';
import { Colors, Text } from 'library';
import { PayCardPaper } from './PayPapers';
import CancelConfirmationModal from './CancelConfirmationModal';

const PairingListItem = ({
    defaultPayIsCreditCard,
    discount,
    errorCount,
    errorMessage,
    loading,
    pairing,
    onCancel,
    onEdit,
    onPayValueChange,
    onPayOpen,
    onSubmitPayment,
    onSurchargeDialogOpen,
    onSurchargeDialogClose,
    isSurchargeDialogOpen,
    onToggleTooltip,
    onPaySetupClose,
}: PairingListItemProps) => {
    const itemEl = useRef<any>(null);
    const tooltipEl = useRef<any>(null);
    const classes = UnpaidStyles();
    const [confirmCancelOpen, setConfirmCancelOpen] = useState(false);

    const getEditButtons = (pairing: PairingModel) => {
        if (pairing.detail) {
            return;
        }

        return (
            <>
                <LinkButton
                    style={{ margin: '0 3px', textTransform: 'none', fontSize: 17 }}
                    onClick={() => onEdit(pairing)}
                >
                    Edit
                </LinkButton>
                <Typography
                    variant="subtitle2"
                    style={{
                        color: theme.palette.primary.main,
                        fontWeight: 'bold',
                        fontSize: '0.8em',
                        paddingLeft: 5,
                        paddingRight: 5,
                    }}
                >
                    |
                </Typography>
            </>
        );
    };

    const getPairingText = (pairing: PairingModel, isBeforeStartTime: boolean) => {
        if (pairing.itemType === 'GROUP') {
            return (
                <Grid container item alignItems="center" justify="center">
                    <Grid item xs={12}>
                        <Text variant="body2" bold>
                            {`Pay your provider${pairing.payInfo.length > 1 ? 's' : ''} for this week`}
                        </Text>
                    </Grid>
                </Grid>
            );
        }

        const { babysitter, appointment } = pairing;

        const start_date_display = formatMonthYearWith12HourTime(appointment.start_date);
        const end_date_display = format12HourTime(appointment.end_date);
        const asterisk = pairing.status === 'AUTOPAY_EXEMPT' ? '*' : '';

        return (
            <Grid container item alignItems="center" justify="center">
                <Grid item xs={12}>
                    <Typography
                        variant="body2"
                        style={{ color: Colors.darkNavy, fontWeight: 'bold' }}
                        className={classes.root}
                    >
                        {babysitter.user.first_name} {babysitter.user.last_name}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography
                        variant="body2"
                        style={{ color: Colors.grey, fontWeight: 'bold', fontSize: '0.85em' }}
                        className={classes.root}
                    >
                        {isBeforeStartTime ? 'Due ' : ''}
                        {start_date_display} - {end_date_display}
                        {asterisk}
                    </Typography>
                </Grid>
                <Grid item xs={12} style={{ marginTop: 5 }}>
                    <Grid container direction="row" className={classes.root}>
                        {getEditButtons(pairing)}
                        <LinkButton
                            style={{ margin: '0 3px', textTransform: 'none', fontSize: 17 }}
                            onClick={() => setConfirmCancelOpen(true)}
                        >
                            Cancel
                        </LinkButton>
                    </Grid>
                </Grid>
            </Grid>
        );
    };

    const getPaymentField = (
        label: string,
        value: string,
        pairing: PairingModel,
        fieldName: string,
        userId?: number,
    ) => {
        return (
            <Grid container direction="row" alignItems="flex-end">
                <Grid item xs={5} style={{ padding: '0 0.5em' }}>
                    <Typography variant="subtitle2" style={{ fontWeight: 'bold', color: theme.palette.primary.main }}>
                        {label}:
                    </Typography>
                </Grid>
                <Grid item xs={7} style={{ padding: '0 0.5em' }}>
                    <CurrencyInput
                        textAlign="right"
                        minimumValue="0"
                        value={value || 0}
                        onChange={(e: any, val: string) => onPayValueChange(pairing, fieldName, val, userId)}
                        decimalPlaces={2}
                        outputFormat="number"
                        style={{ maxWidth: '100px' }}
                    />
                </Grid>
            </Grid>
        );
    };

    const getPayUnit = (pay_is_fixed: boolean) => {
        if (pay_is_fixed) {
            return ' total';
        }

        return '/hr';
    };

    const calculateSingleSurchargeAmount = () => {
        if (!defaultPayIsCreditCard) {
            return 0;
        }
        const total_before_surcharge = !pairing
            ? 0
            : pairing.payInfo.amount + pairing.payInfo.tip + pairing.payInfo.reimbursement - (discount || 0) || 0;
        return (total_before_surcharge + 0.3) / (1 - 0.029) - total_before_surcharge;
    };

    const calculateGroupPaySurchargeAmount = (amount: number) => {
        if (!defaultPayIsCreditCard) {
            return 0;
        }
        const total_before_surcharge = amount - (discount || 0) || 0;
        return (total_before_surcharge + 0.3) / (1 - 0.029) - total_before_surcharge;
    };

    const getSinglePayContent = (pairing: PairingModel) => {
        const surchargeAmount = calculateSingleSurchargeAmount().toFixed(2);
        return (
            <>
                {getRequestText(pairing)}
                <Grid container item direction="row">
                    <Grid item xs={6} style={{ padding: '0 0.5em' }}>
                        {getPaymentField('Amount', pairing.payInfo.amount, pairing, 'amount')}
                        <Typography
                            variant="subtitle2"
                            style={{ fontSize: '0.8em', color: '#a9b9c4', padding: '0 0.5em' }}
                        >
                            ${pairing.appointment.pay}
                            {getPayUnit(pairing.appointment.ongoing_request.pay_is_fixed)}
                        </Typography>
                    </Grid>
                    <Grid item xs={6} style={{ padding: '0 0.5em' }}>
                        {getPaymentField('Tip', pairing.payInfo.tip, pairing, 'tip')}
                    </Grid>
                    <Grid container item xs={12} style={{ padding: '0 1em' }} justify="flex-start" alignItems="center">
                        <Typography
                            variant="subtitle2"
                            style={{ fontWeight: 'bold', color: theme.palette.primary.main }}
                        >
                            Reimbursement:
                        </Typography>
                        <CurrencyInput
                            textAlign="right"
                            minimumValue="0"
                            value={pairing.payInfo.reimbursement || 0}
                            onChange={(e: any, val: string) => onPayValueChange(pairing, 'reimbursement', val)}
                            decimalPlaces={2}
                            outputFormat="number"
                            style={{ maxWidth: '100px' }}
                        />
                    </Grid>
                </Grid>
                <DiscountLabel discount={discount} />
                <TotalLabel
                    amount={
                        pairing.payInfo.amount +
                        pairing.payInfo.tip +
                        pairing.payInfo.reimbursement -
                        (discount || 0) +
                        calculateSingleSurchargeAmount()
                    }
                    defaultPayIsCreditCard={defaultPayIsCreditCard}
                />
                <SurchargeItem
                    isDialogOpen={isSurchargeDialogOpen}
                    onDialogClose={onSurchargeDialogClose}
                    onDialogOpen={onSurchargeDialogOpen}
                    surchargeAmount={surchargeAmount}
                    defaultPayIsCreditCard={defaultPayIsCreditCard}
                />
                {getPaymentButton(pairing, false)}
            </>
        );
    };

    const getGroupProviderItem = (groupPayItem: any, itemId: number, pairing: PairingModel) => {
        return (
            <Grid
                key={groupPayItem.userId}
                container
                style={{
                    border: `1px solid ${Colors.navy}`,
                    borderRadius: '10px',
                    margin: '0.4em 0',
                    overflow: 'hidden',
                }}
            >
                <Grid
                    container
                    item
                    xs={12}
                    justify="center"
                    style={{ backgroundColor: theme.palette.primary.main, padding: 1 }}
                >
                    <Typography
                        style={{
                            color: 'white',
                            fontWeight: 'bold',
                            textAlign: 'center',
                            flexGrow: 10,
                        }}
                    >
                        {groupPayItem.name}
                    </Typography>
                    <Grid>
                        <IconButton
                            style={{ padding: '0', outline: 'transparent' }}
                            onClick={() => onToggleTooltip(pairing, groupPayItem.userId)}
                        >
                            <HelpOutline style={{ color: 'white' }} fontSize="small" ref={tooltipEl} />
                        </IconButton>
                        <BorderPopover
                            id={`tooltip${groupPayItem.userId}`}
                            open={groupPayItem.isTooltipOpen}
                            anchorEl={tooltipEl}
                            onClose={() => onToggleTooltip(pairing, groupPayItem.userId)}
                        >
                            <Grid>
                                <Typography
                                    variant="subtitle2"
                                    style={{
                                        width:
                                            tooltipEl && tooltipEl.current ? tooltipEl!.current.offsetWidth : '200px',
                                        padding: '1em',
                                    }}
                                >
                                    Pressing the "x" to the left of an appointment time will cancel that appointment for
                                    this period. You can also edit the times on the All Jobs screen or edit the amount
                                    you'd like pay your care provider without updating any times. Times in red have been
                                    confirmed by your provider and are reflected in the total amount. If the times or
                                    amount are incorrect you can update the amount.
                                </Typography>
                            </Grid>
                        </BorderPopover>
                    </Grid>
                </Grid>
                <Grid container item style={{ padding: '0.5em 0.5em 0 0.5em' }}>
                    {groupPayItem.pairings.map((x: any) => (
                        <Grid key={x.id} container item xl={6} lg={12} direction="row" alignItems="center">
                            <CancelIcon style={{ height: '65%' }} onClick={() => onCancel(x, itemId)} />
                            <Typography variant="subtitle2" style={{ color: x.confirmed ? Colors.coral : 'inherit' }}>
                                {formatMonthYearWith12HourTime(x.appointment.start_date)} -{' '}
                                {format12HourTime(x.appointment.end_date)}
                            </Typography>
                        </Grid>
                    ))}
                </Grid>
                <Grid container item direction="row" style={{ padding: '0.5em' }}>
                    <Grid item xs={6}>
                        {getPaymentField('Amount', groupPayItem.amount, pairing, 'amount', groupPayItem.userId)}
                    </Grid>
                    <Grid item xs={6}>
                        {getPaymentField('Tip', groupPayItem.tip, pairing, 'tip', groupPayItem.userId)}
                    </Grid>
                </Grid>
            </Grid>
        );
    };

    const getGroupContent = (pairing: PairingModel) => {
        const total = pairing.payInfo.reduce(
            (accumulator: any, item: any) => (accumulator += item.amount + item.tip),
            0,
        );
        const surchargeAmount = calculateGroupPaySurchargeAmount(total - (discount || 0));
        return (
            <>
                {pairing.payInfo.map((x: any) => getGroupProviderItem(x, pairing.id, pairing))}
                <DiscountLabel discount={discount} />
                <TotalLabel
                    amount={total + surchargeAmount - (discount || 0)}
                    defaultPayIsCreditCard={defaultPayIsCreditCard}
                />
                <SurchargeItem
                    isDialogOpen={isSurchargeDialogOpen}
                    onDialogClose={onSurchargeDialogClose}
                    onDialogOpen={onSurchargeDialogOpen}
                    surchargeAmount={surchargeAmount.toFixed(2)}
                    defaultPayIsCreditCard={defaultPayIsCreditCard}
                />
                {getPaymentButton(pairing, total === 0)}
            </>
        );
    };

    const getPopperContent = (pairing: PairingModel) => {
        return pairing.itemType === 'GROUP' ? getGroupContent(pairing) : getSinglePayContent(pairing);
    };

    const getPaymentButton = (pairing: PairingModel, isDisabled: boolean) => {
        return (
            <PayButton onClick={() => onSubmitPayment(pairing)} loading={loading} disabled={isDisabled}>
                Send Payment
            </PayButton>
        );
    };

    const getUserId = (pairing: PairingModel) => {
        if (pairing.babysitter) {
            return pairing.babysitter.user.id;
        }

        if (pairing.itemType === 'GROUP' && pairing?.payInfo?.length === 1) {
            return pairing.payInfo[0].userId;
        }

        return undefined;
    };

    const getRequestText = (pairing: PairingModel) => {
        if (!pairing.detail) {
            return;
        }

        const { appointment } = pairing;
        const originalPay = calculatePay(
            new Date(appointment.end_date),
            new Date(appointment.start_date),
            appointment.pay,
        );

        return (
            <Typography variant="subtitle2">
                Your provider reported the times as{' '}
                {pairing.detail.start_date ? formatMonthYearWith12HourTime(pairing.detail.start_date) : ''} -{' '}
                {pairing.detail.end_date ? format12HourTime(pairing.detail.end_date) : ''}. The original total would
                have been ${originalPay.toFixed(2)}.
            </Typography>
        );
    };

    let isBeforeStartTime = false;
    if (pairing && pairing.itemType !== 'GROUP' && pairing.appointment) {
        isBeforeStartTime = new Date(pairing.appointment.start_date) > new Date();
    }

    return (
        <>
            {!pairing ? null : (
                <StyledListItem id={pairing.id} ref={itemEl}>
                    <PayCardPaper>
                        <Grid container direction="row" className="tour-todos-confirm" spacing={1}>
                            <Grid container item sm={2} xs={12} justify="center" alignItems="center">
                                <Grid item sm={12} xs={2}>
                                    <ProfileImage id={getUserId(pairing)} height={'100%'} width={'100%'} />
                                </Grid>
                            </Grid>
                            <Grid container item sm={6} xs={12} justify="center">
                                {getPairingText(pairing, isBeforeStartTime)}
                            </Grid>
                            <Grid container sm={4} xs={12} alignItems="center" className={classes.extra}>
                                <AnchorButton
                                    onPayOpen={onPayOpen}
                                    pairing={pairing}
                                    itemEl={itemEl}
                                    isPairing={true}
                                    buttonText="Pay"
                                    opacity={isBeforeStartTime ? 0.5 : 1}
                                />
                                {pairing.payInfo && (
                                    <Popper
                                        open={Boolean(pairing.anchor || false)}
                                        anchorEl={pairing.anchor}
                                        placement="bottom"
                                    >
                                        <Grid
                                            container
                                            style={{
                                                width: itemEl.current ? itemEl.current.offsetWidth : '200px',
                                                padding: '1em',
                                                backgroundColor: '#F8F7F7',
                                                borderRadius: 10,
                                                border: `1px solid ${theme.palette.primary.main}`,
                                            }}
                                        >
                                            {pairing.itemType === 'GROUP' && (
                                                <Grid container item justify="flex-end">
                                                    <CancelIcon onClick={(e) => onPayOpen(e, pairing, itemEl)} />
                                                </Grid>
                                            )}
                                            {getPopperContent(pairing)}
                                            <ErrorMessage
                                                errorMessage={errorMessage}
                                                errorCount={errorCount}
                                                onModalClose={onPaySetupClose}
                                            />
                                        </Grid>
                                    </Popper>
                                )}
                            </Grid>
                        </Grid>
                    </PayCardPaper>
                </StyledListItem>
            )}
            <CancelConfirmationModal
                open={confirmCancelOpen}
                doNotCancel={() => setConfirmCancelOpen(false)}
                closeModal={() => setConfirmCancelOpen(false)}
                job={pairing?.appointment?.id ?? 0}
            />
        </>
    );
};

export default PairingListItem;
