import React, { forwardRef, Ref } from 'react';
import { Colors } from '../../../constants';

const variantTagsByKey = {
    display: 'div',
    h1: 'h1',
    h2: 'h2',
    body1: 'div',
    body2: 'div',
    body3: 'div',
    caption: 'div',
    deprecatedCaption: 'caption',
};

export interface TextProps {
    inline?: boolean;
    id?: string;
    bold?: boolean;
    serif?: boolean;
    children: React.ReactNode;
    textStyle?: React.CSSProperties;
    color?: string;
    variant?: keyof typeof variantTagsByKey;
    spaced?: boolean;
    onClick?: () => void;
    title?: string;
    align?: 'center' | 'left' | 'right';
}

interface TextTagTypes {
    div: JSX.IntrinsicElements['div'];
    h1: JSX.IntrinsicElements['h1'];
    h2: JSX.IntrinsicElements['h2'];
    span: JSX.IntrinsicElements['span'];
    caption: JSX.IntrinsicElements['caption'];
}

export type TextRef = HTMLDivElement | HTMLHeadingElement | HTMLTableCaptionElement;

function Text(
    { id, inline, bold, children, color, spaced, textStyle, variant = 'body1', serif, ...props }: TextProps,
    ref: Ref<TextRef>,
) {
    const TextTag = inline ? 'span' : (variantTagsByKey[variant || 'body1'] as keyof TextTagTypes);

    function getFontWeight() {
        if (bold || variant === 'display' || variant === 'h1') {
            return 700;
        } else {
            return 400;
        }
    }

    function getFontFamily() {
        return variant === 'display' || serif ? 'Outfit' : 'Outfit';
    }

    function getFontSize() {
        let fontSize = '22px';
        if (spaced) {
            fontSize = '14px';
        } else if (variant === 'display') {
            fontSize = '34px';
        } else if (variant === 'body1') {
            fontSize = '16px';
        } else if (variant === 'h1') {
            fontSize = '24px';
        } else if (variant === 'h2') {
            fontSize = '18px';
        } else if (variant === 'body2') {
            fontSize = '14px';
        } else if (variant === 'body3') {
            fontSize = '10px';
        } else if (variant === 'caption' || variant === 'deprecatedCaption') {
            fontSize = '12px';
        }
        return fontSize;
    }

    function getLineHeight() {
        let lineHeight = '22px';
        if (spaced) {
            lineHeight = '21px';
        } else if (variant === 'display') {
            lineHeight = '45px';
        } else if (variant === 'h1') {
            lineHeight = '30px';
        } else if (variant === 'h2') {
            lineHeight = '25px';
        } else if (variant === 'body2') {
            lineHeight = '18px';
        } else if (variant === 'body3') {
            lineHeight = '14px';
        } else if (variant === 'caption' || variant === 'deprecatedCaption') {
            lineHeight = '20px';
        }

        return lineHeight;
    }

    function getLetterSpacing() {
        return spaced ? '3' : 'normal';
    }

    return (
        <TextTag
            ref={ref}
            id={id}
            {...props}
            style={{
                ...baseStyle,
                fontWeight: getFontWeight(),
                fontFamily: getFontFamily(),
                fontSize: getFontSize(),
                color: color ? color : Colors.darkNavy,
                lineHeight: getLineHeight(),
                letterSpacing: getLetterSpacing(),
                ...textStyle,
            }}
        >
            {children}
        </TextTag>
    );
}

const baseStyle = {
    margin: 0,
};

export default forwardRef(Text);
