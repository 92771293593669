import { BasicDialog, Checkbox, OutlineButton, OutlinedTextInput, PrimaryButton } from 'library';
import { client } from 'shared';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import DateFnsUtils from '@date-io/date-fns';
import { Text } from 'library';
import { Colors } from 'library';
import React, { useState } from 'react';
import { AdminProvider } from 'models';

export default function SnoozeBtn({ provider, getProvider }: { provider: AdminProvider; getProvider: () => void }) {
    const [snoozeUntil, setSnoozeUntil] = useState<Date | undefined>(undefined);
    const [message, setMessage] = useState<string>('');
    const [open, setOpen] = useState(false);
    const [checked, setChecked] = useState(false);

    function snooze() {
        client(`api/admin-snooze-provider/`, {
            method: 'POST',
            body: { provider_id: provider.id, until_date: snoozeUntil, message: message, snooze_indefinitely: checked },
        })
            .then(getProvider)
            .finally(() => alert('Provider Snoozed'))
            .catch(() => alert('Error Snoozing Provider'));
    }

    return (
        <>
            <OutlineButton onClick={() => setOpen(true)}>Snooze Provider</OutlineButton>
            <BasicDialog isOpen={open} onClose={() => setOpen(false)} closeButton>
                <div>
                    <Checkbox
                        checked={checked}
                        onChange={() => {
                            setChecked(!checked);
                        }}
                        label={'Snooze Indefinitely'}
                    />
                    {!checked ? (
                        <>
                            <Text bold textStyle={{ margin: 15 }}>
                                Select Date To Un-Snooze
                            </Text>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <DatePicker
                                    InputProps={{
                                        inputProps: {
                                            style: { textAlign: 'center', color: Colors.darkNavy },
                                        },
                                    }}
                                    disableToolbar
                                    variant="inline"
                                    format="MM/dd/yyyy"
                                    value={snoozeUntil}
                                    onChange={(d: MaterialUiPickersDate | Date) => {
                                        setSnoozeUntil(d as Date);
                                    }}
                                    minDate={new Date()}
                                />
                            </MuiPickersUtilsProvider>
                            <Text bold textStyle={{ margin: 15 }}>
                                Enter Message to Send to Provider On That Date
                            </Text>

                            <OutlinedTextInput
                                multiline
                                value={message}
                                style={{ backgroundColor: 'white', padding: 10, borderRadius: 15 }}
                                placeholder=""
                                rows={4}
                                fullWidth
                                onChange={(e: any) => setMessage(e.target.value)}
                            />
                        </>
                    ) : null}
                    <PrimaryButton
                        onClick={snooze}
                        buttonStyle={{ maxWidth: 200 }}
                        disabled={!checked && !message && !snoozeUntil}
                    >
                        Submit Snooze
                    </PrimaryButton>
                </div>
            </BasicDialog>
        </>
    );
}
