import React, { Dispatch, SetStateAction } from 'react';
import { Grid } from '@material-ui/core';
import { Text } from 'library';
import ErrorText from '../../../parent-portal/forms/components/ErrorText';
import { TextInputField, DropdownInputField, TextAreaInputField } from './fieldInputs/index';
import { formatPhoneNumber } from '../util-functions';
import { useFormStyles } from '../constants';
import { AutomatedReference, WorkerReferenceForm, ErrorState } from '../models';
import { useMediaQuery } from '@mui/material';
import theme from 'theme';

export default function WorkerReferenceFormField({
    fieldNameDisplay,
    fieldName,
    fieldValue,
    form,
    setForm,
    errorState,
    automatedReference,
}: {
    fieldNameDisplay: string;
    fieldName: string;
    fieldValue: string | undefined;
    form: WorkerReferenceForm;
    setForm: Dispatch<SetStateAction<WorkerReferenceForm>>;
    errorState: ErrorState;
    automatedReference: AutomatedReference;
}) {
    const xsDown = useMediaQuery(theme.breakpoints.down('xs'));
    const styles = useFormStyles();

    function handleOnChange(formArg: WorkerReferenceForm, key: string, value: string | number | undefined) {
        if (Object.prototype.hasOwnProperty.call(formArg, key)) {
            delete formArg[key as keyof WorkerReferenceForm];
            const tempForm = {
                [key]: key !== 'phone_number' ? value : formatPhoneNumber(value?.toString()),
                ...formArg,
            };
            setForm(tempForm);
        }
    }

    const inputFields = [
        {
            field_name: 'first_name',
            component: TextInputField,
            placeholder: automatedReference.contact_info.first_name ?? '',
        },
        {
            field_name: 'last_name',
            component: TextInputField,
            placeholder: automatedReference.contact_info.last_name ?? '',
        },
        {
            field_name: 'email',
            component: TextInputField,
            placeholder: automatedReference.contact_info.email ?? '',
        },
        {
            field_name: 'phone_number',
            component: TextInputField,
            placeholder: formatPhoneNumber(automatedReference.contact_info.phone_number) ?? '',
        },
        {
            field_name: 'relationship',
            component: DropdownInputField,
            placeholder: 'Select One',
        },
        {
            field_name: 'relationship_free_input',
            component: TextInputField,
            placeholder: 'Other',
        },
        {
            field_name: 'reference_text',
            component: TextAreaInputField,
            placeholder: 'Please type your character reference here:',
        },
    ];

    const defaultComponent = {
        field_name: 'default_component',
        component: TextInputField,
        placeholder: '',
    };

    const CurrentField = inputFields.find((option) => option.field_name === fieldName) || defaultComponent;

    const formInputClusterSansErrorStyle =
        fieldName === 'relationship'
            ? { ...styles.formInputClusterSansError, gap: '2vw' }
            : styles.formInputClusterSansError;
    const errorDivStyle =
        fieldName === 'relationship_free_input' ? { ...styles.errorDiv, marginTop: 10 } : styles.errorDiv;
    const formInputClusterSansErrorJustify = fieldName === 'relationship_free_input' ? 'flex-end' : 'space-between';

    return (
        <Grid
            container
            item
            direction="column"
            justify="flex-start"
            alignItems="flex-end"
            style={styles.formInputCluster}
        >
            <Grid
                container
                item
                direction={xsDown ? 'column' : 'row'}
                justify={formInputClusterSansErrorJustify}
                style={formInputClusterSansErrorStyle}
            >
                <Text variant="h1" textStyle={styles.fieldText}>
                    {fieldNameDisplay}
                </Text>
                {CurrentField.component({
                    fieldName,
                    fieldValue,
                    form,
                    errorState,
                    placeholder: CurrentField.placeholder,
                    handleOnChange,
                })}
            </Grid>

            <div style={errorDivStyle}>
                {errorState[fieldName as keyof ErrorState].inErrorState && (
                    <ErrorText>{errorState[fieldName as keyof ErrorState].message}</ErrorText>
                )}
            </div>
        </Grid>
    );
}
