import { BasicDialog, Chip, Text, Colors, PrimaryButton } from 'library';
import { JobQualificationValue, Qualification } from 'models';
import React from 'react';
import FormField from '../../FormField';
import QualificationInput from './QualificationInput';
import { FormFieldProps } from '../../FormTypes';
import { addOrUpdateQualification } from 'parent-portal/forms/jobDetailMethods';

export default function NestedQualificationsModal({
    qualifications,
    visible,
    onClose,
    jobQualifications,
}: {
    qualifications: Qualification[];
    visible: boolean;
    onClose: () => void;
    jobQualifications: FormFieldProps<JobQualificationValue[]>;
}) {
    const { value: values } = jobQualifications;

    function isSelected(qualification: Qualification) {
        return values.some((val) => val.qualification === qualification.id && val.value === 'true');
    }

    function getColor(qualification: Qualification) {
        let selected = isSelected(qualification);
        return {
            color: selected ? Colors.white : Colors.darkNavy,
            backgroundColor: selected ? Colors.darkNavy : Colors.lightTurq,
        };
    }

    return (
        <BasicDialog closeButton onClose={onClose} isOpen={visible}>
            <FormField title="Requirements">
                <Text variant="body2" textStyle={{ marginTop: 20, marginBottom: 20 }}>
                    Select all that apply.
                </Text>
                {qualifications
                    .filter((qual) => qual.value_type === 'BOOLEAN')
                    .map((qual) => (
                        <Chip
                            key={qual.id}
                            label={qual.name}
                            style={{ marginRight: 12, marginBottom: 10, ...getColor(qual) }}
                            onClick={() =>
                                addOrUpdateQualification(
                                    { qualification: qual.id, value: (!isSelected(qual)).toString() },
                                    jobQualifications,
                                )
                            }
                        />
                    ))}
                <br />
                {qualifications
                    .filter((qual) => qual.value_type !== 'BOOLEAN')
                    .map((qual) => (
                        <div style={{ display: 'inline-block', marginTop: 20, paddingTop: 20 }}>
                            <Text variant="h2" bold>
                                {qual.name}
                            </Text>
                            <QualificationInput qualification={qual} jobQualifications={jobQualifications} />
                        </div>
                    ))}
            </FormField>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                <PrimaryButton onClick={onClose} buttonStyle={{ width: 120 }}>
                    Close
                </PrimaryButton>
            </div>
        </BasicDialog>
    );
}
