import React from 'react';
import { Text } from 'library';
import { Grid, Tooltip } from '@mui/material';
import { InfoRounded } from '@mui/icons-material';

export default function CardInfo({ title, content, infoText }: { title: string; content: string; infoText?: string }) {
    return (
        <Grid item container direction="column" style={{ marginTop: 5 }}>
            <Text variant="caption">
                {title}{' '}
                <Text variant="caption" bold>
                    {content}
                    {infoText ? (
                        <Tooltip title={infoText}>
                            <InfoRounded fontSize="small" sx={{ marginTop: '-4px' }} />
                        </Tooltip>
                    ) : (
                        ''
                    )}
                </Text>
            </Text>
        </Grid>
    );
}
