import { Grid, LinearProgress, styled } from '@mui/material';

import { Colors } from 'library';
export const ProgressBar = styled(LinearProgress)({
    height: 15,
    borderRadius: 18,
    marginBottom: 10,
    '& .MuiLinearProgress-barColorPrimary': {
        backgroundColor: Colors.darkNavy,
        borderRadius: 18,
    },
    '& .MuiLinearProgress-barColorSecondary': {
        backgroundColor: Colors.pressedNavy,
    },
});

export const BoxContainer = styled(Grid)({
    borderRadius: 18,
    padding: 20,
    boxShadow: '0px 3px 6px 0px #00000029',
    backgroundColor: Colors.white,
});

export const LightTurqContainer = styled(BoxContainer)({
    backgroundColor: Colors.lightTurq,
});

export const WhiteContainer = styled(BoxContainer)({
    padding: 50,
});
