import React from 'react';
import { Paper, Text } from 'library';
import { useProviderProfileContext } from '../../ProviderProfileContext';
import ProviderProfileJobType from './ProviderProfileJobType';
import PersonalityTraitsAndSkills from './PersonalityTraitsAndSkills';
import AvailableFor from './AvailableFor';

export default function ProviderProfileAboutTab() {
    const { provider } = useProviderProfileContext();
    return (
        <>
            <Paper>
                <Text variant="body2">{provider?.bio}</Text>
            </Paper>
            <ProviderProfileJobType />
            <AvailableFor />
            <PersonalityTraitsAndSkills provider={provider} />
        </>
    );
}
