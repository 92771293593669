import React from 'react';
import FormField from '../../FormField';
import { Checkbox, Colors, Text } from 'library';
import { Grid, TextField } from '@mui/material';
import { FormFieldProps } from '../../FormTypes';

export default function BreakRequiredField({
    breakRequired,
    breakLength,
}: {
    breakRequired: FormFieldProps<boolean>;
    breakLength: FormFieldProps<number | undefined>;
}) {
    return (
        <FormField title="4. Does this job require a break?" style={{ marginTop: 20 }}>
            <Grid container direction="row" gap={4} marginTop={2}>
                <Checkbox
                    style={{ width: undefined }}
                    label="Break Required"
                    checked={breakRequired.value}
                    onChange={() => {
                        if (!breakRequired.value && !breakLength.value) {
                            breakLength.setValue(60);
                        }
                        breakRequired.setValue(!breakRequired.value);
                    }}
                />
                <Grid item>
                    <Grid container item direction="row" alignItems="center">
                        <TextField
                            disabled={!breakRequired.value}
                            value={breakLength.value}
                            onChange={(e) => breakLength.setValue(parseInt(e.target.value))}
                            id="job-form-break-length-input"
                            variant="standard"
                            type="number"
                            sx={styles.inputStyles}
                            InputProps={{
                                inputProps: {
                                    min: 1,
                                },
                            }}
                        />
                        <Text>minutes required</Text>
                    </Grid>
                </Grid>
            </Grid>
        </FormField>
    );
}

const styles = {
    inputStyles: {
        marginRight: 1,
        fontFamily: 'WorkSans-Regular',
        width: 70,
        color: Colors.darkNavy,
        borderColor: Colors.darkNavy,
        '& .Mui-focused': {
            color: Colors.darkNavy,
        },
    },
};
