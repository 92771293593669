import React from 'react';
import { Grid } from '@material-ui/core';
import { Colors, IconButton, Text, TextButton } from 'library';
import { formatMonthDayYear } from 'shared/Dates';
import { client, consoleLogInDev } from 'shared';
import { Popover, styled } from '@mui/material';
import { PriorityHigh } from '@mui/icons-material';
import { getExpirationInfo } from 'shared/Job';

export default function BusinessJobPostDates({
    ongoingRequestId,
    requestPostedAt,
    requestRenewedAt,
    onExtend,
}: {
    ongoingRequestId: number;
    requestPostedAt?: string;
    requestRenewedAt?: string;
    onExtend: () => void;
}) {
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
    const [errorMessage, setErrorMessage] = React.useState('');

    const { expiresAt, expiresInDays, isExpired } = getExpirationInfo(requestRenewedAt, requestPostedAt);
    const isCloseToExpiring = expiresInDays && expiresInDays <= 10;

    function extendExpiresAt() {
        setErrorMessage('');
        client(`api/ongoing/${ongoingRequestId}/`, {
            method: 'PATCH',
            body: { request_renewed_at: new Date().toISOString() },
        })
            .then(() => onExtend())
            .catch((error) => {
                consoleLogInDev(error);
                setErrorMessage(error.message || 'There was an error extending your post');
            });
    }

    function getExtendButton() {
        if (isExpired || !isCloseToExpiring) {
            return;
        }

        return (
            <TextContainer>
                <TextButton onClick={extendExpiresAt} buttonStyle={{ width: undefined, display: 'inline-block' }}>
                    Extend Job Post
                </TextButton>
                <Text variant="caption" textStyle={{ color: Colors.error }}>
                    {errorMessage}
                </Text>
            </TextContainer>
        );
    }

    function getExpireDateColor() {
        if (isCloseToExpiring) {
            return Colors.terraCotta;
        }

        if (isExpired) {
            return Colors.error;
        }

        return Colors.darkNavy;
    }

    const handleClose = () => {
        setAnchorEl(null);
    };

    function getExpiredComponent() {
        if (!isExpired) {
            return;
        }

        const open = Boolean(anchorEl);
        const id = open ? 'simple-popover' : undefined;

        return (
            <TextContainer>
                <IconButton
                    aria-describedby={id}
                    icon={PriorityHigh}
                    color={Colors.error}
                    onClick={(event) => {
                        setAnchorEl(event.currentTarget.parentElement);
                    }}
                />
                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                >
                    <PopoverContainer>
                        <Text variant="caption">The job posting has expired due to lack of qualified applicants.</Text>
                        <br />
                        <Text variant="caption">
                            If you&apos;d like for Tandem to continue looking for applicants for you, please post a new
                            Trial Run job posting.
                        </Text>
                    </PopoverContainer>
                </Popover>
            </TextContainer>
        );
    }

    return (
        <Grid xs={12} item container alignItems="center">
            {requestPostedAt && (
                <Text variant="caption">
                    <b>Posted:</b> {formatMonthDayYear(requestPostedAt)}
                </Text>
            )}
            <TextContainer>
                {expiresAt && (
                    <Text
                        variant="caption"
                        textStyle={{
                            color: getExpireDateColor(),
                            display: 'inline',
                        }}
                    >
                        <b>Job Posting Expiration Date:</b> {formatMonthDayYear(expiresAt)}
                    </Text>
                )}
                {getExtendButton()}
                {getExpiredComponent()}
            </TextContainer>
        </Grid>
    );
}

const TextContainer = styled('span')({
    marginLeft: 10,
});

const PopoverContainer = styled(Grid)({
    padding: 10,
    maxWidth: 150,
    borderRadius: 20,
});
