import { Environment, MixPanelKey, SitterMixPanelKey } from 'environmentVariables';
import mixpanel from 'mixpanel-browser';

mixpanel.init(MixPanelKey);

export const identify = (id) => {
    mixpanel.init(MixPanelKey);
    mixpanel.identify(id);
};

export const identifyProvider = (id) => {
    mixpanel.init(SitterMixPanelKey);
    mixpanel.identify(id);
};

export const track = (message, properties) => {
    try {
        mixpanel.track(message, properties);
    } catch (error) {
        if (Environment !== 'production') {
            console.log(error);
        }
    }
};
