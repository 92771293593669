import React from 'react';
import { Grid, useMediaQuery } from '@material-ui/core';

import theme from 'theme';
import Header from './ProviderHeader';
import { Text, PrimaryButton } from 'library';
import HardWork from './on-demand/HardWork';

export default function PartialSignUpForm() {
    const mdDown = useMediaQuery(theme.breakpoints.down('md'));
    return (
        <Grid container item alignContent="center" justify="center" direction="column">
            <Header smDown={mdDown} />
            <HardWork smDown={mdDown} />
            <Grid container item direction="column" style={{ padding: 30 }}>
                <Text variant="display" textStyle={{ alignSelf: 'center' }}>
                    Other questions? Check out our FAQ's here.
                </Text>
                <PrimaryButton
                    buttonStyle={{ maxWidth: 250, margin: 50, alignSelf: 'center' }}
                    onClick={() => {
                        window.open(`https://info.join-tandem.com/childcare-center-jobs/faq`);
                    }}
                >
                    FAQ's
                </PrimaryButton>
            </Grid>
        </Grid>
    );
}
