import React, { forwardRef, useEffect, useRef } from 'react';
import { Colors } from '../../theme';
import { TextField, TextFieldProps } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const OutlinedTextInput = forwardRef(function _OutlinedTextInput(
    props: TextFieldProps,
    ref: React.ForwardedRef<HTMLInputElement>,
) {
    const inputRef = useRef<HTMLInputElement>();

    function onWheel(e: any) {
        e.currentTarget.blur();
    }

    useEffect(() => {
        if (props.type === 'number' && inputRef.current) {
            inputRef.current?.addEventListener('wheel', onWheel, { passive: false });

            return () => {
                inputRef?.current?.removeEventListener('wheel', onWheel);
            };
        }
    }, [inputRef]);
    return <TextField ref={ref} variant="outlined" {...props} inputRef={props.inputRef ?? inputRef} />;
});

export default withStyles({
    root: {
        '& .MuiInputBase-root': {
            color: Colors.darkNavy,
        },
        '& .MuiInputBase-root.Mui-disabled': {
            color: Colors.lightGrey,
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: Colors.darkNavy,
                borderRadius: 15,
                borderWidth: 0.1,
            },
        },
    },
})(OutlinedTextInput);
