import usePublicSessionTracking, { PublicSessionEventType } from 'parent-portal/shared/PublicSessionTracking';

const appLink = `https://apps.apple.com/app/apple-store/id1491607600?pt=120819140&ct=InviteFriends&mt=8`;

export default function useAppStoreNavigation(
    track: ReturnType<typeof usePublicSessionTracking>['trackSession'],
    linkLocation?: string,
) {
    return function goToAppStore() {
        track('NAVIGATE_FROM', 'To App Store', PublicSessionEventType.User, {
            linkLocation: linkLocation ?? 'Not specified',
        });
        window.open(appLink, '_blank');
    };
}
