import React from 'react';
import { BusinessJobTemplateDetailsDTO, BusinessJobTemplateListDTO } from 'parent-portal/forms/JobTemplateModels';
import { styled } from '@mui/material';
import { Text } from 'library';
import { formatLongMonthDay, to12HourTime } from 'shared/Dates';
import DayCircle from '../JobSummaryPage/components/DayCircle';

export default function JobTemplateOptionScheduleInfo({
    jobTemplate,
}: {
    jobTemplate: BusinessJobTemplateListDTO | BusinessJobTemplateDetailsDTO;
}) {
    return (
        <Container>
            <DayContainer>
                {jobTemplate.daily_schedules.map((schedule) => (
                    <DayCircle key={schedule.id} day={schedule.day} />
                ))}
            </DayContainer>
            {jobTemplate.daily_schedules.length > 0 ? (
                <Text variant="caption">
                    {to12HourTime(jobTemplate.daily_schedules[0].start_time)} -{' '}
                    {to12HourTime(jobTemplate.daily_schedules[0].end_time)}
                </Text>
            ) : null}
            <Text variant="caption">
                {!jobTemplate.end_date && 'Starting as soon as '}
                {formatLongMonthDay(jobTemplate.start_date, 'UTC')}{' '}
                {jobTemplate.end_date && jobTemplate.start_date !== jobTemplate.end_date
                    ? `- ${formatLongMonthDay(jobTemplate.end_date, 'UTC')}`
                    : ''}
            </Text>
        </Container>
    );
}

const Container = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    minWidth: 150,
    gap: 5,
});

const DayContainer = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    gap: 5,
    alignItems: 'center',
});
