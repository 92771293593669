import React from 'react';
import { AdminProvider } from 'models';
import { Colors, Text } from 'library';
import { client, consoleLogInDev } from 'shared';
import { IAdminMedicalRecord } from 'models/Providers/MedicalRecord';
import StatusChanger from './StatusChanger';

export default function MedStatus({ provider, onRefresh }: { provider: AdminProvider; onRefresh: () => void }) {
    const options = [
        'MISSING_TB_SECTION',
        'MISSING_VACCINATION_SECTION',
        'MISSING_SIGNATURE',
        'MISSING_DATE',
        'WRONG_FORM_SIGNED',
        'IN_PROGRESS',
        'AWAITING_MEDICAL_RECORDS',
        'AWAITING_TB_TEST_RESULTS',
        'AWAITING_BLOOD_TEST_RESULTS',
        'AWAITING_LOAN_FROM_TANDEM',
        'AWAITING_SIGNATURE_FROM_DOCTOR',
        'NEEDS_TO_SCHEDULE',
        'NEEDS_TO_RESCHEDULE',
        'NEEDS_VACCINATION_APPOINTMENT',
        'NEEDS_TO_PICK_UP',
        'NEEDS_TO_UPLOAD_FORM',
        'NEEDS_TO_CONTACT_PAST_CENTER',
    ];

    function updateStatus(newStatus: string) {
        client(`api/md-records/update-status/`, {
            method: 'PATCH',
            body: { provider: provider.user.id, status: newStatus },
        })
            .then(() => {
                onRefresh();
            })
            .catch(consoleLogInDev);
    }

    return (
        <>
            <Text variant="h2" bold textStyle={{ margin: 10, color: Colors.turquoise }}>
                Med Info
            </Text>
            <StatusChanger
                provider={provider}
                updateStatus={updateStatus}
                status={
                    provider?.medical_record &&
                    provider?.medical_record?.filter((med: IAdminMedicalRecord) => med.med_flow_status).length
                        ? provider?.medical_record?.filter((med: IAdminMedicalRecord) => !!med.med_flow_status)[0]
                              .med_flow_status
                        : 'NONE'
                }
                options={options}
            />
            {provider.medical_appointment?.appointment_time ? (
                <div>
                    <Text variant="body2" textStyle={{ margin: 10 }}>
                        Telehealth appointment: {provider.medical_appointment?.appointment_time}
                    </Text>
                </div>
            ) : null}
        </>
    );
}
