import React, { useState } from 'react';
import { Add } from '@mui/icons-material';
import PreferWorkerModal from 'parent-portal/shared/UserActions/PreferWorkerModal';
import { IconButton } from 'library';
import { LeanUser, ShiftProviderUser } from 'models';

export function AddPreferredWorker({
    preferred,
    refreshData,
    businessLocationId,
}: {
    preferred: LeanUser;
    refreshData?: () => void;
    businessLocationId: number;
}) {
    const [open, setOpen] = useState(false);

    function transformWorker(worker: LeanUser): ShiftProviderUser {
        return {
            first_name: worker.first_name,
            last_name: worker.last_name,
            id: worker.id,
            profile_picture: { image: '' },
        };
    }

    return (
        <>
            <span style={{ marginLeft: 10 }}>
                <IconButton tooltip="Add as preferred worker" icon={Add} onClick={() => setOpen(true)}></IconButton>
            </span>
            <PreferWorkerModal
                open={open}
                onClose={() => setOpen(false)}
                onSuccess={() => refreshData?.()}
                provider={transformWorker(preferred)}
                businessLocationId={businessLocationId}
            />
        </>
    );
}
