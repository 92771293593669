import React, { useState } from 'react';
import Arrow from '@material-ui/icons/ChevronRight';
import Label from 'reusableComponents/checkboxLabel';

import { Card, Divider, CardContent, RadioGroup, Radio, styled } from '@mui/material';
import { Checkbox, OutlinedDropdown, Text } from 'library';

import { useUserContext } from 'UserContext';
import { STAFF, WORKED } from '../constants';

import type { FilterProps } from '../models';

export function Filters({
    formValues,
    handleChange,
}: {
    formValues: FilterProps;
    handleChange: (value: string | boolean, formKey: string) => void;
}) {
    const { user } = useUserContext();
    const [expanded, setExpanded] = useState<boolean>(true);

    const addresses = user?.address || [];

    const selectOptions = [
        { value: 'Select One', key: '-1' },
        ...addresses.map((x) => {
            return {
                value: `${x.street} ${x.city}, ${x.state} ${x.zip_code} (${x.business_location?.name})`,
                key: x.id.toString(),
            };
        }),
    ];

    return (
        <StyledCard>
            <CardContent>
                <CardHeader onClick={() => setExpanded(!expanded)}>
                    <Text bold variant="h2">
                        Filters
                    </Text>
                    <StyledArrow aria-expanded={expanded} />
                </CardHeader>
                <FilterControls aria-expanded={expanded}>
                    <div>
                        <Text variant="body1">Location:</Text>
                        <StyleOutlinedDropdown
                            value={formValues.address}
                            fields={selectOptions}
                            onChange={(x) => handleChange(x.target.value, 'address')}
                        />
                    </div>
                    <StyledDivider />
                    <div>
                        <Text variant="body1">Workers:</Text>
                        <RadioGroup
                            value={formValues.workerType}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                handleChange(event.target.value, 'workerType')
                            }
                        >
                            <Label
                                control={<Radio color="primary" disableRipple disableFocusRipple />}
                                labelPlacement="end"
                                value={WORKED}
                                label={<Text variant="body1">who worked at my center</Text>}
                            />
                            <Label
                                control={<Radio color="primary" disableRipple disableFocusRipple />}
                                labelPlacement="end"
                                value={STAFF}
                                label={<Text variant="body1">scheduled at my center</Text>}
                            />
                        </RadioGroup>
                    </div>
                    <CheckboxContainer>
                        <Checkbox
                            label="Include blocked workers"
                            disabled={formValues.workerType !== WORKED}
                            checked={formValues.includeBlocked}
                            onChange={(event: boolean) => handleChange(event, 'includeBlocked')}
                        />
                    </CheckboxContainer>
                    <CheckboxContainer>
                        <Checkbox
                            label="Preferred Workers"
                            checked={formValues.showPreferred}
                            onChange={(event: boolean) => handleChange(event, 'showPreferred')}
                        />
                    </CheckboxContainer>
                </FilterControls>
            </CardContent>
        </StyledCard>
    );
}

const StyledCard = styled(Card)({
    minWidth: '25%',
    marginTop: 20,
    height: 'fit-content',
});

const CardHeader = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
});

const FilterControls = styled('div')((props) => ({
    display: props['aria-expanded'] ? 'flex' : 'none',
    flexDirection: 'column',
}));

const CheckboxContainer = styled('div')({
    marginTop: 10,
});

const StyledArrow = styled(Arrow)((props) => ({
    transform: props['aria-expanded'] ? 'rotate(90deg)' : 'rotate(-90deg)',
    transition: 'transform 0.5s ease-in-out',
    width: 30,
    height: 30,
}));

const StyledDivider = styled(Divider)({
    margin: '10px 0',
});

const StyleOutlinedDropdown = styled(OutlinedDropdown)({
    maxWidth: 400,
});
