import React from 'react';
import { PastShift } from '../models';
import { styled, Grid } from '@mui/material';
import ShiftCard from './ShiftCard';

export default function PastShifts({ shifts, onEditDone }: { shifts: PastShift[]; onEditDone: () => void }) {
    return (
        <Container item container direction="row" justifyContent="center">
            {shifts.map((shift: PastShift) => (
                <ShiftCard shift={shift} key={shift.id} onEditDone={onEditDone} />
            ))}
        </Container>
    );
}

const Container = styled(Grid)({
    gap: 10,
});
