import React from 'react';
import { IShift, ShiftProvider } from 'models';
import { BusinessSlotRecruitmentStatus } from './BusinessSlotRecruitmentStatus';
import { BusinessSlotHourReportDetails } from './BusinessSlotHourReportDetails';

export function BusinessSlotProgressDetails({
    appt,
    recruitmentStatuses,
    shiftProvider,
}: {
    appt?: IShift;
    recruitmentStatuses: { title: string; show: boolean }[];
    shiftProvider?: ShiftProvider;
}) {
    const hourReport = appt?.hour_reports.find((h) => h.provider.id === shiftProvider?.provider_id);

    return hourReport?.start || hourReport?.late_minutes ? (
        <BusinessSlotHourReportDetails hourReport={hourReport} />
    ) : (
        <BusinessSlotRecruitmentStatus recruitmentStatuses={recruitmentStatuses} />
    );
}
