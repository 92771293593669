import { Grid } from '@material-ui/core';
import React, { ReactNode } from 'react';

import './JobFormPage.css';
import { useJobFormContext } from 'parent-portal/forms/JobFormContext';
import SecondaryButton from 'library/components/buttons/SecondaryButton';
import { Colors, PrimaryButton, Text } from 'library';

interface JobFormPageProps {
    title?: string;
    subtitle?: string;
    children?: ReactNode | ReactNode[];
    onNextClicked: () => void;
    backText?: string;
    nextText?: string;
    nextDisabled: boolean;
    id?: string;
    hideActionButtons?: boolean;
    formErrors?: Array<string>;
    hideBackButton?: boolean;
}

export default function JobFormPage({
    children,
    title,
    subtitle,
    onNextClicked,
    backText = 'Back',
    nextText = 'Next',
    nextDisabled,
    id,
    hideActionButtons = false,
    hideBackButton = false,
}: JobFormPageProps) {
    const { goToPrevious, formErrors } = useJobFormContext();
    return (
        <div id={id} className="job-form-page">
            <div className="job-form-content">
                <Grid container justify="space-between" style={{ flexDirection: 'column', flexGrow: 1 }}>
                    <div style={{ maxWidth: '100%' }}>
                        {title ? <Text variant="display">{title}</Text> : null}
                        {subtitle ? (
                            <Text variant="body1" textStyle={{ marginTop: 10, color: Colors.grey }}>
                                {subtitle}
                            </Text>
                        ) : null}
                        {children}
                    </div>
                    {formErrors ? (
                        <Grid item>
                            {formErrors.map((error, idx) => (
                                <Text variant="body1" key={idx} textStyle={{ color: Colors.red }}>
                                    * {error}
                                </Text>
                            ))}
                        </Grid>
                    ) : null}
                    {hideActionButtons ? null : (
                        <div className="job-form-button-container">
                            {!hideBackButton && (
                                <div className="job-form-button">
                                    <SecondaryButton onClick={goToPrevious}>{backText}</SecondaryButton>
                                </div>
                            )}
                            <div className="next-container">
                                <div className="job-form-button">
                                    <PrimaryButton
                                        id="job-form-next-button"
                                        onClick={onNextClicked}
                                        disabled={nextDisabled}
                                    >
                                        {nextText}
                                    </PrimaryButton>
                                </div>
                            </div>
                        </div>
                    )}
                </Grid>
            </div>
        </div>
    );
}
