import { Grid } from '@mui/material';
import React, { useState } from 'react';
import { IconButton, Text } from 'library';
import { Edit } from '@material-ui/icons';
import EditDetailsModal from '../EditDetailsModal';
import { BusinessJobResponse } from '../models';
import JobDetailOptions from '../JobDetailOptions';
import JobRequirements from '../JobRequirements';

export default function AdditionalDetails({
    businessJobResponse,
    onEditComplete,
}: {
    businessJobResponse: BusinessJobResponse;
    onEditComplete: () => void;
}) {
    const [editDetailsOpen, setEditDetailsOpen] = useState(false);
    const job = businessJobResponse.job_details;
    return (
        <Grid xs={12} md={4} container item direction="column">
            <Grid container item style={{ gap: 10 }} alignContent="flex-start">
                <Grid xs={12} container item direction="row">
                    <Text variant="h1" textStyle={{ width: undefined }}>
                        Additional Details
                    </Text>
                    <IconButton style={{ margin: '0 10px' }} icon={Edit} onClick={() => setEditDetailsOpen(true)} />
                </Grid>
                <JobDetailOptions details={job.details} jobDetailOptions={businessJobResponse.job_detail_options} />
                <JobRequirements
                    qualification_requirements={job.qualification_requirements}
                    jobQualificationOptions={businessJobResponse.qualification_options}
                />
            </Grid>
            <EditDetailsModal
                open={editDetailsOpen}
                onClose={() => setEditDetailsOpen(false)}
                businessJobResponse={businessJobResponse}
                onSaveComplete={onEditComplete}
            />
        </Grid>
    );
}
