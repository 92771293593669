import React from 'react';
import PetsIcon from '@material-ui/icons/Pets';
import FavoriteIcon from '@material-ui/icons/Favorite';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBroom } from '@fortawesome/free-solid-svg-icons';
import { CategoryCard, Colors } from 'library';
import { CategoryType, JobType } from 'models';
import FormField from '../../FormField';

export default function Categories({
    updateJobType,
    jobTypes,
    selectedJobType,
    includeOther = true,
}: {
    updateJobType: (jobType: string) => void;
    jobTypes: JobType[];
    selectedJobType?: JobType;
    includeOther?: boolean;
}) {
    const baseCategories = [
        { text: 'Child Care', icon: FavoriteIcon, info: 'Child Care', id: 'child-care-category' },
        {
            text: 'House Cleaning',
            icon: () => <FontAwesomeIcon icon={faBroom} color={Colors.turquoise} size="2x" />,
            info: 'House Cleaning',
            id: 'house-cleaning-category',
        },
        { text: 'Pet Care', icon: PetsIcon, info: 'Pet Care', id: 'pet-care-category' },
    ];

    const otherCategory = {
        text: 'Other',
        icon: AddCircleIcon,
        info: 'other',
        id: 'other-category',
    };

    const categories = includeOther ? [...baseCategories, otherCategory] : baseCategories;

    function JobCategoryCard({ category }: { category: CategoryType }) {
        let isSelected =
            selectedJobType?.name === category.info ||
            (category.info === 'other' && selectedJobType && !categories.find((x) => x.info === selectedJobType?.name));
        let style = isSelected
            ? { paddingLeft: 0, border: `solid ${Colors.darkNavy}`, borderWidth: 1, minWidth: 100, width: 100 }
            : { paddingLeft: 0, width: 100, minWidth: 100 };
        return (
            <CategoryCard
                onClick={() => updateJobType(category.info)}
                category={category}
                containerStyle={style}
                iconStyle={{ color: Colors.turquoise, marginBottom: category.info === 'House Cleaning' ? 0 : 10 }}
            />
        );
    }

    return (
        <FormField title="What type of job?*">
            <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
                {categories.map((category: CategoryType) => (
                    <JobCategoryCard category={category} />
                ))}
            </div>
        </FormField>
    );
}
