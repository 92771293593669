import React from 'react';
import { SvgIcon } from '@material-ui/core';
import { Colors } from '../../../constants';
import CloseIcon from '@material-ui/icons/Close';

interface CloseButtonProps {
    onClose: () => void;
}

export default function CloseButton({ onClose }: CloseButtonProps) {
    return (
        <div
            style={{
                cursor: 'pointer',
            }}
            onClick={onClose}
        >
            <SvgIcon
                component={CloseIcon}
                viewBox="0 0 26 26"
                style={{
                    color: Colors.darkNavy,
                }}
            />
        </div>
    );
}
