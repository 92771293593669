import { LinearProgress } from '@material-ui/core';
import { Grid } from '@mui/material';
import { Text, TextButton } from 'library';
import React, { useState } from 'react';
import TrialRunFeedbackModal from './TrialRunFeedbackModal';
import { IShiftsByProvider, WeeklyJobFeedback } from './models';

export default function TrialRunInfo({
    ongoingRequestId,
    providerShifts,
    refresh,
    weeklyJobFeedback,
}: {
    ongoingRequestId: number;
    providerShifts: IShiftsByProvider;
    refresh: () => void;
    weeklyJobFeedback: WeeklyJobFeedback[];
}) {
    const [showTrialRunFeedbackModal, setShowTrialRunFeedbackModal] = useState(false);
    const [continueClicked, setContinueClicked] = useState(false);
    const feedbackSubmitted = weeklyJobFeedback.find((feedback) => feedback.worker.id === providerShifts.provider.id);

    function closeModal() {
        setShowTrialRunFeedbackModal(false);
        refresh();
    }

    return (
        <Grid container item direction="row" alignItems="center">
            <Grid container item direction="column" alignItems="center" xs={12} md={6}>
                <LinearProgress
                    variant="determinate"
                    value={Math.min(providerShifts.hours_worked / providerShifts.hours_required, 1) * 100}
                    style={{ height: 10, borderRadius: 18, width: '100%' }}
                />
                <Text variant="caption">
                    {providerShifts.hours_worked.toFixed(2)} hours worked of {providerShifts.hours_required} hour trial
                    run
                </Text>
            </Grid>
            <Grid container item direction="row" alignItems="flex-end" xs={12} md={6}>
                {feedbackSubmitted ? null : (
                    <>
                        <TextButton
                            disabled={!!feedbackSubmitted}
                            onClick={() => {
                                setContinueClicked(true);
                                setShowTrialRunFeedbackModal(true);
                            }}
                        >
                            Continue next week?
                        </TextButton>

                        <TextButton
                            onClick={() => {
                                setContinueClicked(false);
                                setShowTrialRunFeedbackModal(true);
                            }}
                        >
                            Request new provider?
                        </TextButton>
                    </>
                )}
            </Grid>
            <TrialRunFeedbackModal
                ongoingRequestId={ongoingRequestId}
                open={showTrialRunFeedbackModal}
                onClose={closeModal}
                provider={providerShifts.provider}
                initialContinueValue={continueClicked}
            />
        </Grid>
    );
}
