import React from 'react';

import { DraggableDialog } from 'library';
import { getCall } from 'api/CallHistoryApi';

import CallHistoryForm from './CallHistoryForm';

import type { CallHistory, SaveCallResponse } from 'models/CallHistory';

export function CallLogModal({
    open,
    onClose,
    businessLocationId,
    call,
    setCall,
    refresh,
}: {
    open: boolean;
    onClose: () => void;
    businessLocationId: number;
    call?: CallHistory | undefined;
    setCall?: (call: CallHistory) => void;
    refresh?: () => void;
}) {
    function onSave(callResponse: SaveCallResponse) {
        if (setCall) {
            getCall(callResponse.id).then((response) => setCall(response));
        } else if (refresh) {
            refresh();
        }

        onClose();
    }

    return (
        <DraggableDialog isOpen={open} onClose={onClose}>
            <CallHistoryForm
                businessLocationId={businessLocationId}
                callToEdit={call}
                onSave={onSave}
                onCancelEdit={onClose}
            />
        </DraggableDialog>
    );
}
