import React from 'react';
import { Delete } from '@mui/icons-material';
import { IconButton } from 'library';
import { BusinessJobWithAppointments } from 'models';

export default function StopJob({
    job,
    onStopJob,
}: {
    job: BusinessJobWithAppointments;
    onStopJob: (job: BusinessJobWithAppointments) => void;
}) {
    const onClick = () => {
        if (window.confirm('Are you sure you want to stop this job?')) {
            onStopJob(job);
        }
    };

    return (
        <span style={{ marginLeft: 10 }} title="Stop Job">
            <IconButton tooltip="Stop this job" icon={Delete} onClick={onClick} />
        </span>
    );
}
