export const Environment = window.env?.ENVIRONMENT.toLowerCase();
export const ApiBaseUrl = window.env?.REACT_APP_API_BASE_URL;
export const ApiBaseWSUrl = window.env?.REACT_APP_WS_BASE_URL;
export const MixPanelKey = window.env?.REACT_APP_MIXPANEL;
export const SitterMixPanelKey = window.env?.REACT_APP_SITTER_MIXPANEL;
export const RequirePaymentSetupToChat = window.env?.REACT_APP_REQUIRE_PAYMENT_SETUP_TO_CHAT;
export const RequireConnectedJobToChat = window.env?.REACT_APP_REQUIRE_CONNECTED_JOB_TO_CHAT;
export const StripeKey = window.env?.REACT_APP_STRIPE;
export const MarketingSiteUrl = window.env?.REACT_APP_MARKETING_SITE_URL;
export const GoogleTagManager1 = window.env?.REACT_APP_GOOGLE_TAG_MANAGER_GT1;
export const GoogleTagManager2 = window.env?.REACT_APP_GOOGLE_TAG_MANAGER_GT2;
export const GoogleAnalytics = window.env?.REACT_APP_GOOGLE_ANALYTICS;
export const ReactAppUrl = window.env?.REACT_APP_INTERNAL_APP_SHARE_URL;
export const ChatwootUrl = window.env?.REACT_APP_CHATWOOT_URL;
export const ChatwootAccountId = window.env?.REACT_APP_CHATWOOT_ACCOUNT_ID;
