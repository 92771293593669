import React from 'react';
import { withStyles, Paper, Typography, Grid } from '@material-ui/core';
import theme from '../../../../theme';

const StyledPaper = withStyles({
    root: (props) => ({
        background: props.secondary,
        minHeight: props.height,
        width: '100%',
        borderRadius: 20,
        boxShadow: theme.shadows[4],
    }),
})(Paper);

const TitleBar = withStyles({
    root: (props) => ({
        background: props.primary,
        borderTopLeftRadius: 20,
        borderTopRightRadius: 20,
        color: 'white',
        padding: '5px 20px 5px 20px',
        height: 45,
    }),
})(Grid);

export default function Card(props) {
    const { primary, secondary, title, height, action_title, action, style } = props;
    return (
        <StyledPaper secondary={secondary} height={height} style={style}>
            {title && (
                <TitleBar container justify="center" alignItems="center" primary={primary}>
                    <Typography
                        variant="h6"
                        align="center"
                        style={{ fontWeight: 900, color: 'white', margin: '0 auto' }}
                    >
                        {title}
                    </Typography>
                    {action_title && (
                        <Typography
                            onClick={action}
                            variant="subtitle1"
                            style={{
                                fontWeight: 700,
                                color: 'white',
                                float: 'right',
                                textDecorationLine: 'underline',
                                cursor: 'pointer',
                            }}
                        >
                            {action_title}
                        </Typography>
                    )}
                </TitleBar>
            )}
            <Grid container style={props.contentStyle}>
                {props.children}
            </Grid>
        </StyledPaper>
    );
}
