import React from 'react';
import { ArrowBack } from '@mui/icons-material';
import IconButton from 'library/components/buttons/IconButton';
import { useMediaQuery } from '@material-ui/core';
import theme from 'theme';

function OffsetArrowBack(props: any) {
    return <ArrowBack {...props} style={{ marginLeft: 11, ...props.style }} />;
}

interface BackButtonProps {
    onClick: () => void;
    disabled?: boolean;
}

export default function BackButton({ onClick }: BackButtonProps) {
    const small = useMediaQuery(theme.breakpoints.down('sm'));

    return <IconButton size={small ? 45 : 70} icon={OffsetArrowBack} onClick={onClick} />;
}
