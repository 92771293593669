import React from 'react';
import IconButton from './IconButton';
import { Refresh } from '@mui/icons-material';

const styles = {
    adjustIconMargin: { marginLeft: 8, display: 'inline' },
};

interface RefreshIconButtonProps {
    onClick: () => void;
    tooltip?: string;
}

export default function RefreshIconButton({ onClick, tooltip }: RefreshIconButtonProps) {
    return <IconButton tooltip={tooltip} icon={Refresh} onClick={onClick} style={styles.adjustIconMargin} />;
}
