import React from 'react';
import { Colors } from '../theme';

const avatars = [
    require('../../assets/images/avatars/avatar1.png'),
    require('../../assets/images/avatars/avatar2.png'),
    require('../../assets/images/avatars/avatar3.png'),
    require('../../assets/images/avatars/avatar4.png'),
    require('../../assets/images/avatars/avatar5.png'),
];

interface IProps {
    index?: number;
    src?: string;
    style?: React.CSSProperties;
}

const Avatar = ({ index, src, style }: IProps) => {
    function getImage() {
        if (src) {
            return src;
        } else {
            return index ? avatars[Math.floor(index % 4)] : avatars[Math.floor(Math.random() * 4)];
        }
    }

    return src ? (
        <div
            style={{
                ...defaultStyle,
                backgroundImage: `url(${src})`,
                ...style,
            }}
        />
    ) : (
        <img src={getImage()} style={{ ...defaultStyle, ...style }} alt="" />
    );
};

const defaultStyle = {
    borderRadius: '50%',
    overflow: 'hidden',
    backgroundColor: Colors.white,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    flexShrink: 0,
};

export default Avatar;
