import type { SvgIconProps } from '@material-ui/core';
import { Home, Message, AttachMoney, Group } from '@material-ui/icons';
import { faCalendarCheck, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import type { History } from 'history';
import { track } from 'shared';

export interface NavItem {
    name: string;
    IconImg?: (props: SvgIconProps) => JSX.Element;
    SvgIconImg?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
    FontAwesomeImg?: IconDefinition;
    target: string;
    id: string;
}

export function navItems(role: string): Array<NavItem> {
    const isBusiness = role === 'business_active';

    return [
        { name: 'Home', IconImg: Home, target: '/home', id: 'nav-bar-home' },
        { name: 'Payments', IconImg: AttachMoney, target: '/payments', id: 'nav-bar-payments' },
        { name: 'My Jobs', FontAwesomeImg: faCalendarCheck, target: '/jobs', id: 'nav-bar-my-jobs' },
        {
            name: 'My Staff',
            IconImg: Group,
            target: '/providers',
            id: 'nav-bar-providers',
        },
        { name: 'Messages', IconImg: Message, target: '/messages', id: 'nav-bar-messages' },
    ].filter((item: NavItem) => item.id !== 'nav-bar-providers' || isBusiness);
}

export function updateNavigationByIndex(
    history: History,
    index: number,
    setSelectedPage: (selectedPage: number) => void,
    navigationItems: NavItem[],
) {
    return updateNavigation(history, navigationItems[index], setSelectedPage, navigationItems);
}

export function updateNavigation(
    history: History,
    newItem: NavItem,
    setSelectedPage: (selectedPage: number) => void,
    navigationItems: NavItem[],
) {
    setSelectedPage(navigationItems.findIndex((item) => item.name === newItem.name));
    track('Nav button clicked: ' + newItem.name);
    history.push(newItem.target);
    return newItem.name;
}
