import React, { useState } from 'react';
import { Profile } from '../ProviderProfile';
import { Grid, useMediaQuery } from '@material-ui/core';
import PaymentBanner from 'parent-portal/sitters/ProviderPreview/Components/PaymentBanner';
import { ProviderProfileContextProvider, useProviderProfileContext } from '../ProviderProfileContext';
import ApplicantDrawer from './ApplicantDrawer';
import theme from 'theme';
import { useUserContext } from 'UserContext';
import DrawerToggleButton from './DrawerToggleButton';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import { Colors, Text } from 'library';

export default function ApplicantPage() {
    return (
        <ProviderProfileContextProvider>
            <Applicant />
        </ProviderProfileContextProvider>
    );
}

function Applicant() {
    const { job, applicants } = useProviderProfileContext();
    const [drawerOpen, setDrawerOpen] = useState(true);
    const mdDown = useMediaQuery(theme.breakpoints.down('md'));
    const { role } = useUserContext();

    const toggle = () => {
        setDrawerOpen(!drawerOpen);
    };

    return (
        <>
            {mdDown && <DrawerToggleButton open={drawerOpen} toggle={toggle} />}
            <Grid container justify="center" direction="column" style={{ backgroundColor: Colors.lightTurq }}>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <ApplicantDrawer mobile={mdDown} open={drawerOpen} onProviderSelected={toggle} />
                    <div style={{ paddingLeft: mdDown ? 0 : 370, width: '100%', maxWidth: '100vw' }}>
                        {job && role !== 'business_active' ? <PaymentBanner isOneTime={!job.ongoing} /> : null}
                        {applicants.length > 0 ? (
                            <Profile />
                        ) : (
                            <Grid
                                container
                                justify="center"
                                alignItems="center"
                                direction="column"
                                style={{ height: '50vh', backgroundColor: Colors.lightTurq }}
                            >
                                <PersonSearchIcon style={{ color: Colors.darkNavy, height: 200, width: 200 }} />
                                <Text variant="caption">Looks like you haven't gotten any applicants yet.</Text>
                            </Grid>
                        )}
                    </div>
                </div>
            </Grid>
        </>
    );
}
