import React from 'react';
import { Grid, useMediaQuery } from '@mui/material';
import { PrimaryButton, Text } from 'library';
import { useHistory } from 'react-router';
import theme from 'theme';

interface GetStartedCardProps {
    audience: string;
}
interface AudienceText {
    title: string;
    content: string;
    url: string;
}

export default function GetStartedCard({ audience }: GetStartedCardProps) {
    const xsmall = useMediaQuery(theme.breakpoints.down('xs'));
    const history = useHistory();

    const audienceText: { [key: string]: { title: string; content: string; url: string } } = {
        centers: {
            title: 'For Child Care Programs and Centers',
            content: 'Get the coverage you need when you need it. Temporary and permanent workers available.',
            url: '/',
        },
        workers: {
            title: 'For Child Care Workers',
            content: 'Pick up shifts based on your schedule and preferences. Find short term or permanent roles.',
            url: '/workers',
        },
    };
    const titleAndContent = audienceText[audience as keyof AudienceText];

    function handleClick(url: string) {
        return () => (history.location.pathname === url ? window.scrollTo({ left: 0, top: 0 }) : history.push(url));
    }

    if (!titleAndContent) return null;

    return (
        <Grid
            container
            style={{
                backgroundColor: '#FDFDFD',
                padding: xsmall ? '35px 10px 60px 10px' : '50px 50px 60px 50px',
                width: '400px',
                height: '275px',
                borderRadius: 18,
                boxShadow: '0px 3px 6px #00000029',
                margin: '15px 2vw 0px 2vw',
            }}
            alignItems="center"
            justifyContent="center"
            direction="row"
        >
            <Text variant="h1" textStyle={{ textAlign: 'center', height: '35%', fontSize: xsmall ? 18 : 24 }}>
                {titleAndContent.title}
            </Text>
            <Text
                variant="body2"
                textStyle={{
                    textAlign: 'center',
                    margin: '15px 0px 15px 0px',
                    fontSize: 12,
                    height: '35%',
                }}
            >
                {titleAndContent.content}
            </Text>
            <PrimaryButton
                buttonStyle={{ width: '60%', height: '28%', fontSize: xsmall ? 6 : 18 }}
                onClick={handleClick(titleAndContent.url)}
            >
                Get Started
            </PrimaryButton>
        </Grid>
    );
}
