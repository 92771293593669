import React, { useState } from 'react';
import { Grid, useMediaQuery } from '@mui/material';
import { PrimaryButton, Text, SizeableRoundedDialog, UnderlinedTextInput } from 'library';
import theme from 'theme';
import useLoading from 'library/hooks/useLoading';
import { client } from 'shared';
import { ShiftProviderUser } from 'models';

export default function RevealModal({
    open,
    onClose,
    provider,
}: {
    open: boolean;
    onClose: () => void;
    provider: ShiftProviderUser;
}) {
    const small = useMediaQuery(theme.breakpoints.down('sm'));
    const { loading, setLoading } = useLoading();
    const [code, setCode] = useState<string>('');
    const [ssn, setSsn] = useState<string>();
    const [dob, setDob] = useState<string>();
    const [error, setError] = useState<string>();
    function submit() {
        setError(undefined);
        setLoading(true);
        client(`api/business/reveal/`, { method: 'POST', body: { provider: provider.id, code: code } })
            .then((response) => {
                setSsn(response['ssn']);
                setDob(response['dob']);
            })
            .catch((error) => {
                setError(error['error']);
            })
            .finally(() => {
                setLoading(false);
            });
    }
    const title = `Reveal ${provider.first_name} ${provider.last_name}'s SSN?`;
    const body =
        "You will receive a text message with a code to access the provider's SSN. If your number cannot receive texts you will get an email. Please enter the code here. Kindly note that this information is for roster purposes only.";
    return (
        <SizeableRoundedDialog
            open={open}
            onClose={() => {
                setSsn('');
                setCode('');
                setError('');
                onClose();
            }}
            fullScreen={small}
            closeButton
            maxWidth="md"
            style={{ maxWidth: 500 }}
        >
            <Grid container item direction="column" style={{ padding: 20, gap: 10 }}>
                <Text variant="h1">{title}</Text>
                <Text>{body}</Text>
                {!ssn && (
                    <UnderlinedTextInput
                        label="Code"
                        value={code}
                        onChange={(value: string) => {
                            setCode(value);
                        }}
                        error={false}
                        errorText={''}
                    />
                )}
                {ssn ? (
                    <Grid item container direction="column" style={{ gap: 10 }}>
                        {dob ? <Text>DOB: {dob}</Text> : null}
                        <Text>SSN: {ssn}</Text>
                    </Grid>
                ) : (
                    <PrimaryButton
                        disabled={loading}
                        onClick={submit}
                        buttonStyle={{ width: undefined, alignSelf: 'flex-end' }}
                    >
                        Reveal
                    </PrimaryButton>
                )}
                {error && <Text>{error}</Text>}
            </Grid>
        </SizeableRoundedDialog>
    );
}
