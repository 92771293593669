import React, { useState } from 'react';
import { Colors, TextButton } from 'library';
import ProviderDetailsOutreachModal from 'internal/texting/outreach/ProviderDetailsOutreachModal';
import { BusinessJobWithAppointments, LeanUser } from 'models';
import AddProvider from '../AddProvider';
import SendMatch from './SendMatch';

export default function RebookingListMenuItem({
    user,
    job,
    refreshJobs,
    rowNum,
}: {
    user: LeanUser;
    job: BusinessJobWithAppointments;
    refreshJobs: () => void;
    rowNum: number;
}) {
    const [detailsOpen, setDetailsOpen] = useState(false);

    return (
        <div style={{ marginTop: 8 }}>
            <div style={{ background: `${rowNum % 2 ? Colors.lightTurq : 'white'}`, padding: 10 }}>
                <TextButton buttonStyle={{ width: undefined, marginRight: 10 }} onClick={() => setDetailsOpen(true)}>
                    {user.first_name} {user.last_name} ({user.id})
                </TextButton>
                <div style={{ marginTop: 5 }}>
                    <AddProvider job={job} refreshJobs={refreshJobs} initialProviderUserId={user.id} />
                    <SendMatch job={job} initialProviders={[user]} />
                </div>
            </div>
            <ProviderDetailsOutreachModal
                providerUserId={user.id}
                onClose={() => setDetailsOpen(false)}
                open={detailsOpen}
            />
        </div>
    );
}
