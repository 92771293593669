import React from 'react';
import { track } from 'shared';
const DownloadImage = require('assets/images/DownloadAppStore.png');

export default function DownloadOnTheAppStoreButton() {
    function handleOnClick() {
        track('App Store Download Button Clicked');
        window.open('https://apps.apple.com/us/app/tandem-babysitting-jobs/id1491607600', '_blank');
    }
    return (
        <div onClick={handleOnClick} style={{ cursor: 'pointer' }}>
            <img
                src={DownloadImage}
                alt="tandem-logo"
                style={{ maxWidth: 200, maxHeight: 75, margin: 0, padding: 0 }}
            />
        </div>
    );
}
