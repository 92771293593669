import React from 'react';
import { Text } from 'library';

export default function Label({ label }: { label: string }) {
    return (
        <Text variant="caption" textStyle={{ display: 'inline' }}>
            <b>{label}: </b>
        </Text>
    );
}
