import React, { ChangeEvent, useEffect, useState } from 'react';
import { Colors, OutlinedDropdown } from 'library';
import { makeStyles } from '@material-ui/core';
import { useProviderProfileContext } from './ProviderProfileContext';

const useStyles = makeStyles({
    select: {
        '&.MuiInputBase-root': {
            color: Colors.turquoise,
            border: 0,
            outline: 'none',
            minWidth: 216,
            height: 45,
            fontSize: 16,
            fontWeight: 'bold',
            boxShadow: '0px 3px 6px #00000029',
        },
        '& .MuiSelect-icon': { color: Colors.turquoise },
        '& .MuiOutlinedInput-notchedOutline': { border: 0 },
    },
});

export default function ProviderProfileJobTypeSelection() {
    const styles = useStyles();
    const { visibleJobTypes, selectedProfile, setSelectedProfile } = useProviderProfileContext();
    const [profiles, setProfiles] = useState<{ key: string; value: string }[]>([]);

    useEffect(() => {
        setProfiles(
            visibleJobTypes.map((interest) => ({
                key: interest.id.toString(),
                value: `${interest.job_type.name} Profile`,
            })) || [],
        );
    }, [visibleJobTypes]);

    const selectProfile = (e: ChangeEvent<{ name?: string; value: string }>) => {
        let selected = visibleJobTypes.find((interest) => interest.id.toString() === e.target.value);
        setSelectedProfile(selected, 'Job Type Dropdown');
    };

    return (
        <OutlinedDropdown
            className={styles.select}
            fields={profiles}
            value={selectedProfile?.id.toString() || ''}
            onChange={selectProfile}
            displayEmpty
        />
    );
}
