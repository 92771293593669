import { Paper, Text, Colors } from 'library';
import React from 'react';

export default function FamilyLetter() {
    function P(props: any) {
        return <Text textStyle={{ marginBottom: 10 }} {...props} />;
    }

    return (
        <Paper style={{ marginBottom: 30, backgroundColor: Colors.lightTurq }}>
            <P variant="h2" bold>
                Dear Tandem Family,
            </P>
            <P>
                We want to thank you for the opportunity to serve you and your household over the last 3+ years. It has
                been an honor to be entrusted with helping you find care for those you love best, and we are so proud of
                the many relationships between care providers and families that have flourished as a result of
                connections made on Tandem.
            </P>
            <P>
                While we love making connections for families, market forces necessitate that we redirect our focus to
                the business side of our platform. As we transition our product, you will see some changes to the
                functionality available to you. You will still be able to book and pay providers you have worked with in
                the past. However, posting jobs to create new connections between providers and families will no longer
                be available.
            </P>
            <P>
                We are excited to see where this new direction in our product takes us, and we promise to keep you
                updated with any changes that might impact your use of the platform. Thank you for sharing a part of
                this journey with us.
            </P>
            <P>With gratitude,</P>
            <P variant="display" textStyle={{ fontSize: 18 }}>
                The Tandem Team
            </P>
        </Paper>
    );
}
