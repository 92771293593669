import React from 'react';
import { Link } from 'react-router-dom';
import { Grid } from '@mui/material';
import { Colors, Text } from 'library';
import { IDashboardData } from '../Models/DashboardData';
import { useStyles } from './useStyles';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

export default function DisplayPaySetupNotification({ data }: { data: IDashboardData | undefined }) {
    const classes = useStyles();
    const paymentStatus = data?.payment.status;
    if (paymentStatus === 'complete') return null;

    return (
        <Link to="payments">
            <Grid container direction="row" className={classes.paymentContainer}>
                <Grid container item direction="column" justifyContent="space-around" xs={11}>
                    <Text variant="h2" bold={true}>
                        {paymentStatus === 'needs_verified' ? 'Complete Payment Setup' : 'Add a Payment Method'}
                    </Text>
                    <Text variant="body1">
                        {paymentStatus === 'needs_verified'
                            ? 'Verify your account to complete payment setup.'
                            : 'Set up a primary payment method on your account.'}
                    </Text>
                </Grid>

                <Grid container item xs={1} alignItems="center">
                    <ChevronRightIcon htmlColor={Colors.darkNavy} fontSize="large" />
                </Grid>
            </Grid>
        </Link>
    );
}
