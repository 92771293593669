import { Grid, Rating } from '@mui/material';
import React from 'react';
import { Text } from 'library';

export default function ReviewScore({ score }: { score: { [key: string]: number } }) {
    const option = Object.keys(score)[0];

    return (
        <Grid container item>
            <Grid item container xs={5}>
                <Text bold textStyle={{ textTransform: 'capitalize' }}>
                    {option}
                </Text>
            </Grid>
            <Rating value={score[option]} readOnly />
        </Grid>
    );
}
