import React, { useEffect, useState } from 'react';
import { states } from './states';
import { Grid } from '@material-ui/core';
import { isValidFormInputString, isValidZipCode } from 'shared';
import { IAddressBody } from 'api/UserApi';
import { UnderlinedTextInput, OutlinedDropdown, Text, Colors } from 'library';

export default function AddressInputs({
    setValid,
    setAddressPatchBody,
}: {
    setValid: (valid: boolean) => void;
    setAddressPatchBody: (body: IAddressBody) => void;
}) {
    const [street, setStreet] = useState<string>('');
    const [city, setCity] = useState<string>('');
    const [zip, setZip] = useState<string>('');
    const [state, setState] = useState<string>('Select one');

    useEffect(() => {
        const valid =
            isValidFormInputString(street ?? '') &&
            state !== 'Select one' &&
            isValidFormInputString(city ?? '') &&
            isValidZipCode(zip ?? '') &&
            isValidFormInputString(state ?? '');
        if (street && city && zip && state) {
            setAddressPatchBody({
                street: street,
                state: state,
                city: city,
                zip_code: zip,
            });
        }
        setValid(valid);
    }, [street, city, zip, state, setValid]);

    const stateOptions = [
        { key: 'Select one', value: 'Select one' },
        ...states
            .filter((x) => x)
            .map((x) => {
                return { key: x, value: x };
            }),
    ];

    return (
        <Grid container spacing={3} style={{ padding: 10 }}>
            <Grid item xs={12}>
                <UnderlinedTextInput
                    error={street !== '' && !isValidFormInputString(street)}
                    errorText={
                        street !== '' && !isValidFormInputString(street) ? 'Please enter your street address' : ''
                    }
                    id="street"
                    label="Center Street Address*"
                    value={street}
                    onChange={(value) => setStreet(value)}
                    autoComplete="street-address"
                />
            </Grid>
            <Grid container item xs={12} justify="space-between">
                <Grid item xs={8}>
                    <UnderlinedTextInput
                        error={city !== '' && !isValidFormInputString(city)}
                        errorText={city !== '' && !isValidFormInputString(city) ? 'Please enter your city' : ''}
                        id="city"
                        label="City*"
                        value={city}
                        onChange={(value) => setCity(value)}
                        autoComplete="address-level2"
                    />
                </Grid>
                <Grid item xs={3}>
                    <UnderlinedTextInput
                        error={zip !== '' && !isValidZipCode(zip)}
                        errorText={zip !== '' && !isValidZipCode(zip) ? 'Please enter your zip code' : ''}
                        id="zip"
                        label="Zip*"
                        value={zip}
                        onChange={(value) => setZip(value)}
                        autoComplete="postal-code"
                    />
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Text variant="body2" color={Colors.darkNavy} textStyle={{ marginRight: 10 }}>
                    State*
                </Text>
                <OutlinedDropdown
                    id="state"
                    required
                    error={state !== '' && !isValidFormInputString(state)}
                    value={state}
                    onChange={(event: any) => setState(event?.target.value)}
                    fields={stateOptions}
                    autoComplete="address-level1"
                />
            </Grid>
        </Grid>
    );
}
