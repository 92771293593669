import React from 'react';
import { OutlinedTextInput } from 'library';
import { useFormStyles } from '../../constants';
import { WorkerReferenceForm, ErrorState } from '../../models';
import { useMediaQuery } from '@mui/material';
import theme from 'theme';

export default function TextInputField({
    fieldName,
    fieldValue,
    form,
    errorState,
    placeholder,
    handleOnChange,
}: {
    fieldName: string;
    fieldValue: string | undefined;
    form: WorkerReferenceForm;
    errorState: ErrorState;
    placeholder: string;
    handleOnChange: (formArg: WorkerReferenceForm, key: string, value: string | number | undefined) => void;
}) {
    const styles = useFormStyles();
    const xsDown = useMediaQuery(theme.breakpoints.down('xs'));
    const fieldInputStyle =
        fieldName === 'relationship_free_input'
            ? { ...styles.fieldInput, width: xsDown ? '100%' : '250px' }
            : styles.fieldInput;

    return (
        <OutlinedTextInput
            value={fieldValue}
            inputProps={{
                style: errorState[fieldName as keyof ErrorState].inErrorState
                    ? styles.inputWhiteBackgroundError
                    : styles.inputWhiteBackground,
            }}
            style={fieldInputStyle}
            placeholder={placeholder}
            onChange={(e) => handleOnChange(form, fieldName, e.target.value)}
            type="text"
        />
    );
}
