import { CircularProgress } from '@material-ui/core';
import React from 'react';

export default function LoadingInstantVerificationPage() {
    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <CircularProgress size={100} />
        </div>
    );
}
