import React, { useEffect, useState } from 'react';
import { CheckboxSelect, Link, LoadingIndicator, Text } from 'library';
import { styled } from '@mui/material';
import { ProviderSelection } from 'parent-portal/forms/JobRequestTypes';
import { PreferredWorkerDTO, getPreferredWorkers } from './preferredWorkersApi';
import { useJobFormContext } from 'parent-portal/forms/JobFormContext';
import { workerStatusStyle } from './utilities';
import type { ExtendedProviderSelection } from './utilities';

interface Props {
    selected: ProviderSelection[];
    onChange: (selectedProviderUserIds: ProviderSelection[]) => void;
    addresses?: number[];
    existingMatches?: ProviderSelection[];
    multiple?: boolean;
    clearOtherInput?: () => void;
}

export default function PreferredWorkersInput({
    selected,
    onChange,
    addresses,
    existingMatches,
    multiple = true,
    clearOtherInput,
}: Props) {
    const [preferredWorkers, setPreferredWorkers] = useState<PreferredWorkerDTO[]>([]);
    const [page, setPage] = useState(1);
    const [hasNextPage, setHasNextPage] = useState(false);
    const [loading, setLoading] = useState(false);

    const { form } = useJobFormContext();

    const additionalSelectedOptions = selected.filter(
        (selected) => !preferredWorkers.some((preferred) => preferred.user.id === selected.id),
    );
    const options: ExtendedProviderSelection[] = [
        ...additionalSelectedOptions,
        ...preferredWorkers.map((worker) => ({
            id: worker.user.id,
            name: `${worker.user.first_name} ${worker.user.last_name}`,
        })),
    ].map((worker) => {
        const existingMatch = preferredWorkers?.find((preferredWorker) => preferredWorker.user.id === worker.id);
        return {
            ...worker,
            status: existingMatch?.status,
            sanitizedStatus: existingMatch?.status ? `(${existingMatch.status.replaceAll('_', ' ').capitalize()})` : '',
        };
    });

    const jobTimes = {
        start: form.fieldStates.startDate.value,
        end: form.fieldStates.endDate.value,
        days_of_week: form.fieldStates.daysOfTheWeek.value,
    };

    useEffect(() => {
        setLoading(true);
        getPreferredWorkers(page, addresses, jobTimes)
            .then((response) => {
                setHasNextPage(page < response.num_pages);
                if (page === 1) {
                    setPreferredWorkers(response.results);
                } else {
                    setPreferredWorkers((preferredWorkers) => [...preferredWorkers, ...response.results]);
                }
            })
            .finally(() => setLoading(false));
    }, [page, jobTimes.days_of_week, jobTimes.start, jobTimes.end]);

    function onCheckboxChange(checkedList: string[]) {
        if (!multiple && checkedList.length > 0) clearOtherInput?.();

        if (checkedList.length === 0) {
            onChange([]);
        } else {
            if (multiple) {
                const values = checkedList.map((it) => it);
                onChange(options.filter((it) => values.includes(it.name)));
            } else {
                if (checkedList.length > 0) {
                    const choice = options.find((it) => it.name === checkedList.at(-1));
                    onChange(choice ? [choice] : []);
                } else {
                    onChange([]);
                }
            }
        }
    }

    const renderContent = () => {
        if (loading && preferredWorkers.length === 0) {
            return <LoadingIndicator />;
        }
        if (preferredWorkers.length === 0) {
            return <Text>It looks like you have not set any preferred workers.</Text>;
        }

        const namedOptions = options.map((it) => it.name);
        const statusTexts = options.map((it) => ({ [it.name]: it.sanitizedStatus }));
        const statusStyles = options.map((it) => ({ [it.name]: workerStatusStyle(it.status) }));
        const checkboxSelectValue = [
            ...selected.map((it) => it.name),
            ...(existingMatches?.map((it) => it.name) ?? []),
        ];

        return (
            <CheckboxSelect
                options={namedOptions}
                additionalOptionText={statusTexts}
                additionalOptionStyles={statusStyles}
                value={checkboxSelectValue}
                isDisabled={(option: string) =>
                    !!existingMatches?.some((match) => match.name === option) ||
                    options.find((it) => it.name === option)?.status === 'out_of_compliance'
                }
                onChange={onCheckboxChange}
            />
        );
    };

    return (
        <>
            <Spacer />
            <Text variant="body1" bold>
                Preferred Workers
            </Text>
            {renderContent()}
            {!loading && hasNextPage && <Link onClick={() => setPage((page) => page + 1)}>load more</Link>}
        </>
    );
}

const Spacer = styled('div')({
    marginTop: 15,
});
