import { PagingResult } from 'models';
import { CallHistory, CallStatus } from 'models/CallHistory';
import { client } from 'shared';

export function listCalls(
    businessLocationId: number,
    pageNumber: number = 1,
    includeRelated: boolean = false,
): Promise<PagingResult<CallHistory>> {
    return client(
        `internal/call-history/?business_location=${businessLocationId}&page=${pageNumber}&page_size=10&include_related=${includeRelated}`,
    );
}

export function listOptions(): Promise<CallStatus[]> {
    return client(`internal/call-history/options/`);
}

export function getCall(callHistoryId: number): Promise<CallHistory> {
    return client(`internal/call-history/${callHistoryId}/`);
}

export function saveCallHistory(
    businessLocationId: number,
    call_time: string,
    duration: number,
    isInbound: boolean,
    callStatus: number,
    note: string,
    adminUser?: number,
    user?: number,
    callistoryId?: number,
) {
    return client(callistoryId ? `internal/call-history/${callistoryId}/` : 'internal/call-history/', {
        body: {
            business_location: businessLocationId,
            duration,
            call_time,
            call_to_user: isInbound ? adminUser : user,
            call_from_user: isInbound ? user : adminUser,
            is_inbound: isInbound,
            call_status: callStatus,
            note: note ?? '',
        },
        method: callistoryId ? 'PATCH' : 'POST',
    });
}

export function deleteCallHistory(callistoryId?: number) {
    return client(`internal/call-history/${callistoryId}/`, {
        method: 'DELETE',
    });
}
