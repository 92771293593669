import React from 'react';
import { Text, Colors } from 'library';
import Quotes from 'assets/images/on-demand/quotes.png';
import Julia from 'assets/images/on-demand/julia.png';

export default function QuoteSection({ smDown }: { smDown: boolean }) {
    const styles = {
        turqCard: {
            backgroundColor: Colors.lightTurq,
            borderRadius: smDown ? 0 : 20,
            marginTop: 30,
            marginBottom: 50,
            padding: 40,
            maxWidth: smDown ? '100%' : '60%',
        },
        contents: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: smDown ? 'center' : 'flex-start',
            flexDirection: smDown ? 'column' : 'row',
        },
        iconContainer: {
            padding: 30,
            paddingBottom: 20,
            display: 'flex',
            alignSelf: smDown ? 'center' : 'flex-start',
        },
        container: {
            padding: 30,
        },
        quote: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginTop: 30,
        },
    } as { [key: string]: React.CSSProperties };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Text textStyle={{ margin: 10 }} variant="display">
                We know you’ll love Tandem, too.
            </Text>
            <div style={styles.turqCard}>
                <div style={styles.contents}>
                    <div style={styles.iconContainer}>
                        <img src={Quotes} alt="quote" width={50} height="auto" />
                    </div>
                    <div style={styles.container}>
                        <Text>
                            I will say – trying to hire and fill positions, whether its for a sub or for something more
                            permanent, is <b>SO MUCH BETTER</b> through Tandem. The quality of people who work for you
                            all is by far superior to everywhere else we have used.
                        </Text>
                        <div style={styles.quote}>
                            <div style={{ marginRight: 30 }}>
                                <img src={Julia} alt="Julia Yontz" width={75} height="auto" />
                            </div>
                            <div>
                                <Text bold>Julia Yontz</Text>
                                <Text bold>Goddard School of Westerville</Text>
                                <Text>Westerville, OH</Text>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
