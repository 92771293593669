import React, { useState } from 'react';
import { VisibilityOff } from '@mui/icons-material';
import { Grid } from '@mui/material';
import {
    BasicDialog,
    Colors,
    IconButton,
    OutlinedDropdown,
    OutlinedTextInput,
    PrimaryButton,
    SecondaryButton,
    Text,
} from 'library';
import { saveSnooze } from 'api/AdminBusinessLocationApi';
import { Controller, useForm } from 'react-hook-form';
import { toISOLocal } from 'shared/Dates';
import { consoleLogInDev } from 'shared';
import { BusinessLocationSnoozeReason } from 'models/BusinessLocationSnoozeReason';
import { getSnoozeReasons } from 'api/BusinessLocationStatusApi';

export interface SnoozeFormValues {
    snooze_until: string | null;
    snooze_note_text?: string;
    snooze_reason: number | null;
}

export default function SnoozeButton({
    businessLocationId,
    snoozeUntil,
    snoozeReason,
    note,
    onSnooze,
}: {
    businessLocationId: number;
    snoozeUntil?: string;
    snoozeReason?: number;
    note: string;
    onSnooze: () => void;
}) {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [reasons, setReasons] = useState<BusinessLocationSnoozeReason[]>([]);

    const {
        control,
        register,
        handleSubmit,
        formState: { errors },
        trigger,
        setError,
    } = useForm<SnoozeFormValues>({
        defaultValues: {
            snooze_until: snoozeUntil ? toISOLocal(new Date(snoozeUntil)) : '',
            snooze_reason: snoozeReason || null,
            snooze_note_text: note || '',
        },
    });

    function onOpenClick() {
        if (!reasons.length) {
            getSnoozeReasons()
                .then((response) => {
                    setReasons(response.results);
                })
                .catch(consoleLogInDev);
        }
        setIsModalOpen(true);
    }

    async function onSaveSnooze(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();

        const valid = await trigger();
        if (!valid) {
            if (errors.snooze_until) {
                setError('snooze_until', { type: 'validate', message: 'Date must be in the future' });
            }
            return;
        }

        handleSubmit(async (data: SnoozeFormValues) => {
            saveSnooze(businessLocationId, data.snooze_until, data.snooze_reason, data.snooze_note_text)
                .then(() => {
                    onSnooze();
                    setIsModalOpen(false);
                })
                .catch(consoleLogInDev);
        })();
    }

    function validDate(value: string | null) {
        return !value || new Date(value) > new Date();
    }

    function onCancel() {
        setIsModalOpen(false);
    }

    const reasonOptions = reasons.map((x) => {
        return { key: x.id, value: x.name };
    });

    return (
        <span style={{ marginLeft: 10 }}>
            <IconButton tooltip="Hide this center" icon={VisibilityOff} onClick={onOpenClick} />
            <BasicDialog isOpen={isModalOpen} onClose={onCancel}>
                <Text variant="h2" bold>
                    Hide Location
                </Text>
                <form onSubmit={onSaveSnooze}>
                    <Grid container spacing={2}>
                        <Grid item>
                            <OutlinedTextInput
                                label="Hide Until"
                                type="datetime-local"
                                InputLabelProps={{ shrink: true }}
                                {...register('snooze_until', { validate: validDate })}
                            />
                            {errors.snooze_until && <Text color={Colors.mediumRed}>{errors.snooze_until.message}</Text>}
                        </Grid>
                        <Grid item>
                            <Controller
                                name="snooze_reason"
                                control={control}
                                rules={{ required: true }}
                                render={({ field }) => (
                                    <OutlinedDropdown label="Reason" {...field} fields={reasonOptions} />
                                )}
                            />
                        </Grid>
                        <Grid item>
                            <OutlinedTextInput label="Note" {...register('snooze_note_text')} />
                        </Grid>

                        <Grid container item justifyContent="space-around">
                            <SecondaryButton onClick={() => setIsModalOpen(false)} buttonStyle={{ width: 200 }}>
                                Cancel
                            </SecondaryButton>
                            <PrimaryButton type="submit" onClick={() => {}} buttonStyle={{ width: 200 }}>
                                Submit
                            </PrimaryButton>
                        </Grid>
                    </Grid>
                </form>
            </BasicDialog>
        </span>
    );
}
