import React, { forwardRef, Ref } from 'react';

import { Grid } from '@material-ui/core';
import { Text, Colors } from 'library';
import { useUserContext } from 'UserContext';

export default forwardRef(function NextSteps(props, ref: Ref<HTMLDivElement>) {
    const { role } = useUserContext();
    return (
        <Grid ref={ref} container direction="column" style={{ paddingLeft: 20, paddingRight: 20, paddingTop: 20 }}>
            <ul style={{ margin: 0, paddingLeft: 10 }}>
                <li style={{ color: Colors.darkNavy, marginLeft: 0 }}>
                    <Text variant="body2" textStyle={{ marginBottom: 10 }}>
                        {role !== 'business_active'
                            ? 'Check your email for updates on your job.'
                            : 'Check your email for updates on filling your job.'}
                    </Text>
                </li>
                <li style={{ color: Colors.darkNavy, marginLeft: 0 }}>
                    <Text variant="body2" textStyle={{ marginBottom: 10 }}>
                        {role !== 'business_active'
                            ? 'Go to My Jobs to review the status of your job.'
                            : 'Go to My Jobs to view your confirmed shifts for the upcoming weeks.'}
                    </Text>
                </li>
            </ul>
        </Grid>
    );
});
