import React from 'react';
import { AdminProvider } from 'models';
import { Grid, Popper } from '@mui/material';
import { Colors, Text } from 'library';
import { Gauge } from '@mui/x-charts';
import { Info } from '@mui/icons-material';

export default function DependabilityMetric({ provider }: { provider: AdminProvider }) {
    const [dropdownAnchor, setDropdownAnchor] = React.useState<HTMLElement | null>(null);
    const [tooltipVisible, setTooltipVisible] = React.useState(false);
    const value = provider.dependability_metric.total_score;
    return (
        <>
            <Grid container direction="column" alignItems="center">
                <Text bold>
                    Dependability Metric{' '}
                    <Info titleAccess="A score from 0 to 100 that reflects many factors about a worker's history. Scores close to 50 are okay while lower numbers represent more strongly negative experiences and higher numbers are strongly positive experiences. A score of 0 indicates no history. Hover over the score to see some raw counts that factor into the score." />
                </Text>
                <Grid
                    ref={() => dropdownAnchor}
                    component="div"
                    onMouseEnter={(e) => {
                        setDropdownAnchor(e.currentTarget);
                        setTooltipVisible(true);
                    }}
                    onMouseLeave={() => {
                        setDropdownAnchor(null);
                        setTooltipVisible(false);
                    }}
                >
                    <Gauge
                        value={value}
                        width={100}
                        height={100}
                        startAngle={-100}
                        endAngle={100}
                        valueMin={0}
                        valueMax={100}
                        margin={{ top: -10, right: 0, bottom: 0, left: 0 }}
                        innerRadius={24}
                        sx={{ '& .MuiGauge-valueArc': { fill: value < 50 ? Colors.mediumRed : Colors.turquoise } }}
                    />
                </Grid>
            </Grid>
            <Popper
                open={tooltipVisible}
                anchorEl={dropdownAnchor}
                style={{ backgroundColor: Colors.white, padding: 10, border: 'solid black 1px', zIndex: 1 }}
                disablePortal
            >
                <Text variant="body2" bold>
                    From the 90 days prior to the last worked shift:
                </Text>
                {Object.entries(provider.dependability_metric.raw_scores.counts).map(([name, value]) => (
                    <Text variant="body2" key={name}>
                        {name}: {value}
                    </Text>
                ))}
            </Popper>
        </>
    );
}
