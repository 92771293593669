import React, { useState, useEffect } from 'react';
import { Grid, Typography, useMediaQuery } from '@material-ui/core';
import { MuiPickersUtilsProvider, DateTimePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/moment';
import moment from 'moment';
import PairingList from './PairingList';
import { calculatePay } from '../../../shared/Utils';
import { client } from 'shared';
import theme from '../../../theme';
import Link from '../../../reusableComponents/link';
import DialogWithHeader from '../../../reusableComponents/Dialog/dialogWithHeader';
import LoadingButton from '../../../reusableComponents/loadingButton';
import PaySitter from './PaySitter';
import PaySectionHeader from './PaySectionHeader';
import { Colors, Text } from 'library';
import { Environment } from 'environmentVariables';

const pay = require('../../../assets/icons/pay.png');

const STATUSES = {
    neutral: 'NEUTRAL',
    loading: 'LOADING',
    submitting: 'SUBMITTING',
};

const DefaultPaymentError = "Payment failed. Verify you weren't charged before trying again.";

const sortByDate = (x, y) => {
    const xStartDate = new Date(x.appointment.start_date);
    const yStartDate = new Date(y.appointment.start_date);

    if (xStartDate < yStartDate) {
        return -1;
    }

    if (xStartDate > yStartDate) {
        return 1;
    }

    return 0;
};

const UnpaidAppointments = (props) => {
    const [pageStatus, setPageStatus] = useState(STATUSES.neutral);
    const [pairings, setPairings] = useState([]);
    const [requests, setRequests] = useState([]);
    const [pairingToEdit, setPairingToEdit] = useState();
    const [pairingToCancel, setPairingToCancel] = useState();
    const [discount, setDiscount] = useState(0);
    const [open, setOpen] = useState(false);
    const [showGroupPay, setShowGroupPay] = useState(false);
    const [paymentErrorMessage, setPaymentErrorMessage] = useState();
    const [errorCount, setErrorCount] = useState(0);
    const [displaySurchargeDialog, setDisplaySurchargeDialog] = useState(false);
    const [unusedDiscounts, setUnusedDiscounts] = useState();

    const { unpaidJobsCheck, lastParentUpdate } = props;

    const xsOnly = useMediaQuery(theme.breakpoints.only('xs'));

    useEffect(() => {
        getAppointmentsAndDiscount();
    }, [lastParentUpdate]);

    const getAppointmentsAndDiscount = () => {
        getAppointments();
        getUnusedDiscounts();
    };

    const getAppointments = async () => {
        setPageStatus(STATUSES.loading);
        try {
            const apiPairings = await client('api/get-unpaid-appointments/');

            const allPairings = [
                ...setupGroupPay(apiPairings.custom_payments, apiPairings.pay_requests),
                ...setupDailyPay(apiPairings.daily_payments, apiPairings.pay_requests),
            ];
            const allRequests = [
                ...apiPairings.pay_requests.map((x) => {
                    x.itemType = x.request_type;
                    return x;
                }),
            ];
            setPairings(allPairings);
            setRequests(allRequests);

            if (unpaidJobsCheck) {
                unpaidJobsCheck(allPairings.length + allRequests.length);
            }
        } catch (error) {
            if (Environment !== 'production') {
                console.log(error);
            }
            return;
        } finally {
            setPageStatus(STATUSES.neutral);
        }
    };

    const setupDailyPay = (dailyPays, providerRequests) => {
        const requestDetails = providerRequests.reduce(
            (acc, currVal) => acc.concat(currVal.provider_request_details),
            [],
        );
        return dailyPays
            .map((x) => {
                const detail = requestDetails.find((y) => y.pairing === x.id);
                x.itemType = 'DAILY';
                x.detail = detail;
                return x;
            })
            .sort(sortByDate);
    };

    const setupGroupPay = (groupPays, providerRequests) => {
        const requestDetails = providerRequests.reduce(
            (acc, currVal) => acc.concat(currVal.provider_request_details),
            [],
        );

        return groupPays.map((groupPay) => {
            let providers = [];

            for (let i = 0; i < groupPay.pairings.length; i++) {
                const pairing = groupPay.pairings[i];
                const provider = providers.find((x) => x.userId === pairing.babysitter.user.id);

                let end_date = pairing.appointment.end_date;
                let start_date = pairing.appointment.start_date;
                const confirmDetails = requestDetails.find((y) => y.pairing === pairing.id);
                if (confirmDetails) {
                    end_date = confirmDetails.end_date;
                    start_date = confirmDetails.start_date;
                    pairing.appointment.end_date = confirmDetails.end_date;
                    pairing.appointment.start_date = confirmDetails.start_date;
                    pairing.confirmed = true;
                }

                if (provider) {
                    provider.amount += calculatePay(new Date(end_date), new Date(start_date), pairing.appointment.pay);
                    provider.pairings.push(pairing);
                } else {
                    providers.push({
                        userId: pairing.babysitter.user.id,
                        babysitterId: pairing.babysitter.id,
                        amount: calculatePay(new Date(end_date), new Date(start_date), pairing.appointment.pay),
                        tip: 0,
                        pairings: [pairing],
                        name: `${pairing.babysitter.user.first_name} ${pairing.babysitter.user.last_name}`,
                        ongoingRequestId: pairing.appointment.ongoing_request.id,
                    });
                }
            }

            groupPay.payInfo = providers;
            groupPay.itemType = 'GROUP';

            return groupPay;
        });
    };

    const getUnusedDiscounts = async () => {
        setPageStatus(STATUSES.loading);
        try {
            const response = await client(`payment/api/discount/unused/`);
            setUnusedDiscounts(response);
        } catch (error) {
            if (Environment !== 'production') {
                console.log(error);
            }
            return;
        } finally {
            setPageStatus(STATUSES.neutral);
        }
    };

    const calculateDiscount = (payAmount) => {
        if (unusedDiscounts) {
            let discountAmount = 0;

            if (unusedDiscounts.percent) {
                discountAmount = (payAmount * unusedDiscounts.percent.value) / 100;
                if (unusedDiscounts.percent.max_amount && discountAmount > unusedDiscounts.percent.max_amount) {
                    discountAmount = unusedDiscounts.percent.max_amount;
                }
            }

            if (unusedDiscounts.fixed) {
                for (let i in unusedDiscounts.fixed) {
                    if (discountAmount + unusedDiscounts.fixed[i] <= payAmount) {
                        discountAmount = discountAmount + unusedDiscounts.fixed[i];
                    }
                }
            }

            setDiscount(discountAmount);
        }
    };

    const onCloseModal = () => {
        setShowGroupPay(false);
        setPaymentErrorMessage('');
        setErrorCount(0);
    };

    const patchAppointment = async (appointment) => {
        const patchObject = {
            start_date: moment(appointment.start_date).format('YYYY-MM-DD HH:mm:ss'),
            end_date: moment(appointment.end_date).format('YYYY-MM-DD HH:mm:ss'),
        };

        setPageStatus(STATUSES.submitting);
        client(`api/appointments/${appointment.id}/`, {
            method: 'PATCH',
            body: patchObject,
        }).then(() => {
            setPairingToEdit();
            setPageStatus(STATUSES.neutral);
            setPairings((currState) => {
                return currState.map((x) => {
                    if (!x.appointment) {
                        return x;
                    }

                    if (x.appointment.id === appointment.id) {
                        return {
                            ...x,
                            appointment: {
                                ...x.appointment,
                                start_date: appointment.start_date,
                                end_date: appointment.end_date,
                            },
                        };
                    }

                    return x;
                });
            });
        });
    };

    const onSubmitPayment = (item) => {
        if (item.itemType === 'REIMBURSEMENT' || item.itemType === 'JOB') {
            acceptPayRequest(item);
        } else if (item.itemType === 'GROUP') {
            payGroupPay(item);
        } else {
            payPairing(item);
        }
    };

    const acceptPayRequest = (item) => {
        if (pageStatus === STATUSES.submitting) {
            return;
        }

        onPay(`payment/api/provider-request/${item.id}/accept/`, null, 'Unpaid Appointments: Accept provider request', {
            method: 'POST',
        });
    };

    const payPairing = async (pairing) => {
        if (pageStatus === STATUSES.submitting) {
            return;
        }

        if (!pairing.payInfo.amount) {
            setPaymentErrorMessage('Enter a payment amount');
            return;
        }

        const requestObject = {
            total_pay: pairing.payInfo.amount + pairing.payInfo.tip + pairing.payInfo.reimbursement,
            appointment_tip_amount: pairing.payInfo.tip,
            appointment_net_amount: pairing.payInfo.amount,
            appointment_reimbursement_amount: pairing.payInfo.reimbursement,
        };

        onPay(`pay-sitter/${pairing.id}`, requestObject, 'Unpaid Appointments: Pay Appointment');
    };

    const payGroupPay = (pairing) => {
        if (pageStatus === STATUSES.submitting) {
            return;
        }

        const requestObject = {
            payments: pairing.payInfo.map((x) => {
                return {
                    user_id: x.userId,
                    pairings: x.pairings.map((p) => p.id),
                    amount: x.amount,
                    tip: x.tip,
                };
            }),
        };

        onPay(
            `api/grouped-pay-sitter/${pairing.payInfo[0].ongoingRequestId}`,
            requestObject,
            'Unpaid Appointments: Group Pay',
        );
    };

    const onPay = (url, requestObject, mixPanelTag, requestConfig) => {
        setPageStatus(STATUSES.submitting);
        setPaymentErrorMessage('');

        client(url, {
            body: requestObject,
            ...requestConfig,
        })
            .then(() => {
                getAppointmentsAndDiscount();
                onCloseModal();
                props.setReferralOpen(true);

                if (props.onPaymentComplete) {
                    props.onPaymentComplete();
                }
            })
            .catch((error) => {
                setPaymentErrorMessage(error.message || DefaultPaymentError);
                setErrorCount((prevValue) => prevValue + 1);

                if (Environment !== 'production') {
                    console.log(error);
                }
            })
            .finally(() => {
                setPageStatus(STATUSES.neutral);
            });
    };

    const getAppointmentPay = (item) => {
        const { appointment } = item;
        if (appointment.ongoing_request.pay_is_fixed) {
            return appointment.pay;
        }

        if (item.detail) {
            return calculatePay(new Date(item.detail.end_date), new Date(item.detail.start_date), appointment.pay);
        }

        return calculatePay(new Date(appointment.end_date), new Date(appointment.start_date), appointment.pay);
    };

    const getPayInfo = (item) => {
        if (item.itemType === 'REIMBURSEMENT' || item.itemType === 'JOB') {
            return;
        }

        return item.itemType === 'GROUP'
            ? item.payInfo
            : {
                  amount: getAppointmentPay(item),
                  tip: 0,
                  reimbursement: 0,
              };
    };

    const onPayClick = (e, pairing, el, is_pairing = true) => {
        const payInfo = getPayInfo(pairing);
        if (!pairing.anchor && payInfo && payInfo.amount) {
            calculateDiscount(payInfo.amount);
        }
        if (payInfo && payInfo.length && payInfo[0].amount) {
            calculateDiscount(payInfo[0].amount);
        }

        if (is_pairing) {
            setPairings((currState) => {
                return currState.map((x) => {
                    if (x.id !== pairing.id) {
                        return {
                            ...x,
                            anchor: null,
                            payInfo: x.itemType !== 'GROUP' ? {} : x.payInfo,
                        };
                    }

                    return {
                        ...x,
                        anchor: !x.anchor ? el.current : null,
                        payInfo: payInfo,
                    };
                });
            });
        } else {
            setRequests((currState) => {
                return currState.map((x) => {
                    if (x.id !== pairing.id) {
                        return {
                            ...x,
                            anchor: null,
                            payInfo: x.itemType !== 'GROUP' ? {} : x.payInfo,
                        };
                    }

                    return {
                        ...x,
                        anchor: !x.anchor ? el.current : null,
                        payInfo: payInfo,
                    };
                });
            });
        }
        setPaymentErrorMessage('');
    };

    const onPayValueChange = (pairing, key, value, userId) => {
        setPairings((currState) => {
            return currState.map((x) => {
                if (x.id !== pairing.id) {
                    return x;
                }
                if (pairing.itemType !== 'GROUP') {
                    calculateDiscount(x.payInfo.amount);
                    return {
                        ...x,
                        payInfo: {
                            ...x.payInfo,
                            [key]: value,
                        },
                    };
                } else {
                    calculateDiscount(x.payInfo[0].amount);
                    return {
                        ...x,
                        payInfo: x.payInfo.map((y) => {
                            if (y.userId !== userId) {
                                return y;
                            }

                            return {
                                ...y,
                                [key]: value,
                            };
                        }),
                    };
                }
            });
        });
    };

    const onToggleTooltip = (pairing, userId) => {
        setPairings((currState) => {
            return currState.map((x) => {
                if (x.id !== pairing.id) {
                    return x;
                }

                return {
                    ...x,
                    payInfo: x.payInfo.map((y) => {
                        if (y.userId !== userId) {
                            return { ...y, isTooltipOpen: false };
                        }

                        return {
                            ...y,
                            isTooltipOpen: !y.isTooltipOpen,
                        };
                    }),
                };
            });
        });
    };

    const getEditBody = () => {
        if (!pairingToEdit) {
            return;
        }

        return (
            <EditTimes
                loading={pageStatus === STATUSES.submitting}
                appointment={pairingToEdit}
                onValueChange={onAppointmentEdit}
                onSubmit={onEditSubmit}
            />
        );
    };

    const onAppointmentEdit = (key, value) => {
        setPairingToEdit((currentValue) => {
            return {
                ...currentValue,
                [key]: value,
            };
        });
    };

    const onEditClick = (pairing) => {
        setPairingToEdit({
            id: pairing.appointment.id,
            start_date: pairing.appointment.start_date,
            end_date: pairing.appointment.end_date,
        });
    };

    const onEditSubmit = () => {
        patchAppointment(pairingToEdit);
    };

    const getCancelBody = () => {
        return (
            <Grid
                container
                direction="column"
                alignItems="center"
                style={{ color: Colors.darkNavy, backgroundColor: 'white', padding: '1em' }}
            >
                <Typography style={{ fontWeight: 'bold', marginTop: 5, marginBottom: 10 }}>
                    This job will be cancelled.
                </Typography>
                <LoadingButton onClick={onCancelSubmit} loading={pageStatus === STATUSES.submitting}>
                    Confirm
                </LoadingButton>
            </Grid>
        );
    };

    const onCancelClick = (pairing, itemId) => {
        if (itemId) {
            onGroupCancel(pairing, itemId);
        } else if (pairing.itemType === 'REIMBURSEMENT' || pairing.itemType === 'JOB') {
            declineProviderRequest(pairing);
        } else {
            setPairingToCancel({ id: pairing.appointment.id, isOngoing: pairing.appointment.ongoing_request.ongoing });
        }
    };

    const declineProviderRequest = (item) => {
        setPageStatus(STATUSES.submitting);

        client(`payment/api/provider-request/${item.id}/decline/`, {
            method: 'POST',
        }).then(() => {
            getAppointmentsAndDiscount();
            setPageStatus(STATUSES.neutral);
        });
    };

    const onGroupCancel = async (pairing, itemId) => {
        setPageStatus(STATUSES.submitting);
        await cancelOngoingRequest(pairing.appointment.id);
        setPageStatus(STATUSES.neutral);
        const index = pairings.findIndex((x) => x.id === itemId);
        if (pairings[index].payInfo.length === 1 && pairings[index].payInfo[0].pairings.length === 1) {
            setPairings(pairings.filter((x) => x.id !== itemId));
        } else {
            const payInfoIndex = pairings[index].payInfo.findIndex((x) => x.userId === pairing.babysitter.user.id);

            if (pairings[index].payInfo[payInfoIndex].pairings.length === 1) {
                removeOneProviderFromGroupPay(index, pairing);
            } else {
                removeOneJobFromProvider(index, payInfoIndex, pairing);
            }
        }
    };

    const removeOneProviderFromGroupPay = (itemIndex, pairing) => {
        setPairings([
            ...pairings.slice(0, itemIndex),
            {
                ...pairings[itemIndex],
                payInfo: [...pairings[itemIndex].payInfo.filter((y) => y.userId !== pairing.babysitter.user.id)],
            },
            ...pairings.slice(itemIndex + 1),
        ]);
    };

    const removeOneJobFromProvider = (itemIndex, providerIndex, pairing) => {
        const filteredPairings = pairings[itemIndex].payInfo[providerIndex].pairings.filter((x) => x.id !== pairing.id);

        setPairings([
            ...pairings.slice(0, itemIndex),
            {
                ...pairings[itemIndex],
                payInfo: [
                    ...pairings[itemIndex].payInfo.slice(0, providerIndex),
                    {
                        ...pairings[itemIndex].payInfo[providerIndex],
                        pairings: filteredPairings,
                        amount:
                            pairings[itemIndex].payInfo[providerIndex].amount -
                            calculatePay(
                                new Date(pairing.appointment.end_date),
                                new Date(pairing.appointment.start_date),
                                pairing.appointment.pay,
                            ),
                    },
                    ...pairings[itemIndex].payInfo.slice(providerIndex + 1),
                ],
            },
            ...pairings.slice(itemIndex + 1),
        ]);
    };

    const onCancelSubmit = async () => {
        setPageStatus(STATUSES.submitting);

        if (pairingToCancel.isOngoing) {
            await cancelOngoingRequest(pairingToCancel.id);
        } else {
            await cancelOneTimeRequest(pairingToCancel.id);
        }

        const cancelledId = pairingToCancel.id;
        setPairingToCancel();
        setPairings((currState) => {
            return currState.filter(
                (x) => x.itemType === 'GROUP' || (x.appointment && x.appointment.id !== cancelledId),
            );
        });
        setPageStatus(STATUSES.neutral);
    };

    const cancelOneTimeRequest = async (id) => {
        await client(`cancel-all-pairings-for-approved-appt/?appt=${id}`, {
            method: 'PATCH',
        });
    };

    const cancelOngoingRequest = async (id) => {
        await client(`skip-ongoing/${id}`, {
            method: 'POST',
        });
    };

    const onSurchargeDialogClose = () => {
        setDisplaySurchargeDialog(false);
    };

    const onSurchargeDialogOpen = () => {
        setDisplaySurchargeDialog(true);
    };

    const onPaySetupClose = () => {
        setErrorCount(0);
        setPaymentErrorMessage(undefined);
    };

    const getBody = () => {
        let itemCount = !pairings && !requests ? 0 : !!pairings ? pairings.length : 0;
        itemCount = !!requests ? itemCount + requests.length : itemCount;
        if (!itemCount) {
            return (
                <Grid style={{ padding: '1em' }}>
                    <Text variant="body1">You have no unpaid jobs</Text>
                </Grid>
            );
        }

        return (
            <PairingList
                pairings={pairings}
                requests={requests}
                onPayOpen={onPayClick}
                onPayValueChange={onPayValueChange}
                onSubmitPayment={onSubmitPayment}
                onEdit={onEditClick}
                onCancel={onCancelClick}
                onToggleTooltip={onToggleTooltip}
                discount={discount}
                loading={pageStatus === STATUSES.submitting}
                errorMessage={paymentErrorMessage}
                errorCount={errorCount}
                onSurchargeDialogClose={onSurchargeDialogClose}
                onSurchargeDialogOpen={onSurchargeDialogOpen}
                isSurchargeDialogOpen={displaySurchargeDialog}
                onPaySetupClose={onPaySetupClose}
            />
        );
    };

    const paySitterIcon = () => {
        return <img alt="pay icon" src={pay} style={{ width: 'auto', height: 70 }} />;
    };

    const paySitterLink = () => {
        return (
            <Link
                component="button"
                underline="always"
                style={{
                    color: theme.palette.primary.main,
                    fontWeight: 'bold',
                    marginTop: 5,
                    '&:hover': {
                        outline: 'none',
                    },
                    '&:focus': {
                        outline: 'none',
                    },
                }}
                onClick={() => {
                    setOpen(true);
                }}
                variant="subtitle1"
            >
                Send additional payment
            </Link>
        );
    };

    const _paySitter = () => {
        if (props.hideDirectPayLink) {
            return;
        }

        return (
            <Grid container item xs={12} justify="flex-start" direction="row" alignItems="center">
                <Grid container item xs={2} justify="center">
                    <Grid item>{paySitterIcon()}</Grid>
                </Grid>
                <Grid item xs={10}>
                    {paySitterLink()}
                </Grid>
            </Grid>
        );
    };

    const autopayExemptText = () => {
        if (pairings && pairings.every((x) => x.status !== 'AUTOPAY_EXEMPT')) {
            return;
        }

        return (
            <Grid container item lg={11} md={11} sm={11} xs={10} justify="center" direction="row" alignItems="center">
                <Typography variant="subtitle2">
                    * You have 48 hours after this job's end time to cancel before it will auto-pay the full amount.
                    Moving forward, the grace period will extend only 24 hours after the job’s end time.
                </Typography>
            </Grid>
        );
    };

    const paySitterDialog = () => {
        return (
            <DialogWithHeader
                open={open}
                onClose={() => setOpen(false)}
                title="Send Payment"
                color={Colors.darkNavy}
                content={
                    <div style={{ padding: 20 }}>
                        <PaySitter modal={true} onCompleteGroupPay={getAppointmentsAndDiscount} />
                    </div>
                }
            />
        );
    };

    return (
        <>
            <Grid
                container
                item
                justify="center"
                xs={12}
                style={{ paddingBottom: '0px', marginTop: props.fromDashboard ? 10 : 0 }}
            >
                <Grid container alignItems="center" style={{ marginLeft: 16 }}>
                    <Grid item xs={12}>
                        <PaySectionHeader>Your Unpaid Jobs</PaySectionHeader>
                    </Grid>
                </Grid>
                <Grid item xs={12} style={{ maxHeight: xsOnly ? 250 : 600, overflowY: 'auto' }}>
                    {getBody()}
                </Grid>
                {_paySitter()}
                {paySitterDialog()}
                {autopayExemptText()}
            </Grid>
            <DialogWithHeader
                open={pairingToCancel}
                onClose={() => setPairingToCancel()}
                onBackClick={() => setPairingToCancel()}
                back
                color={Colors.darkNavy}
                content={getCancelBody()}
            />
            <DialogWithHeader
                open={pairingToEdit}
                onClose={() => setPairingToEdit()}
                onBackClick={() => setPairingToEdit()}
                back
                color={Colors.darkNavy}
                content={getEditBody()}
            />
        </>
    );
};

export default UnpaidAppointments;

const EditTimes = (props) => {
    const { appointment } = props;

    const startDate = new Date(appointment.start_date);
    const endDate = new Date(appointment.end_date);
    const diff = endDate - startDate;
    let isSaveDisabled = false;

    let duration = '';
    if (diff < 1800000) {
        duration = 'Duration must be more than 30 minutes';
        isSaveDisabled = true;
    }

    return (
        <Grid container style={{ padding: 15 }}>
            <Grid item sm={6} xs={12}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DateTimePicker
                        margin="normal"
                        id="startDate"
                        value={appointment.start_date}
                        onChange={(e) => props.onValueChange('start_date', e.toDate())}
                        KeyboardButtonProps={{
                            'aria-label': 'change time',
                        }}
                        label="Start"
                        variant="inline"
                    />
                </MuiPickersUtilsProvider>
            </Grid>
            <Grid item sm={6} xs={12}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DateTimePicker
                        margin="normal"
                        id="endDate"
                        value={appointment.end_date}
                        onChange={(e) => props.onValueChange('end_date', e.toDate())}
                        KeyboardButtonProps={{
                            'aria-label': 'change time',
                        }}
                        error={duration}
                        helperText={duration || ''}
                        label="End"
                        variant="inline"
                    />
                </MuiPickersUtilsProvider>
            </Grid>
            <Grid container style={{ marginTop: 10 }}>
                <LoadingButton
                    style={{
                        fontWeight: 'bold',
                        color: 'white',
                        backgroundColor: theme.palette.primary.navy,
                        width: '100%',
                        minWidth: '100px',
                        padding: '1em',
                    }}
                    disableRipple
                    disableFocusRipple
                    onClick={props.onSubmit}
                    loading={props.loading}
                    disabled={isSaveDisabled}
                >
                    Confirm
                </LoadingButton>
            </Grid>
        </Grid>
    );
};
