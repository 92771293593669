import React from 'react';
import { Grid } from '@material-ui/core';
import LoginLine from '../../reusableComponents/line';
import Input from '../../reusableComponents/input';
import { isValidEmail } from 'shared/Validators';

export default function EmailVerificationInput({
    email,
    setEmail,
    setEmailValid,
}: {
    email: string;
    setEmail: (email: string) => void;
    setEmailValid: (valid: boolean) => void;
}) {
    function onValueChanged(value: string) {
        setEmail(value);
        setEmailValid(isValidEmail(value));
    }

    function getHelperText() {
        if (!email) return '';

        return 'Please enter a valid email address';
    }

    return (
        <Grid>
            <LoginLine>
                <Input
                    error={!!email && !isValidEmail(email)}
                    helperText={getHelperText()}
                    fullWidth
                    label="Email"
                    variant="outlined"
                    value={email}
                    onChange={(e) => onValueChanged(e.target.value)}
                />
            </LoginLine>
        </Grid>
    );
}
