import React, { useEffect } from 'react';
import InfoSection from './InfoSection';
import useTrackingCookies from 'parent-portal/shared/EngagementSourceTracking';
import usePublicSessionTracking from 'parent-portal/shared/PublicSessionTracking';
import { useCookies } from 'react-cookie';
import theme from 'theme';
import { Grid, useMediaQuery } from '@mui/material';
import FixingTheSystemSection from './FixingTheSystemSection';
import CentersSection from './CentersSection';
import WorkersSection from './WorkersSection';
import QuoteSection from './QuoteSection';

export default function OnDemandLandingPage() {
    useTrackingCookies('/business');
    const { usePageLoadTracking } = usePublicSessionTracking('On Demand Landing Page');
    const [cookies, setCookie] = useCookies();
    const smDown = useMediaQuery(theme.breakpoints.down('sm'));
    const mdDown = useMediaQuery(theme.breakpoints.down('md'));

    usePageLoadTracking();

    useEffect(() => {
        setCookie('isBusiness', true);
    }, []);

    return (
        <>
            <Grid
                container
                item
                style={{ padding: mdDown ? 0 : 50, marginTop: smDown ? 0 : 30 }}
                alignContent="center"
                justifyContent="center"
            >
                <InfoSection smDown={smDown} />
                <FixingTheSystemSection smDown={smDown} />
                <CentersSection />
                <WorkersSection />
                <QuoteSection smDown={smDown} />
            </Grid>
        </>
    );
}
