import React, { useState } from 'react';
import theme from 'theme';
import moment from 'moment';
import * as Sentry from '@sentry/react';
import DateFnsUtils from '@date-io/date-fns';
import useLoading from 'library/hooks/useLoading';
import { updateHourReport } from './api';
import { useUserContext } from 'UserContext';
import { Grid, useMediaQuery } from '@mui/material';
import { formatShortWeekdayMonthDay } from 'shared/Dates';
import { PrimaryButton, Text, SizeableRoundedDialog } from 'library';
import { MuiPickersUtilsProvider, TimePicker } from '@material-ui/pickers';
import { clockIn, breakStart, breakEnd, breakText, createReport, buildRequestBody } from './AdjustTimesHelpers';
import { AdjustTimesModalProps, IBuildRequestBodyParams } from './types';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

export default function AdjustTimesModal({
    open,
    onClose,
    provider,
    shift,
    hourReport,
    providerId,
    refresh,
}: AdjustTimesModalProps) {
    const { user } = useUserContext();
    const { loading, setLoading } = useLoading();
    const small = useMediaQuery(theme.breakpoints.down('sm'));

    const defaultClockIn = moment(clockIn(hourReport) || shift.start_date);

    const [breakStartInvalid, setBreakStartInvalid] = useState(false);
    const [breakEndInvalid, setBreakEndInvalid] = useState(false);
    const [clockInInvalid, setClockInInvalid] = useState(false);
    const [clockOutInvalid, setClockOutInvalid] = useState(false);

    const [clockTimes, setClockTimes] = useState<IBuildRequestBodyParams['clockTimes']>({
        provider_clock_in: defaultClockIn,
        provider_clock_out: null,
        business_clock_in: defaultClockIn,
        business_clock_out: null,
    });

    const [breakTimes, setBreakTimes] = useState<IBuildRequestBodyParams['breakTimes']>({
        provider_break_start: breakStart(hourReport),
        provider_break_end: breakEnd(hourReport),
        business_break_start: breakStart(hourReport),
        business_break_end: breakEnd(hourReport),
    });

    const validateBreakEnd = (date: MaterialUiPickersDate) => {
        const beforeBreakStart = !!(date && moment(date).isBefore(breakTimes.provider_break_start));
        const afterClockOut = !!(date && moment(date).isAfter(clockTimes.provider_clock_out));
        setBreakEndInvalid(beforeBreakStart || afterClockOut);
    };

    const validateBreakStart = (date: MaterialUiPickersDate) => {
        const afterBreakEnd = !!(date && moment(date).isAfter(breakTimes.provider_break_end));
        const beforeClockIn = !!(date && moment(date).isBefore(clockTimes.provider_clock_in));
        setBreakStartInvalid(afterBreakEnd || beforeClockIn);
    };

    const validateClockIn = (date: MaterialUiPickersDate) => {
        const afterClockOut = !!(date && moment(date).isAfter(clockTimes.provider_clock_out));
        const afterBreakStart = !!(date && moment(date).isAfter(breakTimes.provider_break_start));
        setClockInInvalid(afterClockOut || afterBreakStart);
    };

    const validateClockOut = (date: MaterialUiPickersDate) => {
        const beforeClockIn = !!(date && moment(date).isBefore(clockTimes.provider_clock_in));
        const beforeBreakEnd = !!(date && moment(date).isBefore(breakTimes.provider_break_end));
        setClockOutInvalid(beforeClockIn || beforeBreakEnd);
    };

    const submitChangeRequest = async () => {
        setLoading(true);

        try {
            const body = buildRequestBody({
                providerId,
                shiftId: shift.id,
                businessId: user?.businesses[0]?.id,
                clockTimes,
                breakTimes,
            });
            hourReport ? await updateHourReport(hourReport.id, body) : await createReport(body);
            refresh();
        } catch (error) {
            Sentry.captureException(error);
        } finally {
            setLoading(false);
            onClose();
        }
    };

    return (
        <SizeableRoundedDialog
            open={open}
            onClose={onClose}
            fullScreen={small}
            closeButton
            maxWidth="md"
            style={{ maxWidth: 500 }}
        >
            <Grid container item direction="column" style={{ padding: 20, gap: 10 }}>
                <Text variant="h1">
                    Change times for {provider?.first_name} {provider?.last_name}&apos;s hours for{' '}
                    {formatShortWeekdayMonthDay(shift.start_date)}?
                </Text>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <TimePicker
                        value={clockTimes.provider_clock_in}
                        style={{ maxWidth: '45%' }}
                        error={clockInInvalid}
                        onChange={(date) => {
                            validateClockIn(date);

                            setClockTimes({
                                ...clockTimes,
                                provider_clock_in: date,
                                business_clock_in: date,
                            });
                        }}
                        helperText="Start Time"
                    />
                    <TimePicker
                        value={clockTimes.provider_clock_out}
                        style={{ maxWidth: '45%' }}
                        error={clockOutInvalid}
                        onChange={(date) => {
                            validateClockOut(date);

                            setClockTimes({
                                ...clockTimes,
                                provider_clock_out: date,
                                business_clock_out: date,
                            });
                        }}
                        helperText="End Time"
                    />
                    <Text variant="body2">{breakText(hourReport)}</Text>
                    <TimePicker
                        value={breakTimes.provider_break_start}
                        style={{ maxWidth: '45%' }}
                        error={breakStartInvalid}
                        onChange={(date) => {
                            validateBreakStart(date);

                            setBreakTimes({
                                ...breakTimes,
                                provider_break_start: date,
                                business_break_start: date,
                            });
                        }}
                        helperText="Break Start"
                    />
                    <TimePicker
                        value={breakTimes.provider_break_end}
                        style={{ maxWidth: '45%' }}
                        error={breakEndInvalid}
                        onChange={(date) => {
                            validateBreakEnd(date);

                            setBreakTimes({
                                ...breakTimes,
                                provider_break_end: date,
                                business_break_end: date,
                            });
                        }}
                        helperText="Break End"
                    />
                </MuiPickersUtilsProvider>
                <PrimaryButton
                    disabled={loading || breakStartInvalid || breakEndInvalid || clockInInvalid || clockOutInvalid}
                    onClick={submitChangeRequest}
                    buttonStyle={{ width: undefined, alignSelf: 'flex-end' }}
                >
                    Submit
                </PrimaryButton>
            </Grid>
        </SizeableRoundedDialog>
    );
}
