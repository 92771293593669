import { PrimaryButton, Text, TextArea } from 'library';
import React, { useState } from 'react';
import { client } from 'shared';

export default function SendEmail() {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [emailList, setEmailList] = useState<string>();
    const [invalidAddresses, setInvalidAddresses] = useState<string[]>([]);

    async function unsubscribe() {
        setShowSuccess(false);
        setLoading(true);

        try {
            const response = await client('api/contact-info/unsubscribe', {
                body: { emails: emailList?.split('\n') },
            });
            setInvalidAddresses(response.invalid_addresses);
            setShowSuccess(true);
            reset();
        } catch (error) {
            setError(true);
        }

        setLoading(false);
    }

    function reset() {
        setEmailList(undefined);
        setError(false);
    }

    const valid = !!emailList && emailList.length > 0;

    return (
        <div style={{ margin: '50px 10vw 20px 10vw', display: 'flex', flexDirection: 'column' }}>
            <label style={{ margin: '20px 0' }}>Add emails to unsubscribe, one per line</label>
            <TextArea value={emailList} onChange={(event) => setEmailList(event.target.value)} />
            {error ? <Text>An error occurred unsubscribing people</Text> : null}
            {!valid && !showSuccess ? <Text>Please enter a list of emails</Text> : null}
            {showSuccess ? (
                <>
                    <Text>Hooray!(?) People were unsubscribed</Text>
                    {invalidAddresses?.length > 0 ? (
                        <>
                            <Text variant="body2">The following addresses were not valid:</Text>
                            {invalidAddresses.map((address) => (
                                <Text variant="body2" key={address}>
                                    {address}
                                </Text>
                            ))}
                        </>
                    ) : null}
                </>
            ) : null}
            <div style={{ width: 300, marginTop: 50 }}>
                <PrimaryButton onClick={unsubscribe} disabled={error || loading || !valid}>
                    Unsubscribe Emails
                </PrimaryButton>
            </div>
        </div>
    );
}
