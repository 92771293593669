import { defaultPricingPlan, PricingPlan, UserContextUser } from 'models';

export function getPricingPlan(user?: UserContextUser) {
    return (user?.businesses_active?.length || 0) > 0
        ? user?.businesses_active?.[0].pricing_plan || defaultPricingPlan
        : defaultPricingPlan;
}

export function getHourlyServiceFee(hourlyRate: number, pricingPlan: PricingPlan) {
    return pricingPlan.hourly_type === 'FIXED'
        ? pricingPlan.hourly_value
        : Math.min((pricingPlan.hourly_value / 100) * hourlyRate, pricingPlan.hourly_max_amount);
}

export function getTotalHourlyAmountWithFee(hourlyRate: number, pricingPlan: PricingPlan) {
    const hourlyFee = getHourlyServiceFee(hourlyRate, pricingPlan);

    return hourlyRate + hourlyFee;
}
