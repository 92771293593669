import { Grid, useMediaQuery } from '@mui/material';
import React from 'react';
import { Text, Colors, Highlight } from 'library';
import TandemPayPic from 'assets/images/marketing-site/TandemPay.png';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import theme from 'theme';

export default function JobTypes() {
    const smDown = useMediaQuery(theme.breakpoints.down('sm'));
    const details = [
        'Immediate access to earnings',
        'Tandem debit card for quick spending',
        'Transfer to another bank account',
    ];
    return (
        <Grid xs={12} item container flexDirection="column" style={{ padding: 40 }}>
            <Text variant="display" textStyle={{ textAlign: 'center', marginBottom: 20 }}>
                And get paid the Tandem Way, <Highlight color={Colors.lightTurq}>every single day</Highlight>.
            </Text>
            <Grid xs={12} item container justifyContent="space-around" style={{ gap: 20 }}>
                <Grid xs={12} item container lg={5} justifyContent="center" alignItems="center">
                    <img src={TandemPayPic} style={{ height: smDown ? 350 : 500 }} />
                </Grid>
                <Grid xs={12} lg={5} item container justifyContent="center" alignItems="center">
                    <Grid
                        item
                        container
                        style={{
                            padding: 40,
                            boxShadow: '0px 3px 6px #00000029',
                            gap: 40,
                            borderRadius: 18,
                            backgroundColor: Colors.white,
                        }}
                        flexDirection="column"
                    >
                        <Text variant="h1">
                            We provide a mobile banking app so you can use your earnings as soon as it hits your
                            account.
                        </Text>
                        {details.map((t) => (
                            <Text>
                                <DoneAllIcon style={{ color: Colors.coral, marginRight: 10, fontWeight: 'bold' }} />
                                {t}
                            </Text>
                        ))}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}
