import React from 'react';
import { Grid } from '@mui/material';
import NameButton from './NameButton';
import { AddPreferredWorker } from './AddPreferredWorker';
import { Text, Colors } from 'library';
import { BasePreferredWorker } from 'models';

export default function PreferredWorkers({
    preferred,
    setSelectedUser,
    refreshData,
    businessLocationId,
}: {
    preferred: BasePreferredWorker[];
    setSelectedUser: (id: number) => void;
    refreshData?: () => void;
    businessLocationId: number;
}) {
    return (
        <Grid container item gap={1} direction="column" xs={12} md={3}>
            <Text bold variant="h2" textStyle={{ color: Colors.turquoise }}>
                Preferred Workers:
            </Text>
            <Grid container item gap={1} direction="column">
                {preferred.length === 0 && <Text>No Preferred Workers Found.</Text>}
                {preferred.map((preferred) => (
                    <Grid key={preferred.id} container item>
                        <Grid container item gap={1} direction="column" xs={10}>
                            <NameButton onClick={setSelectedUser} user={preferred.user} />
                            <Text variant="caption">
                                {preferred.business_location ? 'Preferred At Location Only' : 'Preferred Business Wide'}
                            </Text>
                        </Grid>
                        <AddPreferredWorker
                            preferred={preferred.user}
                            refreshData={refreshData}
                            businessLocationId={businessLocationId}
                        />
                    </Grid>
                ))}
            </Grid>
        </Grid>
    );
}
