import { Add } from '@mui/icons-material';
import { Grid } from '@mui/material';
import { IconButton, Text } from 'library';
import AddBusinessLocationForm from 'parent-portal/BusinessLocation/AddBusinessLocationForm';
import React from 'react';

export default function NewAddressForm({
    isAddingAddress,
    setIsAddingAddress,
}: {
    isAddingAddress: boolean;
    setIsAddingAddress: (value: boolean) => void;
}) {
    if (isAddingAddress) {
        return <AddBusinessLocationForm cancel={() => setIsAddingAddress(false)} optionalContact={true} />;
    }

    return (
        <Grid container direction="row" alignItems="center" gap={2} margin={2}>
            <Text variant="body2" bold>
                Add Location
            </Text>
            <IconButton
                icon={Add}
                id="add-address"
                disabled={isAddingAddress}
                onClick={() => setIsAddingAddress(true)}
            />
        </Grid>
    );
}
