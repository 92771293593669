import React from 'react';
import { Grid, useMediaQuery } from '@mui/material';
import { Text, Colors, Highlight } from 'library';
import OutOfRatio from 'assets/images/marketing-site/OutOfRatio.svg';
import InRatio from 'assets/images/marketing-site/InRatio.svg';
import theme from 'theme';

export default function RatioSection() {
    const smDown = useMediaQuery(theme.breakpoints.down('sm'));
    const width = screen.width;
    const imageSize = {
        height: smDown ? 400 : 300,
        width: smDown ? width * 0.6 : 400,
    };
    const ratios = [
        {
            title: 'Without Tandem',
            content: 'Worker is out sick and center is out of ratio. Classroom closes.',
            image: <OutOfRatio height={imageSize.height} width={imageSize.width} />,
        },
        {
            title: 'With Tandem',
            content: 'Worker is out sick and center requests a sub from Tandem. Classroom stays open.',
            image: <InRatio height={imageSize.height} width={imageSize.width} />,
        },
    ];
    return (
        <Grid
            xs={12}
            item
            container
            style={{ padding: 40, gap: 40 }}
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
        >
            <Text variant="h1" textStyle={{ textAlign: 'center' }}>
                Operate stress-free by letting us <Highlight color={Colors.lightTurq}>keep you in ratio.</Highlight>
            </Text>
            <Grid
                xs={12}
                md={8}
                style={{
                    borderRadius: 18,
                    boxShadow: '0px 3px 6px #00000029',
                    padding: 20,
                    backgroundColor: Colors.white,
                    gap: smDown ? 20 : 0,
                    flexWrap: 'wrap',
                }}
                item
                container
                flexDirection="row"
            >
                {ratios.map((r) => (
                    <Grid
                        key={r.title}
                        item
                        container
                        xs={12}
                        md={6}
                        flexDirection="column"
                        justifyContent="center"
                        alignItems="center"
                        style={{ gap: 10 }}
                    >
                        <Text variant="h1" textStyle={{ textAlign: 'center' }}>
                            {r.title}
                        </Text>
                        <Text textStyle={{ textAlign: 'center' }}>{r.content}</Text>
                        {r.image}
                    </Grid>
                ))}
            </Grid>
        </Grid>
    );
}
