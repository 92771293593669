import React, { useState } from 'react';
import { PrimaryButton, SizeableRoundedDialog, Text } from 'library';
import { ComplianceWarningAction } from 'models';
import ButtonList from './ButtonList';

export default function ActionButton({
    action,
    refreshProvider,
}: {
    action: ComplianceWarningAction | null | undefined;
    refreshProvider: () => void;
}) {
    const [actionModalOpen, setActionModalOpen] = useState(false);

    if (!action) return <></>;

    const DisplayComponent =
        action.type === 'button_list' ? (
            <ButtonList
                options={action.options!}
                url={action.url}
                onSubmit={() => {
                    refreshProvider();
                    setActionModalOpen(false);
                }}
            />
        ) : null;

    if (!DisplayComponent) return <></>;

    return (
        <>
            <PrimaryButton
                buttonStyle={{ width: 'unset', marginLeft: 10, height: 20 }}
                onClick={() => setActionModalOpen(true)}
            >
                {action.button_text}
            </PrimaryButton>
            <SizeableRoundedDialog
                title={action.modal_title ?? 'Compliance Action'}
                onClose={() => setActionModalOpen(false)}
                open={actionModalOpen}
                style={{ padding: 10, gap: 20, display: 'flex', flexDirection: 'column' }}
                closeButton
            >
                {action.modal_message ? <Text bold>{action.modal_message}</Text> : null}
                {DisplayComponent}
            </SizeableRoundedDialog>
        </>
    );
}
