import { Grid } from '@mui/material';
import React, { useState } from 'react';
import useTrackingCookies from 'parent-portal/shared/EngagementSourceTracking';
import usePublicSessionTracking from 'parent-portal/shared/PublicSessionTracking';
import useSignUpAction from 'parent-portal/login/SignUpAction';
import HowItWorksHeader from './components/HowItWorksHeader';
import HowItWorksSection from './components/HowItWorksSection';
import TempStaffSection from './components/TempStaffSection';
import TrialRunSection from './components/TrialRunSection';
import PreSignUp from 'parent-portal/signup/PreSignUp/PreSignUp';

export default function HowItWorks() {
    useTrackingCookies('/how-it-works');
    const [open, setOpen] = useState(false);
    const { usePageLoadTracking } = usePublicSessionTracking('how it works');
    const { SignUpWrapper } = useSignUpAction(undefined);
    usePageLoadTracking();

    function signUp() {
        setOpen(true);
    }

    return (
        <SignUpWrapper businessSignup={true}>
            <Grid xs={12}>
                <HowItWorksHeader signUp={signUp} />
                <HowItWorksSection />
                <TrialRunSection />
                <TempStaffSection />
                <PreSignUp
                    open={open}
                    smallerVersion={false}
                    onClose={() => setOpen(false)}
                    headerText={'How will you use Tandem?'}
                    showBusinessOption={true}
                    showProviderOption={true}
                />
            </Grid>
        </SignUpWrapper>
    );
}
