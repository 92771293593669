import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { SimpleDialog } from '../../reusableComponents/dialogsSimple';
import RequestNewSitterDialog from '../jobs/upcoming/RequestNewSitterDialog';
import { client, consoleLogInDev } from 'shared';
import { useProviderProfileContext } from './ProviderProfileContext';
import { AppliedProvider } from 'models';
import { isLoggedIn } from 'api/UserApi';

interface UnpaidAppointment {
    start_date: string;
}

function RequestNewSitter() {
    const [showRequestNewSitter, setShowRequestNew] = useState(false);
    const { provider } = useProviderProfileContext();
    const [unpaidAppointment, setUnpaidAppointment] = useState<UnpaidAppointment>();
    const history = useHistory();

    const authUser = isLoggedIn();

    const getUnpaidAppointments = () => {
        if (!authUser) {
            return;
        }

        client(`api/get-unpaid-appointments`)
            .then((resp) => {
                let jobId = (provider as AppliedProvider).application.ongoing_request;
                let custom = resp.custom_payments
                    .filter((job: any) => job.id === jobId)
                    .reduce((allCustom: any[], current: any) => [...allCustom, ...current.pairings], []);
                let all = [...resp.daily_payments, ...custom];
                let unpaid = all.filter(
                    (job: any) =>
                        job.appointment.ongoing_request.id === jobId &&
                        job.appointment.ongoing_request.ongoing &&
                        moment(job.appointment.start_date).isBefore(moment(), 'day'),
                );

                if (unpaid.length > 0) {
                    setUnpaidAppointment(unpaid[0].appointment);
                }
            })
            .catch(consoleLogInDev);
    };
    useEffect(getUnpaidAppointments, []);

    if (!authUser) {
        return <></>;
    }

    return (
        <React.Fragment>
            <Typography
                onClick={() => setShowRequestNew(true)}
                variant="subtitle2"
                style={{ textDecoration: 'underline', marginTop: 25, cursor: 'pointer' }}
            >
                Not the right fit? Click here to request a new provider.
            </Typography>
            {unpaidAppointment ? (
                <SimpleDialog
                    dialogTitle="Unresolved job"
                    isOpen={showRequestNewSitter}
                    onClose={() => setShowRequestNew(false)}
                    onSubmit={() => history.push('/home')}
                    buttonText="Take me there"
                >
                    <Typography style={{ marginTop: 20, marginBottom: 10 }}>
                        Please pay {provider.user.first_name} for the job on{' '}
                        {unpaidAppointment?.start_date
                            ? moment(unpaidAppointment.start_date).format('dddd, MMMM Do YYYY')
                            : ''}
                        .
                    </Typography>
                    <Typography style={{ marginBottom: 10 }}>
                        Once the previous job has been resolved, you will be able to request a replacement.
                    </Typography>
                </SimpleDialog>
            ) : (
                <RequestNewSitterDialog
                    open={showRequestNewSitter}
                    onClose={() => setShowRequestNew(false)}
                    job={{ ongoing_request: { id: (provider as AppliedProvider).application.ongoing_request } }}
                    provider={provider.id}
                    type="request"
                />
            )}
        </React.Fragment>
    );
}

export default RequestNewSitter;
