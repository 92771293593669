import React, { useState } from 'react';
import { OutlinedTextInput, PrimaryButton, Colors, Text } from 'library';
import { client, consoleLogInDev } from 'shared';
import { useAdminAppContext } from '../AdminAppContext';
import { SketchPicker, Color } from 'react-color';
import { Popover } from '@mui/material';

export default function AddLabelDialog({
    type,
    open,
    onClose,
    anchorEl,
}: {
    type: string;
    open: boolean;
    onClose: () => void;
    anchorEl: null | HTMLElement;
}) {
    const [text, setText] = useState<string>();
    const [description, setDescription] = useState<string>();
    const [color, setColor] = useState<Color>(Colors.lightTurq);
    const { refreshLabels } = useAdminAppContext();

    const submit = () => {
        client('api/label/', { body: { text, description, content_type: type, color: color } })
            .then(() => {
                onClose();
                refreshLabels();
            })
            .catch(consoleLogInDev);
    };

    return (
        <Popover
            open={open}
            onClose={onClose}
            PaperProps={{
                sx: {
                    boxShadow: 'none',
                    borderRadius: '18px',
                    padding: '30px',
                },
            }}
            sx={{
                maxWidth: 600,
                '& .MuiBackdrop-root': { backgroundColor: '#002F4Db3' },
            }}
            anchorEl={anchorEl}
        >
            <Text variant="h1">New {type} label</Text>
            <OutlinedTextInput
                label="Name"
                style={{ padding: 10 }}
                fullWidth
                onChange={(e: any) => setText(e.target.value)}
                value={text}
            />
            <OutlinedTextInput
                label="Description"
                style={{ padding: 10 }}
                multiline
                fullWidth
                onChange={(e: any) => setDescription(e.target.value)}
                value={description}
            />
            <div style={{ padding: 20, display: 'flex', justifyContent: 'center' }}>
                <SketchPicker
                    width="375px"
                    color={color}
                    onChangeComplete={(colorResult, e) => setColor(colorResult.hex)}
                />
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                <PrimaryButton buttonStyle={{ maxWidth: 200 }} onClick={submit}>
                    Submit
                </PrimaryButton>
            </div>
        </Popover>
    );
}
