import React from 'react';
import { ContentCopy } from '@mui/icons-material';
import { IconButton, Text } from 'library';
import { BusinessJob, BusinessJobWithUser } from 'models';
import { getLink } from './jobLink';

export default function JobUniversalLink({ job }: { job: BusinessJob | BusinessJobWithUser }) {
    const link = getLink(job.id);

    return (
        <div style={{ marginTop: 12, marginBottom: 8 }}>
            <Text variant="body2" bold>
                Universal link to job:
            </Text>
            <Text variant="body2">
                {link}
                <IconButton
                    tooltip="Copy link"
                    style={{ marginLeft: 12 }}
                    icon={ContentCopy}
                    onClick={() => navigator.clipboard.writeText(link)}
                />
            </Text>
        </div>
    );
}
