import { CircularProgress } from '@mui/material';
import WeekSelection from 'internal/shared/WeekSelection';
import { Checkbox, RadioGroup, Text } from 'library';
import { AdminListProvider, BusinessJobWithAppointments, PagingResult } from 'models';
import React, { useEffect, useState } from 'react';
import { getOpenSubJobs } from '../substituteApi';
import { getNextMonday } from '../weekdayConstants';
import ReverseMatchingRow from './ReverseMatchingRow';
import PaginatedTable from 'internal/shared/tables/PaginatedAdminTable';

const distanceValues = { '5': '5 miles', '10': '10 miles', '15': '15 miles', '25': '25 miles' };
const jobTypeValues = { SUBSTITUTE: 'Sub', TRIAL_RUN: 'Trial Run', All: 'All' };
const columns = ['Job', 'Minimum Qualifications', 'Actions'];

export default function ReverseMatchingList({
    provider,
    open,
    updateProvider,
    initialWeek,
}: {
    provider: AdminListProvider;
    open: boolean;
    updateProvider?: (providerId: number, fields: Partial<AdminListProvider>) => void;
    initialWeek?: Date;
}) {
    const [matchingList, setMatchingList] = useState<PagingResult<BusinessJobWithAppointments>>({
        count: 0,
        per_page: 0,
        num_pages: 0,
        results: [],
    });
    const [distance, setDistance] = useState(15);
    const [week, setWeek] = useState(initialWeek ?? getNextMonday());
    const [jobType, setJobType] = useState('All');
    const [loading, setLoading] = useState(false);
    const [available, setAvailable] = useState(true);
    const [page, setPage] = useState(1);

    function getJobs() {
        if (open) {
            setLoading(true);
            const options = {
                user: provider.user.id,
                distance: distance,
                start_date: week,
                open: true,
                jobType: jobType !== 'All' ? jobType : undefined,
                page,
                available,
            };
            getOpenSubJobs(options)
                .then(setMatchingList)
                .finally(() => setLoading(false));
        }
    }

    useEffect(getJobs, [open, distance, week, jobType, available]);

    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                <RadioGroup
                    style={{ padding: 10 }}
                    horizontal
                    value={distance.toString()}
                    setValue={(val) => {
                        setDistance(parseInt(val));
                    }}
                    values={distanceValues}
                />
                <WeekSelection week={week} setWeek={setWeek} style={{ marginLeft: 10 }} />
                <RadioGroup
                    style={{ padding: 10 }}
                    horizontal
                    value={jobType}
                    setValue={setJobType}
                    values={jobTypeValues}
                />
                <Checkbox checked={available} onChange={() => setAvailable(!available)} label="Overlaps Availability" />
            </div>
            {loading ? (
                <CircularProgress style={{ padding: 10 }} />
            ) : matchingList.results.length > 0 ? (
                <PaginatedTable columns={columns} data={matchingList} page={page} updatePage={setPage}>
                    {matchingList.results.map((job) => (
                        <ReverseMatchingRow
                            key={job.id}
                            provider={provider}
                            job={job}
                            refreshJobs={getJobs}
                            updateProvider={updateProvider}
                        />
                    ))}
                </PaginatedTable>
            ) : (
                <Text textStyle={{ padding: 10 }}>No matching jobs found</Text>
            )}
        </div>
    );
}
