import React from 'react';
import { TableCell } from '@mui/material';
import { Text } from 'library';
import { AdminListProvider } from 'models';

export default function WorkerQualificationsCell({ provider }: { provider: AdminListProvider }) {
    return (
        <TableCell sx={{ maxWidth: 400 }}>
            {provider.user_qualifications?.split(',').map((qual) => (
                <Text key={qual} variant="body2" textStyle={{ margin: 5, marginLeft: 10 }}>
                    {qual}
                </Text>
            ))}
        </TableCell>
    );
}
