import { useCookies as wrappedUseCookies } from 'react-cookie';

export default function useCookies(dependencies?: string[]) {
    const [cookies, setCookie] = wrappedUseCookies(dependencies);

    return {
        cookies,
        setCookie,
    };
}
