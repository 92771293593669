import React from 'react';
import { Text, Colors, Chip } from 'library';
import { Grid } from '@material-ui/core';
import { styled } from '@mui/material';

export default function ChipRow({ text, chips }: { text: string; chips: string[] }) {
    return (
        <ChipRowContainer container direction="row" alignItems="center">
            <Text bold>{text}</Text>
            {chips.map((x) => (
                <Chip
                    key={x}
                    label={x}
                    style={{
                        margin: '2px 5px',
                        backgroundColor: Colors.lightTurq,
                        color: Colors.darkNavy,
                    }}
                />
            ))}
        </ChipRowContainer>
    );
}

const ChipRowContainer = styled(Grid)({
    padding: '5px 0',
});
