const tintColor = '#2f95dc';

export default {
    tintColor,
    tabIconDefault: '#ccc',
    tabIconSelected: tintColor,
    tabBar: '#fefefe',
    errorBackground: 'red',
    errorText: '#fff',
    warningBackground: '#EAEB5E',
    warningText: '#666804',
    noticeBackground: tintColor,
    noticeText: '#fff',
    tan: '#F5F0ED',
    grey: '#807E7D',
    mediumGrey: '#807E7D', // start of new color palette
    lightGrey: '#F7F6F6',
    green: '#C4FF12',
    mediumGreen: '#E7FFA0',
    lightGreen: '#F3FFD0',
    turquoise: '#00A68D',
    mediumTurq: '#A1DBD3',
    lightTurq: '#E8F6F4',
    red: '#880D30',
    mediumRed: '#E31650',
    lightRed: '#F9D9DC',
    darkNavy: '#002F4D',
    mediumNavy: '#335971',
    error: '#FF0000',
};
