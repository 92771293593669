import { Grid, GridSize, TextField } from '@material-ui/core';
import React from 'react';
import { Text } from 'library';
import StateDropdown from './StateDropdown';
import CountryDropdown from './CountryDropdown';

interface IProps {
    firstName: string;
    setFirstName: (firstName: string) => void;
    lastName: string;
    setLastName: (lastName: string) => void;
    address: string;
    setAddress: (address: string) => void;
    address2: string;
    setAddress2: (address2: string) => void;
    city: string;
    setCity: (city: string) => void;
    state: string;
    setState: (state: string) => void;
    zip: string;
    setZip: (zip: string) => void;
    country: string;
    setCountry: (country: string) => void;
}

interface IField {
    label: string;
    value: string;
    onChange: (value: any) => void;
    component?: React.ReactNode;
}

type IRow = IField[];

export default function BillingAddressSection({
    firstName,
    setFirstName,
    lastName,
    setLastName,
    address,
    setAddress,
    address2,
    setAddress2,
    city,
    setCity,
    state,
    setState,
    zip,
    setZip,
    country,
    setCountry,
}: IProps) {
    const form: IRow[] = [
        [
            {
                label: 'First Name (optional)',
                value: firstName,
                onChange: setFirstName,
            },
            {
                label: 'Last Name',
                value: lastName,
                onChange: setLastName,
            },
        ],
        [
            {
                label: 'Address',
                value: address,
                onChange: setAddress,
            },
        ],
        [
            {
                label: 'Apartment, suite, etc. (optional)',
                value: address2,
                onChange: setAddress2,
            },
        ],
        [
            {
                label: 'City',
                value: city,
                onChange: setCity,
            },
            {
                label: 'State',
                value: state,
                onChange: setState,
                component: <StateDropdown value={state} onChange={setState} />,
            },
            {
                label: 'Zip',
                value: zip,
                onChange: setZip,
            },
        ],
        [
            {
                label: 'Country',
                value: country,
                onChange: setCountry,
                component: <CountryDropdown value={country} onChange={setCountry} />,
            },
        ],
    ];
    return (
        <Grid container>
            <Grid container item>
                <Grid item>
                    <Text variant="body1">Billing Address</Text>
                </Grid>
            </Grid>
            <Grid container item>
                {form.map((row, index) => (
                    <Grid container item key={index} spacing={2} style={{ paddingTop: index > 0 ? 20 : undefined }}>
                        {row.map((field, index) => (
                            <Grid container item xs={12} sm={(12 / row.length) as GridSize} key={index}>
                                {field.component ? (
                                    field.component
                                ) : (
                                    <TextField
                                        label={field.label}
                                        onChange={(e) => field.onChange(e.target.value)}
                                        style={{ width: '100%' }}
                                    />
                                )}
                            </Grid>
                        ))}
                    </Grid>
                ))}
            </Grid>
        </Grid>
    );
}
