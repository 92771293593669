import React from 'react';
import { Job } from 'models';
import { Grid } from '@material-ui/core';
import moment from 'moment';
import JobDayPicker from 'parent-portal/forms/components/pages/DateAndTimePage/JobDayPicker';
import JobDateRangePicker from 'parent-portal/forms/components/pages/DateAndTimePage/JobDateRangePicker';
import JobSameTimePicker from 'parent-portal/forms/components/pages/DateAndTimePage/JobSameTimePicker';
import JobMultiTimePicker from 'parent-portal/forms/components/pages/DateAndTimePage/JobMultiTimePicker';
import { FormFieldProps } from 'parent-portal/forms/components/FormTypes';
import { DayOfWeekSelections } from 'parent-portal/forms/JobRequestTypes';
import FormField from 'parent-portal/forms/components/FormField';

export function Pickers({
    times,
    job,
    daysOfTheWeek,
    sameTimesField,
    startDateField,
    endDateField,
}: {
    times: JSX.Element;
    job: Job;
    daysOfTheWeek: FormFieldProps<DayOfWeekSelections>;
    sameTimesField: FormFieldProps<boolean>;
    startDateField: FormFieldProps<moment.Moment | null>;
    endDateField: FormFieldProps<moment.Moment | null>;
}) {
    return (
        <div className="job-form-background">
            <div className="job-form-content">
                <Grid container xs={10} md={8} lg={7} style={{ flexDirection: 'column', flexGrow: 1 }}>
                    {job.ongoing ? (
                        /* For flexible ongoing jobs the user family must confirm the duration of the job, the days of the week, and the hours on each day that work will occur before hiring */
                        <>
                            <Grid item xs={12}>
                                <FormField title="1. Choose the duration of job">
                                    <JobDateRangePicker startDate={startDateField} endDate={endDateField} />
                                </FormField>
                            </Grid>
                            <JobDayPicker daysOfTheWeek={daysOfTheWeek} />
                            <JobSameTimePicker isSameTime={sameTimesField} daysOfTheWeek={daysOfTheWeek} />
                            <FormField title="3. Choose the times for the job">
                                <Grid item style={{ paddingTop: 20 }}>
                                    {sameTimesField.value ? (
                                        <Grid item container xs={12} md={9}>
                                            {times}
                                        </Grid>
                                    ) : (
                                        <JobMultiTimePicker daysOfTheWeek={daysOfTheWeek} />
                                    )}
                                </Grid>
                            </FormField>
                        </>
                    ) : job.all_day ? (
                        <JobDateRangePicker startDate={startDateField} endDate={endDateField} />
                    ) : (
                        <>
                            <JobDateRangePicker startDate={startDateField} endDate={endDateField} />
                            <Grid xs={12} md={9} item container style={{ paddingTop: 20 }}>
                                {times}
                            </Grid>
                        </>
                    )}
                </Grid>
            </div>
        </div>
    );
}
