import { Grid } from '@mui/material';
import React from 'react';
import { Colors, Text } from 'library';

export default function LateCancellationWarning() {
    return (
        <Grid container direction="column">
            <Text variant="body1" textStyle={{ color: Colors.mediumRed, marginTop: 10, alignSelf: 'flex-end' }}>
                For booked shifts cancelled within 2 hours of the shift start time, you will be responsible for paying
                for 2 hours of the shift.
            </Text>
        </Grid>
    );
}
