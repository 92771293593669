import React, { useState } from 'react';
import _ from 'lodash';
import { Colors } from '../../theme';
import Text from './Text';

export default function TruncateText({
    text,
    length = 150,
    style,
}: {
    text: string;
    length?: number;
    style?: React.CSSProperties;
}) {
    const [truncate, setTruncate] = useState<boolean>(true);
    const Omission =
        text.length > length ? (
            <span
                style={{
                    cursor: 'pointer',
                    color: Colors.grey,
                    textDecoration: 'underline',
                    fontWeight: 'bold',
                }}
                onClick={() => setTruncate(!truncate)}
            >
                {truncate ? 'see more' : 'see less'}
            </span>
        ) : null;

    return (
        <Text variant="body2" textStyle={style}>
            {truncate
                ? `${_.truncate(text, { length: length, omission: '' })}${text.length > length ? '...' : ''}`
                : text}{' '}
            {Omission}
        </Text>
    );
}
