import React, { useEffect, useState } from 'react';
import { useClient } from 'shared';
import type { TProviderRecentlyWorked } from '../dashboard/types';
import { SizeableRoundedDialog } from 'library';
import { IProviderReviewOption } from 'models';
import ProviderReviewModalList from './ProviderReviewModalList';

const SESSION_STORAGE_KEY = 'provider-review-modal-seen';

export default function ProviderReviewModal({ workers }: { workers?: TProviderRecentlyWorked[] }) {
    const { data: options } = useClient<IProviderReviewOption[]>({ url: 'api/provider-review-options/' });

    const hasUnreviewedWorkers = !!workers && workers.length > 0;
    const seenModal = sessionStorage.getItem(SESSION_STORAGE_KEY) === 'true';

    const [isOpen, setOpen] = useState(hasUnreviewedWorkers && !seenModal);

    useEffect(() => {
        if (hasUnreviewedWorkers && !seenModal) {
            setOpen(true);
        } else {
            setOpen(false);
        }
    }, [hasUnreviewedWorkers, seenModal]);

    const onClose = () => {
        setOpen(false);
        sessionStorage.setItem(SESSION_STORAGE_KEY, 'true');
    };

    return (
        <SizeableRoundedDialog style={{ width: '80%' }} closeButton open={isOpen} onClose={onClose}>
            {workers && options && <ProviderReviewModalList workers={workers} options={options} />}
        </SizeableRoundedDialog>
    );
}
