import React from 'react';
import { Grid } from '@mui/material';
import UpcomingShifts from './components/UpcomingShifts';

export default function WorkerShifts({ providerUserId }: { providerUserId?: number }) {
    if (!providerUserId) return null;

    return (
        <Grid container item direction="column" gap={2}>
            <UpcomingShifts providerUserId={providerUserId} />
        </Grid>
    );
}
