import React, { Component } from 'react';
import { Button } from '@material-ui/core';
import theme from '../../../theme';
import Grid from '@material-ui/core/Grid';

class AccountDisplay extends Component {
    render() {
        const { onClickHandler } = this.props;

        return (
            <div>
                <Grid container sm={6} justify="center">
                    <Button
                        style={{
                            textDecoration: 'underline',
                            color: theme.palette.primary.main,
                            marginLeft: '9%',
                            fontSize: 12,
                        }}
                        onClick={() => {
                            onClickHandler();
                        }}
                    >
                        Edit
                    </Button>
                </Grid>
            </div>
        );
    }
}

export default AccountDisplay;
