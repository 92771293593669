import React, { useState } from 'react';
import { Grid } from '@mui/material';
import { Text, Colors, PrimaryButton, SecondaryButton } from 'library';
import useSignUpAction from 'parent-portal/login/SignUpAction';
import LearnMoreModal from './LearnMore/LearnMoreModal';
import { useHistory } from 'react-router-dom';
import Reading from 'assets/images/on-demand/lowly-worm.png';
import Lines from 'assets/images/on-demand/pink-lines-mobile.png';
import PreSignUp from 'parent-portal/signup/PreSignUp/PreSignUp';

export default function InfoSection({ smDown }: { smDown: boolean }) {
    const [open, setOpen] = useState(false);
    const [openLearnMore, setOpenLearnMore] = useState(false);
    const history = useHistory();
    const { SignUpWrapper, signUp } = useSignUpAction(undefined, () => history.push('/'));
    const styles = {
        container: {
            padding: smDown ? 20 : 80,
            backgroundColor: smDown ? Colors.lightTurq : Colors.white,
            marginBottom: smDown ? 0 : '15%',
        },
        button: {
            marginTop: 20,
            width: undefined,
        },
    };

    return (
        <SignUpWrapper businessSignup={true}>
            <Grid container direction="row" style={styles.container}>
                {smDown ? (
                    <div style={{ position: 'absolute', top: 30, right: 12 }}>
                        <img src={Lines} alt="" />
                    </div>
                ) : null}
                <Grid item md={6} style={{ padding: smDown ? 30 : 40, paddingRight: smDown ? 30 : 100 }}>
                    <Text variant="display">Let’s make the child care industry better, together.</Text>
                    <Text variant="body1" textStyle={{ marginTop: 30, marginBottom: 20 }}>
                        Tandem connects child care centers with job-ready staff, allowing child care workers to work on
                        their own time for the first time ever.
                    </Text>
                    <SecondaryButton
                        id="btnLeanCreateBusAccount"
                        buttonStyle={{ ...styles.button, marginRight: 15 }}
                        onClick={() => setOpenLearnMore(true)}
                    >
                        Learn More
                    </SecondaryButton>
                    <PrimaryButton
                        id="btnCreateBusAccount"
                        buttonStyle={styles.button}
                        onClick={() => setOpen(true)}
                        // onClick={() => signUp(`On Demand: First Section`, undefined, `Create your business account.`)}
                    >
                        Get Started
                    </PrimaryButton>
                </Grid>
                {smDown ? null : (
                    <Grid
                        container
                        item
                        md={6}
                        style={{ marginTop: -80, width: '100%', position: 'relative', maxWidth: 500 }}
                    >
                        <img
                            src={Reading}
                            alt="teacher reading"
                            width="100%"
                            height="auto"
                            style={{ position: 'absolute', objectFit: 'cover' }}
                        />
                    </Grid>
                )}
                <PreSignUp
                    open={open}
                    smallerVersion={false}
                    onClose={() => setOpen(false)}
                    headerText={'How will you use Tandem?'}
                    showBusinessOption={true}
                    showProviderOption={true}
                />
                <LearnMoreModal open={openLearnMore} onClose={() => setOpenLearnMore(false)} />
            </Grid>
        </SignUpWrapper>
    );
}
