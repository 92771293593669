import React, { useState, useEffect, useRef } from 'react';
import { Grid, Box, Card, CardContent, Chip, withStyles } from '@material-ui/core';
import { TextButton, Text } from 'library';
import { Colors } from '../../../constants';
import { ThreadItem } from './OutreachModels';
import { formatPhoneNumber } from '../../../shared/Utils';
import ProviderDetailsOutreachModal from './ProviderDetailsOutreachModal';
import moment from 'moment';

const StyleChip = withStyles({
    root: {
        color: Colors.white,
        backgroundColor: `${Colors.terraCotta} !important`,
        fontWeight: 'bold',
    },
})(Chip);

const OutreachThreadCard = ({ data, selectedNumber, onCardClick }: ThreadItem) => {
    const [cardState, setCardState] = useState<object>({});
    const [open, setOpen] = useState<boolean>(false);
    const ref = useRef();

    useEffect(() => {
        if (selectedNumber === data.phone_number) {
            setCardState({ backgroundColor: Colors.lightTurq });
            // @ts-ignore
            ref.current?.scrollIntoView({ behavior: 'smooth' });
        } else {
            setCardState({ backgroundColor: Colors.white });
        }
    }, [selectedNumber]);

    const handleClick = () => {
        if (selectedNumber !== data.phone_number) {
            onCardClick(data.phone_number);
        }
    };
    const handleMoreInfo = () => {
        setOpen(true);
    };
    const firstName = data.user_first_name ? data.user_first_name : data.first_name ? data.first_name : '';
    const lastName = data.user_last_name ? data.user_last_name : data.last_name ? data.last_name : '';
    return (
        // @ts-ignore
        <Box style={{ marginBottom: 10 }} ref={ref}>
            <Card onClick={handleClick} style={cardState}>
                <CardContent>
                    <Grid container direction="row" justify="space-between">
                        <Grid item>
                            <Text variant="body1">{formatPhoneNumber(data.phone_number)}</Text>
                        </Grid>
                        <Grid item>
                            {data.unread === 0 ? (
                                <Chip variant="default" label={data.unread} />
                            ) : (
                                <StyleChip label={data.unread} />
                            )}
                        </Grid>
                    </Grid>
                    <Grid container direction="column">
                        {firstName ? (
                            <Grid item>
                                <Text variant="body2">
                                    {firstName} {lastName}
                                </Text>
                            </Grid>
                        ) : null}
                        <Grid item>
                            <Text variant="body2">{moment(data.most_recent).format('MM/DD/YYYY h:mm A')}</Text>
                        </Grid>
                        {!!data.user_id ? (
                            <Grid item>
                                <Text variant="body1" bold color={Colors.red}>
                                    User ID: {data.user_id}
                                </Text>
                            </Grid>
                        ) : null}
                    </Grid>
                    {!!data.user_id ? (
                        <TextButton
                            onClick={() => {
                                handleMoreInfo();
                            }}
                        >
                            More Info
                        </TextButton>
                    ) : null}
                </CardContent>
            </Card>
            <ProviderDetailsOutreachModal providerUserId={data.user_id} onClose={() => setOpen(false)} open={open} />
        </Box>
    );
};

export default OutreachThreadCard;
