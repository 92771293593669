import React from 'react';
import { Verified } from '@mui/icons-material';
import { Colors, Text } from 'library';
import { Provider } from 'models';
import ProviderProfileTitledContainer from 'parent-portal/provider-profile/ProviderProfileTitledContainer';

export default function Certifications({ provider }: { provider: Provider }) {
    return (provider.certifications?.length || 0) > 0 ? (
        <ProviderProfileTitledContainer title="Certifications">
            {provider.certifications?.map((cert, index) => (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            marginBottom: index === (provider.certifications?.length || 0) - 1 ? 0 : 20,
                        }}
                    >
                        <Verified htmlColor={Colors.terraCotta} style={{ marginRight: 8 }} />
                        <Text variant="body1" bold>
                            {cert}
                        </Text>
                    </div>
                </div>
            ))}
        </ProviderProfileTitledContainer>
    ) : null;
}
