import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { getEducationOptions, saveBusinessLocation } from '../businessLocationProfileApi';
import { CheckboxSelect, Link, PrimaryButton, Text } from 'library';
import { styled } from '@material-ui/core';
import { IBusinessLocation } from 'models';

export default function EducationTypesSection({
    locationId,
    educationTypes,
    updateLocation,
}: {
    locationId: number;
    educationTypes: string[];
    updateLocation: (loc: IBusinessLocation) => void;
}) {
    const numberToShow = 4;

    const [options, setEducationOptions] = useState<string[]>([]);
    const [selected, setSelectedOptions] = useState<string[]>([]);
    const [expanded, setExpanded] = useState(false);
    const isDirty = educationTypes !== undefined && educationTypes.sort().join(',') !== selected.sort().join(',');

    useEffect(() => {
        getEducationOptions().then(setEducationOptions);
    }, []);

    useEffect(() => {
        setSelectedOptions(educationTypes);
    }, [educationTypes, options]);

    const onSave = () => {
        saveBusinessLocation(locationId, { education_types: selected }).then(updateLocation);
    };

    return (
        <Grid container item direction="column">
            <Text variant="body1" textStyle={{ marginBottom: 10 }} bold>
                Education Types
            </Text>
            <CheckboxSelect
                options={expanded ? options : options.slice(0, numberToShow)}
                value={selected}
                onChange={setSelectedOptions}
            />
            <Link onClick={() => setExpanded(!expanded)}>Show {expanded ? 'less' : 'more'}</Link>
            {isDirty && <SaveButton onClick={onSave}>Save</SaveButton>}
        </Grid>
    );
}

const SaveButton = styled(PrimaryButton)({
    maxWidth: 150,
});
