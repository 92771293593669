import React, { useRef, useState, useEffect, useCallback } from 'react';
import ToastCalendar from '@toast-ui/react-calendar';
import '@toast-ui/calendar/dist/toastui-calendar.min.css';
import { UpcomingJobModel } from 'parent-portal/jobs/nestedJobList/jobListModels';
import { Colors, ModalHeader, Text } from 'library';
import { CalendarType, EventType, SelectDateTimeInfo, ToastEvent, ViewType } from './models';
import { Dialog, Grid } from '@material-ui/core';
import { useMediaQuery } from '@mui/material';
import theme from 'theme';
import JobCard from '../JobCard';
import { useWidth } from 'reusableComponents/useWidth';
import moment from 'moment';
import { useModalsContext } from 'ModalsContext';
import { consoleLogInDev } from 'shared';
import CalendarHeader from './CalendarHeader';
import JobEventList from '../EventList/JobEventList';
import DateFnsUtils from '@date-io/date-fns';
import CustomWeekDayRender from './CustomWeekDayRender';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { upcomingJobs } from 'shared/JobApi';
import { formatCalendarEvents } from './CalendarHelpers';

// if you are here I suggest pulling the documentation: https://www.npmjs.com/package/@toast-ui/react-calendar

export default function Calendar() {
    const [jobs, setJobs] = useState<UpcomingJobModel[]>([]);
    useEffect(getUpcomingJobs, []);
    function getUpcomingJobs() {
        upcomingJobs()
            .then((jobsResponse) => {
                setJobs(jobsResponse);
            })
            .catch(consoleLogInDev);
    }
    const smDown = useMediaQuery(theme.breakpoints.down('sm'));
    const mdDown = useMediaQuery(theme.breakpoints.down('md'));
    const breakpoint = useWidth();
    const calendarRef = useRef<ToastCalendar>(null);
    const [calendars, setCalendars] = useState<CalendarType[]>([]);
    const [appointments, setAppointments] = useState<EventType[]>([]);
    const [calendarDate, setCalendarDate] = useState<Date>(new Date());
    const [selectedJob, setSelectedJob] = useState<UpcomingJobModel>();
    const [selectedView, setSelectedView] = useState<ViewType>('month');
    const calendarInst = useCallback(() => calendarRef.current?.getInstance(), [calendarRef]);
    const { openJobForm } = useModalsContext();

    useEffect(() => {
        formatCalendarEvents(jobs, setCalendars, setAppointments);
    }, [jobs]);

    useEffect(() => {
        if (calendarInst()) {
            calendarInst()?.on('clickEvent', onEventClick);
            calendarInst()?.on('selectDateTime', onSelectDate);
            calendarInst()?.scrollToNow();
            calendarInst()?.setDate(calendarDate);
        }
    }, [calendarInst, calendarDate]);

    function onSelectDate(info: SelectDateTimeInfo) {
        openJobForm('Calendar', {
            startDate: moment(info.start),
            jobTimes: {
                start: moment(info.start),
                end: moment(info.end),
                slots: 1,
            },
        });
    }

    function onEventClick({ event }: { event: ToastEvent }) {
        let job = jobs.find((j) => j.id === parseInt(event.calendarId));

        setSelectedJob(job);
    }

    function changeCalendarDate(d: Date) {
        setCalendarDate(d);
    }

    function changeCalendarView(view: ViewType) {
        setSelectedView(view);
    }

    const [mobileDateOpen, setMobileDateOpen] = useState<boolean>(false);

    function setDate(date: any) {
        setCalendarDate(date);
        setMobileDateOpen(true);
    }

    return (
        <Grid container direction="row" xs={12} style={{ padding: 10 }}>
            <Grid item xs={12} lg={9} container justify="center" style={{ marginTop: 10 }}>
                {smDown && !mobileDateOpen ? (
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePicker
                            InputProps={{
                                inputProps: { style: { textAlign: 'center', color: Colors.darkNavy } },
                            }}
                            disableToolbar
                            variant="static"
                            format="MM/dd/yyyy"
                            value={calendarDate}
                            onChange={setDate}
                            renderDay={(day, selectedDate, dayInCurrentMonth, dayComponent) => (
                                <CustomWeekDayRender
                                    date={day}
                                    selectedDate={selectedDate}
                                    appointments={appointments}
                                    dayInCurrentMonth={dayInCurrentMonth}
                                />
                            )}
                        />
                    </MuiPickersUtilsProvider>
                ) : (
                    <>
                        <CalendarHeader
                            width={calendarRef.current?.containerElementRef.current?.offsetWidth}
                            selectedView={selectedView}
                            setSelectedView={changeCalendarView}
                            appointments={appointments}
                            calendarInst={calendarInst}
                            overviewBack={() => {
                                setMobileDateOpen(false);
                            }}
                            includeDatePicker={!smDown}
                            setCalendarDate={changeCalendarDate}
                            calendarDate={calendarDate}
                        />
                        <ToastCalendar
                            gridSelection={{
                                enableDblClick: true,
                                enableClick: true,
                            }}
                            ref={calendarRef}
                            week={{ taskView: ['task'], showNowIndicator: false }}
                            view={smDown ? 'day' : selectedView}
                            calendars={calendars}
                            events={appointments}
                            usageStatistics={false}
                            template={{
                                taskTitle() {
                                    return `<div style="
                              height: 100%; 
                              justify-self: center; 
                              justify-content: center;
                              justify-items: center;
                              text-align: center;
                            ">
                              <span style="font-size: 11px;">Date/Time</span>
                              <br>
                              <span style="font-size: 11px;">Pending</span>
                            </div>`;
                                },
                                alldayTitle() {
                                    return `<div style="
                              height: 100%; 
                              justify-self: center; 
                              justify-content: center;
                              justify-items: center;
                              text-align: center;
                            ">
                              <span style="font-size: 11px;">All Day</span>
                            </div>`;
                                },
                            }}
                        />
                    </>
                )}
            </Grid>
            {!smDown || !mobileDateOpen ? (
                <Grid xs={12} lg={3} style={{ padding: 20 }}>
                    <Grid style={{ position: mdDown ? 'unset' : 'relative' }}>
                        <Grid style={{ position: mdDown ? 'unset' : 'absolute' }}>
                            <Text variant="h1" bold textStyle={{ marginBottom: 20 }}>
                                Upcoming Events
                            </Text>
                            <JobEventList jobs={jobs} />
                        </Grid>
                    </Grid>
                </Grid>
            ) : null}
            <Dialog
                maxWidth={'lg'}
                open={!!selectedJob}
                onClose={() => {
                    setSelectedJob(undefined);
                }}
                fullScreen={breakpoint === 'xs'}
                PaperProps={{ style: { borderRadius: '18px' } }}
            >
                <Grid container style={{ padding: 20, maxWidth: 350 }} justify="center">
                    <ModalHeader
                        onClose={() => setSelectedJob(undefined)}
                        title=""
                        subtitle={
                            selectedJob?.ongoing ? 'Specific days of job are editable if they are within one week' : ''
                        }
                    />
                    <Grid container justify="center" style={{ marginTop: 10 }}>
                        {selectedJob && <JobCard job={selectedJob} />}
                    </Grid>
                </Grid>
            </Dialog>
        </Grid>
    );
}
