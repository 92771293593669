import React, { useEffect, useState } from 'react';
import { AppliedProvider } from 'models';
import AppliedProviderCard from 'parent-portal/providers/cards/applied/AppliedProviderCard';
import { Grid } from '@material-ui/core';
import { Text, Colors, LoadingIndicator } from 'library';
import { useHistory } from 'react-router-dom';
import { useProviderProfileContext } from 'parent-portal/provider-profile/ProviderProfileContext';
import ApplicantStatusToggle from './ApplicantStatusToggle';
import ApplicantFilters from './ApplicantFilters';
import { upcomingJobs } from 'shared/JobApi';

export default function ApplicantCardList({ onProviderSelected }: { onProviderSelected: () => void }) {
    const history = useHistory();
    const [showSuperstars, setShowSuperstars] = useState(false);
    const [activeElement, setActiveElement] = useState<'HIDDEN' | 'LIKED' | 'UNDECIDED'>('UNDECIDED');
    const [superstars, setSuperstars] = useState<AppliedProvider[]>([]);
    const [appointmentId, setAppointmentId] = useState<number>();
    const [undecided, setUndecided] = useState<AppliedProvider[]>([]);
    const [liked, setLiked] = useState<AppliedProvider[]>([]);
    const [hidden, setHidden] = useState<AppliedProvider[]>([]);
    const { provider, refreshProvider, applicants, job, loading, hideApplicant, likeApplicant } =
        useProviderProfileContext();

    useEffect(() => {
        if (applicants.find((a) => a.superstar)) {
            setSuperstars(applicants.filter((a) => a.superstar));
        }
        let undecidedRes = applicants.filter((applicant: AppliedProvider) => !applicant.liked && !applicant.hidden);
        let hiddenRes = applicants.filter((applicant: AppliedProvider) => applicant.hidden);
        let likedRes = applicants.filter((applicant: AppliedProvider) => applicant.liked);
        setUndecided(undecidedRes);
        setHidden(hiddenRes);
        setLiked(likedRes);
        if (undecidedRes.length > 0) {
            refreshProvider(undecidedRes[0].user.id);
        } else if (likedRes.length > 0) {
            refreshProvider(likedRes[0].user.id);
            setActiveElement('LIKED');
        } else if (hiddenRes.length > 0) {
            refreshProvider(hiddenRes[0].user.id);
            setActiveElement('HIDDEN');
        }
    }, [applicants]);

    useEffect(() => {
        if (job && !job.ongoing) {
            upcomingJobs().then((res: any[]) => {
                let appt = res.find((j: any) => j.id === job?.id && j.appointment_id);
                if (appt) {
                    setAppointmentId(appt.appointment_id);
                }
            });
        }
    }, [job]);

    const getCard = (appliedProvider: AppliedProvider) => {
        return (
            <AppliedProviderCard
                provider={appliedProvider}
                selected={provider.id === appliedProvider.id}
                setSelected={(id) => {
                    onProviderSelected();
                    refreshProvider(id);
                }}
                hideApplicant={() => hideApplicant(appliedProvider)}
                likeApplicant={() => likeApplicant(appliedProvider)}
            />
        );
    };

    function SelectedApplicantPool() {
        const selectedApplicants = {
            UNDECIDED: {
                value: undecided,
                display: 'Sorry, no applicants yet.',
            },
            LIKED: {
                value: liked,
                display: "You haven't liked any of your applicants yet, click the thumb to add them to this list.",
            },
            HIDDEN: {
                value: hidden,
                display: "You haven't hidden any of your applicants yet.",
            },
        };
        let list = selectedApplicants[activeElement].value;
        let text = selectedApplicants[activeElement].display;
        return <>{list.length > 0 ? list.map(getCard) : <NoProviderText text={text} />}</>;
    }

    function NoProviderText({ text }: { text: string }) {
        return (
            <Grid item container justify="center" style={{ padding: 20 }}>
                <Text variant="caption" textStyle={{ color: Colors.darkGrey }}>
                    {text}
                </Text>
            </Grid>
        );
    }

    return (
        <>
            {job?.ongoing || appointmentId ? (
                <Text
                    variant={'caption'}
                    textStyle={{ textAlign: 'center', textDecorationLine: 'underline', marginBottom: 20 }}
                    onClick={() => {
                        let link = job?.ongoing
                            ? `/jobs/request/edit/${job?.id}`
                            : `/jobs/upcoming/edit/${appointmentId}`;
                        history.push(link);
                    }}
                >
                    Edit or Cancel Job
                </Text>
            ) : null}
            <Grid
                container
                style={{
                    border: `2px solid ${Colors.darkNavy}`,
                    borderRadius: 18,
                }}
            >
                <ApplicantFilters
                    superstars={superstars}
                    showSuperstars={showSuperstars}
                    setShowSuperstars={setShowSuperstars}
                    disabled={applicants.length === 0}
                />
                <ApplicantStatusToggle setActiveElement={setActiveElement} activeElement={activeElement} />

                {applicants.length > 0 ? (
                    <Grid container item style={{ height: 500, overflow: 'scroll', borderRadius: 18 }}>
                        <Grid container item direction="column" alignItems="flex-start">
                            {showSuperstars ? superstars.map(getCard) : <SelectedApplicantPool />}
                        </Grid>
                    </Grid>
                ) : (
                    <Grid container alignItems="center" justify="flex-start">
                        {applicants.length === 0 && loading ? (
                            <LoadingIndicator />
                        ) : (
                            <NoProviderText text="Sorry, no applicants yet." />
                        )}
                    </Grid>
                )}
            </Grid>
        </>
    );
}
