import React from 'react';
import { Paper, Text, Colors, Highlight } from 'library';
import { Grid, useMediaQuery } from '@mui/material';
import theme from 'theme';

const circleStyle = {
    width: '50px',
    height: '50px',
    backgroundColor: Colors.lightRed,
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
};

const bulletPoints = [
    {
        number: '1',
        title: 'Qualification Preferences',
        description:
            'All required qualifications you specify in your job posting will be met by your confirmed worker.',
    },
    {
        number: '2',
        title: 'Quality',
        description:
            'We collect reviews and monitor reliability to determine the quality of a worker. This factors into their ability to work on Tandem.',
    },
    {
        number: '3',
        title: 'Overall Fit',
        description: 'Your confirmed worker will meet your job’s schedule, pay scale, and commitment preference.',
    },
];

export default function MatchingProcess() {
    const smDown = useMediaQuery(theme.breakpoints.down('sm'));
    const bigCircleStyle2: React.CSSProperties = {
        width: smDown ? '280px' : '600px',
        height: smDown ? '280px' : '600px',
        backgroundColor: '#F1F6F6',
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: -3,
        position: 'absolute',
        right: smDown ? '90px' : '20px',
        bottom: smDown ? '-70vh' : '-80vh',
    };
    const textStyle = {
        marginTop: 30,
        marginBottom: 20,
        marginLeft: smDown ? 10 : 0,
    };
    function Circle(number: string) {
        return (
            <Grid container item xs={12} justifyContent={'center'}>
                <div style={circleStyle}>
                    <Text variant="h1" color={Colors.pink}>
                        {number}
                    </Text>
                </div>
            </Grid>
        );
    }

    return (
        <Grid container item justifyContent="center" style={{ paddingBottom: 100 }}>
            <Text variant="display" textStyle={textStyle}>
                <Highlight color={Colors.lightTurq}>Tandem's Matching Process</Highlight>
            </Text>

            <Paper
                style={{
                    backgroundColor: Colors.white,
                    margin: 20,
                    width: '80vw',
                    padding: smDown ? 10 : 30,
                    paddingBottom: 80,
                    marginLeft: smDown ? 10 : 50,
                    marginRight: smDown ? 10 : 50,
                    marginBottom: 80,
                    position: 'relative',
                    borderRadius: 71,
                }}
            >
                <Grid container item justifyContent="center">
                    <Text variant="h1" textStyle={textStyle}>
                        To find you the best possible match, we consider{' '}
                        <Highlight color={Colors.lightRed}>{bulletPoints.length} factors.</Highlight>
                    </Text>
                    <Grid container item style={{ marginTop: 30 }} direction={smDown ? 'column' : 'row'}>
                        {bulletPoints.map((point) => (
                            <Grid
                                md={4}
                                container
                                item
                                justifyContent="center"
                                key={point.number}
                                style={{ paddingLeft: 5, paddingRight: 5 }}
                            >
                                <Grid container item justifyContent="center">
                                    {Circle(point.number)}

                                    <Text variant="h2" bold textStyle={{ paddingTop: 30 }}>
                                        <Highlight color={Colors.lightTurq}>{point.title}</Highlight>
                                    </Text>
                                    <Text variant="body1" textStyle={{ margin: 20, textAlign: 'center' }}>
                                        {point.description}
                                    </Text>
                                </Grid>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </Paper>

            <div style={bigCircleStyle2}></div>
        </Grid>
    );
}
