import React, { useEffect, useState } from 'react';
import { client, consoleLogInDev } from 'shared';
import { useParams } from 'react-router';
import { Colors, Text } from 'library';
import EditableInfo from 'parent-portal/profile/BusinessProfile/EditableInfo';
import useAddress from 'parent-portal/profile/BusinessProfile/hooks/useAddress';
import { Grid } from '@mui/material';
import { IFAQQuestion } from 'parent-portal/business-profile/BusinessFaq/FaqAnswer';
import BusinessFaqSection from './BusinessFaqSection';
import LocationContactsSection from './LocationContactsSection';
import { IBusinessLocation } from 'models';
import BusinessLocationDetailsSection from './BusinessLocationDetailsSection/BusinessLocationDetailsSection';
import { saveBusinessLocation } from './businessLocationProfileApi';

export interface ILocationInfo {
    locationName: string;
}

export default function BusinessLocationPage() {
    const { id } = useParams<{ id: string }>();
    const [businessLocation, setBusinessLocation] = useState<IBusinessLocation>();
    const [mainLocationInfo, setMainLocationInfo] = useState<ILocationInfo>();
    const [programId, setProgramId] = useState('');
    const [editing, setEditing] = useState(false);
    const [loading, setLoading] = useState(false);
    const { addressInfo, resetAddress, submissionData: addressData, addressUpdated } = useAddress(
        businessLocation?.address,
    );
    const [faqQuestions, setFaqQuestions] = useState<IFAQQuestion[]>([]);

    useEffect(refreshBusinessLocation, [id]);

    useEffect(() => {
        if (!editing && businessLocation) {
            setMainLocationInfo({ locationName: businessLocation.name });
            setProgramId(businessLocation.program_id);
            resetAddress(businessLocation.address);
        }
    }, [businessLocation, editing]);

    function refreshBusinessLocation() {
        client(`api/business-location/${id}/`)
            .then((res) => {
                setFaqQuestions(res.business_faqs);
                updateLocation(res.location);
            })
            .catch(consoleLogInDev);
    }

    function saveLocation() {
        setLoading(true);
        const name =
            mainLocationInfo?.locationName === businessLocation?.name ? undefined : mainLocationInfo?.locationName;
        saveBusinessLocation(id, { name, addressData, program_id: programId })
            .then((res) => {
                updateLocation(res);
                setEditing(false);
            })
            .finally(() => setLoading(false))
            .catch(consoleLogInDev);
    }

    function updateLocation(location?: IBusinessLocation) {
        if (location) {
            resetAddress(location.address);
            setBusinessLocation(location);
        }
    }

    function updateMainLocationInfo(key: keyof ILocationInfo, value: string) {
        if (mainLocationInfo) setMainLocationInfo({ ...mainLocationInfo, [key]: value });
    }

    if (!mainLocationInfo || !businessLocation) return <></>;

    const locationInfo = [
        {
            title: 'Location Name',
            onChange: (v: string) => updateMainLocationInfo('locationName', v),
            value: mainLocationInfo.locationName,
            error: !mainLocationInfo.locationName,
        },
        ...addressInfo,
        {
            title: 'Program ID/Provider ID/License Number',
            onChange: (v: string) => setProgramId(v),
            value: programId,
            error: false,
        },
    ];

    function saveDisabled() {
        if (addressUpdated()) {
            return false || loading;
        } else {
            return (
                loading ||
                (!(
                    mainLocationInfo &&
                    mainLocationInfo?.locationName !== businessLocation?.name &&
                    mainLocationInfo?.locationName?.length > 0
                ) &&
                    programId === businessLocation?.program_id)
            );
        }
    }

    function updateQuestion(question: IFAQQuestion) {
        const qIndex = faqQuestions.findIndex((q: IFAQQuestion) => q.id === question.id);
        const newQuestions = Array.from(faqQuestions);
        if (qIndex !== -1) {
            // eslint-disable-next-line security/detect-object-injection
            newQuestions[qIndex] = question;
        } else {
            newQuestions.push(question);
        }
        setFaqQuestions(newQuestions);
    }

    return (
        <Grid container direction="column" justifyContent="center" style={{ marginTop: 40, padding: '0px 20%' }}>
            <Text variant="display" textStyle={{ marginBottom: 10 }}>
                Edit Center Location Information
            </Text>
            <Grid
                container
                item
                direction="column"
                style={{ padding: 20, backgroundColor: Colors.lightTurq, borderRadius: 18, gap: 20 }}
            >
                <EditableInfo
                    title="Center Information"
                    editing={editing}
                    updateEditing={setEditing}
                    fields={locationInfo}
                    save={saveLocation}
                    saveDisabled={saveDisabled()}
                />
                <BusinessLocationDetailsSection
                    businessLocation={businessLocation}
                    updateLocation={updateLocation}
                    refreshLocation={refreshBusinessLocation}
                />
                <LocationContactsSection businessLocation={businessLocation} updateLocation={updateLocation} />
                <BusinessFaqSection
                    faqQuestions={faqQuestions}
                    updateQuestion={updateQuestion}
                    businessLocation={businessLocation}
                />
            </Grid>
        </Grid>
    );
}
