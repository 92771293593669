import { Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Text, UnderlinedTextInput, Link, TextButton, Colors } from 'library';

export default function EditableValue({
    title,
    onSave,
    existingValue,
    isValid,
    errorMessage,
}: {
    title: string;
    existingValue?: string;
    onSave: (value: string) => void | Promise<void>;
    isValid?: (val: string) => boolean;
    errorMessage?: string;
}) {
    const [editing, setEditing] = useState(false);
    const [value, setValue] = useState(existingValue ?? '');

    useEffect(() => {
        if (!editing) {
            setValue(existingValue ?? '');
        }
    }, [existingValue, editing]);

    const save = () => {
        const result = onSave(value);
        result?.then ? result.then(() => setEditing(false)) : setEditing(false);
    };

    const isInputInValid = isValid ? Boolean(value) && !isValid(value) : false;

    return (
        <Grid container item direction="column">
            <Text variant="body1" bold>
                {title}
            </Text>
            {existingValue || editing ? (
                <Grid container direction="row">
                    <UnderlinedTextInput disabled={!editing} onChange={setValue} value={value} />
                    {!editing && <Link onClick={() => setEditing(true)}>Edit</Link>}
                    {editing && (
                        <TextButton buttonStyle={{ width: undefined }} onClick={save} disabled={isInputInValid}>
                            Save
                        </TextButton>
                    )}
                    <Text textStyle={{ color: Colors.error }}>{isInputInValid ? errorMessage : ''}</Text>
                </Grid>
            ) : (
                <Link onClick={() => setEditing(true)}>Add {title}</Link>
            )}
        </Grid>
    );
}
