import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { client, consoleLogInDev } from 'shared';
import { PointDetailResponse } from './types';
import { Text } from 'library';
import { ManagePointValueModal } from './components/ManagePointValueModal';

export default function UserPoints({ userId }: { userId: number }) {
    const [pointDetails, setPointDetails] = useState<PointDetailResponse>();
    const [showPointDetails, setShowPointDetails] = useState(false);

    function getPointsOverview() {
        if (userId === -1) return;

        client(`api/reliability-overview/point-details/?user_id=${userId}`)
            .then(setPointDetails)
            .catch(consoleLogInDev);
        return;
    }

    useEffect(getPointsOverview, [userId]);

    if (!pointDetails) return null;

    const total = pointDetails?.point.total;
    const totalText = total && total > 0 ? total : 'PROBATION';

    function closeModal() {
        setShowPointDetails(false);
        getPointsOverview();
    }

    return (
        <Grid>
            <Text onClick={() => setShowPointDetails(true)} textStyle={{ textDecorationLine: 'underline' }}>
                Points Total: {totalText}
            </Text>
            <ManagePointValueModal
                open={showPointDetails}
                onClose={closeModal}
                pointDetails={pointDetails}
                userId={userId}
            />
        </Grid>
    );
}
