import React from 'react';
import Header from '../../reusableComponents/header';
import SubHeader from '../../reusableComponents/subHeader';
import { useLocation } from 'react-router-dom';
import { Grid, Link } from '@material-ui/core';
import FamilyLandingBackground from 'parent-portal/login/FamilyLanding/FamilyLandingBackground';

export default function NotFound(props) {
    let location = useLocation();
    return (
        <Grid container>
            <FamilyLandingBackground>
                <Grid container direction="column" style={{ height: '75vh', width: '100vw', padding: 50 }}>
                    {props.error === false ? (
                        <div>
                            <Header variant="h5" gutterBottom>
                                404. {location.pathname} does not exist.
                            </Header>
                            <SubHeader gutterBottom>We hope you can find what you are looking for.</SubHeader>
                        </div>
                    ) : (
                        <div>
                            <Header variant="h5" gutterBottom>
                                We're sorry, there has been an unexpected system error on {location.pathname}.
                            </Header>
                            <SubHeader gutterBottom>
                                Please contact Tandem at <Link href="sms:+16149169512">(614) 916-9512</Link> or{' '}
                                <Link href="mailto:support@join-tandem.com">support@join-tandem.com.</Link>
                            </SubHeader>
                        </div>
                    )}
                </Grid>
            </FamilyLandingBackground>
        </Grid>
    );
}
