import { withStyles, Link as MuiLink } from '@material-ui/core';

const Link = withStyles({
    root: {
        '&:hover': {
            outline: 'none',
        },
        '&:focus': {
            outline: 'none',
        },
        fontFamily: 'Work Sans',
    },
})(MuiLink) as any;

export default Link;
