import { withStyles } from '@material-ui/core';
import CalendarPicker from '@mui/lab/CalendarPicker';
import { Colors } from '../../theme';

export default withStyles({
    root: {
        '& .MuiCalendarPicker-root': {
            overflow: 'visible',
        },
        '& .MuiPickersDay-root': {
            backgroundColor: Colors.turquoise,
            color: Colors.white,
            borderRadius: 0,
            '&:hover': {
                backgroundColor: Colors.darkNavy,
            },
            '&.Mui-selected': {
                backgroundColor: Colors.darkNavy,
            },
        },
        '& .Mui-disabled': {
            textDecoration: 'line-through',
            color: Colors.darkGrey,
            backgroundColor: Colors.lightGrey,
        },
        '& .MuiButtonBase-root.MuiPickersDay-root.Mui-selected': {
            backgroundColor: Colors.darkNavy,
        },
    },
})(CalendarPicker);
