import { useCookies } from 'react-cookie';
import { client, consoleLogInDev } from 'shared';
import useQueryParams from 'shared/QueryParamsHook';
import { CookieSetOptions } from 'universal-cookie';
import useTrackingCookies from './EngagementSourceTracking';

type CookieSetter = ReturnType<typeof useCookies>[1];

export function useDeprecatedReferralCode(url: string) {
    useTrackingCookies(url);
    const [cookies, setCookie] = useCookies(['referral']);

    const query = useQueryParams();

    const deprecatedReferral = query.get('code') || getDeprecatedSource(query);
    const shouldSetCookie = !!deprecatedReferral;

    if (shouldSetCookie) setCookie('referral', deprecatedReferral!, { path: '/' });
}

export function useDeprecatedTrackingCookies(url: string) {
    useTrackingCookies(url);
    const [{ source, referral }, setCookie] = useCookies(['source', 'referral']);

    const query = useQueryParams();

    const newSource = setSourceCookie(query, source, setCookie);
    const newReferral = setReferralCode(query, referral, setCookie);

    logVisit(newReferral || newSource);
}

function setSourceCookie(query: URLSearchParams, source: string, setCookie: CookieSetter) {
    const sourceExists = !!(query.get('source') || source);
    const deprecatedSource = getDeprecatedSource(query);

    if (!sourceExists && deprecatedSource) setCookie('source', deprecatedSource, { path: '/' });

    return deprecatedSource;
}

const knownParams = ['referral', 'source', 'code'];

function getDeprecatedSource(query: URLSearchParams) {
    const deprecatedSource = bareParams(query).filter((key) => !knownParams.includes(key))[0];
    return deprecatedSource;
}

function bareParams(query: URLSearchParams) {
    return Array.from(query.keys()).filter((key) => query.get(key) === '');
}

function setReferralCode(query: URLSearchParams, existingReferral: string, setCookie: CookieSetter) {
    const referralExists = !!(query.get('referral') || existingReferral);
    const deprecatedReferral = query.get('code');
    const shouldSetCookie = !referralExists && deprecatedReferral;

    if (shouldSetCookie) setCookie('referral', deprecatedReferral!, { path: '/' });

    return deprecatedReferral;
}

async function logVisit(trackCode?: string) {
    if (!trackCode) return;

    try {
        client('api/outreach/contact/', {
            body: { code: trackCode },
        });
    } catch (error) {
        consoleLogInDev(error);
    }
}
