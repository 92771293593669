import { JobTimesOfDay } from 'models';
import { client } from 'shared';

export async function addSlot(jobId: number, date: Date, offset: number, times?: JobTimesOfDay) {
    const timesForDay = times ? Object.values(times)[0] : { start: '08:00:00', end: '17:00:00' };
    const startTimes = timesForDay.start.split(':');
    const endTimes = timesForDay.end.split(':');
    const startDate = new Date(date);
    startDate.setUTCHours(parseInt(startTimes[0]) - offset, parseInt(startTimes[1]));
    const endDate = new Date(date);
    endDate.setUTCHours(parseInt(endTimes[0]) - offset, parseInt(endTimes[1]));
    const body = {
        job: jobId,
        start_date: startDate,
        end_date: endDate,
    };
    return await client('api/admin-slots/', { body });
}

export async function deleteSlot(slotId: number) {
    return await client(`api/admin-slots/${slotId}/`, { method: 'DELETE' });
}

export async function updateSlot(slotId: number, start: Date, end: Date, updateAppointments: boolean) {
    const body = {
        start_date: start,
        end_date: end,
        update_appointments: updateAppointments,
    };
    return await client(`api/admin-slots/${slotId}/`, { body, method: 'PATCH' });
}

export async function addAppointmentToSlot(slotId: number) {
    return await client(`api/admin-slots/${slotId}/`, { method: 'PATCH', body: { add_appointment: true } });
}
