import React, { useEffect, useState } from 'react';
import { Checkbox, OutlinedTextInput, SizeableRoundedDialog, Text } from 'library';
import { BusinessJobWithAppointments, IBusinessLocation, RegionAdminUser } from 'models';
import { Grid, useMediaQuery } from '@material-ui/core';
import theme from 'theme';
import LoadingButton from 'reusableComponents/loadingButton';
import useLoading from 'library/hooks/useLoading';
import { client, consoleLogInDev } from 'shared';
import { formatMonthYearWith12HourTime } from 'shared/Dates';
import { getAccountManagementAdmins } from './substituteApi';
import { useUserContext } from 'UserContext';

const BASE_EMAIl =
    'Hi {{Name}},\n\nJust checking in. We are still working on filling your need and will keep you posted throughout the process.\n\nPlease let us know if you have any questions.\n\nBest,\n{{Admin}}';

interface AdminBusinessContact {
    id: number;
    address: number;
    business: number;
    added_at: string;
    last_updated: string;
    user: {
        email: string;
        first_name: string;
        last_name: string;
        phone_number: string;
        id: number;
        communication_preference: string;
    };
    business_location: IBusinessLocation;
    last_general_email?: string;
}

export default function EmailCenter({
    showEmailModal,
    setShowEmailModal,
    job,
}: {
    showEmailModal: boolean;
    setShowEmailModal: (b: boolean) => void;
    job: BusinessJobWithAppointments;
}) {
    const { user } = useUserContext();
    const { loading, setLoading } = useLoading();
    const [body, setBody] = useState(BASE_EMAIl);
    const [subject, setSubject] = useState('Shift update');
    const [admins, setAdmins] = useState<RegionAdminUser[]>([]);
    const [fromEmail, setFromEmail] = useState('');
    const [businessContacts, setBusinessContacts] = useState<AdminBusinessContact[]>([]);
    const [contactsIncluded, setContactsIncluded] = useState<AdminBusinessContact[]>([]);
    const [emailSent, setEmailSent] = useState(false);
    const [lastSent, setLastSent] = useState<string>();
    const [testEmail, setTestEmail] = useState(false);
    const small = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
        if (!showEmailModal) return;
        async function getAdmins() {
            const allAdmins = await getAccountManagementAdmins();
            if (user && allAdmins.some((admin: RegionAdminUser) => admin.email === admin.email)) {
                setFromEmail(user.email);
            }
            setAdmins(allAdmins);
        }
        getAdmins();
    }, [showEmailModal]);

    function retreiveBusinessContacts() {
        if (job.business.length) {
            client(`api/business-contact/business-contacts/?business_id=${job.business[0].id}`)
                .then((res) => {
                    setBusinessContacts(res.contacts);
                    if (res.last_contacted) {
                        setLastSent(res.last_contacted);
                    }
                })
                .catch(consoleLogInDev);
        }
    }

    function emailCenter() {
        setLoading(true);
        const requestBody = {
            emails: [job.user.email, ...contactsIncluded.map((c) => c.user.email)],
            from_email: fromEmail,
            body,
            subject,
            test_email: testEmail,
        };
        client(`api/admin-appointment/send-admin-email/`, { body: requestBody })
            .then(() => {
                if (testEmail) {
                    alert('Test Email Sent');
                } else {
                    setEmailSent(true);
                }
            })
            .catch(consoleLogInDev)
            .finally(() => setLoading(false));
    }

    function updateContactsIncluded(c: AdminBusinessContact) {
        if (contactsIncluded.includes(c)) {
            setContactsIncluded(contactsIncluded.filter((s) => s.id !== c.id));
        } else {
            setContactsIncluded([...contactsIncluded, c]);
        }
    }
    useEffect(retreiveBusinessContacts, [emailSent]);
    function closeAndClear() {
        setBody(BASE_EMAIl);
        setContactsIncluded([]);
        setFromEmail('');
        setShowEmailModal(false);
        setEmailSent(false);
    }
    function lastContacted(d?: string) {
        return d
            ? `Last Email Sent From This Modal: ${formatMonthYearWith12HourTime(new Date(d))}`
            : 'Not Contacted From This Modal';
    }
    return (
        <SizeableRoundedDialog
            closeButton
            open={showEmailModal}
            onClose={closeAndClear}
            style={{ height: small ? 560 : 715, width: small ? '100%' : '80%', maxWidth: 700, paddingBottom: 10 }}
        >
            <Grid item container style={{ padding: 20, gap: 20 }} justify="center" direction="column">
                {emailSent ? (
                    <Text variant="h1">Email Sent</Text>
                ) : (
                    <>
                        <Text variant="h1">Send Communication To Center</Text>
                        <Text variant="caption">{lastContacted(lastSent)}</Text>
                        <Grid container item direction="row" justify="space-between">
                            <Grid container item direction="column" xs={6}>
                                <Text>Who is Sending the email?</Text>
                                {admins.map((contact) => (
                                    <Checkbox
                                        key={contact.id}
                                        style={{ width: small ? '100%' : '40%', textTransform: 'capitalize' }}
                                        label={contact.first_name}
                                        checked={fromEmail === contact.email}
                                        onChange={() => setFromEmail(fromEmail === contact.email ? '' : contact.email)}
                                    />
                                ))}
                            </Grid>
                            {businessContacts.length > 0 ? (
                                <Grid container item direction="column" xs={6}>
                                    <Text>Contacts in addition to owner?</Text>
                                    {businessContacts.map((contact) => (
                                        <Grid key={contact.id} container item direction="column">
                                            <Checkbox
                                                style={{ width: '100%', textTransform: 'capitalize' }}
                                                label={`${contact.user.first_name} ${
                                                    contact.user.last_name
                                                } (Contact for this job ${
                                                    contact.business_location.address.id === job.address.id
                                                })`}
                                                checked={contactsIncluded.includes(contact)}
                                                onChange={() => updateContactsIncluded(contact)}
                                            />
                                            <Text variant="caption" textStyle={{ marginLeft: 12 }}>
                                                {lastContacted(contact.last_general_email)}
                                            </Text>
                                        </Grid>
                                    ))}
                                </Grid>
                            ) : null}
                        </Grid>
                        <Checkbox
                            style={{ width: small ? '100%' : '40%', textTransform: 'capitalize' }}
                            label={'Test Email'}
                            checked={testEmail}
                            onChange={() => setTestEmail(!testEmail)}
                        />
                        <Text>Subject:</Text>
                        <OutlinedTextInput
                            multiline
                            placeholder="Subject"
                            onChange={(e) => setSubject(e.target.value)}
                            value={subject}
                        />
                        <Text>Body:</Text>
                        <Text variant="caption">
                            {'{{Name}}'} will be replaced with the receiving user&#39;s first name and {'{{Admin}}'}{' '}
                            will be replaced with the sending user&#39;s first name.
                        </Text>
                        <OutlinedTextInput
                            rows={10}
                            multiline
                            placeholder="Body"
                            onChange={(e) => setBody(e.target.value)}
                            value={body}
                        />
                        <LoadingButton
                            buttonStyle={{ maxWidth: '50%', alignSelf: 'flex-end' }}
                            onClick={emailCenter}
                            loading={loading}
                        >
                            Submit
                        </LoadingButton>
                    </>
                )}
            </Grid>
        </SizeableRoundedDialog>
    );
}
