import React, { ChangeEvent, useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { consoleLogInDev } from 'shared';
import Calendar from './Calendar/Calendar';
import { Checkbox, Colors, OutlinedDropdown, ToggleButtons } from 'library';
import { Link, useLocation, useHistory } from 'react-router-dom';
import ShiftList from './Shifts/ShiftList';
import JobPostsList from './JobPosts/JobPostsList';
import BusinessCalendar from './BusinessCalendar/BusinessCalendar';
import { IBusinessLocation } from 'models';
import { getBusinessLocations } from 'api/BusinessLocationApi';
import DraftList from './Drafts/DraftList';
import queryString from 'query-string';

const FAM_TABS = ['Calendar View', 'Card View'];
const BIZ_TABS = ['Calendar', 'Jobs', 'Drafts'];
const [familyCalendar] = FAM_TABS;
const [calendar, jobs, drafts] = BIZ_TABS;

const JobCardList = ({ isBusiness }: { isBusiness: boolean }) => {
    const [businessLocations, setBusinessLocations] = useState<IBusinessLocation[]>([]);
    const [selectedLocation, setSelectedLocation] = useState<IBusinessLocation>();
    const location = useLocation();
    const history = useHistory();
    const queryParams = queryString.parse(location.search);
    const [activeJobs, setActiveJobs] = useState<boolean>(true);
    const [expiredJobs, setExpiredJobs] = useState<boolean>(false);

    const selectedTab = getSelectedTab(queryParams.initialTab as string);

    function getSelectedTab(tab: string | null) {
        if (!isBusiness) return familyCalendar;

        switch (tab?.toLocaleLowerCase()) {
            case 'drafts':
                return drafts;
            case 'jobs':
                return jobs;
            default:
                return calendar;
        }
    }

    function toggleView(tab: string) {
        const params = new URLSearchParams(window.location.search);
        params.set('initialTab', tab);
        const url = `jobs?${params.toString()}`;
        history.push(url);
    }

    useEffect(() => {
        fetchBusinessLocations();
    }, []);

    function fetchBusinessLocations() {
        getBusinessLocations()
            .then((result) => {
                const paramLocation = new URLSearchParams(window.location.search).get('location');
                const location = result.results.find((l: IBusinessLocation) => l.id === parseInt(paramLocation || ''));

                setSelectedLocation(location ?? result.results[0]);
                setBusinessLocations(result.results);
            })
            .catch((error) => {
                consoleLogInDev(error);
            });
    }

    function handleLocationChange(e: ChangeEvent<{ name?: string; value: string }>) {
        const params = new URLSearchParams(window.location.search);
        params.set('location', e.target.value);
        const url = `jobs?${params.toString()}`;
        history.push(url);
    }

    function tabFactory(tab: string) {
        const commonProps = { addressId: selectedLocation?.address.id, businessLocationId: selectedLocation?.id };
        switch (tab) {
            case 'Jobs':
            case 'Card View':
                return isBusiness ? (
                    <ShiftList {...commonProps} active={activeJobs} expired={expiredJobs} />
                ) : (
                    <JobPostsList />
                );
            case 'Drafts':
                return <DraftList />;
            default:
                return isBusiness ? <BusinessCalendar {...commonProps} /> : <Calendar />;
        }
    }

    const tabsOptions = isBusiness ? BIZ_TABS : FAM_TABS;

    return (
        <Grid container>
            <Grid style={{ marginTop: 20, paddingRight: 20 }} item container justify="space-between">
                <Grid item container xs={12} md={4} direction="column">
                    <ToggleButtons tabs={tabsOptions} onTabSelect={toggleView} selectedTab={selectedTab} />
                    {selectedTab === jobs && (
                        <Grid item container direction="row" style={{ paddingTop: 15 }}>
                            <Checkbox
                                label="Active jobs"
                                checked={activeJobs}
                                onChange={() => setActiveJobs(!activeJobs)}
                                style={{ width: 'fit-content' }}
                            />
                            <Checkbox
                                label="Expired jobs"
                                checked={expiredJobs}
                                onChange={() => setExpiredJobs(!expiredJobs)}
                                style={{ width: 'fit-content', marginLeft: 30 }}
                            />
                        </Grid>
                    )}
                </Grid>
                <Grid item container xs={12} md={4} justify="center" style={{ margin: '15px 0' }}>
                    {isBusiness ? (
                        <OutlinedDropdown
                            value={selectedLocation?.id.toString() || ''}
                            fields={businessLocations.map((b) => {
                                return { key: b.id.toString(), value: b.name };
                            })}
                            onChange={handleLocationChange}
                        />
                    ) : null}
                </Grid>
                <Grid item container xs={12} md={4} justify="center">
                    <Link style={{ color: Colors.darkNavy, textDecorationLine: 'underline' }} to="confirm-hours">
                        View all reported timesheets
                    </Link>
                </Grid>
            </Grid>
            <Grid container direction="row" style={{ marginTop: 10 }} xs={12}>
                {tabFactory(selectedTab)}
            </Grid>
        </Grid>
    );
};

export default JobCardList;
