import { Grid } from '@mui/material';
import React from 'react';
import { Text, PrimaryButton } from 'library';
import Header from 'assets/images/marketing-site/HowItWorksHeader.png';

export default function HowItWorksHeader({ signUp }: { signUp: () => void }) {
    return (
        <Grid
            xs={12}
            style={{
                backgroundImage: `url(${Header})`,
                height: 900,
                backgroundSize: '100%',
                backgroundRepeat: 'no-repeat',
            }}
        >
            <Grid
                xs={12}
                md={6}
                item
                container
                style={{ height: '100%', padding: 80, gap: 20 }}
                justifyContent="center"
                flexDirection="column"
            >
                <Text variant="display">Hire temporary and permanent child care workers when you need them.</Text>
                <Grid item container style={{ gap: 20 }} flexDirection="column">
                    <Text>The days when you struggled to find and retain staff are over.</Text>
                    <Text>Our solution is built to serve the current state of the industry.</Text>
                    <PrimaryButton onClick={signUp} buttonStyle={{ width: 200 }}>
                        Start Now
                    </PrimaryButton>
                </Grid>
            </Grid>
        </Grid>
    );
}
