import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import { HourReportTimestamp } from '../HourReportModels';
import DateFnsUtils from '@date-io/date-fns';
import { DateTimePicker, MuiPickersUtilsProvider, TimePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { TextButton, Text } from 'library';
import { formatMonthDay, formatMonthDayTime } from 'shared/Dates';

export function EditTimes({
    accessor,
    currentValue,
    selectedTimestamps,
    updatedTimes,
    updateTimestamps,
}: {
    accessor: string;
    currentValue?: string;
    selectedTimestamps: HourReportTimestamp[] | undefined;
    updatedTimes: { [key: string]: Date | undefined };
    updateTimestamps: (d: MaterialUiPickersDate | Date, accessor: string) => void;
}) {
    const defaultDate = selectedTimestamps?.length ? new Date(selectedTimestamps[0].reported_at) : new Date();
    const [pickerOpen, setPickerOpen] = useState(false);

    const blankDateWithCorrectDay = new Date();
    blankDateWithCorrectDay.setDate(defaultDate.getDate());
    const currentDate = currentValue ? new Date(currentValue) : undefined;
    let date = updatedTimes?.[accessor] ?? currentDate;
    if (accessor.includes('altered') && (accessor.includes('admin') || accessor.includes('business'))) return <></>;
    return (
        <Grid item container justify="flex-end">
            {date ? (
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DateTimePicker
                        value={date}
                        open={pickerOpen}
                        onClose={() => setPickerOpen(false)}
                        onChange={(d: MaterialUiPickersDate) => d && updateTimestamps(d, accessor)}
                        style={{ maxWidth: 75 }}
                        TextFieldComponent={() => (
                            <TextButton onClick={() => setPickerOpen(true)}>
                                {formatMonthDayTime(date ?? new Date())}
                            </TextButton>
                        )}
                    />
                </MuiPickersUtilsProvider>
            ) : (
                <TextButton
                    onClick={() => {
                        updateTimestamps(blankDateWithCorrectDay, accessor);
                        setPickerOpen(true);
                    }}
                >
                    Add
                </TextButton>
            )}
        </Grid>
    );
}
