import React, { useState } from 'react';
import { Colors, DownloadTheAppButtons, PrimaryButton, Text } from 'library';
import { InputAdornment, TextField } from '@mui/material';
import { Place } from '@mui/icons-material';

export function HomeFindSection({
    selected,
    zipCode,
    updateZipCode,
    onSearch,
}: {
    selected: 'staff' | 'shifts';
    zipCode: string;
    updateZipCode: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onSearch: () => void;
}) {
    const [showHelperText, setShowHelperText] = useState(false);

    function search() {
        if (zipCode.length < 5) {
            setShowHelperText(true);
        } else {
            setShowHelperText(false);
            onSearch();
        }
    }

    function enterPressHandler(e: React.KeyboardEvent<HTMLInputElement>) {
        if (e.key === 'Enter') {
            search();
        }
    }

    return selected === 'staff' ? (
        <>
            <TextField
                variant="outlined"
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <Place style={{ color: Colors.darkNavy }} />
                        </InputAdornment>
                    ),
                    style: { fontFamily: 'Outfit', color: Colors.darkNavy, borderRadius: 12, marginTop: 20 },
                }}
                style={{ width: '100%' }}
                placeholder="Zip Code"
                value={zipCode}
                onChange={updateZipCode}
                onKeyUp={enterPressHandler}
                helperText={showHelperText ? 'Please enter a valid zip code' : null}
                FormHelperTextProps={{ style: { fontFamily: 'Outfit', color: Colors.error } }}
                maxRows={1}
            />
            <PrimaryButton buttonStyle={{ width: 'fit-content', marginTop: 20 }} onClick={search}>
                Search
            </PrimaryButton>
        </>
    ) : (
        <>
            <Text variant="body1" bold textStyle={{ marginTop: 20, marginBottom: 10 }}>
                Download the Tandem app to start picking up shifts:
            </Text>
            <DownloadTheAppButtons />
        </>
    );
}
