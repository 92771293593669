import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import { Text, Colors, Paper } from 'library';
import { TabContext, TabList } from '@mui/lab';
import { makeStyles, Tab } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    tabs: {
        '&.MuiButtonBase-root.MuiTab-root': {
            fontSize: 16,
            fontWeight: 'bold',
            textTransform: 'none',
            outline: 'none',
        },
        '&.MuiTab-textColorInherit': {
            color: Colors.darkNavy,
        },
        '&.Mui-selected': {
            color: Colors.turquoise,
        },
    },
    panels: {
        '&.MuiTabPanel-root': {
            padding: '20px 0px 20px 0px',
        },
    },
}));
export default function BusinessTabs({ business }: any) {
    const [selectedTab, setSelectedTab] = useState('0');
    const tabs: { label: string; component: React.ReactNode; show?: boolean }[] = [
        {
            label: 'Program Details',
            component: <></>,
            show: true,
        },
        {
            label: 'Cost & Availability',
            component: <></>,
            show: true,
        },
    ];
    const classes = useStyles();
    return (
        <Grid item xs={12}>
            <TabContext value={selectedTab}>
                <TabList
                    onChange={() => (selectedTab === '0' ? setSelectedTab('1') : setSelectedTab('0'))}
                    variant="scrollable"
                    allowScrollButtonsMobile
                    textColor="inherit"
                    TabIndicatorProps={{ style: { backgroundColor: Colors.turquoise, height: 3 } }}
                >
                    {tabs.map((tab, index) => (
                        <Tab label={tab.label} value={index.toString()} className={classes.tabs} />
                    ))}
                </TabList>
            </TabContext>
            <Paper style={{ marginTop: 20 }}>
                {selectedTab === '0' ? (
                    <>
                        <Text variant="body2">{business.user.bio}</Text>
                    </>
                ) : (
                    <div>
                        <Text variant="body1" bold textStyle={{ marginBottom: 10 }}>
                            Class Type/Rate
                        </Text>
                        <Grid container item direction="row" xs={8} spacing={1}>
                            {business.open_availability.map((item: any) => {
                                return (
                                    <Grid container item xs={3} alignContent="center" justify="center">
                                        <Grid
                                            container
                                            item
                                            alignContent="center"
                                            justify="center"
                                            style={{
                                                backgroundColor: Colors.lightTurq,
                                                margin: 10,
                                                borderRadius: 18,
                                                padding: 5,
                                                boxShadow: '0px 3px 6px #00000029',
                                            }}
                                        >
                                            <Text variant="body2" bold>
                                                {item.class_type.name}
                                            </Text>
                                        </Grid>
                                        {item.rate ? (
                                            <Grid container item xs={12} justify="center">
                                                <Text variant="caption">
                                                    ${item.rate}/{item.rate_per.toLowerCase()}
                                                </Text>
                                            </Grid>
                                        ) : null}
                                        {item.available_spots ? (
                                            <Grid container item xs={12} justify="center">
                                                <Text variant="caption" bold>
                                                    {item.available_spots} spots open
                                                </Text>
                                            </Grid>
                                        ) : null}
                                    </Grid>
                                );
                            })}
                        </Grid>
                    </div>
                )}
            </Paper>
            <Paper style={{ marginTop: 20 }}>
                {selectedTab === '0' ? (
                    <div>
                        <Text variant="body1" bold textStyle={{ marginBottom: 10 }}>
                            Type
                        </Text>
                        <Text variant="body2">
                            {business.type.replaceAll(
                                /\S*/g,
                                (word: string) => `${word.slice(0, 1)}${word.slice(1).toLowerCase()}`,
                            )}
                        </Text>
                        <Text variant="body1" bold textStyle={{ marginTop: 24, marginBottom: 10 }}>
                            Providing Care For
                        </Text>
                        {!!business.care_offerings ? (
                            <Grid container item direction="row" xs={8} spacing={1}>
                                {business.care_offerings.map((item: any, index: any) => {
                                    return (
                                        <Grid
                                            container
                                            item
                                            xs={3}
                                            alignContent="center"
                                            justify="center"
                                            style={{
                                                backgroundColor: Colors.lightTurq,
                                                margin: 10,
                                                borderRadius: 18,
                                                boxShadow: '0px 3px 6px #00000029',
                                            }}
                                        >
                                            <Text variant="caption" bold>
                                                {item.care_offering_type.name}
                                            </Text>
                                        </Grid>
                                    );
                                })}
                            </Grid>
                        ) : null}
                        <Text variant="body1" bold textStyle={{ marginTop: 24, marginBottom: 10 }}>
                            Licensing
                        </Text>
                        <Text variant="body2">
                            Care providers are compliant with Ohio's requirements to work in a child care center
                        </Text>
                    </div>
                ) : (
                    <>
                        <Text variant="body1" bold textStyle={{ marginBottom: 10 }}>
                            Offerings
                        </Text>
                        {!!business.offerings
                            ? business.offerings.map((offering: any) => (
                                  <Grid container item xs={12}>
                                      <Text variant="body2">{offering.offering_type.name}</Text>
                                  </Grid>
                              ))
                            : null}
                    </>
                )}
            </Paper>
        </Grid>
    );
}
