import React from 'react';
import { Grid } from '@material-ui/core';
import { Colors, ExpandableHeader, Text } from 'library';
import usePublicSessionTracking from '../shared/PublicSessionTracking';

interface IQuestion {
    title: string;
    body: string | React.ReactNode;
}
interface ISection {
    header: string;
    questions: IQuestion[];
}

const sections = [
    {
        header: 'Getting Started',
        questions: [
            {
                title: `Why should I add my payment information to my profile?`,
                body: `Payment is required to go through Tandem’s site, meaning you’ll need to set it up before hiring a care provider. Don’t worry, we allow you to message care providers and find the right fit before getting set up to pay. We allow you to connect a credit card or bank account using secure technologies Plaid and Stripe.`,
            },
            {
                title: `What rate should I set for my job posting?`,
                body: `Tandem allows parents to set their own rates based on the details of their job and the unique characteristics of their family. We believe provider rates should not solely be based on the number of children the provider is watching, but rather the distinct situation of the parents and their children. When you submit a booking request form, you choose the amount you want to pay. The average rate for a one time job is $14/hour, and $17/hour for a weekly job.`,
            },
            {
                title: `Can I pay Tandem care providers without posting a job?`,
                body: `Yes! Having a scheduled job makes payment easy- you’ll receive a notification when it’s time to pay and then you can adjust the total and even add a tip or reimbursement. If you don’t want to officially schedule the job, you can pay your provider through their profile anytime. Navigate to the “My Providers” page to find all providers you’ve connected with.`,
            },
        ],
    },
    {
        header: 'What is TandemPay',
        questions: [
            {
                title: `So what exactly is TandemPay?`,
                body: `TandemPay is Tandem’s all-in-one payment system. It keeps things simple - you find providers on the site and pay them there, too! You’re able to connect a credit card or bank account and select a payment schedule of your choice. When it’s time to pay your provider, you’ll be notified and can adjust the amount before confirming it.`,
            },
            {
                title: `How does TandemPay benefit me?`,
                body: `TandemPay allows you to schedule and manage payments for free. This means there are no fees or subscriptions ever! Easily track and manage your payments to care providers, and your provider will keep 100% of what you pay them.`,
            },
            {
                title: `Are there any fees or subscriptions?`,
                body: `Nope, Tandem is completely free! This means there is no commitment, you can use your account whenever you need it and will never commit to a subscription or pay a fee while paying your provider.`,
            },
        ],
    },
    {
        header: 'TandemPay and your provider',
        questions: [
            {
                title: `What do I do if my care provider asks to be paid outside of TandemPay?`,
                body: `Payment through Tandem is required for any care provider you connect with on the site. If your provider asks you to pay them through another platform, please let them know that this is a requirement and direct them to contact Support at support@join-tandem.com with any questions or concerns. If payment happens outside of the platform, we will have to deactivate your account and you will not be able to find care providers through Tandem in the future.`,
            },
            {
                title: `How does this work for my provider?`,
                body: `TandemPay allows care providers to receive 100% of their earnings and manage them through a comprehensive banking experience. With their TandemPay account, they can save, spend on their Visa debit card, or transfer to a personal bank account. We also track their earnings, allow them to download historical payment reports, and issue a tax form at the end of the year. This is all for free!`,
            },
            {
                title: `Does Tandem take a cut of my provider’s earnings?`,
                body: `Nope! We’re the only completely free care platform. This means that you pay no fees, and your provider still benefits because we don’t take a cut of their earnings. If you send your care provider $50 through the site, they’ll receive $50.`,
            },
            {
                title: `Can I reimburse my provider for expenses during a job?`,
                body: `If your provider spends their own money on job-related expenses, you can reimburse them using TandemPay. Please select the reimbursement payment type when sending reimbursement payments. Any amounts paid to providers as expense reimbursements will not be included in the total amount on their 1099-K tax form at the end of the year.`,
            },
        ],
    },
    {
        header: 'Who is Tandem',
        questions: [
            {
                title: `How did Tandem get started?`,
                body: (
                    <Text variant="body2" color={Colors.grey}>
                        Olivia founded Tandem in 2019 with the goal of connecting as many people as possible for free,
                        with trust at the foundation. At 10 years old, she started a neighborhood camp for kids in my
                        backyard. Over the years, the camp grew and connected her with a network of families to babysit
                        for. She realized the power of trust-based connections in the child care industry and wanted to
                        make this process easier for families and care providers.
                        <br />
                        <br />
                        Today, we are a local company founded by 3 young women. We’re still a young company, but we’ve
                        built a team of 18 and we’re working every day to build a platform for you.
                    </Text>
                ),
            },
            {
                title: `How is this free?`,
                body: `Tandem is free for both you and care providers. Tandem acts as a bank for our care providers’ earnings allowing us to remain completely fee-free. Without TandemPay (you paying your providers through Tandem), we cannot continue to exist as a free connection platform.`,
            },
            {
                title: `How does Tandem make money?`,
                body: `Tandem issues Visa debit cards to care providers that they can use to spend their earnings as soon as they’re received, wherever they want. They can also choose to transfer their earnings or save in their TandemPay account! When they spend on their Tandem card, Tandem receives a small portion of the merchant fee. That means we’re paid by the place where the provider chooses to spend, leaving all users out of the equation!`,
            },
            {
                title: `How can I support Tandem?`,
                body: (
                    <Text variant="body2" color={Colors.grey}>
                        As you use this platform, we ask you keep two things in mind:
                        <br />
                        <br />
                        1. We are still a young company and we haven’t quite figured everything out yet. Honest feedback
                        is very important to us. Contact us anytime via support@join-tandem.com.
                        <br />
                        <br />
                        2. We can only remain free for you and your care provider if payment stays within the site. We
                        know this is a little out of the ordinary. You may be used to paying your care providers through
                        cash, Venmo, or a payroll service. But, in order to continue to make free connections, payment
                        has to go through Tandem. By paying through Tandem, you’re helping us continue to exist. Thank
                        you for that.
                    </Text>
                ),
            },
        ],
    },
    {
        header: 'Paying providers',
        questions: [
            {
                title: `Am I charged for canceling a job?`,
                body: `No, Tandem does not have a strict cancellation policy. Do your best to be courteous to your provider and cancel the job on the site in a timely manner.`,
            },
            {
                title: `Can I adjust the total for a scheduled job?`,
                body: `Yes! Tandem makes it easy for you by calculating the total for the set hours. You can always adjust the total should the hours have changed. We also allow you to add a tip or reimbursement.`,
            },
            {
                title: `What if I want to pay my provider on a weekly schedule?`,
                body: `When you post a weekly job, you’ll be prompted to set your preferred payment schedule (daily or weekly). The schedule you choose determines when we remind you that it’s time to pay your provider. We’ll send you a notification and then you can adjust the total and add a tip or reimbursement.`,
            },
            {
                title: `Can I pay my provider outside of Tandem?`,
                body: `Tandem is a completely free connection service. We make money by acting as a bank for the providers’ earnings, so payment has to stay within our system in order for us to exist. We do not support outside payment methods such as mobile applications, cash, or check.`,
            },
        ],
    },
    {
        header: 'Taxes',
        questions: [
            {
                title: `Am I under tax obligations if I hire a care provider through Tandem?`,
                body: (
                    <Text variant="body2" color={Colors.grey}>
                        The Tandem payment flow is as follows; parent, Tandem, provider. Technically, you pay Tandem,
                        and we pay the specific provider, which takes all tax obligations off of you! Don’t worry, your
                        care provider still receives their funds right away.
                        <br />
                        <br />
                        It is Tandem’s job to send providers and the Internal Revenue Service a Form 1099-K if they make
                        over $600 in a calendar year through Tandem. Providers will file and pay taxes on their own,
                        leaving the parents out of the equation. Our EIN number is easily accessible to you through
                        payment receipts should you need it.
                    </Text>
                ),
            },
        ],
    },
];

export default function TandemPayFAQ() {
    const { usePageLoadTracking } = usePublicSessionTracking('TandemPay FAQ');
    usePageLoadTracking();

    function renderQuestions(questions: IQuestion[]) {
        return (
            <Grid container spacing={3} style={{ paddingLeft: 24, paddingRight: 24 }}>
                {questions.map((question: IQuestion) => (
                    <Grid container item xs={12} md={6} style={{ alignContent: 'flex-start' }} key={question.title}>
                        <Grid item style={{ paddingBottom: 12 }}>
                            <Text variant="h2" color={Colors.turquoise} bold>
                                {question.title}
                            </Text>
                        </Grid>
                        <Text variant="body2" color={Colors.grey}>
                            {question.body}
                        </Text>
                    </Grid>
                ))}
            </Grid>
        );
    }

    return (
        <Grid container direction="column" style={styles.outerContainer}>
            <Grid item style={styles.title}>
                <Text variant="display" textStyle={{ fontSize: 36 }}>
                    Frequently Asked Questions
                </Text>
            </Grid>
            <Grid container item style={{ justifyContent: 'center' }}>
                {sections.map((section: ISection) => (
                    <Grid item xs={11} style={{ paddingBottom: 24 }} key={section.header}>
                        <ExpandableHeader
                            header={section.header}
                            expandedContent={renderQuestions(section.questions)}
                        />
                    </Grid>
                ))}
                <Grid container item xs={11} direction="column" style={styles.questionContainer}>
                    <Grid item style={{ paddingTop: 12, paddingBottom: 12 }}>
                        <Text variant="h2" bold>
                            Questions not answered?
                        </Text>
                    </Grid>
                    <Text variant="body2">
                        Contact us at support@join-tandem.com and we’ll get back to you within 24 hours!
                    </Text>
                </Grid>
            </Grid>
        </Grid>
    );
}

const styles = {
    outerContainer: {
        paddingBottom: '10vh',
        paddingTop: '15vh',
        alignContent: 'center',
    },
    questionContainer: {
        marginTop: 48,
        borderRadius: 18,
        padding: 12,
        paddingBottom: 24,
        borderStyle: 'solid',
        borderColor: Colors.darkNavy,
        alignContent: 'center',
        alignItems: 'center',
    },
    title: {
        paddingBottom: 48,
        paddingLeft: 24,
        paddingRight: 24,
    },
};
