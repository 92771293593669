import React, { useEffect, useState } from 'react';
import { Paper, Text, OptionChip, IconButton, Colors } from 'library';
import { client, consoleLogInDev, queryStringifyObject } from 'shared';
import { addDays, addMonths } from 'date-fns';
import { Grid } from '@mui/material';
import Refresh from '@mui/icons-material/Refresh';

import { getMondayForThisWeek } from 'internal/substitutes/weekdayConstants';
import { formatDateFromDateObject } from 'shared/Dates';

import LocationSummaryItem from './LocationSummaryItem';
import StartDateSelector from './StartDateSelector';
import { BusinessLocationSummaryToday } from './BusinessLocationSummaryToday';

interface BusinessLocationSummaryDTO {
    businessLocationId: number;
    cancellations: number;
    no_shows: number;
    tardy_shifts: number;
    completed_shifts: number;
    refilled_shifts: number;
    revenue: number;
    period_start: string;
    period_end: string;
}

const periods = ['Today', 'Week', 'Month', 'All Time'];

export default function LocationSummary({ businessLocationId }: { businessLocationId: number }) {
    const [businessLocation, setBusinessLocation] = useState<BusinessLocationSummaryDTO>();
    const [businessLocationPairings, setBusinessLocationPairings] = useState([]);
    const [selectedPeriod, setSelectedPeriod] = useState<string>(periods[0]);
    const [startDate, setStartDate] = useState<Date>(getMondayForThisWeek());
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        let queryParams = null;
        if (selectedPeriod === 'Today') {
            queryParams = queryStringifyObject({
                start: formatDateFromDateObject(new Date()),
                end: formatDateFromDateObject(new Date()),
                timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            });
        } else if (selectedPeriod === 'Week') {
            queryParams = queryStringifyObject({
                start: formatDateFromDateObject(startDate),
                end: formatDateFromDateObject(addDays(startDate, 7)),
            });
        } else if (selectedPeriod === 'Month') {
            queryParams = queryStringifyObject({
                start: formatDateFromDateObject(startDate),
                end: formatDateFromDateObject(addMonths(startDate, 1)),
            });
        }
        setLoading(true);

        queryParams = queryParams ? `?${queryParams}` : '';
        client(`api/business-location-summary/${businessLocationId}/${queryParams}`)
            .then((response) =>
                selectedPeriod.toLowerCase() === 'today'
                    ? setBusinessLocationPairings(response)
                    : setBusinessLocation(response),
            )
            .catch(consoleLogInDev)
            .finally(() => setLoading(false));
    }, [businessLocationId, selectedPeriod, startDate]);

    function onRefreshClick() {
        setLoading(true);
        client(`api/business-location-summary/${businessLocationId}/refresh/`, {
            body: {
                start: formatDateFromDateObject(startDate),
            },
        })
            .then(() => {
                setStartDate(new Date(startDate));
            })
            .catch(consoleLogInDev)
            .finally(() => {
                setLoading(false);
            });
    }

    return (
        <Grid container width="80%">
            <Grid container direction="row" gap={2} alignItems="center" marginBottom={1}>
                <Text variant="h2" bold>
                    Summary
                </Text>
                {periods.map((period) => (
                    <OptionChip
                        key={period}
                        label={period}
                        selected={selectedPeriod === period}
                        onClick={() => setSelectedPeriod(period)}
                    />
                ))}
                <StartDateSelector selectedPeriod={selectedPeriod} startDate={startDate} setStartDate={setStartDate} />
                {selectedPeriod.toLocaleLowerCase() === 'month' && (
                    <IconButton
                        icon={Refresh}
                        onClick={onRefreshClick}
                        backgroundColor={Colors.darkNavy}
                        color={Colors.white}
                        disabled={loading}
                    />
                )}
            </Grid>
            {selectedPeriod.toLowerCase() === 'today' ? (
                <div style={{ width: '100%' }}>
                    <Text bold>Workers Booked</Text>
                    <BusinessLocationSummaryToday pairings={businessLocationPairings} loading={loading} />
                </div>
            ) : (
                <Paper>
                    <Grid container direction="row" justifyContent="space-between">
                        <LocationSummaryItem title="Cancellations" value={businessLocation?.cancellations || 0} />
                        <LocationSummaryItem title="No Shows" value={businessLocation?.no_shows || 0} />
                        <LocationSummaryItem title="Tardy Shifts" value={businessLocation?.tardy_shifts || 0} />
                        <LocationSummaryItem title="Completed Shifts" value={businessLocation?.completed_shifts || 0} />
                        <LocationSummaryItem title="Refilled Shifts" value={businessLocation?.refilled_shifts || 0} />
                        <LocationSummaryItem
                            title="Revenue"
                            value={businessLocation?.revenue ? `$${businessLocation?.revenue.toFixed(2)}` : '$0'}
                        />
                    </Grid>
                </Paper>
            )}
        </Grid>
    );
}
