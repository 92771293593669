import { IPayScaleQualification } from 'parent-portal/forms/JobRequestTypes';
import { IManagedScale } from '../BusinessPayInputs';

export function addToHigherPayRates(
    newScales: IManagedScale[],
    managedScale: IManagedScale,
    addedQualification: IPayScaleQualification,
) {
    newScales.forEach((x) => {
        if (
            x.rate >= managedScale.rate &&
            !x.qualifications.some(
                (y) =>
                    y.qualification_id === addedQualification.qualification_id &&
                    y.option_id === addedQualification.option_id,
            )
        ) {
            x.qualifications.push(addedQualification);
        }
    });
}

export function removeFromLowerPayRates(
    newScales: IManagedScale[],
    managedScale: IManagedScale,
    removedQualification: IPayScaleQualification,
) {
    newScales.forEach((x) => {
        if (
            x.rate < managedScale.rate &&
            x.qualifications.some(
                (y) =>
                    y.qualification_id === removedQualification.qualification_id &&
                    y.option_id === removedQualification.option_id,
            )
        ) {
            x.qualifications = x.qualifications.filter(
                (y) =>
                    y.qualification_id !== removedQualification.qualification_id &&
                    y.option_id !== removedQualification.option_id,
            );
        }
    });
}

export function addOrUpdateSingleToHigherRate(
    newScales: IManagedScale[],
    managedScale: IManagedScale,
    addedSingleQualification: IPayScaleQualification,
) {
    newScales.forEach((x) => {
        const qualification = x.qualifications.find(
            (y) => y.qualification_id === addedSingleQualification.qualification_id,
        );
        if (x.rate >= managedScale.rate && !qualification) {
            x.qualifications.push(addedSingleQualification);
        } else if (
            x.rate >= managedScale.rate &&
            qualification &&
            qualification.option_id &&
            addedSingleQualification.option_id &&
            qualification.option_id < addedSingleQualification.option_id
        ) {
            x.qualifications = x.qualifications.map((y) => {
                if (y.qualification_id === addedSingleQualification.qualification_id) {
                    return {
                        ...y,
                        name: addedSingleQualification.name,
                        option_id: addedSingleQualification.option_id,
                    };
                }

                return y;
            });
        } else if (
            x.rate < managedScale.rate &&
            qualification &&
            qualification.option_id &&
            addedSingleQualification.option_id &&
            qualification.option_id > addedSingleQualification.option_id
        ) {
            x.qualifications = x.qualifications.map((y) => {
                if (y.qualification_id === addedSingleQualification.qualification_id) {
                    return {
                        ...y,
                        name: addedSingleQualification.name,
                        option_id: addedSingleQualification.option_id,
                    };
                }

                return y;
            });
        }
    });
}

export function removeSingleFromLowerRates(
    newScales: IManagedScale[],
    managedScale: IManagedScale,
    removedSingleQualification: number,
) {
    newScales.forEach((x) => {
        if (
            x.rate < managedScale.rate &&
            x.qualifications.some((y) => y.qualification_id === removedSingleQualification)
        ) {
            x.qualifications = x.qualifications.filter((y) => y.qualification_id !== removedSingleQualification);
        }
    });
}
