import { Grid } from '@material-ui/core';
import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { Colors, KebabMenu } from 'library';
import MenuIcon from '@mui/icons-material/Menu';
import Header from './Header';
import AuthModal from 'parent-portal/login/AuthModal';
import useSignUpAction from 'parent-portal/login/SignUpAction';
import PreSignUp from 'parent-portal/signup/PreSignUp/PreSignUp';
import WhiteHeader from './WhiteHeader';

type Routes =
    | 'How It Works'
    | 'Temporary Staff'
    | 'Permanent Hires'
    | 'Workers'
    | 'About Us'
    | 'Sign Up'
    | 'Center Login';

type NavRoutes = 'How It Works' | 'Temporary Staff' | 'Permanent Hires' | 'Workers' | 'About Us';

type RoutesObj = {
    [Property in NavRoutes]: string;
};

export default function ActionNavBar() {
    const [showLoginModal, setShowLoginModal] = useState<boolean>(false);
    const [open, setOpen] = useState<boolean>(false);
    const { SignUpWrapper, signUp } = useSignUpAction();
    const history = useHistory();
    const isEldercare = window.location.pathname.includes('facilities');
    const kebabOptions = isEldercare
        ? ['Sign Up', 'Facility Login']
        : ['Sign Up', 'Center Login', 'How It Works', 'Temporary Staff', 'Permanent Hires', 'Workers', 'About Us'];

    const routes: RoutesObj = {
        'How It Works': '/how-it-works',
        'Temporary Staff': '/substitutes',
        'Permanent Hires': '/trial-runs',
        Workers: '/workers',
        'About Us': '/about-us',
    };

    function menuItemSelect(e: Routes | string | object) {
        if (typeof e === 'object') return;
        if (e === 'Sign Up') {
            isEldercare ? signUp('facilities') : signUpClick();
        } else if (e?.includes('Login')) {
            login();
        } else {
            history.push(routes[e as keyof RoutesObj] ?? '/how-it-works');
        }
    }

    function signUpClick() {
        setOpen(true);
    }

    function login() {
        setShowLoginModal(true);
    }

    function loginModalClose() {
        setShowLoginModal(false);
    }

    return (
        <>
            <Grid
                container
                direction="column"
                style={{
                    backgroundColor: isEldercare ? Colors.darkNavy : Colors.white,
                    boxShadow: 'rgb(0 0 0 / 30%) 0 0 16px',
                }}
            >
                <Grid container item style={{ width: 'auto', justifyContent: 'space-between' }} direction="row">
                    <Grid item>{isEldercare ? <Header /> : <WhiteHeader />}</Grid>
                    <Grid container item style={{ width: 'auto', alignItems: 'center' }} spacing={1}>
                        <SignUpWrapper businessType={isEldercare ? 'ELDERCARE' : 'CHILD_CARE'}>
                            <Grid container item style={{ width: 'auto', alignItems: 'center' }} spacing={1}>
                                <Grid item style={{ width: 'auto' }}>
                                    <KebabMenu
                                        Icon={MenuIcon}
                                        iconColor={isEldercare ? Colors.white : Colors.darkNavy}
                                        options={kebabOptions.map(function (opt) {
                                            return { text: opt };
                                        })}
                                        onSelect={menuItemSelect}
                                    />
                                </Grid>
                                <PreSignUp
                                    open={open}
                                    smallerVersion={false}
                                    onClose={() => setOpen(false)}
                                    headerText={'How will you use Tandem?'}
                                    showBusinessOption={true}
                                    showProviderOption={true}
                                ></PreSignUp>
                            </Grid>
                        </SignUpWrapper>
                    </Grid>
                </Grid>
                <AuthModal
                    open={showLoginModal}
                    onClose={loginModalClose}
                    location={'Header Navigation: Get Started'}
                    signup={false}
                />
            </Grid>
        </>
    );
}
