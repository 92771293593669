import { isLoggedIn } from 'api/UserApi';
import { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { client, consoleLogInDev } from 'shared';
import useQueryParams from 'shared/QueryParamsHook';

export default function useTrackingCookies(url: string) {
    const [cookies, setCookie] = useCookies([
        'source',
        'referral',
        'ccid',
        'group',
        'esid',
        'ciid',
        'sitter',
        'reid',
        'pt',
    ]);
    const query = useQueryParams();

    useEffect(() => {
        let referral = query.get('referral');
        let source = query.get('source');
        let ccid = query.get('ccid');
        let ciid = query.get('ciid');
        let group = query.get('group');
        let sitter = query.get('sitter');
        let reid = query.get('reid');
        let pt = query.get('pt');

        if (!isLoggedIn() && !cookies.esid) {
            trackEngagement(source, referral, url);
        }
        if (ccid || ciid) {
            if (ccid) setCookie('ccid', ccid, { path: '/' });
            if (ciid) setCookie('ciid', ciid, { path: '/' });
            trackCommunityContactClick(ccid ? parseInt(ccid) : null, ciid ? parseInt(ciid) : null);
        }
        if (group) setCookie('group', group, { path: '/' });
        if (sitter) setCookie('provider_accessor', btoa(sitter), { path: '/' });
        if (reid) setCookie('reid', reid, { path: '/' });
        if (pt) setCookie('pt', pt, { path: '/' });
    }, []);

    function trackEngagement(source: string | null, referral: string | null, url: string) {
        client('tracking/api/engagement-source/', { body: { source, user: null, referral, url }, method: 'POST' })
            .then((response) => {
                setCookie('esid', response.id, { path: '/' });
            })
            .catch(consoleLogInDev)
            .finally(() => {
                if (source) {
                    setCookie('source', source, { path: '/' });
                }
                if (referral) {
                    setCookie('referral', referral, { path: '/' });
                }
            });
    }

    function trackCommunityContactClick(ccid: number | null, ciid: number | null) {
        client('api/community-contact/update-engaged/', {
            body: { ccid, contact_info_id: ciid, content_type: 'ContactInfo' },
            method: 'PATCH',
        }).catch(consoleLogInDev);
    }

    return { cookies };
}
