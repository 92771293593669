import React, { Dispatch, SetStateAction } from 'react';
import { Grid } from '@material-ui/core';
import { Text, PrimaryButton } from 'library';
import { useFormStyles } from '../constants';
import { AutomatedReference } from '../models';
import { useMediaQuery } from '@mui/material';
import theme from 'theme';

export default function ReferenceFormHeading({
    formOpen,
    setFormOpen,
    formOpenLoading,
    setFormOpenLoading,
    automatedReference,
}: {
    formOpen: boolean;
    setFormOpen: Dispatch<SetStateAction<boolean>>;
    formOpenLoading: boolean;
    setFormOpenLoading: Dispatch<SetStateAction<boolean>>;
    automatedReference: AutomatedReference;
}) {
    const styles = useFormStyles();
    const xsDown = useMediaQuery(theme.breakpoints.down('xs'));

    function handleGetStartedButton() {
        setFormOpenLoading(true);
        setTimeout(() => {
            setFormOpen(true);
            setFormOpenLoading(false);
        }, 1000);
    }

    return (
        <Grid container direction="column" style={styles.formHeadingGrid}>
            <Text variant="display" textStyle={{ ...styles.welcomeText, textAlign: xsDown ? 'center' : undefined }}>
                {`Hello ${automatedReference.contact_info.first_name}!`}
            </Text>
            <Grid item container style={{ gap: 5 }} direction="column" alignItems={xsDown ? 'center' : undefined}>
                <Text variant="body1" textStyle={{ ...styles.welcomeText, textAlign: xsDown ? 'center' : undefined }}>
                    Thank you so much for providing a character reference for{' '}
                    <b>{`${automatedReference.provider_user.first_name} ${automatedReference.provider_user.last_name}`}</b>
                    .
                </Text>
                <Text variant="body1" textStyle={{ ...styles.welcomeText, textAlign: xsDown ? 'center' : undefined }}>
                    {"All you'll need to do is fill out a short form and hit submit."}
                </Text>
                <Text variant="body1" textStyle={{ ...styles.welcomeText, textAlign: xsDown ? 'center' : undefined }}>
                    {"We'll take it from there!"}
                </Text>
                {!formOpen && (
                    <PrimaryButton
                        buttonStyle={styles.button}
                        onClick={handleGetStartedButton}
                        loading={formOpenLoading}
                    >
                        Get Started
                    </PrimaryButton>
                )}
            </Grid>
        </Grid>
    );
}
