import React from 'react';
import { CircularProgress, styled } from '@mui/material';
import { Text, Link } from 'library';
import { ProviderRow } from './ProviderRow';

import type { IProviderSearch } from '../models';

export function Results({
    loading,
    error,
    providers,
    selectedProviders,
    handleSelect,
    loadMore,
    handleChange,
    workerType,
}: {
    loading: boolean;
    error: boolean;
    providers: IProviderSearch[];
    selectedProviders: IProviderSearch[];
    handleSelect: (checked: boolean, provider: IProviderSearch) => void;
    loadMore: boolean;
    handleChange: (value: string | boolean, formKey: string) => void;
    workerType: string;
}) {
    if (error)
        return (
            <Text>
                Sorry, something went wrong - please try again. If the problem continues, please contact support.
            </Text>
        );

    return (
        <Container>
            <ResultsContainer>
                {providers.length || loading ? (
                    providers.map((provider: IProviderSearch) => (
                        <ProviderRow
                            key={provider.id}
                            provider={provider}
                            checked={selectedProviders.includes(provider)}
                            handleCheck={(checked: boolean) => handleSelect(checked, provider)}
                            workerType={workerType}
                        />
                    ))
                ) : (
                    <Text variant="h2">No results found</Text>
                )}
            </ResultsContainer>
            <StyledBottom>
                {loading && <CircularProgress />}
                {!loading && loadMore && <Link onClick={() => handleChange(true, 'page')}>Load More</Link>}
            </StyledBottom>
        </Container>
    );
}

const Container = styled('div')({
    alignItems: 'center',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
});

const ResultsContainer = styled('div')({
    width: '100%',
});

const StyledBottom = styled('div')({
    marginTop: 20,
    marginBottom: 40,
});
