import React from 'react';
import { Colors, Text } from 'library';
import { ReactNode, Ref, RefObject, useRef, useState } from 'react';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

interface Expandable<ElementRefType extends HTMLElement> {
    collapseControl: ReactNode;
    expandableContent: ReactNode;
    expandableContentRef: RefObject<ElementRefType>;
}

export default function Expandable<ElementRefType extends HTMLElement>({
    collapseControl,
    expandableContent,
    expandableContentRef,
}: Expandable<ElementRefType>) {
    const [expanded, setExpanded] = useState(false);

    function toggleExpanded() {
        setExpanded(!expanded);
    }

    return (
        <>
            <Text bold variant="body2" onClick={toggleExpanded}>
                {collapseControl}
                <ArrowForwardIosIcon
                    style={{
                        color: Colors.darkNavy,
                        fontSize: 14,
                        fontWeight: 'bolder',
                        transform: !!expanded ? 'rotate(90deg)' : '',
                        marginLeft: 8,
                    }}
                />
            </Text>
            <div
                style={{
                    overflow: 'hidden',
                    height: expanded ? expandableContentRef.current?.clientHeight ?? 0 : 0,
                    transition: 'all 0.25s ease-in-out',
                }}
            >
                {expandableContent}
            </div>
        </>
    );
}
