import React from 'react';
import { Text } from 'library';
import { IBusinessLocation } from 'models';

export default function BusinessLocation({ location }: { location: IBusinessLocation }) {
    return (
        <Text variant="caption">
            <Text variant="caption" inline bold>
                {location.name}
            </Text>
            {` ${location.address.street} ${location.address.city}, ${location.address.state}`}
        </Text>
    );
}
