import React from 'react';
import { useHistory } from 'react-router';
import { Colors, Text, TextButton } from 'library';
import { styled } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarker, faMobileScreenButton } from '@fortawesome/free-solid-svg-icons';
import { Loop, Moving, Star } from '@mui/icons-material';
import ProviderSummaryDetailRow from './ProviderSummaryDetailRow';
import { useProviderProfileContext } from './ProviderProfileContext';
import { getPrioritizedCredentials } from './helpers/Helpers';

import type { Provider, ProviderTag } from 'models';

export default function BusinessProviderSummary({
    provider,
    distanceText,
    mobile,
}: {
    provider: Provider;
    distanceText: string;
    mobile: boolean;
}) {
    const history = useHistory();
    const { providerQualifications, childcareCenterQualifications } = useProviderProfileContext();
    const prioritizedCredentials = getPrioritizedCredentials(providerQualifications, childcareCenterQualifications);
    const details = [
        {
            detail: prioritizedCredentials ? 'Notable Credentials' : '',
            icon: <Star />,
            subDetail: prioritizedCredentials,
            bold: true,
        },
        {
            detail: provider.user.last_active,
            icon: <FontAwesomeIcon icon={faMobileScreenButton} style={{ fontSize: 14 }} />,
            italic: true,
        },
        {
            detail: !!provider.user.address
                ? `${provider.user.address[0].city.trim()}, ${provider.user.address[0].state}`
                : '',
            subDetail: distanceText,
            icon: <FontAwesomeIcon icon={faMapMarker} style={{ fontSize: 12 }} />,
        },
    ];

    const tagDetails: {
        [key: string]: {
            backgroundColor: string;
            text: (value: string) => string;
            icon: (styles: React.CSSProperties) => JSX.Element;
        };
    } = {
        experience_tag: {
            backgroundColor: Colors.pink,
            text: (value: string) => value,
            icon: (styles: React.CSSProperties) => <Moving style={styles} />,
        },
        rebooking_tag: {
            backgroundColor: Colors.turquoise,
            text: (value: string) => value,
            icon: (styles: React.CSSProperties) => <Loop style={styles} />,
        },
        review_score: {
            backgroundColor: Colors.darkNavy,
            text: (value: string) => `${value} Stars`,
            icon: (styles: React.CSSProperties) => <Star style={styles} />,
        },
    };

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                width: mobile ? '75%' : '100%',
                marginLeft: mobile ? 12 : 0,
                marginTop: mobile ? 0 : 10,
            }}
        >
            <div style={{ width: '70%' }}>
                <div style={{ marginBottom: 20 }}>
                    {provider.tags.map(
                        ({ name, value }: ProviderTag) =>
                            !!value && (
                                <Tag key={name} backgroundColor={tagDetails[`${name}`]?.backgroundColor}>
                                    {tagDetails[`${name}`].icon({ color: Colors.white, marginRight: 5 })}
                                    <Text
                                        variant="caption"
                                        textStyle={{ color: Colors.white, whiteSpace: 'nowrap' }}
                                        bold
                                    >
                                        {tagDetails[`${name}`].text(value)}
                                    </Text>
                                </Tag>
                            ),
                    )}
                </div>
                {details.map((detail) =>
                    detail.detail ? <ProviderSummaryDetailRow key={detail.detail} {...detail} /> : null,
                )}
            </div>
            {provider.is_staff ? (
                <div style={{ marginTop: '4%' }}>
                    <TextButton onClick={() => history.push(`/providers/review/${provider.user.id}`)}>
                        Add a Review
                    </TextButton>
                </div>
            ) : null}
        </div>
    );
}

const Tag = styled('div')(({ backgroundColor }: { backgroundColor: string }) => ({
    alignItems: 'center',
    backgroundColor,
    borderRadius: 50,
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 5,
    padding: 5,
    paddingLeft: 10,
    paddingRight: 10,
    width: 'fit-content',
}));
