import { client, formatE164USPhoneNumber } from 'shared';
import { AutomatedReference, WorkerReferenceForm } from './models';
import { E164Number } from 'libphonenumber-js/types';

export function getAutomatedReference(uuid: string): Promise<Array<AutomatedReference>> {
    const link = `api/automated-reference/?uuid=${uuid}`;
    return client(link);
}

export function submitReferenceForm(
    automatedReferenceId: number,
    uuid: string,
    form: WorkerReferenceForm,
): Promise<AutomatedReference> {
    const body = {
        first_name: form.first_name,
        last_name: form.last_name,
        email: form.email,
        phone_number: formatE164USPhoneNumber(form.phone_number),
        reference_text: form.reference_text,
        relationship_to_worker: form.relationship === 'Other' ? form.relationship_free_input : form.relationship,
    };
    return _updateAutomatedReference(automatedReferenceId, uuid, body);
}

function _updateAutomatedReference(
    automatedReferenceId: number,
    uuid: string,
    body: {
        [key: string]: string | number | E164Number | null | undefined | Record<string, string>;
    },
): Promise<AutomatedReference> {
    return client(`api/automated-reference/${automatedReferenceId}/?uuid=${uuid}`, {
        method: 'PATCH',
        body: body,
    });
}
