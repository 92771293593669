import React from 'react';
import DateFnsUtils from '@date-io/moment';
import { KeyboardTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Moment } from 'moment';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import AccessTime from '@material-ui/icons/AccessTime';

interface IMaterialTimeInputProps {
    label?: string;
    value?: Moment | Date | string | null;
    placeholder?: string;
    onChange: (time: MaterialUiPickersDate, value?: string | null) => void;
}

export default function MaterialTimeInput({ label, value, placeholder, onChange }: IMaterialTimeInputProps) {
    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardTimePicker
                label={label}
                placeholder={placeholder}
                mask="__:__ _M"
                value={value || null}
                initialFocusedDate={null}
                onChange={onChange}
                variant="inline"
                style={{ maxWidth: 150 }}
                keyboardIcon={<AccessTime />}
            />
        </MuiPickersUtilsProvider>
    );
}
