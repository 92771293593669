import { KeyboardDateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { Colors, Link } from 'library';
import DateFnsUtils from '@date-io/moment';
import React from 'react';
import { FaCalendarCheck } from 'react-icons/fa';
import moment from 'moment';

export default function DateTimePicker({
    value,
    onSelectDate,
}: {
    value?: moment.Moment | null | Date;
    onSelectDate: (selectedDate: MaterialUiPickersDate) => void;
}) {
    function getErrorMessage() {
        return !value ? '' : 'Invalid Date/Time Format';
    }
    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <KeyboardDateTimePicker
                    autoOk
                    format="MM/DD/YYYY h:mm a"
                    mask="__/__/____"
                    value={value ? moment(value) : null}
                    onChange={onSelectDate}
                    openTo="date"
                    showTodayButton
                    style={{ width: '100%' }}
                    keyboardIcon={<FaCalendarCheck color={Colors.darkNavy} />}
                    clearable
                    invalidDateMessage={getErrorMessage()}
                />
                <Link style={{ marginLeft: 4 }} onClick={() => onSelectDate(moment())}>
                    now
                </Link>
            </div>
        </MuiPickersUtilsProvider>
    );
}
