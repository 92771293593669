import React, { useEffect, useState } from 'react';
import { Grid, useMediaQuery } from '@mui/material';
import { PrimaryButton, Text, SizeableRoundedDialog } from 'library';
import theme from 'theme';
import { BusinessJobResponse } from './models';
import useLoading from 'library/hooks/useLoading';
import { client, consoleLogInDev } from 'shared';
import useForm from 'parent-portal/forms/components/FormHook';
import { JobDetail, JobDetailValueWithOption } from 'models';
import JobDetailInput from 'parent-portal/forms/components/pages/HeadlineDescriptionPage/JobDetailInput';
import FormField from 'parent-portal/forms/components/FormField';

export default function EditDetailsModal({
    open,
    onClose,
    businessJobResponse,
    onSaveComplete,
}: {
    open: boolean;
    onClose: () => void;
    businessJobResponse: BusinessJobResponse;
    onSaveComplete: (title: string, description: string) => void;
}) {
    const small = useMediaQuery(theme.breakpoints.down('sm'));
    const form = useForm<{
        jobDetails: JobDetailValueWithOption[];
    }>({
        jobDetails: businessJobResponse.job_details.details,
    });
    const { loading, setLoading } = useLoading();
    const [title, setTitle] = useState<string>(businessJobResponse.job_details.headline);
    const [description, setDescription] = useState(businessJobResponse.job_details.family_comments);
    const [details, setDetails] = useState<JobDetail[]>([]);

    const { jobDetails } = form.fieldStates;

    const getJobDetails = () => {
        if (details.length) {
            return;
        }

        client('api/job-detail/').then(setDetails).catch(consoleLogInDev);
    };

    useEffect(getJobDetails, [details.length]);

    useEffect(() => {
        setTitle(businessJobResponse.job_details.headline);
        setDescription(businessJobResponse.job_details.family_comments);
    }, [businessJobResponse]);

    function submit() {
        setLoading(true);
        client(`api/ongoing/${businessJobResponse?.job_details?.id}/`, {
            method: 'PATCH',
            body: { job_details: jobDetails.value.map((detail) => ({ ...detail, option: detail.option_id })) },
        })
            .then(() => onSaveComplete(title, description))
            .finally(() => {
                setLoading(false);
                onClose();
            });
    }

    return (
        <SizeableRoundedDialog
            open={open}
            onClose={onClose}
            fullScreen={small}
            closeButton
            maxWidth="md"
            style={{ maxWidth: 500 }}
        >
            <Grid container item direction="column" style={{ padding: 20, gap: 10 }}>
                <Text variant="h1">Update Details</Text>
                {details.map((detail) => (
                    <FormField title={detail.name} key={detail.id}>
                        <JobDetailInput detail={detail} jobDetails={jobDetails} />
                    </FormField>
                ))}
                <PrimaryButton
                    loading={loading}
                    disabled={loading}
                    onClick={submit}
                    buttonStyle={{ width: undefined, alignSelf: 'flex-end' }}
                >
                    Save
                </PrimaryButton>
            </Grid>
        </SizeableRoundedDialog>
    );
}
