import React from 'react';
import { Grid } from '@material-ui/core';
import { PrimaryButton, SecondaryButton } from 'library';
import ErrorText from 'parent-portal/forms/components/ErrorText';
import { BookProviderSubmitButtonProps } from './models';
import { useHistory } from 'react-router-dom';

export default function BookProviderSubmitButton({
    errorMessage,
    loading,
    showSuccess,
    onBookPress,
    onBookAgainPress,
}: BookProviderSubmitButtonProps) {
    const history = useHistory();

    return (
        <Grid container item direction="column">
            <Grid item style={{ alignSelf: 'center', marginTop: 20 }}>
                <ErrorText>{errorMessage}</ErrorText>
            </Grid>
            <Grid container item direction="row" spacing={2} style={{ justifyContent: 'flex-end' }}>
                {showSuccess ? (
                    <Grid item>
                        <SecondaryButton
                            onClick={() => {
                                history.push('/jobs');
                            }}
                            buttonStyle={{ maxWidth: 150 }}
                        >
                            View Jobs
                        </SecondaryButton>
                    </Grid>
                ) : (
                    <></>
                )}
                <Grid item>
                    <PrimaryButton
                        onClick={!showSuccess ? onBookPress : onBookAgainPress}
                        loading={loading}
                        disabled={loading}
                        buttonStyle={{ maxWidth: 150 }}
                    >
                        {!showSuccess ? 'Send' : 'Book Again'}
                    </PrimaryButton>
                </Grid>
            </Grid>
        </Grid>
    );
}
