import React, { useState, useEffect } from 'react';
import { Block } from '@mui/icons-material';

import { consoleLogInDev } from 'shared';
import { BasicDialog, IconButton } from 'library';
import { getBusinessOutlawLocations } from 'api/BusinessOutlawsApi';
import { BusinessSearch, OutlawModalBody, UserInfo } from './components';
import type { LeanUser, BusinessWithBusinessOutlaws } from 'models';

export function ProviderOutlawModal({
    user,
    onClose,
    defaultNameOrId,
}: {
    user: LeanUser;
    onClose?: () => void;
    defaultNameOrId?: string | number | null;
}) {
    const [open, setOpen] = useState<boolean>(false);
    const [outlawed, setOutlawed] = useState<BusinessWithBusinessOutlaws[]>([]);
    const [nameOrId, setNameOrId] = useState<string | number | null>(defaultNameOrId ?? null);
    const [search, setSearch] = useState<boolean>(true);

    useEffect(() => {
        if (open && user.id && search) {
            getBusinessOutlawLocations(user.id, nameOrId).then(setOutlawed).catch(consoleLogInDev);
            setSearch(false);
        }
    }, [open, user.id, nameOrId, search]);

    return (
        <>
            <span style={{ marginLeft: 10 }}>
                <IconButton tooltip="Add to banned workers" icon={Block} onClick={() => setOpen(true)}></IconButton>
            </span>
            <BasicDialog
                dialogTitle={`Business Bans`}
                closeButton
                onClose={() => {
                    setOpen(false);
                    onClose?.();
                }}
                isOpen={open}
            >
                <BusinessSearch searchHandler={() => setSearch(true)} setNameOrId={setNameOrId} />
                <UserInfo user={user} outlawed={outlawed} />
                {outlawed.map((business: BusinessWithBusinessOutlaws) => (
                    <OutlawModalBody key={business.id} business={business} user={user} />
                ))}
            </BasicDialog>
        </>
    );
}
