import { useMediaQuery } from '@material-ui/core';
import { Grid } from '@mui/material';
import { Colors } from 'library';
import React, { useEffect, useRef } from 'react';
import StatesMap from 'assets/images/marketing-site/StatesMap.png';
import theme from 'theme';
import { InfoSection } from './InfoSection';
import { HereToHelpBlock } from './HereToHelpBlock';
import ThankYouBlock from './ThankYouBlock';
import { SoonBlock } from './SoonBlock';
import { GoingWellBlock } from './GoingWellBlock';
import { ArcherContainer, ArcherContainerRef } from 'react-archer';

export default function Timeline() {
    const smDown = useMediaQuery(theme.breakpoints.down('sm'));
    const arrowRef = useRef<ArcherContainerRef | null>(null);
    useEffect(() => {
        if (arrowRef.current) arrowRef.current?.refreshScreen();
    }, [arrowRef]);

    const topMargin = smDown ? 40 : 80;
    const blocks = [
        {
            side: 'left',
            content: <ThankYouBlock />,
            year: '2019',
            style: { left: '110%', bottom: '55%', backgroundColor: Colors.lightTerraCotta },
            containerStyle: { marginTop: topMargin },
            targetYear: 'Now',
        },
        {
            side: 'right',
            content: <HereToHelpBlock />,
            year: '2022',
            style: { right: '112%', bottom: '72%', backgroundColor: Colors.lightRed },
            containerStyle: { marginTop: topMargin, zIndex: 100 },
        },
        {
            side: 'left',
            content: <GoingWellBlock />,
            year: 'Now',
            style: { left: '110%', bottom: '55%', backgroundColor: Colors.lightTurq },
            containerStyle: { marginTop: topMargin },
            targetYear: 'Soon',
        },
        {
            side: 'right',
            content: <SoonBlock />,
            year: 'Soon',
            style: { right: '112%', bottom: '72%', backgroundColor: Colors.lightGreen },
            containerStyle: { marginTop: smDown ? 0 : 450 },
            targetBlock: 'none',
            extra: smDown ? (
                <div style={{ padding: 20, zIndex: 100 }}>
                    <img
                        src={StatesMap}
                        alt="states map"
                        style={{ height: screen.width * 0.72, maxWidth: screen.width }}
                    />
                </div>
            ) : (
                <div style={{ height: 0, width: 0, position: 'relative', bottom: '-20%', right: '107%' }}>
                    <img src={StatesMap} alt="states map" />
                </div>
            ),
        },
    ];

    return (
        <ArcherContainer strokeColor={Colors.darkNavy} offset={20} ref={arrowRef} svgContainerStyle={{ zIndex: 0 }}>
            <Grid
                xs={12}
                item
                container
                style={{ padding: 80, backgroundColor: `rgba(249,217,220,.2)`, overflow: 'hidden' }}
                justifyContent="center"
            >
                <Grid item container xs={12} md={8} justifyContent="center">
                    {blocks.map((block) => (
                        <>
                            {block.extra && block.extra}
                            <InfoSection
                                key={block.year}
                                year={block.year}
                                side={block.side}
                                style={block.style}
                                containerStyle={block.containerStyle}
                                targetYear={block.targetYear}
                                targetBlock={block.targetBlock}
                            >
                                {block.content}
                            </InfoSection>
                        </>
                    ))}
                </Grid>
            </Grid>
        </ArcherContainer>
    );
}
