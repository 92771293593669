import { Address } from 'models';

const toFileName = (description: string) => {
    // Replace any non-word char (\W) with '_' and remove consecutive underscores
    return description.replace(/\W/g, '_').replace(/_+/g, '_');
};

export const downloadQRCode = (address: Address) => {
    const inMemoryCanvas = document.createElement('canvas');
    inMemoryCanvas.height = 550;
    inMemoryCanvas.width = 550;

    const qrImage = document.getElementById(`qrCode${address.id}`) as HTMLImageElement;
    const link = document.createElement('a');

    if (!qrImage) return;

    const ctx = inMemoryCanvas.getContext('2d');

    if (ctx) {
        ctx.drawImage(qrImage, 50, 50, qrImage.width, qrImage.height);
    }

    link.href = inMemoryCanvas.toDataURL();
    link.download = `${toFileName(address.description || '')}_QRCode.png`;
    link.click();
};
