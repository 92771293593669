import React, { Component } from 'react';
import moment from 'moment';
// eslint-disable-next-line import/no-named-as-default
import styled from 'styled-components';
import { uuid } from 'uuidv4';
import { withRouter } from 'react-router-dom';
import ProfileHeader from './ProfileHeader';
import AccountDisplay from './displays/AccountDisplay';
import FamilyDisplay from './displays/FamilyDisplay';
import AccountEditDisplay from './displays/AccountEditDisplay';
import PreferenceDisplay from './displays/PreferenceDisplay';
import PreferenceEditDisplay from './displays/PreferenceEditDisplay';
import PayMethods from '../payments/Components/PayMethods';
import { Grid } from '@material-ui/core';
import { client, consoleLogInDev } from 'shared';
import { UserContext } from 'UserContext';
import { trackEvent, PublicSessionEventType } from 'parent-portal/shared/PublicSessionTracking';

const Section = styled.div`
    align-content: center;
    align-items: center;
    justify-content: center;
`;

const certificates = {
    CPR_CERTIFIED: 'CPR Certified',
    FIRST_AID_CERTIFIED: 'First Aid Certified',
    SLEEP_TRAINING_CERTIFIED: 'Sleep Training Certified',
    INFANT_CERTIFIED: 'Infant Certified',
};
const experiences = {
    AUTISM: 'Autism',
    ADHD: 'ADHD',
    LEARNING_DELAYS: 'Learning Delays (i.e. 504 plan/IEP)',
    ALLERGIES: 'Allergies',
    DEVELOPMENT_DELAYS: 'Development Delays',
    BRAIN_INJURY: 'Brain Injury',
    IMMUNOCOMPROMISED_PERSONS: 'Immunocompromised Persons',
};

class FamilyProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: {},
            family: {},
            address: {},
            families: [],
            phoneNumbers: [],
            isZip: true,
            isCity: true,
            isState: true,
            isStreet: true,
            isFirstName: true,
            isLastName: true,
            isEmail: true,
            isPrimaryPhoneNumber: true,
            isSecondaryPhoneNumber: true,
            isBio: true,
            isBirthday: true,
            page: 0,
            gmapsLoaded: false,
            errorMessage: 'Please enter your phone number',
            firstName: '',
            lastName: '',
            city: '',
            state: '',
            street: '',
            zip: '',
            primaryPhoneNumber: '',
            parsedPrimaryNumber: '',
            secondaryPhoneNumber: '',
            parsedSecondaryNumber: '',
            email: '',
            bio: '',
            addedAt: '',
            userId: -1,
            familyId: -1,
            payments: [],
            paymentsLoadingId: null,
            profilePic: null,
            pictureDialogVisible: false,
            certificationPreferences: [],
            experiencePreferences: [],
        };
    }
    static contextType = UserContext;

    patchPreference = async () => {
        this.patchFamily();
        this.pageHandler(7);
    };

    initMap = () => {
        this.setState({
            gmapsLoaded: true,
        });
    };

    pageHandler(p) {
        this.setState({ page: p });
    }

    edit_account() {
        this.setState({ page: 3 });
    }

    deleteNumber = async () => {
        const { phoneNumbers } = this.state;

        const newArray = phoneNumbers;

        newArray.splice(1);

        await this.setState({ secondaryPhoneNumber: null });

        await this.setState({ phoneNumbers: newArray });

        this.patchUser();
    };

    onChangeText = async (name, e) => {
        const text = e.target.value;
        if (name === 'phone_number') {
            var match = text.match(/^(1)(\d{3})(\d{3})(\d{4})$/);
            var cleaned = ('' + text).replace(/\D/g, '');
            match = cleaned.match(/^(1)(\d{3})(\d{3})(\d{4})$/);
            if (match) {
                var number = ['(', match[2], ') ', match[3], ' - ', match[4]].join('');
                this.setState({ primaryPhoneNumber: number });
                return;
            }
            this.setState({ primaryPhoneNumber: text });
        } else if (name === 'secondary_phone_number') {
            match = text.match(/^(1)(\d{3})(\d{3})(\d{4})$/);
            if (match) {
                number = ['(', match[2], ') ', match[3], ' - ', match[4]].join('');
                this.setState({ secondaryPhoneNumber: number });
                return;
            }
            this.setState({ secondaryPhoneNumber: text });
        } else {
            this.setState({ [name]: text });
        }
    };

    validatePhoneNumber(phoneNumber) {
        var letters = /^[A-Za-z]+$/;

        if (phoneNumber.length === 16 && !phoneNumber.match(letters)) {
            return true;
        }
        return false;
    }

    validateDate(date) {
        var valid = moment(date, 'YYYY-MM-DD').isValid();
        return valid;
    }

    patchFamily = async () => {
        const { primaryPhoneNumber, bio, addedAt, family, certificationPreferences, experiencePreferences } =
            this.state;
        var parsed_date = moment(addedAt, 'MM/DD/YYYY hh:mm A').format('YYYY-MM-DD HH:mm:ss');
        var family_id = family.id;
        var parsedNumber =
            '+1' + primaryPhoneNumber.slice(1, 4) + primaryPhoneNumber.slice(6, 9) + primaryPhoneNumber.slice(12, 16);
        var new_family = {
            added_at: parsed_date,
            phone_number: parsedNumber,
            bio: bio,
            covid_exempt: false,
            certification_preferences: certificationPreferences,
            experience_preferences: experiencePreferences,
        };
        try {
            await client('api/families/' + family_id + '/', { body: new_family, method: 'PATCH' });

            this.getFamily();
        } catch (error) {
            consoleLogInDev(error);
        }
    };

    patchAddress = async () => {
        const { street, city, state, zip, address } = this.state;
        var address_id = address.id;
        var user_id = address.user;
        var new_address = {
            street: street,
            state: state,
            city: city,
            zip_code: zip,
            user: user_id,
        };
        try {
            await client('api/addresses/' + address_id + '/', {
                method: 'PATCH',
                body: new_address,
            });
            this.getAddresses();
            this.context.refreshUser();
        } catch (error) {
            consoleLogInDev(error);
        }
    };

    patchUser = async () => {
        const { primaryPhoneNumber, firstName, lastName, secondaryPhoneNumber, user } = this.state;

        var id = user.id;
        var parsedNumber =
            '+1' + primaryPhoneNumber.slice(1, 4) + primaryPhoneNumber.slice(6, 9) + primaryPhoneNumber.slice(12, 16);
        var new_user = {
            first_name: firstName,
            last_name: lastName,
            phone_number: parsedNumber,
            secondary_phone_number: null,
            is_active: true,
            staff: false,
            admin: false,
            is_superuser: false,
            rand_hex: uuid(),
        };
        if (secondaryPhoneNumber !== null) {
            var parsedSecondaryNumber =
                '+1' +
                secondaryPhoneNumber.slice(1, 4) +
                secondaryPhoneNumber.slice(6, 9) +
                secondaryPhoneNumber.slice(12, 16);
            new_user = {
                first_name: firstName,
                last_name: lastName,
                phone_number: parsedNumber,
                secondary_phone_number: parsedSecondaryNumber,
                is_active: true,
                staff: false,
                admin: false,
                is_superuser: false,
            };
        }

        try {
            await client('api/users/' + id + '/', {
                method: 'PATCH',
                body: new_user,
            });
            this.getUser();
        } catch (error) {
            consoleLogInDev(error);
        }
    };

    componentDidMount = async () => {
        await this.getUser();
        await this.getFamily();
        await this.getAddresses();
        await this.getPayments();
        await this.getProfilePicture();

        await this.trackPageArrival();
    };

    getUser = async () => {
        try {
            const user = await client('api/users/identity/');
            this.setState({
                userId: user.id,
                user: user,
                firstName: user.first_name,
                lastName: user.last_name,
                email: user.email,
                primaryPhoneNumber: user.phone_number ? this.formatPhoneNumber(user.phone_number) : '',
                secondaryPhoneNumber: user.secondary_phone_number,
            });

            if (this.state.secondaryPhoneNumber !== null) {
                this.setState({
                    secondaryPhoneNumber: this.formatPhoneNumber(user.secondaryPhoneNumber),
                    phoneNumbers: [this.state.primaryPhoneNumber, this.state.secondaryPhoneNumber],
                });
            } else {
                this.setState({
                    phoneNumbers: [this.state.primaryPhoneNumber],
                });
            }
        } catch (error) {
            consoleLogInDev(error);
        }
    };

    formatPhoneNumber(phoneNumber) {
        return phoneNumber
            ? `(${phoneNumber.slice(2, 5)}) ${phoneNumber.slice(5, 8)} - ${phoneNumber.slice(8, 12)}`
            : null;
    }

    getAddresses = async () => {
        try {
            const addresses = await client('api/addresses/');
            const address = addresses.find((x) => x.address_type === 'PRIMARY');
            this.setState({
                address: address,
                street: address?.street,
                city: address?.city,
                state: address?.state,
                zip: address?.zip_code,
            });
        } catch (error) {
            consoleLogInDev(error);
        }
    };

    getFamily = async () => {
        try {
            const families = await client('api/families/');

            this.setState({
                family: families[0],
                addedAt: families[0].added_at,
                bio: families[0].bio,
                familyId: families[0].id,
                primaryUser: families[0].user,
                certificationPreferences: families[0].certification_preferences,
                experiencePreferences: families[0].experience_preferences,
            });
        } catch (error) {
            consoleLogInDev(error);
        }
    };
    getPayments = async () => {
        try {
            const apiPayInfo = await client('api/get-payment-setup/');
            this.setState({ payments: apiPayInfo });
        } catch (error) {
            consoleLogInDev(error);
        }
    };

    setDefaultPayment = (id) => {
        this.setState({
            payments: this.state.payments.map((x) => {
                return {
                    ...x,
                    errorMessage: '',
                    isLoading: x.id === id,
                };
            }),
        });
        client(`api/payment-info/${id}/default/`, { method: 'POST' })
            .then(() => {
                this.setState({
                    payments: this.state.payments.map((x) => {
                        return {
                            ...x,
                            errorMessage: '',
                            isLoading: false,
                            is_default: x.id === id,
                        };
                    }),
                });
            })
            .catch(() => {
                this.setState({
                    payments: this.state.payments.map((x) => {
                        return {
                            ...x,
                            errorMessage: x.id === id ? 'An error occurred updating your payment method' : '',
                            isLoading: false,
                        };
                    }),
                });
            });
    };

    getProfilePicture = async () => {
        try {
            const profilePic = await client('get-picture/' + this.state.user.id);
            this.setState({ profilePicture: profilePic });
        } catch (error) {
            consoleLogInDev(error);
        }
    };

    trackPageArrival = async () => {
        try {
            await trackEvent(
                'NAVIGATE_TO',
                'Arrived on page',
                PublicSessionEventType.Page,
                {},
                0,
                0,
                'Profile',
                '/profile/',
            );
        } catch (error) {
            consoleLogInDev(error);
        }
    };

    validate() {
        const { page } = this.state;
        switch (page) {
            case 3:
                // eslint-disable-next-line no-case-declarations
                const { primaryPhoneNumber, secondaryPhoneNumber, firstName, lastName, street, city, state } =
                    this.state;
                if (secondaryPhoneNumber !== null) {
                    this.setState({ errorMessage: 'Please enter your phone number' });
                    let sameNumber = secondaryPhoneNumber !== primaryPhoneNumber;
                    if (!sameNumber) {
                        this.setState({
                            errorMessage: 'Your secondary phone number cannot be the same as your primary number',
                        });
                    }
                    this.setState({
                        isSecondaryPhoneNumber:
                            secondaryPhoneNumber !== '' &&
                            sameNumber &&
                            !/^\s*$/.test(secondaryPhoneNumber) &&
                            this.validatePhoneNumber(secondaryPhoneNumber),
                    });
                } else {
                    this.setState({ isSecondaryPhoneNumber: true });
                }
                this.setState(
                    {
                        isPrimaryPhoneNumber:
                            primaryPhoneNumber !== '' &&
                            !/^\s*$/.test(primaryPhoneNumber) &&
                            this.validatePhoneNumber(primaryPhoneNumber),
                        isFirstName: firstName !== '' && !/^\s*$/.test(firstName),
                        isLastName: lastName !== '' && !/^\s*$/.test(lastName),
                        isEmail: true,
                        isStreet: street !== '' && !/^\s*$/.test(street),
                        isCity: city !== '' && !/^\s*$/.test(city),
                        isZip: true,
                        isState: state !== '',
                    },
                    () => {
                        const {
                            isPrimaryPhoneNumber,
                            isFirstName,
                            isLastName,
                            isEmail,
                            isStreet,
                            isCity,
                            isZip,
                            isState,
                        } = this.state;
                        if (
                            isPrimaryPhoneNumber &&
                            this.state.isSecondaryPhoneNumber &&
                            isFirstName &&
                            isLastName &&
                            isEmail &&
                            isStreet &&
                            isCity &&
                            isZip &&
                            isState
                        ) {
                            this.patchAddress();
                            this.patchUser();
                            this.patchFamily();
                            this.setState({ page: 0 });
                        }
                    },
                );
                return;
            default:
                return;
        }
    }

    tabHandler() {
        const { user, family, address, page, certificationPreferences, experiencePreferences } = this.state;

        switch (page) {
            case 0:
                return (
                    <AccountDisplay
                        user={user}
                        address={address}
                        edit_account={() => this.edit_account()}
                        primaryPhoneNumber={this.state.primaryPhoneNumber}
                        secondaryPhoneNumber={this.state.secondaryPhoneNumber}
                    />
                );
            case 1:
                return <FamilyDisplay family={family} />;
            case 3:
                // eslint-disable-next-line no-case-declarations
                const {
                    isFirstName,
                    firstName,
                    isLastName,
                    lastName,
                    isPrimaryPhoneNumber,
                    primaryPhoneNumber,
                    isSecondaryPhoneNumber,
                    secondaryPhoneNumber,
                    street,
                    isStreet,
                    city,
                    isCity,
                    state,
                    streetNumber,
                    isState,
                    zip,
                    isZip,
                    errorMessage,
                } = this.state;
                return (
                    <AccountEditDisplay
                        isFirstName={isFirstName}
                        firstName={firstName}
                        isLastName={isLastName}
                        lastName={lastName}
                        isPrimaryPhoneNumber={isPrimaryPhoneNumber}
                        primaryPhoneNumber={primaryPhoneNumber}
                        isSecondaryPhoneNumber={isSecondaryPhoneNumber}
                        secondaryPhoneNumber={secondaryPhoneNumber}
                        street={street}
                        streetNumber={streetNumber}
                        isStreet={isStreet}
                        city={city}
                        isCity={isCity}
                        state={state}
                        isState={isState}
                        zip={zip}
                        isZip={isZip}
                        errorMessage={errorMessage}
                        phoneNumbers={this.state.phoneNumbers}
                        onChangeText={(e, m) => this.onChangeText(e, m)}
                        deleteNumber={() => this.deleteNumber()}
                        gmapsLoaded={this.state.gmapsLoaded}
                        validate={() => this.validate()}
                        stateHandler={(e) => this.setState(e)}
                    />
                );
            case 6:
                return (
                    <Grid container justify="center">
                        <Grid item sm={6} xs={10}>
                            <PayMethods />
                        </Grid>
                    </Grid>
                );
            case 7:
                return (
                    <PreferenceDisplay
                        certificationPreferences={certificationPreferences}
                        experiencePreferences={experiencePreferences}
                        pageHandler={(page) => this.pageHandler(page)}
                        certificates={certificates}
                        experiences={experiences}
                    />
                );
            case 8:
                return (
                    <PreferenceEditDisplay
                        certificationPreferences={certificationPreferences}
                        experiencePreferences={experiencePreferences}
                        certificates={certificates}
                        experiences={experiences}
                        setCerts={(e) => this.setState(e)}
                        setExps={(e) => this.setState(e)}
                        patchPreference={(e) => this.patchPreference()}
                    />
                );
            default:
                return;
        }
    }

    render() {
        const { user, primaryUser, page, profilePicture, pictureDialogVisible } = this.state;
        return (
            <Section>
                <ProfileHeader
                    user={user}
                    primaryUser={primaryUser}
                    page={page}
                    refreshProfileImage={this.getProfilePicture}
                    profilePicture={profilePicture}
                    setProfilePicture={(url) => this.setState({ profilePicture: url })}
                    setPictureDialogVisible={(value) => this.setState({ pictureDialogVisible: value })}
                    pictureDialogVisible={pictureDialogVisible}
                    pageHandler={(page) => this.pageHandler(page)}
                />
                {this.tabHandler()}
            </Section>
        );
    }
}

export default withRouter(FamilyProfile);
