import React, { useEffect, useState } from 'react';
import { BasicDialog, LoadingSpinner, PrimaryButton } from 'library';
import { DCF_TRANSCRIPT, SIGNED_MED_FORM, verifyForm } from 'api/AdminDocumentApi';
import DocumentVerificationContent from '../texting/outreach/documents/DocumentVerificationContent';
import { DocumentToVerify, FormDetails } from './models';
import {
    FloridaTrainingFields,
    IComplianceRequirement,
    IRequirementOptions,
} from 'internal/texting/outreach/documents/models';
import { IInfoModalDoc } from 'models';
import { useClient } from 'shared';

export default function VerifyModal({
    verifyModalOpen,
    setVerifyModalOpen,
    form,
    refresh,
    formDetails,
    activeWorker,
}: {
    verifyModalOpen: boolean;
    setVerifyModalOpen: (x: boolean) => void;
    form: DocumentToVerify | IInfoModalDoc | undefined;
    refresh?: () => void;
    formDetails?: FormDetails;
    activeWorker: number;
}) {
    const formName = form?.name ?? form?.text ?? '';
    const [signedAt, setSignedAt] = useState<Date | undefined>(undefined);
    const [isSesameDocument, setIsSesameDocument] = useState(false);
    const [expirationDate, setExpirationDate] = useState<Date | undefined>(undefined);
    const [checkboxStates, setCheckboxStates] = useState<FloridaTrainingFields>({});
    const [requirementOptions, setRequirementOptions] = useState<IRequirementOptions[]>([]);

    const selectedDocumentType = (form as DocumentToVerify)?.document_types?.find(
        (dt) => dt.document_type_id === formDetails?.document_type_id,
    );
    const {
        data: complianceRequirements,
        send: fetchComplianceRequirements,
        loading: complianceRequirementsLoading,
    } = useClient<IComplianceRequirement[]>({
        url: `api/compliance-requirements/?user_id=${activeWorker}`,
        options: { sendImmediately: false },
    });

    useEffect(() => {
        if (complianceRequirements) {
            const newRequirementOptions = complianceRequirements
                .filter((req) => req.document_type === selectedDocumentType?.document_type_id)
                .map((req) => ({
                    requirement_type: req.requirement.name,
                    completed: req.completed,
                    disabled: req.completed, // NOTE: completed requirements are disabled
                }));
            setRequirementOptions(newRequirementOptions);
        }
    }, [complianceRequirements]);

    useEffect(() => {
        if (verifyModalOpen) fetchComplianceRequirements();
    }, [verifyModalOpen]);

    function onVerify() {
        if (refresh) {
            setVerifyModalOpen(false);
            refresh();
        }
    }

    const showMedVersion = selectedDocumentType
        ? selectedDocumentType.friendly_name === 'Medical Statement'
        : formName.includes(SIGNED_MED_FORM);
    const formDetailsToSubmit = {
        ...formDetails,
        document_type: selectedDocumentType?.document_type,
        is_sesame_document: isSesameDocument,
        satisfied_document_requirements: requirementOptions
            .filter((req) => req.completed && !req.disabled)
            .map((req) => req.requirement_type),
    };

    function updateRequirementOptions(key: string, value: boolean) {
        const newValues = requirementOptions.map((req) => {
            if (req.requirement_type === key) {
                return { ...req, completed: value };
            }
            return req;
        });
        setRequirementOptions(newValues);
    }

    const disabledVerify =
        (showMedVersion && !signedAt) ||
        (requirementOptions.length > 0 &&
            !requirementOptions.filter((req) => !req.disabled).some((req) => req.completed));

    return (
        <BasicDialog closeButton isOpen={verifyModalOpen} onClose={() => setVerifyModalOpen(false)}>
            {complianceRequirementsLoading ? (
                <LoadingSpinner />
            ) : (
                <>
                    <DocumentVerificationContent
                        signedAt={signedAt}
                        setSignedAt={setSignedAt}
                        documentType={selectedDocumentType?.document_type ?? ''}
                        expirationDate={expirationDate}
                        setExpirationDate={setExpirationDate}
                        checkboxStates={checkboxStates}
                        setCheckboxStates={setCheckboxStates}
                        activeWorker={activeWorker}
                        isSesameDocument={isSesameDocument}
                        setIsSesameDocument={setIsSesameDocument}
                        dcfDocument={!!formName.includes(DCF_TRANSCRIPT)}
                        updateRequirementOptions={updateRequirementOptions}
                        requirementOptions={requirementOptions}
                    />
                    <PrimaryButton
                        buttonStyle={{ marginTop: 20, marginBottom: 20 }}
                        disabled={disabledVerify}
                        onClick={() => {
                            verifyForm(
                                form?.id,
                                form?.name,
                                signedAt,
                                expirationDate,
                                onVerify,
                                formDetailsToSubmit,
                                checkboxStates,
                            );
                        }}
                    >
                        Verify
                    </PrimaryButton>
                </>
            )}
        </BasicDialog>
    );
}
