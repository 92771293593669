import React from 'react';
import { Text } from 'library';
import { useMediaQuery } from '@mui/material';
import theme from 'theme';

function Fee({ text, amount }: { text: string; amount: number }) {
    return (
        <Text textStyle={{ marginTop: 20 }}>
            {text} <b>${amount.toFixed(2)}</b>
        </Text>
    );
}

export default function Fees() {
    const smDown = useMediaQuery(theme.breakpoints.down('sm'));
    return (
        <div style={{ padding: smDown ? 10 : 100 }}>
            <Text variant="display" textStyle={{ marginBottom: 40 }}>
                TandemPay Account Fees
            </Text>
            <Text textStyle={{ fontStyle: 'italic', marginTop: 20 }}>All currency is in USD</Text>
            <Fee text="Physical Card Issuance Fee" amount={0} />
            <Fee text="Physical Card Replacement Fee" amount={0} />
            <Fee text="Transfer to a Bank Fee" amount={1} />
            <Fee text="ATM Balance Inquiry Fee" amount={0.5} />
            <Fee text="ATM Cash Withdrawal Fee (within USA)" amount={1.95} />
            <Fee text="ATM Cash Withdrawal Fee (outside USA)" amount={2.5} />
            <Fee text="ATM Decline Fee" amount={0.5} />
            <Fee text="Bank Teller Cash Withdrawal Fee" amount={1.95} />
            <Fee text="Bank Teller Cash Withdrawal Decline Fee" amount={0.5} />
            <Fee text="Bank Transfer Stop/Reversal/Return/Notification of Change (NOC) Fee" amount={5} />
            <Fee text="Debit Purchase Transaction Fee with or without PIN (Cash Back)" amount={0.59} />
            <Fee text="Purchase Transaction Decline Fee - Signature or PIN" amount={0.5} />
            <Fee text="Monthly Service Fee for active accounts" amount={0} />
            <Fee text="Monthly Service Fee for inactive accounts greater than 120 days" amount={5} />
            <Fee text="Paper Check Fee" amount={5} />
            <Fee text="Paper Check Stop Fee" amount={25} />
            <Fee text="Paper Statement Fee" amount={3.95} />
            <Text textStyle={{ marginTop: 20 }}>
                Foreign Transaction Fee (applies if settlement currency is not USD) <b>3%</b> of Transaction Amount
            </Text>
            <Fee text="Account Closure and/or Balance Refund Fee" amount={15} />
        </div>
    );
}
