import React from 'react';
import { PrimaryButton, TextButton } from 'library';
import { UpdatedTimes } from './TimestampModal';
import { HourReportTimestamp } from '../HourReportModels';

export function RoleActions({
    role,
    times,
    updateAdminTimes,
    resolve,
    selectedTimestamps,
}: {
    role: string;
    times: UpdatedTimes;
    updateAdminTimes: (role: string) => void;
    resolve: () => void;
    selectedTimestamps: HourReportTimestamp[] | undefined;
}) {
    return role === 'admin' ? (
        <PrimaryButton
            disabled={
                !Object.keys(times).some((k) => k.includes('admin')) &&
                !selectedTimestamps?.find((t) => t.time_type.type.includes('admin'))
            }
            buttonStyle={{ maxWidth: 200, padding: 0 }}
            onClick={resolve}
        >
            Resolve
        </PrimaryButton>
    ) : (
        <TextButton onClick={() => updateAdminTimes(role)}>Use Times</TextButton>
    );
}
