import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { Checkbox, UnderlinedTextInput, Text } from 'library';
import { BreakPreference, EditBusinessPreference } from './models';
import BreakRequirements from './BreakRequirements';

const MIN_CLOCK_MINUTES = 5;

export default function BusinessPreferenceInputs({
    preference,
    originalPreference,
    updatePreference,
    editing,
    breakPreferenceOptions,
    setSaveDisabled,
}: {
    preference: EditBusinessPreference;
    originalPreference: EditBusinessPreference;
    updatePreference: (p: any) => void;
    editing: boolean;
    breakPreferenceOptions: BreakPreference[];
    setSaveDisabled: (b: boolean) => void;
}) {
    const [clockOutWhenever, setClockOutWhenever] = useState(originalPreference.clock_out_late === null);
    const [clockInWhenever, setClockInWhenever] = useState(originalPreference.clock_in_early === null);

    function updateSaveDisabled(p: EditBusinessPreference) {
        const clockInsMatch = p.clock_in_early === originalPreference.clock_in_early;
        const clockOutsMatch = p.clock_out_late === originalPreference.clock_out_late;
        const clockInGreaterThanLimit = (p.clock_in_early ?? 0) >= MIN_CLOCK_MINUTES || clockInWhenever;
        const clockOutGreatereThanLimit = (p.clock_out_late ?? 0) >= MIN_CLOCK_MINUTES || clockOutWhenever;
        if (!clockInsMatch && !clockOutsMatch) {
            setSaveDisabled(!clockInGreaterThanLimit || !clockOutGreatereThanLimit);
        } else if (!clockInsMatch && clockInGreaterThanLimit) {
            setSaveDisabled(false);
        } else if (!clockOutsMatch && clockOutGreatereThanLimit) {
            setSaveDisabled(false);
        } else {
            const currentBreak = preference.break_preferences.map((p) => p.id);
            const origBreak = originalPreference.break_preferences.map((p) => p.id);
            setSaveDisabled(
                [
                    ...currentBreak.filter((b) => !origBreak.includes(b)),
                    ...origBreak.filter((b) => !currentBreak.includes(b)),
                ].length === 0,
            );
        }
    }

    useEffect(() => {
        updateSaveDisabled(preference);
    }, [clockInWhenever, clockOutWhenever, preference]);

    useEffect(() => {
        setClockInWhenever(preference.clock_in_early === null);
        setClockOutWhenever(preference.clock_out_late === null);
    }, [editing]);

    return (
        <Grid container item style={{ marginTop: 20, gap: 20 }}>
            <Grid container item xs={12} direction="column" style={{ gap: 20 }}>
                {!editing ? (
                    <Text variant="body1" bold>
                        {clockInWhenever
                            ? 'Providers can clock in whenever they start working regardless of shift start time.'
                            : `Providers can clock in up to ${preference.clock_in_early} minutes before shift start.`}
                    </Text>
                ) : null}
                {editing ? (
                    <>
                        <Text variant="body1" bold>
                            Manage Provider Clock In Preferences
                        </Text>
                        <Checkbox
                            label="Providers can clock in whenever they start working regardless of shift start time."
                            disabled={!editing}
                            onChange={() => {
                                let current = clockInWhenever;
                                setClockInWhenever(!clockInWhenever);
                                updatePreference({
                                    ...preference,
                                    clock_in_early: !current ? null : MIN_CLOCK_MINUTES,
                                });
                            }}
                            checked={clockInWhenever}
                        />
                        {!clockInWhenever ? (
                            <Grid xs={12} md={4} style={{ paddingLeft: 9 }}>
                                <UnderlinedTextInput
                                    label="How many minutes before a shift starts can providers clock in?"
                                    value={preference.clock_in_early ? preference.clock_in_early.toString() : ''}
                                    onChange={(val: string, _isValid: boolean) =>
                                        updatePreference({ ...preference, clock_in_early: val ? parseInt(val) : null })
                                    }
                                    disabled={!editing || clockInWhenever}
                                    error={
                                        !!(preference.clock_in_early && preference.clock_in_early < MIN_CLOCK_MINUTES)
                                    }
                                    type="number"
                                    errorText={`Must be at least ${MIN_CLOCK_MINUTES} minutes per Tandem Policy`}
                                    boldLabel
                                    alwaysShowLabel
                                />
                            </Grid>
                        ) : null}
                    </>
                ) : null}
            </Grid>
            <Grid container item xs={12} direction="column" style={{ gap: 20 }}>
                {!editing ? (
                    <Text variant="body1" bold>
                        {clockOutWhenever
                            ? 'Providers can clock out whenever they finish regardless of shift end time.'
                            : `Providers can clock out up to ${preference.clock_out_late} minutes after shift end.`}
                    </Text>
                ) : (
                    <>
                        <Text variant="body1" bold>
                            Manage Provider Clock Out Preferences
                        </Text>
                        <Checkbox
                            label="Providers can clock out whenever they finish regardless of shift end time."
                            disabled={!editing}
                            onChange={() => {
                                let current = clockOutWhenever;
                                setClockOutWhenever(!clockOutWhenever);
                                updatePreference({
                                    ...preference,
                                    clock_out_late: !current ? null : MIN_CLOCK_MINUTES,
                                });
                            }}
                            checked={clockOutWhenever}
                        />
                        {!clockOutWhenever ? (
                            <Grid xs={12} md={4} style={{ paddingLeft: 9 }}>
                                <UnderlinedTextInput
                                    label="How many minutes after a shift ends is the latest a provider can clock out?"
                                    value={preference.clock_out_late ? preference.clock_out_late.toString() : ''}
                                    onChange={(val: string, _isValid: boolean) =>
                                        updatePreference({ ...preference, clock_out_late: val ? parseInt(val) : null })
                                    }
                                    disabled={!editing || clockOutWhenever}
                                    error={
                                        !!(preference.clock_out_late && preference.clock_out_late < MIN_CLOCK_MINUTES)
                                    }
                                    type="number"
                                    errorText={`Must be at least ${MIN_CLOCK_MINUTES} minutes per Tandem Policy`}
                                    boldLabel
                                    alwaysShowLabel
                                />
                            </Grid>
                        ) : null}
                    </>
                )}
            </Grid>
            <BreakRequirements
                preference={preference}
                editing={editing}
                breakPreferenceOptions={breakPreferenceOptions}
                updatePreference={updatePreference}
            />
        </Grid>
    );
}
