import {
    AppliedProvider,
    ChildcareCenterExperienceQualification,
    Job,
    PastExperience,
    ProfilePromptSubmission,
    Provider,
    ProviderJobType,
} from 'models';

export interface ProviderProfileContextType {
    provider: Provider;
    providerQualifications: ProviderQualifications;
    job?: Job;
    refreshJob: () => void;
    refreshProvider: (id?: number) => void;
    refreshApplicants: (option: 'undecided' | 'liked' | 'hidden') => void;
    visibleJobTypes: ProviderJobType[];
    jobs: Job[];
    selectedProfile?: ProviderJobType;
    setSelectedProfile: (profile: ProviderJobType | undefined, location?: string) => void;
    pastExperience: PastExperience[];
    childcareCenterQualifications: ChildcareCenterExperienceQualification[];
    visibleProfilePrompts: ProfilePromptSubmission[];
    loading: boolean;
    applicants: AppliedProvider[];
    relevanceFilter: boolean;
    setRelevanceFilter: (b: boolean) => void;
    orderByExperience: boolean;
    setOrderByExperience: (b: boolean) => void;
    orderByQualifications: boolean;
    setOrderByQualifications: (b: boolean) => void;
    likeApplicant: (provider: AppliedProvider) => void;
    hideApplicant: (provider: AppliedProvider) => void;
}

export const emptyProvider = {
    id: 0,
    user: { id: 0, first_name: '', last_name: '', uuid: '' },
    school: '',
    job_count: 0,
    job_type_interest: [],
    bio: '',
    age: 18,
    superstar: false,
    tags: [],
};

export const emptyQualifications = {
    education: [],
    professional_development_courses: [],
    employment: [],
    cpl_level: null,
    age_group_experience: [],
    years_childcare_experience: 0,
};

export const initialContext = () => ({
    provider: emptyProvider,
    providerQualifications: emptyQualifications,
    refreshJob: () => {},
    refreshProvider: () => {},
    refreshApplicants: () => {},
    visibleJobTypes: [],
    jobs: [],
    selectedProfile: undefined,
    setSelectedProfile: () => {},
    pastExperience: [],
    childcareCenterQualifications: [],
    visibleProfilePrompts: [],
    loading: false,
    applicants: [],
    relevanceFilter: false,
    setRelevanceFilter: () => {},
    orderByExperience: false,
    setOrderByExperience: () => {},
    orderByQualifications: false,
    setOrderByQualifications: () => {},
    likeApplicant: () => {},
    hideApplicant: () => {},
});

export interface Education {
    institution_name: string;
    attended_from: string | null;
    attended_to: string | null;
    other_major: string;
    city: string;
    state: string;
    major_name: string | null;
    award_name: string;
    verified: boolean;
}

export interface ProfessionalDevelopment {
    title: string;
    date: string;
    verification_status: 'Verified' | 'Outside Date Range' | 'No Show' | 'Registered' | 'Unable to Verify';
}

export interface Employment {
    employer_name: string;
    primary: boolean;
    role: string;
    start_date: string | null;
    end_date: string | null;
    description?: string;
}

export interface ProviderQualifications {
    education: Education[];
    employment: Employment[];
    age_group_experience: string[];
    years_childcare_experience: number | string;

    professional_development_courses: ProfessionalDevelopment[];
    child_care_training_transcript?: string;
    introductory_training_requirements_complete?: boolean;
    introductory_training_requirements?: string[];
    cpl_level?: string | null;
}
