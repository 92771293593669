import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import AuthModal from './AuthModal';
import usePublicSessionTracking, { PublicSessionEventType } from '../shared/PublicSessionTracking';
import { ISignupWrapper } from 'models';

export default function useSignUpAction(onClose?: () => void, onComplete?: () => void) {
    const [open, setOpen] = useState(false);
    const [location, setLocation] = useState('');
    const [functionMessage, setFunctionMessage] = useState<string>('');
    const [subtitle, setSubtitle] = useState<string>('');
    const history = useHistory();
    const { trackSession } = usePublicSessionTracking('Family Signup Modal');

    function signUp(functionLocation: string, extraData?: any, message?: string, subtitle?: string) {
        if (!!message) {
            setFunctionMessage(message);
        }
        if (!!subtitle) {
            setSubtitle(subtitle);
        }
        setLocation(functionLocation);
        setOpen(true);

        const pageAction = functionLocation === 'Drawer' || functionLocation === 'Public Search: Timed Popup';
        trackSession(
            'DIALOG_OPEN',
            `Family signup modal open from ${functionLocation}`,
            pageAction ? PublicSessionEventType.Page : PublicSessionEventType.User,
            !!extraData ? extraData : {},
        );
    }

    function closeModal() {
        setOpen(false);
        onClose && onClose();
        setLocation('');
    }

    function signUpComplete() {
        setOpen(false);
        onComplete ? onComplete() : history.push('/home?distance=15&s=0&source=ACCOUNTCREATION');
        setLocation('');
    }

    return {
        SignUpWrapper: ({
            children,
            message,
            hideReferral,
            hideCompany,
            actionMessage,
            businessSignup = false,
            businessType = 'CHILD_CARE',
        }: ISignupWrapper) => (
            <>
                {children}
                <AuthModal
                    location={location}
                    open={open}
                    signup
                    onClose={closeModal}
                    onComplete={signUpComplete}
                    message={!!message ? message : !!functionMessage ? functionMessage : 'Create Account'}
                    subtitle={subtitle}
                    hideReferral={hideReferral}
                    hideCompany={hideCompany}
                    actionMessage={actionMessage}
                    businessSignup={businessSignup}
                    businessType={businessType}
                    modal
                />
            </>
        ),
        signUp,
    };
}
