import React from 'react';
import BubbleButton from './BubbleButton';
import { Colors } from '../../theme/Colors';
import { ButtonProps } from 'models';
import CircularProgress from '@material-ui/core/CircularProgress';

const defaultStyle = {
    text: {
        color: 'white',
    },
};

const enabledStyle = {
    button: {
        backgroundColor: Colors.white,
        '&:hover': {
            backgroundColor: Colors.pressedNavy,
        },
        outline: 'none',
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: Colors.darkNavy,
        boxShadow: 'none',
    },
};

const disabledStyle = {
    button: {
        backgroundColor: Colors.disabledGrey,
    },
};

const textStyle = {
    color: Colors.darkNavy,
    fontWeight: 600,
};

export default function OutlineButton({ children, boldText = true, loading = false, ...props }: ButtonProps) {
    return (
        <BubbleButton
            defaultStyle={defaultStyle}
            enabledStyle={enabledStyle}
            disabledStyle={disabledStyle}
            boldText={boldText}
            textStyle={textStyle}
            {...props}
        >
            {loading ? (
                <CircularProgress style={{ color: 'white', display: 'flex' }} size={20} thickness={5} />
            ) : (
                children
            )}
        </BubbleButton>
    );
}
