import { Checkbox } from 'library';
import React, { useEffect, useState } from 'react';
import { useUserContext } from 'UserContext';
import useLoading from 'library/hooks/useLoading';
import { Grid } from '@material-ui/core';
import { IArea } from 'models';
import { getRegions } from 'api/RegionApi';
import useQueryParams from 'shared/QueryParamsHook';

export default function RegionMultiSelect({
    regions,
    setRegions,
    direction = 'row',
    autoPopulate = true,
}: {
    regions?: number[];
    setRegions: (s: number[]) => void;
    direction?: 'row' | 'column';
    autoPopulate?: boolean;
}) {
    const [regionOptions, setRegionOptions] = useState<IArea[]>([]);
    const { loading, setLoading, LoadingSpinner } = useLoading();
    const { user } = useUserContext();
    const query = useQueryParams();

    function updateRegions() {
        setLoading(true);
        getRegions()
            .then((res) => {
                const regionOptions = res.filter((r) => !r.name.toLowerCase().includes('small'));
                setRegionOptions(regionOptions);
                if (query.get('regions')) {
                    setRegions(
                        query
                            .get('regions')
                            ?.split(',')
                            .map((x) => parseInt(x)) || [],
                    );
                } else if (user && autoPopulate) {
                    setRegions(regionOptions.filter((x) => x.admins.includes(user.id)).map((x) => x.id));
                }
            })
            .finally(() => setLoading(false));
    }

    useEffect(updateRegions, []);

    function onCheckChanged(id: number, checked: boolean) {
        if (checked) {
            setRegions([...(regions || []), id]);
        } else {
            setRegions((regions || []).filter((x) => x !== id));
        }
    }

    if (loading) {
        return <LoadingSpinner />;
    }

    return (
        <Grid container direction={direction}>
            {regionOptions.map((x) => (
                <Checkbox
                    key={x.id}
                    label={x.name}
                    checked={regions?.includes(x.id) || false}
                    onChange={(checked) => onCheckChanged(x.id, checked)}
                    style={{ width: 'undefined' }}
                />
            ))}
        </Grid>
    );
}
