import { flatten, isEqual, partition, range, sortBy } from 'lodash';
import { uuid } from 'uuidv4';
import { Qualification, QualificationOption } from 'models';
import { IPayScale, IPayScaleQualification } from 'parent-portal/forms/JobRequestTypes';
import { IManagedScale } from '../BusinessPayInputs';
import { CDA, ECE_DEGREE, NO_EXPERIENCE } from './constants';

export function refreshPayScales(
    payMin: number,
    payMax: number,
    initialMin: number,
    initialMax: number,
    managedScales: IManagedScale[],
    defaultPayScales: IPayScale[],
): [IManagedScale[], IPayScale[]] {
    const payFloor = Math.min(payMin, initialMin);
    const payCeiling = Math.max(payMax, initialMax) + 1;

    const newDefaultScales: IPayScale[] = [];
    const newManagedScales: IManagedScale[] = [];

    range(payFloor, payCeiling).map((payRate: number) => {
        const managedMatchingScales = managedScales.filter(
            (managedScale: IManagedScale) => managedScale.rate === payRate && managedScale.qualifications?.length > 0,
        );
        const defaultMatchingScales = defaultPayScales.filter(
            (defaultPayScale: IPayScale) =>
                defaultPayScale.rate === payRate && defaultPayScale.qualifications?.length > 0,
        );

        if (payRate < payMin || payRate > payMax) {
            newDefaultScales.push(
                ...managedMatchingScales.map((managedScale: IManagedScale) => ({
                    rate: managedScale.rate,
                    description: managedScale.description,
                    qualifications: managedScale.qualifications,
                })),
            );
        } else {
            if (defaultMatchingScales?.length > 0) {
                newManagedScales.push(
                    ...defaultMatchingScales.map((defaultPayScale: IPayScale) => ({
                        ...defaultPayScale,
                        id: uuid(),
                    })),
                );
            } else if (managedMatchingScales?.length > 0) {
                newManagedScales.push(...managedMatchingScales);
            } else {
                newManagedScales.push({
                    id: uuid(),
                    rate: payRate,
                    description: payRate.toString(),
                    qualifications: [],
                });
            }
        }
    });

    return [newManagedScales, newDefaultScales];
}

export function transformScales(managedScales: IManagedScale[]): IPayScale[] {
    return managedScales.map((managedScale: IManagedScale) => ({
        rate: managedScale.rate,
        description: managedScale.description,
        qualifications: managedScale.qualifications,
    }));
}

export function partitionQualifications(qualifications: Qualification[], names: string[]) {
    return partition(qualifications, (qualification: Qualification) => names.includes(qualification.name));
}

export function formatSelectionOption(qualification: Qualification, option: QualificationOption) {
    return {
        key: option.id,
        value: qualification.name === ECE_DEGREE && option.value !== CDA ? `ECE Related ${option.value}` : option.value,
    };
}

export function getStartingDegree(qualifications: Qualification[], managedScale: IManagedScale) {
    const qualificationOptions = flatten(
        qualifications.map((qualification: Qualification) =>
            qualification.options.map((option: QualificationOption) => option),
        ),
    );

    const optionIds = qualificationOptions.map((option: QualificationOption) => option.id);
    const qualificationIds = qualificationOptions.map((option: QualificationOption) => option.qualification);

    return managedScale.qualifications.find(
        (qualification: IPayScaleQualification) =>
            qualificationIds.includes(qualification.qualification_id) &&
            optionIds.includes(qualification.option_id ?? 0),
    );
}

export function getStartingExperience(experienceQualification: Qualification, managedScale: IManagedScale) {
    if (!experienceQualification) {
        return null;
    }

    const value = managedScale.qualifications.find(
        (qualification: IPayScaleQualification) => qualification.qualification_id === experienceQualification.id,
    )?.value;

    return value ? parseInt(value) : value;
}

export function listChosenQualifications(
    qualifications: IPayScaleQualification[],
    unwantedQualifications: Qualification[],
) {
    const unwantedIDs = unwantedQualifications.map((qualification: Qualification) => qualification.id);

    const chosenQualifications = qualifications
        .filter((qualification: IPayScaleQualification) => !unwantedIDs.includes(qualification.qualification_id))
        .map((qualification: IPayScaleQualification) => qualification.name);

    return chosenQualifications?.length > 0 ? chosenQualifications.join(', ') : 'None';
}

export function updateEducation(
    qualifications: Qualification[],
    degreeQualifications: Qualification[],
    managedScale: IManagedScale,
    key: number,
) {
    const qualification = qualifications.find((qualification: Qualification) =>
        qualification.options.map((option: QualificationOption) => option.id).includes(key),
    );

    const filteredScaleQualifications = managedScale.qualifications.filter(
        (scaleQualification: IPayScaleQualification) =>
            !degreeQualifications
                .map((degreeQualification: Qualification) => degreeQualification.id)
                .includes(scaleQualification.qualification_id),
    );

    return qualification
        ? [
              ...filteredScaleQualifications,
              { qualification_id: qualification.id, option_id: key, value: null, name: qualification.name },
          ]
        : filteredScaleQualifications;
}

export function updateYearsOfExperience(
    yearsExperienceQualification: Qualification,
    managedScale: IManagedScale,
    key: number,
) {
    const filteredScaleQualifications = managedScale.qualifications.filter(
        (scaleQualification: IPayScaleQualification) =>
            scaleQualification.qualification_id !== yearsExperienceQualification?.id,
    );

    return key !== NO_EXPERIENCE.key && yearsExperienceQualification
        ? [
              ...filteredScaleQualifications,
              {
                  qualification_id: yearsExperienceQualification.id,
                  option_id: null,
                  value: key.toString(),
                  name: yearsExperienceQualification.name,
              },
          ]
        : filteredScaleQualifications;
}

function getIdsOfNoQualificationScales(sortedPayScales: [string, IManagedScale[]][]) {
    return flatten(
        sortedPayScales.map((sortedPayScale: [string, IManagedScale[]]) => {
            const ids: string[] = [];
            sortedPayScale[1].map((qualificationRate: IManagedScale) => {
                if (qualificationRate.qualifications?.length === 0) {
                    ids.push(qualificationRate?.id);
                }
                return ids;
            });
            return ids;
        }),
    );
}

function getIdsOfSameQualificationScales(sortedPayScales: [string, IManagedScale[]][]) {
    const allPayScales = flatten(
        sortedPayScales.map((sortedPayScale: [string, IManagedScale[]]) =>
            sortedPayScale[1].map((payScales: IManagedScale) => payScales),
        ),
    );

    const sort = ['qualification_id', 'option_id'];

    const ids: string[] = [];
    allPayScales.forEach((payScale: IManagedScale, i: number) => {
        const sortedQualifications = sortBy(sortBy(payScale.qualifications.map(normalizeQualification)), sort);
        const restOfScalesQualifications = allPayScales.slice(i + 1).map((scale: IManagedScale) => ({
            id: scale.id,
            qualifications: sortBy(sortBy(scale.qualifications.map(normalizeQualification)), sort),
        }));

        return restOfScalesQualifications.forEach((comparedQualifications) => {
            if (isEqual(sortedQualifications, comparedQualifications.qualifications)) {
                ids.push(comparedQualifications.id);
                ids.push(payScale.id);
            }
        });
    });
    return ids;
}

export function getErrorIds(sortedPayScales: [string, IManagedScale[]][]) {
    const noQualificationIds = getIdsOfNoQualificationScales(sortedPayScales.slice(1));
    const sameQualificationIds = getIdsOfSameQualificationScales(sortedPayScales);

    return [...noQualificationIds, ...sameQualificationIds];
}

export function atLeastOneQualificationPerRate(sortedPayScales: [string, IPayScale[] | IManagedScale[]][]) {
    if (sortedPayScales?.length === 0) return false;
    if (sortedPayScales?.length === 1) return true;

    const payScales = sortedPayScales.slice(1);

    return payScales.every((payScale: [string, IPayScale[]]) =>
        payScale[1].some((qualificationRate: IPayScale) => qualificationRate.qualifications?.length > 0),
    );
}

function normalizeQualification(qualification: IPayScaleQualification) {
    return {
        qualification_id: qualification.qualification_id,
        option_id: qualification.option_id,
        value: qualification.value,
    };
}

export function validRates(sortedPayScales: [string, IPayScale[]][]) {
    const onePer = atLeastOneQualificationPerRate(sortedPayScales);
    if (!onePer) return onePer;

    const allPayScales = flatten(
        sortedPayScales.map((sortedPayScale: [string, IPayScale[]]) =>
            sortedPayScale[1].map((payScales: IPayScale) => payScales),
        ),
    );

    const sort = ['qualification_id', 'option_id'];

    const noSimilarQualifications = allPayScales.every((payScale: IPayScale, i: number) => {
        const sortedQualifications = sortBy(sortBy(payScale.qualifications.map(normalizeQualification)), sort);
        const restOfScalesQualifications = allPayScales
            .slice(i + 1)
            .map((scale: IPayScale) => sortBy(sortBy(scale.qualifications.map(normalizeQualification)), sort));

        return restOfScalesQualifications.every(
            (comparedQualifications) => !isEqual(sortedQualifications, comparedQualifications),
        );
    });

    return noSimilarQualifications;
}
