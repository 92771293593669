import React, { Component } from 'react';
import styled from 'styled-components';
import { Button, Badge } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { PhotoCameraOutlined } from '@material-ui/icons';
import { withStyles } from '@material-ui/styles';
import PictureDialog from '../profile/PictureDialog';
import CompletionBar from './CompletionBar';
import PropTypes from 'prop-types';
import { Colors, Text } from 'library';
import { MixPanelKey } from 'environmentVariables';

var mixpanel = require('mixpanel-browser');
mixpanel.init(MixPanelKey);

const fallbackProfileImage = require('assets/images/profile-blue-background.jpg');

const AvatarImg = styled.img`
    border-radius: 50%;
    margin-top: 45px;
    margin-bottom: 15px;
    margin-left: auto;
    margin-right: auto;
    display: block;
`;

const CategorySeparator = styled.hr`
    width: 95%;
    size: 4;
    color: #f7f7f7;
    margin-top: 25px;
    margin-bottom: 10px;
`;

const NavBar = styled.div`
    margin-top: 15px;
    color: #2a506a;
    font-size: 12;
    font-weight: medium;
    text-align: center;

    &focus: {
        outlined: none;
    }
`;

const useStyles = (theme) => ({
    root: {
        [theme.breakpoints.down('md')]: {
            justifyContent: 'center',
            alignItems: 'center',
        },
    },
});

const editPages = [3, 4, 5, 8];

class ProfileHeader extends Component {
    render() {
        const {
            user,
            primaryUser,
            page,
            profilePicture,
            setProfilePicture,
            pictureDialogVisible,
            setPictureDialogVisible,
            pageHandler,
            classes,
        } = this.props;

        return (
            <div style={{ marginTop: 30 }}>
                <Grid container justify="center">
                    <Grid
                        container
                        direction="row"
                        xs={12}
                        md={8}
                        lg={6}
                        spacing={2}
                        alignItems="center"
                        className={classes.root}
                    >
                        <Grid item>
                            <div>
                                <Badge
                                    onClick={() => setPictureDialogVisible(true)}
                                    overlap="circle"
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    badgeContent={
                                        <PhotoCameraOutlined
                                            style={{
                                                width: 50,
                                                height: 50,
                                                borderRadius: '50%',
                                                padding: 5,
                                                color: Colors.white,
                                                backgroundColor: Colors.turquoise,
                                                position: 'relative',
                                                cursor: 'pointer',
                                            }}
                                        />
                                    }
                                >
                                    <AvatarImg
                                        src={profilePicture ? profilePicture : fallbackProfileImage}
                                        // alt="robot"
                                        style={{
                                            margin: 'auto',
                                            height: 200,
                                            width: 200,
                                            objectFit: 'cover',
                                            cursor: 'pointer',
                                        }}
                                    />
                                </Badge>
                                <PictureDialog
                                    onPhotoChange={(url) => {
                                        setProfilePicture(url);
                                        window.location.reload();
                                    }}
                                    userId={user.id}
                                    open={pictureDialogVisible}
                                    onClose={() => setPictureDialogVisible(false)}
                                />
                            </div>
                        </Grid>
                        <Grid xs={12} md={12} lg={6} container direction="row" className={classes.root}>
                            <Grid
                                container
                                direction="column"
                                className={classes.root}
                                xs={12}
                                style={{ marginBottom: 5 }}
                            >
                                <Text variant="h1" bold>
                                    {primaryUser?.first_name} {primaryUser?.last_name}
                                </Text>
                            </Grid>
                            <Grid
                                container
                                direction="column"
                                justify="flex-end"
                                xs={12}
                                style={{ marginBottom: 5, maxWidth: 200 }}
                            ></Grid>
                        </Grid>
                    </Grid>
                </Grid>
                {/* allows component to rerender when profile pic is added */}
                {!editPages.includes(page) && (
                    <Grid container justify="center">
                        <Grid item xs={12} md={8} lg={6} style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                            <CompletionBar pageHandler={(page) => pageHandler(page)} />
                        </Grid>
                    </Grid>
                )}
                <Grid container justify="center" alignItems="center">
                    <Grid item sm={6}>
                        <NavBar style={{ textAlign: 'left' }}>
                            {page === 0 ? (
                                <div>
                                    <Button
                                        color="inherit"
                                        id="info-button"
                                        style={{ fontWeight: 'bold', outline: 'none' }}
                                        onClick={() => pageHandler(0)}
                                        disableFocusRipple
                                        disableRipple
                                        disableOutline
                                    >
                                        Account
                                    </Button>
                                    <Button
                                        color="inherit"
                                        id="family-button"
                                        style={{ fontWeight: 'medium', outline: 'none' }}
                                        onClick={() => pageHandler(1)}
                                        disableFocusRipple
                                        disableRipple
                                    >
                                        Family
                                    </Button>
                                    <Button
                                        color="inherit"
                                        id="payments-button"
                                        style={{ fontWeight: 'medium', outline: 'none' }}
                                        onClick={() => pageHandler(6)}
                                        disableFocusRipple
                                        disableRipple
                                    >
                                        Payments
                                    </Button>
                                    <Button
                                        color="inherit"
                                        id="payments-button"
                                        style={{ fontWeight: 'medium', outline: 'none' }}
                                        onClick={() => pageHandler(7)}
                                        disableFocusRipple
                                        disableRipple
                                    >
                                        Preferences
                                    </Button>
                                </div>
                            ) : (
                                ''
                            )}
                            {page === 1 ? (
                                <div>
                                    <Button
                                        color="inherit"
                                        id="info-button"
                                        style={{ fontWeight: 'medium', outline: 'none' }}
                                        onClick={() => pageHandler(0)}
                                        disableFocusRipple
                                        disableRipple
                                        disableOutline
                                    >
                                        Account
                                    </Button>
                                    <Button
                                        color="inherit"
                                        id="family-button"
                                        style={{ fontWeight: 'bold', outline: 'none' }}
                                        onClick={() => pageHandler(1)}
                                        disableFocusRipple
                                        disableRipple
                                    >
                                        Family
                                    </Button>
                                    <Button
                                        color="inherit"
                                        id="payments-button"
                                        style={{ fontWeight: 'medium', outline: 'none' }}
                                        onClick={() => pageHandler(6)}
                                        disableFocusRipple
                                        disableRipple
                                    >
                                        Payments
                                    </Button>
                                    <Button
                                        color="inherit"
                                        id="payments-button"
                                        style={{ fontWeight: 'medium', outline: 'none' }}
                                        onClick={() => pageHandler(7)}
                                        disableFocusRipple
                                        disableRipple
                                    >
                                        Preferences
                                    </Button>
                                </div>
                            ) : (
                                ''
                            )}
                            {page === 2 ? (
                                <div>
                                    <Button
                                        color="inherit"
                                        id="info-button"
                                        style={{ fontWeight: 'medium', outline: 'none' }}
                                        onClick={() => pageHandler(0)}
                                        disableFocusRipple
                                        disableRipple
                                        disableOutline
                                    >
                                        Account
                                    </Button>
                                    <Button
                                        color="inherit"
                                        id="family-button"
                                        style={{ fontWeight: 'medium', outline: 'none' }}
                                        onClick={() => pageHandler(1)}
                                        disableFocusRipple
                                        disableRipple
                                    >
                                        Family
                                    </Button>
                                    <Button
                                        color="inherit"
                                        id="payments-button"
                                        style={{ fontWeight: 'medium', outline: 'none' }}
                                        onClick={() => pageHandler(6)}
                                        disableFocusRipple
                                        disableRipple
                                    >
                                        Payments
                                    </Button>
                                    <Button
                                        color="inherit"
                                        id="payments-button"
                                        style={{ fontWeight: 'medium', outline: 'none' }}
                                        onClick={() => pageHandler(7)}
                                        disableFocusRipple
                                        disableRipple
                                    >
                                        Preferences
                                    </Button>
                                </div>
                            ) : (
                                ''
                            )}
                            {page === 6 ? (
                                <div>
                                    <Button
                                        color="inherit"
                                        id="info-button"
                                        style={{ fontWeight: 'medium', outline: 'none' }}
                                        onClick={() => pageHandler(0)}
                                        disableFocusRipple
                                        disableRipple
                                        disableOutline
                                    >
                                        Account
                                    </Button>
                                    <Button
                                        color="inherit"
                                        id="family-button"
                                        style={{ fontWeight: 'medium', outline: 'none' }}
                                        onClick={() => pageHandler(1)}
                                        disableFocusRipple
                                        disableRipple
                                    >
                                        Family
                                    </Button>
                                    <Button
                                        color="inherit"
                                        id="payments-button"
                                        style={{ fontWeight: 'bold', outline: 'none' }}
                                        onClick={() => pageHandler(6)}
                                        disableFocusRipple
                                        disableRipple
                                    >
                                        Payments
                                    </Button>
                                    <Button
                                        color="inherit"
                                        id="payments-button"
                                        style={{ fontWeight: 'medium', outline: 'none' }}
                                        onClick={() => pageHandler(7)}
                                        disableFocusRipple
                                        disableRipple
                                    >
                                        Preferences
                                    </Button>
                                </div>
                            ) : (
                                ''
                            )}
                            {page == 7 && (
                                <div>
                                    <Button
                                        color="inherit"
                                        id="info-button"
                                        style={{ fontWeight: 'medium', outline: 'none' }}
                                        onClick={() => pageHandler(0)}
                                        disableFocusRipple
                                        disableRipple
                                        disableOutline
                                    >
                                        Account
                                    </Button>
                                    <Button
                                        color="inherit"
                                        id="family-button"
                                        style={{ fontWeight: 'medium', outline: 'none' }}
                                        onClick={() => pageHandler(1)}
                                        disableFocusRipple
                                        disableRipple
                                    >
                                        Family
                                    </Button>
                                    <Button
                                        color="inherit"
                                        id="payments-button"
                                        style={{ fontWeight: 'medium', outline: 'none' }}
                                        onClick={() => pageHandler(6)}
                                        disableFocusRipple
                                        disableRipple
                                    >
                                        Payments
                                    </Button>
                                    <Button
                                        color="inherit"
                                        id="payments-button"
                                        style={{ fontWeight: 'bold', outline: 'none' }}
                                        onClick={() => pageHandler(7)}
                                        disableFocusRipple
                                        disableRipple
                                    >
                                        Preferences
                                    </Button>
                                </div>
                            )}
                        </NavBar>
                        <CategorySeparator
                            style={{ width: '100%', color: 'black', marginTop: '5px', marginBottom: '25px' }}
                        />
                    </Grid>
                </Grid>
            </div>
        );
    }
}

ProfileHeader.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(useStyles)(ProfileHeader);
