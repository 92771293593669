import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import theme from '../../../../theme';
import { DiscountLabelProps } from '../../Models/PairingList';

const DiscountLabel = ({ discount }: DiscountLabelProps) => {
    if (!discount) {
        return <></>;
    }

    return (
        <Grid>
            <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="flex-end"
                style={{ padding: '1em 0.5em 0 0.5em' }}
            >
                <Typography variant="subtitle2" style={{ fontWeight: 'bold', color: theme.palette.primary.main }}>
                    Discount:
                </Typography>
                <Typography variant="subtitle2" style={{ fontWeight: 'bold', color: theme.palette.primary.main }}>
                    -${discount.toFixed(2)}
                </Typography>
            </Grid>
            <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="flex-end"
                style={{ padding: '1em 0.5em 0 0.5em' }}
            >
                <Typography variant="subtitle2" style={{ color: theme.palette.primary.main }}>
                    Your provider will NOT experience a pay decrease due to the discount.
                </Typography>
            </Grid>
        </Grid>
    );
};

export default DiscountLabel;
