import React, { CSSProperties } from 'react';

import { PrimaryButton, Text } from 'library';
import theme from 'theme';
import { useMediaQuery } from '@material-ui/core';

interface SaveButtonProps {
    onClick: () => void;
    disabled?: boolean;
    loading?: boolean;
}

const smallStyle: CSSProperties = {
    flexGrow: 1,
};

const largeStyle: CSSProperties = {
    width: 250,
};

export default function SaveButton({ onClick, disabled, loading }: SaveButtonProps) {
    const small = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <div style={small ? smallStyle : largeStyle}>
            <PrimaryButton
                buttonStyle={{ height: small ? 45 : 50 }}
                onClick={onClick}
                disabled={disabled}
                loading={loading}
            >
                <Text color="white" variant="h2" bold>
                    Save
                </Text>
            </PrimaryButton>
        </div>
    );
}
