import React from 'react';
import { Grid, SvgIcon } from '@material-ui/core';
import 'react-infinite-calendar/styles.css';
import DateFnsUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardTimePicker } from '@material-ui/pickers';

import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import ErrorText from '../../../forms/components/ErrorText';
import { IFormTimeOfDay } from './EditJobModels';
export default function RenderRequestTime({
    day,
    confirmation,
    onTimeChange,
}: {
    day: IFormTimeOfDay;
    confirmation?: boolean;
    onTimeChange: (day: string, type: string, time: MaterialUiPickersDate) => void;
}) {
    // Ongoing request displaying time range for a specific day
    return (
        <Grid container direction="column" alignItems="flex-start">
            <Grid container item xs={12} direction="row" justify="space-between" style={{ marginBottom: 10 }}>
                <Grid container xs={1} justify="center" alignItems="center">
                    <Grid item>
                        <SvgIcon component={day.icon} viewBox="0 0 700 750" />
                    </Grid>
                </Grid>
                <Grid container xs={11}>
                    <Grid container xs={5} justify="center" alignItems="center">
                        <Grid item xs={12}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardTimePicker
                                    disabled={confirmation}
                                    variant="inline"
                                    margin="normal"
                                    id="time-picker"
                                    label={'Start - ' + day.day + 's'}
                                    value={day.start}
                                    onChange={(start) => {
                                        onTimeChange(day.day, 'start', start);
                                    }}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change time',
                                    }}
                                    mask="__:__ _M"
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs={11}>
                            {!day.isStart && <ErrorText>Invalid start time</ErrorText>}
                        </Grid>
                    </Grid>
                    <Grid container xs={5} justify="center" alignItems="center" style={{ marginRight: '8%' }}>
                        <Grid item xs={12}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardTimePicker
                                    disabled={confirmation}
                                    variant="inline"
                                    margin="normal"
                                    id="time-picker"
                                    label={'End - ' + day.day + 's'}
                                    value={day.end}
                                    onChange={(end) => onTimeChange(day.day, 'end', end)}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change time',
                                    }}
                                    mask="__:__ _M"
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs={11}>
                            {!day.isEnd && <ErrorText>Invalid end time</ErrorText>}
                        </Grid>
                    </Grid>

                    <Grid container xs={10}>
                        {!day.isDuration && <ErrorText>Duration must be at least 30 minutes</ErrorText>}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}
