import React, { ReactNode } from 'react';

import { useMediaQuery } from '@material-ui/core';
import theme from 'theme';

export default function FormArea({ children }: { children?: ReactNode | ReactNode[] }) {
    const small = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <div style={small ? { marginLeft: 16, marginRight: 16 } : { marginLeft: 64, marginRight: 64 }}>{children}</div>
    );
}
