import React from 'react';
import { Colors, Text } from 'library';
import { Link } from 'react-router-dom';
import { Avatar, styled } from '@mui/material';
import { ShiftProviderUser } from 'models';

export default function ProviderHeader({ provider }: { provider: ShiftProviderUser }) {
    return (
        <>
            <WorkerAvatar
                src={provider.profile_picture?.image ? provider.profile_picture.image : undefined}
                alt={`${provider.first_name} ${provider.last_name}`}
            >
                {provider.first_name[0] + provider.last_name[0]}
            </WorkerAvatar>
            <Link to={`/provider/${provider.id}`}>
                <Text
                    variant="body2"
                    bold
                    textStyle={{
                        textDecorationLine: 'underline',
                        cursor: 'pointer',
                    }}
                >
                    {provider.first_name} {provider.last_name}
                </Text>
            </Link>
        </>
    );
}

const WorkerAvatar = styled(Avatar)({
    margin: 0,
    padding: 0,
    height: 25,
    width: 25,
    backgroundColor: Colors.darkNavy,
    fontSize: '1rem',
    marginRight: 15,
});
