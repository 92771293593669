import React, { ChangeEvent } from 'react';
import Input from 'reusableComponents/input';
import { useFaqQuestions } from './jobFaqQuestionsApi';
import FormField from '../../FormField';
import { Grid, styled } from '@mui/material';
import { Colors, LoadingIndicator, OutlinedTextInput, SmallIcon, Text } from 'library';
import { Star } from '@mui/icons-material';
import { JobFaq } from 'parent-portal/forms/JobRequestTypes';

export default function JobFaqForm({ faqs, setFaqs }: { faqs: JobFaq[]; setFaqs: (newValue: JobFaq[]) => void }) {
    const { data, loading } = useFaqQuestions();

    const updateFaqs = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, questionId: number) => {
        setFaqs([
            ...faqs.filter((it) => it.question.id !== questionId),
            ...(event.target.value ? [{ question: { id: questionId }, answer: event.target.value }] : []),
        ]);
    };

    return (
        <Grid container>
            <FaqSharedNotice container direction="row">
                <SmallIcon icon={<Star />} color={Colors.terraCotta} backgroundColor={Colors.lightTerraCotta} />
                <Text variant="body2">
                    <Text variant="body2" bold>
                        This info will be shared with the confirmed Tandem teacher.
                    </Text>{' '}
                    The more info we can share with the teacher the better! This will prepare them for their shift and
                    allow them to meet your expectations.
                </Text>
            </FaqSharedNotice>
            {loading && <LoadingIndicator />}
            <div>
                {data?.map((question) => {
                    const value = faqs?.find((fieldVal) => fieldVal.question.id === question.id)?.answer;
                    const input = question.multiline ? (
                        <OutlinedTextInput
                            rows={2}
                            multiline
                            fullWidth
                            value={value}
                            onChange={(e) => updateFaqs(e, question.id)}
                        />
                    ) : (
                        <Input fullWidth value={value} onChange={(e) => updateFaqs(e, question.id)} />
                    );
                    return (
                        <FormField key={question.id} title={question.question}>
                            {input}
                        </FormField>
                    );
                })}
            </div>
        </Grid>
    );
}

const FaqSharedNotice = styled(Grid)({
    marginTop: 20,
    marginBottom: 40,
    gap: 10,
    borderRadius: 18,
    padding: 20,
    backgroundColor: Colors.lightTerraCotta,
    alignItems: 'center',
    flexWrap: 'nowrap',
});
