import {
    AdminListProvider,
    BusinessJobWithAppointments,
    PagingResult,
    RegionAdmin,
    RegionAdminRole,
    RegionAdminUser,
} from 'models';
import { client, queryStringifyObject } from 'shared';
import { DaysOfWeek, format12HourTime, formatShortWeekdayMonthDay } from 'shared/Dates';
import {
    ApiTimeslot,
    JobType,
    PostApiSubAvailability,
    PostApiTimeslot,
    SortBy,
    Sub,
    SubAvailability,
    Timeslot,
    TimeslotState,
} from './substituteModels';
import _ from 'lodash';

export async function getWeeklyAvailability(selectedPage: number, week: Date, regions?: number[]) {
    const regionParam = !regions?.length ? '' : `?regions=${regions}`;
    const firstWeekParam = `${regionParam ? '&' : '?'}start=${week.toISOString()}`;
    const thisWeek = await client(`api/sub-availability/${regionParam}${firstWeekParam}`);
    const allSubs = mapAvailabilityToUsers(thisWeek);

    let pageSettings = { count: 0, pageSize: 0, numberOfPages: 0 };
    if (allSubs.length > 0) {
        const allUserIds = allSubs.map((sub) => sub.user.id);
        const providers = await getProviders(allUserIds, selectedPage);
        providers.results.forEach((provider: AdminListProvider) => {
            const sub = allSubs.find((aSub) => aSub.user.id === provider.user.id);
            if (sub) sub.provider = provider;
        });
        pageSettings = {
            count: providers.count,
            pageSize: providers.per_page,
            numberOfPages: providers.num_pages,
        };
    }
    return {
        subs: allSubs.filter((sub) => !!sub.provider),
        pageSettings,
    };
}

export async function getWeeklyAvailabilityForUser(week: Date, userId: number) {
    const result = await client(`api/sub-availability/?start=${week.toISOString()}&user_id=${userId}`);
    if (result.length > 0) {
        return {
            id: result[0].id,
            start_date: result[0].start_date,
            timeslots: result[0].timeslots.map((slot: ApiTimeslot) => ({
                id: slot.id,
                lower: new Date(slot.timeslot.lower),
                upper: new Date(slot.timeslot.upper),
                comment: slot.comment,
                state: TimeslotState.SAVED,
                week: slot.week,
                booked: slot.booked,
            })) as PostApiTimeslot[],
        };
    }
    return undefined;
}

const addShift = (weeklyDays: { [key in DaysOfWeek]: Timeslot[] }, timeslot: ApiTimeslot) => {
    const start = new Date(timeslot.timeslot.lower);
    const end = new Date(timeslot.timeslot.upper);
    const day = Object.values(DaysOfWeek)[(start.getDay() || 7) - 1];
    const timeslotStr = `${format12HourTime(start)} - ${format12HourTime(end)}`;
    const shift = {
        date: formatShortWeekdayMonthDay(start),
        timeslot: timeslotStr,
        comment: timeslot.comment,
        id: timeslot.id,
        week: timeslot.week,
        booked: timeslot.booked,
    };
    if (weeklyDays[day]) {
        weeklyDays[day].push(shift);
    } else {
        weeklyDays[day] = [shift];
    }

    return weeklyDays;
};

function mapAvailabilityToUsers(thisWeek: SubAvailability[]) {
    const allAvailability = thisWeek.map((sub) => ({
        user: sub.user,
        thisWeekStartDate: sub.start_date,
        thisWeek: toWeek(sub),
    })) as Sub[];
    return allAvailability;
}

function toWeek(availability: SubAvailability) {
    return {
        id: availability.id,
        startDate: availability.start_date,
        unavailable: availability.unavailable,
        hidden: availability.hidden,
        days: availability.timeslots.reduce(addShift, {} as { [key in DaysOfWeek]: Timeslot[] }),
    };
}

async function getProviders(userIds: number[], page: number) {
    return await client(`api/admin-providers/?page=${page}&users=${userIds.join(',')}`);
}

interface GetSubJobOptions {
    start_date: Date;
    addressId?: number;
    region?: string;
    distance?: number;
    open?: boolean;
    includeClosed?: boolean;
    user?: number;
    jobType?: string;
    page?: number;
    sort?: SortBy;
    hasOpenShifts?: boolean;
    jobId?: string;
    businessName?: string;
    businessLocationId?: number;
    bookedWorkerId?: string;
    bookedWorkerName?: string;
    emailShiftsNotSent?: boolean;
    needsBackgroundCheck?: boolean;
    commandF?: string;
    bookedOnly?: boolean;
}

export async function getOpenSubJobs(options: GetSubJobOptions): Promise<PagingResult<BusinessJobWithAppointments>> {
    return await client(`api/admin-jobs/${formatFilterQueryParams(options)}`);
}

export async function toggleTimeslotBooked(timeslot: Timeslot | PostApiTimeslot, booked: boolean) {
    return await client(`api/sub-availability/${timeslot.week}/`, {
        method: 'PATCH',
        body: { timeslots: [{ id: timeslot.id, booked }] },
    });
}

export async function saveSubAvailability(subAvailability: PostApiSubAvailability, userId: number) {
    const body = {
        user: userId,
        unavailable: false,
        start_date: subAvailability.start_date,
        timeslots: subAvailability.timeslots
            .filter((slot) => ![TimeslotState.DEFAULT, TimeslotState.DELETED].includes(slot.state))
            .map((slot) => ({
                ...slot,
                lower: slot.lower.toISOString(),
                upper: slot.upper.toISOString(),
            })),
    };
    await client('api/sub-availability/', { body });
}

export async function stopJob(jobId: number) {
    return await client(`api/ongoing/${jobId}/`, { method: 'PATCH', body: { status: 'STOPPED' } });
}

export async function toggleRemoved(jobId: number, removed: boolean) {
    return await client(`api/ongoing/${jobId}/`, {
        method: 'PATCH',
        body: { request_removed_at: removed ? new Date() : 'reopen' },
    });
}

export async function toggleRecurring(jobId: number, isRecurring: boolean) {
    return await client(`api/ongoing/${jobId}/`, { method: 'PATCH', body: { is_recurring_business: isRecurring } });
}

export async function getFeeEstimateForFilteredJobs(options: GetSubJobOptions): Promise<{ estimated_fees: number }> {
    return await client(`api/admin-jobs/estimated-fees/${formatFilterQueryParams(options)}`);
}

function formatFilterQueryParams(options: GetSubJobOptions) {
    const { start_date, region, jobType, includeClosed, ...otherOptions } = options;
    const regionParam = region && region !== 'All' ? `&region=${region}` : '';
    const jobTypeParam = jobType && jobType !== JobType.ALL ? `&job_type=${jobType}` : '';
    const otherQueryParams = queryStringifyObject(otherOptions);
    const separator = otherQueryParams.length > 0 ? '&' : '';
    return `?sub_start_date=${start_date.toISOString()}${regionParam}${jobTypeParam}${separator}${otherQueryParams}${
        includeClosed ? '&closed=true' : ''
    }`;
}

export async function closeAppointment(appt_id: number) {
    return await client(`api/admin-appointment/${appt_id}/close/`, { method: 'POST' });
}

export async function getAccountManagementAdmins() {
    const allAdmins = await client('api/region-admin/');
    return _.uniqBy(
        allAdmins
            .filter((admin: RegionAdmin) => admin.role === RegionAdminRole.MATCHING_SPECIALIST)
            .map((admin: RegionAdmin) => admin.user),
        (user: RegionAdminUser) => user.id,
    );
}
