import React from 'react';
import { Colors } from '../../theme';

export default function SmallIcon({
    icon,
    color,
    backgroundColor,
    style,
}: {
    icon: React.ReactNode;
    color?: Colors;
    backgroundColor?: Colors;
    style?: React.CSSProperties;
}) {
    return (
        <div
            style={{
                color: color || Colors.mediumTurq,
                backgroundColor: backgroundColor || Colors.lightTurq,
                width: 30,
                height: 30,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: 15,
                aspectRatio: '1',
                flexShrink: 0,
                ...style,
            }}
        >
            {icon}
        </div>
    );
}
