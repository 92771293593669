import * as React from 'react';
import { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Grid, Box } from '@material-ui/core';
import { Avatar, Colors, Text, Paper } from 'library';
import client from 'shared/ApiClient';
import ProviderMessenger from './ProviderMessenger';
import { useWidth } from 'reusableComponents/useWidth';
import usePublicSessionTracking from 'parent-portal/shared/PublicSessionTracking';
import { FeatureFlag, useFeatureFlag } from 'shared';

const defaultProfileImage = require('assets/images/default-sitter.png');
interface MessagesParams {
    id: string;
}

interface IThread {
    bot_flag: boolean;
    family_id: number;
    image: string;
    message: string;
    name: string;
    read: boolean;
    parent_read: boolean;
    sitter_id: number;
    timestamp: string;
    completed_background_check: boolean;
}

const Messages = () => {
    let { id } = useParams<MessagesParams>();

    const [threads, setThreads] = useState<IThread[]>([]);
    const [currentUser, setCurrentUser] = useState<any>();
    const [currentThread, setCurrentThread] = useState<IThread>();
    const breakpoint = useWidth();
    const smallScreen = ['xs'].includes(breakpoint);
    const { usePageLoadTracking } = usePublicSessionTracking('Messages');
    const { featureActive: chatwootEnabled } = useFeatureFlag(FeatureFlag.CENTER_CHATWOOT_ENABLED);

    usePageLoadTracking();

    const getMessageThreads = () => {
        client('unique-recipients/').then((response: IThread[]) => {
            let mappedResponse = chatwootEnabled ? response.filter((x) => !x.bot_flag) : response;
            mappedResponse = mappedResponse.map((x) => {
                return { ...x, read: x.family_id === parseInt(id) ? true : x.read };
            });
            setThreads(mappedResponse);
            if (id) {
                const thread = response.find((x) => x.family_id === parseInt(id));
                if (!thread) {
                    getNewThreadInfo(id);
                } else {
                    setCurrentThread(thread);
                    setCurrentUser({ user: { id: parseInt(id) } });
                }
            }
        });
    };

    useEffect(() => {
        getMessageThreads();
        const button: HTMLElement | null = document?.getElementById(id);
        if (button) {
            button.scrollIntoView();
        }
    }, [id]);

    const getNewThreadInfo = (userId: string) => {
        client(`api/babysitters/?user_id=${userId}`).then((response) => {
            const provider = response[0];
            const newThread = {
                family_id: provider.user.id,
                name: `${provider.user.first_name} ${provider.user.last_name}`,
                image: provider.user.profile_picture?.image,
                read: false,
                parent_read: true,
                sitter_id: -1,
                bot_flag: false,
                timestamp: '',
                message: '',
                completed_background_check: provider.completed_background_check,
            };
            setThreads((prevValue) => [...prevValue.slice(0, 1), newThread, ...prevValue.slice(1)]);
            setCurrentThread(newThread);
            setCurrentUser({ user: { id: parseInt(id) } });
        });
    };

    const getExistingThreads = (thread?: IThread) => {
        if (!thread) {
            return;
        }
        return (
            <Grid container alignItems="flex-start" justify="space-between">
                <Grid xs={1} container style={{ height: 60 }} direction="column" alignContent="center" justify="center">
                    {!thread.parent_read && (
                        <span
                            style={{
                                height: 15,
                                width: 15,
                                borderRadius: 50,
                                backgroundColor: Colors.mediumRed,
                            }}
                        />
                    )}
                </Grid>
                <Avatar
                    src={thread.image || defaultProfileImage}
                    style={{
                        width: 60,
                        height: 60,
                    }}
                />
                <Grid lg={8} sm={12} xs={7} style={{ color: Colors.darkNavy, marginLeft: 5 }}>
                    <Grid xs={12} direction="row">
                        <Text bold variant="h2" textStyle={{ textDecoration: 'underline', width: '90%' }}>
                            {thread.name}
                        </Text>
                    </Grid>
                    <Text variant="body2">{thread.timestamp}</Text>
                    <Text
                        variant="body1"
                        textStyle={{
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                        }}
                    >
                        {thread?.message}
                    </Text>
                </Grid>
            </Grid>
        );
    };

    return (
        <>
            <Box display={{ xs: id ? 'none' : 'block', sm: 'block' }}>
                <Grid
                    container
                    xs={11}
                    direction="row"
                    style={{ marginTop: smallScreen ? 10 : 30, marginLeft: smallScreen ? 15 : 50, marginBottom: 30 }}
                >
                    <Grid xs={12} md={4} container>
                        <Text variant="display">Messages</Text>
                    </Grid>
                </Grid>
            </Box>
            <Grid container justify="center" alignContent="center" direction="row">
                <Grid
                    item
                    xs={11}
                    sm={3}
                    style={{ maxHeight: '75vh', overflowY: 'scroll', paddingBottom: smallScreen ? 60 : 0 }}
                >
                    <Box display={{ xs: id ? 'none' : 'inherit', sm: 'inherit' }}>
                        {threads.map((x) => (
                            <Link
                                to={`/messages/${x.family_id}`}
                                style={{
                                    display: 'block',
                                    paddingRight: 10,
                                    paddingBottom: 10,
                                }}
                                key={x.family_id}
                            >
                                <Paper
                                    style={{
                                        backgroundColor: x.family_id === parseInt(id) ? Colors.lightTurq : Colors.white,
                                        borderRadius: 18,
                                    }}
                                >
                                    {getExistingThreads(x)}
                                </Paper>
                            </Link>
                        ))}
                    </Box>
                </Grid>

                {threads.length > 0 ? (
                    <Grid item xs={12} sm={8}>
                        {currentUser && (
                            <ProviderMessenger
                                providerIdObject={currentUser}
                                onMessageSent={getMessageThreads}
                                providerName={currentThread?.name}
                                completedBackgroundCheck={currentThread?.completed_background_check || false}
                                providerImage={currentThread?.image}
                            />
                        )}
                    </Grid>
                ) : (
                    <Text variant="body1">
                        You have no messages to view. Messages from workers will appear here when they message during a
                        shift.
                    </Text>
                )}
            </Grid>
        </>
    );
};

export default Messages;
