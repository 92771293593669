import React, { useEffect } from 'react';
import { IMessageThread } from './OutreachModels';
import { PagingResult } from 'models';
import OutreachThreadCard from './OutreachThreadCard';
import useWebSocket from 'react-use-websocket';
import { ApiBaseWSUrl } from 'environmentVariables';
import { socketConfig } from 'internal/shared/sockets';

const config = socketConfig({});
interface IAggregateCount {
    [key: string]: number;
}

function OutreachThreadCards({
    threads,
    handleClick,
    selectedNumber,
}: {
    threads?: PagingResult<IMessageThread>;
    handleClick: (item: IMessageThread, index: number) => void;
    selectedNumber: string;
}) {
    const [aggregateCount, setAggregateCount] = React.useState<IAggregateCount>({});

    const { lastMessage } = useWebSocket(`${ApiBaseWSUrl}/ws/thread/outreach_msg_thread_aggs/`, config);

    useEffect(() => {
        if (!lastMessage) return;
        const { thread_id, thread_unread_count } = JSON.parse(lastMessage.data).message;
        setAggregateCount((prevState) => {
            return {
                ...prevState,
                [thread_id]: thread_unread_count,
            };
        });
    }, [lastMessage]);

    useEffect(() => {
        if (!threads?.results) return;
        threads.results.forEach((item) => {
            setAggregateCount((prevState) => {
                return {
                    ...prevState,
                    [item.id]: item.unread_count,
                };
            });
        });
    }, [threads, setAggregateCount]);

    if (!threads?.results) return null;

    return (
        <>
            {threads?.results.map((item, index) => {
                return (
                    <OutreachThreadCard
                        key={item.external_phone_number}
                        data={{
                            phone_number: item.external_phone_number,
                            unread: aggregateCount[item.id] || 0,
                            most_recent: item.last_updated,
                            messages: 0,
                            id: 1,
                            first_name: item.first_name,
                            last_name: item.last_name,
                            user_id: item.user?.id,
                        }}
                        onCardClick={() => handleClick(item, index)}
                        selectedNumber={selectedNumber}
                    />
                );
            })}
        </>
    );
}

export default OutreachThreadCards;
