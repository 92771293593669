import React, { Component } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import { Navbar, NavbarBrand, Nav, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { PrimaryButton as Button, Text } from 'library';

class MarketerNavigation extends Component {
    handle_logout() {
        localStorage.removeItem('token');
        localStorage.removeItem('role');
        window.location.reload();
    }

    render() {
        return (
            <Navbar color="light" light expand="md">
                <Text variant="h2">Tandem</Text>
                <Nav className="ml-auto" navbar>
                    <div style={{ width: 130, marginRight: '10%' }}>
                        <UncontrolledDropdown>
                            <DropdownToggle tag="a" className="nav-link" caret>
                                Outreach
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem>
                                    <NavLink style={styles.link} to="/adminperson/outreach/directory/add">
                                        Add
                                    </NavLink>
                                </DropdownItem>
                                <DropdownItem>
                                    <NavLink style={styles.link} to="/adminperson/outreach/directory/view">
                                        View
                                    </NavLink>
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </div>
                    <div style={{ height: 20 }}>
                        <Button onClick={() => this.handle_logout()}>Logout</Button>
                    </div>
                </Nav>
            </Navbar>
        );
    }
}

export default withRouter(MarketerNavigation);

let styles = {
    link: {
        marginTop: 8,
        marginRight: 10,
        color: 'gray',
        fontFamily: 'Work Sans',
    },
};
