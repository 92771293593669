import React, { useEffect, useState } from 'react';
import CurrencyInput from 'reusableComponents/currencyInput';
import { FormFieldProps } from '../../FormTypes';
import ErrorText from '../../ErrorText';
import { IPayFormFields } from 'parent-portal/forms/JobRequestTypes';
import FormField from '../../FormField';
import { Colors } from 'library';

export default function OneTimePayRateInput({ pay }: { pay: FormFieldProps<IPayFormFields> }) {
    const [amount, setAmount] = useState(pay.value.payMin);

    const getErrorText = () => {
        if (amount <= 8) {
            return <ErrorText>Pay must be at least $8/hr</ErrorText>;
        }
    };

    const setPay = () => {
        pay.setValid(amount > 8);
        pay.setValue({ payMin: amount, payMax: amount, isFixedPay: false, payFixed: null });
    };
    useEffect(setPay, [amount]);

    return (
        <FormField title="Hourly Rate*">
            <CurrencyInput
                textAlign="left"
                minimumValue="0"
                maximumValue="999999999"
                fullWidth
                value={amount}
                onChange={(e: any) => setAmount(e.target.value)}
                decimalPlaces={2}
                currencySymbol="$"
                outputFormat="number"
                InputProps={{
                    style: {
                        fontSize: 18,
                        color: Colors.darkNavy,
                    },
                    inputProps: {
                        style: {
                            textAlign: 'left',
                        },
                    },
                    disableUnderline: false,
                }}
                placeholder="0"
                style={{ width: '35%' }}
            />
            {getErrorText()}
        </FormField>
    );
}
