import { Grid, useMediaQuery } from '@mui/material';
import React from 'react';
import { Colors, Text } from 'library';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WorkerPicture from 'assets/images/marketing-site/WorkerPicture.png';
import theme from 'theme';

export default function WorkerProfile() {
    const smDown = useMediaQuery(theme.breakpoints.down('sm'));
    const credentials = [
        'CDA in Preschool',
        '12 years of experience',
        'High School Diploma',
        'CPR, First Aid',
        'Background check',
    ];
    return (
        <Grid
            item
            container
            flexDirection="row"
            style={{ padding: smDown ? 50 : 100 }}
            xs={12}
            justifyContent="space-around"
        >
            <Grid item container xs={12} lg={6} alignItems="center" justifyContent="center">
                <Grid
                    xs={12}
                    lg={6}
                    style={{
                        padding: 20,
                        backgroundColor: `rgba(249,206,200, .75)`,
                        gap: 20,
                        borderRadius: 18,
                        boxShadow: '0px 3px 6px #00000029',
                        marginBottom: 40,
                    }}
                    flexDirection="column"
                    item
                    container
                >
                    <Text variant="h1">Hi! I'm Jana.</Text>
                    <Text variant="h2" bold textStyle={{ textDecorationLine: 'underline' }}>
                        Credentials
                    </Text>
                    <Grid container item style={{ gap: 10 }} flexDirection="column">
                        {credentials.map((c) => (
                            <Text>
                                <CheckCircleIcon style={{ color: Colors.darkNavy, fontSize: 12, marginRight: 10 }} />
                                {c}
                            </Text>
                        ))}
                    </Grid>
                    <div
                        style={{
                            height: 0,
                            width: 0,
                            position: 'relative',
                            bottom: 200,
                            left: 100,
                        }}
                    >
                        <div
                            style={{
                                position: 'absolute',
                                width: 417,
                                height: 354,
                                background: `transparent url(${WorkerPicture}) 0% 0% no-repeat padding-box`,
                                opacity: 1,
                                backgroundSize: 'contain',
                            }}
                        />
                    </div>
                </Grid>
            </Grid>
            <Grid item container justifyContent="center" alignItems="center" xs={12} lg={6} style={{ padding: 40 }}>
                <Text variant="display">
                    We'll help you build your profile and get compliant so you can start earning.
                </Text>
            </Grid>
        </Grid>
    );
}
