import { useUserContext } from 'UserContext';
import { differenceInHours } from 'date-fns';
import PaymentInformation, { PaymentInformationStatus } from 'models/PaymentInformation';

export default function useMicrodepositVerificationInfo() {
    const { user } = useUserContext();

    const getHoursAgoSubmitted = (pay: PaymentInformation) => differenceInHours(new Date(), new Date(pay.added_at));
    const isPaymentUnverified = (pay: PaymentInformation) => pay.status === PaymentInformationStatus.NEW;

    const defaultPayment = user?.payment_information.find((payment) => payment.is_default);

    const needsVerificationWarning = !!user?.payment_information?.some(
        (payment) => isPaymentUnverified(payment) && getHoursAgoSubmitted(payment) >= 24,
    );

    const needsSecondVerificationWarning = !!user?.payment_information?.some(
        (payment) => isPaymentUnverified(payment) && getHoursAgoSubmitted(payment) >= 72,
    );

    const blockJobPostingOnVerification =
        !!defaultPayment && isPaymentUnverified(defaultPayment) && getHoursAgoSubmitted(defaultPayment) >= 72;

    return {
        blockJobPostingOnVerification,
        needsVerificationWarning,
        needsSecondVerificationWarning,
    };
}
