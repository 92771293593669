import { createContext } from 'react';
import { IManagedScale } from '../BusinessPayInputs';
import { Qualification } from 'models';

export interface BusinessPayContextType {
    qualifications: Qualification[];
    errorScales: string[];
    managedScales: IManagedScale[];
    setManagedScales: (managedScales: IManagedScale[]) => void;
}

export const BusinessPayContext = createContext<BusinessPayContextType>({
    qualifications: [],
    errorScales: [],
    managedScales: [],
    setManagedScales: () => {},
});
