import React from 'react';
import { Colors } from '../../theme';

export default function Highlight({
    color = Colors.lightTurq,
    children,
}: {
    color?: string;
    children?: React.ReactNode;
}) {
    return (
        <span
            style={{
                color: 'inherit',
                background: `linear-gradient(to top, ${color} 50%, transparent 50%)`,
            }}
        >
            {children}
        </span>
    );
}
