import React from 'react';
import Cellphone from 'assets/icons/tandempay/cellphone.svg';
import Lightbulb from 'assets/icons/tandempay/lightbulb.svg';
import { Job, Provider } from 'models';
import ModalPage from 'parent-portal/payments/Components/ConnectPaymentModal/ModalPage';
import PageHeader from 'parent-portal/payments/Components/ConnectPaymentModal/PageHeader';
import { Colors, Text, SmallIcon } from 'library';
import { Grid, useMediaQuery } from '@material-ui/core';
import NextButton from 'parent-portal/payments/Components/ConnectPaymentModal/NextButton';
import BackButton from 'parent-portal/payments/Components/ConnectPaymentModal/BackButton';
import theme from 'theme';
import PaySlider from '../components/PaySlider';
import JobDatePicker from 'parent-portal/forms/components/pages/DateAndTimePage/JobDatePicker';
import { Moment } from 'moment';
import { FormFieldProps } from 'parent-portal/forms/components/FormTypes';

interface Props {
    provider: Provider;
    onContinue: () => void;
    updatePayValue: (pay: number) => void;
    job: Job;
    payValue: number;
    onBack?: () => void;
    business?: boolean;
    startDate?: FormFieldProps<Moment | null>;
}

export default function ConfirmPayRate({
    provider,
    onContinue,
    updatePayValue,
    job,
    onBack,
    payValue,
    business = false,
    startDate,
}: Props) {
    const smDown = useMediaQuery(theme.breakpoints.down('sm'));
    const isFixedPay = job.pay_is_fixed;

    function getLightbulbContent() {
        return (
            <Grid container item direction="row" alignItems="center" style={{ marginTop: 10 }}>
                <Grid item style={{ borderRadius: '50%', backgroundColor: Colors.green }}>
                    <SmallIcon color={Colors.darkNavy} icon={<Lightbulb />} />
                </Grid>
                <Grid item alignItems="center" direction="row" style={{ paddingLeft: 10 }}>
                    <Text variant="body1">
                        <Text variant="body1" bold>
                            Tandem Win!
                        </Text>{' '}
                        {provider.user.first_name} will earn ${payValue}
                        {!isFixedPay ? `/hour` : ''}.
                    </Text>
                </Grid>
                <Grid container item md={11} xs={12}>
                    <Text variant="body2" textStyle={{ marginTop: 10 }}>
                        Tandem is the only care service that is completely fee-free and lets providers keep 100% of
                        their earnings.
                    </Text>
                </Grid>
            </Grid>
        );
    }

    return (
        <ModalPage>
            <PageHeader
                iconSource={<Cellphone />}
                headerText={`Great! Now, confirm the pay rate${
                    job.business_job_type === 'TRIAL_RUN' ? ' and start date' : ''
                }.`}
                subtitleText={'Your rate is always adjustable.'}
            />
            <Grid
                item
                style={{
                    marginLeft: smDown ? 20 : 50,
                    marginRight: smDown ? 20 : 50,
                    marginBottom: smDown ? 0 : 50,
                }}
            >
                <PaySlider job={job} onPayChange={updatePayValue} payValue={payValue} />
                {business ? null : getLightbulbContent()}
            </Grid>
            {job.business_job_type === 'TRIAL_RUN' && startDate ? (
                <Grid
                    item
                    style={{
                        marginLeft: smDown ? 20 : 50,
                        marginRight: smDown ? 20 : 50,
                        marginBottom: smDown ? 0 : 50,
                    }}
                >
                    <Text>Start Date</Text>
                    <JobDatePicker jobDate={startDate} />
                </Grid>
            ) : null}

            <Grid
                style={{ position: 'absolute', bottom: 10, padding: 10, paddingTop: 0 }}
                container
                item
                direction="row"
                justify="space-between"
                alignItems="flex-end"
            >
                {onBack && <BackButton onClick={onBack} />}
                <NextButton onClick={onContinue} />
            </Grid>
        </ModalPage>
    );
}
