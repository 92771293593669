import React, { useEffect, useState } from 'react';
import { Provider } from 'models';
import ProviderProfileTitledContainer from '../../ProviderProfileTitledContainer';
import { client, consoleLogInDev } from 'shared';
import { Chip, Colors } from 'library';

interface QualityMapping {
    id: number;
    provider_id: number;
    quality_detail: { id: number; name: string; type: 'SKILL' | 'PERSONALITY_TRAIT' };
}

export default function PersonalityTraits({ provider }: { provider: Provider }) {
    const [traits, setTraits] = useState<QualityMapping[]>([]);
    const [skills, setSkills] = useState<QualityMapping[]>([]);

    const getTraits = () => {
        if (provider.user.id > 0) {
            client(`api/provider-quality-mapping/?user=${provider.user.id}`)
                .then((response: QualityMapping[]) => {
                    setTraits(response.filter((value) => value.quality_detail.type === 'PERSONALITY_TRAIT'));
                    setSkills(response.filter((value) => value.quality_detail.type === 'SKILL'));
                })
                .catch(consoleLogInDev);
        }
    };

    useEffect(getTraits, [provider]);

    return (
        <>
            {traits.length > 0 ? (
                <ProviderProfileTitledContainer title="Personality Traits">
                    {traits.map((trait) => (
                        <Chip key={trait.id} label={trait.quality_detail.name} style={styles.chip} />
                    ))}
                </ProviderProfileTitledContainer>
            ) : null}
            {skills.length > 0 ? (
                <ProviderProfileTitledContainer title="General Skills">
                    {skills.map((skill) => (
                        <Chip key={skill.id} label={skill.quality_detail.name} style={styles.chip} />
                    ))}
                </ProviderProfileTitledContainer>
            ) : null}
        </>
    );
}

const styles = {
    chip: {
        backgroundColor: Colors.mediumTurq,
        marginRight: 8,
        marginTop: 8,
    },
};
