import moment from 'moment';
import React from 'react';
import { FieldStates, FormFieldProps } from '../../FormTypes';
import { useJobFormContext } from 'parent-portal/forms/JobFormContext';
import JobDatePicker from './JobDatePicker';
import JobTimePicker from './JobTimePicker';
import JobFormPage from '../JobFormPage';
import JobDateRangePicker from './JobDateRangePicker';
import JobDayPicker from './JobDayPicker';
import JobSameTimePicker from './JobSameTimePicker';
import {
    DayOfWeekSelections,
    JobFormFields,
    JobTimes,
    RequestType,
    StartEstimateMonthType,
} from 'parent-portal/forms/JobRequestTypes';
import JobMultiTimePicker from './JobMultiTimePicker';
import { Grid } from '@material-ui/core';
import FormField from '../../FormField';
import { Text } from 'library';

interface DateAndTimePageFields {
    jobTimes: FormFieldProps<JobTimes>;
    requestType: FormFieldProps<RequestType>;
    startDate: FormFieldProps<moment.Moment | null>;
    endDate: FormFieldProps<moment.Moment | null>;
    startEstimateMonth: FormFieldProps<StartEstimateMonthType>;
    daysOfTheWeek: FormFieldProps<DayOfWeekSelections>;
    sameTimes: FormFieldProps<boolean>;
}

interface DateAndTimePageProps {
    fieldStates: FieldStates<JobFormFields>;
}

export default function DateAndTimePage({ fieldStates }: DateAndTimePageProps) {
    const {
        jobTimes,
        requestType,
        startDate,
        endDate,
        startEstimateMonth,
        daysOfTheWeek,
        sameTimes,
    }: DateAndTimePageFields = fieldStates;
    const { goToNext } = useJobFormContext();

    const componentStyle = { marginTop: 30, marginBottom: 30 };

    function onNextClicked() {
        pageIsValid() && goToNext('family-date-time-page');
    }

    function oneTimeJobPageIsValid() {
        return startDate.isValid && jobTimes.isValid;
    }

    function recurringTimeJobPageIsValid() {
        let recurringTimeValidState;
        if (sameTimes.value) {
            recurringTimeValidState = jobTimes.isValid;
        } else {
            recurringTimeValidState = true;
        }

        const hasRecurringDateSelected = (startDate.value && endDate.value) || startEstimateMonth.value;

        return recurringTimeValidState && daysOfTheWeek.isValid && hasRecurringDateSelected;
    }

    function pageIsValid() {
        return requestType.value === 'onetime' ? oneTimeJobPageIsValid() : recurringTimeJobPageIsValid();
    }

    function getDateComponent() {
        return (
            <Grid style={{ paddingTop: 20 }}>
                {requestType.value === 'recurring' ? (
                    <>
                        <FormField bold title={'When is the job?'}>
                            <Grid item style={{ ...componentStyle, marginTop: 10 }}>
                                <Text variant="h2" bold>
                                    Date*
                                </Text>
                                <JobDateRangePicker startDate={startDate} endDate={endDate} />
                            </Grid>
                        </FormField>
                        <Grid item style={componentStyle}>
                            <Text variant="body1">Choose the days of the job</Text>
                            <JobDayPicker daysOfTheWeek={daysOfTheWeek} />
                        </Grid>
                        <Grid item style={{ ...componentStyle, marginBottom: 20 }}>
                            <JobSameTimePicker isSameTime={sameTimes} daysOfTheWeek={daysOfTheWeek} />
                        </Grid>
                    </>
                ) : (
                    <FormField bold title={'When is the job?'}>
                        <Grid style={{ marginTop: 20, marginBottom: 20 }}>
                            <Grid item>
                                <Text variant="h2" bold>
                                    Date*
                                </Text>
                                <JobDatePicker jobDate={startDate} />
                            </Grid>
                        </Grid>
                    </FormField>
                )}
            </Grid>
        );
    }

    function getTimeComponent() {
        return (
            <FormField>
                <Grid item style={{ paddingTop: 20 }}>
                    <Text variant="h2" bold>
                        Times*
                    </Text>
                    {sameTimes.value ? (
                        <JobTimePicker
                            jobTimes={jobTimes}
                            startDate={startDate.value}
                            allowPastMidnight={requestType.value === 'onetime'}
                        />
                    ) : (
                        <JobMultiTimePicker daysOfTheWeek={daysOfTheWeek} />
                    )}
                </Grid>
            </FormField>
        );
    }

    return (
        <JobFormPage onNextClicked={onNextClicked} nextDisabled={!pageIsValid()} id="date-time-page">
            {getDateComponent()}
            {getTimeComponent()}
        </JobFormPage>
    );
}
