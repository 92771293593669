import moment from 'moment';
import { getTimestampByType } from '../../../../../subs/HourReportMethods';
import { IHourReport } from 'internal/payments/HourReportModels';
import { IPayload, IBuildRequestBodyParams } from './types';
import { createHourReport, updateHourReport } from './api';

export const createReport = async (body: IPayload) => {
    const report = await createHourReport(body);

    if (body.business_clock_out) {
        updateHourReport(report.id, {
            clock_out: body.business_clock_out,
        });
    }
};

export const buildRequestBody = ({
    providerId,
    shiftId,
    businessId,
    clockTimes,
    breakTimes,
}: IBuildRequestBodyParams): IPayload => {
    const payload: IPayload = {
        provider_id: providerId,
        appointment_id: shiftId,
        business_id: businessId,
    };

    const times = { ...clockTimes, ...breakTimes };

    for (const key in times) {
        payload[key as string] = times[key as string]?.toISOString();
    }

    return payload;
};

export const breakByType = (hourReport: IHourReport | undefined, type: string) => {
    const breakTime = getTimestampByType(hourReport, type);
    return breakTime ? moment(breakTime) : null;
};

export const clockIn = (hourReport: IHourReport | undefined) => {
    const providerClockIn = getTimestampByType(hourReport, 'provider_clock_in');
    const providerAltered = getTimestampByType(hourReport, 'provider_altered_clock_in');
    return providerAltered || providerClockIn;
};

export const breakStart = (hourReport: IHourReport | undefined) => {
    const providerBreakStart = breakByType(hourReport, 'provider_break_start');
    const providerAltered = breakByType(hourReport, 'provider_altered_break_start');

    if (providerAltered) {
        return moment(providerAltered);
    }
    if (providerBreakStart) {
        return moment(providerBreakStart);
    }
    return null;
};

export const breakEnd = (hourReport: IHourReport | undefined) => {
    const providerBreakEnd = breakByType(hourReport, 'provider_break_end');
    const providerAltered = breakByType(hourReport, 'provider_altered_break_end');

    if (providerAltered) {
        return moment(providerAltered);
    }
    if (providerBreakEnd) {
        return moment(providerBreakEnd);
    }
    return null;
};

export const breakText = (hourReport: IHourReport | undefined) => {
    if (hourReport && hourReport.approved_break > 0) {
        return `Provider reported a ${hourReport.approved_break} minute break, if this is incorrect use the
        selection below to update the times.`;
    }
    return 'Provider did not report a break, if they took a break use the selection below to add break times.';
};
