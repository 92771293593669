import React from 'react';
import { Grid, Link, useMediaQuery } from '@material-ui/core';
import theme from '../../theme';
import styled from 'styled-components';
import { styled as muistyled } from '@material-ui/core/styles';
import { useLocation } from 'react-router-dom';
import { Colors } from 'library';

const Text = muistyled(Link)({
    color: Colors.turquoise,
});

const BrowserBar = styled.div`
    position: fixed;
    height: 0;
    bottom: 23px;
    right: 0;
`;

const PublicFooter = () => {
    const pathname = useLocation().pathname;

    const wouldBeHiddenByMobileNav = useMediaQuery(theme.breakpoints.down('sm'));
    const onMessagePage = pathname.includes('messages');

    return wouldBeHiddenByMobileNav || onMessagePage ? null : (
        <BrowserBar>
            <Grid container justify="flex-end">
                <Text href="https://www.join-tandem.com/" variant="subtitle2">
                    © {new Date().getFullYear()} Tandem, Inc.
                </Text>
            </Grid>
        </BrowserBar>
    );
};

export default PublicFooter;
