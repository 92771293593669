import { Chip, Colors, Text } from 'library';
import {
    JobDetailOption,
    JobDetailValue,
    JobDetailValueWithOption,
    JobQualificationValue,
    QualificationOption,
} from 'models';
import React from 'react';

export default function MultiSelect({
    options,
    selectedOptions,
    onSelect,
}: {
    options: QualificationOption[] | JobDetailOption[];
    selectedOptions?: JobQualificationValue[] | JobDetailValueWithOption[] | JobDetailValue[];
    onSelect: (optionId: number) => void;
}) {
    function getColor(option: QualificationOption | JobDetailOption) {
        const selected = selectedOptions?.some((val) => val.option_id === option.id);
        return {
            color: selected ? Colors.white : Colors.darkNavy,
            backgroundColor: selected ? Colors.darkNavy : Colors.lightTurq,
        };
    }

    return (
        <>
            <Text variant="body2">Select all that apply.</Text>
            {options.map((option) => (
                <Chip
                    key={option.id}
                    label={option.value}
                    style={{ marginRight: 12, marginTop: 20, marginBottom: 20, ...getColor(option) }}
                    onClick={() => onSelect(option.id)}
                />
            ))}
        </>
    );
}
