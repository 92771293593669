import React, { useEffect, useState } from 'react';
import { BasicDialog, Checkbox, PrimaryButton, Text, RadioGroup } from 'library';
import { BusinessJobWithAppointments, LeanUser } from 'models';
import { UpperDaysOfWeek, formatMonthDay } from 'shared/Dates';
import { client, consoleLogInDev } from 'shared';
import useLoading from 'library/hooks/useLoading';
import WeeklySchedule from 'internal/substitutes/Duplicate/WeeklySchedule';
import { dayToJobTimesOfDay, extendTrialRun } from 'api/BusinessJobApi';
import { Grid } from '@material-ui/core';
import { addDays, isAfter } from 'date-fns';
import { JobMatch } from 'internal/substitutes/substituteModels';

interface TrialRunExtension {
    workerId: number;
    status: 'PENDING' | 'DECLINED' | 'ACCEPTED';
}

export default function ExtendTrialRunModal({
    job,
    open,
    onClose,
    week,
}: {
    job: BusinessJobWithAppointments;
    open: boolean;
    onClose: () => void;
    week: Date;
}) {
    const [selectedProviders, setSelectedProviders] = useState<number[]>([]);
    const [providerAction, setProviderAction] = useState<string>('extend');
    const { loading, setLoading } = useLoading();
    const [times, setTimes] = useState(
        Object.keys(UpperDaysOfWeek).map((day) => dayToJobTimesOfDay(job.times_of_day, day)),
    );
    const [extendedTrials, setExtendedTrials] = useState<TrialRunExtension[]>([]);
    const nextWeekStart = addDays(week, 7);
    const hiredProviders = getProviders();

    useEffect(getExtendedTrials, [open]);

    function getExtendedTrials() {
        if (!open) return;
        client(`api/match/?job=${job.id}`).then((response) => {
            setExtendedTrials(
                response.reduce((allWorkers: TrialRunExtension[], currentMatch: JobMatch) => {
                    if (
                        currentMatch.exclusive &&
                        isAfter(new Date(currentMatch.appointment.start_date), nextWeekStart) &&
                        !allWorkers.find((x) => x.workerId === currentMatch.matched_worker.user.id)
                    ) {
                        allWorkers.push({
                            workerId: currentMatch.matched_worker.user.id,
                            status: currentMatch.accepted_at
                                ? 'ACCEPTED'
                                : currentMatch.declined_at
                                  ? 'DECLINED'
                                  : 'PENDING',
                        });
                    }
                    return allWorkers;
                }, []),
            );
        });
    }

    function providerLabel(provider: LeanUser) {
        const status = extendedTrials.find((x) => x.workerId === provider.id)?.status ?? 'Not yet extended';
        return `${provider.first_name} ${provider.last_name} (${provider.id}) Week of ${formatMonthDay(nextWeekStart)}: ${status}`;
    }

    function getProviders() {
        const hired = job.slots.reduce((allHiredProviders: LeanUser[], currentSlot) => {
            currentSlot.appointments.forEach((appt) => {
                appt.pairings.forEach((pairing) => {
                    if (!allHiredProviders.some((provider) => provider.id === pairing.hired_provider.id)) {
                        allHiredProviders.push(pairing.hired_provider);
                    }
                });
            });
            return allHiredProviders;
        }, []);
        return hired;
    }

    function save() {
        setLoading(true);
        extendTrialRun(job, times, selectedProviders, providerAction)
            .catch(consoleLogInDev)
            .finally(() => {
                setLoading(false);
                onClose();
            });
    }

    return (
        <BasicDialog closeButton isOpen={open} onClose={onClose}>
            <Grid item container direction="column" style={{ gap: 10 }}>
                <Text bold variant="h1">
                    Extend or Stop Trial Run for a workers
                </Text>
                {hiredProviders.length > 0 ? (
                    <>
                        <WeeklySchedule times={times} updateTimes={setTimes} />
                        <Text bold>Providers</Text>
                        {hiredProviders.map((provider) => (
                            <Checkbox
                                key={provider.id}
                                label={providerLabel(provider)}
                                checked={selectedProviders.some((id) => provider.id === id)}
                                onChange={(checked) => {
                                    if (checked) {
                                        setSelectedProviders([provider.id, ...selectedProviders]);
                                    } else {
                                        setSelectedProviders(selectedProviders.filter((id) => provider.id !== id));
                                    }
                                }}
                            />
                        ))}
                        <RadioGroup
                            horizontal
                            values={{ extend: 'Extend', stop: 'Stop', hire: 'Permanently Hire' }}
                            value={providerAction}
                            setValue={(type: string) => {
                                setProviderAction(type);
                            }}
                        />
                        <PrimaryButton rightAlign loading={loading} onClick={save}>
                            Save
                        </PrimaryButton>
                    </>
                ) : (
                    <Text>No workers to extend</Text>
                )}
            </Grid>
        </BasicDialog>
    );
}
