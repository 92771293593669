import { faCalendarPlus, faRefresh } from '@fortawesome/free-solid-svg-icons';
import { Checkbox, Colors, IconButton, OutlinedDropdown, Text } from 'library';
import React, { useEffect, useState } from 'react';
import { LocationTaskList, TaskListItem, TaskListSearchFilters, UserTaskList } from './matchingInboxModels';
import { getTaskListByLocation, getTaskListByUser } from './matchingInboxApi';
import { FormControl, Grid, InputLabel, MenuItem, OutlinedInput, Select, TablePagination, styled } from '@mui/material';
import { PagingResult } from 'models';
import { GroupedTasks } from './components';
import TaskForm, { TaskFormOptions } from './TaskForm';
import { getTaskFormOptions } from './TaskFormFields';
import { AdminBusinessLocationOption } from 'models/AdminBusinessLocation';

const ITEM_HEIGHT = 40;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            maxWidth: 250,
        },
    },
};

interface ITaskTypeOption {
    value: string;
    label: string;
}

function sortTaskTypes(a: ITaskTypeOption, b: ITaskTypeOption) {
    if (b.label < a.label) {
        return 1;
    } else if (b.label > a.label) {
        return -1;
    }
    return 0;
}

function mapTaskTypes(typeOption: ITaskTypeOption) {
    let label = typeOption.label;
    if (label === 'Location At Risk') {
        label = 'At Risk';
    }

    if (label === '[First] No Show') {
        label = 'First No Show';
    }

    if (label === 'First Clock In') {
        label = 'First Experiencer';
    }

    return {
        ...typeOption,
        label,
    };
}

export default function TaskList({
    isOpen,
    businessLocation,
    addTask = false,
    businessLocationId,
}: {
    isOpen: boolean;
    businessLocation?: AdminBusinessLocationOption;
    addTask?: boolean;
    businessLocationId?: number;
}) {
    const [locationTasks, setLocationTasks] = useState<PagingResult<LocationTaskList>>();
    const [userTasks, setUserTasks] = useState<PagingResult<UserTaskList>>();
    const [searchFilters, setSearchFilters] = useState<TaskListSearchFilters>({
        page: 1,
        inbox: '',
        page_size: 5,
        business_location: businessLocationId || businessLocation?.id,
        task_types: [],
        category: 'center',
    });
    const [isAdding, setIsAdding] = useState(addTask);
    const [options, setOptions] = useState<TaskFormOptions>({
        inboxes: [],
        types: [],
        default_inbox: null,
        businessLocation: businessLocation,
    });
    const [editItem, setEditItem] = useState<TaskListItem>();
    const [taskTypeOptions, setTaskTypeOptions] = useState<{ value: string; label: string }[]>([]);
    const [taskCategory, setTaskCategory] = useState<'center' | 'worker'>('center');

    useEffect(() => {
        getTaskFormOptions(taskCategory).then((result) => {
            setSearchFilters((prev) => {
                return {
                    ...prev,
                    inbox: result.default_inbox?.key || prev.inbox || '',
                };
            });
            setOptions({ ...result, businessLocation: businessLocation });
            setTaskTypeOptions(result.task_types.map(mapTaskTypes).sort(sortTaskTypes));
        });
    }, [taskCategory]);

    useEffect(() => {
        if (isOpen) {
            if (!taskTypeOptions.length) {
                return;
            }
            taskCategory === 'center'
                ? getTaskListByLocation({
                      ...searchFilters,
                      business_location: businessLocationId || businessLocation?.id,
                  }).then(setLocationTasks)
                : getTaskListByUser(searchFilters).then(setUserTasks);
            if (businessLocation?.id !== options.businessLocation?.id) {
                setOptions({ ...options, businessLocation: businessLocation });
            }
        }
    }, [isOpen, searchFilters, businessLocation, businessLocationId]);

    function refresh() {
        taskCategory === 'center'
            ? getTaskListByLocation({ ...searchFilters, business_location: businessLocation?.id }).then(
                  setLocationTasks,
              )
            : getTaskListByUser(searchFilters).then(setUserTasks);
    }

    function onEdit(item: TaskListItem) {
        setEditItem(item);
    }

    function unresolvedTasks(taskList: PagingResult<LocationTaskList | UserTaskList> | undefined, item: TaskListItem) {
        return taskList?.results?.map((locOrUser) => {
            const items = searchFilters.task_types?.includes('-2')
                ? locOrUser.inbox_items.map((it: TaskListItem) => (it.id === item.id ? { ...it, resolved: true } : it))
                : locOrUser.inbox_items.filter((it: TaskListItem) => it.id !== item.id);
            return { ...locOrUser, inbox_items: items };
        });
    }

    function onResolve(item: TaskListItem) {
        const updated = unresolvedTasks(locationTasks, item) as LocationTaskList[];
        setLocationTasks((prev) => (prev && updated ? { ...prev, results: updated } : prev));
    }

    function onUserResolve(item: TaskListItem) {
        const updated = unresolvedTasks(userTasks, item) as UserTaskList[];
        setUserTasks((prev) => (prev && updated ? { ...prev, results: updated } : prev));
    }

    function selectCategory(category: 'center' | 'worker') {
        setTaskCategory(category);
        setSearchFilters({ ...searchFilters, category, task_types: [] });
    }

    function renderList() {
        if (taskCategory === 'center') {
            if (locationTasks && locationTasks?.results.length > 0) {
                return locationTasks?.results.map((location) => (
                    <GroupedTasks
                        key={location.id}
                        taskList={location}
                        name={location.name}
                        refresh={refresh}
                        onEdit={onEdit}
                        onResolve={onResolve}
                    />
                ));
            }

            return <Text>You have finished everything! 💪</Text>;
        } else if (taskCategory === 'worker') {
            if (userTasks && userTasks?.results.length > 0) {
                return userTasks?.results.map((user) => (
                    <GroupedTasks
                        key={user.id}
                        taskList={user}
                        name={`${user.first_name} ${user.last_name}`}
                        refresh={refresh}
                        onEdit={onEdit}
                        onResolve={onUserResolve}
                    />
                ));
            }

            return <Text>You have finished everything! 💪</Text>;
        }

        return null;
    }

    return (
        <Grid justifyContent="space-between">
            {isAdding || Boolean(editItem) ? (
                <TaskForm
                    options={options}
                    onClose={() => {
                        setIsAdding(false);
                        setEditItem(undefined);
                        refresh();
                    }}
                    inbox={options.inboxes.find((i) => i.key === searchFilters.inbox)}
                    editItem={editItem}
                />
            ) : (
                <>
                    <Grid>
                        <Grid container justifyContent="space-between">
                            <OutlinedDropdown
                                label="Assigned to"
                                onChange={(e) => setSearchFilters({ ...searchFilters, inbox: e.target.value })}
                                value={searchFilters.inbox}
                                fields={options.inboxes}
                                style={{ width: 200 }}
                            />
                            {taskTypeOptions.length > 0 && (
                                <FormControl sx={{ m: 1, width: 200 }}>
                                    <InputLabel id="task-types-label" style={{ maxHeight: 40 }}>
                                        Task Types
                                    </InputLabel>
                                    <Select
                                        labelId="task-types-label"
                                        multiple
                                        value={searchFilters.task_types}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            setSearchFilters({
                                                ...searchFilters,
                                                page: 1,
                                                task_types: typeof value === 'string' ? value.split(',') : value,
                                            });
                                        }}
                                        input={<OutlinedInput style={{ maxWidth: 250, maxHeight: 40 }} label="Tag" />}
                                        renderValue={(selected) =>
                                            taskTypeOptions
                                                .filter((x) => selected.includes(x.value))
                                                .map((x) => x.label)
                                                .join(', ')
                                        }
                                        MenuProps={MenuProps}
                                    >
                                        {taskTypeOptions.map((x) => (
                                            <MenuItem key={x.value} value={x.value}>
                                                <Checkbox
                                                    checked={searchFilters?.task_types?.includes(x.value) || false}
                                                    label={x.label}
                                                    onChange={() => {}}
                                                />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            )}
                            <IconButton
                                tooltip="Refresh"
                                onClick={refresh}
                                FontAwesomeImg={faRefresh}
                                color={Colors.white}
                                backgroundColor={Colors.mediumNavy}
                                iconStyle={styles.icon}
                                style={styles.iconButton}
                            />
                            <IconButton
                                tooltip="Add Task"
                                onClick={() => setIsAdding(!isAdding)}
                                FontAwesomeImg={faCalendarPlus}
                                color={Colors.white}
                                backgroundColor={Colors.mediumNavy}
                                iconStyle={styles.icon}
                                style={styles.iconButton}
                            />
                        </Grid>
                        <Grid container justifyContent="center" display="flex" paddingTop={1} paddingBottom={1}>
                            <PillContainer>
                                <LeftSide
                                    selected={(taskCategory === 'center').toString()}
                                    onClick={() => selectCategory('center')}
                                >
                                    <Text
                                        variant="body2"
                                        color={taskCategory === 'center' ? Colors.white : Colors.darkNavy}
                                    >
                                        Center Tasks
                                    </Text>
                                </LeftSide>
                                <RightSide
                                    selected={(taskCategory === 'worker').toString()}
                                    onClick={() => selectCategory('worker')}
                                >
                                    <Text
                                        variant="body2"
                                        color={taskCategory === 'worker' ? Colors.white : Colors.darkNavy}
                                    >
                                        Worker Tasks
                                    </Text>
                                </RightSide>
                            </PillContainer>
                        </Grid>
                        {renderList()}
                    </Grid>
                    <TablePagination
                        component="div"
                        count={taskCategory === 'center' ? locationTasks?.count || 0 : userTasks?.count || 0}
                        rowsPerPage={
                            taskCategory === 'center' ? locationTasks?.per_page || 0 : userTasks?.per_page || 0
                        }
                        page={searchFilters.page - 1}
                        onPageChange={(_, page) => setSearchFilters({ ...searchFilters, page: page + 1 })}
                        rowsPerPageOptions={[]}
                    />
                </>
            )}
        </Grid>
    );
}

const styles = {
    iconButton: {
        marginBottom: 20,
    },
    icon: {
        marginTop: 5,
    },
};

const PillContainer = styled('div')({
    display: 'flex',
});

const Side = styled('div')(({ selected }: { selected: string }) => ({
    backgroundColor: selected === 'true' ? Colors.darkNavy : Colors.white,
    border: `1px solid ${Colors.darkNavy}`,
    cursor: 'pointer',
    display: 'flex',
    padding: '5px 10px',
    width: 'fit-content',
}));

const LeftSide = styled(Side)({
    borderTopLeftRadius: 15,
    borderBottomLeftRadius: 15,
});

const RightSide = styled(Side)({
    borderTopRightRadius: 15,
    borderBottomRightRadius: 15,
});
