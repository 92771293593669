import EditableInfo from './EditableInfo';
import React, { useEffect, useState } from 'react';
import { IContactInfo } from './models';
import { client, consoleLogInDev, formatReadablePhoneNumber, isValidPhone, validateEmail } from 'shared';
import { Address, Business, UserContextUser } from 'models';
import { useUserContext } from 'UserContext';
import useAddress, { IEditAddress } from './hooks/useAddress';

export interface DEVUGAddress {
    id: number;
    street: string;
    city: string;
    state: string;
    zip_code: string;
    longitude: number;
    latitude: number;
    user: number;
    address_type: string;
    description?: string;
}
export default function ContactInfo({
    user,
    business,
    address,
    refreshData,
}: {
    user: UserContextUser;
    business: Business;
    address: Address;
    refreshData?: () => void;
}) {
    const { refreshAlreadyLoggedInUser } = useUserContext();
    const [editing, setEditing] = useState(false);
    const [loading, setLoading] = useState(false);
    const values = ['street', 'city', 'state', 'zip_code'];
    const { addressInfo, mainAddress, resetAddress, submissionData } = useAddress(address);

    useEffect(() => {
        if (!editing) {
            resetAddress();
        }
    }, [user, business, editing, address]);

    function save() {
        if (mainAddress) {
            setLoading(true);
            client('api/addresses/' + mainAddress.id + '/', {
                method: 'PATCH',
                body: submissionData,
            })
                .then(() => {
                    refreshAlreadyLoggedInUser();
                    refreshData?.();
                })
                .finally(() => setLoading(false))
                .catch(consoleLogInDev);
        }
        return;
    }

    if (!mainAddress) return <></>;

    return (
        <EditableInfo
            title="Main Address"
            editing={editing}
            updateEditing={setEditing}
            fields={addressInfo}
            save={save}
            saveDisabled={
                loading ||
                !values.map((v) => mainAddress[v as keyof IEditAddress] === address[v as keyof Address]).includes(false)
            }
        />
    );
}
