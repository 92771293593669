import React from 'react';
import { Colors, OptionButton } from 'library';
import { useOnboardingContext } from '../../../OnboardingContext';
import UpdateIcon from '@mui/icons-material/Update';
import { useHistory } from 'react-router';

export function FutureNeed() {
    const { submitAction } = useOnboardingContext();
    const history = useHistory();

    return (
        <OptionButton
            icon={<UpdateIcon htmlColor={Colors.turquoise} fontSize="large" />}
            title="Future Need"
            highlight="I need help in the future."
            subtitle="I may use Tandem for one offs or when a future role opens."
            action={() => {
                submitAction(() => history.push('/home'), 'Future Need');
            }}
            id="get-started-future-need-card"
        />
    );
}
