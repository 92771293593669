import React from 'react';
import { Grid } from '@mui/material';
import { AdminProvider } from 'models';
import OutreachSuspendedAlert from './OutreachSuspendedAlert';
import UserPoints from 'internal/shared/UserPoints';

export default function OutreachHeaderMessages({ provider }: { provider: AdminProvider }) {
    return (
        <Grid container direction="column">
            <UserPoints userId={provider.user.id} />
            <OutreachSuspendedAlert provider={provider} />
        </Grid>
    );
}
