import { Moment } from 'moment';
import { IJobDetails } from './types.d';
import moment from 'moment';
import { DayOfWeekSelections } from 'parent-portal/forms/JobRequestTypes';

export const allEqual = (arr: Array<string>) => {
    return arr.every((val) => val === arr[0]);
};

export const timesAreEqual = (draftJob: IJobDetails): boolean => {
    const startTimes = selectedStartTimes(draftJob);
    const endTimes = selectedEndTimes(draftJob);

    const allSlots = Object.values(draftJob?.times_of_day ?? {})
        .filter((day) => day?.selected)
        .map((day) => day?.slots?.toString());
    const allSlotsEqual = allSlots ? allEqual(allSlots) : true;

    return allEqual(startTimes) && allEqual(endTimes) && allSlotsEqual;
};

export const selectedStartTimes = (draftJob: IJobDetails): Array<string> => {
    const startTimes = [];
    for (const day in draftJob.times_of_day) {
        if (draftJob.times_of_day[day]['selected']) {
            startTimes.push(draftJob.times_of_day[day]['start']);
        }
    }
    return startTimes;
};

export const selectedEndTimes = (draftJob: IJobDetails): Array<string> => {
    const endTimes = [];
    for (const day in draftJob.times_of_day) {
        if (draftJob.times_of_day[day]['selected']) {
            endTimes.push(draftJob.times_of_day[day]['end']);
        }
    }
    return endTimes;
};

export const formatTimesOfDay = (draftJob: IJobDetails) => {
    if (!draftJob.times_of_day || !draftJob.start_date) return;

    const daysAndTimes = structuredClone(draftJob.times_of_day);
    let endDate = !draftJob.end_date ? draftJob.start_date : draftJob.end_date;

    for (const day in draftJob.times_of_day) {
        if (draftJob.times_of_day[day]['selected']) {
            const { start, end } = daysAndTimes[day];
            daysAndTimes[day]['start'] = moment(`${draftJob.start_date} ${start}`);
            daysAndTimes[day]['end'] = moment(`${endDate} ${end}`);
        }
    }

    return daysAndTimes as unknown as DayOfWeekSelections;
};

export const getStartTime = (draftJob: IJobDetails): Moment | null => {
    if (draftJob.times_of_day) {
        const startTimes = selectedStartTimes(draftJob);
        return allEqual(startTimes) ? moment(`${draftJob.start_date} ${startTimes[0]}`) : null;
    } else {
        return moment(draftJob.start_time);
    }
};

export const getEndTime = (draftJob: IJobDetails): Moment | null => {
    let endDate = !draftJob.end_date ? draftJob.start_date : draftJob.end_date;

    if (draftJob.times_of_day) {
        const endTimes = selectedEndTimes(draftJob);
        return allEqual(endTimes) ? moment(`${endDate} ${endTimes[0]}`) : null;
    } else {
        return moment(draftJob.end_time);
    }
};
