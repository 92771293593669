import { Dialog, Grid, Typography } from '@material-ui/core';
import { Colors } from 'library';
import React, { useEffect, useState } from 'react';
import LoadingButton from 'reusableComponents/loadingButton';
import ApiClient from 'shared/ApiClient';
import { track } from 'shared/Tracking';
import { useUserContext } from 'UserContext';
import { PlaidAccount } from './components/PlaidTypes';

interface AccountSelectionError {
    accountId: PlaidAccount['id'];
    message: string;
}

interface PlaidAccountSelectionProps {
    accounts?: PlaidAccount[];
    token?: string;
    onSelectAccount: (accountId: PlaidAccount['id']) => void;
}

export default function PlaidAccountSelection({ accounts = [], token, onSelectAccount }: PlaidAccountSelectionProps) {
    const { user } = useUserContext();
    const [error, setError] = useState<AccountSelectionError | undefined>();
    const [loading, setLoading] = useState(false);
    const [selectedAccountId, setSelectedAccountId] = useState<PlaidAccount['id']>();

    useEffect(() => {
        if (accounts && accounts.length === 1) {
            selectAccount(accounts[0].id);
        }
    }, [accounts]);

    async function selectAccount(accountId: PlaidAccount['id']) {
        setSelectedAccountId(accountId);
        setLoading(true);
        setError(undefined);

        try {
            const response = await ApiClient(`plaid-user/?id=${user?.id}`, {
                body: { public_token: token, account_id: accountId },
            });
            track('Plaid Setup', { status: response });
            onSelectAccount(accountId);
        } catch (error: any) {
            onSubmissionError(accountId, error);
        }

        setLoading(false);
        setSelectedAccountId(undefined);
    }

    function onSubmissionError(accountId: PlaidAccount['id'], error: { message: string }) {
        setError({
            accountId,
            message: error.message || 'There was an error adding the payment method',
        });
        track('Plaid Error', { error });
        ApiClient('payment/api/setup-events/', { body: { data: error, processor: 'Plaid' } });
    }

    return (
        <Dialog open={!!accounts && accounts.length > 0}>
            {accounts.map((account) => (
                <Account
                    key={account.id}
                    onSelected={selectAccount}
                    item={account}
                    error={error?.accountId === account.id ? error : undefined}
                    loading={loading && selectedAccountId === account.id}
                    disabled={loading}
                />
            ))}
        </Dialog>
    );
}

function Account({
    item,
    error,
    loading,
    disabled,
    onSelected,
}: {
    item: PlaidAccount;
    error?: AccountSelectionError;
    loading: boolean;
    disabled: boolean;
    onSelected: (id: PlaidAccount['id']) => void;
}) {
    return (
        <Grid container direction="row" style={{ padding: '1em', borderBottom: `1px solid ${Colors.darkNavy}` }}>
            <Grid container xs={8} alignItems="center">
                <Typography>
                    {item.name} - {item.mask}
                </Typography>
                {error && error.accountId === item.id && (
                    <Typography style={{ fontSize: '0.9em', color: Colors.darkNavy }}>{error.message}</Typography>
                )}
            </Grid>
            <Grid container xs={4} justify="center">
                <LoadingButton
                    disabled={disabled}
                    loading={loading}
                    style={{ padding: '1em', minWidth: 0 }}
                    onClick={() => onSelected(item.id)}
                >
                    Select
                </LoadingButton>
            </Grid>
        </Grid>
    );
}
