import React from 'react';
import { Colors } from '../../theme';
import { Chip, ChipProps } from '@mui/material';

interface IProps extends ChipProps {
    label: string;
    style?: React.CSSProperties;
    backgroundColor?: string;
    textColor?: string;
}

export default function ChipBase({ label, style, backgroundColor, textColor, ...rest }: IProps) {
    return (
        <Chip
            label={label}
            style={{
                color: !!textColor ? textColor : Colors.navy,
                backgroundColor: !!backgroundColor ? backgroundColor : Colors.lightTurq,
                fontWeight: 'bold',
                minHeight: 32,
                borderRadius: 18,
                ...style,
            }}
            {...rest}
        />
    );
}
