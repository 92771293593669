import React from 'react';
import { Checkbox, LoadingSpinner } from 'library';
import { Grid } from '@material-ui/core';

import { useBusinessLocationsContext } from './BusinessLocationsContext';

export default function BusinessLocationStatusSelect({
    statuses,
    staticStatuses,
    setStatuses,
    setStaticStatuses,
    direction = 'row',
}: {
    statuses?: number[];
    staticStatuses?: number[];
    setStatuses: (s: number[]) => void;
    setStaticStatuses: (s: number[]) => void;
    direction?: 'row' | 'column';
}) {
    const { statuses: statusOptions, staticStatuses: staticStatusOptions, loading } = useBusinessLocationsContext();

    function onCheckChanged(id: number, checked: boolean) {
        if (checked) {
            setStatuses([...(statuses || []), id]);
        } else {
            setStatuses((statuses || []).filter((x) => x !== id));
        }
    }

    function onCheckChangedStatic(id: number, checked: boolean) {
        if (checked) {
            setStaticStatuses([...(staticStatuses || []), id]);
        } else {
            setStaticStatuses((staticStatuses || []).filter((x) => x !== id));
        }
    }

    if (loading) {
        return <LoadingSpinner />;
    }

    return (
        <Grid container direction={direction}>
            {statusOptions.map((x) => (
                <Checkbox
                    key={x.id}
                    label={x.name}
                    checked={statuses?.includes(x.id) || false}
                    onChange={(checked) => onCheckChanged(x.id, checked)}
                    style={{ width: 'undefined' }}
                    labelTitle={x.description}
                />
            ))}
            {staticStatusOptions.map((x) => (
                <Checkbox
                    key={x.id}
                    label={x.name}
                    checked={staticStatuses?.includes(x.id) || false}
                    onChange={(checked) => onCheckChangedStatic(x.id, checked)}
                    style={{ width: 'undefined' }}
                    labelTitle={x.description}
                />
            ))}
        </Grid>
    );
}
