import React from 'react';
import { Colors } from '../../theme';
import { Grid, makeStyles } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import { validateEmail } from 'shared';
import '@fontsource/work-sans';
import '@fontsource/pt-serif';

interface IInputProps {
    id?: string;
    label?: string;
    onChange: (value: string, isValid: boolean) => void;
    value: string;
    error?: boolean;
    errorText?: string;
    maxLength?: number;
    autoComplete?: string;
    disabled?: boolean;
    boldLabel?: boolean;
    type?: string;
    alwaysShowLabel?: boolean;
}

const useStyles = makeStyles({
    input: {
        '&:hover': {
            outline: 'none',
        },
        '&:focus': {
            outline: 'none',
        },
        '&::placeholder': {
            color: Colors.mediumNavy,
        },
        '&:disabled': {
            backgroundColor: Colors.white,
            borderBottomWidth: 0,
        },
        borderBottomColor: Colors.mediumNavy,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        borderWidth: 0,
        borderBottomWidth: 1,
        width: '100%',
        autoCapitalize: 'none',
    },
    label: {
        fontFamily: 'Work Sans',
        color: Colors.darkNavy,
    },
    errorText: {
        fontFamily: 'Work Sans',
        color: Colors.error,
        fontSize: 12,
        margin: 0,
    },
});

export default function UnderlinedTextInput({
    label,
    onChange,
    value,
    error,
    errorText,
    id,
    maxLength,
    autoComplete,
    disabled,
    boldLabel,
    type,
    alwaysShowLabel,
}: IInputProps) {
    const classes = useStyles();

    function getPlaceholder() {
        return value || alwaysShowLabel ? '' : label;
    }

    function getLabel() {
        return !value && !alwaysShowLabel ? '' : label;
    }

    return (
        <Grid container direction="column">
            {label && (
                <Grid item>
                    <label className={classes.label} style={{ fontWeight: boldLabel ? 'bold' : 'normal' }}>
                        {getLabel()}
                    </label>
                </Grid>
            )}
            <Grid item>
                <input
                    disabled={disabled}
                    id={id}
                    className={classes.input}
                    type={type ?? 'text'}
                    value={value}
                    onChange={(e) => onChange(e.target.value, validateEmail(e.target.value))}
                    placeholder={getPlaceholder()}
                    style={{ color: Colors.darkNavy }}
                    maxLength={maxLength ?? undefined}
                    autoComplete={autoComplete}
                />
            </Grid>
            {error ? (
                <Grid container item alignItems="center" style={{ paddingTop: 5 }}>
                    <CancelIcon style={{ color: Colors.error, maxHeight: 12 }} />
                    <p className={classes.errorText}>{errorText}</p>
                </Grid>
            ) : null}
        </Grid>
    );
}
