import { AdminProvider } from 'models';
import { client } from 'shared';

export function refreshProvidersOccrra(id?: number) {
    if (!id) {
        throw new Error('No id provided');
    }

    return client(`api/occrra-info-collect/${id}/refresh/`);
}

export function refreshIllinoisInfoCollect(user_id: number) {
    return client(`api/illinois-info-collect/${user_id}/refresh/`);
}
export function refreshInfoCollect(state: string, provider: AdminProvider) {
    const refreshFunctions = {
        Ohio: () => refreshProvidersOccrra(provider.occrra_info_collect?.id),
        Illinois: () => refreshIllinoisInfoCollect(provider.user.id),
    };
    return refreshFunctions[state as keyof typeof refreshFunctions];
}
