import React from 'react';
import CheckRow from './CheckRow';
import RequirementsLabel from './RequirementsLabel';
import ChipRow from './ChipRow';

export default function IllinoisTrainingsContent() {
    return (
        <>
            <CheckRow text="Introduction to Tandem - Providing Quality Care" />
            <RequirementsLabel>
                Per DCFS requirements, within the outlined days, workers will have the following:
            </RequirementsLabel>
            <ChipRow
                text="30 Days"
                chips={['Mandated Reporter', 'Shaken Baby Syndrome', 'Sudden Infant Death Syndrome']}
            />
        </>
    );
}
