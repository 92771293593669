import React from 'react';
import { Colors, Text } from 'library';

export default function StatusChip({
    text,
    color,
    textColor,
    title,
}: {
    text: string;
    color?: string;
    textColor?: string;
    title?: string;
}) {
    return (
        <Text
            textStyle={{
                backgroundColor: color ?? Colors.terraCotta,
                color: textColor ?? Colors.white,
                borderRadius: 30,
                fontSize: 12,
                padding: '6px 8px',
                width: undefined,
                display: 'inline',
                margin: '0 3px',
            }}
            bold
            title={title}
        >
            {text}
        </Text>
    );
}
