import { Job } from 'models';
import moment, { Moment } from 'moment';

export interface IDaySelection {
    selected: boolean;
    start: Moment;
    end: Moment;
}
export interface TimesOfDay {
    [key: string]: IDaySelection;
}

export function buildTimesOfDay(
    sameTimes: boolean,
    dayInformation: TimesOfDay,
    startTime?: Moment | null | undefined,
    endTime?: Moment | null | undefined,
) {
    return Object.entries(dayInformation)
        .filter((o: any) => o[1]['selected'])
        .map((o: any) => {
            let start = sameTimes && startTime ? startTime : moment(o[1]['start']);
            let end = sameTimes && endTime ? endTime : moment(o[1]['end']);
            return {
                [o[0]]: {
                    start: start.format('HH:mm:ss'),
                    end: end.format('HH:mm:ss'),
                },
            };
        });
}

export function initialDayInformationFromTimesOfDay(job: Job) {
    let initialDayInformation: TimesOfDay = {
        Sunday: { selected: false, start: moment(), end: moment() },
        Monday: { selected: false, start: moment(), end: moment() },
        Tuesday: { selected: false, start: moment(), end: moment() },
        Wednesday: { selected: false, start: moment(), end: moment() },
        Thursday: { selected: false, start: moment(), end: moment() },
        Friday: { selected: false, start: moment(), end: moment() },
        Saturday: { selected: false, start: moment(), end: moment() },
    };
    if (job.times_of_day && typeof job.times_of_day !== 'string') {
        job.times_of_day.forEach((timesByDay) => {
            const [day, times] = Object.entries(timesByDay)[0];
            /* We use an arbitrary date so that we can use moment. we only care about the time anyway */
            initialDayInformation[day].start = moment(`1970-01-01 ${times.start}`);
            initialDayInformation[day].end = moment(`1970-01-01 ${times.end}`);
            initialDayInformation[day].selected = true;
        });
    }
    return initialDayInformation;
}
