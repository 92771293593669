import React from 'react';
import { Map } from '@mui/icons-material';
import { IconButton } from 'library';

export default function ShowWorkerInMap({ region, userId }: { region?: string; userId: number }) {
    function onRegionMapClick() {
        window.open(`/adminperson/region-map?region=${region}&user=${userId}`, '_blank');
    }
    return <IconButton tooltip="Region Map" icon={Map} onClick={onRegionMapClick} style={styles.adjustIconMargin} />;
}

const styles = {
    adjustIconMargin: { marginLeft: 8 },
};
