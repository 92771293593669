import React, { useState } from 'react';
import { injectStripe, StripeProvider, Elements } from 'react-stripe-elements';
import InputWithAdornment from '../../../reusableComponents/inputWithAdornment';
import { Grid } from '@material-ui/core';
import { useUserContext } from '../../../UserContext';
import { Colors, PrimaryButton, Text } from 'library';
import { client } from 'shared';
import { StripeKey } from 'environmentVariables';

interface IMicroDepositFormProps {
    paymentInfoId: number;
}

function _MicroDepositsForm({ paymentInfoId }: IMicroDepositFormProps) {
    const [errorMessage, setErrorMessage] = useState('');
    const [amount1, setAmount1] = useState('');
    const [amount2, setAmount2] = useState('');
    const [confirmation, setConfirmation] = useState(false);
    const [loading, setLoading] = useState(false);

    const { refreshUser } = useUserContext();

    const handleChange1 = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAmount1(event.target.value);
    };
    const handleChange2 = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAmount2(event.target.value);
    };

    function handleResult() {
        setLoading(true);
        setErrorMessage('');
        client('microdeposits/', {
            body: {
                amount1: amount1.replaceAll('.', ''),
                amount2: amount2.replaceAll('.', ''),
                payment_information_id: paymentInfoId,
            },
        }).then((response) => {
            refreshUser();
            if (response['Error']) {
                setErrorMessage(response['Error']);
            } else {
                setConfirmation(true);
            }
            setLoading(false);
        });
    }

    return (
        <Grid container>
            <Grid container justify="center" alignContent="center" direction="row" spacing={2}>
                <Grid item xs={6}>
                    {!confirmation ? (
                        <InputWithAdornment adornment="$0." onChange={handleChange1} />
                    ) : (
                        <InputWithAdornment adornment="$0." disabled />
                    )}
                </Grid>
                <Grid item xs={6}>
                    {!confirmation ? (
                        <InputWithAdornment adornment="$0." onChange={handleChange2} />
                    ) : (
                        <InputWithAdornment adornment="$0." disabled />
                    )}
                </Grid>
            </Grid>
            {!confirmation ? (
                <PrimaryButton
                    loading={loading}
                    disabled={!amount1 || !amount2 || !paymentInfoId}
                    onClick={handleResult}
                    buttonStyle={{ marginTop: 10, width: 200 }}
                >
                    Submit
                </PrimaryButton>
            ) : (
                <Text textStyle={{ marginTop: 10, color: Colors.turquoise }}>Microdeposits Confirmed!</Text>
            )}
            <Text variant="body1" textStyle={{ color: Colors.error, marginTop: 10 }}>
                {errorMessage}
            </Text>
        </Grid>
    );
}

const MicroDepositsForm = injectStripe(_MicroDepositsForm);

interface IManualConfirmationProps {
    paymentInfoId: number;
}

function ManualConfirmation({ paymentInfoId }: IManualConfirmationProps) {
    return (
        <StripeProvider apiKey={StripeKey}>
            <Elements>
                <MicroDepositsForm paymentInfoId={paymentInfoId} />
            </Elements>
        </StripeProvider>
    );
}

export default ManualConfirmation;
