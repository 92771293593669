import { BasicDialog, Text, OptionChip } from 'library';
import apiClient from 'shared/ApiClient';
import React, { useState, useEffect } from 'react';
import Input from './input';
import UserAttributesApi from 'shared/UserAttributesApi';
import { useUserContext } from 'UserContext';

interface DiscoverySourceModel {
    id: number;
    source: string;
    text_required: boolean;
    text: string;
    selected: boolean;
}

interface IBody {
    id: number;
    other_text: string;
}

export default function DiscoverySourcePopUp({ open, onClose }: { open: boolean; onClose: () => void }) {
    const [enableButton, setEnableButton] = useState(false);
    const [sourceOptions, setSourceOptions] = useState([
        { id: 0, source: '', text: '', text_required: false, selected: false },
    ]);
    const { user } = useUserContext();

    useEffect(() => {
        getSourceOptions();
    }, []);

    const getSourceOptions = async () => {
        apiClient('tracking/api/discovery-source/?type=family').then((response) => {
            response.forEach((item: DiscoverySourceModel) => {
                item.selected = false;
                item.text = '';
            });
            setSourceOptions(response);
        });
    };

    useEffect(() => {
        validate();
    }, [sourceOptions]);

    const validate = () => {
        let selectOneMinimum = sourceOptions.some((option) => option.selected == true);
        let textRequiredOptionMissingText = sourceOptions.find(
            (option) => option.selected && option.text_required && option.text.length == 0,
        );
        setEnableButton(selectOneMinimum && !textRequiredOptionMissingText);
    };

    const toggleSource = (key: number) => {
        let copy = [...sourceOptions];
        copy[key].selected = !sourceOptions[key].selected;
        setSourceOptions(copy);
    };

    const onChangeText = (key: number, value: string) => {
        let copy = [...sourceOptions];
        copy[key].text = value;
        setSourceOptions(copy);
    };

    const submit = async () => {
        let selectedOptions = sourceOptions.filter((option) => option.selected);
        let body = new Array<IBody>();
        selectedOptions.forEach((option) => {
            body.push({ id: option.id, other_text: option.text });
        });
        apiClient('tracking/api/user-discovery-source/survey/', { body: body });
        await UserAttributesApi.create('How Did You Hear Viewed');
        onClose();
    };

    return (
        <BasicDialog
            isOpen={open}
            onClose={onClose}
            closeButton
            dialogTitle={`Welcome, ${user?.first_name}.`}
            onSubmit={() => submit()}
            buttonText="Submit"
            buttonEnabled={enableButton}
            webMaxWidth={'200px'}
        >
            <Text variant="h1" bold textStyle={{ marginBottom: '15px' }}>
                We're so glad you're here. How did you hear about us?
            </Text>
            {sourceOptions.map((source: DiscoverySourceModel, key: number) => (
                <>
                    <OptionChip
                        key={key}
                        label={source.source}
                        onClick={() => toggleSource(key)}
                        selected={source.selected}
                    />
                    {source.text_required && source.selected ? (
                        <Input
                            style={{ marginTop: '5px' }}
                            required
                            error={!source.text}
                            fullWidth
                            id="bio"
                            label="Please specify"
                            variant="outlined"
                            value={source.text}
                            onChange={(e) => onChangeText(key, e.target.value)}
                            multiline
                            rows="5"
                        />
                    ) : null}
                </>
            ))}
        </BasicDialog>
    );
}
