import React from 'react';
import { Grid } from '@material-ui/core';
import { Text, IconButton, Colors } from 'library';
import { Add, Delete } from '@material-ui/icons';
import { BreakPreference, EditBusinessPreference } from './models';

export function AddBreakPreference({
    preference,
    breakPreferenceOptions,
    updatePreference,
}: {
    preference: EditBusinessPreference;
    breakPreferenceOptions: BreakPreference[];
    updatePreference: (p: EditBusinessPreference) => void;
}) {
    const selectedPreferences = preference.break_preferences?.map((b) => b.id) ?? [];

    function addPreference(p: BreakPreference) {
        updatePreference({ ...preference, break_preferences: [...preference.break_preferences, p] });
    }

    function removePreference(p: BreakPreference) {
        updatePreference({
            ...preference,
            break_preferences: preference.break_preferences?.filter((b) => b.id !== p.id),
        });
    }

    return (
        <>
            {breakPreferenceOptions.map((b: BreakPreference) => (
                <Grid
                    container
                    item
                    direction="row"
                    style={{
                        gap: 20,
                        padding: 20,
                        backgroundColor: selectedPreferences.includes(b.id) ? Colors.lightBlue : Colors.disabledGrey,
                        borderRadius: 18,
                        cursor: 'pointer',
                    }}
                    alignItems="center"
                    justify="space-between"
                    onClick={() => (selectedPreferences.includes(b.id) ? removePreference(b) : addPreference(b))}
                >
                    <Text variant="body2">
                        <Text variant="body2" bold inline>
                            {b.display}:{' '}
                        </Text>
                        {b.shift_length} hour shifts require at least a{' '}
                        <Text inline variant="body2" bold>
                            {b.minutes_required} minute{' '}
                        </Text>
                        break
                    </Text>
                    {selectedPreferences.includes(b.id) ? (
                        <IconButton icon={Delete} onClick={() => removePreference(b)} />
                    ) : null}
                    {!selectedPreferences.includes(b.id) ? (
                        <IconButton icon={Add} onClick={() => addPreference(b)} />
                    ) : null}
                </Grid>
            ))}
        </>
    );
}
