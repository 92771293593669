import React from 'react';
import { makeStyles, TextField, TextFieldProps } from '@material-ui/core';
import theme from 'theme';

const useStyles = makeStyles({
    root: {
        flexGrow: 1,
        borderColor: theme.palette.primary.main,
        color: theme.palette.primary.main,
        padding: 0,
        margin: 0,
        '& .MuiOutlinedInput-root': {
            borderColor: theme.palette.primary.main,
            color: theme.palette.primary.main,
            borderRadius: '15px',
            flex: 1,
            flexDirection: 'column',
        },
    },
});

export default function TextArea(props: TextFieldProps) {
    const classes = useStyles();
    return (
        <TextField
            classes={classes}
            multiline={props.multiline ?? true}
            variant={props.variant ?? 'outlined'}
            {...props}
        />
    );
}
