import { Grid, Icon } from '@material-ui/core';
import { Colors } from '../../theme';
import { CategoryType } from 'models';
import React from 'react';
import { Text } from '../text';

export default function CategoryCard({
    category,
    onClick,
    containerStyle = {},
    iconStyle = {},
}: {
    category: CategoryType;
    onClick: () => void;
    containerStyle?: React.CSSProperties;
    iconStyle?: React.CSSProperties;
}) {
    const Styles: { [key: string]: React.CSSProperties } = {
        card: {
            backgroundColor: 'white',
            borderRadius: '18px',
            minHeight: '10vh',
            boxShadow: '0px 3px 6px #00000029',
            marginTop: 20,
            minWidth: 150,
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
            marginRight: 10,
            marginLeft: 10,
            cursor: 'pointer',
            ...containerStyle,
        },
    };

    return (
        <Grid item style={Styles.card} onClick={onClick} id={category.id}>
            <Icon
                component={category.icon}
                style={{ color: Colors.terraCotta, marginBottom: 10, ...iconStyle }}
                fontSize={'large'}
            />
            <Text variant="body1" bold textStyle={{ textAlign: 'center' }}>
                {category.text}
            </Text>
        </Grid>
    );
}
