import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import theme from '../theme';

const Header = withStyles({
    root: (props) => ({
        color: theme.palette.primary.main,
        marginBottom: 20,
        fontFamily: 'M PLUS Rounded 1c',
        fontWeight: props.fontWeight,
    }),
})(Typography);

export default Header;
