import React from 'react';
import { Grid, Avatar, styled } from '@mui/material';
import DashboardSection from '../components/DashboardSection';
import { IDashboardData } from '../Models/DashboardData';
import { Text, Colors } from 'library';

export default function CenterContact({ data }: { data: IDashboardData | undefined }) {
    return (
        <DashboardSection header="Tandem Contact Info">
            <Grid container direction="column">
                <Grid item>
                    <Text variant="caption">
                        Need help? Reach out to your {data?.center_contact?.role} for any needs.
                    </Text>
                </Grid>
                <Grid container item direction="row" alignItems="center" spacing={2}>
                    <Grid item xs={3}>
                        <AdminAvatar style={{ width: 55, height: 55 }} src={data?.center_contact?.profile_picture} />
                    </Grid>
                    <Grid item xs={9}>
                        <Text variant="h1">{data?.center_contact?.first_name}</Text>
                        <Text textStyle={{ marginTop: 2 }} variant="caption">
                            {data?.center_contact?.role}
                        </Text>
                        <Text textStyle={{ marginTop: 5 }} variant="body2">
                            {data?.center_contact?.phone_number}
                        </Text>
                        <Text textStyle={{ marginTop: 2 }} variant="body2">
                            {data?.center_contact?.email}
                        </Text>
                    </Grid>
                </Grid>
            </Grid>
        </DashboardSection>
    );
}

const AdminAvatar = styled(Avatar)({
    color: Colors.white,
    backgroundColor: Colors.darkNavy,
    width: 150,
    height: 150,
    borderRadius: '50%',
    marginTop: 10,
    marginBottom: 10,
});
