import { OutlinedTextInput, Checkbox } from 'library';
import React, { Dispatch, SetStateAction, SyntheticEvent, useEffect, useState } from 'react';
import { client } from 'shared';
import Autocomplete from '@mui/material/Autocomplete';
import { Grid } from '@mui/material';
interface IBusinessLocationOptions {
    id: number;
    program_id: string | null;
    location_name: string;
    location_address: string;
}

const blankBusinessLocation: IBusinessLocationOptions = {
    id: -1,
    program_id: null,
    location_name: 'No Location Selected',
    location_address: 'Will Not upload 508/718b',
} as IBusinessLocationOptions;

function Form718bLocationChoice({
    selectedBusinessLocation,
    setSelectedBusinessLocation,
    businessLocationOptions,
    selectedPosition,
    setSelectedPosition,
    showPositionSelect,
}: {
    selectedBusinessLocation?: IBusinessLocationOptions;
    setSelectedBusinessLocation: Dispatch<SetStateAction<IBusinessLocationOptions>>;
    businessLocationOptions: IBusinessLocationOptions[];
    selectedPosition?: string;
    setSelectedPosition: Dispatch<SetStateAction<string | undefined>>;
    showPositionSelect?: boolean;
}) {
    const value = selectedBusinessLocation ? selectedBusinessLocation : blankBusinessLocation;

    function onChange(event: SyntheticEvent<Element, Event>, value: IBusinessLocationOptions | null) {
        setSelectedBusinessLocation(value ?? blankBusinessLocation);
    }
    const positionOptions = ['Early Childhood Teacher', 'Early Childhood Assistant'];

    return (
        <Grid>
            <Autocomplete
                style={{ width: 450 }}
                value={value}
                options={businessLocationOptions}
                onChange={onChange}
                getOptionLabel={(option) => `${option.location_name} - ${option.location_address}`}
                renderInput={(params) => <OutlinedTextInput {...params} label="Business Location" variant="outlined" />}
            />
            {showPositionSelect ? (
                <Grid item container direction="row" style={{ gap: 10 }}>
                    {positionOptions.map((option) => (
                        <Checkbox
                            key={option}
                            checked={selectedPosition === option}
                            onChange={() => setSelectedPosition(option)}
                            label={option}
                            style={{ width: 200 }}
                        />
                    ))}
                </Grid>
            ) : null}
        </Grid>
    );
}

export default function useBusinessLocationOptions(showPositionSelect = true) {
    const [selectedBusinessLocation, setSelectedBusinessLocation] =
        useState<IBusinessLocationOptions>(blankBusinessLocation);
    const [businessLocationOptions, setBusinessLocationOptions] = useState<IBusinessLocationOptions[]>([
        blankBusinessLocation,
    ]);
    const [selectedPosition, setSelectedPosition] = useState<string | undefined>('Early Childhood Assistant');
    const getBusinessLocationOptions = () => {
        client(`api/illinois-info-collect/718b-business-location-options/`).then((data) => {
            setBusinessLocationOptions([blankBusinessLocation, ...data]);
        });
    };

    useEffect(getBusinessLocationOptions, []);

    return {
        selectedBusinessLocation,
        selectedPosition,
        setSelectedBusinessLocation,
        Form178bDrodown: () => (
            <Form718bLocationChoice
                businessLocationOptions={businessLocationOptions}
                selectedBusinessLocation={selectedBusinessLocation}
                setSelectedBusinessLocation={setSelectedBusinessLocation}
                setSelectedPosition={setSelectedPosition}
                selectedPosition={selectedPosition}
                showPositionSelect={showPositionSelect}
            />
        ),
    };
}
