import React, { useState } from 'react';
import { client, consoleLogInDev } from 'shared';
import { IBusinessLocation } from 'models';
import { Checkbox, PrimaryButton, Text, TextArea } from 'library';
import { Grid } from '@mui/material';
import useLoading from 'library/hooks/useLoading';

interface IFAQAnswer {
    question_id: number;
    business_id?: number;
    business_location_id?: number;
    answer?: string;
    id?: number;
}

export interface IFAQQuestion {
    id: number;
    question: string;
    answers?: IFAQAnswer[];
}

export default function FaqAnswer({
    question,
    location,
    updateQuestion,
    booleanAnswer = false,
}: {
    question: IFAQQuestion;
    location: IBusinessLocation;
    updateQuestion: (question: IFAQQuestion) => void;
    booleanAnswer?: boolean;
}) {
    const [answer, setAnswer] = useState<any>(getQuestionAnswer);
    const { loading, setLoading } = useLoading();

    function getQuestionAnswer() {
        const defaultAnswer = {
            question_id: question.id,
            business_location_id: location.id,
            business_id: null,
            answer: null,
        };
        const answers = question?.answers;
        if ((answers?.length || 0) > 0) {
            let existingAnswer = answers?.find((a: IFAQAnswer) => a.business_location_id);
            existingAnswer = existingAnswer ? existingAnswer : answers?.find((a: IFAQAnswer) => a.business_id);
            return existingAnswer !== undefined ? existingAnswer : defaultAnswer;
        }
        return defaultAnswer;
    }

    function submitAnswer() {
        const method = answer.id ? 'PATCH' : 'POST';
        const url = `api/business-faqs/${method === 'PATCH' ? `${answer.id}/` : ''}`;
        setLoading(true);
        client(url, { body: answer, method })
            .then((res) => {
                let answers = [...(question.answers ?? [])];
                if (answer.id) {
                    let answerIndex = answers.findIndex((a) => a.id === answer.id);
                    answers[answerIndex] = res;
                } else {
                    answers.push(res);
                }
                updateQuestion({ ...question, answers: answers });
                setAnswer(res);
            })
            .catch(consoleLogInDev)
            .finally(() => setLoading(false));
    }

    function detectChanges() {
        const originalAnswer = getQuestionAnswer();
        const originalAnswerHadContent =
            (originalAnswer.business_id || originalAnswer.business_location_id) &&
            (originalAnswer.answer ?? '').length > 0;
        const sameText = (answer?.answer ?? '') === (originalAnswer.answer ?? '');
        const sameBusiness = (answer?.business_id ?? null) === originalAnswer.business_id;
        const sameLocation = (answer?.business_location_id ?? null) === originalAnswer.business_location_id;
        return !originalAnswerHadContent ? sameText : sameText && sameBusiness && sameLocation;
    }
    return (
        <Grid direction="column" style={{ gap: 10, marginTop: 20, marginBottom: 20 }}>
            <Grid item container direction="row" justifyContent="space-between">
                <Text variant="body1" inline>
                    {question.question}
                </Text>
                <Checkbox
                    style={{ width: 'unset' }}
                    label="Default for all locations?"
                    checked={!!answer?.business_id}
                    onChange={() =>
                        answer.business_id
                            ? setAnswer({ ...answer, business_id: null, business_location_id: location.id })
                            : setAnswer({ ...answer, business_id: location.business.id, business_location_id: null })
                    }
                />
            </Grid>
            {booleanAnswer ? (
                <Grid item container style={{ gap: 10 }}>
                    <Checkbox
                        style={{ width: 'unset' }}
                        label="Yes"
                        checked={answer?.answer === 'TRUE'}
                        onChange={() => setAnswer({ ...answer, answer: 'TRUE' })}
                    />
                    <Checkbox
                        style={{ width: 'unset' }}
                        label="No"
                        checked={answer?.answer !== 'TRUE'}
                        onChange={() => setAnswer({ ...answer, answer: 'FALSE' })}
                    />
                </Grid>
            ) : (
                <TextArea
                    style={{ width: '100%' }}
                    multiline
                    rows={5}
                    value={answer?.answer}
                    placeholder="Please provide a description for providers"
                    onChange={(e) => setAnswer({ ...answer, answer: e.target.value })}
                    inputProps={{ maxLength: 500 }}
                    helperText="500 Character limit"
                />
            )}
            <Grid item container justifyContent="end" style={{ marginTop: 20 }}>
                <PrimaryButton
                    loading={loading}
                    disabled={detectChanges()}
                    buttonStyle={{ width: 'unset' }}
                    onClick={submitAnswer}
                >
                    Save
                </PrimaryButton>
            </Grid>
        </Grid>
    );
}
