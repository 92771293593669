import React from 'react';
import { Colors, LoadingIndicator, Text } from 'library';
import { styled } from '@mui/material';
import { format12HourTime } from 'shared/Dates';
import { add } from 'date-fns';

export interface BusinessLocationDailyPairing {
    id: number;
    start_time: string;
    end_time: string;
    user: {
        first_name: string;
        last_name: string;
        id: number;
    };
    hour_report: {
        start: string | null;
        end: string | null;
        late_minutes: number | null;
        approved_break: number | null;
    };
    cancelled_differential: number | null;
    shift_unfilled: boolean;
}

interface AugmentedPairing extends BusinessLocationDailyPairing {
    topMessage: string;
    topColor: string;
    bottomMessage?: string;
    bottomColor?: string;
}

export function BusinessLocationSummaryToday({
    pairings,
    loading,
}: {
    pairings: BusinessLocationDailyPairing[];
    loading: boolean;
}) {
    if (loading) return <LoadingIndicator />;

    if (!pairings || pairings?.length === 0) return <Text>No workers found for the day.</Text>;

    function calculateLateArrivalTime(pairing: BusinessLocationDailyPairing): {
        bottomMessage: string;
        bottomColor: string;
    } {
        if (!pairing.hour_report?.late_minutes)
            return {
                bottomMessage: 'Worker has not marked late, arrival time unknown',
                bottomColor: Colors.error,
            };

        const arrival = format12HourTime(
            add(new Date(pairing.start_time), { minutes: pairing.hour_report.late_minutes }),
        );

        return {
            bottomMessage: `Marked late, arrival time ${arrival}`,
            bottomColor: Colors.error,
        };
    }

    function calculateClockedOutEarly(pairing: BusinessLocationDailyPairing): {
        bottomMessage?: string;
        bottomColor?: string;
    } {
        if (!pairing.hour_report?.end) return {};

        if (new Date(pairing.hour_report.end) < new Date(pairing.end_time)) {
            return {
                bottomMessage: 'Clocked out early',
                bottomColor: Colors.error,
            };
        }

        return {};
    }

    function augmentPairing(pairing: BusinessLocationDailyPairing): AugmentedPairing {
        // NOTE: check for cancelled
        if (pairing.cancelled_differential) {
            return {
                ...pairing,
                topMessage: `Cancelled shift within ${pairing.cancelled_differential} hours`,
                topColor: Colors.error,
                bottomMessage: pairing.shift_unfilled
                    ? 'Shift left unfilled'
                    : 'Shift has been filled by another worker',
                bottomColor: pairing.shift_unfilled ? Colors.error : Colors.turquoise,
            };
        }

        const late = calculateLateArrivalTime(pairing);

        // NOTE: if not cancelled, check if clocked in
        if (pairing.hour_report?.start) {
            // NOTE: if clocked in, check if clocked out
            if (pairing.hour_report?.end) {
                return {
                    ...pairing,
                    topMessage: `${format12HourTime(pairing.hour_report.start)} - ${format12HourTime(
                        pairing.hour_report.end,
                    )} (${pairing.hour_report?.approved_break ?? 0} minute break)`,
                    topColor: Colors.turquoise,
                    ...calculateClockedOutEarly(pairing),
                };
            }

            // NOTE: if clocked in but not clocked out
            return {
                ...pairing,
                topMessage: `Clocked in at ${format12HourTime(pairing.hour_report.start)}`,
                topColor: Colors.turquoise,
                ...late,
            };
        }

        return {
            ...pairing,
            topMessage: 'Not clocked in',
            topColor: Colors.error,
            ...late,
        };
    }

    const augmentedPairings: AugmentedPairing[] = Array.isArray(pairings)
        ? pairings.map((pairing: BusinessLocationDailyPairing) => augmentPairing(pairing))
        : [];

    return (
        <div>
            {augmentedPairings.map((pairing: AugmentedPairing, i: number) => (
                <Row key={pairing.id} last={(i + 1 === augmentPairing?.length).toString()}>
                    <Text textStyle={{ width: '20%' }} bold>
                        {pairing.user.first_name} {pairing.user.last_name}
                    </Text>
                    <Text textStyle={{ width: '20%' }}>
                        {format12HourTime(pairing.start_time)} - {format12HourTime(pairing.end_time)}
                    </Text>
                    <div style={{ width: '40%' }}>
                        <Text bold textStyle={{ color: pairing.topColor }}>
                            {pairing.topMessage}
                        </Text>
                        {!!pairing?.bottomMessage && (
                            <Text textStyle={{ color: pairing.bottomColor }}>{pairing.bottomMessage}</Text>
                        )}
                    </div>
                </Row>
            ))}
        </div>
    );
}

const Row = styled('div')(({ last }: { last: string }) => ({
    display: 'flex',
    borderBottom: last === 'true' ? `1px solid ${Colors.lightGrey}` : 'none',
    paddingTop: 20,
    paddingBottom: 20,
}));
