import React, { useState } from 'react';
import { Text } from 'library';
import { useJobFormContext } from 'parent-portal/forms/JobFormContext';
import BusinessJobTypeChip from 'parent-portal/forms/components/BusinessJobTypeChip';
import JobDetailsChip from './JobDetailsChip';
import { styled } from '@mui/material';
import SectionContainer from './SectionContainer';
import EditJobDetailsModal from './EditJobDetailsModal';

export default function JobDetailsSection() {
    const [editOpen, setEditOpen] = useState(false);
    const { form } = useJobFormContext();
    const { headline, businessJobType, comments, jobDetails } = form.fieldStates;
    const roles = jobDetails.value.filter((detail) => detail.detail === 2);
    const ageGroups = jobDetails.value.filter((detail) => detail.detail === 1);
    return (
        <SectionContainer
            editModal={<EditJobDetailsModal open={editOpen} onClose={() => setEditOpen(false)} />}
            setEditOpen={setEditOpen}
        >
            <Text variant="h2" bold>
                {headline.value} <BusinessJobTypeChip businessJobType={businessJobType.value} />
            </Text>
            <Text variant="body2" textStyle={styles.descriptionText}>
                {comments.value}
            </Text>
            <ChipContainer>
                {[...roles, ...ageGroups].map((detail) => (
                    <JobDetailsChip key={detail.id} detail={detail} />
                ))}
            </ChipContainer>
        </SectionContainer>
    );
}

const styles = {
    descriptionText: {
        marginTop: 10,
    },
};

const ChipContainer = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: 10,
    marginTop: 20,
});
