import React from 'react';
import JobFormPage from '../JobFormPage';
import JobTitleInput from '../HeadlineDescriptionPage/JobTitleInput';
import JobDescriptionInput from '../HeadlineDescriptionPage/JobDescriptionInput';
import { FieldStates } from '../../FormTypes';
import OneTimeJobDateInput from './OneTimeJobDateInput';
import moment from 'moment';
import OneTimeJobTimeInput from './OneTimeJobTimeInput';
import OneTimePayRateInput from './OneTimePayRateInput';
import { IPayFormFields, JobTimes, ProviderSelection } from 'parent-portal/forms/JobRequestTypes';
import { useJobFormContext } from 'parent-portal/forms/JobFormContext';
import { useMediaQuery } from '@material-ui/core';
import theme from 'theme';
import MoreOptions from './MoreOptions';
import PreferredWorkersInput from '../ProviderPreferencesPage/PreferredWorkersInput';
import PastWorkersInput from '../ProviderPreferencesPage/PastWorkersInput';
import { styled } from '@mui/material';

interface OneTimeJobDetailsPageFields {
    headline: string;
    comments: string;
    startDate: moment.Moment | null;
    jobTimes: JobTimes;
    pay: IPayFormFields;
    jobTypeId: number;
    preferredWorkers: ProviderSelection[];
    pastWorkers: ProviderSelection[];
}

interface IProps {
    fieldStates: FieldStates<OneTimeJobDetailsPageFields>;
}

export default function OneTimeJobDetailsPage({
    fieldStates: { headline, comments, startDate, jobTimes, pay, preferredWorkers, pastWorkers },
}: IProps) {
    const { submit } = useJobFormContext();
    const sm = useMediaQuery(theme.breakpoints.down('sm'));

    function pageIsValid() {
        return headline.isValid && comments.isValid && startDate.isValid && jobTimes.isValid && pay.isValid;
    }

    return (
        <JobFormPage
            onNextClicked={() => submit('family-short-form-page')}
            nextText="Finish"
            nextDisabled={!pageIsValid()}
        >
            <JobTitleInput title={headline} />
            <Spacer small={sm} />
            <JobDescriptionInput description={comments} />
            <Spacer small={sm} />
            <OneTimeJobDateInput jobDate={startDate} />
            <Spacer small={sm} />
            <OneTimeJobTimeInput jobTimes={jobTimes} jobDate={startDate.value} />
            <Spacer small={sm} />
            <OneTimePayRateInput pay={pay} />
            <Spacer small={sm} />
            <PreferredWorkersInput selected={preferredWorkers.value} onChange={preferredWorkers.setValue} />
            <Spacer small={sm} />
            <PastWorkersInput selected={pastWorkers.value} onChange={pastWorkers.setValue} />
            <Spacer small={sm} />
            <MoreOptions />
        </JobFormPage>
    );
}

const Spacer = styled('div')(({ small }: { small: boolean }) => ({
    height: small ? 0 : 30,
}));
