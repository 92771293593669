import React, { useEffect } from 'react';
import { IfRender, LoadingIndicator, Text } from 'library';
import Notification from '../Notification/Notification';
import { useNotificationContext } from '../NotificationContext/NotificationContext';
import { Grid } from '@material-ui/core';
export default function NotificationList() {
    const { notifications, viewedNotifications, loaded } = useNotificationContext();

    useEffect(() => {
        if (loaded) {
            viewedNotifications(notifications);
        }
    }, [loaded]);

    return (
        <>
            <IfRender condition={notifications.length > 0}>
                <>
                    {notifications.map((notification, index) => {
                        return <Notification key={index} notification={notification} />;
                    })}
                </>
            </IfRender>
            <IfRender condition={loaded && notifications.length === 0}>
                <Grid container justify="center">
                    <Text variant="body1">Come back soon to see your notifications!</Text>
                </Grid>
            </IfRender>
            <IfRender condition={!loaded}>
                <LoadingIndicator />
            </IfRender>
        </>
    );
}
