import React from 'react';
import LongTerm from './LongTerm';
import ShortTerm from './ShortTerm';
import LookAround from './LookAround';

export default function ShortOrLongTerm() {
    return (
        <>
            <ShortTerm />
            <LongTerm />
            <LookAround />
        </>
    );
}
