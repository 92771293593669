import { DayOfWeekTimesSelection } from 'internal/substitutes/Duplicate/duplicateJobModels';
import { BusinessJobWithAppointments, JobTimesOfDay } from 'models';
import { client } from 'shared';

export async function extendTrialRun(
    job: BusinessJobWithAppointments,
    job_times: DayOfWeekTimesSelection[],
    selectedProviders: number[],
    providerAction: string,
) {
    return await client(`api/business-job/${job.id}/extend-trial-run/`, {
        body: {
            job,
            job_times: job_times
                .filter((t) => t.selected)
                .map((time) => ({ [time.day]: { start: time.start, end: time.end } })),
            selected_providers: selectedProviders,
            provider_action: providerAction,
        },
    });
}

export function dayToJobTimesOfDay(times_of_day: JobTimesOfDay[], day: string) {
    const entry = times_of_day.find((time) => Object.keys(time)[0] === day);
    return {
        day,
        start: entry ? Object.values(entry)[0].start : '08:00:00',
        end: entry ? Object.values(entry)[0].end : '17:00:00',
        selected: !!entry,
    };
}
