import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import IconButton from 'library/components/buttons/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Popper from '@material-ui/core/Popper';
import Fade from '@material-ui/core/Fade';
import Paper from '@material-ui/core/Paper';
import NotificationOptionMenu from './NotificationOptionMenu';

export default function NotificationOptions() {
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [open, setOpen] = useState(false);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
        setOpen(!open);
    };

    return (
        <>
            <Popper open={open} anchorEl={anchorEl} placement={'bottom-end'} transition>
                {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={350}>
                        <Paper>
                            <Grid container style={{ padding: 10, width: 300 }}>
                                <Grid container direction="column">
                                    <NotificationOptionMenu onClose={() => setOpen(false)} />
                                </Grid>
                            </Grid>
                        </Paper>
                    </Fade>
                )}
            </Popper>
            <Grid item>
                <IconButton icon={MoreVertIcon} onClick={handleClick} iconStyle={{ marginRight: 0 }} />
            </Grid>
        </>
    );
}
