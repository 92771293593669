import { createContext } from 'react';
import { Business, BusinessLocationPreferredWorker, ShiftProviderUser } from 'models';

interface IPreferredWorkerModalContext {
    isAdmin: boolean;
    business: Business | { id: number; name: string };
    provider: ShiftProviderUser;
    emptyLocationList: boolean;
    locations: BusinessLocationPreferredWorker[];
    businessLocationId?: number;
    setLocations: React.Dispatch<React.SetStateAction<BusinessLocationPreferredWorker[]>>;
    setError: React.Dispatch<React.SetStateAction<boolean>>;
    onSuccess: (preferredWorkers: BusinessLocationPreferredWorker[]) => void;
    onClose: () => void;
}

export const PreferredWorkerModalContext = createContext<IPreferredWorkerModalContext>({
    isAdmin: false,
    business: {} as Business,
    provider: {} as ShiftProviderUser,
    emptyLocationList: false,
    locations: [],
    businessLocationId: 0,
    setLocations: () => {},
    setError: () => {},
    onSuccess: () => {},
    onClose: () => {},
});
