import React, { useEffect, useState } from 'react';
import { Text, Colors, PrimaryButton } from 'library';
import { AdminProvider, IInfoModalDoc } from 'models';
import { Grid } from '@material-ui/core';
import VerifyModal from 'internal/document-verify/VerifyModal';
import DeleteModal from 'internal/document-verify/DeleteModal';
import VerifyDocuments from 'internal/document-verify/VerifyDocuments';
import { getLinks } from './buildLinks';
import AllDocumentActions from './AllDocumentActions';
import { getRequiredDocumentInfo } from 'api/AdminDocumentApi';
import { Link } from 'react-router-dom';
import DocumentLineItem from './DocumentLineItem';

export default function DocumentsSection({
    provider,
    refreshProvider,
}: {
    provider: AdminProvider;
    refreshProvider: () => void;
}) {
    const [verifyModalOpen, setVerifyModalOpen] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [active, setActive] = useState<IInfoModalDoc | undefined>(undefined);
    const [needsDocumentInfo, setNeedsDocumentInfo] = useState(false);
    const links: IInfoModalDoc[] = getLinks(provider);

    function checkForInfoNeeded() {
        if (provider.user.compliance_state !== 'Illinois') return;
        getRequiredDocumentInfo(provider.user.id).then((res) => {
            setNeedsDocumentInfo(res && res.missing_required_fields);
        });
    }

    useEffect(checkForInfoNeeded, [provider]);

    const documentInfoText = needsDocumentInfo ? 'Needs Document Info Collected' : 'Update Document Info';

    return (
        <>
            <Grid container item direction="row" alignItems="center" justify="space-between" style={{ marginTop: 10 }}>
                <Text variant="h2" bold textStyle={{ color: Colors.turquoise }}>
                    Documents
                </Text>
                <Link
                    title={documentInfoText}
                    style={{
                        color: needsDocumentInfo ? Colors.red : Colors.darkNavy,
                        textDecorationLine: 'underline',
                        fontSize: 14,
                    }}
                    to={`/adminperson/compliance/collect-document-info/${provider.user.id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {documentInfoText}
                </Link>
                <Grid item container xs={12} md={8}>
                    <AllDocumentActions provider={provider} />
                </Grid>
            </Grid>

            <VerifyDocuments passedUserId={provider.user.id} onRefresh={refreshProvider} />

            <ul>
                {links.map((doc: IInfoModalDoc, i: number) => (
                    <DocumentLineItem
                        key={doc.id}
                        doc={doc}
                        setVerifyModalOpen={setVerifyModalOpen}
                        setActive={setActive}
                        setDeleteModalOpen={setDeleteModalOpen}
                        backgroundColor={i % 2 === 0 ? Colors.white : Colors.lightGrey}
                    />
                ))}
            </ul>
            <VerifyModal
                verifyModalOpen={verifyModalOpen}
                setVerifyModalOpen={setVerifyModalOpen}
                form={active}
                activeWorker={provider.user.id}
            />
            <DeleteModal deleteModalOpen={deleteModalOpen} setDeleteModalOpen={setDeleteModalOpen} form={active} />
            <Grid container item xs={6} style={{ margin: 20 }}>
                <PrimaryButton
                    onClick={() => window.open(`/adminperson/upload-documents/${provider.user.id}`, '_blank')}
                >
                    Upload Documents
                </PrimaryButton>
            </Grid>
        </>
    );
}
