import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import { BasicDialog } from 'library';
import { client, consoleLogInDev } from 'shared';
import { Appointment } from 'parent-portal/public/JobModels';
import FormField from 'parent-portal/forms/components/FormField';
import Input from 'reusableComponents/input';
import ErrorText from 'parent-portal/forms/components/ErrorText';
import NotPayingCancellationModal from './NotPayingCancellationModal';
import ProviderRadioContent from './components/ProviderRadioList';

interface Props {
    isOpen: boolean;
    onClose: () => void;
    closeAllModals: () => void;
    refresh: () => void;
    job: Appointment;
    appliedProviders: Provider[];
    hasMessaged: boolean;
    submitReason: () => void;
}

export interface Provider {
    user: {
        first_name: string;
        last_name: string;
        id: number;
    };
    has_recieved_message_from_family: boolean;
}

const CancelWithApplicantModal = ({
    isOpen,
    onClose,
    refresh,
    closeAllModals,
    job,
    appliedProviders,
    hasMessaged,
    submitReason,
}: Props) => {
    const [description, setDescription] = useState('');
    const [descriptionPage, setDescriptionPage] = useState(false);
    const [showCantCancel, setShowCantCancel] = useState(false);
    const [hiredProvider, setHiredProvider] = useState<Provider>();
    const [didntHireProvider, setDidntHireProvider] = useState(true);
    const title = descriptionPage
        ? 'Why are you cancelling this job if you found a provider?'
        : 'Did you hire one of your applicants?';

    function postRelationship() {
        const body = {
            provider: hiredProvider?.user.id,
            family: job.ongoing_request.family.id,
            ongoing_request: job.ongoing_request.id,
            description: description,
        };

        client(`tracking/api/relationships/`, {
            body: body,
        }).catch(consoleLogInDev);
    }

    function getChildren() {
        return descriptionPage ? (
            <FormField title="Reason*">
                <Input
                    fullWidth
                    style={{ marginBottom: 0 }}
                    value={description}
                    onChange={(event) => setDescription(event.target.value)}
                    multiline
                    InputLabelProps={{ shrink: true }}
                />
                {description === '' && <ErrorText>Enter a reason</ErrorText>}
            </FormField>
        ) : (
            <ProviderRadioContent
                appliedProviders={appliedProviders}
                selectProvider={(provider: Provider) => setHiredProvider(provider)}
                updateDidntHire={(value: boolean) => setDidntHireProvider(value)}
            />
        );
    }

    function continueToNextPage() {
        if (didntHireProvider) {
            onClose();
        } else if (descriptionPage) {
            postRelationship();
            setShowCantCancel(true);
        } else {
            setDescriptionPage(true);
        }
    }
    return (
        <Grid>
            <>
                {/* this is the can't cancel wall modal */}
                <NotPayingCancellationModal
                    isOpen={showCantCancel}
                    onClose={() => {
                        setShowCantCancel(false);
                        closeAllModals();
                    }}
                    cancelJob={() => null}
                    job={job}
                    cantCancel
                    refresh={() => refresh()}
                    submitReason={submitReason}
                />
                {!showCantCancel && hasMessaged ? (
                    <BasicDialog
                        isOpen={isOpen}
                        onClose={() => {
                            onClose();
                        }}
                        dialogTitle={title}
                        children={getChildren()}
                        closeButton={false || showCantCancel}
                        buttonEnabled={!descriptionPage || description !== ''}
                        mobileMaxWidth={'60%'}
                        webMaxWidth={'30%'}
                        onSubmit={continueToNextPage}
                        buttonText={'Continue'}
                    />
                ) : (
                    () => onClose()
                )}
            </>
        </Grid>
    );
};

export default CancelWithApplicantModal;
