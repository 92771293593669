import React from 'react';
import { Text } from 'library';
import { BusinessJobResponse } from '../models';
import { Grid, styled } from '@mui/material';
import { format } from 'date-fns';
import { BusinessJobShiftSlot } from './BusinessJobShiftSlot';

export function ShiftDate({
    date,
    businessJobResponse,
    getJob,
}: {
    date: string;
    businessJobResponse: BusinessJobResponse;
    getJob: () => void;
}) {
    const job = businessJobResponse.job_details;
    const shifts = businessJobResponse.shifts.filter((s) => format(new Date(s.start_date), 'EEEE M/d') === date);
    return (
        <ShiftDateContainer key={date} item container direction="column" style={{}}>
            <Text bold>{date}</Text>
            <Grid item container direction="row" style={{ gap: 20 }}>
                {shifts.map((slot) => (
                    <BusinessJobShiftSlot slot={slot} job={job} getJob={getJob} key={slot.id} />
                ))}
            </Grid>
        </ShiftDateContainer>
    );
}

const ShiftDateContainer = styled(Grid)({
    boxShadow: '0px 3px 6px 0px #00000029',
    padding: 20,
    gap: 20,
    borderRadius: 18,
});
