import React, { useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
    Drawer,
    CssBaseline,
    AppBar,
    List,
    Typography,
    Divider,
    IconButton,
    ListItem,
    ListItemText,
    ListItemIcon,
    withStyles,
    Grid,
    useMediaQuery,
    Link,
} from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { useHistory, useLocation } from 'react-router-dom';
import { Colors } from 'library';
import { track } from 'shared';
import { useUserContext } from 'UserContext';
import AuthModal from 'parent-portal/login/AuthModal';
import { Person, Home, Message, ArrowBack, Help, Check, Lock, Menu } from '@material-ui/icons';
import Search from 'assets/images/search.svg';
import Payments from 'assets/icons/Dollar.svg';
import NavigationBar from './NavigationBar';
import { ProfilePicInNav } from './AccountMenu/ProfilePicInNav';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarCheck } from '@fortawesome/free-solid-svg-icons';
import usePublicSessionTracking, { PublicSessionEventType } from 'parent-portal/shared/PublicSessionTracking';
import ImpersonationButton from './ImpersonationButton';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    appBar: {
        overflow: 'hidden',
        webkitOverflowScrolling: 'auto',
        width: '100vw',
        [theme.breakpoints.down('sm')]: {
            height: 50,
        },
        [theme.breakpoints.up('md')]: {
            height: 75,
        },
        height: 75,
        justifyContent: 'center',
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    topScrollPaper: {
        alignItems: 'flex-start',
    },
    topPaperScrollBody: {
        verticalAlign: 'top',
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: -drawerWidth,
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    },
}));

const StyledIconButton = withStyles({
    root: {
        '&:focus': {
            outline: 'none',
        },
        paddingRight: 0,
        marginRight: 0,
    },
})(IconButton);

function StyledDrawer() {
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const pathname = useLocation().pathname;
    const { user, role, logout } = useUserContext();
    const [open, setOpen] = React.useState(false);

    const smDown = useMediaQuery(theme.breakpoints.down('sm'));
    const { trackSession } = usePublicSessionTracking('Top Navigation - Main Menu');
    const isBusiness = role === 'business_active';

    const RESOURCES = {
        FAQ: {
            icon: <Help />,
            route: null,
            href:
                role === 'business_active'
                    ? 'https://info.join-tandem.com/business/faq'
                    : 'https://info.join-tandem.com/families#family-faq',
        },
        'Terms of Use': {
            icon: <Check />,
            route: null,
            href: `https://www.join-tandem.com/tandem-terms-of-use/${isBusiness ? 'center' : 'family'}`,
        },
        'Privacy Policy': { icon: <Lock />, route: null, href: 'https://www.join-tandem.com/privacy-policy' },
    };

    const MENU = [
        { title: 'Home', icon: <Home htmlColor={Colors.mediumGrey} />, route: '/home' },
        { title: 'Profile', icon: <Person htmlColor={Colors.mediumGrey} />, route: '/profile' },
        {
            title: 'My Jobs',
            icon: <FontAwesomeIcon icon={faCalendarCheck} style={{ fontSize: 24 }} color={Colors.grey} />,
            route: '/jobs',
        },
        {
            title: role === 'business_active' ? 'My Staff' : 'Providers',
            icon: <Search color={Colors.mediumGrey} width={24} height={24} />,
            route: '/providers?s=0&source=DRAWER',
        },
        { title: 'Messages', icon: <Message htmlColor={Colors.mediumGrey} />, route: '/messages' },
        { title: 'Payments', icon: <Payments width={24} height={24} color={Colors.mediumGrey} />, route: '/payments' },
    ].filter((item) => item !== null);

    useEffect(() => {
        if (open) {
            setOpen(false);
        }
    }, [pathname]);

    const handleDrawerOpen = () => {
        setOpen(true);
        trackSession('CLICK', 'Open', PublicSessionEventType.User);
    };

    const handleDrawerClose = () => {
        setOpen(false);
        trackSession('CLICK', 'Close', PublicSessionEventType.User);
    };

    const handleLogout = () => {
        logout();
        trackSession('CLICK', 'Logout', PublicSessionEventType.User);
    };

    return (
        <div className={classes.root}>
            <CssBaseline />
            <Grid container style={{ height: smDown ? 50 : 75 }} />
            <AppBar position="fixed" className={clsx(classes.appBar)}>
                <Grid container direction="row" justify="space-between">
                    <div
                        style={{
                            padding: smDown ? 0 : 8,
                            paddingLeft: smDown ? 6 : 8,
                            alignItems: 'center',
                            display: 'flex',
                            flexDirection: 'row',
                        }}
                    >
                        <StyledIconButton
                            id="btnDrawer"
                            color="inherit"
                            aria-label="open drawer"
                            onClick={handleDrawerOpen}
                            edge="start"
                            className={clsx(classes.menuButton, open && classes.hide)}
                        >
                            <Menu fontSize={smDown ? 'small' : 'large'} />
                        </StyledIconButton>
                        <ImpersonationButton />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        {!smDown && <NavigationBar />}
                        <ProfilePicInNav />
                    </div>
                </Grid>
            </AppBar>
            {smDown && <NavigationBar />}
            <Drawer
                className={classes.drawer}
                variant="persistent"
                anchor="left"
                open={open}
                classes={{
                    paper: classes.drawerPaper,
                }}
            >
                <div className={classes.drawerHeader}>
                    <StyledIconButton onClick={handleDrawerClose}>
                        {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                    </StyledIconButton>
                </div>
                <Divider />
                <Typography variant="subtitle1" style={{ marginLeft: 10, marginTop: 15 }}>
                    MENU
                </Typography>
                <List>
                    {MENU.map((item, i) => (
                        <ListItem
                            button
                            key={i}
                            onClick={() => {
                                history.push(item?.route ?? '');
                                track('Main Menu: ' + item?.title);
                            }}
                        >
                            <ListItemIcon style={{ marginLeft: 10 }}>{item?.icon}</ListItemIcon>
                            <ListItemText primary={item?.title} style={{ color: theme.palette.primary.main }} />
                        </ListItem>
                    ))}
                </List>
                <Divider />
                <Typography variant="subtitle1" style={{ marginLeft: 10, marginTop: 15 }}>
                    RESOURCES
                </Typography>
                <List>
                    {Object.entries(RESOURCES).map(([k, v], i) => (
                        <ListItem
                            button
                            key={k}
                            onClick={() => {
                                track('Main Menu: ' + k);
                            }}
                            component={Link}
                            href={v.href}
                        >
                            <ListItemIcon style={{ marginLeft: 10 }}>{v.icon}</ListItemIcon>
                            <ListItemText primary={k} style={{ color: theme.palette.primary.main }} />
                        </ListItem>
                    ))}
                </List>
                <Divider />
                <Typography variant="subtitle1" style={{ marginLeft: 10, marginTop: 15 }}>
                    SETTINGS
                </Typography>
                <List>
                    <ListItem
                        id="btnDrawerLogout"
                        button
                        onClick={() => {
                            handleLogout();
                        }}
                    >
                        <ListItemIcon style={{ marginLeft: 10 }}>
                            <ArrowBack />
                        </ListItemIcon>
                        <ListItemText primary="Sign Out" style={{ color: theme.palette.primary.main }} />
                    </ListItem>
                </List>
            </Drawer>
            <AuthModal open={!!user && user.signup_page < 4} location="Drawer" onClose={() => {}} />
        </div>
    );
}

export default StyledDrawer;
