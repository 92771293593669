import React from 'react';
import { Paper as MaterialPaper } from '@material-ui/core';
import type { PaperProps } from '@mui/material';

interface IProps extends PaperProps {
    id?: string;
    children: React.ReactNode | React.ReactNode[];
    borderRadius?: number;
    padding?: number | string;
    style?: React.CSSProperties | undefined;
}
export default function Paper({ children, borderRadius = 18, padding = 30, style, ...props }: IProps) {
    return (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        <MaterialPaper
            style={{
                ...{ width: '100%', padding: padding, borderRadius: borderRadius, boxShadow: '0px 3px 6px #00000029' },
                ...style,
            }}
            {...props}
        >
            {children}
        </MaterialPaper>
    );
}
