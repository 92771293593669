import React, { useState } from 'react';
import theme from 'theme';
import BanWorkerModal from 'parent-portal/shared/UserActions/BanWorkerModal';
import { Grid, useMediaQuery } from '@material-ui/core';
import { PrimaryButton, SecondaryButton, SizeableRoundedDialog, Text } from 'library';
import { useUserContext } from 'UserContext';

import type { ShiftProviderUser } from 'models';

export default function BanProviderAction({
    open,
    onClose,
    provider,
}: {
    open: boolean;
    onClose: () => void;
    provider: ShiftProviderUser;
}) {
    const small = useMediaQuery(theme.breakpoints.down('sm'));
    const { user } = useUserContext();
    const [banAction, setBanAction] = useState(false);
    const businessId = user?.businesses?.[0]?.id;

    if (!businessId) return null;

    return (
        <>
            <SizeableRoundedDialog
                style={{ maxWidth: small ? '100%' : '50%' }}
                open={open}
                onClose={onClose}
                title={``}
                closeButton
            >
                <Text textStyle={{ paddingLeft: 20 }} variant="display">
                    {provider.first_name} has been removed from this shift.
                </Text>
                <Grid item container direction="column" style={{ padding: 20, gap: 20 }}>
                    <Text variant="body1">
                        {provider?.first_name} has been successfully removed from the shift. If you would like to
                        request that they no longer be booked at this location or your entire business. Please select
                        the button below.
                    </Text>
                    <Grid item container justify="space-around">
                        <SecondaryButton
                            buttonStyle={{ width: 'unset' }}
                            onClick={() => {
                                setBanAction(true);
                            }}
                        >
                            Request Not To Book
                        </SecondaryButton>
                        <PrimaryButton
                            buttonStyle={{ width: 'unset' }}
                            onClick={() => {
                                setBanAction(false);
                                onClose();
                            }}
                        >
                            Continue
                        </PrimaryButton>
                    </Grid>
                    <BanWorkerModal
                        open={banAction}
                        onSuccess={() => {}}
                        onClose={() => setBanAction(false)}
                        provider={provider}
                    />
                </Grid>
            </SizeableRoundedDialog>
        </>
    );
}
