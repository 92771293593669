import React, { useContext, useEffect, useState } from 'react';
import client from './ApiClient';

interface FlagInfo {
    value: string | undefined;
    is_active: boolean;
}

export enum FeatureFlag {
    AUTOSCHEDULE_WORKER = 'AUTOSCHEDULE_WORKER',
    CENTER_CHATWOOT_ENABLED = 'CENTER_CHATWOOT_ENABLED',
    FAST_QUALIFICATION_FILTER = 'FAST_QUALIFICATION_FILTER',
}

type FeatureFlagContextType = {
    [key in FeatureFlag]?: FlagInfo;
};

interface FeatureFlagResponse extends FlagInfo {
    category: keyof FeatureFlag;
}

const FeatureFlagContext = React.createContext<FeatureFlagContextType>({});

const FIVE_MINUTES = 1000 * 60 * 5;

export function FeatureFlagProvider({ children }: { children: React.ReactNode }) {
    const [flags, setFlags] = useState<FeatureFlagContextType>();
    const [refreshInterval, setRefreshInterval] = useState<number>(0);

    const fetchData = () => {
        client('api/feature-flag/')
            .then((res: FeatureFlagResponse[]) => {
                const newFlags = res.reduce((a, flag) => {
                    const { category, ...other } = flag;
                    return { ...a, [category]: other };
                }, {});
                setFlags(newFlags);
            })
            .catch(() => {});
    };

    useEffect(() => {
        if (refreshInterval) {
            const interval = setInterval(fetchData, refreshInterval);
            return () => clearInterval(interval);
        } else {
            fetchData();
            setRefreshInterval(FIVE_MINUTES);
        }
    }, [refreshInterval]);

    return <FeatureFlagContext.Provider value={flags ?? {}}>{children}</FeatureFlagContext.Provider>;
}

export function useFeatureFlag(key: FeatureFlag) {
    const flags = useContext(FeatureFlagContext);

    return { featureActive: flags[`${key}`]?.is_active ?? false, featureValue: flags[`${key}`]?.value };
}
