import React, { useState } from 'react';
import Slider from '@material-ui/core/Slider';
import Grid from '@material-ui/core/Grid';
import HelpIcon from '@material-ui/icons/Help';
import { SimpleDialog } from '../../../reusableComponents/dialogsSimple';
import { Colors, Text } from 'library';
import { useUserContext } from 'UserContext';

const stateToMinPay = { Ohio: 11, Florida: 13, Illinois: 14 };

const PaySlider = ({ value, onPayRateChange, payRateTip, isRange }) => {
    const { user } = useUserContext();
    const state = user.address[0].state;
    // eslint-disable-next-line security/detect-object-injection
    const minPay = stateToMinPay[state];
    const marks = [minPay, 35].map((val) => ({ value: val, label: `$${val}` }));
    const [displayPayRangeHelp, setDisplayPayRangeHelp] = useState(false);

    const onHelpClick = () => {
        setDisplayPayRangeHelp(true);
    };

    const getHelpDialogText = (
        <Grid container>
            <Text variant="body1">
                Setting a pay range lets providers know the pay rate is negotiable. You'll confirm the hourly rate when
                you hire your provider.
            </Text>
            <Text variant="body1">To select a set pay rate, move both selectors to the same number.</Text>
        </Grid>
    );

    return (
        <>
            <SimpleDialog
                isOpen={displayPayRangeHelp}
                dialogTitle={'Pay Range vs Set Pay Rate'}
                onClose={() => setDisplayPayRangeHelp(false)}
            >
                {getHelpDialogText}
            </SimpleDialog>
            <Text variant="h2" bold textStyle={{ minWidth: 200 }}>
                Add Pay Range or Set Rate Per Hour
                {isRange && (
                    <HelpIcon
                        onClick={onHelpClick}
                        style={{ paddingBottom: 5, paddingLeft: 5, color: Colors.darkNavy }}
                    />
                )}
            </Text>
            <Slider
                style={{ marginTop: '60px' }}
                value={value}
                onChange={onPayRateChange}
                valueLabelDisplay="on"
                max={35}
                min={minPay}
                step={1}
                marks={marks}
                track={isRange}
            />
            <Text variant="caption" style={{ fontSize: 12 }}>
                {payRateTip}
            </Text>
        </>
    );
};

export default PaySlider;
