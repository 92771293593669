import { Grid, useMediaQuery } from '@mui/material';
import React, { useEffect, useRef } from 'react';
import { Text, Colors, Highlight } from 'library';
import ErrorIcon from '@mui/icons-material/Error';
import theme from 'theme';
import { ArcherContainer, ArcherContainerRef, ArcherElement } from 'react-archer';
import CircleNumber from '../../components/CircleNumber';

export default function HowItWorksSection() {
    const smDown = useMediaQuery(theme.breakpoints.down('sm'));
    const mdDown = useMediaQuery(theme.breakpoints.down('md'));
    const arrowRef = useRef<ArcherContainerRef | null>(null);

    useEffect(() => {
        if (arrowRef.current) arrowRef.current?.refreshScreen();
    }, [arrowRef]);

    const numberedSections = [
        {
            id: 'create-an-account',
            title: 'Create an account.',
            content:
                'Signing up takes less than 30 seconds and there is no cost to do so. Once your profile is created, you will be able to add additional program and specific center information.',
        },
        {
            id: 'post-your-needs',
            title: 'Post your needs.',
            content:
                'You will have the option to post both permanent and temporary roles. Once your roles are posted, Tandem can begin looking for the best fit worker.',
            extra: (
                <Text bold textStyle={{ marginTop: 10 }}>
                    <ErrorIcon
                        style={{
                            backgroundColor: Colors.lightRed,
                            color: Colors.pink,
                            borderRadius: 100,
                            padding: 0,
                            marginRight: 10,
                        }}
                    />
                    Heads up! You set the worker's pay rate.
                </Text>
            ),
        },
        {
            id: 'tandem-begins-the-matching-process',
            title: 'Tandem begins the matching process.',
            content:
                "Tandem's Matching Specialists and technology-driven system will connect your roles to the best-fit, compliant workers.",
        },
        {
            id: 'confirmation-of-worker-and-schedule',
            title: 'Confirmation of worker and schedule.',
            content:
                'You will be notified via email when Tandem confirms a worker for your job. You will receive all of their information, including their profile and compliance file.',
        },
        {
            id: 'worker-works-scheduled-shifts',
            title: 'Worker works scheduled shift(s).',
            content:
                'Once a shift(s) is scheduled, the worker will attend their shift. For permanent roles, the worker will begin their 120 hour trial run. Once a shift is completed, the worker will be paid via Tandem. You will be charged weekly.',
        },
    ];

    return (
        <Grid xs={12} justifyContent="center" item container style={{ padding: 40, gap: 40 }}>
            <Text variant="display" textStyle={{ alignContent: 'center' }}>
                <Highlight color={Colors.lightTurq}>How It Works</Highlight>
            </Text>
            <ArcherContainer strokeColor={Colors.darkNavy} offset={5} ref={arrowRef}>
                <Grid
                    xs={12}
                    item
                    container
                    style={{ gap: 80, borderRadius: 18, boxShadow: '0px 3px 6px #00000029', padding: 20 }}
                    justifyContent={'center'}
                >
                    {numberedSections.map((section, index) => {
                        const nextId = numberedSections[index + 1]?.id;
                        return (
                            <Grid
                                item
                                container
                                key={index}
                                xs={12}
                                md={6}
                                style={{
                                    gap: 20,
                                    justifyContent: mdDown ? 'center' : index % 2 !== 0 ? 'end' : 'start',
                                }}
                                direction="row"
                                alignItems="center"
                            >
                                {smDown ? (
                                    <CircleNumber index={index} />
                                ) : (
                                    <ArcherElement
                                        id={section.id}
                                        relations={[
                                            {
                                                targetId: nextId,
                                                targetAnchor: mdDown ? 'top' : index % 2 === 0 ? 'left' : 'top',
                                                sourceAnchor: 'bottom',
                                                style: { strokeDasharray: '10,5', strokeWidth: 5 },
                                            },
                                        ]}
                                    >
                                        <CircleNumber index={index} />
                                    </ArcherElement>
                                )}

                                <Grid item container xs={12} md={6}>
                                    <Text variant="h1">{section.title}</Text>
                                    <Text>{section.content}</Text>
                                    {section.extra && section.extra}
                                </Grid>
                            </Grid>
                        );
                    })}
                </Grid>
            </ArcherContainer>
        </Grid>
    );
}
