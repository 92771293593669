import React from 'react';
import { Grid } from '@mui/material';
import { ShiftProviderUser } from 'models';
import ProviderHeader from './ProviderHeader';
import UnfilledJobHeader from './UnfilledJobHeader';

export function BusinessSlotHeader({
    provider,
    headline,
    businessJobType,
}: {
    provider?: ShiftProviderUser;
    headline: string;
    businessJobType?: string;
}) {
    return (
        <Grid xs={9} direction="row" container item alignItems="center">
            {provider ? (
                <ProviderHeader provider={provider} />
            ) : (
                <UnfilledJobHeader headline={headline} businessJobType={businessJobType} />
            )}
        </Grid>
    );
}
