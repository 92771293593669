import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { BasicDialog, Colors } from 'library';
import { client, queryStringifyObject } from 'shared';
import { Business, BusinessLocationPreferredWorker, ShiftProviderUser } from 'models';
import { useUserContext } from 'UserContext';
import Error from 'parent-portal/provider-reviews/components/Error';

import { TextContainer } from '../../../../parent-portal/provider-reviews/components/Containers';
import { Header, InnerModalContent, PreferredWorkerModalContext } from './components';

export default function PreferWorkerModal({
    open,
    onSuccess,
    onClose,
    provider,
    businessLocationId,
}: {
    open: boolean;
    onSuccess: (preferredWorkers: BusinessLocationPreferredWorker[], prompt: boolean) => void;
    onClose: (prompt?: boolean) => void;
    provider: ShiftProviderUser;
    businessLocationId?: number;
}) {
    const { user } = useUserContext();

    const [businessLocation, setBusinessLocation] = useState<{ id: number; name: string; business: { id: number } }>();
    const [business, setBusiness] = useState<Business | undefined>(user?.businesses_active?.[0]);
    const [locations, setLocations] = useState<BusinessLocationPreferredWorker[]>([]);
    const [empty, setEmpty] = useState(false);
    const [error, setError] = useState(false);

    const businessIdNumber: number | undefined = business?.id ?? businessLocation?.business?.id;

    useEffect(() => {
        if (open) {
            if (businessIdNumber && provider.id) {
                client(
                    `api/preferred-worker/locations-and-statuses/?business_id=${businessIdNumber}&preferred_worker_id=${provider.id}`,
                )
                    .then((response: BusinessLocationPreferredWorker[]) => {
                        setLocations(response);
                        response?.length === 0 ? setEmpty(true) : setBusiness(response[0].business);
                        setError(false);
                    })
                    .catch(() => setError(true));
            } else {
                setError(true);
            }
        }
    }, [businessIdNumber, provider.id, open]);

    useEffect(() => {
        if (open && businessLocationId) {
            client(`api/business-location-overview/${businessLocationId}/`, { method: 'GET' })
                .then(setBusinessLocation)
                .catch(() => setError(true));
        }
    }, [businessLocationId, open]);

    function onPreferSuccess(preferredWorkers: BusinessLocationPreferredWorker[]) {
        if (user?.admin && (!businessIdNumber || !provider.id)) {
            onSuccess(preferredWorkers, false);
        } else {
            const reviewParams = queryStringifyObject({
                business_id: businessIdNumber,
                user_id: provider.id,
                sentiment: 'prefer',
            });

            client(`api/provider-review/prompt-for-review/?${reviewParams}`)
                .then((response: { prompt: boolean }) => {
                    onSuccess(preferredWorkers, response.prompt);
                })
                .catch(() => {
                    onSuccess(preferredWorkers, false);
                });
        }
    }

    return (
        <BasicDialog
            isOpen={open}
            onClose={() => {
                setError(false);
                onClose();
            }}
            dialogTitle={error ? '' : `Add ${provider.first_name} to your preferred workers list`}
            closeButton
        >
            {!user || !businessIdNumber || error ? (
                <>
                    <Error />
                    <TextContainer>
                        <Link
                            to="/home"
                            style={{ color: Colors.darkNavy, textDecoration: 'underline', fontWeight: 'bold' }}
                        >
                            Back home
                        </Link>
                    </TextContainer>
                </>
            ) : (
                <PreferredWorkerModalContext.Provider
                    value={{
                        isAdmin: user?.admin ?? false,
                        business: business ?? {
                            id: businessIdNumber,
                            name: businessLocation?.name ?? `Business (${businessIdNumber})`,
                        },
                        provider,
                        emptyLocationList: empty,
                        locations,
                        businessLocationId,
                        setLocations,
                        setError,
                        onSuccess: onPreferSuccess,
                        onClose,
                    }}
                >
                    <Header />
                    <InnerModalContent />
                </PreferredWorkerModalContext.Provider>
            )}
        </BasicDialog>
    );
}
