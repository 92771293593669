import React, { useRef } from 'react';
import { Grid } from '@material-ui/core';
import { Colors, Text, PrimaryButton, Expandable } from 'library';
import JobSummary from './JobSummary';
import NextSteps from './NextSteps';
import { Job } from 'models';
import { useUserContext } from 'UserContext';

interface JobSuccessPageProps {
    onClose: () => void;
    job?: Job;
}

export default function JobSuccessPage({ onClose, job }: JobSuccessPageProps) {
    const { role } = useUserContext();

    const isBusiness = role === 'business_active';
    return (
        <>
            <Text variant="display">Your request has been submitted!</Text>
            <Grid container direction="column">
                <NextSteps />
                <Divider />
                {!isBusiness ? <ExpandableJobSummary /> : null}
                <Grid container direction="row" justify="flex-end">
                    <PrimaryButton
                        buttonStyle={{ maxWidth: 250, margin: 5, marginBottom: 15 }}
                        onClick={onClose}
                        id="job-success-page-next-button"
                    >
                        {isBusiness && job?.quick_fill ? 'Close' : 'Next'}
                    </PrimaryButton>
                </Grid>
            </Grid>
        </>
    );
}

function Divider() {
    return (
        <div style={{ marginTop: 25, marginBottom: 25, backgroundColor: Colors.darkNavy, height: 2, width: '100%' }} />
    );
}

function ExpandableJobSummary() {
    const jobSummaryRef = useRef<HTMLDivElement>(null);

    return (
        <div style={{ marginTop: 10, marginBottom: 14 }}>
            <Expandable
                collapseControl="Job Details"
                expandableContent={<JobSummary ref={jobSummaryRef} />}
                expandableContentRef={jobSummaryRef}
            />
        </div>
    );
}
