import * as Yup from 'yup';
import { Colors } from 'library';
import { useMediaQuery } from '@mui/material';
import theme from 'theme';

const phoneRegExp = /^\((\d{3})\) (\d{3})-(\d{4})$/;

export const formSchema = Yup.object().shape({
    first_name: Yup.string().required('First name is required'),
    last_name: Yup.string().required('Last name is required'),
    email: Yup.string().email().required('Email address is required'),
    phone_number: Yup.string()
        .required('required')
        .matches(phoneRegExp, 'Phone number is not valid format: (555) 444-3333'),
    relationship: Yup.string().required('Relationship is required'),
    relationship_free_input: Yup.string().when('relationship', {
        is: (relationship: string) => relationship === 'Other',
        then: Yup.string().required('Relationship text input is required when Other is selected'),
    }),
    reference_text: Yup.string()
        .required('Reference text is required')
        .min(100, 'Minimum 100 characters required for reference text'),
});

export const relationshipSelectOptions = [
    { value: 'Employer', key: 'Employer' },
    { value: 'Supervisor', key: 'Supervisor' },
    { value: 'Mentor', key: 'Mentor' },
    { value: 'Teacher', key: 'Teacher' },
    { value: 'Coworker', key: 'Coworker' },
    { value: 'Other', key: 'Other' },
];

export function useFormStyles() {
    const xsDown = useMediaQuery(theme.breakpoints.down('xs'));
    const smDown = useMediaQuery(theme.breakpoints.down('sm'));
    const smOnly = useMediaQuery(theme.breakpoints.only('sm'));
    const mdAndUp = useMediaQuery(theme.breakpoints.up('md'));
    const lgAndUp = useMediaQuery(theme.breakpoints.up('lg'));

    return {
        pageGrid: {
            minHeight: '75vh',
            minWidth: !xsDown ? 750 : 400,
            backgroundColor: Colors.lightTurq,
            width: xsDown ? '100%' : '',
        },
        pageTitleGrid: {
            marginTop: 10,
            marginBottom: 10,
            minWidth: 350,
        },
        headerText: {
            marginTop: 20,
        },
        welcomeText: {
            paddingTop: '5px',
            paddingBottom: '5px',
            width: '100%',
        },
        button: {
            width: 200,
        },
        formHeadingGrid: {
            marginTop: '3vh',
            paddingLeft: xsDown ? '3vh' : '10vw',
            paddingRight: xsDown ? '3vh' : '',
            paddingTop: 10,
            paddingBottom: 10,
            minWidth: !xsDown ? 650 : '',
        },
        formGrid: {
            marginTop: '3vh',
            gap: 20,
            minWidth: xsDown ? 350 : 650,
            maxWidth: lgAndUp ? '80vw' : '',
            marginLeft: lgAndUp ? '10vw' : '',
            marginRight: lgAndUp ? '10vw' : '',
            paddingLeft: '3vw',
            paddingTop: '25px',
            paddingRight: '3vw',
            paddingBottom: '25px',
        },
        firstFourGrid: {
            gap: smDown ? 0 : 5,
        },
        formRow: {
            width: '100%',
        },
        formColumn: {
            width: smDown ? '100%' : '49.5%',
        },
        formInputCluster: {
            width: '100%',
            gap: xsDown ? 10 : 5,
            paddingBottom: xsDown ? 5 : 10,
        },
        formInputClusterSansError: {
            gap: '2vw',
            width: '100%',
            paddingLeft: 10,
            paddingRight: smOnly ? '10vw' : 5,
        },
        fieldText: {
            padding: '11px 5px 0px 5px',
        },
        fieldInput: {
            backgroundColor: Colors.white,
            borderRadius: 15,
            borderWidth: 0.25,
        },
        dropDownFieldInput: {
            height: '100%',
            width: xsDown ? '100%' : 250,
            backgroundColor: Colors.white,
            borderRadius: 15,
            borderWidth: 0.1,
        },
        dropDownFieldInputError: {
            height: '100%',
            minWidth: mdAndUp ? 100 : '',
            width: xsDown ? '100%' : smOnly ? '200px' : '',
            backgroundColor: Colors.white,
            borderRadius: 15,
            border: `${Colors.error} solid 3px`,
        },
        inputWhiteBackground: {
            backgroundColor: Colors.white,
            borderRadius: 15,
            borderWidth: 0.1,
        },
        inputWhiteBackgroundError: {
            backgroundColor: Colors.white,
            borderRadius: 15,
            border: `${Colors.error} solid 3px`,
        },
        textAreaInput: {
            maxWidth: xsDown ? '100%' : mdAndUp ? '60%' : '70%',
            width: xsDown ? '100%' : '',
            border: `${Colors.darkNavy} solid 1px`,
            backgroundColor: Colors.white,
            borderRadius: '15px 15px 0px 0px',
            borderWidth: 0.1,
        },
        textAreaInputError: {
            border: `${Colors.error} solid 3px`,
            maxWidth: xsDown ? '100%' : '70%',
            width: xsDown ? '100%' : '',
            backgroundColor: Colors.white,
            borderRadius: 15,
        },
        textAreaInputProps: {
            backgroundColor: Colors.white,
            borderRadius: 15,
            borderWidth: 0.1,
            margin: 5,
            padding: 10,
        },
        errorDiv: {
            marginTop: xsDown ? 0 : 5,
            marginRight: xsDown ? 0 : smOnly ? '10vw' : 10,
            width: xsDown ? '100%' : 'fit-content',
            paddingLeft: xsDown ? '10vw' : '',
            paddingTop: xsDown ? 0 : 5,
            paddingBottom: xsDown ? 0 : 5,
            height: '30px',
        },
    };
}
