import React, { CSSProperties } from 'react';
import { Text } from 'library';
import DontWorry from './DontWorry';
import DollarIcon from 'assets/icons/tandempay/dollar-icon-circle.svg';
import DontRecycleMoneyIcon from 'assets/icons/tandempay/dont-recycle-money-icon-circle.svg';
import TouchIcon from 'assets/icons/tandempay/touch-icon-circle.svg';

const spaced: CSSProperties = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 18,
};

const icon: CSSProperties = {
    width: 35,
    height: 35,
    marginRight: 17,
};

export default function OnetimeJobCopy() {
    return (
        <>
            <div style={{ marginBottom: 33 }}>
                <Text variant="body1">
                    We’ll notify you when your job receives applicants. Once you review profiles and hire a provider,
                    you'll pay them through TandemPay, our free-to-use, built-in payment platform.
                </Text>
            </div>
            <div style={spaced}>
                <DontWorry />
            </div>
            <div style={spaced}>
                <TouchIcon style={icon} />
                <Text variant="body1">It's just like Venmo, click of a button.</Text>
            </div>
            <div style={spaced}>
                <DontRecycleMoneyIcon style={icon} />
                <Text variant="body1">You don't pay fees and your provider gets 100%.</Text>
            </div>
            <div style={spaced}>
                <DollarIcon style={icon} />
                <Text variant="body1">Pay your providers for future jobs on their profile.</Text>
            </div>
        </>
    );
}
