import { Grid } from '@mui/material';
import { Colors, Text } from 'library';
import React from 'react';

export function GoingWellBlock() {
    const sections = [
        {
            beforeHighlight: 'Over',
            highlighted: '20,000+',
            afterHighlight: 'shifts worked',
            color: Colors.darkNavy,
        },
        {
            beforeHighlight: 'in',
            highlighted: '200+',
            afterHighlight: 'child care centers',
            color: `rgba(0,47,77, .75)`, //NOTE: darkNavy in rgba with opacity of .75
        },
        {
            beforeHighlight: 'by',
            highlighted: '1,000+',
            afterHighlight: 'fully compliant child care workers',
            color: Colors.turquoise,
        },
        {
            beforeHighlight: 'with only a small',
            highlighted: 'team of 20',
            afterHighlight: 'working to help along the way.',
            color: `rgba(0,166,141, .75)`, //NOTE: darkNavy in rgba with opacity of .75
        },
    ];
    return (
        <Grid item container flexDirection="column" style={{ gap: 20 }}>
            <Text variant="h1">It's going well so far.</Text>
            {sections.map((section) => (
                <Grid
                    item
                    container
                    flexDirection="row"
                    style={{
                        borderLeft: `20px solid ${section.color}`,
                        padding: 20,
                    }}
                >
                    <Grid item container xs={12} flexDirection="column">
                        <Text>
                            {section.beforeHighlight}{' '}
                            <Text inline textStyle={{ color: Colors.turquoise }}>
                                {section.highlighted}
                            </Text>{' '}
                            {section.afterHighlight}
                        </Text>
                    </Grid>
                </Grid>
            ))}
        </Grid>
    );
}
