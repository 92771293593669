import React from 'react';
import { CircularProgress, withStyles } from '@material-ui/core';
import { PrimaryButton, SecondaryButton } from 'library';

const Loader = withStyles()((props) => <CircularProgress size={20} color="white" />);

const LoadingButton = (props) => {
    const { children, loading, asSecondary, ...rest } = props;
    if (!asSecondary) {
        return (
            <PrimaryButton {...rest}>
                {!loading && children}
                {loading && <Loader {...rest} />}
            </PrimaryButton>
        );
    } else {
        return (
            <SecondaryButton {...rest}>
                {!loading && children}
                {loading && <Loader {...rest} />}
            </SecondaryButton>
        );
    }
};

export default LoadingButton;
