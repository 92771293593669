import React, { useState } from 'react';
import ImageUploader from 'react-images-upload';
import { Grid } from '@material-ui/core';
import { BasicDialog, Colors, Text, LoadingIndicator } from 'library';

export default function UploadPhotoDialog({
    onUpload,
    isOpen,
    onClose,
    singleImage = false,
}: {
    onUpload: (files: File[]) => Promise<void>;
    isOpen: boolean;
    onClose: () => void;
    singleImage?: boolean;
}) {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [selectedPhotos, setSelectedPhotos] = useState<File[]>();

    const close = () => {
        setLoading(false);
        setSelectedPhotos(undefined);
        setError(false);
        onClose();
    };

    const upload = () => {
        if (selectedPhotos) {
            setLoading(true);
            onUpload(selectedPhotos)
                .then(close)
                .catch((e) => setError(e))
                .finally(() => setLoading(false));
        }
    };

    return (
        <BasicDialog
            isOpen={isOpen}
            onClose={close}
            dialogTitle={singleImage ? 'Upload Photo' : 'Upload Photos'}
            closeButton
            buttonText="Upload"
            secondaryButtonText="Cancel"
            onSubmit={upload}
            onSecondaryButton={close}
            buttonEnabled={!loading && !!selectedPhotos}
        >
            <Grid>
                {loading ? (
                    <LoadingIndicator />
                ) : (
                    <ImageUploader
                        withIcon={false}
                        withPreview={true}
                        buttonText={singleImage ? 'Select Photo' : 'Select Photos'}
                        onChange={setSelectedPhotos}
                        imgExtension={['.jpg', '.png', '.jpeg']}
                        maxFileSize={5242880}
                        singleImage={singleImage}
                        buttonStyles={{
                            backgroundColor: Colors.darkNavy,
                            paddingTop: 10,
                            paddingBottom: 10,
                            width: 200,
                        }}
                        fileContainerStyle={{
                            boxShadow: 'none',
                        }}
                    />
                )}
                {error && (
                    <Text color={Colors.error}>
                        Oops! Something went wrong uploading your photos. Please try again.
                    </Text>
                )}
            </Grid>
        </BasicDialog>
    );
}
