import React from 'react';
import { Grid, useMediaQuery } from '@mui/material';
import { Colors, SecondaryButton, Text } from 'library';
import LogoRow from './LogoRow';
import DownloadRow from './DownloadRow';
import { useUserContext } from 'UserContext';
import { useHistory } from 'react-router';
import theme from 'theme';

export default function Footer() {
    const xsOnly = useMediaQuery(theme.breakpoints.only('xs'));
    const history = useHistory();
    const { user } = useUserContext();
    const isEldercare = window.location.pathname.includes('facilities');

    const welcomeText = isEldercare
        ? 'The platform for the modern senior care industry'
        : 'The platform for the modern care industry';

    return !user ? (
        <Grid container style={{ backgroundColor: Colors.darkNavy, padding: 50 }} alignItems="center">
            <Grid container direction="column" alignItems="center">
                <Text variant="display" textStyle={{ color: Colors.white }}>
                    Join Tandem
                </Text>
                <Text textStyle={{ marginTop: 20, color: Colors.white }} variant="h2">
                    {welcomeText}
                </Text>
            </Grid>
            <Grid container direction="row" style={{ marginTop: 20 }} justifyContent="center">
                {!isEldercare && (
                    <>
                        <Grid
                            container
                            item
                            xs={9}
                            sm={10}
                            md={6}
                            lg={5}
                            xl={4}
                            direction="row"
                            justifyContent={xsOnly ? 'center' : 'space-between'}
                            alignItems="center"
                        >
                            <SecondaryButton
                                buttonStyle={{ width: 200, marginBottom: xsOnly ? 20 : 0 }}
                                onClick={() => {
                                    if (history.location.pathname === '/') window.scrollTo({ left: 0, top: 0 });
                                    else history.push('/');
                                }}
                            >
                                For Centers
                            </SecondaryButton>
                            <SecondaryButton buttonStyle={{ width: 200 }} onClick={() => history.push('/workers')}>
                                For Workers
                            </SecondaryButton>
                        </Grid>
                    </>
                )}
                <LogoRow />
                <DownloadRow />
            </Grid>
        </Grid>
    ) : null;
}
