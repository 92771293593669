import React from 'react';
import ErrorText from '../../ErrorText';
import Input from 'reusableComponents/input';
import { FormFieldProps } from '../../FormTypes';
import FormField from '../../FormField';
import { Text } from 'library';
import { Grid } from '@material-ui/core';
import Divider from '../../Divider';

export default function JobPromoCodeInput({
    code,
    showErrors,
    error,
}: {
    code: FormFieldProps<string>;
    showErrors: boolean;
    error: boolean;
}) {
    return (
        <>
            <Divider />
            <FormField title="Promo code" style={{ width: '50%', marginTop: 30 }}>
                <Input
                    fullWidth
                    style={{ marginBottom: 0 }}
                    value={code.value}
                    onChange={(e: any) => code.setValue(e.target.value)}
                    InputLabelProps={{ shrink: true }}
                />
                {showErrors &&
                    (error ? (
                        <ErrorText>Please enter a valid promo code.</ErrorText>
                    ) : (
                        <Grid>
                            <Text variant="body2">Applied!</Text>
                            <Text variant="body2"> (discount will be applied if this job is valid for promo)</Text>
                        </Grid>
                    ))}
            </FormField>
        </>
    );
}
