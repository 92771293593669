import React, { useState } from 'react';
import Colors from 'constants/Colors';
import Paper from './Paper';
import { Text } from '../text';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { SvgIcon } from '@material-ui/core';

export default function ExpandablePaper({
    header,
    children,
    style,
    onToggle,
}: {
    header: string;
    children: React.ReactNode;
    style?: React.CSSProperties;
    onToggle?: (expanded: boolean) => void;
}) {
    const [expanded, setExpanded] = useState(false);

    const toggle = () => {
        setExpanded(!expanded);
        onToggle && onToggle(!expanded);
    };

    return (
        <Paper
            onClick={toggle}
            style={{
                backgroundColor: Colors.lightTurq,
                padding: 20,
                paddingTop: 4,
                paddingBottom: expanded ? 20 : 4,
                ...style,
            }}
        >
            <div
                style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}
            >
                <Text variant="h2" bold>
                    {header}
                </Text>
                <SvgIcon
                    onClick={toggle}
                    style={{
                        color: Colors.darkNavy,
                        width: 40,
                        height: 40,
                        padding: 6,
                    }}
                    component={expanded ? ExpandLess : ExpandMore}
                />
            </div>
            {expanded ? children : null}
        </Paper>
    );
}
