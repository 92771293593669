import { BusinessJobWithAppointments } from 'models';
import { client } from 'shared';
import { TaskListItem } from '../matchingInboxModels';
import { isPinned, isResolved } from '../inboxUtils';
import { ProviderCancellationReason } from './models';

export async function getJob(job_id: number): Promise<BusinessJobWithAppointments> {
    return await client(`api/admin-jobs/${job_id}/?sub_start_date=${new Date().toISOString()}`);
}

export async function togglePin(inboxItem: TaskListItem) {
    return await client(`api/matching-inbox/${inboxItem.id}/history/`, {
        method: 'POST',
        body: { action: isPinned(inboxItem) ? 'UNPIN' : 'PIN' },
    });
}

export async function toggledResolved(inboxItem: TaskListItem) {
    return await client(`api/matching-inbox/${inboxItem.id}/history/`, {
        method: 'POST',
        body: { action: isResolved(inboxItem) ? 'REOPEN' : 'RESOLVE' },
    });
}

export async function deleteTask(taskId: number) {
    return await client(`api/matching-inbox/${taskId}/`, {
        method: 'DELETE',
    });
}

export async function getReason(reasonId: number): Promise<ProviderCancellationReason> {
    return await client(`api/reason/${reasonId}/`);
}
