import { AdminSlot } from 'models';
import React from 'react';
import { format12HourTime, formatShortWeekdayMonthDay } from 'shared/Dates';
import { Colors, Text } from 'library';
import { SlotDiv, HorizontalContainer } from './components';
import SubJobAppointments from '../SubJobAppointments';
import SlotMenu from './SlotMenu';

export default function JobSlots({
    updateFlag,
    refreshJobs,
    slot,
    businessLocationId,
}: {
    updateFlag: (staffId: number, businessLocationId: number) => void;
    refreshJobs: () => void;
    slot: AdminSlot;
    businessLocationId?: number;
}) {
    return (
        <SlotDiv key={slot.id}>
            <HorizontalContainer color={Colors.darkNavy}>
                <Text variant="body2" bold textStyle={textMargins} color={Colors.white}>
                    {slot.id} {formatShortWeekdayMonthDay(slot.start_date)}
                </Text>
                <SlotMenu slot={slot} refreshJobs={refreshJobs} />
            </HorizontalContainer>
            <Text variant="body2" bold textStyle={textMargins} color={Colors.white}>
                {format12HourTime(slot.start_date, slot.timezone)} -{' '}
                {format12HourTime(slot.end_date, slot.timezone, true)}{' '}
            </Text>
            <SubJobAppointments
                appts={slot.appointments}
                businessLocationId={businessLocationId}
                updateFlag={updateFlag}
                refreshJobs={refreshJobs}
            />
        </SlotDiv>
    );
}

const textMargins = { marginLeft: 5, marginRight: 5 };
