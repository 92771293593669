import React from 'react';
import { Grid } from '@material-ui/core';
import { Business, UserContextUser } from 'models';
import MainContactInfo from './MainContactInfo';
import Address from './Address';

export default function Basics({
    user,
    business,
    editContactInfo,
    setEditContactInfo,
}: {
    user: UserContextUser;
    business: Business;
    editContactInfo: boolean;
    setEditContactInfo: (b: boolean) => void;
}) {
    const address = user.address.length ? { ...user.address[0] } : undefined;
    return (
        <Grid container item direction="column" justify="space-between" style={{ gap: 10 }}>
            <MainContactInfo
                user={user}
                business={business}
                editing={editContactInfo}
                updateEditing={setEditContactInfo}
            />
            {address ? <Address user={user} business={business} address={address} /> : null}
        </Grid>
    );
}
