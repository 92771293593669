import React, { Component } from 'react';
import { Grid, Container } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import theme from '../theme';
import styled from 'styled-components';
import { BrowserView, MobileView } from 'react-device-detect';
import Footer from '../parent-portal/shared/PublicFooter';
import { withStyles } from '@material-ui/styles';

const MobileContent = styled.div`
    position: relative;
    margin-bottom: 60px;
    transform: scale(0.92);
    margin-left: 10px;
    margin-right: 10px;
`;

const BrowserContent = withStyles({
    root: {
        height: '100%',
    },
})(Grid);

const FamilyImage = styled.img`
    width: 40%;
    max-height: 99vh;
    position: fixed;
    bottom: 0;
    margin-left: 90px;
    transform: scale(0.93);
`;

const FamilyContactImage = styled.img`
    width: 30%;
    max-height: 100vh;
    margin-left: 80px;
    position: fixed;
    bottom: 0;
`;

const SignUpImage = styled.img`
    width: 35%;
    max-height: 90vh;
    margin-left: 100px;
    position: fixed;
    bottom: 0;
`;

const OnetimeImage = styled.img`
    width: 20%;
    max-height: 100vh;
    position: fixed;
    bottom: 0;
`;

const FamilyMobileImage = styled.img`
    width: 98%;
    height: 500px;
`;

const OnetimeMobileImage = styled.img`
    width: 100%;
    height: 300px;
`;

const FamilyContactMobileImage = styled.img`
    width: 100%;
    height: 420px;
`;

const SignUpMobileImage = styled.img`
    width: 98%;
    height: 600px;
`;

const Image = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
`;

export default class HalfLayout extends Component {
    constructor(props) {
        super();
    }

    getHeight() {
        return '80vh';
    }

    render() {
        return (
            <div>
                <BrowserView>
                    <Grid container direction="row" alignItems="center" justify="center">
                        <Grid container sm={4} xs={12}>
                            <Image>
                                {(this.props.type === 'onetime' || this.props.type === 'ongoing') && (
                                    <OnetimeImage src={this.props.background} />
                                )}
                                {this.props.type === 'family' && <FamilyImage src={this.props.background} />}
                                {this.props.type === 'family_contact' && (
                                    <FamilyContactImage src={this.props.background} />
                                )}
                                {this.props.type === 'signup' && <SignUpImage src={this.props.background} />}
                            </Image>
                        </Grid>
                        <Grid container md={8} sm={10} xs={12} style={{ height: this.getHeight() }}>
                            <Grid container justify="center" alignItems="center" alignContent="center">
                                <Grid item lg={7} md={10} xs={11}>
                                    {this.props.children}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </BrowserView>
                <MobileView>
                    <div>
                        <MobileContent>
                            <div style={{ marginBottom: 20 }} />
                            {this.props.children}
                        </MobileContent>
                        {(this.props.type === 'onetime' || this.props.type === 'ongoing') && (
                            <OnetimeMobileImage src={this.props.background} />
                        )}
                        {this.props.type === 'family' && <FamilyMobileImage src={this.props.background} />}
                        {this.props.type === 'family_contact' && (
                            <FamilyContactMobileImage src={this.props.background} />
                        )}
                        {this.props.type === 'signup' && <SignUpMobileImage src={this.props.background} />}
                    </div>
                </MobileView>
            </div>
        );
    }
}
