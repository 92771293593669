import React from 'react';
import { Warning } from '@mui/icons-material';
import { Text, Colors } from 'library';

export default function PayWarning({ visible, text }: { visible: boolean; text: string }) {
    return visible ? (
        <Text
            variant="body2"
            color={Colors.terraCotta}
            textStyle={{ marginLeft: 27, marginBottom: 10, textIndent: -27 }}
        >
            <Warning fontSize="small" style={{ marginRight: 5 }} /> {text}
        </Text>
    ) : null;
}
