import React, { useEffect } from 'react';
import { styled } from '@mui/material';
import { ArrowForward } from '@mui/icons-material';
import { format12HourTime } from 'shared/Dates';
import { useClient } from 'shared';
import { Chip, Colors, SmallIcon, RoundedDialog, PrimaryButton, SecondaryButton, Text } from 'library';
import type { WorkerInitiatedAppointmentTimeChangeRequest } from 'models';

export function TimeChangeModal({
    open,
    setOpen,
    request,
    refresh,
}: {
    open: boolean;
    setOpen: (open: boolean) => void;
    request: WorkerInitiatedAppointmentTimeChangeRequest;
    refresh: () => void;
}) {
    const { loading, finished, error, send } = useClient({
        url: `api/worker-initiated-appointment-time-change-request/${request.id}/`,
        method: 'PATCH',
        options: { sendImmediately: false },
    });

    function click(choice: 'accepted' | 'declined') {
        send({ body: { status: choice } });
    }

    useEffect(() => {
        if (finished && !error) {
            setOpen(false);
            refresh();
        }
    }, [finished, error, setOpen, refresh]);

    return (
        <RoundedDialog open={open} onClose={() => setOpen(false)} title="Time Change Request">
            <Container>
                <TextContainer>
                    <Text>The worker has requested the following time change:</Text>
                    <Chip backgroundColor={Colors.terraCotta} textColor={Colors.white} label={request.status} />
                </TextContainer>
                <InnerContainer>
                    <div>
                        <Text>From:</Text>
                        <Text bold>
                            {`${format12HourTime(request.old_start_time)} - ${format12HourTime(request.old_end_time)}`}
                        </Text>
                    </div>
                    <SmallIcon
                        style={{ backgroundColor: Colors.lightBlue, color: Colors.darkNavy }}
                        icon={<ArrowForward />}
                    />
                    <div>
                        <Text>To:</Text>
                        <Text bold>
                            {`${format12HourTime(request.new_start_time)} - ${format12HourTime(request.new_end_time)}`}
                        </Text>
                    </div>
                </InnerContainer>
                <Text variant="body2" textStyle={{ paddingTop: 10, paddingLeft: 10 }}>
                    If you decline this request, the worker will be{' '}
                    <b>{request.on_decline === 'keep_provider' ? 'kept on' : 'removed from'} this shift.</b>
                </Text>
                {error && (
                    <Text variant="body2" color={Colors.error} textStyle={{ padding: 10 }}>
                        There was an error trying to update this time change request. Please try again or contact
                        support.
                    </Text>
                )}
                <ButtonContainer>
                    <SecondaryButton loading={loading} disabled={loading} onClick={() => click('declined')}>
                        Decline
                    </SecondaryButton>
                    <PrimaryButton loading={loading} disabled={loading} onClick={() => click('accepted')}>
                        Accept
                    </PrimaryButton>
                </ButtonContainer>
            </Container>
        </RoundedDialog>
    );
}

const Container = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    gap: 4,
    padding: 20,
});

const TextContainer = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
});

const InnerContainer = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
});

const ButtonContainer = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: 4,
    paddingTop: 20,
});
