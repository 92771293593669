import React from 'react';
import { Grid } from '@material-ui/core';
import PartialSignUpForm from './PartialSignUpForm';
import { useDeprecatedReferralCode } from 'parent-portal/shared/DeprecatedSourceTracking';

export default function BizProviderPartialSignUp() {
    useDeprecatedReferralCode(`/providers/signup`);

    return (
        <Grid container item justify="center" alignContent="center">
            <PartialSignUpForm />
        </Grid>
    );
}
