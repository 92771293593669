import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import { BasicDialog, Colors, ExpandableCard, Text } from 'library';
import CardIcon from 'assets/icons/tandempay/tp_card.svg';
import QuestionIcon from 'assets/icons/tandempay/how_it_works.svg';
import GuaranteeIcon from 'assets/icons/tandempay/tandem_guarantee.svg';
import HCGuaranteeIcon from 'assets/icons/tandempay/tandem_guarantee_hc.svg';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import PersonIcon from '@mui/icons-material/Person';
import StoreIcon from '@mui/icons-material/Store';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import HelpIcon from '@mui/icons-material/Help';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import MoneyOffIcon from '@mui/icons-material/MoneyOff';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import PhoneIcon from '@mui/icons-material/Phone';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

export default function PayFAQCards({ openExpanded }: { openExpanded: boolean }) {
    const [showTooltip, setShowTooltip] = useState<boolean>(false);
    const data = [
        {
            cardContent: (
                <Grid container item direction="column" style={{ alignItems: 'center', alignContent: 'center' }}>
                    <CardIcon />
                    <Text variant="h2" textStyle={{ fontWeight: 'bolder', paddingTop: 6 }}>
                        TandemPay for You
                    </Text>
                </Grid>
            ),
            expandedContent: (
                <Grid container item>
                    <Text variant="h2" textStyle={{ fontWeight: 'bolder', paddingBottom: 16 }} color={Colors.turquoise}>
                        Easy for you, easy for your provider.
                    </Text>
                    <Grid container item direction="row" style={{ alignItems: 'center', paddingBottom: 16 }}>
                        <Grid container item direction="row" style={styles.iconContainer}>
                            <WatchLaterIcon style={styles.icon} />
                        </Grid>
                        <Grid item style={styles.textContainer}>
                            <Text variant="body2" bold>
                                Schedule and manage your payments.
                            </Text>
                        </Grid>
                    </Grid>
                    <Grid container item direction="row" style={{ alignItems: 'center', paddingBottom: 16 }}>
                        <Grid container item direction="row" style={styles.iconContainer}>
                            <MoneyOffIcon style={styles.icon} />
                        </Grid>
                        <Grid item style={styles.textContainer}>
                            <Text variant="body2" bold>
                                No subscription or fees ever.
                            </Text>
                        </Grid>
                    </Grid>
                    <Grid container item direction="row" style={{ alignItems: 'center' }}>
                        <Grid container item direction="row" style={styles.iconContainer}>
                            <AttachMoneyIcon style={styles.icon} />
                        </Grid>
                        <Grid item style={styles.textContainer}>
                            <Text variant="body2" bold>
                                Your provider gets 100% of their earnings.
                            </Text>
                        </Grid>
                    </Grid>
                </Grid>
            ),
        },
        {
            cardContent: (
                <Grid container item direction="column" style={{ alignItems: 'center', alignContent: 'center' }}>
                    <QuestionIcon />
                    <Text variant="h2" textStyle={{ fontWeight: 'bolder', paddingTop: 6 }}>
                        How It Works
                    </Text>
                </Grid>
            ),
            expandedContent: (
                <Grid container item direction="column">
                    <Text variant="h2" textStyle={{ fontWeight: 'bolder', paddingBottom: 16 }} color={Colors.turquoise}>
                        Tandem is the only free connection platform with a built-in payment system.
                    </Text>
                    <Grid container item>
                        <Text variant="body1" textStyle={{ fontWeight: 'bolder' }}>
                            Here's how that works:
                        </Text>
                    </Grid>
                    <Grid container item style={styles.infoCard} direction="row">
                        <Grid container item style={styles.iconContainer}>
                            <PeopleAltIcon style={styles.icon} />
                        </Grid>
                        <Grid container item style={styles.textContainer} alignContent="center">
                            <Text variant="body2" bold>
                                Family pays $75
                            </Text>
                        </Grid>
                    </Grid>
                    <ArrowDownwardIcon style={styles.arrowIcon} />
                    <Grid container item style={styles.infoCard} direction="row">
                        <Grid container item direction="row" style={styles.iconContainer}>
                            <PersonIcon style={styles.icon} />
                        </Grid>
                        <Grid container item style={styles.textContainer} alignContent="center">
                            <Text variant="body2" bold>
                                Provider receives all $75
                            </Text>
                        </Grid>
                    </Grid>
                    <ArrowDownwardIcon style={styles.arrowIcon} />
                    <Grid container item style={styles.infoCard} direction="row">
                        <Grid container item direction="row" style={styles.iconContainer}>
                            <StoreIcon style={styles.icon} />
                        </Grid>
                        <Grid container item style={styles.textContainer} alignContent="center">
                            <Text variant="body2" bold>
                                Provider spends earnings
                            </Text>
                        </Grid>
                    </Grid>
                    <ArrowDownwardIcon style={styles.arrowIcon} />
                    <Grid container item style={styles.infoCard} direction="row">
                        <Grid container item direction="row" style={styles.iconContainer}>
                            <AttachMoneyIcon style={styles.icon} />
                        </Grid>
                        <Grid container item style={styles.textContainer} direction="row" alignContent="center">
                            <Grid item>
                                <Text variant="body2" bold>
                                    Tandem earns the
                                </Text>
                            </Grid>
                            <Grid item>
                                <Text
                                    variant="body2"
                                    bold
                                    textStyle={{ textDecoration: 'underline', marginLeft: 4 }}
                                    onClick={() => setShowTooltip(true)}
                                >
                                    interchange fee
                                </Text>
                            </Grid>
                            <Grid item>
                                <HelpIcon
                                    onClick={() => setShowTooltip(true)}
                                    style={{ fontSize: 11, marginLeft: 4 }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container item style={{ paddingTop: 12 }}>
                        <Text variant="deprecatedCaption" color={Colors.darkNavy} textStyle={{ lineHeight: 1 }}>
                            By acting as a bank for care providers' earnings, Tandem can exist without charging fees to
                            either side. It's a win-win!
                        </Text>
                    </Grid>
                </Grid>
            ),
        },
        {
            cardContent: (
                <Grid container item direction="column" style={{ alignItems: 'center', alignContent: 'center' }}>
                    <GuaranteeIcon />
                    <Grid
                        container
                        item
                        direction="row"
                        style={{
                            alignItems: 'flex-start',
                            alignContent: 'center',
                            justifyContent: 'center',
                            paddingTop: 6,
                        }}
                    >
                        <Grid item>
                            <Text variant="h2" textStyle={{ fontWeight: 'bolder' }}>
                                Tandem Guarantee
                            </Text>
                        </Grid>
                        <Grid item style={{ paddingLeft: 4 }}>
                            <HCGuaranteeIcon />
                        </Grid>
                    </Grid>
                </Grid>
            ),
            expandedContent: (
                <Grid container item>
                    <Text variant="h2" textStyle={{ fontWeight: 'bolder', paddingBottom: 16 }} color={Colors.turquoise}>
                        Payment through TandemPay is essential to ensure these benefits.
                    </Text>
                    <Grid container item direction="row" style={{ alignItems: 'center', paddingBottom: 16 }}>
                        <Grid container item direction="row" style={styles.iconContainer}>
                            <CheckCircleIcon style={styles.icon} />
                        </Grid>
                        <Grid item style={styles.textContainer}>
                            <Text variant="body2" bold>
                                Your provider will always be Tandem certified.
                            </Text>
                        </Grid>
                    </Grid>
                    <Grid container item direction="row" style={{ alignItems: 'center', paddingBottom: 16 }}>
                        <Grid container item direction="row" style={styles.iconContainer}>
                            <FactCheckIcon style={styles.icon} />
                        </Grid>
                        <Grid item style={styles.textContainer}>
                            <Text variant="body2" bold>
                                We background check every provider and cover the cost.
                            </Text>
                        </Grid>
                    </Grid>
                    <Grid container item direction="row" style={{ alignItems: 'center', paddingBottom: 16 }}>
                        <Grid container item direction="row" style={styles.iconContainer}>
                            <AttachMoneyIcon style={styles.icon} />
                        </Grid>
                        <Grid item style={styles.textContainer}>
                            <Text variant="body2" bold>
                                If your provider doesn't show up or you need backup care, Tandem will cover the cost.
                            </Text>
                        </Grid>
                    </Grid>
                    <Grid container item direction="row" style={{ alignItems: 'center', paddingBottom: 16 }}>
                        <Grid container item direction="row" style={styles.iconContainer}>
                            <PhoneIcon style={styles.icon} />
                        </Grid>
                        <Grid item style={styles.textContainer}>
                            <Text variant="body2" bold>
                                24/7 customer service.
                            </Text>
                        </Grid>
                    </Grid>
                    <Grid container item direction="row" style={{ alignItems: 'center', paddingBottom: 16 }}>
                        <Grid container item direction="row" style={styles.iconContainer}>
                            <CreditCardIcon style={styles.icon} />
                        </Grid>
                        <Grid item style={styles.textContainer}>
                            <Text variant="body2" bold>
                                All payments are secured through TandemPay.
                            </Text>
                        </Grid>
                    </Grid>
                </Grid>
            ),
        },
    ];

    function dialogContent() {
        return (
            <Grid>
                <Text variant="body1">
                    When a customer uses a debit/credit card at a store like Kroger or Old Navy, the store must pay the
                    customer’s card-issuing bank a small transaction fee, called the interchange fee. Since Tandem is
                    the card-issuing bank for providers, we receive the interchange fee.
                </Text>
            </Grid>
        );
    }

    return (
        <Grid container item style={{ paddingBottom: 48 }}>
            {data.map((card) => (
                <Grid item xs={10} md={4} style={{ paddingBottom: 24 }}>
                    <ExpandableCard
                        key={data.indexOf(card)}
                        cardContent={card.cardContent}
                        expandedContent={card.expandedContent}
                        openExpanded={openExpanded}
                    />
                </Grid>
            ))}
            <BasicDialog
                isOpen={showTooltip}
                onClose={() => setShowTooltip(false)}
                children={dialogContent()}
                dialogTitle="What does this mean?"
                closeButton={true}
                mobileMaxWidth={'100%'}
                webMaxWidth={'50%'}
            />
        </Grid>
    );
}

const styles = {
    arrowIcon: {
        color: Colors.darkNavy,
        fontSize: 22,
        marginTop: 12,
        marginLeft: 12,
    },
    icon: {
        color: Colors.mediumTurq,
        fontSize: 14,
    },
    iconContainer: {
        backgroundColor: Colors.lightTurq,
        height: 24,
        width: 24,
        maxWidth: '20%',
        borderRadius: 12,
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: 12,
    },
    infoCard: {
        marginTop: 12,
        backgroundColor: Colors.white,
        borderRadius: 20,
        minHeight: 40,
        padding: 12,
        boxShadow: '0px 3px 6px #00000029',
        width: '100%',
    },
    textContainer: {
        maxWidth: '75%',
        lineHeight: 'normal',
    },
};
