import React from 'react';
import { Text, Colors } from 'library';

export default function ProviderOutlawedWarning({
    business_locations_outlawed,
    businesses_outlawed,
    business_id,
    business_location_id,
}: {
    businesses_outlawed: number[];
    business_locations_outlawed: number[];
    business_id?: number;
    business_location_id?: number;
}) {
    const isOutlawed =
        (business_id && businesses_outlawed?.includes(business_id)) ||
        (business_location_id && business_locations_outlawed?.includes(business_location_id));
    return isOutlawed ? (
        <Text textStyle={{ color: Colors.error }} variant="caption" bold>
            Provider Outlawed from this Location
        </Text>
    ) : (
        <></>
    );
}
