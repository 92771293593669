import React from 'react';
import FormField from '../../FormField';
import { FormFieldProps } from '../../FormTypes';
import JobTimePicker from '../DateAndTimePage/JobTimePicker';
import { JobTimes } from 'parent-portal/forms/JobRequestTypes';

export default function OneTimeJobTimeInput({
    jobTimes,
    jobDate,
}: {
    jobDate: moment.Moment | null;
    jobTimes: FormFieldProps<JobTimes>;
}) {
    return (
        <FormField>
            <JobTimePicker jobTimes={jobTimes} startDate={jobDate} allowPastMidnight={true} title="Time*" />
        </FormField>
    );
}
