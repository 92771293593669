import React, { useState } from 'react';
import { AdminSlot } from 'models';
import { IconButton, styled } from '@mui/material';
import { Add, Close, Edit, MoreVert } from '@mui/icons-material';
import { addAppointmentToSlot, deleteSlot } from './slotsApi';
import { Colors, Menu } from 'library';
import EditSlotModal from './EditSlotModal';
import RemoveProviderModal from '../RemoveProviderModal';

export default function SlotMenu({ slot, refreshJobs }: { slot: AdminSlot; refreshJobs: () => void }) {
    const [menuOpen, setMenuOpen] = useState(false);
    const [editOpen, setEditOpen] = useState(false);
    const [removeProviderOpen, setRemoveProviderOpen] = useState(false);
    const [modalAnchorEl, setModalAnchorEl] = useState<HTMLDivElement | null>(null);
    const appt = slot.appointments.length > 0 ? slot.appointments[0] : undefined;
    const removeProviderUser = appt?.pairings && appt.pairings.length > 0 ? appt.pairings[0].hired_provider : undefined;

    const options = [
        { text: 'Edit times', icon: <Edit />, onClick: () => setEditOpen(!editOpen) },
        {
            text: 'Close slot',
            icon: <Close />,
            onClick: () => deleteSlot(slot.id).then(refreshJobs),
            disabled: !!removeProviderUser,
        },
        {
            text: 'Close slot and cancel worker',
            icon: <Close />,
            onClick: () => setRemoveProviderOpen(true),
            disabled: !removeProviderUser,
        },
        { text: 'Add appointment', icon: <Add />, onClick: () => addAppointmentToSlot(slot.id).then(refreshJobs) },
    ];

    return (
        <>
            <Menu
                options={options}
                anchor={
                    <div onClick={(event) => setModalAnchorEl(event.currentTarget)}>
                        <StyledIconButton onClick={() => setMenuOpen(!menuOpen)}>
                            <MoreVert fontSize="small" />
                        </StyledIconButton>
                    </div>
                }
            />
            {appt ? (
                <RemoveProviderModal
                    providerUser={removeProviderUser}
                    deleteSlot
                    onClose={() => setRemoveProviderOpen(false)}
                    open={removeProviderOpen}
                    appointment={appt}
                    refreshJobs={refreshJobs}
                />
            ) : null}
            <EditSlotModal
                slot={slot}
                open={editOpen}
                refreshJobs={refreshJobs}
                onClose={() => setEditOpen(false)}
                anchorEl={modalAnchorEl}
            />
        </>
    );
}

const StyledIconButton = styled(IconButton)({
    padding: 5,
    color: Colors.white,
});
