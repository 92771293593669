import React from 'react';
import { Text, Colors } from 'library';
import { AdminProvider } from 'models';
import { Grid } from '@material-ui/core';
import { format } from 'date-fns';

export function WorkerPastExperience({ provider }: { provider: AdminProvider }) {
    const lce_provider = provider.licensed_center_employment_history;
    return (
        <Grid container direction="row" item style={{ gap: 10, alignItems: 'center', justifyContent: 'space-between' }}>
            <Grid xs={12}>
                <Text variant="h2" bold textStyle={{ margin: 10, color: Colors.turquoise }}>
                    Past Experiences
                </Text>
            </Grid>

            {lce_provider ? (
                <Grid xs={6}>
                    <Text variant="body1" bold textStyle={{ margin: 10 }}>
                        Licensed Center Employments Listed
                    </Text>
                    <div style={{ margin: 10 }}>
                        <Text bold variant="caption">
                            {lce_provider?.center_name} - {lce_provider?.center_location}
                        </Text>
                        <Text variant="caption" bold>
                            End date: {format(new Date(lce_provider?.end_date), 'MM-dd-yyyy')}
                        </Text>
                        <Text variant="caption" bold>
                            Said they got a level 2 check:{' '}
                            {lce_provider?.listed_completed_background_check ? 'Yes' : 'No'}
                        </Text>
                        <Text variant="caption" bold>
                            Said this was a K-12 facility: {lce_provider?.listed_was_k_12_program ? 'Yes' : 'No'}
                        </Text>
                        <Text variant="caption" bold>
                            Listed has not worked in a center:{' '}
                            {lce_provider?.listed_has_not_worked_in_center ? 'Yes' : 'No'}
                        </Text>
                        {lce_provider?.ignored_at ? (
                            <Text variant="caption" bold>
                                Ignored at: {''}
                                {format(new Date(lce_provider?.ignored_at), 'MM-dd-yyyy')}
                            </Text>
                        ) : null}
                        <Text variant="caption">
                            Note: If they added this data prior to 6/26/23, they may have No listed for the above
                            questions even though they did not select No.
                        </Text>
                    </div>
                </Grid>
            ) : null}
            <Grid xs={12}>
                <Text variant="body1" bold textStyle={{ margin: 10 }}>
                    Past Experiences from App & OCCRRA
                </Text>
                {provider.past_experience.map((exp) => (
                    <div key={exp.id} style={{ margin: 10 }}>
                        <Text bold variant="caption">
                            {exp.title} - {exp.employer}
                        </Text>
                        <Text variant="caption">
                            {exp.start_date} - {exp.end_date}
                        </Text>
                    </div>
                ))}
            </Grid>
        </Grid>
    );
}
