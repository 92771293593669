import React, { useContext, useState } from 'react';
import { client } from 'shared';
import { Grid, styled } from '@mui/material';
import { Checkbox, Colors, SecondaryButton, PrimaryButton, Text } from 'library';
import { BusinessLocationPreferredWorker } from 'models';
import { PreferredWorkerModalContext } from '.';

import Row from './Row';

interface ILocationCheckbox {
    [key: string]: boolean;
}

interface IPreferredWorkerRequest {
    business_id: number;
    preferred_worker_id: number;
    preferred_worker_locations: ILocationParam[];
}

interface ILocationParam {
    business_location_id: string;
    preferred_worker_id: number;
    is_preferred_worker: boolean;
}

export default function LocationsList() {
    const { business, businessLocationId, provider, locations, setLocations, setError, onSuccess, onClose } =
        useContext(PreferredWorkerModalContext);

    function getCheckboxes(checked: boolean = true, useChecked: boolean = true) {
        const checkboxes: ILocationCheckbox = {};
        locations.map(
            (location: BusinessLocationPreferredWorker) =>
                (checkboxes[`${location.id}`] = useChecked ? checked : location.is_preferred_worker),
        );
        return checkboxes;
    }

    function onClick() {
        setSubmitting(true);

        const requestParams: IPreferredWorkerRequest = {
            business_id: business.id,
            preferred_worker_id: provider.id,
            preferred_worker_locations: [],
        };

        requestParams.preferred_worker_locations = Object.entries(locationCheckboxes).map(
            (locationCheckbox: [string, boolean]) => ({
                business_location_id: locationCheckbox[0],
                preferred_worker_id: provider.id,
                is_preferred_worker: locationCheckbox[1],
            }),
        );

        client('api/preferred-worker/update-locations/', {
            method: 'POST',
            body: requestParams,
        })
            .then((response: BusinessLocationPreferredWorker[]) => {
                setLocations(response);
                onClose();
                onSuccess(response);
            })
            .catch(() => setError(true))
            .finally(() => setSubmitting(false));
    }

    const [locationCheckboxes, setLocationCheckboxes] = useState(getCheckboxes(false, false));
    const [submitting, setSubmitting] = useState(false);

    function setCheckbox(checked: boolean, id: string) {
        setLocationCheckboxes({ ...locationCheckboxes, [`${id}`]: checked });
    }

    return (
        <>
            <InnerDialog container direction="column">
                <BusinessRow item>
                    <Text bold>{business.name}</Text>
                    <Checkbox
                        label="Add to all locations"
                        checked={Object.values(locationCheckboxes).every((value) => value)}
                        onChange={(checked: boolean) => setLocationCheckboxes(getCheckboxes(checked, true))}
                        style={{ width: 'unset' }}
                    />
                </BusinessRow>
                <Text bold>Locations:</Text>
                <LocationsGrid>
                    {locations.map((location: BusinessLocationPreferredWorker) => (
                        <Row
                            key={location.id}
                            highlight={!!(businessLocationId === location.id)}
                            createdBy={location.preferred_worker_created_by}
                            location={location}
                            checked={locationCheckboxes[`${location.id}`]}
                            disabled={submitting}
                            setCheckbox={setCheckbox}
                        />
                    ))}
                </LocationsGrid>
            </InnerDialog>
            <ButtonRow container direction="row">
                <SecondaryButton onClick={onClose} buttonStyle={{ width: 'unset', marginRight: 10 }}>
                    Cancel
                </SecondaryButton>
                <PrimaryButton onClick={onClick} buttonStyle={{ width: 'unset' }} loading={submitting}>
                    Set
                </PrimaryButton>
            </ButtonRow>
        </>
    );
}

const InnerDialog = styled(Grid)({
    background: Colors.lightTurq,
    marginBottom: '1em',
    borderRadius: '10px',
    padding: '1em',
});

const BusinessRow = styled(Grid)({
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: '1em',
});

const LocationsGrid = styled(Grid)({
    marginLeft: '1em',
});

const ButtonRow = styled(Grid)({
    display: 'flex',
    justifyContent: 'flex-end',
});
