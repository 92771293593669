import React from 'react';
import { Text } from 'library';

export default function RequirementsLabel({ children }: { children: React.ReactNode }) {
    return (
        <Text variant="body2" bold textStyle={{ margin: '10px 0' }}>
            {children}
        </Text>
    );
}
