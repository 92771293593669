import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { InputLabel, Input, InputAdornment, FormControl, FormHelperText } from '@material-ui/core';
import theme from '../theme';

const StyledInput = withStyles((props) => ({
    root: {
        borderBottomColor: theme.palette.primary.main,
        '&:not(.Mui-disabled):hover::before': {
            borderColor: theme.palette.primary.main,
        },
    },
    focused: {
        borderBottomColor: theme.palette.primary.main,
    },
}))(Input);

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    margin: {
        marginRight: theme.spacing(1),
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    textField: {
        width: '25ch',
    },
}));

const ErrorText = withStyles({
    root: {
        color: theme.palette.error.main,
    },
})(FormHelperText);

export default function InputWithAdornment(props) {
    const classes = useStyles();
    return (
        <FormControl fullWidth className={classes.margin}>
            <InputLabel>{props.label}</InputLabel>
            <StyledInput
                value={props.value}
                onChange={props.onChange}
                startAdornment={<InputAdornment position="start">{props.adornment}</InputAdornment>}
                labelWidth={28}
                error={props.error}
                disabled={props.disabled}
                disableUnderline={props.transparent}
                {...props}
            />
            <ErrorText id="component-helper-text">{props.helperText}</ErrorText>
        </FormControl>
    );
}
