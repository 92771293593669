import { PrimaryButton, Text } from 'library';
import Dropdown from 'library/components/formInputs/Dropdown';
import React, { useEffect, useState } from 'react';
import Input from 'reusableComponents/input';
import { client } from 'shared';
import TemplateFields from './TemplateFields';
import { EmailTemplate } from './models';

export default function SendEmail() {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);

    const [emailTemplates, setEmailTemplates] = useState<EmailTemplate[]>([]);

    const [csvFile, setCsvFile] = useState<File | undefined>();
    const [selectedTemplate, setSelectedTemplate] = useState('');
    const [username, setUsername] = useState<string>();
    const [password, setPassword] = useState<string>();

    useEffect(() => {
        client('api/email-template/')
            .then(setEmailTemplates)
            .catch(() => setError(true))
            .finally(() => setLoading(false));
    }, []);

    async function sendEmails() {
        setShowSuccess(false);
        setLoading(true);

        try {
            await client(
                'api/email/',
                {
                    body: formBody(),
                },
                false,
            );
            setShowSuccess(true);
            reset();
        } catch (error) {
            setError(true);
        }

        setLoading(false);
    }

    function formBody() {
        const formData = new FormData();
        formData.append('email_info_csv', csvFile!, csvFile?.name);
        formData.append('template_id', selectedTemplate);
        username && formData.append('username', username);
        password && formData.append('password', password);

        return formData;
    }

    function reset() {
        setUsername(undefined);
        setPassword(undefined);
        setSelectedTemplate('');
        setCsvFile(undefined);
        setError(false);
    }

    const valid = !!csvFile;

    const templateOptions = emailTemplates.map((template) => ({
        key: template.id,
        value: template.description,
    }));

    const templateToDisplay = emailTemplates.find((template) => template.id === selectedTemplate);

    return (
        <div style={{ margin: '50px 10vw 20px 10vw', display: 'flex', flexDirection: 'column' }}>
            <label style={{ margin: '20px 0' }}>Template:</label>
            <Dropdown
                selected={selectedTemplate}
                onChange={(event) => setSelectedTemplate(event.target.value)}
                fields={templateOptions}
            />
            <Text variant="body2">Add templates in Django admin</Text>
            <TemplateFields template={templateToDisplay} />
            <label style={{ margin: '20px 0' }}>Email CSV:</label>
            <input type="file" onChange={(event) => setCsvFile(event.target?.files?.[0])} />
            <Text variant="body2" textStyle={{ margin: '20px 0' }}>
                Your csv must contain email and business_name fields. All other headers map to the fields in the
                template you select. first_name and last_name are optional for adding to contact info.
            </Text>
            <label style={{ margin: '20px 0' }}>Sender account username:</label>
            <Input value={username} onChange={(event) => setUsername(event.target.value)} />
            <label style={{ margin: '20px 0' }}>Sender account password:</label>
            <Input type="password" value={password} onChange={(event) => setPassword(event.target.value)} />
            <Text variant="body2" textStyle={{ margin: '20px 0' }}>
                Leave these fields blank to use oliviaweinstock@join-tandem.com
            </Text>
            {error ? <Text>An error occurred sending the emails</Text> : null}
            {!valid && !showSuccess ? <Text>Please upload a csv</Text> : null}
            {showSuccess ? <Text>Hooray! The emails sent successfully</Text> : null}
            <div style={{ width: 300, marginTop: 50 }}>
                <PrimaryButton onClick={sendEmails} disabled={error || loading || !valid}>
                    Send Emails
                </PrimaryButton>
            </div>
        </div>
    );
}
