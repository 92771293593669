import React, { useEffect, useState } from 'react';
import { LoadingSpinner, Text, Colors, TruncateText, IconButton, Checkbox } from 'library';
import { Grid, styled } from '@mui/material';
import { consoleLogInDev } from 'shared';
import { formatMonthDayYearWith12HourTime } from 'shared/Dates';
import { TablePagination } from '@material-ui/core';
import { Delete, Edit } from '@mui/icons-material';
import { listCalls, deleteCallHistory } from 'api/CallHistoryApi';
import { CallHistory } from 'models/CallHistory';
import ConfirmDialog from 'reusableComponents/confirmDialog';
import CallHistoryForm from './CallHistoryForm';

import type { ActualLeanUser, PagingResult } from 'models';

export default function LocationCallHistory({
    businessLocationId,
    status,
    error,
    onCallAdded,
}: {
    businessLocationId: number;
    status: string;
    error: string | undefined;
    onCallAdded: () => void;
}) {
    const [page, setPage] = useState(1);
    const [callHistory, setCallHistory] = useState<PagingResult<CallHistory>>();
    const [deleteId, setDeleteId] = useState<number>();
    const [callToEdit, setCallToEdit] = useState<CallHistory>();
    const [includeRelated, setIncludeRelated] = useState(false);

    useEffect(() => {
        getData(page);
    }, [businessLocationId, includeRelated]);

    function onPageChange(event: React.MouseEvent<HTMLButtonElement> | null, pageNumber: number) {
        const newPage = pageNumber + 1;
        setPage(newPage);
        getData(newPage);
    }

    function getData(pageNumber: number) {
        listCalls(businessLocationId, pageNumber, includeRelated)
            .then((response) => {
                setCallHistory(response);
            })
            .catch(consoleLogInDev);
    }

    function onEditClick(callId: number) {
        const call = callHistory?.results.find((x) => x.id === callId);
        setCallToEdit(call);
    }

    function onDeleteClick(callId: number) {
        setDeleteId(callId);
    }

    function onCancelDelete() {
        setDeleteId(undefined);
    }

    function onCancelEdit() {
        setCallToEdit(undefined);
    }

    function onCallSaved() {
        getData(1);
        onCallAdded();
        setCallToEdit(undefined);
    }

    function onConfirmDelete() {
        if (!deleteId) {
            return;
        }

        deleteCallHistory(deleteId)
            .then(() => {
                setDeleteId(undefined);
                getData(1);
                onCallAdded();
            })
            .catch((error) => {
                consoleLogInDev(error);
            });
    }

    function getName(user?: ActualLeanUser) {
        if (!user) {
            return 'Unknown';
        }

        return `${user?.first_name} ${user?.last_name}`;
    }

    if (status === 'loading') return <LoadingSpinner />;
    if (status === 'error') return <Text>{error}</Text>;
    if (!businessLocationId) return <Text>Location not found.</Text>;

    return (
        <Container container>
            <ItemContainer item container gap={1} direction="column" md={6}>
                <CallHistoryForm
                    businessLocationId={businessLocationId}
                    callToEdit={callToEdit}
                    onSave={onCallSaved}
                    onCancelEdit={onCancelEdit}
                />
            </ItemContainer>
            <ItemContainer item container gap={1} direction="column" md={6}>
                <Checkbox
                    label="Included Related"
                    checked={includeRelated}
                    onChange={(val) => setIncludeRelated(val)}
                />
                <StyledList>
                    {callHistory?.results.map((x) => (
                        <StyledListItem key={x.id}>
                            <Grid container justifyContent="space-between">
                                <Text bold>
                                    Call{' '}
                                    {x.is_inbound
                                        ? `From ${getName(x.call_from_user)} to ${getName(x.call_to_user)}`
                                        : `To ${getName(x.call_to_user)} by ${getName(x.call_from_user)}`}
                                    {x.business_location_name ? ` (${x.business_location_name})` : ''}
                                </Text>
                                <Text textStyle={{ color: Colors.darkGrey }}>
                                    {formatMonthDayYearWith12HourTime(x.call_time)}
                                </Text>
                            </Grid>
                            <Grid container justifyContent="space-between">
                                <Text>
                                    <Text textStyle={{ color: Colors.turquoise, display: 'inline' }} bold>
                                        {x.is_inbound ? 'Inbound' : 'Outbound'}
                                    </Text>
                                    {x.call_status ? ` call, resulting in ${x.call_status.name}` : ''}
                                </Text>
                                {x.duration ? (
                                    <Text textStyle={{ color: Colors.darkGrey }}>{x.duration} minutes</Text>
                                ) : (
                                    <></>
                                )}
                            </Grid>
                            <TruncateText text={x.note || ''} style={{ marginTop: 5, marginBottom: 5 }} />
                            <Grid container gap={1}>
                                <IconButton icon={Edit} onClick={() => onEditClick(x.id)} />
                                <IconButton icon={Delete} onClick={() => onDeleteClick(x.id)} />
                            </Grid>
                        </StyledListItem>
                    ))}
                </StyledList>
                <TablePagination
                    component="div"
                    count={callHistory?.count || 0}
                    rowsPerPage={callHistory?.per_page || 0}
                    page={page - 1}
                    onChangePage={onPageChange}
                    rowsPerPageOptions={[]}
                />
            </ItemContainer>
            <ConfirmDialog
                title="Confirm Delete"
                message="Are you sure you want to delete this call?"
                open={Boolean(deleteId)}
                onClose={onCancelDelete}
                onConfirm={onConfirmDelete}
            />
        </Container>
    );
}

const Container = styled(Grid)({
    padding: 20,
});
const ItemContainer = styled(Grid)({
    padding: 5,
});

const StyledList = styled('ul')({
    listStyle: 'none',
    '& li': { borderBottom: `1px solid ${Colors.darkGrey};` },
    '& li:last-child': { borderBottom: 'none' },
});

const StyledListItem = styled('li')({
    padding: 5,
    marginBottom: 10,
});
