import React, { useEffect, useState } from 'react';
import { CircularProgress, useMediaQuery } from '@material-ui/core';
import { SizeableRoundedDialog } from 'library';
import theme from 'theme';
import { Job, Provider } from 'models';
import FoundYourMatch from './pages/FoundYourMatch';
import { useUserContext } from 'UserContext';
import ConfirmPayRate from './pages/ConfirmPayRate';
import SetSchedule from './pages/SetSchedule';
import { consoleLogInDev } from 'shared';
import moment from 'moment';
import SetDetails from './pages/SetDetails';
import HireSuccess from './pages/HireSuccess';
import PostHireReferral from './pages/PostHireReferral';
import ConnectPaymentModal from 'parent-portal/payments/Components/ConnectPaymentModal';
import { useHistory } from 'react-router-dom';
import { hireProviderForJob } from 'shared/JobApi';

export interface IDateRange {
    validDateRange: boolean;
    startDate: Date;
    endDate: moment.Moment;
}

interface IProps {
    provider: Provider;
    isOpen: boolean;
    onClose: () => void;
    onOpen: () => void;
    job: Job;
    daysAppliedTo: string[];
    previouslyFilledDays: string[];
}

enum ModalPage {
    foundYourMatch = 'found your match',
    setSchedule = 'set schedule',
    confirmPayRate = 'confirm pay rate',
    setDetails = 'set details',
    isHired = 'provider is hired',
    refer = 'refer',
}

export default function HireProviderModal({
    provider,
    isOpen,
    onClose,
    onOpen,
    job,
    daysAppliedTo,
    previouslyFilledDays,
}: IProps) {
    const { user, refreshAlreadyLoggedInUser } = useUserContext();
    const history = useHistory();
    const isPaymentSetup = !!user?.customer_id || (!!user?.payment_information && user?.payment_information.length > 0);
    const initialPayValue = job.pay ? job.pay : job.rate_max ? job.rate_max : 17;
    const [previousPages, setPreviousPages] = useState<Array<ModalPage>>([]);
    const [currentPage, setCurrentPage] = useState<ModalPage>(ModalPage.foundYourMatch);
    const [payValue, setPayValue] = useState(initialPayValue);
    const [hireDateRange, setHireDateRange] = useState({
        validDateRange: true,
        startDate: new Date(),
        endDate: moment(new Date()).add(28, 'days'),
    });
    const [unfilledDays, setUnfilledDays] = useState<string[]>([]);
    const [openPaymentSetup, setOpenPaymentSetup] = useState(false);
    const [loading, setLoading] = useState(false);
    const [flexible, setFlexible] = useState(false);

    useEffect(() => {
        populateValues();
    }, [daysAppliedTo, previouslyFilledDays]);

    async function populateValues() {
        const filledDaysAfterHire = [...daysAppliedTo, ...previouslyFilledDays];
        const unfilledDays = job?.days_of_week?.filter((day) => !filledDaysAfterHire.includes(day));
        setUnfilledDays(unfilledDays);
    }

    function onBack() {
        if (previousPages[previousPages.length - 1]) {
            setCurrentPage(previousPages[previousPages.length - 1]);
            setPreviousPages(previousPages.slice(0, -1));
        }
    }

    function goToPage(page: ModalPage) {
        const modalOrder = [
            ModalPage.foundYourMatch,
            ModalPage.setSchedule,
            ModalPage.confirmPayRate,
            ModalPage.setDetails,
            ModalPage.isHired,
            ModalPage.refer,
        ];

        if (modalOrder.indexOf(page) > modalOrder.indexOf(currentPage)) {
            setPreviousPages([...previousPages, currentPage]);
        }
        setCurrentPage(page);
    }

    function continueFromFoundYourMatch() {
        if (job.flexible || (job.timeless && !(job.all_day && job.pay_is_fixed)) || job.dateless) {
            goToPage(ModalPage.setSchedule);
        } else if (!!user?.customer_id || (!!user?.payment_information && user?.payment_information.length > 0)) {
            goToPage(ModalPage.confirmPayRate);
        } else {
            onClose();
            setOpenPaymentSetup(true);
        }
    }

    function continueFromSetSchedule() {
        if (!!user?.customer_id || (!!user?.payment_information && user?.payment_information.length > 0)) {
            goToPage(ModalPage.confirmPayRate);
        } else {
            onClose();
            setOpenPaymentSetup(true);
        }
    }
    async function hire(dateRange: IDateRange) {
        setLoading(true);
        goToPage(ModalPage.isHired);

        let finalStartDate = moment(dateRange.startDate, 'ddd MMM DD YYYY HH:mm:ss').format('YYYY-MM-DD');
        let finalEndDate = moment(dateRange.endDate, 'ddd MMM DD YYYY HH:mm:ss').format('YYYY-MM-DD');

        let body = {
            sitter_id: provider.id,
            start: finalStartDate,
            end: finalEndDate,
            pay_rate: payValue,
        };

        await hireProviderForJob(body, job.id)
            .catch(consoleLogInDev)
            .finally(() => setLoading(false));
    }

    const allPages = {
        [ModalPage.foundYourMatch]: () => (
            <FoundYourMatch
                provider={provider}
                isOneTimeJob={!job.ongoing}
                isPaymentSetup={isPaymentSetup}
                onContinue={continueFromFoundYourMatch}
            />
        ),
        [ModalPage.setSchedule]: () => (
            <SetSchedule
                job={job}
                provider={provider}
                initialPayValue={initialPayValue}
                onContinue={continueFromSetSchedule}
                update={(pay: number) => {
                    setPayValue(pay);
                }}
                setFlexible={setFlexible}
            />
        ),
        [ModalPage.confirmPayRate]: () => (
            <ConfirmPayRate
                payValue={payValue}
                job={job}
                provider={provider}
                onContinue={() => {
                    // TODO: go to page collecting job times
                    if (job.ongoing) {
                        goToPage(ModalPage.setDetails);
                    } else {
                        hire(hireDateRange);
                    }
                }}
                onBack={onBack}
                updatePayValue={(pay: number) => {
                    setPayValue(pay);
                }}
            />
        ),
        [ModalPage.setDetails]: () => (
            <SetDetails
                onBack={onBack}
                onContinue={() => hire(hireDateRange)}
                flexible={flexible}
                update={(dateRange: IDateRange) => {
                    setHireDateRange(dateRange);
                }}
                job={job}
                provider={provider}
                daysAppliedTo={daysAppliedTo}
                unfilledDays={unfilledDays}
            />
        ),

        [ModalPage.isHired]: () => (
            <>
                {loading ? (
                    <CircularProgress style={{ marginLeft: '35%', marginTop: '20%' }} />
                ) : (
                    <HireSuccess provider={provider} onContinue={() => goToPage(ModalPage.refer)} />
                )}
            </>
        ),
        [ModalPage.refer]: () => <PostHireReferral />,
    };

    const small = useMediaQuery(theme.breakpoints.down('sm'));

    const close = () => {
        onClose();
        if (currentPage === ModalPage.isHired || currentPage === ModalPage.refer) {
            history.push('/jobs');
        }
    };

    return (
        <>
            <SizeableRoundedDialog
                closeButton
                open={isOpen}
                onClose={close}
                style={{ height: small ? 560 : 715, width: small ? '100%' : '80%', maxWidth: 700, paddingBottom: 10 }}
            >
                {allPages[currentPage]()}
            </SizeableRoundedDialog>
            <ConnectPaymentModal
                isOpen={openPaymentSetup}
                onClose={() => {
                    setOpenPaymentSetup(false);
                }}
                fromRoute={'HireProvider'}
                onSuccess={() => {
                    refreshAlreadyLoggedInUser();
                    onOpen();
                }}
            />
        </>
    );
}
