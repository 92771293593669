import React from 'react';
import { Grid } from '@mui/material';
import { DownloadTheAppButtons } from 'library';
import SocialsRow from 'parent-portal/public/SocialsRow';

export default function DownloadRow() {
    return (
        <Grid container direction="row" justifyContent="space-between" style={{ marginTop: 30 }}>
            <DownloadTheAppButtons />
            <SocialsRow />
        </Grid>
    );
}
