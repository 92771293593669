import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { toBookingDate, BusinessJobWithAppointments } from 'models';
import { Text, Colors } from 'library';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import { getProviderBookings } from './matchingListApi';
import moment from 'moment';

export default function ProviderBookings({
    userId,
    week,
    job,
    initialBookingDates,
    disableBookingFetch,
}: {
    userId: number;
    week: Date | undefined;
    job: BusinessJobWithAppointments;
    initialBookingDates?: string[];
    disableBookingFetch?: boolean;
}) {
    const [bookingDates, setBookingDates] = useState<string[]>([]);
    const avalableAptStatuses = ['AWAITING_SELECTION', 'NO_PAIRING', 'PENDING_PAIRING'];
    const jobAppointments = job.slots
        .filter(
            (slot, idx, results) =>
                results.findIndex((s) => s.start_date.split('T')[0] === slot.start_date.split('T')[0]) === idx &&
                (slot.appointments.length == 0 ||
                    slot.appointments.some((appt) => avalableAptStatuses.includes(appt.status))),
        )
        .map((slot) => slot.start_date.split('T')[0]);

    const formatApptDate = (date: string) => {
        return moment(date).format('ddd, MMM D');
    };
    useEffect(() => {
        setBookingDates(initialBookingDates || []);
    }, [initialBookingDates]);

    useEffect(() => {
        if (disableBookingFetch) {
            return;
        }

        const currWeek = week ? moment(week) : moment();
        const endDate = moment(currWeek).add(7, 'd').format('yyyy-MM-D');
        const startDate = currWeek.format('yyyy-MM-D');

        getProviderBookings(userId, startDate, endDate).then((bookings) => {
            const dates = bookings.results.map(toBookingDate).filter((date: string, idx: number, results: string[]) => {
                return results.indexOf(date) === idx;
            });
            setBookingDates(dates);
        });
    }, [userId, week, disableBookingFetch]);

    return (
        <Grid direction="column" style={{ flex: 1, marginLeft: '15px' }}>
            <Grid item>
                <Text bold variant="body2">
                    Bookings
                </Text>
            </Grid>
            {jobAppointments.length > 0
                ? jobAppointments.map((aptDate, idx) => (
                      <div key={idx}>
                          {bookingDates.includes(aptDate) ? (
                              <Grid style={{ display: 'flex', alignItems: 'center' }}>
                                  <EventBusyIcon sx={{ color: Colors.red }} />
                                  <Text variant="body2">{formatApptDate(aptDate)}</Text>
                              </Grid>
                          ) : (
                              <Grid style={{ display: 'flex', alignItems: 'center' }}>
                                  <EventAvailableIcon sx={{ color: 'green' }} />
                                  <Text variant="body2">{formatApptDate(aptDate)}</Text>
                              </Grid>
                          )}
                      </div>
                  ))
                : null}
        </Grid>
    );
}
