import { client, queryStringifyObject } from 'shared';

export function getDrafts() {
    return client(`api/business-ongoing/?${queryStringifyObject({ status: 'DRAFT' })}`);
}

export function deleteDraft(draftId: number) {
    return client(`api/business-ongoing/${draftId}/`, {
        method: 'DELETE',
    });
}
