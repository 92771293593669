import React from 'react';
import { Text, Colors } from 'library';

export default function DontWorry() {
    return (
        <Text variant="h2" bold textStyle={{ display: 'inline' }}>
            <Text variant="h2" bold color={Colors.turquoise} textStyle={{ display: 'inline' }}>
                Don't worry!
            </Text>{' '}
            Easy for you, easy for your provider.
        </Text>
    );
}
