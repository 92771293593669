import React, { useEffect, useState } from 'react';
import { Colors, LoadingSpinner, OutlinedTextInput, PrimaryButton, Text } from 'library';
import {
    Grid,
    styled,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TableRow,
} from '@mui/material';
import { useBusinessLocationJobs } from '../api';
import { formatMonthDayYearWith12HourTime, formatShortMonthDayYear } from 'shared/Dates';
import { createDiscount, list } from 'api/DiscountApi';
import { IAdminDiscount } from 'models/Discount';
import { consoleLogInDev, IsDecimal } from 'shared';
import { PageStates } from 'constants/PageStates';
import { search } from 'api/InvoiceApi';
import { PagingResult } from 'models';
import { IInvoice } from 'internal/payments/Models';

const DISCOUNT_NOTE_LIMIT = 512;

export default function BusinessLocationPayments({ businessLocationId }: { businessLocationId: number }) {
    const [unusedDiscounts, setUnusedDiscounts] = useState<IAdminDiscount[]>([]);
    const [newDiscountAmount, setNewDiscountAmount] = useState('');
    const [notes, setNotes] = useState('');
    const [invoices, setInvoices] = useState<PagingResult<IInvoice>>();
    const [errorMessage, setErrorMessage] = useState('');
    const [pageState, setPageState] = useState(PageStates.neutral);
    const { status, error, data } = useBusinessLocationJobs(businessLocationId);

    function getDiscounts() {
        setPageState(PageStates.loading);
        if (data?.business_location?.address) {
            list(data?.business_location?.address.id, 'open')
                .then((response) => {
                    setUnusedDiscounts(response.results);
                })
                .catch(consoleLogInDev)
                .finally(() => {
                    setPageState(PageStates.neutral);
                });
        }
    }

    function getOverdueInvoices() {
        search(1, null, null, null, true, businessLocationId)
            .then((response) => setInvoices(response))
            .catch(consoleLogInDev);
    }

    useEffect(() => {
        getDiscounts();
        getOverdueInvoices();
    }, [data?.business_location?.address]);

    function onSubmitClick() {
        setErrorMessage('');
        if (!data?.business_location?.address) {
            return;
        }

        setPageState(PageStates.submitting);
        createDiscount(
            data.business_location.address.id,
            parseFloat(newDiscountAmount),
            data.business_location.business.user.id,
            notes,
        )
            .then(() => {
                getDiscounts();
                setNewDiscountAmount('');
                setNotes('');
            })
            .catch((error) => {
                setErrorMessage(error.message || 'An error occurred');
                consoleLogInDev(error);
                setPageState(PageStates.neutral);
            });
    }

    if (status === 'loading') return <LoadingSpinner />;
    if (status === 'error') return <Text>{error}</Text>;

    return (
        <Container container item gap={1}>
            <Text bold>Payments</Text>
            <Grid container>
                <ColumnContainer container item sm={6} direction="column">
                    <Text bold>
                        Overdue Payments -{' '}
                        <a
                            href={`/adminperson/payments/invoices?page=1&overdue=true&location=${businessLocationId}`}
                            target="_blank"
                            rel="noreferrer"
                        >
                            Expanded results
                        </a>
                    </Text>
                    <TableContainer>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="right">Id</TableCell>
                                    <TableCell align="right">Amount</TableCell>
                                    <TableCell align="right">Bill Date</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {invoices?.results.map((row) => (
                                    <TableRow key={row.id}>
                                        <TableCell align="right">
                                            <a
                                                href={`/adminperson/payments/invoices/${row.id}`}
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                {row.id}
                                            </a>
                                        </TableCell>
                                        <TableCell align="right">${row.amount?.toFixed(2)}</TableCell>
                                        <TableCell align="right">
                                            {row.bill_date ? formatShortMonthDayYear(row.bill_date) : ''}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                    <TableCell align="right" colSpan={3}>
                                        {invoices?.results.length} of {invoices?.count}
                                    </TableCell>
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                </ColumnContainer>
                <ColumnContainer item sm={6}>
                    <Grid container item sm={6} direction="column">
                        <Text bold>Add Discount:</Text>
                        <OutlinedTextInput
                            label="Amount"
                            value={newDiscountAmount}
                            onChange={(e) => setNewDiscountAmount(e.target.value)}
                            style={{ maxWidth: 120, margin: '5px 0' }}
                        />
                        <OutlinedTextInput
                            label="Notes (Visible to Users)"
                            value={notes}
                            onChange={(e) => setNotes(e.target.value)}
                            style={{ margin: '5px 0' }}
                            error={notes.length > DISCOUNT_NOTE_LIMIT}
                        />
                        <PrimaryButton
                            loading={pageState === PageStates.submitting}
                            disabled={
                                !newDiscountAmount ||
                                !IsDecimal(newDiscountAmount) ||
                                notes.length > DISCOUNT_NOTE_LIMIT ||
                                pageState === PageStates.submitting
                            }
                            onClick={onSubmitClick}
                            buttonStyle={{ maxWidth: 200 }}
                        >
                            Submit
                        </PrimaryButton>
                        <Text textStyle={{ color: Colors.error }}>{errorMessage}</Text>
                    </Grid>
                    <hr />
                    <Text bold>Unused Discounts:</Text>
                    {pageState === PageStates.loading ? <LoadingSpinner /> : null}
                    <ul>
                        {unusedDiscounts.map((x) => (
                            <ListItem key={x.id}>
                                <Grid container direction="column">
                                    <Text bold>Amount: ${x.value.toFixed(2)}</Text>
                                    <Text>Created: {formatMonthDayYearWith12HourTime(x.added_at)}</Text>
                                    <Text>
                                        Created By: {x?.created_by?.first_name} {x?.created_by?.last_name}
                                    </Text>
                                    <Text>Notes: {x.notes}</Text>
                                </Grid>
                            </ListItem>
                        ))}
                    </ul>
                </ColumnContainer>
            </Grid>
        </Container>
    );
}

const Container = styled(Grid)({
    padding: 20,
});

const ColumnContainer = styled(Grid)({
    padding: 5,
});

const ListItem = styled('li')({
    padding: '5px 0',
});
