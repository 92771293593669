import React from 'react';
import { useUserContext } from 'UserContext';
import BusinessProfile from './BusinessProfile/BusinessProfile';
import FamilyProfile from './FamilyProfile';

export default function Profile() {
    const { role } = useUserContext();

    return role && role !== 'business_active' ? <FamilyProfile /> : <BusinessProfile />;
}
