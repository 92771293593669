import { AsYouType, isValidPhoneNumber, parsePhoneNumber } from 'libphonenumber-js';

export function formatReadablePhoneNumber(number: string) {
    if (!isValidPhoneNumber(number, 'US')) {
        return stripPhoneNumber(number);
    }
    const parsedNumber = parsePhoneNumber(number, 'US');
    return parsedNumber.formatNational();
}

export function formatE164USPhoneNumber(number: string) {
    if (!isValidPhoneNumber(number, 'US')) {
        return stripPhoneNumber(number);
    }
    const parsedNumber = parsePhoneNumber(number, 'US');
    return parsedNumber?.number;
}

export function stripPhoneNumber(number: string) {
    return number.replace(/\D/g, '');
}

export function formatPhoneAsYouType(number: string) {
    const formatter = new AsYouType('US');
    const newValue = formatter.input(number);
    //Fix for not being able to delete area code because (614 formats back to (614)
    return newValue === `${number})` ? newValue.slice(0, -1) : newValue;
}

export function formatCurrency(number: number, decimalPlaces: number = 2) {
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: decimalPlaces,
    });

    return formatter.format(number);
}

export const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});
