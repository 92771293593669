import { Step, StepConnector, stepConnectorClasses, StepIconProps, StepLabel, Stepper, styled } from '@mui/material';
import React from 'react';
import Worker from 'assets/images/on-demand/worker.png';
import Business from 'assets/images/on-demand/center.png';
import CareCertified from 'assets/images/on-demand/center-certified.svg';
import { Colors } from 'library';

export type StepsType = {
    key: string;
    label: React.ReactNode;
};

export default function TheTandemSolutionStepper({
    smDown,
    steps,
    stepperStyles,
}: {
    smDown: boolean;
    steps: StepsType[];
    stepperStyles?: React.CSSProperties;
}) {
    const Connector = styled(StepConnector)(() => ({
        [`& .${stepConnectorClasses.lineHorizontal}`]: {
            borderColor: Colors.darkNavy,
            borderStyle: 'dashed',
            borderWidth: 0,
            borderTopWidth: 3,
        },
        [`& .${stepConnectorClasses.lineVertical}`]: {
            borderColor: Colors.darkNavy,
            borderStyle: 'dashed',
            borderWidth: 0,
            borderLeftWidth: 3,
            marginLeft: smDown ? 15 : 15,
        },
        [`&.${stepConnectorClasses.root}`]: {
            top: smDown ? 30 : 50,
        },
    }));

    function IconComponent(props: StepIconProps) {
        const iconSize = smDown ? 60 : 100;
        const icons: { [index: string]: React.ReactElement } = {
            1: <img src={Worker} alt="worker" width={iconSize} height={iconSize} style={{ zIndex: 100 }} />,
            2: (
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: Colors.lightTurq,
                        width: iconSize,
                        height: iconSize,
                        borderRadius: 50,
                    }}
                >
                    <CareCertified height={smDown ? 36 : 60} color={Colors.darkNavy} />
                </div>
            ),
            3: <img src={Business} alt="business" width={iconSize} height={iconSize} />,
        };

        return <div style={{ zIndex: 100 }}>{icons[String(props.icon)]}</div>;
    }

    return (
        <Stepper
            sx={{ width: '70%', marginTop: '30px', marginLeft: smDown ? '40px' : '240px', ...stepperStyles }}
            alternativeLabel={!smDown}
            connector={<Connector />}
            orientation={smDown ? 'vertical' : 'horizontal'}
        >
            {steps.map((step) => (
                <Step active={false} key={step.key}>
                    <StepLabel StepIconComponent={IconComponent}>{step.label}</StepLabel>
                </Step>
            ))}
        </Stepper>
    );
}
