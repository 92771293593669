import React, { useState } from 'react';
import { Colors } from '../../../../constants';
import { Grid, makeStyles } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import { validatePassword } from 'shared';
import '@fontsource/work-sans';
import '@fontsource/pt-serif';
import { TextButton } from 'library';

interface IInputProps {
    id?: string;
    label: string;
    onChange: (value: string, isValid: boolean) => void;
    value: string;
    error: boolean;
    errorText: string;
}

const useStyles = makeStyles({
    input: {
        '&:hover': {
            outline: 'none',
        },
        '&:focus': {
            outline: 'none',
        },
        '&::placeholder': {
            color: Colors.mediumNavy,
        },
        borderBottomColor: Colors.mediumNavy,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        borderWidth: 0,
        borderBottomWidth: 1,
        width: '100%',
        autoCapitalize: 'none',
    },
    label: {
        fontFamily: 'Work Sans',
        color: Colors.darkNavy,
    },
    errorText: {
        fontFamily: 'Work Sans',
        color: Colors.error,
        fontSize: 12,
        margin: 0,
    },
});

export default function PasswordInput({ label, onChange, value, error, errorText, id }: IInputProps) {
    const classes = useStyles();
    const [hidden, setHidden] = useState<boolean>(true);

    function getPlaceholder() {
        return value ? '' : label;
    }

    function getLabel() {
        return !value ? '' : label;
    }

    return (
        <Grid container direction="column">
            <Grid item>
                <label className={classes.label}>{getLabel()}</label>
            </Grid>
            <Grid item>
                <input
                    id={id}
                    className={classes.input}
                    type={hidden ? 'password' : 'text'}
                    value={value}
                    onChange={(e) => onChange(e.target.value, validatePassword(e.target.value))}
                    placeholder={getPlaceholder()}
                    style={{ color: Colors.darkNavy }}
                />
            </Grid>
            <Grid item xs={12} style={{ alignSelf: 'flex-end' }}>
                <TextButton textStyle={{ textTransform: 'none', fontSize: 8 }} onClick={() => setHidden(!hidden)}>
                    {hidden ? 'Show Password' : 'Hide Password'}
                </TextButton>
            </Grid>
            {error ? (
                <Grid container item alignItems="center">
                    <Grid item style={{ paddingLeft: 5 }}>
                        <CancelIcon style={{ color: Colors.error, maxHeight: 12 }} />
                    </Grid>
                    <Grid item>
                        <p className={classes.errorText}>{errorText}</p>
                    </Grid>
                </Grid>
            ) : null}
        </Grid>
    );
}
