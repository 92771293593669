import React from 'react';
import { SubJobClosedAppointment } from 'models';
import { Colors, Text } from 'library';
import { format12HourTime, formatShortWeekdayMonthDay } from 'shared/Dates';

export default function SubJobClosedAppointmentEntry({
    appt,
    additionalHeaderContent,
    menu,
    additionalCardContent,
    cardColor,
    textColor,
}: {
    appt: SubJobClosedAppointment;
    additionalHeaderContent?: React.ReactNode;
    menu?: React.ReactNode;
    additionalCardContent?: React.ReactNode;
    cardColor: Colors;
    textColor: Colors;
}) {
    return (
        <div
            style={{
                border: 'solid black 1px',
                marginTop: 5,
            }}
        >
            <div
                style={{
                    backgroundColor: cardColor,
                    padding: 5,
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                }}
            >
                <div>
                    <Text variant="body2" bold color={textColor}>
                        {formatShortWeekdayMonthDay(appt.start_date)} ({appt.id})
                    </Text>
                    <Text variant="body2" bold color={textColor}>
                        {appt.status}
                    </Text>
                    <Text variant="body2" bold color={textColor}>
                        {format12HourTime(appt.start_date, appt.timezone)} -{' '}
                        {format12HourTime(appt.end_date, appt.timezone)}
                    </Text>
                    {additionalHeaderContent}
                </div>
                {menu}
            </div>
            {additionalCardContent}
        </div>
    );
}
