import { Chip, Colors } from 'library';
import { JobDetailValueWithOption } from 'models';
import React from 'react';

const backgroundColor = {
    1: Colors.lightTurq,
    2: Colors.mediumTurq,
} as { [key: number]: string };

export default function JobDetailsChip({ detail }: { detail: JobDetailValueWithOption }) {
    const label = detail.option ? detail.option.value : detail.value || '';
    return (
        <Chip
            label={label}
            backgroundColor={backgroundColor[detail.detail]}
            textColor={Colors.darkNavy}
            style={styles.chip}
        />
    );
}

const styles = {
    chip: {
        height: 20,
        fontSize: 12,
        minHeight: 20,
    },
};
