import { Grid } from '@mui/material';
import { Colors, Text } from 'library';
import React from 'react';
import DateFnsUtils from '@date-io/moment';
import { EmploymentHistory } from './models';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { FaCalendarCheck } from 'react-icons/fa';

export function EmploymentDetails({
    employmentHistory,
    jobDates,
    setJobDates,
}: {
    employmentHistory: EmploymentHistory;
    jobDates: { start: Date; end: Date };
    setJobDates: (dates: { start: Date; end: Date }) => void;
}) {
    return (
        <>
            <Grid item container direction="column">
                <Text bold>Employment Location:</Text>
                <Text>{employmentHistory.center_name}</Text>
                <Text>{employmentHistory.center_location}</Text>
            </Grid>
            <Grid item container direction="row" style={{ gap: 20 }}>
                <Grid xs={12} md={3} item container direction="column">
                    <Text bold>Employment Dates:</Text>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            autoOk
                            variant="inline"
                            format="MM/DD/YYYY"
                            mask="__/__/____"
                            value={jobDates.start}
                            onChange={(date) =>
                                setJobDates({ start: date?.toDate() ?? jobDates.start, end: jobDates.end })
                            }
                            openTo="date"
                            helperText="Start Date"
                            showTodayButton={false}
                            maxDate={jobDates.end}
                            style={{ width: '100%' }}
                            keyboardIcon={<FaCalendarCheck color={Colors.darkNavy} />}
                            id={`emp-hist-date-picker-start`}
                        />
                        <KeyboardDatePicker
                            autoOk
                            helperText="End Date"
                            variant="inline"
                            format="MM/DD/YYYY"
                            mask="__/__/____"
                            value={jobDates.end}
                            onChange={(date) =>
                                setJobDates({ end: date?.toDate() ?? jobDates.end, start: jobDates.start })
                            }
                            openTo="date"
                            showTodayButton={false}
                            minDate={jobDates.start}
                            style={{ width: '100%' }}
                            keyboardIcon={<FaCalendarCheck color={Colors.darkNavy} />}
                            id={`emp-hist-date-picker-end`}
                        />
                    </MuiPickersUtilsProvider>
                </Grid>
                <Grid xs={12} md={3} item container direction="column">
                    <Text bold>Position:</Text>
                    <Text>{employmentHistory.position}</Text>
                </Grid>
                <Grid xs={12} md={3} item container direction="column">
                    <Text bold>Supervisor:</Text>
                    <Text>{employmentHistory.supervisor}</Text>
                </Grid>
            </Grid>
            {employmentHistory.job_duties ? (
                <Grid item container direction="column">
                    <Text bold>Job Duties:</Text>
                    <Text> {employmentHistory.job_duties}</Text>
                </Grid>
            ) : null}
        </>
    );
}
