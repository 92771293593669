import React from 'react';
import { Grid } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import { IconButton, Text } from 'library';
import { ExtendedPayScale } from 'parent-portal/forms/JobRequestTypes';
import BusinessJobPayQualifications from './BusinessJobPayQualifications';

export default function BusinessJobPay({
    pay,
    rateMin,
    rateMax,
    onEdit,
    payScales,
}: {
    pay?: number;
    rateMin?: number;
    rateMax?: number;
    onEdit: () => void;
    payScales: ExtendedPayScale[];
}) {
    // NOTE: temporary holdover until all jobs have pay scales with qualifications
    const hasSomeQualifications = payScales.some((payScale) => payScale.qualifications?.length > 0);

    return (
        <Grid xs={12} md={3} item container alignContent="flex-start">
            <Grid container item>
                <Grid xs={12} item container direction="row" style={{ gap: 10 }}>
                    <Text variant="h1">Pay Scale</Text>
                    <IconButton icon={Edit} onClick={onEdit} />
                </Grid>
                <Text textStyle={{ width: '100%' }}>${pay ?? `${rateMin}-${rateMax}`}/hr</Text>
                {hasSomeQualifications && (
                    <div>
                        {payScales.map((payScale) => (
                            <BusinessJobPayQualifications key={payScale.id} payScale={payScale} />
                        ))}
                    </div>
                )}
            </Grid>
        </Grid>
    );
}
