import { Checkbox } from 'library';
import { BusinessJobWithAppointments } from 'models';
import React from 'react';

interface IToggleIsRecurringProps {
    job: BusinessJobWithAppointments;
    onChange: (job: BusinessJobWithAppointments, isChecked: boolean) => void;
}

export default function ToggleIsRecurring({ job, onChange }: IToggleIsRecurringProps) {
    return job.business_job_type === 'SUBSTITUTE' ? (
        <Checkbox
            label="Is Recurring"
            checked={job.is_recurring_business || false}
            onChange={(checked) => onChange(job, checked)}
        />
    ) : null;
}
