import React, { useState } from 'react';
import { useMediaQuery } from '@material-ui/core';
import theme from 'theme';
import ChangeModal from './ConfirmSubHours/ChangeModal';
import { IHourReport, IHourReportView } from 'internal/payments/HourReportModels';
import { getHourReport } from 'api/HourReportApi';
import { consoleLogInDev } from 'shared';
import Report from './ConfirmSubHours/Report';
import { Grid } from '@mui/material';

export function ConfirmHoursContent({
    hourReports,
    updateHoursReported,
}: {
    hourReports: IHourReportView[];
    updateHoursReported: () => void;
}) {
    const small = useMediaQuery(theme.breakpoints.down('sm'));
    const [changeReport, setChangeReport] = useState<IHourReport>();

    function onSelectChangeReport(reportId?: number) {
        if (!reportId) {
            setChangeReport(undefined);
            return;
        }

        getHourReport(reportId)
            .then((response: IHourReport) => setChangeReport(response))
            .catch(consoleLogInDev);
    }

    hourReports.sort((a, b) => (new Date(a.clock_in).getTime() > new Date(b.clock_in).getTime() ? -1 : 1));

    return (
        <>
            <Grid container direction="row" spacing={2}>
                {hourReports.map((x) => (
                    <Grid key={x.id} item lg={4} md={6} xs={12}>
                        <Report
                            report={x}
                            setChangeReport={onSelectChangeReport}
                            updateHoursReported={updateHoursReported}
                        />
                    </Grid>
                ))}
            </Grid>
            {changeReport ? (
                <ChangeModal
                    changeReport={changeReport}
                    setChangeReport={setChangeReport}
                    updateHoursReported={updateHoursReported}
                    fullScreen={small}
                />
            ) : null}
        </>
    );
}
