import React from 'react';
import DashboardSection from '../components/DashboardSection';
import { IBusinessDashboardProps } from './types';
import UnclockedInShifts from '../../subs/ConfirmSubHours/UnclockedInShifts';

export default function ClockInWorkers({ data, updateData }: IBusinessDashboardProps) {
    const workersToClockIn = data?.hour_reports?.users_needing_clocked_in;

    if (!workersToClockIn?.length) {
        return <></>;
    }

    return (
        <DashboardSection
            header="Clock In Workers"
            description="These workers are scheduled at your centers today but have not clocked in"
        >
            <UnclockedInShifts workersToClockIn={workersToClockIn} onShiftClockIn={updateData} />
        </DashboardSection>
    );
}
