import { BusinessOutlawWithUser } from 'models';
import React from 'react';
import NameButton from './NameButton';
import { Grid } from '@mui/material';
import { Colors, Text } from 'library';
import { ProviderOutlawModal } from 'internal/shared/UserActions';

export default function OutlawedWorkers({
    outlawed,
    setSelectedUser,
    refreshData,
    businessLocationId,
}: {
    outlawed: BusinessOutlawWithUser[];
    setSelectedUser: (userId: number) => void;
    refreshData?: () => void;
    businessLocationId: number;
}) {
    return (
        <Grid container item gap={1} direction="column" xs={12} md={3}>
            <Text bold variant="h2" textStyle={{ color: Colors.turquoise }}>
                Outlawed Workers:
            </Text>
            {outlawed.length === 0 && <Text>No Outlawed Workers Found.</Text>}
            {outlawed.map((o) => (
                <Grid key={o.id} container item alignItems="center">
                    <Grid key={o.id} container item gap={1} direction="column" xs={10}>
                        <NameButton onClick={setSelectedUser} user={o.user} />
                        <Text variant="caption">
                            {o.business_location ? 'Banned At Location Only' : 'Banned Business Wide'}
                        </Text>
                        {o.memo ? <Text variant="caption">{o.memo}</Text> : null}
                    </Grid>
                    <ProviderOutlawModal user={o.user} onClose={refreshData} defaultNameOrId={businessLocationId} />
                </Grid>
            ))}
        </Grid>
    );
}
