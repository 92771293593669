import { CircularProgress } from '@mui/material';
import { BusinessApplicantWithJob } from 'internal/applicants/ApplicantModels';
import { Switch, Text } from 'library';
import { AdminBusinessUser, LeanUser, LeanUserWithPhone } from 'models';
import React, { useEffect, useState } from 'react';
import { adminLog, client } from 'shared';
import AppliedJobsMenuItem from './AppliedJobsMenuItem';
import { Grid } from '@mui/material';

export default function AppliedJobsMenu({
    user,
    open,
}: {
    user: AdminBusinessUser | LeanUserWithPhone | LeanUser;
    open: boolean;
}) {
    const [appliedJobs, setAppliedJobs] = useState<BusinessApplicantWithJob[]>([]);
    const [loading, setLoading] = useState(false);
    const [showClosedFilled, setShowClosedFilled] = useState(false);

    useEffect(() => {
        if (open) {
            setLoading(true);
            client(
                `api/business-applicant/?applied_user=${user.id}&show_closed_filled=${showClosedFilled}&show_ignore=true`,
            )
                .then(setAppliedJobs)
                .catch(adminLog)
                .finally(() => setLoading(false));
        }
    }, [open, user, showClosedFilled]);

    return (
        <>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                {loading ? (
                    <CircularProgress style={{ padding: 10 }} />
                ) : appliedJobs.length > 0 ? (
                    <>
                        <Grid container direction="row" gap={2} margin={2}>
                            <Text>Closed/Filled Shifts</Text>
                            <Switch
                                checked={!showClosedFilled}
                                onChange={(e, checked) => setShowClosedFilled(!checked)}
                            />
                            <Text>Open Shifts</Text>
                        </Grid>
                        {appliedJobs.map((job) => (
                            <AppliedJobsMenuItem key={job.id} application={job} user={user} />
                        ))}
                    </>
                ) : (
                    <Text textStyle={{ padding: 10 }}>No jobs found</Text>
                )}
            </div>
        </>
    );
}
