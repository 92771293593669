import { Grid } from '@material-ui/core';
import React from 'react';
import UnsupportedRegionCallout from './UnsupportedRegionCallout';
export default function UnsupportedRegionPage() {
    return (
        <Grid container justify="center" style={{ height: '80vh', paddingTop: 100 }}>
            <Grid item xs={10} sm={8} md={6}>
                <UnsupportedRegionCallout />
            </Grid>
        </Grid>
    );
}
