import React from 'react';
import { Grid } from '@mui/material';
import { OutlinedTextInput, PrimaryButton } from 'library';

type BusinessSearchProps = {
    searchHandler: () => void;
    setNameOrId: (value: string) => void;
};

export function BusinessSearch({ searchHandler, setNameOrId }: BusinessSearchProps) {
    return (
        <Grid container style={{ marginBottom: '2em' }} alignItems="center">
            <Grid item sm={6}>
                <OutlinedTextInput label="Business ID/Name" onChange={(e) => setNameOrId(e.target.value)} />
            </Grid>
            <Grid item sm={6}>
                <PrimaryButton onClick={searchHandler}>Search</PrimaryButton>
            </Grid>
        </Grid>
    );
}
