import React from 'react';
import BackButton from './BackButton';
import PageFooter from './PageFooter';
import SaveButton from './SaveButton';

interface PageActionsFooterProps {
    save?: () => void;
    goBack?: () => void;
    loading?: boolean;
    disabled?: boolean;
}

export default function PageActionsFooter({ save, goBack, loading, disabled }: PageActionsFooterProps) {
    return (
        <PageFooter>
            <div
                style={{ display: 'flex', height: '100%', alignItems: 'center', flexDirection: 'row', paddingLeft: 30 }}
            >
                {goBack ? (
                    <div style={{ marginRight: 50 }}>
                        <BackButton onClick={goBack} disabled={disabled || loading} />
                    </div>
                ) : null}
                {save ? <SaveButton onClick={save} loading={loading} disabled={disabled} /> : null}
            </div>
        </PageFooter>
    );
}
