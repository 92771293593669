import React, { useState } from 'react';
import StripeCheckout from './StripeCheckout';
import client from '../shared/ApiClient';
import { useCart } from './CartContext';
import { track } from '../shared/Tracking';
import { useUserContext } from '../UserContext';
import { CircularProgress, Modal } from '@material-ui/core';
import { BackgroundCheckCode } from './SitterPaymentModels';
import { consoleLogInDev } from 'shared';

interface PaymentSuccessfulResult {
    id: number;
}

interface PaymentFailedResult {
    message: string;
}

interface BackgroundCheckCheckoutProps {
    onSuccess: (message: string) => void;
    onFail: (message: string) => void;
}

const BackgroundCheckCheckout = ({ onSuccess, onFail }: BackgroundCheckCheckoutProps) => {
    const { cart, refreshCart, licenseNumber, licenseState, ssn, inputsInvalid } = useCart();
    const { user } = useUserContext();
    const [processing, setProcessing] = useState(false);

    const inputs = {
        MVR: {
            drivers_license_id: licenseNumber,
            drivers_license_state: licenseState,
        },
        STANDARD: {
            ssn: ssn,
        },
        BASIC: {
            ssn: ssn,
        },
        DOCUMENT_VERIFICATION: {},
        PRO: {
            ssn: ssn,
        },
        SESAME_TELEHEALTH_OHIO: {},
    };

    const performBackgroundCheck = (code: BackgroundCheckCode) => {
        let body = inputs[code];
        if (code === 'SESAME_TELEHEALTH_OHIO') {
            // Not submitting background check for Ohio Telehealth
            return;
        }
        client(`api/background-check-token/user/${user?.id}/${code}/`, { body }).catch(consoleLogInDev);
    };

    const paymentSuccessful = (result: PaymentSuccessfulResult) => {
        let successMessage =
            'Payment Complete! Please check your email for confirmation and to view your background check status.';
        client(`api/cart/${cart[0].cart}/`, {
            method: 'PATCH',
            body: { status: 'PAYMENT_COMPLETE', stripe_id: result.id },
        })
            .then(() => {
                cart.forEach((itemRow) => {
                    if (itemRow.item.code === 'DOCUMENT_VERIFICATION' || itemRow.item.code === 'PRO') {
                        successMessage = `${successMessage} You will also receive an email from our partner Evident to submit your identification document for verification.`;
                    }
                    performBackgroundCheck(itemRow.item.code as BackgroundCheckCode);
                });
                refreshCart();
                setProcessing(false);
                onSuccess(successMessage);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const paymentFailed = (error: PaymentFailedResult) => {
        client(`api/cart/${cart[0].cart}/`, { method: 'PATCH', body: { status: 'PAYMENT_REJECTED' } }).then(
            refreshCart,
        );
        onFail(error.message);
        setProcessing(false);
    };

    return (
        <>
            {cart?.length > 0 && (
                <StripeCheckout
                    disabled={inputsInvalid}
                    paymentStarted={() => setProcessing(true)}
                    paymentSuccessful={paymentSuccessful}
                    paymentFailed={paymentFailed}
                />
            )}
            <Modal style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} open={processing}>
                <CircularProgress />
            </Modal>
        </>
    );
};

export default BackgroundCheckCheckout;
