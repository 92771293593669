import React, { useEffect } from 'react';
import { FeatureFlag, useFeatureFlag } from 'shared';
import { useUserContext } from 'UserContext';
import { chatwootScript } from './chatwootScript';

const CHATWOOT_READY_EVENT = 'chatwoot:ready';
const SCRIPT_TAG = 'script';

export default function ChatAndHelpCenter() {
    const { user } = useUserContext();
    const { featureActive } = useFeatureFlag(FeatureFlag.CENTER_CHATWOOT_ENABLED);
    useEffect(() => {
        if (!!window.$chatwoot || !user || !featureActive || user.businesses.length <= 0) {
            return;
        }
        const websiteToken = user.center_chatwoot_web_token;
        const identifierHash = user.center_chatwoot_hmac_token;
        const baseUrl = window.env.REACT_APP_CHATWOOT_URL;

        chatwootScript(document, SCRIPT_TAG, baseUrl, websiteToken);

        window.addEventListener(CHATWOOT_READY_EVENT, () => {
            window.$chatwoot.setUser(user.id.toString(), {
                email: user.email,
                name: `${user.first_name} ${user.last_name}`,
                phone_number: user.phone_number,
                identifier_hash: identifierHash,
            });
            window.$chatwoot.setCustomAttributes({
                region: user.region_info.area,
                state: user.address[0].state,
            });
        });

        return () => {
            window.removeEventListener(CHATWOOT_READY_EVENT, () => {});
        };
    }, [user]);

    return <></>;
}
