import { BasicDialog, Colors, IconButton, PrimaryButton, SecondaryButton, Text } from 'library';
import React, { useEffect, useState } from 'react';
import PhoneNumberInput from 'parent-portal/signup/PhoneNumberInput';
import { client, consoleLogInDev } from 'shared';
import { Edit } from '@mui/icons-material';

export default function EditPhoneNumberButton({
    userId,
    initialPhoneNumber,
    onSave,
}: {
    userId: number;
    initialPhoneNumber: string;
    onSave: () => void;
}) {
    const [isOpen, setIsOpen] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState<string>(initialPhoneNumber);
    const [phoneDisplay, setPhoneDisplay] = useState<string>(initialPhoneNumber);
    const [isValid, setIsValid] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        setPhoneNumber(initialPhoneNumber);
        setPhoneDisplay(initialPhoneNumber);
    }, [initialPhoneNumber]);

    function onSaveClick() {
        if (!isValid) {
            return;
        }

        setErrorMessage('');

        client(`internal/user/${userId}/`, { body: { phone_number: phoneNumber }, method: 'PATCH' })
            .then(() => {
                onSave();
                setIsOpen(false);
            })
            .catch((error) => {
                consoleLogInDev(error);
                setErrorMessage(
                    error.phone_number || 'There was an error. The phone number may belong to another user',
                );
            });
    }

    function onClose() {
        setIsOpen(false);
        setPhoneNumber(initialPhoneNumber);
        setPhoneDisplay(initialPhoneNumber);
        setErrorMessage('');
    }

    return (
        <>
            <IconButton
                onClick={() => setIsOpen(true)}
                icon={Edit}
                backgroundColor={Colors.darkNavy}
                color={Colors.white}
                style={{ marginLeft: 5 }}
            />
            <BasicDialog closeButton isOpen={isOpen} onClose={onClose} style={{ width: '80%' }}>
                <div>
                    <PhoneNumberInput
                        label="Phone"
                        onChange={(value, displayPhone, isValid) => {
                            setPhoneNumber(value);
                            setPhoneDisplay(displayPhone);
                            setIsValid(isValid);
                        }}
                        value={phoneDisplay}
                        error={!isValid}
                        errorText="Invalid Format"
                    />
                    <SecondaryButton
                        onClick={onClose}
                        buttonStyle={{ marginTop: 10, marginRight: 10, width: undefined }}
                    >
                        Cancel
                    </SecondaryButton>
                    <PrimaryButton
                        disabled={!isValid}
                        onClick={onSaveClick}
                        buttonStyle={{ marginTop: 10, width: undefined }}
                    >
                        Save
                    </PrimaryButton>
                    <Text textStyle={{ color: Colors.error }}>{errorMessage}</Text>
                </div>
            </BasicDialog>
        </>
    );
}
