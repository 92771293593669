import React, { useEffect, useState } from 'react';
import { useUserContext } from 'UserContext';
import { Grid } from '@mui/material';
import { Text, PrimaryButton } from 'library';
import { Address } from 'models/User';
import useQueryParams from 'shared/QueryParamsHook';
import LocationQRCode from './LocationQRCode';
import { downloadQRCode } from './downloadQRcode';

export default function ClockInQRCode() {
    const { user } = useUserContext();
    const [address, setAddress] = useState<Address>();
    const params = useQueryParams();
    const addressId = String(params.get('loc_id'));

    useEffect(() => {
        if (!addressId && user && user.address.length > 0) {
            setAddress(user.address[0]);
        } else if (addressId) {
            setAddress(findAddress(addressId));
        }
    }, [user]);

    const findAddress = (locationId: string) => {
        return user?.address.find((addr) => String(addr.id) === locationId);
    };

    return (
        <Grid container direction="column" alignItems="center">
            <Grid container xs={12} md={6} direction="column" alignItems="center" style={{ marginTop: 20 }}>
                <Text variant="h1">Tandem Shift Management</Text>
                <Text variant="body1">
                    Workers can scan this QR code on arrival at your center to report their hours. It will take them to
                    a page in their app where they can clock in and out. We suggest printing this page and placing it
                    somewhere providers can easily access it at the beginning and end of their shift. This helps us to
                    ensure their hours and payments are accurate.
                </Text>
                {address && (
                    <Text textStyle={{ marginTop: 20 }} variant="h1">
                        Center Address
                    </Text>
                )}
                <LocationQRCode addressId={addressId} />
                <Grid container sm={12} lg={6}>
                    {address && (
                        <PrimaryButton variant="contained" onClick={() => downloadQRCode(address)}>
                            Download QR Code
                        </PrimaryButton>
                    )}
                </Grid>
            </Grid>
        </Grid>
    );
}
