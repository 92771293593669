import { PreferredJobSitterDTO } from 'parent-portal/forms/components/pages/ProviderPreferencesPage/preferredWorkersApi';
import { client } from 'shared';
import moment from 'moment';
import { OnDecline } from '../../../../public/JobModels';

interface UpdateBusinessAppointmentBody {
    start_date?: string;
    end_date?: string;
    preferred_sitters?: PreferredJobSitterDTO;
}

export function updateBusinessAppointment(shiftId: number, body: UpdateBusinessAppointmentBody) {
    return client(`api/business-appointment/${shiftId}/`, {
        method: 'PATCH',
        body: body,
    });
}

export function createCenterInitiatedAppointmentTimeChangeRequest({
    appointmentId,
    startTime,
    endTime,
    onDecline,
}: {
    appointmentId: number;
    startTime: moment.Moment | null;
    endTime: moment.Moment | null;
    onDecline: OnDecline;
}) {
    return client(`api/center-initiated-appointment-time-change-request/`, {
        method: 'POST',
        body: {
            appointment_id: appointmentId,
            start_time: startTime?.toISOString(),
            end_time: endTime?.toISOString(),
            on_decline: onDecline,
        },
    });
}
