import { Divider, styled } from '@mui/material';
import { getBusinessLocations } from 'api/AdminBusinessLocationApi';
import { BusinessFilterValues } from 'internal/business-locations/BusinessLocationFilters';
import BusinessLocationListItem from 'internal/business-locations/BusinessLocationListItem';
import BusinessLocationMapFilters from 'internal/business/maps/BusinessLocationMapFilters';
import PaginatedTable from 'internal/shared/tables/PaginatedAdminTable';
import { Text } from 'library';
import { AdminListProvider, PagingResult } from 'models';
import { AdminBusinessLocation } from 'models/AdminBusinessLocation';
import React, { useEffect, useState } from 'react';
import { adminLog, queryStringifyObject } from 'shared';

export default function CenterMatchingList({ provider }: { provider: AdminListProvider }) {
    const [businessLocations, setBusinessLocations] = useState<PagingResult<AdminBusinessLocation>>({
        count: 0,
        per_page: 0,
        num_pages: 0,
        results: [],
    });
    const [page, setPage] = useState(1);
    const [businessLocationFilters, setBusinessLocationFilters] = useState<BusinessFilterValues>({});

    useEffect(() => {
        const queryParams = queryStringifyObject({
            ...businessLocationFilters,
            page,
            sort_by: 'distance',
            distance_for_user: provider.user.id,
        });
        getBusinessLocations(queryParams).then(setBusinessLocations).catch(adminLog);
    }, [page, businessLocationFilters]);

    return (
        <>
            <HorizontalDiv>
                <Text bold textStyle={{ marginTop: 20 }}>
                    Business location filters
                </Text>
                <BusinessLocationMapFilters onSearch={setBusinessLocationFilters} />
            </HorizontalDiv>
            <PaginatedTable columns={[]} data={businessLocations} page={page} updatePage={setPage}>
                {businessLocations.results.map((businessLocation) => (
                    <>
                        <BusinessLocationListItem
                            key={businessLocation.id}
                            businessLocation={businessLocation}
                            position="single"
                        />
                        <Divider />
                    </>
                ))}
            </PaginatedTable>
        </>
    );
}

const HorizontalDiv = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
});
