import React from 'react';
import DownloadIcon from '@mui/icons-material/Download';
import { Grid } from '@material-ui/core';
import { Provider } from 'models';
import { downloadFile, getBackGroundCheckLink } from './DocumentShared';
import Colors from 'constants/Colors';

export default function DownloadAllDocuments({ provider }: { provider: Provider }) {
    function download() {
        downloadFile(`api/providers/${provider.id}/documents/`);
        const backgroundCheckLink = getBackGroundCheckLink(provider);
        const hasBackgroundPDF = provider.compliance_documents?.some(
            (x) => x.friendly_name === 'Background Check Document',
        );
        if (backgroundCheckLink && !hasBackgroundPDF) {
            window.open(backgroundCheckLink, '_blank');
        }
    }
    return (
        <Grid onClick={download} style={{ cursor: 'pointer' }}>
            <DownloadIcon htmlColor={Colors.darkNavy} />
        </Grid>
    );
}
