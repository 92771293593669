import React from 'react';
import { IPayMethod } from 'models';
import { Grid } from '@material-ui/core';
import { client } from 'shared';
import { useUserContext } from 'UserContext';
import LoadingButton from 'reusableComponents/loadingButton';
import { EventAction } from 'parent-portal/shared/PublicSessionTracking';

export default function DefaultPaymentMethod({
    payMethod,
    setIdToDelete,
    setPayMethods,
    trackEvent,
}: {
    payMethod: IPayMethod;
    setIdToDelete: (n: number) => void;
    setPayMethods: React.Dispatch<React.SetStateAction<IPayMethod[]>>;
    trackEvent: (trackMessage: string, data?: any, action?: EventAction) => void;
}) {
    const { refreshUser } = useUserContext();

    const onDeleteClick = (payMethodId: number) => {
        setIdToDelete(payMethodId);
    };

    function setDefaultPayment(id: number) {
        setPayMethods((currentState: IPayMethod[]) => {
            return currentState.map((x) => {
                return {
                    ...x,
                    errorMessage: '',
                    isLoading: x.id === id,
                };
            });
        });

        client(`api/payment-info/${id}/default/`, { method: 'POST' } as any)
            .then(() => {
                setPayMethods((currentState) => {
                    return currentState.map((x) => {
                        return {
                            ...x,
                            errorMessage: '',
                            isLoading: false,
                            is_default: x.id === id,
                        };
                    });
                });
                trackEvent('Payments: Set Default Payment', { payInfoId: id });
                refreshUser();
            })
            .catch((error) => {
                setPayMethods((currentState) => {
                    return currentState.map((x) => {
                        return {
                            ...x,
                            errorMessage: x.id === id ? 'An error occurred updating your payment method' : '',
                            isLoading: false,
                        };
                    });
                });
            });
    }
    return (
        <Grid xs={12} md={6} container direction="row" justify="space-between">
            <LoadingButton
                loading={payMethod.isLoading}
                onClick={() => onDeleteClick(payMethod.id)}
                asSecondary={true}
                buttonStyle={{ padding: '0.25em', margin: '0.25em', minWidth: 0, width: 100 }}
            >
                Delete
            </LoadingButton>
            <LoadingButton
                loading={payMethod.isLoading}
                onClick={() => setDefaultPayment(payMethod.id)}
                buttonStyle={{ padding: '0.25em 1em', margin: '0.25em', minWidth: 0, width: 'unset' }}
            >
                Make Default
            </LoadingButton>
        </Grid>
    );
}
