import { logout } from 'api/UserApi';
import makeRequest from './Request';

interface Request {
    body?: { [key: string]: any };
    method?: string;
    headers?: { [key: string]: any };
    token?: string;
}

function addTrailingSlash(endpoint: string) {
    if (endpoint.includes('?')) {
        return endpoint;
    } else if (endpoint.charAt(endpoint.length - 1) !== '/') {
        return `${endpoint}${'/'}`;
    }
    return endpoint;
}

type NetworkSuccess = {
    ok: true;
    response: Response;
};

type RequestFailureError = {
    ok: false;
    message: string;
    error: unknown;
};

type HttpResponseError = {
    ok: false;
    message: string;
    response: Response;
};

type NetworkError = RequestFailureError | HttpResponseError;
type NetworkResult = NetworkSuccess | NetworkError;

type ClientOptions = {
    stringify?: boolean;
};

async function rawResponseClient(
    endpoint: string,
    { body, ...customConfig }: Request = {},
    options: ClientOptions = {},
): Promise<NetworkResult> {
    const { stringify = true } = options;

    const updatedEndpoint = addTrailingSlash(endpoint);
    let response: Response;

    try {
        response = await makeRequest(updatedEndpoint, { body, ...customConfig }, stringify);
    } catch (e) {
        return { ok: false, message: 'Error', error: e };
    }

    if (response.status === 401) {
        logout();
        return { ok: false, message: 'Unauthorized', response };
    }

    if (!response.ok) {
        return { ok: false, message: 'Bad response', response };
    }

    return { ok: true, response };
}

async function client(endpoint: string, { body, ...customConfig }: Request = {}, stringify: boolean = true) {
    let updatedEndpoint = addTrailingSlash(endpoint);
    const response = await makeRequest(updatedEndpoint, { body, ...customConfig }, stringify);
    const text = await response.text();
    try {
        const data = text ? JSON.parse(text) : null;
        if (response.ok) {
            return data;
        } else if (response.status === 401) {
            logout();
        } else {
            return Promise.reject(data);
        }
    } catch (error) {
        console.log(text);
        throw error;
    }
}

export default client;
export { rawResponseClient };
