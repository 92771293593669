import { Colors } from '../../theme';
import Text from '../text/Text';
import React from 'react';
import DragIndicator from '@material-ui/icons/DragIndicator';
import Draggable from 'react-draggable';
import { Grid, styled } from '@mui/material';
import CloseButton from './DialogCloseButton';

const DEFAULT_WIDTH = 500;

interface IProps {
    isOpen: boolean;
    dialogTitle?: string;
    children: React.ReactNode;
    onClose: () => void;
    headerColor?: Colors;
    headerComponent?: React.ReactNode;
    anchorElement?: HTMLElement | null;
    initialPosition?: 'center' | 'right';
    parentOffset?: boolean;
}
export default function DraggableDialog({
    isOpen,
    dialogTitle,
    children,
    onClose,
    headerColor,
    headerComponent,
    anchorElement,
    initialPosition,
    parentOffset = false,
}: IProps) {
    if (!isOpen) {
        return <></>;
    }
    const documentBodyBoundingRect = document.body.getBoundingClientRect();
    const parentBoundingRect = anchorElement?.getBoundingClientRect();

    let position = { x: 0, y: 0 };
    if (!initialPosition || initialPosition === 'center') {
        position = {
            x: documentBodyBoundingRect.width / 2 - (parentBoundingRect?.left ?? 0),
            y: documentBodyBoundingRect.top - (parentBoundingRect?.top ?? 0),
        };
    } else if (initialPosition === 'right') {
        position = {
            x: parentBoundingRect ? -1 * DEFAULT_WIDTH + parentBoundingRect.width : 0,
            y: 0,
        };
    }

    return (
        <Draggable
            axis="both"
            handle=".dragContainer"
            defaultPosition={position}
            positionOffset={
                parentOffset ? { x: parentBoundingRect?.left ?? 0, y: parentBoundingRect?.top ?? 0 } : { x: 0, y: 0 }
            }
            grid={[5, 5]}
            scale={1}
            cancel=".notDraggable"
            enableUserSelectHack={false}
        >
            <ResizeableContainer>
                <DraggableContainer className="dragContainer">
                    <DraggableHeader
                        color={headerColor}
                        container
                        justifyContent="space-between"
                        alignItems="center"
                        className="dragContent"
                    >
                        <Text textStyle={{ color: Colors.white }}>
                            <DragIndicator />
                            {dialogTitle}
                            {headerComponent}
                        </Text>
                        <CloseButton
                            onClose={onClose}
                            buttonStyle={{ color: Colors.white }}
                            style={{ position: 'unset', marginLeft: 10 }}
                        />
                    </DraggableHeader>
                    <ContentContainer>{children}</ContentContainer>
                </DraggableContainer>
            </ResizeableContainer>
        </Draggable>
    );
}

const DraggableHeader = styled(Grid)(({ color }: { color?: Colors }) => ({
    backgroundColor: color ?? Colors.darkNavy,
    color: Colors.white,
    padding: 10,
    borderRadius: '15px 15px 0 0',
    cursor: 'grab',
    '&:active': { cursor: 'grabbing' },
}));

const DraggableContainer = styled('div')({
    backgroundColor: Colors.white,
    zIndex: 1000,
    width: '100%',
    height: '100%',
});

const ResizeableContainer = styled('div')({
    backgroundColor: Colors.white,
    position: 'fixed',
    resize: 'both',
    overflow: 'auto',
    minWidth: 400,
    minHeight: 200,
    width: DEFAULT_WIDTH,
    maxHeight: '93vh',
    borderRadius: 18,
    boxShadow: 'lightgray 3px 3px 10px 3px',
    zIndex: 2000,
});

const ContentContainer = styled('div')({
    padding: 10,
});
