import React from 'react';
import { Qualification } from 'models';
import { Chip, Colors } from 'library';

export function BusinessPayQualificationChip({
    qualification,
    selected,
    onSelect,
}: {
    qualification: Qualification;
    selected: boolean;
    onSelect: (qualification: Qualification) => void;
}) {
    return (
        <Chip
            label={qualification.name}
            style={{
                marginRight: 12,
                marginBottom: 10,
                backgroundColor: selected ? Colors.darkNavy : Colors.lightTurq,
                color: selected ? Colors.white : Colors.darkNavy,
            }}
            onClick={() => onSelect(qualification)}
        />
    );
}
