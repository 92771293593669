import React from 'react';
import BubbleButton from './BubbleButton';
import { Colors } from '../../theme/Colors';
import { ButtonProps } from 'models';

const defaultStyle = {
    text: {
        color: Colors.darkNavy,
    },
    button: {
        padding: 8,
        borderRadius: '50%',
        minHeight: 0,
        minWidth: 0,
    },
};

const enabledStyle = {
    button: {
        backgroundColor: 'rgba(253, 253, 253, 0.3)',
        '&:hover': {
            backgroundColor: Colors.lightGrey,
        },
        outline: 'none',
    },
};

const disabledStyle = {
    button: {
        backgroundColor: Colors.disabledGrey,
    },
};

export default function TranslucentIconButton({ children, boldText = true, ...props }: ButtonProps) {
    return (
        <BubbleButton
            defaultStyle={defaultStyle}
            enabledStyle={enabledStyle}
            disabledStyle={disabledStyle}
            boldText={boldText}
            {...props}
        >
            {children}
        </BubbleButton>
    );
}
