import React from 'react';
import { Typography, Grid, List, ListItem } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import CircularProgress from '@material-ui/core/CircularProgress';
import SimpleExpansionPanel from '../../reusableComponents/expansionPanel';
import moment from 'moment';
import { IPaymentHistory } from './Models/PaymentHistory';
import PaySectionHeader from './Components/PaySectionHeader';
import { PayBackgroundPaper, PayCardPaper } from './Components/PayPapers';
import { Text } from 'library';
import DollarIcon from 'assets/icons/tandempay/dollar-icon-circle.svg';

interface IPastProps {
    isLoading: boolean;
    pastPayments?: { payments: IPaymentHistory[]; pageCount: number; pageSize: number; numberOfPages: number };
    currentPage: number;
    onPageChange: (newPage: number) => void;
}

const Past = (props: IPastProps) => {
    const getPaymentText = (payment: IPaymentHistory) => {
        const forOrOn = !payment.pairing_date ? 'on' : 'for';
        const pairingOrDirectPayDate = payment.pairing_date || payment.payment_date;
        const refunded = payment.was_refunded ? '(Refunded)' : '';

        return `Paid $${payment.total.toFixed(2)} ${forOrOn} ${shortDate(pairingOrDirectPayDate)} ${refunded}`;
    };

    const primary = (p: IPaymentHistory) => {
        return (
            <Grid container direction="row">
                <Grid container xs={2} alignItems="center" justify="center">
                    <Grid item>
                        <DollarIcon style={{ width: 35, height: 35, marginRight: 17 }} />
                    </Grid>
                </Grid>
                <Grid item xs={10}>
                    <Text variant="body1">{getPaymentText(p)}</Text>
                </Grid>
            </Grid>
        );
    };

    const secondary = (p: IPaymentHistory) => {
        return (
            <Grid container style={{ paddingLeft: 35 }}>
                <Grid container direction="row">
                    <Grid item xs={12}>
                        {p.first_name ? (
                            <Typography variant="subtitle1">
                                <b>Sitter:</b> {p.first_name} {p.last_name}
                            </Typography>
                        ) : null}
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <Typography variant="subtitle1">
                            <b>Base Pay:</b> ${p.base_pay.toFixed(2)}
                        </Typography>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <Typography variant="subtitle1">
                            {p.tip ? (
                                <div>
                                    <b>Tip:</b> ${p.tip}
                                </div>
                            ) : null}
                        </Typography>
                    </Grid>
                    <Grid item sm={4} xs={12}>
                        <Typography variant="subtitle1">
                            {p.fee ? (
                                <div>
                                    <b>Fees:</b> ${p.fee.toFixed(2)}
                                    <sup>*</sup>
                                    <br />
                                </div>
                            ) : null}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle1">
                            {p.discount ? (
                                <div>
                                    <b>Discount:</b> ${p.discount}
                                </div>
                            ) : null}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="subtitle1">
                        <b>Payment Info:</b> {p.brand_or_bank}{' '}
                        {p.last_four ? (
                            <span>
                                {' •••• '} {p.last_four}
                            </span>
                        ) : null}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    {p.payment_date ? (
                        <Typography variant="subtitle1">
                            <span>
                                <b>Payment Charged:</b> {longDate(p.payment_date)}
                            </span>
                        </Typography>
                    ) : null}
                </Grid>
                <Grid item xs={12} style={{ marginTop: 5 }}>
                    {p.pay_method === 'card' ? (
                        <Typography variant="subtitle2">
                            <sup>*</sup>Credit card charges incur a 2.9% + 30¢ fee
                        </Typography>
                    ) : null}
                </Grid>
            </Grid>
        );
    };

    const shortDate = (d: string) => {
        const formattedDate = moment(new Date(d)).format('ddd, MMM D, YYYY');
        return formattedDate;
    };

    const longDate = (d: string) => {
        const formattedDate = moment(new Date(d)).format('MMM D, YYYY @ h:mm A');
        return formattedDate;
    };

    const renderRow = (p: IPaymentHistory, index: number) => {
        return (
            <Grid key={index} item xs={12} style={{ marginTop: 15 }}>
                <ListItem style={{ width: '100%' }}>
                    <PayCardPaper>
                        <SimpleExpansionPanel primary={primary(p)} secondary={secondary(p)} />
                    </PayCardPaper>
                </ListItem>
            </Grid>
        );
    };

    const getContent = () => {
        const { pastPayments, currentPage, onPageChange } = props;

        if (!pastPayments || !pastPayments.payments || !pastPayments.payments.length) {
            return (
                <Grid item xs={12}>
                    <Text textStyle={{ marginTop: 5 }} variant="body2">
                        You have no past payments.
                    </Text>
                </Grid>
            );
        }

        const { payments, numberOfPages } = pastPayments;

        return (
            <>
                <Grid item xs={12}>
                    <List>
                        <Grid container justify="center" alignContent="center">
                            {payments.map((p, i) => renderRow(p, i))}
                        </Grid>
                    </List>
                </Grid>
                <Grid container item xs={12} justify="center">
                    <Pagination
                        count={numberOfPages}
                        color="primary"
                        onChange={(event, page) => onPageChange(page)}
                        style={{ marginTop: 10 }}
                        page={currentPage}
                        boundaryCount={2}
                    />
                </Grid>
            </>
        );
    };

    return (
        <PayBackgroundPaper>
            <Grid container direction="column" style={{ marginBottom: 10 }}>
                <Grid item xs={12}>
                    <PaySectionHeader>Past Payments</PaySectionHeader>
                </Grid>
                <Grid container item justify="center" xs={12}>
                    {props.isLoading && <CircularProgress />}
                </Grid>
                {getContent()}
            </Grid>
        </PayBackgroundPaper>
    );
};

export default Past;
