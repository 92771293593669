import React from 'react';
import Input from '../reusableComponents/input';
import { useCart } from './CartContext';

const MvrInputs = () => {
    const {
        licenseNumber,
        licenseState,
        licenseNumberValid,
        licenseStateValid,
        updateLicenseNumber,
        updateLicenseState,
    } = useCart();

    const licenseNumberChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        updateLicenseNumber(e.target.value);
    };

    const licenseStateChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        updateLicenseState(e.target.value);
    };

    return (
        <>
            <Input
                id="license-number"
                required
                label="Driver's License Number"
                value={licenseNumber}
                variant="outlined"
                onChange={licenseNumberChanged}
                error={!licenseNumberValid}
                helperText={licenseNumberValid ? '' : 'License Number must be populated.'}
            />
            <Input
                id="license-state"
                required
                label="Driver's License State"
                value={licenseState}
                variant="outlined"
                onChange={licenseStateChanged}
                error={!licenseStateValid}
                helperText={licenseStateValid ? '' : 'License State must be a two-letter U.S. state abbreviation.'}
            />
        </>
    );
};

export default MvrInputs;
