import { Grid } from '@mui/material';
import { addDays, addHours, format } from 'date-fns';
import { Text } from 'library';
import React from 'react';

export default function OutreachCollectionStats({ addedAt }: { addedAt: string }) {
    const addedDate = new Date(addedAt);
    const outReachCollection = {
        'Added At': addedDate,
        'First Outreach': addHours(addedDate, 1),
        'Second Outreach': addDays(addedDate, 2),
        'Provider Outreach': addDays(addedDate, 7),
    };
    const entries = Object.entries(outReachCollection);

    return (
        <Grid container item style={{ gap: 10 }}>
            <Text bold variant="body2">
                Dates of Outreach:
            </Text>
            {entries.map((entry) => (
                <Text key={entry[0]} variant="body2" textStyle={{ marginLeft: 10 }}>
                    {entry[0]}: {format(entry[1], 'MM/dd/yyyy hh:mm a')}
                </Text>
            ))}
        </Grid>
    );
}
