import React, { useState } from 'react';
import { Grid, useMediaQuery } from '@material-ui/core';
import theme from 'theme';
import { BusinessJobWithAppointments } from 'models';
import { OutlinedTextInput, SizeableRoundedDialog, Checkbox } from 'library';
import TadaIcon from 'assets/icons/tandempay/tada-circle.svg';
import PageHeader from 'parent-portal/payments/Components/ConnectPaymentModal/PageHeader';
import { client, consoleLogInDev } from 'shared';
import LoadingButton from 'reusableComponents/loadingButton';

export default function EmailCenterShiftsModal({
    closeModal,
    modalOpen,
    job,
}: {
    closeModal: () => void;
    modalOpen: boolean;
    job: BusinessJobWithAppointments;
}) {
    const [loading, setLoading] = useState(false);
    const [addressOnly, setAddressOnly] = useState(true);
    const [body, setBody] = useState('');
    const small = useMediaQuery(theme.breakpoints.down('sm'));

    function emailCenter() {
        const business_id = job.business.length ? job.business[0].id : null;
        const address_id = job.address.id;

        const requestBody = {
            body,
            business_id,
            address_id: addressOnly ? address_id : null,
        };

        setLoading(true);
        client(`api/admin-appointment/send-schedule-email/`, { body: requestBody })
            .then(clearAndClose)
            .finally(() => setLoading(false))
            .catch(consoleLogInDev);
    }

    function clearAndClose() {
        setAddressOnly(true);
        setBody('');
        closeModal();
    }

    return (
        <SizeableRoundedDialog
            closeButton
            open={modalOpen}
            onClose={clearAndClose}
            style={{ height: small ? 560 : 715, width: small ? '100%' : '80%', maxWidth: 700, paddingBottom: 10 }}
        >
            <PageHeader iconSource={<TadaIcon />} headerText="Email center upcoming shifts" subtitleText={''} />
            <Grid item container style={{ padding: 20, gap: 20 }} justify="center" direction="column">
                <Grid style={{ padding: 20 }} item container justify="space-between" alignContent="center">
                    <Checkbox
                        style={{ width: small ? '100%' : '40%' }}
                        label={'Only notify this location'}
                        checked={addressOnly}
                        onChange={() => setAddressOnly(!addressOnly)}
                    />
                </Grid>
                <OutlinedTextInput
                    multiline
                    placeholder="Additional Body"
                    onChange={(e) => setBody(e.target.value)}
                    value={body}
                />
                <LoadingButton
                    buttonStyle={{ maxWidth: '50%', alignSelf: 'flex-end' }}
                    onClick={emailCenter}
                    loading={loading}
                >
                    Submit
                </LoadingButton>
            </Grid>
        </SizeableRoundedDialog>
    );
}
