import { useJobFormContext } from 'parent-portal/forms/JobFormContext';
import React from 'react';
import JobFormPage from '../JobFormPage';
import RequestedWorkersOnJob from './RequestedWorkersOnJob';
import { Colors, Text } from 'library';

interface ProviderPreferencesPageProps {
    title?: string;
    subtitle?: string;
    nextText?: string;
    submit?: () => void;
    name: string;
}

export default function ProviderPreferencesJobFormPage({
    title,
    subtitle,
    nextText = 'Next',
    name,
}: ProviderPreferencesPageProps) {
    const { goToNext } = useJobFormContext();

    function onNextClicked() {
        goToNext(name);
    }

    return (
        <JobFormPage
            onNextClicked={onNextClicked}
            title={title ?? 'Request Specific Workers'}
            nextText={nextText}
            subtitle={subtitle}
            nextDisabled={false}
            id={name}
        >
            <Text variant="body1" textStyle={{ marginTop: 10, color: Colors.grey }}>
                If the workers you select are not available for the shift, don’t worry. We’ll still try to find you
                someone by opening the shift up to our ever growing pool of compliant workers.
            </Text>
            <RequestedWorkersOnJob />
        </JobFormPage>
    );
}
