import { Grid, useMediaQuery } from '@mui/material';
import React from 'react';
import { Text, Colors } from 'library';
import SubJobArrows from 'assets/images/marketing-site/SubJobArrows.svg';
import TrialRuns from 'assets/images/marketing-site/TrialRuns.svg';
import JobDescription from 'assets/images/marketing-site/JobDescription.png';
import theme from 'theme';

export default function JobTypes() {
    const mdDown = useMediaQuery(theme.breakpoints.down('md'));
    const types = [
        {
            icon: <SubJobArrows height={75} width={75} />,
            title: 'Sub Jobs',
            desc:
                "Pick up single-day, week-long, or ongoing sub jobs when you're available. Choose shifts that match your availability, pay and age group preferences.",
        },
        {
            icon: <TrialRuns height={75} width={75} />,
            title: 'Trial Runs',
            desc:
                "Looking for a long-term job but want to be sure it's the right center first? Find the right fit before you commit. Work through the app before accepting an offer.",
        },
    ];
    return (
        <Grid xs={12} item container flexDirection="column" style={{ backgroundColor: Colors.lightTurq, padding: 40 }}>
            <Text variant="display" textStyle={{ marginBottom: mdDown ? 20 : 0 }}>
                Work only when it's convenient for you.
            </Text>
            <Grid xs={12} item container justifyContent="space-around">
                <Grid xs={12} lg={5} item container justifyContent="center" alignItems="center">
                    <Grid
                        item
                        container
                        style={{
                            padding: 40,
                            boxShadow: '0px 3px 6px #00000029',
                            gap: 60,
                            borderRadius: 18,
                            backgroundColor: Colors.white,
                        }}
                        flexDirection="column"
                    >
                        {types.map((t) => (
                            <Grid item container flexDirection="row" style={{ gap: 20 }}>
                                {t.icon}
                                <Grid xs={12} md={10} item container flexDirection="column" style={{ gap: 10 }}>
                                    <Text variant="h1">{t.title}</Text>
                                    <Text>{t.desc}</Text>
                                </Grid>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
                <Grid xs={12} item container lg={5} justifyContent="center" alignItems="center">
                    <img src={JobDescription} style={{ height: mdDown ? 350 : 500 }} />
                </Grid>
            </Grid>
        </Grid>
    );
}
