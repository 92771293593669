import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { isDev } from 'shared';

const DEEP_LINK_PREFIX = isDev() ? 'exp://127.0.0.1:19000/--/' : 'jointandem:///';

export default function RedirectToAppJobDetails() {
    const { id } = useParams<{ id: string }>();

    useEffect(() => {
        if (id) {
            const linkUrl = `${DEEP_LINK_PREFIX}public/jobs/${id})}`;
            window.location.assign(linkUrl);
        }
    }, [id]);
    return <></>;
}
