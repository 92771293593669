import React, { useState } from 'react';
import { Grid, InputAdornment } from '@material-ui/core';
import { SubJobAppointment } from 'models';
import { OutlinedTextInput, PrimaryButton, Text } from 'library';

import { client, consoleLogInDev } from 'shared';
import { Popover } from '@mui/material';

export default function EditPayModal({
    appt,
    refreshJobs,
    anchorEl,
    open,
    onClose,
}: {
    appt: SubJobAppointment;
    refreshJobs: () => void;
    anchorEl: HTMLElement | null;
    open: boolean;
    onClose: () => void;
}) {
    const pairing = appt.pairings.find((x) => ['ON_STAFF', 'APPROVED'].includes(x.status));
    const [payValue, setPayValue] = useState((pairing?.pay_rate || 0).toString());

    function confirmChanges() {
        const body = { pay: payValue, pairing_id: pairing?.id };
        client(`api/admin-appointment/${appt.id}/`, { method: 'PATCH', body })
            .then(refreshJobs)
            .finally(onClose)
            .catch(consoleLogInDev);
    }

    function updateValues(newValue: string) {
        setPayValue(newValue);
    }

    return (
        <Popover open={open} onClose={onClose} anchorEl={anchorEl}>
            <Grid container item style={{ padding: 20, maxWidth: 500 }}>
                <Text variant="h1">Edit Pay</Text>
                <Grid container item style={{ padding: 20, gap: 20 }}>
                    <Text>Edit pay for appointment {appt.id}</Text>
                    <OutlinedTextInput
                        InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                        value={payValue}
                        style={{ backgroundColor: 'white', padding: 0, borderRadius: 15, maxWidth: '50%' }}
                        placeholder="Pay Rate"
                        onChange={(e) => updateValues(e.target.value)}
                        error={!parseFloat(payValue)}
                        helperText="Pay Rate"
                        type="number"
                    />
                    <PrimaryButton
                        onClick={confirmChanges}
                        disabled={!parseFloat(payValue)}
                        buttonStyle={{ width: '50%' }}
                    >
                        Confirm
                    </PrimaryButton>
                </Grid>
            </Grid>
        </Popover>
    );
}
