import React, { useEffect, useState } from 'react';
import ErrorText from '../../ErrorText';
import Input from 'reusableComponents/input';
import { FormFieldProps } from '../../FormTypes';
import FormField from '../../FormField';

const oneOrMoreNonWhitespaceRegex = /[^\s]+/;

export default function JobTitleInput({
    title,
    business = false,
}: {
    title: FormFieldProps<string>;
    business?: boolean;
}) {
    const { value, setValue, isValid, setValid } = title;
    const [showErrors, setShowErrors] = useState(false);

    useEffect(() => setValid(!!value && oneOrMoreNonWhitespaceRegex.test(value)), [value]);

    return (
        <FormField title={business ? 'Job Title*' : 'Headline*'}>
            <Input
                fullWidth
                style={{ marginBottom: 0 }}
                value={value}
                onChange={onValueChanged}
                multiline
                InputLabelProps={{ shrink: true }}
                id="job-form-headline-input"
                placeholder="Assistant Teacher, Lead Teacher, etc."
            />
            {titleHasError() && <ErrorText>Please enter a {business ? 'title' : 'headline'}</ErrorText>}
        </FormField>
    );

    function titleHasError() {
        return showErrors && !isValid;
    }

    function onValueChanged(event: any) {
        setShowErrors(true);

        const newValue = event.target.value;

        newValue.length <= 50 && setValue(newValue);
    }
}
