import React from 'react';
import { Colors, Text } from 'library';
import { styled } from '@mui/material';

import DateFnsUtils from '@date-io/date-fns';
import { AccessAlarm } from '@material-ui/icons';
import { KeyboardTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

export function SelectTimes({
    workerStart,
    setWorkerStart,
    workerEnd,
    setWorkerEnd,
}: {
    workerStart: Date | null;
    setWorkerStart: (date: Date | null) => void;
    workerEnd: Date | null;
    setWorkerEnd: (date: Date | null) => void;
}) {
    return (
        <div>
            <Text>Select the times the worker worked:</Text>
            <Row>
                <Container>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardTimePicker
                            InputProps={{
                                inputProps: {
                                    style: { color: Colors.darkNavy },
                                },
                            }}
                            autoOk
                            variant="inline"
                            mask="__:__ _M"
                            format="hh:mm a"
                            keyboardIcon={<AccessAlarm />}
                            openTo="hours"
                            views={['hours', 'minutes']}
                            label="Start Time"
                            value={workerStart}
                            onChange={(time: MaterialUiPickersDate | Date) => setWorkerStart(time as Date)}
                            required
                        />
                    </MuiPickersUtilsProvider>
                </Container>
                <Container>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardTimePicker
                            InputProps={{
                                inputProps: {
                                    style: { color: Colors.darkNavy },
                                },
                            }}
                            ampm
                            variant="inline"
                            format="hh:mm a"
                            mask="__:__ _M"
                            keyboardIcon={<AccessAlarm />}
                            openTo="hours"
                            views={['hours', 'minutes']}
                            label="End Time"
                            value={workerEnd}
                            onChange={(time: MaterialUiPickersDate | Date) => setWorkerEnd(time as Date)}
                            required
                        />
                    </MuiPickersUtilsProvider>
                </Container>
            </Row>
            {workerStart && workerEnd && workerStart > workerEnd && (
                <Text variant="body2" textStyle={{ color: Colors.error, paddingLeft: 5, paddingTop: 10 }}>
                    Start time should be before end time
                </Text>
            )}
        </div>
    );
}

const Row = styled('div')({
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingTop: 10,
});

const Container = styled('div')({
    display: 'flex',
    paddingLeft: 5,
    paddingRight: 5,
});
