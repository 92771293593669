import React from 'react';
import { Colors } from '../../theme';
import Text from '../text/Text';
import SecondaryButton from './SecondaryButton';

export default function OptionButton({
    title,
    highlight,
    subtitle,
    icon,
    action,
    id,
    style,
}: {
    title: string;
    highlight: string;
    subtitle?: string;
    icon: React.ReactNode;
    action: () => void;
    id?: string;
    style?: React.CSSProperties;
}) {
    return (
        <SecondaryButton onClick={action} buttonStyle={{ ...styles.button, ...style }}>
            <div style={{ display: 'flex', flexDirection: 'row' }} id={id}>
                {icon}
                <div style={{ marginLeft: 20, textAlign: 'left' }}>
                    <Text variant="h2" bold textStyle={{ marginBottom: 10 }}>
                        {title}
                    </Text>
                    <Text variant="body2">
                        <Text variant="body2" inline bold color={Colors.turquoise}>
                            {highlight}
                        </Text>{' '}
                        {subtitle}
                    </Text>
                </div>
            </div>
        </SecondaryButton>
    );
}

const styles = {
    button: {
        marginTop: 20,
        maxWidth: 450,
        borderRadius: 18,
        height: 120,
        justifyContent: 'flex-start',
    },
};
