import React from 'react';
import { OutlinedTextInput, PrimaryButton, SecondaryButton, Text, Colors } from 'library';
import { Grid } from '@mui/material';
import useTaskForm, { DropdownItem } from './TaskFormFields';
import FormControlledDropdown from './components/FormControlledDropdown';
import BusinessLocationDropdown from './components/BusinessLocationDropdown';
import LabelWrapper from './components/LabelWrapper';
import { isAfter } from 'date-fns';
import { AdminBusinessLocationOption } from 'models/AdminBusinessLocation';
import { TaskListItem } from './matchingInboxModels';

export interface TaskFormOptions {
    inboxes: DropdownItem[];
    types: DropdownItem[];
    default_inbox: DropdownItem | null;
    businessLocation?: AdminBusinessLocationOption;
}

export default function TaskForm({
    inbox,
    options,
    onClose,
    editItem,
}: {
    inbox?: DropdownItem;
    options: TaskFormOptions;
    onClose: () => void;
    editItem?: TaskListItem;
}) {
    const { control, register, errors, submit, reset, trigger, setError, getValues } = useTaskForm(
        inbox?.key || '',
        options.businessLocation,
        editItem,
    );
    const [submittedMessage, setSubmittedMessage] = React.useState<string>();

    async function create(onComplete: () => void) {
        const valid = await trigger();
        if (!valid) {
            if (errors.expires_at) {
                setError('expires_at', { type: 'validate', message: 'Due date must be in the future' });
            }
            return;
        }
        submit()
            .then(onComplete)
            .catch((error) => {
                setError('root.serverError', { message: `An error occurred: ${error}` });
            });
    }

    function onSubmit() {
        setSubmittedMessage(`Successfully created "${getValues().subject}"`);
        setTimeout(() => setSubmittedMessage(undefined), 5000);
        reset();
    }

    function cancel() {
        reset();
        onClose();
    }

    function validDate(value: string | null) {
        return !value || isAfter(new Date(value), new Date());
    }

    return (
        <form onSubmit={submit}>
            <Grid container direction="column">
                <Text variant="h2" bold textStyle={{ marginBottom: 10 }}>
                    Add a Task
                </Text>
                <LabelWrapper label="Task Title*">
                    <OutlinedTextInput label="Task Title" {...register('subject', { required: true })} fullWidth />
                </LabelWrapper>
                {errors.subject && <Text color={Colors.mediumRed}>Task title is required</Text>}
                <LabelWrapper label="Note">
                    <OutlinedTextInput multiline {...register('body')} fullWidth />
                </LabelWrapper>
                <BusinessLocationDropdown
                    control={control}
                    defaultLocations={getValues().business_locations}
                    disabled={!!editItem}
                />
                <Grid>
                    <LabelWrapper label="Due Date">
                        <OutlinedTextInput
                            type="datetime-local"
                            {...register('expires_at', { valueAsDate: true, validate: validDate })}
                        />
                        {errors.expires_at && <Text color={Colors.mediumRed}>{errors.expires_at.message}</Text>}
                    </LabelWrapper>
                    <FormControlledDropdown
                        displayName="Task Type"
                        control={control}
                        fieldName="item_type"
                        fields={options.types}
                    />
                    <FormControlledDropdown
                        displayName="Assigned to"
                        control={control}
                        fieldName="inbox"
                        fields={options.inboxes}
                        required
                    />
                    {errors.inbox && <Text color={Colors.mediumRed}>Inbox is required</Text>}
                </Grid>
                <Text color={Colors.mediumRed}>{errors.root?.message}</Text>
                <Text>{submittedMessage}</Text>
                <Grid container flexDirection="row" justifyContent="flex-end" gap={2} sx={{ marginBottom: 2 }}>
                    <SecondaryButton buttonStyle={{ width: undefined }} onClick={cancel}>
                        Cancel
                    </SecondaryButton>
                    <PrimaryButton onClick={() => create(onClose)} buttonStyle={{ width: undefined }}>
                        {editItem ? 'Update' : 'Create'}
                    </PrimaryButton>
                </Grid>
                {!editItem && (
                    <PrimaryButton rightAlign onClick={() => create(onSubmit)}>
                        Create and add another
                    </PrimaryButton>
                )}
            </Grid>
        </form>
    );
}
