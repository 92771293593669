import React from 'react';
import { Link } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import Colors from '../../../constants/Colors';
import WhiteLogo from '../../../assets/images/WhiteLogo.png';
import usePublicSessionTracking, { PublicSessionEventType } from 'parent-portal/shared/PublicSessionTracking';

interface IProps {
    logoWidth: number;
}

export default function PublicProviderPageHeader({ logoWidth }: IProps) {
    const { trackSession } = usePublicSessionTracking('Public Provider Page Header');

    function trackHomeClick() {
        trackSession('NAVIGATE_FROM', 'To provider home page', PublicSessionEventType.User);
    }

    return (
        <Grid container direction="row" style={{ backgroundColor: Colors.turquoise }}>
            <Link to="/providers/home" onClick={trackHomeClick}>
                <img
                    alt=""
                    src={WhiteLogo}
                    style={{
                        maxWidth: logoWidth ? logoWidth : 175,
                        paddingLeft: 10,
                        marginTop: 5,
                        marginBottom: 8,
                    }}
                />
            </Link>
        </Grid>
    );
}
