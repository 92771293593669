import { Grid } from '@mui/material';
import React from 'react';
import { Colors, Text } from 'library';
import XCalendar from 'assets/images/marketing-site/XCalendar.svg';
import BabyCarriage from 'assets/images/marketing-site/BabyCarriage.svg';
import Families from 'assets/images/marketing-site/Families.svg';
import Suitcase from 'assets/images/marketing-site/Suitcase.svg';
import Frown from 'assets/images/marketing-site/Frown.svg';

export default function LastMinuteSection() {
    const lastMinuteDetails = [
        {
            title: 'Your Staff',
            content:
                'The staff at your center likely will experience burn off if there is consistent call offs and turnover. This creates an unstability in the environment, impacting the quality of their job.',
            emoji: <Suitcase height="40px" width="40px" />,
        },
        {
            title: 'Families',
            content:
                'The families who enroll their children at your center expect to receive the service they are paying for (care for their child). If their child doesn’t have care, they may need to call off work and stay home, or stress to find another solution.',
            emoji: <Families height="40px" width="40px" />,
        },
        {
            title: 'Children',
            content:
                'Children deserve consistent, quality teachers. They are the seedlings of the future. Pre-vetted, compliant Tandem workers are a great option to ensure they continue to receive quality care while your teacher is out.',
            emoji: <BabyCarriage height="40px" width="40px" />,
        },
    ];
    return (
        <Grid xs={12} item container style={{ padding: 40, backgroundColor: Colors.lightTurq }} flexDirection="row">
            <Grid
                xs={12}
                md={6}
                style={{ gap: 20, padding: 40 }}
                item
                container
                flexDirection="column"
                justifyContent="center"
            >
                <Text variant="display">Last minute call offs effect everyone.</Text>
                <Text variant="h2">
                    Find a substitute teacher through Tandem to fill a gap in a classroom, give your teachers an extra
                    hand, or cover an employee's vacation.
                </Text>
            </Grid>
            <Grid
                xs={12}
                md={6}
                style={{
                    borderRadius: 18,
                    boxShadow: '0px 3px 6px #00000029',
                    padding: 40,
                    gap: 20,
                    backgroundColor: Colors.white,
                }}
                item
                container
                flexDirection="column"
            >
                {lastMinuteDetails.map((section, index) => (
                    <Grid key={index} item container flexDirection="column" style={{ gap: 10 }}>
                        {section.emoji}
                        <Text variant="h1">{section.title}</Text>
                        <Text>{section.content}</Text>
                    </Grid>
                ))}
            </Grid>
        </Grid>
    );
}
