import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import theme from '../../../../theme';
import { useUserContext } from '../../../../UserContext';
import HelpIcon from '@material-ui/icons/Help';
import { SimpleDialog } from '../../../../reusableComponents/dialogsSimple';
import { Colors } from '../../../../constants';
import { SurchargeItemProps } from '../../Models/PairingList';

const SurchargeItem = ({
    surchargeAmount,
    defaultPayIsCreditCard,
    isDialogOpen,
    onDialogClose,
    onDialogOpen,
}: SurchargeItemProps) => {
    const { user } = useUserContext();

    const hasCheckingPaymentMethod = () => {
        const hasChecking = (element: any) => element.payment_type !== 'CREDIT_CARD';
        return user?.payment_information?.some(hasChecking) || false;
    };

    const getHowToAvoidSurchargeText = () => {
        let start = 'You can avoid the surcharge by ';
        if (hasCheckingPaymentMethod()) {
            return start + 'changing your default payment method to your bank account.';
        }
        return start + 'connecting a bank account.';
    };

    const getHelpDialogText = () => {
        return (
            <Grid container>
                <Typography style={{ color: theme.palette.primary.main }}>
                    Credit card payments incur a $0.30 + 2.9% fee by our payment processor. Alternatively, bank account
                    payments incur no surcharge.
                </Typography>
                <Typography style={{ color: theme.palette.primary.main }}>{getHowToAvoidSurchargeText()}</Typography>
            </Grid>
        );
    };
    if (!defaultPayIsCreditCard) {
        return <></>;
    }
    return (
        <>
            <SimpleDialog
                isOpen={isDialogOpen}
                dialogTitle={'Why am I experiencing a surcharge?'}
                children={getHelpDialogText()}
                onClose={onDialogClose}
            />
            <Grid container item direction="row" alignItems="center" style={{ paddingBottom: 10 }}>
                <Grid item>
                    <Typography
                        style={{
                            fontSize: 12,
                            color: Colors.mediumGrey,
                        }}
                    >
                        *includes ${surchargeAmount} surcharge
                    </Typography>
                </Grid>
                <Grid item>
                    <HelpIcon onClick={onDialogOpen} style={{ paddingLeft: 5 }} />
                </Grid>
            </Grid>
        </>
    );
};

export default SurchargeItem;
