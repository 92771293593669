import React, { ReactNode } from 'react';

import { useMediaQuery } from '@material-ui/core';
import theme from 'theme';

export default function PageContent({ children }: { children?: ReactNode | ReactNode[] }) {
    const small = useMediaQuery(theme.breakpoints.down('sm'));

    return <div style={{ marginBottom: small ? 15 : 69, flexGrow: 1 }}>{children}</div>;
}
