import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { Colors, Text } from 'library';

const useStyles = makeStyles({
    root: {
        backgroundColor: 'white',
        alignItems: 'center',
        padding: 10,
        cursor: 'pointer',
        transition: 'all .2s ease',
        WebkitTransition: 'all .2s ease',
        MozTransition: 'all .2s ease',
        '&:hover': {
            backgroundColor: Colors.lightGrey,
        },
    },
});

export default function OptionMenuItem({
    icon,
    text,
    action,
    onClose,
}: {
    icon: JSX.Element;
    text: string;
    action: () => void;
    onClose: () => void;
}) {
    const classes = useStyles();

    function handleAction() {
        action();
        onClose();
    }
    return (
        <Grid className={classes.root} container item onClick={handleAction}>
            {icon}
            <Text variant="body2">{text}</Text>
        </Grid>
    );
}
