import React, { useEffect } from 'react';
import FormField from './FormField';
import { FormFieldProps } from './FormTypes';
import { RequestType } from '../JobRequestTypes';
import { RadioGroup, Text } from 'library';
import { JobType } from 'models/JobType';

export default function JobFrequencyInput({
    requestType,
    jobTypeId,
    jobTypes,
}: {
    requestType: FormFieldProps<RequestType>;
    jobTypeId: FormFieldProps<number>;
    jobTypes: JobType[];
}) {
    const { setValid, value, setValue } = requestType;
    const jobTypeName = jobTypes.find((jobType) => jobType.id === jobTypeId.value)?.name ?? 'other';
    useEffect(() => setValid(!!value), [value]);

    return (
        <FormField title="Job Frequency*" style={{ marginTop: 30 }}>
            <RadioGroup
                value={value}
                setValue={(val) => setValue(val as RequestType)}
                values={{ onetime: 'One-time', recurring: 'Weekly' }}
                horizontal
            />
            {jobTypeName === 'House Cleaning' && value === 'recurring' ? (
                <Text variant="caption">
                    One-time house cleaning jobs are 4X more likely to be filled. We recommend forming a relationship
                    with a provider before posting a weekly house cleaning job.
                </Text>
            ) : null}
        </FormField>
    );
}
