import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { IfRender, ModalHeader, PrimaryButton, Text } from 'library';
import { loginUser } from './LoginMethod';
import { UserContext } from '../../UserContext';
import { useHistory } from 'react-router-dom';
import ErrorText from 'parent-portal/forms/components/ErrorText';
import EmailInput from 'parent-portal/public/public-application/InputFields/EmailInput';
import PasswordInput from 'parent-portal/public/public-application/InputFields/PasswordInput';

interface IProps {
    onClose: () => void;
    email?: string;
}
export default function LoginModal({ onClose, email }: IProps) {
    const history = useHistory();

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [isUsernameError, setIsUsernameError] = useState(false);
    const [isPasswordError, setIsPasswordError] = useState(false);
    const [isValidError, setIsValidError] = useState(false);

    useEffect(() => {
        if (isValidError) {
            setIsValidError(false);
        }
    }, [username, password]);
    useEffect(() => {
        setUsername(email || '');
    }, [email]);

    function forgotPassword() {
        history.push('/signin/change/password/verify');
        onClose();
    }

    function handleUsernameChange(e: any) {
        if (isUsernameError) {
            setIsUsernameError(false);
        }
        setUsername(e);
    }

    function handlePasswordChange(e: any) {
        if (isPasswordError) {
            setIsPasswordError(false);
        }
        setPassword(e);
    }

    function handleLogin(context: any) {
        loginUser(
            username,
            password,
            context,
            history,
            setIsUsernameError,
            setIsPasswordError,
            setIsValidError,
            onClose,
        );
    }

    const Form = () => {
        return (
            <UserContext.Consumer>
                {(context) => {
                    const action = () => handleLogin(context);
                    return (
                        <form
                            onSubmit={(e) => {
                                e.preventDefault();
                                action();
                            }}
                        >
                            <ModalHeader onClose={onClose} />
                            {InputFields()}
                            <LoginAction action={action} />
                        </form>
                    );
                }}
            </UserContext.Consumer>
        );
    };

    const InputFields = () => {
        return (
            <Grid container item direction="column" spacing={2} style={{ padding: 15 }}>
                <Grid container item>
                    <EmailInput
                        error={isUsernameError}
                        errorText={'Please enter your email'}
                        label="Email"
                        id="login-email"
                        value={username}
                        onChange={(e: any) => handleUsernameChange(e)}
                    />
                </Grid>
                <Grid container item direction="column">
                    <PasswordInput
                        id="login-password"
                        error={isPasswordError}
                        errorText={
                            'Password must be minimum 8 characters with at least one uppercase letter, one lowercase letter, one digit, and one special character.'
                        }
                        value={password}
                        label="Password"
                        onChange={(e: any) => handlePasswordChange(e)}
                    />
                </Grid>
                <Grid container item>
                    <IfRender condition={isValidError}>
                        <ErrorText>Either your username and password is incorrect</ErrorText>
                    </IfRender>
                </Grid>
            </Grid>
        );
    };

    const LoginAction = ({ action }: { action: () => void }) => {
        return (
            <Grid item style={{ marginLeft: 10 }}>
                <Grid container direction="row" spacing={1} justify="space-between">
                    <Grid item xs={8} sm={12} md={12} lg={4} style={{ height: '2em', marginTop: 5, marginBottom: 15 }}>
                        <PrimaryButton id="login-submit-button" onClick={action}>
                            Login
                        </PrimaryButton>
                    </Grid>
                </Grid>
                <button type="submit" hidden />
                <Text
                    variant="body2"
                    textStyle={{
                        textDecoration: 'underline',
                        cursor: 'pointer',
                        marginBottom: 5,
                        marginTop: 10,
                        justifyContent: 'flex-end',
                    }}
                    onClick={forgotPassword}
                >
                    Forgot password?
                </Text>
            </Grid>
        );
    };

    return Form();
}
