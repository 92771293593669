import React, { useEffect, useState } from 'react';
import { client, consoleLogInDev, isDev } from 'shared';

export default function LocationQRCode({ addressId }: { addressId: string }) {
    const [qrCode, setQrCode] = useState<string>();

    useEffect(() => {
        client(`api/business/qr-code/?addressId=${addressId}`)
            .then((response) => setQrCode(response.data))
            .catch(consoleLogInDev);
    }, [addressId]);

    return qrCode ? <img id={`qrCode${addressId}`} src={`data:image/png;base64, ${qrCode}`} alt="qr code" /> : <></>;
}
