import React from 'react';
import { Text, Colors, Chip } from 'library';
import { Rating, styled, Grid } from '@mui/material';
import { IProviderReview } from 'models';
export default function Review({ review, view = 'worker' }: { review: IProviderReview; view?: 'business' | 'worker' }) {
    return (
        <Container container item direction="column">
            <GapContainer container item alignContent="center">
                <Grid item>
                    <Text bold variant="h2">
                        {view === 'worker'
                            ? review.reviewer.business?.name
                            : `${review.reviewee.first_name} ${review.reviewee.last_name}`}{' '}
                        ({new Date(review.added_at).toLocaleDateString()})
                    </Text>
                </Grid>
                <Rating value={review.total_score} readOnly />
            </GapContainer>
            <Grid container item spacing={1}>
                {review.review_results.map((result) => {
                    return result.value ? (
                        <Grid key={result.id} container item>
                            <Grid item container xs={5}>
                                <Text bold>{result.option.text}</Text>
                            </Grid>
                            <Rating value={result.value} readOnly />
                        </Grid>
                    ) : (
                        <Grid key={result.id} item>
                            <Chip
                                label={result.option.text}
                                textColor={Colors.white}
                                backgroundColor={review.total_score > 2 ? Colors.turquoise : Colors.red}
                            />
                        </Grid>
                    );
                })}
            </Grid>
            {review.text ? (
                <Text key={review.id} variant="body1">
                    {review.text}
                </Text>
            ) : null}
        </Container>
    );
}

const Container = styled(Grid)({
    backgroundColor: Colors.lightTurq,
    padding: 20,
    borderRadius: 18,
    gap: 10,
});

const GapContainer = styled(Grid)({
    gap: 20,
});
