import React from 'react';
import { Grid, withStyles } from '@material-ui/core';
import Text from '../text/Text';
import PrimaryButton from './PrimaryButton';

const GridCell = withStyles({
    root: {
        padding: 5,
    },
})(Grid);

interface IProps {
    content: React.ReactNode;
    copyText: string;
    onButtonClick: () => void;
}

export default function CopyButton({ content, copyText, onButtonClick }: IProps) {
    return (
        <>
            <GridCell item>
                <PrimaryButton onClick={() => onButtonClick()}>{content}</PrimaryButton>
            </GridCell>
            <GridCell item>
                <Text variant="body1">{copyText}</Text>
            </GridCell>
        </>
    );
}
