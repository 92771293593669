import React from 'react';
import { Text } from 'library';
import { BusinessJobResponse } from '../models';
import { Grid } from '@mui/material';
import { format } from 'date-fns';
import { ShiftDate } from './ShiftDate';

export default function ShiftsSection({
    businessJobResponse,
    getJob,
}: {
    businessJobResponse: BusinessJobResponse;
    getJob: () => void;
}) {
    const shiftDates = businessJobResponse.shifts
        .map((slot) => format(new Date(slot.start_date), 'EEEE M/d'))
        .reduce((acc: string[], cur: string) => (acc.includes(cur) ? acc : [...acc, cur]), []);
    return (
        <>
            <Text variant="h1">Shifts</Text>
            <Grid item container direction="column" style={{ gap: 10 }}>
                {shiftDates?.map((date: string) => (
                    <ShiftDate key={date} date={date} businessJobResponse={businessJobResponse} getJob={getJob} />
                ))}
            </Grid>
        </>
    );
}
