import { styled, TableRow } from '@mui/material';
import { Colors } from 'library';

const Row = styled(TableRow)(({ oddRowColor, hoverColor }: { oddRowColor?: string; hoverColor?: string }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: oddRowColor || Colors.lightGrey,
    },
    '& .hover': {
        backgroundColor: hoverColor || Colors.lightGreen,
    },
}));

export default Row;
