import React, { useEffect, useState } from 'react';
import { BasicDialog, Checkbox, Colors, OutlinedTextInput, PrimaryButton, Text, TextArea } from 'library';
import { BusinessJobWithAppointments, LeanUser } from 'models';
import { UpperDaysOfWeek } from 'shared/Dates';
import { dayToJobTimesOfDay, saveJob } from './duplicateJob';
import { client, consoleLogInDev } from 'shared';
import WeeklySchedule from './WeeklySchedule';
import useLoading from 'library/hooks/useLoading';
import SendMatchModal from '../Matching/SendMatchModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';

export default function DuplicateSubJob({
    job,
    open,
    onClose,
    week,
}: {
    job: BusinessJobWithAppointments;
    open: boolean;
    onClose: () => void;
    week?: Date;
}) {
    const [preferredProviders, setPreferredProviders] = useState<LeanUser[]>([]);
    const { loading, setLoading } = useLoading();
    const [headline, setHeadline] = useState(job.headline);
    const [description, setDescription] = useState(job.family_comments);
    const [userId, setUserId] = useState<number>();
    const [provider, setProvider] = useState<LeanUser>();
    const [showMatches, setShowMatches] = useState<number>();
    const [matchEndDate, setMatchEndDate] = useState<string | undefined>();

    const [times, setTimes] = useState(
        Object.keys(UpperDaysOfWeek).map((day) => dayToJobTimesOfDay(job.times_of_day, day)),
    );

    useEffect(() => {
        setPreferredProviders(getProviders());
        setHeadline(job.headline);
        setDescription(job.family_comments);
    }, [job]);

    function addOrRemovePreferredProvider(add: boolean, provider: LeanUser) {
        if (add) {
            setPreferredProviders([provider, ...preferredProviders]);
        } else {
            setPreferredProviders(preferredProviders.filter((pref) => provider.id !== pref.id));
        }
    }

    function getProviders() {
        const hired = job.slots.reduce((allHiredProviders: LeanUser[], currentSlot) => {
            currentSlot.appointments.forEach((appt) => {
                appt.pairings.forEach((pairing) => {
                    if (!allHiredProviders.some((provider) => provider.id === pairing.hired_provider.id)) {
                        allHiredProviders.push(pairing.hired_provider);
                    }
                });
            });
            return allHiredProviders;
        }, []);
        return hired;
    }

    function getProvider() {
        if (userId) client(`api/users/${userId}/`).then(setProvider).catch(consoleLogInDev);
    }

    function save() {
        setLoading(true);
        saveJob(job.id, headline, description, times, week)
            .then((job) => {
                setLoading(false);
                if (preferredProviders.length) {
                    setShowMatches(job.id);
                    setMatchEndDate(job.end_date);
                } else {
                    onClose();
                }
            })
            .catch(consoleLogInDev);
    }

    return showMatches ? (
        <SendMatchModal
            jobId={showMatches}
            open={!!showMatches}
            onClose={() => {
                setShowMatches(undefined);
                onClose();
            }}
            initialProviders={preferredProviders}
            initialEndDate={matchEndDate}
        />
    ) : (
        <BasicDialog closeButton isOpen={open} onClose={onClose}>
            <>
                <TextArea
                    fullWidth
                    label="Headline"
                    style={{ marginTop: 20 }}
                    value={headline}
                    onChange={(e) => setHeadline(e.target.value)}
                />
                <TextArea
                    fullWidth
                    label="Description"
                    style={{ marginTop: 20, marginBottom: 20 }}
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                />
                <WeeklySchedule times={times} updateTimes={setTimes} week={week} />
                <Text textStyle={{ backgroundColor: Colors.lightGreen, padding: 5, borderRadius: 8 }}>
                    Send matches to these providers <FontAwesomeIcon icon={faPaperPlane} />
                </Text>
                {getProviders().map((provider) => (
                    <Checkbox
                        key={provider.id}
                        label={`${provider.first_name} ${provider.last_name} (${provider.id})`}
                        checked={preferredProviders.some((pref) => pref.id === provider.id)}
                        onChange={(checked) => {
                            addOrRemovePreferredProvider(checked, provider);
                        }}
                    />
                ))}
                <OutlinedTextInput
                    label="Provider User Id"
                    value={userId}
                    onChange={(e) => setUserId(parseInt(e.target.value) || undefined)}
                    onBlur={getProvider}
                    style={{ marginTop: 10 }}
                />
                {provider && (
                    <Checkbox
                        key={provider.id}
                        label={`${provider.first_name} ${provider.last_name} (${provider.id})`}
                        checked={preferredProviders.some((pref) => pref.id === provider.id)}
                        onChange={(checked) => {
                            addOrRemovePreferredProvider(checked, provider);
                        }}
                    />
                )}
                <PrimaryButton rightAlign loading={loading} onClick={save}>
                    Save
                </PrimaryButton>
            </>
        </BasicDialog>
    );
}
