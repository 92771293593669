import React, { useEffect, useState } from 'react';
import { Dialog, Grid } from '@material-ui/core';
import { useWidth } from 'reusableComponents/useWidth';
import LoginModal from './LoginModal';
import SignUpForm from 'parent-portal/signup/SignUpForm';
import { useUserContext } from 'UserContext';

interface IProps {
    location?: string;
    open: boolean;
    onClose: () => void;
    email?: string;
    signup?: boolean;
    phoneNumber?: string;
    message?: string;
    onComplete?: () => void;
    redirect?: boolean;
    hideReferral?: boolean;
    hideCompany?: boolean;
    actionMessage?: string;
    extraData?: any;
    subtitle?: string;
    businessSignup?: boolean;
    modal?: boolean;
    businessType?: 'CHILD_CARE' | 'ELDERCARE';
}

export default function AuthModal({
    open,
    onClose,
    email,
    phoneNumber,
    signup,
    message,
    onComplete,
    redirect = true,
    hideReferral,
    hideCompany,
    actionMessage,
    subtitle,
    businessSignup = false,
    modal = false,
    businessType = 'CHILD_CARE',
}: IProps) {
    const breakpoint = useWidth();
    const { user } = useUserContext();
    const [showSignup, setShowSignup] = useState(signup);
    const [showVerificationPage, setShowVerificationPage] = useState(false);

    useEffect(() => {
        if (!!user && user.signup_page < 4) {
            if (user.phone_number && user.address[0].zip_code) {
                setShowVerificationPage(true);
            }
            setShowSignup(true);
        }
    }, [user]);

    return (
        <Dialog
            maxWidth={'lg'}
            open={open}
            onClose={onClose}
            fullScreen={breakpoint === 'xs'}
            PaperProps={{ style: { borderRadius: '18px' } }}
        >
            <Grid
                container
                direction="column"
                justify="center"
                style={{
                    minWidth: 370,
                    maxWidth: 600,
                    padding: 30,
                    alignSelf: 'center',
                }}
            >
                {showSignup ? (
                    <SignUpForm
                        onClose={onClose}
                        existingEmail={email}
                        existingPhoneNumber={phoneNumber ?? user?.phone_number}
                        message={message}
                        onComplete={onComplete}
                        redirect={redirect}
                        hideReferral={hideReferral}
                        hideCompany={hideCompany}
                        actionMessage={actionMessage}
                        subtitle={subtitle}
                        businessSignup={businessSignup}
                        modal={modal}
                        skipToPhone={showVerificationPage}
                        businessType={businessType}
                    />
                ) : (
                    <LoginModal onClose={onClose} email={email} />
                )}
            </Grid>
        </Dialog>
    );
}
