import { client } from 'shared';
import { TimeChange } from './jobChangeModels';

export async function getExistingJobChangeRequests(jobId: number, provider_user_id: number) {
    const result = await client(`api/job-change-request/?job=${jobId}&provider_user_id=${provider_user_id}`);
    if (result.length > 0) {
        return result[0];
    }
    return undefined;
}

export async function submitChanges(body: { [key: string]: boolean | number | TimeChange[] | string | number[] }) {
    const result = client('api/job-change-request/', {
        body: body,
    });
    return result;
}
