import React from 'react';
import { Colors } from 'library';
import { formatMonthDay, getThisWeeksMonday } from 'shared/Dates';
import { addDays } from 'date-fns';
import StatusChip from './StatusChip';

export default function JobThisWeekChip() {
    const thisWeeksMonday = getThisWeeksMonday();
    const thisWeekLabel = `${formatMonthDay(thisWeeksMonday)} - ${formatMonthDay(addDays(thisWeeksMonday, 6))}`;

    return <StatusChip text="Jobs This Week" color={Colors.turquoise} title={thisWeekLabel} />;
}
