import React, { useState } from 'react';
import ImageUploader from 'react-images-upload';
import { Grid } from '@material-ui/core';
import LoadingButton from '../../reusableComponents/loadingButton';
import { BasicDialog, PrimaryButton, Colors, Text } from 'library';
import { client } from 'shared';
import { Environment } from 'environmentVariables';

function PictureDialog(props) {
    const [loading, setLoading] = useState(false);
    const [confirmed, setConfirmed] = useState(false);
    const [error, setError] = useState(false);

    const uploadPhoto = async (image) => {
        setLoading(true);
        var form_data = new FormData();
        form_data.append('image', image[0], 'profile-pic.jpeg');
        form_data.append('title', String(props.userId) + '-profile-pic');
        form_data.append('content', 'Profile Picture');
        form_data.append('user', props.userId);
        try {
            const response = await client(
                'upload-picture/',
                {
                    body: form_data,
                },
                false,
            );

            setConfirmed(true);
            setLoading(false);
            props.onPhotoChange(response.image);
        } catch (error) {
            setError(true);
            if (Environment != 'production') {
                console.log(error);
            }
        }
    };

    return (
        <BasicDialog
            isOpen={props.open}
            onClose={() => {
                setConfirmed(false);
                setError(false);
                props.onClose();
            }}
            dialogTitle={'Update Profile Picture'}
            closeButton
            children={
                <>
                    <Grid container direction="column">
                        <Text variant="body1">{'Max file size: 5MB'}</Text>
                        <Text variant="body1">{'Accepted file type: JPEG or PNG'}</Text>
                    </Grid>
                    {loading && (
                        <LoadingButton
                            buttonStyle={{ width: 200, marginTop: 10 }}
                            loading={true}
                            type="submit"
                            variant="contained"
                            disableRipple={true}
                        >
                            Uploading...
                        </LoadingButton>
                    )}
                    {confirmed && (
                        <PrimaryButton disabled buttonStyle={{ width: 200, marginTop: 10 }}>
                            Image Uploaded
                        </PrimaryButton>
                    )}
                    {error && (
                        <PrimaryButton disabled type="submit" buttonStyle={{ width: 200, marginTop: 10 }}>
                            Error Uploading Image
                        </PrimaryButton>
                    )}
                    {!loading && !confirmed && !error && (
                        <ImageUploader
                            label={''}
                            withIcon={false}
                            withPreview={false}
                            buttonText={
                                <Text variant="body1" bold color={Colors.white}>
                                    Upload
                                </Text>
                            }
                            onChange={uploadPhoto}
                            imgExtension={['.jpg', '.png', '.jpeg']}
                            maxFileSize={5242880}
                            singleImage
                            buttonStyles={{
                                backgroundColor: Colors.darkNavy,
                                paddingTop: 10,
                                paddingBottom: 10,
                                width: 200,
                            }}
                            fileContainerStyle={{
                                margin: 0,
                                padding: 0,
                                boxShadow: 'none',
                                alignItems: 'flex-start',
                            }}
                        />
                    )}
                </>
            }
        />
    );
}

export default PictureDialog;
