import React from 'react';
import { Grid } from '@material-ui/core';
import { Text } from 'library';
import ProviderBullets from './ProviderBullets';
import Blob from 'assets/images/on-demand/teal-blob.png';
const IMAGE = require('../../../assets/images/public-hub/biz-partial-signup.jpeg');
export default function HardWork({ smDown }: { smDown: boolean }) {
    return (
        <Grid container direction="row">
            <div
                style={{
                    width: '50%',
                    padding: smDown ? 20 : 40,
                    paddingRight: smDown ? 20 : 0,
                }}
            >
                <img
                    src={Blob}
                    alt=""
                    width={smDown ? '90%' : '55%'}
                    style={{ marginLeft: 80, marginTop: smDown ? 15 : 0 }}
                />
                <img
                    src={IMAGE}
                    alt=""
                    width={smDown ? '95%' : '48%'}
                    style={{ borderRadius: '50%', marginTop: '-50%' }}
                />
            </div>
            <div
                style={{
                    width: smDown ? '100%' : '50%',
                    padding: smDown ? 20 : 40,
                    paddingRight: smDown ? 20 : 100,
                    marginTop: 20,
                }}
            >
                <Text variant="display">
                    Child care is hard work. We created Tandem to make it just a little bit easier.
                </Text>

                <Text variant={smDown ? 'body1' : 'h2'} textStyle={{ marginTop: smDown ? 10 : 30, marginBottom: 20 }}>
                    Become a compliant worker in your state of residence without the hassle. We'll verify your
                    credentials or help you get started if you're new to child care, for free.
                </Text>
                <ProviderBullets smDown={smDown} />
            </div>
        </Grid>
    );
}
