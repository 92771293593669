import React from 'react';
import { Text, Colors } from 'library';
import { useProviderProfileContext } from '../../ProviderProfileContext';

export default function ProviderResumeSection() {
    const { provider } = useProviderProfileContext();

    if (!provider.user.resume) return null;

    return (
        <div style={{ borderRadius: 25, backgroundColor: Colors.lightTurq }}>
            <div style={{ padding: 20 }}>
                <Text color={Colors.turquoise} variant="body1" bold textStyle={{ letterSpacing: 3.2 }}>
                    RESUME
                </Text>
                <a
                    href={provider.user.resume?.document}
                    target="_blank"
                    rel="noreferrer"
                    download
                    style={{ textDecoration: 'underline' }}
                >
                    <Text variant="body1" bold>
                        {provider.user.first_name} {provider.user.last_name}&apos;s Resume
                    </Text>
                </a>
            </div>
        </div>
    );
}
