import React from 'react';
import { Colors } from 'library';
import { styled } from '@mui/material';
import ProviderSearchAndFilter from './ProvidersSearchAndFilter';
import usePublicSessionTracking from 'parent-portal/shared/PublicSessionTracking';

export default function Providers() {
    const { usePageLoadTracking } = usePublicSessionTracking('Providers');

    usePageLoadTracking();

    return (
        <Container>
            <ProviderSearchAndFilter />
        </Container>
    );
}

const Container = styled('div')({
    backgroundColor: Colors.white,
    height: '100%',
});
