import { useClient } from 'shared';

import type { ExtendedOptions } from 'shared/commonHooks/useClient';

export function useCategories() {
    return useClient<{ categories: { name: string; value: string; require_comment: boolean }[] }>({
        url: 'internal/revert-no-show-reason/categories/',
        method: 'GET',
    });
}

export function useCreate({
    revertData,
    options,
}: {
    revertData: { category: string; comment: string; point_history_id: number | null };
    options: ExtendedOptions;
}) {
    return useClient<{ message: string } | null>({
        url: 'internal/revert-no-show-reason/',
        method: 'POST',
        body: revertData,
        options,
    });
}
