import React, { useState, useEffect } from 'react';
import { Colors, Text } from 'library';
import { useJobFormContext } from 'parent-portal/forms/JobFormContext';
import { Alert, styled } from '@mui/material';
import { format12HourTime, formatLongMonthDay } from 'shared/Dates';
import SectionContainer from './SectionContainer';
import EditSchedulesModal from './EditSchedulesModal';
import DayCircle from './DayCircle';
import { DayOfWeek, JobTimes } from 'parent-portal/forms/JobRequestTypes';
import { makeStyles } from '@material-ui/core/styles';
import { CheckCircle, Error } from '@mui/icons-material';
import { DAYS } from 'parent-portal/forms/components/pages/DateAndTimePage/BusinessDatesAndTimes';

const useStyles = makeStyles(() => ({
    message: {
        flexGrow: 1,
    },
}));

export default function JobSchedulesSection() {
    const classes = useStyles();
    const { form, setDatesConfirmed, datesConfirmed } = useJobFormContext();
    const {
        daysOfTheWeek,
        startDate,
        endDate,
        breakRequired,
        headline,
        businessJobType,
        jobTimes,
        sameTimes,
        templateJobId,
    } = form.fieldStates;
    const [editOpen, setEditOpen] = useState(false);
    const trialRun = businessJobType.value === 'TRIAL_RUN';
    const isSingleDay = startDate.value?.isSame(endDate.value, 'day');

    useEffect(() => {
        if (!templateJobId.value) {
            setDatesConfirmed(true);
        }
        if (editOpen) {
            setDatesConfirmed(true);
        }
    }, [editOpen, setDatesConfirmed, templateJobId.value]);

    function getTime(time: DayOfWeek | JobTimes, startOrEnd: 'start' | 'end') {
        // eslint-disable-next-line security/detect-object-injection
        const timeToFormat = time[startOrEnd]?.toDate() ?? new Date();
        return format12HourTime(timeToFormat);
    }

    function getSchedule(time: DayOfWeek | JobTimes) {
        const dayOfWeek = time.day ?? startDate.value?.toDate().getDay();
        const timeToDisplay = sameTimes.value ? jobTimes.value : time;
        return (
            <ScheduleContainer key={time.day}>
                {dayOfWeek && <DayCircle day={dayOfWeek} />}
                <Text variant="body2">
                    {getTime(timeToDisplay, 'start')} - {getTime(timeToDisplay, 'end')} ({timeToDisplay.slots} worker
                    {timeToDisplay.slots > 1 ? 's' : ''})
                </Text>
            </ScheduleContainer>
        );
    }

    function getDateText() {
        if (!startDate.value) return '';
        if (trialRun) {
            return `Starting as soon as ${formatLongMonthDay(startDate.value.toDate())}`;
        }
        let text = formatLongMonthDay(startDate.value.toDate());
        if (!isSingleDay && endDate.value) {
            text = `${text} - ${formatLongMonthDay(endDate.value.toDate())}`;
        }
        return text;
    }
    return (
        <StyledAlert
            severity={datesConfirmed ? 'success' : 'error'}
            icon={
                datesConfirmed ? <CheckCircle htmlColor={Colors.turquoise} /> : <Error htmlColor={Colors.mediumRed} />
            }
            variant="outlined"
            classes={{ message: classes.message }}
            datesConfirmed={datesConfirmed}
        >
            <Text color={datesConfirmed ? Colors.darkNavy : Colors.mediumRed}>
                Please review the following details before submitting your job post.
            </Text>
            <SectionContainer
                editModal={
                    <EditSchedulesModal open={editOpen} onClose={() => setEditOpen(false)} headline={headline.value} />
                }
                setEditOpen={setEditOpen}
            >
                <Text variant="h2" bold>
                    {getDateText()}{' '}
                    {breakRequired.value && (
                        <Text variant="caption" inline>
                            {' '}
                            *Break required
                        </Text>
                    )}
                </Text>

                <SchedulesContainer>
                    <div>
                        {Object.entries(daysOfTheWeek.value)
                            .map(([day, values]) => ({ day: DAYS.indexOf(day), ...values }))
                            .filter((day) => day.selected)
                            .map((time) => getSchedule(time))}
                    </div>
                </SchedulesContainer>
            </SectionContainer>
        </StyledAlert>
    );
}

const SchedulesContainer = styled('div')({
    gap: 4,
    marginTop: 12,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
});

const ScheduleContainer = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    gap: 10,
    marginTop: 8,
});

const StyledAlert = styled(Alert)(({ datesConfirmed }: { datesConfirmed: boolean }) => ({
    borderRadius: 18,
    paddingRight: 0,
    borderColor: datesConfirmed ? Colors.turquoise : Colors.mediumRed,
}));
