import React, { ReactNode } from 'react';
import { Paper, Text, Colors } from 'library';
import { Grid, useMediaQuery } from '@mui/material';
import ArrowUp from 'assets/images/marketing-site/trial-runs/arrow-up.svg';
import ArrowDown from 'assets/images/marketing-site/trial-runs/arrow-down.svg';
import theme from 'theme';

const bulletPoints = [
    {
        icon: <ArrowUp height={50} />,
        title: 'Invest more time assessing fit',
        description:
            'Spend your allocated hiring time determining if your Tandem provider is the right fit. During the first 120 hours, you should determine if the worker would be a good long term, permanent fit at your center.',
    },
    {
        icon: <ArrowDown height={50} />,
        title: 'Put less time into qualifiying candidates',
        description:
            'Qualifiying and interviewing candidates is tedious and your return is not guaranteed. Spend less time going through extensive hiring processes and utilize Tandem’s Trial Run to focus exclusively on a worker’s mutual fit at your center.',
    },
];

export default function AllowsYouTo() {
    const smDown = useMediaQuery(theme.breakpoints.down('sm'));
    const textStyle = {
        marginTop: 50,
        marginLeft: smDown ? 10 : 0,
    };
    function Circle(icon: ReactNode) {
        return (
            <Grid container item xs={2} alignSelf="center" justifySelf="center">
                {icon}
            </Grid>
        );
    }

    return (
        <Grid
            container
            item
            justifyContent="center"
            style={{ paddingBottom: 100, backgroundColor: Colors.lightTurq, paddingTop: 50, marginTop: 30 }}
            direction="column"
        >
            <Grid container item justifyContent="center">
                <Text variant="display" textStyle={textStyle}>
                    A Trial Run allows you to…
                </Text>
            </Grid>
            <Grid container item justifyContent="center">
                <Paper
                    style={{
                        backgroundColor: Colors.white,
                        margin: smDown ? 15 : 80,
                        width: smDown ? undefined : '55vw',
                        borderRadius: smDown ? 54 : 71,
                        padding: smDown ? 10 : 40,
                    }}
                >
                    <Grid container item style={{ padding: smDown ? 10 : 40, paddingBottom: 60 }}>
                        {bulletPoints.map((point, index) => (
                            <Grid
                                container
                                item
                                key={index}
                                alignContent="center"
                                justifyContent="center"
                                style={{ marginTop: 20, paddingLeft: 20, paddingRight: 20, paddingTop: 10 }}
                            >
                                {Circle(point.icon)}
                                <Grid container item xs={10} style={{ paddingLeft: smDown ? 10 : 0 }}>
                                    <Text variant="h2" bold>
                                        {point.title}
                                    </Text>
                                    <Text variant="body2" textStyle={{ marginTop: 8 }}>
                                        {point.description}
                                    </Text>
                                </Grid>
                            </Grid>
                        ))}
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
    );
}
