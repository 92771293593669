import React, { useState } from 'react';
import { Grid } from '@mui/material';
import usePublicSessionTracking from 'parent-portal/shared/PublicSessionTracking';
import useSignUpAction from 'parent-portal/login/SignUpAction';
import { HomeHeader } from './components/HomeHeader';
import { ExclusivePlatform } from './components/ExclusivePlatform';
import { ThreeStepSolution } from './components/ThreeStepSolution';
import { StaffingTool } from './components/StaffingTool';
import { BothStaff } from './components/BothStaff';
import { WhyWorkersLikeUs } from './components/WhyWorkersLikeUs';
import Payments from './components/Payments';
import PreSignUp from 'parent-portal/signup/PreSignUp/PreSignUp';
import useTrackingCookies from 'parent-portal/shared/EngagementSourceTracking';

export default function Home() {
    useTrackingCookies('/');
    const { usePageLoadTracking } = usePublicSessionTracking('home');
    const { SignUpWrapper } = useSignUpAction(undefined);
    const [open, setOpen] = useState(false);
    usePageLoadTracking();

    function signUp() {
        setOpen(true);
    }

    return (
        <SignUpWrapper businessSignup={true}>
            <Grid container item style={{ gap: 20, marginBottom: 40 }}>
                <HomeHeader signUp={signUp} />
                <ExclusivePlatform />
                <ThreeStepSolution signUp={signUp} />
                <StaffingTool />
                <BothStaff />
                <Payments />
                <WhyWorkersLikeUs />
                <PreSignUp
                    open={open}
                    smallerVersion={false}
                    onClose={() => setOpen(false)}
                    headerText={'How will you use Tandem?'}
                    showBusinessOption={true}
                    showProviderOption={true}
                />
            </Grid>
        </SignUpWrapper>
    );
}
