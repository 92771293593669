import React from 'react';
import { useProviderProfileContext } from '../ProviderProfileContext';
import SaveProviderButton from 'parent-portal/providers/cards/iconActions/SaveProviderButton';
import { useUserContext } from 'UserContext';

export default function ProviderProfileActions({ mobile }: { mobile: boolean }) {
    const { provider } = useProviderProfileContext();
    const { role } = useUserContext();

    if (role === 'business_active') {
        return <></>;
    }

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                minHeight: 42,
                marginTop: 8,
                maxWidth: 160,
            }}
        >
            <SaveProviderButton provider={provider} shadow />
        </div>
    );
}
