import React from 'react';
import { Link } from 'react-router-dom';
import { Grid } from '@mui/material';
import { useUserContext } from 'UserContext';
import { IDashboardData } from '../Models/DashboardData';
import DashboardSection from '../components/DashboardSection';
import BusinessLocationCard from 'parent-portal/BusinessLocation/BusinessLocationCard';
import { IBusinessLocation } from 'models';
import { useStyles } from './useStyles';

export default function DayCareCenterList({ data }: { data: IDashboardData | undefined }) {
    const classes = useStyles();
    const { getDisplayText } = useUserContext();
    const businessLocations = data?.business_locations?.centers;
    const businessFaqQuestions = data?.business_faq_questions || [];

    return (
        <DashboardSection
            header={getDisplayText('centers').capitalize()}
            description={`These are your ${getDisplayText(
                'center',
            )} locations, view all and add additional locations on the profile.`}
        >
            <Grid>
                {businessLocations?.map((location: IBusinessLocation) => (
                    <BusinessLocationCard
                        businessLocation={location}
                        key={location.id}
                        faqQuestions={businessFaqQuestions}
                    />
                ))}
            </Grid>
            <Grid container justifyContent="center">
                <Link to="profile" className={classes.link}>
                    View All
                </Link>
            </Grid>
        </DashboardSection>
    );
}
