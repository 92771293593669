import React, { useEffect, useState } from 'react';
import { Colors, PrimaryButton, SecondaryButton, Text, UnderlinedTextInput } from 'library';
import { Grid } from '@material-ui/core';
import { client, consoleLogInDev } from 'shared';

const INVALID_CODE_ERR = 'The code you entered is incorrect.';
const CODE_REQUEST_ERR = 'There was an error sending the verification code. Please try again later.';

export default function PhoneNumberVerification({
    phoneNumber,
    handleSuccess,
}: {
    phoneNumber: string;
    handleSuccess: (token: string) => void;
}) {
    const [code, setCode] = useState('');
    const [buttonDisabled, setButtonDisabled] = useState(true);
    const [invalidCode, setInvalidCode] = useState(false);
    const [attempts, setAttempts] = useState(0);
    const [errorText, setErrorText] = useState('');
    const [display, setDisplay] = useState('');

    useEffect(() => {
        let cleaned = ('' + phoneNumber).replace(/\D/g, '');
        let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            setDisplay(['(', match[2], ') ', match[3], '-', match[4]].join(''));
        }
        sendCode();
    }, []);

    useEffect(() => {
        if (code.length === 4 && !invalidCode && attempts < 6) {
            setButtonDisabled(false);
        } else {
            setButtonDisabled(true);
        }
    }, [code, invalidCode, attempts]);

    function verifyCode() {
        setAttempts(attempts + 1);
        setErrorText('');
        client(`check-verification-code/?code=${code}&receiver=${display.replace(/\D/g, '')}`, { method: 'POST' })
            .then((resp) => {
                if (resp.token) localStorage.setItem('token', resp.token);
                handleSuccess(resp.token);
            })
            .catch((err) => {
                consoleLogInDev(err);
                setInvalidCode(true);
                setErrorText(INVALID_CODE_ERR);
                resetValues();
            });
    }

    function sendCode() {
        setAttempts(attempts + 1);
        setErrorText('');
        if (attempts < 5) {
            client(`send-verification-text/?receiver=${phoneNumber}`, {
                method: 'POST',
            })
                .then(() => setErrorText(''))
                .catch((err) => {
                    consoleLogInDev(err);
                    setErrorText(CODE_REQUEST_ERR);
                    resetValues();
                });
        }
    }

    const resetValues = () => {
        setButtonDisabled(true);
        setInvalidCode(false);
        setCode('');
    };

    return (
        <Grid>
            <Text variant="body2">
                Please enter the verification code sent to {display} to finish setting up your Tandem account.
            </Text>
            {attempts > 5 ? (
                <Text variant="body2" color={Colors.mediumRed} textStyle={{ paddingTop: 24 }}>
                    Maximum attempts exceeded. Please contact support@join-tandem.com for help.
                </Text>
            ) : (
                <UnderlinedTextInput
                    id="code"
                    label=""
                    value={code}
                    onChange={setCode}
                    error={errorText !== ''}
                    errorText={errorText}
                    maxLength={4}
                />
            )}
            <Grid container direction="row" spacing={2} style={{ paddingTop: 24 }}>
                <Grid container item xs={12} md={6}>
                    <SecondaryButton disabled={attempts > 5} id="sign-up-resend-code-button" onClick={() => sendCode()}>
                        Resend Code
                    </SecondaryButton>
                </Grid>

                <Grid container item xs={12} md={6}>
                    <PrimaryButton
                        id="signup-finish-button"
                        disabled={buttonDisabled}
                        onClick={() => {
                            verifyCode();
                        }}
                    >
                        Finish
                    </PrimaryButton>
                </Grid>
            </Grid>
        </Grid>
    );
}
