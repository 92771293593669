import React from 'react';
import { Text } from 'library';
import theme from 'theme';
import { Radio, RadioGroup, FormControlLabel, Grid, useMediaQuery } from '@material-ui/core';
import { CSSProperties } from '@material-ui/styles';

export default function KeepJobOpen({
    keepJobOpen,
    setKeepJobOpen,
    style,
}: {
    keepJobOpen: 'Yes' | 'No';
    setKeepJobOpen: (s: 'Yes' | 'No') => void;
    style?: CSSProperties;
}) {
    const smDown = useMediaQuery(theme.breakpoints.down('sm'));
    return (
        <Grid container style={{ paddingRight: 20, marginTop: 10, paddingLeft: smDown ? 20 : 40, ...style }}>
            <Grid item xs={12}>
                <Text variant="body1" bold>
                    Do you want to keep this job open to hire additional staff members?
                </Text>
            </Grid>
            <Grid item xs={12}>
                <RadioGroup
                    aria-label="position"
                    name="position"
                    defaultValue="top"
                    value={keepJobOpen}
                    onChange={(e) => {
                        setKeepJobOpen(e.target.value as 'Yes' | 'No');
                    }}
                    style={styles.radioGroup}
                >
                    <FormControlLabel
                        value="Yes"
                        control={<Radio color="primary" />}
                        label={<Text variant="body1">Yes, keep job open</Text>}
                    />
                    <FormControlLabel
                        value="No"
                        control={<Radio color="primary" />}
                        label={<Text variant="body1">No, close job</Text>}
                    />
                </RadioGroup>
            </Grid>
        </Grid>
    );
}
const styles = {
    radioGroup: {
        width: '100%',
        marginTop: 10,
    },
};
