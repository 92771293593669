import React, { useState, useEffect } from 'react';
import { Contacts } from '@mui/icons-material';
import { BasicDialog, Colors, IconButton, OutlinedTextInput, PrimaryButton, Text } from 'library';
import StaffInfoModal from './StaffInfoModal';
import { client, consoleLogInDev, isNumber } from 'shared';
import { PagingResult, Staff, IBusinessLocation } from 'models';
import { Grid } from '@mui/material';

export default function StaffInfo({ userId }: { userId: number }) {
    const [open, setOpen] = useState(false);
    const [staff, setStaff] = useState<PagingResult<Staff>>();
    const [searchInput, setSearchInput] = useState('');
    const [pageNumber, setPageNumber] = useState(1);
    const [businessLocation, setBusinessLocation] = useState<IBusinessLocation>();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isSearching, setIsSearching] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const getStaffInfo = () => {
        if (open && userId) {
            client(`api/staff/?page=${pageNumber}&user_id=${userId}`)
                .then(setStaff)
                .catch(consoleLogInDev)
                .finally(() => {
                    setIsSubmitting(false);
                });
        }

        if (!open) {
            setPageNumber(1);
            setSearchInput('');
            setErrorMessage('');
            setBusinessLocation(undefined);
        }
    };

    useEffect(getStaffInfo, [userId, open, pageNumber]);

    const onAddClick = () => {
        if (isSubmitting) {
            return;
        }

        const isANumber = isNumber(searchInput);
        const bizId = isANumber && !businessLocation ? parseInt(searchInput) : businessLocation?.id;
        if (bizId) {
            setIsSubmitting(true);
            setErrorMessage('');
            client(`api/staff/submit-bci/`, {
                body: {
                    user_id: userId,
                    business_location_id: bizId,
                },
            })
                .then((response) => {
                    if (pageNumber !== 1) {
                        setPageNumber(1);
                    } else {
                        getStaffInfo();
                    }
                    setSearchInput('');
                    setBusinessLocation(undefined);
                })
                .catch((error) => {
                    consoleLogInDev(error);
                    setIsSubmitting(false);
                    setErrorMessage('Error creating staff record.');
                });
        }
    };

    const onSearchClick = () => {
        if (searchInput) {
            setBusinessLocation(undefined);
            setIsSearching(true);
            client(`api/business-location/?name=${encodeURIComponent(searchInput)}`)
                .then((response: PagingResult<IBusinessLocation>) => {
                    if (response.count) {
                        setBusinessLocation(response.results[0]);
                    }
                })
                .catch((error) => {
                    consoleLogInDev(error);
                    setIsSubmitting(false);
                })
                .finally(() => {
                    setIsSearching(false);
                });
        }
    };

    const onPageChange = (newPage: number) => {
        setPageNumber(newPage);
    };

    const onDelete = (id: number) => {
        if (isSubmitting) {
            return;
        }

        setIsSubmitting(true);
        client(`api/staff/${id}/`, {
            method: 'DELETE',
        })
            .then((response) => {
                if (pageNumber !== 1) {
                    setPageNumber(1);
                } else {
                    getStaffInfo();
                }
            })
            .catch((error) => {
                consoleLogInDev(error);
                setIsSubmitting(false);
            });
    };

    return (
        <>
            <span style={{ marginLeft: 10 }}>
                <IconButton
                    tooltip="View/Edit On Staff Centers"
                    icon={Contacts}
                    onClick={() => setOpen(true)}
                ></IconButton>
            </span>
            <BasicDialog
                dialogTitle={`On Staff`}
                closeButton
                onClose={() => {
                    setOpen(false);
                }}
                isOpen={open}
            >
                <Grid container>
                    <Grid item sm={6}>
                        <OutlinedTextInput
                            label="Business Location Id or Start of Name"
                            value={searchInput}
                            onChange={(e) => setSearchInput(e.target.value)}
                        />
                        <Text variant="body2">
                            Lookup will only work for names. If you know the ids you can hit submit
                        </Text>
                    </Grid>
                    <Grid item sm={6}>
                        <PrimaryButton disabled={isSearching} loading={isSearching} onClick={onSearchClick}>
                            Lookup Name
                        </PrimaryButton>
                    </Grid>
                    <Grid item sm={12}>
                        {businessLocation && (
                            <Text variant="body2">
                                First matching business location found is {businessLocation?.name} with id of{' '}
                                {businessLocation.id}
                            </Text>
                        )}
                        <PrimaryButton disabled={isSubmitting} loading={isSubmitting} onClick={onAddClick}>
                            Submit BCI
                        </PrimaryButton>
                        <Text variant="body2" textStyle={{ color: Colors.error }}>
                            {errorMessage}
                        </Text>
                    </Grid>
                </Grid>
                <StaffInfoModal staff={staff} pageNumber={pageNumber} onPageChange={onPageChange} onDelete={onDelete} />
            </BasicDialog>
        </>
    );
}
