import { faAdd } from '@fortawesome/free-solid-svg-icons';
import AdminButton from 'internal/shared/AdminButton';
import { Colors } from 'library';
import { BusinessJobWithUser, BusinessJobWithAppointments } from 'models';
import React, { useState } from 'react';
import HireProviderModal from './HireProviderModal';
import { DailyBusinessApplicantJob } from 'internal/applicants/ApplicantModels';

export default function AddProvider({
    job,
    refreshJobs,
    initialProviderUserId,
    btnStyles,
}: {
    job: BusinessJobWithAppointments | BusinessJobWithUser | DailyBusinessApplicantJob;
    refreshJobs?: () => void;
    initialProviderUserId?: number;
    btnStyles?: any;
}) {
    const [addProviderModal, setAddProviderModal] = useState(false);
    return (
        <>
            <AdminButton
                text="Add Provider"
                color={Colors.mediumTurq}
                onClick={() => setAddProviderModal(true)}
                FontAwesomeImg={faAdd}
                btnStyles={btnStyles}
            />
            <HireProviderModal
                closeModal={() => setAddProviderModal(false)}
                modalOpen={addProviderModal}
                refreshJobs={refreshJobs}
                job={job}
                initialProviderUserId={initialProviderUserId}
            />
        </>
    );
}
