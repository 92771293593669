import { styled } from '@mui/material';
import { ImgContainer } from './Containers';
import DefaultProfileImage from '../../../assets/icons/Profile.svg';
import React from 'react';

const ProfileImage = ({ image, imageId }: { image?: string; imageId: string }) => {
    const Img = styled('img')({
        width: 125,
        height: 125,
        borderRadius: 100,
    });

    if (!image) {
        return (
            <ImgContainer>
                <DefaultProfileImage
                    style={{
                        width: 125,
                        height: 125,
                        borderRadius: 100,
                    }}
                />
            </ImgContainer>
        );
    }

    return (
        <ImgContainer>
            <Img alt="profile" src={image} id={imageId} />
        </ImgContainer>
    );
};

export default ProfileImage;
