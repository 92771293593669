import React from 'react';
import { Add, Cake, Check, Favorite } from '@material-ui/icons';
import { useProviderProfileContext } from '../../ProviderProfileContext';
import { Colors, Text } from 'library';
import ProviderProfileDetailRow from '../../ProviderProfileDetailRow';

export default function ProviderProfileChildCare() {
    const { provider } = useProviderProfileContext();
    const sortedAges = ['0-1', '2-5', '6-9', '10+'];

    const DetailText = ({ bold, children }: { bold?: boolean; children: string | string[] }) => (
        <Text variant="body2" bold={bold}>
            {children}
        </Text>
    );

    const details = [
        {
            detail: (
                <DetailText>
                    {provider.number_of_years_of_experience?.toString() || ''} years child care experience
                </DetailText>
            ),
            icon: <Favorite style={{ fontSize: 14 }} />,
            show: !!provider.number_of_years_of_experience,
            style: { marginBottom: 16 },
        },
        {
            detail: (
                <DetailText>
                    {provider.job_count.toString()} completed job{provider.job_count > 1 ? 's' : ''}
                </DetailText>
            ),
            icon: <Check style={{ fontSize: 16 }} />,
            show: provider.job_count > 0,
        },
        {
            detail: (
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <DetailText>Ages worked with: </DetailText>
                    {sortedAges.map((age) => {
                        return provider.age_group_experience?.includes(age) ? (
                            <div
                                style={{
                                    padding: 5,
                                    borderRadius: 25,
                                    border: `solid ${Colors.darkNavy}`,
                                    borderWidth: '1px',
                                    minWidth: 40,
                                    margin: 4,
                                    textAlign: 'center',
                                }}
                            >
                                <DetailText>{age}</DetailText>
                            </div>
                        ) : null;
                    })}
                </div>
            ),
            icon: <Cake style={{ fontSize: 14 }} />,
            show: !!provider.age_group_experience,
            style: { alignItems: 'center' },
        },
        {
            detail: (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <DetailText>Has experience with: </DetailText>
                    {provider.experience?.map((exp) => (
                        <DetailText>{exp}</DetailText>
                    ))}
                </div>
            ),
            icon: <Add style={{ fontSize: 16 }} />,
            show: (provider.experience?.length || 0) > 0,
        },
    ];
    return (
        <div style={{ marginTop: 20 }}>
            {details.map((detail, index) =>
                detail.show ? (
                    <ProviderProfileDetailRow
                        key={index}
                        icon={detail.icon}
                        detail={detail.detail}
                        style={detail.style}
                    />
                ) : null,
            )}
        </div>
    );
}
