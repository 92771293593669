import React, { CSSProperties, ReactNode } from 'react';
import { Text, Colors } from 'library';

interface Props {
    title?: ReactNode;
    children: ReactNode | ReactNode[];
    style?: CSSProperties;
    textStyle?: CSSProperties;
    bold?: boolean;
    titleStyle?: CSSProperties;
}

export default function FormField({ title, children, style, titleStyle }: Props) {
    return (
        <div style={{ marginBottom: 20, ...style }}>
            {title ? (
                <Text textStyle={{ color: Colors.turquoise, ...titleStyle }} bold>
                    {title}
                </Text>
            ) : null}
            {children}
        </div>
    );
}
