import React from 'react';
import { BusinessJobTemplateDetailsDTO, BusinessJobTemplateListDTO } from 'parent-portal/forms/JobTemplateModels';
import { styled } from '@mui/material';
import { Text } from 'library';
import BusinessJobTypeChip from '../../BusinessJobTypeChip';

export default function JobTemplateOptionJobDetails({
    jobTemplate,
}: {
    jobTemplate: BusinessJobTemplateListDTO | BusinessJobTemplateDetailsDTO;
}) {
    return (
        <Container>
            <HeadlineContainer>
                <Text variant="body1" bold>
                    {jobTemplate.headline}
                </Text>
                <BusinessJobTypeChip businessJobType={jobTemplate.business_job_type} />
            </HeadlineContainer>
            <Text variant="caption">
                {jobTemplate.pay ? `$${jobTemplate.pay}/hr` : `$${jobTemplate.rate_min}-$${jobTemplate.rate_max}/hr`}
            </Text>
            {jobTemplate.requested_workers.length > 0 ? (
                <div style={{ marginRight: 10 }}>
                    <Text variant="caption">
                        <Text variant="caption" bold inline>
                            Requested Worker{jobTemplate.requested_workers.length == 1 ? '' : 's'}:
                        </Text>{' '}
                        {jobTemplate.requested_workers.join(', ')}
                    </Text>
                </div>
            ) : null}
        </Container>
    );
}

const Container = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    gap: 5,
    marginLeft: 10,
});

const HeadlineContainer = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    gap: 10,
});
