import { Tabs } from 'library';
import React, { useState } from 'react';
import LocationEmailHistory from './components/LocationEmailHistory';
import NotesModal from 'internal/shared/tables/NotesModal';
import LocationCallHistory from './components/LocationCallHistory';
import LocationAllCommunicationsHistory from './components/LocationAllCommunicationsHistory';
import { useBusinessLocationOverview } from '../api';

export default function BusinessCommunicationHistory({ businessLocationId }: { businessLocationId: number }) {
    const [selectedTab, setSelectedTab] = useState('0');
    const [noteFetchDate, setNoteFetchDate] = useState(new Date());

    const { status, error, data: businessLocation } = useBusinessLocationOverview(businessLocationId);

    const tabs = ['All', 'Emails', 'Notes', 'Calls'];
    const values = [
        <LocationAllCommunicationsHistory
            key="All"
            businessLocationId={businessLocationId}
            businessLocation={businessLocation}
            locationLoading={status === 'loading'}
            locationErrored={status === 'error'}
        />,
        <LocationEmailHistory key="CommHistory" businessLocation={businessLocation} error={error} status={status} />,
        <NotesModal key="Notes" type="business-location" objectId={businessLocationId} lastFetchDate={noteFetchDate} />,
        <LocationCallHistory
            key="CallHistory"
            businessLocationId={businessLocationId}
            error={error}
            status={status}
            onCallAdded={() => setNoteFetchDate(new Date())}
        />,
    ];

    return <Tabs selectedTab={selectedTab} setSelectedTab={setSelectedTab} tabs={tabs} values={values} />;
}
