import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import { PrimaryButton, Text, SecondaryButton } from 'library';
import useContact from 'parent-portal/profile/BusinessProfile/hooks/useContact';
import { IContactInfo } from 'parent-portal/profile/BusinessProfile/models';
import { client, consoleLogInDev } from 'shared';
import SelectOrCreateContact from './SelectOrCreateContact';
import { IBusinessContact } from 'models';

export default function AddLocationContact({
    updateContact,
    contact,
    locationId,
}: {
    updateContact: (c: IBusinessContact) => void;
    contact?: IContactInfo;
    locationId: number;
}) {
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [existingContact, setExistingContact] = useState(-1);
    const {
        isNewContactValid,
        mainContactInfo,
        updateMainContactInfo,
        populateCurrentContactInfo,
        submissionData,
    } = useContact(contact);

    function save() {
        setLoading(true);
        let data = existingContact === -1 ? submissionData : { user_id: existingContact };
        client('api/business-contact/', {
            body: { ...data, business_location_id: locationId },
        })
            .then((res) => {
                if (res.status === 500) {
                    setErrorMessage(res.message);
                } else {
                    updateContact(res);
                }
            })
            .catch(consoleLogInDev)
            .finally(() => setLoading(false));
    }

    function isAddValid() {
        return isNewContactValid();
    }

    function onCancel() {
        populateCurrentContactInfo();
    }

    return (
        <Grid container style={{ padding: 30 }} direction="column">
            <Text variant="display">Add New Location Contact</Text>
            <SelectOrCreateContact
                mainContactInfo={mainContactInfo}
                updateMainContactInfo={updateMainContactInfo}
                setExistingContact={setExistingContact}
                existingContact={existingContact}
                errorMessage={errorMessage}
                locationId={locationId}
            />
            <Grid container item xs={12} justify="space-around">
                <SecondaryButton buttonStyle={{ maxWidth: '30%' }} onClick={onCancel}>
                    Cancel
                </SecondaryButton>
                <PrimaryButton
                    disabled={(!isAddValid() && existingContact === -1) || loading}
                    buttonStyle={{ maxWidth: '30%' }}
                    onClick={save}
                >
                    Add
                </PrimaryButton>
            </Grid>
        </Grid>
    );
}
