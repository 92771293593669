import React, { ReactNode } from 'react';
import { Colors, Text } from 'library';

interface Props {
    children: ReactNode | ReactNode[];
}

export default function ErrorText({ children }: Props) {
    return (
        <Text variant="body2" color={Colors.error}>
            {children}
        </Text>
    );
}
