import React from 'react';
import { useWidth } from 'reusableComponents/useWidth';
import StickyBar from './components/StickyBar';
import DefaultBar from './components/DefaultBar';
import useTrackingCookies from 'parent-portal/shared/EngagementSourceTracking';

export default function PublicNavBar() {
    useTrackingCookies('/');

    const breakpoint = useWidth();
    const breakLayout = ['xs', 'sm'].includes(breakpoint);

    return <>{!breakLayout ? <DefaultBar /> : <StickyBar />}</>;
}
