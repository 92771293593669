import React from 'react';
import { Grid } from '@material-ui/core';
import { Colors, Text } from 'library';
import StripeIcon from 'assets/icons/tandempay/stripe_slate.svg';
import PlaidIcon from 'assets/images/plaid_logo.svg';
import VisaIcon from 'assets/icons/tandempay/Visa_2021.svg';

export default function PaymentPartners() {
    return (
        <Grid container item direction="column" alignItems="center">
            <Grid item>
                <Text variant="body3" color={Colors.grey}>
                    Our payment partners
                </Text>
            </Grid>
            <Grid container item direction="row" justify="space-around" alignContent="center" alignItems="center">
                <Grid item>
                    <StripeIcon style={styles.icon} />
                </Grid>
                <Grid item>
                    <PlaidIcon style={{ maxWidth: 48, maxHeight: 48 }} />
                </Grid>
                <Grid container item xs={2} style={styles.visaContainer}>
                    <VisaIcon style={styles.icon} />
                </Grid>
            </Grid>
        </Grid>
    );
}

const styles = {
    icon: { width: 48, maxHeight: 48 },
    visaContainer: {
        height: 32,
        alignItems: 'center',
        alignContent: 'center',
        justifyContent: 'center',
        maxWidth: 48,
    },
};
