import React from 'react';
import { isMobile } from 'react-device-detect';
import { useUserContext } from 'UserContext';
import PublicProviderPageHeader from '../parent-portal/public/public-application/PublicProviderPageHeader';
import Drawer from '../parent-portal/navigation/drawer';
import AdminNav from 'internal/shared/Navigation';
import MarketerNavigation from 'internal/shared/MarketerNavigation';
import PublicNavBar from 'parent-portal/LandingPages/Shared/ActionNavBar/PublicNavBar';

const publicProviderPaths = ['/indeed/', '/providers/signup', '/gotandem', '/public/interview/actions'];

const noHeaderRoutes = [
    '/sitter/',
    '/providers/ambassador/',
    '/parent-verification',
    '/job-request',
    '/providers/home',
    '/get-started',
];

const Navigation = () => {
    const { user, role } = useUserContext();

    if (!user || user.signup_page < 4) {
        if (publicProviderPaths.some((x) => window.location.pathname.startsWith(x))) {
            return <PublicProviderPageHeader logoWidth={isMobile ? 100 : 175} />;
        }
        return <PublicNavBar />;
    }

    if (noHeaderRoutes.some((x) => window.location.pathname.startsWith(x))) {
        return <></>;
    }

    if (role === 'admin') {
        return <AdminNav />;
    } else if (role === 'marketer') {
        return <MarketerNavigation />;
    } else if (role === 'family' || role === 'business' || role === 'business_active') {
        return <Drawer />;
    }

    return <></>;
};

export default Navigation;
