import React from 'react';
import { Text, Colors } from 'library';
import { Grid } from '@material-ui/core';
import { UpcomingJobModel, HiredProvider } from '../nestedJobList/jobListModels';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import EmailIcon from '@mui/icons-material/Email';
import { useHistory } from 'react-router-dom';
import { track } from 'shared';
import DefaultProfileImage from 'assets/icons/Profile.svg';
import { useUserContext } from 'UserContext';
import CalendarEditIcon from 'assets/icons/calendar/calendar-edit.svg';
import CardItem from './CardItem';
import HiredProviderAction from './HiredProviderAction';

export const ActiveJobActions = ({ job }: { job: UpcomingJobModel }) => {
    const { role } = useUserContext();

    const ReviewApplicants = ({ job }: { job: UpcomingJobModel }) => {
        if (job) {
            return !job.quick_fill ? (
                <CardItem
                    text="Review Applicants"
                    link={`/jobs/${job.id}/applicants`}
                    icon={<PeopleAltIcon fontSize="medium" htmlColor={Colors.white} style={{ padding: 3 }} />}
                />
            ) : (
                <CardItem text="Waiting for a provider to accept" link="" icon="" />
            );
        }
        return null;
    };

    const ViewAndEdit = ({ job }: { job: UpcomingJobModel }) => {
        if (job) {
            return (
                <CardItem
                    id="active-job-card-view-and-edit"
                    text="View & Edit Job"
                    link={job.ongoing ? `/jobs/request/edit/${job.id}` : `/jobs/upcoming/edit/${job.appointment_id}`}
                    icon={
                        <CalendarEditIcon
                            height={'23px'}
                            width={'23px'}
                            color={Colors.white}
                            fontSize="medium"
                            style={{ padding: 3 }}
                        />
                    }
                />
            );
        }
        return null;
    };

    if (role === 'business_active') {
        return (
            <>
                {job.hired_provider
                    ? job.hired_provider.map((provider: HiredProvider) => (
                          <HiredProviderAction jobId={job.id} hiredProvider={provider} />
                      ))
                    : null}
                <ViewAndEdit job={job} />
            </>
        );
    }
    return (
        <>
            {job.hired_provider ? (
                <HiredProviderAction jobId={job.id} hiredProvider={job.hired_provider[0]} />
            ) : (
                <>
                    <ReviewApplicants job={job} />
                </>
            )}
            <ViewAndEdit job={job} />
        </>
    );
};
