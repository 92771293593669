import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import { AdminProvider } from 'models';
import { consoleLogInDev, download } from 'shared';
import { PrimaryButton } from 'library';
import useBusinessLocationOptions from '../components/Form718bLocationChoice';

async function downloadFile(link: string) {
    try {
        return download(link);
    } catch (error) {
        consoleLogInDev(error);
    }
}

interface DownloadParams {
    business_location_id?: number | null;
    program_id?: string | null;
    selected_position?: string;
}

export default function AllDocumentActions({ provider }: { provider: AdminProvider }) {
    const readyForWork = provider?.ready_to_work;
    const hasLabel = (provider?.labels ?? []).map((l) => l.label.text).includes('✅');
    const inChicago = provider?.user?.address?.state == 'Illinois';
    const { selectedBusinessLocation, Form178bDrodown, selectedPosition } = useBusinessLocationOptions(true);
    const [loading, setLoading] = useState(false);

    const canRun718b = (readyForWork || hasLabel) && inChicago;

    async function onClick() {
        setLoading(true);
        const downloadLink = `api/providers/${provider.id}/documents/`;
        const queryObject: DownloadParams = {
            business_location_id: selectedBusinessLocation?.id,
            program_id: selectedBusinessLocation?.program_id,
            selected_position: selectedPosition,
        };
        const qyeryString = Object.keys(queryObject)
            .map((x) => {
                const value = queryObject[x as keyof DownloadParams];
                if (typeof value === 'string') {
                    return `${x}=${value}`;
                } else {
                    const val = value !== undefined && value !== null ? value : -1;
                    return val > -1 ? `${x}=${val}` : '';
                }
            })
            .filter(Boolean)
            .join('&');

        const link = qyeryString ? `${downloadLink}?${qyeryString}` : downloadLink;

        downloadFile(link).finally(() => setLoading(false));
    }

    return (
        <Grid container item direction="row" style={{ gap: 20 }}>
            {canRun718b ? <Form178bDrodown /> : null}
            <PrimaryButton onClick={onClick} disabled={loading} buttonStyle={{ maxWidth: 250, height: 50 }}>
                Download All Documents
            </PrimaryButton>
        </Grid>
    );
}
