import React from 'react';
import { Chip, Colors } from 'library';
import { Grid } from '@mui/material';
import { IProviderReviewOption } from 'models';

export default function ReviewOptionSelect({
    options,
    value,
    onChange,
    isScorePositive,
}: {
    options: IProviderReviewOption[];
    value: number[];
    onChange: (newVal: number[]) => void;
    isScorePositive?: boolean | 0;
}) {
    const optionSelected = (option: IProviderReviewOption) => value && value.includes(option.id);
    const onToggle = (option: IProviderReviewOption) => {
        onChange(optionSelected(option) ? value.filter((it) => it !== option.id) : [...value, option.id]);
    };

    return (
        <Grid direction="row" container justifyContent="left" gap={1}>
            {options.map((option) => (
                <Chip
                    key={option.id}
                    backgroundColor={
                        optionSelected(option)
                            ? isScorePositive
                                ? Colors.turquoise
                                : Colors.mediumRed
                            : Colors.disabledGrey
                    }
                    onClick={() => onToggle(option)}
                    label={option.text}
                    textColor={Colors.white}
                />
            ))}
        </Grid>
    );
}
