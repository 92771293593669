import { Grid } from '@mui/material';
import React, { useState } from 'react';
import NameButton from './NameButton';
import { ProviderOutlawModal } from 'internal/shared/UserActions';
import { Text, Colors, TextButton } from 'library';
import { LeanUser } from 'models';
import { AddPreferredWorker } from './AddPreferredWorker';
import HireWorkerModal from './HireWorkerModal';

export default function StaffWorkers({
    staff,
    setSelectedUser,
    refreshData,
    businessLocationId,
}: {
    staff: LeanUser[];
    setSelectedUser: (id: number) => void;
    refreshData: (page?: number, selectedHistoryTypes?: string[], showAll?: boolean) => void;
    businessLocationId: number;
}) {
    const [showAll, setShowAll] = useState(true);

    const handleShowAll = () => {
        setShowAll(!showAll);
        refreshData(undefined, undefined, showAll);
    };

    return (
        <Grid container item gap={1} direction="column" xs={12} md={3} flexWrap="wrap" sx={{ paddingRight: '10px' }}>
            <Grid container justifyContent="space-between">
                <Grid item>
                    <Text bold variant="h2" textStyle={{ color: Colors.turquoise }}>
                        On Staff:
                    </Text>
                </Grid>
                <Grid item>
                    <TextButton onClick={handleShowAll} textStyle={{ color: Colors.turquoise }}>
                        {showAll ? 'Show All' : 'Show Less'}
                    </TextButton>
                </Grid>
            </Grid>
            <Grid container item gap={1}>
                {staff.length === 0 && <Text>No Staff Found.</Text>}
                {staff.map((s) => (
                    <Grid key={s.id} item container justifyContent={'space-between'} gap={1}>
                        <Grid item>
                            <NameButton onClick={setSelectedUser} user={s} />
                        </Grid>
                        <Grid item>
                            <AddPreferredWorker
                                preferred={s}
                                refreshData={refreshData}
                                businessLocationId={businessLocationId}
                            />
                            <ProviderOutlawModal user={s} onClose={refreshData} defaultNameOrId={businessLocationId} />
                            <HireWorkerModal
                                user={s}
                                business_location_id={businessLocationId}
                                refreshData={refreshData}
                            />
                        </Grid>
                    </Grid>
                ))}
            </Grid>
        </Grid>
    );
}
