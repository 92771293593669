import moment, { Moment } from 'moment';
import { plusOneDay, timeWithDate } from 'parent-portal/forms/time-operations';

type JobTimes = {
    start: Moment | null;
    end: Moment | null;
};

type JobDate = moment.Moment | null;

export function getTimeString(jobTimes: JobTimes, jobDate: JobDate) {
    const { start, end } = jobTimes;

    const jobIsOvernight = end?.isBefore(start);

    return jobIsOvernight ? overnightTimeString(jobDate!, start!, end!) : singleDayTimeString(jobDate!, start!, end!);
}

function singleDayTimeString(jobDate: Moment | null, start: Moment, end: Moment) {
    let day = 'Dates are flexible';
    if (jobDate) {
        day = jobDate.format('MMM DD, YYYY');
    }

    let times = 'Times are flexible';
    if (start && end) {
        times = `${start.format('h:mm A')} - ${end.format('h:mm A')}`;
    }

    return `${day}\n${times}`;
}

function overnightTimeString(jobDate: Moment, start: Moment, end: Moment) {
    const startString = timeWithDate(start, jobDate).format('MMM DD, YYYY - h:mm A');

    const nextDay = plusOneDay(jobDate);
    const endString = timeWithDate(end, nextDay).format('MMM DD, YYYY - h:mm A');

    return `${startString}\n${endString}\nThis job ends past midnight`;
}
