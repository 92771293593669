import React from 'react';
import { formatPhoneNumber, isValidEmail, isValidPhone } from 'shared';
import { IContactInfo } from 'parent-portal/profile/BusinessProfile/models';
import { UnderlinedTextInput } from 'library';
import { Grid } from '@material-ui/core';

export default function ContactInfo({
    newContact,
    updateContact,
    editing = true,
    disablePhoneAndEmail = false,
}: {
    newContact: IContactInfo;
    updateContact: (k: keyof IContactInfo, v: string) => void;
    editing?: boolean;
    disablePhoneAndEmail?: boolean;
}) {
    return (
        <Grid container item direction="column" style={{ margin: '20px 0', gap: 10 }}>
            <UnderlinedTextInput
                label="First Name"
                value={newContact.firstName}
                onChange={(val: string, _isValid: boolean) => updateContact('firstName', val)}
                disabled={!editing}
                error={!newContact.firstName}
                errorText={'First name required'}
                boldLabel
            />
            <UnderlinedTextInput
                label="Last Name"
                value={newContact.lastName}
                onChange={(val: string, _isValid: boolean) => updateContact('lastName', val)}
                disabled={!editing}
                error={!newContact.lastName}
                errorText={'Last name required'}
                boldLabel
            />
            <UnderlinedTextInput
                label="Email"
                value={newContact.email}
                onChange={(val: string, _isvalid: boolean) => updateContact('email', val)}
                error={!!newContact.email && !isValidEmail(newContact.email)}
                errorText={'Valid email required'}
                disabled={!editing || disablePhoneAndEmail}
                boldLabel
            />
            <UnderlinedTextInput
                label="Phone Number"
                value={formatPhoneNumber(newContact.phoneNumber) ?? newContact.phoneNumber}
                onChange={(val: string, _isvalid: boolean) => updateContact('phoneNumber', val)}
                error={!!newContact.phoneNumber && !isValidPhone(newContact.phoneNumber)}
                errorText={'Must be valid phone number'}
                disabled={!editing || disablePhoneAndEmail}
                boldLabel
            />
        </Grid>
    );
}
