import React, { useState } from 'react';
import { BusinessJobWithAppointments } from 'models';
import StartIcon from '@mui/icons-material/Start';
import { IconButton } from 'library';
import ExtendTrialRunModal from './ExtendTrialRunModal';

export default function ExtendTrialRun({ job, week }: { job: BusinessJobWithAppointments; week: Date }) {
    const [open, setOpen] = useState(false);
    return job.business_job_type === 'TRIAL_RUN' ? (
        <span style={{ marginLeft: 10 }}>
            <IconButton
                tooltip="Extend or stop this trial run for hired workers to next week"
                icon={StartIcon}
                onClick={() => setOpen(true)}
            />
            <ExtendTrialRunModal job={job} open={open} onClose={() => setOpen(false)} week={week} />
        </span>
    ) : null;
}
