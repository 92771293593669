import { client } from 'shared';
import { FormData, RecheckDCFSFingerprintSearchAPIResponse } from '../internal/providers/ILFingerprintCheckTypes';

export function recheckDCFSFingerprintSearch(
    id: number,
    data: FormData,
): Promise<RecheckDCFSFingerprintSearchAPIResponse> {
    return client(`api/illinois-info-collect/recheck-dcfs-fingerprint-search/?provider=${id}`, {
        method: 'POST',
        body: data,
    });
}
