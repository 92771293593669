import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { useWidth } from 'reusableComponents/useWidth';
import { Text } from 'library';
import { useUserContext } from 'UserContext';
import PayFAQCards from './PayFAQCards';
import ConnectPayCard from './ConnectPayCard';
import PaymentPartners from './PaymentPartners';
import FamilyTestimony from './FamilyTestimony';
import QuestionFooter from './QuestionFooter';
import usePublicSessionTracking from '../../shared/PublicSessionTracking';
import useQueryParams from 'shared/QueryParamsHook';

const background = require('assets/gradients/tandempay_gradient.svg');

export default function TandemPayInfo() {
    const [isSmallDevice, setIsSmallDevice] = useState<boolean>(false);
    const width = useWidth();
    const { user } = useUserContext();
    const { usePageLoadTracking } = usePublicSessionTracking('TandemPay Info');
    const query = useQueryParams();
    const openExpanded: boolean = query.get('open') === 'true';

    usePageLoadTracking();

    useEffect(() => {
        setIsSmallDevice(width === 'sm' || width === 'xs');
    }, [width]);

    function headerText() {
        return (
            <Grid
                container
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignContent: 'center',
                    alignItems: 'center',
                    paddingTop: 32,
                }}
            >
                {!isSmallDevice ? (
                    <>
                        <Grid item>
                            <Text variant="display" textStyle={{ fontSize: 30 }}>
                                Find reliable care for free.
                            </Text>
                        </Grid>
                        <Grid item>
                            <Text variant="display" textStyle={{ fontSize: 30 }}>
                                Pay and schedule all-in-one.
                            </Text>
                        </Grid>
                    </>
                ) : (
                    <Grid container item style={{ paddingLeft: 12, paddingRight: 12 }}>
                        <Text variant="display" textStyle={{ fontSize: 26, textAlign: 'center', fontWeight: 'bolder' }}>
                            Find reliable care for free. Pay and schedule all-in-one.
                        </Text>
                    </Grid>
                )}
            </Grid>
        );
    }

    return (
        <Grid container direction="column" style={{ paddingBottom: '10vh', paddingTop: '10vh' }}>
            <Grid
                container
                item
                style={{
                    position: 'absolute',
                    top: !!isSmallDevice ? '-20%' : '-5%',
                    backgroundSize: !!isSmallDevice ? '400%' : '290%',
                    ...styles.background,
                }}
            ></Grid>
            <Grid
                container
                direction="column"
                style={{
                    paddingLeft: 24,
                    paddingRight: 24,
                    alignItems: 'center',
                    alignContent: 'center',
                }}
            >
                {headerText()}
                <Grid item style={{ paddingTop: 24 }}>
                    <PayFAQCards openExpanded={openExpanded} />
                </Grid>
                {!!user?.payment_information ? (
                    <Grid item style={{ paddingTop: 24 }}>
                        <ConnectPayCard />
                    </Grid>
                ) : null}
                <Grid container item xs={12} md={3} style={{ marginTop: 24 }}>
                    <PaymentPartners />
                </Grid>
                <Grid item style={{ paddingTop: 48 }}>
                    <FamilyTestimony />
                </Grid>
                <Grid item style={{ paddingTop: 60 }}>
                    <QuestionFooter />
                </Grid>
            </Grid>
        </Grid>
    );
}

const styles = {
    background: {
        backgroundImage: `url(${background})`,
        paddingBottom: '10vh',
        backgroundPosition: 'center',
        minHeight: '100vh',
        filter: `blur(25px)`,
        zIndex: -1,
    },
};
