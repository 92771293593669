import ApiClient from '../shared/ApiClient';

const apiPath = 'api/provider-like/';

export async function likeProvider(providerId: number) {
    return ApiClient(apiPath, { body: { provider: providerId } });
}

export async function isLiked(providerId: number) {
    return ApiClient(`${apiPath}is-liked/${providerId}/`) as Promise<boolean>;
}
