import React from 'react';
import { Grid, SvgIcon } from '@mui/material';
import { Colors } from '../../constants';
import InstagramIcon from '@material-ui/icons/Instagram';
import FacebookIcon from '@material-ui/icons/Facebook';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import usePublicSessionTracking, { PublicSessionEventType } from '../shared/PublicSessionTracking';

interface IProps {
    style?: React.CSSProperties;
}

export default function SocialsRow({ style }: IProps) {
    const icons = [
        { name: InstagramIcon, link: `https://www.instagram.com/jointandem/` },
        { name: FacebookIcon, link: `https://www.facebook.com/jointandem/` },
        { name: LinkedInIcon, link: `https://www.linkedin.com/company/jointandem/` },
    ];
    const { trackSession } = usePublicSessionTracking('Socials Row');

    function redirect(icon: any) {
        trackSession('NAVIGATE_FROM', 'Away from Tandem', PublicSessionEventType.User, { link: icon.link });
        window.open(icon.link, '_blank');
    }

    return (
        <Grid container item xs={12} md={6} direction="row" justifyContent="flex-end" style={style}>
            {icons.map((icon: any) => (
                <Grid key={icon.link} item>
                    <SvgIcon
                        fontSize={'large'}
                        component={icon.name}
                        onClick={() => redirect(icon)}
                        viewBox="0 0 26 26"
                        style={{ color: Colors.darkNavy }}
                    />
                </Grid>
            ))}
        </Grid>
    );
}
