import { isValidPhoneNumber } from 'libphonenumber-js';

function isValidIsoDate(date: string) {
    // checks if date is in "yyyy-mm-dd" format
    const re = /^\d{4}[\/\-](0?[1-9]|1[012])[\/\-](0?[1-9]|[12][0-9]|3[01])$/;
    return re.test(date);
}

function isValidStandardDate(date: string) {
    // checks if date is in "mm/dd/yyyy" format
    const re = /^(0?[1-9]|1[012])[\/\-](0?[1-9]|[12][0-9]|3[01])[\/\-]\d{4}$/;
    return re.test(date);
}

export function isValidDate(date: string) {
    return isValidIsoDate(date) || isValidStandardDate(date);
}

export function isValidEmail(email: string) {
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
}

export function isValidZipCode(zip: string) {
    var z = /^\d{5}(-\d{4})?$/;
    return z.test(zip);
}

export function isValidPhone(phone: string) {
    return isValidPhoneNumber(phone, 'US');
}

export function isValidFormInputString(text: string) {
    return text !== '' && !/^\s*$/.test(text);
}
