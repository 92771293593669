import React, { useEffect } from 'react';
import AppStoreButton from './AppStoreButton';
import { Grid } from '@material-ui/core';
import { Text } from 'library';
import usePublicSessionTracking, { PublicSessionEventType } from 'parent-portal/shared/PublicSessionTracking';
import { isDev } from 'shared';

const DEEP_LINK_PREFIX = isDev() ? 'exp://127.0.0.1:19000/--/' : 'jointandem:///';
export default function UniversalLinkingPage() {
    const url = window.location.origin;
    const { trackSession, usePageLoadTracking } = usePublicSessionTracking('Universal Linking Page');

    usePageLoadTracking();

    const trackAndRedirect = () => {
        trackSession('NAVIGATE_FROM', 'To Providers Home', PublicSessionEventType.User);
        window.open(`${url}/providers/home`);
    };

    useEffect(() => {
        const linkUrl = `${DEEP_LINK_PREFIX}gotandem/${window.location.search}`;
        window.location.assign(linkUrl);
    }, []);

    return (
        <Grid container direction="row" alignItems="flex-start">
            <Grid
                container
                item
                xs={12}
                md={6}
                direction="column"
                alignContent="center"
                justify="center"
                style={{ height: '100vh' }}
            >
                <Text variant="display" textStyle={{ marginBottom: 5 }}>
                    Welcome to Tandem.
                </Text>
                <Text variant="h1">Start booking jobs and earning money by downloading our app.</Text>
                <Text
                    variant="body1"
                    textStyle={{ textDecoration: 'underline', marginBottom: 15, cursor: 'pointer' }}
                    onClick={trackAndRedirect}
                >
                    Learn more
                </Text>
                <AppStoreButton tracking={'Universal linking app download click'} />
            </Grid>
        </Grid>
    );
}
