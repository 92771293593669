import React from 'react';
import { Grid, Link } from '@material-ui/core';
import { isMobile } from 'react-device-detect';
import { BasicDialog, Text, Colors } from 'library';
import { useHistory } from 'react-router-dom';
import { client, consoleLogInDev } from 'shared';
import { Appointment } from 'parent-portal/public/JobModels';

interface Props {
    isOpen: boolean;
    onClose: () => void;
    cancelJob: () => void;
    job: Appointment;
    cantCancel?: boolean;
    refresh: () => void;
    submitReason: () => void;
}

const NotPayingCancellationModal = ({ isOpen, onClose, cancelJob, job, cantCancel, refresh, submitReason }: Props) => {
    const history = useHistory();
    const hasApprovedPairings = job.status === 'APPROVED_PAIRING';
    const hasApplicants = job.status === 'AWAITING_SELECTION';

    const title =
        hasApprovedPairings || cantCancel
            ? `Contact support to proceed.`
            : hasApplicants
              ? `We're sorry you don't want to pay through Tandem.`
              : `We're sad to see you go.`;

    function deactivateJob() {
        client(`api/ongoing/${job.ongoing_request.id}/`, {
            body: { deactivated: true },
            method: 'PATCH',
        })
            .then(() => submitReason())
            .catch(consoleLogInDev);
        refresh();
    }
    function pairingsContent() {
        return (
            <Grid container item direction="row">
                <Text variant="h2" bold textStyle={{ marginBottom: 15 }}>
                    Here's why you might want to consider it in the future.
                </Text>
                <Text variant="body1" color={Colors.darkGrey}>
                    • We handle taxes for your provider and give you easy access to your payment history.
                </Text>
                <Text variant="body1" color={Colors.darkGrey}>
                    • You don't pay fees and your provider gets 100% of their pay.
                </Text>
                <Text variant="body1" color={Colors.darkGrey}>
                    • You set your rate and a payment schedule of your choice.
                </Text>
            </Grid>
        );
    }
    function noPairingsContent() {
        return (
            <Grid container item direction="row">
                <Text variant={isMobile ? 'body1' : 'h2'} color={Colors.darkGrey}>
                    We'd love for you to learn more about TandemPay and give it a try in the future.
                </Text>
            </Grid>
        );
    }

    function cantCancelContent() {
        return (
            <Grid container item direction="row">
                <Grid container item direction="row">
                    <Text variant="h2" bold textStyle={{ marginBottom: 15 }}>
                        We're happy we found you a match.
                    </Text>
                    <Text variant="body1" color={Colors.darkGrey}>
                        If you want to proceed forward with cancelling, please reach out to Support at{' '}
                        <Link href="mailto:support@join-tandem.com">support@join-tandem.com</Link> or 614-349-5661
                    </Text>
                </Grid>
            </Grid>
        );
    }
    const goToTPInfo = () => {
        if (hasApprovedPairings || cantCancel) {
            deactivateJob();
        } else {
            cancelJob();
        }
        history.push('/tandempay');
    };

    function close() {
        onClose();
        if (hasApprovedPairings || cantCancel) {
            deactivateJob();
        } else {
            cancelJob();
        }
        refresh();
    }
    return (
        <Grid>
            {/* add secondary button for cant cancel */}
            <BasicDialog
                isOpen={isOpen}
                onClose={close}
                dialogTitle={title}
                children={
                    cantCancel || hasApprovedPairings
                        ? cantCancelContent()
                        : hasApplicants
                          ? pairingsContent()
                          : noPairingsContent()
                }
                closeButton={true}
                buttonEnabled
                mobileMaxWidth={'60%'}
                webMaxWidth={'30%'}
                onSubmit={goToTPInfo}
                buttonText={'Learn more'}
            ></BasicDialog>
        </Grid>
    );
};

export default NotPayingCancellationModal;
