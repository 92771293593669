import React, { useState } from 'react';
import parse from 'html-react-parser';
import { styled } from '@mui/material';
import { BasicDialog, Colors, PrimaryButton, Text, TextArea } from 'library';
import { formatMonthDayYearWith12HourTime } from 'shared/Dates';

import type { EmailHistory } from './LocationEmailHistory';
import { consoleLogInDev } from 'shared';

export function LocationEmailCommunicationRow({ email }: { email: EmailHistory }) {
    const [seeMore, setSeeMore] = useState(false);
    const [templateData, setTemplateData] = useState('');

    const attributes = [
        {
            label: 'From:',
            value: email.from_email,
        },
        {
            label: 'To:',
            value: email.to_email,
        },
        {
            label: 'Description:',
            value: email.description ?? 'N/A',
        },
    ];

    const longBody = email.body && email.body.length > 350;

    function showEmailBody(emailBody: string) {
        try {
            const pattern = /[\f\n\r\t\v ]{2,}/g;
            const replacement = ' ';

            const normalized = emailBody.replace(pattern, replacement);
            // eslint-disable-next-line regexp/no-unused-capturing-group, regexp/prefer-d
            if (/<([A-Za-z][A-Za-z0-9]*)\b[^>]*>(.*?)<\/\1>/.test(normalized)) {
                return parse(normalized);
            }
            return normalized;
        } catch (error) {
            consoleLogInDev(error);
        }
        return '';
    }

    return (
        <Row>
            <div>
                <Header>
                    <Text bold variant="h2">
                        {email.template_id ? 'Automated' : 'Manual'} Email
                    </Text>
                </Header>
                <Subheader>
                    {attributes.map((attribute: { label: string; value: string | undefined }) => (
                        <Text
                            key={attribute.label}
                            variant="body2"
                            textStyle={{ display: 'flex', flexDirection: 'row', paddingRight: 20 }}
                        >
                            <Text variant="body2" bold textStyle={{ paddingRight: 5 }}>
                                {attribute.label}
                            </Text>
                            {attribute.value}
                        </Text>
                    ))}
                </Subheader>
                <Subheader>
                    <Text variant="body2" bold textStyle={{ paddingRight: 5 }}>
                        Template:
                    </Text>
                    <Text variant="body2">{email.template_id ?? 'N/A'}</Text>
                </Subheader>
                {!!email.body && (
                    <Subheader direction="column">
                        <Text variant="body2" bold textStyle={{ paddingRight: 5 }}>
                            Body:
                        </Text>
                        <div style={{ width: '100%' }}>
                            <EmailSection seeMore={seeMore.toString()}>{showEmailBody(email.body)}</EmailSection>
                            {longBody && (
                                <SeeMore>
                                    <Text
                                        variant="body2"
                                        onClick={() => setSeeMore(!seeMore)}
                                        textStyle={{ textDecorationLine: 'underline' }}
                                    >
                                        {seeMore ? 'See Less' : 'See More'}
                                    </Text>
                                </SeeMore>
                            )}
                        </div>
                    </Subheader>
                )}
            </div>
            <Container>
                <Text variant="body2" textStyle={{ color: Colors.grey }}>
                    {formatMonthDayYearWith12HourTime(email.sent_at)}
                </Text>
                {!!email.template_data && (
                    <PrimaryButton
                        onClick={() => setTemplateData(email.template_data)}
                        buttonStyle={{ width: 'fit-content', padding: 0 }}
                        textStyle={{ fontSize: 12 }}
                    >
                        View
                    </PrimaryButton>
                )}
            </Container>
            <BasicDialog isOpen={!!templateData} onClose={() => setTemplateData('')}>
                <div>
                    <div>
                        <TextArea rows={10} style={{ width: '100%' }} label="Data" value={templateData} />
                    </div>
                    <PrimaryButton onClick={() => setTemplateData('')} buttonStyle={{ width: 300 }}>
                        Close
                    </PrimaryButton>
                </div>
            </BasicDialog>
        </Row>
    );
}

const Row = styled('div')({
    borderBottom: `1px solid ${Colors.lightGrey}`,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 10,
    width: '100%',
});

const Header = styled('div')({
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    width: '100%',
});

const Container = styled('div')({
    alignItems: 'flex-start',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    minWidth: '12%',
    paddingLeft: 10,
});

const Subheader = styled('div')(({ direction }: { direction?: string }) => ({
    alignItems: 'flex-start',
    display: 'flex',
    flexDirection: direction === 'column' ? 'column' : 'row',
    paddingTop: 5,
    width: '100%',
}));

const EmailSection = styled('div')(({ seeMore }: { seeMore: string }) => ({
    maxHeight: seeMore === 'true' ? 'auto' : 36,
    overflow: 'hidden',
    paddingLeft: 10,
    paddingTop: 10,
}));

const SeeMore = styled('div')({
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'right',
    paddingTop: 5,
});
