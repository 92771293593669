import React, { useState } from 'react';
import { Grid, Input, InputLabel } from '@mui/material';
import { client } from 'shared';
import { PrimaryButton, Text } from 'library';

import type { PointDetailResponse } from '../types';

export function EditPointsContent({
    pointDetails,
    onClose,
    userId,
}: {
    pointDetails: PointDetailResponse;
    onClose: () => void;
    userId: number;
}) {
    const [selectedPointValue, setSelectedPointValue] = useState<number>();

    function updateUserPoints() {
        client(`api/point/update-user-point/`, {
            method: 'POST',
            body: { worker_user_id: userId, point_value: selectedPointValue },
        }).then(() => {
            setSelectedPointValue(undefined);
            onClose();
        });
    }

    function setPoints(event: React.ChangeEvent<HTMLInputElement>) {
        const points = parseInt(event.target.value);

        if (!points) setSelectedPointValue(0);

        if (points > -101 && points < 101) {
            setSelectedPointValue(points);
        }
    }

    const calculatedTotal = pointDetails.point.total + (selectedPointValue ?? 0);
    const newPointTotal = calculatedTotal >= 0 ? calculatedTotal : 0;

    return (
        <div style={{ marginBottom: 20, marginTop: 20 }}>
            <Text textStyle={{ marginBottom: 20 }} bold>
                Current Points: {pointDetails.point.total}
            </Text>
            <InputLabel htmlFor="point-edit-amount">
                <Text variant="body2" textStyle={{ marginRight: 5 }} bold>
                    Add points
                </Text>
            </InputLabel>
            <Input
                id="point-edit-amount"
                type="number"
                onChange={setPoints}
                value={selectedPointValue?.toString()}
                placeholder="Enter points"
                style={{ marginBottom: 20 }}
            />
            <Text textStyle={{ marginBottom: 20 }} bold>
                New Point Total: {newPointTotal}
            </Text>
            <Grid item container style={{ marginTop: 20, gap: 10 }}>
                <PrimaryButton
                    onClick={updateUserPoints}
                    buttonStyle={{ width: 'unset' }}
                    disabled={!selectedPointValue}
                >
                    Update Points
                </PrimaryButton>
            </Grid>
        </div>
    );
}
