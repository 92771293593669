import React from 'react';
import { Text, Colors } from 'library';
import { isoWeekDayAbbreviations } from 'parent-portal/forms/JobTemplateModels';
import { styled } from '@mui/material';

export default function DayCircle({ day }: { day: number }) {
    return (
        <Circle>
            <Text variant="caption" bold color={Colors.white}>
                {/*eslint-disable-next-line security/detect-object-injection*/}
                {isoWeekDayAbbreviations[day]}
            </Text>
        </Circle>
    );
}

const Circle = styled('div')({
    width: 20,
    height: 20,
    borderRadius: 10,
    backgroundColor: Colors.turquoise,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
});
