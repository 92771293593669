import React from 'react';
import { useJobFormContext } from 'parent-portal/forms/JobFormContext';
import EditDialog from './EditDialog';
import BusinessPayInputs from '../../PayPage/BusinessPayInputs';
import { useFieldValidation } from 'parent-portal/forms/components/FormHook';

export default function EditPayScalesModal({ open, onClose }: { open: boolean; onClose: () => void }) {
    const { form, defaultPayScales, updateDefaultPayScales, hasSeenTrainingsModal, updateHasSeenTrainingsModal } =
        useJobFormContext();
    const { pay, payScales, headline } = form.fieldStates;

    useFieldValidation(pay);

    return (
        <EditDialog title={`Edit ${headline.value} Pay`} open={open} onClose={onClose} isValid={pay.isValid}>
            <BusinessPayInputs
                pay={pay}
                payScales={payScales}
                defaultPayScales={defaultPayScales}
                updateDefaultPayScales={updateDefaultPayScales}
                hasSeenTrainingsModal={hasSeenTrainingsModal}
                updateHasSeenTrainingsModal={updateHasSeenTrainingsModal}
            />
        </EditDialog>
    );
}
