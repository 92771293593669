import React, { useState } from 'react';
import { IconButton, styled } from '@mui/material';
import { Delete, Edit, MoreVert } from '@mui/icons-material';
import { Colors, Menu } from 'library';
import { TaskListItem } from '../matchingInboxModels';
import { deleteTask } from '../modal-content/modalContentApi';
import { consoleLogInDev } from 'shared';

export default function TaskMenu({
    task,
    refresh,
    onEdit,
}: {
    task: TaskListItem;
    refresh: () => void;
    onEdit: (item: TaskListItem) => void;
}) {
    const [menuOpen, setMenuOpen] = useState(false);

    function onDeleteClick() {
        deleteTask(task.id).then(refresh).catch(consoleLogInDev);
    }

    const options = [
        { text: 'Edit', icon: <Edit />, onClick: () => onEdit(task) },
        {
            text: 'Delete',
            icon: <Delete />,
            onClick: onDeleteClick,
        },
    ];

    return (
        <Menu
            options={options}
            anchor={
                <StyledIconButton onClick={() => setMenuOpen(!menuOpen)}>
                    <MoreVert htmlColor={Colors.darkNavy} fontSize="small" />
                </StyledIconButton>
            }
        />
    );
}

const StyledIconButton = styled(IconButton)({
    padding: 5,
    color: Colors.white,
});
