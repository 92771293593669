import React, { ElementType, CSSProperties, useState } from 'react';
import { SvgIcon } from '@material-ui/core';
import { Colors } from '../../theme';
import { Text } from '../text';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

export default function SmallIconButton({
    icon,
    color,
    backgroundColor,
    onClick,
    style,
    size = 30,
    disabled = false,
    shadow = false,
    hoverText,
    FontAwesomeImg,
}: {
    icon?: ElementType<any>;
    color?: Colors;
    backgroundColor?: Colors | 'transparent';
    onClick: (e: any) => void;
    style?: CSSProperties;
    size?: number;
    disabled?: boolean;
    shadow?: boolean;
    hoverText?: string;
    FontAwesomeImg?: IconDefinition;
}) {
    const [hover, setHover] = useState(false);
    return (
        <>
            <button
                onClick={onClick}
                style={{
                    width: shadow ? 42 : 30,
                    height: shadow ? 42 : 30,
                    border: 'none',
                    cursor: 'pointer',
                    padding: shadow ? 6 : 0,
                    backgroundColor: 'transparent',
                    marginRight: 12,
                    outline: 'none',
                    ...style,
                }}
                disabled={disabled}
                onMouseOver={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
            >
                {!!FontAwesomeImg ? (
                    <FontAwesomeIcon
                        icon={FontAwesomeImg}
                        color={color || Colors.darkNavy}
                        style={{
                            width: size,
                            height: size,
                            padding: 6,
                            borderRadius: '50%',
                            boxShadow: shadow ? '0px 3px 6px #00000029' : undefined,
                            backgroundColor: backgroundColor || Colors.lightGrey,
                        }}
                    />
                ) : !!icon ? (
                    <SvgIcon
                        style={{
                            color: color || Colors.darkNavy,
                            backgroundColor: backgroundColor || Colors.lightGrey,
                            width: size,
                            height: size,
                            padding: 6,
                            borderRadius: '50%',
                            boxShadow: shadow ? '0px 3px 6px #00000029' : undefined,
                        }}
                        component={icon}
                        viewBox={`-2 -2 ${size} ${size}`}
                    />
                ) : (
                    <></>
                )}
                {hover && hoverText ? (
                    <div
                        style={{
                            position: 'relative',
                            top: 0,
                            left: 20,
                            backgroundColor: Colors.white,
                            boxShadow: '0px 3px 6px #00000029',
                            padding: 3,
                            fontSize: 9,
                            borderRadius: 4,
                            width: 70,
                            zIndex: 999,
                        }}
                    >
                        <Text variant="body2">{hoverText}</Text>
                    </div>
                ) : null}
            </button>
        </>
    );
}
