import { client } from 'shared';
import { ISalesLeadPaginatedResponse } from 'internal/salesLeads/types';

export interface IContactInfoPostBody {
    first_name: string;
    last_name: string;
    business_name: string;
    email: string;
    phone_number: string | null;
    contact_info_location: Array<{ zip_code: string }>;
}

export async function createContactLead(postBody: IContactInfoPostBody): Promise<Response> {
    return await client('api/contact-info/add-sales-lead/', { body: postBody });
}

export async function getSalesLeads(page?: number | undefined): Promise<ISalesLeadPaginatedResponse> {
    const queryString = `api/contact-info/sales-leads/${page ? `?page=${page}` : ''}`;
    return await client(queryString, { method: 'GET' });
}
