import React from 'react';
import { useHistory } from 'react-router';
import { Colors, OptionButton } from 'library';
import { Dashboard } from '@mui/icons-material';

export default function LookAround() {
    const history = useHistory();

    return (
        <OptionButton
            icon={<Dashboard htmlColor={Colors.turquoise} fontSize="large" />}
            title="Browse"
            highlight="Look around first"
            subtitle="Go to the dashboard and browse around before making any decisons."
            action={() => history.push('/home')}
            id="look-around-card"
        />
    );
}
