import { Grid } from '@material-ui/core';
import React, { ReactNode } from 'react';
import { SmallIcon, Text } from 'library';
import Lightbulb from 'assets/icons/tandempay/lightbulb.svg';

interface IconRowProps {
    text: ReactNode | ReactNode[];
    secondaryText?: ReactNode | ReactNode[];
    children?: ReactNode | ReactNode[];
}

export default function IconRow({ text, secondaryText, children }: IconRowProps) {
    return (
        <div style={{ marginBottom: 20 }}>
            <Grid container direction="row" alignItems="center" wrap="nowrap">
                <SmallIcon icon={<Lightbulb />} />
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end', flexWrap: 'wrap' }}>
                    <Text variant="body1" bold textStyle={{ marginRight: 10, marginLeft: 10 }}>
                        {text}
                    </Text>
                    {secondaryText ? (
                        <Text
                            variant="deprecatedCaption"
                            textStyle={{ marginLeft: 10, padding: 0, lineHeight: '16.5px' }}
                        >
                            {secondaryText}
                        </Text>
                    ) : null}
                </div>
            </Grid>
            <div style={{ marginLeft: 44 }}>{children}</div>
        </div>
    );
}
