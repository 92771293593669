import { Colors, TextButton } from 'library';
import ConnectPaymentModal from 'parent-portal/payments/Components/ConnectPaymentModal';
import React from 'react';
import { useState } from 'react';
import { useUserContext } from 'UserContext';
import Divider from '../../Divider';
import IconRow from './IconRow';

export default function SetupPaymentLink() {
    const { user, refreshUser } = useUserContext();
    const showSetupPaymentLink = !!user && (user.payment_information.length === 0 || user.customer_id === null);
    const [showSetUpTandemPay, setShowSetUpTandemPay] = useState(false);
    const [setupCompleted, setSetupCompleted] = useState(false);

    function onPaymentSetup() {
        refreshUser();
        setSetupCompleted(true);
    }

    return showSetupPaymentLink && !setupCompleted ? (
        <>
            <ConnectPaymentModal
                fromRoute="Job Form"
                isOpen={showSetUpTandemPay}
                onClose={() => setShowSetUpTandemPay(false)}
                onSuccess={onPaymentSetup}
            />
            <Divider />
            <div style={{ marginTop: 30 }}>
                <IconRow text="Finish setting up your account?">
                    <TextButton
                        buttonStyle={{ width: 'unset' }}
                        textStyle={{ fontSize: 12, fontWeight: 700 }}
                        color={Colors.mediumRed}
                        onClick={() => setShowSetUpTandemPay(true)}
                    >
                        Set up TandemPay
                    </TextButton>
                </IconRow>
            </div>
        </>
    ) : null;
}
