import React from 'react';
import { Text, Chip, Colors } from 'library';
import { useProviderProfileContext } from '../../ProviderProfileContext';

const ChildcareCenterExperienceSpecifics = () => {
    const { providerQualifications } = useProviderProfileContext();
    return providerQualifications.years_childcare_experience > 0 ||
        providerQualifications.age_group_experience.length > 0 ? (
        <div style={{ marginTop: '3%', borderRadius: 25, backgroundColor: Colors.lightTurq }}>
            <div style={{ padding: 20 }}>
                <Text
                    color={Colors.turquoise}
                    variant="body1"
                    bold
                    textStyle={{ letterSpacing: 3.2, textTransform: 'uppercase' }}
                >
                    Experience
                </Text>
                {providerQualifications.years_childcare_experience > 0 ? (
                    <div style={{ marginTop: '2%' }}>
                        <Text variant="h2" bold textStyle={{ marginTop: 8 }}>
                            Total Years of Experience
                        </Text>
                        <Text variant="caption" textStyle={{ marginTop: 4 }}>
                            (Working in a Child Care Center)
                        </Text>
                        <Text variant="body1" textStyle={{ marginTop: 8 }}>
                            {providerQualifications.years_childcare_experience} year
                            {providerQualifications.years_childcare_experience === 1 ? '' : 's'}
                        </Text>
                    </div>
                ) : null}

                {providerQualifications.age_group_experience.length > 0 ? (
                    <div style={{ marginTop: '3%' }}>
                        <Text variant="h2" bold textStyle={{ marginTop: 8, marginBottom: 8 }}>
                            Worked with Age Groups
                        </Text>
                        {providerQualifications.age_group_experience.map((age) => (
                            <Chip
                                key={age}
                                label={age}
                                backgroundColor={Colors.white}
                                style={{ boxShadow: '0px 3px 6px #00000029', margin: 4 }}
                            />
                        ))}
                    </div>
                ) : null}
            </div>
        </div>
    ) : null;
};

export default ChildcareCenterExperienceSpecifics;
