import React, { useState } from 'react';
import { useHistory } from 'react-router';
import AuthModal from 'parent-portal/login/AuthModal';
import { Colors, PrimaryButton, Text } from 'library';
import { AppBar, Grid, Toolbar } from '@mui/material';
import WhiteHeader from './WhiteHeader';
import { Link } from 'react-router-dom';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import WorkerMenu from './WorkerMenu';
import { ElderCareBar } from './ElderCareBar';

export default function DefaultBar() {
    const location = `Public Header: Home Page`;
    const [openLoginModal, setOpenLoginModal] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const history = useHistory();
    const menuRef = React.useRef<HTMLDivElement>(null);
    const isEldercare = window.location.pathname.includes('facilities');

    function handleSignInClick() {
        setOpenLoginModal(true);
    }

    function handleMenuClose() {
        setIsMenuOpen(false);
    }

    return isEldercare ? (
        <ElderCareBar
            openLoginModal={openLoginModal}
            setOpenLoginModal={setOpenLoginModal}
            handleSignInClick={handleSignInClick}
        />
    ) : (
        <Grid>
            <AppBar position="static" sx={styles.appbar}>
                <Toolbar sx={styles.toolbar}>
                    <WhiteHeader />
                    <Text onClick={() => setIsMenuOpen(true)} ref={menuRef} textStyle={styles.menuText}>
                        Childcare Programs
                        <KeyboardArrowDownIcon style={{ fontSize: 18 }} />
                    </Text>
                    <Link style={styles.link} to="/workers">
                        Workers
                    </Link>
                    <Link style={styles.link} to="/about-us">
                        About Us
                    </Link>
                    <PrimaryButton onClick={handleSignInClick} buttonStyle={styles.widthUnset}>
                        Center Login
                    </PrimaryButton>
                </Toolbar>
            </AppBar>
            <AuthModal
                open={openLoginModal}
                onClose={() => setOpenLoginModal(false)}
                onComplete={() => history.push('/home')}
                location={location}
            />
            <WorkerMenu handleMenuClose={handleMenuClose} isMenuOpen={isMenuOpen} anchorRef={menuRef} />
        </Grid>
    );
}

export const styles = {
    toolbar: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    appbar: { flexGrow: 1, backgroundColor: Colors.white },
    menuText: { cursor: 'pointer', fontSize: 16 },
    link: { color: Colors.darkNavy, fontFamily: 'Outfit', fontSize: 14 },
    widthUnset: { width: 'unset' },
};
