import React from 'react';
import { Grid, styled } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import { Colors, Text, TextButton } from 'library';
import { IBusinessDashboardProps } from './types';
import userAttributesApi from 'shared/UserAttributesApi';
import { consoleLogInDev } from 'shared';

const DISMISS_GIVE_50_GET_50_BANNER = 'Dismissed Give 50, Get 50 Top Banner';

export default function Give50DiscountBanner({ data, updateData }: IBusinessDashboardProps) {
    function onHideClick() {
        userAttributesApi
            .create(DISMISS_GIVE_50_GET_50_BANNER)
            .then(() => {
                updateData();
            })
            .catch(consoleLogInDev);
    }

    if (!data?.discounts || data?.discounts.has_dismissed_top_banner) {
        return <></>;
    }

    return (
        <Container container>
            <Grid item xs={1}></Grid>
            <Grid item xs={10}>
                <Text bold textStyle={{ color: Colors.white, textAlign: 'center' }}>
                    <StarIcon fontSize="small" style={{ marginTop: -3, marginRight: 3 }} />
                    Give $50, Get $50 - Refer a Center
                </Text>
            </Grid>
            <Grid item xs={1}>
                <TextButton
                    buttonStyle={{ float: 'right', width: undefined, minWidth: 32 }}
                    textStyle={{ textDecoration: 'none', fontSize: 18, fontWeight: 'bolder' }}
                    onClick={onHideClick}
                >
                    X
                </TextButton>
            </Grid>
        </Container>
    );
}

const Container = styled(Grid)({
    backgroundColor: Colors.terraCotta,
    padding: 10,
});
