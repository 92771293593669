import React from 'react';
import DashboardSection from '../components/DashboardSection';
import { Grid } from '@mui/material';
import { Avatar, Colors, LoadingIndicator, PrimaryButton, Text } from 'library';
import DefaultProfileImage from 'assets/icons/Profile.svg';
import { useHistory } from 'react-router';
import { styles } from './useStyles';
import { Link } from 'react-router-dom';
import useGoToProfile from '../../providers/cards/actions/goToProfile';
import { TProviderRecentlyWorked } from './types';
import WorkerReviewCopy from 'parent-portal/shared/WorkerReviewCopy';

export default function ProviderReviewList({
    workers,
    loading,
    error,
}: {
    workers?: TProviderRecentlyWorked[];
    loading: boolean;
    error: boolean;
}) {
    const history = useHistory();

    const { getProfilePath } = useGoToProfile();
    return (
        <DashboardSection header="Review Your Workers">
            {workers && workers.length > 0 ? <WorkerReviewCopy /> : null}

            <Grid container direction="column">
                {workers && workers.length > 0 ? (
                    workers.map((worker) => (
                        <Grid
                            key={worker.id}
                            container
                            direction="row"
                            alignItems="center"
                            justifyContent="space-between"
                            wrap="nowrap"
                            style={styles.providerRow}
                        >
                            <Grid item gap={2} style={styles.providerInfo}>
                                <Grid item>
                                    {worker.profile_image?.image ? (
                                        <Avatar src={worker.profile_image?.image} style={styles.avatar} />
                                    ) : (
                                        <DefaultProfileImage style={styles.defaultAvatar} />
                                    )}
                                </Grid>
                                <Grid container item>
                                    <Link
                                        to={getProfilePath(worker)}
                                        style={{ color: Colors.darkNavy, textDecoration: 'underline' }}
                                    >
                                        <Text variant="body1" bold>
                                            {worker.user.first_name} {worker.user.last_name}
                                        </Text>
                                    </Link>
                                </Grid>
                            </Grid>
                            <Grid item alignSelf="end">
                                <PrimaryButton onClick={() => history.push(`/providers/review/${worker.user.id}`)}>
                                    Review
                                </PrimaryButton>
                            </Grid>
                        </Grid>
                    ))
                ) : (
                    <Grid>
                        {loading && <LoadingIndicator />}
                        {error && (
                            <Text color={Colors.error} variant="body1">
                                There was an error fetching workers that need reviews. Please refresh your page and try
                                again.
                            </Text>
                        )}
                        {!loading && !error && <Text variant="body1">You&apos;re all caught up on reviews!</Text>}
                    </Grid>
                )}
            </Grid>
        </DashboardSection>
    );
}
