import React, { Component } from 'react';
import styled from 'styled-components';
import theme from '../../../theme';
import Grid from '@material-ui/core/Grid';
import DropDown from '../../../reusableComponents/outlinedDropdown';
import Input from '../../../reusableComponents/input';
import { AddCircle } from '@material-ui/icons';
import { Delete } from '@material-ui/icons';
import PlacesAutocomplete from 'react-places-autocomplete';
import { geocodeByAddress } from 'react-places-autocomplete';
import AccountEditSaveButton from './AccountEditSaveButton';

const InfoSection = styled.div`
    border-radius: 6px;
    background-color: #f7f7f7;
    margin: 10px;
    padding: 10px;
    color: ${theme.palette.primary.main};
`;

const Category = styled.div`
    margin: 0px;
`;

const CategoryTitle = styled.p`
    color: ${theme.palette.primary.main};
    font-size: 12;
    font-weight: bold;
    margin-left: 20px;
    margin-top: 15px;
`;

const CategoryInfo = styled.div`
    background-color: #ffffff;
    border-radius: 6px;
    font-size: 12px;
    font-weight: regular;
    margin-top: 15px;
    margin-bottom: 15px;
    margin-left: 18px;
    margin-right: 18px;
    padding: 15;
`;

const states = [
    '',
    'Alabama',
    'Alaska',
    'Arizona',
    'Arkansas',
    'California',
    'Colorado',
    'Connecticut',
    'Delaware',
    'Florida',
    'Georgia',
    'Hawaii',
    'Idaho',
    'Illinois',
    'Indiana',
    'Iowa',
    'Kansas',
    'Kentucky',
    'Louisiana',
    'Maine',
    'Maryland',
    'Massachusetts',
    'Michigan',
    'Minnesota',
    'Mississippi',
    'Missouri',
    'Montana',
    'Nebraska',
    'Nevada',
    'New Hampshire',
    'New Jersey',
    'New Mexico',
    'New York',
    'North Carolina',
    'North Dakota',
    'Oklahoma',
    'Ohio',
    'Oregon',
    'Pennsylvania',
    'Rhode Island',
    'South Carolina',
    'South Dakota',
    'Tennessee',
    'Texas',
    'Utah',
    'Vermont',
    'Virginia',
    'Washington',
    'West Virginia',
    'Wisconsin',
    'Wyoming',
];

export default class AccountEditDisplay extends Component {
    onDropdownChange = (event) => {
        const { stateHandler } = this.props;
        stateHandler({
            state: event.target.value,
        });
    };

    handleChange = (street) => {
        const { stateHandler } = this.props;
        stateHandler({ street });
    };

    addNumber() {
        const { primaryPhoneNumber, stateHandler } = this.props;
        const newNumber = '';
        stateHandler({ phoneNumbers: [primaryPhoneNumber, newNumber] });
    }

    handleSelect = (address) => {
        const { stateHandler } = this.props;
        geocodeByAddress(address).then((results) => {
            for (let i = 0; i < results[0].address_components.length; i++) {
                let type = results[0].address_components[i].types[0];
                let value = results[0].address_components[i].long_name;
                switch (type) {
                    case 'street_number':
                        stateHandler({ streetNumber: value });
                        break;
                    case 'route':
                        stateHandler({ street: value });
                        break;
                    case 'locality':
                        stateHandler({ city: value });
                        break;
                    case 'postal_code':
                        stateHandler({ zip: value });
                        break;
                    case 'administrative_area_level_1':
                        stateHandler({ state: value });
                        break;
                    default:
                        break;
                }
            }
            const { streetNumber, street } = this.props;
            stateHandler({ street: streetNumber + ' ' + street });
        });
    };

    render() {
        const {
            isFirstName,
            firstName,
            isLastName,
            lastName,
            isPrimaryPhoneNumber,
            primaryPhoneNumber,
            isSecondaryPhoneNumber,
            secondaryPhoneNumber,
            street,
            isStreet,
            city,
            isCity,
            state,
            isState,
            zip,
            isZip,
            phoneNumbers,
            errorMessage,
            onChangeText,
            validate,
            gmapsLoaded,
            deleteNumber,
        } = this.props;

        return (
            <div>
                <Grid container justify="center" alignItems="center">
                    <Grid item sm={6} xs={12}>
                        <InfoSection style={{ minWidth: '90%', marginBottom: '20%' }}>
                            <Category style={{ justifyContent: 'center' }}>
                                <CategoryTitle>Edit Contact Info</CategoryTitle>
                                <CategoryInfo>
                                    <Grid item xs={11} sm={11} justify="center" style={{ paddingTop: 20 }}>
                                        <Input
                                            fullWidth
                                            error={!isFirstName}
                                            helperText={!isFirstName ? 'Please enter your first name' : ''}
                                            id="firstName"
                                            label="First Name"
                                            variant="outlined"
                                            value={firstName}
                                            onChange={(e) => onChangeText('firstName', e)}
                                            style={{ marginLeft: 17, marginRight: 17 }}
                                        />
                                    </Grid>

                                    <Grid item xs={11} sm={11} alignItems="center">
                                        <Input
                                            fullWidth
                                            error={!isLastName}
                                            helperText={!isLastName ? 'Please enter your last name' : ''}
                                            id="lastName"
                                            label="Last Name"
                                            variant="outlined"
                                            value={lastName}
                                            onChange={(e) => onChangeText('lastName', e)}
                                            style={{ marginLeft: 17, marginRight: 17 }}
                                        />
                                    </Grid>
                                    {phoneNumbers.map((number, i) => (
                                        <div>
                                            {i === 0 ? (
                                                <div>
                                                    {phoneNumbers.length === 1 ? (
                                                        <Grid container justify="space-between" item>
                                                            <Grid item sm={10} xs={10}>
                                                                <Input
                                                                    fullWidth
                                                                    error={!isPrimaryPhoneNumber}
                                                                    helperText={
                                                                        !isPrimaryPhoneNumber
                                                                            ? 'Please enter your phone number'
                                                                            : ''
                                                                    }
                                                                    id="primaryPhoneNumber"
                                                                    label="Primary Phone Number"
                                                                    variant="outlined"
                                                                    value={primaryPhoneNumber}
                                                                    onChange={(e) => onChangeText('phone_number', e)}
                                                                    style={{ marginLeft: 17 }}
                                                                />
                                                            </Grid>
                                                            <Grid item sm={1} xs={1}>
                                                                <AddCircle
                                                                    style={{ marginRight: 20, marginTop: 12 }}
                                                                    onClick={(e) => this.addNumber()}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    ) : (
                                                        <Grid container justify="space-between" item>
                                                            <Grid item sm={11} xs={11}>
                                                                <Input
                                                                    fullWidth
                                                                    error={!isPrimaryPhoneNumber}
                                                                    helperText={
                                                                        !isPrimaryPhoneNumber
                                                                            ? 'Please enter your phone number'
                                                                            : ''
                                                                    }
                                                                    id="primaryPhoneNumber"
                                                                    label="Primary Phone Number"
                                                                    variant="outlined"
                                                                    value={primaryPhoneNumber}
                                                                    onChange={(e) => onChangeText('phone_number', e)}
                                                                    style={{ marginLeft: 17 }}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    )}
                                                </div>
                                            ) : (
                                                <Grid container justify="space-between" item>
                                                    <Grid item sm={10} xs={10}>
                                                        <Input
                                                            fullWidth
                                                            error={!isSecondaryPhoneNumber}
                                                            id="secondaryPhoneNumber"
                                                            label="Secondary Phone Number"
                                                            variant="outlined"
                                                            helperText={
                                                                !isSecondaryPhoneNumber
                                                                    ? errorMessage
                                                                    : 'Other parent, grandparent, guardian, etc.'
                                                            }
                                                            value={secondaryPhoneNumber}
                                                            onChange={(e) => onChangeText('secondary_phone_number', e)}
                                                            style={{ marginLeft: 17 }}
                                                        />
                                                    </Grid>
                                                    <Grid item sm={1} xs={1}>
                                                        <Delete onClick={deleteNumber} style={{ marginTop: 12 }} />
                                                    </Grid>
                                                </Grid>
                                            )}
                                        </div>
                                    ))}
                                </CategoryInfo>
                                <CategoryTitle>Edit Address</CategoryTitle>
                                <CategoryInfo style={{ paddingTop: 20 }}>
                                    {gmapsLoaded && (
                                        <PlacesAutocomplete
                                            value={street}
                                            onChange={this.handleChange}
                                            onSelect={this.handleSelect}
                                        >
                                            {({ getInputProps, suggestions, getSuggestionItemProps }) => (
                                                <Grid item xs={11} sm={11}>
                                                    <Input
                                                        fullWidth
                                                        error={!isStreet}
                                                        style={{ marginLeft: 17, marginRight: 17, marginBottom: 0 }}
                                                        helperText={!isStreet ? 'Please enter your street address' : ''}
                                                        label="Street"
                                                        variant="outlined"
                                                        {...getInputProps({
                                                            className: 'locations-search-input',
                                                        })}
                                                    />
                                                    <Grid
                                                        item
                                                        xs={11}
                                                        justify="center"
                                                        sm={12}
                                                        style={{
                                                            marginLeft: 'auto',
                                                            marginRight: 'auto',
                                                            marginBottom: 10,
                                                            marginTop: 0,
                                                            minWidth: '95%',
                                                        }}
                                                    >
                                                        {suggestions.map((suggestion) => {
                                                            const className = suggestion.activate
                                                                ? 'suggestion-item--active'
                                                                : 'suggestion-item';
                                                            const style = suggestion.active
                                                                ? {
                                                                      backgroundColor: '#c9c9c9',
                                                                      cursor: 'pointer',
                                                                      marginLeft: 17,
                                                                  }
                                                                : {
                                                                      backgroundColor: '#ffffff',
                                                                      cursor: 'pointer',
                                                                      marginLeft: 17,
                                                                  };
                                                            return (
                                                                <div
                                                                    {...getSuggestionItemProps(suggestion, {
                                                                        className,
                                                                        style,
                                                                    })}
                                                                >
                                                                    <span>{suggestion.description}</span>
                                                                </div>
                                                            );
                                                        })}
                                                    </Grid>
                                                </Grid>
                                            )}
                                        </PlacesAutocomplete>
                                    )}
                                    <Grid item xs={11} sm={11} style={{ marginTop: 20 }}>
                                        <Input
                                            fullWidth
                                            error={!isCity}
                                            helperText={!isCity ? 'Please enter your city' : ''}
                                            id="city"
                                            label="City"
                                            variant="outlined"
                                            value={city}
                                            onChange={(e) => onChangeText('city', e)}
                                            style={{ backgroundColor: '#ffffff', marginLeft: 17 }}
                                        />
                                    </Grid>
                                    <Grid item xs={11} sm={11} justify="center" style={{ marginLeft: 17 }}>
                                        <DropDown
                                            white
                                            required
                                            error={!isState}
                                            errorMessage={!isState ? 'Please enter your state' : ''}
                                            selected={state}
                                            onChange={this.onDropdownChange}
                                            title="State"
                                            fields={states}
                                            style={{ marginLeft: 17 }}
                                        />
                                    </Grid>
                                    <Grid item xs={11} sm={11}>
                                        <Input
                                            fullWidth
                                            error={!isZip}
                                            helperText={!isZip ? 'Please enter your zip code' : ''}
                                            id="zip_code"
                                            label="Zip Code"
                                            variant="outlined"
                                            value={zip}
                                            onChange={(e) => onChangeText('zip', e)}
                                            style={{ backgroundColor: '#ffffff', marginLeft: 17 }}
                                        />
                                    </Grid>
                                </CategoryInfo>
                                <Grid container>
                                    <Grid item style={{ marginLeft: 17 }}>
                                        <AccountEditSaveButton
                                            onClick={() => {
                                                validate();
                                            }}
                                            address={{
                                                street: street,
                                                city: city,
                                                state: state,
                                                zip_code: zip,
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Category>
                        </InfoSection>
                    </Grid>
                </Grid>
            </div>
        );
    }
}
