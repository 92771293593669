import React from 'react';
import { Text } from 'library';
import { Grid, Tooltip } from '@mui/material';
import { InfoRounded } from '@mui/icons-material';

export function TitleDescription({
    title,
    description,
    infoText,
}: {
    title: string;
    description: string;
    infoText?: string;
}) {
    return (
        <Grid item container direction="column" style={{ width: 'unset' }}>
            <Text variant="caption" bold>
                {title}{' '}
            </Text>
            <Text variant="caption">
                {description}{' '}
                {infoText ? (
                    <Tooltip title={infoText}>
                        <InfoRounded fontSize="small" sx={{ marginTop: '-4px' }} />
                    </Tooltip>
                ) : (
                    ''
                )}
            </Text>
        </Grid>
    );
}
