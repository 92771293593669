import React from 'react';
import { Grid, IconButton, withStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Colors } from 'library';

const StyledIconButton = withStyles({
    root: {
        padding: 0,
        '&:focus': {
            outline: 'none',
        },
    },
})(IconButton);

const CloseButton = ({ onClose, id }: { onClose: () => void; id?: string }) => {
    return (
        <Grid id={id ?? ''} container item justify="flex-end" alignContent="flex-start" lg={12}>
            <StyledIconButton onClick={() => onClose()}>
                <CloseIcon style={{ color: Colors.darkNavy }} />
            </StyledIconButton>
        </Grid>
    );
};

export default CloseButton;
