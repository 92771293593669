import React from 'react';
import { Text } from 'library';
import { styled } from '@mui/material';
import { Grid } from '@material-ui/core';

export default function WorkerReviewCopy() {
    return (
        <Container>
            <Header item>
                <Text>Reviews help Tandem provide you the highest quality and most reliable staff possible!</Text>
            </Header>
            <Grid item>
                <Text>
                    When you leave a positive review, workers are rewarded and given points! If you have a poor
                    experience, you&apos;ll have a chance to tell us you don&apos;t want them back (and don&apos;t
                    worry, we won&apos;t show them the review!)
                </Text>
            </Grid>
        </Container>
    );
}

const Container = styled(Grid)({
    marginBottom: 20,
});

const Header = styled(Grid)({
    marginBottom: 10,
});
