import React, { useState, useEffect } from 'react';
import { BasicDialog, Colors } from 'library';
import { AdminProvider } from 'models';
import { getAdminProvider } from './OutreachApi';
import { consoleLogInDev } from 'shared';
import ProviderTabs from './ProviderTabs';

export default function ProviderDetailsOutreachModal({
    providerUserId,
    open,
    onClose,
}: {
    providerUserId?: number;
    open: boolean;
    onClose: () => void;
}) {
    const [provider, setProvider] = useState<AdminProvider>();
    const [userRefreshError, setUserRefreshError] = useState('');

    useEffect(() => {
        if (open && (!provider || provider.user.id !== providerUserId) && providerUserId !== -1) {
            refreshProvider();
        }
    }, [providerUserId, provider, open]);

    function refreshProvider() {
        if (providerUserId)
            getAdminProvider(providerUserId)
                .then(setProvider)
                .catch((error) => {
                    setUserRefreshError(error.message);
                    consoleLogInDev(error);
                });
    }

    return (
        <BasicDialog closeButton isOpen={open} onClose={onClose} style={classes.modal}>
            <ProviderTabs
                providerUserId={providerUserId}
                provider={provider}
                refreshProvider={refreshProvider}
                userRefreshError={userRefreshError}
            />
        </BasicDialog>
    );
}

const classes = {
    tabs: {
        '&.MuiButtonBase-root.MuiTab-root': {
            fontSize: 16,
            fontWeight: 'bold',
            textTransform: 'none',
            outline: 'none',
        },
        '&.MuiTab-textColorInherit': {
            color: Colors.darkNavy,
        },
        '&.Mui-selected': {
            color: Colors.turquoise,
        },
    },
    panels: {
        '&.MuiTabPanel-root': {
            paddingTop: '20px',
            paddingBottom: '20px',
        },
    },
    modal: {
        width: '80%',
        maxWidth: 'unset',
    },
    tabIndicator: {
        backgroundColor: Colors.turquoise,
        height: 3,
    },
};
