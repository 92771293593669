import { Grid } from '@mui/material';
import React from 'react';
import { Text, TextButton, Colors, Highlight } from 'library';
import Header from 'assets/images/marketing-site/SubstituteHeader.png';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

export default function SubstituteHeader({ signUp }: { signUp: () => void }) {
    return (
        <Grid
            xs={12}
            style={{
                backgroundImage: `url(${Header})`,
                height: 900,
                backgroundSize: '100%',
                backgroundRepeat: 'no-repeat',
            }}
        >
            <Grid
                xs={12}
                md={6}
                item
                container
                style={{ height: '100%', padding: 80, gap: 20 }}
                justifyContent="center"
                direction="column"
            >
                <Text variant="display">
                    <Highlight color={Colors.lightRed}>Get the coverage you need when you need it.</Highlight>
                </Text>
                <Grid
                    item
                    container
                    flexDirection="column"
                    style={{ gap: 20, backgroundColor: 'rgba(255,255,255,0.4)', padding: 20 }}
                >
                    <Text>
                        In the event of a call off, a planned vacation, or an extended leave, Tandem's got your back.
                        Our ready-to-work substitutes can be booked for a single day or an ongoing need.
                    </Text>
                    <TextButton
                        onClick={() => signUp()}
                        buttonStyle={{ width: 200 }}
                        textStyle={{ textAlign: 'left', width: '100%', fontWeight: 'bold' }}
                    >
                        <Text variant="h2" bold>
                            Start Now <ArrowForwardIosIcon style={{ color: Colors.darkNavy, fontSize: 18 }} />
                        </Text>
                    </TextButton>
                </Grid>
            </Grid>
        </Grid>
    );
}
