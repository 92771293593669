import React, { createContext, useContext, useEffect, useState } from 'react';
import { consoleLogInDev } from 'shared';
import { getLocationStatuses, getLocationStaticStatuses } from 'api/BusinessLocationStatusApi';

import type { BusinessLocationStatus } from 'models/BusinessLocationStatus';

interface BusinessLocationsContextType {
    loading: boolean;
    statuses: BusinessLocationStatus[];
    statusColors: Record<number, Record<string, string>>;
    staticStatuses: BusinessLocationStatus[];
    staticStatusColors: Record<number, Record<string, string>>;
}

export const BusinessLocationsContext = createContext<BusinessLocationsContextType>({} as BusinessLocationsContextType);

export function BusinessLocationsContextProvider({ children }: { children: React.ReactNode[] | React.ReactNode }) {
    const [statusOptions, setStatusOptions] = useState<BusinessLocationStatus[]>([]);
    const [staticStatusOptions, setStaticStatusOptions] = useState<BusinessLocationStatus[]>([]);
    const [statusColors, setStatusColors] = useState<Record<number, Record<string, string>>>({});
    const [staticStatusColors, setStaticStatusColors] = useState<Record<number, Record<string, string>>>({});
    const [loading, setLoading] = useState(false);

    function reduceColors(statuses: BusinessLocationStatus[]) {
        return statuses.reduce((acc: Record<number, Record<string, string>>, status: BusinessLocationStatus) => {
            acc[`${status.id}`] = { color: `#${status.color}`, textColor: `#${status.text_color}` };
            return acc;
        }, {});
    }

    useEffect(() => {
        setLoading(true);

        Promise.all([getLocationStatuses(), getLocationStaticStatuses()])
            .then(([statusResponse, staticStatusResponse]) => {
                setStatusOptions(statusResponse.results);
                setStatusColors(reduceColors(statusResponse.results));
                setStaticStatusOptions(staticStatusResponse);
                setStaticStatusColors(reduceColors(staticStatusResponse));
            })
            .catch(() => consoleLogInDev('Error fetching statuses'))
            .finally(() => setLoading(false));
    }, []);

    const contextValue = {
        statuses: statusOptions,
        statusColors,
        staticStatuses: staticStatusOptions,
        staticStatusColors,
        loading,
    };

    return <BusinessLocationsContext.Provider value={contextValue}>{children}</BusinessLocationsContext.Provider>;
}

export function useBusinessLocationsContext() {
    return useContext(BusinessLocationsContext);
}
