import React, { useState } from 'react';
import Slider from '@material-ui/core/Slider';
import { Grid } from '@mui/material';
import { Text } from 'library';
import { useEffect } from 'react';
import { UpcomingJobModel } from 'parent-portal/jobs/nestedJobList/jobListModels';
import { Job } from 'models';

export default function PaySlider({
    job,
    onPayChange,
    payValue,
}: {
    job: UpcomingJobModel | Job;
    onPayChange: (n: number) => void;
    payValue: number;
}) {
    const [maxPay, setMaxPay] = useState(80);
    const isFixedPay = job.pay_is_fixed;

    useEffect(() => {
        const initialPayValue = job.pay ? job.pay : job.rate_max ? job.rate_max : 17;

        setMaxPay(initialPayValue * 3);
        onPayChange(initialPayValue);
    }, [job]);

    function changePay(event: any, newValue: number | number[]) {
        if (typeof newValue === 'number') {
            onPayChange(newValue);
        }
    }

    const marks = [
        {
            value: isFixedPay ? 10 : 8,
            label: isFixedPay ? '$10' : '$8',
        },
        {
            value: isFixedPay ? maxPay : 80,
            label: isFixedPay ? `$${maxPay}` : '$80',
        },
    ];
    const jobCreationPayCopy =
        !!job.rate_min && !!job.rate_max
            ? `When creating the job you set the pay to be  $${job.rate_min}-${job.rate_max}/hr.`
            : job.pay
            ? `When creating the job you set the pay to be ${job.pay}${job.pay_is_fixed ? '.' : '/hr.'}`
            : '';

    return (
        <Grid container direction="row" md={8} xs={12}>
            <Text variant="body1" bold>
                {jobCreationPayCopy}
            </Text>
            <Text variant="body1">
                {isFixedPay
                    ? `Select the total amount you will pay the provider for this job:`
                    : `Select the hourly pay rate you will pay the provider for this job:`}
            </Text>

            <Slider
                style={{ marginTop: 40 }}
                value={payValue}
                onChange={changePay}
                valueLabelDisplay="on"
                max={isFixedPay ? maxPay : 80}
                min={isFixedPay ? 10 : 8}
                step={1}
                marks={marks}
            />
            {isFixedPay ? <Text variant="deprecatedCaption">*The average rate for jobs is $17/hour</Text> : null}
        </Grid>
    );
}
