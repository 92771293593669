import React from 'react';
import { FormFieldProps } from '../../FormTypes';
import { Grid } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/moment';
import moment from 'moment';
import { useWidth } from 'reusableComponents/useWidth';
import JobDatePicker from './JobDatePicker';

interface JobDateRangePickerProps {
    startDate: FormFieldProps<moment.Moment | null>;
    endDate: FormFieldProps<moment.Moment | null>;
    minRangeInDays?: number;
}

export default function JobDateRangePicker({ startDate, endDate, minRangeInDays = 14 }: JobDateRangePickerProps) {
    const width = useWidth();

    const dateHasError =
        endDate.value !== null &&
        startDate.value !== null &&
        (endDate.value?.endOf('day') as moment.Moment).diff(startDate.value?.startOf('day') as moment.Moment, 'days') >=
            minRangeInDays;

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container>
                <Grid item style={{ marginRight: width === 'xs' ? 0 : 30, width: width === 'xs' ? '100%' : undefined }}>
                    <JobDatePicker
                        jobDate={startDate}
                        additionalErrorState={(startDate.value as moment.Moment) < (endDate.value as moment.Moment)}
                        dependency={[startDate.value, endDate.value]}
                        id="start-date"
                        maxDate={moment().add(1, 'years')}
                    />
                </Grid>
                <Grid item style={{ width: width === 'xs' ? '100%' : undefined }}>
                    <JobDatePicker
                        jobDate={endDate}
                        minDate={moment().add(minRangeInDays, 'days')}
                        maxDate={moment(startDate.value).add(1, 'years')}
                        additionalErrorState={dateHasError}
                        errorMessage={`Date range must be longer than ${minRangeInDays} days`}
                        dependency={[startDate.value, endDate.value]}
                        id="end-date"
                    />
                </Grid>
            </Grid>
        </MuiPickersUtilsProvider>
    );
}
