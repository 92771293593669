import { styled } from '@mui/material';
import { Colors } from 'library';

export const HorizontalContainer = styled('div')(({ color }: { color?: Colors }) => ({
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: color || Colors.lightBlue,
}));

export const SlotDiv = styled('div')({
    border: `solid ${Colors.darkNavy} 2px`,
    paddingTop: 5,
    paddingBottom: 5,
    backgroundColor: Colors.darkNavy,
    marginBottom: 5,
    borderRadius: 8,
});
