import { Grid } from '@material-ui/core';
import { Text, SecondaryButton, PrimaryButton } from 'library';
import React from 'react';

interface IProps {
    cancelAction: () => void;
    confirmAction: () => void;
}
export default function AddressBlocker({ cancelAction, confirmAction }: IProps) {
    return (
        <Grid container style={{ padding: 20 }}>
            <Grid container>
                <Text variant="display">Uh oh! Your address will be out of our supported region.</Text>
            </Grid>
            <Grid container style={{ paddingTop: 30 }}>
                <Text variant="body1">
                    Being outside of our supported region will cause you to be unable to use certain parts of the
                    platform.
                </Text>
            </Grid>
            <Grid container style={{ paddingTop: 30 }}>
                <Text variant="h1">Are you sure you want to continue?</Text>
            </Grid>
            <Grid container direction="row-reverse" style={{ paddingTop: 30 }}>
                <Grid item>
                    <Grid container>
                        <Grid item style={{ paddingRight: 10 }}>
                            <SecondaryButton onClick={cancelAction}>Cancel</SecondaryButton>
                        </Grid>
                        <Grid item>
                            <PrimaryButton onClick={confirmAction}>Continue</PrimaryButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}
