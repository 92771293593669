import React from 'react';
import { styled } from '@mui/material';
import { Colors, Text } from 'library';
import { ExtendedPayScale, ExtendedPayScaleQualification } from 'parent-portal/forms/JobRequestTypes';

export default function BusinessJobPayQualifications({ payScale }: { payScale: ExtendedPayScale }) {
    const { rate, qualifications } = payScale;

    function transformName(qualification: ExtendedPayScaleQualification) {
        const { name, option, value } = qualification;
        const sanitizedName = name.includes('Years experience') ? 'Years experience' : name;

        if (option) {
            return `${sanitizedName}: ${option}`;
        } else if (value && value !== 'true') {
            return `${sanitizedName}: ${value}`;
        }

        return sanitizedName;
    }

    return (
        <Container>
            <Text>
                ${rate}: {!qualifications.length && 'No Qualifications.'}
            </Text>
            <PillContainer>
                {qualifications.map((qualification: ExtendedPayScaleQualification) => (
                    <Pill key={qualification.id}>
                        <Text variant="caption">{transformName(qualification)}</Text>
                    </Pill>
                ))}
            </PillContainer>
        </Container>
    );
}

const Container = styled('div')({
    marginTop: 10,
});

const PillContainer = styled('div')({
    display: 'flex',
    flexWrap: 'wrap',
});

const Pill = styled('div')({
    backgroundColor: Colors.lightTurq,
    borderRadius: 8,
    padding: 4,
    marginRight: 4,
    marginTop: 4,
    width: 'fit-content',
});
