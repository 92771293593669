import React from 'react';
import { Colors, Text } from 'library';
import { Review } from 'models';
import { Stars } from '@material-ui/icons';
import { isLoggedIn } from 'api/UserApi';
import PetsIcon from '@material-ui/icons/Pets';
import FavoriteIcon from '@material-ui/icons/Favorite';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBroom } from '@fortawesome/free-solid-svg-icons';
import CheckIcon from '@material-ui/icons/Check';

export default function ProviderReview({ review }: { review: Review }) {
    function getIconFromJobType(jobTypeText: string) {
        switch (jobTypeText) {
            case 'Pet Care':
                return <PetsIcon style={{ fontSize: '14pt' }} />;
            case 'House Cleaning':
                return <FontAwesomeIcon icon={faBroom} />;
            case 'Child Care':
                return <FavoriteIcon style={{ fontSize: '14pt' }} />;
            default:
                return <CheckIcon style={{ fontSize: '14pt' }} />;
        }
    }

    const internalUser = isLoggedIn();
    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 12 }}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <Text variant="h2" bold>
                        {review.reviewer_name} {review.reviewer_last_initial}
                    </Text>
                    <Text variant="body2" color={Colors.mediumGrey}>
                        {review.reviewer_location}
                    </Text>
                    <Text variant="body2" color={Colors.mediumGrey}>
                        {review.time_employed}
                    </Text>
                </div>
            </div>
            <div
                style={{
                    borderRadius: '18px',
                    backgroundColor: Colors.lightTurq,
                    paddingTop: '5px',
                    paddingBottom: '5px',
                    paddingLeft: '10px',
                    paddingRight: '10px',
                    marginBottom: '12px',
                    color: Colors.darkNavy,
                    width: 'fit-content',
                }}
            >
                <span style={{ fontWeight: 700 }}>
                    {getIconFromJobType(review.job_type_string)} {review.job_type_string}.
                </span>{' '}
                {review.job_details}
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 12 }}>
                {[1, 2, 3, 4, 5].map((score) => (
                    <Stars key={score} htmlColor={score <= review.total_score ? Colors.turquoise : Colors.mediumGrey} />
                ))}
            </div>
            {internalUser && (
                <Text variant="body2" color={Colors.mediumGrey}>
                    {review.text}
                </Text>
            )}
        </div>
    );
}
