import React from 'react';
import { ISpotlightWorker } from '../Models/DashboardData';
import { Avatar, Grid, styled } from '@mui/material';
import { Checkbox, Colors, Text, TruncateText } from 'library';

export default function WorkerSpotlightCard({
    worker,
    selected,
    toggleSelected,
}: {
    worker: ISpotlightWorker;
    selected: boolean;
    toggleSelected: (worker: ISpotlightWorker) => void;
}) {
    function updateChipText(text: string) {
        const vals = text.split(': ');
        const isYearsExperience = text.includes('experience');

        return isYearsExperience ? `${vals[1]} years of experience` : vals[1];
    }

    function getChipColor(text: string) {
        const isYearsExperience = text.includes('experience');

        return isYearsExperience ? Colors.lightTerraCotta : Colors.mediumTurq;
    }

    const credentialsSet = new Set(worker.credentials.map(updateChipText));
    const credentials = Array.from(credentialsSet.values());

    return (
        <CardContainer item sm={4}>
            <CardContent>
                <Grid container alignItems="center" justifyContent="space-between">
                    <HeaderContainer>
                        <WorkerAvatar style={{ width: 55, height: 55 }} src={worker.pictureUrl} />
                        <Text variant="h2" bold textStyle={{ display: 'inline' }}>
                            {worker.name}
                        </Text>
                    </HeaderContainer>
                    <Checkbox
                        label=""
                        labelTitle="Select this teacher"
                        checked={selected}
                        onChange={() => toggleSelected(worker)}
                        style={{ width: 'auto' }}
                    />
                </Grid>
                <ImageCredentialContainer>
                    <div>
                        {credentials.map((x, index) => (
                            <CredentialPill key={index} backgroundColor={getChipColor(x)}>
                                {x}
                            </CredentialPill>
                        ))}
                    </div>
                </ImageCredentialContainer>
                <TruncateText text={worker.bio} />
            </CardContent>
        </CardContainer>
    );
}

const CardContainer = styled(Grid)({
    padding: 10,
});

const CardContent = styled(Grid)({
    borderRadius: 20,
    padding: 10,
    boxShadow: '0px 3px 6px #00000029',
});

const HeaderContainer = styled('div')({
    display: 'flex',
    alignItems: 'center',
});

const WorkerAvatar = styled(Avatar)({
    color: Colors.white,
    backgroundColor: Colors.darkNavy,
    width: 150,
    height: 150,
    borderRadius: '50%',
    marginTop: 10,
    marginBottom: 10,
    display: 'inline-flex',
    marginRight: 10,
});

const ImageCredentialContainer = styled('div')({
    display: 'flex',
});

const CredentialPill = styled('span')(({ backgroundColor }: { backgroundColor: string }) => ({
    fontSize: 10,
    color: Colors.darkNavy,
    backgroundColor,
    borderRadius: 20,
    margin: 5,
    padding: '5px 10px',
    display: 'inline-block',
    fontWeight: 'bold',
}));
