import React from 'react';
import moment from 'moment';
import { Text, Colors } from 'library';
import { AdminProvider } from 'models';

export function FingerPrintAppointmentInfo({ provider }: { provider: AdminProvider }) {
    if (!provider?.bci_fbi_info_collect.length) return null;

    return (
        <div>
            <Text variant="h2" bold textStyle={{ margin: 10, color: Colors.turquoise }}>
                Fingerprint Appointment Info
            </Text>
            <Text variant="body2" textStyle={{ margin: 10 }}>
                Time: {moment(provider?.bci_fbi_info_collect[0].appointment_time).format('MM/DD/YYYY HH:mm A')}
            </Text>
            <Text variant="body2" textStyle={{ margin: 10 }}>
                Appointment ID: {provider?.bci_fbi_info_collect[0].appointment_id}
            </Text>
            <Text variant="body2" textStyle={{ margin: 10 }}>
                Location: {provider?.bci_fbi_info_collect[0].appointment_location_street_address}{' '}
                {provider?.bci_fbi_info_collect[0].appointment_location_city},{' '}
                {provider?.bci_fbi_info_collect[0].appointment_location_state}{' '}
                {provider?.bci_fbi_info_collect[0].appointment_location_zip}
            </Text>
        </div>
    );
}
