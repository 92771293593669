import React, { useState } from 'react';
import { Grid, useMediaQuery } from '@material-ui/core';
import { Button } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { client, formatAddress } from 'shared';
import { Checkbox, PrimaryButton, SizeableRoundedDialog, Text, TextButton } from 'library';
import { Address, IPayMethod } from 'models';
import theme from 'theme';
import { useUserContext } from 'UserContext';

export default function BusinessPaymentMethod({
    payMethod,
    updatePayMethods,
}: {
    payMethod: IPayMethod;
    updatePayMethods: (a: Address[]) => void;
}) {
    const { user } = useUserContext();
    const smDown = useMediaQuery(theme.breakpoints.down('sm'));
    const user_addresses = user?.address;
    const [addAddress, setAddAddress] = useState<IPayMethod>();
    const [addresses, setAddresses] = useState<Address[]>([]);
    const [loading, setLoading] = useState(false);

    function deleteAddressPayMethod(address: Address) {
        let newAddresses = payMethod.addresses.filter((a) => a.id !== address.id) ?? [];
        client('api/addresses/update-address-payment-info/', {
            body: { address_ids: [address.id] },
        }).then(() => updatePayMethods(newAddresses));
    }

    function updateAddresses(address: Address) {
        const addressIndex = addresses.findIndex((a) => a.id === address.id);
        addressIndex === -1
            ? setAddresses([...addresses, address])
            : setAddresses(addresses.filter((a) => a.id !== address.id));
    }

    function submitAddressPayMethods() {
        let newAddresses = [...payMethod.addresses, ...addresses];
        setLoading(false);
        client('api/addresses/update-address-payment-info/', {
            body: { address_ids: addresses.map((a) => a.id), payment_information_id: addAddress?.id },
        })
            .then(() => setAddAddress(undefined))
            .finally(() => {
                updatePayMethods(newAddresses);
                setLoading(false);
            });
    }

    return (
        <>
            <Grid>
                {payMethod.addresses
                    ? payMethod.addresses.map((addr) => (
                          <Grid
                              key={addr.id}
                              style={{ margin: '10px 0' }}
                              container
                              item
                              justify="space-between"
                              alignItems="center"
                          >
                              <Text
                                  textStyle={{ marginLeft: '1em' }}
                                  variant="caption"
                                  bold
                              >{`${addr.street} ${addr.city}, ${addr.state}`}</Text>
                              <TextButton
                                  buttonStyle={{ padding: '0.25em 1em', margin: '0.25em', minWidth: 0, width: 'unset' }}
                                  textStyle={{ fontSize: 12 }}
                                  onClick={() => deleteAddressPayMethod(addr)}
                              >
                                  Remove
                              </TextButton>
                          </Grid>
                      ))
                    : null}
                {payMethod.addresses.length !== user_addresses?.length ? (
                    <Button
                        endIcon={<AddCircleOutlineIcon />}
                        onClick={() => {
                            setAddresses([]);
                            setAddAddress(payMethod);
                        }}
                    >
                        Add New
                    </Button>
                ) : null}
            </Grid>
            <SizeableRoundedDialog
                fullScreen={smDown}
                open={!!addAddress}
                style={{ maxWidth: smDown ? '100%' : 250 }}
                onClose={() => {
                    setAddresses([]);
                    setAddAddress(undefined);
                }}
                closeButton
            >
                <Grid container item direction="column" style={{ padding: 10 }}>
                    <Text variant="h1">
                        Add address to {addAddress?.account_type} *{addAddress?.last_four} payment method
                    </Text>
                    <Text textStyle={{ margin: '20px 0' }} variant="h2" bold>
                        Addresses
                    </Text>

                    {user_addresses
                        ?.filter((a) => !addAddress?.addresses.some((x) => x.id === a.id))
                        .map((x) => (
                            <Checkbox
                                key={x.id}
                                label={formatAddress(x)}
                                checked={(addresses || []).some((a) => a.id === x.id)}
                                onChange={() => updateAddresses(x)}
                            />
                        ))}
                    <PrimaryButton
                        buttonStyle={{ width: 100, alignSelf: 'end' }}
                        onClick={submitAddressPayMethods}
                        disabled={addresses.length === 0 || loading}
                    >
                        Submit
                    </PrimaryButton>
                </Grid>
            </SizeableRoundedDialog>
        </>
    );
}
