import React, { useState } from 'react';
import { BasicDialog, OutlinedTextInput, PrimaryButton, SecondaryButton, Text } from 'library';
import { client, formatE164USPhoneNumber, formatReadablePhoneNumber, stripPhoneNumber, isValidPhone } from 'shared';

export default function OutreachNewMessageModal({
    open,
    onClose,
    search,
    senderPhone,
    selectedNumber,
}: {
    open: boolean;
    onClose: () => void;
    search: string;
    senderPhone: string;
    selectedNumber?: string;
}) {
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const [sending, setSending] = useState(false);
    const [sent, setSent] = useState(false);

    function sendExternalMessage() {
        setSending(true);
        let userPhoneNumber;
        try {
            userPhoneNumber = selectedNumber ? formatUserPhoneNumber(selectedNumber) : formatUserPhoneNumber(search);
        } catch (error) {
            setError((error as Error).message);
        }
        if (userPhoneNumber) {
            client('tracking/api/external-message/manual-text-send/', {
                method: 'POST',
                body: {
                    user_phone_number: userPhoneNumber,
                    phone_number: senderPhone,
                    message: message,
                },
            })
                .then((res) => {
                    if (res?.status && res.status === 'fail') {
                        throw new Error(res.message);
                    }
                    setSent(true);
                })
                .catch((e) => {
                    setError(e.message || `Oh no it failed, ask a dev what's up.`);
                })
                .finally(() => {
                    setMessage('');
                });
        }
        setSending(false);
    }

    function formatUserPhoneNumber(search: string) {
        if (search.match(/\D/g)?.length === search.length) {
            throw new Error('please search a phone number or select a messaging thread');
        }
        const phone_number = stripPhoneNumber(search);
        if (isValidPhone(phone_number)) {
            return formatE164USPhoneNumber(phone_number).toString();
        }
        throw new Error('please check the search phone number');
    }

    function displayUserPhoneNumber(search: string) {
        if (search.match(/\D/g)?.length === search.length) {
            return search;
        }
        const phone_number = stripPhoneNumber(search);
        if (isValidPhone(phone_number)) {
            return formatReadablePhoneNumber(phone_number);
        }
        return phone_number;
    }

    function resetModal() {
        setMessage('');
        setError('');
        setSending(false);
        setSent(false);
    }

    return (
        <BasicDialog
            closeButton
            isOpen={open}
            onClose={() => {
                onClose();
                setSent(false);
                setError('');
            }}
        >
            <Text variant="h2" bold>
                {`Sending a message to ${displayUserPhoneNumber(search)}`}
            </Text>
            <OutlinedTextInput
                multiline
                fullWidth
                label="Message to send:"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                style={{ marginTop: 20 }}
            />

            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    marginTop: 20,
                    marginBottom: 20,
                }}
            >
                {!!error && (
                    <SecondaryButton buttonStyle={{ width: 100, marginRight: 10 }} onClick={resetModal}>
                        Reset
                    </SecondaryButton>
                )}
                <PrimaryButton
                    loading={sending}
                    buttonStyle={{ width: 100 }}
                    disabled={sending || sent || !!error}
                    onClick={sendExternalMessage}
                >
                    {sent ? 'Sent!' : 'Send'}
                </PrimaryButton>
            </div>
            <Text>{error}</Text>
        </BasicDialog>
    );
}
