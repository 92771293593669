import React, { useState } from 'react';
import { EventRepeat } from '@mui/icons-material';
import { IconButton } from 'library';

import { PastAppointmentModal } from './PastAppointmentModal';

export function PastAppointmentButton({
    ongoingRequestId,
    refresh,
}: {
    ongoingRequestId: number;
    refresh?: () => void;
}) {
    const [open, setOpen] = useState(false);

    return (
        <span style={{ marginLeft: 10 }}>
            <IconButton tooltip="Edit a past appointment" icon={EventRepeat} onClick={() => setOpen(true)} />
            <PastAppointmentModal ongoingRequestId={ongoingRequestId} open={open} setOpen={setOpen} onClose={refresh} />
        </span>
    );
}
