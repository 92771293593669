import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import StyledDivider from '../reusableComponents/StyledDivider';
import { Colors } from '../constants';
import { CartItemMappingModel } from './SitterPaymentModels';
import { useStyles } from './CartStyles';

interface CartRowProps {
    itemRow: CartItemMappingModel;
    formatter: Intl.NumberFormat;
}

const CartRow = ({ itemRow, formatter }: CartRowProps) => {
    const cartStyles = useStyles();
    return (
        <>
            <Grid container className={cartStyles.cartItemRow}>
                <Typography className={cartStyles.cartText}>{itemRow.item.title}</Typography>
                <Typography className={cartStyles.cartText}>{formatter.format(itemRow.item.price)}</Typography>
            </Grid>
            <StyledDivider style={{ backgroundColor: Colors.earth }} />
        </>
    );
};

export default CartRow;
