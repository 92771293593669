import React, { useState } from 'react';
import { styled } from '@mui/material';
import { Edit, Save } from '@mui/icons-material';
import { Colors, IconButton, OutlinedTextInput, Text } from 'library';
import { client } from 'shared';
import { formatMonthDayYearWith12HourTime } from 'shared/Dates';

import type { Note } from 'models';

export function LocationNoteCommunicationRow({ note }: { note: Note }) {
    const [text, setText] = useState<string>(note.text);
    const [temporaryText, setTemporaryText] = useState<string>(note.text);
    const [editing, setEditing] = useState<boolean>(false);

    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);

    function submit() {
        if (temporaryText && temporaryText !== text) {
            setLoading(true);
            setError(false);

            client(`api/note/${note.id}/`, { body: { text: temporaryText }, method: 'PATCH' })
                .then((response) => {
                    setText(response.text);
                    setTemporaryText(response.text);
                    setEditing(false);
                })
                .catch(() => setError(true))
                .finally(() => setLoading(false));
        }
    }

    return (
        <Row>
            <div style={{ width: '100%' }}>
                <Subheader>
                    <Text variant="h2" bold>
                        Note
                    </Text>
                    <Text variant="body2" textStyle={{ paddingLeft: 5 }}>
                        from {`${note.author.first_name} ${note.author.last_name}`}{' '}
                    </Text>
                </Subheader>
                {editing ? (
                    <>
                        <OutlinedTextInput
                            multiline
                            fullWidth
                            style={{ marginTop: 10 }}
                            inputProps={{ style: { fontSize: 14 } }}
                            value={temporaryText}
                            onChange={(e) => setTemporaryText(e.target.value)}
                            disabled={loading}
                        />
                        {error && (
                            <Text variant="body2" textStyle={{ paddingTop: 10, color: Colors.error }}>
                                We had a problem saving your note. Please try again.
                            </Text>
                        )}
                    </>
                ) : (
                    <Text textStyle={{ paddingTop: 10 }} variant="body2">
                        {text}
                    </Text>
                )}
            </div>
            <Container>
                <Text variant="body2" textStyle={{ color: Colors.grey, whiteSpace: 'nowrap' }}>
                    {formatMonthDayYearWith12HourTime(note.added_at)}
                </Text>
                <Buttons>
                    <IconButton
                        icon={Edit}
                        size={24}
                        style={{ marginRight: 10 }}
                        onClick={() => setEditing(!editing)}
                    />
                    <IconButton
                        disabled={!editing || temporaryText === text || !temporaryText}
                        icon={Save}
                        size={24}
                        onClick={submit}
                    />
                </Buttons>
            </Container>
        </Row>
    );
}

const Row = styled('div')({
    borderBottom: `1px solid ${Colors.lightGrey}`,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 10,
    width: '100%',
});

const Subheader = styled('div')({
    alignItems: 'baseline',
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
});

const Container = styled('div')({
    height: '100%',
    minWidth: '12%',
    paddingLeft: 10,
});

const Buttons = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    paddingTop: 10,
});
