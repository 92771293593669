import usePlaid from 'parent-portal/payments/setup/components/PlaidHook';
import { PlaidAccount } from 'parent-portal/payments/setup/components/PlaidTypes';
import PlaidAccountSelection from 'parent-portal/payments/setup/PlaidAccountSelection';
import React, { useEffect } from 'react';

export default function PlaidDialog({
    onReady,
    onAccountSelected,
    onAbort,
}: {
    onReady: () => void;
    onAccountSelected: (accountId: PlaidAccount['id']) => void;
    onAbort: () => void;
}) {
    const { accounts, token: plaidToken, ready, exited } = usePlaid();

    useEffect(() => {
        ready && onReady();
    }, [ready]);

    useEffect(() => {
        exited && onAbort();
    }, [exited]);

    return <PlaidAccountSelection accounts={accounts} token={plaidToken} onSelectAccount={onAccountSelected} />;
}
