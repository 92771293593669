import React from 'react';
import { Text, PrimaryButton } from 'library';
import { Grid, useMediaQuery } from '@mui/material';
import HomeWorkerGraphic from 'assets/images/marketing-site/home-worker-graphic.png';
import DoubleCheckIcon from 'assets/images/marketing-site/double-check-icon.svg';
import PinkPartialCircle from 'assets/images/marketing-site/pink-partial-circle.svg';
import { useHistory } from 'react-router';
import theme from 'theme';

const reasons = ['Stay in compliance', 'Have autonomy over their schedule', 'Access earnings immediately'];

export function WhyWorkersLikeUs() {
    const history = useHistory();
    const smDown = useMediaQuery(theme.breakpoints.down('sm'));
    return (
        <Grid container item style={{ gap: 80, padding: smDown ? 40 : 80, marginBottom: 40 }}>
            <Grid container item xs={12} md={6}>
                <PinkPartialCircle width={706} height={592} />
                <Grid
                    item
                    xs={10}
                    style={{ position: 'relative', left: '5%', bottom: smDown ? '80%' : '110%', height: 0, width: 0 }}
                >
                    <img src={HomeWorkerGraphic} alt="home-worker-graphic" style={{ width: '120%', height: 'auto' }} />
                </Grid>
            </Grid>
            <Grid container item md={5} xs={12} style={{ paddingTop: '20%' }}>
                <Grid style={{ display: 'inline-block' }}>
                    <Grid container item xs={12} style={{ gap: 20 }}>
                        <Text variant="display">Why do workers like us?</Text>
                        {reasons.map((item: string) => (
                            <Grid container item direction="row" key={item} alignItems="center" style={{ gap: 20 }}>
                                <DoubleCheckIcon width={15} height={15} />
                                <Text variant="body1">{item}</Text>
                            </Grid>
                        ))}
                        <PrimaryButton
                            onClick={() => history.push('/workers')}
                            buttonStyle={{ width: 200, maxHeight: 50 }}
                        >
                            Create Profile
                        </PrimaryButton>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}
