import { DayOfWeekSelections, JobTimes, TemplateOption } from 'parent-portal/forms/JobRequestTypes';
import { BusinessJobTemplateDetailsDTO, DayToIsoWeekDay } from 'parent-portal/forms/JobTemplateModels';
import { formatDateFromDateObject } from 'shared/Dates';
import moment from 'moment';
import { JobFormPresets, toJobFormPresets } from 'parent-portal/forms/JobFormPresets';
import { addDays, nextDay } from 'date-fns';
import { getMondayForNextWeek } from 'internal/substitutes/weekdayConstants';

export function toTemplate(
    template: BusinessJobTemplateDetailsDTO,
    startDate: moment.Moment | null,
    endDate: moment.Moment | null,
    daysOfTheWeek: DayOfWeekSelections,
    jobTimes: JobTimes | null,
    sameTimes: boolean,
) {
    const schedules = Object.entries(daysOfTheWeek)
        .filter(([, values]) => values.selected)
        .map(([day, values]) => ({
            // eslint-disable-next-line security/detect-object-injection
            day: DayToIsoWeekDay[day],
            start_time:
                sameTimes && jobTimes?.start ? jobTimes.start.format('HH:mm:ss') : values.start.format('HH:mm:ss'),
            end_time: sameTimes && jobTimes?.end ? jobTimes.end.format('HH:mm:ss') : values.end.format('HH:mm:ss'),
            slots: values.slots,
            timezone: values.timezone,
            id: values.id,
        }));
    const val = {
        ...template,
        start_date: (startDate && formatDateFromDateObject(startDate.toDate())) ?? template.start_date,
        end_date: (endDate && formatDateFromDateObject(endDate.toDate())) ?? template.end_date,
        daily_schedules: schedules,
    };
    return val;
}

export function trialRunToSubJobFormPresets(
    trialRunTemplate: BusinessJobTemplateDetailsDTO,
    useTemplate: TemplateOption,
    currentPage: number,
): [BusinessJobTemplateDetailsDTO, JobFormPresets] {
    const template = { ...trialRunTemplate };
    template.business_job_type = 'SUBSTITUTE';
    template.start_date = getFirstDay(template);
    template.end_date = getLastDay(template);
    return [
        template,
        {
            ...toJobFormPresets(template),
            useTemplate: useTemplate,
            pageNumber: currentPage,
            trialRunCoverage: true,
            businessJobType: 'SUBSTITUTE',
            draftJobId: 0,
            multipleProviders: true,
        },
    ];
}

function getFirstDay(job: BusinessJobTemplateDetailsDTO) {
    const sortedSchedules = job.daily_schedules.sort((a, b) => a.day - b.day);
    let firstDayOfWeek = null;
    for (const schedule of sortedSchedules) {
        const nextDateForDay = nextDay(new Date(), schedule.day as Day);
        if (!firstDayOfWeek || nextDateForDay < firstDayOfWeek) {
            firstDayOfWeek = nextDateForDay;
        }
    }
    return formatDateFromDateObject(firstDayOfWeek || getMondayForNextWeek(new Date()));
}

function getLastDay(job: BusinessJobTemplateDetailsDTO) {
    const lastDayOfWeek = Math.max(...job.daily_schedules.map((schedule) => schedule.day));
    return formatDateFromDateObject(addDays(getMondayForNextWeek(new Date()), lastDayOfWeek - 1));
}
