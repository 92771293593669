import React, { useState, useEffect, Dispatch, SetStateAction } from 'react';
import DateFnsUtils from '@date-io/moment';
import { styled } from 'styled-components';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Text, OutlinedTextInput, IconButton, SmallIcon } from 'library';
import { Colors } from '../../library/theme';
import { Grid } from '@material-ui/core';
import { Edit, Save } from '@mui/icons-material';

const CustomizedDatePicker = styled(KeyboardDatePicker)`
    & .MuiInputBase-input {
        font-size: 18px;
        font-family: Outfit;
        width: 71%;
    }
`;

export default function ILFingerprintCheckFormField({
    fieldNameDisplay,
    fieldNameValue,
    setFieldNameValue,
    isErrorState,
}: {
    fieldNameDisplay: string;
    fieldNameValue: string;
    setFieldNameValue: Dispatch<SetStateAction<string>>;
    isErrorState: boolean;
}) {
    const [editField, setEditField] = useState<boolean>(false);

    const iconSize = 30;

    useEffect(() => {
        if (isErrorState) {
            setEditField(true);
        }
    }, [isErrorState]);

    function handleOnChange(textValue: string) {
        const cleanedTextValue =
            fieldNameDisplay === 'SSN Last 4' ? onChangeSocialSecurityNumber(textValue) : textValue.trim();
        setFieldNameValue(cleanedTextValue);
    }

    const onChangeSocialSecurityNumber = (text: string) => {
        const cleaned = ('' + text).replace(/\D/g, '');
        const match = cleaned.match(/^(\d{4})$/);
        return match ? match[0] : cleaned;
    };

    function getErrorMessage() {
        return !fieldNameValue ? '' : 'Invalid Date Format';
    }

    const CustomizedIcon = () => (
        <div onClick={() => setEditField(true)}>
            <SmallIcon
                icon={
                    !editField ? <Edit style={styles.datePickerIconIcon} /> : <Save style={styles.datePickerIconIcon} />
                }
                style={styles.datePickerIcon}
            />
        </div>
    );

    return (
        <Grid container direction="row" alignItems="center" style={styles.formFieldGrid}>
            <Grid item xs={6}>
                <Text variant="h1">{fieldNameDisplay}</Text>
                {fieldNameDisplay.includes('SSN') && (
                    <Text variant="caption">* Leave blank to use what is on file</Text>
                )}
            </Grid>

            <Grid item xs={6} container direction="row" justify="flex-end">
                <Grid item xs={8} container direction="row" justify="flex-end" alignItems="center">
                    {fieldNameDisplay === 'Date of Birth' ? (
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <CustomizedDatePicker
                                autoOk
                                onClose={() => setEditField(false)}
                                format="MM/DD/YYYY"
                                mask="__/__/____"
                                value={fieldNameValue}
                                onChange={(e) => handleOnChange(e ? e.format('MM/DD/YYYY') : '')}
                                openTo="date"
                                style={{
                                    float: 'right',
                                }}
                                onFocus={() => setEditField(true)}
                                onBlur={() => setEditField(false)}
                                keyboardIcon={<CustomizedIcon />}
                                InputProps={{
                                    disableUnderline: true,
                                    fullWidth: false,
                                }}
                                invalidDateMessage={getErrorMessage()}
                                KeyboardButtonProps={{ edge: 'start' }}
                            />
                        </MuiPickersUtilsProvider>
                    ) : (
                        <>
                            <Grid item xs={9}>
                                {editField ? (
                                    <OutlinedTextInput
                                        value={fieldNameValue}
                                        style={isErrorState ? styles.fieldInputError : styles.fieldInput}
                                        placeholder={fieldNameDisplay}
                                        onChange={(e) => handleOnChange(e.target.value)}
                                        type="text"
                                    />
                                ) : (
                                    <Text variant="h2">{fieldNameValue}</Text>
                                )}
                            </Grid>
                            <Grid item xs={3}>
                                <IconButton
                                    icon={!editField ? Edit : Save}
                                    size={iconSize}
                                    onClick={() => setEditField(!editField)}
                                />
                            </Grid>
                        </>
                    )}
                </Grid>
            </Grid>
        </Grid>
    );
}

const styles = {
    formFieldGrid: {
        height: '85px',
    },
    displayText: {
        paddingRight: '15px',
    },
    fieldInput: {
        backgroundColor: 'white',
        padding: 0,
        borderRadius: 15,
    },
    fieldInputError: {
        backgroundColor: 'white',
        padding: 0,
        borderRadius: 15,
    },
    datePickerIcon: {
        padding: 0,
        minWidth: 'unset',
        borderRadius: '50%',
        boxShadow: '0px 3px 8px #00000029',
        height: 30,
        width: 30,
        outline: 'none',
        backgroundColor: Colors.white,
        color: Colors.darkNavy,
    },
    datePickerIconIcon: {
        fontSize: '80%',
    },
};
