import React from 'react';
import { Grid, List, ListItem, ListItemText, Link, IconButton, Tooltip } from '@mui/material';
import { Colors } from 'library';
import DownloadIcon from '@mui/icons-material/Download';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

import PaySectionHeader from './PaySectionHeader';
import { PayBackgroundPaper } from './PayPapers';
import { useUserContext } from 'UserContext';
import LocationQRCode from './business/LocationQRCode';
import { downloadQRCode } from './business/downloadQRcode';

export default function PaymentLocationQRCodes() {
    const { user, getDisplayText } = useUserContext();

    const tooltipTxt = `Providers can scan QR codes on arrival at your ${getDisplayText(
        'center',
    )} to report their hours.
        Download your QR codes here and place them where providers can access them at the beginning
        and end of their shifts to ensure their hours and payments are accurate.`;

    return (
        <PayBackgroundPaper>
            <Grid container item xs={12} alignItems="center">
                <Grid item>
                    <PaySectionHeader>My QR Codes</PaySectionHeader>
                </Grid>
                <Grid item>
                    <Tooltip title={tooltipTxt} style={{ padding: 0, margin: '0 0 25px 5px' }}>
                        <IconButton>
                            <HelpOutlineIcon />
                        </IconButton>
                    </Tooltip>
                </Grid>
            </Grid>
            <List>
                {user?.address.map((address, i) => (
                    <>
                        <ListItem
                            key={i}
                            secondaryAction={
                                <IconButton edge="end">
                                    <DownloadIcon onClick={() => downloadQRCode(address)} />
                                </IconButton>
                            }
                        >
                            <ListItemText
                                primary={
                                    <Link href={`/clock-in/?loc_id=${address.id}`} color={Colors.darkNavy}>
                                        {address.description}
                                    </Link>
                                }
                                secondary={`${address.street} ${address.city} ${address.state} ${address.zip_code}`}
                            ></ListItemText>
                        </ListItem>
                        <div style={{ display: 'none' }}>
                            <LocationQRCode addressId={String(address.id)} />
                        </div>
                    </>
                ))}
            </List>
        </PayBackgroundPaper>
    );
}
