import React from 'react';
import { DayOfWeekSelections } from 'parent-portal/forms/JobRequestTypes';
import { FormFieldProps } from '../../FormTypes';
import { Grid, Typography, Checkbox } from '@material-ui/core';
import { getDayString } from 'parent-portal/forms/day-operations';
import { useFieldValidation } from '../../FormHook';
import { checkIfAllDaysHaveTime, checkIfAnyDayIsSelected } from './JobTimeValidation';

interface JobSameTimeProps {
    daysOfTheWeek: FormFieldProps<DayOfWeekSelections>;
    isSameTime: FormFieldProps<boolean>;
}

export default function JobSameTimePicker({ isSameTime, daysOfTheWeek }: JobSameTimeProps) {
    const selectedDays = Object.entries(daysOfTheWeek.value).filter(([_, day]) => day.selected);

    useFieldValidation(
        daysOfTheWeek,
        isSameTime.value ? checkIfAnyDayIsSelected(daysOfTheWeek.value) : checkIfAllDaysHaveTime(daysOfTheWeek.value),
        [isSameTime.value],
    );

    function handleClick() {
        isSameTime.setValue(!isSameTime.value);
    }

    return selectedDays.length > 1 ? (
        <Grid container xs={12} alignItems="center" style={{ flexWrap: 'nowrap', marginBottom: 5 }}>
            <Checkbox
                disableRipple
                disableTouchRipple
                color="primary"
                checked={isSameTime.value}
                onChange={() => handleClick()}
                value="primary"
            />
            <Typography variant="subtitle1">
                The times and number of workers are the same for <b>{getDayString(selectedDays)}</b>
            </Typography>
        </Grid>
    ) : null;
}
