import React from 'react';
import { Colors, OptionButton } from 'library';
import { OnboardingStep, useOnboardingContext } from '../../OnboardingContext';
import { RestartAlt } from '@mui/icons-material';
import { useUserContext } from 'UserContext';

export default function ShortTerm() {
    const { goToStep } = useOnboardingContext();
    const { getDisplayText } = useUserContext();

    return (
        <OptionButton
            icon={<RestartAlt htmlColor={Colors.turquoise} fontSize="large" />}
            title="Short-term or temporary (Fastest)"
            highlight={`One-time or short-term ${getDisplayText('substitutes')}`}
            subtitle="You need credentialed childcare workers on a short-term or as-needed basis."
            action={() => goToStep(OnboardingStep.DoYouKnowTheDates, 'Do you know the dates')}
            id="get-started-short-term-card"
        />
    );
}
