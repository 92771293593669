import React from 'react';

import BackgroundSvg from 'assets/images/FamilyLandingPage.svg';

export default function FamilyLandingBackground({ children }: { children: React.ReactNode }) {
    return (
        <div
            style={{
                backgroundImage: `url(${BackgroundSvg})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                paddingTop: 66,
                paddingBottom: 76,
            }}
        >
            {children}
        </div>
    );
}
