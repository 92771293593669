import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableFooter } from '@mui/material';
import { LoadingSpinner } from 'library';

export interface AdminTableProps {
    columns: string[];
    children: React.ReactNode;
    header?: React.ReactNode;
    footer?: React.ReactNode;
    loading?: boolean;
}

export default function AdminTable({ columns, children, header, footer, loading }: AdminTableProps) {
    return (
        <TableContainer>
            {header}
            <Table aria-label="simple table">
                <TableHead>
                    <TableRow>
                        {columns.map((col) => (
                            <TableCell key={col}>{col}</TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                {loading ? <LoadingSpinner /> : <TableBody>{children}</TableBody>}
                {footer ? (
                    <TableFooter>
                        <TableRow>
                            <TableCell colSpan={10}>{footer}</TableCell>
                        </TableRow>
                    </TableFooter>
                ) : null}
            </Table>
        </TableContainer>
    );
}
