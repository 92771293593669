import { BusinessJobWithAppointments, PagingResult } from 'models';
import React, { useCallback, useEffect, useState } from 'react';
import { LoadingSpinner, Text } from 'library';
import { weekdayHeaders } from 'internal/substitutes/weekdayConstants';

import JobTable from 'internal/substitutes/Table/JobTable';
import { getOpenSubJobs } from 'internal/substitutes/substituteApi';

export default function BusinessLocationJobTable({
    businessLocationId,
    week,
    includeClosed,
}: {
    businessLocationId: number;
    week: Date;
    includeClosed: boolean;
}) {
    const [page, setPage] = useState(1);
    const [jobs, setJobs] = useState<PagingResult<BusinessJobWithAppointments>>();
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);

    const getJobs = useCallback(() => {
        const options = { start_date: week, businessLocationId, page, includeClosed };
        getOpenSubJobs(options)
            .then((response) => {
                setError(false);
                setJobs(response);
            })
            .catch(() => setError(true))
            .finally(() => setLoading(false));
    }, [week, page, businessLocationId, includeClosed]);

    useEffect(() => {
        getJobs();
    }, [getJobs]);

    if (loading) return <LoadingSpinner />;
    if (error) return <Text>Something went wrong when getting jobs for this week.</Text>;

    return jobs?.results.length ? (
        <JobTable
            showLocationInfo={false}
            data={jobs}
            columns={['Job Details', ...weekdayHeaders(week)]}
            week={week}
            refresh={() => getJobs()}
            updateJobs={() => {}}
            page={page}
            updatePage={setPage}
        />
    ) : (
        <Text>No jobs for this location.</Text>
    );
}
