import React, { useEffect } from 'react';
import { useJobFormContext } from 'parent-portal/forms/JobFormContext';
import EditDialog from './EditDialog';
import BusinessAddressSelection from '../../BusinessLocationPage/components/BusinessAddressSelection';

export default function EditJobLocationModal({ open, onClose }: { open: boolean; onClose: () => void }) {
    const { form } = useJobFormContext();
    const { address, headline } = form.fieldStates;
    const [isAddingAddress, setIsAddingAddress] = React.useState(false);

    useEffect(() => {
        address.setValid(address.value?.length > 0);
    }, [address]);

    return (
        <EditDialog
            title={`Edit ${headline.value} Locations`}
            open={open}
            onClose={onClose}
            isValid={address.isValid}
            hideSubmit={isAddingAddress}
        >
            <BusinessAddressSelection isAddingAddress={isAddingAddress} setIsAddingAddress={setIsAddingAddress} />
        </EditDialog>
    );
}
