import { CircularProgress, Grid } from '@material-ui/core';
import React from 'react';

export default function LoadingIndicator({ size }: { size?: number }) {
    return (
        <Grid container justify="center">
            <CircularProgress size={size} />
        </Grid>
    );
}
