import { Days, OngoingJobModel, UpcomingJobModel } from 'parent-portal/jobs/nestedJobList/jobListModels';
import { Colors } from 'library';
import moment from 'moment';
import { EventType, CalendarType, CalendarColorType } from './models';

const days: Days[] = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
export const CalendarColors: CalendarColorType = {
    ongoing: Colors.lightTerraCotta,
    onetime: Colors.lightTurq,
    allday: Colors.lightGreen,
    draft: Colors.mediumRed,
};

function eventCategory(j: UpcomingJobModel | OngoingJobModel) {
    if (j.all_day) {
        return 'allday';
    } else if (j.timeless || j.dateless) {
        return 'task';
    } else {
        return 'time';
    }
}

function nextDateForDOW(dow: Days, w: number, d: Date) {
    d.setDate(d.getDate() + (days.indexOf(dow) + (w * 7 - d.getDay())));
    return d;
}

function updateDateWithTime(time: string, date: Date) {
    let mDate = moment(date);
    let mTime = moment(time, 'HH:mm');

    mDate.set({
        hour: mTime.get('hour'),
        minute: mTime.get('minute'),
        second: mTime.get('second'),
    });

    return mDate.toDate();
}

function calendarBackgroundColor(j: UpcomingJobModel) {
    let draftJob = j.status === 'DRAFT';
    if (j.ongoing && !draftJob && !j.all_day) {
        return CalendarColors.ongoing;
    } else if (!draftJob && !j.all_day) {
        return CalendarColors.onetime;
    } else {
        return CalendarColors.allday;
    }
}

function createJobPattern(j: OngoingJobModel) {
    if (!j.job_day_pattern) return [];

    let date = new Date();
    let count = 0;
    let appts: EventType[] = [];

    let jobEndDate = j.end_date ? moment(j.end_date).toDate() : new Date();
    if (!j.end_date && j.start_month) {
        let tempDate = new Date(j.start_month);
        jobEndDate = new Date(tempDate.setMonth(tempDate.getMonth() + 2));
    }
    let jobOverallStartDate = j.start_date
        ? moment(j.start_date).toDate()
        : j.start_month
        ? new Date(j.start_month)
        : new Date();
    function getDay(day: Days, d: Date) {
        return nextDateForDOW(day, count, d);
    }
    while (date < jobEndDate) {
        let d: Date | null = null;
        Object.keys(j.job_day_pattern).forEach((day: string) => {
            // you have to duplicate the overallstartdate or it will be edited directly, beware
            d = getDay(day as Days, moment(jobOverallStartDate).toDate());
            let dow: Days = days[d.getDay()];
            let endDate = updateDateWithTime(j.job_day_pattern[dow].end, d);
            let startDate = updateDateWithTime(j.job_day_pattern[dow].start, d);
            if (
                endDate.toLocaleDateString('en-US') === jobEndDate.toLocaleDateString('en-US') ||
                (endDate <= jobEndDate && startDate >= jobOverallStartDate)
            ) {
                appts.push({
                    id: `${j.id}-${d}`,
                    calendarId: j.id.toString(),
                    title: j.headline,
                    category: eventCategory(j),
                    start: startDate,
                    end: endDate,
                    isReadOnly: true,
                });
            }
        });
        date = d ?? date;
        count++;
    }
    return appts;
}

function formatJobs(jobs: UpcomingJobModel[] | OngoingJobModel[]) {
    let appts: EventType[] = [];
    let calendars: CalendarType[] = [];
    jobs.forEach((j: UpcomingJobModel) => {
        let draftJob = j.status === 'DRAFT';
        if (draftJob) {
            return;
        }

        calendars.push({ id: j.id.toString(), name: j.headline, backgroundColor: calendarBackgroundColor(j) });
        if (j.ongoing && ((j.start_date && j.end_date) || j.start_month)) {
            appts = [...appts, ...createJobPattern(j as OngoingJobModel)];
        } else {
            if (j.appointments.length > 0) {
                j.appointments.forEach((a) => {
                    appts.push({
                        id: a.id.toString(),
                        calendarId: j.id.toString(),
                        title: j.headline,
                        category: eventCategory(j),
                        start: a.start_date,
                        end: a.end_date,
                        isReadOnly: true,
                    });
                });
            } else {
                appts.push({
                    id: j.id.toString(),
                    calendarId: j.id.toString(),
                    title: j.headline,
                    category: eventCategory(j),
                    start: j.start_date ? moment(j.start_date).toDate() : updateDateWithTime('8:00', new Date()),
                    end: j.end_date ? moment(j.end_date).toDate() : updateDateWithTime('8:00', new Date()),
                    isReadOnly: true,
                });
            }
        }
    });

    return { calendars, appts };
}

export function formatCalendarEvents(
    jobs: UpcomingJobModel[] | OngoingJobModel[],
    handleCalendarResult: (c: CalendarType[]) => void,
    handleApptResult: (a: EventType[]) => void,
) {
    let { calendars: jobCals, appts: jobEvents } = formatJobs(jobs);
    handleCalendarResult(jobCals);
    handleApptResult(jobEvents);
}
