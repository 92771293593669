import React, { forwardRef } from 'react';
import { Grid } from '@mui/material';
import { Text, Colors } from 'library';

const CircleNumber = forwardRef(({ index }: { index: number }, ref: React.Ref<HTMLInputElement>) => {
    return (
        <Grid
            ref={ref}
            key={index}
            item
            container
            style={{
                height: 75,
                width: 75,
                borderRadius: 100,
                backgroundColor: Colors.lightRed,
            }}
            justifyContent="center"
            alignItems="center"
        >
            <Text
                textStyle={{
                    color: Colors.pink,
                    fontSize: 40,
                    width: 'unset',
                    textAlign: 'center',
                }}
            >
                {index + 1}
            </Text>
        </Grid>
    );
});

export default CircleNumber;
