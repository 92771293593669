import React from 'react';
import { Colors, OptionButton } from 'library';
import { OnboardingStep, useOnboardingContext } from '../../../OnboardingContext';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';

export function ImmediateNeed() {
    const { goToStep } = useOnboardingContext();
    const { submitAction } = useOnboardingContext();

    return (
        <OptionButton
            icon={<CalendarTodayIcon htmlColor={Colors.turquoise} fontSize="large" />}
            title="Immediate Need"
            highlight="I need help now."
            subtitle={`I am currently looking for a temporary worker or have a permanent opening.`}
            action={() => submitAction(() => goToStep(OnboardingStep.ShortOrLongTerm), 'Immediate Need')}
            id="get-started-immediate-need-card"
        />
    );
}
