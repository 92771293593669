import { PagingResult } from 'models';
import { IDiscount, IAdminDiscount, IBusinessUserDiscount } from 'models/Discount';
import { client } from 'shared';

export function list(addressId: number, status: string = 'open'): Promise<PagingResult<IAdminDiscount>> {
    return client(`payment/api/discount/?address_id=${addressId}&status=${status}`);
}

export function listForBusiness(): Promise<PagingResult<IBusinessUserDiscount>> {
    return client(`payment/api/discount/?status=open`);
}

export function createDiscount(addressId: number, value: number, user: number, notes?: string): Promise<IDiscount> {
    return client('payment/api/discount/', {
        body: {
            value,
            address: addressId,
            type: 'FIXED_AMOUNT',
            user,
            notes,
        },
    });
}
