import React, { useState } from 'react';
import { Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import FCRADisclosure from './FCRADisclosure';

const Disclosure = () => {
    const [showDisclosure, setShowDisclosure] = useState(false);
    return (
        <>
            <Typography variant="subtitle2" align="left" style={{ marginBottom: 10 }}>
                By submitting payment, I agree to this{' '}
                <Link
                    to="/sitter/payment"
                    style={{ fontWeight: 'bold' }}
                    onClick={() => {
                        setShowDisclosure(true);
                    }}
                >
                    Authorization Form.
                </Link>
            </Typography>
            <FCRADisclosure isOpen={showDisclosure} onClose={() => setShowDisclosure(false)} />
        </>
    );
};

export default Disclosure;
