import React from 'react';
import { daysOfTheWeek } from 'shared/Dates';
import { BusinessJobWithAppointments, SubJobClosedAppointment } from 'models';
import { isSameDay } from 'date-fns';
import { JobSlots } from './Slots';
import SubJobClosedAppointmentEntry from './SubJobClosedAppointmentEntry';

export function TimesForDay({
    job,
    refresh,
    updateFlag,
    date,
}: {
    job: BusinessJobWithAppointments;
    refresh: () => void;
    updateFlag: (staffId: number, businessLocationId: number) => void;
    date: Date;
}) {
    const slots = job.slots.filter((slot) => isSameDay(date, new Date(slot.start_date)));
    const closed_appointments = job.closed_appointments.filter((appt) => isSameDay(date, new Date(appt.start_date)));
    const day = daysOfTheWeek[date.getDay()];
    return (
        <>
            <JobSlots
                jobId={job.id}
                businessLocationId={job.address.business_location?.id}
                utcOffset={job.slots.length > 0 ? job.slots[0].timezone_offset : -5}
                date={date}
                slots={slots}
                updateFlag={updateFlag}
                refreshJobs={refresh}
                times={job.times_of_day.find((timeOfDay) => Object.keys(timeOfDay)[0] === day)}
            />
            {closed_appointments.map((appt: SubJobClosedAppointment) => (
                <SubJobClosedAppointmentEntry key={appt.id} appt={appt} />
            ))}
        </>
    );
}
