import { Grid } from '@mui/material';
import { Colors, LoadingSpinner, OutlinedTextInput, PrimaryButton, SecondaryButton, Text } from 'library';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { client, consoleLogInDev } from 'shared';
import { ConfirmEmploymentHistoryParams, EmploymentHistory } from './models';
import { EmploymentDetails } from './EmploymentDetails';
import NoEmploymentHistory from './NoEmploymentHistory';
import EmploymentHistorySubmitted from './EmploymentHistorySubmitted';
import { format, isBefore } from 'date-fns';

export default function ConfirmEmploymentHistory() {
    const { id: uuid } = useParams<ConfirmEmploymentHistoryParams>();
    const [employmentHistory, setEmploymentHistory] = useState<EmploymentHistory>();
    const [employerComments, setEmployerComments] = useState<string>('');
    const url = `api/licensed-center-employment/${uuid}/verify-employment/`;
    const [submitted, setSubmitted] = useState(false);
    const [loading, setLoading] = useState(false);
    const [jobDates, setJobDates] = useState<{ start: Date; end: Date }>({
        start: new Date(),
        end: new Date(),
    });

    useEffect(() => {
        if (uuid) {
            setLoading(true);
            client(url)
                .then((res) => {
                    if (res === 'No record found') {
                        setEmploymentHistory(undefined);
                        return;
                    } else {
                        setJobDates({ start: new Date(res.from_date), end: new Date(res.end_date) });
                        setEmploymentHistory(res);
                    }
                })
                .catch(consoleLogInDev)
                .finally(() => setLoading(false));
        }
    }, [uuid]);

    function submitData(verified: boolean) {
        setLoading(true);
        client(url, {
            method: 'PATCH',
            body: {
                verified_by_employer: verified || undefined,
                employer_comments: employerComments || undefined,
                from_date: format(jobDates.start, 'yyyy-MM-dd'),
                end_date: format(jobDates.end, 'yyyy-MM-dd'),
            },
        })
            .catch(consoleLogInDev)
            .finally(() => {
                setSubmitted(true);
                setLoading(false);
            });
    }

    if (loading) return <LoadingSpinner />;

    if (!employmentHistory) return <NoEmploymentHistory />;

    const providerName = `${employmentHistory.provider.user.first_name} ${employmentHistory.provider.user.last_name}`;
    const disabledButton = isBefore(jobDates.end, jobDates.start);

    return (
        <Grid style={{ padding: 50 }} item container justifyContent="center">
            <Grid
                xs={12}
                md={8}
                style={{ padding: 20, gap: 20, backgroundColor: Colors.lightTurq, borderRadius: 18 }}
                item
                container
                direction="column"
            >
                <Text variant="h1">Verify Employment History for {providerName}</Text>
                {submitted ? (
                    <EmploymentHistorySubmitted providerName={providerName} />
                ) : (
                    <>
                        <Text>
                            {providerName} reported the below dates of employment at {employmentHistory.center_name}.
                            Please confirm or explain any discrepancies.
                        </Text>
                        <Grid item container style={{ gap: 20 }}>
                            <EmploymentDetails
                                employmentHistory={employmentHistory}
                                jobDates={jobDates}
                                setJobDates={setJobDates}
                            />

                            <Grid style={{ gap: 10 }} direction="column" container item xs={12}>
                                <Text bold>
                                    Employer Comments: <Text inline>(optional)</Text>
                                </Text>
                                <OutlinedTextInput
                                    multiline
                                    value={employerComments}
                                    onChange={(e) => setEmployerComments(e.target.value)}
                                    style={{ width: '80%', borderRadius: 18, backgroundColor: Colors.white }}
                                />
                            </Grid>
                            <SecondaryButton
                                buttonStyle={{ maxWidth: 300 }}
                                onClick={() => submitData(false)}
                                disabled={disabledButton}
                            >
                                Deny Employment
                            </SecondaryButton>
                            <PrimaryButton
                                buttonStyle={{ maxWidth: 300 }}
                                onClick={() => submitData(true)}
                                disabled={disabledButton}
                            >
                                Verify Employment
                            </PrimaryButton>
                        </Grid>
                    </>
                )}
            </Grid>
        </Grid>
    );
}
