import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp, faUserCheck } from '@fortawesome/free-solid-svg-icons';
import { Colors, Text, TextButton } from 'library';
import React, { CSSProperties, useEffect } from 'react';
import { Collapse } from '@mui/material';

export default function TandemHiringPolicy({ initialExpandedState }: { initialExpandedState: boolean }) {
    const [expanded, setExpanded] = React.useState(initialExpandedState);
    useEffect(() => {
        if (!initialExpandedState) setExpanded(false);
    }, [initialExpandedState]);
    return (
        <div style={styles.mainContainer}>
            <div style={styles.outerHeadingContainer}>
                <div style={styles.innerHeadingContainer}>
                    <div style={styles.circularContainer}>
                        <FontAwesomeIcon icon={faUserCheck} style={styles.icon} />
                    </div>
                    <Text variant="h2" textStyle={{ fontWeight: 'bold' }}>
                        Tandem Hiring Policy
                    </Text>
                </div>
                <div>
                    <TextButton disableRipple buttonStyle={styles.button} onClick={() => setExpanded(!expanded)}>
                        Learn More <FontAwesomeIcon icon={expanded ? faChevronUp : faChevronDown} />
                    </TextButton>
                </div>
            </div>
            <Collapse in={expanded}>
                <Text variant="body1">There is a 35% fee on top of the worker’s hourly rate.</Text>
                <Text>
                    All workers (substitute or permanent) can be onboarded permanently to your staff after 120 hours of
                    them working at your center. There is no additional cost. Just let your Tandem account manager know!
                </Text>
            </Collapse>
        </div>
    );
}

const styles: { [key: string]: CSSProperties } = {
    mainContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: 20,
        marginTop: 30,
    },
    outerHeadingContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: 10,
    },
    innerHeadingContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 8,
    },
    icon: {
        color: '#F9CEC8',
        fontSize: 16,
    },
    circularContainer: {
        borderRadius: '50%',
        backgroundColor: Colors.terraCotta,
        width: 38,
        height: 38,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    button: {
        width: undefined,
    },
};
