import React from 'react';
import { IconButton, Colors } from 'library';
import { Edit } from '@mui/icons-material';
import { styled } from '@mui/material';

export default function SectionContainer({
    children,
    editModal,
    setEditOpen,
    error,
}: {
    children: React.ReactNode;
    editModal?: React.ReactNode;
    setEditOpen?: (open: boolean) => void;
    error?: boolean;
}) {
    return (
        <SectionDiv error={error}>
            <div>{children}</div>
            {setEditOpen && <IconButton icon={Edit} onClick={() => setEditOpen(true)} />}
            {!!editModal && editModal}
        </SectionDiv>
    );
}

const SectionDiv = styled('div')(({ error }: { error?: boolean }) => ({
    padding: '20px 10px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: error ? Colors.lightRed : undefined,
}));
