import React, { ReactNode } from 'react';
import Grid from '@material-ui/core/Grid';
import { useMediaQuery } from '@material-ui/core';
import theme from 'theme';
import PaymentPartners from 'parent-portal/payments/TandemPayInfo/PaymentPartners';

interface FooterProps {
    children?: ReactNode | ReactNode[];
}

export default function PageFooter({ children }: FooterProps) {
    const small = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Grid
            container
            justify="space-between"
            direction={'row'}
            wrap="wrap"
            style={{ height: small ? undefined : 70, paddingBottom: 10 }}
        >
            <div style={{ paddingRight: 16, flexGrow: small ? 1 : 0 }}>{children}</div>
            <div style={{ display: 'flex', flexGrow: 1, justifyContent: 'flex-end', marginTop: small ? 16 : 0 }}>
                <div style={{ display: 'flex', flexDirection: 'column', marginRight: 45 }}>
                    <PaymentPartners />
                </div>
            </div>
        </Grid>
    );
}
