import React, { useState } from 'react';
import { useBusinessLocationReviews } from '../api';
import { LoadingSpinner, Text } from 'library';
import { Accordion, AccordionDetails, AccordionSummary, Grid, styled } from '@mui/material';
import CenterReview from './components/CenterReview';
import { Review } from 'internal/texting/outreach/components/WorkerHistory/Reviews';
import { ExpandMore } from '@mui/icons-material';

export default function BusinessLocationReviews({ businessLocationId }: { businessLocationId: number }) {
    const { data, status, error } = useBusinessLocationReviews(businessLocationId);
    const [expandedTab, setExpandedTab] = useState<string>('received');

    if (status === 'loading') return <LoadingSpinner />;
    if (status === 'error') return <Text>{error}</Text>;

    const providerReviews = data?.worker_reviews ?? [];
    const reviewsReceived = data?.reviews_received ?? [];
    return (
        <Container container item gap={2}>
            <StyledAccordion expanded={expandedTab === 'received'}>
                <StyledAccordionSummary expandIcon={<ExpandMore onClick={() => setExpandedTab('received')} />}>
                    <Text bold variant="h2">
                        Reviews Received ({reviewsReceived.length})
                    </Text>
                </StyledAccordionSummary>
                <StyledAccordionDetails>
                    <Grid container item gap={1}>
                        {reviewsReceived.length > 0 ? (
                            reviewsReceived.map((review) => (
                                <Grid key={review.id} container item xs={12} sm={4}>
                                    <CenterReview review={review} />
                                </Grid>
                            ))
                        ) : (
                            <Text>No Reviews Received</Text>
                        )}
                    </Grid>
                </StyledAccordionDetails>
            </StyledAccordion>
            <StyledAccordion expanded={expandedTab === 'given'}>
                <StyledAccordionSummary expandIcon={<ExpandMore onClick={() => setExpandedTab('given')} />}>
                    <Text bold variant="h2">
                        Provider Reviews ({providerReviews.length})
                    </Text>
                </StyledAccordionSummary>
                <StyledAccordionDetails>
                    <Grid container item gap={1}>
                        {providerReviews.length > 0 ? (
                            providerReviews.map((review) => (
                                <Grid key={review.id} container item xs={12} sm={4}>
                                    <Review review={review} view="business" />
                                </Grid>
                            ))
                        ) : (
                            <Text>No Provider Reviews</Text>
                        )}
                    </Grid>
                </StyledAccordionDetails>
            </StyledAccordion>
        </Container>
    );
}

const Container = styled(Grid)({
    padding: 20,
});

const StyledAccordion = styled(Accordion)({
    borderRadius: 18,
    width: '100%',
    marginTop: 5,
    position: 'unset',
});

const StyledAccordionSummary = styled(AccordionSummary)({
    cursor: 'initial',
});

const StyledAccordionDetails = styled(AccordionDetails)({
    flexWrap: 'wrap',
});
