import React from 'react';

import BankIcon from 'assets/icons/tandempay/bank-circle.svg';
import CheckmarkIcon from 'assets/icons/tandempay/checkmark-circle.svg';
import CheckIcon from 'assets/icons/tandempay/personal-check-circle.svg';
import ModalOptionRow from '../ModalOptionRow';
import PageHeader from '../PageHeader';
import PageActionsFooter from '../PageActionsFooter';
import ModalPage from '../ModalPage';
import PageContent from '../PageContent';

interface ConnectBankAccountPageProps {
    goToManualVerification: () => void;
    goToInstantVerification: () => void;
    goBack: () => void;
}

export default function ConnectBankAccountPage({
    goToManualVerification,
    goToInstantVerification,
    goBack,
}: ConnectBankAccountPageProps) {
    return (
        <ModalPage>
            <PageHeader
                iconSource={<BankIcon />}
                headerText="Connect Bank Account"
                subtitleText="You can connect your bank account instantly or manually."
            />
            <PageContent>
                <ModalOptionRow
                    onClick={goToInstantVerification}
                    icon={<CheckmarkIcon />}
                    titleText="Instant Verification"
                    bodyText="This is our quickest option. Sign into your bank to verify through Plaid. You'll be able to pay providers immediately."
                />
                <ModalOptionRow
                    onClick={goToManualVerification}
                    icon={<CheckIcon />}
                    titleText="Manual Verification"
                    bodyText="Use your bank's account and routing number. Verification takes up to 3 days and will require confirmation of microdeposits."
                />
            </PageContent>
            <PageActionsFooter goBack={goBack} />
        </ModalPage>
    );
}
