import { Colors } from 'library';

import type { BusinessLocationStatus } from 'models/BusinessLocationStatus';

export function getPageNumber(query: URLSearchParams) {
    const pageNumQuery = query.get('page');
    return pageNumQuery ? parseInt(pageNumQuery) : 1;
}

export function getQuerystringObject(query: URLSearchParams, page?: number) {
    return {
        page: page ?? getPageNumber(query),
        regions: query.get('regions'),
        statuses: query.get('statuses'),
        static_statuses: query.get('static_statuses'),
        open_jobs: query.get('open_jobs'),
        posted_never_transacted: query.get('posted_never_transacted'),
        jobs_posted: query.get('jobs_posted'),
        no_jobs_posted: query.get('no_jobs_posted'),
        jobs_this_week: query.get('jobs_this_week'),
        jobs_next_week: query.get('jobs_next_week'),
        none_this_week: query.get('none_this_week'),
        none_next_week: query.get('none_next_week'),
        include_snoozed: query.get('include_snoozed'),
        business_ids: query.get('business_ids'),
        name: query.get('name'),
        trans_start: query.get('trans_start'),
        trans_end: query.get('trans_end'),
        days: query.get('days'),
        sort_by: query.get('sort_by'),
        show_in_groups: query.get('show_in_groups'),
        activity_status: query.get('activity_status'),
    };
}

export function statusColor(statusColors: Record<string, Record<string, string>>, statusId: number | undefined) {
    if (!statusId) return Colors.darkGrey;

    return statusColors[`${statusId}`].color ?? Colors.darkNavy;
}

export function statusTextColor(statusColors: Record<string, Record<string, string>>, statusId: number | undefined) {
    if (!statusId) return Colors.darkNavy;

    return statusColors[`${statusId}`].textColor ?? Colors.darkNavy;
}

export function staticStatusColor(staticStatuses: BusinessLocationStatus[], name: string) {
    const status = staticStatuses.find((location: BusinessLocationStatus) => location.name === name);

    if (!status) return Colors.darkGrey;

    return status.color ? `#${status.color}` : Colors.darkGrey;
}
