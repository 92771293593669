import React from 'react';
import { Email } from '@mui/icons-material';
import { Link, Tooltip } from '@mui/material';
import { useUserContext } from 'UserContext';
import { Colors } from 'library';

const GOOGLE_MAIL_URL = 'https://mail.google.com/mail/?view=cm&fs=1';

export default function EmailButton({ contacts }: { contacts?: string[] }) {
    const { user } = useUserContext();
    const url = `${GOOGLE_MAIL_URL}&to=${contacts?.join(';')}&authuser=${user?.email}`;

    return (
        <div>
            <Link href={url} target="_blank">
                <Tooltip title="Open gmail to send email to this center" placement="top">
                    <Email sx={{ color: Colors.darkNavy }} />
                </Tooltip>
            </Link>
        </div>
    );
}
