import { DropDown, Tabs } from 'library';
import React, { useEffect, useState } from 'react';
import BusinessLocationOverview from './BusinessLocationOverview';
import BusinessLocationWorkers from './Workers';
import BusinessLocationReviews from './Reviews';
import BusinessLocationJobs from './BusinessLocationJobs/BusinessLocationJobs';
import BusinessCommunicationHistory from './BusinessLocationOverview/BusinessCommunicationHistory';
import BusinessLocationPayments from './Payments/BusinessLocationPayments';
import { BusinessLocationOverrides } from './BusinessLocationOverride/BusinessLocationOverrides';
import { useHistory } from 'react-router';
import { Grid, styled } from '@mui/material';
import { consoleLogInDev } from 'shared';
import { AdminBusinessLocationOption } from 'models/AdminBusinessLocation';
import { getRelatedLocations } from 'api/AdminBusinessLocationApi';
import { BusinessLocationTasks } from './BusinessLocationTasks';

export default function BusinessLocationTabs({
    businessLocationId,
    initialTab,
}: {
    businessLocationId: number;
    initialTab?: string;
}) {
    const tabs = ['Overview', 'Workers', 'Reviews', 'Jobs', 'Communications', 'Tasks', 'Payments', 'Overrides'];
    const [selectedTab, setSelectedTab] = useState(initialTab ? tabs.indexOf(initialTab).toString() : '0');
    const [currentBusinessLocationId, setCurrentBusinessLocationId] = useState(businessLocationId);
    const [businessLocationOptions, setBusinessLocationOptions] = React.useState<AdminBusinessLocationOption[]>([]);

    useEffect(() => {
        getRelatedLocations(businessLocationId)
            .then((response) => {
                setBusinessLocationOptions(response);
            })
            .catch(consoleLogInDev);
    }, [businessLocationId]);

    const history = useHistory();

    function updateBusinessLocation(id: number) {
        if (window.location.pathname.includes('/business-location/')) {
            history.push(id.toString());
        } else {
            setCurrentBusinessLocationId(id);
        }
    }

    const dropdownOptions = businessLocationOptions.map((x) => {
        return {
            key: x.id,
            value: x.name,
        };
    });

    const values = [
        <BusinessLocationOverview key={'Overview'} businessLocationId={currentBusinessLocationId} />,
        <BusinessLocationWorkers key={'Workers'} businessLocationId={currentBusinessLocationId} />,
        <BusinessLocationReviews key={'Reviews'} businessLocationId={currentBusinessLocationId} />,
        <BusinessLocationJobs key={'Jobs'} businessLocationId={currentBusinessLocationId} />,
        <BusinessCommunicationHistory key="CommHistory" businessLocationId={currentBusinessLocationId} />,
        <BusinessLocationTasks
            key="Tasks"
            businessLocation={businessLocationOptions.find((x) => x.id === currentBusinessLocationId)}
            isOpen={selectedTab === tabs.indexOf('Tasks').toString()}
            businessLocationId={currentBusinessLocationId}
        />,
        <BusinessLocationPayments key="Payments" businessLocationId={currentBusinessLocationId} />,
        <BusinessLocationOverrides key="Overrides" businessLocationId={currentBusinessLocationId} />,
    ];

    return (
        <Grid container>
            <DropDownContainer container item>
                <DropDown
                    selected={currentBusinessLocationId}
                    placeholder="Business Location"
                    title="Business Location"
                    fields={dropdownOptions}
                    onChange={(e) => updateBusinessLocation(e.target.value)}
                    style={{ maxWidth: 450 }}
                />
            </DropDownContainer>
            <Tabs selectedTab={selectedTab} setSelectedTab={setSelectedTab} tabs={tabs} values={values} />
        </Grid>
    );
}

const DropDownContainer = styled(Grid)({ padding: '5px 10px' });
