import { Dialog, useMediaQuery, type DialogProps, type ModalProps } from '@mui/material';
import React, { CSSProperties, ReactNode } from 'react';
import theme from 'theme';

interface RoundedDialogProps extends DialogProps {
    open: boolean;
    children?: ReactNode | ReactNode[];
    style?: CSSProperties;
    onClose?: ModalProps['onClose'];
}

export default function RoundedDialog({ open, children, style, onClose, ...dialogProps }: RoundedDialogProps) {
    const smDown = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Dialog
            open={open}
            PaperProps={{
                style: {
                    borderRadius: 20,
                    minWidth: smDown ? '90%' : '35%',
                    ...style,
                },
            }}
            BackdropProps={{
                style: {
                    backgroundColor: `rgba(0, 47, 77, 0.75)`,
                },
            }}
            onClose={onClose}
            {...dialogProps}
        >
            {children}
        </Dialog>
    );
}
