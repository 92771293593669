import {
    BusinessPreference,
    EditBusinessPreference,
} from 'parent-portal/profile/BusinessProfile/BusinessPreferences/models';
import { client } from 'shared';

export function getBusinessPreferences(): Promise<BusinessPreference[]> {
    return client('api/business-preferences/');
}

export async function updateBusinessPreference(preference: EditBusinessPreference, id?: number) {
    const body = { ...preference, break_preferences: preference.break_preferences.map((p) => p.id) };
    const url = `api/business-preferences/${id ? `${id}/` : ''}`;
    return client(url, {
        method: id ? 'PATCH' : 'POST',
        body,
    });
}
