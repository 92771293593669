import React from 'react';
import { Link } from '@material-ui/core';
import { Colors } from '../../theme';

export default function TandemLink({
    onClick,
    style,
    children,
    color = Colors.darkNavy,
}: {
    onClick: () => void;
    style?: React.CSSProperties;
    children?: React.ReactNode;
    color?: string;
}) {
    return (
        <Link
            style={{
                color: color,
                fontSize: 14,
                cursor: 'pointer',
                fontWeight: 'bold',
                fontFamily: 'Work Sans',
                textDecoration: 'underline',
                ...style,
            }}
            onClick={onClick}
        >
            {children}
        </Link>
    );
}
