import React from 'react';
import { Colors, Text } from 'library';
import Lightbulb from 'assets/icons/tandempay/lightbulb.svg';
import Substitute from './Substitute';
import LookAroundOption from '../../shared/LookAroundOption';
import { OnboardingStep } from '../../OnboardingContext';
import GoBack from '../../shared/GoBack';

export default function DoYouKnowTheDates() {
    return (
        <>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <Lightbulb width={40} height={40} color={Colors.turquoise} />
                <Text variant="body2" textStyle={{ marginTop: 20, maxWidth: 450 }}>
                    Remember, you'll pay your providers for all short-term jobs through Tandem.
                </Text>
            </div>
            <Substitute />
            <LookAroundOption />
            <GoBack step={OnboardingStep.ShortOrLongTerm} />
        </>
    );
}
