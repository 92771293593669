import React from 'react';
import { AdminProvider } from 'models';
import { client, consoleLogInDev } from 'shared';
import StatusChanger from './StatusChanger';

export default function OCCRRAStatus({ provider, onRefresh }: { provider: AdminProvider; onRefresh: () => void }) {
    const options = [
        'VERIFIED',
        'NEEDS_TO_REQUEST',
        'AWAITING_RESULT',
        'OUT_OF_DATE_CHECK',
        'ANNUAL_OPR_NEEDED',
        'BLOCKED_BY_TWO_FA',
        'PASSWORD_CHANGED',
        'FINGERPRINTING_FLOW',
        'TANDEM_NEEDS_TO_FILE_REQUEST',
        'NEEDS_HISTORY',
        'FAILED',
    ];

    function updateStatus(newStatus: string) {
        if (provider.occrra_info_collect?.id === undefined) {
            alert('No OCCRA Info Collect ID');
            return;
        }
        client(`api/occrra-info-collect/update-status/`, {
            method: 'PATCH',
            body: { occrra: provider.occrra_info_collect?.id, status: newStatus },
        })
            .then(() => {
                onRefresh();
            })
            .catch(consoleLogInDev);
    }

    return (
        <StatusChanger
            provider={provider}
            updateStatus={updateStatus}
            status={provider.occrra_info_collect?.admin_status ? provider.occrra_info_collect?.admin_status : 'NONE'}
            options={options}
        />
    );
}
