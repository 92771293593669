import React, { useState, useEffect } from 'react';
import { Colors, PrimaryButton, SizeableRoundedDialog, Text } from 'library';
import { Grid } from '@material-ui/core';
import { differenceInMinutes } from 'date-fns';
import { formatShortWeekdayMonthDay } from 'shared/Dates';
import { approveReport, getTimestampByType } from '../HourReportMethods';
import { KeyboardTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

// eslint-disable-next-line import/named
import moment, { Moment } from 'moment';
import { IHourReport } from '../../../internal/payments/HourReportModels';
import { FaClock } from 'react-icons/fa';
import { consoleLogInDev } from 'shared';

export default function ChangeModal({
    changeReport,
    setChangeReport,
    updateHoursReported,
    fullScreen,
}: {
    changeReport: IHourReport;
    setChangeReport: (r: IHourReport | undefined) => void;
    updateHoursReported: () => void;
    fullScreen: boolean;
}) {
    const [loading, setLoading] = useState(false);

    const [breakStartErr, setBreakStartErr] = useState('');
    const [breakEndErr, setBreakEndErr] = useState('');
    const [shiftStartErr, setShiftStartErr] = useState('');
    const [shiftEndErr, setShiftEndErr] = useState('');

    const initialBreakStart =
        getTimestampByType(changeReport, 'business_break_start') ??
        getTimestampByType(changeReport, 'provider_altered_break_start') ??
        getTimestampByType(changeReport, 'provider_break_start');
    const initialBreakEnd =
        getTimestampByType(changeReport, 'business_break_end') ??
        getTimestampByType(changeReport, 'provider_altered_break_end') ??
        getTimestampByType(changeReport, 'provider_break_end');

    const [breakStart, setBreakStart] = useState<Moment | null>(initialBreakStart ? moment(initialBreakStart) : null);
    const [breakEnd, setBreakEnd] = useState<Moment | null>(initialBreakEnd ? moment(initialBreakEnd) : null);

    const initialShiftStart =
        getTimestampByType(changeReport, 'business_clock_in') ??
        getTimestampByType(changeReport, 'provider_altered_clock_in') ??
        getTimestampByType(changeReport, 'provider_clock_in') ??
        changeReport.start;
    const initialShiftEnd =
        getTimestampByType(changeReport, 'business_clock_out') ??
        getTimestampByType(changeReport, 'provider_altered_clock_out') ??
        getTimestampByType(changeReport, 'provider_clock_out') ??
        changeReport.end;

    const [shiftEnd, setShiftEnd] = useState<Moment | null>(initialShiftEnd ? moment(initialShiftEnd) : null);
    const [shiftStart, setShiftStart] = useState<Moment | null>(initialShiftStart ? moment(initialShiftStart) : null);

    useEffect(() => {
        validateShiftStart();
        validateShiftEnd();
        validateBreakStart();
        validateBreakEnd();
    }, [shiftStart, shiftEnd, breakStart, breakEnd]);

    const validateBreakStart = () => {
        if (!breakStart) return;
        const start = moment(breakStart);
        setBreakStartErr('');

        if (shiftEnd && start.isAfter(shiftEnd)) {
            setBreakStartErr("can't be after the end time");
            return;
        }
        if (start.isBefore(shiftStart)) {
            setBreakStartErr("can't be before the start time");
            return;
        }
        if (start.isAfter(breakEnd)) {
            setBreakStartErr("can't be after the break end time");
            return;
        }
        if (start && !breakEnd) {
            setBreakEndErr('is required if break start is set');
            return;
        }
        setBreakStartErr('');
    };

    const validateBreakEnd = () => {
        if (!breakEnd) return;

        const end = moment(breakEnd);

        setBreakEndErr('');

        if (shiftStart && end.isBefore(shiftStart)) {
            setBreakEndErr("can't be before the start time");
            return;
        }
        if (end.isAfter(shiftEnd)) {
            setBreakEndErr("can't be after the end time");
            return;
        }
        if (end.isBefore(breakStart)) {
            setBreakEndErr("can't be before the break start time");
            return;
        }
        setBreakEndErr('');
    };

    const validateShiftEnd = () => {
        if (!shiftEnd) return;

        const end = moment(shiftEnd);
        setShiftEndErr('');

        if (end.isSame(shiftStart)) {
            setShiftEndErr("can't be the same as the start time");
            return;
        }
        if (end.isBefore(shiftStart)) {
            setShiftEndErr("can't be before the shift start time");
            return;
        }
        if (breakStart && end.isBefore(breakStart)) {
            setShiftEndErr("can't be before the break start time");
            return;
        }
        if (breakEnd && end.isBefore(breakEnd)) {
            setShiftEndErr("can't be before the break end time");
            return;
        }
        setShiftEndErr('');
    };

    const validateShiftStart = () => {
        if (!shiftStart) return;

        const start = moment(shiftStart);
        setShiftStartErr('');

        if (start.isSame(shiftEnd)) {
            setShiftStartErr("can't be the same as the end time");
            return;
        }
        if (shiftEnd && start.isAfter(shiftEnd)) {
            setShiftStartErr("can't be after the end time");
            return;
        }
        if (breakStart && start.isAfter(breakStart)) {
            setShiftStartErr("can't be after the break start time");
            return;
        }
        if (breakEnd && start.isAfter(breakEnd)) {
            setShiftStartErr("can't be after the break end time");
            return;
        }
        setShiftStartErr('');
    };

    const hasTimestamps = changeReport.provider_break_end || changeReport.provider_break_start;

    const getBreakMinutes = () => {
        try {
            return breakStart && breakEnd
                ? differenceInMinutes(new Date(breakEnd.toISOString()), new Date(breakStart.toISOString()))
                : 0;
        } catch (error) {
            consoleLogInDev(error);
        }

        return 0;
    };
    const breakMinutes = getBreakMinutes();

    const breakText =
        breakMinutes > 0
            ? `Provider reported a ${breakMinutes} minute break, if this is incorrect use the
    selection below to update the times.`
            : 'Provider did not report a break, if they took a break use the selection below to update the times.';

    async function submitChangeRequest() {
        if (changeReport) {
            setLoading(true);
            const body = {
                business_clock_in: shiftStart ? shiftStart.toISOString() : undefined,
                business_clock_out: shiftEnd ? shiftEnd.toISOString() : undefined,
                business_break_start: breakStart ? breakStart.toISOString() : undefined,
                business_break_end: breakEnd ? breakEnd.toISOString() : undefined,
            };
            approveReport(changeReport.id, body)
                .then(() => {
                    setChangeReport(undefined);
                })
                .finally(updateHoursReported);
        }
    }

    return (
        <SizeableRoundedDialog
            open={!!changeReport}
            onClose={() => setChangeReport(undefined)}
            fullScreen={fullScreen}
            closeButton
            maxWidth="lg"
        >
            <Grid container item direction="column" style={{ padding: 20, gap: 20 }}>
                <Text variant="h1">
                    Change {changeReport?.provider.user.first_name}&rsquo;s hours for{' '}
                    {formatShortWeekdayMonthDay(changeReport.start ?? new Date())}
                </Text>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardTimePicker
                        label={'Start'}
                        format={`hh:mm a`}
                        mask="__:__ _M"
                        value={shiftStart}
                        onChange={(t) => setShiftStart(t)}
                        variant="inline"
                        style={{ width: 200, marginBottom: 0 }}
                        keyboardIcon={<FaClock color={Colors.darkNavy} />}
                    />
                    <KeyboardTimePicker
                        label={'End'}
                        format={`hh:mm a`}
                        mask="__:__ _M"
                        value={shiftEnd}
                        onChange={(t) => setShiftEnd(t)}
                        variant="inline"
                        style={{ width: 200, marginBottom: 0 }}
                        keyboardIcon={<FaClock color={Colors.darkNavy} />}
                    />
                    <Text>{breakText}</Text>
                    {!hasTimestamps && changeReport.break_minutes > 0 ? (
                        <Text variant="caption">
                            Provider reported this break as we transitioned to the new process for clocking in and out
                            for breaks so please excuse if the timestamps are not reflective of the exact times the
                            provider may have taken a break.
                        </Text>
                    ) : null}
                    <KeyboardTimePicker
                        label={'Break Start'}
                        format={`hh:mm a`}
                        mask="__:__ _M"
                        value={breakStart}
                        onChange={(t) => setBreakStart(t)}
                        variant="inline"
                        style={{ width: 200, marginBottom: 0 }}
                        keyboardIcon={<FaClock color={Colors.darkNavy} />}
                    />
                    <KeyboardTimePicker
                        label={'Break End'}
                        format={`hh:mm a`}
                        mask="__:__ _M"
                        value={breakEnd}
                        onChange={(t) => setBreakEnd(t)}
                        variant="inline"
                        style={{ width: 200, marginBottom: 0 }}
                        keyboardIcon={<FaClock color={Colors.darkNavy} />}
                    />
                </MuiPickersUtilsProvider>
                <PrimaryButton
                    disabled={loading || !!breakStartErr || !!breakEndErr || !!shiftEndErr || !!shiftStartErr}
                    onClick={submitChangeRequest}
                >
                    Confirm
                </PrimaryButton>
            </Grid>
        </SizeableRoundedDialog>
    );
}
