import React, { useState, useEffect } from 'react';
import Cellphone from 'assets/icons/tandempay/cellphone.svg';
import { Job, Provider, TimeOfDay } from 'models';
import ModalPage from 'parent-portal/payments/Components/ConnectPaymentModal/ModalPage';
import PageHeader from 'parent-portal/payments/Components/ConnectPaymentModal/PageHeader';
import { Grid } from '@material-ui/core';
import NextButton from 'parent-portal/payments/Components/ConnectPaymentModal/NextButton';
import moment, { Moment } from 'moment';
import { FormFieldProps } from 'parent-portal/forms/components/FormTypes';
import { DayOfWeekSelections } from 'parent-portal/forms/JobRequestTypes';
import { client, consoleLogInDev } from 'shared';
import { useProviderProfileContext } from 'parent-portal/provider-profile/ProviderProfileContext';
import { TimePickers } from './TimePickers';
import { Pickers } from './Pickers';
import { buildTimesOfDay } from 'parent-portal/jobs/JobMethods';

interface Props {
    provider: Provider;
    onContinue: () => void;
    update: (pay: number) => void;
    initialPayValue: number;
    job: Job;
    setFlexible: (b: boolean) => void;
}

export default function SetSchedule({ provider, onContinue, update, initialPayValue, job, setFlexible }: Props) {
    const { refreshJob } = useProviderProfileContext();
    const [startDate, setStartDate] = useState<Moment | null>(moment());
    const [endDate, setEndDate] = useState<Moment | null>(moment());
    const [startTime, setStartTime] = useState<Moment | null>();
    const [endTime, setEndTime] = useState<Moment | null>();
    const [sameTimes, setSameTimes] = useState<boolean>(getSameTimes());
    const [dayInformation, setDayInformation] = useState<any>(getInitialDayInformationFromTimesOfDay());

    useEffect(() => {
        if (job.ongoing) {
            setStartDate(job.start_date ? moment(job.start_date) : moment());
            setEndDate(job.end_date ? moment(job.end_date) : moment());
        } else {
            client(`api/upcoming-appointments/?or-id=${job.id}`).then((res) => {
                let appt = res[0];
                let start = appt?.start_date ? moment(appt.start_date, 'MM/DD/YYYY HH:mm LT') : moment();
                let end = appt?.end_date ? moment(appt.end_date, 'MM/DD/YYYY HH:mm LT') : moment();
                setStartTime(start);
                setEndTime(end);
                setStartDate(start);
                setEndDate(end);
            });
        }
    }, [job]);

    useEffect(() => {
        if (sameTimes && job.times_of_day && typeof job.times_of_day !== 'string') {
            let timesForFirstDay = job.times_of_day[0];
            const [, times] = Object.entries(timesForFirstDay)[0];
            let start = moment(times.start, 'HH:mm:ss');
            let end = moment(times.end, 'HH:mm:ss');
            let newStartTime = moment(startTime);
            let newEndTime = moment(endTime);

            newEndTime.set({ hour: end.hour(), minute: end.minute() });
            newStartTime.set({ hour: start.hour(), minute: start.minute() });
            setStartTime(start);
            setEndTime(end);
        }
    }, [sameTimes]);

    function getInitialDayInformationFromTimesOfDay() {
        let initialDayInformation: {
            [key: string]: { selected: boolean; start: Moment; end: Moment };
        } = {
            Sunday: { selected: false, start: moment(), end: moment() },
            Monday: { selected: false, start: moment(), end: moment() },
            Tuesday: { selected: false, start: moment(), end: moment() },
            Wednesday: { selected: false, start: moment(), end: moment() },
            Thursday: { selected: false, start: moment(), end: moment() },
            Friday: { selected: false, start: moment(), end: moment() },
            Saturday: { selected: false, start: moment(), end: moment() },
        };
        if (job.times_of_day && typeof job.times_of_day !== 'string') {
            job.times_of_day.forEach((timesByDay) => {
                const [day, times] = Object.entries(timesByDay)[0];
                /* We use an arbitrary date so that we can use moment. we only care about the time anyway */
                initialDayInformation[day].start = moment(`1970-01-01 ${times.start}`);
                initialDayInformation[day].end = moment(`1970-01-01 ${times.end}`);
                initialDayInformation[day].selected = true;
            });
        }
        return initialDayInformation;
    }

    function getSameTimes() {
        if (job.times_of_day && typeof job.times_of_day !== 'string') {
            let firstStart: any = null;
            let firstEnd: any = null;

            for (const timesForDay of job.times_of_day) {
                let [day, times] = Object.entries(timesForDay)[0];

                if (!firstStart || !firstEnd) {
                    firstStart = times.start;
                    firstEnd = times.end;
                } else {
                    if (firstStart !== times.start) {
                        return false;
                    }
                    if (firstEnd !== times.end) {
                        return false;
                    }
                }
            }
        }
        return true;
    }

    const startDateField: FormFieldProps<Moment | null> = {
        isValid: true,
        setValid: () => {},
        value: startDate,
        setValue: (e) => {
            setStartDate(e);
        },
    };

    const endDateField: FormFieldProps<Moment | null> = {
        isValid: true,
        setValid: () => {},
        value: endDate,
        setValue: setEndDate,
    };

    const sameTimesField: FormFieldProps<boolean> = {
        isValid: true,
        setValid: () => {},
        value: sameTimes,
        setValue: setSameTimes,
    };

    const daysOfTheWeek: FormFieldProps<DayOfWeekSelections> = {
        isValid: true,
        setValid: () => {},
        value: dayInformation,
        setValue: (value) => {
            setDayInformation(value);
        },
    };

    function submitDayInformation() {
        let days = Object.keys(dayInformation);
        let newDays: string[] = [];
        days.forEach((d: string) => {
            if (dayInformation[d].selected) {
                newDays.push(d);
            }
        });
        return newDays;
    }

    function submitForm() {
        interface postBody {
            end_date: string | null | undefined;
            start_date: string | null | undefined;
            times_of_day?: any;
            headline: string;
            days_of_week?: string[];
            timeless: boolean;
            flexible: boolean;
        }
        let newEndDate = moment(endDate);
        let newStartDate = moment(startDate);
        if (endTime) {
            newEndDate.set({ hour: endTime.hour(), minute: endTime.minute() });
        }
        if (startTime) {
            newStartDate.set({ hour: startTime.hour(), minute: startTime.minute() });
        }

        if (job.ongoing) {
            let body: postBody = {
                end_date: newEndDate?.format('YYYY-MM-DD'),
                start_date: newStartDate?.format('YYYY-MM-DD'),
                headline: job.headline,
                days_of_week: submitDayInformation(),
                timeless: false,
                flexible: false,
                times_of_day: buildTimesOfDay(sameTimes, dayInformation, startTime, endTime),
            };
            client(`api/edit-ongoing-request/${job.id}`, {
                method: 'PATCH',
                body,
            })
                .then(() => {
                    setFlexible(true);
                    refreshJob();
                })
                .catch(consoleLogInDev);
        } else {
            let body: postBody = {
                end_date: newEndDate?.format('YYYY-MM-DD  HH:mm:ss'),
                start_date: newStartDate?.format('YYYY-MM-DD HH:mm:ss'),
                headline: job.headline,
                timeless: false,
                flexible: false,
            };
            client(`api/edit-onetime-request/${job.id}`, {
                method: 'PATCH',
                body,
            })
                .then(() => {
                    setFlexible(true);
                    refreshJob();
                })
                .catch(consoleLogInDev);
        }
    }

    return (
        <ModalPage>
            <PageHeader
                iconSource={<Cellphone />}
                headerText="Let's confirm the schedule for this job"
                subtitleText="You can change this later."
            />
            <Grid container xs={12} style={{ padding: '3%' }} justify="center">
                <Pickers
                    times={
                        <TimePickers
                            startTime={startTime}
                            endTime={endTime}
                            setStartTime={setStartTime}
                            setEndTime={setEndTime}
                        />
                    }
                    job={job}
                    daysOfTheWeek={daysOfTheWeek}
                    sameTimesField={sameTimesField}
                    startDateField={startDateField}
                    endDateField={endDateField}
                />
            </Grid>

            <Grid container item justify="flex-end" style={{ padding: 50 }}>
                <NextButton
                    onClick={() => {
                        submitForm();
                        onContinue();
                    }}
                />
            </Grid>
        </ModalPage>
    );
}
