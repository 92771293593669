import React, { useState, useEffect, useCallback } from 'react';
import { AdminProvider } from 'models';
import { Colors, Text, PrimaryButton } from 'library';
import { client } from 'shared';
import { Grid } from '@material-ui/core';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import Divider from 'parent-portal/forms/components/Divider';
import useBusinessLocationOptions from './components/Form718bLocationChoice';

interface IAddressProps {
    street: string;
    state: string;
    city: string;
}

interface IBusinessLocationProps {
    name: string;
    program_id: string;
    address: IAddressProps;
}

interface IForm718bSubmissionProps {
    added_at: string;
    last_updated: string;
    business_location: IBusinessLocationProps;
}

interface IForm718bStatusProps {
    personal_history_completed: boolean;
    fingerprints_on_file: boolean;
    form_718b_submitted: boolean;
    background_check_status: string;
    background_check_eligibility_date: string;
    form_718b_submissions: IForm718bSubmissionProps[];
}

export default function Form718bStatus({ provider }: { provider: AdminProvider }) {
    const [form718bStatus, setForm718bStatus] = useState<IForm718bStatusProps>();
    const [loading, setLoading] = useState(false);
    const { selectedBusinessLocation, Form178bDrodown } = useBusinessLocationOptions();

    const get718bStatus = useCallback(() => {
        client(`api/illinois-info-collect/retrieve-by-user/?user=${provider.user.id}`, {
            method: 'GET',
        }).then((data) => {
            setForm718bStatus(data);
        });
    }, [provider.user.id]);

    useEffect(() => {
        get718bStatus();
    }, [get718bStatus]);

    function submitForm718b() {
        const program_id = selectedBusinessLocation?.program_id;
        if (!program_id) {
            alert('Please select a business location to submit 718b form.');
            return;
        }
        setLoading(true);
        client(`api/illinois-info-collect/718b-form-submission/`, {
            method: 'POST',
            body: { program_id: program_id, user_id: provider.user.id },
        })
            .then(() => {
                setLoading(false);
                get718bStatus();
            })
            .catch((e) => {
                setLoading(false);
                alert(e.message || '718b-form-submission failed');
            });
    }

    return (
        <>
            <Text variant="h2" bold textStyle={{ margin: 10, color: Colors.turquoise }}>
                Form 718b Status
            </Text>
            {form718bStatus && form718bStatus.personal_history_completed ? (
                <Grid container item direction="row" style={{ margin: 20, gap: 20 }}>
                    <Form178bDrodown />
                    <PrimaryButton
                        disabled={selectedBusinessLocation.id < 0}
                        loading={loading}
                        onClick={submitForm718b}
                        buttonStyle={{ maxWidth: 150 }}
                    >
                        File 718b
                    </PrimaryButton>
                </Grid>
            ) : (
                <Text bold>Personal History not completed</Text>
            )}

            <Divider />
            {form718bStatus?.form_718b_submissions?.length && form718bStatus?.form_718b_submissions?.length > 0 ? (
                <>
                    <Text variant="h2" bold textStyle={{ margin: 10, color: Colors.turquoise }}>
                        Previous Submissions
                    </Text>

                    <TableContainer component={Paper} style={{ margin: 20 }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{ fontWeight: 'bold', color: Colors.turquoise }}>
                                        Added at
                                    </TableCell>
                                    <TableCell style={{ fontWeight: 'bold', color: Colors.turquoise }}>Name</TableCell>
                                    <TableCell style={{ fontWeight: 'bold', color: Colors.turquoise }}>
                                        Address
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {form718bStatus?.form_718b_submissions.map((submission) => (
                                    <TableRow key={submission.added_at}>
                                        <TableCell>
                                            {new Date(submission.added_at).toLocaleDateString('en-US', {
                                                year: 'numeric',
                                                month: '2-digit',
                                                day: '2-digit',
                                            })}
                                        </TableCell>
                                        <TableCell>{submission.business_location.name}</TableCell>
                                        <TableCell>
                                            {`${submission.business_location.address.street}, ${submission.business_location.address.city}`}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </>
            ) : (
                <Text bold> No previous submissions </Text>
            )}
        </>
    );
}
