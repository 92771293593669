import React, { useState } from 'react';
import { AdminProvider } from 'models';
import { BasicDialog, DropDown, IconButton, PrimaryButton, Text } from 'library';
import { Edit } from '@mui/icons-material';
import { Grid } from '@material-ui/core';

export default function StatusChanger({
    provider,
    status,
    options,
    updateStatus,
}: {
    provider: AdminProvider;
    status?: string;
    options: string[];
    updateStatus: (newStatus: string) => void;
}) {
    const [open, setOpen] = useState<boolean>(false);
    const [newStatus, setNewStatus] = useState<string>('');

    return (
        <>
            <Text variant="body2" bold textStyle={{ margin: 10 }}>
                STATUS: {status ? status : 'NONE'}
                <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', maxWidth: 300 }}>
                    <div>
                        <Text>
                            {' '}
                            <IconButton icon={Edit} onClick={() => setOpen(true)} /> Edit Status
                        </Text>
                    </div>
                </div>
            </Text>

            <BasicDialog closeButton isOpen={open} onClose={() => setOpen(false)}>
                <Grid container>
                    <Text variant="h2">
                        Select status for {provider.user.first_name} {provider.user.last_name}'s
                    </Text>
                    <DropDown selected={newStatus} fields={options} onChange={(e) => setNewStatus(e.target.value)} />
                </Grid>
                <PrimaryButton
                    onClick={() => {
                        updateStatus(newStatus);
                        setOpen(false);
                    }}
                    buttonStyle={{ width: 250, margin: 10 }}
                >
                    Save
                </PrimaryButton>
            </BasicDialog>
        </>
    );
}
