import React from 'react';
import { Grid } from '@material-ui/core';
import { IfRender, Text, TextButton } from 'library';
import NotificationList from '../../../components/NotificationList';
import { useHistory } from 'react-router';
import NotificationOptions from './components/NotificationOptions/NotificationOptions';
import NotificationModals from 'parent-portal/notifications/components/NotificationModals';

export default function NotificationSection({
    showViewAll = false,
    showOptions = false,
}: {
    showViewAll?: boolean;
    showOptions?: boolean;
}) {
    const history = useHistory();

    function handleViewAll() {
        history.push('/notifications');
    }
    return (
        <>
            <Grid container>
                <Grid container item justify="space-between">
                    <Text variant="h1">Notifications</Text>
                    <IfRender condition={showViewAll}>
                        <Grid item>
                            <TextButton onClick={handleViewAll}>View All</TextButton>
                        </Grid>
                    </IfRender>
                    <IfRender condition={showOptions}>
                        <NotificationOptions />
                    </IfRender>
                </Grid>
                <Grid container item direction={'column'} spacing={4} style={{ paddingTop: 20 }}>
                    <NotificationList />
                </Grid>
            </Grid>
            <NotificationModals />
        </>
    );
}
