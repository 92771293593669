import { Chip, Colors } from 'library';
import { AdminListProvider } from 'models';
import React, { useState } from 'react';
import SentMatchesModal from './SentMatchesModal';
import SentReverseMatchesModal from './SentReverseMatchesModal';

export default function OutstandingMatches({
    jobId,
    outstandingMatches,
    provider,
}: {
    jobId?: number;
    provider?: AdminListProvider;
    outstandingMatches?: number;
}) {
    const [modalOpen, setModalOpen] = useState(false);
    return (
        <>
            <Chip
                label={outstandingMatches?.toString() || provider?.outstanding_matches?.toString() || '0'}
                onClick={() => setModalOpen(!modalOpen)}
                style={{ minHeight: 20, height: 20, marginLeft: 10 }}
                backgroundColor={
                    outstandingMatches || provider?.outstanding_matches ? Colors.mediumRed : Colors.mediumGrey
                }
                textColor={Colors.white}
            />
            {jobId ? (
                <SentMatchesModal open={modalOpen} onClose={() => setModalOpen(false)} jobId={jobId} />
            ) : provider ? (
                <SentReverseMatchesModal open={modalOpen} onClose={() => setModalOpen(false)} provider={provider} />
            ) : null}
        </>
    );
}
