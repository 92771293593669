import React from 'react';
import { Strike } from '../models';
import { Colors, Text } from 'library';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { formatMonthDayTime } from 'shared/Dates';
import { styled } from '@mui/material';

export default function StrikeIndicator({ strike }: { strike: Strike }) {
    return (
        <Container>
            <Text>STRIKE: </Text>
            <FontAwesomeIcon
                size="lg"
                icon={faXmark}
                color={strike.forgiven_date ? Colors.mediumGrey : Colors.mediumRed}
            />
            {strike.forgiven_date ? (
                <Text variant="caption">(Forgiven: {formatMonthDayTime(strike.forgiven_date)})</Text>
            ) : null}
        </Container>
    );
}

const Container = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: 8,
});
