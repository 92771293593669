import React from 'react';
import { PayBackgroundPaper } from './PayPapers';
import { Text } from 'library';
import PaySectionHeader from './PaySectionHeader';

export default function PaymentFaq() {
    return (
        <PayBackgroundPaper>
            <PaySectionHeader>Payment FAQ's</PaySectionHeader>
            <section style={{ marginBottom: '1em' }}>
                <Text variant="body1" bold>
                    How much does it cost?
                </Text>
                <Text variant="body2">
                    Tandem charges a flat rate of 35% on top of the worker's hourly rate. For temp-to-permanent hires,
                    there is a 120-hour cap.
                </Text>
            </section>

            <section style={{ marginBottom: '1em' }}>
                <Text variant="body1" bold>
                    When will I get charged?
                </Text>
                <Text variant="body2">
                    You will be charged weekly for wages and associated fees for the workers who worked the week prior.
                    You will have until 8pm on the day of the shift (or 3 hours after the shift ends, whichever is
                    later) to correct or dispute any of the hours that were recorded by the worker for the day.
                    Corrections should be made on the calendar on the “My Jobs” page.
                </Text>
            </section>

            <section style={{ marginBottom: '1em' }}>
                <Text variant="body1" bold>
                    Who sets the pay rate?
                </Text>
                <Text variant="body2">
                    You do! We know pay rates are not one size fits all. You will be able to set a pay scale when you
                    post your need. Tandem will use your pay scale and notes to set the pay rate for each hired worker.
                </Text>
            </section>

            <section style={{ marginBottom: '1em' }}>
                <Text variant="body1" bold>
                    Who handles taxes?
                </Text>
                <Text variant="body2">
                    Tandem’s workers are 1099k contractors, so we issue all relevant tax forms to worker.
                </Text>
            </section>

            <section style={{ marginBottom: '1em' }}>
                <Text variant="body1" bold>
                    What type of payment methods can I use?
                </Text>
                <Text variant="body2">
                    You can add either a credit card or bank account. Credit cards incur a 2.9% + $.30 fee due to
                    processing fees. Both payment methods can be connected via secure, third party payment processors,
                    including Stripe and Plaid.
                </Text>
            </section>

            <section style={{ marginBottom: '1em' }}>
                <Text variant="body1" bold>
                    How does my worker clock their hours?
                </Text>
                <Text variant="body2">
                    Workers clock in and out using the Tandem app. For ultimate accuracy, you can print a QR code
                    located in the “payments” page of your account. Have Tandem workers scan this to clock in and scan
                    it again to clock out. They’ll be prompted to add their break minutes as well.
                </Text>
            </section>

            <section style={{ marginBottom: '1em' }}>
                <Text variant="body1" bold>
                    How do I adjust my worker’s clocked hours?
                </Text>
                <Text variant="body2">
                    You can view clocked hours on the calendar under the “My Jobs” page of your account. Please adjust
                    the hours on the calendar within 3 hours of the shift ending.
                </Text>
            </section>

            <section style={{ marginBottom: '1em' }}>
                <Text variant="body1" bold>
                    Do I pay for worker’s breaks?
                </Text>
                <Text variant="body2">
                    No, if a worker clocks a break, you will not be charged as this is unpaid time.
                </Text>
            </section>

            <section style={{ marginBottom: '1em' }}>
                <Text variant="body1" bold>
                    Will I receive a receipt?
                </Text>
                <Text variant="body2">
                    Yes, after you’ve been charged on Sunday evening, you will receive a receipt via email.
                </Text>
            </section>

            <section style={{ marginBottom: '1em' }}>
                <Text variant="body1" bold>
                    How do I access my invoices?
                </Text>
                <Text variant="body2">
                    All paid and unpaid invoices can be downloaded from the “payments” page in your account. You can
                    specify the time period, business location, and worker by which you want an invoice. Downloading an
                    unpaid invoice allows you to see your outstanding charges for the current week.
                </Text>
            </section>
        </PayBackgroundPaper>
    );
}
