import React, { useEffect, useState } from 'react';
import { BasicDialog, Checkbox, PrimaryButton, Text } from 'library';
import { AdminListProvider } from 'models';
import { client } from 'shared';
import { JobMatch } from '../substituteModels';
import ProviderNameCell from 'internal/shared/ProviderNameCell';
import SentMatchesTable from './SentMatchesTable';
import { isAfter } from 'date-fns';

interface GroupedMatch {
    provider: AdminListProvider;
    matches: JobMatch[];
    isPreferred: boolean;
    addedAt: string;
}

export default function SentMatchesModal({
    jobId,
    open,
    onClose,
}: {
    jobId: number;
    open: boolean;
    onClose: () => void;
}) {
    const [matches, setMatches] = useState<GroupedMatch[]>([]);
    const [showOutstandingOnly, setShowOutstandingOnly] = useState(false);
    const [matchesToShow, setMatchesToShow] = useState<GroupedMatch[]>(matches);
    useEffect(() => {
        if (showOutstandingOnly) {
            const groupsWithOutstandingMatches = matches.filter((workerMatches) =>
                workerMatches.matches.some(
                    (x) => isAfter(new Date(x.expires_at), new Date()) && !x.accepted_at && !x.declined_at,
                ),
            );
            groupsWithOutstandingMatches.forEach((group) => {
                group.matches = group.matches.filter(
                    (x) => isAfter(new Date(x.expires_at), new Date()) && !x.accepted_at && !x.declined_at,
                );
            });
            setMatchesToShow(groupsWithOutstandingMatches);
        } else {
            setMatchesToShow(matches);
        }
    }, [showOutstandingOnly, matches]);

    useEffect(getMatches, [open]);

    function getMatches() {
        if (open)
            client(`api/match/?job=${jobId}`).then((res: JobMatch[]) => {
                const reducedMatches = res.reduce((groupedMatches: GroupedMatch[], thisMatch: JobMatch) => {
                    const groupedMatch = groupedMatches?.find(
                        (person: GroupedMatch) => person.provider.id === thisMatch.matched_worker.id,
                    );
                    if (groupedMatch) groupedMatch.matches.push(thisMatch);
                    else
                        groupedMatches.push({
                            provider: thisMatch.matched_worker,
                            matches: [thisMatch],
                            isPreferred: thisMatch.is_preferred || false,
                            addedAt: thisMatch.added_at,
                        });
                    return groupedMatches;
                }, []);

                reducedMatches.sort((a, b) => {
                    if (b.isPreferred && !a.isPreferred) {
                        return 1;
                    } else if (!b.isPreferred && a.isPreferred) {
                        return -1;
                    } else {
                        return new Date(b.addedAt).getTime() - new Date(a.addedAt).getTime();
                    }
                });

                setMatches(reducedMatches);
            });
    }

    return (
        <BasicDialog closeButton isOpen={open} onClose={onClose} style={{ minWidth: '60%' }}>
            <Checkbox
                label="Show Outstanding Matches Only"
                checked={showOutstandingOnly}
                onChange={() => setShowOutstandingOnly(!showOutstandingOnly)}
            />
            {matchesToShow.length > 0 ? (
                matchesToShow.map((match) => (
                    <SentMatchesTable
                        key={match.provider.user.id}
                        userId={match.provider.user.id}
                        matches={match.matches}
                        header={<ProviderNameCell provider={match.provider} />}
                        refresh={getMatches}
                    />
                ))
            ) : (
                <Text>No matches found.</Text>
            )}
            <PrimaryButton rightAlign onClick={onClose}>
                Done
            </PrimaryButton>
        </BasicDialog>
    );
}
