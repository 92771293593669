import moment from 'moment';
import { useState, useEffect } from 'react';
import { Environment } from 'environmentVariables';

export const WEEKDAYS_ABV = {
    Monday: 'MON',
    Tuesday: 'TUE',
    Wednesday: 'WED',
    Thursday: 'THU',
    Friday: 'FRI',
    Saturday: 'SAT',
    Sunday: 'SUN',
};

export const WEEKDAYS_ORDINAL = {
    Monday: 0,
    Tuesday: 1,
    Wednesday: 2,
    Thursday: 3,
    Friday: 4,
    Saturday: 5,
    Sunday: 6,
};

export const WEEKDAYS_KEY_VALUE = [
    { key: 'MONDAY', value: 'Monday' },
    { key: 'TUESDAY', value: 'Tuesday' },
    { key: 'WEDNESDAY', value: 'Wednesday' },
    { key: 'THURSDAY', value: 'Thursday' },
    { key: 'FRIDAY', value: 'Friday' },
    { key: 'SATURDAY', value: 'Saturday' },
    { key: 'SUNDAY', value: 'Sunday' },
];

export const formatPhoneNumber = (phoneNumberString) => {
    const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        return ['(', match[2], ') ', match[3], '-', match[4]].join('');
    }
    return null;
};

export const calculatePay = (endDate, startDate, hourlyRate) => {
    return ((endDate - startDate) / 3600000) * hourlyRate;
};

export const calculatePaymentInfo = (startDate, endDate, hourlyRate, tip, discount) => {
    const pay = calculatePay(new Date(endDate), new Date(startDate), hourlyRate);
    let totalPay = pay + tip;
    let discountApplied = false;
    if (totalPay - discount > 0 && discount > 0) {
        totalPay -= discount;
        discountApplied = true;
    }
    const transaction = {
        pay: pay,
        tip: tip,
        discount: discount,
        discountApplied: discountApplied,
        totalPay: Math.max(totalPay, 0),
    };
    return transaction;
};

export const queryStringifyObject = (object) => {
    const qs = Object.keys(object)
        .map((x) => {
            if (object[x]) {
                return `${x}=${encodeURIComponent(object[x])}`;
            }
        })
        .filter((x) => !!x)
        .join('&');

    return qs;
};

export const getRecurringStartEndDates = (times_of_day, day_of_week) => {
    let times = {};
    times.start = null;
    times.end = null;
    try {
        if (times_of_day) {
            let jsonTimes = times_of_day;
            if (typeof times_of_day === 'string') {
                jsonTimes = JSON.parse(times_of_day.replaceAll("'", '"'));
            }
            jsonTimes.forEach((newday) => {
                if (newday.hasOwnProperty(day_of_week)) {
                    times.start = moment(newday[day_of_week].start, 'HH:mm:ss');
                    times.end = moment(newday[day_of_week].end, 'HH:mm:ss');
                }
            });
        }
    } catch (e) {
        console.log(e);
    }
    return times;
};

export const formatAppointmentDate = (job, usePlural = false, ignoreStart = false) => {
    if (job.start_date && !ignoreStart) {
        return moment(job.start_date, 'MM/DD/YYYY hh:mm A').format('ddd, MMM D');
    } else if (job.ongoing_request.ongoing && job.ongoing_request.start_date === null) {
        return usePlural ? job.days_of_week + 's' : 'Every ' + job.days_of_week;
    }
    return null;
};

export const formatAppointmentTime = (job, longDate = false) => {
    const TIME_FORMAT = longDate ? 'ddd, MMM DD h:mm A' : 'h:mm A';
    let times = {};
    times.start = job.start_date;
    times.end = job.end_date;

    if (!times.start && job.ongoing_request.ongoing && job.ongoing_request.start_date === null) {
        try {
            times = getRecurringStartEndDates(job.ongoing_request.times_of_day, job.days_of_week);
        } catch (error) {
            if (Environment !== 'production') {
                console.log(error);
            }
            return null;
        }
    }

    return (
        moment(times.start, 'MM/DD/YYYY hh:mm A').format(TIME_FORMAT) +
        ' - ' +
        moment(times.end, 'MM/DD/YYYY hh:mm A').format(TIME_FORMAT)
    );
};

export const formatAppointmentPay = (job) => {
    let times = {};
    times.start = job.start_date;
    times.end = job.end_date;

    if (!times.start && job.ongoing_request.ongoing && job.ongoing_request.start_date === null) {
        try {
            times = getRecurringStartEndDates(job.ongoing_request.times_of_day, job.days_of_week);
        } catch (error) {
            if (Environment != 'production') {
                console.log(error);
            }
            return null;
        }
    }
    return (
        '$' +
        job.pay +
        '/hour ~ $' +
        ((moment(times.end).diff(moment(times.start)) / 3600000) * job.pay).toFixed(2) +
        ' total'
    );
};

export const getKeyByValue = (value, obj) => {
    return Object.keys(obj).find((key) => {
        return obj[key] === value;
    });
};

export const getKeyByCaseInsensitive = (lookup, obj) => {
    return Object.keys(obj).find((key) => {
        return key.toLowerCase() === lookup.toLowerCase();
    });
};

export const isNilOrEmpty = (value) => {
    return value === '' || value === undefined || value === null;
};

export const isNil = (value) => {
    return value === undefined || value === null;
};

export const groupByKey = (list, key, { omitKey = false }) => {
    return list.reduce(
        (hash, { [key]: value, ...rest }) => ({
            ...hash,
            [value]: (hash[value] || []).concat(omitKey ? { ...rest } : { [key]: value, ...rest }),
        }),
        {},
    );
};

export function getAge(birthday) {
    let age = moment().diff(moment(birthday), 'years');
    return age < 0 ? 0 : age;
}

export function getDaysSinceToday(date) {
    return moment().diff(date, 'days');
}

export function getMonthsSinceToday(date) {
    return moment().diff(date, 'months');
}

export function consoleLogInDev(value) {
    if (isDev()) {
        // eslint-disable-next-line no-console
        console.log(value);
    }
}

export function adminLog(value) {
    // eslint-disable-next-line no-console
    console.log(value);
}

export function logRejectionsInDev(wrappedFunction) {
    return async function () {
        try {
            return await wrappedFunction.apply(this, arguments);
        } catch (error) {
            consoleLogInDev(error);
        }
    };
}

export function getObjectFromSearchString(searchString) {
    if (searchString) {
        let params = searchString
            .replace('?', '')
            .split('&')
            .map((e) => e.split('='));
        return params.reduce((obj, param) => {
            obj[param[0]] = decodeURIComponent(param[1]);
            return obj;
        }, {});
    }
    return {};
}

export function isDev() {
    return Environment.toLowerCase() !== 'production';
}

export function validatePassword(password) {
    var valid = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?\d)(?=.*?[#?!@$%^&*-]).{8,}$/;
    return valid.test(password);
}
export function validateEmail(email) {
    // eslint-disable-next-line security/detect-unsafe-regex
    var re = /^[\w+&*-]+(?:\.[\w+&*-]+)*@(?:[a-zA-Z\d-]+\.)+[a-zA-Z]{2,}$/;
    return re.test(email);
}

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height,
    };
}

export function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
}

export function isNumber(val) {
    return /^\d+$/.test(val);
}

export function IsDecimal(num) {
    return /^-?\d+(\.\d{1,2})?$/.test(num);
}

export function formatAddress(address, includeDescription = true) {
    return `${address.description && includeDescription ? address.description + ' ' : ''}${address.street} ${
        address.city
    }, ${address.state} ${address.zip_code}`;
}
