import { client } from 'shared';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

export async function hire(
    user_id: number,
    business_location_id: number,
    hire: boolean,
    hireDate?: MaterialUiPickersDate | Date,
) {
    return client('api/staff/hire/', {
        method: 'PATCH',
        body: {
            user_id,
            business_location_id,
            hire,
            hire_date: hireDate,
        },
    });
}
