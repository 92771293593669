import React from 'react';
import { Grid, Typography, Dialog } from '@material-ui/core';
import StyledIconButton from '../StyledIconButton';
import { ArrowBackIos as ArrowBackIosIcon } from '@material-ui/icons';

const DialogWithHeader = (props) => {
    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            maxWidth={props.maxWidth ? props.maxWidth : 'xs'}
            PaperProps={{
                style: { borderRadius: 10 },
            }}
            onBackdropClick={props.onClose}
        >
            <Grid container alignItems="center">
                <Grid
                    container
                    item
                    xs={12}
                    direction="row"
                    alignItems="center"
                    style={{ background: props.color, height: 50 }}
                >
                    {props.back && (
                        <Grid container item xs={2} justify="center" spacing={1}>
                            <StyledIconButton onClick={props.onBackClick}>
                                <ArrowBackIosIcon style={{ color: 'white' }} />
                            </StyledIconButton>
                        </Grid>
                    )}
                    <Grid item xs={props.back ? 10 : 12}>
                        <Typography variant="h6" style={{ fontWeight: 'bold', color: 'white' }} align="center">
                            {props.title}
                        </Typography>
                    </Grid>
                </Grid>
                {props.content}
            </Grid>
        </Dialog>
    );
};

export default DialogWithHeader;
