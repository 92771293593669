import { Grid } from '@material-ui/core';
import { Colors, Paper, Text } from 'library';
import React from 'react';
import { useHistory } from 'react-router';
import { PreSignupCardProps } from './models';
import { useWidth } from 'reusableComponents/useWidth';

export default function OptionCard({
    id,
    icon,
    label,
    highlightDescription,
    description,
    action,
    smallerVersion = false,
}: PreSignupCardProps) {
    const breakpoint = useWidth();
    const breakLayout = smallerVersion || ['xs', 'sm'].includes(breakpoint);

    return (
        <Grid item={!breakLayout} container={breakLayout} justify={breakLayout ? 'center' : undefined}>
            <Grid
                item
                style={{
                    width: smallerVersion ? 300 : breakpoint === 'xs' ? '100%' : 298,
                    height: smallerVersion ? 150 : breakpoint === 'xs' ? 200 : 220,
                    marginBottom: smallerVersion ? 10 : breakpoint === 'xs' ? 20 : 40,
                }}
            >
                <Paper
                    id={id}
                    elevation={3}
                    padding={smallerVersion ? 10 : 30}
                    style={{ height: '100%', cursor: 'pointer' }}
                    onClick={action}
                >
                    <Grid container direction="column" justify="center" style={{ height: '100%' }}>
                        <Grid item>{icon}</Grid>
                        <Grid item>
                            <Text variant={smallerVersion ? 'body1' : 'h1'} bold>
                                {label}
                            </Text>
                        </Grid>
                        <Grid item style={{ marginTop: 10 }}>
                            <Text variant={smallerVersion ? 'caption' : 'body1'}>
                                <Text
                                    inline
                                    variant={smallerVersion ? 'caption' : 'body1'}
                                    color={Colors.turquoise}
                                    bold
                                >
                                    {highlightDescription}
                                </Text>{' '}
                                {description}
                            </Text>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
    );
}
