import React from 'react';
import { Tab } from '@mui/material';
import { TabContext, TabList } from '@mui/lab';
import SwipeableViews from 'react-swipeable-views';
import { Colors } from '../../theme/Colors';

export default function Tabs({
    selectedTab,
    setSelectedTab,
    tabs,
    values,
}: {
    selectedTab: string;
    setSelectedTab: (value: string) => void;
    tabs: string[];
    values: JSX.Element[];
}) {
    return (
        <TabContext value={selectedTab}>
            <TabList
                onChange={(e, value) => setSelectedTab(value)}
                variant="scrollable"
                allowScrollButtonsMobile
                textColor="inherit"
                TabIndicatorProps={{ style: classes.tabIndicator }}
            >
                {tabs.map((t: string, i: number) => (
                    <Tab key={t} label={t} value={i.toString()} sx={classes.tabs} />
                ))}
            </TabList>
            <SwipeableViews
                index={parseInt(selectedTab)}
                onChangeIndex={(index: number) => setSelectedTab(index.toString())}
            >
                {values}
            </SwipeableViews>
        </TabContext>
    );
}

const classes = {
    tabs: {
        '&.MuiButtonBase-root.MuiTab-root': {
            fontSize: 16,
            fontWeight: 'bold',
            textTransform: 'none',
            outline: 'none',
        },
        '&.MuiTab-textColorInherit': {
            color: Colors.darkNavy,
        },
        '&.Mui-selected': {
            color: Colors.turquoise,
        },
    },
    panels: {
        '&.MuiTabPanel-root': {
            paddingTop: '20px',
            paddingBottom: '20px',
        },
    },
    modal: {
        width: '80%',
        maxWidth: 'unset',
    },
    tabIndicator: {
        backgroundColor: Colors.turquoise,
        height: 3,
    },
};
