import React from 'react';
import MoreDetailsCell from './tables/MoreDetailsCell';
import { Rating, styled } from '@mui/material';
import { Text, Colors, Chip } from 'library';
import { AdminProviderListUser, IUserStats } from 'models';

export default function ProviderStatsCell({
    user,
    providerStats,
    isModal = false,
    setIsModal,
    isNumber = false,
    hiringGroupOpened,
    vertical = false,
    lastLogin,
}: {
    user: Partial<AdminProviderListUser>;
    providerStats?: IUserStats;
    isModal?: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setIsModal: React.Dispatch<React.SetStateAction<any>>;
    isNumber?: boolean | undefined;
    hiringGroupOpened?: string;
    vertical?: boolean;
    lastLogin?: string;
}) {
    const {
        review_average,
        review_count,
        cancellations_count,
        hour_reports_count,
        on_time_hour_reports_count,
        shifts_worked,
        streak_length,
    } = providerStats || {};

    const reviewAverage = review_average ?? 0;
    const reviewCount = review_count ?? 0;
    const cancellationsCount = cancellations_count ?? 0;
    const hourReportsCount = hour_reports_count ?? 0;
    const onTimeHourReportsCount = on_time_hour_reports_count ?? 0;
    const shiftsWorked = shifts_worked ?? 0;
    const streakLength = streak_length ?? 'N/A';

    const onTimePercentage = onTimeHourReportsCount
        ? `${Math.round((onTimeHourReportsCount / hourReportsCount) * 100)}%`
        : 'N/A';

    const cancellationPercentage = cancellationsCount
        ? `${Math.round((cancellationsCount / (cancellationsCount + hourReportsCount)) * 100)}%`
        : '0%';

    return (
        <StyledWrapper vertical={vertical}>
            <MoreDetailsCell
                text={`${user.first_name} ${user.last_name} (${user.id})`}
                toggleExpanded={() => (isNumber ? setIsModal(user.id || -1) : setIsModal(!isModal))}
            />
            {!!providerStats && (
                <StyledRatingWrapper>
                    <Rating size="small" readOnly value={reviewAverage} />
                    <Text variant="caption" color={Colors.mediumGrey}>
                        ({reviewCount})
                    </Text>
                </StyledRatingWrapper>
            )}
            {!!hiringGroupOpened && (
                <Chip
                    backgroundColor={Colors.mediumNavy}
                    textColor={Colors.white}
                    label={`Hiring group ${hiringGroupOpened}`}
                />
            )}
            {!!providerStats && (
                <StyledWrapper vertical={vertical}>
                    <Text variant="caption" textStyle={{ marginRight: 5 }} color={Colors.mediumGrey}>
                        Shifts Worked: {shiftsWorked}
                    </Text>
                    <Text variant="caption" textStyle={{ marginRight: 5 }} color={Colors.mediumGrey}>
                        | On Time Percentage: {onTimePercentage}
                    </Text>
                    <Text variant="caption" textStyle={{ marginRight: 5 }} color={Colors.mediumGrey}>
                        | Cancellation Percentage: {cancellationPercentage}
                    </Text>
                    <Text variant="caption" color={Colors.mediumGrey}>
                        | Current Streak: {streakLength}
                    </Text>
                    {lastLogin && (
                        <Text variant="caption" color={Colors.mediumGrey}>
                            | Last Login: {new Date(lastLogin).toLocaleDateString()}
                        </Text>
                    )}
                    {!user.show_spotlight && (
                        <Text variant="caption" color={Colors.mediumGrey}>
                            | Profile Share: 🚫 No
                        </Text>
                    )}
                </StyledWrapper>
            )}
        </StyledWrapper>
    );
}

const StyledWrapper = styled('div')(({ vertical }: { vertical: boolean }) => ({
    display: 'flex',
    flexDirection: vertical ? 'column' : 'row',
    gap: 5,
    flexWrap: 'wrap',
}));

const StyledRatingWrapper = styled('div')({
    display: 'flex',
    alignItems: 'center',
});
