import React from 'react';
import { Grid } from '@mui/material';
import { AdminProvider } from 'models';
import { ReverseMatchingTabs } from 'internal/substitutes/Matching/ReverseMatching';

export default function Matching({
    provider,
    refreshProvider,
}: {
    provider?: AdminProvider;
    refreshProvider: () => void;
}) {
    if (!provider) return null;

    return (
        <Grid container item direction="column" gap={2}>
            <ReverseMatchingTabs provider={provider} updateProvider={refreshProvider} />{' '}
        </Grid>
    );
}
