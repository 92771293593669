import { Colors, IconButton } from 'library';
import React, { useState } from 'react';
import Phone from '@material-ui/icons/Phone';
import DraggablePhoneDialog from './DraggablePhoneDialog';

interface IPhoneDialogProps {
    businessLocationId: number;
    name: string;
    size?: number;
}

export default function PhoneDialog({ businessLocationId, name, size }: IPhoneDialogProps) {
    const [isOpen, setIsOpen] = useState(false);
    const ref = React.useRef<HTMLButtonElement>(null);

    const iconSize = size
        ? {
              size,
              iconStyle: {
                  width: size + 2,
                  height: size + 2,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
              },
          }
        : {};

    return (
        <>
            <IconButton
                tooltip="Add a call"
                ref={ref}
                icon={Phone}
                color={Colors.darkNavy}
                onClick={() => setIsOpen(true)}
                {...iconSize}
            />
            <DraggablePhoneDialog
                isOpen={isOpen}
                onClose={() => setIsOpen(false)}
                name={name}
                businessLocationId={businessLocationId}
                anchorElement={ref.current}
            />
        </>
    );
}
