import React from 'react';
import { Card, CardContent, Grid } from '@material-ui/core';
import { Text } from 'library';
import { UserContextUser } from 'models';
import { formatReadablePhoneNumber } from 'shared';
import ProfilePicture from './ProfilePicture';

export default function ProfileCard({
    user,
    setExpanded,
    editContactInfo,
    updateEditContact,
    setPictureDialogVisible,
}: {
    user: UserContextUser;
    setExpanded: (s: string) => void;
    editContactInfo: boolean;
    updateEditContact: (b: boolean) => void;
    setPictureDialogVisible: (b: boolean) => void;
}) {
    const cardInfo = [
        {
            title: 'Contact Person',
            display: `${user.first_name} ${user?.last_name}`,
        },
        {
            title: 'Email',
            display: user.email,
        },
        {
            title: 'Phone Number',
            display: user.phone_number && formatReadablePhoneNumber(user.phone_number),
        },
        {
            title: 'Communication Preference',
            display: user.communication_preference.toLocaleLowerCase(),
        },
    ];

    return (
        <Grid container item xs={12} md={3} style={{ height: 400 }}>
            <Card
                style={{
                    borderRadius: 18,
                    justifyContent: 'space-between',
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <CardContent
                    style={{
                        justifyContent: 'space-around',
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100%',
                    }}
                >
                    <ProfilePicture user={user} setPictureDialogVisible={setPictureDialogVisible} />
                    {cardInfo.map((i) => (
                        <Grid
                            key={i.title}
                            container
                            item
                            style={{
                                paddingTop: 10,
                            }}
                            direction="column"
                        >
                            <Text variant="caption" bold textStyle={{ padding: 0, margin: 0 }}>
                                {i.title}
                            </Text>
                            <Text variant="caption" textStyle={{ padding: 0, margin: 0 }}>
                                {i.display}
                            </Text>
                        </Grid>
                    ))}
                    <Text
                        onClick={() => {
                            updateEditContact(!editContactInfo);
                            setExpanded('The Basics');
                        }}
                        variant="caption"
                        textStyle={{ textDecorationLine: 'underline', cursor: 'pointer', textAlign: 'right' }}
                    >
                        {editContactInfo ? 'Cancel' : 'Edit'}
                    </Text>
                </CardContent>
            </Card>
        </Grid>
    );
}
