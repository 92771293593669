import { Colors, Text } from 'library';
import React from 'react';

export default function Highlight({ title, children }: { title: string; children: React.ReactNode }) {
    return (
        <div
            style={{
                backgroundColor: Colors.lightTurq,
                borderRadius: 18,
                padding: 25,
                height: '100%',
            }}
        >
            <Text bold color={Colors.turquoise} textStyle={{ letterSpacing: 3.2 }}>
                {title.toUpperCase()}
            </Text>
            {children}
        </div>
    );
}
