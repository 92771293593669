import React from 'react';
import { FormControl, FormControlLabel, Radio, RadioGroup, styled } from '@mui/material';
import { Chip, Text, Colors } from 'library';
import { useJobFormContext } from 'parent-portal/forms/JobFormContext';
import { TemplateOption } from 'parent-portal/forms/JobRequestTypes';

export default function TemplateRadioGroup() {
    const { form } = useJobFormContext();
    const { useTemplate } = form.fieldStates;
    return (
        <FormControl>
            <RadioGroup
                value={useTemplate.value}
                onChange={(e) => {
                    useTemplate.setValue(e.target.value as TemplateOption);
                }}
                sx={styles.radioGroup}
            >
                <div>
                    <FormControlLabel
                        key={TemplateOption.NEW}
                        value={TemplateOption.NEW}
                        control={<StyledRadio disableRipple disableFocusRipple />}
                        label={
                            <RadioGroupChoice>
                                <Text variant="body1" bold>
                                    Create a new job from scratch
                                </Text>
                                <Text variant="caption">
                                    We’ll collect all the info from you to create your job posting.
                                </Text>
                            </RadioGroupChoice>
                        }
                    />
                    <FormControlLabel
                        key={TemplateOption.TEMPLATE}
                        value={TemplateOption.TEMPLATE}
                        control={<StyledRadio disableRipple disableFocusRipple />}
                        label={
                            <RadioGroupChoice>
                                <TemplateHeaderContainer>
                                    <Text variant="body1" bold>
                                        Use previous job post as a template
                                    </Text>
                                    <Chip
                                        backgroundColor={Colors.terraCotta}
                                        textColor={Colors.white}
                                        label="Quick Post"
                                        style={styles.chip}
                                    />
                                </TemplateHeaderContainer>
                                <Text variant="caption">Re-use a past job and edit any details you’d like.</Text>
                            </RadioGroupChoice>
                        }
                    />
                </div>
            </RadioGroup>
        </FormControl>
    );
}

const styles = {
    radioOption: {
        marginTop: 15,
    },
    radioGroup: {
        marginTop: 2,
        marginLeft: 2,
    },
    templateContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: 10,
    },
    chip: {
        height: 20,
        fontSize: 12,
        minHeight: 20,
        boxShadow: '0px 1px 10px rgba(0,1,1,0.15)',
    },
    radio: {
        '&.Mui-checked': {
            color: Colors.darkNavy,
        },
    },
};

const RadioGroupChoice = styled('div')(styles.radioOption);
const TemplateHeaderContainer = styled('div')(styles.templateContainer);
const StyledRadio = styled(Radio)(styles.radio);
