import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Typography, withStyles } from '@material-ui/core';
import theme from '../../../theme';
import CurrencyInput from '../../../reusableComponents/currencyInput';
import { Text, Colors } from 'library';

const FixedPayInput = ({ pay, onChange }) => {
    const ErrorText = withStyles({
        root: {
            color: theme.palette.error.main,
        },
    })(Typography);

    const getErrorText = () => {
        if (!pay) {
            return <ErrorText variant="subtitle2">Enter total pay for job</ErrorText>;
        }
    };

    return (
        <Grid container item direction="column" alignContent="flex-start" style={{ marginLeft: 10 }}>
            <Grid item alignItems="center">
                <Text variant="body1" textStyle={{ minWidth: 200 }}>
                    Total Pay
                </Text>
            </Grid>
            <Grid container item direction="row">
                <Grid container item>
                    <CurrencyInput
                        textAlign="left"
                        minimumValue="0"
                        maximumValue="999999999"
                        fullWidth
                        value={pay}
                        onChange={onChange}
                        decimalPlaces={0}
                        outputFormat="number"
                        InputProps={{
                            style: {
                                fontSize: 18,
                                color: theme.palette.primary.main,
                            },
                            inputProps: {
                                style: {
                                    textAlign: 'left',
                                },
                            },
                            disableUnderline: false,
                            startAdornment: <Typography style={{ fontSize: 18 }}>$</Typography>,
                        }}
                        placeholder="0"
                        style={{ width: '35%' }}
                    />
                </Grid>
                <Grid container item alignItems="flex-start">
                    {getErrorText()}
                </Grid>
            </Grid>
        </Grid>
    );
};

export default FixedPayInput;
