import React from 'react';
import { useProviderProfileContext } from '../../ProviderProfileContext';
import { Paper, Text, Colors } from 'library';
import DocumentLinks from './DocumentShared';
import DownloadAllDocuments from './DownloadAllDocuments';

export default function BusinessDocumentsTab() {
    const { provider } = useProviderProfileContext();

    return (
        <Paper>
            <div style={{ borderRadius: 25, backgroundColor: Colors.lightTurq, padding: 10 }}>
                <div>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            marginTop: 10,
                        }}
                    >
                        <Text
                            color={Colors.turquoise}
                            variant="body1"
                            bold
                            textStyle={{ letterSpacing: 3.2, textTransform: 'uppercase' }}
                        >
                            Documents
                        </Text>
                        <div>
                            <DownloadAllDocuments provider={provider} />
                        </div>
                    </div>
                    <div style={{ paddingLeft: 30, paddingTop: 10 }}>
                        <DocumentLinks provider={provider} />
                    </div>
                </div>
            </div>
        </Paper>
    );
}
