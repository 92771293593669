import React from 'react';
import { Text, Colors, PrimaryButton, Highlight } from 'library';
import { Grid, useMediaQuery } from '@mui/material';
import ThreeStepSolutionImage from 'assets/images/marketing-site/home-three-step-solution.png';
import theme from 'theme';

const howItWorks = [
    {
        step: '01',
        title: 'Post',
        subtitle: "Child care programs post a job, whether it's a temporary or permanent role.",
    },
    {
        step: '02',
        title: 'Match',
        subtitle:
            'Our Matching Strategists and technology-based algoirthm finds you the best fit compliant, credentialed worker.',
    },
    {
        step: '03',
        title: 'Confirmation',
        subtitle: "Once confirmed, we send you the schedule and the worker's compliance file.",
    },
];

export function ThreeStepSolution({ signUp }: { signUp: () => void }) {
    const smDown = useMediaQuery(theme.breakpoints.down('sm'));
    return (
        <Grid
            container
            item
            direction="row"
            xs={12}
            style={{
                paddingTop: smDown ? 40 : 80,
                gap: 10,
                paddingLeft: smDown ? 40 : 80,
                paddingBottom: smDown ? 40 : 80,
                paddingRight: smDown ? 40 : undefined,
            }}
            justifyContent="space-between"
            overflow="hidden"
        >
            <Grid item container direction="column" xs={12} md={5} style={{ marginTop: '1.5%' }}>
                <Text variant="display">A streamlined, three step solution.</Text>
                <Grid item container xs={2} style={{ gap: 20 }} direction="column">
                    <Text variant="h2">
                        Our goal is to decrease both the time and money you put into hiring and retaining staff.
                    </Text>
                    <PrimaryButton onClick={signUp} buttonStyle={{ width: 200 }}>
                        Start Now
                    </PrimaryButton>
                </Grid>
            </Grid>
            <Grid
                xs={12}
                md={6}
                style={{
                    backgroundImage: smDown ? undefined : `url(${ThreeStepSolutionImage})`,
                    backgroundSize: smDown ? undefined : 'cover',
                    backgroundRepeat: 'no-repeat',
                    // backgroundImageSize: 'cover',
                    paddingTop: '10%',
                    paddingLeft: '2%',
                    paddingBottom: '2%',
                    marginRight: smDown ? '2%' : '-5%',
                    borderBottomLeftRadius: 80,
                }}
                item
                container
            >
                <Grid
                    style={{
                        boxShadow: '0px 3px 6px #00000029',
                        backgroundColor: smDown ? Colors.lightTurq : Colors.white,
                        borderRadius: 18,
                        gap: 20,
                        margin: 15,
                        ...(smDown ? styles.smallStyles : styles.largeStyles),
                    }}
                    item
                    container
                >
                    <Text bold variant="h1">
                        <Highlight>How It Works</Highlight>
                    </Text>
                    <Grid container item xs={12} style={{ gap: 20 }}>
                        {howItWorks.map((step: any) => (
                            <Grid
                                key={step.step}
                                container
                                item
                                flexDirection="row"
                                style={{ borderRadius: 18, gap: 20 }}
                                xs={12}
                            >
                                <Grid item container xs={1}>
                                    <Text bold variant="h2" textStyle={{ width: 'unset' }}>
                                        {step.step}
                                    </Text>
                                </Grid>
                                <Grid item container xs={12} md={3}>
                                    <Text bold variant="h1" textStyle={{ width: 'unset' }}>
                                        {step.title}
                                    </Text>
                                </Grid>
                                <Grid item container xs={12} md={6}>
                                    <Text variant="h2" textStyle={{ width: 'unset' }}>
                                        {step.subtitle}
                                    </Text>
                                </Grid>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

const styles = {
    smallStyles: {
        padding: 20,
    },
    largeStyles: {
        paddingTop: '1.5%',
        paddingLeft: '5%',
        paddingBottom: '5%',
    },
};
