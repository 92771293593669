import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { Colors, Text } from 'library';
import { useUserContext } from 'UserContext';
import { client, consoleLogInDev } from 'shared';
import usePublicSessionTracking from '../shared/PublicSessionTracking';
import ConnectPaymentModal from 'parent-portal/payments/Components/ConnectPaymentModal';
import { IDashboardData } from './Models/DashboardData';
import BusinessDashboard from './BusinessDashboard';
import FamilyLetter from './FamilyLetter';

const HomeDashboard = () => {
    const { user, role } = useUserContext();
    const [showSetUpTandemPay, setShowSetUpTandemPay] = useState(false);
    const [dashboardData, setDashboardData] = useState<IDashboardData>();
    const { usePageLoadTracking } = usePublicSessionTracking('Dashboard');

    const getData = () => {
        client(`api/dashboard/portal/`)
            .then((response: IDashboardData) => {
                setDashboardData(response);
            })
            .catch(consoleLogInDev);
    };

    useEffect(getData, []);

    usePageLoadTracking({ userId: user?.id });

    if (role === 'business_active') {
        return <BusinessDashboard data={dashboardData} updateData={getData} />;
    }

    return (
        <div style={{ backgroundColor: Colors.white, height: '100%' }}>
            <Grid container direction="row" style={{ paddingTop: '2%', paddingLeft: 50, paddingRight: 50 }}>
                <Grid item style={{ padding: 10 }}>
                    <Text variant="h1">Welcome {user ? `${user?.first_name}!` : 'back!'}</Text>
                </Grid>
                <FamilyLetter />
            </Grid>
            <ConnectPaymentModal
                fromRoute="Dashboard"
                isOpen={showSetUpTandemPay}
                onClose={() => setShowSetUpTandemPay(false)}
            />
        </div>
    );
};

export default HomeDashboard;
