import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { consoleLogInDev, queryStringifyObject } from '../../shared/Utils';
import { IHistoryApiResult } from './Models/PaymentHistory';
import { useUserContext } from '../../UserContext';
import { client } from 'shared';
import usePublicSessionTracking from '../shared/PublicSessionTracking';
import BusinessPayments from './BusinessPayments';
import FamilyPayments from './FamilyPayments';
import { IPastPaymentState } from './Models/PastPayment';
import PaymentsHeader from './Components/PaymentsHeader';

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};

const Payments = () => {
    const query = useQuery();
    const history = useHistory();
    const { role } = useUserContext();
    const { usePageLoadTracking } = usePublicSessionTracking('Payments');

    const [pastPayments, setPastPayments] = useState<IPastPaymentState>();
    const [isLoadingPastPayments, setIsLoadingPastPayments] = useState(false);
    const [hasUnpaidAppointments, setHasUnpaidAppointments] = useState<number>(1);

    usePageLoadTracking();

    const searchQuery = useLocation().search;
    useEffect(() => {
        getPayments();
    }, [searchQuery]);

    const getPageNumber = () => {
        const pageNumQuery = query.get('page');
        return pageNumQuery ? parseInt(pageNumQuery) : 1;
    };

    const getQuerystringObject = () => {
        return {
            page: getPageNumber(),
            size: 4,
        };
    };

    const getPayments = async () => {
        if (role === 'business_active') {
            return;
        }

        setIsLoadingPastPayments(true);
        try {
            const qs = queryStringifyObject(getQuerystringObject());
            const url = `payment/api/invoice/history/?${qs}`;
            const response = (await client(url)) as IHistoryApiResult;

            setPastPayments({
                payments: response.results,
                pageCount: response.count,
                pageSize: response.per_page,
                numberOfPages: response.num_pages,
            });
        } catch (error) {
            consoleLogInDev(error);
        }
        setIsLoadingPastPayments(false);
    };

    const onPageChange = (newPage: number) => {
        const params = getQuerystringObject();
        params.page = newPage;
        // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-comments/disallowComments
        const { size, ...rest } = params; // don't put page size in page query string
        const qs = queryStringifyObject(rest);
        const url = !qs ? 'payments' : `payments?${qs}`;
        history.push(url);
    };

    const onPaymentComplete = () => {
        const pageNum = getPageNumber();
        if (pageNum === 1) {
            getPayments();
        } else {
            onPageChange(1);
        }
    };

    return (
        <Grid
            container
            justify="center"
            alignContent="center"
            direction="column"
            style={{ padding: 10, paddingBottom: 100 }}
        >
            <PaymentsHeader />
            {role === 'business_active' ? (
                <BusinessPayments />
            ) : (
                <FamilyPayments
                    hasUnpaidAppointments={hasUnpaidAppointments}
                    onPaymentComplete={onPaymentComplete}
                    setHasUnpaidAppointments={setHasUnpaidAppointments}
                    isLoadingPastPayments={isLoadingPastPayments}
                    pastPayments={pastPayments}
                    getPageNumber={getPageNumber}
                    onPageChange={onPageChange}
                />
            )}
        </Grid>
    );
};

export default Payments;
