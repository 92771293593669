import { Colors } from 'library';
import React from 'react';

export default function ProfileDivider({ index, pageSize }: { index: number; pageSize: number }) {
    return index !== pageSize - 1 ? (
        <div
            style={{
                height: 1,
                width: '100%',
                backgroundColor: Colors.lightGrey,
                marginTop: 20,
                marginBottom: 20,
            }}
        />
    ) : null;
}
