import React, { useState } from 'react';
import { PrimaryButton, Text, TextButton } from 'library';
import { IBusinessJobDetailsModel, IShiftsByProvider } from '../models';
import { Grid } from '@mui/material';
import { BusinessSlotHeader } from './BusinessSlotHeader';
import TrialRunFeedbackModal from '../TrialRunFeedbackModal';
import { BoxContainer, ProgressBar } from './StyledComponents';

export default function TrialRunOverviewSection({
    providerShifts,
    job,
    getJob,
}: {
    providerShifts: IShiftsByProvider[];
    job: IBusinessJobDetailsModel;
    getJob: () => void;
}) {
    const [continueTrialRun, setContinueTrialRun] = useState(false);
    const [trialRunFeedbackOpen, setTrialRunFeedbackOpen] = useState(false);

    function trialRunAction(continueTrialRun: boolean) {
        return () => {
            setContinueTrialRun(continueTrialRun);
            setTrialRunFeedbackOpen(true);
        };
    }

    return (
        <Grid item container xs={12} style={{ gap: 20 }}>
            <Text variant="h1">Workers On Trial Run</Text>
            <Grid item container flexDirection="row" xs={12} style={{ gap: 20 }}>
                {providerShifts.map(({ provider, hours_required, hours_worked }) => (
                    <BoxContainer item container key={provider.id} xs={12} md={3} style={{ gap: 20 }}>
                        <BusinessSlotHeader provider={provider} headline="" />
                        <Grid item container flexDirection="column">
                            <ProgressBar
                                variant="determinate"
                                value={Math.min((hours_worked / hours_required) * 100, 100)}
                            />
                            <Text bold>{hours_worked.toFixed(2)} hours worked</Text>
                            <Text variant="caption">out of {hours_required} hour trial run</Text>
                        </Grid>
                        {!job.weekly_job_feedback?.length ? (
                            <Grid item container flexDirection="row" justifyContent="space-between">
                                <PrimaryButton
                                    buttonStyle={{ width: 'unset', padding: '5px 20px' }}
                                    textStyle={{ fontSize: 12 }}
                                    onClick={trialRunAction(true)}
                                >
                                    Continue Next Week
                                </PrimaryButton>
                                <TextButton
                                    buttonStyle={{ width: 'unset' }}
                                    textStyle={{ fontSize: 12 }}
                                    onClick={trialRunAction(false)}
                                >
                                    Remove Worker
                                </TextButton>
                            </Grid>
                        ) : null}
                        <TrialRunFeedbackModal
                            open={trialRunFeedbackOpen}
                            onClose={() => {
                                getJob();
                                setTrialRunFeedbackOpen(false);
                            }}
                            provider={provider}
                            initialContinueValue={continueTrialRun}
                            ongoingRequestId={job.id}
                        />
                    </BoxContainer>
                ))}
            </Grid>
        </Grid>
    );
}
