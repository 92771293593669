import React from 'react';
import { Text, Colors } from 'library';
import { Grid } from '@material-ui/core';
import { UpcomingJobModel } from '../nestedJobList/jobListModels';
import moment from 'moment';
import { ActiveJobActions } from './ActiveJobActions';
import { MoreOptions } from '../JobCardMoreOptions';
import { useUserContext } from 'UserContext';

const JobCard = ({ job }: { job: UpcomingJobModel }) => {
    const { getDisplayText } = useUserContext();
    function formatDate() {
        if (job.start_month) {
            return job.start_month;
        }
        const endDate = moment(job.end_date, 'YYYY-MM-DD');
        const formattedEndDate = endDate.format('MMM D, YYYY');

        if (!job.ongoing && !job.all_day) {
            return String(formattedEndDate);
        }

        const startDate = moment(job.start_date, 'YYYY-MM-DD');
        const formattedStartDate = startDate.format('MMM D');
        return String(formattedStartDate) + ' - ' + String(formattedEndDate);
    }

    function ColoredHeader() {
        const colorKey = job.business_job_type;
        const colors: { [key: string]: { color: string; text: string } } = {
            PERMANENT: { color: Colors.darkNavy, text: 'Permanent' },
            SUBSTITUTE: { color: Colors.turquoise, text: getDisplayText('substitute').capitalize() },
            TRIAL_RUN: { color: Colors.terraCotta, text: 'Trial Run' },
            DRAFT: { color: Colors.mediumRed, text: 'Draft' },
        };
        const color = job.status === 'DRAFT' ? colors.DRAFT : colorKey ? colors[colorKey as string] : null;

        return color ? (
            <Grid
                container
                justify="center"
                alignItems="center"
                style={{
                    height: 30,
                    backgroundColor: color.color,
                    borderTopLeftRadius: 18,
                    borderTopRightRadius: 18,
                }}
            >
                <Text variant="body2" bold color={Colors.white}>
                    {color.text}
                </Text>
            </Grid>
        ) : null;
    }

    return (
        <Grid item container xs={12}>
            <ColoredHeader />
            <Grid xs={12} style={{ padding: 20 }}>
                <Grid container direction="row" justify="space-between">
                    <Grid xs={10}>
                        <Text variant="h1" textStyle={{ wordBreak: 'break-word' }}>
                            {job.headline}
                        </Text>
                    </Grid>
                    <MoreOptions job={job} past={false} oneTime={!job.ongoing} review={false} jobId={job.id} />
                </Grid>
                <Text variant="h2" textStyle={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>
                    {formatDate()}
                </Text>
                {job.business_job_type ? (
                    <Text variant="caption" textStyle={{ wordBreak: 'break-word' }}>
                        {job.address.street} {job.address.city}, {job.address.state} {job.address.zip_code}
                    </Text>
                ) : null}
                {job.status !== 'DRAFT' ? <ActiveJobActions job={job} /> : null}
            </Grid>
        </Grid>
    );
};

export default JobCard;
