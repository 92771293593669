import React, { useEffect, useState } from 'react';
import { OutlinedTextInput, Text } from 'library';
import { styled } from '@mui/material';
import { Autocomplete } from '@material-ui/lab';
import { useClient } from 'shared';

import type { ActualLeanUser, PagingResult } from 'models';

export function SearchWorker({
    selectedWorker,
    setSelectedWorker,
    showAll = false,
}: {
    selectedWorker: ActualLeanUser | null;
    setSelectedWorker: (worker: ActualLeanUser | null) => void;
    showAll?: boolean;
}) {
    const [page, setPage] = useState<number>(1);
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [options, setOptions] = useState<ActualLeanUser[]>([]);

    const { loading, data, send, controller } = useClient<PagingResult<ActualLeanUser>>({
        url: `internal/user-lookup/search/?search_term=${searchTerm}&page=${page}&show_all=${showAll.toString()}`,
        options: { sendImmediately: false },
    });

    useEffect(() => {
        if (searchTerm.length >= 3) {
            setPage(1);
            controller?.abort();
            send();
        } else {
            setOptions([]);
        }
    }, [searchTerm]);

    useEffect(() => {
        if (page === 1) {
            setOptions(data?.results ?? []);
        } else {
            setOptions([...options, ...(data?.results ?? [])]);
        }
    }, [data?.results]);

    function loadMoreItems(e: React.UIEvent<HTMLElement>) {
        if (e.currentTarget.scrollHeight - e.currentTarget.scrollTop === e.currentTarget.clientHeight) {
            if (data && page < data?.num_pages) {
                setPage((prev) => prev + 1);
                send();
            }
        }
    }

    return (
        <Autocomplete
            value={selectedWorker}
            options={options ?? []}
            onChange={(_, value) => setSelectedWorker(value)}
            loading={loading}
            onInputChange={(_, value) => setSearchTerm(value)}
            renderInput={(params) => (
                <OutlinedTextInput
                    {...params}
                    style={{ marginTop: 10 }}
                    variant="outlined"
                    helperText="Select workers to set for this appointment. (3 character minimum)"
                    placeholder="Click here to search for workers"
                    value={searchTerm}
                />
            )}
            getOptionLabel={(option) => `${option.first_name} ${option.last_name} (${option.id})`}
            getOptionSelected={(option: ActualLeanUser, value: ActualLeanUser | null) => option?.id === value?.id}
            renderOption={(option: ActualLeanUser) => (
                <>
                    <StyledOption>
                        <Text>
                            {option.first_name} {option.last_name}
                        </Text>
                        <Text variant="caption" textStyle={{ paddingLeft: 5 }}>
                            ({option.id})
                        </Text>
                    </StyledOption>
                    {options?.[-1]?.id === option.id && <Text variant="caption">Loading...</Text>}
                </>
            )}
            ListboxProps={{
                onScroll: loadMoreItems,
            }}
        />
    );
}

const StyledOption = styled('div')({
    display: 'flex',
    alignItems: 'baseline',
});
