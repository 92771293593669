import React from 'react';
import { IconButton, styled } from '@mui/material';
import { MoreVert, NotificationsOff } from '@mui/icons-material';
import { addDays } from 'date-fns';
import { Colors, Menu } from 'library';
import { client, consoleLogInDev } from 'shared';

export default function GroupTaskMenu({
    inboxId,
    businessLocationId,
    refresh,
}: {
    inboxId: number;
    businessLocationId: number;
    refresh: () => void;
}) {
    function onSnoozeClick() {
        let nextDay = addDays(new Date(), 1);
        while (nextDay.getDay() === 6 || nextDay.getDay() === 0) {
            nextDay = addDays(nextDay, 1);
        }

        client('api/matching-inbox/snooze/', {
            method: 'PATCH',
            body: { inbox_id: inboxId, expires_at: nextDay.toISOString(), business_location_id: businessLocationId },
        })
            .then(refresh)
            .catch(consoleLogInDev);
    }

    const options = [{ text: 'Snooze 1 day', icon: <NotificationsOff />, onClick: onSnoozeClick }];

    return (
        <Menu
            options={options}
            anchor={
                <StyledIconButton onClick={() => {}}>
                    <MoreVert htmlColor={Colors.darkNavy} fontSize="small" />
                </StyledIconButton>
            }
        />
    );
}

const StyledIconButton = styled(IconButton)({
    padding: 5,
    color: Colors.white,
});
