import React from 'react';
import { useProviderProfileContext } from '../../ProviderProfileContext';
import BusinessCredentials from './BusinessCredentials';
import { Paper } from 'library';

export default function ProviderProfileBusinessCredentialsTab() {
    const { provider, providerQualifications, childcareCenterQualifications } = useProviderProfileContext();

    return (
        <Paper>
            <BusinessCredentials
                provider={provider}
                qualifications={providerQualifications}
                childcareCenterQualifications={
                    childcareCenterQualifications.length > 0 ? childcareCenterQualifications[0] : null
                }
            />
        </Paper>
    );
}
