import React, { useState, useEffect } from 'react';
import { consoleLogInDev } from 'shared';
import { UpcomingJobModel } from '../../nestedJobList/jobListModels';
import JobCard from '../JobCard';
import { Text, Paper } from 'library';
import { upcomingJobs } from 'shared/JobApi';
import { Grid, CircularProgress } from '@material-ui/core';

export default function JobPostsList() {
    const [jobs, setJobs] = useState<UpcomingJobModel[]>([]);
    const [loading, setLoading] = useState(true);
    useEffect(getUpcomingJobs, []);
    function getUpcomingJobs() {
        upcomingJobs()
            .then((jobsResponse) => {
                setJobs(jobsResponse);
            })
            .catch(consoleLogInDev)
            .finally(() => setLoading(false));
    }

    if (loading) {
        return (
            <Grid container item xs={9} style={{ height: '100vh' }}>
                <Grid container alignItems="center" justify="center">
                    <Grid item>
                        <CircularProgress />
                    </Grid>
                </Grid>
            </Grid>
        );
    }

    return (
        <>
            <Text bold variant="body1">
                Jobs:
            </Text>
            <Grid container item direction="row" xs={12}>
                {jobs.length > 0
                    ? jobs.map((job) => (
                          <Grid key={job.id} xs={12} md={4} lg={3} style={{ margin: 10 }}>
                              <Paper padding={0}>
                                  <JobCard job={job} />
                              </Paper>
                          </Grid>
                      ))
                    : null}
            </Grid>
        </>
    );
}
