import React from 'react';
import { Text, Colors, Highlight } from 'library';
import { Grid } from '@mui/material';
import ComparisonTable from './ComparisonTable';

export function StaffingTool() {
    return (
        <Grid
            container
            item
            style={{
                background:
                    'radial-gradient(circle, rgba(232,246,244,0.7539390756302521) 0%, rgba(249,217,220,0.7707457983193278) 100%)',
                gap: 40,
                padding: 40,
                paddingBottom: 60,
            }}
            justifyContent="center"
            alignItems="center"
            direction="column"
            flexWrap="wrap"
        >
            <Text variant="display" textStyle={{ textAlign: 'center' }}>
                The <Highlight color={Colors.lightTerraCotta}>all-in-one</Highlight> staffing tool that actually works.
            </Text>
            <ComparisonTable />
        </Grid>
    );
}
