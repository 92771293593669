import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { Colors } from '../../../../constants';
import { TotalLabelProps } from '../../Models/PairingList';

const TotalLabel = ({ amount, defaultPayIsCreditCard }: TotalLabelProps) => {
    return (
        <Grid
            container
            direction="row"
            alignItems="flex-start"
            justify="center"
            style={{
                backgroundColor: 'white',
                padding: '1em',
                margin: '1em 0',
                borderRadius: '10px',
            }}
        >
            <Typography
                style={{
                    fontSize: '1em',
                    color: Colors.navy,
                    fontWeight: 'bold',
                }}
            >
                $
            </Typography>
            <Typography
                display="inline"
                style={{
                    fontSize: '2em',
                    color: Colors.navy,
                    fontWeight: 'bold',
                    lineHeight: 1,
                }}
            >
                {amount.toFixed(2)}
                {defaultPayIsCreditCard && (
                    <Typography
                        display="inline"
                        style={{
                            fontSize: '1em',
                            color: Colors.mediumGrey,
                            lineHeight: 1,
                        }}
                    >
                        *
                    </Typography>
                )}
            </Typography>
        </Grid>
    );
};

export default TotalLabel;
