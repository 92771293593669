import React from 'react';
import { RadioGroup as MuiRadioGroup, Radio, FormControl, FormControlLabel } from '@material-ui/core';
import { Text } from '../text';

export default function RadioGroup({
    value,
    setValue,
    values,
    horizontal = false,
    disabled = false,
    radioStyle,
    labelStyle,
    style,
}: {
    value: string;
    setValue: (x: string) => void;
    values: { [key: string]: string };
    horizontal?: boolean;
    disabled?: boolean;
    radioStyle?: React.CSSProperties;
    labelStyle?: React.CSSProperties;
    style?: React.CSSProperties;
}) {
    return (
        <FormControl disabled={disabled}>
            <MuiRadioGroup
                value={value}
                onChange={(e) => {
                    setValue(e.target.value);
                }}
                style={{ marginTop: 10, ...style }}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: horizontal ? 'row' : 'column',
                        alignItems: horizontal ? 'center' : 'flex-start',
                    }}
                >
                    {Object.keys(values).map((val) => (
                        <FormControlLabel
                            key={val}
                            value={val}
                            control={<Radio color="primary" disableRipple disableFocusRipple style={radioStyle} />}
                            label={
                                <Text
                                    variant="body1"
                                    textStyle={{
                                        marginRight: 8,
                                        fontSize: 14,
                                        ...labelStyle,
                                    }}
                                >
                                    {values[val]}
                                </Text>
                            }
                        />
                    ))}
                </div>
            </MuiRadioGroup>
        </FormControl>
    );
}
