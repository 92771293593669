import React, { useState } from 'react';
import JobFormPage from '../JobFormPage';
import { useJobFormContext } from 'parent-portal/forms/JobFormContext';
import BusinessDatesAndTimes from './BusinessDatesAndTimes';

export default function BusinessDateAndTimePage({ name }: { name: string }) {
    const [isValid, setIsValid] = useState(false);
    const { goToNext } = useJobFormContext();

    return (
        <JobFormPage
            title="When is your job?"
            onNextClicked={() => goToNext(name)}
            backText="Back"
            nextDisabled={!isValid}
            id={name}
        >
            <BusinessDatesAndTimes setIsValid={setIsValid} />
        </JobFormPage>
    );
}
