import React, { useState, useEffect } from 'react';
import Input from '../../../reusableComponents/input';
import FormField from './FormField';
import ErrorText from './ErrorText';
import { FormFieldProps } from './FormTypes';

const oneOrMoreNonWhitespaceRegex = /[^\s]+/;

export default function OtherJobCategoryInput({
    jobTypeId,
    otherJobType,
}: {
    jobTypeId: FormFieldProps<number>;
    otherJobType: FormFieldProps<string>;
}) {
    const { value, setValue, isValid, setValid } = otherJobType;
    const [showErrors, setShowErrors] = useState(false);

    useEffect(
        () =>
            setValid(
                jobTypeId.value >= 0 || (!!value && jobTypeId.value < 0 && oneOrMoreNonWhitespaceRegex.test(value)),
            ),
        [value, jobTypeId.value],
    );

    if (jobTypeId.value >= 0 || jobTypeId.value === -2) {
        return <></>;
    }

    function onValueChanged(event: any) {
        setShowErrors(true);

        setValue(event.target.value);
    }

    function hasError() {
        return showErrors && !isValid;
    }

    return (
        <FormField title="Other Type*" style={{ marginTop: 30 }}>
            <Input
                fullWidth
                style={{ marginBottom: 0 }}
                value={value}
                onChange={onValueChanged}
                multiline
                InputLabelProps={{ shrink: true }}
            />
            {hasError() && <ErrorText>Please enter a job description</ErrorText>}
        </FormField>
    );
}
