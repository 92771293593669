

export function getDayString(filteredDays: Array<any>) {

    let dayString = ""

    Object.entries(filteredDays).map(([index, [dayName]]) => {
        if (filteredDays.length > 1 && String(index) !== '0') {
            if (String(filteredDays.length - 1) !== index) {
                dayString += `, ${dayName}`;
            } else {
                dayString += ` and ${dayName}`;
            }
        } else {
            dayString += dayName;
        }
    })
    
    return dayString
}
