import React from 'react';
import { Grid } from '@material-ui/core';
import Text from '../text/Text';
import { useWidth } from 'reusableComponents/useWidth';
import CloseButton from 'reusableComponents/CloseButton';

export default function ModalHeader({
    onClose,
    title = 'Login',
    subtitle = '',
}: {
    onClose?: () => void;
    title?: string;
    subtitle?: string;
}) {
    const breakpoint = useWidth();
    return (
        <>
            {onClose !== undefined && breakpoint === 'xs' ? (
                <Grid container item>
                    <CloseButton onClose={onClose} />
                </Grid>
            ) : null}
            <Grid container item>
                <Text variant="display" bold textStyle={{ fontSize: 28, fontWeight: 900 }}>
                    {title}
                </Text>
            </Grid>
            <Grid container item>
                <Text variant="body2" bold>
                    {subtitle}
                </Text>
            </Grid>
        </>
    );
}
