import React from 'react';
import { Slider, SliderProps } from '@mui/material';
import { Colors } from 'library/theme';

export default function TandemSlider(sliderProps: SliderProps) {
    return (
        <Slider
            sx={{
                padding: '50px 0px 0px',
                margin: '20px',
                '& .MuiSlider-thumb': {
                    height: 32,
                    width: 32,
                    backgroundColor: Colors.white,
                    border: `solid 1px ${Colors.darkNavy}`,
                },
                '& .MuiSlider-valueLabel': {
                    fontSize: 14,
                    fontWeight: 'normal',
                    top: -6,
                    backgroundColor: 'transparent',
                    color: Colors.darkNavy,
                    '&:before': {
                        display: 'none',
                    },
                    '& *': {
                        background: 'transparent',
                        color: Colors.darkNavy,
                    },
                },
                '& .MuiSlider-track': {
                    border: 'none',
                    height: 22,
                    backgroundColor: Colors.darkNavy,
                },
                '& .MuiSlider-rail': {
                    opacity: 1,
                    height: 22,
                    left: '-5%',
                    width: '105',
                    backgroundColor: Colors.darkNavy,
                },
                '& .MuiSlider-mark': {
                    backgroundColor: Colors.darkNavy,
                    height: 0,
                    width: '1px',
                    '&.MuiSlider-markActive': {
                        opacity: 1,
                        backgroundColor: 'currentColor',
                    },
                },
                '& .MuiSlider-markLabel': {
                    color: Colors.darkNavy,
                    fontFamily: 'Work Sans',
                    marginTop: '20px',
                    [`&[data-index="${
                        Array.isArray(sliderProps.marks) && sliderProps.marks.length ? sliderProps.marks?.length - 1 : 1
                    }"]`]: {
                        transform: 'translateX(-150%)',
                    },
                },
            }}
            valueLabelDisplay="on"
            {...sliderProps}
        />
    );
}
