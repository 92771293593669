import { Colors, DraggableDialog, IconButton, LoadingIndicator } from 'library';
import React, { useEffect, useState } from 'react';
import TaskIcon from '@mui/icons-material/Task';
import TaskForm, { TaskFormOptions } from 'internal/TaskList/TaskForm';
import { getTaskFormOptions } from 'internal/TaskList/TaskFormFields';
import { AdminBusinessLocation } from 'models/AdminBusinessLocation';

export default function AddTaskDialog({
    businessLocation,
    size,
}: {
    businessLocation: AdminBusinessLocation;
    size?: number;
}) {
    const [isOpen, setIsOpen] = useState(false);
    const ref = React.useRef<HTMLButtonElement>(null);
    const [options, setOptions] = useState<TaskFormOptions>();

    const getOptions = () => {
        getTaskFormOptions().then((result) => {
            setOptions({ ...result, businessLocation: { id: businessLocation.id, name: businessLocation.name } });
        });
    };

    useEffect(() => {
        if (isOpen && !options) {
            getOptions();
        }
    }, [isOpen]);

    return (
        <>
            <IconButton
                tooltip="Add a task"
                ref={ref}
                icon={TaskIcon}
                color={Colors.darkNavy}
                onClick={() => setIsOpen(true)}
                size={size}
                iconStyle={size ? { width: size + 5, height: size + 5 } : undefined}
            />
            <DraggableDialog
                isOpen={isOpen}
                dialogTitle="Add a Task"
                onClose={() => setIsOpen(false)}
                initialPosition="center"
                anchorElement={ref.current}
            >
                {options ? <TaskForm options={options} onClose={() => setIsOpen(false)} /> : <LoadingIndicator />}
            </DraggableDialog>
        </>
    );
}
