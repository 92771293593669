import React from 'react';
import { Grid } from '@mui/material';
import { IHourReport } from 'internal/payments/HourReportModels';
import { TitleDescription } from './TitleDescription';
import { formatMinutesFromDate } from 'shared/Dates';

export function BusinessSlotHourReportDetails({ hourReport }: { hourReport: IHourReport }) {
    return (
        <Grid item container direction="row" style={{ marginTop: 5, gap: 20 }}>
            {hourReport.start ? (
                <TitleDescription title={'Clocked In'} description={`${formatMinutesFromDate(hourReport.start)}`} />
            ) : (
                <TitleDescription title={'Reported Late'} description={`${hourReport.late_minutes} minutes`} />
            )}
            {hourReport.end ? (
                <TitleDescription title="Clocked Out" description={`${formatMinutesFromDate(hourReport.end)}`} />
            ) : null}
            {hourReport.approved_break ?? 0 > 0 ? (
                <TitleDescription title="Break" description={`${hourReport.approved_break} minutes`} />
            ) : null}
            {hourReport.hours_worked ?? 0 > 0 ? (
                <TitleDescription title="Total Hours" description={`${hourReport.hours_worked} hours`} />
            ) : null}
        </Grid>
    );
}
