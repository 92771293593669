import React from 'react';
import { Text, Paper, Link } from 'library';
import { Grid } from '@mui/material';
import download from 'shared/Download';

type DocumentToVerify = {
    added_at: string;
    first_name: string;
    id: number;
    last_name: string;
    name: string;
    record: string;
    title: string;
    user_id: number;
};

export default function ILFingerprintCheckResultsPaper({
    fingerprintsOnFile,
    fingerprintCheckDocument,
}: {
    fingerprintsOnFile: boolean;
    fingerprintCheckDocument: DocumentToVerify;
}) {
    const handleDocDownload = (doc: DocumentToVerify) => {
        const endpoint = `api/documents/${doc.id}/download`;
        download(endpoint);
    };

    return (
        <Paper style={styles.resultsPaper}>
            <Grid container direction="row" justifyContent="space-between" spacing={2}>
                <Grid item xs={6}>
                    <Text>{`Fingerprints${!fingerprintsOnFile ? ' NOT ' : ' '}on file`}</Text>
                </Grid>
                <Grid item xs={6}>
                    <Link
                        onClick={() => {
                            handleDocDownload(fingerprintCheckDocument);
                        }}
                        style={styles.resultsLink}
                    >
                        Fingerprint Check Document
                    </Link>
                </Grid>
            </Grid>
        </Paper>
    );
}

const styles = {
    resultsPaper: {
        marginTop: 20,
    },
    resultsLink: {
        marginLeft: 1,
        cssFloat: 'inline-end',
    },
};
