import React from 'react';
import { OutlinedDropdown } from 'library';
import { relationshipSelectOptions, useFormStyles } from '../../constants';
import { WorkerReferenceForm, ErrorState } from '../../models';

export default function DropdownInputField({
    fieldName,
    fieldValue,
    form,
    errorState,
    placeholder,
    handleOnChange,
}: {
    fieldName: string;
    fieldValue: string | undefined;
    form: WorkerReferenceForm;
    errorState: ErrorState;
    placeholder: string;
    handleOnChange: (formArg: WorkerReferenceForm, key: string, value: string | number | undefined) => void;
}) {
    const styles = useFormStyles();
    return (
        <OutlinedDropdown
            placeholder={placeholder}
            value={fieldValue ?? ''}
            style={
                errorState[fieldName as keyof ErrorState].inErrorState
                    ? styles.dropDownFieldInputError
                    : styles.dropDownFieldInput
            }
            onChange={(e) => handleOnChange(form, fieldName, e.target.value)}
            fields={relationshipSelectOptions}
        />
    );
}
