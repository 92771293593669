import React from 'react';
import { useHistory } from 'react-router';
import { Menu, MenuItem } from '@mui/material';
import { Colors } from 'library';

export default function WorkerMenu({
    isMenuOpen,
    handleMenuClose,
    anchorRef,
}: {
    isMenuOpen: boolean;
    handleMenuClose: () => void;
    anchorRef: React.RefObject<HTMLDivElement> | null;
}) {
    const history = useHistory();
    function navFromMenu(route: string) {
        return () => {
            history.push(`/${route}`);
            handleMenuClose();
        };
    }
    return (
        <Menu
            anchorEl={anchorRef?.current}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            open={isMenuOpen}
            onClose={handleMenuClose}
            sx={{
                borderRadius: 18,
            }}
        >
            <MenuItem onClick={navFromMenu('how-it-works')} sx={{ color: Colors.darkNavy }}>
                How It Works
            </MenuItem>
            <MenuItem onClick={navFromMenu('trial-runs')} sx={{ color: Colors.darkNavy }}>
                Permanent Hires
            </MenuItem>
            <MenuItem onClick={navFromMenu('substitutes')} sx={{ color: Colors.darkNavy }}>
                Temporary Staff
            </MenuItem>
        </Menu>
    );
}
