import { MainContainer, TextContainer } from '../components/Containers';
import { Colors, Text } from 'library';
import { Link } from 'react-router-dom';
import React from 'react';
import ThanksForFeedback from '../components/ThanksForFeedback';

type ProviderWelcomeBackProps = {
    providerFirstName: string;
    businessName: string;
};

const ProviderWelcomeBack = ({ providerFirstName, businessName }: ProviderWelcomeBackProps) => {
    return (
        <MainContainer>
            <TextContainer>
                <ThanksForFeedback />
            </TextContainer>
            <TextContainer>
                <Text variant="body1">
                    Got it. {providerFirstName} will still be allowed to book shifts with {businessName}.
                </Text>
            </TextContainer>
            <TextContainer>
                <Link to="/home" style={{ color: Colors.darkNavy, textDecoration: 'underline', fontWeight: 'bold' }}>
                    Back home
                </Link>
            </TextContainer>
        </MainContainer>
    );
};

export default ProviderWelcomeBack;
