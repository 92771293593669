import React from 'react';
import { Text, Colors } from 'library';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarDays, faCreditCard, faPaperclip } from '@fortawesome/free-solid-svg-icons';
import { IconProp, SizeProp } from '@fortawesome/fontawesome-svg-core';

interface Bullet {
    key: string;
    text: string;
    icon: IconProp;
    iconSize: SizeProp;
}

export default function ProviderBullets({ smDown }: { smDown: boolean }) {
    const bullets = [
        {
            key: 'compliance',
            text: 'Manage your compliance.',
            icon: faPaperclip,
            iconSize: (smDown ? 'lg' : '4x') as SizeProp,
        },
        {
            key: 'schedule',
            text: 'Build your own schedule',
            icon: faCalendarDays,
            iconSize: (smDown ? 'lg' : '4x') as SizeProp,
        },
        {
            key: 'payment',
            text: 'Get paid daily.',
            icon: faCreditCard,
            iconSize: (smDown ? 'lg' : '4x') as SizeProp,
        },
    ];

    const styles = {
        container: {
            display: 'flex',
            flexDirection: smDown ? 'column' : 'row',
            paddingTop: smDown ? 0 : 40,
        },
        bullet: {
            display: 'flex',
            flexDirection: smDown ? 'row' : 'column',
            marginBottom: 30,
            alignItems: 'center',
            marginRight: smDown ? 0 : 60,
            width: smDown ? undefined : 140,
        },
        circle: {
            backgroundColor: Colors.mediumTurq,
            minWidth: smDown ? 30 : 110,
            minHeight: smDown ? 30 : 110,
            borderRadius: 80,
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginBottom: smDown ? 0 : 30,
            marginRight: smDown ? 20 : 0,
        },
    } as { [key: string]: React.CSSProperties };

    function Row({ bullet }: { bullet: Bullet }) {
        return (
            <div style={styles.bullet}>
                <div style={styles.circle}>
                    <FontAwesomeIcon icon={bullet.icon} color={Colors.lightTurq} size={bullet.iconSize} />
                </div>
                <Text variant={smDown ? 'body1' : 'h2'} textStyle={{ textAlign: smDown ? 'left' : 'center' }}>
                    {bullet.text}
                </Text>
            </div>
        );
    }

    return (
        <div style={styles.container}>
            {bullets.map((bullet) => (
                <Row key={bullet.key} bullet={bullet} />
            ))}
        </div>
    );
}
