import React from 'react';
import { Text } from 'library';
import { useProviderProfileContext } from '../../ProviderProfileContext';
import ProviderProfileChildCare from './ProviderProfileChildCare';
import ProviderProfileTitledContainer from '../../ProviderProfileTitledContainer';

export default function ProviderProfileJobType() {
    const { provider, selectedProfile } = useProviderProfileContext();

    return selectedProfile?.job_type.name === 'Child Care' || selectedProfile?.description?.trim() ? (
        <ProviderProfileTitledContainer
            title={selectedProfile?.job_type.name || ''}
            subtitle={`$${selectedProfile?.hourly_rate || provider.pay_preference_min}+/hr`}
        >
            <Text variant="body2">{selectedProfile?.description}</Text>
            {selectedProfile?.job_type.name === 'Child Care' && <ProviderProfileChildCare />}
        </ProviderProfileTitledContainer>
    ) : null;
}
