import React, { CSSProperties } from 'react';

import Colors from 'constants/Colors';
import { Text } from 'library';
import { Grid } from '@material-ui/core';

import { useMediaQuery } from '@material-ui/core';
import theme from 'theme';

const dayAbbreviations = {
    Sunday: 'S',
    Monday: 'M',
    Tuesday: 'T',
    Wednesday: 'W',
    Thursday: 'T',
    Friday: 'F',
    Saturday: 'S',
};

interface ApplicationDaysSummaryProps {
    allJobDays: string[];
    daysAppliedTo: string[];
}

export default function ApplicationDaysSummary({ allJobDays, daysAppliedTo }: ApplicationDaysSummaryProps) {
    const daysOfTheWeek = Object.keys(dayAbbreviations);

    return (
        <Grid container direction="row" wrap="nowrap">
            {daysOfTheWeek.map((day: string) =>
                daysAppliedTo?.includes(day) ? (
                    <AppliedDay day={day} />
                ) : allJobDays.includes(day) ? (
                    <NonAppliedDay day={day} />
                ) : (
                    <NormalDay day={day} />
                ),
            )}
        </Grid>
    );
}

const normalSizing = {
    width: '1.4rem',
    height: '1.4rem',
    marginRight: 5,
};

const smallSizing = {
    width: '1rem',
    height: '1rem',
    marginRight: 4,
};

const square = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    aspectRatio: '1',
};

function NormalDay({ day, style }: { day: string; style?: CSSProperties }) {
    const smallOrLess = useMediaQuery(theme.breakpoints.down('sm'));
    const sizingStyle = smallOrLess ? smallSizing : normalSizing;

    return (
        <div style={{ ...style, ...square, ...sizingStyle }}>
            <Text
                bold
                variant="deprecatedCaption"
                textStyle={{ fontSize: smallOrLess ? '0.6rem' : undefined, padding: 0 }}
            >
                {dayAbbreviations[day as keyof typeof dayAbbreviations]}
            </Text>
        </div>
    );
}

function AppliedDay({ day }: { day: string }) {
    return <NormalDay day={day} style={borderStyle(Colors.mediumTurq)} />;
}

function NonAppliedDay({ day }: { day: string }) {
    return <NormalDay day={day} style={borderStyle('#fff')} />;
}

function borderStyle(color: string) {
    return {
        backgroundColor: color,
        borderWidth: 1,
        borderRadius: '100%',
        borderStyle: 'solid',
        borderColor: Colors.mediumGrey,
    };
}
