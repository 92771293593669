import React, { useState } from 'react';
import theme from 'theme';
import { Colors, Text, Highlight, IconButton, LoadingIndicator, PrimaryButton } from 'library';
import { Grid, Icon, InputAdornment, TextField, styled, useMediaQuery } from '@mui/material';
import { Check, Close, Place, Search } from '@mui/icons-material';
import { track } from 'shared';

import WorkerSpotlightCard from './WorkerSpotlightCard';

import type { ISpotlightWorker } from 'parent-portal/home/Models/DashboardData';

export function StaffNearYou({
    signUp,
    zipCode,
    searchedZipCode,
    updateZipCode,
    onSearch,
    loading,
    status,
    workers,
}: {
    signUp: () => void;
    zipCode: string;
    searchedZipCode: string;
    updateZipCode: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onSearch: () => void;
    loading: boolean;
    status?: number;
    workers?: ISpotlightWorker[];
}) {
    const smDown = useMediaQuery(theme.breakpoints.down('sm'));
    const [showHelperText, setShowHelperText] = useState(false);

    function search() {
        if (zipCode.length < 5) {
            setShowHelperText(true);
        } else {
            setShowHelperText(false);
            onSearch();
        }
    }

    function enterPressHandler(e: React.KeyboardEvent<HTMLInputElement>) {
        if (e.key === 'Enter') {
            search();
        }
    }

    return (
        <Container container item flexDirection="row" xs={12}>
            <SubContainer smDown={smDown.toString()} item container xs={12} md={5} flexDirection="column">
                <Text variant="display" bold textStyle={{ marginBottom: smDown ? '6%' : '2%' }}>
                    <Highlight color={Colors.mediumTurq}>Staff Near {searchedZipCode}</Highlight>
                </Text>
            </SubContainer>
            <InteriorContainer smDown={smDown.toString()} item container flexDirection="column" xs={12} md={12}>
                <Text bold>Zip Code</Text>
                <Grid
                    item
                    container
                    flexDirection="row"
                    xs={12}
                    md={12}
                    justifyContent="space-between"
                    alignItems="center"
                    style={{ marginTop: 10 }}
                >
                    <Grid item container xs={8} md={4}>
                        <TextField
                            variant="outlined"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Place style={{ color: Colors.darkNavy }} />
                                    </InputAdornment>
                                ),
                                style: {
                                    fontFamily: 'Outfit',
                                    color: Colors.darkNavy,
                                    borderRadius: 12,
                                },
                            }}
                            style={{ width: '100%' }}
                            placeholder="Zip Code"
                            value={zipCode}
                            onChange={updateZipCode}
                            onKeyUp={enterPressHandler}
                            helperText={showHelperText ? 'Please enter a valid zip code' : null}
                            FormHelperTextProps={{ style: { fontFamily: 'Outfit', color: Colors.error } }}
                            maxRows={1}
                        />
                    </Grid>
                    <Grid item container xs={2} md={2} justifyContent="flex-end">
                        <IconButton
                            icon={Search}
                            onClick={search}
                            iconStyle={{ color: Colors.white }}
                            backgroundColor={Colors.darkNavy}
                            size={48}
                        />
                    </Grid>
                </Grid>
            </InteriorContainer>
            <Grid
                container
                xs={12}
                style={{
                    minHeight: 600,
                    marginTop: 20,
                    marginBottom: 20,
                }}
            >
                <Grid
                    item
                    container
                    flexDirection="column"
                    xs={12}
                    md={12}
                    style={{
                        backgroundColor: Colors.white,
                        borderRadius: 18,
                        border: `1px solid ${Colors.mediumGrey}`,
                        padding: '30px 20px 30px 20px',
                        marginBottom: smDown ? '15%' : 0,
                        alignItems: 'center',
                    }}
                >
                    {loading && <LoadingIndicator />}
                    {!loading && (status === 204 || !workers) && (
                        <Grid container xs={12} justifyContent="center" height="fit-content">
                            <Grid container xs={12} justifyContent="center" height="fit-content">
                                <NotInYourAreaContainer>
                                    <Icon
                                        component={Close}
                                        style={{
                                            color: Colors.white,
                                            backgroundColor: Colors.error,
                                            borderRadius: 100,
                                            padding: 5,
                                            marginRight: 10,
                                        }}
                                    />
                                    <Text>Bummer! We're not in your area yet.</Text>
                                </NotInYourAreaContainer>
                            </Grid>
                            <Grid
                                container
                                xs={12}
                                alignItems="center"
                                style={{
                                    backgroundColor: Colors.lightTurq,
                                    borderRadius: 16,
                                    padding: 20,
                                    marginTop: 10,
                                }}
                                direction="column"
                                height="fit-content"
                            >
                                <Text
                                    variant="display"
                                    textStyle={{ marginTop: 10, width: '70%', textAlign: 'center' }}
                                >
                                    We're expanding quickly! Make an account to be notified when we open in your area.
                                </Text>
                                <PrimaryButton
                                    buttonStyle={{
                                        width: 'fit-content',
                                        height: 'fit-content',
                                        marginTop: 20,
                                        marginBottom: 10,
                                    }}
                                    onClick={() => {
                                        track('Unsupported Region Customer Clicked Sign Up Button');
                                        signUp();
                                    }}
                                >
                                    Create Free Account
                                </PrimaryButton>
                            </Grid>
                        </Grid>
                    )}
                    {!loading && status === 200 && !!workers && (
                        <Grid container xs={12} justifyContent="center">
                            <Grid container xs={12} justifyContent="center" height="fit-content">
                                <SuccessContainer>
                                    <Icon
                                        component={Check}
                                        style={{
                                            color: Colors.white,
                                            backgroundColor: Colors.turquoise,
                                            borderRadius: 100,
                                            padding: 5,
                                            marginRight: 10,
                                        }}
                                    />
                                    <Text>Good news! We found some great workers near you!</Text>
                                </SuccessContainer>
                            </Grid>
                            <Grid container xs={12} justifyContent="center">
                                {workers.map((worker, index) => (
                                    <Grid item xs={12} md={4} justifyContent="center">
                                        <WorkerSpotlightCard key={index} worker={worker} />
                                    </Grid>
                                ))}
                            </Grid>
                            <Grid
                                container
                                xs={12}
                                alignItems="center"
                                style={{
                                    backgroundColor: Colors.lightTurq,
                                    borderRadius: 16,
                                    padding: 20,
                                    marginTop: 40,
                                }}
                                direction="column"
                            >
                                <Text variant="display" textStyle={{ marginTop: 10 }}>
                                    Interested in seeing more worker profiles?
                                </Text>
                                <PrimaryButton
                                    buttonStyle={{
                                        width: 'fit-content',
                                        height: 'fit-content',
                                        marginTop: 20,
                                        marginBottom: 10,
                                    }}
                                    onClick={() => {
                                        track('Supported Region Customer Clicked Sign Up Button');
                                        signUp();
                                    }}
                                >
                                    Create Free Account
                                </PrimaryButton>
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </Container>
    );
}

const Container = styled(Grid)({
    height: 'fit-content',
});

const SubContainer = styled(Container)(({ smDown }: { smDown: string }) => ({
    marginTop: smDown === 'true' ? '6%' : '2%',
}));

const InteriorContainer = styled(SubContainer)({
    backgroundColor: Colors.white,
    border: `1px solid ${Colors.mediumGrey}`,
    borderRadius: 18,
    padding: '20px 30px 20px 30px',
});

const NotInYourAreaContainer = styled('div')({
    backgroundColor: Colors.lightRed,
    border: `1px solid ${Colors.error}`,
    borderRadius: 16,
    display: 'flex',
    height: 'fit-content',
    padding: 15,
    width: 'fit-content',
});

const SuccessContainer = styled('div')({
    backgroundColor: Colors.lightTurq,
    border: `1px solid ${Colors.turquoise}`,
    borderRadius: 16,
    display: 'flex',
    height: 'fit-content',
    padding: 15,
    width: 'fit-content',
});
