import React from 'react';

import DiscoverySourcePopUp from 'reusableComponents/DiscoverySourcePopUp';
import { useNotificationContext } from '../NotificationContext/NotificationContext';

export default function NotificationModals() {
    const { modalsOpen, closeModal } = useNotificationContext();

    return (
        <>
            <DiscoverySourcePopUp
                open={modalsOpen.discoverySource}
                onClose={() => {
                    closeModal('discoverySource');
                }}
            />
        </>
    );
}
