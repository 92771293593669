import React from 'react';
import Copy from '@mui/icons-material/CopyAll';
import IconButton from './IconButton';
import { Colors } from '../../theme';

interface ICopyIconButtonProps {
    tooltip?: string;
    textToCopy: string;
}

export default function CopyIconButton({ tooltip, textToCopy }: ICopyIconButtonProps) {
    return (
        <IconButton
            tooltip={tooltip}
            onClick={() => navigator.clipboard.writeText(textToCopy)}
            icon={Copy}
            backgroundColor={Colors.darkNavy}
            color={Colors.white}
            style={{ marginLeft: 5 }}
        />
    );
}
