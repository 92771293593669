import React, { useState, Dispatch, SetStateAction } from 'react';
import { consoleLogInDev } from 'shared';
import { Grid } from '@material-ui/core';
import { useMediaQuery } from '@mui/material';
import theme from 'theme';
import { PrimaryButton } from 'library';
import ErrorText from '../../../parent-portal/forms/components/ErrorText';
import WorkerReferenceFormField from './WorkerReferenceFormField';
import { submitReferenceForm } from '../ProvideWorkerReferenceApi';
import { errorStateInitializer, formInitializer } from '../util-functions';
import { formSchema, useFormStyles } from '../constants';
import {
    WorkerReferenceForm,
    ErrorArray,
    ValidationError,
    ErrorState,
    YupOverallError,
    AutomatedReference,
} from '../models';

export default function ReferenceFormBody({
    automatedReference,
    setOpenThankYouDialog,
}: {
    automatedReference: AutomatedReference;
    setOpenThankYouDialog: Dispatch<SetStateAction<boolean>>;
}) {
    const styles = useFormStyles();
    const smDown = useMediaQuery(theme.breakpoints.down('sm'));
    const [form, setForm] = useState<WorkerReferenceForm>(() => formInitializer(automatedReference));
    const [submissionLoading, setSubmissionLoading] = useState<boolean>(false);
    const [submitDisabled, setSubmitDisabled] = useState<boolean>(false);
    const [validationErrors, setValidationErrors] = useState<ErrorArray>([]);
    const [errorState, setErrorState] = useState<ErrorState>(errorStateInitializer);

    async function handleFormValidation(data: WorkerReferenceForm) {
        const isFormValid = await formSchema.isValid(data, {
            abortEarly: false, // NOTE: Prevent aborting validation after first error
        });
        const tempErrorState = errorStateInitializer();
        if (isFormValid) {
            setErrorState(tempErrorState);
            return true;
        } else {
            // NOTE: if form is not valid, check which fields are incorrect and add them to useState errors array
            formSchema.validate(data, { abortEarly: false }).catch((error: YupOverallError) => {
                for (const yupError of error.inner) {
                    tempErrorState[yupError.path].message = yupError.message;
                    tempErrorState[yupError.path].inErrorState = true;
                }
                setErrorState(tempErrorState);
                return false;
            });
        }
    }

    async function handleSubmit() {
        setSubmissionLoading(true);
        if (form) {
            handleFormValidation(form)
                .then((formValid) => {
                    if (formValid) {
                        submitReferenceForm(automatedReference.id, automatedReference.uuid, form)
                            .then(() => setOpenThankYouDialog(true))
                            .catch((e) => {
                                consoleLogInDev(`error submitting worker reference: ${e.message}`);
                                const tempErrors = validationErrors;
                                tempErrors.push({
                                    label: 'form',
                                    message: `form submission error - contact support`,
                                });
                                setValidationErrors(tempErrors);
                                setSubmitDisabled(true);
                            });
                    }
                })
                .catch((e) => {
                    consoleLogInDev(e);
                })
                // NOTE - just trying to prevent people from spamming the button
                .finally(() => setTimeout(() => setSubmissionLoading(false), 1500));
        }
    }

    return (
        <>
            <Grid container direction="column" style={styles.formGrid}>
                <Grid
                    item
                    container
                    direction={smDown ? 'column' : 'row'}
                    justify="space-between"
                    style={styles.firstFourGrid}
                >
                    <Grid item container direction="column" justify="space-between" style={styles.formColumn}>
                        <WorkerReferenceFormField
                            fieldNameDisplay="First Name"
                            fieldName={'first_name'}
                            fieldValue={form.first_name}
                            form={form}
                            setForm={setForm}
                            errorState={errorState}
                            automatedReference={automatedReference}
                        />
                        <WorkerReferenceFormField
                            fieldNameDisplay="Last Name"
                            fieldName={'last_name'}
                            fieldValue={form.last_name}
                            form={form}
                            setForm={setForm}
                            errorState={errorState}
                            automatedReference={automatedReference}
                        />
                    </Grid>
                    <Grid item container direction="column" justify="space-between" style={styles.formColumn}>
                        <WorkerReferenceFormField
                            fieldNameDisplay="Email Address"
                            fieldName={'email'}
                            fieldValue={form.email}
                            form={form}
                            setForm={setForm}
                            errorState={errorState}
                            automatedReference={automatedReference}
                        />
                        <WorkerReferenceFormField
                            fieldNameDisplay="Phone Number"
                            fieldName={'phone_number'}
                            fieldValue={form.phone_number}
                            form={form}
                            setForm={setForm}
                            errorState={errorState}
                            automatedReference={automatedReference}
                        />
                    </Grid>
                </Grid>
                <Grid item container direction="row" justify="space-between" style={styles.formRow}>
                    <WorkerReferenceFormField
                        fieldNameDisplay="Relationship to Worker"
                        fieldName={'relationship'}
                        fieldValue={form.relationship}
                        form={form}
                        setForm={setForm}
                        errorState={errorState}
                        automatedReference={automatedReference}
                    />
                    {form.relationship === 'Other' && (
                        <WorkerReferenceFormField
                            fieldNameDisplay=""
                            fieldName={'relationship_free_input'}
                            fieldValue={form.relationship_free_input}
                            form={form}
                            setForm={setForm}
                            errorState={errorState}
                            automatedReference={automatedReference}
                        />
                    )}
                </Grid>
                <Grid item container direction="row" justify="flex-start" style={styles.formRow}>
                    <WorkerReferenceFormField
                        fieldNameDisplay="Reference Text"
                        fieldName={'reference_text'}
                        fieldValue={form.reference_text}
                        form={form}
                        setForm={setForm}
                        errorState={errorState}
                        automatedReference={automatedReference}
                    />
                </Grid>

                <Grid
                    container
                    item
                    direction="row"
                    justify="flex-end"
                    style={{ ...styles.formInputClusterSansError, marginTop: 20 }}
                >
                    <PrimaryButton
                        buttonStyle={styles.button}
                        onClick={handleSubmit}
                        loading={submissionLoading}
                        disabled={submitDisabled}
                    >
                        Submit
                    </PrimaryButton>
                </Grid>
            </Grid>
            {validationErrors.map((error: ValidationError, index: number) => (
                <div key={index} style={styles.errorDiv}>
                    <ErrorText>{error.message}</ErrorText>
                </div>
            ))}
        </>
    );
}
