import React, { useState } from 'react';
import styled from 'styled-components';
import { SizeableRoundedDialog, Text } from 'library';
import { SvgIcon } from '@material-ui/core';
import TadaIcon from 'assets/icons/tandempay/tada-circle.svg';

import LearnMoreForm from './LearnMoreForm';

const StyledInnerModal = styled.div({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
});

const SuccessDiv = styled.div({
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '20px 0px',
    width: '90%',
});

const StyledText = styled(Text)({
    textAlign: 'center',
});

const StyledIcon = styled(SvgIcon)({
    minWidth: 100,
    minHeight: 100,
    paddingTop: 20,
});

const LearnMoreModal = ({ open, onClose }: { open: boolean; onClose: () => void }) => {
    const [success, setSucccess] = useState<boolean>(false);

    return (
        <SizeableRoundedDialog open={open} onClose={onClose} closeButton style={{ maxWidth: undefined }}>
            <StyledInnerModal>
                {success ? (
                    <SuccessDiv>
                        <StyledText variant="h2">Thank you for contacting us! We'll get back to you soon.</StyledText>
                        <StyledIcon>
                            <TadaIcon />
                        </StyledIcon>
                    </SuccessDiv>
                ) : (
                    <LearnMoreForm setSuccess={setSucccess} />
                )}
            </StyledInnerModal>
        </SizeableRoundedDialog>
    );
};

export default LearnMoreModal;
