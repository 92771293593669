import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import AdminButton from 'internal/shared/AdminButton';
import { Colors } from 'library';
import { BusinessJobWithAppointments, LeanUser } from 'models';
import React, { useState } from 'react';
import SendMatchModal from './SendMatchModal';

export default function SendMatch({
    job,
    initialProviders,
    refreshJobs,
    refreshProvider,
    text,
}: {
    job: BusinessJobWithAppointments;
    initialProviders: LeanUser[];
    refreshJobs?: () => void;
    refreshProvider?: () => void;
    text?: string;
}) {
    const [showModal, setShowModal] = useState(false);
    return (
        <>
            <AdminButton
                text={text || 'Send Match'}
                color={Colors.mediumLime}
                onClick={() => setShowModal(true)}
                FontAwesomeImg={faPaperPlane}
            />
            <SendMatchModal
                onClose={() => {
                    setShowModal(false);
                    refreshJobs && refreshJobs();
                    refreshProvider && refreshProvider();
                }}
                open={showModal}
                initialJob={job}
                jobId={job.id}
                initialProviders={initialProviders}
            />
        </>
    );
}
