import React, { ReactNode, CSSProperties, useCallback } from 'react';
import { Drawer as MaterialDrawer, Grid, styled } from '@mui/material';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';

const defaultDrawerWidth = 260;
const minDrawerWidth = 200;
const maxDrawerWidth = 1000;

export default function SideDrawer({
    children,
    style,
    className,
    draggable,
    paperStyle,
}: {
    children: ReactNode;
    style?: CSSProperties;
    className?: string;
    draggable?: boolean;
    paperStyle?: CSSProperties;
}) {
    const [drawerWidth, setDrawerWidth] = React.useState(defaultDrawerWidth);

    const handleMouseDown = () => {
        document.addEventListener('mouseup', handleMouseUp, true);
        document.addEventListener('mousemove', handleMouseMove, true);
    };

    const handleMouseUp = () => {
        document.removeEventListener('mouseup', handleMouseUp, true);
        document.removeEventListener('mousemove', handleMouseMove, true);
    };

    const handleMouseMove = useCallback((e: MouseEvent) => {
        e.preventDefault();
        const newWidth = e.clientX - document.body.offsetLeft;
        if (newWidth > minDrawerWidth && newWidth < maxDrawerWidth) {
            setDrawerWidth(newWidth);
        }
    }, []);

    const paper = paperStyle ? paperStyle : {};

    return (
        <FilterDrawer
            variant="permanent"
            anchor="left"
            style={style}
            className={className}
            PaperProps={{ style: { ...{ width: drawerWidth }, ...paper } }}
        >
            {draggable ? (
                <Draggable onMouseDown={handleMouseDown}>
                    <DragIndicatorIcon />
                </Draggable>
            ) : null}
            <DrawerContent>{children}</DrawerContent>
        </FilterDrawer>
    );
}

const DrawerContent = styled(Grid)({
    padding: 20,
    zIndex: 0,
});

const FilterDrawer = styled(MaterialDrawer)({
    width: defaultDrawerWidth,
    flexShrink: 0,
    zIndex: 9,
});

const Draggable = styled('div')({
    width: '10px',
    cursor: 'ew-resize',
    padding: '4px 0 0',
    borderTop: '1px solid #ddd',
    position: 'absolute',
    top: 0,
    right: 10,
    zIndex: 10,
});
