import React, { useState } from 'react';
import { BasicDialog, TextArea, PrimaryButton, SecondaryButton } from 'library';
import { styled } from '@mui/material/styles';

export default function RemoveProviderMessageModal({
    open,
    onClose,
    message,
    setMessage,
}: {
    open: boolean;
    onClose: () => void;
    message: string;
    setMessage: (message: string) => void;
}) {
    const [temporaryMessage, setTemporaryMessage] = useState<string>(message);

    function onModalClose() {
        setTemporaryMessage(message);
        onClose();
    }

    return (
        <BasicDialog isOpen={open} onClose={onModalClose} closeButton>
            <StyledWrapper>
                <StyledTextArea
                    value={temporaryMessage}
                    onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setTemporaryMessage(e.target.value)}
                />
                <StyledButtonWrapper>
                    <SecondaryButton buttonStyle={{ width: '50%' }} onClick={onModalClose}>
                        Cancel
                    </SecondaryButton>
                    <PrimaryButton
                        onClick={() => {
                            setMessage(temporaryMessage);
                            onClose();
                        }}
                        buttonStyle={{ width: '50%', marginLeft: 15 }}
                    >
                        Save
                    </PrimaryButton>
                </StyledButtonWrapper>
            </StyledWrapper>
        </BasicDialog>
    );
}

const StyledWrapper = styled('div')({
    marginTop: 15,
});

const StyledTextArea = styled(TextArea)({
    width: '100%',
});

const StyledButtonWrapper = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 15,
});
