import React, { useState } from 'react';
import { useBusinessLocationWorker } from '../api';
import { LoadingSpinner, Text } from 'library';
import { Grid } from '@mui/material';
import ProviderDetailsOutreachModal from 'internal/texting/outreach/ProviderDetailsOutreachModal';
import OutlawedWorkers from './components/OutlawedWorkers';
import StaffWorkers from './components/StaffWorkers';
import PreferredWorkers from './components/PreferredWorkers';
import HiredWorkers from './components/HiredWorkers';

export default function BusinessLocationWorkers({ businessLocationId }: { businessLocationId: number }) {
    const { status, error, data, refresh } = useBusinessLocationWorker(businessLocationId);
    const [selectedUser, setSelectedUser] = useState<number>();

    if (status === 'loading') return <LoadingSpinner />;
    if (status === 'error') return <Text>{error}</Text>;

    const outlawed = data?.outlaws ?? [];
    const staff = data?.on_staff ?? [];
    const preferred = data?.preferred_workers ?? [];
    const hired = data?.hired ?? [];

    return (
        <Grid container item style={{ padding: 20 }} direction="row">
            <StaffWorkers
                staff={staff}
                setSelectedUser={setSelectedUser}
                refreshData={refresh}
                businessLocationId={businessLocationId}
            />
            <PreferredWorkers
                preferred={preferred}
                setSelectedUser={setSelectedUser}
                refreshData={refresh}
                businessLocationId={businessLocationId}
            />
            <HiredWorkers
                hired={hired}
                setSelectedUser={setSelectedUser}
                refreshData={refresh}
                businessLocationId={businessLocationId}
            />
            <OutlawedWorkers
                outlawed={outlawed}
                setSelectedUser={setSelectedUser}
                businessLocationId={businessLocationId}
            />
            <ProviderDetailsOutreachModal
                open={!!selectedUser}
                onClose={() => setSelectedUser(undefined)}
                providerUserId={selectedUser}
            />
        </Grid>
    );
}
