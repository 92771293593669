import GradientHeader from './shared/GradientHeader';
import React from 'react';
import { useUserContext } from 'UserContext';
import DoYouKnowTheDates from './businessSteps/doYouKnowDates/DoYouKnowTheDates';
import RoleOptions from './businessSteps/roleOptions/RoleOptions';
import ShortOrLongTerm from './businessSteps/shortOrLongTerm/ShortOrLongTerm';
import { OnboardingContextProvider, OnboardingStep, useOnboardingContext } from './OnboardingContext';
import StaffingNeeds from './businessSteps/staffingNeeds';

function Steps() {
    const { user } = useUserContext();
    const { currentStep } = useOnboardingContext();
    const headers: { [key in OnboardingStep]: string } = {
        [OnboardingStep.BusinessNeeds]: `Welcome, ${user?.first_name}! What are your current staffing needs?`,
        [OnboardingStep.ShortOrLongTerm]: `Sounds good! What type of position would you like to fill?`,
        [OnboardingStep.DoYouKnowTheDates]: 'Great! Do you know the dates you need help?',
        [OnboardingStep.Role]: 'We can help! Do you have a role in mind?',
    };
    const steps: { [key in OnboardingStep]: React.ReactNode } = {
        [OnboardingStep.BusinessNeeds]: <StaffingNeeds />,
        [OnboardingStep.ShortOrLongTerm]: <ShortOrLongTerm />,
        [OnboardingStep.DoYouKnowTheDates]: <DoYouKnowTheDates />,
        [OnboardingStep.Role]: <RoleOptions />,
    };
    const header = headers[currentStep as OnboardingStep];
    const step = steps[currentStep as OnboardingStep];
    return (
        <>
            <GradientHeader>{header}</GradientHeader>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>{step}</div>
        </>
    );
}

export default function Onboarding() {
    return (
        <OnboardingContextProvider>
            <Steps />
        </OnboardingContextProvider>
    );
}
