import React, { useEffect, useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import StyledDivider from '../reusableComponents/StyledDivider';
import { Colors } from '../constants';
import { useStyles } from './CartStyles';
import { CartItemMappingModel } from './SitterPaymentModels';

interface CartTotalProps {
    formatter: Intl.NumberFormat;
    cart: Array<CartItemMappingModel>;
}

const CartTotals = ({ formatter, cart }: CartTotalProps) => {
    const [subtotal, setSubtotal] = useState(0);
    const [total, setTotal] = useState(0);
    const [fee, setFee] = useState(0);
    const cartStyles = useStyles();

    useEffect(() => {
        let cartTotal = cart.reduce((total, itemRow) => total + itemRow.item.price, 0);
        let totalWithFee = (cartTotal + 0.3) / 0.971;
        setSubtotal(cartTotal);
        setFee(totalWithFee - cartTotal);
        setTotal(totalWithFee);
    }, [cart]);

    return (
        <>
            <Grid container className={cartStyles.cartItemRow}>
                <Typography className={cartStyles.totalText}>Subtotal</Typography>
                <Typography className={cartStyles.totalText}>{formatter.format(subtotal)}</Typography>
            </Grid>
            <Grid container className={cartStyles.cartItemRow}>
                <Typography className={cartStyles.feeText}>Credit card processing fee</Typography>
                <Typography className={cartStyles.feeText}>{formatter.format(fee)}</Typography>
            </Grid>
            <Grid container className={cartStyles.cartItemRow}>
                <Typography className={cartStyles.totalText}>Total</Typography>
                <Typography className={cartStyles.totalText}>{formatter.format(total)}</Typography>
            </Grid>
            <StyledDivider style={{ backgroundColor: Colors.navy }} />
        </>
    );
};

export default CartTotals;
