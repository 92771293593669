import React from 'react';
import { SizeableRoundedDialog } from 'library';
import { IProviderReview } from 'models';
import Review from './Review';
import { styled, Grid } from '@mui/material';

export default function ReviewModal({
    reviews,
    viewAll,
    setViewAll,
}: {
    reviews: IProviderReview[];
    viewAll: boolean;
    setViewAll: (viewAll: boolean) => void;
}) {
    return (
        <SizeableRoundedDialog open={viewAll} onClose={() => setViewAll(false)} closeButton>
            <Container container item direction="column">
                <GapContainer container item>
                    {reviews.map((review: IProviderReview) => (
                        <Review review={review} key={review.id} />
                    ))}
                </GapContainer>
            </Container>
        </SizeableRoundedDialog>
    );
}

const Container = styled(Grid)({
    gap: 40,
    padding: 20,
});

const GapContainer = styled(Grid)({
    gap: 20,
});
