import React, { useContext, useEffect, useState } from 'react';
import { client, consoleLogInDev } from 'shared';
import { Label } from 'models';
import { DraggablePhoneDialogProps } from 'internal/business-locations/DraggablePhoneDialog';
import { DraggableNotesDialogProps } from './tables/DraggableNotesDialog';
import { NotesObjectType } from './tables/NotesModal';

export type AllLabelsKeys =
    | 'business'
    | 'ongoing'
    | 'applicants'
    | 'interview'
    | 'admin-providers'
    | 'external-message'
    | 'external-message-thread';

export type AllLabelsType = {
    [key in AllLabelsKeys]?: Label[];
};

interface ConfigurablePhoneDialogProps extends Omit<DraggablePhoneDialogProps, 'onClose'> {}
interface ConfigurableNotesDialogProps extends Omit<DraggableNotesDialogProps, 'onClose'> {}

interface AdminAppContextType {
    providerLabels: Label[];
    businessLabels: Label[];
    jobLabels: Label[];
    applicantLabels: Label[];
    externalMessageLabels: Label[];
    externalMessageThreadLabels: Label[];
    allLabels: AllLabelsType;
    refreshLabels: () => void;
    getLabelByName: (type: AllLabelsKeys, name: string) => Label | undefined;
    phoneDialogProps: DraggablePhoneDialogProps;
    notesDialogProps: DraggableNotesDialogProps;
    setGlobalPhoneDialogOpen: (phoneDialogOptions: ConfigurablePhoneDialogProps) => void;
    setGlobalNotesDialogOpen: (notesDialogOptions: ConfigurableNotesDialogProps) => void;
}

const defaultPhoneDialogProps = {
    isOpen: false,
    onClose: () => {},
    name: '',
    businessLocationId: 0,
    refresh: () => {},
    anchorElement: null,
};
const defaultNotesDialogProps = {
    isOpen: false,
    onClose: () => {},
    name: '',
    objectId: 0,
    type: 'business-location' as NotesObjectType,
    anchorElement: null,
};

export const AdminAppContext = React.createContext<AdminAppContextType>({
    providerLabels: [],
    businessLabels: [],
    jobLabels: [],
    applicantLabels: [],
    externalMessageLabels: [],
    externalMessageThreadLabels: [],
    allLabels: {},
    refreshLabels: () => {},
    getLabelByName: () => undefined,
    phoneDialogProps: defaultPhoneDialogProps,
    notesDialogProps: defaultNotesDialogProps,
    setGlobalPhoneDialogOpen: () => {},
    setGlobalNotesDialogOpen: () => {},
});

export function AdminAppContextProvider({ children }: { children: React.ReactNode }) {
    const [businessLabels, setBusinessLabels] = useState<Label[]>([]);
    const [jobLabels, setJobLabels] = useState<Label[]>([]);
    const [applicantLabels, setApplicantLabels] = useState<Label[]>([]);
    const [interviewLabels, setInterviewLabels] = useState<Label[]>([]);
    const [providerLabels, setProviderLabels] = useState<Label[]>([]);
    const [externalMessageLabels, setExternalMessageLabels] = useState<Label[]>([]);
    const [externalMessageThreadLabels, setExternalMessageThreadLabels] = useState<Label[]>([]);
    const [allLabels, setAllLabels] = useState<AllLabelsType>({});
    const [phoneDialogProps, setPhoneDialogProps] = useState<DraggablePhoneDialogProps>(defaultPhoneDialogProps);
    const [notesDialogProps, setNotesDialogProps] = useState<DraggableNotesDialogProps>(defaultNotesDialogProps);

    const getLabels = () => {
        client('api/label/')
            .then((results) => {
                setBusinessLabels(results.filter((label: Label) => label.content_type === 'business'));
                setJobLabels(results.filter((label: Label) => label.content_type === 'ongoingrequest'));
                setApplicantLabels(results.filter((label: Label) => label.content_type === 'applicant'));
                setInterviewLabels(results.filter((label: Label) => label.content_type === 'interview'));
                setProviderLabels(results.filter((label: Label) => label.content_type === 'babysitter'));
                setExternalMessageLabels(results.filter((label: Label) => label.content_type === 'externalmessage'));
                setExternalMessageThreadLabels(
                    results.filter((label: Label) => label.content_type === 'externalmessagethread'),
                );
            })
            .catch(consoleLogInDev);
    };

    const getLabelByName = (type: AllLabelsKeys, name: string) => {
        return allLabels[type]?.find((label) => label.text === name);
    };

    useEffect(getLabels, []);
    useEffect(() => {
        setAllLabels({
            business: businessLabels,
            ongoing: jobLabels,
            applicants: applicantLabels,
            interview: interviewLabels,
            'admin-providers': providerLabels,
            'external-message': externalMessageLabels,
            'external-message-thread': externalMessageThreadLabels,
        });
    }, [
        businessLabels,
        jobLabels,
        applicantLabels,
        interviewLabels,
        providerLabels,
        externalMessageLabels,
        externalMessageThreadLabels,
    ]);
    function setGlobalPhoneDialogOpen(phoneDialogOptions: ConfigurablePhoneDialogProps) {
        setPhoneDialogProps({
            ...phoneDialogOptions,
            parentOffset: true,
            isOpen: phoneDialogOptions.isOpen,
            onClose: () => setPhoneDialogProps({ ...phoneDialogProps, isOpen: false }),
        });
    }

    function setGlobalNotesDialogOpen(notesDialogOptions: ConfigurableNotesDialogProps) {
        setNotesDialogProps({
            ...notesDialogOptions,
            parentOffset: true,
            isOpen: notesDialogOptions.isOpen,
            onClose: () => setNotesDialogProps({ ...notesDialogProps, isOpen: false }),
        });
    }

    const contextValue = {
        providerLabels,
        businessLabels,
        jobLabels,
        applicantLabels,
        externalMessageLabels,
        externalMessageThreadLabels,
        allLabels,
        refreshLabels: getLabels,
        getLabelByName,
        phoneDialogProps,
        notesDialogProps,
        setGlobalPhoneDialogOpen,
        setGlobalNotesDialogOpen,
    };
    return <AdminAppContext.Provider value={contextValue}>{children}</AdminAppContext.Provider>;
}

export const useAdminAppContext = () => {
    return useContext(AdminAppContext);
};
