import React, { useState } from 'react';
import { LocationTaskList, TaskListItem, UserTaskList } from '../matchingInboxModels';
import Task from './Task';
import { styled } from '@mui/material';
import { isToday, isTomorrow } from 'date-fns';
import { formatShortWeekdayMonthDay } from 'shared/Dates';
import { Colors, Text } from 'library';
import { ChevronLeft } from '@material-ui/icons';
import GroupTaskMenu from './GroupTaskMenu';

export default function GroupedTasks({
    taskList,
    name,
    refresh,
    onEdit,
    onResolve,
}: {
    taskList: LocationTaskList | UserTaskList;
    name: string;
    refresh: () => void;
    onEdit: (task: TaskListItem) => void;
    onResolve: (task: TaskListItem) => void;
}) {
    const [show, setShow] = useState(true);

    function displayDate(date: string | null) {
        if (!date) return 'Unknown';

        const today = isToday(new Date(date));
        const tomorrow = isTomorrow(new Date(date));
        return today ? 'Today' : tomorrow ? 'Tomorrow' : formatShortWeekdayMonthDay(date);
    }

    const inboxId = taskList.inbox_items[0]?.inbox;

    return (
        <div>
            <TitleContainer>
                <SubTitleContainer onClick={() => setShow(!show)}>
                    <Text variant="body1" bold color={Colors.turquoise} textStyle={{ textDecoration: 'underline' }}>
                        {name}
                    </Text>
                    <Text
                        variant="caption"
                        bold
                        textStyle={{
                            alignItems: 'center',
                            backgroundColor: Colors.darkNavy,
                            borderRadius: 50,
                            color: Colors.white,
                            display: 'flex',
                            marginLeft: 8,
                            padding: '0 8px',
                        }}
                    >
                        {taskList.inbox_items.length}
                    </Text>
                </SubTitleContainer>
                <SubTitleContainer onClick={() => setShow(!show)}>
                    <Text variant="caption">Earliest Due Date: {displayDate(taskList.soonest_due)}</Text>
                    <ChevronLeft style={{ rotate: show ? '-90deg' : '90deg', marginLeft: 8 }} />
                </SubTitleContainer>
                <SubTitleContainer>
                    <GroupTaskMenu inboxId={inboxId} businessLocationId={taskList.id} refresh={refresh} />
                </SubTitleContainer>
            </TitleContainer>
            {show &&
                taskList.inbox_items.map((task, index) => (
                    <Task
                        key={task.id}
                        task={task}
                        refresh={refresh}
                        noDivider={index === taskList.inbox_items.length - 1}
                        onEdit={onEdit}
                        onResolve={() => onResolve(task)}
                    />
                ))}
            <FatDivider />
        </div>
    );
}

const TitleContainer = styled('div')({
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 8,
    width: '100%',
});

const SubTitleContainer = styled('div')({
    cursor: 'pointer',
    alignItems: 'center',
    display: 'flex',
});

const FatDivider = styled('div')({
    width: '100%',
    height: 3,
    marginTop: 8,
    marginBottom: 8,
    backgroundColor: Colors.mediumGrey,
    borderRadius: 5,
});
