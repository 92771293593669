import React from 'react';
import Labels from 'internal/shared/tables/Labels';
import { Text, Colors } from 'library';
import { AdminProvider } from 'models';

export function ProviderLabels({ provider }: { provider: AdminProvider }) {
    return (
        <>
            <Text variant="h2" bold textStyle={{ margin: 10, color: Colors.turquoise }}>
                Provider Labels
            </Text>
            <Labels type="admin-providers" object={provider} />
        </>
    );
}
