import * as React from 'react';
import { Grid } from '@material-ui/core';
import { Text } from 'library';
import { format12HourTime } from '../../../shared/Dates';
import { HourReportTimestamp } from '../HourReportModels';

export function TimestampDisplay({ timestamp }: { timestamp: HourReportTimestamp }) {
    return (
        <Grid item container direction="row" style={{ gap: 10 }}>
            <Text variant="body1" bold>
                {timestamp.time_type.display_name}:
            </Text>
            <Text>{format12HourTime(new Date(timestamp.reported_at))}</Text>
        </Grid>
    );
}
