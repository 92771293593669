import React from 'react';
import CheckRow from './CheckRow';
import RequirementsLabel from './RequirementsLabel';
import ChipRow from './ChipRow';

export default function OhioTrainingsContent() {
    return (
        <>
            <CheckRow text="Child Care Center Orientation Training (OCCRRA)" />
            <CheckRow text="Introduction to Tandem - Providing Quality Care" />
            <RequirementsLabel>
                Per ODJFS requirements, within the outlined days, workers will be encouraged to obtain the following:
            </RequirementsLabel>
            <ChipRow text="30 Days" chips={['Ohio Approach to Quality', 'Ohio Overview of Child Development']} />
            <ChipRow text="60 Days" chips={['Child Abuse']} />
            <ChipRow text="90 Days" chips={['First Aid/CPR']} />
        </>
    );
}
