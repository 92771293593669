//TODO: Coming soon. I don't want to confuse the screen by showing types that are not available yet.
//EXAMPLE: const historyTypes = ['Filled Job', 'Cancellation', 'Tardy', 'No Show', 'New Job'];
export const historyTypes = [
    'Add Preferred Worker',
    'Cancellation',
    'Center Blocked Worker',
    'Center Requested Time Change',
    'Continue Trial Run',
    'Discontinue Trial Run',
    'Filled Job',
    'New Sub Job',
    'New Trial Run',
    'Permanently Hired',
    'Worker Requested Time Change',
    'Worker Accepted Trial Run Continuation',
    'Worker Declined Trial Run Continuation',
    'Worker Permanently Cancelled Trial Run',
];
