import React, { useEffect, useRef } from 'react';
import { Grid, useMediaQuery } from '@mui/material';
import { Colors, Highlight, Text } from 'library';
import TandemBlocksLogo from 'assets/images/tandem-blocks-logo.svg';
import WorkerProfilePic from 'assets/images/marketing-site/WorkerProfilePic.png';
import CenterLogo from 'assets/images/marketing-site/CenterLogo.svg';
import ProfileCard from './ProfileCard';
import { ArcherContainer, ArcherContainerRef, ArcherElement } from 'react-archer';
import theme from 'theme';

export default function HowMatchingWorks() {
    const smDown = useMediaQuery(theme.breakpoints.down('sm'));
    const arrowRef = useRef<ArcherContainerRef | null>(null);
    useEffect(() => {
        if (arrowRef.current) arrowRef.current?.refreshScreen();
    }, [arrowRef]);
    const profiles = [
        {
            icon: <CenterLogo height={50} width={50} />,
            title: 'Learn and Grow Child Development',
            subtitle: 'Job Details',
            details: [
                {
                    title: 'Minimum Education Requirement',
                    content: 'High School Diploma',
                },
                {
                    title: 'Schedule Needed',
                    content: 'Monday 8am-5pm',
                },
                {
                    title: 'Pay Scale',
                    content: '$13/hr, for entry level, $14/hr for degree',
                },
                {
                    title: 'RequiredTrainings',
                    content: 'First Aid, CPR',
                },
            ],
        },
        {
            icon: <img src={WorkerProfilePic} height={50} width={50} />,
            title: 'Caroline, care worker since 2015',
            subtitle: 'Profile Details',
            details: [
                {
                    title: 'Education',
                    content: 'Bachelors in Early Childhood Education',
                },
                {
                    title: 'Availability',
                    content: 'Off for the summer, open Monday to Fridays',
                },
                {
                    title: 'Pay Rate',
                    content: '$13-15/hr',
                },
                {
                    title: 'Trainings',
                    content: 'First Aid, CPR',
                },
                {
                    title: 'Quality Rating',
                    content: '5/5',
                },
            ],
        },
    ];
    return (
        <Grid xs={12} style={{ gap: 40, paddingTop: 40 }}>
            <Grid xs={12} item container flexDirection="column" justifyContent="center" style={{ gap: 20 }}>
                <Text variant="display" textStyle={{ textAlign: 'center' }}>
                    <Highlight color={Colors.lightTurq}>How Matching Works</Highlight>
                </Text>
                <Text textStyle={{ textAlign: 'center' }}>
                    Our dedicated team of Matching Specialists and technology-based algorithm allows us to match you
                    with the best possible fit.
                </Text>
            </Grid>
            <ArcherContainer strokeColor={Colors.darkNavy} ref={arrowRef} offset={20}>
                <Grid item container direction="row" xs={12} style={{ padding: 20, gap: 20 }} justifyContent="center">
                    {smDown ? (
                        <ProfileCard profile={profiles[0]} />
                    ) : (
                        <ArcherElement
                            id="centerprofile"
                            relations={[
                                {
                                    targetId: 'tandemlogo-top',
                                    targetAnchor: 'left',
                                    sourceAnchor: 'right',
                                    style: { strokeDasharray: '10,5', strokeWidth: 5 },
                                },
                            ]}
                        >
                            <ProfileCard profile={profiles[0]} />
                        </ArcherElement>
                    )}

                    <Grid item container justifyContent="center" alignItems="center" xs={2} flexDirection="column">
                        <ArcherElement id="tandemlogo-top">
                            <div />
                        </ArcherElement>
                        <Grid
                            style={{
                                height: 100,
                                width: 100,
                                borderRadius: 100,
                                backgroundColor: Colors.darkNavy,
                            }}
                            item
                            container
                            justifyContent="center"
                            alignItems="center"
                        >
                            <TandemBlocksLogo color={Colors.white} height={50} width={50} />
                        </Grid>
                        <ArcherElement id="tandemlogo-bottom">
                            <div />
                        </ArcherElement>
                    </Grid>

                    {smDown ? (
                        <ProfileCard profile={profiles[1]} />
                    ) : (
                        <ArcherElement
                            id="workerprofile"
                            relations={[
                                {
                                    targetId: 'tandemlogo-bottom',
                                    targetAnchor: 'right',
                                    sourceAnchor: 'left',
                                    style: { strokeDasharray: '10,5', strokeWidth: 5 },
                                },
                            ]}
                        >
                            <ProfileCard profile={profiles[1]} />
                        </ArcherElement>
                    )}
                </Grid>
            </ArcherContainer>
        </Grid>
    );
}
