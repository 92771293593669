import React from 'react';
import { Link } from 'react-router-dom';
import { Grid } from '@mui/material';
import { IDashboardData } from '../Models/DashboardData';
import { useStyles } from './useStyles';
import DashboardSection from '../components/DashboardSection';
import Notification from 'parent-portal/notifications/Notification/Notification';
import DisplayPaySetupNotification from './DisplayPaySetupNotification';

export default function BusinessNotifications({ data }: { data: IDashboardData | undefined }) {
    const classes = useStyles();
    const unread_notifications = data?.notifications.unread;

    return (
        <DashboardSection header="Latest Notifications">
            <DisplayPaySetupNotification data={data} />
            <Grid>
                {unread_notifications?.map((x) => (
                    <Notification key={x.id} notification={x} />
                ))}
            </Grid>
            <Grid container justifyContent="center">
                <Link to="notifications" className={classes.link}>
                    View All
                </Link>
            </Grid>
        </DashboardSection>
    );
}
