import React, { useEffect, useState, useTransition } from 'react';
import { BasicDialog, PrimaryButton, Text, Colors, Checkbox } from 'library';
import { DocumentToVerify } from './models';
import { Grid, MenuItem, Select } from '@mui/material';
import { getDocumentTypeIgnoreMessages, ignoreForm } from 'api/AdminDocumentApi';

interface IgnoreModalProps {
    isOpen: boolean;
    setIsOpenModal: (isOpen: boolean) => void;
    form: DocumentToVerify;
    refresh: () => void;
}

interface DocumentTypeIgnoreMessage {
    id: number;
    reason: string;
    message: string;
}

const IgnoreModal = ({ isOpen, setIsOpenModal, form, refresh }: IgnoreModalProps) => {
    const [messages, setMessages] = useState<DocumentTypeIgnoreMessage[]>([]);
    const [reasonId, setReasonId] = useState<number>();
    const [withMessage, setWithMessage] = useState<boolean>(false);
    const [pending, startTransition] = useTransition();

    useEffect(() => {
        startTransition(() => {
            if (isOpen && form) {
                getDocumentTypeIgnoreMessages(form.document_type_id).then((res) => {
                    setMessages(res);
                });
            } else {
                setMessages([]);
            }
        });
    }, [isOpen]);

    const handleOnClick = () => {
        ignoreForm(form?.id, form?.name, refresh, reasonId);
        setIsOpenModal(false);
    };

    const getMessage = () => {
        const reason = messages?.find((it) => it.id === reasonId);
        return reason?.message;
    };

    return (
        <BasicDialog closeButton isOpen={isOpen} onClose={() => setIsOpenModal(false)}>
            {pending ? (
                <Text>Loading...</Text>
            ) : (
                <Grid container gap={6}>
                    <Text variant="h2" bold>
                        {form.name}
                    </Text>
                    <Grid gap={2} width={'100%'}>
                        <Checkbox
                            style={{ width: 'unset' }}
                            label={'Send message to worker for reason'}
                            checked={withMessage}
                            onChange={(checked) => {
                                setWithMessage(checked);
                                if (!checked) {
                                    setReasonId(undefined);
                                }
                            }}
                        />
                        <Select
                            value={reasonId}
                            autoWidth
                            onChange={(e) => setReasonId(e.target.value as number)}
                            disabled={!withMessage}
                            style={{
                                padding: 10,
                                borderWidth: 0.1,
                                height: 40,
                                width: '100%',
                                backgroundColor: Colors.white,
                                fontFamily: 'Work Sans',
                                color: Colors.darkNavy,
                            }}
                        >
                            {messages.map((it, i) => (
                                <MenuItem
                                    key={i}
                                    value={it.id}
                                    style={{ width: '100%', fontFamily: 'Work Sans', color: Colors.darkNavy }}
                                >
                                    {it.reason}
                                </MenuItem>
                            ))}
                        </Select>
                    </Grid>
                    {reasonId && <Text variant="caption">Message: {getMessage()}</Text>}
                    {withMessage && !reasonId && <Text variant="caption">Please select a reason</Text>}
                    <PrimaryButton buttonStyle={{ marginTop: 20, marginBottom: 20 }} onClick={handleOnClick}>
                        Ignore Document
                    </PrimaryButton>
                </Grid>
            )}
        </BasicDialog>
    );
};

export default IgnoreModal;
