import React from 'react';
import { TablePagination, styled } from '@mui/material';
import { PagingResult } from 'models';

interface PaginatedListProps<T> {
    data: PagingResult<T>;
    page: number;
    updatePage: (page: number) => void;
    children: React.ReactNode;
}

export default function PaginatedList<T>({ data, page, updatePage, children }: PaginatedListProps<T>) {
    const onPageChange = (event: React.MouseEvent | null, page: number) => updatePage(page);
    return (
        <>
            <Pagination
                count={data?.count || 0}
                rowsPerPage={data?.per_page || 0}
                page={page - 1}
                onPageChange={onPageChange}
                rowsPerPageOptions={[]}
            />
            {children}
            <Pagination
                count={data?.count || 0}
                rowsPerPage={data?.per_page || 0}
                page={page - 1}
                onPageChange={onPageChange}
                rowsPerPageOptions={[]}
            />
        </>
    );
}

const Pagination = styled(TablePagination)({
    display: 'flex',
    justifyContent: 'flex-end',
});
