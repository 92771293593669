import { Grid } from '@material-ui/core';
import React from 'react';
import { IEditableField } from './models';
import { Text, Colors, PrimaryButton, UnderlinedTextInput, OutlinedDropdown } from 'library';
import { states } from 'parent-portal/signup/states';

export default function EditableInfo({
    editing,
    title,
    updateEditing,
    fields,
    save,
    saveDisabled = false,
    children,
    subtitle,
}: {
    editing: boolean;
    title: string;
    updateEditing: (b: boolean) => void;
    fields?: IEditableField[];
    save: () => void;
    saveDisabled?: boolean;
    children?: JSX.Element | JSX.Element[];
    subtitle?: string;
}) {
    const stateOptions = [
        { key: 'Select one', value: 'Select one' },
        ...states
            .filter((x) => x)
            .map((x) => {
                return { key: x, value: x };
            }),
    ];

    return (
        <Grid
            container
            item
            style={{ backgroundColor: Colors.white, borderRadius: 18, padding: 20 }}
            direction="column"
        >
            <Grid item container direction="row" justify="space-between">
                <Text variant="h1" textStyle={{ marginBottom: 10 }}>
                    {title}
                </Text>
                <Text
                    variant="caption"
                    onClick={() => updateEditing(!editing)}
                    textStyle={{ textDecorationLine: 'underline', cursor: 'pointer' }}
                >
                    {editing ? 'Cancel' : 'Edit'}
                </Text>
            </Grid>
            {subtitle ? <Text variant="caption">{subtitle}</Text> : null}
            {fields?.map((c: IEditableField) => (
                <Grid key={c.title} container item style={{ gap: 20 }}>
                    {c.title !== 'State' ? (
                        <UnderlinedTextInput
                            disabled={!editing}
                            label={c.title}
                            onChange={(value: string, _isValid: boolean) => c.onChange(value)}
                            value={c.value}
                            error={c.error}
                            errorText={`${c.title} is required.`}
                            boldLabel
                        />
                    ) : (
                        <Grid item>
                            <Text variant="body1" bold={true}>
                                State
                            </Text>
                            <OutlinedDropdown
                                id="state"
                                required
                                disabled={!editing}
                                error={!c.value || c.value === 'Select one'}
                                value={c.value}
                                onChange={(event: any) => c.onChange(event?.target.value)}
                                fields={stateOptions}
                                autoComplete="address-level1"
                                style={{ minWidth: 150 }}
                            />
                        </Grid>
                    )}
                </Grid>
            ))}
            {children}

            {editing ? (
                <PrimaryButton
                    disabled={saveDisabled}
                    onClick={save}
                    buttonStyle={{ maxWidth: 150, marginTop: 10, alignSelf: 'end' }}
                >
                    Save
                </PrimaryButton>
            ) : null}
        </Grid>
    );
}
