import React, { useMemo } from 'react';
import { Control, Controller } from 'react-hook-form';
import { DropdownItem, TaskFormValues } from '../TaskFormFields';
import { client } from 'shared';
import { Colors, OutlinedTextInput } from 'library';
import { Autocomplete, styled } from '@mui/material';
import LabelWrapper from './LabelWrapper';
import { BusinessLocation, PagingResult } from 'models';
import { debounce } from '@mui/material/utils';

export default function BusinessLocationDropdown({
    control,
    defaultLocations,
    disabled,
}: {
    control: Control<TaskFormValues>;
    defaultLocations: DropdownItem[];
    disabled?: boolean;
}) {
    const [locations, setLocations] = React.useState<DropdownItem[]>(defaultLocations);
    const [searchTerm, setSearchTerm] = React.useState(defaultLocations.length > 0 ? defaultLocations[0].value : '');

    const search = useMemo(
        () =>
            debounce((input: string) => {
                client(`api/business-location/?name=${input}`).then((response: PagingResult<BusinessLocation>) => {
                    setLocations(
                        response.results.map((location) => ({ key: location.id.toString(), value: location.name })),
                    );
                });
            }, 400),
        [],
    );

    React.useEffect(() => {
        search(searchTerm);
    }, [searchTerm, search]);

    return (
        <LabelWrapper label="Business Location">
            <Controller
                name="business_locations"
                control={control}
                render={({ field }) => (
                    <StyledAutoComplete
                        {...field}
                        multiple
                        disabled={disabled}
                        onChange={(_, value) => {
                            field.onChange(value);
                        }}
                        disableCloseOnSelect
                        disablePortal
                        options={locations}
                        getOptionLabel={(option) => (option as DropdownItem).value}
                        inputValue={searchTerm}
                        isOptionEqualToValue={(option, value) =>
                            (option as DropdownItem).key === (value as DropdownItem).key
                        }
                        fullWidth={false}
                        renderInput={(params) => (
                            <OutlinedTextInput
                                {...params}
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                label="Business Location"
                                variant="outlined"
                            />
                        )}
                    />
                )}
            />
        </LabelWrapper>
    );
}

const StyledAutoComplete = styled(Autocomplete)({
    color: Colors.darkNavy,
    '& .MuiAutocomplete-groupLabel': {
        color: Colors.darkNavy,
    },
    '& .MuiAutocomplete-clearIndicator': {
        color: Colors.mediumNavy,
    },
    '& .MuiAutocomplete-input': {
        color: Colors.darkNavy,
    },
    '& .MuiAutocomplete-tag': {
        backgroundColor: Colors.lightBlue,
        color: Colors.darkNavy,
    },
    '& .MuiChip-deleteIcon': {
        color: Colors.darkNavy,
    },
});
