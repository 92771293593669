import { Grid, styled } from '@mui/material';
import { BusinessApplicant, BusinessApplicantWithJob } from 'internal/applicants/ApplicantModels';
import React from 'react';
import { formatMonthDayTime } from 'shared/Dates';
import { Text } from 'library';

export default function AutobookDetails({ applicant }: { applicant: BusinessApplicant | BusinessApplicantWithJob }) {
    return (
        <AutobookInfoContainer container direction="column">
            <Text variant="body2">
                <b>Autobook Status:</b> {applicant.autobook_status ?? 'None'}
            </Text>
            <Text variant="body2">
                <b>Reason:</b> {applicant.autobook_reason ?? 'None'}
            </Text>
            <Text variant="body2">
                <b>Next autobook review at:</b>{' '}
                {applicant.autobook_next_review ? formatMonthDayTime(applicant.autobook_next_review) : 'None scheduled'}
            </Text>
        </AutobookInfoContainer>
    );
}

const AutobookInfoContainer = styled(Grid)({
    paddingTop: 10,
    paddingBottom: 10,
    whiteSpace: 'normal',
});
