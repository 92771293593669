import React, { useState } from 'react';
import LoadingSpinner from '../components/misc/LoadingSpinner';

export default function useLoading() {
    const [loading, setLoading] = useState(false);

    return {
        loading,
        setLoading,
        LoadingSpinner,
    };
}
