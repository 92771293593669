import { IconButton, withStyles } from '@material-ui/core';

const TinyIconButton = withStyles({
    root: {
        height: 12,
        width: 12,
        '&:focus': {
            outline: 'none',
        },
    },
})(IconButton);

export default TinyIconButton;
