import React from 'react';
import { Avatar, Grid, styled } from '@mui/material';
import { Colors, IconButton, Link, Text } from 'library';
import { Add, Person } from '@material-ui/icons';
import { ILocationAdministration } from 'models';

export default function LocationAdministrationItem({
    placeholderRole,
    administration,
    onAdd,
    onRemove,
    onEdit,
    onAddPhoto,
}: {
    placeholderRole?: string;
    administration?: ILocationAdministration;
    onAddPhoto: (id: number) => void;
    onAdd: () => void;
    onEdit: (id: number) => void;
    onRemove: (id: number) => void;
}) {
    return (
        <Grid item>
            <Container container direction="row">
                <Grid item>
                    <Grid container direction="row">
                        <AdminAvatar src={administration?.photo?.image}>
                            <Person fontSize="large" />
                        </AdminAvatar>
                        {administration && <AddPhotoButton onClick={() => onAddPhoto(administration.id)} icon={Add} />}
                    </Grid>
                </Grid>
                <Grid item>
                    <InfoSection container direction="column">
                        <Text variant="body1" bold>
                            {administration?.role ?? placeholderRole}
                        </Text>
                        {administration?.name && <Text variant="body1">{administration.name}</Text>}
                        {administration ? (
                            <Text>
                                <Link onClick={() => onEdit(administration?.id)}>Edit</Link> /{' '}
                                <Link onClick={() => onRemove(administration?.id)}>Remove</Link>
                            </Text>
                        ) : (
                            <Link onClick={() => onAdd()}>Add</Link>
                        )}
                    </InfoSection>
                </Grid>
            </Container>
        </Grid>
    );
}

const Container = styled(Grid)({
    gap: 10,
});

const AdminAvatar = styled(Avatar)({
    color: Colors.white,
    backgroundColor: Colors.darkNavy,
    width: 60,
    height: 60,
});

const AddPhotoButton = styled(IconButton)({
    marginLeft: -15,
});

const InfoSection = styled(Grid)({
    justifyContent: 'space-between',
    height: '100%',
});
