import React, { useState } from 'react';
import { Edit, Save } from '@mui/icons-material';
import { Note } from 'models';
import { Checkbox, Chip, Colors, IconButton, OutlinedTextInput, Text } from 'library';
import { client, consoleLogInDev } from 'shared';
import { Divider } from '@mui/material';
import { useUserContext } from 'UserContext';
import moment from 'moment';

export default function NoteView({
    note,
    refresh,
    handleRefresh,
    hideEdit,
    limit,
}: {
    note: Note;
    refresh?: () => void;
    handleRefresh?: (text?: string, pinned?: boolean) => void;
    hideEdit?: boolean;
    limit?: number;
}) {
    const [editing, setEditing] = useState(false);
    const [editText, setEditText] = useState('');
    const isHubspotNote = hideEdit === true;
    const { user } = useUserContext();
    const textLimit = limit ? limit : note.text.length;

    const toggleEditing = () => {
        setEditText(editing ? '' : note.text);
        setEditing(!editing);
    };

    const update = (note: Note, pinned: boolean, text?: string) => {
        client(`api/note/${note.id}/`, {
            method: 'PATCH',
            body: { pinned, text, author_id: user?.id ?? note.author.id },
        })
            .then(() => {
                refresh && refresh();
                handleRefresh?.(text, pinned);
                setEditing(false);
            })
            .catch(consoleLogInDev);
    };

    return (
        <div key={note.id} style={{ padding: 10, backgroundColor: isHubspotNote ? Colors.lightGreen : 'transparent' }}>
            <Text variant="body2" bold>
                {moment(note.added_at).format('MMM Do YYYY, h:mm a')}{' '}
                <span style={{ color: Colors.turquoise }}>
                    ({note.author.first_name} {note.author.last_name}){' '}
                </span>
                {note.is_from_call && <Chip label="Call" backgroundColor={Colors.pink} textColor={Colors.white} />}
            </Text>
            {note.object_description && (
                <Text bold textStyle={{ color: Colors.turquoise }}>
                    {note.object_description}
                </Text>
            )}
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginBottom: 4,
                }}
            >
                {editing ? (
                    <OutlinedTextInput
                        style={{ padding: 10, flexWrap: 'wrap' }}
                        multiline
                        fullWidth
                        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setEditText(e.target.value)}
                        value={editText}
                        InputProps={{
                            endAdornment: (
                                <IconButton
                                    tooltip="Save note"
                                    icon={Save}
                                    onClick={() => update(note, note.pinned, editText)}
                                />
                            ),
                        }}
                    />
                ) : (
                    <Text variant="body2">{note.text.slice(0, textLimit)}</Text>
                )}
                {!hideEdit ? (
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <IconButton tooltip="Edit note" icon={Edit} onClick={toggleEditing} />
                        <Checkbox label="pinned" checked={note.pinned} onChange={() => update(note, !note.pinned)} />
                    </div>
                ) : null}
            </div>
            <Divider />
        </div>
    );
}
