import { Person } from '@material-ui/icons';
import React from 'react';
import { useHistory } from 'react-router';
import { track } from 'shared';
import AccountMenuItem from './AccountMenuItem';
import usePublicSessionTracking, { PublicSessionEventType } from 'parent-portal/shared/PublicSessionTracking';

export default function ProfileMenuItem() {
    const history = useHistory();
    const { trackSession } = usePublicSessionTracking('Top Navigation - Profile Picture - Item');

    const profileClicked = () => {
        track(`Profile dropdown clicked: My Account`);
        trackSession('CLICK', 'My Account', PublicSessionEventType.User);
        history.push('/profile');
    };

    return <AccountMenuItem icon={<Person color="primary" />} text="My Account" action={profileClicked} />;
}
