import {
    IPayFormFields,
    RequestType,
    ProviderSelection,
    DayOfWeekSelections,
    JobFormFields,
    JobTimes,
    JobPaymentTypes,
    BusinessJobTypes,
    IPayScale,
    TemplateOption,
    JobFaq,
} from 'parent-portal/forms/JobRequestTypes';
import moment from 'moment';
import { JobDetailValueWithOption, JobQualificationValue, Provider } from 'models';
import { FieldStates } from './components/FormTypes';
import { BusinessJobTemplateDetailsDTO, DayToIsoWeekDay } from 'parent-portal/forms/JobTemplateModels';
import { ISpotlightWorker } from 'parent-portal/home/Models/DashboardData';

export interface JobFormPresets {
    invitedProvider?: Provider;
    providerToBookDirect?: ProviderSelection;
    pastProviders?: ProviderSelection[];
    frequency?: RequestType;
    jobType?: number;
    headline?: string;
    description?: string;
    simple?: boolean;
    pageNumber?: number;
    pay?: IPayFormFields;
    jobTimes?: JobTimes;
    daysOfTheWeek?: DayOfWeekSelections | null;
    startMonth?: string | null;
    startDate?: moment.Moment | null;
    endDate?: moment.Moment | null;
    lastHireOffsetMinutes?: number;
    isQuickFill?: boolean;
    draftJobId?: number;
    sameTimes?: boolean;
    jobQualifications?: JobQualificationValue[];
    payType?: JobPaymentTypes;
    businessJobType?: BusinessJobTypes;
    multipleProviders?: boolean;
    jobDetails?: JobDetailValueWithOption[];
    breakRequired?: boolean | null;
    breakLength?: number | null;
    slotsAvailable?: number | null;
    address?: { id: number; businessLocationId?: number }[];
    payScales?: IPayScale[];
    useTemplate?: TemplateOption;
    templateJobId?: number;
    pastWorkers?: ProviderSelection[];
    trialRunCoverage?: boolean;
    faqs?: JobFaq[];
    requireMinimumQualifications?: boolean;
    addMinimumBreak?: boolean;
    breakEveryNHours?: number | null;
    trialRunBenefits?: string;
    selectedSpotlightWorkers?: ISpotlightWorker[];
    allSpotlightWorkers?: ISpotlightWorker[];
}

export function setJobFormPresets(
    presets: JobFormPresets,
    fieldStates: FieldStates<JobFormFields>,
    setSimple: (simple: boolean) => void,
    setPage: (pageNumber: number) => void,
    setInvitedProvider: (provider?: Provider) => void,
    setJobId: (jobId: number) => void,
) {
    const {
        providerToBookDirect,
        frequency,
        jobType,
        headline,
        description,
        simple,
        pageNumber,
        pay,
        pastProviders,
        jobTimes,
        startMonth,
        startDate,
        endDate,
        lastHireOffsetMinutes,
        daysOfTheWeek,
        draftJobId,
        sameTimes,
        invitedProvider,
        isQuickFill,
        jobQualifications,
        payType,
        businessJobType,
        multipleProviders,
        jobDetails,
        breakRequired,
        breakLength,
        slotsAvailable,
        address,
        payScales,
        useTemplate,
        templateJobId,
        pastWorkers,
        trialRunCoverage,
        faqs,
        requireMinimumQualifications,
        breakEveryNHours,
        trialRunBenefits,
        selectedSpotlightWorkers,
        allSpotlightWorkers,
    } = presets;
    if (simple !== undefined) setSimple(simple);
    if (pageNumber) setPage(pageNumber);
    if (frequency) fieldStates.requestType.setValue(frequency);
    if (headline) fieldStates.headline.setValue(headline);
    if (description) fieldStates.comments.setValue(description);

    if (providerToBookDirect) {
        fieldStates.preferredWorkers.setValue([providerToBookDirect]);
    }
    if (jobType) {
        fieldStates.jobTypeId.setValue(jobType);
    }
    if (pay) fieldStates.pay.setValue(pay);
    if (pastProviders) fieldStates.preferredWorkers.setValue(pastProviders);
    if (jobTimes?.start && jobTimes?.end) fieldStates.jobTimes.setValue(jobTimes);
    if (startMonth) fieldStates.startEstimateMonth.setValue(startMonth);
    if (startDate) fieldStates.startDate.setValue(startDate);
    if (endDate) fieldStates.endDate.setValue(endDate);
    if (lastHireOffsetMinutes || lastHireOffsetMinutes === 0)
        fieldStates.lastHireOffsetMinutes.setValue(lastHireOffsetMinutes);
    if (daysOfTheWeek) fieldStates.daysOfTheWeek.setValue(daysOfTheWeek);
    setInvitedProvider(invitedProvider);
    if (draftJobId !== undefined) setJobId(draftJobId);
    if (sameTimes !== undefined) fieldStates.sameTimes.setValue(sameTimes);
    if (isQuickFill !== undefined) fieldStates.isQuickFill.setValue(isQuickFill);
    if (jobQualifications) fieldStates.jobQualifications.setValue(jobQualifications);
    if (payType) fieldStates.payType.setValue(payType);
    if (businessJobType) fieldStates.businessJobType.setValue(businessJobType);
    if (multipleProviders) fieldStates.multipleProviders.setValue(multipleProviders);
    if (jobDetails) fieldStates.jobDetails.setValue(jobDetails);
    if (breakRequired) fieldStates.breakRequired.setValue(breakRequired);
    if (breakLength) fieldStates.breakLength.setValue(breakLength);
    if (slotsAvailable) fieldStates.slotsAvailable.setValue(slotsAvailable);
    if (address) fieldStates.address.setValue(address);
    if (payScales && payScales.length) {
        fieldStates.payScales.setValue(payScales);
    }
    if (useTemplate) fieldStates.useTemplate.setValue(useTemplate);
    if (templateJobId) fieldStates.templateJobId.setValue(templateJobId);
    if (pastWorkers) fieldStates.pastWorkers.setValue(pastWorkers);
    if (trialRunCoverage !== undefined) fieldStates.trialRunCoverage.setValue(trialRunCoverage);
    if (faqs && faqs.length > 0) fieldStates.faqs.setValue(faqs);
    if (requireMinimumQualifications) fieldStates.requireMinimumQualifications.setValue(requireMinimumQualifications);
    if (breakEveryNHours) {
        fieldStates.addMinimumBreak.setValue(true);
        fieldStates.breakEveryNHours.setValue(breakEveryNHours);
    }
    if (trialRunBenefits) fieldStates.trialRunBenefits.setValue(trialRunBenefits);
    if (selectedSpotlightWorkers) fieldStates.selectedSpotlightWorkers.setValue(selectedSpotlightWorkers);
    if (allSpotlightWorkers) fieldStates.allSpotlightWorkers.setValue(allSpotlightWorkers);
}

export function toJobFormPresets(template: BusinessJobTemplateDetailsDTO): JobFormPresets {
    const {
        headline,
        pay,
        rate_min,
        rate_max,
        daily_schedules,
        start_date,
        end_date,
        last_hire_offset_minutes,
        pay_scales,
        details,
        address,
        requested_workers,
        description,
        faqs,
        require_minimum_qualifications,
        break_required_every_n_minutes,
        break_length,
        trial_run_benefits,
        business_location,
    } = template;
    const schedules = Object.keys(DayToIsoWeekDay).map((x) => {
        // eslint-disable-next-line security/detect-object-injection
        const scheduleForDay = daily_schedules.find((y) => y.day === DayToIsoWeekDay[x]);
        return {
            [x]: {
                start: scheduleForDay
                    ? moment(scheduleForDay.start_time, 'HH:mm')
                    : moment(daily_schedules[0].start_time, 'HH:mm'),
                end: scheduleForDay
                    ? moment(scheduleForDay.end_time, 'HH:mm')
                    : moment(daily_schedules[0].end_time, 'HH:mm'),
                slots: scheduleForDay ? scheduleForDay.slots : daily_schedules[0].slots,
                selected: !!scheduleForDay,
                // eslint-disable-next-line security/detect-object-injection
                day: DayToIsoWeekDay[x],
            },
        };
    });
    const sameTimes = daily_schedules.every(
        (x) =>
            x.start_time === daily_schedules[0].start_time &&
            x.end_time === daily_schedules[0].end_time &&
            x.slots === daily_schedules[0].slots,
    );
    const daysOfTheWeek = Object.assign({}, ...schedules);
    return {
        headline,
        pay: {
            payMin: rate_min || pay || 13,
            payMax: rate_max || pay || 15,
            payFixed: pay,
            isFixedPay: false,
        },
        daysOfTheWeek,
        startDate: moment(start_date),
        endDate: end_date ? moment(end_date) : null,
        lastHireOffsetMinutes: last_hire_offset_minutes,
        payScales: pay_scales,
        jobDetails: details,
        address: [{ id: address, businessLocationId: business_location }],
        pastWorkers: requested_workers,
        description,
        useTemplate: TemplateOption.TEMPLATE,
        templateJobId: template.id,
        pageNumber: 2,
        sameTimes,
        faqs,
        jobTimes: sameTimes
            ? {
                  start: moment(daily_schedules[0].start_time, 'HH:mm'),
                  end: moment(daily_schedules[0].end_time, 'HH:mm'),
                  slots: daily_schedules[0].slots,
              }
            : {
                  start: start_date ? moment(start_date, 'HH:mm') : null,
                  end: end_date ? moment(end_date, 'HH:mm') : null,
                  slots: 1,
              },
        requireMinimumQualifications: require_minimum_qualifications,
        breakEveryNHours: break_required_every_n_minutes ? Math.floor(break_required_every_n_minutes / 60) : null,
        breakLength: break_length,
        trialRunBenefits: trial_run_benefits,
    };
}
