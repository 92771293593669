import React, { useEffect, useState } from 'react';
import { JobQualificationValue, Qualification } from 'models';
import { Slider } from 'library';
import MultiSelect from './MultiSelect';
import SingleSelect from './SingleSelect';
import { Grid } from '@mui/material';
import { FormFieldProps } from '../../FormTypes';
import { addOrRemoveQualification, addOrUpdateQualification } from 'parent-portal/forms/jobDetailMethods';

export default function QualificationInput({
    qualification,
    jobQualifications,
}: {
    qualification: Qualification;
    jobQualifications: FormFieldProps<JobQualificationValue[]>;
}) {
    const { value: values } = jobQualifications;
    const [selectedOptions, setSelectedOptions] = useState<JobQualificationValue[]>([]);
    const [selectedValue, setSelectedValue] = useState(getValue(qualification));

    function toNumber(qualification: Qualification) {
        let val = getValue(qualification);
        return val ? parseInt(val) : undefined;
    }

    function setNumber(value?: number | number[]) {
        addOrUpdateQualification({ qualification: qualification.id, value: value?.toString() }, jobQualifications);
    }

    function getValue(qualification: Qualification) {
        return values?.find((val) => val.qualification === qualification.id)?.value;
    }

    function getOption(qualification: Qualification) {
        let selected = values?.find((val) => qualification.id === val.qualification);
        let option = qualification.options.find((option) => option.id === selected?.option)?.value;
        return option;
    }

    function setOption(value?: string) {
        addOrUpdateQualification(
            {
                qualification: qualification.id,
                option: qualification.options.find((option) => option.value === value)?.id,
            },
            jobQualifications,
        );
    }

    useEffect(() => {
        if (qualification.value_type === 'MULTI_SELECT') {
            setSelectedOptions(values?.filter((val) => val.qualification === qualification.id));
        } else if (qualification.value_type === 'SINGLE_SELECT') {
            setSelectedValue(getOption(qualification));
        }
    }, [values, qualification]);

    return qualification.value_type === 'NUMBER' ? (
        <Grid container item style={{ width: '100%', padding: 20 }}>
            <Slider
                marks={[
                    { value: 0, label: 'None Required' },
                    { value: 10, label: '10+' },
                ]}
                min={0}
                max={10}
                value={toNumber(qualification)}
                onChange={(event, value) => setNumber(value)}
            />
        </Grid>
    ) : qualification.value_type === 'MULTI_SELECT' ? (
        <MultiSelect
            options={qualification.options}
            selectedOptions={selectedOptions}
            onSelect={(optionId) => addOrRemoveQualification(jobQualifications, qualification, optionId)}
        />
    ) : qualification.value_type === 'SINGLE_SELECT' ? (
        <SingleSelect value={selectedValue} onChange={setOption} options={qualification.options} />
    ) : null;
}
