import { Colors } from 'library';
import type { ProviderSelection } from 'parent-portal/forms/JobRequestTypes';

export interface ExtendedProviderSelection extends ProviderSelection {
    status?: string;
    sanitizedStatus?: string;
}

export function workerStatusStyle(status: string | undefined) {
    const baseStyle = { fontSize: 12, fontWeight: 700 };

    if (!status) {
        return baseStyle;
    }

    switch (status.toLowerCase()) {
        case 'unavailable':
            return { color: Colors.error, ...baseStyle };
        case 'out_of_compliance':
            return { color: Colors.terraCotta, ...baseStyle };
        default:
            return { color: Colors.navy, ...baseStyle };
    }
}
