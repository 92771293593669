import React from 'react';
import { Text, Colors } from 'library';
import { AdminProvider, OCCRRAEducations } from 'models';
import { formatMonthDayYearWith12HourTime } from 'shared/Dates';
import { format } from 'date-fns';
import { Grid, Divider } from '@mui/material';

export function WorkerQualifications({ provider }: { provider: AdminProvider }) {
    return (
        <>
            <Text variant="h2" bold textStyle={{ margin: 10, color: Colors.turquoise }}>
                Child Care Center Qualifications
            </Text>
            <Grid item container style={{ gap: 20, marginBottom: 10 }}>
                <Grid container item flexDirection="column" xs={12} md={5}>
                    {provider.occrra_info_collect && provider.occrra_info_collect.occrra_educations ? (
                        <div style={{ margin: 5, marginLeft: 10 }}>
                            <Text variant="body2" bold>
                                Education (from OCCRRA):{' '}
                            </Text>
                            {provider.occrra_info_collect?.occrra_educations.map(
                                (education: OCCRRAEducations, index) => (
                                    <Text key={index} variant="caption">
                                        <b>{education.institution_name}</b> ({education.attended_from} to{' '}
                                        {education.attended_to}) (Status: {education.verification_status})
                                    </Text>
                                ),
                            )}
                        </div>
                    ) : null}
                    {provider.occrra_info_collect && provider.occrra_info_collect.cpl_level ? (
                        <Text variant="body2" textStyle={{ margin: 5, marginLeft: 10 }}>
                            <b>CPL Level (from OCCRRA)</b>: {provider.occrra_info_collect.cpl_level}
                        </Text>
                    ) : null}
                    {provider.qualifications.map((q, index) => (
                        <React.Fragment key={index}>
                            {q.qualification ? (
                                <Text variant="body2" textStyle={{ margin: 5, marginLeft: 10 }}>
                                    <b>
                                        {q.qualification.name} {!q.calculated_at ? '(self-reported)' : ''}
                                    </b>
                                    : {q.value ? q.value : q.option ? `${q.option.value}` : 'None'}{' '}
                                    {q.calculated_at
                                        ? `(last calculated: ${formatMonthDayYearWith12HourTime(q.calculated_at)})`
                                        : ''}
                                </Text>
                            ) : null}
                        </React.Fragment>
                    ))}
                    <Divider />
                    <Text variant="body2" bold textStyle={{ marginTop: 10 }}>
                        Qualifications for autobooking
                    </Text>
                    {provider.all_qualifications.split('\n').map((qual) => (
                        <Text key={qual} variant="body2" textStyle={{ margin: 5, marginLeft: 10 }}>
                            {qual}
                        </Text>
                    ))}
                </Grid>
                <Grid container item flexDirection="column" xs={12} md={5}>
                    {provider.employment_and_training_summary.school_readiness ? (
                        <>
                            <Text variant="body1" bold inline>
                                Florida School Readiness:{' '}
                                {provider.employment_and_training_summary.school_readiness.school_readiness_complete
                                    ? '✅'
                                    : '❌'}
                            </Text>
                            <Text variant="body2" textStyle={{ margin: 5, marginLeft: 10 }}>
                                DEVSR:{' '}
                                {provider.employment_and_training_summary.school_readiness.verified_devsr ? '✅' : '❌'}{' '}
                                SAFESR:{' '}
                                {provider.employment_and_training_summary.school_readiness.verified_safesr
                                    ? '✅'
                                    : '❌'}{' '}
                                HNSR:{' '}
                                {provider.employment_and_training_summary.school_readiness.verified_hnsr ? '✅' : '❌'}
                            </Text>
                        </>
                    ) : null}
                    {provider.employment_and_training_summary.director_credential_expiration ? (
                        <Text variant="body2" textStyle={{ margin: 5, marginLeft: 10 }}>
                            <Text variant="body2" bold inline>
                                Worker Director Certificate Expiration:{' '}
                            </Text>
                            {format(
                                new Date(provider.employment_and_training_summary.director_credential_expiration),
                                'MM/dd/yyyy',
                            )}
                        </Text>
                    ) : null}
                    {provider.employment_and_training_summary.staff_credential_expiration ? (
                        <Text variant="body2" textStyle={{ margin: 5, marginLeft: 10 }}>
                            <Text variant="body2" bold inline>
                                Worker Staff Certificate Expiration:{' '}
                            </Text>
                            {format(
                                new Date(provider.employment_and_training_summary.staff_credential_expiration),
                                'MM/dd/yyyy',
                            )}
                        </Text>
                    ) : null}

                    <Text variant="body2" textStyle={{ margin: 5, marginLeft: 10 }}>
                        <b>Other Certifications (self-reported)</b>:{' '}
                        {provider.childcare_center_qualification.length > 0 &&
                        'other_certification' in provider.childcare_center_qualification[0] &&
                        provider.childcare_center_qualification[0].other_certification
                            ? provider.childcare_center_qualification[0].other_certification
                            : 'None on file'}
                    </Text>
                    {typeof provider.employment_and_training_summary.has_verified_staff_credential === 'boolean' ? (
                        <Text variant="body2" textStyle={{ margin: 5, marginLeft: 10 }}>
                            <Text variant="body2" bold inline>
                                Has Verified Staff Credential:{' '}
                            </Text>
                            {provider.employment_and_training_summary.has_verified_staff_credential ? '✅' : '❌'}
                        </Text>
                    ) : null}
                    {typeof provider.employment_and_training_summary.has_verified_director_credential === 'boolean' ? (
                        <Text variant="body2" textStyle={{ margin: 5, marginLeft: 10 }}>
                            <Text variant="body2" bold inline>
                                Has Verified Director Credential:{' '}
                            </Text>
                            {provider.employment_and_training_summary.has_verified_director_credential ? '✅' : '❌'}
                        </Text>
                    ) : null}
                    {typeof provider.employment_and_training_summary.has_verified_vpk_credential === 'boolean' ? (
                        <Text variant="body2" textStyle={{ margin: 5, marginLeft: 10 }}>
                            <Text variant="body2" bold inline>
                                Has Verified VPK Credential:{' '}
                            </Text>
                            {provider.employment_and_training_summary.has_verified_vpk_credential ? '✅' : '❌'}
                        </Text>
                    ) : null}
                </Grid>
            </Grid>
        </>
    );
}
