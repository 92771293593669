import React from 'react';
import { Grid } from '@material-ui/core';
import { SmallIcon } from 'library';

export default function ProviderProfileDetailRow({
    icon,
    detail,
    style,
}: {
    icon: React.ReactNode;
    detail?: React.ReactNode;
    style?: React.CSSProperties;
}) {
    return (
        <Grid container direction="row" wrap="nowrap" style={{ marginBottom: 8, alignItems: 'flex-start', ...style }}>
            <SmallIcon icon={icon} style={{ width: 19, height: 19, marginRight: 8 }} />
            {detail}
        </Grid>
    );
}
