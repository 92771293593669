import { Text } from 'library';
import { Education } from 'parent-portal/provider-profile/providerProfileModels';
import React, { useEffect, useState } from 'react';
import Highlight from './Highlight';

const defaultEducation = { major_name: '', award_name: '', institution_name: 'Contact provider for more information' };

export default function EducationHighlight({ education }: { education: Education[] }) {
    const [highestEducation, setHighestEducation] = useState<Education>(defaultEducation as Education);

    useEffect(() => {
        if (education.length > 0) setHighestEducation(education[0]);
    }, [education]);
    return (
        <Highlight title="Education">
            {highestEducation.major_name && (
                <Text variant="h2" bold textStyle={{ marginTop: 8 }}>
                    {highestEducation.major_name}
                </Text>
            )}
            <Text
                variant={highestEducation.major_name ? 'body1' : 'h2'}
                bold={!highestEducation.major_name}
                textStyle={{ marginTop: 8 }}
            >
                {highestEducation.award_name}
            </Text>
            <Text textStyle={{ marginTop: 8 }}>{highestEducation.institution_name}</Text>
        </Highlight>
    );
}
