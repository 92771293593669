import React, { useContext } from 'react';
import { Colors, Text } from 'library';
import { styled } from '@mui/material';

import { PreferredWorkerModalContext } from '.';
import { ShiftProviderUser } from 'models';

export default function Header() {
    const { provider }: { provider: ShiftProviderUser } = useContext(PreferredWorkerModalContext);

    return (
        <Container>
            <Text variant="caption" textStyle={{ marginBottom: 10 }}>
                You can add preferred workers business wide or at specific locations using the options below.
            </Text>
            <Text>
                Add{' '}
                <Text inline variant="h2" textStyle={{ color: Colors.turquoise }}>
                    {provider.first_name} {provider.last_name}
                </Text>{' '}
                to:
            </Text>
        </Container>
    );
}

const Container = styled('div')({
    paddingBottom: '2em',
});
