import { isMonday, nextMonday, previousMonday } from 'date-fns';
import { formatShortWeekdayMonthDay } from 'shared/Dates';

export const getNextMonday = () => {
    let monday = new Date();
    monday.setDate(monday.getDate() + 7 - (monday.getDay() % 7) + 1 || 7);
    monday.setHours(0, 0, 0, 0);
    return monday;
};

export const getMondayForThisWeek = () => {
    let today = new Date();
    today.setHours(0, 0, 0, 0);
    return isMonday(today) ? today : previousMonday(today);
};

export const getMondayForNextWeek = (day: Date) => {
    if (day && isMonday(day)) {
        return nextMonday(day.setDate(day.getDate() + 1));
    }

    return nextMonday(day);
};

export const getTodayStart = () => {
    let today = new Date();
    today.setHours(0, 0, 0, 0);
    return today;
};

const getDateForWeekdayIndex = (date: Date, index: number) => {
    const newDate = new Date(date);
    newDate.setDate(date.getDate() + index);
    return newDate;
};

export const weekdayHeaders = (week?: Date) =>
    week
        ? [
              formatShortWeekdayMonthDay(week),
              formatShortWeekdayMonthDay(getDateForWeekdayIndex(week, 1)),
              formatShortWeekdayMonthDay(getDateForWeekdayIndex(week, 2)),
              formatShortWeekdayMonthDay(getDateForWeekdayIndex(week, 3)),
              formatShortWeekdayMonthDay(getDateForWeekdayIndex(week, 4)),
              formatShortWeekdayMonthDay(getDateForWeekdayIndex(week, 5)),
              formatShortWeekdayMonthDay(getDateForWeekdayIndex(week, 6)),
          ]
        : [];
