import { Popover, withStyles } from '@material-ui/core';
import { Colors } from '../../../../constants';

const BorderPopover = withStyles({
    paper: {
        border: `1px solid ${Colors.coral}`,
        borderRadius: '10px',
    },
})(Popover) as any;

export default BorderPopover;
