import React from 'react';
import { Text, Colors } from 'library';
import { ScheduledShift } from '../models';
import { styled, Grid } from '@mui/material';
import { formatMonthDayTime, formatShortMonthDayYear } from 'shared/Dates';
import { formatDistance } from 'date-fns';
import Strike from './Strike';

export default function ShiftCard({ shift }: { shift: ScheduledShift }) {
    const startDate = new Date(shift.appointment.start_date);
    const cancelled = shift.status === 'SITTER_CANCELLED';
    const familyCancelled = shift.status === 'FAMILY_CANCELLED';
    const cancelledAt = shift.cancelled_at ? new Date(shift.cancelled_at) : null;
    const cancelledBeforeShift = cancelledAt && cancelledAt < startDate;
    const cancellationNotice = cancelledAt ? formatDistance(startDate, cancelledAt) : null;
    const noShow = shift.cancellation_reason === 'PROVIDER_NO_SHOW';

    let statusText = 'COMPLETED SHIFT';

    if (noShow) {
        statusText = 'NO SHOW';
    } else if (cancelled) {
        statusText = 'WORKER CANCELLED';
    } else if (familyCancelled) {
        statusText = 'FAMILY CANCELLED';
    }

    return (
        <Container item container xs={12} md={3} direction="column" cancelled={cancelled || noShow || familyCancelled}>
            <TextContainer direction="row" justifyContent="space-between" container item>
                <Text bold>{formatShortMonthDayYear(shift.appointment.start_date)}</Text>
                {shift.strike ? <Strike strike={shift.strike} /> : null}
            </TextContainer>
            {shift.business ? <Text>{shift.business}</Text> : null}
            <TextContainer direction="row" justifyContent="space-between" container item>
                <Text variant="caption" bold>
                    {statusText}
                </Text>
                <TextRow>
                    <Text variant="caption" bold textStyle={{ paddingRight: 5 }}>
                        Shift Start Time:
                    </Text>{' '}
                    <Text variant="caption">{formatMonthDayTime(startDate)}</Text>
                </TextRow>
                {cancelledAt && (
                    <TextRow>
                        <Text variant="caption" bold textStyle={{ paddingRight: 5, whiteSpace: 'nowrap' }}>
                            Cancelled At:
                        </Text>{' '}
                        <Text variant="caption">
                            {formatMonthDayTime(cancelledAt)} ({cancellationNotice}
                            {cancelledBeforeShift ? ' notice' : ' AFTER SHIFT START!!'})
                        </Text>
                    </TextRow>
                )}
                {shift.no_show_reason && (
                    <TextRow>
                        <Text variant="caption" bold textStyle={{ paddingRight: 5, whiteSpace: 'nowrap' }}>
                            No Show Reason:
                        </Text>{' '}
                        <Text variant="caption">{shift.no_show_reason}</Text>
                    </TextRow>
                )}
                {shift.reason && (
                    <TextRow>
                        <Text variant="caption" bold textStyle={{ paddingRight: 5, whiteSpace: 'nowrap' }}>
                            Reason:
                        </Text>{' '}
                        <Text variant="caption">{shift.reason}</Text>
                    </TextRow>
                )}
                {shift.cancellation_message && (
                    <TextRow>
                        <Text variant="caption" bold textStyle={{ paddingRight: 5, whiteSpace: 'nowrap' }}>
                            Cancel Message:
                        </Text>{' '}
                        <Text variant="caption">{shift.cancellation_message}</Text>
                    </TextRow>
                )}
            </TextContainer>
        </Container>
    );
}

const Container = styled(Grid)(({ cancelled }: { cancelled: boolean }) => ({
    backgroundColor: cancelled ? Colors.lightRed : Colors.lightTurq,
    padding: 20,
    borderRadius: 18,
}));

const TextContainer = styled(Grid)({
    marginTop: 10,
});

const TextRow = styled('div')({
    display: 'flex',
});
