import React from 'react';
import { LeanUser, BusinessJobWithAppointments } from 'models';
import { Grid } from '@mui/material';
import AdminButton from 'internal/shared/AdminButton';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import SendMatch from './SendMatch';
import { Colors } from 'library';

export default function MatchingListActions({
    job,
    refreshJobs,
    matchingList,
    selectedUsers,
    setSelectedUsers,
}: {
    job: BusinessJobWithAppointments;
    refreshJobs: () => void;
    matchingList: Array<LeanUser> | undefined;
    selectedUsers: Array<LeanUser>;
    setSelectedUsers: (users: Array<LeanUser>) => void;
}) {
    const allSelected = () => {
        if (matchingList) {
            return selectedUsers.length === matchingList.length;
        }
        return false;
    };

    const toggleAllUsers = () => {
        const all = allSelected();

        if (matchingList && !all) {
            setSelectedUsers(Array.from(matchingList));
        } else if (all) {
            setSelectedUsers([]);
        }
    };

    return (
        <Grid container style={{ alignItems: 'center', padding: '0 15px 0 10px' }}>
            {matchingList && matchingList.length > 0 && (
                <AdminButton
                    color={Colors.white}
                    text={`${allSelected() ? 'Un-' : ''}Select All`}
                    onClick={toggleAllUsers}
                    FontAwesomeImg={faCheck}
                />
            )}
            {selectedUsers.length > 0 && (
                <SendMatch
                    job={job}
                    initialProviders={selectedUsers}
                    refreshJobs={refreshJobs}
                    text="Match All Selected"
                />
            )}
        </Grid>
    );
}
