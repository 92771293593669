import React from 'react';
import { BasicDialog, Colors, PrimaryButton, Text } from 'library';
import { styled } from '@mui/material';
import { CheckCircleOutline } from '@mui/icons-material';

import { InnerModal } from './components';
import { usePastHire } from './hooks';

export function PastAppointmentModal({
    open,
    setOpen,
    onClose,
    ongoingRequestId,
}: {
    open: boolean;
    setOpen: (open: boolean) => void;
    onClose?: () => void;
    ongoingRequestId: number;
}) {
    const pastHire = usePastHire({ options: { sendImmediately: false } });

    function handleClose() {
        setOpen(false);

        onClose?.();
    }

    return (
        <BasicDialog isOpen={open} onClose={handleClose} closeButton>
            {pastHire.status !== 200 ? (
                <InnerModal ongoingRequestId={ongoingRequestId} handleClose={handleClose} pastHire={pastHire} />
            ) : (
                <>
                    <Header>
                        <Success>
                            <CheckCircleOutline sx={{ color: Colors.white, fontSize: 48 }} />
                        </Success>
                        <Text variant="h1" textStyle={{ paddingLeft: 10 }}>
                            Success!
                        </Text>
                    </Header>
                    <Text textStyle={{ paddingLeft: 20, paddingRight: 20 }}>
                        The worker has been added to that appointment and an hour report has been created!
                    </Text>
                    <ButtonRow>
                        <PrimaryButton buttonStyle={{ width: 'fit-content' }} onClick={handleClose}>
                            Close
                        </PrimaryButton>
                    </ButtonRow>
                </>
            )}
        </BasicDialog>
    );
}

const Header = styled('div')({
    alignItems: 'center',
    display: 'flex',
    marginBottom: 20,
    paddingLeft: 20,
});

const Success = styled('div')({
    backgroundColor: Colors.turquoise,
    borderRadius: '50%',
    width: 'fit-content',
});

const ButtonRow = styled('div')({
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 20,
});
