import React from 'react';
import { BackgroundCheck, Provider, BackgroundCheckToken } from 'models';
import { Text, Colors } from 'library';
import { Verified } from '@mui/icons-material';
import moment from 'moment';
import { useProviderProfileContext } from 'parent-portal/provider-profile/ProviderProfileContext';
import { useUserContext } from 'UserContext';

interface SafetyCheckInfoProps {
    check: BackgroundCheck;
    provider: Provider;
    token: BackgroundCheckToken | null;
    loggedIn: boolean;
    pendingToken: BackgroundCheckToken | null;
}

export default function SafetyCheckInfo({ check, provider, token, loggedIn, pendingToken }: SafetyCheckInfoProps) {
    const { user } = useUserContext();
    const { childcareCenterQualifications } = useProviderProfileContext();

    const selfReportedOccrraCheck = () => {
        return (
            childcareCenterQualifications.length > 0 &&
            childcareCenterQualifications[0].occrra_check &&
            (user?.businesses_active?.length || 0) > 0
        );
    };

    const getStatusColor = (check: BackgroundCheck) => {
        return getCompleted()
            ? Colors.turquoise
            : pendingToken !== null
            ? Colors.terraCotta
            : selfReportedOccrraCheck() && check.code === 'OCCRRA'
            ? Colors.darkNavy
            : Colors.mediumGrey;
    };

    const getCompleted = () => {
        if (check.code === 'REFERENCE_CHECK') return !!provider.reference_check;

        return token?.status === 'COMPLETED';
    };

    const getLastCompletedText = () => {
        if (check.code === 'REFERENCE_CHECK' && !!provider.reference_check) return 'Completed';

        if (token != null) {
            return `Last completed: ${moment(token.status_updated_at, 'MM-DD-YYYY hh:mm A').format('MMM DD, YYYY')}`;
        } else if (pendingToken != null) {
            return `In progress, submitted ${moment(pendingToken.status_updated_at, 'MM-DD-YYYY hh:mm A').format(
                'MMM DD, YYYY',
            )}`;
        } else if (check.code === 'OCCRRA' && selfReportedOccrraCheck()) {
            let qualification = childcareCenterQualifications[0];
            return `${provider.user.first_name} reported completing this check${
                qualification.occrra_date ? ' on ' + qualification.occrra_date : ''
            }. If you require this check for your business, please verify the status in OCCRRA before hiring this provider.`;
        }
        return 'Not yet completed';
    };

    const getTitle = (check: BackgroundCheck) => {
        return (
            <Text
                variant="h2"
                bold
                color={getStatusColor(check)}
                textStyle={{ marginBottom: 8, display: 'flex', flexDirection: 'row', alignItems: 'center' }}
            >
                {check.title}
            </Text>
        );
    };

    return (
        <>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
                <Verified htmlColor={getStatusColor(check)} style={{ marginRight: 8 }} />
                {getTitle(check)}
            </div>
            <Text variant="body2" textStyle={{ fontStyle: 'italic', marginBottom: 8 }}>
                {getLastCompletedText()}
            </Text>
            <Text variant="body2" color={Colors.mediumGrey} textStyle={{ marginBottom: 12 }}>
                {check.description}
            </Text>
        </>
    );
}
