import React from 'react';
import PasswordInput from '../reusableComponents/passwordInput';
import { useCart } from './CartContext';

const CriminalCheckInputs = () => {
    const { ssn, ssnValid, updateSsn } = useCart();

    const ssnChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        updateSsn(e.target.value);
    };

    return (
        <PasswordInput
            id="ssn"
            required
            label="SSN"
            value={ssn}
            variant="outlined"
            onChange={ssnChanged}
            error={!ssnValid}
            helperText={ssnValid ? '' : 'SSN must be a 9-digit number'}
        />
    );
};

export default CriminalCheckInputs;
