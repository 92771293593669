import React from 'react';
import { Grid } from '@material-ui/core';
import { SecondaryButton, Text } from 'library';
import { useHistory } from 'react-router-dom';

export default function QuestionFooter() {
    const history = useHistory();
    const buttonClick = () => {
        history.push('/tandempay/faq');
    };

    return (
        <Grid container style={{ justifyContent: 'center' }}>
            <Grid item>
                <Text variant="h1" bold>
                    Have more questions?
                </Text>
            </Grid>
            <Grid item xs={8} style={{ paddingTop: 24 }}>
                <SecondaryButton onClick={buttonClick}>We have answers</SecondaryButton>
            </Grid>
        </Grid>
    );
}
