import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import { BasicDialog, Colors, Text } from 'library';
import ErrorIcon from '@mui/icons-material/Error';

export default function ActionRequired() {
    const [open, setOpen] = useState(false);
    return (
        <Grid container item style={{ paddingTop: 12, paddingBottom: 6 }}>
            <button
                onClick={() => setOpen(true)}
                style={{
                    alignSelf: 'flex-start',
                    border: 'none',
                    backgroundColor: 'transparent',
                    outline: 'none',
                }}
            >
                <Text
                    variant="caption"
                    bold
                    textStyle={{
                        textDecorationLine: 'underline',
                        cursor: 'pointer',
                        color: Colors.mediumRed,
                        alignSelf: 'center',
                    }}
                >
                    <ErrorIcon style={{ color: Colors.mediumRed, marginRight: 2, height: 18, width: 18 }} />
                    Action Required
                </Text>
            </button>

            <BasicDialog isOpen={open} onClose={() => setOpen(false)} closeButton>
                <Text bold variant="h2">
                    In order to confirm the workers compliance before they work, please ensure that you have completed
                    the following steps:
                </Text>
                <br />
                <Text>1. Log into Clearinghouse</Text>
                <Text>2. Click on the 'Background Screening' tab</Text>
                <Text>3. Click 'Search'</Text>

                <Text>
                    4. Type in the workers social security number (click the three dots next to their name on Tandem)
                </Text>
                <Text>5. Add the worker to your roster</Text>
                <br />
                <Text bold variant="body2">
                    Please note: If the worker has '90 Day Lapse in Employment' listed, please click 'Initiate
                    Resubmission'. Then, you can add them to your roster.
                </Text>
                <br />
                <Text variant="caption" textStyle={{ fontStyle: 'italic' }}>
                    If you have any questions or trouble adding the worker to your roster, please email
                    support@join-tandem.com.
                </Text>
            </BasicDialog>
        </Grid>
    );
}
