import React, { ReactNode } from 'react';
import BubbleButton from './BubbleButton';
import { Colors } from '../../theme';

interface ITextButtonProps {
    children?: ReactNode | ReactNode[];
    onClick: (e: any) => void;
    color?: string;
    textStyle?: React.CSSProperties;
    buttonStyle?: React.CSSProperties;
    disabled?: boolean;
    disableRipple?: boolean;
}

const enabledStyle = {
    button: {
        backgroundColor: 'none',
        boxShadow: 'none',
        '&:hover': {
            backgroundColor: Colors.pressedNavy,
        },
        outline: 'none',
    },
};

const disabledStyle = {
    button: {
        backgroundColor: Colors.disabledGrey,
    },
};

export default function TextButton({ color, children, onClick, ...props }: ITextButtonProps) {
    const defaultStyle = {
        button: {
            padding: 0,
            margin: 0,
        },
        text: {
            color: color ? color : Colors.darkNavy,
            textDecorationLine: 'underline',
        },
    };
    return (
        <BubbleButton
            defaultStyle={defaultStyle}
            enabledStyle={enabledStyle}
            disabledStyle={disabledStyle}
            onClick={onClick}
            {...props}
        >
            {children}
        </BubbleButton>
    );
}
