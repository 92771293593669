import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { Message } from 'models/Message';
import { Colors, Text, Avatar } from 'library';
import moment from 'moment';
const defaultProfileImage = require('assets/images/default-sitter.png');

interface IThreadProps {
    message: Message;
    user: any;
    providerImage?: string | undefined | null;
}

export const Thread = ({ message, user, providerImage }: IThreadProps) => {
    return (
        <Grid container direction="column" alignItems={message.sender.id === user.id ? 'flex-end' : 'flex-start'}>
            {message.ongoing_request ? (
                <Grid container item direction="row" lg={7} md={5} xs={7} spacing={2}>
                    <Grid container item sm={1} xs={2} alignItems="center" justify="center">
                        <Avatar
                            src={providerImage || defaultProfileImage}
                            style={{
                                width: 40,
                                height: 40,
                            }}
                        />
                    </Grid>
                    <Grid container item sm={11} xs={10} alignItems="center">
                        <Grid container item xs={12}>
                            <Text variant="body1" bold>
                                <i>Submitted Application</i>
                            </Text>
                        </Grid>
                        {message.ongoing_request.headline ? (
                            <Grid container item xs={12}>
                                <Text variant="body2">{message.ongoing_request.headline}</Text>
                            </Grid>
                        ) : null}
                        <Grid container item xs={12} style={{ marginTop: 5 }}>
                            <Text variant="body2">
                                <b>Here's what they had to say when applying:</b>
                            </Text>
                        </Grid>
                    </Grid>
                </Grid>
            ) : null}
            <Grid
                item
                lg={7}
                md={5}
                xs={7}
                style={{
                    backgroundColor: message.sender.id == user.id ? '#E8E8E8' : Colors.lightTurq,
                    borderRadius: 18,
                    padding: 10,
                    marginTop: 10,
                    wordWrap: 'break-word',
                }}
            >
                {!message.ongoing_request ? (
                    <Typography variant="subtitle1">{message.message}</Typography>
                ) : message.ongoing_request.question ? (
                    <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                        {message.ongoing_request.question}
                    </Typography>
                ) : null}
                {message.ongoing_request && message.ongoing_request.answer ? (
                    <>
                        <Typography variant="subtitle1">{message.ongoing_request.answer}</Typography>
                        <Typography variant="subtitle2" style={{ marginTop: 10, color: Colors.turquoise }}>
                            <b>Message them to get the conversation started.</b>
                        </Typography>
                    </>
                ) : null}
                {message.image && (
                    <img
                        src={message.image['image'] ? message.image['image'] : message.image['url']}
                        style={{ maxHeight: 150, maxWidth: 150, display: 'block' }}
                    ></img>
                )}
            </Grid>
            <Typography variant="subtitle2">{moment(message.sent_at).format('dddd M/D/YYYY h:mm A')}</Typography>
        </Grid>
    );
};
