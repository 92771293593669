import { SmallIcon, Text } from 'library';
import React from 'react';

export default function ProviderSummaryDetailRow({
    icon,
    detail,
    italic,
    subDetail,
    bold,
}: {
    icon?: React.ReactNode;
    detail?: string;
    subDetail?: string;
    italic?: boolean;
    bold?: boolean;
}) {
    return (
        <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', marginBottom: 5, marginTop: 8 }}>
            {!!icon ? <SmallIcon icon={icon} style={{ width: 25, height: 25, marginRight: 8 }} /> : null}
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    flexGrow: 1,
                    justifyContent: 'center',
                }}
            >
                <Text
                    variant="body2"
                    textStyle={{
                        padding: 0,
                        fontStyle: italic ? 'italic' : undefined,
                        width: '100%',
                        fontWeight: bold ? 'bold' : undefined,
                    }}
                >
                    {detail}
                </Text>
                {!!subDetail && (
                    <Text
                        variant="caption"
                        textStyle={{
                            textAlign: 'left',
                            padding: 0,
                            lineHeight: 1,
                            fontStyle: italic ? 'italic' : undefined,
                            width: '100%',
                        }}
                    >
                        {subDetail}
                    </Text>
                )}
            </div>
        </div>
    );
}
