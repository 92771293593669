import React from 'react';
import { Avatar, Grid, styled } from '@mui/material';
import { Colors, Text } from 'library';
import ClockInButton from '../../jobs/upcoming/BusinessCalendar/ShiftCard/ClockInButton';
import { IUserToClockIn } from 'models';

interface ClockInWorkersProps {
    workersToClockIn: IUserToClockIn[];
    onShiftClockIn: () => void;
}
export default function UnclockedInShifts({ workersToClockIn, onShiftClockIn }: ClockInWorkersProps) {
    if (!workersToClockIn?.length) {
        return <></>;
    }

    function onClockIn() {
        onShiftClockIn();
    }

    return (
        <Grid container direction="row" style={{ gap: 5 }}>
            {workersToClockIn.map((x) => (
                <ClockInContainer key={x.appointment_id} container item xs={12}>
                    <Grid container item xs={2} alignItems="center" justifyContent="center">
                        <Avatar
                            src={x.profile_picture ? x.profile_picture : undefined}
                            alt={x.provider_name}
                            style={{
                                margin: 0,
                                padding: 0,
                                height: 70,
                                width: 70,
                                backgroundColor: Colors.darkNavy,
                                fontSize: '1rem',
                                marginRight: 5,
                            }}
                        ></Avatar>
                    </Grid>
                    <Grid container item xs={7} alignItems="center">
                        <Text>
                            Clock in <b>{x.provider_name}</b> for their shift at {x.location_name}
                        </Text>
                    </Grid>
                    <Grid container item xs={3} alignItems="center" justifyContent="flex-end">
                        <ClockInButton
                            providerName={x.provider_name}
                            providerUserId={x.provider_user_id}
                            appointmentId={x.appointment_id}
                            startDate={x.start_date}
                            onComplete={onClockIn}
                        />
                    </Grid>
                </ClockInContainer>
            ))}
        </Grid>
    );
}

const ClockInContainer = styled(Grid)({
    backgroundColor: Colors.lightTurq,
    borderRadius: 20,
    margin: '5px 0',
    padding: 5,
});
