import { FormControl, Select, SelectChangeEvent } from '@mui/material';
import { MenuItem } from '@material-ui/core';
import React from 'react';

interface AdminSelectProps {
    fields: string[];
    selected: string;
    onChange: (value: string) => void;
}

export default function AdminSelect({ fields, selected, onChange }: AdminSelectProps) {
    const handleChange = (event: SelectChangeEvent<string>, child: React.ReactNode) => {
        onChange(event.target.value);
    };

    return (
        <FormControl fullWidth variant="standard">
            <Select value={selected} onChange={handleChange}>
                {fields.map((field) => (
                    <MenuItem key={field} value={field}>
                        {field}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}
