import React from 'react';
import { Paper } from 'library';
import { useProviderProfileContext } from '../../ProviderProfileContext';
import PreviousChildcareCenterJobExperience from './PreviousChildcareCenterExperience';
import ChildcareCenterExperienceSpecifics from './ChildcareCenterExperienceSpecifics';
import ProviderResumeSection from './ProviderResumeSection';

export default function ProviderProfileBusinessExperienceTab() {
    const { providerQualifications } = useProviderProfileContext();
    return (
        <>
            <Paper>
                <ProviderResumeSection />
                <PreviousChildcareCenterJobExperience employment={providerQualifications.employment} />
                <ChildcareCenterExperienceSpecifics />
            </Paper>
        </>
    );
}
