import { Grid, useMediaQuery } from '@material-ui/core';
import React, { MouseEventHandler, CSSProperties, ReactElement } from 'react';
import OptionRow from './OptionRow';
import { Colors, Text } from 'library';
import theme from 'theme';

const optionRowStyle: CSSProperties = {
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 76,
    minHeight: 120,
};

const smallOptionRowStyle: CSSProperties = {
    paddingTop: 30,
    paddingBottom: 30,
    paddingLeft: 16,
    minHeight: 150,
};

const iconStyle: CSSProperties = {
    width: 70,
    height: 70,
    marginRight: 53,
};

const smallIconStyle: CSSProperties = {
    width: 45,
    height: 45,
    marginRight: 18,
    alignSelf: 'flex-start',
};

interface ModalOptionRowProps {
    icon: ReactElement;
    titleText: string;
    bodyText?: string;
    onClick?: MouseEventHandler<HTMLDivElement>;
}

export default function ModalOptionRow({ icon, titleText, bodyText, onClick }: ModalOptionRowProps) {
    const small = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <OptionRow style={small ? smallOptionRowStyle : optionRowStyle} onClick={onClick}>
            <Grid container direction="row" wrap="nowrap">
                {React.cloneElement(icon, { style: small ? smallIconStyle : iconStyle })}
                <Grid direction="column" style={{ marginTop: 5, marginRight: 5 }}>
                    <Text bold variant="h2" textStyle={{ marginBottom: 5 }}>
                        {titleText}
                    </Text>
                    {bodyText !== undefined ? (
                        <Text color={Colors.grey} variant="body2">
                            {bodyText}
                        </Text>
                    ) : null}
                </Grid>
            </Grid>
        </OptionRow>
    );
}
