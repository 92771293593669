import React from 'react';
import { Text, Colors, SecondaryButton } from 'library';

export default function SimpleOption({
    title,
    highlight,
    action,
    id,
}: {
    title: string;
    highlight: string;
    action: () => void;
    id?: string;
}) {
    return (
        <SecondaryButton onClick={action} buttonStyle={styles.button} id={id}>
            <Text variant="h2" bold textStyle={{ marginTop: 10, marginBottom: 10 }}>
                {title}
            </Text>
            <Text variant="body2" inline bold color={Colors.turquoise}>
                {highlight}
            </Text>
        </SecondaryButton>
    );
}

const styles = {
    button: {
        marginTop: 20,
        maxWidth: 450,
        borderRadius: 18,
    },
};
