import { Grid } from '@mui/material';
import { Text } from 'library';
import React from 'react';

export function HereToHelpBlock() {
    return (
        <Grid item container flexDirection="column" style={{ gap: 20 }} justifyContent="right">
            <Text variant="h1">We're here to help.</Text>
            <Text>
                Tandem narrowed focus on the largest issue in the industry: <Text bold>staffing shortages.</Text>
            </Text>
            <Text>We created a platform for centers to get quality care as affordably as possible.</Text>
        </Grid>
    );
}
