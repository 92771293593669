import React, { useState, useEffect } from 'react';
import { Grid, withStyles, IconButton, Tooltip } from '@material-ui/core';
import moment from 'moment';
import 'react-infinite-calendar/styles.css';
import DateFnsUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import AccessAlarmsIcon from '@material-ui/icons/AccessAlarms';
import InfoIcon from '@material-ui/icons/Info';
import ErrorText from '../../../forms/components/ErrorText';

import { Text } from 'library';
import { IJob, IFormInfo, IFormTimeOfDay } from './EditJobModels';
import DayButton from 'parent-portal/forms/components/pages/DateAndTimePage/DayButton';
import { Job } from 'models';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import RenderRequestTime from './RenderRequestTime';
import { initialDayInformationFromTimesOfDay } from 'parent-portal/jobs/JobMethods';
import { useUserContext } from 'UserContext';
const StyledButton = withStyles({
    root: {
        '&:focus': {
            outline: 'none',
        },
    },
})(IconButton);

export default function EditRequestTimes({
    job,
    confirmation,
    formInfo,
    onStartDateChange,
    onEndDateChange,
    onTimeChange,
    changeRecurringJobDays,
}: {
    job?: IJob;
    confirmation?: boolean;
    formInfo: IFormInfo;
    onStartDateChange: (d: MaterialUiPickersDate, isOngoing?: boolean) => void;
    onEndDateChange: (d: MaterialUiPickersDate) => void;
    onTimeChange: (day: string, type: string, time: MaterialUiPickersDate) => void;
    changeRecurringJobDays: (n: IFormTimeOfDay[]) => void;
}) {
    // Ongoing request start and end date of full request
    const [initialDays, setInitialDays] = useState<any>({});
    const { role } = useUserContext();
    let today = new Date();

    useEffect(() => {
        if (job) {
            //@ts-ignore
            setInitialDays(initialDayInformationFromTimesOfDay(job as Job));
        }
    }, [job]);

    function sortDays(days: IFormTimeOfDay[]) {
        let baseDays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        return days.sort((a: IFormTimeOfDay, b: IFormTimeOfDay) => {
            let day1 = baseDays.indexOf(a.day);
            let day2 = baseDays.indexOf(b.day);
            if (day1 < day2) return -1;
            if (day1 > day2) return 1;
            return 0;
        });
    }

    function addOrRemoveDay(day: string) {
        let selectedDays = { ...initialDays, [day]: { ...initialDays[day], selected: !initialDays[day].selected } };
        setInitialDays(selectedDays);
        let formTimes = formInfo?.form_times;
        let activeDays = Object.keys(selectedDays).filter((d: string) => selectedDays[d].selected);
        if (formTimes !== undefined) {
            let removedDays = formTimes.reduce((p: IFormTimeOfDay[], t: IFormTimeOfDay) => {
                if (activeDays.includes(t.day)) {
                    return [...p, t];
                }
                return p;
            }, []);
            let addedDays = activeDays.reduce((p: IFormTimeOfDay[], c: string) => {
                if (!removedDays.find((d) => d.day === c) && formTimes) {
                    return [
                        ...p,
                        { ...formTimes[0], day: c, start: moment(formTimes[0].start), end: moment(formTimes[0].end) },
                    ];
                }
                return p;
            }, []);
            changeRecurringJobDays(sortDays([...removedDays, ...addedDays]));
        }
    }
    return (
        <Grid container direction="row" justify="space-between">
            <Grid container xs={1} justify="center" alignItems="center">
                <Grid item>
                    <AccessAlarmsIcon color="primary" />
                </Grid>
            </Grid>
            {job?.start_date ? (
                <Grid container xs={11}>
                    <Grid container sm={5} xs={9} style={{ marginLeft: 10, marginBottom: 10 }}>
                        <Grid item xs={12}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    autoOk
                                    variant="inline"
                                    disabled={confirmation}
                                    label={'Start Date - ' + formInfo?.start?.format('ddd, MMM D')}
                                    value={formInfo?.start?.isValid() ? formInfo.start : null}
                                    onChange={(date) => onStartDateChange(date)}
                                    format="MM/DD/YYYY"
                                    minDate={
                                        moment(job.start_date, 'YYYY-MM-DD').isBefore(today)
                                            ? moment(job.start_date, 'YYYY-MM-DD')
                                            : today
                                    }
                                    style={{ width: '100%', borderRadius: 10 }}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        {!formInfo.isRequestStart && (
                            <Grid item xs={12}>
                                <ErrorText>Invalid start date</ErrorText>
                            </Grid>
                        )}
                    </Grid>
                    <Grid container sm={5} xs={9} style={{ marginLeft: 10, marginBottom: 10 }}>
                        <Grid item xs={12}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    autoOk
                                    variant="inline"
                                    disabled={confirmation}
                                    label={'End Date - ' + formInfo?.end?.format('ddd, MMM D')}
                                    value={formInfo?.end?.isValid() ? formInfo.end : null}
                                    onChange={(date) => onEndDateChange(date)}
                                    format="MM/DD/YYYY"
                                    minDate={today}
                                    style={{ width: '100%', borderRadius: 10 }}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        {!formInfo.isRequestEnd && (
                            <Grid item xs={12}>
                                <ErrorText>Invalid end date</ErrorText>
                            </Grid>
                        )}
                    </Grid>
                    <Grid container xs={1} justify="center" alignItems="center" style={{ marginLeft: 10 }}>
                        <Tooltip title={'End day may be tentative.'} enterTouchDelay={10}>
                            <StyledButton>
                                <InfoIcon color="primary" />
                            </StyledButton>
                        </Tooltip>
                    </Grid>
                    {!formInfo.isDateRange && role !== 'business_active' && (
                        <Grid item xs={12}>
                            <ErrorText>Please enter a date range greater than 2 weeks</ErrorText>
                        </Grid>
                    )}
                    {job.days_of_week.length > 1 ? (
                        <Grid container style={{ flexWrap: 'wrap' }}>
                            {Object.keys(initialDays).map((d) => {
                                return (
                                    <DayButton
                                        day={initialDays[d]}
                                        dayName={d}
                                        onClick={() => addOrRemoveDay(d)}
                                        style={{ marginRight: 20, marginTop: 10 }}
                                    />
                                );
                            })}
                        </Grid>
                    ) : null}
                </Grid>
            ) : (
                <Grid container xs={11}>
                    <Grid item xs={12}>
                        <Text variant="body2" textStyle={{ marginLeft: 5 }}>
                            The appointment start date will be set when a provider is hired.
                        </Text>
                        <Grid container style={{ flexWrap: 'wrap' }}>
                            {Object.keys(initialDays).map((d) => {
                                return (
                                    <DayButton
                                        day={initialDays[d]}
                                        dayName={d}
                                        onClick={() => addOrRemoveDay(d)}
                                        style={{ marginRight: 20, marginTop: 10 }}
                                    />
                                );
                            })}
                        </Grid>
                    </Grid>
                </Grid>
            )}
            {formInfo?.form_times?.map((d) => (
                <RenderRequestTime day={d} confirmation={confirmation} onTimeChange={onTimeChange} />
            ))}
        </Grid>
    );
}
