import React, { useState } from 'react';
import { Text } from 'library';
import { MenuItem, Menu as MaterialMenu } from '@mui/material';
import MenuAnchor from './MenuAnchor';

interface Option {
    text: string;
    onClick?: () => void;
    asyncOnClick?: () => Promise<void>;
    icon?: React.ReactNode;
    disabled?: boolean;
}

export default function Menu({ options, anchor }: { options: Option[]; anchor: React.ReactNode }) {
    const [open, setOpen] = useState(false);

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    async function handleClick(option: Option) {
        if (option.asyncOnClick) {
            option.asyncOnClick?.()?.finally(() => setOpen(false));
        } else {
            option.onClick?.();
        }
    }

    return (
        <>
            <MenuAnchor open={open} setOpen={setOpen} setAnchorEl={setAnchorEl}>
                {anchor}
            </MenuAnchor>
            <MaterialMenu
                sx={{
                    '& .MuiMenu-paper': {
                        borderRadius: '8px',
                    },
                    '& .MuiMenu-list': {
                        padding: '8px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                    },
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={() => setOpen(false)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                {options.map((option) => (
                    <MenuItem
                        autoFocus={false}
                        sx={{ '&.MuiButtonBase-root': { minHeight: '36px' } }}
                        key={option.text}
                        onClick={() => handleClick(option)}
                        disabled={option.disabled}
                    >
                        <Text>
                            {option.icon} {option.text}
                        </Text>
                    </MenuItem>
                ))}
            </MaterialMenu>
        </>
    );
}
