import React, { useContext } from 'react';
import { Text, UnderlineTextInput } from 'library';
import { styled } from '@mui/material';

import { BannedWorkerModalContext } from './BannedWorkerModalContext';

export default function Memo({
    dirty,
    memo,
    setMemo,
    locationId,
}: {
    dirty: boolean;
    memo: string | null;
    setMemo: (id: number, memo: string) => void;
    locationId?: number;
}) {
    const { isAdmin } = useContext(BannedWorkerModalContext);

    if (!isAdmin) return null;

    return dirty ? (
        <UnderlineTextInput
            placeholder="Memo/Notes"
            inputProps={{ style: { fontSize: 14, fontFamily: 'Outfit' } }}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                locationId ? setMemo(locationId, e.target.value) : setMemo(0, e.target.value)
            }
            value={memo ?? ''}
        />
    ) : (
        <Row>
            <Text variant="body2" bold>
                Memo:
            </Text>
            <Text variant="body2" textStyle={{ paddingLeft: 5 }}>
                {memo ?? 'N/A'}
            </Text>
        </Row>
    );
}

const Row = styled('div')({
    display: 'flex',
    flexDirection: 'row',
});
