import React from 'react';
import { Colors, Text } from 'library';
import Star from 'assets/images/marketing-site/Star.svg';
import { Grid, useMediaQuery } from '@mui/material';
import theme from 'theme';

export default function Payments() {
    const smDown = useMediaQuery(theme.breakpoints.down('sm'));
    return (
        <Grid item container xs={12} justifyContent="center" style={{ gap: 60 }}>
            <Grid item container flexDirection="column" justifyContent="center" xs={12} style={{ gap: 20 }}>
                <Text variant="display" textStyle={{ textAlign: 'center' }}>
                    We keep it very simple
                </Text>
                <Text variant="h2" textStyle={{ textAlign: 'center' }}>
                    We charge a 35% fee on top of the worker's hourly rate.
                </Text>
            </Grid>
            <Grid item container flexDirection="row" style={{ gap: 40 }} justifyContent="center">
                <Grid item style={{ display: 'flex', justifyContent: 'center' }} flexDirection="column">
                    <Text variant="display" textStyle={{ textAlign: 'center' }}>
                        $13/hr
                    </Text>
                    <Text>You set the worker's hourly rate.</Text>
                </Grid>
                <Text variant="display">+</Text>
                <Grid item style={{ display: 'flex', justifyContent: 'center' }} flexDirection="column">
                    <Text variant="display" textStyle={{ textAlign: 'center', color: Colors.coral }}>
                        $4.55/hr
                    </Text>
                    <Text>Tandem's 35% service fee.</Text>
                </Grid>
                <Text variant="display">=</Text>
                <Grid item style={{ display: 'flex', justifyContent: 'center' }} flexDirection="column">
                    <Text variant="display" textStyle={{ textAlign: 'center' }}>
                        $17.55/hr
                    </Text>
                    <Text>This is what you'd pay per hour.</Text>
                </Grid>
                <Grid
                    item
                    container
                    flexDirection="row"
                    style={{ gap: 40, padding: 20 }}
                    alignItems="center"
                    justifyContent="center"
                >
                    <Star height={50} width={50} />
                    <Grid style={{ display: 'inline-block' }}>
                        <Text variant="h1" textStyle={{ textAlign: smDown ? 'center' : 'left' }}>
                            How can I bring the worker permanently onto my staff?
                        </Text>
                        <Text variant="h2" textStyle={{ textAlign: smDown ? 'center' : 'left' }}>
                            You can onboard workers after 120 hours of them working at your center at no additional
                            cost.
                        </Text>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}
