import React from 'react';
import { Link } from 'react-router-dom';
import { Grid } from '@mui/material';
import DashboardSection from '../components/DashboardSection';
import { ConfirmHoursContent } from 'parent-portal/subs/ConfirmHoursContent';
import { IBusinessDashboardProps } from './types';
import { useStyles } from './useStyles';

export default function WorkerTimeSheets({ data, updateData }: IBusinessDashboardProps) {
    const classes = useStyles();
    const hourReports = data?.hour_reports?.highlighted;
    const totalReports = data?.hour_reports.count ?? 0;
    const multiReportText = totalReports > 1 ? 's this week' : ' this week';
    const hourReportDescription =
        totalReports > 0
            ? `You have ${totalReports} timesheet${multiReportText}. You can view all timesheets on the confirm hours page.`
            : 'You have no timesheets so far this week.';

    return (
        <DashboardSection header="Worker Timesheets" description={hourReportDescription}>
            <Grid container item direction="row" style={{ gap: 20 }}>
                {hourReports ? (
                    <ConfirmHoursContent hourReports={hourReports} updateHoursReported={updateData} />
                ) : null}
            </Grid>
            <Grid container justifyContent="center">
                <Link to="confirm-hours" className={classes.link}>
                    View All
                </Link>
            </Grid>
        </DashboardSection>
    );
}
