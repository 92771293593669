import { Text } from 'library';
import Checkmark from 'assets/icons/Checkmark.svg';
import React from 'react';

const ThanksForFeedback = () => {
    return (
        <Text textStyle={{ textAlign: 'center' }} variant="h1">
            Thank you for your feedback! <Checkmark style={{ marginLeft: 10 }} height={25} />
        </Text>
    );
};

export default ThanksForFeedback;
