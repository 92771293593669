import { Tune } from '@mui/icons-material';
import { Popover } from '@mui/material';
import { IconButton, Text, Colors, PrimaryButton, OutlinedTextInput } from 'library';
import React, { useState } from 'react';
import BusinessLocationStatusSelect from '../../business-locations/BusinessLocationStatusSelect';
import { BusinessFilterValues } from '../../business-locations/BusinessLocationFilters';
import BusinessLocationJobFilters from '../../business-locations/BusinessLocationJobFilters';
import { useBusinessLocationsContext } from 'internal/business-locations/BusinessLocationsContext';

interface IBusinessLocationMapFiltersProps {
    onSearch: (filters: BusinessFilterValues) => void;
}

const jobFilterProps = [
    'open_jobs',
    'jobs_posted',
    'no_jobs_posted',
    'jobs_this_week',
    'jobs_next_week',
    'none_this_week',
    'none_next_week',
    'posted_never_transacted',
    'include_snoozed',
];

export default function BusinessLocationMapFilters({ onSearch }: IBusinessLocationMapFiltersProps) {
    const [filters, setFilters] = useState<BusinessFilterValues>({});
    const [appliedFilters, setAppliedFilters] = useState<BusinessFilterValues>({});
    const [showFilters, setShowFilters] = useState(false);
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    const { statuses: statusOptions, staticStatuses: staticStatusOptions } = useBusinessLocationsContext();

    function doFilter() {
        onSearch(filters);
        setAppliedFilters({ ...filters });
        setShowFilters(false);
    }

    function toggleMenu() {
        setShowFilters(!showFilters);
    }

    function onNameChange(val: string) {
        setFilters((prevValue) => {
            return {
                ...prevValue,
                name: val,
            };
        });
    }

    function getFiltersText() {
        const labels = [];

        if (appliedFilters.name) {
            labels.push(`Name: ${appliedFilters.name}`);
        }

        if (appliedFilters.statuses || appliedFilters.static_statuses) {
            const statsuses = appliedFilters.statuses?.map((x) => statusOptions.find((y) => y.id === x)?.name) || [];
            const staticStatsuses =
                appliedFilters.static_statuses?.map((x) => staticStatusOptions.find((y) => y.id === x)?.name) || [];
            labels.push(`Status: ${[...statsuses, ...staticStatsuses].map((x) => x)}`);
        }

        const selectedJobOptions = jobFilterProps
            .filter((x) => appliedFilters[x as keyof BusinessFilterValues])
            .map((x) => x.replaceAll('_', ' '))
            .join(', ');
        if (selectedJobOptions) {
            labels.push(`Options: ${selectedJobOptions}`);
        }

        return labels.join(', ');
    }

    return (
        <>
            <div onClick={(e) => setAnchorEl(e.currentTarget)} style={{ marginTop: 20, marginLeft: 10, width: 300 }}>
                <IconButton icon={Tune} onClick={toggleMenu} />
                <Text variant="caption" textStyle={{ marginTop: 4 }}>
                    {getFiltersText()}
                </Text>
            </div>
            <Popover
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: 'center', horizontal: 'left' }}
                open={showFilters}
                onClose={toggleMenu}
                slotProps={{ paper: { style: { borderRadius: 18, padding: 20 } } }}
            >
                <div style={{ padding: 10, borderRadius: 18, marginBottom: 8, backgroundColor: Colors.lightTurq }}>
                    <OutlinedTextInput
                        label="Search"
                        value={filters.name || ''}
                        onChange={(e) => onNameChange(e.target.value)}
                        helperText="Business or user name, email, or part of phone number"
                        multiline={false}
                    />
                </div>
                <div style={{ padding: 10, borderRadius: 18, marginBottom: 8, backgroundColor: Colors.lightTurq }}>
                    <Text variant="body2" bold>
                        Status Filters
                    </Text>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <BusinessLocationStatusSelect
                            statuses={filters.statuses}
                            staticStatuses={filters.static_statuses}
                            direction="column"
                            setStatuses={(statuses) => setFilters({ ...filters, statuses })}
                            setStaticStatuses={(static_statuses) => setFilters({ ...filters, static_statuses })}
                        />
                    </div>
                </div>
                <div style={{ padding: 10, borderRadius: 18, marginBottom: 8, backgroundColor: Colors.lightTurq }}>
                    <Text variant="body2" bold>
                        Status Filters
                    </Text>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <BusinessLocationJobFilters
                            filters={filters}
                            setFilters={(vals) => setFilters({ ...filters, ...vals })}
                        />
                    </div>
                </div>
                <PrimaryButton rightAlign onClick={doFilter}>
                    Done
                </PrimaryButton>
            </Popover>
        </>
    );
}
