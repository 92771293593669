import { Link } from 'library';
import { useJobFormContext } from 'parent-portal/forms/JobFormContext';
import React from 'react';

export default function MoreOptions() {
    const { toggleSimpleForm } = useJobFormContext();

    function goToLongForm() {
        toggleSimpleForm();
    }

    return <Link onClick={goToLongForm}>More Options</Link>;
}
