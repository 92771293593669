import { PrimaryButton } from 'library';
import AddressBlockerDialog from 'parent-portal/blockers/SupportedRegions/AddressBlocker/AddressBlockerDialog';
import React, { useState } from 'react';
import { client } from 'shared';

interface IProps {
    onClick: () => void;
    address: {
        street: string;
        city: string;
        state: string;
        zip_code: string;
    };
}
export default function AccountEditSaveButton({ onClick, address }: IProps) {
    const [openAddressBlocker, setOpenAddressBlocker] = useState(false);
    const [loading, setLoading] = useState(false);
    function handleOnClick() {
        setLoading(true);
        client('api/addresses/verify-supported/', {
            body: address,
        })
            .then((res) => {
                if (res.supported) {
                    onClick();
                    setLoading(false);
                } else {
                    setOpenAddressBlocker(true);
                }
            })
            .catch(() => {
                onClick();
            });
    }

    return (
        <>
            <PrimaryButton onClick={handleOnClick} loading={loading}>
                Save
            </PrimaryButton>
            <AddressBlockerDialog
                open={openAddressBlocker}
                onClose={() => {
                    setLoading(false);
                    setOpenAddressBlocker(false);
                }}
                onConfirm={() => {
                    setLoading(false);
                    setOpenAddressBlocker(false);
                    onClick();
                }}
            />
        </>
    );
}
