import React from 'react';
import BubbleButton from './BubbleButton';
import { Colors } from '../../theme/Colors';
import { ButtonProps } from 'models';

const defaultStyle = {
    text: {
        color: Colors.darkNavy,
        paddingTop: 3,
        paddingBottom: 3,
    },
    button: {
        outline: 'none',
        '&:focus': {
            outline: 'none',
        },
    },
    outline: 'none',
};

const enabledStyle = {
    button: {
        backgroundColor: Colors.white,
        '&:hover': {
            backgroundColor: Colors.pressedNavy,
        },
    },
};

const disabledStyle = {
    button: {
        backgroundColor: Colors.disabledGrey,
    },
};

export default function SecondaryButton({ children, boldText = true, ...props }: ButtonProps) {
    return (
        <BubbleButton
            defaultStyle={defaultStyle}
            enabledStyle={enabledStyle}
            disabledStyle={disabledStyle}
            boldText={boldText}
            {...props}
        >
            {children}
        </BubbleButton>
    );
}
