import { Grid } from '@mui/material';
import { DocumentToVerify, FormDetails } from '../models';
import React from 'react';
import EditDocumentType from './EditDocumentType';

export default function EditFormDetails({
    form,
    formDetails,
    updateFormDetails,
}: {
    form: DocumentToVerify;
    formDetails: FormDetails;
    updateFormDetails: (key: keyof FormDetails, value: number) => void;
}) {
    function updateFormDetailsByKey(key: keyof FormDetails) {
        return (v: number) => updateFormDetails(key, v);
    }
    return (
        <Grid item container xs={12} md={5}>
            <EditDocumentType
                form={form}
                currentDocTypeId={formDetails.document_type_id}
                updateDocType={updateFormDetailsByKey('document_type_id')}
            />
        </Grid>
    );
}
