import React from 'react';
import { Grid, styled } from '@mui/material';
import AutoFixNormalIcon from '@mui/icons-material/AutoFixNormal';
import { Text, Colors, TextButton, IconButton } from 'library';
import { PricingPlan } from 'models';
import { client, getTotalHourlyAmountWithFee } from 'shared';

import PaySlider from '../../PaySlider';
import { IManagedScale } from './BusinessPayInputs';

interface BusinessPayInputHeaderProps {
    payMin: number;
    payMax: number;
    setPayMin: (value: number) => void;
    setPayMax: (value: number) => void;
    pricingPlan: PricingPlan;
    onUseTandemsScales: (scales: IManagedScale[]) => void;
}

export default function BusinessPayInputHeader({
    payMin,
    payMax,
    setPayMin,
    setPayMax,
    pricingPlan,
    onUseTandemsScales,
}: BusinessPayInputHeaderProps) {
    function onDefaultChange() {
        client('api/pay-scale/recommended/').then((response: IManagedScale[]) => {
            onUseTandemsScales(response);
        });
    }

    function onPayRateChange(event: React.ChangeEvent<HTMLInputElement>, newValue: number | number[]) {
        if (typeof newValue === 'number') {
            setPayMin(newValue);
            setPayMax(newValue);
        } else {
            setPayMin(newValue[0]);
            setPayMax(newValue[1]);
        }
    }

    const minTotalWithFee = getTotalHourlyAmountWithFee(payMin, pricingPlan);
    const maxTotalWithFee = getTotalHourlyAmountWithFee(payMax, pricingPlan);

    return (
        <Grid container item style={{ width: '75%' }}>
            <Text color={Colors.turquoise} bold textStyle={{ marginBottom: 20, marginTop: 30 }}>
                Pay
            </Text>
            <PaySlider
                payRateTip={`Enter the rate you want to pay the worker before Tandem's fee. Your total pay will be $${minTotalWithFee.toFixed(
                    2,
                )}/hr - $${maxTotalWithFee.toFixed(2)}/hr with this scale`}
                value={[payMin, payMax]}
                onPayRateChange={onPayRateChange}
                isRange={true}
            />
            <DefaultScalesContainer container direction="row" alignItems="center">
                <IconButton icon={AutoFixNormalIcon} onClick={onDefaultChange} />

                <HelpTextContainer item>
                    <Text variant="caption" bold>
                        Need help?
                    </Text>
                    <TextButton
                        onClick={onDefaultChange}
                        buttonStyle={{ width: undefined }}
                        textStyle={{ fontSize: 11 }}
                    >
                        Click to set pay scales to Tandem&apos;s recommended?
                    </TextButton>
                </HelpTextContainer>
            </DefaultScalesContainer>
        </Grid>
    );
}

const DefaultScalesContainer = styled(Grid)({
    margin: '5px 0',
});

const HelpTextContainer = styled(Grid)({
    margin: '0 5px',
});
