import { Checkbox, TextButton } from 'library';
import React, { useState } from 'react';
import { DayOfWeekTimesSelection } from './duplicateJobModels';
import ScheduledDay from './ScheduledDay';

export default function WeeklySchedule({
    times,
    week,
    updateTimes,
}: {
    times: DayOfWeekTimesSelection[];
    updateTimes: (times: DayOfWeekTimesSelection[]) => void;
    week?: Date;
}) {
    const [allDays, setAllDays] = useState(true);
    const [showWeekend, setShowWeekend] = useState(false);

    function updateTimesForDay(dayToUpdate: DayOfWeekTimesSelection) {
        const { day, start, end, selected } = dayToUpdate;
        const newTimes = Array.from(times);
        const index = times.findIndex((time) => time.day === day);
        if (allDays) {
            newTimes.forEach((time) => {
                time.start = start;
                time.end = end;
            });
            newTimes[index].selected = selected;
        } else {
            newTimes[index] = { day, start, end, selected };
        }
        updateTimes(newTimes);
    }

    return (
        <>
            <Checkbox label="Apply time changes to all days" checked={allDays} onChange={setAllDays} />
            {times.slice(0, 5).map((day, index) => (
                <ScheduledDay key={index} day={day} update={updateTimesForDay} week={week} />
            ))}
            <TextButton buttonStyle={{ width: undefined }} onClick={() => setShowWeekend(!showWeekend)}>
                {showWeekend ? 'Hide' : 'Show'} Weekend
            </TextButton>
            {showWeekend &&
                times.slice(5).map((day, index) => <ScheduledDay key={index} day={day} update={updateTimesForDay} />)}
        </>
    );
}
