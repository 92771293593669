import React from 'react';
import SendIcon from '@mui/icons-material/Send';
import { IconButton } from 'library';

const GOOGLE_MAIL_URL = 'https://mail.google.com/mail/u/0/#inbox';

export default function EmailThreadButton({ messageId }: { messageId?: string }) {
    const url = `${GOOGLE_MAIL_URL}/${messageId}`;
    const sendEmail = () => {
        window.open(url, '_blank');
    };

    return <IconButton icon={SendIcon} onClick={sendEmail} />;
}
