import React from 'react';
import { Grid } from '@mui/material';
import { Colors, Text } from 'library';
import Blob from 'assets/images/on-demand/terracotta-blob-left.png';
import MobileBlob from 'assets/images/on-demand/light-terracotta-blob-mobile.png';
import { ChevronRight } from '@mui/icons-material';
import TheTandemSolutionStepper from './TheTandemSolutionStepper';

export default function FixingTheSystemSection({ smDown }: { smDown: boolean }) {
    const variant = smDown ? 'body1' : 'h2';
    const styles = {
        container: {
            marginTop: smDown ? 30 : 80,
            position: 'relative',
            marginBottom: smDown ? 0 : '5%',
        },
        textBox: {
            backgroundColor: smDown ? 'transparent' : Colors.lightTurq,
            borderRadius: 50,
            padding: 40,
            marginLeft: smDown ? 0 : 220,
            marginRight: smDown ? 0 : '10%',
        },
        image: { position: 'absolute', top: -40, left: 40, zIndex: -1 },
        mobileImage: { position: 'absolute', top: 120, right: 0, zIndex: -1 },
        solution: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            backgroundColor: smDown ? Colors.mediumTurq : Colors.turquoise,
            height: 50,
            width: 240,
            padding: 20,
            paddingRight: 10,
            borderRadius: 40,
            marginLeft: smDown ? 40 : 320,
            marginTop: smDown ? 0 : 30,
        },
        chevron: {
            backgroundColor: smDown ? Colors.darkNavy : Colors.white,
            width: 36,
            height: 36,
            borderRadius: 25,
            display: 'flex',
            alignItems: 'ceneter',
            justifyContent: 'center',
        },
    };

    const steps = [
        {
            key: 'worker',
            label: (
                <Text variant={variant} bold>
                    Credentialed{' '}
                    <Text variant={variant} inline bold color={Colors.turquoise}>
                        Childcare Worker{' '}
                    </Text>
                    looking for work.
                </Text>
            ),
        },
        {
            key: 'tandem',
            label: (
                <Text variant={variant} bold color={Colors.turquoise}>
                    Tandem{' '}
                    <Text variant={variant} inline bold>
                        facilitates the match.
                    </Text>
                </Text>
            ),
        },
        {
            key: 'business',
            label: (
                <Text variant={variant} bold color={Colors.turquoise}>
                    Child Care Center{' '}
                    <Text variant={variant} inline bold>
                        looking for staff.
                    </Text>
                </Text>
            ),
        },
    ];

    return (
        <Grid container direction="row" justifyContent="flex-start" style={styles.container as React.CSSProperties}>
            {smDown ? null : (
                <div style={styles.image as React.CSSProperties}>
                    <img src={Blob} alt="colored blob" />
                </div>
            )}
            <Grid container direction="column">
                <Grid item xs={12} md={6} style={styles.textBox}>
                    <Text variant="display">
                        We are revitalizing a{' '}
                        <span style={{ color: smDown ? Colors.terraCotta : Colors.darkNavy }}>failing</span> system.
                    </Text>
                    <Text variant={'body1'} textStyle={{ marginTop: 20 }}>
                        The struggle for child care centers to find and retain staff is causing burnout, closures, and
                        extreme cuts to enrollment. <b>We see this, we hear this, we care about this.</b>
                    </Text>
                </Grid>
            </Grid>
            <Grid item style={styles.solution as React.CSSProperties}>
                <Text variant="h2" bold color={smDown ? Colors.darkNavy : Colors.white}>
                    Our Solution
                </Text>
                <div style={styles.chevron}>
                    <ChevronRight htmlColor={smDown ? Colors.mediumTurq : Colors.turquoise} fontSize="large" />
                </div>
            </Grid>
            <TheTandemSolutionStepper smDown={smDown} steps={steps} />
            {smDown ? (
                <div style={styles.mobileImage as React.CSSProperties}>
                    <img src={MobileBlob} alt="colored blob" />
                </div>
            ) : null}
        </Grid>
    );
}
