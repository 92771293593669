import React from 'react';
import { AccessTime, Cake } from '@material-ui/icons';
import { Provider } from 'models';
import ProviderSummaryDetailRow from './ProviderSummaryDetailRow';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSchool, faMapMarker, faCalendarCheck } from '@fortawesome/free-solid-svg-icons';

export default function FamilyProviderSummary({
    provider,
    distanceText,
}: {
    provider: Provider;
    distanceText: string;
}) {
    const details = [
        {
            detail: !!provider.user.address
                ? `${provider.user.address[0].city.trim()}, ${provider.user.address[0].state}`
                : '',
            subDetail: distanceText,
            icon: <FontAwesomeIcon icon={faMapMarker} style={{ fontSize: 10 }} />,
        },
        { detail: provider.user.last_active, icon: <AccessTime style={{ fontSize: 16 }} />, italic: true },
        {
            detail: `${provider.job_count} job${provider.job_count === 1 ? '' : 's'} completed`,
            icon: <FontAwesomeIcon icon={faCalendarCheck} style={{ fontSize: 10 }} />,
        },
        { detail: `${provider.age} years old`, icon: <Cake style={{ fontSize: 14 }} /> },
        { detail: provider.school, icon: <FontAwesomeIcon icon={faSchool} style={{ fontSize: 10 }} /> },
    ];

    return (
        <div style={{ paddingLeft: 20, paddingRight: 20 }}>
            {details.map((detail) => (!!detail.detail ? <ProviderSummaryDetailRow {...detail} /> : null))}
        </div>
    );
}
