import React from 'react';
import { useHistory } from 'react-router-dom';
import { useOnboardingContext } from '../OnboardingContext';
import SimpleOption from './SimpleOption';

export default function LookAroundOption() {
    const { submitAction } = useOnboardingContext();
    const history = useHistory();

    function lookAroundAction() {
        submitAction(() => history.push('/home'), 'dashboard', "I'd like to take a look around first.");
    }

    return (
        <SimpleOption
            id="get-started-dashboard-option"
            title="I'd like to take a look around first."
            highlight="Take me to my dashboard."
            action={lookAroundAction}
        />
    );
}
