import React from 'react';
import { Table, TableBody, TableCell, TableFooter, TableHead, TablePagination, TableRow } from '@material-ui/core';
import { Delete } from '@mui/icons-material';
import { PagingResult, Staff } from 'models';
import { IconButton, Text } from 'library';

export default function StaffInfoModal({
    staff,
    pageNumber,
    onPageChange,
    onDelete,
}: {
    staff?: PagingResult<Staff>;
    pageNumber: number;
    onPageChange: (newPage: number) => void;
    onDelete: (id: number) => void;
}) {
    return (
        <>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Bus (Id)</TableCell>
                        <TableCell>Bus Loc (Id)</TableCell>
                        <TableCell>Bci Submitted</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {staff?.results.map((x) => (
                        <TableRow key={x.id}>
                            <TableCell>
                                <Text variant="body2">
                                    {x.business.name} ({x.business.id})
                                </Text>
                            </TableCell>
                            <TableCell>
                                <Text variant="body2">
                                    {x.business_location?.name} ({x.business_location?.id})
                                </Text>
                            </TableCell>
                            <TableCell>
                                <Text variant="body2">{x.bci_fbi_submitted ? 'Yes' : 'No'}</Text>
                            </TableCell>
                            <TableCell>
                                <IconButton icon={Delete} onClick={() => onDelete(x.id)} />
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TableCell colSpan={3}>
                            <TablePagination
                                component="div"
                                count={staff?.count || 0}
                                rowsPerPage={staff?.per_page || 25}
                                page={pageNumber - 1}
                                onChangePage={(e, newPage) => onPageChange(newPage + 1)}
                                rowsPerPageOptions={[]}
                            />
                        </TableCell>
                    </TableRow>
                </TableFooter>
            </Table>
        </>
    );
}
