import React from 'react';
import { Grid } from '@material-ui/core';
import { DeleteForever, Edit } from '@mui/icons-material';
import { Chip, Colors, IconButton, Text } from 'library';
import { CalendarColors } from '../BusinessCalendar/HiredShift';
import { useUserContext } from 'UserContext';

export default function BusinessJobHeader({
    businessJobType,
    headline,
    onEdit,
    openCancel,
}: {
    businessJobType?: string;
    headline: string;
    onEdit: () => void;
    openCancel: () => void;
}) {
    const { getDisplayText } = useUserContext();

    return (
        <Grid xs={12} direction="row" item container alignItems="center" justify="space-between">
            <Grid xs={10} item container direction="row" alignItems="center">
                <Text variant="display">{headline}</Text>
                <IconButton style={{ margin: '0 10px' }} icon={Edit} onClick={onEdit} />
                {businessJobType ? (
                    <Chip
                        label={getDisplayText(businessJobType.replace('_', ' ').toLowerCase())}
                        style={{
                            textTransform: 'capitalize',
                        }}
                        backgroundColor={CalendarColors[businessJobType ?? ''] ?? Colors.lightTurq}
                    />
                ) : null}
            </Grid>
            <Text
                textStyle={{ textDecorationLine: 'underline', cursor: 'pointer', justifySelf: 'flex-end' }}
                onClick={openCancel}
            >
                <DeleteForever />
                Cancel Job
            </Text>
        </Grid>
    );
}
