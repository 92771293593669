import React, { useState } from 'react';
import EditableInfo from './EditableInfo';
import BusinessPreferenceInputs from './BusinessPreferences/BusinessPreferenceInputs';
import useLoading from 'library/hooks/useLoading';
import { updateBusinessPreference } from 'api/BusinessPreferenceApi';
import { BreakPreference, EditBusinessPreference } from './BusinessPreferences/models';
import { consoleLogInDev } from 'shared';

export default function BusinessPreferences({
    businessPreference,
    breakPreferenceOptions,
}: {
    businessPreference: EditBusinessPreference;
    breakPreferenceOptions: BreakPreference[];
}) {
    const [editingMain, setEditingMain] = useState(false);
    const [saveDisabled, setSaveDisabled] = useState(true);
    const [selectedPreference, setSelectedPreference] = useState<EditBusinessPreference>({ ...businessPreference });
    const [originalPreference, setOriginalPreference] = useState<EditBusinessPreference>(businessPreference);
    const { loading, setLoading } = useLoading();
    const preferenceId = selectedPreference.id;

    function updatePreference(p: EditBusinessPreference) {
        setSelectedPreference(p);
    }

    function updateEditing(editing: boolean) {
        if (!editing) {
            setSelectedPreference({ ...originalPreference });
        }
        setEditingMain(editing);
    }

    function submit() {
        setLoading(true);
        updateBusinessPreference(selectedPreference, preferenceId !== -1 ? preferenceId : undefined)
            .then(() => {
                const submittedPreference = { ...selectedPreference };
                setOriginalPreference(submittedPreference);
                setSelectedPreference(submittedPreference);
                setEditingMain(false);
            })
            .catch(consoleLogInDev)
            .finally(() => {
                setLoading(false);
            });
    }

    const business_wide_wording = `These will be used if you don't set specific preferences for a location. Location preferences can be set by editing the business location.`;

    const newLocationWording =
        'This location is using the business wide defaults, edit below to create location specific preferences.';
    const locationWording = preferenceId === -1 ? newLocationWording : 'These are the preferences for this location.';

    const subtitle = businessPreference.business ? business_wide_wording : locationWording;

    const title = businessPreference.business ? 'Default Business Preferences' : 'Location Preferences';

    return (
        <EditableInfo
            title={title}
            editing={editingMain}
            updateEditing={updateEditing}
            save={submit}
            saveDisabled={saveDisabled || loading}
            subtitle={subtitle}
            children={
                <BusinessPreferenceInputs
                    originalPreference={originalPreference}
                    preference={selectedPreference}
                    updatePreference={updatePreference}
                    editing={editingMain}
                    breakPreferenceOptions={breakPreferenceOptions}
                    setSaveDisabled={setSaveDisabled}
                />
            }
        />
    );
}
