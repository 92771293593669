import { client } from 'shared';

export function upcomingJobs() {
    return client('api/upcoming-jobs/');
}

export function hireProviderForJob(body: any, jobId: number) {
    return client(`api/select-sitter/${jobId}`, {
        method: 'PATCH',
        body: body,
    });
}
