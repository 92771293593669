import TrialRunImage from 'assets/images/marketing-site/trial-runs/trial-run-main.png';
import React from 'react';
import { Text, Colors, TextButton } from 'library';
import { Grid, useMediaQuery } from '@mui/material';
import TrialRunBg from 'assets/images/marketing-site/trial-runs/trial-run-main-bg.png';
import theme from 'theme';

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
const innerContainerStyle = {
    padding: 50,
};

export default function TrialHeader({
    signUp,
}: {
    signUp: (functionLocation: string, extraData?: any, message?: string, subtitle?: string) => void;
}) {
    const smDown = useMediaQuery(theme.breakpoints.down('sm'));
    const containerStyle = {
        backgroundImage: `url(${TrialRunBg})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        minHeight: '90vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        paddingBottom: smDown ? 40 : 0,
    };
    const bigCircleStyle: React.CSSProperties = {
        width: smDown ? '250px' : '550px',
        height: smDown ? '250px' : '550px',
        backgroundColor: '#F9D0DC',
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: -3,
        position: 'absolute',
        right: '10px',
        bottom: smDown ? '-24vh' : undefined,
    };
    return (
        <div style={containerStyle}>
            <Grid container style={innerContainerStyle} alignItems="center">
                <Grid item xs={12} md={6} container direction="column" spacing={2}>
                    <Grid item>
                        <Text variant="h1" bold textStyle={{ color: Colors.turquoise }}>
                            The new approach to permanent staffing:
                        </Text>
                    </Grid>
                    <Grid item>
                        <Text variant="display" textStyle={{ marginTop: 20 }}>
                            Find mutual fit before you commit.
                        </Text>
                    </Grid>
                    <Grid item>
                        <Text variant="body1" textStyle={{ marginTop: 20 }}>
                            Trial Runs allow you to avoid costly upfront hiring expenses and decrease time-intensive
                            recruiting efforts. Tandem facilitates the Trial Run placement, and your only job is to
                            determine if the worker is the right fit.
                        </Text>
                    </Grid>
                    <Grid item>
                        <TextButton
                            onClick={() => signUp('trial run header')}
                            buttonStyle={{ width: 200 }}
                            textStyle={{ textAlign: 'left', width: '100%', fontWeight: 'bold' }}
                        >
                            <Text variant="h2" bold>
                                Start Now <ArrowForwardIosIcon style={{ color: Colors.darkNavy, fontSize: 18 }} />
                            </Text>
                        </TextButton>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                    <img
                        src={TrialRunImage}
                        alt="Trial Run"
                        style={{ width: smDown ? '95%' : '88%', height: 'auto' }}
                    />
                </Grid>
                <div style={bigCircleStyle}></div>
            </Grid>
        </div>
    );
}
