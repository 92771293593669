import React from 'react';
import { SubJobAppointment } from 'models';
import SubJobAppointmentEntry from './SubJobAppointmentEntry';

export default function SubJobAppointments({
    appts,
    businessLocationId,
    updateFlag,
    refreshJobs,
}: {
    appts: SubJobAppointment[];
    businessLocationId?: number;
    updateFlag: (staffId: number, businessLocationId: number) => void;
    refreshJobs: () => void;
}) {
    return (
        <>
            {appts.length > 0
                ? appts.map((appt) => (
                      <SubJobAppointmentEntry
                          key={appt.id}
                          appt={appt}
                          businessLocationId={businessLocationId}
                          updateFlag={updateFlag}
                          refreshJobs={refreshJobs}
                      />
                  ))
                : null}
        </>
    );
}
