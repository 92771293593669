import { Grid } from '@mui/material';
import Colors from 'constants/Colors';
import { Highlight, Text } from 'library';
import React from 'react';
import CircleNumber from '../../components/CircleNumber';

export default function Schedule() {
    function highlightText(text: string) {
        return <Highlight color={Colors.lightTurq}>{text}</Highlight>;
    }

    const details = [
        {
            title: highlightText('All-in-one'),
            subtitle: 'Become a compliant worker in your state of residence without the hassle.',
        },
        {
            title: highlightText('Flexible'),
            subtitle: "Work as much or as little as you'd like. Try out different centers and find your fit.",
        },
        {
            title: highlightText('Daily Pay'),
            subtitle: 'You work hard for your pay, we believe you should receive it the same day.',
        },
    ];
    return (
        <Grid item container flexDirection="row" xs={12} style={{ padding: 40 }} justifyContent="space-around">
            <Grid item container xs={12} md={4} style={{ padding: 20 }} justifyContent="center" alignItems="center">
                <Text variant="display">
                    97% of workers value schedule control. 4 out of 5 workers prefer{' '}
                    {highlightText('immediate payment')}.
                </Text>
            </Grid>
            <Grid
                item
                container
                style={{ gap: 40, padding: 20, boxShadow: '0px 3px 6px #00000029', borderRadius: 18 }}
                alignItems="center"
                sm={12}
                md={4}
                lg={3}
            >
                {details.map((detail, index) => (
                    <Grid
                        item
                        container
                        xs={12}
                        flexDirection="column"
                        justifyContent="center"
                        alignItems="center"
                        style={{ gap: 10 }}
                    >
                        <CircleNumber index={index} />
                        <Text variant="h2" bold>
                            {detail.title}
                        </Text>
                        <Text textStyle={{ textAlign: 'center' }}>{detail.subtitle}</Text>
                    </Grid>
                ))}
            </Grid>
        </Grid>
    );
}
