import React, { useEffect, useState } from 'react';
import { StyledIconButton } from 'reusableComponents/styledItems';
import { Colors, Text } from 'library';
import { useHistory } from 'react-router-dom';
import { NavItem, updateNavigation } from './navigationItems';
import BadgedNavItem from './BadgedNavItem';
import { getMessageCount } from './messageCount';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import usePublicSessionTracking, { PublicSessionEventType } from 'parent-portal/shared/PublicSessionTracking';
import { FeatureFlag, useFeatureFlag } from 'shared';

interface NavigationItemProps {
    item: NavItem;
    selected: boolean;
    setSelectedPage: (selectedPage: number) => void;
    navigationItems: NavItem[];
}

const sizes = {
    width: 30,
    top: 46,
    font: 12,
    fontAwesome: 25,
};

const NavigationItem = (props: NavigationItemProps) => {
    const history = useHistory();
    const { item, selected, setSelectedPage, navigationItems } = props;
    const { name, IconImg, SvgIconImg, FontAwesomeImg } = item;
    const [unreadMessages, setUnreadMessages] = useState(0);
    const { trackSession } = usePublicSessionTracking('Top Navigation - Web');
    const { featureActive: chatwootEnabled } = useFeatureFlag(FeatureFlag.CENTER_CHATWOOT_ENABLED);

    useEffect(() => {
        getMessageCount(!chatwootEnabled).then((count) => setUnreadMessages(count));
    }, []);

    const getColor = () => (selected ? Colors.mediumTurq : Colors.white);

    const getIcon = () => (
        <>
            {IconImg && <IconImg htmlColor={getColor()} fontSize="inherit" />}
            {SvgIconImg && <SvgIconImg color={getColor()} width={sizes.width} height={sizes.width} />}
            {FontAwesomeImg && (
                <FontAwesomeIcon
                    icon={FontAwesomeImg}
                    color={getColor()}
                    style={{ fontSize: sizes.fontAwesome, padding: 3 }}
                />
            )}
        </>
    );

    const handleNavigation = () => {
        updateNavigation(history, item, setSelectedPage, navigationItems);
        trackSession('CLICK', item.name, PublicSessionEventType.User);
    };

    return (
        <StyledIconButton
            key={item.name}
            style={{ marginLeft: 15, marginRight: 15, alignItems: 'center' }}
            onClick={handleNavigation}
            id={item.id}
        >
            <div style={{ flexDirection: 'column', display: 'flex', alignItems: 'center', fontSize: sizes.width }}>
                {name === 'Messages' ? <BadgedNavItem count={unreadMessages}>{getIcon()}</BadgedNavItem> : getIcon()}

                <Text
                    variant="caption"
                    color={getColor()}
                    textStyle={{
                        whiteSpace: 'nowrap',
                        textAlign: 'center',
                        lineHeight: 1,
                        padding: 0,
                        fontSize: sizes.font,
                    }}
                >
                    {name}
                </Text>
                {selected && (
                    <div
                        style={{
                            position: 'absolute',
                            top: sizes.top,
                            height: 2,
                            width: sizes.width,
                            backgroundColor: Colors.mediumTurq,
                        }}
                    />
                )}
            </div>
        </StyledIconButton>
    );
};

export default NavigationItem;
