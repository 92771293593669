import { client } from 'shared';
import { IPayload } from './types';
export async function updateHourReport(reportId: number, body: IPayload) {
    return client(`api/hour-report/${reportId}/`, {
        method: 'PATCH',
        body,
    });
}
export async function createHourReport(body: IPayload) {
    return client(`api/hour-report/clock-in/`, {
        method: 'POST',
        body,
    });
}
