import React from 'react';
import { IBusinessLocation } from 'models';
import { Colors, Text } from 'library';
import { Grid, styled } from '@mui/material';
import EducationTypesSection from './EducationTypesSection';
import LocationPhotoSection from './LocationPhotoSection';
import LocationAdministrationSection from './LocationAdministrationSection';
import { saveBusinessLocation } from '../businessLocationProfileApi';
import EditableValue from './EditableValue';
import { isNumber } from 'shared';

export default function BusinessLocationDetailsSection({
    businessLocation,
    updateLocation,
    refreshLocation,
}: {
    businessLocation: IBusinessLocation;
    updateLocation: (loc: IBusinessLocation) => void;
    refreshLocation: () => void;
}) {
    const id = businessLocation.id;
    const saveCapacity = (capacity: string) =>
        saveBusinessLocation(id, { capacity: parseInt(capacity) ? parseInt(capacity) : null }).then(updateLocation);
    const saveWebsite = (website: string) => saveBusinessLocation(id, { website }).then(updateLocation);

    return (
        <Container container item direction="column">
            <Text variant="h1" textStyle={{ marginBottom: 10 }}>
                Center Details
            </Text>
            <LocationPhotoSection
                locationId={id}
                refreshLocation={refreshLocation}
                photos={businessLocation.location_photos}
            />
            <LocationAdministrationSection
                locationId={id}
                locationAdministration={businessLocation.location_administration}
                refreshLocation={refreshLocation}
            />
            <EditableValue
                title="Capacity"
                existingValue={businessLocation.capacity?.toString() || ''}
                onSave={saveCapacity}
                isValid={isNumber}
                errorMessage="Enter a number"
            />
            <EditableValue title="Website" existingValue={businessLocation.website} onSave={saveWebsite} />
            <EducationTypesSection
                locationId={id}
                educationTypes={businessLocation.education_type_names}
                updateLocation={updateLocation}
            />
        </Container>
    );
}

const Container = styled(Grid)({
    backgroundColor: Colors.white,
    borderRadius: 18,
    padding: 20,
    gap: 30,
});
