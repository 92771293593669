import React from 'react';
import { Colors, Paper, Text } from 'library';

export default function ProviderProfileTitledContainer({
    title,
    subtitle,
    children,
}: {
    title: string;
    subtitle?: string;
    children: React.ReactNode;
}) {
    return (
        <Paper
            style={{
                marginTop: 20,
                backgroundColor: Colors.lightTurq,
                border: `5px solid ${Colors.white}`,
                padding: 0,
            }}
        >
            <div style={{ padding: 30, paddingBottom: 20 }}>
                <Text variant="h2" bold textStyle={{ marginBottom: 12 }}>
                    {title}
                </Text>
                {subtitle && <Text variant="body2">{subtitle}</Text>}
            </div>
            <div
                style={{
                    backgroundColor: Colors.white,
                    padding: 30,
                    borderTopRightRadius: 18,
                    borderTopLeftRadius: 18,
                }}
            >
                {children}
            </div>
        </Paper>
    );
}
