import { withStyles } from '@material-ui/core/styles';
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import theme from '../theme';

const CurrencyInput = withStyles({
    root: (props) => ({
        marginBottom: props.noMargin ? 0 : 20,
        borderColor: theme.palette.primary.main,
        '& label.Mui-focused': {
            color: theme.palette.primary.main,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: theme.palette.primary.main,
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: theme.palette.primary.main,
            },
            '&:hover fieldset': {
                borderColor: theme.palette.primary.main,
            },
            '&.Mui-focused fieldset': {
                borderColor: theme.palette.primary.main,
            },
        },
        '& .MuiTextField-root': {
            '& fieldset': {
                borderColor: theme.palette.primary.main,
            },
            '&:hover fieldset': {
                borderColor: theme.palette.primary.main,
            },
            '&.Mui-focused fieldset': {
                borderColor: theme.palette.primary.main,
            },
        },
        '&:hover:not($disabled):after': {
            borderColor: theme.palette.primary.main,
        },
        '&:hover:not($disabled):before': {
            borderColor: theme.palette.primary.main,
        },
    }),
})(CurrencyTextField);

export default CurrencyInput;
