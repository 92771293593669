import React from 'react';
import { Link } from 'react-router-dom';
import { Typography } from '@material-ui/core';
import LoadingButton from '../../../reusableComponents/loadingButton';
import { useUserContext } from '../../../UserContext';
import PaymentInformation from '../../../models/PaymentInformation';

interface PayButtonProps {
    onClick: () => void;
    disabled?: boolean;
    loading?: boolean;
    style?: any;
    children?: React.ReactNode;
}

const PayButton: React.FC<PayButtonProps> = ({ children, onClick, disabled, loading, style }) => {
    const { user } = useUserContext();

    const paymentSetup = user?.payment_information;
    const isPaymentNotSetup = !paymentSetup?.length;
    const hasMicrodepositsToVerify =
        !!paymentSetup &&
        paymentSetup.length > 0 &&
        paymentSetup.some((x: PaymentInformation) => x.status === 'NEW' && x.is_default);

    const disableForNoPay = isPaymentNotSetup || hasMicrodepositsToVerify;

    const getSetupLabel = () => {
        if (disableForNoPay) {
            if (isPaymentNotSetup) {
                return <Link to="/payments">Set up payment before paying</Link>;
            }

            if (hasMicrodepositsToVerify) {
                return (
                    <Typography variant="subtitle2">
                        Your payment method needs verified. You should have recieved 2 deposits in you account under $1.
                        Enter the amounts on the <Link to="/payments">payment page</Link> to enable payment.
                    </Typography>
                );
            }
        }
    };

    return (
        <>
            {getSetupLabel()}
            <LoadingButton
                disabled={disableForNoPay || disabled}
                loading={loading}
                disableRipple
                disableFocusRipple
                onClick={onClick}
            >
                {children}
            </LoadingButton>
        </>
    );
};

export default PayButton;
