import React from 'react';
import { Grid } from '@material-ui/core';
import { Text, Colors } from 'library';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';

export default function ApplicantStatusToggle({ activeElement, setActiveElement }: any) {
    const options = [
        {
            active: activeElement === 'HIDDEN',
            status: 'Hidden',
            icon: (
                <VisibilityOffIcon
                    style={{ fontSize: 15, color: activeElement === 'HIDDEN' ? Colors.white : Colors.darkNavy }}
                />
            ),
            text: 'Hidden',
        },
        {
            active: activeElement === 'UNDECIDED',
            status: 'Undecided',
            icon: (
                <VisibilityIcon
                    style={{ fontSize: 15, color: activeElement === 'UNDECIDED' ? Colors.white : Colors.darkNavy }}
                />
            ),
            text: 'To Review',
        },
        {
            active: activeElement === 'LIKED',
            status: 'Liked',
            icon: (
                <ThumbUpIcon
                    style={{
                        fontSize: 15,
                        color: activeElement === 'LIKED' ? Colors.white : Colors.darkNavy,
                    }}
                />
            ),
            text: 'Liked',
        },
    ];
    function handleUndecidedLikedHiddenTabClick(elementClickedName: string) {
        setActiveElement(elementClickedName as 'LIKED' | 'HIDDEN' | 'UNDECIDED');
    }
    return (
        <Grid
            container
            style={{
                marginBottom: 20,
                borderTop: `2px solid ${Colors.darkNavy}`,
                borderBottom: `2px solid ${Colors.darkNavy}`,
                height: '100%',
                width: '100%',
            }}
        >
            {options.map((o) => (
                <Grid
                    key={o.status}
                    item
                    xs={4}
                    justify="center"
                    style={{
                        textAlign: 'center',
                        backgroundColor: o.active ? Colors.darkNavy : Colors.white,
                        cursor: 'pointer',
                    }}
                    onClick={() => handleUndecidedLikedHiddenTabClick(o.status.toUpperCase())}
                >
                    {o.icon}
                    <Text variant="body1" textStyle={{ color: o.active ? Colors.white : Colors.darkNavy }}>
                        {o.text}
                    </Text>
                </Grid>
            ))}
        </Grid>
    );
}
