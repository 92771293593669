import React from 'react';
import { Colors } from 'library';
import { formatMonthDay, getThisWeeksMonday } from 'shared/Dates';
import { addDays } from 'date-fns';
import StatusChip from './StatusChip';

export default function JobNextWeekChip() {
    const thisWeeksMonday = getThisWeeksMonday();
    const nextWeeksMonday = addDays(thisWeeksMonday, 7);
    const nextWeekLabel = `${formatMonthDay(nextWeeksMonday)} - ${formatMonthDay(addDays(nextWeeksMonday, 6))}`;

    return <StatusChip text="Jobs Next Week" color={Colors.turquoise} title={nextWeekLabel} />;
}
