import { Grid, Snackbar } from '@material-ui/core';
import { Colors } from 'library';
import React from 'react';

type IVariant = 'success' | 'warning' | 'info' | 'error';

interface IProps {
    open: boolean;
    onClose: () => void;
    variant: IVariant;
    children: JSX.Element | JSX.Element[];
}

export default function AlertBar({ open, children, onClose, variant }: IProps) {
    function handleVariant() {
        switch (variant) {
            case 'success':
                return Colors.aqua;
            case 'error':
                return Colors.error;
            default:
                return Colors.darkNavy;
        }
    }

    return (
        <Snackbar
            open={open}
            onClose={onClose}
            autoHideDuration={3000}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
            <Grid
                container
                style={{
                    width: '95vw',
                    backgroundColor: handleVariant(),
                    justifyContent: 'center',
                    padding: 15,
                    boxShadow: '0 7px 30px -10px rgba(150,170,180,0.5)',
                }}
            >
                {children}
            </Grid>
        </Snackbar>
    );
}
