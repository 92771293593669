import React from 'react';
import { Grid } from '@material-ui/core';
import { BasicDialog, Text, PrimaryButton, SecondaryButton } from 'library';
import { client } from 'shared';

interface IProps {
    open: boolean;
    closeModal: () => void;
    doNotCancel: () => void;
    job: number;
}

export default function CancelConfirmationModal({ open, closeModal, doNotCancel, job }: IProps) {
    async function cancelOngoingAppointment() {
        client(`api/ongoing/${job}/cancel-payment-day/`);
        closeModal();
        window.location.reload();
    }

    const modalContents = () => {
        return (
            <Grid>
                <Text variant="body1">
                    Confirming will remove this payment from your unpaid jobs. This should only be used if your provider
                    did not work this day.
                </Text>
                <Grid container direction="row" justify="flex-end" style={{ marginTop: 15 }} spacing={1}>
                    <SecondaryButton onClick={doNotCancel} buttonStyle={{ maxWidth: 200, margin: 5 }}>
                        Never mind
                    </SecondaryButton>
                    <PrimaryButton onClick={cancelOngoingAppointment} buttonStyle={{ maxWidth: 200, margin: 5 }}>
                        Confirm Cancel
                    </PrimaryButton>
                </Grid>
            </Grid>
        );
    };

    return (
        <BasicDialog
            isOpen={open}
            children={modalContents()}
            dialogTitle={'Are you sure you want to cancel?'}
            onClose={closeModal}
        ></BasicDialog>
    );
}
