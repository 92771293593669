import { Grid } from '@mui/material';
import React from 'react';
import { Text, Colors, Highlight } from 'library';
import XCalendar from 'assets/images/marketing-site/XCalendar.svg';
import BabyCarriage from 'assets/images/marketing-site/BabyCarriage.svg';
import Frown from 'assets/images/marketing-site/Frown.svg';

export default function TempStaffSection() {
    const temporaryDetails = [
        {
            title: 'Last Minute Call Offs',
            content:
                "You can request same-day workers when you're in a bind. We utilize a standby list to always know who is available last minute, allowing us to fill your most dire needs.",
            emoji: <Frown height="40px" width="40px" />,
        },
        {
            title: 'Extended Leaves',
            content:
                'We can match you with a worker for an extended period of time when you have a planned leave or gap in coverage. These jobs can last a few weeks or a few months.',
            emoji: <BabyCarriage height="40px" width="40px" />,
        },
        {
            title: 'Time Off',
            content:
                "Let us help you give your permanent staff members the time off that they need. We'll provide temporary coverage so you can stay staffed.",
            emoji: <XCalendar height="40px" width="40px" />,
        },
    ];
    return (
        <Grid xs={12} item container style={{ padding: 40 }} flexDirection="row">
            <Grid
                xs={12}
                md={6}
                style={{ gap: 20, padding: 40 }}
                item
                container
                flexDirection="column"
                justifyContent="center"
            >
                <Text variant="display">
                    Utilizing{' '}
                    <Text inline variant="display">
                        <Highlight color={Colors.lightTurq}>temporary staff</Highlight>
                    </Text>{' '}
                    will keep you in ratio and in compliance.
                </Text>
                <Text variant="h2">
                    Find a substitute teacher through Tandem to fill a gap in a classroom, give your teachers an extra
                    hand, or cover an employee's vacation.
                </Text>
            </Grid>
            <Grid
                xs={12}
                md={6}
                style={{ borderRadius: 18, boxShadow: '0px 3px 6px #00000029', padding: 40, gap: 20 }}
                item
                container
                flexDirection="column"
            >
                {temporaryDetails.map((section, index) => (
                    <Grid key={index} item container flexDirection="column" style={{ gap: 10 }}>
                        {section.emoji}
                        <Text variant="h1">{section.title}</Text>
                        <Text>{section.content}</Text>
                    </Grid>
                ))}
            </Grid>
        </Grid>
    );
}
