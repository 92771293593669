import React from 'react';
import { Link } from 'react-router-dom';
import { Colors, Text } from 'library';
import { Provider } from 'models';
import useGoToProfile from '../actions/goToProfile';
import usePublicSessionTracking, { PublicSessionEventType } from 'parent-portal/shared/PublicSessionTracking';

const ListingName = ({
    provider,
    onGoToProfileClick,
    jobId,
}: {
    provider: Provider;
    onGoToProfileClick?: () => void;
    jobId?: number;
}) => {
    const { getProfilePath, onGoToProfileLinkClicked } = useGoToProfile();
    const { trackSession } = usePublicSessionTracking('Provider Search Card');

    return (
        <Link
            to={getProfilePath(provider, jobId)}
            onClick={() => {
                onGoToProfileLinkClicked(provider, 'Provider Card Click', onGoToProfileClick);
                trackSession('CLICK', 'View Profile', PublicSessionEventType.User, {
                    providerId: provider.id,
                    name: `${provider.user.first_name} ${provider.user.last_name}`,
                });
            }}
            style={{ color: Colors.darkNavy, textDecoration: 'underline' }}
        >
            <Text
                variant="display"
                textStyle={{
                    fontSize: 22,
                    maxWidth: '100%',
                    fontWeight: 900,
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    lineHeight: '36px',
                }}
            >
                {provider.user.first_name} {provider.user.last_name}
            </Text>
        </Link>
    );
};

export default ListingName;
