import { Grid } from '@mui/material';
import { Colors, Text } from 'library';
import React from 'react';

export default function NoEmploymentHistory() {
    return (
        <Grid style={{ padding: 50 }}>
            <Text variant="h2">
                No employment record found, please reach out to{' '}
                <a
                    target="_blank"
                    rel="noreferrer"
                    href="mailto:support@join-tandem.com"
                    style={{ color: Colors.darkNavy, textDecorationLine: 'underline' }}
                >
                    support@join-tandem.com
                </a>{' '}
                for more information.
            </Text>
        </Grid>
    );
}
