import { TableCell } from '@mui/material';
import React from 'react';
import SubAvailability from '../../SubAvailability';
import { AdminListProvider } from 'models';

export default function AvailabilityCell({ provider }: { provider: AdminListProvider }) {
    return (
        <TableCell>
            {provider.sub_availability && (
                <SubAvailability
                    sub_availability={provider.sub_availability}
                    assumed_availability={provider.assumed_availability}
                />
            )}
        </TableCell>
    );
}
