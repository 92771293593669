import React, { ChangeEvent, forwardRef } from 'react';
// eslint-disable-next-line import/named
import { FormControl, InputLabel, MenuItem, Select, SelectProps } from '@material-ui/core';
import { Colors } from '../../theme';

interface DropdownProps extends SelectProps {
    fields: { key: string | number; value: string }[];
    value: string | number | null;
    onChange: (event: ChangeEvent<{ name?: string; value: any }>) => void;
    fullWidth?: boolean;
}

const OutlinedDropdown = forwardRef(function _OutlinedDropdown(
    { fields, value, onChange, style, fullWidth = false, label, ...props }: DropdownProps,
    ref: React.ForwardedRef<HTMLSelectElement>,
) {
    const form_ctrl_style = fullWidth ? { width: '100%' } : { minWidth: 120 };

    return (
        <FormControl variant="outlined" style={form_ctrl_style}>
            {label && !value ? <InputLabel style={{ transform: 'translate(14px, 10px)' }}>{label}</InputLabel> : null}
            <Select
                ref={ref}
                autoWidth
                value={value}
                onChange={onChange}
                style={{
                    padding: 0,
                    borderRadius: 18,
                    borderWidth: 0.1,
                    height: 40,
                    width: '100%',
                    backgroundColor: Colors.white,
                    fontFamily: 'Work Sans',
                    color: Colors.darkNavy,
                    ...style,
                }}
                {...props}
            >
                {fields.map((field) => (
                    <MenuItem
                        key={field.key}
                        value={field.key}
                        style={{ width: '100%', fontFamily: 'Work Sans', color: Colors.darkNavy }}
                    >
                        {field.value}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
});

export default OutlinedDropdown;
