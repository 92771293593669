import { useCallback, useEffect, useState } from 'react';
import { usePlaidLink, PlaidLinkOnEventMetadata, PlaidLinkOnExitMetadata, PlaidLinkError } from 'react-plaid-link';
import { track } from 'shared/Tracking';
import { useUserContext } from 'UserContext';
import ApiClient from '../../../../shared/ApiClient';
import { PlaidAccount, PlaidMetadata } from './PlaidTypes';

export default function usePlaid() {
    const { user } = useUserContext();

    const [linkToken, setLinkToken] = useState<string>();
    const [token, setToken] = useState<string>();
    const [accounts, setAccounts] = useState<PlaidAccount[]>();
    const [exited, setExited] = useState(false);

    useEffect(() => {
        ApiClient('api/create-link-token/', { method: 'POST' }).then((response: any) => setLinkToken(response.token));
    }, []);

    const onEvent = useCallback((eventName: string, metadata: PlaidLinkOnEventMetadata) => {
        if (eventName === 'ERROR') {
            track('Plaid Error', { userId: user?.id, metadata });
            ApiClient('payment/api/setup-events/', { body: { data: metadata, processor: 'Plaid' } });
        }
    }, []);

    function onExit(error: PlaidLinkError | null, metadata: PlaidLinkOnExitMetadata) {
        track('Plaid Exit', { userId: user?.id, metadata });
        setExited(true);
    }

    const { open, ready } = usePlaidLink({
        token: linkToken || '',
        onSuccess: onPlaidCallback,
        onExit,
        onEvent,
    });

    useEffect(() => {
        ready && open();
    }, [ready, open]);

    function onPlaidCallback(token: string, metadata: PlaidMetadata) {
        setToken(token);
        setAccounts(metadata.accounts);
    }

    return { token, accounts, ready, exited };
}
