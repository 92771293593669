import { History } from 'history';
import { client } from 'shared';

type ContextType = {
    user: undefined;
    role: undefined;
    refreshUser: () => void;
};

export function loginUser(
    username: string,
    password: string,
    context: ContextType,
    history: History<any>,
    setUsernameError: (x: boolean) => void,
    setPasswordError: (x: boolean) => void,
    setLoginAttemptError: (x: boolean) => void,
    onClose?: () => void,
) {
    function checkForInvalid() {
        setUsernameError(!username);
        setPasswordError(!password);
    }

    function failedLoginAttempt() {
        setLoginAttemptError(true);
    }

    function setUserToken(token: string) {
        localStorage.clear();
        localStorage.setItem('token', token);
    }

    function handleNavigation() {
        if (
            history.location.pathname.includes('job-request') ||
            history.location.pathname.search('/family/[1-9][0-9]*/verify') >= 0 ||
            history.location.pathname.includes('send-funds')
        ) {
            onClose && onClose();
            return;
        }
        const { from } = history.location.state ?? { from: { pathname: '/home' } };

        client('api/users/identity/').then((res) => {
            if (res.signup_page === 4) {
                if (res.hasOwnProperty('family') || res.hasOwnProperty('is_superuser')) {
                    history.push(from);
                }
            }
        });
    }

    if (!!username && !!password) {
        client('api/rest-auth/login/', {
            body: {
                username: username.toLocaleLowerCase(),
                password: password,
            },
        })
            .then((res) => {
                if (res.mfaToken) {
                    onClose && onClose();
                    history.push('/login/verify', { mfaToken: res.mfaToken });
                    return;
                }

                setUserToken(res.key);
                context.refreshUser();
                handleNavigation();
            })
            .catch(failedLoginAttempt);
    } else {
        return checkForInvalid();
    }
}
