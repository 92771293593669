import React, { useState } from 'react';
import { IButtonListProps } from './models';
import { IHandleActionParams } from 'models';
import { client } from 'shared';
import { LoadingSpinner, TextButton } from 'library';
import { Grid } from '@material-ui/core';
export default function ButtonList({ options, url, onSubmit }: IButtonListProps) {
    const [loading, setLoading] = useState(false);
    function handleAction(data: IHandleActionParams['data'], method: IHandleActionParams['method']) {
        return () => {
            setLoading(true);
            client(url, { body: data, method })
                .catch(() => {
                    setLoading(false);
                    alert('Something went wrong please try again.');
                })
                .finally(() => {
                    setLoading(false);
                    onSubmit();
                });
        };
    }

    return (
        <Grid container style={{ gap: 10 }} justify="flex-start" direction="column">
            {loading ? (
                <LoadingSpinner />
            ) : (
                options.map((option) => (
                    <TextButton
                        key={option.display}
                        onClick={handleAction(option.data, option.method)}
                        buttonStyle={{ width: 'unset' }}
                    >
                        {option.display}
                    </TextButton>
                ))
            )}
        </Grid>
    );
}
