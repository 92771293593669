import React, { useState } from 'react';
import { DocumentToVerify, FormDetails } from '../models';
import { DocumentHeader } from './DocumentHeader';
import EditFormDetails from './EditFormDetails';
import { Accordion, AccordionDetails, AccordionSummary, Grid } from '@mui/material';
import { ExpandMore } from '@material-ui/icons';
import { Colors } from 'library';
import AutoVerifyResults from './AutoVerifyResults';

export function DocumentDisplay({
    form,
    refresh,
    expanded,
    setExpanded,
}: {
    form: DocumentToVerify;
    refresh: () => void;
    expanded: number;
    setExpanded: (id: number) => void;
}) {
    const isHeicFile = form.name.toLowerCase().includes('.heic');
    const [formDetails, setFormDetails] = useState<FormDetails>({ document_type_id: form?.document_type_id });

    function updateFormDetails(key: keyof FormDetails, value: number) {
        setFormDetails({ ...formDetails, [key]: value });
    }

    function handleExpansionChange() {
        setExpanded(expanded === form.id ? -1 : form.id);
    }

    return (
        <Accordion
            expanded={expanded === form.id}
            key={form.id}
            onChange={handleExpansionChange}
            style={{ borderRadius: 18, marginTop: 20, position: 'unset', backgroundColor: Colors.lightTurq }}
        >
            <AccordionSummary expandIcon={<ExpandMore />}>
                <DocumentHeader form={form} refresh={refresh} formDetails={formDetails} />
            </AccordionSummary>
            <AutoVerifyResults results={form.auto_verify_result} />

            <AccordionDetails>
                <Grid
                    xs={12}
                    item
                    container
                    flexDirection="row"
                    justifyContent="space-between"
                    style={{ marginTop: 20 }}
                >
                    <EditFormDetails form={form} formDetails={formDetails} updateFormDetails={updateFormDetails} />
                    <Grid item container xs={12} md={5}>
                        {form.file && !isHeicFile ? (
                            <embed src={form.file} style={{ height: '80vh', width: '50vh' }} />
                        ) : null}
                    </Grid>
                </Grid>
            </AccordionDetails>
        </Accordion>
    );
}
