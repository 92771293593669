import React, { useState } from 'react';
import styled from 'styled-components';
import Input from '../../reusableComponents/input';
import PasswordInput from '../../reusableComponents/passwordInput';
import { Card, Grid } from '@material-ui/core';
import Header from '../../reusableComponents/header';
import Button from '../../reusableComponents/button';
import { withRouter } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core/styles';
import theme from '../../theme';

const LOGO = require('../../assets/images/NavyLogo.png');

const LogoLink = styled.a`
    &:hover {
        cursor: pointer;
    }
`;

const Logo = styled.img`
    max-width: 175px;
    margin: 0;
    padding: 0;
`;

const LoginForm = (props) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [usernameValid, setUsernameValid] = useState(true);
    const [passwordValid, setPasswordValid] = useState(true);

    const updateUsername = (event) => {
        setUsername(event.target.value);
        setUsernameValid(event.target.value && event.target.value.length > 0);
    };

    const updatePassword = (event) => {
        setPassword(event.target.value);
        setPasswordValid(event.target.value && event.target.value.length > 0);
    };

    return (
        <ThemeProvider theme={theme}>
            <Grid container direction="column" style={{ height: '100vh' }}>
                <a href="https://www.join-tandem.com">
                    <LogoLink style={{ margin: 20 }} className="navbar-brand">
                        <Logo src={LOGO} alt="tandem-logo" />
                    </LogoLink>
                </a>
                <Grid
                    container
                    direction="column"
                    item
                    xs={12}
                    style={{ padding: 20, flex: 1, backgroundColor: '#E6F5FF' }}
                >
                    <Card style={{ padding: 20, borderRadius: 15, maxWidth: 450, alignSelf: 'center' }}>
                        <Header variant="h5" gutterBottom>
                            Provider Login
                        </Header>
                        <Input
                            fullWidth
                            id="username"
                            label="Username"
                            variant="outlined"
                            value={username}
                            onChange={updateUsername}
                            error={!usernameValid}
                            helperText={usernameValid ? '' : 'Please enter your username'}
                            inputProps={{ autoCapitalize: 'none' }}
                        />
                        <PasswordInput
                            fullWidth
                            id="password"
                            label="Password"
                            variant="outlined"
                            value={password}
                            onChange={updatePassword}
                            error={!passwordValid || props.loginFailed}
                            helperText={
                                props.loginFailed
                                    ? 'Login failed. Please try again.'
                                    : !passwordValid
                                    ? 'Please enter your password'
                                    : ''
                            }
                            inputProps={{ autoCapitalize: 'none' }}
                            autoComplete="off"
                        />
                        <Button fullWidth onClick={() => props.handleLogin(username, password)} variant="contained">
                            Login
                        </Button>
                    </Card>
                </Grid>
            </Grid>
        </ThemeProvider>
    );
};

export default withRouter(LoginForm);
