import { Badge } from '@mui/material';
import { Colors } from 'library';
import React from 'react';

export default function BadgedNavButton({
    children,
    count,
    rightOffset,
}: {
    children: React.ReactNode;
    count: number;
    rightOffset?: string;
}) {
    return (
        <Badge
            showZero={false}
            sx={{
                '.MuiBadge-badge': {
                    backgroundColor: Colors.mediumRed,
                    color: Colors.white,
                    right: rightOffset,
                },
            }}
            badgeContent={count}
            max={9}
            overlap="circular"
        >
            {children}
        </Badge>
    );
}
