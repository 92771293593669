import React from 'react';
import { Avatar, styled } from '@mui/material';
import { Colors, Text, TruncateText } from 'library';

import { ISpotlightWorker } from 'parent-portal/home/Models/DashboardData';

export default function WorkerSpotlightCard({ worker }: { worker: ISpotlightWorker }) {
    function updateChipText(text: string) {
        const vals = text.split(': ');
        const isYearsExperience = text.includes('experience');

        return isYearsExperience ? `${vals[1]} years of experience` : vals[1];
    }

    function getChipColor(text: string) {
        const isYearsExperience = text.includes('experience');

        return isYearsExperience ? Colors.lightTerraCotta : Colors.mediumTurq;
    }

    const credentialsSet = new Set(worker.credentials.map(updateChipText));
    const credentials = Array.from(credentialsSet.values());

    return (
        <Card>
            <Header>
                <AvatarContainer>
                    <WorkerAvatar src={worker.pictureUrl} />
                </AvatarContainer>
                <div>
                    <Text variant="h2" bold textStyle={{ marginBottom: 5 }}>
                        {worker.name}
                    </Text>
                    <ImageCredentialContainer>
                        {credentials.map((x, index) => (
                            <CredentialPill key={index} backgroundColor={getChipColor(x)}>
                                {x}
                            </CredentialPill>
                        ))}
                    </ImageCredentialContainer>
                </div>
            </Header>
            <TruncateText text={worker.bio} length={350} />
        </Card>
    );
}

const Card = styled('div')({
    borderRadius: 20,
    padding: 30,
    boxShadow: '0px 3px 6px #00000029',
    margin: 10,
    minHeight: 320,
    height: 'fit-content',
});

const Header = styled('div')({
    alignItems: 'flex-start',
    display: 'flex',
});

const AvatarContainer = styled('div')({
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 10,
});

const WorkerAvatar = styled(Avatar)({
    color: Colors.white,
    backgroundColor: Colors.darkNavy,
    width: 100,
    height: 100,
    borderRadius: '50%',
    marginTop: 10,
    marginBottom: 10,
});

const ImageCredentialContainer = styled('div')({
    display: 'inline',
});

const CredentialPill = styled('span')(({ backgroundColor }: { backgroundColor: string }) => ({
    fontSize: 10,
    color: Colors.darkNavy,
    backgroundColor,
    borderRadius: 20,
    margin: 5,
    padding: '5px 10px',
    display: 'inline-block',
    fontWeight: 'bold',
}));
