import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import { PrimaryButton, Text } from 'library';
import { formatE164USPhoneNumber } from 'shared';
import { AsYouType } from 'libphonenumber-js';
import { isValidEmail, isValidPhone, isValidZipCode } from 'shared/Validators';
import { createContactLead, IContactInfoPostBody } from 'api/ContactInfoApi';

import { StyledGrid, StyledTextInputRow, StyledSubmitRow, StyledErrorText } from './LearnMoreComponents';
import { ILearnMoreAttribute, ILearnMoreSignUp } from './types';

const LearnMoreForm = ({ setSuccess }: { setSuccess: React.Dispatch<React.SetStateAction<boolean>> }) => {
    const [somethingWentWrong, setSomethingWentWrong] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [signUpAttributes, setSignUpAttributes] = useState<ILearnMoreSignUp>({
        firstName: {
            value: '',
            error: false,
            helperText: 'Please enter a first name',
        },
        lastName: {
            value: '',
            error: false,
            helperText: 'Please enter a last name',
        },
        businessName: {
            value: '',
            error: false,
            helperText: 'Please enter a business name',
        },
        email: {
            value: '',
            error: false,
            helperText: 'Please enter a valid email',
        },
        phone: {
            value: '',
            error: false,
            helperText: 'Please enter a valid phone number',
        },
        zipcode: {
            value: '',
            error: false,
            helperText: 'Please enter a valid zipcode',
        },
    });

    function helperText(attribute: ILearnMoreAttribute): string | false {
        return attribute?.error && attribute.helperText;
    }

    function onChange(attribute: ILearnMoreAttribute, attributeName: string, value: string): void {
        setSignUpAttributes({
            ...signUpAttributes,
            ...{ [`${attributeName}`]: { ...attribute, value } },
        });
    }

    function onPhoneChange(value: string): void {
        const parsedValue: string = formatE164USPhoneNumber(value).toString();
        const formattedValue: string = parsedValue?.length > 4 ? new AsYouType('US').input(parsedValue) : parsedValue;

        onChange(signUpAttributes.phone, 'phone', formattedValue);
    }

    function setError(attribute: ILearnMoreAttribute, attributeName: string, error: boolean): void {
        setSignUpAttributes({
            ...signUpAttributes,
            ...{ [`${attributeName}`]: { ...attribute, error } },
        });
    }

    function isValidName(name: string): boolean {
        return (name?.trim()?.length || 0) > 0;
    }

    function validateName(attribute: ILearnMoreAttribute, attributeName: string): void {
        setError(attribute, attributeName, !isValidName(attribute.value));
    }

    function validateAttributes(): ILearnMoreSignUp {
        const finalNameAttributes: ILearnMoreSignUp = ['firstName', 'lastName', 'businessName'].reduce(
            (accumulatedAttributes: ILearnMoreSignUp, attributeName: string) => {
                const attribute: ILearnMoreAttribute =
                    signUpAttributes[`${attributeName as keyof typeof signUpAttributes}`];
                const isNameValid: boolean = isValidName(attribute.value);

                return {
                    ...accumulatedAttributes,
                    ...{ [`${attributeName}`]: { ...attribute, value: attribute.value.trim(), error: !isNameValid } },
                };
            },
            signUpAttributes,
        );

        return {
            ...finalNameAttributes,
            ...{
                email: { ...finalNameAttributes.email, error: !isValidEmail(finalNameAttributes.email.value.trim()) },
            },
            ...{
                phone: {
                    ...finalNameAttributes.phone,
                    error: !!finalNameAttributes.phone.value && !isValidPhone(finalNameAttributes.phone.value.trim()),
                },
            },
            ...{
                zipcode: {
                    ...finalNameAttributes.zipcode,
                    error: !isValidZipCode(finalNameAttributes.zipcode.value.trim()),
                },
            },
        };
    }

    async function onSubmit(): Promise<void> {
        setLoading(true);
        setSomethingWentWrong(false);

        const attributes: ILearnMoreSignUp = validateAttributes();
        const attributesAreValid: boolean = Object.keys(signUpAttributes).every(
            (attribute: string) => !attributes[`${attribute as keyof typeof signUpAttributes}`]?.error,
        );

        if (attributesAreValid) {
            const phoneNumber = signUpAttributes.phone.value
                ? (formatE164USPhoneNumber(signUpAttributes.phone.value.trim()) as string)
                : null;

            const body: IContactInfoPostBody = {
                first_name: signUpAttributes.firstName.value,
                last_name: signUpAttributes.lastName.value,
                business_name: signUpAttributes.businessName.value,
                email: signUpAttributes.email.value.trim(),
                phone_number: phoneNumber,
                contact_info_location: [{ zip_code: signUpAttributes.zipcode.value.trim() }],
            };

            await createContactLead(body)
                .then(() => setSuccess(true))
                .catch(() => {
                    setSomethingWentWrong(true);
                    setLoading(false);
                });
        } else {
            setSignUpAttributes(attributes);
            setLoading(false);
        }
    }

    return (
        <StyledGrid container item justify="center" direction="column">
            <Grid item>
                <Text textStyle={{ padding: 14 }}>
                    We're excited to connect with you! If you'd like to learn more from one of our staffing experts,
                    enter your information below, and we'll get back to you.
                </Text>
            </Grid>
            <Grid item>
                <StyledTextInputRow
                    error={signUpAttributes.firstName.error}
                    helperText={helperText(signUpAttributes.firstName)}
                    onBlur={() => validateName(signUpAttributes.firstName, 'firstName')}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        onChange(signUpAttributes.firstName, 'firstName', e.target.value)
                    }
                    placeholder="First name"
                    value={signUpAttributes.firstName.value}
                />
            </Grid>
            <Grid item>
                <StyledTextInputRow
                    error={signUpAttributes.lastName.error}
                    helperText={helperText(signUpAttributes.lastName)}
                    onBlur={() => validateName(signUpAttributes.lastName, 'lastName')}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        onChange(signUpAttributes.lastName, 'lastName', e.target.value)
                    }
                    placeholder="Last name"
                    value={signUpAttributes.lastName.value}
                />
            </Grid>
            <Grid item>
                <StyledTextInputRow
                    error={signUpAttributes.businessName.error}
                    helperText={helperText(signUpAttributes.businessName)}
                    onBlur={() => validateName(signUpAttributes.businessName, 'businessName')}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        onChange(signUpAttributes.businessName, 'businessName', e.target.value)
                    }
                    placeholder="Business name"
                    value={signUpAttributes.businessName.value}
                />
            </Grid>
            <Grid item>
                <StyledTextInputRow
                    error={signUpAttributes.email.error}
                    helperText={helperText(signUpAttributes.email)}
                    onBlur={() =>
                        setError(signUpAttributes.email, 'email', !isValidEmail(signUpAttributes.email.value.trim()))
                    }
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        onChange(signUpAttributes.email, 'email', e.target.value)
                    }
                    placeholder="Email"
                    value={signUpAttributes.email.value}
                />
            </Grid>
            <Grid item>
                <StyledTextInputRow
                    error={signUpAttributes.phone.error}
                    helperText={helperText(signUpAttributes.phone)}
                    onBlur={() => {
                        const validPhone =
                            !signUpAttributes.phone.value || isValidPhone(signUpAttributes.phone.value.trim());
                        setError(signUpAttributes.phone, 'phone', !validPhone);
                    }}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => onPhoneChange(e.target.value)}
                    placeholder="Phone"
                    value={signUpAttributes.phone.value}
                />
            </Grid>
            <Grid item>
                <StyledTextInputRow
                    error={signUpAttributes.zipcode.error}
                    helperText={helperText(signUpAttributes.zipcode)}
                    onBlur={() =>
                        setError(
                            signUpAttributes.zipcode,
                            'zipcode',
                            !isValidZipCode(signUpAttributes.zipcode.value.trim()),
                        )
                    }
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        onChange(signUpAttributes.zipcode, 'zipcode', e.target.value)
                    }
                    placeholder="Zip code"
                    value={signUpAttributes.zipcode.value}
                />
            </Grid>
            <StyledSubmitRow
                container
                item
                style={{ justifyContent: somethingWentWrong ? 'space-between' : 'flex-end' }}
            >
                {somethingWentWrong && (
                    <StyledErrorText color="error">
                        Sorry, something seems to have gone wrong. Please try again later.
                    </StyledErrorText>
                )}
                <PrimaryButton
                    buttonStyle={{ width: undefined }}
                    loading={loading}
                    disabled={loading}
                    type="submit"
                    onClick={() => onSubmit()}
                >
                    Submit
                </PrimaryButton>
            </StyledSubmitRow>
        </StyledGrid>
    );
};

export default LearnMoreForm;
