import React from 'react';
import { Chip, Colors } from 'library';
import { Note } from 'models';
import moment from 'moment';

export default function LastNoteLabel({ note }: { note?: Note }) {
    if (!note) {
        return <></>;
    }

    return (
        <>
            <b>
                {moment(note?.added_at).format('MMM Do YYYY, h:mm a')} ({note?.author.first_name}{' '}
                {note?.author.last_name}):
            </b>
            {note?.is_from_call && <Chip label="Call" backgroundColor={Colors.pink} textColor={Colors.white} />}{' '}
        </>
    );
}
