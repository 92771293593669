import React from 'react';
import { Grid } from '@mui/material';
import { Colors, Text } from 'library';

import type { BusinessWithBusinessOutlaws, LeanUser } from 'models';

export function UserInfo({ user, outlawed }: { user: LeanUser; outlawed: BusinessWithBusinessOutlaws[] }) {
    const isOutlawedSomewhere = outlawed.some(
        (business) =>
            business.business_outlaws.length ||
            business.business_locations.some((location) => location.is_business_outlaw),
    );

    return (
        <Grid style={{ marginBottom: '2em' }}>
            <Text variant="caption" textStyle={{ marginBottom: 10 }}>
                You can block/un-block workers from being booked business wide or at specific locations using the
                options below. By blocking this worker, you will be removing them from any future shifts as well. Tandem
                will then reopen those shifts to our sub pool.
            </Text>
            <Text>
                {!isOutlawedSomewhere ? 'Block ' : ''}
                <Text inline variant="h2" textStyle={{ color: Colors.turquoise }}>
                    {user.first_name} {user.last_name}
                </Text>{' '}
                {!isOutlawedSomewhere ? 'from:' : 'has been blocked from:'}
            </Text>
        </Grid>
    );
}
