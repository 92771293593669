import React from 'react';
import { ICenterNotificationPreference } from 'models';
import { Checkbox, Text } from 'library';
import { Grid } from '@material-ui/core';
import { client, consoleLogInDev } from 'shared';
import { styled } from '@mui/material';

export default function CenterNotifications({
    emailPreferences,
    setEmailPreferences,
}: {
    emailPreferences: ICenterNotificationPreference[];
    setEmailPreferences: (val: ICenterNotificationPreference[]) => void;
}) {
    function onCheckChange(emailPreferenceId: number, key: string, value: boolean) {
        client(`api/center-notification-preference/${emailPreferenceId}/`, {
            method: 'PATCH',
            body: { [key]: value },
        })
            .then((result: ICenterNotificationPreference) => {
                const index = emailPreferences.findIndex((x) => x.id === emailPreferenceId);
                setEmailPreferences([
                    ...emailPreferences.slice(0, index),
                    { ...result },
                    ...emailPreferences.slice(index + 1),
                ]);
            })
            .catch(consoleLogInDev);
    }

    function getName(user?: { first_name: string; last_name: string }, isMain?: boolean) {
        if (!user) {
            return '';
        }

        return `${user.first_name} ${user.last_name}${isMain ? ' (Main)' : ''}`;
    }

    return (
        <Grid style={{ padding: '0px 10px 10px 10px' }} alignContent="center">
            {emailPreferences.map((preference) => (
                <PreferenceContainer key={preference.id}>
                    <Text bold textStyle={{ marginBottom: 5 }}>
                        {getName(preference.user, !preference.business_location)}
                    </Text>
                    <Checkbox
                        checked={preference.scheduling}
                        label="Scheduling"
                        onChange={(checked) => onCheckChange(preference.id, 'scheduling', checked)}
                        style={{ display: 'inline' }}
                    />
                    <Checkbox
                        checked={preference.billing}
                        label="Billing"
                        onChange={(checked) => onCheckChange(preference.id, 'billing', checked)}
                        style={{ display: 'inline' }}
                    />
                    <Checkbox
                        checked={preference.promotional}
                        label="Informational"
                        onChange={(checked) => onCheckChange(preference.id, 'promotional', checked)}
                        style={{ display: 'inline' }}
                    />
                </PreferenceContainer>
            ))}
        </Grid>
    );
}

const PreferenceContainer = styled('div')({
    marginBottom: 15,
});
