import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import { client, consoleLogInDev } from 'shared';
import { Colors, PrimaryButton, SecondaryButton, Text } from 'library';
import useContact from 'parent-portal/profile/BusinessProfile/hooks/useContact';
import ContactInfo from './ContactInfo';
import { IBusinessContact } from 'models';

export default function EditExistingContact({
    businessContact,
    setEditing,
    updateContact,
}: {
    businessContact: IBusinessContact;
    setEditing: (b: boolean) => void;
    updateContact: (c: IBusinessContact, remove?: boolean) => void;
}) {
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const {
        isNewContactValid,
        mainContactInfo,
        updateMainContactInfo,
        populateCurrentContactInfo,
        submissionData: contactData,
    } = useContact({
        firstName: businessContact.user.first_name,
        lastName: businessContact.user.last_name,
        phoneNumber: businessContact.user.phone_number,
        email: businessContact.user.email,
        contactPreference: businessContact.user.communication_preference,
    });
    function onCancel() {
        populateCurrentContactInfo();
        setEditing(false);
    }

    function save() {
        setLoading(true);
        client(`api/business-contact/${businessContact.id}/`, {
            method: 'PATCH',
            body: contactData,
        })
            .then((res) => {
                if (res.status === 500) {
                    setErrorMessage(res.message);
                } else {
                    updateContact(res);
                    setEditing(false);
                }
            })
            .catch((res) => {
                consoleLogInDev(res);
            })
            .finally(() => setLoading(false));
    }
    return (
        <Grid style={{ padding: 20 }} container item direction="column">
            <Text bold variant="h1">
                Edit {businessContact.user.first_name}'s contact info
            </Text>
            <ContactInfo newContact={mainContactInfo} updateContact={updateMainContactInfo} />
            {errorMessage ? (
                <Text variant="caption" textStyle={{ color: Colors.error }}>
                    {errorMessage}
                </Text>
            ) : null}
            <Grid container item xs={12} justify="space-around">
                <SecondaryButton buttonStyle={{ maxWidth: '30%' }} onClick={onCancel}>
                    Cancel
                </SecondaryButton>
                <PrimaryButton
                    disabled={!isNewContactValid() || loading}
                    buttonStyle={{ maxWidth: '30%' }}
                    onClick={save}
                >
                    Save
                </PrimaryButton>
            </Grid>
        </Grid>
    );
}
