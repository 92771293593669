import React from 'react';
import { OutlinedTextInput, Text } from 'library';
import { styled } from '@mui/material';
import { SearchWorker } from 'internal/shared/SearchWorker';

import type { ActualLeanUser } from 'models';

export function SearchWorkerAndPay({
    selectedWorker,
    setSelectedWorker,
    pay,
    setPay,
}: {
    selectedWorker: ActualLeanUser | null;
    setSelectedWorker: (worker: ActualLeanUser | null) => void;
    pay: string | null;
    setPay: (pay: string) => void;
}) {
    return (
        <div>
            <Text>Select the worker and pay rate you would like to add to the shift:</Text>
            <Row>
                <SearchWorker selectedWorker={selectedWorker} setSelectedWorker={setSelectedWorker} showAll={true} />
                <OutlinedTextInput
                    label="Pay rate"
                    type="number"
                    value={pay}
                    onChange={(e) => setPay(e.target.value)}
                    style={{ margin: 10, width: 100 }}
                    InputProps={{ startAdornment: '$' }}
                />
            </Row>
        </div>
    );
}

const Row = styled('div')({
    display: 'flex',
    alignItems: 'flex-start',
});
