import React from 'react';
import { styled } from '@mui/material';
import OpenInNew from '@mui/icons-material/OpenInNew';
import { Colors } from '../../theme/Colors';

export default function OpenInNewLink({ path }: { path: string }) {
    return (
        <a href={path} target="_blank" rel="noreferrer">
            {' '}
            <StyledOpenInNew />
        </a>
    );
}

const StyledOpenInNew = styled(OpenInNew)({
    color: Colors.darkNavy,
    height: 20,
    marginLeft: 3,
});
