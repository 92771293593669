import React from 'react';
import { AddCircle, RemoveCircle } from '@mui/icons-material';
import { IconButton } from 'library';
import { BusinessJobWithAppointments } from 'models';
import { adminLog } from 'shared';
import { toggleRemoved } from './substituteApi';

export default function RemoveListing({ job, refresh }: { job: BusinessJobWithAppointments; refresh: () => void }) {
    const removed = !!job.request_removed_at;
    const tooltipText = removed ? 'Reopen this trial run listing' : 'Remove this trial run listing';
    const icon = removed ? AddCircle : RemoveCircle;
    const onClick = () => {
        toggleRemoved(job.id, !removed).then(refresh).catch(adminLog);
    };

    return (
        <span style={{ marginLeft: 10 }} title={tooltipText}>
            <IconButton tooltip={tooltipText} icon={icon} onClick={onClick} />
        </span>
    );
}
