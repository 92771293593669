import React from 'react';
import { Grid } from '@mui/material';
import { Text, Colors } from 'library';
import { StartEnd } from './models';
import { format12HourTime } from 'shared/Dates';

export default function TimeslotEntry({ timeslot }: { timeslot: StartEnd }) {
    return (
        <Grid item xs={12}>
            <Text variant="body2" textStyle={{ textDecoration: timeslot.booked ? 'line-through' : undefined }}>
                {format12HourTime(new Date(timeslot.lower))} - {format12HourTime(new Date(timeslot.upper))}
            </Text>
            {timeslot.booked && (
                <Text variant="caption" color={Colors.mediumRed}>
                    Already booked
                </Text>
            )}
        </Grid>
    );
}
