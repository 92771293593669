import { Button } from '@material-ui/core';
import { KeyboardDoubleArrowLeft, KeyboardDoubleArrowRight } from '@mui/icons-material';
import { Colors } from 'library';
import React from 'react';

export default function DrawerToggleButton({ open, toggle }: { open: boolean; toggle: () => void }) {
    return (
        <Button
            style={{
                position: 'fixed',
                top: 100,
                left: open ? 334 : 0,
                height: 50,
                width: 36,
                minWidth: 36,
                backgroundColor: Colors.white,
                borderTopRightRadius: open ? 0 : 18,
                borderBottomRightRadius: open ? 0 : 18,
                borderTopLeftRadius: open ? 18 : 0,
                borderBottomLeftRadius: open ? 18 : 0,
                boxShadow: '0px 3px 6px #00000029',
                zIndex: 910,
                padding: 0,
            }}
            onClick={toggle}
        >
            {open ? (
                <KeyboardDoubleArrowLeft htmlColor={Colors.darkNavy} />
            ) : (
                <KeyboardDoubleArrowRight htmlColor={Colors.darkNavy} />
            )}
        </Button>
    );
}
