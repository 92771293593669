import { AdminBusinessLocationOption } from 'models/AdminBusinessLocation';
import { useForm } from 'react-hook-form';
import { client } from 'shared';
import { TaskListItem } from './matchingInboxModels';
import { toISOLocal } from 'shared/Dates';

export interface DropdownItem {
    key: string;
    value: string;
}

export type TaskFormFieldNames =
    | 'subject'
    | 'body'
    | 'business_locations'
    | 'expires_at'
    | 'inbox'
    | 'item_type'
    | 'category';

export interface TaskFormValues {
    subject: string | null;
    body: string | null;
    business_locations: DropdownItem[];
    expires_at: string | null;
    inbox: string | null;
    item_type: string | null;
    category: 'worker' | 'center';
}

export default function useTaskForm(
    defaultInbox: string,
    defaultBusinessLocation?: AdminBusinessLocationOption,
    itemToEdit?: TaskListItem,
) {
    const businessLocation = itemToEdit?.business_location ?? defaultBusinessLocation;
    const {
        control,
        register,
        handleSubmit,
        formState: { errors },
        reset,
        trigger,
        setError,
        getValues,
        setValue,
    } = useForm<TaskFormValues>({
        defaultValues: {
            subject: itemToEdit?.subject || null,
            body: itemToEdit?.body || null,
            item_type: itemToEdit?.item_type?.toString() || '',
            expires_at: itemToEdit?.expires_at ? toISOLocal(new Date(itemToEdit?.expires_at)) : null,
            business_locations: businessLocation
                ? [{ key: businessLocation.id.toString(), value: businessLocation.name }]
                : [],
            inbox: itemToEdit?.inbox.toString() || defaultInbox,
            category: itemToEdit?.category || 'center',
        },
    });

    const submit = handleSubmit(async (data: TaskFormValues) => {
        const newData = {
            ...data,
            reason_for_post: 'Agent Added',
            business_locations: data.business_locations.map((location: DropdownItem) => parseInt(location.key)),
        };
        const url = itemToEdit ? `api/matching-inbox/${itemToEdit.id}/` : 'api/matching-inbox/';
        const method = itemToEdit ? 'PATCH' : 'POST';
        await client(url, { body: newData, method });
    });

    return { control, register, submit, errors, reset, trigger, setError, getValues, setValue };
}

export async function getTaskFormOptions(category: 'center' | 'worker' = 'center') {
    return await client(`api/matching-inbox/options/?category=${category}`);
}
