import React from 'react';
import { Grid, Box } from '@material-ui/core';
import { Checkbox } from 'library';

export function FilterCheckboxSection({
    options,
    selectedOptions,
    setSelectedOptions,
    singleSelect = false,
}: {
    options: string[];
    selectedOptions: string[];
    setSelectedOptions: (options: string[]) => void;
    singleSelect?: boolean;
}) {
    function singleSelectOption(x: string) {
        const newSelection = selectedOptions.includes(x) ? [] : [x];
        setSelectedOptions(newSelection);
    }
    function multiSelectOption(x: string) {
        if (selectedOptions.includes(x)) {
            setSelectedOptions(selectedOptions.filter((y) => y !== x));
        } else {
            setSelectedOptions([...selectedOptions, x]);
        }
    }
    return (
        <Grid item xs={12}>
            <Box style={{ marginTop: '3%' }}>
                <Grid item container direction="row" style={{ gap: 10 }}>
                    {options.map((x) => (
                        <Checkbox
                            key={x}
                            label={x}
                            checked={selectedOptions.includes(x)}
                            onChange={() => (singleSelect ? singleSelectOption(x) : multiSelectOption(x))}
                        />
                    ))}
                </Grid>
            </Box>
        </Grid>
    );
}
