import { Business, ShiftProviderUser } from 'models';
import { TextContainer } from '../components/Containers';
import ThanksForFeedback from '../components/ThanksForFeedback';
import { Text } from 'library';
import ProfileImage from '../components/ProfileImage';
import React from 'react';

const MainCopy = ({ provider, business }: { provider: ShiftProviderUser; business: Business }) => (
    <>
        <TextContainer>
            <ThanksForFeedback />
        </TextContainer>

        <TextContainer>
            <Text variant="body1">Oh no! We are so sorry to hear you had a poor experience.</Text>

            <Text variant="body1">
                {`We use reviews as a way to ensure we are booking only the best workers
            for your center. If you'd like to take action now,
            you are also able to block ${provider.first_name} from your center
            for all future bookings.`}
            </Text>
        </TextContainer>

        <TextContainer>
            <Text textStyle={{ fontWeight: 'bold' }} variant="body1">
                Would you like to block {provider.first_name} from {business.name}?
            </Text>
        </TextContainer>

        <ProfileImage image={provider.profile_picture?.image} imageId={provider.id.toString()} />
    </>
);

export default MainCopy;
