import { Grid } from '@material-ui/core';
import { useJobFormContext } from 'parent-portal/forms/JobFormContext';
import React, { forwardRef, ReactNode, Ref } from 'react';
import { getTimeString as getOneTimeString } from './OneTimeForm';
import { getTimeString as getOngoingTimeString } from './OngoingForm';
import getPayString from '../PayString';
import { Colors, Text } from 'library';

export default forwardRef(function JobSummary(props: object, ref: Ref<HTMLDivElement>) {
    const { form } = useJobFormContext();

    const formValues = form.toValues();
    const payString = getPayString(formValues.pay, formValues.isQuickFill);

    function getTimeString() {
        if (formValues.requestType === 'onetime') {
            return getOneTimeString(formValues.jobTimes, formValues.startDate);
        } else {
            return getOngoingTimeString(
                form.fieldStates.daysOfTheWeek,
                formValues.jobTimes,
                formValues.sameTimes,
                formValues.startEstimateMonth,
                formValues.startDate,
                formValues.endDate,
            );
        }
    }

    const comments = formValues.comments.length > 80 ? formValues.comments.slice(0, 80) + '...' : formValues.comments;

    return (
        <Grid
            container
            ref={ref}
            direction="column"
            style={{ backgroundColor: Colors.lightTurq, padding: 20, borderRadius: 18, marginTop: 7 }}
        >
            <Text textStyle={{ marginBottom: 5 }} variant="h1">
                {formValues.headline}
            </Text>
            <Text textStyle={{ marginBottom: 10 }} variant="body1">
                {comments}
            </Text>
            <SummaryRow title="Time">
                <Text variant="body2" textStyle={{ whiteSpace: 'pre-wrap' }}>
                    {getTimeString()}
                </Text>
            </SummaryRow>
            <SummaryRow title="Pay">
                <Text variant="body2">{payString}</Text>
            </SummaryRow>
        </Grid>
    );
});

interface SummaryRowProps {
    children?: ReactNode[] | ReactNode;
    title: string;
}

function SummaryRow({ title, children }: SummaryRowProps) {
    return (
        <div style={{ marginBottom: 10 }}>
            <Text bold variant="body2">
                {title}
            </Text>
            {children}
        </div>
    );
}
