import React from 'react';
import { Map } from '@mui/icons-material';
import { IconButton } from 'library';

interface IShowInMapProps {
    businessLocationId?: number;
    region: string;
    latitude: number;
    longitude: number;
}

export default function ShowInMap({ businessLocationId, region, latitude, longitude }: IShowInMapProps) {
    const url = window.location.origin;
    const goToMap = () =>
        window.open(
            `${url}/adminperson/region-map?businessLocation=${businessLocationId}&region=${region}&latitude=${latitude}&longitude=${longitude}`,
        );
    return (
        <IconButton tooltip="Show this job in the region map" icon={Map} onClick={goToMap} style={{ marginLeft: 8 }} />
    );
}
