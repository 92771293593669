import React from 'react';
import { useJobFormContext } from 'parent-portal/forms/JobFormContext';
import EditDialog from './EditDialog';
import RequestedWorkersOnJob from '../../ProviderPreferencesPage/RequestedWorkersOnJob';

export default function EditRequestedWorkersModal({ open, onClose }: { open: boolean; onClose: () => void }) {
    const { form } = useJobFormContext();
    const { headline } = form.fieldStates;

    return (
        <EditDialog title={`Edit ${headline.value} Requested Workers`} open={open} onClose={onClose} isValid>
            <RequestedWorkersOnJob />
        </EditDialog>
    );
}
