import React, { useState, useEffect } from 'react';
import { Checkbox, RefreshIconButton, Text } from 'library';
import { Grid } from '@mui/material';
import JobAppointments from './JobAppointments';
import useLoading from 'library/hooks/useLoading';
import { getUpcomingShifts, WorkerShiftsJobs } from './UpcomingShiftsApi';
import { adminLog } from 'shared';

const weeks = ['lastWeek', 'thisWeek', 'nextWeek'] as (keyof WorkerShiftsJobs)[];

export default function UpcomingShifts({ providerUserId }: { providerUserId: number }) {
    const [showing, setShowing] = useState<(keyof WorkerShiftsJobs)[]>(['lastWeek', 'thisWeek', 'nextWeek']);
    const [jobs, setJobs] = useState<WorkerShiftsJobs>({ lastWeek: [], thisWeek: [], nextWeek: [] });
    const { loading, setLoading, LoadingSpinner } = useLoading();
    function refreshUpcomingShifts() {
        setLoading(true);
        getUpcomingShifts(providerUserId)
            .then(setJobs)
            .catch(adminLog)
            .finally(() => setLoading(false));
    }

    useEffect(() => {
        refreshUpcomingShifts();
    }, []);

    if (loading) return <LoadingSpinner />;

    return (
        <Grid direction="row" container item gap={2}>
            <Text variant="h2" bold>
                Shifts <RefreshIconButton onClick={refreshUpcomingShifts} tooltip="Refresh upcoming shifts" />
            </Text>
            <Grid container item direction="row" style={{ gap: 10 }}>
                {weeks.map((week) => (
                    <Checkbox
                        key={week}
                        label={week.capitalize().replace('W', ' W')}
                        checked={showing.includes(week)}
                        onChange={(checked) =>
                            setShowing(
                                checked
                                    ? weeks.filter((w) => !showing.includes(w) || w != week)
                                    : showing.filter((w) => w !== week),
                            )
                        }
                        style={{ width: 'auto' }}
                    />
                ))}
            </Grid>
            {showing.map((week) => (
                <Grid container item direction="column" style={{ gap: 10 }} key={week}>
                    <Text bold>{week.capitalize().replace('W', ' W')}</Text>
                    {
                        // eslint-disable-next-line security/detect-object-injection
                        jobs[week].length > 0 ? (
                            // eslint-disable-next-line security/detect-object-injection
                            jobs[week].map((job) => (
                                <JobAppointments key={job.id} job={job} refreshUpcomingShifts={refreshUpcomingShifts} />
                            ))
                        ) : (
                            <Text>No shifts</Text>
                        )
                    }
                </Grid>
            ))}
        </Grid>
    );
}
