import React from 'react';
import { Grid, useMediaQuery } from '@material-ui/core';
import { Checkbox, Chip, Colors, IconButton } from 'library';
import { addDays, addWeeks, startOfWeek, subDays, subWeeks } from 'date-fns';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { CalendarColors } from './HiredShift';
import theme from 'theme';
import { useUserContext } from 'UserContext';

export default function CalendarFilters({
    currentDay,
    setCurrentDay,
    showWeekend,
    setShowWeekend,
}: {
    currentDay: Date;
    setCurrentDay: (d: Date) => void;
    showWeekend: boolean;
    setShowWeekend: (show: boolean) => void;
}) {
    const mdDown = useMediaQuery(theme.breakpoints.down('md'));
    const { getDisplayText } = useUserContext();

    function next() {
        let newDate = addWeeks(currentDay, 1);
        setCurrentDay(mdDown ? addDays(currentDay, 1) : startOfWeek(newDate));
    }

    function last() {
        let newDate = subWeeks(currentDay, 1);
        setCurrentDay(mdDown ? subDays(currentDay, 1) : startOfWeek(newDate));
    }

    return (
        <Grid item container direction="row" xs={12} justify="space-around" style={{ marginTop: 20 }}>
            <Grid
                xs={12}
                md={4}
                direction="row"
                item
                container
                justify="flex-end"
                alignItems="center"
                style={{
                    margin: mdDown ? '10px 0px' : 0,
                    justifyContent: mdDown ? 'center' : 'unset',
                }}
            >
                {Object.keys(CalendarColors).map((k: string, index: number) => (
                    <Chip
                        key={k}
                        label={getDisplayText(k.replace('_', ' ').toLowerCase())}
                        style={{
                            marginRight: index === Object.keys(CalendarColors).length ? 0 : 5,
                            textTransform: 'capitalize',
                        }}
                        backgroundColor={CalendarColors[k]}
                    />
                ))}
            </Grid>
            <Grid
                xs={12}
                md={4}
                direction="row"
                item
                container
                style={{ marginBottom: 20, marginTop: mdDown ? 20 : 0, justifyContent: mdDown ? 'center' : 'flex-end' }}
            >
                {!mdDown && (
                    <Checkbox
                        label="Show 7-day week"
                        checked={showWeekend}
                        onChange={setShowWeekend}
                        style={{ width: undefined, marginRight: 20 }}
                    />
                )}
                <IconButton icon={ArrowBackIosIcon} onClick={last} />
                <>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePicker
                            InputProps={{
                                inputProps: {
                                    style: { textAlign: 'center', color: Colors.darkNavy },
                                },
                            }}
                            disableToolbar
                            variant="inline"
                            format="MM/dd/yyyy"
                            value={currentDay}
                            onChange={(d: MaterialUiPickersDate | Date) => {
                                setCurrentDay(d as Date);
                            }}
                        />
                    </MuiPickersUtilsProvider>
                    <IconButton icon={ArrowForwardIosIcon} onClick={next} />
                </>
            </Grid>
        </Grid>
    );
}
