import { MainContainer, TextContainer } from './Containers';
import { Text } from 'library';
import React from 'react';

const Error = () => {
    return (
        <MainContainer>
            <TextContainer>
                <Text variant="h1">Something went wrong. Please reach out to support.</Text>
            </TextContainer>
        </MainContainer>
    );
};

export default Error;
