import React, { useEffect, useState } from 'react';
import { Colors, UnderlinedTextInput } from 'library';
import { Grid } from '@material-ui/core';
import { IconButton, Tooltip, styled } from '@mui/material';
import QuestionMark from '@mui/icons-material/QuestionMark';
import { BusinessData } from './BusinessModels';

export default function BusinessSignup({
    updateBusinessData,
    updateError,
    businessType,
    isValidProgramId,
    state,
}: {
    updateBusinessData: (data: BusinessData) => void;
    updateError: (error: boolean) => void;
    businessType: 'CHILD_CARE' | 'ELDERCARE';
    isValidProgramId: boolean;
    state?: string;
}) {
    const [name, setName] = useState<string>();
    const [programId, setProgramId] = useState<string>();
    const [nameError, setNameError] = useState<boolean>(false);

    function getProgramText() {
        if (businessType === 'ELDERCARE') {
            return 'Facility Id';
        }

        if (state === 'Florida') {
            return 'License Number';
        }

        return 'Provider ID/Program ID/License Number**';
    }

    const programText = getProgramText();

    useEffect(() => {
        setNameError(name === '');

        if (!!name && !nameError) {
            const data: BusinessData = { name, programId };
            updateBusinessData(data);
            updateError(false);
        } else {
            updateError(true);
        }
    }, [name, nameError, programId]);

    return (
        <Grid container item xs={12}>
            <UnderlinedTextInput
                id="txtBusinessName"
                label="Business Name*"
                value={name ? name : ''}
                onChange={(value: string) => setName(value)}
                error={nameError}
                errorText={'Please enter a business name.'}
            />
            <ProgramRow>
                <Tooltip
                    placement="top"
                    title="Enter the Program Number associated with the address of the location you entered above."
                >
                    <Icon>
                        <Question />
                    </Icon>
                </Tooltip>
                <UnderlinedTextInput
                    id="txtProgramId"
                    label={programText}
                    value={programId ? programId : ''}
                    onChange={(value: string) => setProgramId(value)}
                    error={!isValidProgramId}
                    errorText="Please enter a valid program ID."
                />
            </ProgramRow>
        </Grid>
    );
}

const ProgramRow = styled('div')({
    display: 'flex',
    alignItems: 'flex-end',
    width: '100%',
});

const Question = styled(QuestionMark)({
    padding: 5,
});

const Icon = styled(IconButton)({
    color: Colors.darkGrey,
    backgroundColor: Colors.lightGrey,
    height: 20,
    width: 20,
    marginRight: 10,
    marginBottom: 2,
});
