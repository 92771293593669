import React from 'react';
import { DraggableDialog, Text } from 'library';
import CallHistoryForm from 'internal/business-location/BusinessLocationOverview/components/CallHistoryForm';

export interface DraggablePhoneDialogProps {
    isOpen: boolean;
    onClose: () => void;
    name: string;
    businessLocationId: number;
    refresh?: () => void;
    anchorElement: HTMLElement | null;
    parentOffset?: boolean;
}

export default function DraggablePhoneDialog({
    isOpen,
    onClose,
    name,
    businessLocationId,
    refresh,
    anchorElement,
    parentOffset,
}: DraggablePhoneDialogProps) {
    return (
        <DraggableDialog
            isOpen={isOpen}
            dialogTitle="Log Call"
            onClose={onClose}
            initialPosition="center"
            anchorElement={anchorElement}
            parentOffset={parentOffset}
        >
            <>
                <Text>
                    {name} ({businessLocationId})
                </Text>
                <CallHistoryForm businessLocationId={businessLocationId} onSave={refresh} />
            </>
        </DraggableDialog>
    );
}
