import {faStar} from "@fortawesome/free-solid-svg-icons";
import {Colors} from "library";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";

export type OptionType = {
    title: string;
    subtitle: string;
    highlight?: string;
    listItems?: string[];
    icon?: JSX.Element;
    cardItems?: { backgroundColor: string; textColor: string; text: string; }[];
    option: string;
};

export type CardItemType = {
    backgroundColor: string;
    textColor: string;
    text: string;
    icon?: IconDefinition;
};

export function usePaymentOptions() {
    return [
        {
            title: 'Substitute (One-time, short-term, ongoing coverage)',
            subtitle: 'Opportunity to onboard worker permanently if fit is mutual',
            option: 'SUBSTITUTE',
            cardItems: [
                {
                    text: 'Recommended, faster fill time',
                    backgroundColor: Colors.terraCotta,
                    textColor: Colors.white,
                    icon: faStar,
                },
            ],
        },
        {
            title: 'Permanent (Filling a current vacancy or future opening)',
            subtitle: 'Onboarded through trial period to ensure fit is mutual before permanent hire is made',
            option: 'TRIAL_RUN',
        },
    ];
}
