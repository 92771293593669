import { Star } from '@mui/icons-material';
import { ChildcareCenterExperienceQualification, Provider } from 'models';
import { ProviderQualifications } from 'parent-portal/provider-profile/providerProfileModels';
import ProviderSummaryDetailRow from 'parent-portal/provider-profile/ProviderSummaryDetailRow';
import React, { useEffect, useState } from 'react';
import Highlight from './Highlight';

export default function CredentialsHighlight({
    provider,
    qualifications,
    childcareCenterQualifications,
}: {
    provider: Provider;
    qualifications: ProviderQualifications;
    childcareCenterQualifications: ChildcareCenterExperienceQualification | null;
}) {
    const [occrraTrainings, setOccrraTrainings] = useState('');

    useEffect(() => {
        let trainingsFromOccrra = qualifications.professional_development_courses
            .filter((course) => course.verification_status === 'Verified')
            .slice(0, 3)
            .map((course) => course.title)
            .join(', ');
        setOccrraTrainings(trainingsFromOccrra || childcareCenterQualifications?.occrra_training_description || '');
    }, [qualifications.professional_development_courses, childcareCenterQualifications]);
    return (
        <Highlight title="Credentials">
            <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div style={{ width: '50%' }}>
                    {qualifications.cpl_level && (
                        <ProviderSummaryDetailRow icon={<Star />} detail={`CPL Level ${qualifications.cpl_level}`} />
                    )}
                    {provider.hasBCIFBICheck && (
                        <ProviderSummaryDetailRow icon={<Star />} detail="BCI/FBI Background Check" />
                    )}
                    {childcareCenterQualifications?.cpr_certification && (
                        <ProviderSummaryDetailRow icon={<Star />} detail="CPR Certified" />
                    )}
                    {childcareCenterQualifications?.first_aid_certification && (
                        <ProviderSummaryDetailRow icon={<Star />} detail="First Aid Certified" />
                    )}
                    {childcareCenterQualifications?.covid_vaccinated && (
                        <ProviderSummaryDetailRow icon={<Star />} detail="COVID Vaccinated" />
                    )}
                </div>
                <div style={{ width: '50%' }}>
                    {childcareCenterQualifications?.cdc_certification && (
                        <ProviderSummaryDetailRow icon={<Star />} detail="CDA Certificate" />
                    )}
                    {occrraTrainings && (
                        <ProviderSummaryDetailRow
                            icon={<Star />}
                            detail="OCCRRA Trainings"
                            subDetail={occrraTrainings}
                        />
                    )}
                </div>
            </div>
        </Highlight>
    );
}
