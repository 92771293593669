import { Grid } from '@mui/material';
import { LoadingSpinner, Text, Colors, IconButton } from 'library';
import { AdminProvider } from 'models';
import React from 'react';
import { useClient } from 'shared';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import OutreachCollectionStats from './OutReachCollectionStats';

interface AutomatedReference {
    id: number;
    provider_user: {
        first_name: string;
        last_name: string;
        phone_number?: string;
        id: number;
    };
    contact_info: {
        id: number;
        first_name: string;
        last_name: string;
        phone_number?: string;
        email?: string;
    };
    added_at: string;
    last_updated: string;
    uuid: string;
    pdf_created_at?: string;
    reference_text?: string;
    relationship_to_worker?: string;
    pdf_document?: number;
}
const BASE_REFERENCE_PAGE_LINK = 'https://www.join-tandem.com/provide-reference/';

export default function AutomatedReferences({ provider }: { provider: AdminProvider }) {
    const { data, loading } = useClient<AutomatedReference[]>({
        url: `api/automated-reference/?user_id=${provider.user.id}`,
    });

    if (loading) return <LoadingSpinner />;
    if (!data) return null;

    function convertDataToDisplay(reference: AutomatedReference) {
        return {
            contact_name: {
                label: 'Contact Name',
                value: `${reference.contact_info.first_name} ${reference.contact_info.last_name}`,
                copy: false,
            },
            relationship_to_worker: {
                label: 'Relationship to Worker',
                value: reference.relationship_to_worker,
                copy: true,
            },
            email: {
                label: 'Email Address',
                value: reference.contact_info.email,
                copy: true,
            },
            phone_number: {
                label: 'Phone Number',
                value: reference.contact_info.phone_number,
                copy: true,
            },

            reference_link: {
                label: 'Reference Link',
                value: `${BASE_REFERENCE_PAGE_LINK}${reference.uuid}`,
                copy: true,
            },
            pdf_status: {
                label: 'PDF Status',
                value: reference.pdf_created_at ? 'Created' : 'Not Created',
                copy: false,
            },
        };
    }

    return (
        <Grid>
            <Text variant="h2" bold textStyle={{ margin: 10, color: Colors.turquoise }}>
                Automated References
            </Text>
            <Grid item container style={{ gap: 20 }}>
                {data.map((reference) => (
                    <Grid
                        key={reference.id}
                        item
                        container
                        xs={12}
                        md={5}
                        lg={3}
                        direction="column"
                        style={{
                            padding: 10,
                            borderRadius: 18,
                            backgroundColor: Colors.lightTurq,
                            flexShrink: 1,
                            gap: 20,
                        }}
                    >
                        {Object.entries(convertDataToDisplay(reference)).map(([key, value]) => {
                            return value.value ? (
                                <Grid key={key} item container style={{ gap: 10, alignItems: 'center' }}>
                                    {value.copy ? <CopyIcon textToCopy={value.value} /> : null}
                                    <Text variant="body2" bold>
                                        {value.label}:
                                    </Text>
                                    <Text variant="body2">{value.value}</Text>
                                </Grid>
                            ) : null;
                        })}
                        <OutreachCollectionStats addedAt={reference.added_at} />
                    </Grid>
                ))}
            </Grid>
        </Grid>
    );
}

function CopyIcon({ textToCopy }: { textToCopy: string }) {
    return (
        <IconButton
            backgroundColor={Colors.white}
            onClick={(e) => {
                e.stopPropagation();
                navigator.clipboard.writeText(textToCopy);
            }}
            icon={ContentCopyIcon}
            size={24}
        />
    );
}
