import React from 'react';
import { track } from 'shared';
const DownloadImage = require('assets/images/google-play-badge.png');

const url =
    'https://play.google.com/store/apps/details?id=com.jointandem&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1';

export default function DownloadOnGooglePlayButton() {
    function handleOnClick() {
        track('Google Play Store Download Button Clicked');
        window.open(url, '_blank');
    }
    return (
        <div onClick={handleOnClick} style={{ cursor: 'pointer' }}>
            <img src={DownloadImage} alt="Get it on Google Play" style={{ maxWidth: 200, margin: 0, padding: 0 }} />
        </div>
    );
}
