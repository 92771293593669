import React from 'react';
import { OnboardingStep } from '../../OnboardingContext';
import GoBack from '../../shared/GoBack';
import LookAroundOption from '../../shared/LookAroundOption';
import Role from './Role';
import { Colors } from 'library';

const FLOATER_ID = 8;
const ASSISTANT_TEACHER_ID = 7;
const LEAD_TEACHER_ID = 6;

export default function RoleOptions() {
    return (
        <>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    gap: 10,
                    maxWidth: 450,
                    justifyContent: 'space-between',
                }}
            >
                <Role title="Floater" color={Colors.turquoise} optionId={FLOATER_ID} />
                <Role title="Assistant Teacher" color={Colors.lightRed} optionId={ASSISTANT_TEACHER_ID} />
                <Role title="Lead Teacher" color={Colors.mediumNavy} optionId={LEAD_TEACHER_ID} />
                <Role title="Other" color={Colors.mediumGreen} />
            </div>
            <LookAroundOption />
            <GoBack step={OnboardingStep.ShortOrLongTerm} />
        </>
    );
}
