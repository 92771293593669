import React, { useEffect, useState } from 'react';
import { RefreshIconButton, Text } from 'library';
import { IWorkerHistory, IWorkerHistoryProps } from './models';
import Reviews from './Reviews';
import ReliabilityStats from './ReliabilityStats';
import OnStaffBusinesses from './OnStaffBusinesses';
import { styled, Grid } from '@mui/material';
import useLoading from 'library/hooks/useLoading';
import { client, consoleLogInDev } from 'shared';
import UserPoints from 'internal/shared/UserPoints';

export default function WorkerHistory({ providerUserId }: IWorkerHistoryProps) {
    const [history, setHistory] = useState<IWorkerHistory>();
    const { loading, setLoading, LoadingSpinner } = useLoading();
    const [errorText, setErrorText] = useState<string>();

    useEffect(() => {
        if (providerUserId && providerUserId > 0) {
            refreshHistory();
        }
    }, [providerUserId]);

    function refreshHistory() {
        setLoading(true);
        setErrorText(undefined);
        client(`api/worker-history/${providerUserId}/`)
            .then(setHistory)
            .catch((error) => {
                setErrorText(error.message);
                consoleLogInDev(error);
            })
            .finally(() => setLoading(false));
    }

    if (loading) return <LoadingSpinner />;

    if (errorText)
        return (
            <Container>
                <Text>{errorText}</Text>
            </Container>
        );

    return (
        <Container container item>
            <Grid container item flexDirection="column">
                <Text variant="h1">
                    Worker History
                    <RefreshIconButton tooltip="Refresh worker history" onClick={refreshHistory} />
                </Text>
                {providerUserId && <UserPoints userId={providerUserId} />}
            </Grid>
            <OnStaffBusinesses businessLocations={history?.business_locations} providerUserId={providerUserId} />
            <ReliabilityStats overall_stats={history?.overall_stats} providerUserId={providerUserId} />
            <Reviews review_stats={history?.overall_stats?.reviews} reviews={history?.reviews} />
        </Container>
    );
}

const Container = styled(Grid)({
    padding: 20,
    gap: 20,
});
