import React, { ReactNode } from 'react';
import Text from './Text';
import { Colors } from '../../theme/Colors';

interface Props {
    children: ReactNode | ReactNode[];
}

export default function ErrorText({ children }: Props) {
    return (
        <Text variant="body2" color={Colors.error}>
            {children}
        </Text>
    );
}
