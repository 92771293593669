import React, { useRef } from 'react';
import { Grid, Typography, Popper, Button } from '@material-ui/core';
import LoadingButton from '../../../reusableComponents/loadingButton';
import { ExpandMore as ExpandMoreIcon, ExpandLess as ExpandLessIcon } from '@material-ui/icons';
import ProfileImage from '../../jobs/upcoming/ProfileImage';
import theme from '../../../theme';
import AnchorButton from './unpaid/AnchorButton';
import DiscountLabel from './unpaid/DiscountLabel';
import ErrorMessage from './unpaid/ErrorMessage';
import StyledListItem from './unpaid/StyledListItem';
import SurchargeItem from './unpaid/SurchargeItem';
import TotalLabel from './unpaid/TotalLabel';
import UnpaidStyles from './unpaid/UnpaidStyles';
import { ProviderRequestListItemProps } from '../Models/PairingList';
import { Colors, Text } from 'library';
import { PayCardPaper } from './PayPapers';

const ProviderRequestListItem = ({
    defaultPayIsCreditCard,
    discount,
    errorCount,
    errorMessage,
    loading,
    request,
    onCancel,
    onPayOpen,
    onSubmitPayment,
    onSurchargeDialogOpen,
    onSurchargeDialogClose,
    isSurchargeDialogOpen,
    onPaySetupClose,
}: ProviderRequestListItemProps) => {
    const itemEl = useRef<any>(null);

    const provider = request.provider;
    const classes = UnpaidStyles();

    const calculateRequestSurcharge = () => {
        if (!defaultPayIsCreditCard) {
            return 0;
        }
        return (request.amount - (discount || 0) + 0.3) / (1 - 0.029) - request.amount + (discount || 0);
    };

    return (
        <StyledListItem ref={itemEl}>
            <PayCardPaper>
                <Grid container direction="row" className="tour-todos-confirm" spacing={1}>
                    <Grid container item sm={2} xs={12} justify="center" alignItems="center">
                        <Grid item sm={12} xs={2}>
                            <ProfileImage id={provider.user.id} height={'100%'} width={'100%'} />
                        </Grid>
                    </Grid>
                    <Grid container item sm={6} xs={12} justify="center">
                        <Grid container item alignItems="center" justify="center">
                            <Grid item xs={12}>
                                <Typography
                                    variant="body2"
                                    style={{ color: Colors.darkNavy, fontWeight: 'bold' }}
                                    className={classes.root}
                                >
                                    {provider.user.first_name} {provider.user.last_name}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography
                                    variant="body2"
                                    style={{
                                        color: Colors.darkNavy,
                                        fontWeight: 'bold',
                                        fontSize: '0.85em',
                                    }}
                                    className={classes.root}
                                >
                                    {request.itemType === 'JOB' ? 'Job' : 'Reimbursement'} request
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container item sm={4} xs={12} alignItems="center" className={classes.extra}>
                        <AnchorButton
                            onPayOpen={onPayOpen}
                            pairing={request}
                            itemEl={itemEl}
                            isPairing={false}
                            buttonText="Review"
                        />
                        {request.anchor && (
                            <Popper
                                open={Boolean(request.anchor || false)}
                                anchorEl={request.anchor}
                                placement="bottom"
                            >
                                <Grid
                                    container
                                    justify="space-between"
                                    style={{
                                        width: itemEl.current ? itemEl.current.offsetWidth : '200px',
                                        padding: '1em',
                                        backgroundColor: '#F8F7F7',
                                        borderRadius: 10,
                                        border: `1px solid ${theme.palette.primary.main}`,
                                    }}
                                >
                                    <Grid container direction="column" item sm={5} alignItems="center">
                                        <Text variant="body2" bold>
                                            Amount
                                        </Text>
                                        <DiscountLabel discount={discount} />
                                        <TotalLabel
                                            amount={request.amount - (discount || 0) + calculateRequestSurcharge()}
                                            defaultPayIsCreditCard={defaultPayIsCreditCard}
                                        />
                                    </Grid>
                                    <Grid container item sm={6} direction="column" alignItems="center" spacing={2}>
                                        <Grid item>
                                            <Text variant="body2" bold>
                                                Reason
                                            </Text>
                                        </Grid>
                                        <Grid item>
                                            <Text variant="body2">{request.description}</Text>
                                        </Grid>
                                    </Grid>
                                    <SurchargeItem
                                        isDialogOpen={isSurchargeDialogOpen}
                                        onDialogClose={onSurchargeDialogClose}
                                        onDialogOpen={onSurchargeDialogOpen}
                                        surchargeAmount={calculateRequestSurcharge().toFixed(2)}
                                        defaultPayIsCreditCard={defaultPayIsCreditCard}
                                    />
                                    <Grid container justify="space-around" direction="row">
                                        <Grid item>
                                            <LoadingButton
                                                onClick={() => onCancel(request)}
                                                loading={loading}
                                                asSecondary={true}
                                            >
                                                Decline
                                            </LoadingButton>
                                        </Grid>
                                        <Grid item>
                                            <LoadingButton onClick={() => onSubmitPayment(request)} loading={loading}>
                                                Accept
                                            </LoadingButton>
                                        </Grid>
                                    </Grid>
                                    <ErrorMessage
                                        errorMessage={errorMessage}
                                        errorCount={errorCount}
                                        onModalClose={onPaySetupClose}
                                    />
                                </Grid>
                            </Popper>
                        )}
                    </Grid>
                </Grid>
            </PayCardPaper>
        </StyledListItem>
    );
};

export default ProviderRequestListItem;
