import React, { useEffect, useState } from 'react';
import 'react-infinite-calendar/styles.css';
import JobDescriptionPage from './components/pages/JobDescriptionPage';
import DateAndTimePage from './components/pages/DateAndTimePage';
import PayPage from './components/pages/PayPage';
import ProviderPreferencesJobFormPage from './components/pages/ProviderPreferencesPage/ProviderPreferencesJobFormPage';
import { JobFormContextProvider, useJobFormContext } from './JobFormContext';
import { useHistory } from 'react-router';
import HeadlineDescriptionPage from './components/pages/HeadlineDescriptionPage/HeadlineDescriptionPage';
import { useUserContext } from 'UserContext';
import TandemPayPage from './components/pages/TandemPayPage';
import useSignUpAction from 'parent-portal/login/SignUpAction';
import JobSuccessPage from './components/pages/ConfirmationPage/JobSuccessPage';
import { SizeableRoundedDialog as Dialog } from 'library';
import { useMediaQuery } from '@material-ui/core';
import theme from 'theme';
import { useModalsContext } from 'ModalsContext';
import OneTimeJobDetailsPage from './components/pages/SimpleJobForm/OneTimeJobDetailsPage';
import { isLoggedIn } from 'api/UserApi';

enum ConditionalPage {
    userInfo = 'userInfo',
    tandemPayAgreement = 'tandemPayAgreement',
    providerPreferences = 'providerPreferences',
    longFormPages = 'longFormPages',
}

function JobRequestForm() {
    const { currentPage, form, createdJob, setPageCount, previouslyAgreedToTandemPay, reset, simple } =
        useJobFormContext();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const xs = useMediaQuery(theme.breakpoints.only('xs'));
    const { fieldStates } = form ?? {};
    const { user } = useUserContext();
    const [conditionalPagesToInclude, setConditionalPagesToInclude] = useState<ConditionalPage[]>([]);
    const history = useHistory();
    const { SignUpWrapper, signUp } = useSignUpAction(() => history.push('/jobs'));
    const { jobFormOpen, closeJobForm } = useModalsContext();

    useEffect(() => {
        const includeTandemPayAgreementPage = !previouslyAgreedToTandemPay;
        const showProviderPreferences = isLoggedIn();

        const conditionalPages = [
            includeTandemPayAgreementPage && ConditionalPage.tandemPayAgreement,
            showProviderPreferences && ConditionalPage.providerPreferences,
            !simple && ConditionalPage.longFormPages,
        ].filter((pageApplies) => !!pageApplies) as ConditionalPage[];

        setConditionalPagesToInclude(conditionalPages);
    }, [fieldStates.isQuickFill.value, fieldStates.jobTypeId.value, previouslyAgreedToTandemPay, simple]);

    useEffect(() => {
        const addresses = user?.address;
        if (addresses?.length) {
            const mailingAddress = addresses.find((x) => x.address_type === 'PRIMARY');
            const addressId = mailingAddress ? mailingAddress.id : addresses[0].id;
            const busLocationId = mailingAddress?.business_location
                ? mailingAddress.business_location.id
                : addresses[0]?.business_location?.id;

            form.fieldStates.address.setValue([{ id: addressId, businessLocationId: busLocationId }]);
        }
    }, [user]);

    function completeFollowUps() {
        if (!!user?.first_name) {
            close();
            if (history.location.pathname.includes('/jobs')) history.go(0);
        } else {
            signUp(`${fieldStates.requestType.value} Job Form`);
        }
    }

    function close() {
        reset();
        closeJobForm();
    }

    function shouldInclude(page: ConditionalPage) {
        return conditionalPagesToInclude.includes(page);
    }

    const pages = [
        <JobDescriptionPage key="job-form-description" fieldStates={fieldStates} />,
        shouldInclude(ConditionalPage.longFormPages) ? <DateAndTimePage fieldStates={fieldStates} /> : null,
        shouldInclude(ConditionalPage.longFormPages) ? <HeadlineDescriptionPage fieldStates={fieldStates} /> : null,
        shouldInclude(ConditionalPage.longFormPages) && shouldInclude(ConditionalPage.providerPreferences) ? (
            <ProviderPreferencesJobFormPage name="family-requested-workers-page" />
        ) : null,
        shouldInclude(ConditionalPage.tandemPayAgreement) ? <TandemPayPage fieldStates={fieldStates} /> : null,
        shouldInclude(ConditionalPage.longFormPages) ? <PayPage fieldStates={fieldStates} /> : null,
        !shouldInclude(ConditionalPage.longFormPages) ? <OneTimeJobDetailsPage fieldStates={fieldStates} /> : null,
        <JobSuccessPage key="job-success-page" job={createdJob} onClose={completeFollowUps} />,
    ].filter((page) => page !== null);

    useEffect(() => {
        setPageCount(pages.length);
    }, [pages]);

    return (
        <Dialog
            open={jobFormOpen}
            onClose={createdJob ? completeFollowUps : close}
            closeButton
            style={{ width: isMobile ? undefined : '60%', maxWidth: 643, maxHeight: '95vh' }}
            contentStyle={{ marginTop: 20 }}
            fullScreen={xs}
        >
            {pages[currentPage]}
            <SignUpWrapper />
        </Dialog>
    );
}

export default function JobFormWithContext() {
    const { role } = useUserContext();
    if (role && role !== 'admin') {
        return (
            <JobFormContextProvider>
                <JobRequestForm />
            </JobFormContextProvider>
        );
    }
    return null;
}
