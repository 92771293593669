import { Button, withStyles } from '@material-ui/core';
import theme from '../../../../theme';

const LinkButton = withStyles({
    root: {
        background: 'inherit',
        border: 0,
        textDecoration: 'underline',
        textDecorationColor: theme.palette.primary.main,
        padding: 0,
        minWidth: 0,
        '&:hover': {
            textDecoration: 'underline',
            color: theme.palette.primary.main,
            outline: 'none',
            backgroundColor: 'inherit',
        },
        '&:focus': {
            outline: 'none',
        },
    },
    label: {
        padding: 0,
        fontSize: '0.7em',
        color: theme.palette.primary.main,
    },
})(Button);

export default LinkButton;
