import React from 'react';
import { useProviderProfileContext } from '../../ProviderProfileContext';
import BackgroundChecks from './BackgroundChecks';
import Certifications from './Certifications';

export default function ProfileProfileSafetyTab() {
    const { provider } = useProviderProfileContext();
    return (
        <>
            <BackgroundChecks provider={provider} />
            <Certifications provider={provider} />
        </>
    );
}
