import moment from 'moment';

interface Appointment {
    start_date: string;
    end_date: string;
    id: number;
}

export interface IPairing {
    id: number;
    status: string;
    added_at: Date;
    appointment: Appointment;
    user_id?: number;
}

export function toBookingDate(pairing: IPairing) {
    return moment(pairing.appointment.start_date).format('yyyy-MM-DD');
}
