import React, { Component } from 'react';
import styled from 'styled-components';
import theme from '../../../theme';
import Grid from '@material-ui/core/Grid';

const InfoSection = styled.div`
    border-radius: 6px;
    background-color: #f7f7f7;
    margin: 10px;
    padding: 10px;
    color: ${theme.palette.primary.main};
`;

const Category = styled.div`
    margin: 0px;
`;

const CategoryTitle = styled.p`
    color: ${theme.palette.primary.main};
    font-size: 12;
    font-weight: bold;
    margin-left: 20px;
    margin-top: 15px;
`;

const CategoryInfo = styled.div`
    background-color: #ffffff;
    border-radius: 6px;
    font-size: 12px;
    font-weight: regular;
    margin-top: 15px;
    margin-bottom: 15px;
    margin-left: 18px;
    margin-right: 18px;
    padding: 15;
`;
export default class FamilyDisplay extends Component {
    render() {
        const { family } = this.props;

        return (
            <div>
                <Grid container justify="center">
                    <Grid item sm={6} container>
                        <InfoSection id="family-info" style={{ marginBottom: 100, minWidth: '90%' }}>
                            <Category>
                                <Grid container item>
                                    <CategoryTitle>Bio</CategoryTitle>
                                    <CategoryInfo style={{ padding: 15, minWidth: '90%' }}>
                                        <Grid item>
                                            <p style={{ float: 'none', fontWeight: 'regular', fontSize: '14px' }}>
                                                {family.bio}
                                            </p>
                                        </Grid>
                                    </CategoryInfo>
                                </Grid>
                            </Category>
                        </InfoSection>
                    </Grid>
                </Grid>
            </div>
        );
    }
}
