import { JobTimesOfDay } from 'models';
import { formatDateFromDateObject, UpperDaysOfWeek } from 'shared/Dates';
import { client } from 'shared';
import { getMondayForNextWeek, getNextMonday } from '../weekdayConstants';
import { DayOfWeekTimesSelection } from './duplicateJobModels';

export function getDayForNextWeek(day: string, fromDate?: Date) {
    const newDate = fromDate ? getMondayForNextWeek(fromDate) : getNextMonday();
    newDate.setDate(newDate.getUTCDate() + Object.keys(UpperDaysOfWeek).indexOf(day));
    return newDate;
}

function formatTime(time: string) {
    return time.length === 8 ? time : `${time}:00`;
}

function toPostBody(headline: string, description: string, times: DayOfWeekTimesSelection[], week?: Date) {
    const selectedDays = times.filter((time) => time.selected);
    const ongoing = {
        start_date: formatDateFromDateObject(getDayForNextWeek(selectedDays[0].day, week)),
        end_date: formatDateFromDateObject(getDayForNextWeek(selectedDays[selectedDays.length - 1].day, week)),
        family_comments: description,
        days_of_week: selectedDays.map((day) => day.day),
        times_of_day: selectedDays.map((time) => ({
            [time.day]: { start: formatTime(time.start), end: formatTime(time.end) },
        })),
        headline: headline,
    };
    return ongoing;
}

export async function saveJob(
    jobId: number,
    headline: string,
    description: string,
    times: DayOfWeekTimesSelection[],
    week?: Date,
) {
    return await client(`api/admin-jobs/${jobId}/copy`, {
        body: toPostBody(headline, description, times, week),
    });
}

export function dayToJobTimesOfDay(times_of_day: JobTimesOfDay[], day: string) {
    const entry = times_of_day.find((time) => Object.keys(time)[0] === day);
    return {
        day,
        start: entry ? Object.values(entry)[0].start : '08:00',
        end: entry ? Object.values(entry)[0].end : '17:00',
        selected: !!entry,
    };
}
