import React from 'react';
import { ShiftProviderUser } from 'models';
import { Avatar } from '@material-ui/core';
import { Colors, Text } from 'library';
import { Link } from 'react-router-dom';

export default function ProviderDisplay({
    provider,
    containerStyles,
}: {
    provider: ShiftProviderUser;
    containerStyles?: React.CSSProperties;
}) {
    return (
        <div style={containerStyles}>
            <Avatar
                src={provider.profile_picture?.image ? provider.profile_picture.image : undefined}
                alt={`${provider.first_name} ${provider.last_name}`}
                style={{
                    margin: 0,
                    padding: 0,
                    height: 25,
                    width: 25,
                    backgroundColor: Colors.darkNavy,
                    fontSize: '1rem',
                    marginRight: 15,
                }}
            >
                {provider.first_name[0] + provider.last_name[0]}
            </Avatar>
            <Link to={`/provider/${provider.id}`}>
                <Text variant="body2" bold textStyle={{ textDecorationLine: 'underline', cursor: 'pointer' }}>
                    {provider.first_name} {provider.last_name}
                </Text>
            </Link>
        </div>
    );
}
