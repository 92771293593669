import { IconButtonProps } from '@material-ui/core';
import React from 'react';
import HelpIcon from '@material-ui/icons/Help';
import TinyIconButton from './TinyIconButton';

function TooltipIconButton({ children, ...props }: IconButtonProps) {
    return (
        <TinyIconButton {...props}>
            {children ? children : <HelpIcon color="primary" style={{ height: 12 }} />}
        </TinyIconButton>
    );
}

export default TooltipIconButton;
