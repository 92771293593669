import { DropDown } from 'library';
import { JobDetailOption, QualificationOption } from 'models';
import React from 'react';

export default function SingleSelect({
    options,
    value,
    onChange,
}: {
    options: QualificationOption[] | JobDetailOption[];
    value?: string;
    onChange: (val?: string) => void;
}) {
    return (
        <DropDown
            selected={value}
            onChange={(e) => onChange(e.target.value)}
            fields={options.map((option) => option.value)}
        />
    );
}
