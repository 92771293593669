import React from 'react';
import { Checkbox, Colors, Text } from 'library';
import { Grid, styled } from '@mui/material';

import Memo from './Memo';

import type { BusinessLocationBannedWorker } from 'models';

export default function Row({
    location,
    highlight,
    createdBy,
    checked,
    disabled,
    setCheckbox,
    memo,
    setMemo,
}: {
    location: BusinessLocationBannedWorker;
    highlight: boolean;
    createdBy?: string;
    checked: boolean;
    disabled: boolean;
    setCheckbox: (checked: boolean, id: string) => void;
    memo: { memo: string | null | undefined; dirty: boolean };
    setMemo: (id: number, memo: string) => void;
}) {
    return (
        <StyledGrid key={location.id} highlight={highlight.toString()}>
            <StyledTextGrid>
                <Text>{location.name || 'No location name found'}</Text>
                <ContainerRow>
                    <Text
                        variant="body2"
                        color={Colors.mediumGrey}
                    >{`${location.address.street}, ${location.address.city}, ${location.address.state}`}</Text>
                    <BottomRow>
                        {!!createdBy && <Text variant="caption">{`Created by ${createdBy}`}</Text>}
                        {highlight && (
                            <Text variant="caption" textStyle={{ color: Colors.darkGrey }}>
                                This location
                            </Text>
                        )}
                    </BottomRow>
                    <Memo dirty={memo.dirty} memo={memo.memo ?? null} setMemo={setMemo} locationId={location.id} />
                </ContainerRow>
            </StyledTextGrid>
            <Grid item>
                <Checkbox
                    label=""
                    checked={checked}
                    onChange={(checked: boolean) => setCheckbox(checked, location.id.toString())}
                    disabled={disabled}
                />
            </Grid>
        </StyledGrid>
    );
}

const StyledGrid = styled(Grid)(({ highlight }: { highlight: string }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    border: `solid 1px ${highlight === 'true' ? Colors.mediumTurq : 'transparent'}`,
    borderRadius: highlight ? '10px' : '0px',
    padding: highlight ? '0px' : '0px',
}));

const StyledTextGrid = styled(Grid)({
    marginBottom: '2em',
    padding: '4px 8px',
});

const ContainerRow = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
});

const BottomRow = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
});
