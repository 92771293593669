import { Grid, useMediaQuery } from '@mui/material';
import React from 'react';
import { Text } from 'library';
import Founders from 'assets/images/marketing-site/Founders.png';
import theme from 'theme';

export default function AboutUsHeader() {
    const smDown = useMediaQuery(theme.breakpoints.down('sm'));
    return (
        <Grid
            xs={12}
            style={{
                backgroundImage: `url(${Founders})`,
                backgroundSize: `100% 100%`,
                backgroundRepeat: 'no-repeat',
                backgroundColor: `rgba(249,217,220,.2)`,
                resize: 'both',
                height: smDown ? '40vh' : '75vh',
                overflow: 'hidden',
            }}
        >
            <Grid xs={12} item container style={{ padding: smDown ? 20 : 80 }} justifyContent="center">
                <Text variant={smDown ? 'h1' : 'display'} bold>
                    A note from the founders…
                </Text>
            </Grid>
        </Grid>
    );
}
