import React, { useState } from 'react';
import EditDialog from './EditDialog';
import BusinessDatesAndTimes from '../../DateAndTimePage/BusinessDatesAndTimes';

export default function EditSchedulesModal({
    open,
    onClose,
    headline,
}: {
    open: boolean;
    onClose: () => void;
    headline: string;
}) {
    const [isValid, setIsValid] = useState(false);

    return (
        <EditDialog title={`Edit ${headline} Schedule`} open={open} onClose={onClose} isValid={isValid}>
            <BusinessDatesAndTimes setIsValid={setIsValid} />
        </EditDialog>
    );
}
