import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { getUA } from 'react-device-detect';
import { client, consoleLogInDev } from 'shared';
import { uuid } from 'uuidv4';
import Cookies from 'universal-cookie';
import { isLoggedIn } from 'api/UserApi';

export enum PublicSessionEventType {
    Page = 'page',
    User = 'user',
}

interface TrackingRequestBody {
    request_token: string;
    puuid?: string;
    suuid?: string;
    action: string;
    desc?: string;
    event_type: string;
    path?: string;
    search?: string;
    lat?: number;
    lon?: number;
    component: string;
    other_info?: any;
    user_agent?: any;
    overall_tracking_code?: string | undefined;
    is_logged_in: boolean;
}

type UrlParams = {
    id: string;
};

export type EventAction =
    | 'NAVIGATE_FROM'
    | 'NAVIGATE_TO'
    | 'DIALOG_CLOSE'
    | 'DIALOG_OPEN'
    | 'SUBMIT'
    | 'CLICK'
    | 'SIGNUP'
    | 'ERROR';

const USER_UUID = 'puuid';
const SESSION_UUID = 'suuid';
const TRACKING_SOURCE = 'source';
const SESSION_REQUEST_ID = 'srid';
const GENERIC_ID = 'id';

const synchronousCookies = new Cookies();

export function getPublicUserInfo() {
    let requestToken = synchronousCookies.get(SESSION_REQUEST_ID);
    if (!requestToken) synchronousCookies.set(SESSION_REQUEST_ID, uuid(), { path: '/' });

    return {
        puuid: synchronousCookies.get(USER_UUID),
        suuid: synchronousCookies.get(SESSION_UUID),
        request_token: synchronousCookies.get(SESSION_REQUEST_ID),
    };
}

export async function trackEvent(
    eventAction: EventAction,
    eventDesc: string,
    eventType: PublicSessionEventType = PublicSessionEventType.Page,
    data: any = {},
    latitude: number,
    longitude: number,
    section: string,
    path: string,
    overallTrackingCode?: string,
) {
    const request: TrackingRequestBody = {
        ...getPublicUserInfo(),
        lat: latitude,
        lon: longitude,
        path,
        action: eventAction,
        desc: eventDesc,
        event_type: eventType,
        component: section,
        other_info: data,
        user_agent: getUA ? getUA : null,
        overall_tracking_code: overallTrackingCode ? overallTrackingCode : synchronousCookies.get(TRACKING_SOURCE),
        is_logged_in: isLoggedIn(),
    };

    try {
        const response = await client('tracking/api/session-event/track/', { body: request, method: 'POST' });

        if (response?.puuid) {
            let expireDate = new Date();
            let fullYear = expireDate.getFullYear();
            expireDate.setFullYear(fullYear + 1);
            synchronousCookies.set(USER_UUID, response?.puuid, { path: '/', expires: expireDate });
        }
        if (response?.suuid) {
            synchronousCookies.set(SESSION_UUID, response?.suuid, { path: '/' });
        }
        return response?.puuid;
    } catch (error) {
        consoleLogInDev(error);
    }
}

export default function usePublicSessionTracking(section: string) {
    const [latitude, setLatitude] = useState<number>(0);
    const [longitude, setLongitude] = useState<number>(0);
    const location = useLocation();

    const { id } = useParams<UrlParams>();

    function getPath() {
        const fullUrl = location?.pathname;
        let returnPath: string = '';
        if (!!id && !!fullUrl) {
            returnPath = fullUrl.replace(id, GENERIC_ID);
        } else if (!!fullUrl) {
            returnPath = fullUrl;
        }

        if (returnPath.length && !returnPath.endsWith('/')) {
            returnPath += '/';
        }
        return returnPath;
    }

    async function trackSession(
        eventAction: EventAction,
        eventDesc: string,
        eventType: PublicSessionEventType = PublicSessionEventType.Page,
        data: any = {},
        overallTrackingCode?: string,
    ) {
        data.path = location?.pathname;
        data.search = location?.search;

        return await trackEvent(
            eventAction,
            eventDesc,
            eventType,
            data,
            latitude,
            longitude,
            section,
            getPath(),
            overallTrackingCode,
        );
    }

    function usePageLoadTracking(data?: any) {
        useEffect(() => {
            trackSession('NAVIGATE_TO', 'Arrived on page', PublicSessionEventType.Page, data);
        }, []);
    }

    return { trackSession, usePageLoadTracking };
}
