import React from 'react';
import { Grid } from '@material-ui/core';
import { Text, IconButton } from 'library';
import { Delete } from '@material-ui/icons';
import { BreakPreference, EditBusinessPreference } from './models';
import { AddBreakPreference } from './AddBreakPreference';

export default function BreakRequirements({
    preference,
    breakPreferenceOptions,
    editing,
    updatePreference,
}: {
    preference: EditBusinessPreference;
    breakPreferenceOptions: BreakPreference[];
    updatePreference: (p: EditBusinessPreference) => void;
    editing: boolean;
}) {
    return (
        <Grid>
            <Text variant="body1" bold>
                Break Requirements
            </Text>
            <Text variant="caption" bold>
                For shifts of a certain length, we will require our providers to take the break you add below.
            </Text>
            <Grid container item direction="column" style={{ paddingTop: 20, gap: 20 }}>
                {!editing
                    ? preference.break_preferences?.map((b: any) => (
                          <Grid container item direction="row" style={{ gap: 20 }} alignItems="center">
                              <Text variant="body2">
                                  <Text variant="body2" bold inline>
                                      {b.display}:{' '}
                                  </Text>
                                  {b.shift_length} hour shifts require at least a{' '}
                                  <Text inline variant="body2" bold>
                                      {b.minutes_required} minute{' '}
                                  </Text>
                                  break
                              </Text>
                          </Grid>
                      ))
                    : null}
                {editing ? (
                    <AddBreakPreference
                        preference={preference}
                        breakPreferenceOptions={breakPreferenceOptions}
                        updatePreference={updatePreference}
                    />
                ) : null}
            </Grid>
        </Grid>
    );
}
