import React, { useState } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import { Colors, LoadingIndicator, PrimaryButton, SecondaryButton, Text } from 'library';
import { Checkbox, styled } from '@mui/material';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { subDays } from 'date-fns';

import { AppointmentBox, SearchWorkerAndPay, SelectTimes } from './index';
import { usePastAppointments } from '../hooks';

import type { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import type { ActualLeanUser } from 'models';
import type { LeanAppointment, PastHireRequest } from '../hooks';

export function InnerModal({
    ongoingRequestId,
    handleClose,
    pastHire,
}: {
    ongoingRequestId: number;
    handleClose: () => void;
    pastHire: {
        loading: boolean;
        status: number | undefined;
        data: { message: string } | null | undefined;
        send: (args: { body: PastHireRequest }) => void;
    };
}) {
    const [selectedWorker, setSelectedWorker] = useState<ActualLeanUser | null>(null);
    const [selectedAppointment, setSelectedAppointment] = useState<number | null>(null);
    const [createAppointment, setCreateAppointment] = useState<boolean>(false);
    const [appointmentDate, setAppointmentDate] = useState<Date>(subDays(new Date(), 1));
    const [workerStart, setWorkerStart] = useState<Date | null>(null);
    const [workerEnd, setWorkerEnd] = useState<Date | null>(null);
    const [pay, setPay] = useState<string | null>(null);

    const { loading: appointmentsLoading, data: appointments } = usePastAppointments({ ongoingRequestId });

    function handleCreateCheck() {
        setCreateAppointment(!createAppointment);
        !createAppointment ? setSelectedAppointment(null) : null;
    }

    function handleChooseAppointment(appointmentId: number) {
        setSelectedAppointment(appointmentId);
        setCreateAppointment(false);
    }

    function handleSave() {
        pastHire.send({
            body: {
                ongoing_request_id: ongoingRequestId,
                worker_id: selectedWorker?.id ?? null,
                appointment_id: selectedAppointment,
                appointment_date: createAppointment ? appointmentDate : null,
                start_time: workerStart,
                end_time: workerEnd,
                pay: pay ?? '',
            },
        });
    }

    const startAfterEnd = workerStart && workerEnd && workerStart.getTime() >= workerEnd.getTime();
    const saveDisabled =
        !selectedWorker ||
        (!selectedAppointment && !createAppointment) ||
        !workerStart ||
        !workerEnd ||
        !pay ||
        isNaN(workerStart?.getTime()) ||
        isNaN(workerEnd?.getTime()) ||
        startAfterEnd;

    if (appointmentsLoading) return <LoadingIndicator />;

    return (
        <>
            <OuterContainer>
                <Container>
                    <Text textStyle={{ paddingBottom: 10 }}>Select the appointment to add the worker to:</Text>
                    {appointments ? (
                        <AppointmentContainer>
                            {appointments.map((appointment: LeanAppointment) => (
                                <AppointmentBox
                                    key={appointment.id}
                                    appointment={appointment}
                                    selectedAppointment={selectedAppointment}
                                    setSelectedAppointment={handleChooseAppointment}
                                />
                            ))}
                        </AppointmentContainer>
                    ) : (
                        <Text
                            variant="body2"
                            textStyle={{ color: Colors.darkGrey, paddingBottom: 10, paddingLeft: 10 }}
                        >
                            No past unfilled appointments found.
                        </Text>
                    )}
                    <OrContainer>
                        <Text bold>OR</Text>
                    </OrContainer>
                    <div>
                        <CreateAppointmentContainer>
                            <Checkbox
                                checked={createAppointment}
                                onChange={handleCreateCheck}
                                style={{ color: Colors.darkNavy }}
                            />
                            <Text>Create a new appointment</Text>
                        </CreateAppointmentContainer>
                        <StyledPickerContainer>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <DatePicker
                                    InputProps={{
                                        inputProps: {
                                            style: { color: Colors.darkNavy },
                                        },
                                    }}
                                    label="Appointment Date"
                                    value={appointmentDate}
                                    onChange={(date: MaterialUiPickersDate | Date) => setAppointmentDate(date as Date)}
                                    maxDate={new Date()}
                                    variant="inline"
                                    disabled={!createAppointment}
                                />
                            </MuiPickersUtilsProvider>
                        </StyledPickerContainer>
                    </div>
                </Container>
                <Container>
                    <SelectTimes
                        workerStart={workerStart}
                        setWorkerStart={setWorkerStart}
                        workerEnd={workerEnd}
                        setWorkerEnd={setWorkerEnd}
                    />
                </Container>
                <Container>
                    <SearchWorkerAndPay
                        selectedWorker={selectedWorker}
                        setSelectedWorker={setSelectedWorker}
                        pay={pay}
                        setPay={setPay}
                    />
                </Container>
            </OuterContainer>
            {pastHire.status === 400 && !!pastHire.data?.message && (
                <Text variant="caption" textStyle={{ color: Colors.error, paddingTop: 10 }}>
                    Something, went wrong: {pastHire.data?.message}
                </Text>
            )}
            <ButtonContainer>
                <SecondaryButton buttonStyle={{ width: 'fit-content', marginRight: 10 }} onClick={handleClose}>
                    Cancel
                </SecondaryButton>
                <PrimaryButton
                    buttonStyle={{ width: 'fit-content' }}
                    onClick={handleSave}
                    disabled={saveDisabled || pastHire.loading}
                    loading={pastHire.loading}
                >
                    Save
                </PrimaryButton>
            </ButtonContainer>
        </>
    );
}

const OuterContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'scroll',
    maxHeight: '60vh',
});

const Container = styled('div')({
    backgroundColor: Colors.lightGrey,
    borderRadius: 10,
    marginTop: 10,
    padding: 10,
});

const AppointmentContainer = styled('div')({
    display: 'flex',
    overflowX: 'scroll',
    paddingRight: 20,
});

const OrContainer = styled('div')({
    display: 'flex',
    justifyContent: 'center',
    paddingTop: 10,
});

const CreateAppointmentContainer = styled('div')({
    alignItems: 'center',
    display: 'flex',
});

const StyledPickerContainer = styled('div')({
    display: 'flex',
    paddingLeft: 40,
});

const ButtonContainer = styled('div')({
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: 20,
});
