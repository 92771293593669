import React from 'react';
import { Grid, useMediaQuery } from '@material-ui/core';
import theme from '../../../theme';
import { Colors, PrimaryButton, Text } from 'library';
import { useModalsContext } from 'ModalsContext';
import type { IProviderSearch } from '../ProvidersSearchAndFilter/models';

export default function StaffSendJob({
    selectedProviders,
    resetSelectedProviders,
}: {
    selectedProviders: IProviderSearch[];
    resetSelectedProviders: () => void;
}) {
    const smDown = useMediaQuery(theme.breakpoints.down('sm'));
    const { openJobForm } = useModalsContext();
    return selectedProviders.length > 0 ? (
        <Grid
            container
            item
            style={{
                borderRadius: 18,
                backgroundColor: Colors.lightTurq,
                position: 'fixed',
                zIndex: 5,
                bottom: smDown ? 16 + 64 : 34,
                width: smDown ? '90vw' : '40vw',
                height: 100,
                boxShadow: '0 0 5px rgba(0,0,0,0.5)',
                padding: 20,
            }}
        >
            <Grid style={{ justifyContent: 'space-between', alignItems: 'center' }} container item direction="row">
                <Text variant="body1" bold>
                    Create a job request and send to selected staff
                </Text>
                <PrimaryButton
                    buttonStyle={{ width: 150 }}
                    onClick={() =>
                        openJobForm(
                            'Staff List',
                            {
                                pastProviders: selectedProviders.map((p) => {
                                    return {
                                        name: p.user.first_name,
                                        firstName: p.user.first_name,
                                        id: p.user.id,
                                        providerId: p.id,
                                    };
                                }),
                                isQuickFill: true,
                                pageNumber: 0,
                                frequency: 'onetime',
                            },
                            resetSelectedProviders,
                        )
                    }
                >
                    Post Job
                </PrimaryButton>
            </Grid>
        </Grid>
    ) : (
        <></>
    );
}
