import { Grid } from '@mui/material';
import { Text } from 'library';
import React, { forwardRef } from 'react';

const ThankYouBlock = forwardRef((props, ref: React.Ref<HTMLInputElement>) => {
    return (
        <Grid ref={ref} item container flexDirection="column" style={{ gap: 20 }}>
            <Text variant="h1">Thank you for being here.</Text>
            <Text>
                Tandem was founded in 2019 by three women in Ohio who were determined to make the care industry better
                for all parties involved.
            </Text>
        </Grid>
    );
});

export default ThankYouBlock;
