import React, { useState } from 'react';
import { Text, Colors, TextButton, Chip } from 'library';
import { Rating, styled, Grid } from '@mui/material';
import { IWorkerOverallStats } from '../models';
import ReviewModal from './ReviewModal';
import { IProviderReview } from 'models';
import { flatMap, groupBy } from 'lodash';

export default function Reviews({
    review_stats,
    reviews,
}: {
    review_stats?: IWorkerOverallStats['reviews'];
    reviews?: IProviderReview[];
}) {
    const [viewAll, setViewAll] = useState(false);

    function truncateReview(text: string) {
        const textLength = 100;
        if (text.length > textLength) {
            return text.substring(0, textLength) + '...';
        }
        return text;
    }

    function getTagCounts() {
        const tags = flatMap(reviews, (review) => {
            const usesOldStarRating = review.review_results.some((it) => it.value);
            return usesOldStarRating
                ? []
                : review.review_results.map((it) => ({ text: it.option.text, positive: review.total_score > 2 }));
        });
        const grouped = groupBy(tags, (it) => it.text + it.positive);
        return Object.values(grouped).map((it) => ({ ...it[0], count: it.length }));
    }

    const hasReviewStats = review_stats && Object.values(review_stats).some((v) => v !== null);

    if (!hasReviewStats || !reviews) return null;

    const viewableRevies = reviews.length > 2 ? reviews.slice(0, 2) : reviews;

    return (
        <Container container item>
            <Container container item alignContent="center">
                <Grid item>
                    <Text variant="h2" bold textStyle={{ width: 'unset' }}>
                        Reviews ({review_stats.count})
                    </Text>
                </Grid>
                <Rating value={review_stats.average} readOnly />
            </Container>
            <Grid container spacing={1}>
                {getTagCounts().map((tag) => (
                    <Grid key={tag.text + tag.positive} item>
                        <Chip
                            label={`${tag.text} (${tag.count})`}
                            textColor={Colors.white}
                            backgroundColor={tag.positive ? Colors.turquoise : Colors.red}
                        />
                    </Grid>
                ))}
            </Grid>
            <Grid>
                <List>
                    {viewableRevies.map((review: IProviderReview) =>
                        review.text ? (
                            <ListItem key={review.id}>
                                <Text key={review.id} variant="body1">
                                    {truncateReview(review.text)}
                                </Text>
                            </ListItem>
                        ) : null,
                    )}
                </List>
                <TextButton
                    onClick={() => setViewAll(true)}
                    buttonStyle={{
                        width: 'unset',
                        float: 'right',
                    }}
                >
                    View all
                </TextButton>
            </Grid>
            <ReviewModal reviews={reviews} viewAll={viewAll} setViewAll={setViewAll} />
        </Container>
    );
}

const Container = styled(Grid)({
    gap: 20,
});

const List = styled('ul')({
    listStyleType: 'disclosure-closed',
    color: Colors.darkNavy,
    gap: 20,
});

const ListItem = styled('li')({
    marginBottom: 20,
});
