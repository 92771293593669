import { IconButton, Link, withStyles } from '@material-ui/core';

// Reusable 'styled' components

const StyledLink = withStyles({
    root: {
        '&:hover': {
            outline: 'none',
        },
        '&:focus': {
            outline: 'none',
        },
    },
})(Link);

const StyledIconButton = withStyles({
    root: {
        padding: 0,
        '&:focus': {
            outline: 'none',
        },
    },
})(IconButton);

export { StyledIconButton, StyledLink };
