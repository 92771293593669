import { TableCell, TableRow } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { Text, Checkbox } from 'library';
import AdminTable from 'internal/shared/tables/AdminTable';
import { IWorkerBusinessLocation } from './models';
import { hire } from 'api/StaffInfoApi';
import { getWorkerHistory } from 'api/WorkerHistoryApi';
import { consoleLogInDev } from 'shared';

export default function OnStaffBusinesses({
    businessLocations,
    providerUserId,
}: {
    businessLocations?: IWorkerBusinessLocation[];
    providerUserId?: number;
}) {
    const [locations, setLocations] = useState<IWorkerBusinessLocation[]>([]);
    const columns = ['Location', 'Shifts', 'Rate', 'Permanently Hired'];
    const header = (
        <Text bold variant="h2">
            On Staff Businesses
        </Text>
    );

    useEffect(() => {
        if (businessLocations) {
            setLocations(businessLocations);
        }
    }, [businessLocations]);

    function getRate(business: IWorkerBusinessLocation) {
        return business.rate ? `$${business.rate}/hr` : 'N/A';
    }

    async function handleHire(checked: boolean, business: IWorkerBusinessLocation) {
        if (!providerUserId) return;

        try {
            await hire(providerUserId, business.id, checked);
            const history = await getWorkerHistory(providerUserId);
            setLocations(history.business_locations);
        } catch (error) {
            consoleLogInDev(error);
        }
    }

    return (
        <AdminTable columns={columns} header={header}>
            {locations.length > 0 ? (
                locations.map((location: IWorkerBusinessLocation) => (
                    <TableRow key={location.id}>
                        <TableCell>
                            <Text>{location.name}</Text>
                        </TableCell>
                        <TableCell>
                            <Text>{location.count}</Text>
                        </TableCell>
                        <TableCell>
                            <Text>{getRate(location)}</Text>
                        </TableCell>
                        <TableCell>
                            <Checkbox
                                checked={Boolean(location.hired)}
                                label={''}
                                onChange={(checked) => {
                                    handleHire(checked, location);
                                }}
                            />
                        </TableCell>
                    </TableRow>
                ))
            ) : (
                <TableRow>
                    <TableCell>
                        <Text>No completed shifts with businesses.</Text>
                    </TableCell>
                </TableRow>
            )}
        </AdminTable>
    );
}
