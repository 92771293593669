import React from 'react';
import { Grid } from '@mui/material';
import useTrackingCookies from 'parent-portal/shared/EngagementSourceTracking';
import usePublicSessionTracking from 'parent-portal/shared/PublicSessionTracking';
import useSignUpAction from 'parent-portal/login/SignUpAction';
import WorkerHeader from './components/WorkerHeader';
import Schedule from './components/Schedule';
import WorkerProfile from './components/WorkerProfile';
import JobTypes from './components/JobTypes';
import TandemPay from './components/TandemPay';

export default function Workers() {
    useTrackingCookies('/workers');
    const { usePageLoadTracking } = usePublicSessionTracking('workers');
    usePageLoadTracking();

    return (
        <Grid xs={12}>
            <WorkerHeader />
            <Schedule />
            <WorkerProfile />
            <JobTypes />
            <TandemPay />
        </Grid>
    );
}
