import React from 'react';
import { PastExperience } from 'models';
import { Text, Colors, TruncateText } from 'library';
import { Grid } from '@material-ui/core';
import ProviderProfileDetailRow from '../../ProviderProfileDetailRow';
import { Cake, DirectionsCar, EventAvailable } from '@material-ui/icons';
import { Calculate } from '@mui/icons-material';
import { GiCookingPot, GiBeachBall } from 'react-icons/gi';
import { FaBroom } from 'react-icons/fa';

export default function PastExperienceEntry({
    experience,
    childcareCenter = false,
}: {
    experience: PastExperience;
    childcareCenter?: boolean;
}) {
    const DetailText = ({ children }: { children: string | string[] }) => <Text variant="body2">{children}</Text>;
    const details = [
        {
            detail: <DetailText>{experience.children_count_string} Children</DetailText>,
            icon: <GiBeachBall style={{ fontSize: 11 }} />,
            show: !!experience.children_count_string,
        },
        {
            detail: <DetailText>Ages {experience.children_age_spread}</DetailText>,
            icon: <Cake style={{ fontSize: 13 }} />,
            show: !!experience.children_age_spread,
        },
        {
            detail: <DetailText>{experience.frequency_string}</DetailText>,
            icon: <EventAvailable style={{ fontSize: 13 }} />,
            show: !!experience.frequency_string,
        },
        {
            detail: <DetailText>Homework Help</DetailText>,
            icon: <Calculate style={{ fontSize: 13 }} />,
            show: experience.homework,
        },
        {
            detail: <DetailText>Transportation</DetailText>,
            icon: <DirectionsCar style={{ fontSize: 13 }} />,
            show: experience.transportation,
        },
        {
            detail: <DetailText>Cooking Help</DetailText>,
            icon: <GiCookingPot style={{ fontSize: 13 }} />,
            show: experience.cooking,
        },
        {
            detail: <DetailText>Cleaning</DetailText>,
            icon: <FaBroom style={{ fontSize: 11 }} />,
            show: experience.cleaning,
        },
    ];
    return (
        <Grid container direction="row">
            <Grid container item direction="column" xs={12} lg={8} style={{ paddingRight: 20 }}>
                <Text variant="h2" textStyle={{ textTransform: 'capitalize' }}>
                    {experience.title_string}
                </Text>
                {(experience.employer || experience.start_date) && childcareCenter ? (
                    <>
                        <Text variant="body1" color={Colors.mediumGrey} textStyle={{ marginBottom: 8 }}>
                            {experience.title_string}
                        </Text>
                        <Text variant="body1" color={Colors.mediumGrey} textStyle={{ marginBottom: 8 }}>
                            {experience.start_date} {experience.end_date ? ` - ${experience.end_date}` : ''}
                        </Text>
                    </>
                ) : experience.employer || experience.start_date ? (
                    <Text variant="body1" color={Colors.mediumGrey} textStyle={{ marginBottom: 8 }}>
                        {experience.employer} {experience.start_date}
                        {experience.end_date ? ` - ${experience.end_date}` : ''}
                    </Text>
                ) : null}
                <TruncateText text={experience.description || ''} length={600} style={{ marginBottom: 12 }} />
            </Grid>
            <Grid item xs={12} lg={4}>
                {details.map((detail) =>
                    detail.show ? (
                        <ProviderProfileDetailRow
                            icon={detail.icon}
                            detail={detail.detail}
                            style={{ marginBottom: 12 }}
                        />
                    ) : null,
                )}
            </Grid>
        </Grid>
    );
}
