import { Colors } from 'library';
import OptionCard from './OptionCard';
import React from 'react';
import BusinessIcon from '@material-ui/icons/Business';

export default function BusinessCard({
    smallerVersion = false,
    onPress,
}: {
    smallerVersion?: boolean;
    onPress: () => void;
}) {
    return (
        <OptionCard
            id="presignup-business-button"
            icon={<BusinessIcon style={{ color: Colors.turquoise }} />}
            label="Business"
            highlightDescription="Looking to hire"
            description="temporary or consistent employees for my company."
            action={onPress}
            smallerVersion={smallerVersion}
        />
    );
}
