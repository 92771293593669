import React from 'react';
import { Grid } from '@material-ui/core';
import Checkbox from './Checkbox';

interface CheckboxSelectProps {
    options: string[];
    additionalOptionText?: { [key: string]: string | undefined }[];
    additionalOptionStyles?: { [key: string]: React.CSSProperties }[];
    value: string[];
    onChange: (selectedValues: string[]) => void;
    isDisabled?: (option: string) => boolean;
}

export default function CheckboxSelect({
    options,
    additionalOptionText,
    additionalOptionStyles,
    value: values,
    onChange,
    isDisabled = () => false,
}: CheckboxSelectProps) {
    return (
        <Grid container direction="column" justify="flex-start" style={{ width: '100%' }}>
            {options.map((option: string) => (
                <Grid
                    key={option}
                    container
                    item
                    direction="row"
                    justify="flex-start"
                    style={{ alignItems: 'center', width: '100%' }}
                >
                    <Checkbox
                        checked={values.includes(option)}
                        disabled={isDisabled(option)}
                        onChange={(checked) => {
                            const updatedValues = checked
                                ? [...values, option]
                                : values.filter((value) => value !== option);
                            onChange(updatedValues);
                        }}
                        label={option}
                        // eslint-disable-next-line security/detect-object-injection
                        subLabel={additionalOptionText?.find((text) => text[option])?.[option]}
                        // eslint-disable-next-line security/detect-object-injection
                        subLabelStyle={additionalOptionStyles?.find((style) => style[option])?.[option]}
                    />
                </Grid>
            ))}
        </Grid>
    );
}
