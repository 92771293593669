import { UserContextUser } from 'models';
import { client } from 'shared';

export interface IPostBody {
    email: string;
    username: string;
    password1: string;
    password2: string;
}

export interface IPatchBody {
    username?: string;
    phone_number?: string;
    secondary_phone_number?: string;
    first_name?: string;
    middle_name?: string;
    last_name?: string;
    email?: string;
    is_active?: boolean;
    referral_code?: string;
    signup_page?: number;
    snoozed?: boolean;
    company?: string;
    points?: number;
    esid?: string;
    phone_verified?: boolean;
}

export interface IAddressBody {
    street?: string;
    state?: string;
    city?: string;
    zip_code: string;
    user?: number;
}

export async function getUser(tempToken?: string): Promise<UserContextUser> {
    const user = await client('api/users/identity/', { token: tempToken });
    return user;
}

export async function patchUser(id: number, body: IPatchBody, location: string, tempToken?: string | null) {
    const user = await client(`api/users/${id}/`, {
        method: 'PATCH',
        body,
        token: tempToken || '',
    });
    return user;
}

export async function postUserAddress(body: IAddressBody, tempToken: string) {
    return await client('api/addresses/', { body, token: tempToken });
}

export function isLoggedIn() {
    return !!localStorage.getItem('token');
}

export function logout() {
    if (isLoggedIn()) {
        client('api/auth/logout/', { method: 'POST' });
        localStorage.removeItem('token');
        localStorage.removeItem('role');
        window.location.reload();
    }
}

export function trackReferralEngagement(userId: number, path: string, context: string) {
    return client('tracking/api/referral-engagement', {
        method: 'POST',
        body: { user: userId, path, context },
    });
}

interface IRegisterUserInfo {
    email: string;
    password1: string;
    password2: string;
    phone_number: string;
    first_name: string;
    last_name: string;
    street: string;
    city: string;
    state: string;
    zip_code: string;
    business_name: string;
    business_type: string;
    program_id?: string;
    public_user_token?: string;
    referral_code?: string;
    source?: string;
}

interface IRegisterResponse {
    key: string;
    user: {
        pk: number;
        username: string;
        email: string;
        first_name: string;
        last_name: string;
    };
}

export function businessSignUp(requestBody: IRegisterUserInfo): Promise<IRegisterResponse> {
    return client('api/rest-auth/business-registration/', { body: requestBody });
}
