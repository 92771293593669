import React, { useState } from 'react';
import { BasicDialog, Text, OutlinedTextInput, PrimaryButton } from 'library';
import { Grid } from '@material-ui/core';
import { client, consoleLogInDev } from 'shared';
import { useUserContext } from 'UserContext';
import { isLoggedIn } from 'api/UserApi';
import AuthModal from 'parent-portal/login/AuthModal';

export default function BusinessEnrollmentModal({
    business,
    open,
    onClose,
}: {
    business: any;
    open: boolean;
    onClose: () => void;
}) {
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const { user } = useUserContext();

    consoleLogInDev(user);

    function submit() {
        setLoading(true);
        client(`api/business-enrollment-inquiry/`, {
            body: { business: business.id, family: user?.family[0].id, message: message },
        });
    }
    return isLoggedIn() ? (
        <BasicDialog closeButton isOpen={open} onClose={onClose}>
            <Text variant="h2" bold>
                Send a message
            </Text>
            <Text variant="body2">
                Introduce yourself here! Include which ages you are looking to enroll for and how often and you will
                hear back in 24-48 hours.
            </Text>
            <Grid item style={{ marginTop: 20 }}>
                <OutlinedTextInput
                    multiline
                    value={message}
                    style={{ backgroundColor: 'white', padding: 0, borderRadius: 15 }}
                    placeholder="Message"
                    rows={4}
                    fullWidth
                    onChange={(event: any) => setMessage(event.target.value)}
                />
            </Grid>
            <Grid container item xs={6} style={{ alignSelf: 'flex-end', marginTop: 15 }}>
                <PrimaryButton disabled={loading} onClick={submit}>
                    {!loading ? `Submit` : `Submitted`}
                </PrimaryButton>
            </Grid>
        </BasicDialog>
    ) : (
        <AuthModal
            location="Child Care Center Enrollment"
            open={open}
            onComplete={onClose}
            onClose={onClose}
            redirect={false}
        />
    );
}
