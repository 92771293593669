import React from 'react';
import { AppliedProvider } from 'models';
import { Text } from 'library';
import ApplicationDaysSummary from './ApplicationDaysSummary';

const ApplicationDaysDetail = ({ provider, allJobDays }: { provider: AppliedProvider; allJobDays: string[] }) => {
    const applicationDaysText = `${provider.user.first_name} can work ${provider.days_applied?.length} of ${allJobDays.length} days of your job`;
    return (
        <div style={{ display: 'flex', flexDirection: 'column', marginTop: 8 }}>
            <ApplicationDaysSummary allJobDays={allJobDays} daysAppliedTo={provider.days_applied || []} />
            <Text variant="deprecatedCaption" bold textStyle={{ lineHeight: '18px' }}>
                {applicationDaysText}
            </Text>
        </div>
    );
};

export default ApplicationDaysDetail;
