import { client } from 'shared';

import type { Note, PagingResult } from 'models';
import type { CallHistory } from 'models/CallHistory';
import type { EmailHistory } from 'internal/business-location/BusinessLocationOverview/components/LocationEmailHistory';

export interface CommunicationHistory {
    communication: CallHistory | EmailHistory | Note;
    communication_type: 'call' | 'email' | 'note';
}

export function listLocationCommunications(
    businessLocationId: number,
    pageNumber: number = 1,
    selectedEmail: string,
): Promise<PagingResult<CommunicationHistory>> {
    return client(
        `internal/business-location/${businessLocationId}/communication-history/?page=${pageNumber}&${selectedEmail && selectedEmail !== 'All' ? `to_email=${encodeURIComponent(selectedEmail)}` : ''}`,
    );
}
