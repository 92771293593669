import { useState, Dispatch, SetStateAction } from 'react';
import { LeanUser } from 'models';

const useSelectedUsers = (): [Array<LeanUser>, Dispatch<SetStateAction<LeanUser[]>>, (user: LeanUser) => void] => {
    const [selectedUsers, setSelectedUsers] = useState<Array<LeanUser>>([]);

    const selectUserOnChange = (user: LeanUser) => {
        setSelectedUsers((prevSelectedUsers) => {
            const selected = prevSelectedUsers.some((usr) => usr.id === user.id);

            if (selected) {
                return prevSelectedUsers.filter((usr) => usr.id !== user.id);
            } else {
                return [...prevSelectedUsers, user];
            }
        });
    };

    return [selectedUsers, setSelectedUsers, selectUserOnChange];
};

export default useSelectedUsers;
