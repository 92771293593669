import React from 'react';
import { Text, TextButton } from 'library';
import { Grid } from '@material-ui/core';
import { DocumentToVerify, DropDownField } from '../models';
import { downloadFile } from 'parent-portal/provider-profile/tabs/documents/DocumentShared';
import DocumentTypeSelection from './DocumentTypeSelection';

export default function EditDocumentType({
    form,
    updateDocType,
    currentDocTypeId,
}: {
    form: DocumentToVerify;
    updateDocType: (value: number) => void;
    currentDocTypeId?: number;
}) {
    if (!form.document_types) return null;
    const currentDocType = form.document_types.find((d) => d.document_type_id === currentDocTypeId);

    const docTypeOptions: DropDownField[] = form.document_types.map((d) => ({
        name: d.document_link ? d.friendly_name + ' (1)' : d.friendly_name,
        value: d.document_type_id.toString(),
    }));

    function updateDocument(document_type_id: number) {
        const new_type = form?.document_types?.find((d) => d.document_type_id === document_type_id);
        if (new_type) updateDocType(new_type.document_type_id);
    }

    const selectedDoc = docTypeOptions.find((d) => parseInt(d.value ?? '0') === currentDocType?.document_type_id);

    function downloadOtherDoc() {
        if (currentDocType?.document_link) {
            const link = currentDocType?.document_link.split('/api/')[1];
            downloadFile(`/api/${link}`);
        }
    }

    return (
        <Grid container item xs={12} direction="column" style={{ gap: 20 }}>
            <Text bold>Document Type: </Text>
            <DocumentTypeSelection
                selectedType={selectedDoc?.value ?? ''}
                updateSelected={updateDocument}
                documentTypes={docTypeOptions}
            />

            {currentDocType?.document_link ? (
                <TextButton onClick={downloadOtherDoc}>Existing document for this type download to view.</TextButton>
            ) : null}
        </Grid>
    );
}
