import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import { useCart } from './CartContext';
import CartRow from './CartRow';
import CartTotals from './CartTotals';
import Disclosure from './Disclosure';
import MvrInputs from './MvrInputs';
import { useStyles } from './CartStyles';
import CriminalCheckInputs from './CriminalCheckInputs';

const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});

const Cart = () => {
    const { cart, mvrInCart, criminalCheckInCart } = useCart();
    const cartStyles = useStyles();

    return cart?.length > 0 ? (
        <>
            {cart.map((itemRow) => (
                <CartRow key={itemRow.id} itemRow={itemRow} formatter={formatter} />
            ))}
            <CartTotals cart={cart} formatter={formatter} />
            {(mvrInCart || criminalCheckInCart) && (
                <Typography variant="subtitle2" align="left" style={{ marginBottom: 15 }}>
                    The following information is required to complete your background check.
                </Typography>
            )}
            <Grid container direction="column">
                {mvrInCart && <MvrInputs />}
                {criminalCheckInCart && <CriminalCheckInputs />}
            </Grid>
            <Disclosure />
        </>
    ) : (
        <Typography className={cartStyles.emptyCartText}>Your shopping cart is empty.</Typography>
    );
};

export default Cart;
