import React, { useEffect, useState } from 'react';
import { Text } from 'library';
import { Grid } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import { ConfirmHoursContent } from './ConfirmHoursContent';
import { IHourReportView } from 'internal/payments/HourReportModels';
import { getHourReports, getUnclockedInShifts } from 'api/HourReportApi';
import { IUserToClockIn, PagingResult } from 'models';
import { consoleLogInDev } from 'shared';
import UnclockedInShifts from './ConfirmSubHours/UnclockedInShifts';

export default function ConfirmSubHours() {
    const [hourReports, setHourReports] = useState<PagingResult<IHourReportView>>();
    const [unclockedInShifts, setUnclockedInShifts] = useState<IUserToClockIn[]>();
    const [pageNumber, setPageNumber] = useState(1);

    useEffect(() => {
        updateHoursReported(1);
        refreshUnclockedInShifts();
    }, []);

    function refreshUnclockedInShifts() {
        getUnclockedInShifts()
            .then((response) => {
                setUnclockedInShifts(response);
            })
            .catch(consoleLogInDev);
    }

    function updateHoursReported(page: number) {
        getHourReports(page).then(setHourReports);
    }

    function getTitle() {
        const hasUnpaid = hourReports?.results.some((x) => !x.paid && !x.admin_resolved);
        if (!hasUnpaid) {
            return;
        }

        return (
            <Grid item xs={12}>
                <Text variant="h2" bold>
                    There are hour reports that need your attention before they are paid.
                </Text>
            </Grid>
        );
    }

    function getUsersToClockIn() {
        if (!unclockedInShifts?.length) {
            return <></>;
        }

        return (
            <Grid container>
                <Grid item xs={12} sm={6}>
                    <Text variant="h1" bold>
                        Clock in workers
                    </Text>
                    <Text>These workers have yet to clock in for their shifts.</Text>
                    <UnclockedInShifts
                        workersToClockIn={unclockedInShifts || []}
                        onShiftClockIn={refreshUnclockedInShifts}
                    />
                </Grid>
            </Grid>
        );
    }

    return (
        <Grid style={{ display: 'flex', flexDirection: 'column', gap: 10, padding: 20, paddingBottom: 100 }}>
            {getUsersToClockIn()}
            <Text variant="display">Confirm Job Hours</Text>
            {getTitle()}
            <ConfirmHoursContent
                hourReports={hourReports?.results || []}
                updateHoursReported={() => updateHoursReported(pageNumber)}
            />
            <Pagination
                count={hourReports?.num_pages}
                page={pageNumber}
                onChange={(event, page) => {
                    setPageNumber(page);
                    updateHoursReported(page);
                }}
                siblingCount={2}
                boundaryCount={2}
            />
        </Grid>
    );
}
