import { useJobFormContext } from 'parent-portal/forms/JobFormContext';
import React from 'react';
import JobFormPage from '../JobFormPage';
import JobFaqForm from './JobFaqForm';

export default function JobFaqPage({ name }: { name: string }) {
    const { loading, goToNext, form } = useJobFormContext();
    const faqs = form.fieldStates.faqs;

    return (
        <JobFormPage
            title={'Have time to tell us a little more?'}
            onNextClicked={() => goToNext(name)}
            nextText={'Next'}
            nextDisabled={loading}
            id={name}
        >
            <JobFaqForm faqs={faqs.value} setFaqs={faqs.setValue} />
        </JobFormPage>
    );
}
