import { makeStyles } from '@material-ui/core';

const UnpaidStyles = makeStyles((theme) => ({
    root: {
        [theme.breakpoints.only('xs')]: {
            'justify-content': 'center',
            textAlign: 'center',
        },
        [theme.breakpoints.up('sm')]: {
            'justify-content': 'flex-start',
        },
    },
    extra: {
        [theme.breakpoints.only('xs')]: {
            'justify-content': 'center',
        },
        [theme.breakpoints.up('sm')]: {
            'justify-content': 'flex-end',
        },
    },
}));

export default UnpaidStyles;
