import { BusinessJobWithUser, PagingResult } from 'models';
import { client, queryStringifyObject } from 'shared';
import { ApplicantSearchFilters, BlockedApplicant, BusinessApplicant, ApplicantsQueryParams } from './ApplicantModels';

export async function getBusinessApplicantJob(id: number): Promise<BusinessJobWithUser> {
    return await client(`api/business-applicant/${id}/job/`);
}

export function getBusinessApplicants(requestParams: ApplicantsQueryParams): Promise<BusinessApplicant[]> {
    return client(`api/business-applicant/?${queryStringifyObject(requestParams)}`);
}

export async function getBlockedBusinessApplicants(
    searchFilters: ApplicantSearchFilters,
): Promise<PagingResult<BlockedApplicant>> {
    const params = queryStringifyObject(searchFilters);
    return await client(`api/business-applicant/?${params}`);
}

export function ignore(applicationId: number) {
    return client(`api/business-applicant/${applicationId}/`, {
        method: 'PATCH',
        body: { ignored: true },
    });
}

export function unignore(applicationId: number) {
    return client(`api/business-applicant/${applicationId}/`, {
        method: 'PATCH',
        body: { ignored: false },
    });
}
