import { Colors } from 'library';
import OptionCard from './OptionCard';
import React from 'react';
import { useHistory } from 'react-router';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';

export default function ProviderCard({
    smallerVersion = false,
    onClose = () => null,
}: {
    smallerVersion?: boolean;
    onClose?: () => void;
}) {
    const history = useHistory();

    function navigate() {
        const url = '/workers';
        history.push(url);
        if (onClose) {
            onClose();
        }
    }

    return (
        <OptionCard
            icon={<AssignmentIndIcon style={{ color: Colors.turquoise }} />}
            label="Worker"
            highlightDescription="Looking to pick up shifts"
            description="in child care centers, either short-term or permanently."
            action={navigate}
            smallerVersion={smallerVersion}
        />
    );
}
