import React from 'react';
import { useJobFormContext } from 'parent-portal/forms/JobFormContext';
import JobFormPage from '../JobFormPage';
import { FieldStates } from '../../FormTypes';
import JobTitleInput from './JobTitleInput';
import JobDescriptionInput from './JobDescriptionInput';
import { Grid } from '@material-ui/core';

interface IHeadlineDescriptionPage {
    headline: string;
    comments: string;
}

interface IProps {
    fieldStates: FieldStates<IHeadlineDescriptionPage>;
}

export default function HeadlineDescriptionPage({ fieldStates: { headline, comments } }: IProps) {
    const { goToNext } = useJobFormContext();

    function pageIsValid() {
        return headline.isValid && comments.isValid;
    }

    return (
        <JobFormPage
            onNextClicked={() => goToNext('family-headline-description-page')}
            nextDisabled={!pageIsValid()}
            id="description-page"
        >
            <Grid container direction="column" style={{ marginTop: 20 }}>
                <Grid style={{ flexGrow: 1 }}>
                    <JobTitleInput title={headline} />
                </Grid>
                <Grid style={{ flexGrow: 1, marginTop: 20 }}>
                    <JobDescriptionInput description={comments} />
                </Grid>
            </Grid>
        </JobFormPage>
    );
}
