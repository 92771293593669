import React from 'react';
import { ArrowForward } from '@mui/icons-material';
import IconButton from 'library/components/buttons/IconButton';
import { useMediaQuery } from '@material-ui/core';
import theme from 'theme';

function OffsetArrowForward(props: any) {
    return <ArrowForward {...props} style={{ marginLeft: 11, ...props.style }} />;
}

interface NextButtonProps {
    onClick: () => void;
    disabled?: boolean;
}

export default function NextButton({ onClick, disabled }: NextButtonProps) {
    const small = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <IconButton
            size={small ? 45 : 70}
            icon={OffsetArrowForward}
            onClick={onClick}
            disabled={disabled ? disabled : false}
        />
    );
}
