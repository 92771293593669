import { withStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import theme from '../theme';

const getBackgroundColor = (props) => {
    if (props.variant !== 'outlined') {
        if (props.color === 'red') {
            return theme.palette.error.main;
        } else if (props.color === 'yellow') {
            return theme.palette.info.main;
        } else if (props.color) {
            return props.background;
        } else {
            return theme.palette.primary.main;
        }
    } else {
        return 'white';
    }
};

const getHoverColor = (props) => {
    if (props.color === 'red') {
        return theme.palette.error.dark;
    } else if (props.color === 'yellow') {
        return theme.palette.info.dark;
    } else if (props.color) {
        return props.color;
    } else {
        return theme.palette.primary.dark;
    }
};

const getDisabledColor = (props) => {
    if (props.color === 'red') {
        return theme.palette.error.light;
    } else if (props.color === 'yellow') {
        return theme.palette.info.light;
    } else {
        return theme.palette.primary.main;
    }
};

const getLabelColor = (props) => {
    if (props.color === 'red') {
        return 'white';
    } else if (props.color === 'yellow') {
        return theme.palette.primary.main;
    } else if (props.dark || props.variant == 'outlined') {
        return theme.palette.primary.main;
    } else {
        return 'white';
    }
};

const getBorder = (props) => {
    if (props.variant == 'outlined') {
        return theme.palette.primary.main;
    }
};

const StyledButton = withStyles({
    root: (props) => ({
        margin: props.margin,
        minWidth: props.noMinWidth ? 0 : '200px',
        width: props.width,
        background: getBackgroundColor(props),
        borderRadius: 10,
        height: 35,
        padding: props.padding || '0 50px',
        textTransform: 'none',
        borderColor: getBorder(props),
        '&:focus': {
            outline: 'none',
        },
        '&:hover': {
            backgroundColor: getHoverColor(props),
            // Reset on touch devices, it doesn't add specificity
            '@media (hover: none)': {
                backgroundColor: getHoverColor(props),
            },
            '&$disabled': {
                color: props.color !== 'red' ? theme.palette.primary.light : theme.palette.error.light,
            },
        },
    }),
    disabled: (props) => ({
        background: props.color !== 'red' ? theme.palette.primary.light : theme.palette.error.light,
    }),
    label: (props) => ({
        color: getLabelColor(props),
        '&:hover': {
            color: 'white',
        },
    }),
})(Button);

export default StyledButton;
