import React, { useState } from 'react';
import moment from 'moment';
import { OutlineButton } from 'library';
import { consoleLogInDev } from 'shared';
import { suspendUser, unSuspendUser, sendSuspensionNotification } from './OutreachApi';
import { AdminProviderListUser } from 'models';
import SuspendReasonModal from './SuspendReasonModal';

export default function OutreachSuspendedBtn({
    user,
    getProvider,
}: {
    user: AdminProviderListUser;
    getProvider: () => void;
}) {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleClick = () => {
        if (user.suspended_at) {
            handleUnsuspend();
        } else {
            setIsModalOpen(true);
        }
    };

    const handleUnsuspend = () => {
        unSuspendUser(user.id).then(getProvider).catch(consoleLogInDev);
    };

    const handleSuspend = (reason: string, details?: string) => {
        const now = moment().toDate();

        suspendUser(user.id, now, reason, details)
            .then(() => {
                getProvider();
                sendSuspensionNotification(user.id);
                onModalClose();
            })
            .catch(consoleLogInDev);
    };

    function onModalClose() {
        setIsModalOpen(false);
    }

    return (
        <>
            <OutlineButton buttonStyle={{ marginRight: 20 }} onClick={() => handleClick()}>
                {user.suspended_at ? `Un-Suspend` : `Suspend User`}
            </OutlineButton>
            <SuspendReasonModal isModalOpen={isModalOpen} onSuspend={handleSuspend} onClose={onModalClose} />
        </>
    );
}
