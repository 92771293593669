import { AutomatedReference } from './models';

export function formatPhoneNumber(inputValue: string | undefined) {
    const cleaned = ('' + inputValue).replace(/\D/g, '');
    const match = cleaned.match(/^(1)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        return ['(', match[2], ') ', match[3], '-', match[4]].join('');
    } else {
        return inputValue;
    }
}

export function formInitializer(automatedReference: AutomatedReference) {
    return {
        first_name: automatedReference?.contact_info?.first_name || '',
        last_name: automatedReference?.contact_info?.last_name || '',
        email: automatedReference?.contact_info?.email || '',
        phone_number: formatPhoneNumber(automatedReference?.contact_info?.phone_number) || '',
        relationship: '',
        relationship_free_input: undefined,
        reference_text: '',
    };
}

export function errorStateInitializer() {
    return {
        first_name: {
            path: 'first_name',
            label: 'First Name',
            inErrorState: false,
            message: '',
        },
        last_name: {
            path: 'last_name',
            label: 'Last Name',
            inErrorState: false,
            message: '',
        },
        email: {
            path: 'email',
            label: 'Email Address',
            inErrorState: false,
            message: '',
        },
        phone_number: {
            path: 'phone_number',
            label: 'Phone Number',
            inErrorState: false,
            message: '',
        },
        relationship: {
            path: 'relationship',
            label: 'Relationship',
            inErrorState: false,
            message: '',
        },
        relationship_free_input: {
            path: 'relationship_free_input',
            label: 'Relationship: Other',
            inErrorState: false,
            message: '',
        },
        reference_text: {
            path: 'reference_text',
            label: 'Reference Text',
            inErrorState: false,
            message: '',
        },
    };
}
