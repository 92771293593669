import React, { useState } from 'react';
import { BusinessJobWithAppointments } from 'models';
import { FileCopy } from '@mui/icons-material';
import { IconButton } from 'library';
import DuplicateSubJobModal from './DuplicateSubJobModal';

export default function DuplicateSubJob({ job, week }: { job: BusinessJobWithAppointments; week?: Date }) {
    const [open, setOpen] = useState(false);
    return job.business_job_type === 'SUBSTITUTE' ? (
        <span style={{ marginLeft: 10 }}>
            <IconButton tooltip="Duplicate this job to next week" icon={FileCopy} onClick={() => setOpen(true)} />
            <DuplicateSubJobModal job={job} week={week} open={open} onClose={() => setOpen(false)} />
        </span>
    ) : null;
}
