import React from 'react';
import { Grid, CircularProgress } from '@mui/material';

export default function Loading() {
    return (
        <Grid container item xs={9} style={{ height: '100vh', width: '100%' }}>
            <Grid container style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '25%' }}>
                <Grid item>
                    <CircularProgress />
                </Grid>
            </Grid>
        </Grid>
    );
}
