import { Colors, Text } from 'library';
import React, { useState } from 'react';

import { CardElement } from 'react-stripe-elements';
import CreditCardIcon from 'assets/icons/tandempay/credit-card-circle.svg';
import Link from '../../../../../../reusableComponents/link';
import { useStripe } from '../../StripeContext';
import client from 'shared/ApiClient';
import { useUserContext } from 'UserContext';
import { track } from 'shared/Tracking';
import ErrorText from '../../../ErrorText';
import PageHeader from '../../PageHeader';
import PageActionsFooter from '../../PageActionsFooter';
import ModalPage from '../../ModalPage';
import PageContent from '../../PageContent';
import FormArea from '../../FormArea';
import BillingAddressSection from './BillingAddressSection/BillingAddressSection';
import { Grid } from '@material-ui/core';

interface IntroPageProps {
    goToSuccess: () => void;
    goBack: () => void;
}

const stripeCardInputStyle = {
    base: {
        fontSize: '16px',
        fontWeight: 'normal',
        color: Colors.grey,
        fontFamily: 'Work Sans',
        letterSpacing: 'normal',
        '::placeholder': {
            color: '#aab7c4',
        },
        width: '100%',
    },
    invalid: {
        color: Colors.red,
    },
};

export default function ConnectCreditCardPage({ goToSuccess, goBack }: IntroPageProps) {
    const { stripe, stripeLoaded } = useStripe();
    const { user } = useUserContext();

    const [errorMessage, setErrorMessage] = useState<string>();
    const [loading, setLoading] = useState(false);

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [address, setAddress] = useState('');
    const [address2, setAddress2] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('Ohio');
    const [zip, setZip] = useState('');
    const [country, setCountry] = useState('US');

    function onCardDataChanged({ error }: stripe.elements.ElementChangeResponse) {
        setErrorMessage(error?.message);
    }

    async function submit() {
        setLoading(true);
        const createToken = stripe.createToken as () => Promise<stripe.TokenResponse>;
        const tokenResponse = await createToken();

        if (tokenResponse.error) {
            setErrorMessage(tokenResponse.error.message);
        } else {
            await updateAccount(tokenResponse.token!);
            goToSuccess();
        }
        setLoading(false);
    }

    async function updateAccount(token: stripe.Token) {
        const response = await client('get-account/', {
            method: 'POST',
            body: {
                stripe_token: token.id,
                id: user?.id,
                stripe_card_id: token.card!.id,
                first_name: firstName,
                last_name: lastName,
                address: address,
                address_2: address2,
                city: city,
                state: state,
                postal_code: zip,
                country: country,
            },
        });
        if (response.Error != null) {
            alert(response.Error);
            track('Credit Card Setup', { status: 'failed', error: response.Error });
            client('payment/api/setup-events/', { body: { data: response, processor: 'Stripe' } });
        } else {
            track('Credit Card Setup', { status: 'success' });
        }
    }
    const BillingAddressFilledOut =
        lastName !== '' && address !== '' && city !== '' && state !== '' && zip !== '' && country !== '';

    const formDisabled = loading || !stripeLoaded;

    return (
        <ModalPage>
            <PageHeader
                iconSource={<CreditCardIcon />}
                headerText="Connect Credit Card"
                subtitleText={
                    <>
                        Tandem uses{' '}
                        <Link target="_blank" href="https://stripe.com/">
                            Stripe
                        </Link>{' '}
                        to complete all credit card processing. Stripe incurs a $0.30 + 2.9% additional fee for handling
                        each credit card transaction.
                    </>
                }
            />
            <PageContent>
                <Grid container style={{ padding: '0px 40px' }}>
                    <Grid container xs={12} md={6}>
                        <Grid container item style={{ paddingTop: 40 }}>
                            <Grid container item>
                                <Text variant="body1">Card Details</Text>
                            </Grid>
                            <Grid container item style={{ paddingTop: 10 }}>
                                <div
                                    style={{
                                        borderBottom: `1px solid ${Colors.grey}`,
                                        width: '100%',
                                    }}
                                >
                                    <CardElement
                                        style={stripeCardInputStyle}
                                        onChange={onCardDataChanged}
                                        disabled={formDisabled}
                                    />
                                </div>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid container item style={{ paddingTop: 50 }}>
                                <BillingAddressSection
                                    firstName={firstName}
                                    setFirstName={setFirstName}
                                    lastName={lastName}
                                    setLastName={setLastName}
                                    address={address}
                                    setAddress={setAddress}
                                    address2={address2}
                                    setAddress2={setAddress2}
                                    city={city}
                                    setCity={setCity}
                                    state={state}
                                    setState={setState}
                                    zip={zip}
                                    setZip={setZip}
                                    country={country}
                                    setCountry={setCountry}
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container style={{ paddingTop: 30 }}>
                        {errorMessage ? <ErrorText>{errorMessage}</ErrorText> : null}
                    </Grid>
                </Grid>
            </PageContent>
            <PageActionsFooter
                save={submit}
                goBack={goBack}
                loading={loading}
                disabled={formDisabled || !BillingAddressFilledOut}
            />
        </ModalPage>
    );
}
