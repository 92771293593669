import { useJobFormContext } from 'parent-portal/forms/JobFormContext';
import React from 'react';
import { FieldStates } from '../FormTypes';
import JobFormPage from './JobFormPage';
import JobCategoryInput from '../JobCategoryInput';
import OtherJobCategoryInput from '../OtherJobCategoryInput';
import JobFrequencyInput from '../JobFrequencyInput';
import { JobFormFields } from 'parent-portal/forms/JobRequestTypes';
import Categories from './JobTypePage/Categories';

interface JobDescriptionPageProps {
    fieldStates: FieldStates<JobFormFields>;
}

export default function JobDescriptionPage({
    fieldStates: { otherJobType, requestType, jobTypeId },
}: JobDescriptionPageProps) {
    const { goToNext, jobTypes } = useJobFormContext();

    function onNextClicked() {
        pageIsValid() && goToNext('family-job-type-page');
    }

    function pageIsValid() {
        return otherJobType.isValid && requestType.isValid && jobTypeId.isValid;
    }

    function updateJobType(jobType: string) {
        const selected = jobTypes.find((x) => x.name === jobType);
        jobTypeId.setValue(selected?.id ?? -2);
        if (jobType === 'House Cleaning') {
            requestType.setValue('onetime');
        }
    }

    return (
        <JobFormPage
            title="Let's create your job."
            onNextClicked={onNextClicked}
            backText="Cancel"
            nextDisabled={!pageIsValid()}
        >
            <div style={{ height: 30 }} />
            <Categories
                updateJobType={updateJobType}
                selectedJobType={jobTypes.find((x) => x.id === jobTypeId.value)}
                jobTypes={jobTypes}
            />
            <JobCategoryInput jobTypeId={jobTypeId} jobTypes={jobTypes} />
            <OtherJobCategoryInput jobTypeId={jobTypeId} otherJobType={otherJobType} />
            <JobFrequencyInput requestType={requestType} jobTypeId={jobTypeId} jobTypes={jobTypes} />
        </JobFormPage>
    );
}
