import React, { useState, useEffect } from 'react';
import { IPayFormFields, IPayScale } from 'parent-portal/forms/JobRequestTypes';
import { Qualification } from 'models';
import { Text } from 'library';
import { Grid } from '@mui/material';
import { useUserContext } from 'UserContext';
import { client, getPricingPlan } from 'shared';
import { groupBy, sortBy } from 'lodash';
import { uuid } from 'uuidv4';

import { FormFieldProps } from '../../FormTypes';
import { useFieldValidation } from '../../FormHook';
import BusinessPayInputHeader from './BusinessPayInputHeader';
import BusinessPayInputSection from './BusinessPayInputSection';
import { getErrorIds, validRates, refreshPayScales, transformScales } from './utils/common';
import { BusinessPayContext } from './utils/BusinessPayContext';

interface PayPageProps {
    pay: FormFieldProps<IPayFormFields>;
    payScales: FormFieldProps<IPayScale[]>;
    defaultPayScales: IPayScale[];
    updateDefaultPayScales: (value: IPayScale[]) => void;
    hasSeenTrainingsModal: boolean;
    updateHasSeenTrainingsModal: () => void;
}

export interface IManagedScale extends IPayScale {
    id: string;
}

export function payRangeInfoValid(payScales: IPayScale[]) {
    const sortedPay = Object.entries(groupBy(payScales, 'rate'));

    return validRates(sortedPay);
}

export default function BusinessPayInputs({
    pay,
    payScales,
    defaultPayScales,
    updateDefaultPayScales,
    hasSeenTrainingsModal,
    updateHasSeenTrainingsModal,
}: PayPageProps) {
    const { user } = useUserContext();
    const initialScales = defaultPayScales?.length > 0 ? defaultPayScales : payScales.value;
    const sortedDefaults = sortBy(defaultPayScales, 'rate');

    const [payMin, setPayMin] = useState(sortedDefaults?.[0]?.rate ?? pay.value.payMin);
    const [payMax, setPayMax] = useState(sortedDefaults?.slice(-1)?.[0]?.rate ?? pay.value.payMax);
    const [qualifications, setQualifications] = useState<Qualification[]>([]);
    const [managedScales, setManagedScales] = useState<IManagedScale[]>(
        initialScales.filter((x: IPayScale) => payMin <= x.rate && x.rate <= payMax).map((x) => ({ ...x, id: uuid() })),
    );
    const [errorScales, setErrorScales] = useState<string[]>([]);

    const getQualifications = () => {
        client('api/qualification/').then(setQualifications);
    };

    useEffect(getQualifications, []);

    const pricingPlan = getPricingPlan(user);

    useFieldValidation(pay);

    useEffect(() => {
        pay.setValue({
            payMin,
            payMax,
            payFixed: null,
            isFixedPay: false,
        });

        const [newManagedScales, newDefaultScales] = refreshPayScales(
            payMin,
            payMax,
            pay.value.payMin,
            pay.value.payMax,
            managedScales,
            defaultPayScales,
        );

        setManagedScales(newManagedScales);
        updateDefaultPayScales(newDefaultScales);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [payMin, payMax]);

    useEffect(() => {
        const errors = getErrorIds(Object.entries(groupBy(managedScales, 'rate')));

        setErrorScales(errors);
        payScales.setValue(transformScales(managedScales));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [managedScales]);

    function onUseTandemsScales(scales: IManagedScale[]) {
        setPayMin(scales[0].rate);
        setPayMax(scales[scales.length - 1].rate);
        setManagedScales(scales);
    }

    return (
        <>
            <BusinessPayInputHeader
                payMin={payMin}
                payMax={payMax}
                setPayMin={setPayMin}
                setPayMax={setPayMax}
                pricingPlan={pricingPlan}
                onUseTandemsScales={onUseTandemsScales}
            />
            <Grid container item style={{ marginTop: 10, marginBottom: 30 }}>
                <Grid style={{ marginBottom: 10, width: '100%' }}>
                    <BusinessPayContext.Provider
                        value={{ qualifications, errorScales, managedScales, setManagedScales }}
                    >
                        {Object.entries(groupBy(managedScales, 'rate')).map(([rate, managedScalesByRate]) => (
                            <BusinessPayInputSection
                                key={rate}
                                dollarAmount={rate}
                                isMinimumRate={rate === (payMin && payMin.toString())}
                                managedScalesByRate={managedScalesByRate}
                                hasSeenTrainingsModal={hasSeenTrainingsModal}
                                updateHasSeenTrainingsModal={updateHasSeenTrainingsModal}
                            />
                        ))}
                    </BusinessPayContext.Provider>
                    {!payRangeInfoValid(payScales.value) ? (
                        <Text variant="caption" textStyle={{ color: 'red' }}>
                            At least one qualification is required for every rate above the lowest one. Additionally,
                            please do not have any rows with the exact same qualifications.
                        </Text>
                    ) : null}
                </Grid>
            </Grid>
        </>
    );
}
