import React from 'react';
import { Text, IconButton } from 'library';
import { Grid } from '@material-ui/core';
import { TimeChange } from './jobChangeModels';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import { TextField } from '@mui/material';
import { formatMonthDayYearWith12HourTime } from 'shared/Dates';

export default function JobTimeChange({
    removedTimeSlots,
    timeChange,
    updateJobChangeTime,
    index,
    setRemovedTimeSlots,
}: {
    removedTimeSlots: number[];
    timeChange: TimeChange;
    updateJobChangeTime: (d: Date | MaterialUiPickersDate, i: number, field: keyof TimeChange) => void;
    index: number;
    setRemovedTimeSlots: (n: number[]) => void;
}) {
    const start = new Date(timeChange.start);
    const end = new Date(timeChange.end);
    const removed = removedTimeSlots.includes(timeChange.appointment);

    function updateTime(d: string, startOrEnd: 'start' | 'end') {
        const [hours, minutes] = d.split(':');
        let date = new Date(end);
        if (startOrEnd === 'start') {
            date = new Date(start);
        }
        date.setHours(parseInt(hours));
        date.setMinutes(parseInt(minutes));
        !isNaN(date.getDate()) && updateJobChangeTime(date, index, startOrEnd);
    }

    function getTimeStringFromDate(d: Date) {
        return `${d.getHours().toString().padStart(2, '0')}:${d.getMinutes().toString().padStart(2, '0')}`;
    }

    return (
        <Grid key={timeChange.appointment} item container direction="row" alignItems="center" justify="space-between">
            <Grid item container direction="column" style={{ opacity: removed ? 0.5 : 1, width: '90%' }}>
                <Grid item container direction="row" alignItems="center" justify="space-between">
                    <Grid item container direction="column" xs={4}>
                        <Text textStyle={{ width: 50 }}>
                            {start.toLocaleDateString('en-US', { weekday: 'short' })}:
                        </Text>
                        <Text>{start.toLocaleDateString('en-US')}</Text>
                    </Grid>
                    <TextField
                        label="Start time"
                        type="time"
                        value={getTimeStringFromDate(start)}
                        onChange={(e) => updateTime(e.target.value, 'start')}
                        disabled={removed}
                        inputProps={{ step: 60 }}
                    />
                    <TextField
                        label="End time"
                        type="time"
                        value={getTimeStringFromDate(end)}
                        onChange={(e) => updateTime(e.target.value, 'end')}
                        disabled={removed}
                        inputProps={{ step: 60 }}
                    />
                </Grid>
                {!!timeChange.last_hire_date && (
                    <Text variant="caption">
                        Last time to fill this appointment:{' '}
                        {formatMonthDayYearWith12HourTime(timeChange.last_hire_date)}
                    </Text>
                )}
            </Grid>
            <IconButton
                icon={removed ? AddIcon : CloseIcon}
                onClick={() =>
                    removed
                        ? setRemovedTimeSlots(removedTimeSlots.filter((i) => i !== timeChange.appointment))
                        : setRemovedTimeSlots([...removedTimeSlots, timeChange.appointment])
                }
            />
        </Grid>
    );
}
