import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import TextButton from '../../../../library/components/buttons/TextButton';
import { Colors } from '../../../../constants';
import { Text } from 'library';
import { ErrorMessageProps } from '../../Models/PairingList';
import ConnectPaymentModal from 'parent-portal/payments/Components/ConnectPaymentModal';

const ErrorMessage = ({ errorMessage, errorCount, onModalClose }: ErrorMessageProps) => {
    const [openPaySetup, setOpenPaySetup] = useState<boolean>(false);

    if (!errorMessage) {
        return <></>;
    }

    const onPayModalClose = () => {
        setOpenPaySetup(false);
        if (!!onModalClose) {
            onModalClose();
        }
    };

    return (
        <>
            <Grid container item justify="center">
                <Grid container item lg={9} md={9} sm={11} xs={9} spacing={1}>
                    <Grid item xs={12}>
                        <Text variant="body1" color={Colors.terraCotta} bold>
                            {errorMessage}
                        </Text>
                    </Grid>
                    {!!errorCount && errorCount > 2 && (
                        <>
                            <Grid item xs={12}>
                                <Text variant="body1">
                                    There may be an issue with your payment method. Set up a new one here:
                                </Text>
                            </Grid>
                            <Grid item xs={12}>
                                <TextButton onClick={() => setOpenPaySetup(true)} textStyle={{ fontWeight: 'bold' }}>
                                    Payment Set Up
                                </TextButton>
                            </Grid>
                        </>
                    )}
                </Grid>
            </Grid>
            <ConnectPaymentModal isOpen={openPaySetup} onClose={onPayModalClose} fromRoute="payments" />
        </>
    );
};

export default ErrorMessage;
