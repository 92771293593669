import React, { useState } from 'react';
import InitialFeedback from './InitialFeedback';
import MainButtons from './MainButtons';
import SetPreferredModal from '../../../parent-portal/shared/UserActions/PreferWorkerModal/index';
import DoNotAddPreferredWorkers from './DoNotAddPreferredWorkers';
import SuccessfullyUpdatedPreferredWorkers from './SuccessfullyUpdatedPreferredWorkers';
import { ShiftProviderUser } from 'models';

export default function HighStarProviderReview({ provider }: { provider: ShiftProviderUser }) {
    const [open, setOpen] = useState(false);
    const [noUpdate, setNoUpdate] = useState(false);
    const [success, setSuccess] = useState(false);

    if (noUpdate) return <DoNotAddPreferredWorkers />;

    if (success) return <SuccessfullyUpdatedPreferredWorkers />;

    return (
        <>
            <InitialFeedback provider={provider} />
            <MainButtons onSetPreferredClick={() => setOpen(true)} onNoClick={() => setNoUpdate(true)} />
            <SetPreferredModal
                open={open}
                onSuccess={() => setSuccess(true)}
                onClose={() => setOpen(false)}
                provider={provider}
            />
        </>
    );
}
