export enum PaymentInformationStatus {
    NEW = 'NEW',
    VERIFIED = 'VERIFIED',
}

export default interface PaymentInformation {
    id: number;
    status: PaymentInformationStatus;
    payment_type: string;
    is_default: boolean;
    deleted_at: string;
    added_at: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data?: any;
    last_four?: string;
}
