import React from 'react';
import { ImmediateNeed } from './components/ImmediateNeed';
import { FutureNeed } from './components/FutureNeed';

export default function StaffingNeeds() {
    return (
        <>
            <ImmediateNeed />
            <FutureNeed />
        </>
    );
}
