import React, { useContext } from 'react';
import { Grid, styled } from '@mui/material';
import { Colors, Text } from 'library';

import { LocationsList, BannedWorkerModalContext } from '.';

export default function InnerModalContent() {
    const { emptyLocationList, locations } = useContext(BannedWorkerModalContext);

    if (!emptyLocationList && !locations?.length) {
        return (
            <InnerDialog container direction="column">
                <Text>Loading...</Text>
            </InnerDialog>
        );
    }

    if (emptyLocationList) {
        return (
            <InnerDialog container direction="column">
                <Text>No locations found! Please contact support if you feel this is an error.</Text>
            </InnerDialog>
        );
    }

    return <LocationsList />;
}

const InnerDialog = styled(Grid)({
    background: Colors.lightTurq,
    marginBottom: '1em',
    borderRadius: '10px',
    padding: '1em',
});
