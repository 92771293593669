import { useLocation } from 'react-router-dom';
import { useCookies } from 'react-cookie';

export default function useFamilyApi() {
    const query = new URLSearchParams(useLocation().search);
    const [cookies] = useCookies();

    function getTrackingValues() {
        return {
            sitter: query.get('sitter') ?? cookies.sitter,
            referral_code: query.get('referral') ?? cookies.referral,
            source: query.get('source') ?? cookies.source,
            group: query.get('group') ?? cookies.group,
            ciid: query.get('ciid') ?? cookies.ciid,
            ccid: query.get('ccid') ?? cookies.ccid,
            esid: query.get('esid') ?? cookies.esid,
            reid: query.get('reid') ?? cookies.reid,
            pt: query.get('pt') ?? cookies.pt,
        };
    }

    return { getTrackingValues };
}
