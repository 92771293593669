import React, { useEffect, useState } from 'react';
import { Card, TextField, styled } from '@mui/material';
import { useClient } from 'shared';
import { Checkbox, Colors, IconButton, LoadingSpinner, PrimaryButton, Text } from 'library';
import { Delete } from '@mui/icons-material';

interface OverrideValues {
    id: number;
    breakRequired: boolean;
    breakLength: number | null;
    hasNMinutes: boolean;
    nMinutes: number | null;
    hasLastHireOffsetMinutes: boolean;
    lastHireOffsetMinutes: number | null;
}

interface OverrideResponse {
    id: number;
    break_required: boolean;
    break_length: number;
    break_required_every_n_minutes: number | null;
    last_hire_offset_minutes: number | null;
    created_by: {
        first_name: string;
        last_name: string;
    };
}

export function BusinessLocationOverrides({ businessLocationId }: { businessLocationId: number }) {
    const [action, setAction] = useState<string | undefined>();
    const [display, setDisplay] = useState<string>('Add');
    const [override, setOverride] = useState<OverrideResponse | null>(null);
    const [values, setValues] = useState<OverrideValues>({
        id: 0,
        breakRequired: false,
        breakLength: null,
        hasNMinutes: false,
        nMinutes: null,
        hasLastHireOffsetMinutes: false,
        lastHireOffsetMinutes: null,
    });

    function updateValues(newValues: Partial<OverrideValues>) {
        setValues({ ...values, ...newValues });
    }

    const { loading, error, data, send } = useClient<OverrideResponse | OverrideResponse[]>({
        url: `internal/business-location-override/?business_location=${businessLocationId}`,
    });

    const {
        loading: updateLoading,
        error: updateError,
        data: updateData,
        status,
        send: updateOverride,
    } = useClient<OverrideResponse>({
        url: `internal/business-location-override/${values.id}/`,
        options: { sendImmediately: false },
    });

    function destroy() {
        setAction('delete');
        updateOverride({ method: 'DELETE' });
    }

    function submit() {
        setAction(override?.id ? 'update' : 'create');
        const body = {
            last_hire_offset_minutes: values.hasLastHireOffsetMinutes ? values.lastHireOffsetMinutes : null,
            break_length: values.breakLength,
            break_required: values.breakRequired,
            break_required_every_n_minutes: values.hasNMinutes ? values.nMinutes : null,
            business_location: businessLocationId,
        };

        override?.id ? updateOverride({ body, method: 'PATCH' }) : send({ body, method: 'POST' });
    }

    function extractData() {
        if (!action || action === 'create') {
            return Array.isArray(data) ? data?.[0] : data;
        }

        if (['update', 'delete'].includes(action)) {
            return updateData;
        }

        return null;
    }

    useEffect(() => {
        if (action === 'delete') {
            if (!updateLoading && status === 204) {
                setOverride(null);
                setDisplay('Add');
            }
        } else {
            if (!error) {
                const instance = extractData();
                setOverride(instance ?? null);
                setDisplay(instance?.id ? 'Update' : 'Add');

                setValues({
                    id: instance?.id ?? 0,
                    breakRequired: !!instance?.break_required,
                    breakLength: instance?.break_length ?? null,
                    hasNMinutes: !!instance?.break_required_every_n_minutes,
                    nMinutes: instance?.break_required_every_n_minutes ?? null,
                    hasLastHireOffsetMinutes: !!instance?.last_hire_offset_minutes,
                    lastHireOffsetMinutes: instance?.last_hire_offset_minutes ?? null,
                });
            }
        }
    }, [data, error, updateLoading, status, action]);

    if (loading) return <LoadingSpinner />;

    return (
        <Container>
            <Text bold textStyle={{ marginBottom: 10 }}>
                Overrides
            </Text>
            {!error &&
                (override?.created_by ? (
                    <Text textStyle={{ marginBottom: 10 }} variant="body2">
                        Current override is active and created by {override.created_by.first_name}{' '}
                        {override.created_by.last_name}.
                    </Text>
                ) : (
                    <Text textStyle={{ marginBottom: 10 }} variant="body2">
                        No current overrides implemented.
                    </Text>
                ))}
            {!!error && !action && (
                <Text variant="body2" textStyle={{ color: Colors.error, marginBottom: 10 }}>
                    There was a problem retrieving overrides, please let the developers know!
                </Text>
            )}
            <OverrideCard>
                <InnerContainer>
                    <Checkbox
                        style={{ width: undefined }}
                        label=""
                        checked={values.breakRequired}
                        onChange={() => {
                            const newBreakRequiredValue = !values.breakRequired;
                            updateValues({
                                breakLength: newBreakRequiredValue ? 60 : null,
                                hasNMinutes: newBreakRequiredValue ? values.hasNMinutes : false,
                                nMinutes: newBreakRequiredValue && values.hasNMinutes ? 240 : null,
                                breakRequired: newBreakRequiredValue,
                            });
                        }}
                    />
                    <Text>Break Required</Text>
                </InnerContainer>
                <LeftContainer>
                    <InnerContainer>
                        <Text textStyle={{ paddingLeft: 13 }}>For</Text>
                        <StyledField
                            disabled={!values.breakRequired}
                            value={values.breakLength}
                            onChange={(e) => updateValues({ breakLength: parseInt(e.target.value) })}
                            variant="standard"
                            type="number"
                            InputProps={{
                                inputProps: {
                                    min: 1,
                                },
                            }}
                            style={{ paddingLeft: 5, paddingRight: 5 }}
                        />
                        <Text>minutes</Text>
                    </InnerContainer>
                    <InnerContainer>
                        <Checkbox
                            style={{ width: undefined }}
                            label=""
                            checked={values.breakRequired && values.hasNMinutes}
                            onChange={() =>
                                updateValues({
                                    nMinutes: values.hasNMinutes ? null : 240,
                                    hasNMinutes: !values.hasNMinutes,
                                })
                            }
                        />
                        <InnerContainer>
                            <Text textStyle={{ paddingRight: 5 }}>Require this break every: </Text>
                            <StyledField
                                disabled={!values.breakRequired || !values.nMinutes}
                                value={values.nMinutes}
                                onChange={(e) => updateValues({ nMinutes: parseInt(e.target.value) })}
                                variant="standard"
                                type="number"
                                InputProps={{
                                    inputProps: {
                                        min: 1,
                                    },
                                }}
                            />
                            <Text textStyle={{ paddingLeft: 5 }}>minutes</Text>
                        </InnerContainer>
                    </InnerContainer>
                </LeftContainer>
                <InnerContainer title="A positive number indicates minutes AFTER the shift starts. A negative number indicates minutes BEFORE the shift starts.">
                    <Checkbox
                        style={{ width: undefined }}
                        label=""
                        checked={values.hasLastHireOffsetMinutes}
                        onChange={() =>
                            updateValues({
                                lastHireOffsetMinutes: values.hasLastHireOffsetMinutes ? null : 240,
                                hasLastHireOffsetMinutes: !values.hasLastHireOffsetMinutes,
                            })
                        }
                    />
                    <InnerContainer>
                        <Text textStyle={{ paddingRight: 5 }}>Last hire time offset: </Text>
                        <StyledField
                            disabled={!values.hasLastHireOffsetMinutes}
                            value={values.lastHireOffsetMinutes}
                            onChange={(e) => updateValues({ lastHireOffsetMinutes: parseInt(e.target.value) })}
                            variant="standard"
                            type="number"
                        />
                        <Text textStyle={{ paddingLeft: 5 }}>minutes</Text>
                    </InnerContainer>
                </InnerContainer>
                <ButtonContainer>
                    <IconButton
                        onClick={destroy}
                        icon={Delete}
                        iconStyle={{ color: Colors.error }}
                        disabled={!values.id}
                        tooltip="Delete the overrides and revert to default settings"
                    />
                    <PrimaryButton
                        buttonStyle={{ width: 'fit-content' }}
                        onClick={submit}
                        disabled={
                            (!values.breakRequired && !values.lastHireOffsetMinutes) ||
                            (action === 'delete' && updateLoading)
                        }
                        loading={loading}
                    >
                        {display} Override
                    </PrimaryButton>
                </ButtonContainer>
                {action && (error || updateError) && (
                    <Text color={Colors.error} variant="caption" textStyle={{ marginRight: 13, marginTop: 10 }}>
                        The following error occurred trying to {action} the override:{' '}
                        {JSON.stringify(error ? data : updateData)}
                    </Text>
                )}
                {!error && !updateError && action && (
                    <Text color={Colors.turquoise} variant="caption" textStyle={{ marginRight: 13, marginTop: 10 }}>
                        Override {action}d successfully!
                    </Text>
                )}
            </OverrideCard>
        </Container>
    );
}

const Container = styled('div')({
    padding: 20,
});

const OverrideCard = styled(Card)({
    display: 'flex',
    flexDirection: 'column',
    padding: 10,
    width: 'fit-content',
});

const LeftContainer = styled('div')({
    paddingLeft: 20,
});

const InnerContainer = styled('div')({
    display: 'flex',
    alignItems: 'center',
});

const ButtonContainer = styled(InnerContainer)({
    justifyContent: 'space-between',
    marginRight: 13,
    marginTop: 20,
});

const StyledField = styled(TextField)({
    margin: '0 5px',
    fontFamily: 'WorkSans-Regular',
    width: 70,
    color: Colors.darkNavy,
    borderColor: Colors.darkNavy,
    paddingBottom: 10,
    '& .Mui-focused': {
        color: Colors.darkNavy,
    },
});
