import { Grid } from '@material-ui/core';
import { IContactInfo } from 'parent-portal/profile/BusinessProfile/models';
import React, { useEffect, useState } from 'react';
import ContactInfo from './ContactInfo';
import { LeanUser } from 'models';
import { Text, Colors, OutlinedDropdown } from 'library';
import { client } from 'shared';
import { useUserContext } from 'UserContext';

export default function SelectOrCreateContact({
    mainContactInfo,
    updateMainContactInfo,
    setExistingContact,
    existingContact,
    errorMessage,
    locationId,
}: {
    mainContactInfo: IContactInfo;
    updateMainContactInfo: (k: keyof IContactInfo, v: string) => void;
    setExistingContact: (v: number) => void;
    existingContact: number;
    errorMessage?: string;
    locationId?: number;
}) {
    const [existingContacts, setExistingContacts] = useState<{ key: string | number; value: string | number }[]>([]);
    useEffect(fetchExistingContacts, []);
    const { user } = useUserContext();

    function fetchExistingContacts() {
        let url = 'api/business-contact/existing-contacts/';
        if (locationId) {
            url += `?location_id=${locationId}`;
        }
        client(url).then((res: LeanUser[]) => {
            const dropDownContacts = res.map((c: LeanUser) => {
                return { key: c.id, value: `${c.first_name} ${c.last_name[0]}` };
            });
            setExistingContacts([{ key: -1, value: 'New Contact' }, ...dropDownContacts]);
        });
    }
    return (
        <>
            <Grid item container direction="column" style={{ marginTop: 10, marginBottom: 10 }}>
                <Text textStyle={{ marginBottom: 10 }}>
                    By default the account owner {user ? `(${user.first_name})` : ''} will be the contact for this
                    location. Do you want to add an additional contact for this location?
                </Text>
                <Text variant="body1" bold>
                    Select existing contact or create a new contact for this location below.
                </Text>
                <OutlinedDropdown
                    value={existingContact || ''}
                    fields={existingContacts.map((center) => ({
                        key: center.key,
                        value: center.value.toString(),
                    }))}
                    onChange={(e) => setExistingContact(e.target.value)}
                />
            </Grid>
            {existingContact === -1 ? (
                <>
                    <ContactInfo newContact={mainContactInfo} updateContact={updateMainContactInfo} />
                    {errorMessage ? (
                        <Text variant="caption" textStyle={{ color: Colors.error }}>
                            {errorMessage}
                        </Text>
                    ) : null}
                </>
            ) : null}
        </>
    );
}
