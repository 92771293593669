import { Grid } from '@mui/material';
import { Colors, Text } from 'library';
import React from 'react';

export function SoonBlock() {
    return (
        <Grid item container flexDirection="column" style={{ gap: 20 }}>
            <Text variant="h1">But we're just getting started.</Text>
            <Text>
                Tandem strives to be a leader in transforming the child care industry by addressing its biggest
                challenges.
            </Text>
            <Grid item container flexDirection="column">
                <Text bold>Want to join the mission?</Text>
                <Text>
                    Email{' '}
                    <a href="mailto:support@join-tandem.com" style={{ color: Colors.darkNavy }}>
                        support@join-tandem.com
                    </a>
                    .
                </Text>
            </Grid>
        </Grid>
    );
}
