import React from 'react';
import theme from 'theme';
import SearchIcon from 'assets/images/search.svg';
import { styled } from '@mui/material';
import { Colors } from 'library';

export function Search({ handleChange }: { handleChange: (value: string | boolean, formKey: string) => void }) {
    return (
        <>
            <SearchBarContainer>
                <SearchIconContainer>
                    <SearchIcon />
                </SearchIconContainer>
                <SearchBar
                    placeholder="Search providers"
                    type="search"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        handleChange(event.target.value, 'searchQuery')
                    }
                />
            </SearchBarContainer>
        </>
    );
}

const SearchBarContainer = styled('div')({
    alignItems: 'center',
    border: `1px solid ${Colors.darkNavy}`,
    borderRadius: 10,
    display: 'flex',
    flex: 1,
    height: 'fit-content',
});

const SearchIconContainer = styled('div')({
    padding: '0px 5px',
});

const SearchBar = styled('input')({
    color: theme.palette.primary.main,
    width: '100%',
    border: 'none',
    borderRadius: 10,
    padding: '15px 5px',
    outline: 'none',
    fontSize: 16,
});
