import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import AdminButton from 'internal/shared/AdminButton';
import { getWeeklyAvailabilityForUser, saveSubAvailability } from 'internal/substitutes/substituteApi';
import { PostApiSubAvailability, PostApiTimeslot, TimeslotState } from 'internal/substitutes/substituteModels';
import { getMondayForThisWeek } from 'internal/substitutes/weekdayConstants';
import { BasicDialog, OutlinedDropdown, PrimaryButton, Text, Colors } from 'library';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { formatDateFromDateObject } from 'shared/Dates';
import SubAvailabilityLegend from './SubAvailabilityLegend';
import SubAvailabilityTimeslot from './SubAvailabilityTimeslot';

enum Weekdays {
    Monday = 'Monday',
    Tuesday = 'Tuesday',
    Wednesday = 'Wednesday',
    Thursday = 'Thursday',
    Friday = 'Friday',
    Saturday = 'Saturday',
    Sunday = 'Sunday',
}

const thisMonday = getMondayForThisWeek();

const get7am = (date: Date) => {
    date.setHours(7, 0, 0, 0);
    return date;
};

const get7pm = (date: Date) => {
    date.setHours(19, 0, 0, 0);
    return date;
};

const getDefaultShiftForDay = (start: Date, index: number) => {
    let lower = new Date(start);
    lower.setDate(lower.getDate() + index);
    let upper = new Date(lower);
    return {
        id: -1,
        week: -1,
        lower: get7am(lower),
        upper: get7pm(upper),
        comment: null,
        booked: false,
        state: TimeslotState.DEFAULT,
    };
};

const defaultShifts = (week: Date) => ({
    id: -1,
    start_date: formatDateFromDateObject(week),
    timeslots: Object.keys(Weekdays).map((day, index) => getDefaultShiftForDay(week, index)),
});

export default function SubAvailabilityDialog({ userId }: { userId?: number }) {
    const [subAvailability, setSubAvailability] = useState<PostApiSubAvailability>();
    const [week, setWeek] = useState<Date>();
    const [allWeeks, setAllWeeks] = useState<{ key: string; value: string }[]>([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState();

    useEffect(() => {
        let weeks = [-4, -3, -2, -1, 0, 1, 2, 3, 4].map((offset) => {
            let newMonday = new Date(thisMonday);
            newMonday.setDate(thisMonday.getDate() + offset * 7);
            return {
                key: formatDateFromDateObject(newMonday),
                value: formatDateFromDateObject(newMonday),
            };
        });
        setAllWeeks(weeks);
        setWeek(thisMonday);
    }, []);

    useEffect(() => {
        if (week && modalOpen) {
            setSubAvailability(defaultShifts(week));
            getAvailability(week);
        }
    }, [week, modalOpen]);

    function getAvailability(week?: Date) {
        if (userId && week) {
            getWeeklyAvailabilityForUser(week, userId).then((result) => {
                if (result) {
                    setSubAvailability({
                        id: result.id,
                        start_date: result.start_date,
                        timeslots: _.flatten(
                            Object.keys(Weekdays).map((day, index) => {
                                let defaultDay = getDefaultShiftForDay(week, index);
                                let savedDays = result.timeslots.filter(
                                    (slot) => slot.lower.getDay() === defaultDay.lower.getDay(),
                                );
                                return savedDays.length > 0 ? savedDays : defaultDay;
                            }),
                        ),
                    });
                }
            });
        }
    }

    function updateSlot(slot: PostApiTimeslot, index: number, duplicate: boolean = true) {
        let newSubAvailability = { ...subAvailability };
        if (
            (newSubAvailability.timeslots?.filter((timeslot) => timeslot.lower.getDay() === slot.lower.getDay())
                ?.length || 0) > 1 &&
            slot.state === TimeslotState.DEFAULT
        ) {
            newSubAvailability.timeslots?.splice(index, 1);
        } else if (duplicate) {
            newSubAvailability.timeslots?.splice(index + 1, 0, slot);
        } else {
            newSubAvailability.timeslots?.splice(index, 1, slot);
        }
        //@ts-ignore this won't be undefined, but typescript won't even ignore it with a truth check
        setSubAvailability(newSubAvailability);
    }

    function save() {
        if (subAvailability && userId) {
            setErrorMessage(undefined);
            saveSubAvailability(subAvailability, userId)
                .then(() => getAvailability(week))
                .catch((error) => {
                    setErrorMessage(error.message);
                    console.log(error);
                });
        }
    }

    return userId ? (
        <>
            <AdminButton
                text="Availability"
                onClick={() => setModalOpen(true)}
                color={Colors.lightTerraCotta}
                FontAwesomeImg={faCalendar}
            />
            <BasicDialog closeButton isOpen={modalOpen} onClose={() => setModalOpen(false)}>
                <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 10, alignItems: 'center' }}>
                    <Text bold textStyle={{ marginRight: 8 }}>
                        Week:
                    </Text>
                    <OutlinedDropdown
                        value={formatDateFromDateObject(week || thisMonday)}
                        onChange={(e) => setWeek(new Date(e.target.value + 'T00:00:00'))}
                        fields={allWeeks}
                        style={{ marginRight: 30 }}
                    />
                    <SubAvailabilityLegend />
                </div>
                {subAvailability?.timeslots
                    ? subAvailability.timeslots.map((slot, index) => (
                          <SubAvailabilityTimeslot
                              key={index}
                              slot={slot}
                              index={index}
                              updateSlot={updateSlot}
                              week={week}
                          />
                      ))
                    : null}
                <Text variant="caption" color={Colors.mediumRed}>
                    {errorMessage || ''}
                </Text>
                <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                    <PrimaryButton
                        buttonStyle={{ width: '40%' }}
                        onClick={save}
                        disabled={
                            !subAvailability?.timeslots.some((slot) =>
                                [TimeslotState.UNSAVED, TimeslotState.DELETED].includes(slot.state),
                            )
                        }
                    >
                        Save
                    </PrimaryButton>
                </div>
            </BasicDialog>
        </>
    ) : null;
}
