import React, { useState } from 'react';
import { styled } from '@mui/material';
import { Edit } from '@mui/icons-material';
import { Colors, IconButton, Text } from 'library';
import { formatMonthDayYearWith12HourTime } from 'shared/Dates';
import { CallLogModal } from './CallLogModal';

import type { IBusinessLocationWithNotes } from 'models';
import type { CallHistory } from 'models/CallHistory';

export function LocationCallCommunicationRow({
    businessLocation,
    uneditedCall,
}: {
    businessLocation: IBusinessLocationWithNotes;
    uneditedCall: CallHistory;
}) {
    const [open, setOpen] = useState(false);
    const [call, setCall] = useState<CallHistory>(uneditedCall);

    return (
        <Row>
            <div>
                <Subheader>
                    <Text bold variant="h2">
                        {call.is_inbound ? 'Inbound' : 'Outbound'} Call
                    </Text>
                    <Text variant="body2" textStyle={{ paddingLeft: 5 }}>
                        from {`${call.call_from_user?.first_name} ${call.call_from_user?.last_name}`} to{' '}
                        {call.call_to_user?.id
                            ? `${call.call_to_user?.first_name} ${call.call_to_user?.last_name}`
                            : 'Unknown'}
                    </Text>
                </Subheader>
                <TextContainer>
                    <Text
                        textStyle={{
                            color:
                                call.call_status?.name?.toLowerCase() === 'voicemail' ? Colors.error : Colors.darkNavy,
                            height: 'fit-content',
                        }}
                        variant="body2"
                    >
                        {call.call_status?.name ?? 'Call Status Unknown'}
                    </Text>
                    <Text variant="body2" textStyle={{ paddingLeft: 5 }}>
                        - {call.note ?? 'No notes found!'}
                    </Text>
                </TextContainer>
            </div>
            <Container>
                <Text variant="body2" textStyle={{ color: Colors.grey, whiteSpace: 'nowrap' }}>
                    {formatMonthDayYearWith12HourTime(call.call_time)}
                </Text>
                <Text variant="body2" textStyle={{ whiteSpace: 'nowrap', paddingTop: 5 }}>
                    {call.duration ? `${call.duration} minutes` : 'Duration: N/A'}
                </Text>
                <IconButton
                    icon={Edit}
                    size={24}
                    style={{ marginRight: 10, marginTop: 10 }}
                    onClick={() => setOpen(true)}
                />
            </Container>
            <CallLogModal
                open={open}
                onClose={() => setOpen(false)}
                businessLocationId={businessLocation.id}
                call={call}
                setCall={setCall}
            />
        </Row>
    );
}

const Row = styled('div')({
    borderBottom: `1px solid ${Colors.lightGrey}`,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 10,
    width: '100%',
});

const Subheader = styled('div')({
    alignItems: 'baseline',
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
});

const Container = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    minWidth: '12%',
    paddingLeft: 10,
    height: '100%',
});

const TextContainer = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    paddingTop: 10,
});
