import React from 'react';
import { ScheduledShift } from '../models';
import { styled, Grid } from '@mui/material';
import ShiftHistoryCard from './ShiftHistoryCard';

export default function ShiftsHistory({ shifts }: { shifts: ScheduledShift[] }) {
    return (
        <Container item container direction="row" justifyContent="center">
            {shifts.map((shift: ScheduledShift) => (
                <ShiftHistoryCard shift={shift} key={shift.id} />
            ))}
        </Container>
    );
}

const Container = styled(Grid)({
    gap: 10,
});
