import { Snackbar, SnackbarContent } from '@mui/material';
import { useUserContext } from 'UserContext';
import { ApiBaseWSUrl } from 'environmentVariables';
import { Colors, Text, TextButton } from 'library';
import React, { useEffect, useState } from 'react';
import useWebSocket from 'react-use-websocket';
import { socketConfig } from 'internal/shared/sockets';

const config = socketConfig({});

interface IHotConversation {
    message_sender_number: string;
    message_text: string;
    message_thread_id: number;
    user_name: string;
    user_id: number;
    thread_unread_count: number;
    last_sender_id: number;
}

export default function HotConversationNotifications() {
    const [threads, setThreads] = useState<IHotConversation[]>([]);
    const { user } = useUserContext();

    const { lastMessage } = useWebSocket(`${ApiBaseWSUrl}/ws/thread/outreach_hot_conversation/`, config);

    useEffect(() => {
        if (!lastMessage) return;
        const message = JSON.parse(lastMessage.data).message as IHotConversation;
        const lastMessagedByCurrentAdmin = message.last_sender_id === user?.id;

        if (!message.message_thread_id || !lastMessagedByCurrentAdmin) return;

        setThreads((prevState) => {
            const indexOfSameThread = prevState.findIndex(
                (thread) => thread.message_thread_id === message.message_thread_id,
            );
            if (indexOfSameThread !== -1) {
                return Array.from(prevState).splice(indexOfSameThread, 1);
            }
            return [...prevState.slice(-2), message];
        });
    }, [lastMessage, user]);

    function removeThread(id: number) {
        setThreads((prevState) => {
            return prevState.filter((thread) => thread.message_thread_id !== id);
        });
    }

    function openConversation(userName: string, id: number) {
        removeThread(id);
        const pathName = `${window.location.origin}/adminperson/outreach-new/`;
        const newLocation = `${pathName}?search=${userName}&thread=${id}`;
        window.open(newLocation, '_blank');
    }

    return <HotConversationSnack action={openConversation} threads={threads} onClose={removeThread} />;
}

function HotConversationSnack({
    action,
    threads,
    onClose,
}: {
    action: (userName: string, id: number) => void;
    threads: IHotConversation[];
    onClose: (id: number) => void;
}) {
    const openConversation = (thread: IHotConversation) => () => action(thread.user_name, thread.message_thread_id);
    const closeSnack = (id: number) => () => onClose(id);

    return (
        <>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                onClose={() => {}}
                ContentProps={{
                    sx: {
                        backgroundColor: 'transparent',
                        boxShadow: 'none',
                        gap: 5,
                        display: 'flex',
                        flexDirection: 'column',
                    },
                }}
                open={threads.length > 0}
                message={threads.map((thread) => (
                    <SnackbarContent
                        key={thread.message_thread_id}
                        message={
                            <>
                                <TextButton onClick={openConversation(thread)}>
                                    Message from {thread.user_name ?? thread.message_sender_number} (
                                    {thread.thread_unread_count})
                                </TextButton>
                                <Text variant="caption">
                                    {thread.message_text.substring(0, 45).trim() +
                                        (thread.message_text.length > 45 ? '...' : '')}
                                </Text>
                            </>
                        }
                        sx={{
                            backgroundColor: Colors.lightRed,
                            marginBottom: 2,
                        }}
                        action={<TextButton onClick={closeSnack(thread.message_thread_id)}>X</TextButton>}
                    />
                ))}
            />
        </>
    );
}
