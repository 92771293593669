import React from 'react';
import { Colors, Text, SecondaryButton } from 'library';
import { useOnboardingContext } from '../../OnboardingContext';
import { useModalsContext } from 'ModalsContext';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';

export default function Role({ title, color, optionId }: { title: string; color: Colors; optionId?: number }) {
    const { submitAction } = useOnboardingContext();
    const { openJobForm } = useModalsContext();

    const action = () => {
        submitAction(
            () =>
                openJobForm('Onboarding trial run', {
                    payType: 'PERCENTAGE',
                    businessJobType: 'TRIAL_RUN',
                    pageNumber: 1,
                    startDate: moment().add(1, 'day'),
                    jobDetails: optionId
                        ? [{ detail: 2, option: { id: optionId, value: title, detail: 2 }, option_id: optionId }]
                        : [],
                }),
            'Trial Run',
            title,
        );
    };

    return (
        <SecondaryButton onClick={action} buttonStyle={styles.button}>
            <div style={{ display: 'flex', flexDirection: 'row' }} id={`get-started-${title}-card`}>
                <div style={{ paddingTop: 10 }}>
                    <FontAwesomeIcon icon={faCircle} color={color} size="lg" />
                </div>
                <div style={{ marginLeft: 20, textAlign: 'left', display: 'flex', justifyContent: 'center' }}>
                    <Text variant="h2" bold textStyle={{ margin: '10px 0px' }}>
                        {title}
                    </Text>
                </div>
            </div>
        </SecondaryButton>
    );
}

const styles = {
    button: {
        marginTop: 20,
        maxWidth: 450,
        borderRadius: 18,
        height: 60,
        width: '48%',
        justifyContent: 'flex-start',
        alignItems: 'center',
        padding: '10px 20px',
    },
};
