import React from 'react';
import Text from './Text';

export default function WebLink({
    url,
    children,
    style,
}: {
    url: string;
    children?: string;
    style?: React.CSSProperties;
}) {
    return (
        <Text textStyle={style}>
            <a
                style={{ fontFamily: 'inherit', fontSize: 'inherit', color: 'inherit', textDecoration: 'underline' }}
                href={url}
            >
                {children ? children : url}
            </a>
        </Text>
    );
}
