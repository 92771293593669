import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import AdminButton from 'internal/shared/AdminButton';
import { Colors } from 'library';
import { BusinessJobWithAppointments } from 'models';
import React, { useState } from 'react';
import EmailCenterShiftsModal from './EmailCenterShiftsModal';

export default function EmailShifts({ job }: { job: BusinessJobWithAppointments }) {
    const [emailCenter, setEmailCenter] = useState(false);
    return (
        <>
            <AdminButton
                text="Email Shifts"
                FontAwesomeImg={faEnvelope}
                onClick={() => setEmailCenter(true)}
                color={Colors.lightRed}
            />
            <EmailCenterShiftsModal closeModal={() => setEmailCenter(false)} modalOpen={emailCenter} job={job} />
        </>
    );
}
