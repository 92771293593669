import React, { useEffect, useState } from 'react';
import { client, consoleLogInDev } from 'shared';
import { Grid, useMediaQuery, CircularProgress } from '@material-ui/core';
import { Text } from 'library';
import theme from 'theme';
import { ISubAvailability } from './models';
import AvailabilityCard from './AvailabilityCard';
import { useUserContext } from 'UserContext';

const SubAvailability = () => {
    const [subs, setSubs] = useState<ISubAvailability[]>([]);
    const [loading, setLoading] = useState(false);
    const [isNextWeek, setIsNextWeek] = useState(true);
    const smDown = useMediaQuery(theme.breakpoints.down('sm'));
    const { getDisplayText } = useUserContext();

    useEffect(() => {
        setLoading(true);
        client(`api/sub-availability/slots/?next-week=${isNextWeek}`)
            .then((response: ISubAvailability[]) => {
                setSubs(response);
                setLoading(false);
            })
            .catch(consoleLogInDev);
    }, [isNextWeek]);

    return (
        <Grid container style={{ width: '100%', padding: '3%', paddingBottom: smDown ? '30%' : '10%' }}>
            <Grid container item xs={12} justify="center">
                <Grid item xs={12}>
                    <Text variant="display" bold textStyle={{ textAlign: 'center' }}>
                        {getDisplayText('substitute').capitalize()} Availability
                    </Text>
                </Grid>
                <Grid item sm={9} xs={12}>
                    <Grid
                        container
                        item
                        style={{
                            marginTop: '1%',
                        }}
                        direction="row"
                        spacing={3}
                    >
                        <Grid item>
                            <Text
                                variant="body1"
                                bold={!isNextWeek}
                                textStyle={{ textDecoration: !isNextWeek ? 'underline' : undefined, cursor: 'pointer' }}
                                onClick={() => setIsNextWeek(false)}
                            >
                                This Week
                            </Text>
                        </Grid>
                        <Grid item>
                            <Text
                                variant="body1"
                                bold={isNextWeek}
                                textStyle={{ textDecoration: isNextWeek ? 'underline' : undefined, cursor: 'pointer' }}
                                onClick={() => setIsNextWeek(true)}
                            >
                                Next Week
                            </Text>
                        </Grid>
                    </Grid>
                </Grid>
                {!loading ? (
                    !!subs && subs?.length > 0 ? (
                        subs.map((sub: ISubAvailability) => <AvailabilityCard sub={sub} />)
                    ) : (
                        <Grid container justify="center" style={{ marginTop: '2%' }}>
                            <Grid container item sm={9} xs={12}>
                                <Text variant="body1">Come back soon! No subs currently available.</Text>
                            </Grid>
                        </Grid>
                    )
                ) : (
                    <Grid container justify="center" style={{ marginTop: '2%' }}>
                        <CircularProgress />
                    </Grid>
                )}
            </Grid>
        </Grid>
    );
};

export default SubAvailability;
