import React from 'react';
import CheckRow from './CheckRow';
import RequirementsLabel from './RequirementsLabel';
import ChipRow from './ChipRow';

export default function FloridaTrainingsContent() {
    return (
        <>
            <CheckRow text="Child Care Facility Rules and Regulations (RNRF)" />
            <CheckRow text="Introduction to Tandem - Providing Quality Care" />
            <RequirementsLabel>
                Per DCF requirements, within the outlined days, workers will be encouraged to obtain the following:
            </RequirementsLabel>
            <ChipRow text="30 Days" chips={['Early Literacy (5 Hours)', 'Safe Sleep/Shaken Baby Syndrome Training']} />
            <ChipRow
                text="90 Days"
                chips={['Child Care Facility Part 1 (30 Hours)', 'Child Care Facility Part 2 (10 Hours)']}
            />
        </>
    );
}
