import React, { useCallback, useEffect, useState } from 'react';
import { LoadingSpinner, SizeableRoundedDialog, Text, TextButton } from 'library';
import { client, consoleLogInDev, queryStringifyObject } from 'shared';
import { IBusinessLocationWithNotes, LocationHistory, PagingResult } from 'models';
import { Grid } from '@mui/material';
import LocationHistoryDisplay from './LocationHistoryDisplay';
import PaginatedList from 'internal/shared/PaginatedList';
import LocationHistoryHeader from './LocationHistoryHeader';

export default function ViewAllBusinessLocationHistory({
    businessLocation,
}: {
    businessLocation: IBusinessLocationWithNotes;
}) {
    const [selectedTypes, setSelectedTypes] = useState<string[]>(['All']);
    const [viewAllHistory, setViewAllHistory] = useState(false);
    const [loading, setLoading] = useState(false);
    const [locationHistory, setLocationHistory] = useState<PagingResult<LocationHistory>>();
    const [page, setPage] = useState(1);
    const updateLocationHistory = useCallback(() => {
        setLoading(true);
        const params =
            selectedTypes.length > 0 && !selectedTypes.includes('All')
                ? queryStringifyObject({
                      history_types: selectedTypes.join(','),
                      location_id: businessLocation.id,
                      page,
                  })
                : queryStringifyObject({
                      location_id: businessLocation.id,
                      page,
                  });
        client(`api/location-history/?${params}`)
            .then(setLocationHistory)
            .catch(consoleLogInDev)
            .finally(() => setLoading(false));
    }, [businessLocation, page, selectedTypes]);

    useEffect(() => {
        if (viewAllHistory) {
            updateLocationHistory();
        }
    }, [viewAllHistory, page, selectedTypes]);

    function content() {
        if (loading) return <LoadingSpinner />;

        if (!locationHistory) {
            return (
                <Grid style={{ padding: 20 }}>
                    <Text>No Location History Found.</Text>
                </Grid>
            );
        }

        return (
            <PaginatedList
                data={locationHistory}
                page={page}
                updatePage={(p) => {
                    setPage(p + 1);
                }}
            >
                <LocationHistoryDisplay history={locationHistory.results} />
            </PaginatedList>
        );
    }

    return (
        <>
            <TextButton onClick={() => setViewAllHistory(true)}>View All</TextButton>
            <SizeableRoundedDialog
                onClose={() => setViewAllHistory(false)}
                open={viewAllHistory}
                closeButton
                style={{ width: '70%', height: '93vh' }}
            >
                <Grid container item gap={1} direction="column" style={{ padding: 20 }}>
                    <LocationHistoryHeader
                        title={`${businessLocation.name} History`}
                        setSelectedTypes={setSelectedTypes}
                    />
                    {content()}
                </Grid>
            </SizeableRoundedDialog>
        </>
    );
}
