import React, { useState } from 'react';
import { styled } from '@mui/material';
import { PrimaryButton, Text, SecondaryButton, OutlinedDropdown, Colors } from 'library';
import { client, consoleLogInDev, track, isValidZipCode, isValidFormInputString } from 'shared';

import { useUserContext } from 'UserContext';
import { states } from 'parent-portal/signup/states';
import useContact from 'parent-portal/profile/BusinessProfile/hooks/useContact';

import TextInput from './TextInput';

const DEFAULT_NEW_CONTACT = {
    firstName: '',
    lastName: '',
    phoneNumber: '',
    email: '',
    contactPreference: 'EMAIL',
};

const DEFAULT_LOCATION = {
    street: { value: '', error: true, displayHelper: false },
    city: { value: '', error: true, displayHelper: false },
    state: { value: 'Select one', error: true, displayHelper: false },
    zip: { value: '', error: true, displayHelper: false },
    name: { value: '', error: false, displayHelper: false },
    programId: { value: '', error: false, displayHelper: false },
};

const STATE_OPTIONS = [
    { key: 'Select one', value: 'Select one' },
    ...states
        .filter((x) => x)
        .map((x) => {
            return { key: x, value: x };
        }),
];

type LocationDetail = keyof typeof DEFAULT_LOCATION;

export default function AddBusinessLocationForm({
    cancel,
    complete,
    optionalContact,
}: {
    cancel: () => void;
    complete?: () => void;
    optionalContact?: boolean;
}) {
    const { refreshAlreadyLoggedInUser } = useUserContext();

    const { isNewContactValid, populateCurrentContactInfo } = useContact(DEFAULT_NEW_CONTACT, optionalContact);

    const [locationDetails, setLocationDetails] = useState(DEFAULT_LOCATION);
    const [errorMessage, setErrorMessage] = useState('');

    function checkIsError(key: LocationDetail, value: string) {
        if (['street', 'city'].includes(key)) {
            return !isValidFormInputString(value);
        } else if (key === 'state') {
            return value !== 'Select one' && !isValidFormInputString(value);
        } else if (key === 'zip') {
            return !isValidZipCode(value);
        } else if (key === 'programId') {
            return locationDetails.state.value?.toLowerCase() === 'ohio'
                ? !(value.length > 5 && value.length < 21)
                : false;
        }

        return false;
    }

    function setValue(key: LocationDetail, value: string) {
        const additionalChecks =
            key === 'state' && value?.toLowerCase() === 'ohio'
                ? {
                      programId: {
                          value: locationDetails.programId.value,
                          error: checkIsError('programId', value),
                          displayHelper: false,
                      },
                  }
                : {
                      programId: {
                          value: locationDetails.programId.value,
                          error: checkIsError('programId', value),
                          displayHelper: false,
                      },
                  };

        setLocationDetails({
            ...locationDetails,
            ...additionalChecks,
            ...{ [key]: { value, error: checkIsError(key, value), displayHelper: false } },
        });
    }

    function setDisplay(key: LocationDetail) {
        const error = checkIsError(key, locationDetails[`${key}`].value);
        setLocationDetails({
            ...locationDetails,
            ...{ [key]: { ...locationDetails[`${key}`], error, displayHelper: error } },
        });
    }

    function checkBeforeSubmit() {
        const newLocationDetails = { ...locationDetails };
        Object.keys(locationDetails).forEach((key: string) => {
            const error = checkIsError(key as LocationDetail, locationDetails[`${key as LocationDetail}`].value);
            newLocationDetails[`${key as LocationDetail}`] = {
                ...newLocationDetails[`${key as LocationDetail}`],
                error,
                displayHelper: error,
            };
        });
        setLocationDetails(newLocationDetails);

        return Object.values(newLocationDetails).every((x) => !x.error) && isNewContactValid();
    }

    function onAddAddress() {
        if (!checkBeforeSubmit()) return;

        client('api/business-location/', {
            body: {
                street: locationDetails.street.value,
                city: locationDetails.city.value,
                state: locationDetails.state.value,
                zip_code: locationDetails.zip.value,
                location_name: locationDetails.name.value,
                program_id: locationDetails.programId.value,
            },
        })
            .then((res) => {
                if (res.status === 500) {
                    setErrorMessage(res.message);
                } else {
                    refreshAlreadyLoggedInUser(onCancel);
                }
            })
            .catch((error) => {
                consoleLogInDev(error);
            });
    }

    function onCancel() {
        complete?.();
        setLocationDetails(DEFAULT_LOCATION);
        populateCurrentContactInfo(DEFAULT_NEW_CONTACT);
        cancel();
    }

    const firstFields: LocationDetail[] = ['name', 'street', 'city'];
    const secondFields: LocationDetail[] = ['zip', 'programId'];

    const validLocation = Object.values(locationDetails).every((x) => !x.error) && isNewContactValid();

    return (
        <Container>
            <Text variant="display">Add a Center Location</Text>
            <SubContainer>
                {firstFields.map((field: LocationDetail) => (
                    <TextInput
                        error={locationDetails[`${field}`].error}
                        helperText={
                            locationDetails[`${field}`].error && locationDetails[`${field}`].displayHelper
                                ? `Please enter a valid ${field}.`
                                : undefined
                        }
                        key={field}
                        label={`${field === 'name' ? 'Location Name' : `${field.capitalize()} *`}`}
                        onBlur={() => setDisplay(field)}
                        onChange={(value: string) => setValue(field, value)}
                        value={locationDetails[`${field}`].value}
                    />
                ))}
                <StateContainer>
                    <Text variant="body1" bold={true}>
                        State *
                    </Text>
                    <OutlinedDropdown
                        id="state"
                        required
                        error={
                            locationDetails.state.value !== 'Select one' &&
                            !isValidFormInputString(locationDetails.state.value)
                        }
                        value={locationDetails.state.value}
                        onChange={(event: React.ChangeEvent<{ name?: string; value: string }>) =>
                            setValue('state', event.target.value)
                        }
                        fields={STATE_OPTIONS}
                        autoComplete="address-level1"
                        style={{ minWidth: 150 }}
                    />
                </StateContainer>
                {secondFields.map((field: LocationDetail) => (
                    <TextInput
                        error={locationDetails[`${field}`].error}
                        helperText={
                            locationDetails[`${field}`].error && locationDetails[`${field}`].displayHelper
                                ? `Please enter a valid ${field}.`
                                : undefined
                        }
                        key={field}
                        label={field === 'zip' ? 'Zip *' : 'Program ID **'}
                        onBlur={() => setDisplay(field)}
                        onChange={(value: string) => setValue(field, value)}
                        value={locationDetails[`${field}`].value}
                    />
                ))}
                <Text variant="caption">* Required</Text>
                <Text variant="caption">** Required in the state of Ohio</Text>
                <ButtonRow>
                    <SecondaryButton buttonStyle={{ maxWidth: '30%' }} onClick={onCancel}>
                        Cancel
                    </SecondaryButton>
                    <PrimaryButton
                        disabled={!validLocation}
                        buttonStyle={{ maxWidth: '30%', marginLeft: 10 }}
                        onClick={onAddAddress}
                    >
                        Add
                    </PrimaryButton>
                </ButtonRow>
                <Text textStyle={{ color: Colors.error }}>{errorMessage}</Text>
            </SubContainer>
        </Container>
    );
}

const Container = styled('div')({
    padding: 30,
});

const SubContainer = styled('div')({
    paddingTop: 20,
});

const StateContainer = styled('div')({
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 20,
    marginTop: 20,
});

const ButtonRow = styled('div')({
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 10,
});
