import React, { ReactNode, useContext, useEffect, useRef, useState } from 'react';
import States from '../constants/States';
import client from '../shared/ApiClient';
import { consoleLogInDev } from '../shared/Utils';
import { CartContextType, CartItemMappingModel } from './SitterPaymentModels';

interface CartContextProps {
    children: ReactNode;
}

const CartContext = React.createContext<CartContextType>({
    cart: [],
    refreshCart: () => {},
    licenseNumber: '',
    licenseState: '',
    ssn: '',
    updateLicenseNumber: () => {},
    updateLicenseState: () => {},
    updateSsn: () => {},
    licenseStateValid: true,
    licenseNumberValid: true,
    ssnValid: true,
    mvrInCart: false,
    criminalCheckInCart: false,
    inputsInvalid: true,
    removeFromCart: () => {},
});

export const CartContextProvider = ({ children }: CartContextProps) => {
    const [cart, setCart] = useState<Array<CartItemMappingModel>>([]);
    const [licenseNumber, setLicenseNumber] = useState('');
    const [licenseNumberValid, setLicenseNumberValid] = useState(true);
    const [licenseState, setLicenseState] = useState('');
    const [licenseStateValid, setLicenseStateValid] = useState(true);
    const [ssn, setSsn] = useState('');
    const [ssnValid, setSsnValid] = useState(true);
    const [mvrInCart, setMvrInCart] = useState(false);
    const [criminalCheckInCart, setCriminalCheckInCart] = useState(false);
    const [documentVerificationInCart, setDocumentVerificationInCart] = useState(false);
    const [inputsInvalid, setInputsInvalid] = useState(true);
    const firstRender = useRef(true);
    const stateAbbrs = Object.keys(States);
    const criminalChecks = ['BASIC', 'STANDARD', 'PRO'];

    const refreshCart = () => {
        client('api/cart-purchase-item/').then(setCart).catch(consoleLogInDev);
    };

    const removeFromCart = (item: CartItemMappingModel) => {
        client(`api/cart-purchase-item/${item.id}/`, { method: 'DELETE' }).then(refreshCart).catch(consoleLogInDev);
    };

    const updateLicenseNumber = (num: string) => {
        setLicenseNumberValid(num.length > 0);
        setLicenseNumber(num);
    };

    const updateLicenseState = (state: string) => {
        setLicenseStateValid(stateAbbrs.includes(state.toUpperCase()));
        setLicenseState(state);
    };

    const updateSsn = (newSsn: string) => {
        setSsnValid(!!newSsn.match(/^[0-9]{9}$/));
        setSsn(newSsn);
    };

    useEffect(refreshCart, []);

    useEffect(() => {
        setMvrInCart(cart.some((itemRow) => itemRow.item.code === 'MVR'));
        setCriminalCheckInCart(cart.some((itemRow) => criminalChecks.includes(itemRow.item.code)));
        setDocumentVerificationInCart(cart.some((itemRow) => itemRow.item.code === 'DOCUMENT_VERIFICATION'));
    }, [cart]);

    useEffect(() => {
        if (firstRender.current) {
            firstRender.current = false;
            setInputsInvalid(!cart.every((itemRow) => itemRow.item.code === 'SESAME_TELEHEALTH_OHIO'));
        } else {
            let ssnPresentAndValid = !criminalCheckInCart || (ssn && ssnValid);
            let mvrPresentAndValid =
                !mvrInCart || (licenseNumber && licenseNumberValid && licenseState && licenseStateValid);
            let documentVerificationOnly = documentVerificationInCart && !mvrInCart && !criminalCheckInCart;
            setInputsInvalid(!documentVerificationOnly && (!ssnPresentAndValid || !mvrPresentAndValid));
        }
    }, [
        licenseNumber,
        licenseNumberValid,
        licenseState,
        licenseStateValid,
        mvrInCart,
        ssn,
        ssnValid,
        criminalCheckInCart,
        documentVerificationInCart,
    ]);

    const contextValue = {
        cart,
        refreshCart,
        licenseNumber,
        licenseState,
        ssn,
        updateLicenseNumber,
        updateLicenseState,
        updateSsn,
        licenseNumberValid,
        licenseStateValid,
        ssnValid,
        mvrInCart,
        criminalCheckInCart,
        inputsInvalid,
        removeFromCart,
    };

    return <CartContext.Provider value={contextValue}>{children}</CartContext.Provider>;
};

export const useCart = () => {
    return useContext(CartContext);
};
