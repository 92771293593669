import React, { useState } from 'react';
import { InputAdornment, withStyles, IconButton } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import PropTypes from 'prop-types';
import Input from './input';

function PasswordInput(props) {
    const [passwordIsMasked, setPasswordIsMasked] = useState(true);
    const { classes, ...otherProps } = props;

    return (
        <Input
            type={passwordIsMasked ? 'password' : 'text'}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton
                            className={classes.eye}
                            disableRipple
                            onClick={() => setPasswordIsMasked(!passwordIsMasked)}
                        >
                            {passwordIsMasked ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                    </InputAdornment>
                ),
                classes: { adornedEnd: classes.adornment },
            }}
            {...otherProps}
        />
    );
}

PasswordInput.propTypes = {
    classes: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
    id: PropTypes.string,
    required: PropTypes.bool,
    label: PropTypes.string,
    variant: PropTypes.string,
    error: PropTypes.bool,
    helperText: PropTypes.string,
    fullWidth: PropTypes.bool,
};

const styles = (theme) => ({
    eye: {
        cursor: 'pointer',
        color: theme.palette.primary.main,
        '&:focus': {
            outline: 'none',
        },
    },
    adornment: {
        backgroundColor: 'white',
    },
});

export default withStyles(styles)(PasswordInput);
