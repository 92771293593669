import React from 'react';
import { Text, Colors } from 'library';
import { Link } from 'react-router-dom';
import { Grid, useMediaQuery } from '@mui/material';
import TrialRunIcon from 'assets/images/marketing-site/trial-run-icon.svg';
import HandshakeIcon from 'assets/images/marketing-site/handshake-icon.svg';
import theme from 'theme';

export function BothStaff() {
    const smDown = useMediaQuery(theme.breakpoints.down('sm'));
    return (
        <Grid container item direction="row" justifyContent="center" style={{ gap: 40, padding: smDown ? 40 : 80 }}>
            <Text variant="display" textStyle={{ textAlign: 'center' }}>
                We help you find both temporary and permanent staff.
            </Text>
            <Grid
                container
                item
                xs={12}
                alignItems="center"
                justifyContent="center"
                flexDirection={'row'}
                style={{ gap: 40 }}
            >
                <Grid
                    xs={10}
                    md={5}
                    container
                    item
                    direction="row"
                    style={{ boxShadow: '0px 3px 6px #00000029', padding: 20, borderRadius: 18, gap: 20 }}
                    justifyContent="center"
                    alignItems="center"
                >
                    <Grid container item justifyContent="center" alignItems="center" xs={2} md={1}>
                        <TrialRunIcon width={60} height={60} />
                    </Grid>
                    <Grid item container xs={9} md={10} flexDirection="column">
                        <Text variant="body1" textStyle={{ color: Colors.turquoise }}>
                            A new approach to permanent hiring
                        </Text>
                        <Text variant="h1">Trial Run</Text>
                        <Text variant="body1" textStyle={{ paddingTop: '3%' }}>
                            Feel confident in the fit of a worker at your program long-term before making them a
                            permanent offer. Seamless onboarding, no finder's fees to us.
                        </Text>
                        <Grid container item style={{ marginTop: '3%' }}>
                            <Link to="/trial-runs" style={{ color: Colors.darkNavy, textDecorationLine: 'underline' }}>
                                Learn More
                            </Link>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid
                    xs={10}
                    md={5}
                    container
                    item
                    direction="row"
                    style={{ boxShadow: '0px 3px 6px #00000029', padding: 20, borderRadius: 18, gap: 20 }}
                    justifyContent="center"
                    alignItems="center"
                >
                    <Grid container item justifyContent="center" alignItems="center" xs={2} md={1}>
                        <HandshakeIcon width={60} height={60} />
                    </Grid>
                    <Grid item container xs={9} md={10} flexDirection="column">
                        <Text variant="body1" textStyle={{ color: Colors.turquoise }}>
                            A tried and true process
                        </Text>
                        <Text variant="h1">Substitutes</Text>
                        <Text variant="body1" textStyle={{ paddingTop: '3%' }}>
                            Book short-term workers when you're in a bind or when you have a planned need. We'll find
                            you a sub 90% of the time.
                        </Text>
                        <Grid container item style={{ marginTop: '3%' }}>
                            <Link to="/substitutes" style={{ color: Colors.darkNavy, textDecorationLine: 'underline' }}>
                                Learn More
                            </Link>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}
