import React from 'react';
import { TextField } from '@mui/material';
import { Colors } from 'library';
import { InputAdornment } from '@material-ui/core';
import { PersonAddAlt1 } from '@mui/icons-material';

export default function SlotsPicker({
    label,
    slotsAvailable = 1,
    setSlotsAvailable,
    disabled = false,
}: {
    label: string;
    slotsAvailable?: number;
    setSlotsAvailable: (slotsAvailable: number) => void;
    disabled?: boolean;
}) {
    return (
        <TextField
            label={label}
            value={slotsAvailable}
            onChange={(e) => setSlotsAvailable(parseInt(e.target.value))}
            id="job-form-slots-input"
            variant="standard"
            type="number"
            sx={styles.inputStyles}
            disabled={disabled}
            InputProps={{
                inputProps: {
                    min: 1,
                },
                endAdornment: (
                    <InputAdornment position="end">
                        <PersonAddAlt1 htmlColor={Colors.darkNavy} />
                    </InputAdornment>
                ),
            }}
        />
    );
}

const styles = {
    inputStyles: {
        fontFamily: 'WorkSans-Regular',
        width: 120,
        color: Colors.darkNavy,
        borderColor: Colors.darkNavy,
        '& .Mui-focused': {
            color: Colors.darkNavy,
        },
    },
};
