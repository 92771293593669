import React, { useEffect, useState } from 'react';
import { ActualLeanUser, AdminBusinessUser, AdminProviderListUser, LeanUserWithPhone } from 'models';
import { IconButton } from 'library';
import { Message } from '@mui/icons-material';
import OutreachConversationModal from 'internal/texting/outreach/OutreachConversationModal';
import { TANDEM_NUMBERS } from 'internal/texting/outreach/OutreachModels';
import { client, consoleLogInDev } from 'shared';
import { ChatwootUrl, ChatwootAccountId } from 'environmentVariables';

export default function ProviderExternalMessaging({
    user,
    messages,
    iconSize,
}: {
    user: LeanUserWithPhone | AdminBusinessUser | AdminProviderListUser | ActualLeanUser;
    messages?: string[];
    iconSize?: number;
}) {
    const [messagesOpen, setMessagesOpen] = useState(false);
    const [chatwootConversationId, setChatwootConversationId] = useState<number>();

    useEffect(() => {
        if (messages && messages.length > 0) {
            setMessagesOpen(true);
        }
    }, [messages]);

    function onRightClick(e: React.MouseEvent<HTMLButtonElement>) {
        e.preventDefault();
        setMessagesOpen(true);
    }

    async function onOpenClick() {
        const url = (id?: number) => `${ChatwootUrl}/app/accounts/${ChatwootAccountId}/conversations/${id}`;

        if (chatwootConversationId) window.open(url(chatwootConversationId));

        try {
            const response = await client(
                `support/admin/chatwoot-conversation/most-recent-open-conversation?user_id=${user.id}`,
            );
            setChatwootConversationId(response.id);
            window.open(url(response.id));
        } catch (e) {
            try {
                const response = await client(`support/admin/chatwoot-conversation/`, {
                    method: 'POST',
                    body: { user_id: user.id },
                });
                setChatwootConversationId(response.id);
                window.open(url(response.id));
            } catch (e) {
                setMessagesOpen(true);
                consoleLogInDev(e);
            }
        }
    }

    return (
        <>
            <span style={{ marginLeft: 10 }}>
                <IconButton
                    tooltip="Message worker"
                    icon={Message}
                    onClick={onOpenClick}
                    onContextMenu={onRightClick}
                    size={iconSize}
                />
            </span>
            <OutreachConversationModal
                externalNumber={user.phone_number}
                tandemNumber={TANDEM_NUMBERS.PROVIDER_SUCCESS_FUNNEL}
                displayName={`${user.first_name} ${user.last_name}`}
                isOpen={messagesOpen}
                onClose={() => setMessagesOpen(false)}
            />
        </>
    );
}
