import React, { useState } from 'react';
import type { TProviderRecentlyWorked } from '../dashboard/types';
import { Text, OutlinedDropdown } from 'library';
import { Grid, styled } from '@mui/material';
import { IProviderReviewOption } from 'models';
import ProviderReviewModalLine from './ProviderReviewModalLine';
import WorkerReviewCopy from 'parent-portal/shared/WorkerReviewCopy';

export default function ProviderReviewModalList({
    workers,
    options,
}: {
    workers: TProviderRecentlyWorked[];
    options: IProviderReviewOption[];
}) {
    const uniqueLocations = workers
        .flatMap((worker) => worker.appointments)
        .reduce((locations: { id: number; name: string }[], appt) => {
            const isNew = !locations.some((loc) => appt.location.id === loc.id);
            return isNew ? [...locations, appt.location] : locations;
        }, []);

    const [selectedCenter, setSelectedCenter] = useState(uniqueLocations[0]?.id);

    const getWorkersAtCenter = (centerId: number) => {
        return workers.filter((worker) => {
            return worker.appointments.some((appt) => appt.location.id === centerId);
        });
    };

    return (
        <Container container direction="column">
            <Text variant="display">Review Your Workers</Text>
            <WorkerReviewCopy />
            <OutlinedDropdown
                value={selectedCenter}
                disabled={uniqueLocations.length <= 1}
                fields={uniqueLocations.map((center) => ({
                    key: center.id,
                    value: `${center.name} (${getWorkersAtCenter(center.id)?.length})`,
                }))}
                onChange={(e) => setSelectedCenter(e.target.value)}
            />
            <WorkerSection container>
                {(selectedCenter ? getWorkersAtCenter(selectedCenter) : workers)?.map((worker) => (
                    <ProviderReviewModalLine
                        key={worker.id}
                        worker={worker}
                        options={options}
                        businessLocationId={selectedCenter}
                    />
                ))}
            </WorkerSection>
            <Text>*If you aren&apos;t the admin that supervised these workers, don&apos;t leave them a review.</Text>
        </Container>
    );
}

const Container = styled(Grid)({
    paddingLeft: 40,
    paddingRight: 40,
    gap: 5,
});

const WorkerSection = styled(Grid)({
    gap: 30,
    marginTop: 30,
    marginBottom: 30,
});
