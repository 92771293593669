import React, { useState, useEffect } from 'react';
import { IInputProps } from './PublicApplicationModels';
import Input from '../../../reusableComponents/input';

const PublicApplicationInput = ({
    label,
    value,
    rows,
    onChange,
    shortWidth = true,
    medWidth = false,
    onKeyDown,
}: IInputProps) => {
    const [inputStyle, setInputStyle] = useState<object>({ marginBottom: 3 });

    useEffect(() => {
        if (shortWidth) {
            setInputStyle({ marginBottom: 3, width: 235 });
        } else if (medWidth) {
            setInputStyle({ marginBottom: 3, width: 488 });
        }
    }, [shortWidth, medWidth]);

    return (
        <Input
            fullWidth
            style={inputStyle}
            id={label}
            label={label}
            variant="outlined"
            value={value}
            onChange={(e) => onChange(label, e)}
            inputProps={{ autoCapitalize: 'none' }}
            autoComplete="off"
            rows={rows}
            multiline={rows > 1}
            onKeyDown={onKeyDown}
        />
    );
};

export default PublicApplicationInput;
