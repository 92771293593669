import React from 'react';
import { Grid } from '@mui/material';
import { Text, Colors, IconButton } from 'library';
import { BaseHiredWorker } from 'internal/business-location/models';
import { formatMonthDayYearWith12HourTime } from 'shared/Dates';
import NameButton from './NameButton';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import { hire } from 'api/StaffInfoApi';
import { consoleLogInDev } from 'shared';

export default function HiredWorkers({
    hired,
    setSelectedUser,
    refreshData,
    businessLocationId,
}: {
    hired: BaseHiredWorker[];
    setSelectedUser: (id: number) => void;
    refreshData?: () => void;
    businessLocationId: number;
}) {
    const handleRemoveHire = async (user_id: number) => {
        try {
            await hire(user_id, businessLocationId, false);
            refreshData?.();
        } catch (error) {
            consoleLogInDev(error);
        }
    };
    return (
        <Grid container item gap={1} direction="column" xs={12} md={3}>
            <Text bold variant="h2" textStyle={{ color: Colors.turquoise }}>
                Permanently Hired:
            </Text>
            <Grid container item gap={1} direction="column">
                {hired.length === 0 && <Text>No Hired Workers Found.</Text>}
                {hired.map((hire) => (
                    <Grid key={hire.user.id} container item>
                        <Grid container item gap={1} direction="column" xs={10}>
                            <NameButton onClick={setSelectedUser} user={hire.user} />
                            <Text variant="caption">Hired on {formatMonthDayYearWith12HourTime(hire.hire_date)}</Text>
                        </Grid>
                        <Grid item>
                            <IconButton
                                tooltip="Unmark as permanently hired (Hire date will be lost)"
                                icon={PersonRemoveIcon}
                                onClick={() => handleRemoveHire(hire.user.id)}
                            />
                        </Grid>
                    </Grid>
                ))}
            </Grid>
        </Grid>
    );
}
