import React from 'react';
import FormField from 'parent-portal/forms/components/FormField';
import Input from 'reusableComponents/input';
import { Colors } from 'library';

export default function TextInput({
    label,
    value,
    onChange,
    onBlur,
    error,
    helperText,
    multiline = true,
    onKeyDown,
}: {
    label: string;
    value: string;
    onChange: (value: string) => void;
    onBlur?: () => void;
    error?: boolean;
    helperText?: string;
    multiline?: boolean;
    onKeyDown?: React.KeyboardEventHandler<HTMLDivElement>;
}) {
    return (
        <FormField title={label} titleStyle={{ color: Colors.darkNavy }}>
            <Input
                error={error}
                helperText={helperText}
                fullWidth
                style={{ marginBottom: 0 }}
                value={value}
                onChange={(e) => onChange(e.target.value)}
                onBlur={onBlur}
                multiline={multiline}
                InputLabelProps={{ shrink: true }}
                onKeyDown={onKeyDown}
            />
        </FormField>
    );
}
