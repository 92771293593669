import React, { useEffect, useState } from 'react';
import { AdminSlot } from 'models';
import { Checkbox, PrimaryButton, Text, TimeSlider } from 'library';
import { midnightMinutes } from 'shared/Dates';
import { Grid } from '@material-ui/core';
import { consoleLogInDev } from 'shared';
import { Popover } from '@mui/material';
import { updateSlot } from './slotsApi';
import { addMinutes } from 'date-fns';

export default function EditSlotModal({
    slot,
    refreshJobs,
    anchorEl,
    open,
    onClose,
}: {
    slot: AdminSlot;
    refreshJobs: () => void;
    anchorEl: HTMLElement | null;
    open: boolean;
    onClose: () => void;
}) {
    const [sliderValues, setSliderValues] = useState([0, midnightMinutes]);
    const [changeAppointmentTimes, setChangeAppointmentTimes] = useState(true);

    useEffect(() => setSliderValues(getValues(slot)), [slot]);

    function confirmChanges() {
        let startDate = new Date(slot.start_date);
        let endDate = new Date(slot.end_date);
        const originalValues = getValues(slot);
        if (originalValues[0] !== sliderValues[0])
            startDate = minutesToDate(slot.start_date, slot.timezone_offset, sliderValues[0], true);
        if (originalValues[1] !== sliderValues[1])
            endDate = minutesToDate(slot.end_date, slot.timezone_offset, sliderValues[1]);
        updateSlot(slot.id, startDate, endDate, changeAppointmentTimes)
            .then(refreshJobs)
            .finally(onClose)
            .catch(consoleLogInDev);
    }

    function updateValues(newValues: number | number[]) {
        if (!(newValues instanceof Array)) return;
        setSliderValues(newValues);
    }

    function sliderValueFromDate(iso8601DateString: string, timezoneOffset: number, isStart: boolean = false) {
        const date = new Date(iso8601DateString);
        let hour = (date.getUTCHours() + timezoneOffset + 24) % 24;
        if (!isStart && hour === 0) hour = 24;
        return hour * 60 + date.getMinutes();
    }

    function getValues(slot: AdminSlot): [number, number] {
        return [
            sliderValueFromDate(slot.start_date, slot.timezone_offset, true),
            sliderValueFromDate(slot.end_date, slot.timezone_offset),
        ];
    }
    function minutesToDate(date: string, timezoneOffset: number, currentSliderValue: number, isStart: boolean = false) {
        let newDate = new Date(date);
        const oldSliderValue = sliderValueFromDate(date, timezoneOffset, isStart);
        const totalMinutesDifference = currentSliderValue - oldSliderValue;

        newDate = addMinutes(newDate, totalMinutesDifference);
        return newDate;
    }

    return (
        <Popover open={open} onClose={onClose} anchorEl={anchorEl}>
            <Grid container item style={{ padding: 20, maxWidth: 500 }}>
                <Grid container item style={{ padding: 20, gap: 20 }}>
                    <Text>Edit slot {slot.id}</Text>
                    <TimeSlider min={0} max={midnightMinutes} values={sliderValues} onValuesChange={updateValues} />
                    <Checkbox
                        label="Also change appointment times"
                        checked={changeAppointmentTimes}
                        onChange={setChangeAppointmentTimes}
                    />
                    <PrimaryButton onClick={confirmChanges} buttonStyle={{ width: '50%' }}>
                        Confirm
                    </PrimaryButton>
                </Grid>
            </Grid>
        </Popover>
    );
}
