import { Grid } from '@mui/material';
import React, { useState } from 'react';
import { IconButton, PrimaryButton, SizeableRoundedDialog, Text } from 'library';
import { Edit } from '@material-ui/icons';
import { BusinessJobResponse } from '../models';
import JobFaqForm from 'parent-portal/forms/components/pages/JobFaqPage/JobFaqForm';
import { JobFaq } from 'parent-portal/forms/JobRequestTypes';
import { client } from 'shared';
import { styled } from '@material-ui/core';

export default function JobDetailsFaq({
    businessJobResponse,
    onEditComplete,
}: {
    businessJobResponse: BusinessJobResponse;
    onEditComplete: () => void;
}) {
    const [editFaqOpen, setEditFaqOpen] = useState(false);
    const faqs = businessJobResponse.job_details.faqs;

    const [editingFaqs, setEditingFaqs] = useState<JobFaq[]>(faqs ?? []);
    const [loading, setLoading] = useState(false);
    const onSave = () => {
        setLoading(true);
        client(`api/ongoing/${businessJobResponse?.job_details?.id}/`, {
            method: 'PATCH',
            body: { faqs: editingFaqs },
        })
            .then(() => onEditComplete())
            .finally(() => {
                setLoading(false);
                setEditFaqOpen(false);
            });
    };

    return (
        <Grid xs={12} md={4} container item direction="column">
            <Grid container item style={{ gap: 10 }} alignContent="flex-start">
                <Grid xs={12} container item direction="row">
                    <Text variant="h1" textStyle={{ width: undefined }}>
                        Frequently Asked Questions
                    </Text>
                    <IconButton style={{ margin: '0 10px' }} icon={Edit} onClick={() => setEditFaqOpen(true)} />
                </Grid>
                {faqs?.map((faq) => {
                    return (
                        <Grid item key={faq.question.id}>
                            <Text>
                                <Text bold>{faq.question.question}</Text> {faq.answer}
                            </Text>
                        </Grid>
                    );
                })}
            </Grid>
            <SizeableRoundedDialog
                style={{ maxWidth: 500, padding: 20 }}
                open={editFaqOpen}
                onClose={() => setEditFaqOpen(false)}
                closeButton
                maxWidth="md"
            >
                <Grid container item direction="column" style={{ padding: 20, gap: 10 }}>
                    <Text variant="h1">Update Faqs</Text>
                    <JobFaqForm faqs={editingFaqs} setFaqs={setEditingFaqs} />
                    <SaveButton
                        loading={loading}
                        disabled={loading}
                        onClick={onSave}
                        buttonStyle={{ width: undefined, alignSelf: 'flex-end' }}
                    >
                        Save
                    </SaveButton>
                </Grid>
            </SizeableRoundedDialog>
        </Grid>
    );
}

const SaveButton = styled(PrimaryButton)({
    width: undefined,
    alignSelf: 'flex-end',
});
