import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { Chip, Colors, SmallIconButton, Text } from 'library';
import { JobQualificationValue, Qualification } from 'models';
import React, { useEffect, useState } from 'react';
import FormField from '../../FormField';
import NestedQualificationsModal from './NestedQualificationsModal';
import { addOrRemoveQualification, addOrUpdateQualification } from 'parent-portal/forms/jobDetailMethods';
import { FormFieldProps } from '../../FormTypes';

export default function AdditionalQualifications({
    qualifications,
    jobQualifications,
}: {
    qualifications: Qualification[];
    jobQualifications: FormFieldProps<JobQualificationValue[]>;
}) {
    const [nestedModalVisible, setNestedModalVisible] = useState(false);
    const [additionalQualifications, setAdditionalQualifications] = useState<string[]>([]);
    const { value: values } = jobQualifications;

    useEffect(() => {
        setAdditionalQualifications(
            qualifications
                .filter((qual) => values.some((val) => val.qualification === qual.id))
                .map((qual) => {
                    if (qual.value_type === 'SINGLE_SELECT') {
                        let value = values.find((val) => val.qualification === qual.id);
                        let option = qual.options.find((option) => option.id === value?.option);
                        return `${qual.name} ${option?.value}`;
                    }
                    return qual.name;
                }),
        );
    }, [values, qualifications]);
    return (
        <>
            <FormField title="Qualifications">
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                        alignItems: 'center',
                        paddingTop: 20,
                    }}
                >
                    {additionalQualifications.length === 0 && (
                        <Text variant="h2" bold>
                            Add Qualification
                        </Text>
                    )}
                    {additionalQualifications.map((qual) => (
                        <Chip
                            label={qual}
                            backgroundColor={Colors.darkNavy}
                            textColor={Colors.white}
                            style={{ marginRight: 12, marginBottom: 10 }}
                        />
                    ))}
                    <SmallIconButton
                        backgroundColor={Colors.darkNavy}
                        color={Colors.white}
                        size={18}
                        FontAwesomeImg={faPlus}
                        onClick={() => setNestedModalVisible(true)}
                        style={{ marginLeft: 10, marginBottom: 10 }}
                    />
                </div>
            </FormField>
            <NestedQualificationsModal
                visible={nestedModalVisible}
                onClose={() => setNestedModalVisible(false)}
                qualifications={qualifications.filter((qual) => qual.web_view_options.nested)}
                jobQualifications={jobQualifications}
            />
        </>
    );
}
