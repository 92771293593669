import React from 'react';
import { ThumbUpOutlined } from '@material-ui/icons';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityOffOutlined from '@mui/icons-material/VisibilityOffOutlined';
import { useProviderProfileContext } from '../ProviderProfileContext';
import HireProviderAction from 'parent-portal/providers/cards/actions/HireProviderAction';
import DeclineApplicantAction from '../applicants/DeclineApplicantAction';
import { AppliedProvider } from 'models';
import ApplicationDaysDetail from 'parent-portal/providers/cards/details/ApplicationDaysDetail';
import { Grid } from '@material-ui/core';
import RequestNewSitter from '../RequestNewSitter';
import { useUserContext } from 'UserContext';
import { IconButton } from 'library';

export default function ProviderProfileMainAction() {
    const { role } = useUserContext();
    const { provider, job, likeApplicant, hideApplicant, applicants } = useProviderProfileContext();

    const applicant = applicants.find((x) => x.id === provider.id);

    const isBusiness = role === 'business' || 'business_active';

    const hideLikeActions = () => {
        if (!applicant) {
            return;
        }

        return (
            <Grid container direction="row" justify="space-around" style={{ padding: 5, minWidth: 100, maxWidth: 150 }}>
                {!isBusiness ? (
                    <IconButton
                        icon={applicant.liked ? ThumbUpIcon : ThumbUpOutlined}
                        onClick={() => likeApplicant(applicant)}
                    />
                ) : null}
                {!isBusiness ? (
                    <IconButton
                        icon={applicant.hidden ? VisibilityOffIcon : VisibilityOffOutlined}
                        onClick={() => hideApplicant(applicant)}
                    />
                ) : null}
            </Grid>
        );
    };

    const appliedProviderActions = () =>
        job ? (
            <>
                <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', alignItems: 'center' }}>
                    {job.ongoing && (
                        <ApplicationDaysDetail provider={provider as AppliedProvider} allJobDays={job.days_of_week} />
                    )}

                    <div style={{ width: 16, height: 80 }} />
                    <div style={{ display: 'flex', flexDirection: 'row', flexGrow: 1, alignItems: 'center' }}>
                        {hideLikeActions()}
                        {!isBusiness && (
                            <DeclineApplicantAction
                                provider={provider as AppliedProvider}
                                job={job}
                                style={{ marginRight: 16, minWidth: 156 }}
                            />
                        )}
                        <HireProviderAction
                            provider={provider as AppliedProvider}
                            job={job}
                            style={{ minWidth: 156 }}
                        />
                    </div>
                </div>
            </>
        ) : null;

    if (role === 'business_active') {
        return <></>;
    }

    return (
        <Grid>
            {(provider as AppliedProvider).application?.hired ? (
                <RequestNewSitter />
            ) : job ? (
                appliedProviderActions()
            ) : null}
        </Grid>
    );
}
