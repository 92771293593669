import React, { useEffect, useState } from 'react';
import { useJobFormContext } from 'parent-portal/forms/JobFormContext';
import BusinessJobDetails from '../../HeadlineDescriptionPage/BusinessJobDetails';
import EditDialog from './EditDialog';

export default function EditJobDetailsModal({ open, onClose }: { open: boolean; onClose: () => void }) {
    const { form } = useJobFormContext();
    const { headline, comments, jobDetails, trialRunBenefits, businessJobType } = form.fieldStates;
    const [isValid, setIsValid] = useState(false);

    useEffect(() => {
        setIsValid(headline.value !== '' && comments.value !== '');
    }, [headline, comments]);

    return (
        <EditDialog title={`Edit ${headline.value} Details`} open={open} onClose={onClose} isValid={isValid}>
            <BusinessJobDetails
                headline={headline}
                comments={comments}
                jobDetails={jobDetails}
                trialRunBenefits={trialRunBenefits}
                businessJobType={businessJobType}
            />
        </EditDialog>
    );
}
