import React, { useState } from 'react';
import { Colors, Text } from 'library';
import { useJobFormContext } from 'parent-portal/forms/JobFormContext';
import SectionContainer from './SectionContainer';
import { useUserContext } from 'UserContext';
import EditJobLocationModal from './EditJobLocationModal';
import { Grid } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquareCheck } from '@fortawesome/free-solid-svg-icons';

export default function LocationSection() {
    const [editOpen, setEditOpen] = useState(false);
    const { user } = useUserContext();
    const { form } = useJobFormContext();
    const { address } = form.fieldStates;

    if (!address.value || address.value.length === 0) return null;

    return (
        <SectionContainer
            editModal={<EditJobLocationModal open={editOpen} onClose={() => setEditOpen(false)} />}
            setEditOpen={setEditOpen}
        >
            <Text variant="h2" bold textStyle={styles.header}>
                Location{address.value.length > 1 ? 's' : ''}
            </Text>
            {address.value.map((a) => {
                const location = user?.address.find((x) => x.id === a.id);
                return (
                    <Grid key={a.id} marginBottom={1} container direction="row" gap={1} alignItems="center">
                        <FontAwesomeIcon icon={faSquareCheck} color={Colors.turquoise} />
                        <Text variant="body2">
                            {location?.description}, {location?.street}, {location?.city}, {location?.state}{' '}
                            {location?.zip_code}
                        </Text>
                    </Grid>
                );
            })}
        </SectionContainer>
    );
}

const styles = {
    header: {
        marginBottom: 8,
    },
};
