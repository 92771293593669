import React from 'react';
import { Grid, TextField } from '@material-ui/core';
import moment, { Moment } from 'moment';
import theme from 'theme';
import { useMediaQuery } from '@mui/material';

export function TimePickers({ startTime, endTime, setStartTime, setEndTime }: any) {
    const smDown = useMediaQuery(theme.breakpoints.down('xs'));
    function error() {
        return startTime?.isAfter(endTime);
    }

    function handleTimeChange(time: string, dateValue: Moment) {
        let times = moment(time, 'HH:mm');
        let newDate = moment(dateValue);
        newDate.set({ hour: times.hour(), minute: times.minute() });
        return newDate;
    }
    return startTime && endTime ? (
        <>
            <TextField
                style={{ marginBottom: 10, marginRight: smDown ? 0 : 30, minWidth: 215 }}
                id="Start Time"
                label="Start Time"
                type="time"
                defaultValue={moment(startTime).format('HH:mm')}
                onChange={(e) => {
                    setStartTime(handleTimeChange(e.target.value, startTime));
                }}
                error={error()}
                helperText={error() ? 'Start time must be before end time.' : ''}
                InputLabelProps={{
                    shrink: true,
                }}
            />
            <Grid>
                <TextField
                    style={{ marginBottom: 10, minWidth: 215 }}
                    id="End Time"
                    label="End Time"
                    type="time"
                    defaultValue={moment(endTime).format('HH:mm')}
                    onChange={(e) => {
                        setEndTime(handleTimeChange(e.target.value, endTime));
                    }}
                    error={error()}
                    helperText={error() ? 'End time must be after start time.' : ''}
                />
            </Grid>
        </>
    ) : null;
}
