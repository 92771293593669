import { Accordion, AccordionDetails, AccordionSummary, CircularProgress, Grid, makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { client, formatAddress, consoleLogInDev } from 'shared';
import { Chip, Colors, Paper, Text, TextButton } from 'library';
import { formatShortWeekdayMonthDay } from 'shared/Dates';
import { ExpandMore } from '@material-ui/icons';
import { Address, ShiftProviderUser } from 'models';
import { IShiftsByProvider, WeeklyJobFeedback } from '../BusinessJob/models';
import TrialRunInfo from '../BusinessJob/TrialRunInfo';
import { Link } from 'react-router-dom';
import { useModalsContext } from 'ModalsContext';
import { CalendarColors } from '../BusinessCalendar/HiredShift';
import { useUserContext } from 'UserContext';

interface IBusinessOngoing {
    id: number;
    headline: string;
    family_comments: string;
    status: string;
    start_date: string;
    end_date: string;
    business_job_type: string;
    address: Address;
    filled_count: number;
    shift_count: number;
    weekly_job_feedback: WeeklyJobFeedback[];
    expired?: boolean;
}

interface IProvidersResponse {
    provider_shifts: IShiftsByProvider[];
    cancelled_users: ShiftProviderUser[];
}

interface IShiftListProps {
    addressId?: number;
    active: boolean;
    expired: boolean;
}

export default function ShiftList({ addressId, active, expired }: IShiftListProps) {
    const [openCenter, setOpenCenter] = useState<number>(-1);
    const [loading, setLoading] = useState(true);
    const [ongoingRequests, setOngoingRequests] = useState<IBusinessOngoing[]>([]);
    const [providers, setProviders] = useState<IProvidersResponse>();
    const { openJobForm } = useModalsContext();
    const { getDisplayText } = useUserContext();

    const classes = useStyles();

    function getUpcomingShifts() {
        setProviders(undefined);
        setOpenCenter(-1);
        if (addressId) {
            client(`api/business-ongoing/?address_id=${addressId}&show_active=${active}&show_expired=${expired}`)
                .then((response: IBusinessOngoing[]) => {
                    setOngoingRequests(response);
                })
                .catch(consoleLogInDev)
                .finally(() => {
                    setLoading(false);
                });
        }
    }

    function getShiftDetails(ongoingRequestId: number) {
        setProviders(undefined);
        client(`api/business-ongoing/${ongoingRequestId}/providers/`)
            .then((response: IProvidersResponse) => {
                const provider_shifts = response.provider_shifts;
                const cancelled_users = response.cancelled_users.filter(
                    (x) => !provider_shifts.some((y) => y.provider.id === x.id),
                );

                setProviders({ provider_shifts, cancelled_users });
            })
            .catch(consoleLogInDev);
    }

    useEffect(() => {
        getUpcomingShifts();
    }, [addressId, active, expired]);

    function onAccordianItemClick(ongoingRequest: IBusinessOngoing) {
        if (openCenter === ongoingRequest.id) {
            setOpenCenter(-1);
        } else {
            setOpenCenter(ongoingRequest.id);
            getShiftDetails(ongoingRequest.id);
        }
    }

    function getStatus(ongoingRequest: IBusinessOngoing) {
        if (!ongoingRequest.filled_count) {
            return 'Awaiting Placement';
        }

        if (ongoingRequest.shift_count && ongoingRequest.shift_count === ongoingRequest.filled_count) {
            return 'Filled';
        } else if (ongoingRequest.shift_count !== ongoingRequest.filled_count) {
            return 'Partially Filled';
        }

        return 'Awaiting Placement';
    }

    if (loading) {
        return (
            <Grid container item xs={9} style={{ height: '100vh' }}>
                <Grid container alignItems="center" justify="center">
                    <Grid item>
                        <CircularProgress />
                    </Grid>
                </Grid>
            </Grid>
        );
    }

    function getCancelledActions(userId: number) {
        return (
            <Grid item direction="row" sm={8} justify="flex-end">
                <Link to={`/providers/review/${userId}`} className={classes.link}>
                    Review
                </Link>
            </Grid>
        );
    }

    function getActionsAndDetails(ongoingRequest: IBusinessOngoing, provider: IShiftsByProvider) {
        if (ongoingRequest.business_job_type.toLowerCase() === 'substitute') {
            return (
                <Grid container direction="row" sm={8} justify="space-around">
                    <Link to={`/providers/review/${provider.provider.id}`} className={classes.link}>
                        Review
                    </Link>
                    <TextButton
                        buttonStyle={{ width: undefined }}
                        onClick={() =>
                            openJobForm('Jobs', {
                                pastProviders: [
                                    {
                                        name: provider.provider.first_name,
                                        id: provider.provider.id,
                                    },
                                ],
                            })
                        }
                    >
                        Request Again
                    </TextButton>
                </Grid>
            );
        }

        return (
            <Grid item direction="row" alignItems="center" sm={8}>
                <TrialRunInfo
                    providerShifts={provider}
                    ongoingRequestId={ongoingRequest.id}
                    weeklyJobFeedback={ongoingRequest.weekly_job_feedback}
                    refresh={getUpcomingShifts}
                />
            </Grid>
        );
    }

    return (
        <Grid container item direction="row" xs={12} style={{ margin: '20px 0', borderRadius: 18 }}>
            {!ongoingRequests.length ? (
                <Text>
                    You currently have no confirmed shifts, post a job or reach out to us at{' '}
                    <a
                        style={{ color: Colors.darkNavy, textDecorationLine: 'underline' }}
                        href="mailto:support@join-tandem.com"
                    >
                        support@join-tandem.com
                    </a>{' '}
                    to get started!
                </Text>
            ) : (
                ongoingRequests.map((ongoingRequest) => (
                    <Accordion
                        key={ongoingRequest.id}
                        expanded={openCenter === ongoingRequest.id}
                        style={{ borderRadius: 18, width: '80%', marginTop: 5, position: 'unset' }}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMore onClick={() => onAccordianItemClick(ongoingRequest)} />}
                            style={{ cursor: 'initial' }}
                        >
                            <Grid container direction="row" alignItems="center">
                                <Grid container item sm={6}>
                                    <Grid container direction="row" alignItems="center">
                                        <TextButton
                                            buttonStyle={{ display: 'inline', width: undefined }}
                                            onClick={() => onAccordianItemClick(ongoingRequest)}
                                        >
                                            <Text variant="h2" bold textStyle={{ textDecoration: 'underline' }}>
                                                {ongoingRequest.headline}
                                            </Text>
                                        </TextButton>
                                        <Chip
                                            label={getDisplayText(
                                                ongoingRequest.business_job_type.replace('_', ' ').toLowerCase(),
                                            )}
                                            style={{
                                                textTransform: 'capitalize',
                                                marginLeft: 10,
                                            }}
                                            backgroundColor={CalendarColors[ongoingRequest.business_job_type]}
                                        />
                                        {ongoingRequest.expired && (
                                            <Chip
                                                label="Expired"
                                                style={{
                                                    textTransform: 'capitalize',
                                                    marginLeft: 10,
                                                }}
                                                backgroundColor={Colors.mediumTerraCotta}
                                            />
                                        )}
                                    </Grid>
                                    <Text variant="body1">{formatAddress(ongoingRequest.address)}</Text>
                                </Grid>
                                <Grid container item sm={3} justify="center">
                                    <Text variant="body2">
                                        <b>Start Date</b>:{' '}
                                        {formatShortWeekdayMonthDay(ongoingRequest.start_date.replaceAll('-', '/'))}
                                    </Text>
                                </Grid>
                                <Grid item sm={3}>
                                    <Text variant="body2">
                                        <b>Status</b>: {getStatus(ongoingRequest)}
                                    </Text>
                                </Grid>
                            </Grid>
                        </AccordionSummary>
                        <AccordionDetails style={{ flexWrap: 'wrap' }}>
                            <Paper>
                                <Link to={`/jobs/${ongoingRequest.id}`} className={classes.link}>
                                    View Details
                                </Link>
                                {providers?.provider_shifts.map((provider) => {
                                    return (
                                        <Grid
                                            key={provider.provider.id}
                                            container
                                            direction="row"
                                            alignItems="center"
                                            className={classes.provideContainer}
                                        >
                                            <Grid item sm={4}>
                                                <Link to={`/provider/${provider.provider.id}`} className={classes.link}>
                                                    <Text variant="body2">
                                                        {provider.provider.first_name} {provider.provider.last_name}
                                                    </Text>
                                                </Link>
                                            </Grid>
                                            {getActionsAndDetails(ongoingRequest, provider)}
                                        </Grid>
                                    );
                                })}
                                {providers?.cancelled_users.map((provider) => {
                                    return (
                                        <Grid
                                            key={provider.id}
                                            container
                                            direction="row"
                                            alignItems="center"
                                            justify="space-between"
                                            className={classes.provideContainer}
                                        >
                                            <Grid item sm={4} className={classes.cancelledContainer}>
                                                <Link to={`/provider/${provider.id}`} className={classes.link}>
                                                    <Text variant="body2">
                                                        {provider.first_name} {provider.last_name}
                                                    </Text>
                                                </Link>
                                                <Text variant="body2" textStyle={{ display: 'inline', marginLeft: 5 }}>
                                                    (Cancelled)
                                                </Text>
                                            </Grid>
                                            {getCancelledActions(provider.id)}
                                        </Grid>
                                    );
                                })}
                            </Paper>
                        </AccordionDetails>
                    </Accordion>
                ))
            )}
        </Grid>
    );
}

const useStyles = makeStyles(() => ({
    provideContainer: {
        padding: '15px 10px',
        backgroundColor: Colors.lightGrey,
        borderRadius: 10,
        marginTop: 10,
        marginBottom: 10,
    },
    link: {
        textDecoration: 'underline',
        color: Colors.darkNavy,
    },
    cancelledContainer: {
        display: 'inline-flex',
    },
}));
