import React, { ReactNode, CSSProperties } from 'react';
import Text from '../text/Text';
import { Accordion as MaterialAccordion, AccordionSummary, AccordionDetails, styled } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function Accordion({
    title,
    children,
    defaultExpanded = true,
    style,
    className,
}: {
    title: string;
    children: ReactNode;
    defaultExpanded?: boolean;
    style?: CSSProperties;
    className?: string;
}) {
    return (
        <MaterialAccordion defaultExpanded={defaultExpanded} elevation={0} style={style} className={className}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{padding: 0}}>
                <Text bold variant="body1">
                    {title}
                </Text>
            </AccordionSummary>
            <DetailsSection>{children}</DetailsSection>
        </MaterialAccordion>
    );
}

const DetailsSection = styled(AccordionDetails)({
    padding: 0,
});
