import React from 'react';

import { BasicDialog } from 'library';
import ReviewProvider from 'parent-portal/provider-reviews/ReviewProvider';

export function PromptForReviewModal({
    open,
    onClose,
    userId,
}: {
    open: boolean;
    onClose: () => void;
    userId?: number;
}) {
    return (
        <BasicDialog
            isOpen={open}
            onClose={() => {
                onClose();
            }}
            closeButton
        >
            <ReviewProvider promptedByAction userId={userId} />
        </BasicDialog>
    );
}
