import React from 'react';
import BubbleButton from './BubbleButton';
import { Colors } from '../../theme/Colors';
import { ButtonProps } from 'models';
import CircularProgress from '@material-ui/core/CircularProgress';

const defaultStyle = {
    text: {
        color: 'white',
        paddingTop: 3,
        paddingBottom: 3,
    },
    button: {
        outline: 'none',
    },
};

const enabledStyle = {
    button: {
        backgroundColor: Colors.darkNavy,
        '&:hover': {
            backgroundColor: Colors.pressedNavy,
        },
    },
};

const disabledStyle = {
    button: {
        backgroundColor: Colors.disabledGrey,
    },
};

const PrimaryButton = React.forwardRef<HTMLButtonElement, ButtonProps>(function _PrimaryButton(
    { children, rightAlign, boldText = true, loading = false, ...props }: ButtonProps,
    ref,
) {
    function getButton() {
        return (
            <BubbleButton
                ref={ref}
                defaultStyle={defaultStyle}
                enabledStyle={enabledStyle}
                disabledStyle={disabledStyle}
                boldText={boldText}
                buttonStyle={{ width: rightAlign ? undefined : '100%', minWidth: 180 }}
                disabled={loading ? true : props.disabled}
                {...props}
            >
                {loading ? (
                    <CircularProgress style={{ color: 'white', display: 'flex' }} size={20} thickness={5} />
                ) : (
                    children
                )}
            </BubbleButton>
        );
    }
    return rightAlign ? (
        <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'flex-end' }}>
            {getButton()}
        </div>
    ) : (
        getButton()
    );
});

export default PrimaryButton;
