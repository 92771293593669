import React from 'react';
import { Avatar, Grid } from '@material-ui/core';
import { IconButton, Colors } from 'library';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import { UserContextUser } from 'models';

export default function ProfilePicture({
    user,
    setPictureDialogVisible,
}: {
    user: UserContextUser;
    setPictureDialogVisible: (b: boolean) => void;
}) {
    return (
        <Grid>
            <Avatar
                src={user?.profile_picture?.image ? user.profile_picture.image : undefined}
                alt={user?.businesses.length > 0 ? user?.businesses[0].name : 'profile'}
                style={{
                    margin: 0,
                    padding: 0,
                    height: 150,
                    width: 150,
                    backgroundColor: Colors.darkNavy,
                    fontSize: '5rem',
                }}
            >
                {user?.businesses.length > 0 ? user?.businesses[0].name[0] : undefined}
            </Avatar>
            <IconButton
                onClick={() => setPictureDialogVisible(true)}
                icon={AddAPhotoIcon}
                style={{
                    position: 'relative',
                    bottom: 30,
                    left: 100,
                }}
            />
        </Grid>
    );
}
