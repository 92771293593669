import { isMonday, parse, previousMonday } from 'date-fns';
import { OutlinedTextInput } from 'library';
import React, { useEffect } from 'react';
import { formatDateFromDateObject } from 'shared/Dates';

export default function StartDateSelector({
    selectedPeriod,
    startDate,
    setStartDate,
}: {
    selectedPeriod: string;
    startDate: Date;
    setStartDate: (val: Date) => void;
}) {
    useEffect(() => {
        updateStartDate(formatDateFromDateObject(startDate));
    }, [selectedPeriod]);

    function updateStartDate(val: string) {
        let date = parse(val, 'yyyy-MM-dd', new Date());
        if (selectedPeriod === 'Week') {
            if (!isMonday(date)) {
                date = previousMonday(date);
            }
        } else if (selectedPeriod === 'Month') {
            date.setDate(1);
        }
        setStartDate(date);
    }

    return (
        <OutlinedTextInput
            type="date"
            label={`${selectedPeriod} starting`}
            value={formatDateFromDateObject(startDate)}
            onChange={(e) => updateStartDate(e.target.value)}
            disabled={selectedPeriod === 'All Time' || selectedPeriod === 'Today'}
        />
    );
}
