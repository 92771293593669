import React, { useState } from 'react';
import { AdminProvider, BabysitterSuccessProgress } from 'models';
import { BasicDialog, PrimaryButton, Text } from 'library';
import DateTimePicker from 'internal/shared/DateTimePicker';
import moment from 'moment';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { client, consoleLogInDev } from 'shared';

const bspDateFields = [
    'provider_completed_background_check_florida',
    'verified_background_check_florida',
] as (keyof BabysitterSuccessProgress)[];

export default function BspDialog({ provider }: { provider: AdminProvider }) {
    const [showBsp, setShowBsp] = useState(false);
    const [updatedFields, setUpdatedFields] = useState<Partial<Record<keyof BabysitterSuccessProgress, any>>>();
    const [allFields, setAllFields] = useState<Partial<Record<keyof BabysitterSuccessProgress, any>>>(
        provider.success_progress[0],
    );

    function updateField(fieldName: keyof BabysitterSuccessProgress, date: MaterialUiPickersDate) {
        const formattedDate = date?.format('YYYY-MM-DD HH:MM') || null;
        const newFields = { ...updatedFields };
        newFields[fieldName] = formattedDate;
        setUpdatedFields(newFields);

        allFields[fieldName] = formattedDate;
        setAllFields(allFields);
    }

    function save() {
        client(`api/babysitter-success-progress/${provider.success_progress[0].id}/`, {
            method: 'PATCH',
            body: updatedFields,
        })
            .then((result) => alert(`BSP saved: ${JSON.stringify(result)}`))
            .catch(consoleLogInDev);
    }

    return (
        <>
            <PrimaryButton buttonStyle={{ width: 200, marginTop: 10 }} onClick={() => setShowBsp(true)}>
                Edit BSP
            </PrimaryButton>
            <BasicDialog closeButton isOpen={showBsp} onClose={() => setShowBsp(false)}>
                {bspDateFields.map((fieldName) => (
                    <>
                        <Text variant="body2" textStyle={{ marginTop: 10 }}>
                            {fieldName}
                        </Text>
                        <DateTimePicker
                            value={allFields[fieldName] ? moment(allFields[fieldName]) : null}
                            onSelectDate={(date) => updateField(fieldName, date)}
                        />
                    </>
                ))}
                <PrimaryButton buttonStyle={{ marginTop: 20 }} onClick={save}>
                    Save
                </PrimaryButton>
            </BasicDialog>
        </>
    );
}
