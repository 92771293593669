import { Colors } from 'library';
import React from 'react';
import { TaskListItem } from '../matchingInboxModels';
import { styled } from '@mui/material';
import { Check } from '@mui/icons-material';
import { toggledResolved } from '../modal-content/modalContentApi';

export default function ResolveButton({ task, onResolve }: { task: TaskListItem; onResolve: () => void }) {
    function resolve() {
        toggledResolved(task).then(onResolve);
    }
    return (
        <CheckBoxContainer onClick={resolve}>
            {task.resolved && <Check htmlColor={Colors.darkNavy} />}
        </CheckBoxContainer>
    );
}

const CheckBoxContainer = styled('button')({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 24,
    height: 24,
    borderRadius: 20,
    border: `1px solid ${Colors.darkNavy}`,
    marginRight: 4,
    padding: 14,
    alignSelf: 'center',
    backgroundColor: Colors.white,
});
