import React from 'react';
import { BasicDialog } from 'library';
import OutreachConversation from './OutreachConversation';

export default function OutreachConversationModal({
    externalNumber,
    tandemNumber,
    displayName,
    isOpen,
    onClose,
}: {
    externalNumber: string;
    tandemNumber: string;
    displayName: string;
    isOpen: boolean;
    onClose: () => void;
}) {
    return (
        <BasicDialog isOpen={isOpen} onClose={onClose} closeButton>
            <OutreachConversation
                externalNumber={externalNumber}
                tandemNumber={tandemNumber}
                displayName={displayName}
                onMarkUnread={onClose}
            />
        </BasicDialog>
    );
}
