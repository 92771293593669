import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import { Paper } from '@material-ui/core';
import theme from '../theme';
import { fade } from '@material-ui/core/styles/colorManipulator';

const StyledPaper = withStyles({
    root: (props) => ({
        background: props.white ? 'white' : '#F7F7F7',
        marginTop: props.topMargin ? props.topMargin : 0,
        borderRadius: 5,
        padding: '3%',
        height: '100%',
        width: '100%',
    }),
})(Paper);

export default function ColoredPaper(props) {
    return (
        <StyledPaper elevation={1} topMargin={props.topMargin} white={props.white}>
            {props.children}
        </StyledPaper>
    );
}
