import React, { useState } from 'react';
import { ClickAwayListener, Grid, Radio, Tooltip, withStyles } from '@material-ui/core';
import { Text, Colors } from 'library';
import { useProviderProfileContext } from 'parent-portal/provider-profile/ProviderProfileContext';
import HelpIcon from '@material-ui/icons/Help';
import { useUserContext } from 'UserContext';

export default function ApplicantFilters({ superstars, showSuperstars, setShowSuperstars, disabled }: any) {
    const [showTooltip, setShowTooltip] = useState<string>();
    const { role } = useUserContext();

    const {
        relevanceFilter,
        orderByExperience,
        setRelevanceFilter,
        setOrderByExperience,
        orderByQualifications,
        setOrderByQualifications,
    } = useProviderProfileContext();
    const baseFilters = [
        role !== 'business_active'
            ? {
                  text: 'Relevance',
                  checked: relevanceFilter,
                  onChange: setRelevanceFilter,
                  title: 'These providers all have this job type in their service offerings.',
              }
            : null,
        role !== 'business_active'
            ? {
                  text: 'Experience',
                  checked: orderByExperience,
                  onChange: setOrderByExperience,
                  title: 'These providers have all completed jobs of this type on Tandem.',
              }
            : null,
    ].filter((item) => !!item);

    let avialFilters =
        superstars.length > 0
            ? [
                  {
                      text: 'Show Superstars',
                      checked: showSuperstars,
                      onChange: setShowSuperstars,
                      title: 'Superstars are Tandem’s top-rated and most experienced providers',
                  },
                  ...baseFilters,
              ]
            : baseFilters;

    const StyledToolTip = withStyles({
        tooltip: {
            backgroundColor: Colors.white,
            boxShadow: '0px 3px 6px #00000029',
            borderRadius: 18,
        },
    })(Tooltip);

    const StyledRadio = withStyles({
        root: {
            color: Colors.darkNavy,
            '&$checked': {
                color: Colors.darkNavy,
            },
            '& .MuiSvgIcon-root': { fontSize: '1rem' },
            padding: 2,
            paddingRight: 10,
        },
        checked: { color: Colors.darkNavy },
        icon: {
            fontSize: 12,
        },
    })(Radio);

    function updateFilter(b: boolean, name: string, stateUpdate: (b: boolean) => void) {
        setShowTooltip(undefined);
        stateUpdate(b);
    }

    function showToolTip(text: string) {
        if (!disabled) setShowTooltip(showTooltip === text ? '' : text);
    }

    return (
        <ClickAwayListener onClickAway={() => setShowTooltip(undefined)}>
            <Grid style={{ padding: 10 }}>
                <Text bold variant="body2">
                    Filter Applicants
                </Text>
                {avialFilters.map(
                    (f) =>
                        f !== null && (
                            <Grid key={f.title} container direction="row" alignItems="center">
                                <StyledRadio
                                    checked={f.checked}
                                    value={f.checked}
                                    onClick={() => updateFilter(!f.checked, f.text, f.onChange)}
                                    disabled={disabled}
                                />
                                <Text
                                    variant="caption"
                                    textStyle={{
                                        cursor: disabled ? 'text' : 'pointer',
                                        color: disabled ? Colors.grey : Colors.darkNavy,
                                    }}
                                    onClick={() => {
                                        if (!disabled) updateFilter(!f.checked, f.text, f.onChange);
                                    }}
                                >
                                    {f.text}
                                </Text>
                                <Grid
                                    container
                                    item
                                    xs={2}
                                    alignItems="center"
                                    style={{ padding: 10 }}
                                    onClick={() => showToolTip(f.text)}
                                >
                                    <StyledToolTip
                                        onClick={() => showToolTip(f.text)}
                                        open={showTooltip === f.text}
                                        style={{ backgroundColor: Colors.white }}
                                        title={<Text variant="caption">{f.title ?? ''}</Text>}
                                    >
                                        <HelpIcon
                                            style={{ fontSize: 14 }}
                                            htmlColor={disabled ? Colors.grey : Colors.darkNavy}
                                        />
                                    </StyledToolTip>
                                </Grid>
                            </Grid>
                        ),
                )}
            </Grid>
        </ClickAwayListener>
    );
}
