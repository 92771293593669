import React from 'react';
import { styled } from '@material-ui/core';
import { Colors } from 'library';
import Menu, { MenuProps } from '@mui/material/Menu';
import ProfileMenuItem from './ProfileMenuItem';
import NotificationMenuItem from './NotificationMenuItem';
import LogoutMenuItem from './LogoutMenuItem';

const StyledMenu = styled((props: MenuProps) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 18,
        marginTop: theme.spacing(1),
        width: 180,
        color: Colors.white,
        backgroundColor: Colors.white,
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px',
        },
        '& .MuiMenuItem-root': {
            '&:active': {
                color: Colors.white,
                backgroundColor: Colors.white,
            },
            '&:hover': {
                color: Colors.white,
                backgroundColor: Colors.white,
            },
            '&:focus': {
                color: Colors.white,
                backgroundColor: Colors.white,
            },
        },
    },
}));

export default function AccountMenu({
    open,
    onClose,
    anchorEl,
}: {
    open: boolean;
    onClose: () => void;
    anchorEl: HTMLElement | null;
}) {
    return (
        <StyledMenu
            id="demo-customized-menu"
            MenuListProps={{
                'aria-labelledby': 'demo-customized-button',
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={onClose}
        >
            <ProfileMenuItem />
            <NotificationMenuItem />
            <LogoutMenuItem />
        </StyledMenu>
    );
}
