import React, { CSSProperties } from 'react';
import { Text } from 'library';
import DontWorry from './DontWorry';
import DollarIcon from 'assets/icons/tandempay/dollar-icon-circle.svg';
import DontRecycleMoneyIcon from 'assets/icons/tandempay/dont-recycle-money-icon-circle.svg';
import CalendarIcon from 'assets/icons/tandempay/calendar-icon-circle.svg';

const spaced: CSSProperties = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 18,
};

const icon: CSSProperties = {
    width: 35,
    height: 35,
    marginRight: 17,
};

export default function WeeklyJobCopy() {
    return (
        <>
            <div style={{ marginBottom: 33 }}>
                <Text variant="body1" textStyle={{ marginBottom: 33 }}>
                    You can expect this job to receive applicants. Once you review profiles and hire a provider, you'll
                    pay them through TandemPay, our free-to-use, built-in payment platform.
                </Text>
            </div>
            <div style={spaced}>
                <DontWorry />
            </div>
            <div style={spaced}>
                <CalendarIcon style={icon} />
                <Text variant="body1" textStyle={{ fontSize: 18 }}>
                    We handle taxes for your provider and give you easy access to your payment history.
                </Text>
            </div>
            <div style={spaced}>
                <DontRecycleMoneyIcon style={icon} />
                <Text variant="body1" textStyle={{ fontSize: 18 }}>
                    You don't pay fees and your provider gets 100%.
                </Text>
            </div>
            <div style={spaced}>
                <DollarIcon style={icon} />
                <Text variant="body1" textStyle={{ fontSize: 18 }}>
                    You set your rate and a payment schedule of your choice.
                </Text>
            </div>
        </>
    );
}
