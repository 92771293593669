import React from 'react';
import { Grid } from '@material-ui/core';
import CancelIcon from '@mui/icons-material/Cancel';
import { Colors, Text } from 'library';
import { Link } from 'react-router-dom';
import { IBusinessLocation } from 'models';
import { IBusinessFaqQuestions } from 'parent-portal/home/Models/DashboardData';
import { useLocationRequirements } from './useLocationRequirements';

export default function BusinessLocationUnfilledDetails({
    faqQuestions,
    businessLocation,
}: {
    faqQuestions: IBusinessFaqQuestions[];
    businessLocation: IBusinessLocation;
}) {
    const { unfilledDetails, percentFilled } = useLocationRequirements({ faqQuestions, businessLocation });

    return (
        <Grid container item xs={12} md={6} direction="column" style={{ marginTop: '10px' }}>
            <Text textStyle={{ marginBottom: 10 }} color={percentFilled === 100 ? Colors.turquoise : Colors.error} bold>
                {percentFilled}% of your profile completed.
            </Text>
            <Grid container style={{ maxHeight: 60, overflowY: 'scroll' }}>
                {unfilledDetails.map((unfilledMessage) => (
                    <Grid key={unfilledMessage} container style={{ marginBottom: '2px' }}>
                        <Grid container item direction="row" style={{ display: 'flex', alignItems: 'center' }}>
                            <Grid container item xs={10}>
                                <CancelIcon fontSize="small" sx={{ color: Colors.mediumRed }} />
                                <Grid item style={{ marginLeft: 5 }}>
                                    <Text variant="body2">{unfilledMessage}</Text>
                                </Grid>
                            </Grid>
                            <Text variant="body2">
                                <Link
                                    to={`/profile/locations/${businessLocation.id}`}
                                    style={{
                                        color: Colors.darkNavy,
                                        textDecorationLine: 'underline',
                                        fontSize: 'inherit',
                                    }}
                                >
                                    Add
                                </Link>
                            </Text>
                        </Grid>
                    </Grid>
                ))}
            </Grid>
        </Grid>
    );
}
