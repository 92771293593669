import { Grid } from '@mui/material';
import { Colors, Text } from 'library';
import React from 'react';
import { ArcherElement } from 'react-archer';

export function InfoSection({
    year,
    side,
    children,
    style,
    targetYear,
    targetBlock,
    containerStyle,
}: {
    year: string;
    side: string;
    children: JSX.Element | JSX.Element[];
    style: any;
    targetYear?: string;
    targetBlock?: string;
    containerStyle?: any;
}) {
    function content() {
        return (
            <Grid xs={10} item container direction="row" style={{ gap: 20 }} justifyContent={side}>
                {children}

                <Grid
                    item
                    container
                    style={{
                        borderRadius: 100,
                        position: 'relative',
                        width: 200,
                        maxHeight: 100,
                        boxShadow: '0px 3px 6px #00000029',
                        padding: 20,
                        ...style,
                    }}
                    justifyContent="center"
                >
                    {targetYear ? (
                        <ArcherElement
                            id={year}
                            relations={[
                                {
                                    targetId: targetYear,
                                    targetAnchor: 'top',
                                    sourceAnchor: 'bottom',
                                    style: { strokeDasharray: '10,5', strokeWidth: 5 },
                                },
                            ]}
                        >
                            <div>
                                <Text variant="h1" textStyle={{ lineHeight: 'unset' }}>
                                    {year}
                                </Text>
                            </div>
                        </ArcherElement>
                    ) : (
                        <Text variant="h1" textStyle={{ lineHeight: 'unset' }}>
                            {year}
                        </Text>
                    )}
                </Grid>
            </Grid>
        );
    }
    return (
        <Grid xs={12} container item flexDirection="row" justifyContent={side} style={containerStyle}>
            <Grid
                xs={12}
                md={8}
                container
                item
                style={{
                    backgroundColor: Colors.white,
                    borderRadius: 18,
                    boxShadow: '0px 3px 6px #00000029',
                    padding: 40,
                    justifySelf: side,
                }}
                justifyContent={side}
            >
                {targetBlock ? (
                    <ArcherElement
                        id={year}
                        relations={[
                            {
                                targetId: targetBlock,
                                targetAnchor: 'top',
                                sourceAnchor: 'top',
                                style: { strokeDasharray: '10,5', strokeWidth: 5 },
                            },
                        ]}
                    >
                        {content()}
                    </ArcherElement>
                ) : (
                    content()
                )}
            </Grid>
        </Grid>
    );
}
