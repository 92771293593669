import { ButtonContainer } from '../components/Containers';
import { PrimaryButton, SecondaryButton } from 'library';
import React from 'react';

export default function MainButtons({
    onSetPreferredClick,
    onNoClick,
}: {
    onSetPreferredClick: () => void;
    onNoClick: () => void;
}) {
    return (
        <ButtonContainer>
            <SecondaryButton buttonStyle={{ width: 'unset' }} onClick={onNoClick}>
                No, not right now
            </SecondaryButton>
            <PrimaryButton buttonStyle={{ width: 'unset' }} onClick={onSetPreferredClick}>
                Yes, please add
            </PrimaryButton>
        </ButtonContainer>
    );
}
