import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import theme from '../theme';

const MarginSubHeader = withStyles({
    root: (props) => ({
        color: theme.palette.primary.main,
        textAlign: 'justify',
        marginBottom: 30,
        marginRight: 20,
        marginLeft: 2,
    }),
})(Typography);

const NoMarginSubHeader = withStyles({
    root: (props) => ({
        color: theme.palette.primary.main,
        textAlign: 'justify',
    }),
})(Typography);

export default function SubHeader(props) {
    if (props.noMargin == undefined) {
        return (
            <MarginSubHeader variant="subtitle1" style={{ fontWeight: props.fontWeight }}>
                {props.children}
            </MarginSubHeader>
        );
    } else {
        return (
            <NoMarginSubHeader variant="subtitle1" style={{ fontWeight: props.fontWeight }}>
                {props.children}
            </NoMarginSubHeader>
        );
    }
}
