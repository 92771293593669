import React, { useState } from 'react';
import TrialHeader from './sections/TrialHeader';
import MatchingProcess from './sections/MatchingProcess';
import { Grid } from '@mui/material';
import Credentials from './sections/Credentials';
import AllowsYouTo from './sections/AllowsYouTo';
import TrialSummary from './sections/TrialSummary';
import useTrackingCookies from 'parent-portal/shared/EngagementSourceTracking';
import usePublicSessionTracking from 'parent-portal/shared/PublicSessionTracking';
import useSignUpAction from 'parent-portal/login/SignUpAction';
import PreSignUp from 'parent-portal/signup/PreSignUp/PreSignUp';

export default function TrialRunLandingPage() {
    useTrackingCookies('/trial-runs');
    const [open, setOpen] = useState(false);
    const { usePageLoadTracking } = usePublicSessionTracking('how it works');
    const { SignUpWrapper } = useSignUpAction(undefined);
    usePageLoadTracking();

    function signUp() {
        setOpen(true);
    }

    return (
        <SignUpWrapper businessSignup={true}>
            <Grid>
                <TrialHeader signUp={signUp} />
                <MatchingProcess />
                <Credentials />
                <AllowsYouTo />
                <TrialSummary />
                <PreSignUp
                    open={open}
                    smallerVersion={false}
                    onClose={() => setOpen(false)}
                    headerText={'How will you use Tandem?'}
                    showBusinessOption={true}
                    showProviderOption={true}
                />
            </Grid>
        </SignUpWrapper>
    );
}
