import React from 'react';
import { format12HourTime, formatShortWeekdayMonthDay } from 'shared/Dates';
import { ApiTimeslot } from '../substituteModels';
import { Text } from 'library';

export default function SubAvailability({
    sub_availability,
    assumed_availability,
}: {
    sub_availability?: ApiTimeslot[];
    assumed_availability?: ApiTimeslot[];
}) {
    const filteredAvailability = assumed_availability
        ?.filter((availability) => {
            return !assumed_availability?.find((sub) => {
                return (
                    new Date(sub.timeslot.lower).getTime() <= new Date(availability.timeslot.lower).getTime() &&
                    new Date(sub.timeslot.upper).getTime() >= new Date(availability.timeslot.upper).getTime() &&
                    sub.id !== availability.id
                );
            });
        })
        .sort((a, b) => new Date(a.timeslot.lower).getTime() - new Date(b.timeslot.lower).getTime());

    return (
        <div>
            {(sub_availability?.length || 0) > 0 && (
                <>
                    <Text variant="body2" bold>
                        Provider-entered shift availability
                    </Text>
                    {sub_availability?.map((availability) => (
                        <Text variant="body2" key={availability.id}>
                            {formatShortWeekdayMonthDay(availability.timeslot.lower)}{' '}
                            {format12HourTime(availability.timeslot.lower)}-
                            {format12HourTime(availability.timeslot.upper)}
                        </Text>
                    ))}
                </>
            )}

            {(filteredAvailability?.length || 0) > 0 && (
                <>
                    <Text variant="body2" bold>
                        Assumed availability (applied shift dates)
                    </Text>
                    {filteredAvailability?.map((availability) => (
                        <Text variant="body2" key={availability.id}>
                            {formatShortWeekdayMonthDay(availability.timeslot.lower)}{' '}
                            {format12HourTime(availability.timeslot.lower)}-
                            {format12HourTime(availability.timeslot.upper)}
                        </Text>
                    ))}
                </>
            )}
        </div>
    );
}
