import { Dialog, Grid } from '@material-ui/core';
import { DialogCloseButton, Text } from 'library';
import React from 'react';
import { useWidth } from 'reusableComponents/useWidth';
import ProviderCard from './ProviderCard';
import BusinessCard from './BusinessCard';
import useSignUpAction from 'parent-portal/login/SignUpAction';
import { useHistory } from 'react-router-dom';

export default function PreSignUp({
    open,
    showBusinessOption = true,
    showProviderOption = true,
    headerText,
    smallerVersion = false,
    onClose = () => null,
}: {
    open: boolean;
    smallerVersion?: boolean;
    onClose?: () => void;
    headerText: string;
    showBusinessOption?: boolean;
    showProviderOption?: boolean;
}) {
    const history = useHistory();
    const { SignUpWrapper, signUp } = useSignUpAction(undefined, () => history.push('/get-started'));

    const breakpoint = useWidth();
    const breakLayout = ['xs', 'sm'].includes(breakpoint);

    return (
        <SignUpWrapper businessSignup={true}>
            <Dialog
                maxWidth={'lg'}
                open={open}
                onClose={onClose}
                fullScreen={breakpoint === 'xs'}
                PaperProps={{ style: { borderRadius: '18px' } }}
            >
                <DialogCloseButton onClose={onClose} />
                <Grid container direction="column" style={{ padding: 30 }}>
                    <Grid item style={{ alignSelf: breakLayout || smallerVersion ? 'center' : undefined }}>
                        <Text variant={smallerVersion ? 'body1' : breakLayout ? 'h1' : 'display'}>{headerText}</Text>
                    </Grid>

                    <Grid item style={{ paddingTop: 30 }}>
                        <Grid container spacing={4}>
                            {showBusinessOption ? (
                                <BusinessCard
                                    smallerVersion={smallerVersion && !breakLayout}
                                    onPress={() => {
                                        signUp('Pre Sign Up', undefined, 'Create business account');
                                    }}
                                />
                            ) : null}
                            {showProviderOption ? (
                                <ProviderCard smallerVersion={smallerVersion && !breakLayout} onClose={onClose} />
                            ) : null}
                        </Grid>
                    </Grid>
                </Grid>
            </Dialog>
        </SignUpWrapper>
    );
}
