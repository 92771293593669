export interface IProvider {
    user: {
        id: number;
    };
}

export const JOB_TYPES = { '1': 'Child Care', '2': 'Pet Care', '8': 'House Cleaning' };

export const PAY_TYPES = { hourly: 'Hourly', fixed: 'Total' };

export const PAGE_STEPS = {
    create: 'create',
    success: 'success',
};

export interface BookProviderSubmitButtonProps {
    errorMessage?: string;
    loading: boolean;
    showSuccess: boolean;
    onBookPress: () => void;
    onBookAgainPress: () => void;
}

export interface BookProviderSuccessProps {
    providerName: string;
    onClose: () => void;
}
