import React, { useEffect, useState } from 'react';
import CancelIcon from '@material-ui/icons/Cancel';
import { Colors } from 'library';
import { client, consoleLogInDev } from 'shared';
import { Grid, makeStyles } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import { useCookies } from 'react-cookie';

const useStyles = makeStyles({
    input: {
        '&:hover': {
            outline: 'none',
        },
        '&:focus': {
            outline: 'none',
        },
        '&::placeholder': {
            color: Colors.mediumNavy,
        },
        borderBottomColor: Colors.mediumNavy,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        borderWidth: 0,
        borderBottomWidth: 1,
        width: '100%',
    },
    label: {
        fontFamily: 'Work Sans',
        color: Colors.darkNavy,
    },
    errorText: {
        fontFamily: 'Work Sans',
        color: Colors.error,
        fontSize: 12,
        margin: 0,
    },
});

export default function ReferralCodeInput({
    referralCode,
    setReferralCode,
    valid,
    setValid,
}: {
    referralCode: string;
    setReferralCode: (referralCode: string) => void;
    valid: boolean;
    setValid: (valid: boolean) => void;
}) {
    const [showErrors, setShowErrors] = useState(false);
    const [cookies, setCookie, removeCookie] = useCookies(['referral']);
    const location = useLocation();
    const classes = useStyles();

    useEffect(() => {
        let referral = new URLSearchParams(location.search).get('referral') ?? cookies.referral;
        setReferralCode(referral);
        validateReferralCode(referral);
    }, []);

    function validateReferralCode(code: string) {
        setShowErrors(true);
        referralCode ? setValid(false) : setValid(true);
        if (code) {
            client(`api/referral-code/validate/?code=${code}`)
                .then((response: { id: number }) => {
                    if (response.id !== null) {
                        setValid(true);
                        setCookie('referral', code, { path: '/' });
                    } else {
                        setValid(false);
                        removeCookie('referral');
                    }
                })
                .catch((error) => {
                    consoleLogInDev(error);
                    setValid(true);
                });
        }
    }

    function onValueChanged(event: any) {
        setReferralCode(event.target.value.toUpperCase());
    }

    function getPlaceholder() {
        return !!referralCode ? '' : 'Referral Code';
    }

    function getLabel() {
        return !referralCode ? '' : 'Referral Code';
    }

    return (
        <Grid container direction="column">
            <Grid item>
                <label className={classes.label}>{getLabel()}</label>
            </Grid>
            <Grid item>
                <input
                    className={classes.input}
                    type="text"
                    value={referralCode}
                    onChange={onValueChanged}
                    placeholder={getPlaceholder()}
                    style={{ color: Colors.darkNavy }}
                />
            </Grid>
            {showErrors && !valid ? (
                <Grid container item alignItems="center">
                    <Grid item style={{ paddingLeft: 5 }}>
                        <CancelIcon style={{ color: Colors.error, maxHeight: 12 }} />
                    </Grid>
                    <Grid item>
                        <p className={classes.errorText}>{showErrors && !valid && 'Invalid referral code'}</p>
                    </Grid>
                </Grid>
            ) : null}
        </Grid>
    );
}
