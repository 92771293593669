import React from 'react';
import { HiredProvider } from '../nestedJobList/jobListModels';
import DefaultProfileImage from 'assets/icons/Profile.svg';
import CardItem from './CardItem';

export default function HiredProviderAction({ jobId, hiredProvider }: { jobId: number; hiredProvider: HiredProvider }) {
    return (
        <CardItem
            text={`View ${hiredProvider.first_name}'s Profile`}
            link={`/provider/${hiredProvider.user_id}/?job=${jobId}`}
            icon={
                hiredProvider.profile_picture ? (
                    <img
                        src={hiredProvider.profile_picture}
                        style={{ height: 30, width: 30, borderRadius: '50%' }}
                        alt={`${hiredProvider.first_name}'s profile`}
                    />
                ) : (
                    <DefaultProfileImage style={{ height: 30, width: 30, borderRadius: '50%' }} />
                )
            }
            ext="Provider Profile"
        />
    );
}
