import { useClient } from '../useClient';
import type { ExtendedOptions } from '../useClient';

export function useCheckEnabled({ userIds, options }: { userIds: number[]; options?: ExtendedOptions }) {
    return useClient<{ [key: string]: boolean }[]>({
        url: 'support/admin/chatwoot-contact/check-enabled/',
        method: 'POST',
        body: { ids: userIds },
        options,
    });
}
