import React, { useState } from 'react';
import { Snackbar } from '@mui/material';
import { PrimaryButton } from 'library';
import { stopImpersonating } from 'shared/impersonation';
import useWindowFocus from 'shared/WindowFocus';

export default function ImpersonationCheck() {
    const [snackOpen, setSnackOpen] = useState(false);
    function onFocus() {
        if (localStorage.getItem('old')) {
            setSnackOpen(true);
        }
    }

    useWindowFocus(onFocus, []);

    function stop() {
        setSnackOpen(false);
        stopImpersonating();
    }

    return (
        <Snackbar
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            open={snackOpen}
            autoHideDuration={6000}
            onClose={() => setSnackOpen(false)}
            message="It looks like you're impersonating in another tab. Would you like to stop before you continue working?"
            action={<PrimaryButton onClick={stop}>Stop Impersonating</PrimaryButton>}
        />
    );
}
