import React, { useState } from 'react';
import { Badge, Grid, styled, ButtonGroup } from '@mui/material';
import { useUserContext } from 'UserContext';
import { Colors } from 'library';
import TasksDialog from './TasksDialog';
import { faCalendarPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useWebSocket from 'react-use-websocket';
import { ApiBaseWSUrl } from 'environmentVariables';
import { socketConfig } from 'internal/shared/sockets';

const config = socketConfig({});

export default function TaskNavItem() {
    const { user } = useUserContext();
    const [tasksOpen, setTasksOpen] = useState(false);
    const [adding, setAdding] = useState(false);
    const ref = React.useRef(null);

    const onAddClick = () => {
        setAdding(true);
        setTasksOpen(!tasksOpen);
    };

    const onClose = () => {
        setAdding(false);
        setTasksOpen(false);
    };

    const { lastMessage } = useWebSocket(`${ApiBaseWSUrl}/ws/thread/inbox_count_${user?.inbox?.id}/`, config);
    const data = JSON.parse(lastMessage?.data || '{}');

    return (
        <Container ref={ref}>
            <Badge badgeContent={data?.message?.count ?? user?.inbox?.open_count} color="primary">
                <TasksButtonGroup variant="text">
                    <TaskButton onClick={() => setTasksOpen(!tasksOpen)}>Tasks</TaskButton>
                    <AddButton onClick={onAddClick}>
                        <FontAwesomeIcon icon={faCalendarPlus} />
                    </AddButton>
                </TasksButtonGroup>
            </Badge>
            <TasksDialog parentRef={ref.current} isOpen={tasksOpen} addTask={adding} onClose={onClose} />
        </Container>
    );
}

const TasksButtonGroup = styled(ButtonGroup)({
    height: 30,
    backgroundColor: Colors.mediumRed,
    borderRadius: 30,
});

const AddButton = styled(Grid)({
    color: Colors.white,
    borderColor: Colors.darkNavy,
    paddingLeft: 10,
    paddingRight: 10,
    cursor: 'pointer',
    alignContent: 'center',
});

const TaskButton = styled(AddButton)({
    borderRightColor: Colors.darkNavy,
    borderRightStyle: 'solid',
    borderRightWidth: 2,
});

const Container = styled(Grid)({
    marginTop: 5,
    marginRight: 20,
});
