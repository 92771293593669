import { FormFieldProps } from './components/FormTypes';
import { BusinessPreference } from 'parent-portal/profile/BusinessProfile/BusinessPreferences/models';

export function setBreakMinutes(
    businessPreferences: BusinessPreference[],
    breakRequired: FormFieldProps<boolean>,
    breakLength: FormFieldProps<number | undefined>,
    addressId?: number,
) {
    const bizPref = addressId
        ? businessPreferences.find((x) => x.business_location?.address === addressId)
        : businessPreferences.find((x: BusinessPreference) => x.business);
    if (!breakRequired.value && bizPref) {
        if (!bizPref.break_preferences.length) {
            return;
        }
        const minutes = bizPref.break_preferences[0].minutes_required;
        if (minutes) {
            breakRequired.setValue(true);
            breakLength.setValue(minutes);
        }
    }
}
