import { Moment } from 'moment';

export function plusOneDay(date: Moment) {
    const dateCopy = date.clone();
    return dateCopy.add(1, 'day');
}

export function timeWithDate(time: Moment, date: Moment) {
    const dateCopy = time.clone();
    dateCopy.set('year', date.year());
    dateCopy.set('month', date.month());
    dateCopy.set('date', date.date());
    return dateCopy;
}
