import { withStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';
import { TextFieldProps } from '@material-ui/core/TextField/TextField';
import theme from '../theme';
import { CSSProperties } from 'react';
import { Colors } from 'library';

type InputProps = TextFieldProps & {
    noMargin?: boolean;
    paddingLeft?: CSSProperties['width'];
    backgroundColor?: CSSProperties['color'];
    underlineColor?: CSSProperties['color'];
};

const StyledInput = withStyles({
    root: ({ noMargin, paddingLeft, backgroundColor = '#ffffff', underlineColor }: InputProps) => ({
        marginBottom: noMargin ? 0 : 20,
        borderColor: underlineColor ?? theme.palette.primary.main,
        '& label.Mui-focused': {
            color: theme.palette.primary.main,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: underlineColor ?? theme.palette.primary.main,
        },
        '& .MuiInput-underline::before': {
            borderColor: underlineColor ?? theme.palette.primary.main,
        },
        '& .MuiOutlinedInput-root': {
            borderRadius: 18,
            '& fieldset': {
                borderColor: underlineColor ?? theme.palette.primary.main,
                borderRadius: 18,
            },
            '&:hover fieldset': {
                borderColor: underlineColor ?? theme.palette.primary.main,
            },
            '&.Mui-focused fieldset': {
                borderColor: underlineColor ?? theme.palette.primary.main,
                color: theme.palette.primary.main,
            },
        },
        '& .MuiInputBase-input': {
            backgroundColor,
            paddingLeft,
            color: Colors.darkNavy,
            borderRadius: 18,
        },
        '& .MuiOutlinedInput-input': {
            backgroundColor,
            borderRadius: 18,
        },
        '& .MuiTextField-root': {
            '& fieldset': {
                backgroundColor,
            },
            '&:hover fieldset': {
                borderColor: underlineColor ?? theme.palette.primary.main,
            },
            '&.Mui-focused fieldset': {
                borderColor: underlineColor ?? theme.palette.primary.main,
            },
        },
        '&:hover:not($disabled):after': {
            borderColor: underlineColor ?? theme.palette.primary.main,
        },
        '&:hover:not($disabled):before': {
            borderColor: underlineColor ?? theme.palette.primary.main,
        },
    }),
})(TextField);

export default StyledInput;
