import { IBusinessLocation } from 'models';
import { client } from 'shared';

export async function getEducationOptions(): Promise<string[]> {
    const result = await client('api/location-education-options/');
    return result.map((it: { name: string }) => it.name);
}

export function addPhotos(businessLocationId: number, photos: File[]) {
    return Promise.all(
        photos.map((photo) => {
            const formData = new FormData();
            formData.append('title', photo.name);
            formData.append('location', businessLocationId.toString());
            formData.append('image', photo, photo.name);

            return client('api/location-photo/', { method: 'POST', body: formData }, false);
        }),
    );
}

export function deletePhoto(photoId: number) {
    return client(`api/location-photo/${photoId}/`, { method: 'DELETE' });
}

export function addAdministration(businessLocationId: number, name: string, role: string) {
    return client('api/location-administration/', {
        method: 'POST',
        body: { location: businessLocationId, role, name },
    });
}

export function editAdministration(id: number, name: string, role: string) {
    return client(`api/location-administration/${id}/`, { method: 'PATCH', body: { role, name } });
}

export function removeAdministration(id: number) {
    return client(`api/location-administration/${id}/`, { method: 'DELETE' });
}

export function addAdministrationPhoto(id: number, image: File) {
    const formData = new FormData();
    formData.append('title', image.name);
    formData.append('image', image, image.name);

    return client(`api/location-administration/${id}/add-photo/`, { method: 'PATCH', body: formData }, false);
}

export interface SaveLocationRequest {
    education_types?: string[];
    name?: string;
    program_id?: string;
    capacity?: number | null;
    website?: string;
    addressData?: {
        street?: string;
        city?: string;
        state?: string;
        zip_code?: string;
    };
}

export async function saveBusinessLocation(id: number | string, req: SaveLocationRequest): Promise<IBusinessLocation> {
    const { addressData, ...businessLocationData } = req;
    return client(`api/business-location/${id}/`, {
        method: 'PATCH',
        body: { ...businessLocationData, ...addressData },
    });
}
