import React, { Component } from 'react';
import { Avatar } from '@material-ui/core';
import theme from '../../../theme';
import { client } from 'shared';
import { Environment } from 'environmentVariables';

const fallbackProfileImage = require('../../../assets/images/default-sitter.png');

class ProfileImage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            image: null,
        };
    }

    componentDidMount = async () => {
        try {
            const response = await client('get-picture/' + this.props.id);
            this.setState({ image: response });
        } catch (error) {
            if (Environment !== 'production') {
                console.log(error);
            }
        }
    };

    render() {
        const { image } = this.state;
        return (
            <Avatar
                src={image ? image : fallbackProfileImage}
                alt="robot"
                style={{
                    margin: 0,
                    padding: 0,
                    height: this.props.height,
                    width: this.props.width,
                    boxShadow: theme.shadows[1],
                }}
                classes={this.props.classes}
                variant={this.props.variant ? this.props.variant : 'circle'}
            >
                <img src={fallbackProfileImage} className="MuiAvatar-img" />
            </Avatar>
        );
    }
}

export default ProfileImage;
