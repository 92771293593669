import React from 'react';
import { FormControl, Select, InputLabel, MenuItem } from '@material-ui/core';
import theme from '../../../../theme';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: '10%',
        },
    },
};

const Dropdown = (props) => {
    const { value, options, title, transparent } = props;
    return (
        <FormControl style={{ background: transparent ? 'transparent' : 'white', width: '100%', height: 40 }}>
            {value === '' && (
                <InputLabel
                    shrink={false}
                    style={{
                        marginTop: -15,
                        marginLeft: 5,
                        fontSize: 16,
                        color: theme.palette.primary.main,
                        fontWeight: 'bold',
                    }}
                >
                    {title}
                </InputLabel>
            )}
            <Select
                value={value}
                onChange={props.onChange}
                inputProps={{
                    name: value,
                    id: value,
                }}
                MenuProps={MenuProps}
                disabled={props.disabled}
                disableUnderline
                style={{
                    marginTop: 0,
                    marginBottom: 0,
                    marginLeft: 5,
                    marginRight: 5,
                    fontSize: 16,
                    color: theme.palette.primary.main,
                }}
            >
                {options.map((option) => {
                    return (
                        <MenuItem key={option.key} value={option.key}>
                            {option.value}
                        </MenuItem>
                    );
                })}
            </Select>
        </FormControl>
    );
};

export default Dropdown;
