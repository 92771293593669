import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { PayBackgroundPaper } from '../PayPapers';
import PaySectionHeader from '../PaySectionHeader';
import { client, consoleLogInDev } from 'shared';
import download from 'shared/Download';
import { OutlinedDropdown, PrimaryButton, Text, Colors } from 'library';

const InvoiceDownload = () => {
    type stringKeyValuePair = { key: string; value: string };
    type numberKeyValuePair = { key: number; value: string };
    const invoiceTypeOptions = [
        { key: 'unpaid', value: 'Unpaid' },
        { key: 'weekly', value: 'Weekly' },
        { key: 'monthly', value: 'Monthly' },
        { key: 'yearly', value: 'Yearly' },
    ];
    const [statementOptions, setStatementOptions] = useState<stringKeyValuePair[]>([]);
    const [providerOrCenterLocationOptions, setProviderOrCenterLocationOptions] = useState<numberKeyValuePair[]>([]);
    const [providerOptions, setProviderOptions] = useState<numberKeyValuePair[]>([]);

    const [selectedStatement, setSelectedStatement] = useState('');
    const [selectedInvoiceType, setSelectedInvoiceType] = useState('unpaid');
    const [selectedProviderOrCenterLocation, setSelectedProviderOrCenterLocation] = useState(-1);
    const [selectedProvider, setSelectedProvider] = useState(-1);

    const [downloading, setDownloading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        getProviderOrCenerLocationOptions();
    }, []);
    useEffect(() => {
        getProvidersForCenterLocation();
    }, [selectedProviderOrCenterLocation]);

    useEffect(() => {
        getStatementList();
    }, [selectedInvoiceType, selectedProviderOrCenterLocation, selectedProvider]);

    const getProvidersForCenterLocation = () => {
        if (selectedProviderOrCenterLocation === -1) {
            setProviderOptions([{ key: -1, value: 'All' }]);
            setSelectedProvider(-1);
            return;
        }

        client('payment/api/invoice/providers-for-location/?location_id=' + selectedProviderOrCenterLocation)
            .then((response: numberKeyValuePair[]) => {
                response = [{ key: -1, value: 'All Providers' }, ...response];
                setProviderOptions(response);
                setSelectedProvider(-1);
            })
            .catch((error) => {
                consoleLogInDev(error);
            });
    };

    const getProviderOrCenerLocationOptions = () => {
        client('payment/api/invoice/provider-or-center-locations-list/')
            .then((response: numberKeyValuePair[]) => {
                response = [{ key: -1, value: 'All Locations' }, ...response];
                setProviderOrCenterLocationOptions(response);
                setSelectedProviderOrCenterLocation(response[0]['key']);
            })
            .catch((error) => {
                consoleLogInDev(error);
            });
    };

    const getStatementList = () => {
        client(`payment/api/invoice/statement-list/?style=${selectedInvoiceType}`)
            .then((response: string[]) => {
                setStatementOptions([
                    ...response.map((x) => {
                        return {
                            key: x,
                            value: x,
                        };
                    }),
                ]);
                setSelectedStatement(response[0]);
            })
            .catch((error) => {
                consoleLogInDev(error);
            });
    };

    const onDownloadClick = () => {
        setDownloading(true);
        setErrorMessage('');
        download(
            `payment/api/invoice/statement/?week=${encodeURIComponent(
                selectedStatement,
            )}&style=${selectedInvoiceType}&paid_user=${selectedProvider}&bus_location=${selectedProviderOrCenterLocation}`,
        )
            .catch((error) => {
                if (error.message == 'Not found') {
                    setErrorMessage('No invoices found');
                } else {
                    setErrorMessage('There was an error generating your invoice');
                }
            })
            .finally(() => {
                setDownloading(false);
            });
    };

    return (
        <Grid item xs={12}>
            <PayBackgroundPaper>
                <PaySectionHeader>Download Invoice</PaySectionHeader>
                <Grid container spacing={2}>
                    <Grid item xs={6} style={{ marginTop: 10 }}>
                        <OutlinedDropdown
                            fullWidth
                            value={selectedInvoiceType}
                            title={'Select Invoice Type'}
                            fields={invoiceTypeOptions}
                            onChange={(e) => setSelectedInvoiceType(e.target.value)}
                            displayEmpty={true}
                        />
                    </Grid>
                    <Grid item xs={6} style={{ marginTop: 10 }}>
                        <OutlinedDropdown
                            fullWidth
                            value={selectedProviderOrCenterLocation}
                            title={'Select Provider or Center Location'}
                            fields={providerOrCenterLocationOptions}
                            onChange={(e) => setSelectedProviderOrCenterLocation(e.target.value)}
                            displayEmpty={true}
                        />
                    </Grid>
                    <Grid item xs={4} style={{ marginTop: 10 }}>
                        <OutlinedDropdown
                            fullWidth
                            value={selectedProvider}
                            title={'Select Provider'}
                            fields={providerOptions}
                            onChange={(e) => setSelectedProvider(e.target.value)}
                            displayEmpty={true}
                        />
                    </Grid>
                    <Grid item xs={8} style={{ marginTop: 10 }}>
                        <OutlinedDropdown
                            fullWidth
                            value={selectedStatement}
                            title={'Select Statement'}
                            fields={statementOptions}
                            onChange={(e) => setSelectedStatement(e.target.value)}
                            displayEmpty={true}
                        />
                    </Grid>
                    <Grid container item xs={12} style={{ marginTop: 20, marginBottom: 10 }} justify="flex-end">
                        <Grid item>
                            <PrimaryButton
                                disabled={!selectedStatement}
                                loading={downloading}
                                onClick={() => onDownloadClick()}
                                buttonStyle={{ minWidth: 150 }}
                            >
                                Download
                            </PrimaryButton>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Text variant="body2" textStyle={{ color: Colors.error }}>
                            {errorMessage}
                        </Text>
                    </Grid>
                </Grid>
            </PayBackgroundPaper>
        </Grid>
    );
};

export default InvoiceDownload;
