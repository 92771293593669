import React from 'react';
import { Link } from 'react-router-dom';
import NavyLogo from 'assets/images/NavyLogo.svg';

export default function WhiteHeader() {
    const isElderCare = window.location.href.includes('facilities');

    return (
        <Link style={styles.link} to={isElderCare ? '/facilities' : '/'}>
            <NavyLogo height={50} width={150} alt="tandem logo" />
        </Link>
    );
}

const styles = {
    link: {
        paddingLeft: 10,
    },
};
