import React from 'react';
import { Text, Colors, Highlight } from 'library';
import { Grid, useMediaQuery } from '@mui/material';
import HeartIcon from 'assets/images/marketing-site/heart-icon.svg';
import LightIcon from 'assets/images/marketing-site/light-icon.svg';
import CheckIcon from 'assets/images/marketing-site/check-icon.svg';
import theme from 'theme';

export function ExclusivePlatform() {
    const smDown = useMediaQuery(theme.breakpoints.down('sm'));
    const valueProps = [
        {
            icon: <LightIcon width={50} height={50} />,
            title: 'Built by compliance experts.',
            subtitle: 'State licensing requirements are met by 100% of our workers.',
        },
        {
            icon: <CheckIcon width={50} height={50} />,
            title: 'Verified credentials beyond the basics.',
            subtitle: 'Child care specific profiles you can trust are accurate.',
        },
        {
            icon: <HeartIcon width={50} height={50} />,
            title: 'Deep understanding of the industry.',
            subtitle: 'We know staffing is just a piece of the puzzle. Consider us an operational partner.',
        },
    ];
    return (
        <Grid
            container
            style={{ backgroundColor: Colors.white, gap: 80, padding: smDown ? 40 : 80 }}
            alignItems="center"
            flexDirection="row"
            xs={12}
        >
            <Text variant="display" bold textStyle={{ textAlign: 'center' }}>
                A platform built <Highlight>exclusively</Highlight> for child care programs.
            </Text>
            <Grid
                container
                item
                direction={smDown ? 'column' : 'row'}
                alignSelf="center"
                xs={12}
                style={{ gap: 80 }}
                justifyContent="center"
            >
                {valueProps.map((value) => (
                    <Grid
                        key={value.title}
                        container
                        item
                        xs={12}
                        md={3}
                        justifyContent="center"
                        flexDirection="column"
                        alignItems="center"
                        style={{ borderRadius: 18, boxShadow: '0px 3px 6px #00000029', padding: 40, gap: 20 }}
                    >
                        {value.icon}
                        <Text
                            variant="h2"
                            bold
                            textStyle={{
                                textAlign: 'center',
                            }}
                        >
                            {value.title}
                        </Text>
                        <Text
                            variant="body1"
                            textStyle={{
                                textAlign: 'center',
                            }}
                        >
                            {value.subtitle}
                        </Text>
                    </Grid>
                ))}
            </Grid>
        </Grid>
    );
}
