import { Provider } from 'models';
import User from 'models/User';
import { useCookies } from 'react-cookie';
import { useHistory } from 'react-router-dom';
import usePublicSessionTracking, { PublicSessionEventType } from 'parent-portal/shared/PublicSessionTracking';

export default function useGoToProfile() {
    const history = useHistory();
    const [{ source }] = useCookies(['source']);
    const { trackSession } = usePublicSessionTracking('useGoToProfile');

    type UserType = Pick<User, 'id'>;
    type ProviderType = Pick<Provider, 'applied'> & { user: UserType };

    function getProfilePath(provider: ProviderType, jobId?: number) {
        if (jobId && provider.applied) {
            return `/jobs/${jobId}/applicants/${provider.user.id}`;
        }
        return `/provider/${provider.user.id}`;
    }

    function onGoToProfileLinkClicked(provider: Provider, location: string, extraActions?: () => void) {
        trackSession('NAVIGATE_FROM', 'Provider Display: View Profile Link Click', PublicSessionEventType.User, {
            location,
            provider_id: provider?.id,
            source,
        });
        extraActions?.();
    }

    const goToProfile = (provider: Provider, location: string, jobType?: string) => {
        const profilePath = jobType ? `${getProfilePath(provider)}?type=${jobType}` : getProfilePath(provider);
        trackSession('NAVIGATE_FROM', 'Provider Display: View Profile Button Click', PublicSessionEventType.User, {
            location,
            provider_id: provider?.id,
            source,
        });

        history.push(profilePath);
    };

    return { goToProfile, getProfilePath, onGoToProfileLinkClicked };
}
