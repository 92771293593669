import React, { useEffect, useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Grid } from '@material-ui/core';
import { useUserContext } from 'UserContext';
import { Text, Colors } from 'library';
import Basics from './Basics';
import ProfileCard from './ProfileCard';
import { ExpandMore } from '@material-ui/icons';
import PictureDialog from '../PictureDialog';
import { client, consoleLogInDev } from 'shared';
import { ICenterNotificationPreference, IBusinessLocation } from 'models';
import CenterLocations from './CenterLocations';
import BusinessPreferences from './BusinessPreferences';
import { BreakPreference, BusinessPreference, EditBusinessPreference } from './BusinessPreferences/models';
import CenterNotifications from './CenterNotifications';

export default function BusinessProfile() {
    const { user, refreshAlreadyLoggedInUser, getDisplayText } = useUserContext();
    const [business, setBusiness] = useState(user?.businesses.length ? user.businesses[0] : null);
    const [expanded, setExpanded] = useState('');
    const [pictureDialogVisible, setPictureDialogVisible] = useState(false);
    const [editContactInfo, setEditContactInfo] = useState(false);
    const [businessLocations, setBusinessLocations] = useState<IBusinessLocation[]>([]);
    const [businessPreferences, setBusinessPreferences] = useState<EditBusinessPreference[]>([]);
    const [breakPreferenceOptions, setBreakPreferenceOptions] = useState<BreakPreference[]>([]);
    const [emailPreferences, setEmailPreferences] = useState<ICenterNotificationPreference[]>([]);
    const [faqQuestions, setFaqQuestions] = useState([]);

    useEffect(() => {
        const updatedBusiness = user?.businesses.length ? user.businesses[0] : null;
        setBusiness(updatedBusiness);
        loadProfileData();
    }, [user]);

    function loadProfileData() {
        client('api/business-profile/details/')
            .then((res) => {
                setBusinessLocations(res.locations);
                setFaqQuestions(res.business_faq_questions);
                setBusinessPreferences(
                    res.business_preferences.map((x: BusinessPreference) => {
                        return {
                            x,
                            business_location: x.business_location?.id,
                        };
                    }),
                );
                setBreakPreferenceOptions(res.break_preferences);
                setEmailPreferences(
                    (res.location_contacts_preferences as ICenterNotificationPreference[]).sort((a) =>
                        a.business_location ? 1 : -1,
                    ),
                );
            })
            .catch(consoleLogInDev);
    }

    function refreshBusinessLocations() {
        client('api/business-locations/').then(setBusinessLocations).catch(consoleLogInDev);
    }

    const businessPreference = businessPreferences.find((p) => p.business);
    if (!user || !business) return <></>;

    const accordions = [
        {
            title: 'The Basics',
            summary: 'Keep your contact information up to date so you never miss an update or notification.',
            body: (
                <Basics
                    user={user}
                    business={business}
                    editContactInfo={editContactInfo}
                    setEditContactInfo={setEditContactInfo}
                />
            ),
        },
        {
            title: `${getDisplayText('center').capitalize()} Locations`,
            summary: `Manage all of your ${getDisplayText('center')} locations, contacts, and requirements.`,
            body: (
                <CenterLocations
                    businessLocations={businessLocations}
                    refreshBusinessLocations={refreshBusinessLocations}
                    faqQuestions={faqQuestions}
                />
            ),
        },
        {
            title: `Notification Preferences`,
            summary: `Manage all of your contact's notification preferences.`,
            body: <CenterNotifications emailPreferences={emailPreferences} setEmailPreferences={setEmailPreferences} />,
        },
    ];

    if (businessPreferences.length && businessPreference) {
        accordions.push({
            title: 'Shift Preferences',
            summary:
                'Manage your shift preferences like how early a worker can clock in/out, how long a break should be by shift length, etc.',
            body: (
                <BusinessPreferences
                    businessPreference={businessPreference}
                    breakPreferenceOptions={breakPreferenceOptions}
                />
            ),
        });
    }

    return (
        <Grid container direction="column" justify="center" style={{ marginTop: 40, padding: '0px 10%' }}>
            <Text variant="display">Account Information</Text>
            <Grid container item style={{ paddingTop: 40 }} justify="space-between">
                <ProfileCard
                    user={user}
                    setExpanded={setExpanded}
                    editContactInfo={editContactInfo}
                    updateEditContact={setEditContactInfo}
                    setPictureDialogVisible={setPictureDialogVisible}
                />

                <Grid xs={12} md={9}>
                    {accordions.map((a) => (
                        <Accordion
                            key={a.title}
                            style={{
                                backgroundColor: expanded === a.title ? Colors.lightTurq : Colors.white,
                                borderRadius: 18,
                                marginTop: 20,
                                position: 'unset',
                            }}
                            expanded={expanded === a.title}
                            onChange={() => setExpanded(expanded === a.title ? '' : a.title)}
                        >
                            <AccordionSummary expandIcon={<ExpandMore />}>
                                <Grid container item direction="column">
                                    <Text bold>{a.title}</Text> <Text variant="caption">{a.summary}</Text>
                                </Grid>
                            </AccordionSummary>
                            <AccordionDetails>{a.body}</AccordionDetails>
                        </Accordion>
                    ))}
                </Grid>
            </Grid>
            <PictureDialog
                onPhotoChange={() => refreshAlreadyLoggedInUser()}
                userId={user.id}
                open={pictureDialogVisible}
                onClose={() => setPictureDialogVisible(false)}
            />
        </Grid>
    );
}
