import React, { useState } from 'react';
import {
    LoadingSpinner,
    Text,
    DropDown,
    TextArea,
    PrimaryButton,
    BasicDialog,
    TextButton,
    CopyIconButton,
    Colors,
} from 'library';
import {
    Grid,
    styled,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TableRow,
} from '@mui/material';
import { client, consoleLogInDev } from 'shared';
import { ActualLeanUser, PagingResult } from 'models';
import { formatMonthDayYearWith12HourTime } from 'shared/Dates';
import { TablePagination } from '@material-ui/core';
import MessageBody from './MessageBody';

import type { IBusinessLocationWithNotes } from 'models';

interface User extends ActualLeanUser {
    email: string;
}

export interface EmailHistory {
    id: number;
    to_user?: User;
    to_email: string;
    from_user?: User;
    from_email: string;
    template_id: string;
    template_data: string;
    status: string;
    sent_at: string;
    description?: string;
    body?: string;
    message_id?: string;
}

export default function LocationEmailHistory({
    businessLocation,
    error,
    status,
}: {
    businessLocation: IBusinessLocationWithNotes | undefined;
    error: string | undefined;
    status: string;
}) {
    const [selectedEmail, setSelectedEmail] = useState('');
    const [templateData, setTemplateData] = useState('');
    const [page, setPage] = useState(1);
    const [emailHistory, setEmailHistory] = useState<PagingResult<EmailHistory>>();

    function onSelectEmail(email: string) {
        setSelectedEmail(email);
        setPage(1);
        getData(email, 1);
    }

    function onPageChange(event: React.MouseEvent<HTMLButtonElement> | null, pageNumber: number) {
        const newPage = pageNumber + 1;
        setPage(newPage);
        getData(selectedEmail, newPage);
    }

    function getData(email: string, pageNumber: number) {
        client(`internal/email-history/?to_email=${encodeURIComponent(email)}&page=${pageNumber}`)
            .then((response) => {
                setEmailHistory(response);
            })
            .catch(consoleLogInDev);
    }

    if (status === 'loading') return <LoadingSpinner />;
    if (status === 'error') return <Text>{error}</Text>;
    if (!businessLocation) return <Text>Location not found.</Text>;

    const emails = [
        {
            key: businessLocation.business.user.email,
            value: `${businessLocation.business.user.first_name} ${businessLocation.business.user.last_name}`,
        },
        ...businessLocation.business_contacts.map((x) => {
            return {
                key: x.user.email,
                value: `${x.user.first_name} ${x.user.last_name}`,
            };
        }),
    ];

    const defaultEmail = emails[0].key;
    if (!selectedEmail) onSelectEmail(defaultEmail);

    return (
        <Container container item gap={1}>
            <Grid item container gap={1} direction="column">
                <Text bold>Business Contacts:</Text>
                <Grid item container direction="row" gap={1}>
                    <DropDown
                        selected={selectedEmail}
                        placeholder="Chose Email"
                        title="Email"
                        fields={emails}
                        onChange={(e) => onSelectEmail(e.target.value)}
                        style={{ maxWidth: 450 }}
                    />
                </Grid>
            </Grid>
            <Grid item container gap={1} direction="column">
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Sent At</TableCell>
                                <TableCell>From</TableCell>
                                <TableCell>To</TableCell>
                                <TableCell>Description</TableCell>
                                <TableCell>
                                    Template{' '}
                                    <a
                                        href="https://mc.sendgrid.com/dynamic-templates/"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        SendGrid
                                    </a>
                                </TableCell>
                                <TableCell>Template Data</TableCell>
                                <TableCell sx={{ width: '35em' }}>Body</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {emailHistory?.results.map((x) => (
                                <ColoredRow
                                    key={x.id}
                                    backgroundColor={x.template_id ? Colors.lightTurq : Colors.lightTerraCotta}
                                >
                                    <TableCell>{formatMonthDayYearWith12HourTime(x.sent_at)}</TableCell>
                                    <TableCell>{x.from_user?.email || x.from_email}</TableCell>
                                    <TableCell>{x.to_user?.email || x.to_email}</TableCell>
                                    <TableCell>{x.description}</TableCell>
                                    <TableCell>
                                        {x.template_id ? (
                                            <>
                                                {x.template_id.slice(0, 10)}...
                                                <CopyIconButton tooltip="Copy Template ID" textToCopy={x.template_id} />
                                            </>
                                        ) : (
                                            'Gmail Email'
                                        )}
                                    </TableCell>
                                    <TableCell>
                                        {x.template_data ? (
                                            <TextButton
                                                buttonStyle={{ width: 150 }}
                                                onClick={() => setTemplateData(x.template_data)}
                                            >
                                                View
                                            </TextButton>
                                        ) : null}
                                    </TableCell>
                                    <TableCell>
                                        <MessageBody message={x.body || ''} messageId={x.message_id || ''} />
                                    </TableCell>
                                </ColoredRow>
                            ))}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TableCell align="right" colSpan={8}>
                                    <Grid>
                                        <TablePagination
                                            component="div"
                                            count={emailHistory?.count || 0}
                                            rowsPerPage={emailHistory?.per_page || 0}
                                            page={page - 1}
                                            onChangePage={onPageChange}
                                            rowsPerPageOptions={[]}
                                        />
                                    </Grid>
                                </TableCell>
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
            </Grid>
            <BasicDialog isOpen={Boolean(templateData)} onClose={() => setTemplateData('')}>
                <div>
                    <div>
                        <TextArea rows={10} style={{ width: '100%' }} label="Data" value={templateData} />
                    </div>
                    <PrimaryButton onClick={() => setTemplateData('')} buttonStyle={{ width: 300 }}>
                        Close
                    </PrimaryButton>
                </div>
            </BasicDialog>
        </Container>
    );
}

const Container = styled(Grid)({
    padding: 20,
});

const ColoredRow = styled(TableRow)(({ backgroundColor }: { backgroundColor?: string }) => ({
    backgroundColor: backgroundColor || 'inherit',
}));
