import { client, formatE164USPhoneNumber } from 'shared';

export const checkPhoneNumber = async (phoneNumber: string) => {
    if (!!phoneNumber) {
        let resp = await client(`api/phone-number-exists/?phone-number=${formatE164USPhoneNumber(phoneNumber)}`);
        return { duplicate: resp.number_exists, isMobile: resp.is_mobile };
    } else {
        return { duplicate: false, isMobile: false };
    }
};
