import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { FormControl, InputLabel, Select, MenuItem, FormHelperText } from '@material-ui/core';
import theme from '../theme';

const useStyles = makeStyles((theme) => ({
    root: (props) => ({
        marginBottom: 15,
        width: '100%',
        '& label.Mui-focused': {
            color: !props.error ? theme.palette.primary.main : theme.palette.error.main,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: !props.error ? theme.palette.primary.main : theme.palette.error.main,
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: !props.error ? theme.palette.primary.main : theme.palette.error.main,
            },
            '&:hover fieldset': {
                borderColor: !props.error ? theme.palette.primary.main : theme.palette.error.main,
            },
            '&.Mui-focused fieldset': {
                borderColor: !props.error ? theme.palette.primary.main : theme.palette.error.main,
            },
        },
    }),
}));

const ErrorText = withStyles({
    root: {
        color: theme.palette.error.main,
    },
})(FormHelperText);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export default function DropDown(props) {
    const { selected } = props;
    const classes = useStyles(props);

    let mapping = {};
    if (!props.native) {
        if (props.keyPairs) {
            mapping = props.fields;
        } else {
            props.fields.map((field) => {
                mapping[field] = field;
            });
        }
    }

    return props.native === undefined ? (
        <FormControl variant="outlined" className={classes.root}>
            <InputLabel margin="dense" error={props.error} htmlFor={props.id} style={{ marginRight: 12 }}>
                {props.title}
            </InputLabel>
            <Select
                id={props.id}
                fullWidth
                value={selected}
                onChange={(e) => props.onChange(e)}
                label={props.title}
                inputProps={{
                    name: selected,
                }}
                MenuProps={MenuProps}
            >
                {Object.keys(mapping).map((field) => {
                    return (
                        <MenuItem key={field} value={field}>
                            {mapping[field]}
                        </MenuItem>
                    );
                })}
            </Select>
            <ErrorText id="component-helper-text">{props.errorMessage}</ErrorText>
            <FormHelperText>{props.helperText}</FormHelperText>
        </FormControl>
    ) : (
        <FormControl variant="outlined" className={classes.root}>
            <InputLabel error={props.error} htmlFor={props.id}>
                {props.title}
            </InputLabel>
            <Select
                id={props.id}
                fullWidth
                native
                value={selected}
                onChange={(e) => props.onChange(e)}
                label={props.title}
                inputProps={{
                    name: selected,
                }}
            >
                {props.fields.map((field) => {
                    return <option value={field}>{field}</option>;
                })}
            </Select>
            <ErrorText id="component-helper-text">{props.errorMessage}</ErrorText>
            <FormHelperText>{props.helperText}</FormHelperText>
        </FormControl>
    );
}
