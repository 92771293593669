import React, { ReactNode } from 'react';
import { Colors, Text } from 'library';
import { Provider } from 'models';
import moment from 'moment';
import { Grid, useMediaQuery } from '@material-ui/core';
import theme from 'theme';

function commaSeparatedList(values: Array<string | undefined>) {
    if (values.length === 1) {
        return values[0];
    } else if (values.length === 2) {
        return values.join(' and ');
    } else {
        return `${values.slice(0, -1).join(', ')}, and ${values.slice(-1)}`;
    }
}

function sortByWeekday(days: string[]) {
    const weekdays = moment.weekdays();
    return days.sort((day, otherDay) => weekdays.indexOf(day) - weekdays.indexOf(otherDay));
}

interface JobDaysProps {
    daysAppliedTo: string[];
    unfilledDays: string[];
    totalDays: number;
    provider: Provider;
    isOnetimeJob: boolean;
}

export default function JobDays({ provider, daysAppliedTo, unfilledDays, totalDays, isOnetimeJob }: JobDaysProps) {
    return isOnetimeJob ? (
        <OnetimeText provider={provider} daysAppliedTo={daysAppliedTo} />
    ) : (
        <WeeklyText
            provider={provider}
            daysAppliedTo={daysAppliedTo}
            unfilledDays={unfilledDays}
            totalDays={totalDays}
        />
    );
}

function OnetimeText({ provider, daysAppliedTo }: { provider: Provider; daysAppliedTo: string[] }) {
    return (
        <Text variant="body1" textStyle={{ fontSize: 18 }}>
            You are hiring <Bold>{provider.user.first_name}</Bold> for <Bold>{daysAppliedTo[0]}.</Bold>
        </Text>
    );
}

function WeeklyText({
    provider,
    daysAppliedTo,
    unfilledDays,
    totalDays,
}: {
    daysAppliedTo: string[];
    unfilledDays: string[];
    totalDays: number;
    provider: Provider;
}) {
    const appliedDaysList = commaSeparatedList(sortByWeekday(daysAppliedTo));
    const unfilledDaysList = commaSeparatedList(sortByWeekday(unfilledDays));
    const small = useMediaQuery(theme.breakpoints.down('sm'));
    const variant = small ? 'body1' : 'h2';

    return (
        <Grid container direction="column" style={{ padding: 10 }}>
            <Text variant={variant} textStyle={{ marginBottom: 10 }}>
                You are hiring <Bold>{provider.user.first_name}</Bold> for <Bold>{appliedDaysList}</Bold>.
            </Text>
            <Text variant={variant} textStyle={{ marginBottom: 10 }}>
                This fills{' '}
                <Bold>
                    {daysAppliedTo.length} of {totalDays}
                </Bold>{' '}
                days of your job.
            </Text>
            {unfilledDays.length > 0 ? (
                <Text variant={variant}>
                    You still need a provider for <Bold>{unfilledDaysList}.</Bold>
                </Text>
            ) : null}
        </Grid>
    );
}

function Bold({ children }: { children: ReactNode | ReactNode[] }) {
    return <b style={{ color: Colors.turquoise }}>{children}</b>;
}
