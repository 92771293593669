import React from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { AccordionDetails, AccordionSummary, Accordion, withStyles } from '@material-ui/core';

const StyledExpansionPanel = withStyles({
    root: {
        boxShadow: 'none',
        width: '100%',
    },
    rounded: {
        borderRadius: 50,
    },
})(Accordion);

export default function SimpleExpansionPanel(props) {
    const { primary, secondary } = props;

    return (
        <div>
            <StyledExpansionPanel>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon color="primary" />}
                    IconButtonProps={{ disableFocusRipple: true, disableRipple: true }}
                >
                    {primary}
                </AccordionSummary>
                <AccordionDetails>{secondary}</AccordionDetails>
            </StyledExpansionPanel>
        </div>
    );
}
