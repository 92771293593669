import React, { useState } from 'react';
import { Grid, useMediaQuery } from '@mui/material';
import { Text } from 'library';
import { useUserContext } from 'UserContext';
import theme from 'theme';
import PostJob from './dashboard/PostJob';
import BusinessNotifications from './dashboard/BusinessNotifications';
import DayCareCenterList from './dashboard/DayCareCenterList';
import WorkerTimeSheets from './dashboard/WorkerTimeSheets';
import type { IBusinessDashboardProps } from './dashboard/types';
import MissingMicrodepositVerificationModal from './paymentVerificationWarning/MissingMicrodepositVerificationModal';
import useMicrodepositVerificationInfo from './paymentVerificationWarning/useMicrodepositVerficationInfo';
import ReferralSection from './dashboard/ReferralSection';
import Give50DiscountBanner from './dashboard/Give50DiscountBanner';
import ProviderReviewModal from './providerReivewModal/ProviderReviewModal';
import ProviderReviewList from './dashboard/ProviderReviewList';
import { useWorkersForReview } from './dashboard/dashboardApi';
import CenterContact from './dashboard/CenterContact';
import ClockInWorkers from './dashboard/ClockInWorkers';
import WorkersSpotlight from './dashboard/WorkersSpotlight';

const BusinessDashboard = ({ data, updateData }: IBusinessDashboardProps) => {
    const { user } = useUserContext();
    const { needsVerificationWarning } = useMicrodepositVerificationInfo();
    const { data: workersForReivew, loading: workersLoading, error: workersError } = useWorkersForReview();
    const mdUp = useMediaQuery(theme.breakpoints.up('md'));

    const [isModalOpen, setModalOpen] = useState(needsVerificationWarning);

    return (
        <>
            <Give50DiscountBanner data={data} updateData={updateData} />
            <Grid container spacing={2} style={{ padding: mdUp ? 46 : 10 }}>
                <Grid item sm={6} xs={12}>
                    <Text variant="display" textStyle={{ paddingLeft: 20 }}>
                        Welcome {user ? user.first_name : 'User'}!
                    </Text>
                    <PostJob />
                    <ClockInWorkers data={data} updateData={updateData} />
                    <WorkerTimeSheets data={data} updateData={updateData} />
                    <ProviderReviewList workers={workersForReivew} loading={workersLoading} error={!!workersError} />
                </Grid>

                <Grid item sm={6} xs={12}>
                    <Grid container>
                        <Grid container>
                            <Grid item xs={12} sm={7}>
                                <BusinessNotifications data={data} />
                            </Grid>
                            <Grid item xs={12} sm={5}>
                                <CenterContact data={data} />
                            </Grid>
                        </Grid>
                        <WorkersSpotlight />
                        <DayCareCenterList data={data} />

                        <ReferralSection />
                    </Grid>
                </Grid>

                <ProviderReviewModal workers={workersForReivew} />
                <MissingMicrodepositVerificationModal isOpen={isModalOpen} onClose={() => setModalOpen(false)} />
            </Grid>
        </>
    );
};

export default BusinessDashboard;
