import React from 'react';
import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import { states } from '../../../../../../signup/states';
interface IProps {
    onChange: (state: string) => void;
    value: string;
}

export default function StateDropdown({ value, onChange }: IProps) {
    return (
        <FormControl style={{ width: '100%' }}>
            <InputLabel>State</InputLabel>
            <Select value={value} onChange={(e) => onChange(e.target.value as string)}>
                {states.map((state, index) => {
                    return (
                        <MenuItem key={index} value={state}>
                            {state}
                        </MenuItem>
                    );
                })}
            </Select>
        </FormControl>
    );
}
