export interface Region {
    id: number;
    name: string;
    latitude: number;
    longitude: number;
}

export interface RegionAdmin {
    id: number;
    user: RegionAdminUser;
    area: number;
    role: RegionAdminRole;
    primary: boolean;
}

export interface RegionAdminUser {
    id: number;
    first_name: string;
    last_name: string;
    email: string;
}

export enum RegionAdminRole {
    MATCHING_SPECIALIST = 'MATCHING_SPECIALIST',
    ONBOARDING_SPECIALIST = 'ONBOARDING_SPECIALIST',
    COMPLIANCE_SPECIALIST = 'COMPLIANCE_SPECIALIST',
}
