import React, { useRef } from 'react';
import { Drawer } from '@material-ui/core';
import { Colors, Text } from 'library';
import ApplicantCardList from 'parent-portal/sitters/Components/ApplicantCardList';
import { useProviderProfileContext } from '../ProviderProfileContext';

export default function ApplicantDrawer({
    mobile,
    open,
    onProviderSelected,
}: {
    mobile: boolean;
    open: boolean;
    onProviderSelected: () => void;
}) {
    const containerRef = useRef(null);
    const { job, applicants } = useProviderProfileContext();

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'row',
                position: 'relative',
                top: 0,
                height: mobile ? '80vh' : '95vh',
                overflow: 'visible',
                zIndex: 900,
            }}
            ref={containerRef}
        >
            <Drawer
                container={containerRef.current}
                onClose={onProviderSelected}
                open={open}
                ModalProps={{
                    style: { width: 370 },
                    keepMounted: true,
                }}
                PaperProps={{ style: { width: 370, paddingTop: mobile ? 30 : 70 } }}
                variant={mobile ? undefined : 'permanent'}
                hideBackdrop
            >
                <div
                    style={{
                        padding: 20,
                        width: 370,
                        backgroundColor: Colors.white,
                    }}
                >
                    <Text variant="body1" bold textStyle={{ marginBottom: 10 }}>
                        {applicants.length} applicant{applicants.length === 1 ? '' : 's'} for
                    </Text>
                    <Text variant="h2" bold color={Colors.turquoise}>
                        {job?.headline || ''}
                    </Text>
                    <ApplicantCardList onProviderSelected={onProviderSelected} />
                </div>
            </Drawer>
        </div>
    );
}
