import React from 'react';
import { Text, Colors } from 'library';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboardCheck, faDollarSign, faUserCheck } from '@fortawesome/free-solid-svg-icons';
import { IconProp, SizeProp } from '@fortawesome/fontawesome-svg-core';

interface Bullet {
    key: string;
    leadText: string;
    boldText: string;
    endText: string;
    icon: IconProp;
    iconSize: SizeProp;
}

export default function CentersBullets({ smDown }: { smDown: boolean }) {
    const bullets = [
        {
            key: 'preferences',
            leadText: 'Needs filled based on your ',
            boldText: 'preferences',
            endText: '.',
            icon: faClipboardCheck,
            iconSize: (smDown ? 'lg' : '3x') as SizeProp,
        },
        {
            key: 'matching',
            leadText: 'Matched with ',
            boldText: 'fully credentialed',
            endText: ' workers.',
            icon: faUserCheck,
            iconSize: (smDown ? 'sm' : '2x') as SizeProp,
        },
        {
            key: 'payment',
            leadText: 'Payments sent via integrated, secure ',
            boldText: 'payment system',
            endText: '.',
            icon: faDollarSign,
            iconSize: (smDown ? 'lg' : '3x') as SizeProp,
        },
    ];

    const styles = {
        row: {
            display: 'flex',
            flexDirection: 'row',
            marginBottom: 30,
            alignItems: 'center',
        },
        circle: {
            backgroundColor: Colors.mediumTurq,
            minWidth: smDown ? 30 : 75,
            minHeight: smDown ? 30 : 75,
            borderRadius: 50,
            position: 'relative',
            marginRight: smDown ? 20 : 30,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
    } as { [key: string]: React.CSSProperties };

    function Row({ bullet }: { bullet: Bullet }) {
        return (
            <div style={styles.row}>
                <div style={styles.circle}>
                    <FontAwesomeIcon icon={bullet.icon} color={Colors.lightTurq} size={bullet.iconSize} />
                </div>
                <Text variant={smDown ? 'body1' : 'h2'}>
                    {bullet.leadText}
                    <Text inline bold>
                        {bullet.boldText}
                    </Text>
                    {bullet.endText}
                </Text>
            </div>
        );
    }

    return (
        <>
            {bullets.map((bullet) => (
                <Row key={bullet.key} bullet={bullet} />
            ))}
        </>
    );
}
