import { PagingResult } from 'models';
import { client, queryStringifyObject } from 'shared';
import { InboxCount, LocationTaskList, TaskListSearchFilters, UserTaskList } from './matchingInboxModels';

export async function getTaskListByLocation(
    searchFilters: TaskListSearchFilters,
): Promise<PagingResult<LocationTaskList>> {
    const params = queryStringifyObject(searchFilters);
    return await client(`api/matching-inbox/location-tasks/?${params}`);
}

export async function getTaskListByUser(searchFilters: TaskListSearchFilters): Promise<PagingResult<UserTaskList>> {
    const params = queryStringifyObject(searchFilters);
    return await client(`api/matching-inbox/worker-tasks/?${params}`);
}

export async function getInboxCountSummary(regions: number[]): Promise<InboxCount[]> {
    return await client(`api/matching-inbox/count/?regions=${regions.join(',')}`);
}
