import React from 'react';
import { Text } from 'library';
import { EmailTemplate } from './models';

interface TemplateFieldsProps {
    template?: EmailTemplate;
}

export default function TemplateFields({ template }: TemplateFieldsProps) {
    const bodyFields = template?.body.match(/\{[^\}]*\}/g) ?? [];
    const subjectFields = template?.subject.match(/\{[^\}]*\}/g) ?? [];

    const combinedFields = Array.from(new Set([...bodyFields, ...subjectFields]));
    const templateFields = combinedFields.map(stripBrackets);

    const hasFields = templateFields.length > 0;
    const headerText = hasFields ? 'Fields in this template:' : 'Selected template has no fields';

    return template ? (
        <div style={{ marginTop: 20 }}>
            <Text variant="body2">{headerText}</Text>
            {templateFields?.map((field) => (
                <Text variant="body2" key={field}>
                    {field}
                </Text>
            ))}
        </div>
    ) : null;
}

function stripBrackets(value: string) {
    return value.substring(1, value.length - 1);
}
