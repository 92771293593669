import { CircularProgress, Grid, TextField } from '@mui/material';
import { Checkbox, RadioGroup, Text } from 'library';
import { AdminListProvider, BusinessJobWithAppointments, PagingResult } from 'models';
import React, { useEffect, useState } from 'react';
import MatchingRow from './MatchingRow';
import MatchingListActions from './MatchingListActions';
import useSelectedUsers from './useSelectedUsers';
import useMatchingListQuery from './useMatchingListQuery';
import { getAdminProviders } from './matchingListApi';
import { adminLog } from 'shared';
import PaginatedTable from 'internal/shared/tables/PaginatedAdminTable';
import { formatDateFromDateObject } from 'shared/Dates';

const distanceValues = { '5': '5 miles', '10': '10 miles', '15': '15 miles', '25': '25 miles' };

export default function MatchingList({
    job,
    open,
    refreshJobs,
    week,
}: {
    job: BusinessJobWithAppointments;
    open: boolean;
    refreshJobs: () => void;
    week?: Date;
}) {
    const [matches, setMatches] = useState<PagingResult<AdminListProvider>>({
        results: [],
        count: 0,
        per_page: 0,
        num_pages: 0,
    });
    const [distance, setDistance] = useState(15);
    const [loading, setLoading] = useState(false);
    const [selectedUsers, setSelectedUsers, selectUserOnChange] = useSelectedUsers();
    const generateQueryParams = useMatchingListQuery();
    const [qualified, setQualified] = useState(true);
    const [available, setAvailable] = useState(true);
    const [vettedInLastXDays, setVettedInLastXDays] = useState<number>();
    const [unbookedDate, setUnbookedDate] = useState<string | undefined>(formatDateFromDateObject(new Date()));
    const [excludeBooked, setExcludeBooked] = useState(true);
    const [page, setPage] = useState(1);

    function refresh() {
        if (open) {
            setLoading(true);
            const queryParams = generateQueryParams(
                job,
                distance,
                week,
                qualified,
                available,
                vettedInLastXDays,
                unbookedDate,
                page,
            );
            getAdminProviders(queryParams)
                .then(setMatches)
                .catch(adminLog)
                .finally(() => setLoading(false));
        }
    }

    function enterPressHandler(e: React.KeyboardEvent<HTMLInputElement>) {
        if (e.key === 'Enter') {
            refresh();
        }
    }

    function updateUnbooked() {
        setUnbookedDate(!excludeBooked ? formatDateFromDateObject(new Date()) : undefined);
        setExcludeBooked(!excludeBooked);
    }

    useEffect(() => {
        refresh();
    }, [open, job, distance, qualified, available, vettedInLastXDays, page, unbookedDate]);

    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <RadioGroup
                style={{ padding: 10 }}
                horizontal
                value={distance.toString()}
                setValue={(val) => {
                    setDistance(parseInt(val));
                }}
                values={distanceValues}
            />
            <Grid container direction="row" padding={1}>
                <Checkbox
                    checked={qualified}
                    onChange={() => setQualified(!qualified)}
                    label="Qualified"
                    style={{ width: 'auto' }}
                />
                <Checkbox
                    checked={available}
                    onChange={() => setAvailable(!available)}
                    label="Available"
                    style={{ width: 'auto' }}
                />
                <TextField
                    type="number"
                    value={vettedInLastXDays}
                    onChange={(e) => setVettedInLastXDays(parseInt(e.target.value))}
                    label="Vetted in last X days"
                    style={{ width: 'auto', marginLeft: 10 }}
                />
                <Checkbox
                    checked={excludeBooked}
                    onChange={updateUnbooked}
                    label="Unbooked"
                    style={{ width: 'auto', marginRight: 5 }}
                />
                <TextField
                    type="date"
                    disabled={!excludeBooked}
                    value={unbookedDate}
                    onKeyUp={enterPressHandler}
                    onChange={(e) => setUnbookedDate(e.target.value)}
                />
            </Grid>
            <MatchingListActions
                job={job}
                refreshJobs={refreshJobs}
                matchingList={matches.results.map((provider) => provider.user)}
                selectedUsers={selectedUsers}
                setSelectedUsers={setSelectedUsers}
            />
            {loading ? (
                <CircularProgress style={{ padding: 10 }} />
            ) : (
                <PaginatedTable
                    data={matches}
                    page={page}
                    updatePage={setPage}
                    columns={['Worker', 'Availability', 'Bookings', 'Qualifications', 'Actions']}
                >
                    {matches.results.length > 0 ? (
                        matches.results.map((provider) => (
                            <MatchingRow
                                key={provider.id}
                                provider={provider}
                                job={job}
                                refreshJobs={refreshJobs}
                                userSelected={selectedUsers.some((usr) => usr.id === provider.user.id)}
                                setSelectedUser={selectUserOnChange}
                                week={week}
                            />
                        ))
                    ) : (
                        <Text textStyle={{ padding: 10 }}>No matching providers found</Text>
                    )}
                </PaginatedTable>
            )}
        </div>
    );
}
