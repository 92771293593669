import { client, consoleLogInDev } from 'shared';
import { AdminProvider } from 'models';
export function getAdminProvider(providerUserId: number): Promise<AdminProvider> {
    return client(`api/admin-providers/${providerUserId}/`);
}

export async function refreshConversation(
    tandemNumber: string,
    externalNumber: string,
    handleResult: (d: any) => void,
    mark_as_read: boolean = false,
    cleanup?: () => void,
    thread_id?: number,
) {
    client('tracking/api/external-message/thread/', {
        body: { external_phone_number: externalNumber, tandem_phone_number: tandemNumber, thread_id },
        method: 'POST',
    })
        .then((data) => {
            handleResult(data);
            if (mark_as_read) {
                client('tracking/api/external-message/read/', {
                    body: {
                        sender_phone_number: externalNumber,
                        receiver_phone_number: tandemNumber,
                    },
                    method: 'POST',
                });
            }
        })
        .catch(consoleLogInDev)
        .finally(() => cleanup?.());
}

export function unSuspendUser(userId: number) {
    return client(`api/users/suspend/`, {
        method: 'PATCH',
        body: { suspended_at: null, user: userId },
    });
}

export function suspendUser(userId: number, date: Date, reason: string, details?: string) {
    return client(`api/users/suspend/`, {
        method: 'PATCH',
        body: { suspended_at: date, user: userId, reason, details },
    });
}

const SUSPENSION_MSG =
    'Your account has been suspended due to reliability concerns. We will be unable to approve shift requests for you until further notice.';
export function sendSuspensionNotification(userId: number) {
    client('notification/api/notification/manual-notification/', {
        method: 'POST',
        body: {
            users: [userId],
            action: 'navigate',
            action_type: 'mobile',
            title: 'Suspension Notice',
            message: SUSPENSION_MSG,
            path_template: 'MyJobs',
        },
    });
}
