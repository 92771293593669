import React from 'react';
import { Paper, Text } from 'library';
import { useProviderProfileContext } from '../../ProviderProfileContext';
import BusinessHighlights from './BusinessHighlights';

export default function ProviderProfileBusinessSummaryTab() {
    const { provider, providerQualifications, childcareCenterQualifications } = useProviderProfileContext();

    return (
        <>
            <Paper>
                <Text variant="body2">{provider?.bio}</Text>
            </Paper>
            <BusinessHighlights
                provider={provider}
                providerQualifications={providerQualifications}
                childcareCenterQualifications={childcareCenterQualifications}
            />
        </>
    );
}
