import { AdminSlot, JobTimesOfDay } from 'models';
import React from 'react';
import JobSlot from './JobSlot';
import AddSlotButton from './AddSlotButton';

export default function JobSlots({
    jobId,
    businessLocationId,
    utcOffset,
    slots,
    updateFlag,
    refreshJobs,
    times,
    date,
}: {
    jobId: number;
    businessLocationId?: number;
    utcOffset: number;
    slots: AdminSlot[];
    updateFlag: (staffId: number, businessLocationId: number) => void;
    refreshJobs: () => void;
    times?: JobTimesOfDay;
    date: Date;
}) {
    return (
        <>
            {slots.map((slot) => (
                <JobSlot
                    key={slot.id}
                    slot={slot}
                    businessLocationId={businessLocationId}
                    updateFlag={updateFlag}
                    refreshJobs={refreshJobs}
                />
            ))}
            <AddSlotButton jobId={jobId} times={times} utcOffset={utcOffset} refreshJobs={refreshJobs} date={date} />
        </>
    );
}
