import React from 'react';
import { Colors, Text } from 'library';
import { styled } from '@mui/material';

export function FindButtons({
    selected,
    setSelected,
}: {
    selected: 'staff' | 'shifts';
    setSelected: (value: 'staff' | 'shifts') => void;
}) {
    return (
        <Container>
            <StaffButton>
                <Text
                    variant="h2"
                    textStyle={{
                        color: selected === 'staff' ? Colors.turquoise : Colors.darkNavy,
                        cursor: 'pointer',
                    }}
                    bold={selected === 'staff'}
                    onClick={() => setSelected('staff')}
                >
                    Find Staff
                </Text>
                {selected === 'staff' && <Line />}
            </StaffButton>
            <div>
                <Text
                    variant="h2"
                    textStyle={{
                        color: selected === 'shifts' ? Colors.turquoise : Colors.darkNavy,
                        cursor: 'pointer',
                    }}
                    bold={selected === 'shifts'}
                    onClick={() => setSelected('shifts')}
                >
                    Find Shifts
                </Text>
                {selected === 'shifts' && <Line />}
            </div>
        </Container>
    );
}

const Container = styled('div')({
    display: 'flex',
});

const StaffButton = styled('div')({
    marginRight: 25,
});

const Line = styled('div')({
    backgroundColor: Colors.turquoise,
    height: 4,
});
