import React from 'react';
import { TextButton } from 'library';
import { LeanUser } from 'models';

export default function NameButton({ user, onClick }: { user: LeanUser; onClick: (userId: number) => void }) {
    return (
        <TextButton
            onClick={() => onClick(user.id)}
            textStyle={{ textAlign: 'left', width: 'fit-content' }}
            buttonStyle={{ width: 'fit-content' }}
        >
            {user.first_name} {user.last_name} ({user.id})
        </TextButton>
    );
}
