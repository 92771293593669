import React, { useEffect } from 'react';
import { Grid, useMediaQuery } from '@mui/material';
import { PrimaryButton, Text, SizeableRoundedDialog } from 'library';
import theme from 'theme';
import { BusinessJobResponse } from './models';
import useLoading from 'library/hooks/useLoading';
import { client, consoleLogInDev } from 'shared';
import useForm from 'parent-portal/forms/components/FormHook';
import BusinessPayInputs, { payRangeInfoValid } from 'parent-portal/forms/components/pages/PayPage/BusinessPayInputs';
import { IPayFormFields, IPayScale } from 'parent-portal/forms/JobRequestTypes';

export default function EditPayModal({
    open,
    onClose,
    businessJobResponse,
    onSaveComplete,
}: {
    open: boolean;
    onClose: () => void;
    businessJobResponse: BusinessJobResponse;
    onSaveComplete: () => void;
}) {
    const small = useMediaQuery(theme.breakpoints.down('sm'));
    const form = useForm<{
        pay: IPayFormFields;
        payScales: IPayScale[];
    }>({
        pay: initPayValues(),
        payScales: businessJobResponse.job_details.pay_scales,
    });
    const { loading, setLoading } = useLoading();

    const { pay, payScales } = form.fieldStates;

    function initPayValues() {
        return {
            payFixed: businessJobResponse.job_details.pay || null,
            payMax: businessJobResponse.job_details.rate_max || 0,
            payMin: businessJobResponse.job_details.rate_min || 0,
            isFixedPay: businessJobResponse.job_details.pay_is_fixed || null,
        };
    }

    useEffect(() => {
        if (open) {
            pay.setValue(initPayValues());
            payScales.setValue(businessJobResponse.job_details.pay_scales);
        }
    }, [open]);

    function submit() {
        setLoading(true);
        client(`api/ongoing/${businessJobResponse?.job_details?.id}/`, {
            method: 'PATCH',
            body: {
                pay: pay.value.payFixed,
                pay_is_fixed: false,
                rate_min: pay.value.payMin,
                rate_max: pay.value.payMax,
                pay_scales: payScales.value,
            },
        })
            .then((response) => onSaveComplete())
            .catch(consoleLogInDev)
            .finally(() => {
                setLoading(false);
                onClose();
            });
    }

    return (
        <SizeableRoundedDialog
            open={open}
            onClose={onClose}
            fullScreen={small}
            closeButton
            maxWidth="md"
            style={{ maxWidth: 500 }}
        >
            <Grid container item direction="column" style={{ padding: 20, gap: 10 }}>
                <Text variant="h1">Update Pay</Text>
                <Grid container justifyContent="center">
                    <BusinessPayInputs
                        pay={pay}
                        payScales={payScales}
                        defaultPayScales={businessJobResponse.job_details.pay_scales}
                        updateDefaultPayScales={(val) => {}}
                        hasSeenTrainingsModal={true}
                        updateHasSeenTrainingsModal={() => {}}
                    />
                </Grid>
                <PrimaryButton
                    loading={loading}
                    disabled={loading || !payRangeInfoValid(payScales.value)}
                    onClick={submit}
                    buttonStyle={{ width: undefined, alignSelf: 'flex-end' }}
                >
                    Save
                </PrimaryButton>
            </Grid>
        </SizeableRoundedDialog>
    );
}
