export interface JobType {
    id: number;
    name: string;
    description: string;
    approved: boolean;
    interest_count: number;
}

export const JobTypeIds = {
    ChildCare: 1,
    PetCare: 2,
    Housecleaning: 8,
    Other: -1,
    Tutoring: 3,
};

export const JobTypesWithDependents = [1, 2];
