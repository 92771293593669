import React from 'react';
import { Text, Colors } from 'library';
import { Rating, styled, Grid } from '@mui/material';
import { ReviewWithUser } from 'models/Review';
import ReviewScore from './ReviewScore';

export default function CenterReview({ review }: { review: ReviewWithUser }) {
    return (
        <Container container item direction="column">
            <GapContainer container item alignContent="center">
                <Grid item>
                    <Text bold variant="h2">
                        {review.reviewer.first_name} {review.reviewer.last_name} (
                        {new Date(review.added_at).toLocaleDateString()})
                    </Text>
                </Grid>
                <Rating value={review.total_score} readOnly />
            </GapContainer>
            <Grid container item>
                {review.scores?.map((res, i) => (
                    <ReviewScore key={i} score={res} />
                ))}
            </Grid>
            {review.text ? (
                <Text key={review.id} variant="body1">
                    {review.text}
                </Text>
            ) : null}
        </Container>
    );
}

const Container = styled(Grid)({
    backgroundColor: Colors.lightTurq,
    padding: 20,
    borderRadius: 18,
    gap: 10,
});

const GapContainer = styled(Grid)({
    gap: 20,
});
