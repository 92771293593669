import React, { useEffect, useState } from 'react';
import { SubJobAppointment } from 'models';
import { PrimaryButton, Text, TimeSlider } from 'library';
import { midnightMinutes } from 'shared/Dates';
import { Grid } from '@material-ui/core';
import { client, consoleLogInDev } from 'shared';
import { Popover } from '@mui/material';
import { addMinutes } from 'date-fns';

export default function EditJobModal({
    appt,
    refreshJobs,
    anchorEl,
    open,
    onClose,
}: {
    appt: SubJobAppointment;
    refreshJobs: () => void;
    anchorEl: HTMLElement | null;
    open: boolean;
    onClose: () => void;
}) {
    const [sliderValues, setSliderValues] = useState([0, midnightMinutes]);

    useEffect(() => setSliderValues(getValues(appt)), [appt]);

    function confirmChanges() {
        const body = {} as { start_date?: string; end_date?: string };
        const originalValues = getValues(appt);
        if (originalValues[0] !== sliderValues[0])
            body.start_date = minutesToDate(appt.start_date, appt.timezone_offset, sliderValues[0], true).toISOString();
        if (originalValues[1] !== sliderValues[1])
            body.end_date = minutesToDate(appt.end_date, appt.timezone_offset, sliderValues[1]).toISOString();
        client(`api/admin-appointment/${appt.id}/`, { method: 'PATCH', body: body })
            .then(refreshJobs)
            .finally(onClose)
            .catch(consoleLogInDev);
    }

    function updateValues(newValues: number | number[]) {
        if (!(newValues instanceof Array)) return;
        setSliderValues(newValues);
    }

    function sliderValueFromDate(iso8601DateString: string, timezoneOffset: number, isStart: boolean = false) {
        const date = new Date(iso8601DateString);
        let hour = (date.getUTCHours() + timezoneOffset + 24) % 24;
        if (!isStart && hour === 0) hour = 24;
        return hour * 60 + date.getMinutes();
    }

    function getValues(slot: SubJobAppointment): [number, number] {
        return [
            sliderValueFromDate(slot.start_date, slot.timezone_offset, true),
            sliderValueFromDate(slot.end_date, slot.timezone_offset),
        ];
    }
    function minutesToDate(date: string, timezoneOffset: number, currentSliderValue: number, isStart: boolean = false) {
        let newDate = new Date(date);
        const oldSliderValue = sliderValueFromDate(date, timezoneOffset, isStart);
        const totalMinutesDifference = currentSliderValue - oldSliderValue;

        newDate = addMinutes(newDate, totalMinutesDifference);
        return newDate;
    }

    return (
        <Popover open={open} onClose={onClose} anchorEl={anchorEl}>
            <Grid container item style={{ padding: 20, maxWidth: 500 }}>
                <Text variant="h1">Edit Job</Text>
                <Grid container item style={{ padding: 20, gap: 20 }}>
                    <Text>Edit appointment {appt.id}</Text>
                    <TimeSlider min={0} max={midnightMinutes} values={sliderValues} onValuesChange={updateValues} />
                    <PrimaryButton onClick={confirmChanges} buttonStyle={{ width: '50%' }}>
                        Confirm
                    </PrimaryButton>
                </Grid>
            </Grid>
        </Popover>
    );
}
