import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    root: {
        marginBottom: 5,
        width: '100%',
        borderColor: theme.palette.primary.main,
        '& label.Mui-focused': {
            color: theme.palette.primary.main,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: theme.palette.primary.main,
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: theme.palette.primary.main,
            },
            '&:hover': {
                borderColor: theme.palette.primary.main,
            },
            '&.Mui-focused': {
                borderColor: theme.palette.primary.main,
            },
        },
        '&:hover:not($disabled):after': {
            borderColor: theme.palette.primary.main,
        },
        '&:hover:not($disabled):before': {
            borderColor: theme.palette.primary.main,
        },
    },
    select: {
        '&:before': {
            borderColor: theme.palette.primary.main,
        },
        '&:hover:not(.Mui-disabled):before': {
            borderColor: theme.palette.primary.main,
        },
    },
}));
