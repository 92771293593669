import { Chip, Colors } from 'library';
import { BusinessJobWithAppointments } from 'models';
import React from 'react';

export default function SubJobChips({ job }: { job: BusinessJobWithAppointments }) {
    return (
        <span>
            {job.business_job_type === 'PERMANENT' && (
                <Chip
                    style={{ minHeight: 20, height: 20, marginRight: 4 }}
                    textColor={Colors.white}
                    backgroundColor={Colors.terraCotta}
                    label="Legacy"
                />
            )}
            {job.details
                .filter((detail) => detail.option)
                .map((detailValue) => (
                    <Chip
                        key={detailValue.id}
                        style={{ minHeight: 20, height: 20, marginRight: 4 }}
                        textColor={detailValue.detail === 2 ? Colors.white : Colors.darkNavy}
                        backgroundColor={detailValue.detail === 2 ? Colors.darkNavy : Colors.mediumTurq}
                        label={detailValue.option?.value || ''}
                    />
                ))}
        </span>
    );
}
