import React from 'react';
import { Colors } from '../../../constants';
import { Chip, withStyles } from '@material-ui/core';

function getStyleChip(selected: boolean, limitWidth: boolean) {
    const style = {
        root: {
            color: Colors.navy,
            backgroundColor: `${Colors.lightTurq} !important`,
            fontWeight: 700,
            minHeight: 32,
            maxHeight: 70,
            margin: '5px',
            maxWidth: limitWidth ? 200 : 'unset',
        },
    };
    if (selected) {
        style.root = {
            ...style.root,
            backgroundColor: `${Colors.turquoise} !important`,
            color: Colors.white,
        };
    }
    return withStyles(style)(Chip);
}

interface IProps {
    label: string;
    onClick: () => void;
    selected: boolean;
    limitWidth?: boolean;
}

export default function OptionChip({ label, onClick, selected, limitWidth = false }: IProps) {
    const StyleChip = getStyleChip(selected, limitWidth);
    return <StyleChip label={label} onClick={onClick} />;
}
