import { Grid } from '@material-ui/core';
import { LocationHistory } from 'models';
import React from 'react';
import { formatMonthDayYearWith12HourTime } from 'shared/Dates';
import { Text } from 'library';
import Icon from '@mui/material/Icon';

export default function LocationHistoryRow({ entry }: { entry: LocationHistory }) {
    const iconName = entry.history_type?.icon;
    const icon = iconName ? <Icon sx={{ color: entry.history_type?.foreground_color }}>{iconName}</Icon> : <></>;
    return (
        <Grid
            container
            direction="row"
            key={entry.id}
            style={{ backgroundColor: entry.history_type?.background_color, padding: 5 }}
        >
            <Grid container item xs={1} justify="center" alignItems="center">
                {icon}
            </Grid>
            <Grid container direction="column" justify="center" item xs={11}>
                <Text variant="body2">{entry.display}</Text>
                <Text variant="caption">{formatMonthDayYearWith12HourTime(entry.added_at)}</Text>
            </Grid>
        </Grid>
    );
}
