import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import MobileStepper from '@mui/material/MobileStepper';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { Colors, Text, PrimaryButton } from 'library';
import { Job } from 'models';
import PostStepHeader from 'assets/images/job/post-step-header.png';
import PostStepOne from 'assets/images/job/post-step-one.png';
import SubStepTwo from 'assets/images/job/sub-step-two.png';
import SubStepThree from 'assets/images/job/sub-step-three.png';
import TrialStepTwo from 'assets/images/job/trial-step-two.png';
import TrialStepThree from 'assets/images/job/trial-step-three.png';
import { styled } from '@mui/material';
import { client, consoleLogInDev } from 'shared';

interface BusinessJobSuccessPageProps {
    onClose: () => void;
    job?: Job;
    setShowModalClose: (val: boolean) => void;
}

export default function BusinessJobSuccessPage({ onClose, job, setShowModalClose }: BusinessJobSuccessPageProps) {
    const [isLoading, setIsLoading] = useState(true);
    const [isFirst, setIsFirst] = useState(false);
    const [step, setStep] = useState(0);
    const [hasSeenAllSteps, setHasSeenAllSteps] = useState(false);

    useEffect(() => {
        client(`api/ongoing/${job?.id}/is-first-of-type/`)
            .then((response) => {
                setIsFirst(response.is_first);
                if (response.is_first) {
                    setShowModalClose(false);
                }
            })
            .catch(consoleLogInDev)
            .finally(() => {
                setIsLoading(false);
            });
    }, []);

    function getTitle() {
        if (!isFirst) {
            return 'Great, we got your job request!';
        }

        const jobType = job?.business_job_type === 'TRIAL_RUN' ? 'Trial Run' : 'Substitute';

        return `YAY! You posted your first ${jobType} position`;
    }

    function getContent() {
        if (isFirst) {
            return job?.business_job_type === 'TRIAL_RUN' ? getTrialContent() : getSubContent();
        }

        return (
            <SubStepContent image={PostStepOne}>
                <Text>
                    Our expert Matching team and software will look at your schedule, requirements, etc. to match you
                    with the best fit teacher.
                </Text>
                <Text textStyle={{ marginTop: 10 }}>
                    <b>You will be notified via email once we confirm a teacher.</b> You can also check the schedule by
                    going to the “My Jobs” tab.
                </Text>
            </SubStepContent>
        );
    }

    function getTrialContent() {
        if (step === 0) {
            return (
                <SubStepContent image={PostStepOne}>
                    <Text>
                        Our expert Matching team and software will look at your schedule, requirements, etc. to match
                        you with the best fit teacher.{' '}
                        <b>Only teachers interested and available for permanent roles will be considered.</b>
                    </Text>
                </SubStepContent>
            );
        } else if (step === 1) {
            return (
                <SubStepContent image={TrialStepTwo}>
                    <Text>
                        Once we confirm a teacher, you will be notified via{' '}
                        <b>
                            <u>email.</u>
                        </b>{' '}
                        You can also check the schedule by going to the “My Jobs” tab. Click their name to view their
                        profile.
                    </Text>
                    <Text textStyle={{ marginTop: 10 }}>
                        You can download the teacher&apos;s documents, edit the shift times, and report their hours via
                        the three dots next to their name.
                    </Text>
                </SubStepContent>
            );
        } else if (step === 2) {
            return (
                <SubStepContent image={TrialStepThree}>
                    <Text>
                        <b>Once we confirm a teacher, they will be set to start.</b> It is required that they work{' '}
                        <b>120 hours through Tandem</b> before they join your staff. Use this time period to ensure they
                        are the right fit.
                    </Text>
                </SubStepContent>
            );
        }
    }

    function getSubContent() {
        if (step === 0) {
            return (
                <SubStepContent image={PostStepOne}>
                    <Text>
                        Our expert Matching team and software will look at your schedule, requirements, etc. to match
                        you with the best fit teacher.
                    </Text>
                </SubStepContent>
            );
        } else if (step === 1) {
            return (
                <SubStepContent image={SubStepTwo}>
                    <Text>
                        Once we confirm a teacher, you will be notified via email. You can also check the schedule by
                        going to the “My Jobs” tab. Click their name to view their profile.
                    </Text>
                </SubStepContent>
            );
        } else if (step === 2) {
            return (
                <SubStepContent image={SubStepThree}>
                    <Text>
                        <b>Once we confirm a teacher, they will be set to start.</b> You can download the teacher&apos;s
                        documents, edit the shift times, and report their hours via the three dots next to their name.
                    </Text>
                </SubStepContent>
            );
        }
    }

    function onUpdateStep(val: number) {
        setStep(val);
        if (!hasSeenAllSteps && val === 2) {
            setHasSeenAllSteps(true);
            setShowModalClose(true);
        }
    }

    if (isLoading) {
        return <></>;
    }

    const isCloseEnabled = !isFirst || hasSeenAllSteps;

    return (
        <>
            <Grid container direction="column">
                <Grid container item xs={12} direction="row" alignItems="center">
                    <Grid item xs={1}>
                        <HeaderImage src={PostStepHeader} />
                    </Grid>
                    <Grid item xs={11}>
                        <Text variant="h1">{getTitle()}</Text>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Text variant="h2" bold textStyle={{ margin: '10px 0' }}>
                        What to Expect:
                    </Text>
                    {getContent()}
                </Grid>
                {isFirst && (
                    <Grid container justify="flex-end">
                        <Stepper step={step} setStep={onUpdateStep} />
                    </Grid>
                )}
                <Grid container direction="row" justify="flex-end">
                    <PrimaryButton
                        buttonStyle={{ maxWidth: 250, margin: 5, marginBottom: 15 }}
                        onClick={onClose}
                        disabled={!isCloseEnabled}
                        id="job-success-page-next-button"
                    >
                        Close
                    </PrimaryButton>
                </Grid>
            </Grid>
        </>
    );
}

function SubStepContent({ children, image }: { children: React.ReactNode; image: string }) {
    return (
        <>
            {children}
            <ImageContainer>
                <StyledImage src={image} />
            </ImageContainer>
        </>
    );
}

function Stepper({ step, setStep }: { step: number; setStep: (val: number) => void }) {
    const handleNext = () => {
        setStep(step + 1);
    };

    const handleBack = () => {
        setStep(step - 1);
    };

    return (
        <StyledMobileStepper
            variant="dots"
            steps={3}
            position="static"
            activeStep={step}
            sx={{ maxWidth: '40%', flexGrow: 1 }}
            nextButton={
                <StepperButton size="small" onClick={handleNext} disabled={step === 2}>
                    Next
                    <KeyboardArrowRight />
                </StepperButton>
            }
            backButton={
                <StepperButton size="small" onClick={handleBack} disabled={step === 0}>
                    <KeyboardArrowLeft />
                    Back
                </StepperButton>
            }
        />
    );
}

const HeaderImage = styled('img')({
    height: 50,
});

const StyledImage = styled('img')({
    maxWidth: '90%',
    alignSelf: 'center',
    height: 240,
});

const ImageContainer = styled('div')({
    marginTop: 10,
    width: '100%',
    textAlign: 'center',
});

const StepperButton = styled(Button)({
    color: Colors.darkNavy,
});

const StyledMobileStepper = styled(MobileStepper)({
    '& .MuiMobileStepper-dotActive': {
        backgroundColor: Colors.darkNavy,
    },
});
