import React, { useState } from 'react';
import { Grid } from '@mui/material';
import { ShiftMatch } from 'models';
import { Chip, Colors, Link, Text } from 'library';
import moment from 'moment';

export function MatchStatusChips({ matches }: { matches: ShiftMatch[] }) {
    const numMatchesToShow = 3;
    const [showAllMatches, setShowAllMatches] = useState(false);

    const shownMatches = showAllMatches ? matches : matches.slice(0, numMatchesToShow);

    const getMatchChipStyle = (match: ShiftMatch) => {
        const now = moment();
        if (match.accepted_at && ['ON_STAFF', 'APPROVAL_NEEDED'].includes(match.pairing_status ?? '')) {
            return {
                label: 'Accepted',
                color: Colors.turquoise,
            };
        }

        if (
            match.declined_at ||
            match.worker_booked ||
            ['SITTER_CANCELLED', 'APPLIED_CANCELLED'].includes(match.pairing_status ?? '')
        ) {
            return {
                label: 'Unavailable',
                color: Colors.error,
            };
        }

        if (moment(match.expires_at).isBefore(now)) {
            return {
                label: 'No response',
                color: Colors.red,
            };
        }
        return {
            label: 'Pending',
            color: Colors.coral,
        };
    };

    return matches && matches.length > 0 ? (
        <Grid item container direction="column">
            <Text variant="caption" bold>
                Requested Worker(s)
            </Text>
            {shownMatches.map((match) => (
                <Grid key={match.matched_worker.id} item container direction="row" justifyContent="space-between">
                    <Text>
                        {match.matched_worker.first_name} {match.matched_worker.last_name}
                    </Text>
                    <Chip
                        style={{ minHeight: 20, height: 20, width: 130 }}
                        backgroundColor={getMatchChipStyle(match).color}
                        label={getMatchChipStyle(match).label}
                        textColor={Colors.white}
                    />
                </Grid>
            ))}
            {!showAllMatches && matches.length > numMatchesToShow && (
                <Link onClick={() => setShowAllMatches(true)}>view {matches.length - numMatchesToShow} more</Link>
            )}
            {showAllMatches && <Link onClick={() => setShowAllMatches(false)}>Hide</Link>}
        </Grid>
    ) : null;
}
