export default {
    defaultButtonStyle: {
        justifyContent: 'center',
        alignItems: 'center',
        textTransform: 'none',
        borderRadius: '70px',
        paddingTop: 5,
        paddingBottom: 5,
        paddingLeft: 30,
        paddingRight: 30,
        boxShadow: '0px 3px 6px #807E7D80',
        width: '100%',
    },
    defaultTextStyles: {
        fontSize: 16,
        fontFamily: 'Outfit',
    },
};
