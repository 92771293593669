import React, { useState } from 'react';
import { TableCell } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Checkbox, OnProbationChip } from 'library';
import { AdminListProvider, BusinessJobWithAppointments, LeanUser } from 'models';
import AddProvider from '../AddProvider';
import SendMatch from './SendMatch';
import ProviderBookings from './ProviderBookings';
import ProviderStatsCell from 'internal/shared/ProviderStatsCell';
import ProviderDetailsOutreachModal from 'internal/texting/outreach/ProviderDetailsOutreachModal';
import Row from 'internal/shared/tables/Row';
import OutstandingMatches from './OutstandingMatches';
import WorkerQualificationsCell from './ReverseMatching/components/WorkerQualificationsCell';
import AvailabilityCell from './ReverseMatching/components/AvailabilityCell';

export default function MatchingRow({
    provider,
    job,
    refreshJobs,
    userSelected,
    setSelectedUser,
    week,
}: {
    provider: AdminListProvider;
    job: BusinessJobWithAppointments;
    refreshJobs: () => void;
    userSelected: boolean;
    setSelectedUser: (user: LeanUser) => void;
    week?: Date | undefined;
}) {
    const user = provider.user;
    const [isModal, setIsModal] = useState(false);

    return (
        <>
            <Row>
                <TableCell sx={{ display: 'flex', flexDirection: 'row' }}>
                    <Checkbox
                        label=""
                        checked={userSelected}
                        onChange={() => setSelectedUser(user)}
                        style={{ width: 'auto' }}
                    />
                    <ProviderStatsCell
                        user={user}
                        providerStats={provider.user_stats}
                        isModal={isModal}
                        setIsModal={setIsModal}
                        vertical
                        lastLogin={provider.last_login}
                    />
                    {!!job?.user_on_probation && (
                        <ProbationWrapper>
                            <OnProbationChip />
                        </ProbationWrapper>
                    )}
                    <OutstandingMatches provider={provider} />
                </TableCell>
                <AvailabilityCell provider={provider} />
                <TableCell>
                    <ProviderBookings userId={provider.user.id} week={week} job={job} />
                </TableCell>
                <WorkerQualificationsCell provider={provider} />
                <TableCell>
                    <AddProvider job={job} refreshJobs={refreshJobs} initialProviderUserId={user.id} />
                    <SendMatch job={job} initialProviders={[provider.user]} refreshJobs={refreshJobs} />
                </TableCell>
            </Row>
            <ProviderDetailsOutreachModal providerUserId={user.id} onClose={() => setIsModal(false)} open={isModal} />
        </>
    );
}

const ProbationWrapper = styled('div')({
    marginRight: 8,
});
