import { IPayFormFields } from 'parent-portal/forms/JobRequestTypes';
const formatNumber = new Intl.NumberFormat().format;

export default function getPayString(paySettings: IPayFormFields, isQuickFill: boolean | null) {
    const { payMin, payMax, isFixedPay } = paySettings;

    const payIsNotRange = isQuickFill || payMin === payMax || isFixedPay;
    const suffix = isFixedPay ? ' Total' : '/hr';
    const dollarAmountString = payIsNotRange ? singlePayString(paySettings) : rangedPayString(paySettings);

    return `${dollarAmountString}${suffix}`;
}

function rangedPayString({ payMin, payMax }: { payMin: number; payMax: number }) {
    return payMin === payMax ? `$${payMin}` : `$${payMin} - ${payMax}`;
}

function singlePayString({
    isFixedPay,
    payFixed,
    payMin,
}: {
    isFixedPay: boolean | null;
    payFixed: number | null;
    payMin: number;
}) {
    const actualPay = isFixedPay ? payFixed : payMin;
    return `$${formatNumber(actualPay || 0)}`;
}
