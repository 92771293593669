import { AdminListProvider, BusinessJobWithUser, LeanUser } from 'models';
import { AppointmentModel } from 'parent-portal/payments/Models/PairingList';
import { DaysOfWeek } from 'shared/Dates';

export interface ApiTimeslot {
    id: number;
    timeslot: { lower: string; upper: string; bounds: string };
    comment: string;
    deleted: boolean;
    user: number;
    week: number;
    booked: boolean;
}

export interface PostApiSubAvailability {
    id: number;
    start_date: string;
    timeslots: PostApiTimeslot[];
}

export enum TimeslotState {
    DEFAULT = 'DEFAULT',
    UNSAVED = 'UNSAVED',
    SAVED = 'SAVED',
    DELETED = 'DELETED',
}
export interface PostApiTimeslot {
    id: number;
    lower: Date;
    upper: Date;
    comment: string | null;
    booked: boolean;
    state: TimeslotState;
    week: number;
}

export interface SubAvailability {
    id: number;
    user: {
        first_name: string;
        last_name: string;
        phone_number: string;
        id: number;
    };
    start_date: string;
    timeslots: ApiTimeslot[];
    unavailable: boolean;
    hidden: boolean;
}

export interface IBusiness {
    id: number;
    name: string;
}

export interface Sub {
    provider?: AdminListProvider;
    user: {
        first_name: string;
        last_name: string;
        phone_number: string;
        id: number;
    };
    thisWeek?: Week;
}

export interface Timeslot {
    id: number;
    date: string;
    timeslot: string;
    comment: string | null;
    booked: boolean;
    week: number;
}

export interface Week {
    hidden: boolean;
    unavailable: boolean;
    id: number;
    startDate: string;
    days: { [key in DaysOfWeek]: Timeslot[] };
}

export interface BaseMatch {
    id: number;
    matched_worker: number;
    expires_at: string;
    accepted_at: string;
    declined_at: string;
    appointment: AppointmentModel;
    pay_rate: number;
    added_at: string;
    exclusive: boolean;
    hiring_group_opened_at: string | null;
    created_by: LeanUser;
}

export interface JobMatch extends Omit<BaseMatch, 'matched_worker'> {
    matched_worker: AdminListProvider;
    is_preferred?: boolean;
}

export interface ProviderMatch extends Omit<BaseMatch, 'appointment'> {
    appointment: ProviderMatchAppointment;
}

interface ProviderMatchAppointment extends Omit<AppointmentModel, 'ongoing_request'> {
    ongoing_request: BusinessJobWithUser;
}

export interface JobsSearchFilters {
    page?: number;
    jobType?: JobType;
    page_size?: number;
    sort?: SortBy;
    includeClosed?: boolean;
    hasOpenShifts?: boolean;
    jobId?: string;
    businessName?: string;
    bookedWorkerId?: string;
    bookedWorkerName?: string;
    needsEmailSent?: boolean;
    needsBackgroundCheck?: boolean;
    commandF?: string;
    bookedOnly?: boolean;
    unfilledToday?: boolean;
    workerCancelledToday?: boolean;
    centerPostedToday?: boolean;
}

export enum SortBy {
    ADDED_AT = 'added-at',
    LOCATION = 'location',
    NOT_CLOCKED_IN = 'not-clocked-in',
    NOT_CONFIRMED = 'not-confirmed',
    URGENT = 'urgent',
}

export enum JobType {
    ALL = 'ALL',
    SUBSTITUTE = 'SUBSTITUTE',
    TRIAL_RUN = 'TRIAL_RUN',
}
