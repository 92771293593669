import { Grid, useMediaQuery } from '@mui/material';
import React from 'react';
import { Highlight, Text, Colors } from 'library';
import DashboardPicture from 'assets/images/marketing-site/DashboardOverview.png';
import theme from 'theme';

export default function DashboardOverview() {
    const smDown = useMediaQuery(theme.breakpoints.down('sm'));
    const width = screen.width;
    return (
        <Grid xs={12} style={{ gap: 40, padding: 40 }} container item>
            <Grid xs={12} item container flexDirection="row" justifyContent="center" style={{ gap: 40 }}>
                <Text variant="display">
                    <Highlight color={Colors.lightTurq}>
                        Pre-screened, compliant workers can view your shift and apply.
                    </Highlight>
                </Text>
            </Grid>
            <Grid container item flexDirection="row" justifyContent="center" style={{ marginTop: 20 }}>
                <img src={DashboardPicture} height={smDown ? 300 : 700} width={smDown ? width * 0.9 : 1000} />
            </Grid>
            <Grid xs={12} item container flexDirection="row" justifyContent="center" style={{ gap: 40 }}>
                <Text variant="display">
                    <Highlight color={Colors.lightBlue}>
                        And Tandem confirms the best fit worker for the role.
                    </Highlight>
                </Text>
            </Grid>
        </Grid>
    );
}
