import { StripeKey } from 'environmentVariables';
import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { Elements, injectStripe, StripeProvider } from 'react-stripe-elements';

type StripeContextType = {
    stripe: stripe.Stripe;
    elements: stripe.elements.Elements;
    stripeLoaded: boolean;
};

type StripeContextProps = {
    children?: ReactNode | ReactNode[];
    stripe: stripe.Stripe;
    elements: stripe.elements.Elements;
};

export const StripeContext = createContext<StripeContextType>(undefined as any);

function InternalStripeContextProvider({ children, stripe, elements }: StripeContextProps) {
    const [stripeLoaded, setStripeLoaded] = useState(false);

    useEffect(() => setStripeLoaded(!!stripe), [stripe]);

    const context = {
        stripe,
        elements,
        stripeLoaded,
    };

    return <StripeContext.Provider value={context as any}>{children}</StripeContext.Provider>;
}

const InjectedContextProvider = injectStripe(InternalStripeContextProvider) as React.ComponentType<{
    children?: ReactNode | ReactNode[];
}>;

export function StripeContextProvider({ children }: { children?: ReactNode | ReactNode[] }) {
    return (
        <StripeProvider apiKey={StripeKey || ''}>
            <Elements>
                <InjectedContextProvider>{children}</InjectedContextProvider>
            </Elements>
        </StripeProvider>
    );
}

export function useStripe() {
    return useContext(StripeContext);
}
