import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import { Text } from 'library';

export default function AccountMenuItem({
    icon,
    action,
    text,
}: {
    icon: React.ReactNode;
    action: () => void;
    text: string;
}) {
    return (
        <MenuItem onClick={action} disableRipple>
            {icon}
            <Text variant="body1" bold textStyle={{ marginLeft: 8 }}>
                {text}
            </Text>
        </MenuItem>
    );
}
