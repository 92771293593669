import React, { useState } from 'react';
import { Text, Colors, PrimaryButton } from 'library';
import { Grid } from '@material-ui/core';
import JobCardList from './JobCardList';
import Past from '../past/Past';
import { useUserContext } from 'UserContext';
import ConnectPaymentModal from 'parent-portal/payments/Components/ConnectPaymentModal';
import { useWidth } from 'reusableComponents/useWidth';
import usePublicSessionTracking from 'parent-portal/shared/PublicSessionTracking';

type SelectionType = 'Upcoming' | 'Completed' | 'Job Posts';

const UpcomingJobsPage = () => {
    const [selection, setSelection] = useState<SelectionType>('Upcoming');
    const [paymentSetupModalOpen, setPaymentSetupModalOpen] = useState(false);
    const { user, role } = useUserContext();
    const width = useWidth();
    const smallScreen = ['xs', 'sm'].includes(width);
    const { usePageLoadTracking } = usePublicSessionTracking('Upcoming Jobs');
    const isBusiness = role === 'business_active';

    usePageLoadTracking();

    const openSetupModal = () => {
        setPaymentSetupModalOpen(true);
    };

    const familyMenuItems: { name: SelectionType; track: string; display: React.JSX.Element }[] = [
        {
            name: 'Upcoming',
            track: 'User viewed upcoming jobs',
            display: <JobCardList isBusiness={isBusiness} />,
        },
        { name: 'Completed', track: 'User viewed completed jobs', display: <Past /> },
    ];

    const paymentSetupNeeded =
        (!!user?.payment_information && user?.payment_information.length <= 0) || user?.customer_id === null;

    return (
        <Grid container justify="center" style={{ paddingTop: 30, paddingBottom: 30, overflow: 'scroll', flex: 1 }}>
            <Grid xs={11}>
                <Grid container direction="row">
                    <Text variant="display">Jobs</Text>
                    {paymentSetupNeeded ? (
                        <>
                            <PrimaryButton
                                buttonStyle={{
                                    backgroundColor: Colors.turquoise,
                                    width: 'fit-content',
                                    marginLeft: smallScreen ? 0 : 10,
                                    marginTop: smallScreen ? 10 : 0,
                                }}
                                onClick={openSetupModal}
                            >
                                Set up TandemPay
                            </PrimaryButton>
                            <ConnectPaymentModal
                                isOpen={paymentSetupModalOpen}
                                onClose={() => setPaymentSetupModalOpen(false)}
                                fromRoute="DashboardUpcomingJobsButton"
                            />
                        </>
                    ) : null}
                </Grid>
                {!isBusiness && (
                    <Grid
                        style={{ marginTop: 50 }}
                        container
                        direction="row"
                        lg={5}
                        md={12}
                        xs={12}
                        justify="space-between"
                    >
                        {familyMenuItems.map((i) => (
                            <Grid key={i.name} container direction="column" xs={6} md={4}>
                                <Text
                                    onClick={() => {
                                        if (i.name === selection) return;
                                        setSelection(i.name);
                                    }}
                                    variant="h1"
                                    bold
                                    textStyle={{
                                        cursor: 'pointer',
                                    }}
                                >
                                    {i.name}
                                </Text>

                                {selection === i.name ? (
                                    <div
                                        style={{
                                            borderBottom: `4px solid ${Colors.darkNavy}`,
                                            marginTop: 5,
                                            width: 120,
                                        }}
                                    />
                                ) : null}
                            </Grid>
                        ))}
                    </Grid>
                )}
            </Grid>
            {
                <Grid xs={11} md={11} style={{ marginTop: 10 }} justify="flex-start">
                    {isBusiness ? (
                        <JobCardList isBusiness={isBusiness} />
                    ) : (
                        familyMenuItems.find((i) => i.name === selection)?.display
                    )}
                </Grid>
            }
        </Grid>
    );
};

export default UpcomingJobsPage;
