import React from 'react';
import { Grid, Box } from '@material-ui/core';
import LabelSelection from 'internal/shared/tables/LabelSelection';
import { Label } from 'models';

export function FilterLabelSection({
    selectedLabels,
    setSelectedLabels,
}: {
    selectedLabels: Label[];
    setSelectedLabels: (labels: Label[]) => void;
}) {
    return (
        <Grid item xs={12}>
            <Box style={{ marginTop: '3%', height: '40vh', overflow: 'scroll' }}>
                <LabelSelection
                    type="admin-providers"
                    selectedLabels={selectedLabels}
                    setSelectedLabels={setSelectedLabels}
                    columns={2}
                />
            </Box>
        </Grid>
    );
}
