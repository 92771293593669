import React from 'react';
import { Grid, Button } from '@material-ui/core';
import { ExpandMore as ExpandMoreIcon, ExpandLess as ExpandLessIcon } from '@material-ui/icons';
import { Colors } from 'library';
import { AnchorButtonProps } from '../../Models/PairingList';

const AnchorButton = ({ onPayOpen, pairing, itemEl, isPairing, buttonText, opacity }: AnchorButtonProps) => {
    return (
        <Button
            style={{
                fontWeight: 'bold',
                color: 'white',
                backgroundColor: Colors.darkNavy,
                width: '120px',
                outline: 'none',
                borderRadius: 70,
                textTransform: 'none',
                opacity: opacity ? opacity : 1,
            }}
            onClick={(e) => onPayOpen(e, pairing, itemEl, isPairing)}
        >
            <Grid item xs={9}>
                {buttonText}
            </Grid>
            <Grid item xs={2}>
                {pairing.anchor ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </Grid>
        </Button>
    );
};

export default AnchorButton;
