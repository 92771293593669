import React from 'react';
import { Grid, useMediaQuery } from '@mui/material';
import { Text, PrimaryButton, Colors, DownloadTheAppButtons } from 'library';
import { useHistory } from 'react-router-dom';
import Teacher from 'assets/images/on-demand/teacher-child.png';
import BlueLines from 'assets/images/on-demand/blue-lines-mobile.png';
import MobileTeacher from 'assets/images/on-demand/teacher-child-mobile.png';
import ProviderBullets from './ProviderBullets';
import theme from 'theme';

const title = 'Child Care Workers';
const subtitle = 'Meaningful work on your time, with ';
const subtitleEnd = 'daily pay';

export default function WorkersSection() {
    const history = useHistory();
    const mdDown = useMediaQuery(theme.breakpoints.down('md'));

    const styles = {
        container: {
            padding: mdDown ? 20 : 80,
            marginTop: 40,
        },
        button: {
            marginTop: 20,
            width: undefined,
        },
    };

    function GetStartedButton() {
        return (
            <PrimaryButton buttonStyle={styles.button} onClick={() => history.push('/providers/home')}>
                Get Started
            </PrimaryButton>
        );
    }

    return (
        <Grid container direction="row" style={styles.container}>
            {mdDown ? (
                <Grid container justifyContent="space-between" style={{ paddingTop: 30 }}>
                    <Grid item xs={7} style={{ padding: 10, marginTop: 20 }}>
                        <Text variant="display" textStyle={{ fontSize: 28 }}>
                            {title}
                        </Text>
                        <Text variant="h2" bold textStyle={{ marginTop: 10 }}>
                            {subtitle}
                            <Text color={Colors.terraCotta} inline bold>
                                {subtitleEnd}
                            </Text>
                            .
                        </Text>
                    </Grid>

                    <Grid item xs={5} style={{ position: 'relative' }}>
                        <div style={{ position: 'absolute', top: '-30%', left: '-30%' }}>
                            <img src={BlueLines} alt="" width="50%" />
                        </div>
                        <img src={MobileTeacher} alt="teacher and child" width={'85%'} style={{ maxWidth: 240 }} />
                    </Grid>
                </Grid>
            ) : null}
            {mdDown ? null : (
                <Grid
                    container
                    item
                    md={6}
                    style={{ width: '100%', position: 'relative', maxWidth: 600, marginTop: -50 }}
                >
                    <img
                        src={Teacher}
                        alt="teacher and child"
                        width="100%"
                        height="auto"
                        style={{ position: 'absolute', objectFit: 'cover' }}
                    />
                    <div
                        style={{
                            backgroundColor: 'transparent',
                            zIndex: 999,
                            position: 'absolute',
                            left: 100,
                            maxWidth: 360,
                            bottom: 50,
                        }}
                    >
                        <Text variant="display" textStyle={{ marginTop: 15 }}>
                            {title}
                        </Text>
                        <GetStartedButton />
                    </div>
                </Grid>
            )}
            <Grid
                item
                md={6}
                style={{
                    padding: mdDown ? 20 : 40,
                    marginLeft: mdDown ? 0 : mdDown ? -75 : -150,
                    zIndex: 999,
                }}
            >
                {mdDown ? null : (
                    <Text variant="display">
                        {subtitle}
                        {subtitleEnd}.
                    </Text>
                )}
                <Text variant={mdDown ? 'body1' : 'h2'} textStyle={{ marginTop: mdDown ? 10 : 30, marginBottom: 20 }}>
                    Our network of child care centers allows you to find work on your own time and form relationships
                    with centers without upfront commitment.
                </Text>
                <Grid>
                    <ProviderBullets smDown={mdDown} />
                </Grid>
                {mdDown ? <GetStartedButton /> : null}
            </Grid>
        </Grid>
    );
}
