import React from 'react';
import { Grid } from '@material-ui/core';
import { Colors } from '../../../constants';

const styles = {
    chipStyle: {
        backgroundColor: Colors.mediumGreen,
        height: 24,
        borderRadius: 80,
        padding: '2px 5px',
        color: Colors.darkNavy,
        margin: '0 3px',
        display: 'flex',
        alignItems: 'center',
    },
    textStyle: {
        fontFamily: 'Work Sans',
        fontWeight: 800,
        fontSize: '12px',
        lineHeight: 'normal',
    },
};

export default function NewChip() {
    return (
        <Grid style={styles.chipStyle}>
            <span style={styles.textStyle}>NEW!</span>
        </Grid>
    );
}
