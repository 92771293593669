import React from 'react';
import { Menu as MaterialMenu } from '@mui/material';

interface Option {
    text: string;
    onClick?: () => void;
    icon?: React.ReactNode;
    disabled?: boolean;
}

export default function UnformattedMenu({
    children,
    open,
    onClose,
    anchorEl,
}: {
    children: React.ReactNode;
    open: boolean;
    onClose: () => void;
    anchorEl: HTMLElement | null;
}) {
    return (
        <MaterialMenu
            sx={{
                '& .MuiMenu-paper': {
                    borderRadius: '8px',
                },
                '& .MuiMenu-list': {
                    padding: '8px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                },
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={onClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
        >
            {children}
        </MaterialMenu>
    );
}
