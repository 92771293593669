import React, { Component } from 'react';
import { Pagination } from '@material-ui/lab';

export default class CustomPagination extends Component {
    constructor(props) {
        super(props);
        this.state = {
            content: this.props.content,
            pageSize: this.props.pageSize,
        };
    }

    changePage(page_number) {
        const { content, pageSize } = this.state;
        const clonedContent = [...content];
        this.props.showNewContent(clonedContent.slice((page_number - 1) * pageSize, page_number * pageSize));
    }

    render() {
        return (
            <Pagination
                count={this.props.pageCount}
                color="primary"
                onChange={(event, page) => this.changePage(page)}
                style={{ marginTop: 10 }}
                boundaryCount={2}
            />
        );
    }
}
