import React from 'react';
import { BasicDialog, PrimaryButton } from 'library';
import { deleteForm } from 'api/AdminDocumentApi';

export default function DeleteModal({
    deleteModalOpen,
    setDeleteModalOpen,
    form,
    refresh,
}: {
    deleteModalOpen: boolean;
    setDeleteModalOpen: (deleteModalOpen: boolean) => void;
    form?: { id: number; text?: string; name?: string };
    refresh?: () => void;
}) {
    const formName = form?.text || form?.name || '';
    return (
        <BasicDialog closeButton isOpen={deleteModalOpen} onClose={() => setDeleteModalOpen(false)}>
            <PrimaryButton
                buttonStyle={{ marginTop: 20, marginBottom: 20 }}
                onClick={() => {
                    deleteForm(
                        form?.id,
                        formName,
                        refresh
                            ? () => {
                                  refresh();
                                  setDeleteModalOpen(false);
                              }
                            : () => setDeleteModalOpen(false),
                    );
                }}
            >
                Delete
            </PrimaryButton>
        </BasicDialog>
    );
}
