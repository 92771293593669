import React, { useContext } from 'react';
import { Colors, Text } from 'library';
import { styled } from '@mui/material';

import { BannedWorkerModalContext } from './BannedWorkerModalContext';
import { ShiftProviderUser } from 'models';

export default function Header() {
    const { provider }: { provider: ShiftProviderUser } = useContext(BannedWorkerModalContext);

    return (
        <Container>
            <Text variant="caption" textStyle={{ marginBottom: 10 }}>
                You can block/unblock workers business wide or at specific locations using the options below. By
                blocking this worker, you will be removing them from any future shifts as well. Tandem will then reopen
                those shifts to our sub pool.
            </Text>
            <Text>
                Block{' '}
                <Text inline variant="h2" textStyle={{ color: Colors.turquoise }}>
                    {provider.first_name} {provider.last_name}
                </Text>{' '}
                from:
            </Text>
        </Container>
    );
}

const Container = styled('div')({
    paddingBottom: '2em',
});
