import { withStyles } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';

const StyledIconButton = withStyles({
    root: {
        padding: 0,
        '&:hover': {
            outline: 'none',
        },
        '&:focus': {
            outline: 'none',
        },
    },
})(IconButton);

export default StyledIconButton;
