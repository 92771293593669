import * as Sentry from '@sentry/react';

interface IConfig {
    shouldReconnect?: () => boolean;
    reconnectAttempts?: number;
    heartbeat?: {
        message?: string;
        returnMessage?: string;
        interval?: number;
    };
    share?: boolean;
    onError?: (event: WebSocketEventMap['error']) => void;
    retryOnError?: boolean;
    filter?: (message: WebSocketEventMap['message']) => boolean;
}

export const socketConfig = (config: IConfig) => {
    const defaultConfig = {
        shouldReconnect: () => true,
        reconnectAttempts: 3,
        heartbeat: {
            message: JSON.stringify({ message: '__ping__' }),
            returnMessage: JSON.stringify({ message: '__pong__' }),
            interval: 45000,
        },
        share: true,
        onError: (event: WebSocketEventMap['error']) => Sentry.captureException(event),
        retryOnError: true,
        filter: (message: WebSocketEventMap['message']) => {
            const msg = JSON.parse(message.data).message;
            if (msg === '__pong__') return false;
            return true;
        },
    };
    Object.assign(defaultConfig, config);
    return defaultConfig;
};
