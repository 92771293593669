import React, { useEffect, useState } from 'react';
import { Provider } from 'models';
import { Text, Colors } from 'library';
import ProviderProfileTitledContainer from 'parent-portal/provider-profile/ProviderProfileTitledContainer';
import { client } from 'shared';
import { isLoggedIn } from 'api/UserApi';
import SafetyCheckInfo from './BackgroundCheck';
import moment from 'moment';
import { useUserContext } from 'UserContext';

interface BackgroundCheck {
    title: string;
    price: string;
    code: string;
    description: string;
    accepted_criminal_check: boolean;
}

export default function BackgroundChecks({ provider }: { provider: Provider }) {
    const [criminalChecks, setCriminalChecks] = useState<BackgroundCheck[]>([]);
    const [addOnChecks, setAddOnChecks] = useState<BackgroundCheck[]>([]);
    const { role } = useUserContext();

    useEffect(getChecks, []);

    function getChecks() {
        client('api/purchase-item/').then((result: BackgroundCheck[]) => {
            setCriminalChecks(
                result.filter(
                    (check) =>
                        check.accepted_criminal_check &&
                        (role === 'business_active' || check.code !== 'OCCRRA' || getLatestToken(check, 'COMPLETED')),
                ),
            );
            setAddOnChecks(result.filter((check) => !check.accepted_criminal_check));
        });
    }

    function getLatestToken(checkType: BackgroundCheck, status: string) {
        let completed = provider.user.background_checks?.filter(
            (check) => check.check_type === checkType.code && check.status === status,
        );
        if (completed && completed.length > 0) {
            completed?.sort((a, b) =>
                moment(b.status_updated_at, 'MM-DD-YYYY hh:mm A').diff(
                    moment(a.status_updated_at, 'MM-DD-YYYY hh:mm A'),
                ),
            );
            return completed && completed.length > 0 ? completed[0] : null;
        }
        return null;
    }

    function Section({
        checks,
        title,
        description,
    }: {
        checks: BackgroundCheck[];
        title: string;
        description: string;
    }) {
        return checks.length > 0 ? (
            <>
                <Text variant="body1" bold textStyle={{ marginBottom: 12 }}>
                    {title}
                </Text>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <Text variant="body2" color={Colors.mediumGrey} textStyle={{ marginBottom: 12 }}>
                        {description}
                    </Text>
                    {checks.map((check) => (
                        <SafetyCheckInfo
                            provider={provider}
                            check={check}
                            token={getLatestToken(check, 'COMPLETED')}
                            loggedIn={isUserLoggedIn}
                            pendingToken={getLatestToken(check, 'PENDING')}
                        />
                    ))}
                </div>
            </>
        ) : null;
    }

    const isUserLoggedIn = isLoggedIn();

    return (
        <ProviderProfileTitledContainer title="Safety Checks">
            <Section
                checks={criminalChecks}
                title="Criminal Background Checks"
                description={`We offer ${criminalChecks.length} levels of criminal background checks. Each provider must complete a criminal background check when they are first hired. If you hire ${provider.user.first_name}, and they have not yet completed a criminal check, Tandem will automatically trigger the Basic check at no cost, but you may choose to reimburse a candidate for a background check before hire.`}
            />
            <div
                style={{
                    backgroundColor: Colors.darkNavy,
                    height: 5,
                    width: '100%',
                    marginBottom: 20,
                    marginTop: 20,
                }}
            />
            <Section
                checks={addOnChecks}
                title="Add-On Safety Checks"
                description="Providers can pay to add these safety checks to their profile. You can encourage candidates to purchase these checks or offer to reimburse the provider for them if you choose."
            />
        </ProviderProfileTitledContainer>
    );
}
