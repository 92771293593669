import { Colors, Text } from 'library';
import React from 'react';
import {CardItemType} from "./PaymentOptions";
import ChipWithIcon from "./Components/ChipWithIcon";

export default function PaymentChoices({
    icon,
    title,
    subtitle,
    highlight,
    listItems,
    cardItems,
    id,
}: {
    title: string;
    highlight?: string;
    subtitle?: string;
    icon?: React.ReactNode;
    listItems?: string[];
    cardItems?: CardItemType[];
    id?: string;
}) {
    return (
        <div
          id={id}
          style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: 10,
              padding: 10,
              borderRadius: 10,
          }}
        >
            <div style={{
                marginLeft: 10,
                textAlign: 'left',
                display: 'flex',
                flexDirection: 'column',
                gap: 12,
                alignItems: 'flex-start',
                paddingTop: 8,
                paddingBottom: 8,
            }} >
                <div style={{ width: '100%'}}>{icon}</div>
                <div>
                    <Text variant="h1" bold textStyle={{ color: Colors.turquoise, textAlign: 'left', marginBottom: 8 }}>
                        {title}
                    </Text>
                    <Text variant="body1" textStyle={{ textAlign: 'left' }}>
                        {subtitle}
                    </Text>
                </div>
                {highlight ? (
                    <div style={{ minHeight: 80 }}>
                        <Text
                            variant="body2"
                            inline
                            bold
                            color={Colors.turquoise}
                            textStyle={{ marginBottom: 20, textAlign: 'left'}}
                        >
                            {highlight}
                        </Text>
                    </div>
                ) : null}
                {listItems ? (
                    <ul style={{ paddingInlineStart: 0 }}>
                        {listItems.map((l: string) => (
                            <li key={l}>
                                <Text variant="caption" textStyle={{ textAlign: 'left' }}>{l}</Text>
                            </li>
                        ))}
                    </ul>
                ) : null}

                {cardItems
                    ? cardItems.map((c) => (
                        <ChipWithIcon text={c.text} key={c.text} icon={c.icon} />
                      ))
                    : null}
            </div>
        </div>
    );
}
