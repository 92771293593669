import React from 'react';
import { Grid, Typography } from '@material-ui/core';

import Button from 'reusableComponents/button';

import { useHistory } from 'react-router-dom';
export default function PaymentNotSetup({ providerName }: { providerName: string | undefined }) {
    const history = useHistory();
    return (
        <Grid item>
            <Typography variant="subtitle1">
                To message {providerName}, set up your payment information first.
            </Typography>
            <Grid item style={{ marginTop: 5 }}>
                <Typography variant="subtitle1">
                    <b>Why set up payment?</b>
                </Typography>
                <Typography variant="subtitle2">
                    TandemPay allows you to quickly pay providers for free through a seamless, secure platform.
                    Providers receive 100% of their earnings.Adding a form of payment allows you to directly communicate
                    with applicants but does not commit you to using a Tandem provider.
                </Typography>
                <Button onClick={() => history.push('/setup')} style={{ marginTop: 10 }}>
                    <Typography>Set Up Payment</Typography>
                </Button>
            </Grid>
        </Grid>
    );
}
