import React, { useEffect, useState } from 'react';
import { Send } from '@mui/icons-material';

import { Note } from 'models';
import { Checkbox, IconButton, OutlinedTextInput } from 'library';
import { client, consoleLogInDev } from 'shared';
import NoteView from './NoteView';

export type NotesObjectType =
    | 'business'
    | 'ongoing'
    | 'applicants'
    | 'interview'
    | 'admin-providers'
    | 'matching-inbox'
    | 'business-location'
    | 'auto-verify';

export default function NotesModal({
    type,
    objectId,
    lastFetchDate,
}: {
    type: NotesObjectType;
    objectId: number;
    lastFetchDate?: Date;
}) {
    const [notes, setNotes] = useState<Note[]>([]);
    const [text, setText] = useState<string>();
    const [includeRelated, setIncludeRelated] = useState(false);

    const getNotes = () => {
        client(`api/${type}/${objectId}/notes/?include_related=${includeRelated}`)
            .then(setNotes)
            .catch(consoleLogInDev);
    };

    const submit = () => {
        if (text) {
            client(`api/${type}/${objectId}/notes/`, { body: { text } })
                .then(() => {
                    setText('');
                    getNotes();
                })
                .catch(consoleLogInDev);
        }
    };
    useEffect(getNotes, [objectId, type, lastFetchDate, includeRelated]);

    const showRelatedCheckbox = type === 'business' || type === 'business-location';

    return (
        <>
            {showRelatedCheckbox && (
                <Checkbox label="Include Related" checked={includeRelated} onChange={(val) => setIncludeRelated(val)} />
            )}
            <OutlinedTextInput
                className="notDraggable"
                style={{ padding: 10 }}
                multiline
                fullWidth
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setText(e.target.value)}
                value={text}
                InputProps={{
                    endAdornment: <IconButton tooltip="Create note" icon={Send} onClick={submit} />,
                }}
            />
            {notes.map((note) => (
                <NoteView key={note.id} note={note} refresh={getNotes} hideEdit={note.editable === false} />
            ))}
        </>
    );
}
