import { CircularProgress, Grid } from '@mui/material';
import React from 'react';

export default function LoadingSpinner({ style }: { style?: React.CSSProperties }) {
    return (
        <Grid container alignItems="center" justifyContent="center" style={{ padding: 20, ...style }}>
            <Grid item xs={12}>
                <CircularProgress />
            </Grid>
        </Grid>
    );
}
