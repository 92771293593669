import React, { useState } from 'react';
import { useUserContext } from 'UserContext';

import Error from '../components/Error';
import ProviderWelcomeBack from './ProviderWelcomeBack';
import MainCopy from './MainCopy';
import MainButtons from './MainButtons';
import BanWorkerModal from 'parent-portal/shared/UserActions/BanWorkerModal';
import { MainContainer } from '../components/Containers';

import type { ShiftProviderUser } from 'models';

const LowStarProviderReview = ({ provider }: { provider: ShiftProviderUser }) => {
    const { user } = useUserContext();

    const business = user?.businesses?.[0];
    const businessId = business?.id;

    const [banAction, setBanAction] = useState(false);
    const [selectedWelcomeBack, setSelectedWelcomeBack] = useState<boolean>(false);

    if (!businessId) {
        return <Error />;
    }

    if (selectedWelcomeBack) {
        return <ProviderWelcomeBack providerFirstName={provider.first_name} businessName={business.name} />;
    }

    return (
        <MainContainer>
            <MainCopy provider={provider} business={business} />
            <MainButtons
                onWelcomeBackClick={() => {
                    setSelectedWelcomeBack(true);
                }}
                onBanClick={() => {
                    setBanAction(true);
                }}
            />
            <BanWorkerModal
                open={banAction}
                onSuccess={() => {}}
                onClose={() => setBanAction(false)}
                provider={provider}
            />
        </MainContainer>
    );
};

export default LowStarProviderReview;
