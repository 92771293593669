import downloadFile from 'downloadjs';
import makeRequest from './Request';

async function downloadFileFromResponse(response) {
    const blob = await response.blob();
    const filenameRegex = /filename="(.*)"/;
    const filename = filenameRegex.exec(response.headers.get('Content-Disposition'))[1];

    downloadFile(blob, filename, blob.type);
}

async function download(endpoint) {
    const response = await makeRequest(endpoint);

    if (response.status === 404) {
        throw new Error('Not found');
    }

    return downloadFileFromResponse(response);
}

export default download;
export { downloadFileFromResponse };
