import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';

let theme = createMuiTheme({
    props: {
        MuiUseMediaQuery: {
            noSsr: true,
        },
        MuiPopover: {
            style: {
                zIndex: 2100,
            },
        },
    },
    palette: {
        primary: {
            main: '#002F4D',
            contrastText: '#fff',
            dark: '#2f4c62',
            light: '#a9b9c4',
            navy: '#002F4D',
            aqua: '#b4e1e3',
            olive: '#809b7d',
            tangerine: '#ffbf78',
        },
        secondary: {
            main: '#00A68D',
            contrastText: '#fff',
            light: '#ffcb8d',
            cloud: '#e9f1fa',
            earth: '#968886',
            blush: '#f9b7bb',
            coral: '#ff7b67',
        },
        error: {
            main: '#FF0000',
            dark: '#a02025',
            light: '#da494e',
        },
        info: {
            main: '#ffbf78',
            dark: '#ffb966',
            light: '#ffddb4',
        },
        alternate: {
            light: '#ececec',
        },
        card: {
            applicantButton: '#E77562',
            applicantButtonHover: '#E05238',
            paymentButton: '#869C81',
            paymentButtonHover: '#63785e',
        },
        payments: {
            main: '#809d7d',
            light: '#ced9cd',
            dark: '#637f60',
        },
        coral: {
            main: '#ff7b67',
        },
        background: {
            default: '#FDFDFD',
        },
        common: {
            turquoise: '#00A68D',
        },
    },
    typography: {
        fontFamily: 'Work Sans',
        h6: {
            color: '#002F4D',
        },
        h5: {
            color: '#002F4D',
            fontWeight: 'bold',
        },
        h4: {
            color: '#002F4D',
            fontWeight: 'bold',
        },
        subtitle2: {
            color: '#002F4D',
        },
        subtitle1: {
            color: '#002F4D',
        },
        h2: {
            color: '#002F4D',
            fontWeight: 'bold',
        },
        h1: {
            color: '#002F4D',
            fontWeight: 'bold',
        },
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1280,
            xl: 1920,
        },
    },
    overrides: {
        MuiInput: {
            underline: {
                '&:after': {
                    borderBottom: `1px solid #002F4D`,
                },
                '&$focused:after': {
                    borderBottomColor: `#002F4D`,
                },
                '&$error:after': {
                    borderBottomColor: `#002F4D`,
                },
                '&:before': {
                    borderBottom: `1px solid #002F4D`,
                },
                '&:hover:not($disabled):not($focused):not($error):before': {
                    borderBottom: `1px solid #002F4D`,
                },
                '&$disabled:before': {
                    borderBottom: `1px solid #002F4D`,
                },
            },
        },
        MuiSelect: {
            select: {
                '&:focus': {
                    backgroundColor: '$labelcolor',
                },
            },
        },
    },
});

theme = responsiveFontSizes(theme);

export default theme;
