import React, { useState } from 'react';
import { styled } from '@mui/material';
import { Chip, Colors, Text } from 'library';
import { format12HourTime } from 'shared/Dates';

import { TimeChangeModal } from './TimeChangeModal';

import type { CenterInitiatedAppointmentTimeChangeRequest, WorkerInitiatedAppointmentTimeChangeRequest } from 'models';

const ColorsHashMap = {
    Pending: { background: Colors.lightTerraCotta, foreground: Colors.terraCotta },
    Accepted: { background: Colors.lightGreen, foreground: Colors.turquoise },
    Declined: { background: Colors.pink, foreground: Colors.red },
};

export default function CardRequestedTimeChange({
    type,
    timeChangeRequest,
    refresh,
}: {
    type: 'center' | 'worker';
    timeChangeRequest: CenterInitiatedAppointmentTimeChangeRequest | WorkerInitiatedAppointmentTimeChangeRequest;
    refresh: () => void;
}) {
    const colors = ColorsHashMap[`${timeChangeRequest.status}`];

    const action = timeChangeRequest.status === 'Pending' ? 'requested' : timeChangeRequest.status.toLowerCase();

    const someone = {
        center: timeChangeRequest.status === 'Pending' ? 'You' : 'A worker',
        worker: timeChangeRequest.status === 'Pending' ? 'A worker' : 'You',
    }[`${type}`];

    const title = `${someone} ${action} a time change`;

    const [open, setOpen] = useState(false);

    return (
        <Container backgroundColor={colors.background}>
            <Text variant="caption">{title}</Text>
            <InnerContainer>
                <Text variant="caption" bold>
                    {`${format12HourTime(new Date(timeChangeRequest.new_start_time))} - ${format12HourTime(
                        new Date(timeChangeRequest.new_end_time),
                    )}`}
                </Text>
                <Chip
                    label={timeChangeRequest.status}
                    backgroundColor={colors.foreground}
                    textColor={Colors.white}
                    style={{ minHeight: 10, maxHeight: 14, fontSize: 10 }}
                />
            </InnerContainer>
            {type === 'worker' && timeChangeRequest.status === 'Pending' && (
                <ButtonContainer>
                    <Text
                        variant="caption"
                        textStyle={{ textDecorationLine: 'underline', cursor: 'pointer' }}
                        onClick={() => setOpen(true)}
                    >
                        Click to accept or decline
                    </Text>
                    <TimeChangeModal open={open} setOpen={setOpen} request={timeChangeRequest} refresh={refresh} />
                </ButtonContainer>
            )}
        </Container>
    );
}

const Container = styled('div')(({ backgroundColor }: { backgroundColor: string }) => ({
    backgroundColor,
    borderRadius: 5,
    display: 'flex',
    flexDirection: 'column',
    gap: 4,
    padding: 5,
    flex: 1,
}));

const InnerContainer = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 12,
});

const ButtonContainer = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: 12,
});
