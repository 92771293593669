import React, { useState } from 'react';
import { Text, Colors } from 'library';
import { IWorkerOverallStats } from './models';
import { styled, Grid } from '@mui/material';
import { PastShiftsModal } from './PastShifts';
import { CancellationHistoryModal } from './CancellationHistory';

export default function ReliabilityStats({
    overall_stats,
    providerUserId,
}: {
    overall_stats?: IWorkerOverallStats;
    providerUserId?: number;
}) {
    const [shiftHistoryOpen, setShiftHistoryOpen] = useState(false);
    const [cancellationHistoryOpen, setCancellationHistoryOpen] = useState(false);

    function getOnTimePercentage() {
        const hour_reports = overall_stats?.hour_reports;
        if (!hour_reports) return 0;
        return Math.round((hour_reports.successful / hour_reports.total) * 100);
    }

    if (!overall_stats) return null;

    return (
        <Grid container item direction="row" justifyContent="space-between">
            <Container container item direction="column" xs={5} onClick={() => setShiftHistoryOpen(true)}>
                <Text variant="h2" bold textStyle={{ textDecoration: 'underline' }}>
                    Shifts
                </Text>
                <Text>Total: {overall_stats.shifts?.total}</Text>
                <Text>Arrived On Time: {overall_stats.hour_reports?.successful}</Text>
                <Text>On Time Percentage: {getOnTimePercentage()}%</Text>
                <Text>Confirmed: {overall_stats.confirmed?.successful}</Text>
                <Text>Repeats: {overall_stats.repeats?.count}</Text>
            </Container>
            <Container container item direction="column" xs={5} onClick={() => setCancellationHistoryOpen(true)}>
                <Text variant="h2" bold textStyle={{ textDecoration: 'underline' }}>
                    Cancellations
                </Text>

                <Text>Total: {overall_stats.cancellations?.total}</Text>
                <Text>Average Notice: {overall_stats.cancellations?.avg_notice}</Text>
            </Container>
            <PastShiftsModal
                open={shiftHistoryOpen}
                onClose={() => setShiftHistoryOpen(false)}
                providerUserId={providerUserId}
            />
            <CancellationHistoryModal
                open={cancellationHistoryOpen}
                onClose={() => setCancellationHistoryOpen(false)}
                providerUserId={providerUserId}
            />
        </Grid>
    );
}

const Container = styled(Grid)({
    backgroundColor: Colors.lightTurq,
    padding: 20,
    borderRadius: 18,
    cursor: 'pointer',
});
