import React, { useEffect, useState } from 'react';
import { BasicDialog, Checkbox, Colors, Link, OutlinedTextInput, PrimaryButton, Text } from 'library';
import { BusinessJobWithAppointments, IPairing, LeanUser, SubJobAppointment } from 'models';

import { format12HourTime, formatMonthDay, formatShortWeekdayMonthDay } from 'shared/Dates';
import { client, consoleLogInDev, queryStringifyObject } from 'shared';

import { Add } from '@mui/icons-material';
import { addHours, compareAsc, formatISO, roundToNearestMinutes, subHours, differenceInCalendarDays } from 'date-fns';
import { getLink } from 'internal/business/jobLink';
import _ from 'lodash';
import { Grid } from '@material-ui/core';
import ProviderOutlawedWarning from '../ProviderOutlawedWarning';
import { allAppointmentsForSlots, getProviderBookings } from './matchingListApi';
import { getTodayStart } from '../weekdayConstants';

export default function SendMatchModal({
    initialJob,
    jobId,
    open,
    onClose,
    initialProviders,
    initialEndDate,
}: {
    initialJob?: BusinessJobWithAppointments;
    jobId: number;
    open: boolean;
    onClose: () => void;
    initialProviders: LeanUser[];
    initialEndDate?: string;
}) {
    const [providers, setProviders] = useState<LeanUser[]>(initialProviders);
    const [userId, setUserId] = useState<number>();
    const [provider, setProvider] = useState<LeanUser>();
    const [selectedApptIds, setSelectedApptIds] = useState<number[]>([]);
    const [autoHire, setAutoHire] = useState(true);
    const [payRate, setPayRate] = useState<string>();
    const [expireHours, setExpireHours] = useState<number | null>(12);
    const [message, setMessage] = useState(getMessage());
    const [dontSendText, setDontSendText] = useState(true);
    const [job, setJob] = useState<BusinessJobWithAppointments | undefined>(initialJob);
    const [bookings, setBookings] = useState<IPairing[]>([]);
    const appointments = allAppointmentsForSlots(job?.slots ?? []);

    useEffect(() => {
        if (!initialJob) {
            //TODO: is this working?
            const query = queryStringifyObject({
                sub_start_date: getTodayStart().toISOString(),
                sub_end_date: initialEndDate, // TODO: is the format on this correct?
            });
            client(`api/admin-jobs/${jobId}/?${query}`).then(setJob).catch(consoleLogInDev);
        }
    }, []);

    useEffect(() => {
        if (job) {
            setSelectedApptIds(appointments.filter(futureOpenAppointments).map((appt) => appt.id));
            setPayRate(job.pay?.toString() ?? job.rate_min?.toString());
        }
    }, [job]);

    useEffect(() => {
        setProviders(initialProviders);
    }, [initialProviders]);

    useEffect(() => {
        if (!open || !job || !providers.length) {
            return;
        }

        const dates = appointments.map((x) => new Date(x.start_date)).sort((x, y) => x.getTime() - y.getTime());

        const startDate = formatISO(dates[0], { representation: 'date' });
        const endDate = formatISO(dates[dates.length - 1], { representation: 'date' });

        getProviderBookings(
            providers.map((x) => x.id),
            startDate,
            endDate,
            250,
        ).then((bookings) => {
            setBookings(bookings.results);
        });
    }, [open, providers]);

    function addOrRemoveProvider(add: boolean, provider: LeanUser) {
        if (add) {
            setProviders([provider, ...providers]);
        } else {
            setProviders(providers.filter((prov) => provider.id !== prov.id));
        }
    }

    function addOrRemoveAppt(add: boolean, apptId: number) {
        if (add) {
            setSelectedApptIds([apptId, ...selectedApptIds]);
        } else {
            setSelectedApptIds(selectedApptIds.filter((id) => apptId !== id));
        }
    }

    function getProvider() {
        if (userId) client(`api/users/${userId}/`).then(setProvider).catch(consoleLogInDev);
    }

    function getExpiration() {
        return roundToNearestMinutes(addHours(new Date(), expireHours!), { nearestTo: 15 });
    }

    function getMessage() {
        return `Would you like to work this job? Accept it in the app to be automatically booked. ${getLink(jobId)}`;
    }

    function save() {
        client('api/match/bulk/', {
            body: {
                message,
                send_text: !dontSendText,
                matches: _.flatten(
                    providers.map((user) =>
                        selectedApptIds.map((apptId) => ({
                            matched_worker_id: user.id,
                            appointment_id: apptId,
                            expires_at: formatISO(getExpiration()),
                            pay_rate: payRate,
                            auto_hire_on_accept: autoHire,
                        })),
                    ),
                ),
            },
        }).then(onClose);
    }

    function futureOpenAppointments(appt: SubJobAppointment) {
        return (
            ['PENDING_PAIRING', 'NO_PAIRING', 'AWAITING_SELECTION'].includes(appt.status) &&
            new Date(appt.start_date) > subHours(new Date(), 4)
        );
    }

    function getOverlaps(userId?: number) {
        const futureAppointmentDates = appointments
            .filter(futureOpenAppointments)
            .filter((x) => selectedApptIds.includes(x.id))
            .map((x) => new Date(x.start_date));

        const dates = bookings
            .map((x) => {
                if (x.user_id !== userId) {
                    return [];
                }
                const futureDates = futureAppointmentDates
                    ?.filter((y) => differenceInCalendarDays(new Date(x.appointment.start_date), y) === 0)
                    .map(() => new Date(x.appointment.start_date));
                if (!futureDates?.length) {
                    return [];
                }

                return Array.from(futureDates);
            })
            .reduce((acc, x) => [...(acc || []), ...(x || [])], []);

        return dates;
    }

    function renderWarningLabel(provider: LeanUser) {
        const overlaps = getOverlaps(provider.id);
        if (!overlaps?.length) {
            return;
        }

        return (
            <Text variant="caption" textStyle={{ color: Colors.error }}>
                {provider.first_name} {provider.last_name} is already booked for{' '}
                {overlaps.map((x) => formatMonthDay(x)).join(', ')}
            </Text>
        );
    }

    return (
        <BasicDialog closeButton isOpen={open} onClose={onClose}>
            <>
                <Text>
                    Send <b>{job?.headline}</b> match to
                </Text>
                {providers.map((provider) => (
                    <Grid key={provider.id} container item direction="row" alignItems="center" style={{ gap: 10 }}>
                        <Checkbox
                            label={`${provider.first_name} ${provider.last_name} (${provider.id})`}
                            style={{ width: 'unset' }}
                            checked={providers.some((prov) => prov.id === provider.id)}
                            onChange={(checked) => {
                                addOrRemoveProvider(checked, provider);
                            }}
                        />
                        {renderWarningLabel(provider)}

                        <ProviderOutlawedWarning
                            businesses_outlawed={provider.businesses_outlawed ?? []}
                            business_locations_outlawed={provider.business_locations_outlawed ?? []}
                            business_id={job?.business[0].id}
                            business_location_id={job?.business_location?.id}
                        />
                    </Grid>
                ))}
                <OutlinedTextInput
                    label="Add another user"
                    value={userId}
                    onChange={(e) => setUserId(parseInt(e.target.value) || undefined)}
                    onBlur={getProvider}
                    style={{ marginTop: 10, marginBottom: 10 }}
                />
                {provider && (
                    <Link
                        style={{ marginLeft: 20, lineHeight: 5 }}
                        onClick={() => {
                            addOrRemoveProvider(true, provider);
                        }}
                    >
                        <Add /> Add {provider.first_name} {provider.last_name} ({provider.id})
                    </Link>
                )}
                <Checkbox label="Auto-hire on accept" checked={autoHire} onChange={setAutoHire} />
                <Checkbox
                    label="Only send push notification (no text)"
                    checked={dontSendText}
                    onChange={setDontSendText}
                />
                <OutlinedTextInput
                    label="Pay rate"
                    type="number"
                    value={payRate}
                    onChange={(e) => setPayRate(e.target.value)}
                    style={{ marginTop: 10, marginBottom: 10, marginRight: 10 }}
                    InputProps={{ startAdornment: '$' }}
                />
                <OutlinedTextInput
                    label="Hours until expiration"
                    type="number"
                    value={expireHours}
                    onChange={(e) => setExpireHours(e.target.value ? parseInt(e.target.value) : null)}
                    style={{ marginTop: 10, marginBottom: 10 }}
                />
                <OutlinedTextInput
                    multiline
                    disabled={dontSendText}
                    style={{ width: '100%', marginBottom: 10 }}
                    label="Message"
                    onChange={(e) => setMessage(e.target.value)}
                    value={message}
                />
                {appointments
                    .filter(futureOpenAppointments)
                    .sort((apptLeft, apptRight) =>
                        compareAsc(new Date(apptLeft.start_date), new Date(apptRight.start_date)),
                    )
                    .map((appt) => (
                        <div
                            key={appt.id}
                            style={{
                                backgroundColor: Colors.lightBlue,
                                marginBottom: 10,
                                borderRadius: 18,
                                padding: 20,
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                            }}
                        >
                            <Text>
                                {formatShortWeekdayMonthDay(appt.start_date)} {format12HourTime(appt.start_date)}-
                                {format12HourTime(appt.end_date)}{' '}
                            </Text>
                            <Checkbox
                                style={{ width: undefined }}
                                checked={selectedApptIds.includes(appt.id)}
                                onChange={(checked) => {
                                    addOrRemoveAppt(checked, appt.id);
                                }}
                                label=""
                            />
                        </div>
                    )) || null}
                <PrimaryButton
                    rightAlign
                    onClick={save}
                    disabled={
                        !expireHours ||
                        !payRate ||
                        (!dontSendText && !message) ||
                        providers.length === 0 ||
                        selectedApptIds.length === 0
                    }
                >
                    Save
                </PrimaryButton>
            </>
        </BasicDialog>
    );
}
