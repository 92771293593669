import React from 'react';
import { styled } from '@mui/material';
import { PrimaryButton, Text } from 'library';

export function PreferredButton({
    preferredAll,
    preferredLocations,
    setOpenPreferredModal,
    buttonTextStyle,
    buttonStyle,
    style,
}: {
    preferredAll: boolean;
    preferredLocations: number;
    setOpenPreferredModal: (open: boolean) => void;
    buttonTextStyle?: React.CSSProperties;
    buttonStyle?: React.CSSProperties;
    style?: React.CSSProperties;
}) {
    const phrase = preferredLocations === 1 && !preferredAll ? 'location' : 'locations';

    return (
        <ButtonContainer style={style}>
            <PrimaryButton
                buttonStyle={buttonStyle}
                textStyle={buttonTextStyle}
                onClick={() => setOpenPreferredModal(true)}
            >
                Add as a Preferred Worker
            </PrimaryButton>
            <Text variant="caption">
                Currently a Preferred Worker at {preferredAll ? 'all' : preferredLocations ?? 0} {phrase}
            </Text>
        </ButtonContainer>
    );
}

const ButtonContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
});
