import { PagingResult } from 'models';
import { BusinessLocationSnoozeReason } from 'models/BusinessLocationSnoozeReason';
import { BusinessLocationStatus } from 'models/BusinessLocationStatus';
import { client } from 'shared';

export function getLocationStatuses(): Promise<PagingResult<BusinessLocationStatus>> {
    return client('internal/business-location-status/');
}

export function getLocationStaticStatuses(): Promise<BusinessLocationStatus[]> {
    return client('internal/business-location-static-status/');
}

export function getSnoozeReasons(): Promise<PagingResult<BusinessLocationSnoozeReason>> {
    return client('internal/business-location-snooze-reason/');
}
