import React, { useEffect, useState } from 'react';
import JobFormPage from '../JobFormPage';
import { useJobFormContext } from 'parent-portal/forms/JobFormContext';
import { client } from 'shared';
import { BusinessJobTemplateDetailsDTO } from 'parent-portal/forms/JobTemplateModels';
import { styled } from '@mui/material';
import { Checkbox, Text, TextButton } from 'library';
import JobTemplateOption from '../SelectTemplatePage/JobTemplateOption';
import EditSchedulesModal from '../JobSummaryPage/components/EditSchedulesModal';
import { toTemplate, trialRunToSubJobFormPresets } from './templateUtils';

export default function TrialRunToSubJobPage({ name }: { name: string }) {
    const { goToNext, submit, form, setPresets, createdJob, currentPage } = useJobFormContext();
    const { useTemplate, headline, startDate, endDate, daysOfTheWeek, jobTimes, sameTimes } = form.fieldStates;
    const [jobTemplate, setJobTemplate] = useState<BusinessJobTemplateDetailsDTO>();
    const [createSubJob, setCreateSubJob] = useState(false);
    const [editOpen, setEditOpen] = useState(false);

    useEffect(() => {
        if (!createdJob) return;
        client(`api/job-template/${createdJob.id}`).then((resp) => {
            const [template, jobFormPresets] = trialRunToSubJobFormPresets(resp, useTemplate.value, currentPage);
            setJobTemplate(template);
            setPresets(jobFormPresets);
        });
    }, [createdJob]);

    useEffect(() => {
        if (!jobTemplate) return;
        setJobTemplate(
            toTemplate(
                jobTemplate,
                startDate.value,
                endDate.value,
                daysOfTheWeek.value,
                jobTimes.value,
                sameTimes.value,
            ),
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [startDate.value, endDate.value, daysOfTheWeek.value, jobTimes.value, sameTimes.value]);

    async function duplicateAndGoToNext() {
        if (createSubJob) {
            if (!jobTemplate) return;
            submit(name);
        } else {
            goToNext(name);
        }
    }

    return (
        <JobFormPage
            title="One last thing before you go..."
            onNextClicked={duplicateAndGoToNext}
            nextDisabled={!createdJob || !jobTemplate}
            hideBackButton
            id={name}
        >
            <Text variant="h2" bold textStyle={styles.subHeader}>
                Do you need immediate coverage?
            </Text>
            <Text variant="body1" textStyle={styles.bodyText}>
                If you need immediate coverage, we recommend also posting this need as a substitute position. While we
                work to find the right fit for your trial run, you will have the coverage you need to stay in ratio,
                keep classrooms open, and support your staff.
            </Text>
            <TemplateContainer>
                {jobTemplate && <JobTemplateOption jobTemplate={jobTemplate} showCheckbox={false} />}
                <TextButton
                    onClick={() => setEditOpen(!editOpen)}
                    buttonStyle={{ width: undefined, alignSelf: 'flex-end' }}
                    textStyle={{ fontSize: 14 }}
                >
                    Edit Dates/Times
                </TextButton>
                <EditSchedulesModal open={editOpen} onClose={() => setEditOpen(false)} headline={headline.value} />
            </TemplateContainer>
            <Checkbox
                label="Post as substitute job while awaiting trial run candidate"
                checked={createSubJob}
                style={{ marginTop: 20 }}
                onChange={(checked) => setCreateSubJob(checked)}
            />
        </JobFormPage>
    );
}

const TemplateContainer = styled('div')({
    marginTop: 40,
    display: 'flex',
    flexDirection: 'column',
});

const styles = {
    subHeader: {
        marginTop: 20,
    },
    bodyText: {
        marginTop: 10,
    },
};
