import React from 'react';
import { UpcomingJobModel } from 'parent-portal/jobs/nestedJobList/jobListModels';
import { Chip, Colors, Text } from 'library';
import JobCard from '../JobCard';
import moment from 'moment';
import Event from '../EventList/Event';
import { CalendarColors } from '../Calendar/CalendarHelpers';

export default function JobEventList({ jobs }: { jobs: UpcomingJobModel[] }) {
    return jobs.length > 0 ? (
        <>
            <Text bold textStyle={{ marginBottom: 10 }} variant="body1">
                Jobs:
            </Text>
            {jobs.map((j: UpcomingJobModel) => (
                <Event
                    header={`${moment(j.start_date).format('MMMM').substring(0, 4)} ${moment(j.start_date).format(
                        'D',
                    )}`}
                    key={j.id}
                    title={j.headline}
                    chip={
                        <Chip
                            style={{ marginLeft: 5, float: 'right' }}
                            backgroundColor={
                                j.status === 'DRAFT'
                                    ? CalendarColors.draft
                                    : j.ongoing
                                      ? CalendarColors.ongoing
                                      : CalendarColors.onetime
                            }
                            textColor={j.status === 'DRAFT' ? Colors.white : Colors.darkNavy}
                            label={j.status === 'DRAFT' ? 'DRAFT' : j.ongoing ? 'WEEKLY' : 'ONE-TIME'}
                        />
                    }
                    details={<JobCard job={j} />}
                />
            ))}
        </>
    ) : (
        <></>
    );
}
