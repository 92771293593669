import React from 'react';
import { styled } from '@mui/material';
import { Qualification, QualificationOption } from 'models';
import { partition } from 'lodash';
import { BasicDialog, Colors, PrimaryButton, Text } from 'library';
import { IPayScaleQualification } from 'parent-portal/forms/JobRequestTypes';
import { BusinessPayQualificationChip } from './BusinessPayQualificationChip';
import MultiSelect from '../BusinessJobRequirementsPage/MultiSelect';
import SingleSelect from '../BusinessJobRequirementsPage/SingleSelect';
import { IManagedScale } from './BusinessPayInputs';

import { BOOL, SINGLE, MULTI } from './utils/constants';
import TrainingsModalButton from './TrainingsModalButton';
import PayWarning from './PayWarning';

interface ChosenQualification extends Qualification {
    selected: boolean;
}

export default function BusinessPayQualificationsModal({
    open,
    onClose,
    qualifications,
    managedScale,
    updateManagedScales,
    hasSeenTrainingsModal,
    updateHasSeenTrainingsModal,
    isMinimumRate,
}: {
    open: boolean;
    onClose: () => void;
    qualifications: Qualification[];
    managedScale: IManagedScale;
    updateManagedScales: (
        managedScale: IManagedScale,
        newQualifications: IPayScaleQualification[],
        addedQualification?: IPayScaleQualification,
        removedQualification?: IPayScaleQualification,
        singleValueSelected?: IPayScaleQualification,
        singleValueRemoved?: number,
    ) => void;
    hasSeenTrainingsModal: boolean;
    updateHasSeenTrainingsModal: () => void;
    isMinimumRate: boolean;
}) {
    const qualificationsToWarn = qualifications
        .filter((qualification) => qualification.web_view_options.warn_for_minimum_pay)
        .map((qualification) => qualification.id);

    const hasExcessiveTrainings =
        isMinimumRate &&
        qualifications.filter(
            (qualification) =>
                qualificationsToWarn.includes(qualification.id) &&
                !!hasQualification(qualification) &&
                qualification.value_type === BOOL,
        ).length > 0;

    function singleQualificationTooHigh(qualification: Qualification) {
        const qualificationChosen = hasQualification(qualification);
        const lowestOption = qualification.options.sort((a, b) => a.id - b.id)[0];
        return (
            isMinimumRate &&
            qualification.value_type === SINGLE &&
            qualificationsToWarn.includes(qualification.id) &&
            !!qualificationChosen?.option_id &&
            qualificationChosen.option_id > lowestOption.id
        );
    }

    function filterQualifications(type: string): ChosenQualification[] {
        const typedQualifications = qualifications.filter(
            (qualification: Qualification) => qualification.value_type === type,
        );
        return typedQualifications.map((qualification: Qualification) => {
            const selected = managedScale.qualifications.some(
                (payQualification) => payQualification.qualification_id === qualification.id,
            );
            return { ...qualification, selected };
        });
    }

    function hasQualification(qualification: Qualification) {
        return managedScale.qualifications.find(
            (scaleQualification: IPayScaleQualification) => scaleQualification.qualification_id === qualification.id,
        );
    }

    function getValue(qualification: Qualification) {
        const qualificationChosen = hasQualification(qualification);
        return qualificationChosen
            ? qualification.options.find((option) => option.id === hasQualification(qualification)?.option_id)?.value
            : '';
    }

    function clickChip(qualification: Qualification) {
        const qualificationChosen = hasQualification(qualification);
        const filteredQualifications = managedScale.qualifications.filter(
            (scaleQualification: IPayScaleQualification) => {
                return scaleQualification.qualification_id !== qualification.id;
            },
        );

        const selectedQualification = {
            qualification_id: qualification.id,
            option_id: null,
            value: 'true',
            name: qualification.name,
        };

        const newChosenQualifications = qualificationChosen
            ? filteredQualifications
            : [...filteredQualifications, selectedQualification];

        updateManagedScales(
            managedScale,
            newChosenQualifications,
            qualificationChosen ? undefined : selectedQualification,
            qualificationChosen ? selectedQualification : undefined,
        );
    }

    function singleSelect(qualification: Qualification, value: string | undefined) {
        const qualificationOption = qualification.options.find((option: QualificationOption) => option.value === value);

        const filteredQualifications = managedScale.qualifications.filter(
            (scaleQualification: IPayScaleQualification) => {
                return scaleQualification.qualification_id !== qualification.id;
            },
        );

        const newChosenQualifications = !qualificationOption
            ? filteredQualifications
            : [
                  ...filteredQualifications,
                  {
                      qualification_id: qualification.id,
                      option_id: qualificationOption.id,
                      value: null,
                      name: `${qualification.name} ${qualificationOption.value}`,
                  },
              ];

        updateManagedScales(
            managedScale,
            newChosenQualifications,
            undefined,
            undefined,
            qualificationOption
                ? {
                      qualification_id: qualification.id,
                      option_id: qualificationOption.id,
                      value: null,
                      name: `${qualification.name} ${qualificationOption.value}`,
                  }
                : undefined,
            !qualificationOption ? qualification.id : undefined,
        );
    }

    function multiSelect(optionId: number) {
        const chosenQualification = qualifications.find((qualification: Qualification) =>
            qualification.options.map((option: QualificationOption) => option.id).includes(optionId),
        );
        const chosenQualificationOption = chosenQualification?.options?.find(
            (option: QualificationOption) => option.id === optionId,
        );

        if (chosenQualification && chosenQualificationOption) {
            const [filteredQualifications, otherQualifications] = partition(
                managedScale.qualifications,
                (scaleQualification: IPayScaleQualification) => {
                    return (
                        scaleQualification.qualification_id === chosenQualification.id &&
                        scaleQualification.option_id === chosenQualificationOption.id
                    );
                },
            );

            const selectedQualification = {
                qualification_id: chosenQualification.id,
                option_id: chosenQualificationOption.id,
                value: null,
                name: `${chosenQualification.name} ${chosenQualificationOption.value}`,
            };

            const isRemoved = filteredQualifications?.length > 0;

            const newChosenQualifications = isRemoved
                ? otherQualifications
                : [...managedScale.qualifications, selectedQualification];

            updateManagedScales(
                managedScale,
                newChosenQualifications,
                isRemoved ? undefined : selectedQualification,
                isRemoved ? selectedQualification : undefined,
            );
        }
    }

    function getChosenMultis(qualification: Qualification) {
        const chosenQualifications = managedScale.qualifications.filter(
            (scaleQualification: IPayScaleQualification) => scaleQualification.qualification_id === qualification.id,
        );

        if (chosenQualifications?.length > 0) {
            return chosenQualifications.map((chosenQualification: IPayScaleQualification) => ({
                qualification: qualification.id,
                option: chosenQualification.option_id ?? undefined,
                value: chosenQualification.value ?? undefined,
                option_id: chosenQualification.option_id ?? undefined,
            }));
        }

        return [];
    }

    const booleanQualifications = filterQualifications(BOOL);
    const multiQualifications = filterQualifications(MULTI);
    const singleQualifications = filterQualifications(SINGLE);

    return (
        <BasicDialog isOpen={open} onClose={onClose} closeButton>
            <Text variant="h1" bold>
                Requirements
            </Text>
            <TrainingsModalButton initialOpen={!hasSeenTrainingsModal} onClose={updateHasSeenTrainingsModal} />
            <Spacing warning={hasExcessiveTrainings}>
                <PayWarning
                    visible={hasExcessiveTrainings}
                    text="Are all of these trainings absolutely required? If not remove requirements to increase the chance of a teacher getting booked."
                />
                <Text variant="body2" textStyle={{ marginTop: 20, marginBottom: 20 }}>
                    Select all that apply.
                </Text>
                {booleanQualifications.map((qualification) => (
                    <BusinessPayQualificationChip
                        key={qualification.id}
                        qualification={qualification}
                        selected={hasQualification(qualification)?.value?.toString() === 'true'}
                        onSelect={(qualification) => clickChip(qualification)}
                    />
                ))}
            </Spacing>
            {multiQualifications.map((qualification) => (
                <Spacing key={qualification.id}>
                    <Text variant="h2" bold>
                        {qualification.name}
                    </Text>
                    <MultiSelect
                        options={qualification.options}
                        selectedOptions={getChosenMultis(qualification)}
                        onSelect={(optionId: number) => {
                            multiSelect(optionId);
                        }}
                    />
                </Spacing>
            ))}
            {singleQualifications.map((qualification) => (
                <Spacing key={qualification.id} warning={singleQualificationTooHigh(qualification)}>
                    <PayWarning
                        visible={singleQualificationTooHigh(qualification)}
                        text="Is this qualification absolutely required for the base pay? If not remove the requirement to increase the chance of a teacher getting booked."
                    />
                    <Text variant="h2" bold>
                        {qualification.name}
                    </Text>
                    <SingleSelect
                        options={[{ id: -1, value: 'Not Required', qualification: -1 }, ...qualification.options]}
                        value={getValue(qualification) || 'Not Required'}
                        onChange={(value?: string) => singleSelect(qualification, value)}
                    />
                </Spacing>
            ))}
            <CloseRow>
                <PrimaryButton buttonStyle={{ width: 'unset' }} onClick={onClose}>
                    Close
                </PrimaryButton>
            </CloseRow>
        </BasicDialog>
    );
}

const Spacing = styled('div')(({ warning }: { warning?: boolean }) => ({
    marginTop: 20,
    border: `1px solid ${warning ? Colors.terraCotta : undefined}`,
    borderRadius: 10,
    padding: 10,
}));

const CloseRow = styled('div')({
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 20,
    width: '100%',
});
