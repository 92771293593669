import { OutlinedDropdown } from 'library';
import React from 'react';
import { Controller, Control, FieldValues, Path } from 'react-hook-form';
import LabelWrapper from './LabelWrapper';

export default function FormControlledDropdown<T extends FieldValues>({
    control,
    fieldName,
    fields,
    required,
    displayName,
}: {
    control: Control<T>;
    fieldName: Path<T>;
    fields: { key: string; value: string }[];
    required?: boolean;
    displayName?: string;
}) {
    return (
        <LabelWrapper
            label={
                displayName ??
                fieldName
                    .capitalize()
                    .replace(/([A-Z])/g, ' $1')
                    .trim()
            }
        >
            <Controller
                name={fieldName}
                control={control}
                rules={{ required: required }}
                render={({ field }) => <OutlinedDropdown {...field} fields={fields} />}
            />
        </LabelWrapper>
    );
}
