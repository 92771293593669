import React from 'react';
import { Grid } from '@mui/material';
import { Text, Colors } from 'library';
import { useHistory } from 'react-router-dom';
import AppScreenshot from 'assets/images/on-demand/worker-screenshot.png';
import DownloadRow from 'parent-portal/shared/Footer/DownloadRow';

export default function Header({ smDown }: { smDown: boolean }) {
    const history = useHistory();

    const styles = {
        container: {
            padding: smDown ? 20 : 120,
            backgroundColor: Colors.lightTurq,
            marginBottom: smDown ? 0 : 50,
            paddingBottom: 80,
        },
        button: {
            marginTop: 20,
            width: undefined,
        },
    };

    return (
        <Grid container direction="row" style={styles.container}>
            {smDown ? <img src={AppScreenshot} alt="" style={{ width: '38vh' }} /> : null}
            <Grid item md={6} style={{ padding: smDown ? 30 : 40, paddingRight: smDown ? 30 : 100 }}>
                <Text variant="display">Work flexible hours and earn daily pay on Tandem.</Text>
                <Text variant="body1" textStyle={{ marginTop: 30, marginBottom: 20 }}>
                    We allow child care providers to easily connect with jobs in local child care programs. It's time
                    you regain autonomy over your hours and pay.
                </Text>
                <DownloadRow />
            </Grid>
            <Grid container item md={6} style={{ marginTop: -80, width: '100%', position: 'relative', maxWidth: 500 }}>
                {smDown ? null : (
                    <div style={{ position: 'absolute', left: 25 }}>
                        <img src={AppScreenshot} alt="" style={{ width: '52vh' }} />
                    </div>
                )}
            </Grid>
        </Grid>
    );
}
