import React from 'react';
import { Paper, Text } from 'library';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarker, faCalendarCheck } from '@fortawesome/free-solid-svg-icons';
import BusinessProviderSummaryDetailRow from './BusinessProviderSummaryDetailRow';
import ProviderProfileImage from 'parent-portal/provider-profile/ProviderProfileImage';
import { Grid } from '@material-ui/core';
import moment from 'moment';

const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat'];

export default function BusinessSummaryCard({ business, mobile }: { business: any; mobile: boolean }) {
    const details = [
        {
            detail: `Location`,
            subDetail: (
                <Grid container item xs={12} alignItems="flex-start">
                    <Text variant="deprecatedCaption">
                        {business.user.address[0].city}, {business.user.address[0].state}{' '}
                        {business.user.address[0].zip_code}
                    </Text>
                </Grid>
            ),
            icon: <FontAwesomeIcon icon={faMapMarker} style={{ fontSize: 10 }} />,
        },
        {
            detail: `Hours`,
            subDetail: !!business.hours
                ? business.hours.map((hour: any) => (
                      <Grid container item xs={12}>
                          <Text variant="deprecatedCaption">
                              <b>{daysOfWeek[hour.day_of_week - 1]}:</b>{' '}
                              {moment(hour.start_time, 'hh:mm A').format('h:mm A')}-
                              {moment(hour.end_time, 'hh:mm A').format('h:mm A')}
                          </Text>
                      </Grid>
                  ))
                : null,
            icon: <FontAwesomeIcon icon={faCalendarCheck} style={{ fontSize: 10 }} />,
        },
    ];

    return (
        <Paper
            style={{
                minHeight: mobile ? 240 : 500,
                maxWidth: mobile ? undefined : 254,
                padding: '5%',
                paddingTop: '10%',
            }}
        >
            <Grid container alignItems="center" justify="center">
                <Grid container item xs={12} justify="center">
                    <ProviderProfileImage mobile={mobile} business={business} />
                </Grid>
                <Grid container sm={10} xs={12} justify="center" style={{ marginTop: '5%' }}>
                    {details.map((detail, i) =>
                        !!detail.detail ? (
                            <Grid container style={{ marginTop: '10%' }} justify="center">
                                <BusinessProviderSummaryDetailRow {...detail} />
                            </Grid>
                        ) : null,
                    )}
                </Grid>
            </Grid>
        </Paper>
    );
}
