import React from 'react';
import { Text } from 'library';
import { ShiftProviderUser } from 'models';
import { TextContainer } from '../components/Containers';
import ThanksForFeedback from '../components/ThanksForFeedback';
import ProfileImage from '../components/ProfileImage';

export default function InitialFeedback({ provider }: { provider: ShiftProviderUser }) {
    return (
        <>
            <TextContainer>
                <ThanksForFeedback />
            </TextContainer>

            <TextContainer>
                <Text variant="body1">
                    We are so glad you had a great experience! Would you like to add {provider.first_name} to your
                    preferred workers list? Adding workers to this list will allow us to prioritize the people you
                    choose to work your jobs.
                </Text>
            </TextContainer>

            <TextContainer>
                <Text textStyle={{ fontWeight: 'bold' }} variant="body1">
                    Would you like to add {provider.first_name} to your preferred workers list?
                </Text>
            </TextContainer>

            <ProfileImage image={provider.profile_picture?.image} imageId={provider.id.toString()} />
        </>
    );
}
