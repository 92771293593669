import React from 'react';
import { Grid } from '@mui/material';
import { Paper } from 'library';
import { ProviderQualifications } from 'parent-portal/provider-profile/providerProfileModels';
import CredentialsHighlight from './CredentialsHighlight';
import EducationHighlight from './EducationHighlight';
import ExperienceHighlight from './ExperienceHighlight';
import { ChildcareCenterExperienceQualification, Provider } from 'models';
import EmploymentHighlight from './EmploymentHighlight';

export default function BusinessHighlights({
    provider,
    providerQualifications,
    childcareCenterQualifications,
}: {
    provider: Provider;
    providerQualifications: ProviderQualifications;
    childcareCenterQualifications: ChildcareCenterExperienceQualification[];
}) {
    const showEducation = providerQualifications.education.length > 0;
    const showEmployment = providerQualifications.employment.length > 0;
    const showExperience =
        providerQualifications.years_childcare_experience > 0 || providerQualifications.age_group_experience.length > 0;
    const showCredentials =
        providerQualifications.professional_development_courses.length > 0 ||
        providerQualifications.cpl_level ||
        provider.hasBCIFBICheck ||
        (childcareCenterQualifications.length > 0 &&
            (childcareCenterQualifications[0].occrra_training_description ||
                childcareCenterQualifications[0].first_aid_certification ||
                childcareCenterQualifications[0].cpr_certification ||
                childcareCenterQualifications[0].covid_vaccinated ||
                childcareCenterQualifications[0].cdc_certification));
    return showEducation || showEmployment || showExperience || showCredentials ? (
        <Paper style={{ marginTop: 20, padding: 10 }}>
            <Grid container>
                {showEducation && (
                    <Grid item xs={12} md={showCredentials ? 4 : 12} style={{ padding: 5 }}>
                        <EducationHighlight education={providerQualifications.education} />
                    </Grid>
                )}

                {showCredentials && (
                    <Grid item xs={12} md={showEducation ? 8 : 12} style={{ padding: 5 }}>
                        <CredentialsHighlight
                            provider={provider}
                            qualifications={providerQualifications}
                            childcareCenterQualifications={
                                childcareCenterQualifications.length > 0 ? childcareCenterQualifications[0] : null
                            }
                        />
                    </Grid>
                )}
                {showEmployment ? (
                    <Grid item xs={12} style={{ padding: 5 }}>
                        <EmploymentHighlight employment={providerQualifications.employment} />
                    </Grid>
                ) : null}
                {showExperience ? (
                    <Grid item xs={12} style={{ padding: 5 }}>
                        <ExperienceHighlight qualifications={providerQualifications} />
                    </Grid>
                ) : null}
            </Grid>
        </Paper>
    ) : null;
}
