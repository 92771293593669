import React from 'react';
import clsx from 'clsx';
import { Colors, Text } from 'library';
import { createStyles } from '@mui/material';
import format from 'date-fns/format';
import isSameDay from 'date-fns/isSameDay';
import { IconButton, withStyles } from '@material-ui/core';
import { EventType } from './models';

function CustomWeekDayRender({
    classes,
    date,
    selectedDate,
    appointments,
    dayInCurrentMonth,
}: {
    classes: any;
    date: any; // for some reason these dates are mistyped by MaterialUiDatePickers
    selectedDate: any; // as moment objects but they are actually just Date objects
    appointments: EventType[];
    dayInCurrentMonth: boolean;
}) {
    let dateClone = date ?? new Date();
    let selectedDateClone = selectedDate ?? new Date();
    let sameDay = isSameDay(dateClone, selectedDateClone);

    const wrapperClassName = clsx({
        [classes.highlight]: sameDay,
    });

    const dayClassName = clsx(classes.day, {
        [classes.nonCurrentMonthDay]: !dayInCurrentMonth,
    });

    let datesWithJobs: string[] = appointments.reduce(
        (dates: string[], a: EventType) => [
            ...dates,
            new Date(a.start).toLocaleDateString('en-US'),
            new Date(a.end).toLocaleDateString('en-US'),
        ],
        [],
    );

    return (
        <div className={wrapperClassName}>
            <IconButton className={dayClassName}>
                <Text
                    variant="caption"
                    textStyle={{
                        color: sameDay ? Colors.white : dayInCurrentMonth ? Colors.darkNavy : Colors.disabledGrey,
                        fontSize: '1rem',
                        lineHeight: 1,
                    }}
                >
                    {format(dateClone, 'd')}
                    <Text
                        textStyle={{
                            textAlign: 'center',
                            color: sameDay ? Colors.white : Colors.darkNavy,
                            fontWeight: 'bold',
                            fontSize: '1rem',
                            lineHeight: 0,
                            height: 1,
                        }}
                    >
                        {dayInCurrentMonth && dateClone && datesWithJobs.includes(dateClone.toLocaleDateString('en-us'))
                            ? '.'
                            : ''}
                    </Text>
                </Text>
            </IconButton>
        </div>
    );
}

const styles = createStyles(() => ({
    dayWrapper: {
        position: 'relative',
    },
    day: {
        width: 36,
        height: 36,
        flexDirection: 'column',
        fontSize: '1rem',
        margin: '0 2px',
        color: 'inherit',
        '&:focus': {
            outline: 0,
            border: 0,
        },
    },
    nonCurrentMonthDay: {
        color: Colors.disabledGrey,
    },
    highlightNonCurrentMonthDay: {
        color: '#676767',
    },
    highlight: {
        background: Colors.darkNavy,
        color: Colors.white,
        borderRadius: '50%',
    },
}));

export default withStyles(styles)(CustomWeekDayRender);
