import React, { ElementType } from 'react';
import { TextButton, Colors } from 'library';
import { SvgIcon } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

export default function AdminButton({
    text,
    icon,
    FontAwesomeImg,
    color,
    borderColor,
    onClick,
    btnStyles,
}: {
    text: string;
    icon?: ElementType<any>;
    FontAwesomeImg?: IconDefinition;
    color: string;
    borderColor?: string;
    onClick: (e: any) => void;
    btnStyles?: any;
}) {
    return (
        <TextButton
            buttonStyle={{
                width: undefined,
                backgroundColor: color,
                border: borderColor ? `solid 1px ${borderColor}` : undefined,
                padding: 4,
                marginRight: 8,
                ...btnStyles,
            }}
            textStyle={{ fontSize: 14, margin: 0 }}
            onClick={onClick}
        >
            {!!FontAwesomeImg ? (
                <FontAwesomeIcon
                    icon={FontAwesomeImg}
                    color={Colors.darkNavy}
                    style={{
                        width: 14,
                        height: 14,
                    }}
                />
            ) : icon ? (
                <SvgIcon
                    style={{
                        color: Colors.darkNavy,
                        width: 14,
                        height: 14,
                    }}
                    component={icon}
                />
            ) : null}{' '}
            {text}
        </TextButton>
    );
}
