import React from 'react';
import { Colors, Text } from 'library';
import { Grid, useMediaQuery } from '@mui/material';
import Imagee from 'assets/images/marketing-site/trial-runs/stock-image-group.png';
import Jana from 'assets/images/marketing-site/trial-runs/jana.png';
import theme from 'theme';

export default function Credential() {
    const smDown = useMediaQuery(theme.breakpoints.down('sm'));
    const containerStyle = {
        backgroundImage: `url(${Imagee})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        minHeight: '50vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: smDown ? 54 : 71,
    };

    const innerContainerStyle = {
        padding: smDown ? 20 : 50,
        paddingBottom: 100,
    };
    const paperStyle: React.CSSProperties = {
        borderRadius: smDown ? 54 : 71,
        backgroundColor: Colors.white,
        margin: 20,
        width: smDown ? '95%' : '60vw',
        padding: smDown ? 15 : 30,
        marginLeft: smDown ? 0 : 50,
        top: '14vh',
        marginBottom: smDown ? 10 : 80,
        position: 'relative',
        justifyContent: 'row',
        left: smDown ? 0 : '10vh',
    };
    const bigCircleStyle: React.CSSProperties = {
        width: smDown ? 0 : '550px',
        height: '550px',
        backgroundColor: '#FDF2F0',
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: -999,
        position: 'absolute',
        left: '10px',
        marginTop: '40vh',
    };
    return (
        <Grid container style={innerContainerStyle} alignItems="center">
            <Grid item xs={12} md={3} container direction="column" spacing={2}>
                <Grid item>
                    <Text variant="display" textStyle={{ marginTop: 20, marginBottom: smDown ? 30 : 0 }}>
                        We verify child care specific qualifications & experiences.
                    </Text>
                </Grid>
            </Grid>
            <Grid item xs={12} md={9} style={{ left: smDown ? 0 : '40vh' }}>
                <div style={containerStyle}>
                    <Grid container item style={paperStyle}>
                        <div style={{ marginTop: 30 }}>
                            <Grid container item direction={smDown ? 'column' : 'row'}>
                                <Grid container item md={3} xs={12} style={{ marginLeft: smDown ? 20 : undefined }}>
                                    <img
                                        src={Jana}
                                        alt="Trial Run"
                                        width={smDown ? 80 : undefined}
                                        height={smDown ? 80 : undefined}
                                    />
                                </Grid>
                                <Grid container item md={9} xs={12} style={{ paddingLeft: 25 }} direction="column">
                                    <Text variant="h2" bold>
                                        Meet Jana, care worker since 2011
                                    </Text>
                                    <Text variant="h2" bold textStyle={{ textDecorationLine: 'underline' }}>
                                        Credentials
                                    </Text>
                                    <Text variant="body2" textStyle={{ marginTop: 8 }}>
                                        ✔ CDA in Preschool
                                    </Text>
                                    <Text variant="body2" textStyle={{ marginTop: 8 }}>
                                        ✔ 12 years of experience at Kiddie Company, Lil Tots, and Just Kidz
                                    </Text>
                                    <Text variant="body2" textStyle={{ marginTop: 8 }}>
                                        ✔ High School Diploma
                                    </Text>
                                    <Text variant="body2" textStyle={{ marginTop: 8 }}>
                                        ✔ CPR, First Aid, Professional Development Completed
                                    </Text>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                </div>
            </Grid>
            <div style={bigCircleStyle}></div>
        </Grid>
    );
}
