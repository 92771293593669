import React, { useState, useEffect } from 'react';
import { Grid, useMediaQuery } from '@material-ui/core';
import theme from 'theme';
import { Text, PrimaryButton, Colors } from 'library';
import { useParams } from 'react-router-dom';
import BusinessSummaryCard from './BusinessSummaryCard';
import { makeStyles } from '@material-ui/core';
import BusinessTabs from './BusinessTabs';
import client from 'shared/ApiClient';
import BusinessEnrollmentModal from './BusinessEnrollementModal';

const useStyles = makeStyles((theme) => ({
    '@global': {
        body: {
            backgroundColor: Colors.lightTurq,
        },
    },
    tabs: {
        '&.MuiButtonBase-root.MuiTab-root': {
            fontSize: 16,
            fontWeight: 'bold',
            textTransform: 'none',
            outline: 'none',
        },
        '&.MuiTab-textColorInherit': {
            color: Colors.darkNavy,
        },
        '&.Mui-selected': {
            color: Colors.turquoise,
        },
    },
    panels: {
        '&.MuiTabPanel-root': {
            padding: '20px 0px 20px 0px',
        },
    },
}));

export default function BusinessProfile() {
    const [business, setBusiness] = useState<any>();
    const [open, setOpen] = useState<any>();
    const { id } = useParams<{ id: string }>();

    useEffect(() => {
        client(`api/business/${id}/details/`).then((response: any[]) => {
            if (response.length > 0) {
                setBusiness(response[0]);
            }
        });
    }, []);

    const isSmall = useMediaQuery(theme.breakpoints.down('md'));
    useStyles(theme);

    return business ? (
        <Grid container style={{ padding: isSmall ? 20 : 50, paddingTop: 0, marginBottom: 80 }}>
            <Grid container justify="space-between" style={{ marginTop: '3%', marginBottom: isSmall ? 24 : 62 }}>
                <Text variant="display" textStyle={{ marginRight: 30 }}>
                    {business.name}
                </Text>
                <Grid item>
                    <PrimaryButton onClick={() => setOpen(true)}>Contact</PrimaryButton>
                </Grid>
            </Grid>
            <Grid container>
                <Grid container item xs={12} sm={3} justify="center">
                    <BusinessSummaryCard business={business} mobile={isSmall} />
                </Grid>
                <Grid container item sm={9} xs={12} justify="center">
                    <Grid container item xs={11}>
                        <BusinessTabs business={business} />
                    </Grid>
                </Grid>
            </Grid>
            <BusinessEnrollmentModal business={business} open={open} onClose={() => setOpen(false)} />
        </Grid>
    ) : (
        <Text>No center found.</Text>
    );
}
