import { useEffect } from 'react';

export default function useWindowFocus(handleFocus: () => void, dependencies: any[]) {
    useEffect(() => {
        window.addEventListener('focus', handleFocus);

        return () => {
            window.removeEventListener('focus', handleFocus);
        };
    }, dependencies);
}
