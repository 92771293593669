import React, { useState } from 'react';
import { Grid, useMediaQuery } from '@material-ui/core';
import Past from './Past';
import PayMethods from './Components/PayMethods';
import PaySitter from './Components/PaySitter';
import UnpaidAppointments from './Components/UnpaidAppointments';
import FailedPayments from './Components/FailedPayments';
import PaySectionHeader from './Components/PaySectionHeader';
import { PayBackgroundPaper, PayCardBackgroundPaper } from './Components/PayPapers';
import SendReceipt from './Components/SendReceipt';
import DiscountBalance from './DiscountBalance';
import theme from 'theme';
import MicrodepositsEntry from './Components/MicrodepositsEntry';
import { IPastPaymentState } from './Models/PastPayment';

interface FamilyPaymentsProps {
    hasUnpaidAppointments: number;
    onPaymentComplete: () => void;
    setHasUnpaidAppointments: (unpaid: number) => void;
    isLoadingPastPayments: boolean;
    pastPayments?: IPastPaymentState;
    getPageNumber: () => number;
    onPageChange: (newPage: number) => void;
}

export default function FamilyPayments({
    hasUnpaidAppointments,
    onPaymentComplete,
    setHasUnpaidAppointments,
    isLoadingPastPayments,
    pastPayments,
    getPageNumber,
    onPageChange,
}: FamilyPaymentsProps) {
    const mdUp = useMediaQuery(theme.breakpoints.up('md'));
    return (
        <Grid container justify="center" alignContent="center" direction="row">
            <Grid
                container
                item
                direction="column"
                lg={6}
                md={7}
                sm={9}
                xs={12}
                style={{ paddingRight: mdUp ? 20 : 0 }}
            >
                <Grid container item>
                    <MicrodepositsEntry />
                    <FailedPayments />
                    {hasUnpaidAppointments ? (
                        <Grid item xs={12}>
                            <PayCardBackgroundPaper>
                                <UnpaidAppointments
                                    hideDirectPayLink={true}
                                    onPaymentComplete={onPaymentComplete}
                                    unpaidJobsCheck={(x: number) => setHasUnpaidAppointments(x)}
                                />
                            </PayCardBackgroundPaper>
                        </Grid>
                    ) : null}
                    <Grid item xs={12}>
                        <Past
                            isLoading={isLoadingPastPayments}
                            pastPayments={pastPayments}
                            currentPage={getPageNumber()}
                            onPageChange={onPageChange}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid container item lg={4} md={5} sm={9} xs={12} alignContent="flex-start">
                <Grid container item xs={12}>
                    <PayBackgroundPaper>
                        <PaySectionHeader>Send Payment</PaySectionHeader>
                        <PaySitter onPaymentComplete={onPaymentComplete} />
                    </PayBackgroundPaper>
                </Grid>
                <Grid container item xs={12}>
                    <PayBackgroundPaper>
                        <DiscountBalance />
                    </PayBackgroundPaper>
                </Grid>
                <Grid container item xs={12} direction="row" justify="center" alignItems="center">
                    <SendReceipt />
                </Grid>
                <Grid item xs={12}>
                    <PayMethods />
                </Grid>
            </Grid>
        </Grid>
    );
}
