import React, { useState } from 'react';
import { BusinessJobWithAppointments } from 'models';
import { AddCard } from '@mui/icons-material';
import { IconButton } from 'library';
import AddDaysModal from './AddDaysModal';

export default function AddDays({ job, refreshJobs }: { job: BusinessJobWithAppointments; refreshJobs: () => void }) {
    const [open, setOpen] = useState(false);

    return (
        <span style={{ marginLeft: 10 }} title="Add Day">
            <IconButton tooltip="Add days to this job" icon={AddCard} onClick={() => setOpen(true)} />
            <AddDaysModal job={job} open={open} onClose={() => setOpen(false)} refreshJobs={refreshJobs} />
        </span>
    );
}
