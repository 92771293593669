import React from 'react';
import { Text } from 'library';
import { Checkbox, Grid } from '@material-ui/core';
import CheckboxLabel from 'reusableComponents/checkboxLabel';
import { IAppointment, TimeChange } from './jobChangeModels';
import { isAfter, isBefore } from 'date-fns';

export default function GenerateAppointments({
    generateBefore,
    setGenerateBefore,
    generateAfter,
    setGenerateAfter,
    timeChange,
    appt,
}: {
    generateBefore: number[];
    setGenerateBefore: (n: number[]) => void;
    generateAfter: number[];
    setGenerateAfter: (n: number[]) => void;
    timeChange: TimeChange;
    appt: IAppointment | undefined;
}) {
    function displayDate(date: Date) {
        return date.toLocaleTimeString('en-US', { hour12: true, hour: '2-digit', minute: '2-digit' });
    }

    function startCovered() {
        const date1 = new Date(timeChange.start);
        const date2 = appt ? new Date(appt.start_date) : new Date();
        return !isAfter(date1, date2) || displayDate(date1) === displayDate(date2);
    }

    function endCovered() {
        const date1 = new Date(timeChange.end);
        const date2 = appt ? new Date(appt.end_date) : new Date();
        return !isBefore(date1, date2) || displayDate(date1) === displayDate(date2);
    }

    return !appt ? null : (
        <>
            {!startCovered() || !endCovered() ? (
                <>
                    <Text variant="body1">Fill remaining hours with new provider:</Text>
                    <Grid justify="space-between" container item>
                        {!startCovered() ? (
                            <CheckboxLabel
                                value={timeChange.appointment}
                                control={
                                    <Checkbox
                                        checked={generateBefore.includes(timeChange.appointment)}
                                        color="primary"
                                        onChange={(e: any) =>
                                            generateBefore.includes(timeChange.appointment)
                                                ? setGenerateBefore(
                                                      generateBefore.filter((i) => i !== timeChange.appointment),
                                                  )
                                                : setGenerateBefore([...generateBefore, timeChange.appointment])
                                        }
                                    />
                                }
                                label={
                                    <Text variant="body1">
                                        {displayDate(new Date(appt.start_date))} -{' '}
                                        {displayDate(new Date(timeChange.start))}
                                    </Text>
                                }
                            />
                        ) : null}
                        {!endCovered() ? (
                            <CheckboxLabel
                                value={timeChange.appointment}
                                control={
                                    <Checkbox
                                        checked={generateAfter.includes(timeChange.appointment)}
                                        color="primary"
                                        onChange={(e: any) =>
                                            generateAfter.includes(timeChange.appointment)
                                                ? setGenerateAfter(
                                                      generateAfter.filter((i) => i !== timeChange.appointment),
                                                  )
                                                : setGenerateAfter([...generateAfter, timeChange.appointment])
                                        }
                                    />
                                }
                                label={
                                    <Text variant="body1">
                                        {displayDate(new Date(timeChange.end))} - {displayDate(new Date(appt.end_date))}
                                    </Text>
                                }
                            />
                        ) : null}
                    </Grid>
                </>
            ) : null}
        </>
    );
}
