import React from 'react';
import { MenuItem } from '@mui/material';
import { Text, Colors } from 'library';
import JobDescription from 'internal/business/JobDescription';
import { AdminBusinessUser, LeanUser, LeanUserWithPhone } from 'models';
import JobChangeRequestDetails from 'internal/applicants/JobChangeRequestDetails';
import { BusinessApplicantWithJob } from 'internal/applicants/ApplicantModels';
import AddProvider from 'internal/substitutes/AddProvider';
import AutobookDetails from 'internal/substitutes/Matching/ReverseMatching/components/AutobookDetails';

export default function AppliedJobsMenuItem({
    application,
    user,
}: {
    application: BusinessApplicantWithJob;
    user: AdminBusinessUser | LeanUserWithPhone | LeanUser;
}) {
    const job = application.job;
    return (
        <>
            <MenuItem disableRipple style={{ justifyContent: 'space-between' }}>
                <div>
                    <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                        <Text textStyle={{ fontSize: 14, marginRight: 10, textDecoration: 'underline' }}>
                            {job.headline}({job.id}) {application.ignored ? 'IGNORED' : ''}
                        </Text>
                        <AutobookDetails applicant={application} />
                        <Text
                            bold
                            variant="body2"
                            color={Colors.turquoise}
                            textStyle={{ backgroundColor: Colors.lightGreen, marginRight: 10 }}
                        >
                            {application.applicant_type}
                        </Text>
                        <AddProvider job={job} initialProviderUserId={user.id} />
                        <JobDescription job={job} />
                    </div>
                    <Text variant="caption">
                        {job.business[0].name} ({job.business[0].id})
                    </Text>
                    {job.change_requests?.map((request) => (
                        <JobChangeRequestDetails key={request.id} jobChangeRequest={request} />
                    ))}
                </div>
            </MenuItem>
        </>
    );
}
