import React from 'react';
import { Grid } from '@material-ui/core';
import { Colors, Text } from 'library';
import { useUserContext } from 'UserContext';
import PayMethod from './Components/PayMethod';
import BusinessPaymentMethod from './Components/business/BusinessPaymentMethod';
import { Address, IPayMethod } from 'models';

export default function RenderPaymentMethod({
    payMethod,
    updatePayMethods,
    DefaultPayComponent,
}: {
    payMethod: IPayMethod;
    DefaultPayComponent: JSX.Element;
    updatePayMethods: (a: Address[]) => void;
}) {
    const { user } = useUserContext();

    const addresses = user?.address ?? [];

    return (
        <Grid
            style={{ backgroundColor: Colors.lightTurq, borderRadius: 18, padding: 10, marginBottom: 10 }}
            container
            direction="row"
            alignItems="center"
        >
            <Grid
                style={{
                    margin: '1em 0',
                }}
                item
                container
                xs={12}
                direction="row"
                justify="space-between"
                alignItems="center"
            >
                <PayMethod payMethod={payMethod} />
                {payMethod.is_default ? <Text variant="body1">(Default)</Text> : DefaultPayComponent}
            </Grid>
            {user?.businesses_active?.length && addresses.length > 1 ? (
                <BusinessPaymentMethod payMethod={payMethod} updatePayMethods={updatePayMethods} />
            ) : null}
        </Grid>
    );
}
