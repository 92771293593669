import React from 'react';
import AdminTable, { AdminTableProps } from './AdminTable';
import { TablePagination, styled } from '@mui/material';
import { PagingResult } from 'models';

export interface PaginatedTableProps<T> extends AdminTableProps {
    data: PagingResult<T>;
    page: number;
    updatePage: (page: number) => void;
}

export default function PaginatedTable<T>({ data, page, updatePage, ...tableProps }: PaginatedTableProps<T>) {
    const onPageChange = (_: React.MouseEvent | null, page: number) => updatePage(page + 1);
    return (
        <Container>
            <Pagination
                count={data?.count || 0}
                rowsPerPage={data?.per_page || 0}
                page={page - 1}
                onPageChange={onPageChange}
                rowsPerPageOptions={[]}
            />
            <AdminTable {...tableProps} />
            <Pagination
                count={data?.count || 0}
                rowsPerPage={data?.per_page || 0}
                page={page - 1}
                onPageChange={onPageChange}
                rowsPerPageOptions={[]}
            />
        </Container>
    );
}

const Pagination = styled(TablePagination)({
    display: 'flex',
    justifyContent: 'flex-end',
    padding: 0,
});

const Container = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
});
