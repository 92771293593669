import { Grid } from '@material-ui/core';
import { LocationHistory } from 'models';
import React from 'react';
import LocationHistoryRow from './LocationHistoryRow';

export default function LocationHistoryDisplay({ history }: { history: LocationHistory[] }) {
    return (
        <Grid item container style={{ gap: 10, width: '80%' }} direction="column">
            {history.map((entry: LocationHistory) => (
                <LocationHistoryRow key={entry.id} entry={entry} />
            ))}
        </Grid>
    );
}
