import React from 'react';
import { Text } from 'library';

interface PaySectionHeaderProps {
    children: React.ReactNode;
    textStyle?: React.CSSProperties;
}

const PaySectionHeader = ({ children, textStyle }: PaySectionHeaderProps) => {
    return (
        <Text variant="h1" textStyle={{ marginTop: 0, marginBottom: 25, ...textStyle }}>
            {children}
        </Text>
    );
};

export default PaySectionHeader;
