import React, { useState } from 'react';
import { Grid, Radio } from '@material-ui/core';
import Label from 'reusableComponents/checkboxLabel';
import { Provider } from '../CancelWithApplicantModal';
import { Text } from 'library';

export default function ProviderRadioContent({
    appliedProviders,
    selectProvider,
    updateDidntHire,
}: {
    appliedProviders: Provider[];
    selectProvider: (provider: Provider) => void;
    updateDidntHire: (value: boolean) => void;
}) {
    const [hiredProvider, setHiredProvider] = useState<Provider>();
    const [didntHireProvider, setDidntHireProvider] = useState(false);

    return (
        <>
            {appliedProviders.map((provider, index) => (
                <Grid
                    key={index}
                    container
                    direction="row"
                    alignItems="center"
                    onClick={() => {
                        setHiredProvider(provider);
                        setDidntHireProvider(false);
                        updateDidntHire(false);
                        selectProvider(provider);
                    }}
                >
                    <Label
                        control={
                            <Radio
                                checked={hiredProvider === provider && !didntHireProvider}
                                color="primary"
                                onClick={() => {
                                    setHiredProvider(provider);
                                    selectProvider(provider);
                                    setDidntHireProvider(false);
                                    updateDidntHire(false);
                                }}
                                disableFocusRipple
                            />
                        }
                        label={<Text variant="body1">{`${provider.user.first_name} ${provider.user.last_name}`}</Text>}
                    />
                </Grid>
            ))}

            <Grid
                container
                direction="row"
                alignItems="center"
                onClick={() => {
                    setDidntHireProvider(true);
                    updateDidntHire(true);
                }}
            >
                <Label
                    control={
                        <Radio
                            checked={didntHireProvider}
                            color="primary"
                            onClick={() => {
                                setDidntHireProvider(true);
                                updateDidntHire(true);
                            }}
                            disableFocusRipple
                        />
                    }
                    label={<Text variant="body1">No, I did not hire a provider from Tandem.</Text>}
                />
            </Grid>
        </>
    );
}
