import { MenuItem, Select } from '@mui/material';
import React from 'react';
import { DropDownField } from '../models';

export default function DocumentTypeSelection({
    selectedType,
    updateSelected,
    documentTypes,
}: {
    selectedType: string;
    updateSelected: (type: number) => void;
    documentTypes: DropDownField[];
}) {
    return (
        <Select
            value={selectedType}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onChange={(e: any) => updateSelected(parseInt(e.target.value))}
            label={'Document Type'}
            style={{ height: 40, marginBottom: 20, maxWidth: 400 }}
            MenuProps={{
                MenuListProps: {
                    sx: {
                        'li.MuiButtonBase-root': {
                            display: 'flex',
                            flexDirection: 'column',
                        },
                    },
                },
            }}
        >
            {documentTypes.map((d) => (
                <MenuItem key={d.name} value={d.value}>
                    {d.name}
                </MenuItem>
            ))}
        </Select>
    );
}
