import React, { useState, useEffect } from 'react';
import { Colors } from '../../theme';
import Paper from '../paper/Paper';
import Text from '../text/Text';
import ProgressDots from './ProgressDots';
import { Grid } from '@material-ui/core';
import Arrow from '@material-ui/icons/ChevronRight';
import { track } from 'shared';
import usePublicSessionTracking, { PublicSessionEventType } from 'parent-portal/shared/PublicSessionTracking';
import { useHistory } from 'react-router-dom';
import HCGuaranteeIcon from 'assets/icons/tandempay/tandem_guarantee_hc.svg';
import { PrimaryButton } from '../buttons';

interface IProps {
    header: string;
    subHeader?: string;
    tips: ITip[];
    minHeight?: number;
    internalTitle: string;
    onActionClick?: (actionType: string, internalTitle: string) => void;
    elevation?: number;
    padding?: number;
}

interface ITip {
    index: number;
    title: string;
    description: string;
    actions?: ITipAction[];
}

interface ITipAction {
    title: string;
    bold?: boolean;
    action_type: string;
    action_link?: string;
    action_icon?: string;
}

const TandemTips = ({
    minHeight,
    header,
    subHeader,
    tips,
    internalTitle,
    onActionClick,
    elevation = 1,
    padding = 30,
}: IProps) => {
    const [currentTip, setCurrentTip] = useState(tips[0]);
    const { trackSession } = usePublicSessionTracking('Tandem Tips');
    const history = useHistory();
    const [elevationStyle, setElevationStyle] = useState({});

    useEffect(() => {
        track(`TandemTips: ${internalTitle} displayed`);
    }, []);

    useEffect(() => {
        setElevationStyle(elevation === 0 ? { boxShadow: undefined } : {});
    }, [elevation]);

    useEffect(() => {
        setCurrentTip(tips[0]);
    }, [tips]);

    function showNextArrow() {
        return currentTip.index + 1 < tips.length;
    }

    function showBackArrow() {
        return currentTip.index > 0;
    }

    function onArrowPress(increment: number) {
        const trackMessage = `TandemTips: ${internalTitle}: ${increment < 0 ? 'Back' : 'Next'} Click`;
        track(trackMessage, {
            currentTipIndex: currentTip,
            totalTipCount: tips.length,
            currentTipTitle: currentTip.title,
        });
        trackSession('CLICK', trackMessage, PublicSessionEventType.User, {
            nextTipIndex: currentTip.index + increment,
            totalTipCount: tips.length,
            currentTipTitle: currentTip.title,
        });
        setCurrentTip(tips[currentTip.index + increment]);
    }

    function getArrow(increment: number, transformation?: string) {
        return (
            <Grid item onClick={() => onArrowPress(increment)} xs={1} justify="center">
                <Arrow
                    style={{
                        width: 50,
                        height: 50,
                        transform: transformation ? transformation : '',
                    }}
                    color="primary"
                />
            </Grid>
        );
    }

    function handleAction(actionType: string, actionLink?: string) {
        switch (actionType) {
            case 'navigate':
                if (actionLink) {
                    trackSession(
                        'NAVIGATE_FROM',
                        `TandemTips: ${internalTitle}: ${actionLink}`,
                        PublicSessionEventType.User,
                    );
                    history.push(actionLink);
                }
                break;
            case 'openJobForm':
                trackSession('CLICK', `TandemTips: ${internalTitle}: post a job`, PublicSessionEventType.User);
                break;
            case 'setUpTandemPay':
                trackSession('CLICK', `TandemTips: ${internalTitle}: set up tandempay`, PublicSessionEventType.User);
                break;
        }
        if (onActionClick) {
            onActionClick(actionType, internalTitle);
        }
    }

    function getActions() {
        const normalStyle = { textDecoration: 'underline', color: Colors.darkNavy, fontSize: 12, marginRight: 4 };
        const boldStyle = { fontWeight: 'bold', ...normalStyle };
        return (
            <>
                {currentTip.actions?.map((item, index) => (
                    <Grid item key={`${item.title}${index}`} xs={6}>
                        <PrimaryButton onClick={() => handleAction(item.action_type, item?.action_link)}>
                            {item.title}
                        </PrimaryButton>
                        {item?.action_icon ? getActionIcon(item.action_icon) : null}
                    </Grid>
                ))}
            </>
        );
    }

    function getActionIcon(iconName: string) {
        switch (iconName) {
            case 'HCGuaranteeIcon':
                return <HCGuaranteeIcon />;
            default:
                return null;
        }
    }

    function getTip() {
        return (
            <Grid
                container
                item
                xs={10}
                style={{
                    backgroundColor: Colors.lightTurq,
                    borderRadius: 20,
                    padding: 24,
                    minHeight: minHeight ? minHeight : 0,
                }}
            >
                <Grid item xs={12}>
                    <Text
                        bold
                        variant={'body3'}
                        textStyle={{ color: Colors.turquoise, textTransform: 'uppercase', marginBottom: 12 }}
                    >
                        Next Steps
                    </Text>
                </Grid>
                <Grid item>
                    <Text bold variant={'body1'} textStyle={{ fontSize: 20, fontFamily: 'PT Serif', marginBottom: 12 }}>
                        {currentTip.title}
                    </Text>
                </Grid>
                <Grid item style={currentTip?.actions ? { marginBottom: 12 } : {}}>
                    <Text variant={'body2'}>{currentTip.description}</Text>
                </Grid>
                <Grid
                    container
                    item
                    style={{ alignItems: 'flex-end', alignSelf: 'flex-end', alignContent: 'flex-end' }}
                >
                    <ProgressDots activeIndex={currentTip.index} totalCount={tips.length} fillPrevious={false} />
                    {currentTip?.actions ? getActions() : null}
                </Grid>
            </Grid>
        );
    }

    return (
        <Paper
            style={{
                backgroundColor: Colors.white70percent,
                marginBottom: 30,
                ...elevationStyle,
            }}
            padding={padding}
            elevation={elevation}
        >
            <Grid item style={{ marginBottom: !!subHeader ? 8 : 24 }}>
                <Text variant={'h1'} textStyle={{ color: Colors.turquoise, fontFamily: 'PT Serif' }}>
                    {header}
                </Text>
            </Grid>
            {!!subHeader ? (
                <Grid item style={{ marginBottom: 24 }}>
                    <Text bold variant={'body1'}>
                        {subHeader}
                    </Text>
                </Grid>
            ) : (
                <></>
            )}
            <Grid container item direction="row" alignItems="center" justify="space-evenly">
                {!!showBackArrow() ? (
                    getArrow(-1, 'rotate(180deg) translate(12px, 0px)')
                ) : (
                    <Grid item xs={1} justify="center"></Grid>
                )}
                {getTip()}
                {!!showNextArrow() ? getArrow(1) : <Grid item xs={1} justify="center"></Grid>}
            </Grid>
        </Paper>
    );
};

export default TandemTips;
