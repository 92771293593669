import { BusinessSlot } from 'models';
import { formatMinutesFromDate } from 'shared/Dates';

export function getShiftTimes(slot: BusinessSlot) {
    const shift = slot.appointments.length ? slot.appointments[0] : undefined;
    const slotStart = new Date(slot.start_date);
    const slotEnd = new Date(slot.end_date);
    const shiftStart = shift ? new Date(shift.start_date) : slotStart;
    const shiftEnd = shift ? new Date(shift.end_date) : slotEnd;
    const showTimesChanged = shiftStart.getTime() !== slotStart.getTime() || shiftEnd.getTime() !== slotEnd.getTime();
    const infoText = showTimesChanged
        ? `This shift was originally scheduled from ${formatMinutesFromDate(slotStart)} - ${formatMinutesFromDate(slotEnd)}.`
        : undefined;

    return {
        shiftStart,
        shiftEnd,
        infoText,
    };
}
