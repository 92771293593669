import React from 'react';
import { Provider } from 'models';
import { Badge } from '@mui/material';
import CareCertified from 'assets/icons/center-certified.svg';
import { Colors, Text } from 'library';

export default function ProviderProfileImage({
    provider,
    mobile,
    showBadge = false,
    business,
}: {
    provider?: Provider;
    mobile: boolean;
    showBadge?: boolean;
    business?: any;
}) {
    const hasImage = !!provider?.profile_image?.image || !!business?.user.profile_picture?.image;
    return (
        <Badge
            overlap="circular"
            badgeContent={
                showBadge ? (
                    <CareCertified
                        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                        style={{
                            width: mobile ? 28 : 40,
                            height: mobile ? 28 : 40,
                            border: `2px solid ${Colors.white}`,
                            borderRadius: 20,
                            backgroundColor: Colors.white,
                        }}
                    />
                ) : (
                    <></>
                )
            }
            sx={
                hasImage
                    ? {
                          paddingTop: mobile ? 0 : '80%',
                          position: 'relative',
                          width: mobile ? 103 : '80%',
                          height: mobile ? 88 : '100%',
                          borderRadius: '50%',
                          marginBottom: '20px',
                      }
                    : {
                          paddingTop: mobile ? 0 : '80%',
                          position: 'relative',
                          width: mobile ? 103 : '80%',
                          height: mobile ? 88 : '100%',
                      }
            }
        >
            {hasImage ? (
                <div
                    style={{
                        position: 'absolute',
                        top: 0,
                        width: mobile ? 88 : '100%',
                        height: mobile ? 88 : '100%',
                        borderRadius: '50%',
                        backgroundImage: `url(${
                            provider ? provider.profile_image?.image : business.user.profile_picture?.image
                        })`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                    }}
                />
            ) : (
                <div
                    style={{
                        position: 'absolute',
                        top: 0,
                        display: 'flex',
                        width: mobile ? 88 : '100%',
                        height: mobile ? 88 : '100%',
                        borderRadius: '50%',
                        marginBottom: 20,
                        backgroundColor: Colors.darkNavy,
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Text variant="display" color={Colors.white}>
                        {provider ? `${provider.user.first_name[0]} ${provider.user.last_name[0]}` : business.name[0]}
                    </Text>
                </div>
            )}
        </Badge>
    );
}
