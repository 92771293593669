import React, { useState } from 'react';
import { FormFieldProps } from '../../FormTypes';

import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Typography } from '@material-ui/core';
import DateFnsUtils from '@date-io/moment';
import moment from 'moment';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import FormField from '../../FormField';
import { useUserContext } from 'UserContext';
import { useFieldValidation } from '../../FormHook';
import ErrorText from '../../ErrorText';
import { FaCalendarCheck } from 'react-icons/fa';
import { Colors } from 'library';

interface JobDatePickerProps {
    jobDate: FormFieldProps<moment.Moment | null>;
    minDate?: moment.Moment | Date;
    additionalErrorState?: boolean;
    errorMessage?: string;
    dependency?: Array<any>;
    title?: string;
    id?: string;
    maxDate?: moment.Moment | Date;
}

export default function JobDatePicker({
    jobDate,
    additionalErrorState = true,
    dependency = [jobDate.value],
    errorMessage = 'This Date is not valid!',
    title,
    id,
    maxDate,
}: JobDatePickerProps) {
    const { value, setValue, setValid, isValid } = jobDate;
    const { user } = useUserContext();
    const [showErrors, setShowErrors] = useState(false);

    const today = moment();
    const tomorrow = moment().add(1, 'days');
    const payment_setup = !!user?.payment_information && user?.payment_information?.length > 0;
    const minDate = payment_setup ? today : tomorrow;

    useFieldValidation(jobDate, additionalErrorState, dependency);

    function onSelectDate(selectedDate: MaterialUiPickersDate) {
        setShowErrors(true);
        setValue(selectedDate);
    }

    return (
        <FormField title={title}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                    autoOk
                    variant="inline"
                    format="MM/DD/YYYY"
                    mask="__/__/____"
                    value={value}
                    onChange={onSelectDate}
                    openTo="date"
                    showTodayButton={false}
                    minDate={minDate}
                    maxDate={maxDate}
                    style={{ width: '100%' }}
                    keyboardIcon={<FaCalendarCheck color={Colors.darkNavy} />}
                    id={`job-date-picker-${id}`}
                />
            </MuiPickersUtilsProvider>
            {!payment_setup ? (
                <Typography style={{ fontSize: 12 }}>
                    Same day appointments unavailable until payment is set up.
                </Typography>
            ) : null}
            {!isValid && showErrors ? <ErrorText>{errorMessage}</ErrorText> : ''}
        </FormField>
    );
}
