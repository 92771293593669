import React, { ChangeEvent, useEffect, useState } from 'react';
import ErrorText from '../../ErrorText';
import { FormFieldProps } from '../../FormTypes';
import FormField from '../../FormField';
import { Link, OutlinedTextInput } from 'library';
import { Grid, styled } from '@mui/material';

const oneOrMoreNonWhitespaceRegex = /\S+/;
export const tandemRecommendedDescription =
    'We ask that you follow the class schedule, take initiative in the classroom, and stay engaged with the children. Assist in classroom cleaning and organization. Always prioritize the safety of the children by maintaining ratio and absolutely no phone usage in classroom.';

export default function JobDescriptionInput({
    description,
    business = false,
}: {
    description: FormFieldProps<string>;
    business?: boolean;
}) {
    const { value, setValue, isValid, setValid } = description;
    const [showErrors, setShowErrors] = useState(false);

    useEffect(() => setValid(!!value && oneOrMoreNonWhitespaceRegex.test(value)), [value]);

    return (
        <FormField title={`Job ${business ? 'Responsibilities*' : 'Description*'}`}>
            {business && (
                <UseTandemDescriptionSection>
                    <Link onClick={() => setValue(tandemRecommendedDescription)}>
                        Set to Tandem&apos;s recommended description
                    </Link>
                </UseTandemDescriptionSection>
            )}
            <OutlinedTextInput
                fullWidth
                value={value}
                onChange={onValueChanged}
                multiline
                rows={3}
                InputLabelProps={{ shrink: true }}
                id="job-form-description-input"
            />
            {jobDescriptionHasError() && (
                <ErrorText>Please enter {business ? 'job responsibilities' : 'a job description'}</ErrorText>
            )}
        </FormField>
    );

    function onValueChanged(event: ChangeEvent<HTMLInputElement>) {
        setShowErrors(true);

        setValue(event.target.value);
    }

    function jobDescriptionHasError() {
        return showErrors && !isValid;
    }
}

const UseTandemDescriptionSection = styled(Grid)({
    marginTop: 10,
    marginBottom: 10,
});
