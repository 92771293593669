import React from 'react';
import { Grid, Divider } from '@mui/material';
import { Text } from 'library';
import GetStartedCard from './GetStartedCard';

export default function SuperFooter() {
    const welcomeTextLineOne = 'Join us today. We promise';
    const welcomeTextLineTwo = 'tomorrow will be better.';

    return (
        <Grid container direction="column">
            <Divider style={{ background: '#ED6A5A', height: '42px' }} orientation="horizontal" />
            <Grid
                container
                style={{
                    backgroundColor: '#FDFDFD',
                    padding: 50,
                    background: 'linear-gradient(to bottom, #FFFFFF 0%, #FFFFFF 50%, #FBFBFB 50%, #FBFBFB 100%)',
                }}
                alignItems="center"
            >
                <Grid container direction="column" alignItems="center" style={{ marginBottom: '2%' }}>
                    <Text variant="display">{welcomeTextLineOne}</Text>
                    <Text variant="display">{welcomeTextLineTwo}</Text>
                </Grid>
                <Grid
                    container
                    direction="row"
                    style={{ marginTop: 20 }}
                    justifyContent="center"
                    alignItems="space-around"
                >
                    <GetStartedCard audience="centers" />
                    <GetStartedCard audience="workers" />
                </Grid>
            </Grid>
        </Grid>
    );
}
