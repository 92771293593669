import React, { useEffect, useState } from 'react';
import { AutobookHistory, getAutobookHistory } from './autobookHistoryApi';
import { PagingResult } from 'models';
import PaginatedTable from 'internal/shared/tables/PaginatedAdminTable';
import { TableCell, Grid } from '@mui/material';
import Row from 'internal/shared/tables/Row';
import { Colors, Text } from 'library';
import { format } from 'date-fns';
import { consoleLogInDev } from 'shared';
import RegionMultiSelect from 'internal/shared/RegionMultiSelect';

const AutobookHistoryPage = () => {
    const [historyData, setHistoryData] = useState<PagingResult<AutobookHistory>>();
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [regions, setRegions] = useState<number[]>([]);

    useEffect(() => {
        setLoading(true);
        getAutobookHistory(page, regions)
            .then(setHistoryData)
            .catch(consoleLogInDev)
            .finally(() => setLoading(false));
    }, [page, regions]);

    const columns = [
        'id',
        'last updated',
        'action',
        'reason',
        'worker user id',
        'worker name',
        'job id',
        'job headline',
        'business location name',
    ];

    const updateRegions = (newRegions: number[]) => {
        setRegions(newRegions);
        setPage(1);
    };

    return (
        <Grid margin={10}>
            <Text variant="h1">Autobook History</Text>
            <RegionMultiSelect regions={regions} setRegions={updateRegions} />
            {historyData && (
                <PaginatedTable loading={loading} columns={columns} data={historyData} page={page} updatePage={setPage}>
                    {historyData.results.map((item) => (
                        <Row key={item.id} oddRowColor={Colors.lightTurq}>
                            <TableCell>{item.id}</TableCell>
                            <TableCell>{format(new Date(item.last_updated), 'MM/dd/yyyy p')}</TableCell>
                            <TableCell>{item.autobook_action}</TableCell>
                            <TableCell>{item.reason}</TableCell>
                            <TableCell>{item.user.id}</TableCell>
                            <TableCell>
                                {item.user.first_name} {item.user.last_name}
                            </TableCell>
                            <TableCell>{item.job.id}</TableCell>
                            <TableCell>{item.job.headline}</TableCell>
                            <TableCell>{item.job.location_name}</TableCell>
                        </Row>
                    ))}
                </PaginatedTable>
            )}
        </Grid>
    );
};

export default AutobookHistoryPage;
