import React, { createContext, useContext, useState } from 'react';
import { track } from 'shared';
import { useUserContext } from 'UserContext';
import { useHistory } from 'react-router';
import { JobFormPresets } from 'parent-portal/forms/JobFormPresets';


export const ModalsContext = createContext({
    jobFormOpen: false,
    businessJobFormOpen: false,

    openJobForm: (location: string, presets?: JobFormPresets, onClose?: () => void) => {},
    closeJobForm: (onClose?: () => void) => {},
    closeBusinessJobForm: (onClose?: () => void) => {},

    jobFormPresets: {},
});

export function ModalsContextProvider({ children }: { children?: React.ReactNode }) {
    const [jobFormOpen, setJobFormOpen] = useState(false);
    const [businessJobFormOpen, setBusinessJobFormOpen] = useState(false);
    const [jobFormPresets, setJobFormPresets] = useState({});
    const [jobFormOnClose, setJobFormOnClose] = useState<() => void>();

    const [location, setLocation] = useState('');
    const { role } = useUserContext();
    const history = useHistory();

    function openJobForm(location: string, presets?: JobFormPresets, onClose?: () => void) {
        track('Opened job form modal', { location: location, businessForm: role === 'business_active' });
        setLocation(location);
        if (onClose) setJobFormOnClose(() => onClose);
        const multipleProviders = role === 'business_active';
        presets && setJobFormPresets({ ...presets, multipleProviders });
        if (role === 'business_active') {
            if (!presets) {
                setJobFormPresets({ multipleProviders });
            }
            setBusinessJobFormOpen(true);
        } else {
            setJobFormOpen(true);
        }
    }

    function closeJobForm() {
        track('Closed job form modal', { location: location, businessForm: false });
        setJobFormOpen(false);
        jobFormOnClose && jobFormOnClose();
        setLocation('');
        setJobFormPresets({});
        setJobFormOnClose(undefined);
        if (history.location.pathname.includes('/jobs')) history.go(0);
    }

    function closeBusinessJobForm() {
        track('Closed job form modal', { location: location, businessForm: true });
        setBusinessJobFormOpen(false);
        jobFormOnClose && jobFormOnClose();
        setLocation('');
        setJobFormPresets({});
        setJobFormOnClose(undefined);
    }

    const contextValue = {
        jobFormOpen,
        businessJobFormOpen,
        openJobForm,
        closeJobForm,
        closeBusinessJobForm,
        jobFormPresets,
    };

    return <ModalsContext.Provider value={contextValue}>{children}</ModalsContext.Provider>;
}

export function useModalsContext() {
    return useContext(ModalsContext);
}
