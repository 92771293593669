import { Grid } from '@mui/material';
import React from 'react';
import { Text, Colors } from 'library';

export default function HowToDeleteAcct() {
    return (
        <Grid container justifyContent="center" style={{ height: '40vh', paddingTop: 100 }}>
            <Grid container item xs={10} sm={8} md={6} flexDirection="column" style={{ gap: 40 }}>
                <Text variant="h1">How to delete your account on the Tandem App by Join Tandem</Text>
                <Text>
                    To delete your account and its associated data, please log into the mobile app on your phone and
                    navigate to the profile tab. Once there click the settings (gear) icon next to your name, scroll to
                    the bottom and click the Delete Account button. This will delete your email and phone number.
                </Text>
                <Text>
                    If you would rather or want all of your data removed, email{' '}
                    <a
                        style={{ color: Colors.darkNavy, textDecorationLine: 'underline' }}
                        href="mailto:support@join-tandem.com"
                    >
                        support@join-tandem.com
                    </a>{' '}
                    to fully delete your account. This may take up to 14 days to complete deletion.
                </Text>
            </Grid>
        </Grid>
    );
}
