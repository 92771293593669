import React from 'react';
import { useHistory } from 'react-router';
import AuthModal from 'parent-portal/login/AuthModal';
import PersonIcon from '@mui/icons-material/Person';
import { Colors, Text } from 'library';
import Header from './Header';
import { Grid } from '@mui/material';

export function ElderCareBar({
    openLoginModal,
    setOpenLoginModal,
    handleSignInClick,
}: {
    openLoginModal: boolean;
    setOpenLoginModal: React.Dispatch<React.SetStateAction<boolean>>;
    handleSignInClick: () => void;
}) {
    const location = `Public Header: Home Page`;
    const history = useHistory();
    return (
        <>
            <Grid container style={{ padding: 30, backgroundColor: Colors.darkNavy }}>
                <Grid container item style={{ flex: 1, flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Grid item>
                        <Header />
                    </Grid>
                    <Grid container item style={{ width: 'auto' }}>
                        <Grid item container direction="row" spacing={2} style={styles.signIn}>
                            <Grid item onClick={handleSignInClick} style={{ display: 'flex' }}>
                                <span style={styles.iconSpan}>
                                    <PersonIcon style={{ fontSize: 18 }} htmlColor={Colors.white} />
                                </span>
                                <Text variant="body1" color={Colors.white} textStyle={{ cursor: 'pointer' }}>
                                    Sign In
                                </Text>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <AuthModal
                open={openLoginModal}
                onClose={() => setOpenLoginModal(false)}
                onComplete={() => history.push('/home')}
                location={location}
            />
        </>
    );
}
const styles = {
    iconSpan: {
        marginRight: 12,
        borderColor: Colors.white,
        borderStyle: 'solid',
        borderRadius: 12,
        height: 24,
        width: 24,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    signIn: {
        width: 'auto',
        alignItems: 'center',
        paddingLeft: 12,
        marginLeft: 12,
    },
};
