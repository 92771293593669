import React from 'react';
import { Colors, SmallIcon, Text } from 'library';
import { Grid, styled } from '@mui/material';
import FaqAnswer, { IFAQQuestion } from 'parent-portal/business-profile/BusinessFaq/FaqAnswer';
import { IBusinessLocation } from 'models';
import { Star } from '@mui/icons-material';

export default function BusinessFaqSection({
    faqQuestions,
    updateQuestion,
    businessLocation,
}: {
    faqQuestions: IFAQQuestion[];
    updateQuestion: (q: IFAQQuestion) => void;
    businessLocation: IBusinessLocation;
}) {
    const BooleanAnswerText = [
        'Prevent providers from clocking in early?',
        'Prevent providers from clocking out late?',
    ];
    const viewableQuestions = faqQuestions.filter((q) => !BooleanAnswerText.includes(q.question));
    return (
        <Grid
            container
            item
            style={{ backgroundColor: Colors.white, borderRadius: 18, padding: 20 }}
            direction="column"
        >
            <Text variant="body1" bold>
                Frequently Asked Questions
            </Text>
            <FaqSharedNotice container direction="row">
                <SmallIcon icon={<Star />} color={Colors.terraCotta} backgroundColor={Colors.lightTerraCotta} />
                <Text variant="body2">
                    <Text variant="body2" bold>
                        This info will be shared with the confirmed Tandem teacher.
                    </Text>{' '}
                    The more info we can share with the teacher the better! This will prepare them for their shift and
                    allow them to meet your expectations.
                </Text>
            </FaqSharedNotice>
            {viewableQuestions.map((q: IFAQQuestion) => (
                <FaqAnswer key={q.question} question={q} location={businessLocation} updateQuestion={updateQuestion} />
            ))}
        </Grid>
    );
}

const FaqSharedNotice = styled(Grid)({
    marginTop: 20,
    marginBottom: 20,
    gap: 10,
    borderRadius: 18,
    padding: 20,
    backgroundColor: Colors.lightTerraCotta,
});
