import React from 'react';
import { Chip, Colors } from 'library';
import { BusinessJobTypes } from '../JobRequestTypes';

export default function BusinessJobTypeChip({ businessJobType }: { businessJobType?: BusinessJobTypes }) {
    return businessJobType ? (
        <Chip
            label={businessJobType.replace('_', ' ').toLowerCase().capitalize()}
            backgroundColor={businessJobType === 'SUBSTITUTE' ? Colors.terraCotta : Colors.mediumNavy}
            textColor={Colors.white}
            style={styles.chip}
        />
    ) : null;
}

const styles = {
    chip: {
        height: 20,
        fontSize: 12,
        minHeight: 20,
        boxShadow: '0px 1px 10px rgba(0,1,1,0.15)',
    },
};
