import React, { useState } from 'react';
import { styled, Tab } from '@mui/material';
import { TabContext, TabList } from '@mui/lab';
import { Colors, Text } from 'library';
import { AdminListProvider } from 'models';
import SwipeableViews from 'react-swipeable-views';
import AppliedJobsMenu from 'internal/providers/AppliedJobsMenu';
import ReverseMatchingList from '../../ReverseMatchingList';
import CenterMatchingList from '../CenterMatchingList';
import { BusinessLocationsContextProvider } from 'internal/business-locations/BusinessLocationsContext';

export default function ReverseMatchingTabs({
    provider,
    updateProvider,
    initialTabIndex,
    initialWeek,
}: {
    provider: AdminListProvider;
    updateProvider?: (providerId: number, fields: Partial<AdminListProvider>) => void;
    initialTabIndex?: number;
    initialWeek?: Date;
}) {
    const [selectedTab, setSelectedTab] = useState(initialTabIndex?.toString() ?? '0');
    return (
        <div>
            {provider.user ? (
                <Text bold>
                    {provider.user.first_name} {provider.user.last_name} ({provider.user.id})
                </Text>
            ) : (
                <></>
            )}
            <TabContext value={selectedTab}>
                <TabList
                    onChange={(e, value: string) => setSelectedTab(value)}
                    variant="scrollable"
                    allowScrollButtonsMobile
                    textColor="inherit"
                    TabIndicatorProps={{ style: { backgroundColor: Colors.turquoise, height: 3 } }}
                >
                    <Tab label="Applied Jobs" value="0" sx={classes.tabs} />
                    <Tab label="Matching" value="1" sx={classes.tabs} />
                    <Tab label="Center Matching" value="2" sx={classes.tabs} />
                </TabList>
                <SwipeableViews
                    index={parseInt(selectedTab)}
                    onChangeIndex={(index: number) => setSelectedTab(index.toString())}
                >
                    <TabDiv selectedTab={selectedTab} tab="0">
                        <AppliedJobsMenu open={selectedTab === '0'} user={provider.user} />
                    </TabDiv>
                    <TabDiv selectedTab={selectedTab} tab="1">
                        <ReverseMatchingList
                            provider={provider}
                            open={selectedTab === '1'}
                            updateProvider={updateProvider}
                            initialWeek={initialWeek}
                        />
                    </TabDiv>
                    <TabDiv selectedTab={selectedTab} tab="2">
                        <BusinessLocationsContextProvider>
                            <CenterMatchingList provider={provider} />
                        </BusinessLocationsContextProvider>
                    </TabDiv>
                </SwipeableViews>
            </TabContext>
        </div>
    );
}

const classes = {
    tabs: {
        '&.MuiButtonBase-root.MuiTab-root': {
            fontSize: 16,
            fontWeight: 'bold',
            textTransform: 'none',
            outline: 'none',
        },
        '&.MuiTab-textColorInherit': {
            color: Colors.darkNavy,
        },
        '&.Mui-selected': {
            color: Colors.turquoise,
        },
    },
    panels: {
        '&.MuiTabPanel-root': {
            paddingTop: '20px',
            paddingBottom: '20px',
        },
    },
};

const TabDiv = styled('div')(({ selectedTab, tab }: { selectedTab: string; tab: string }) => ({
    visibility: selectedTab === tab ? 'visible' : 'hidden',
    ...classes.panels,
}));
