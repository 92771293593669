import { Grid } from '@material-ui/core';
import { Text } from 'library';
import React from 'react';

export default function UnsupportedRegionCallout() {
    return (
        <Grid container direction="column" style={{ alignItems: 'center', textAlign: 'center' }}>
            <Grid item>
                <Text variant="display">We're sorry, but your location is not supported.</Text>
            </Grid>
            <Grid item style={{ padding: '60px 0' }}>
                {/* <Text variant="display">Placeholder Image Here</Text> */}
            </Grid>
            <Grid item>
                <Text variant="h1">
                    Tandem right now is only available in Columbus, Cleveland, and Cincinnati. Please check back as we
                    expand into different areas!
                </Text>
            </Grid>
        </Grid>
    );
}
