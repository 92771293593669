import React from 'react';
import { Colors, OptionButton } from 'library';
import { useOnboardingContext } from '../../OnboardingContext';
import { useModalsContext } from 'ModalsContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarPlus } from '@fortawesome/free-solid-svg-icons';

export default function Substitute() {
    const title = 'Yes, I have specific dates in mind.';
    const { submitAction } = useOnboardingContext();
    const { openJobForm } = useModalsContext();

    const action = () => {
        submitAction(
            () =>
                openJobForm('Onboarding sub', {
                    payType: 'PERCENTAGE',
                    businessJobType: 'SUBSTITUTE',
                    pageNumber: 1,
                }),
            'Sub',
            title,
        );
    };

    return (
        <OptionButton
            title={title}
            highlight="Let's get started."
            subtitle="We can save your job details, and you can update or cancel at any time."
            icon={<FontAwesomeIcon icon={faCalendarPlus} color={Colors.turquoise} size="2x" />}
            action={action}
        />
    );
}
