import React from 'react';
import { Colors, Text } from 'library';
import { AdminProvider } from 'models';

const SesameAppointmentInfo = ({ provider }: { provider: AdminProvider }) => {
    const props = provider.sesame_appointment;
    if (!props) return null;

    const link = props.transaction_id ? `https://sesamecare.com/account/appointments/${props.transaction_id}` : null;

    return (
        <>
            <Text variant="h2" bold textStyle={{ margin: 10, color: Colors.turquoise }}>
                Sesame Appointment Info
            </Text>

            <div style={{ marginLeft: 10, marginBottom: 10, display: 'flex', flexDirection: 'column', gap: 4 }}>
                <Text variant="body2">{`Internal Appointment ID: ${props.id}`}</Text>
                {props.booked_at && (
                    <>
                        <Text variant="body2">{`Time: ${props.booked_appointment_datetime}`}</Text>
                        <Text variant="body2">{`Doctor: ${props.doctor_name}`}</Text>
                        <Text variant="body2">{`Booked on: ${props.booked_at}`}</Text>
                        <Text variant="body2">{`Video Call: ${props.video_link}`}</Text>
                    </>
                )}

                {link && (
                    <div style={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
                        <Text variant="body2">{'More Info:'}</Text>
                        <a href={link} target="_blank" rel="noreferrer">
                            {link}
                        </a>
                    </div>
                )}
            </div>
        </>
    );
};

export default SesameAppointmentInfo;
