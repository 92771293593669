import React, { useEffect, useState } from 'react';
import { Grid, useMediaQuery } from '@mui/material';
import { OutlinedTextInput, PrimaryButton, Text, SizeableRoundedDialog } from 'library';
import theme from 'theme';
import { BusinessJobResponse } from './models';
import useLoading from 'library/hooks/useLoading';
import { client } from 'shared';

export default function EditDescriptionModal({
    open,
    onClose,
    businessJobResponse,
    onSaveComplete,
}: {
    open: boolean;
    onClose: () => void;
    businessJobResponse: BusinessJobResponse;
    onSaveComplete: () => void;
}) {
    const small = useMediaQuery(theme.breakpoints.down('sm'));
    const { loading, setLoading } = useLoading();
    const [title, setTitle] = useState<string>(businessJobResponse.job_details.headline);
    const [description, setDescription] = useState(businessJobResponse.job_details.family_comments);
    const [benefits, setBenefits] = useState(businessJobResponse.job_details.trial_run_benefits);

    useEffect(() => {
        setTitle(businessJobResponse.job_details.headline);
        setDescription(businessJobResponse.job_details.family_comments);
    }, [businessJobResponse]);

    function submit() {
        setLoading(true);
        client(`api/ongoing/${businessJobResponse?.job_details?.id}/`, {
            method: 'PATCH',
            body: { headline: title, family_comments: description, trial_run_benefits: benefits },
        })
            .then(() => onSaveComplete())
            .finally(() => {
                setLoading(false);
                onClose();
            });
    }

    return (
        <SizeableRoundedDialog
            open={open}
            onClose={onClose}
            fullScreen={small}
            closeButton
            maxWidth="md"
            style={{ maxWidth: 500 }}
        >
            <Grid container item direction="column" style={{ padding: 20, gap: 10 }}>
                <Text variant="h1">Update Details</Text>
                <OutlinedTextInput
                    label="Title"
                    style={{ padding: 10 }}
                    fullWidth
                    onChange={(e) => setTitle(e.target.value)}
                    value={title}
                />
                <OutlinedTextInput
                    label="Description"
                    style={{ padding: 10 }}
                    multiline
                    fullWidth
                    onChange={(e) => setDescription(e.target.value)}
                    value={description}
                />
                {businessJobResponse.job_details.business_job_type === 'TRIAL_RUN' && (
                    <OutlinedTextInput
                        label="What benefits do you offer to permanent staff?"
                        style={{ padding: 10 }}
                        multiline
                        fullWidth
                        onChange={(e) => setBenefits(e.target.value)}
                        value={benefits}
                    />
                )}
                <PrimaryButton
                    loading={loading}
                    disabled={loading}
                    onClick={submit}
                    buttonStyle={{ width: undefined, alignSelf: 'flex-end' }}
                >
                    Save
                </PrimaryButton>
            </Grid>
        </SizeableRoundedDialog>
    );
}
