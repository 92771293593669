import { BusinessJobWithAppointments } from 'models';
import { queryStringifyObject } from 'shared';
import base64 from 'base-64';
import { useAdminAppContext } from 'internal/shared/AdminAppContext';

const useMatchingListQuery = (): ((
    job: BusinessJobWithAppointments,
    distance: number,
    week: Date | undefined,
    qualified: boolean,
    available: boolean,
    vettedInLastXDays?: number,
    unbookedDate?: string,
    page?: number,
) => string) => {
    const { getLabelByName } = useAdminAppContext();
    const generateQueryParams = (
        job: BusinessJobWithAppointments,
        distance: number,
        week: Date | undefined,
        qualified: boolean,
        available: boolean,
        vettedInLastXDays?: number,
        unbookedDate?: string,
        page?: number,
    ) => {
        const available_week = week ? week.toISOString() : undefined;

        return queryStringifyObject({
            distance: distance,
            distance_job: job.id,
            available_for_job: available ? job.id : undefined,
            encoded_qualifications: qualified ? generateQualifications(job) : undefined,
            vetted_within_last_x_days: vettedInLastXDays,
            include_stats: true,
            include_qualifications: true,
            availability_week: available_week,
            unbooked_date: unbookedDate,
            recently_active: true,
            include_labels: getLabelByName('admin-providers', '✅')?.id,
            page: page,
        });
    };

    return generateQueryParams;
};

export default useMatchingListQuery;

const generateQualifications = (job: BusinessJobWithAppointments) => {
    const minPayScale = job.pay_scales?.sort((a, b) => a.rate - b.rate)[0];
    const qualifications = minPayScale?.qualifications || [];
    return base64.encode(JSON.stringify(qualifications));
};
