import { IPayScale } from 'parent-portal/forms/JobRequestTypes';
import { BASE_EDUACTION_RECOMMENDATION, EDUCATION_PAY_RATE } from './recommendationConstants';

const FORTY_45_HOUR_REQ_LOOKUP = '45';
// eslint-disable-next-line no-comments/disallowComments
/**
 * Because there can be multiple entries for each rate
 * this function accept an array of them
 *
 * It goes highest to lowest and returns early so only one rule is returned for each
 */
export function getFloridaRecommendations(payScales: IPayScale[]): string[] {
    const recs: string[] = [];

    payScales.forEach((x) => {
        if (x.rate < EDUCATION_PAY_RATE) {
            if (x.qualifications.some((q) => q.name.toLowerCase().includes('degree'))) {
                recs.push(BASE_EDUACTION_RECOMMENDATION);
                return;
            }
            if (
                x.qualifications.some((q) =>
                    ['VPK', 'Staff', 'Director'].some((y) => q.name.toLowerCase().includes(y.toLowerCase())),
                )
            ) {
                recs.push('Remove the required credential or increase the pay to $15/hr');
                return;
            }
        }

        if (x.rate < 13 && x.qualifications.some((q) => q.name.toLowerCase().includes(FORTY_45_HOUR_REQ_LOOKUP))) {
            recs.push(`Remove ${FORTY_45_HOUR_REQ_LOOKUP} hours completed or increase the pay to $13/hr`);
            return;
        }
    });

    return recs;
}
