import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import Dropdown from './Dropdown';
import { Colors } from 'library';

const useStyles = makeStyles({
    root: {
        borderColor: Colors.mediumNavy,
        borderStyle: 'solid',
        paddingTop: 5,
        paddingLeft: 20,
        paddingRight: 20,
        borderRadius: 25,
    },
});

interface OutlineDropdownProps {
    value?: string;
    title?: string;
    options?: object;
    onChange?: (e: string) => void;
    transparent?: boolean;
    disabled?: boolean;
}

const OutlineDropdown = ({ ...props }: OutlineDropdownProps) => {
    const classes = useStyles();
    return (
        <Grid container item xs={12} classes={classes}>
            <Dropdown {...props} />
        </Grid>
    );
};

export default OutlineDropdown;
