import { ProviderQualifications } from '../providerProfileModels';

function getPrioritizedCredentials(
    providerQualifications: ProviderQualifications,
    childcareCenterQualifications: any = [],
) {
    const cplLevel =
        providerQualifications.cpl_level && providerQualifications.cpl_level !== 'I'
            ? `CPL ${providerQualifications.cpl_level}`
            : '';
    const cda =
        providerQualifications.education.length > 0 && providerQualifications.education[0].award_name?.includes('CDA')
            ? providerQualifications.education[0].award_name
            : childcareCenterQualifications.length > 0 && childcareCenterQualifications[0].cdc_certification
            ? 'CDA'
            : '';
    const edu =
        providerQualifications.education.length > 0 &&
        providerQualifications.education[0].award_name &&
        !providerQualifications.education[0].award_name.includes('High School') &&
        !providerQualifications.education[0].award_name.includes('CDA')
            ? `${providerQualifications.education[0].award_name}${
                  providerQualifications.education[0].major_name
                      ? ` ${providerQualifications.education[0].major_name}`
                      : ''
              }`
            : '';
    const occrraTraining =
        providerQualifications.professional_development_courses.filter(
            (course) => course.verification_status === 'Verified',
        ).length > 0 ||
        (childcareCenterQualifications.length > 0 && childcareCenterQualifications[0].occrra_training_description)
            ? 'OCCRRA training'
            : '';
    const yearsInChildcare =
        providerQualifications.years_childcare_experience > 0
            ? `${providerQualifications.years_childcare_experience} year${
                  providerQualifications.years_childcare_experience === 1 ? '' : 's'
              } experience`
            : '';
    const prioritizedCredentials = [cplLevel, cda, edu, occrraTraining, yearsInChildcare]
        .filter((entry) => entry !== '')
        .join(', ');
    return prioritizedCredentials;
}

export { getPrioritizedCredentials };
