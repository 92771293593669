import React from 'react';
import { Grid } from '@material-ui/core';
import { Message } from 'models/Message';
import { Colors, Text } from 'library';
import { Thread } from './Thread';
import { ThreadHeader } from './ThreadHeader/ThreadHeader';
import { IMessageForm } from './models';

export default function MobileMessageForm({
    providerIdObject,
    providerName,
    messages,
    locked,
    bottomOfThread,
    user,
    MessageInput,
    providerImage,
    role,
}: IMessageForm) {
    return (
        <Grid key="mobile-message" xs={12} style={{ backgroundColor: Colors.white, width: '100%' }}>
            <div style={{ position: 'sticky', top: '40px', left: 0 }}>
                <ThreadHeader providerIdObject={providerIdObject} providerName={providerName ?? ''} role={role} />
            </div>
            <Grid>
                <Grid
                    style={{
                        overflowY: 'scroll',
                        padding: 5,
                        paddingTop: '10vh',
                        paddingBottom: '1vh',
                        minHeight: '70vh',
                    }}
                >
                    {messages &&
                        messages.map((message: Message) => (
                            <Thread key={message.id} message={message} user={user} providerImage={providerImage} />
                        ))}
                    <Grid ref={bottomOfThread} />
                </Grid>
            </Grid>
            <Grid style={{ position: 'sticky', bottom: 0, left: 0, backgroundColor: Colors.white }}>
                <hr
                    style={{
                        color: Colors.darkGrey,
                        width: '100%',
                        padding: 0,
                    }}
                />
                <Grid container item xs={12}>
                    {locked ? <Text bold>This chat is from a previous shift and is read only.</Text> : MessageInput}
                </Grid>
            </Grid>
        </Grid>
    );
}
