import { useClient } from 'shared';

export const useFaqQuestions = () =>
    useClient<
        {
            id: number;
            question: string;
            multiline: boolean;
        }[]
    >({ url: 'api/job-faq-questions/' });
