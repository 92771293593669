import { JobTimesOfDay } from 'models';
import React from 'react';
import { to12HourTime } from 'shared/Dates';
import { Colors } from 'library';
import { styled } from '@mui/material';
import AdminButton from 'internal/shared/AdminButton';
import { faSquarePlus } from '@fortawesome/free-solid-svg-icons';
import { addSlot } from './slotsApi';
import { isPast, addDays } from 'date-fns';

export default function AddSlotButton({
    jobId,
    utcOffset,
    refreshJobs,
    times,
    date,
}: {
    jobId: number;
    utcOffset: number;
    refreshJobs: () => void;
    times?: JobTimesOfDay;
    date: Date;
}) {
    const timesForDay = times ? Object.values(times)[0] : null;
    return isPast(addDays(date, 1)) ? null : (
        <ButtonDiv>
            <AdminButton
                text={
                    timesForDay
                        ? `Add slot ${to12HourTime(timesForDay.start)} - ${to12HourTime(timesForDay.end)}`
                        : `Add slot 8:00am - 5:00pm`
                }
                FontAwesomeImg={faSquarePlus}
                color={Colors.lightGrey}
                onClick={() => addSlot(jobId, date, utcOffset, times).then(refreshJobs)}
            />
        </ButtonDiv>
    );
}

const ButtonDiv = styled('div')({
    border: 'solid black 1px',
    backgroundColor: Colors.lightGrey,
    marginBottom: 3,
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    borderRadius: 8,
});
