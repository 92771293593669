import React, { useEffect, useState } from 'react';
import { IconButton } from 'library';
import { ModelTraining } from '@mui/icons-material';
import streamRequest from 'shared/StreamRequest';

const ProgressDetails = ({
    isOpen,
    onClose,
    children,
}: {
    isOpen: boolean;
    onClose: () => void;
    children: React.ReactNode;
}) => {
    if (!isOpen) {
        return null;
    }

    const progressInfoContainer: React.CSSProperties = {
        backgroundColor: 'white',
        padding: '20px',
        borderRadius: '8px',
        width: '600px',
        maxWidth: '100%',
        border: '1px solid #ccc',
        position: 'absolute',
        right: '20px',
        top: '60px',
        zIndex: 100,
    };

    const closeButton: React.CSSProperties = {
        position: 'absolute',
        top: '10px',
        right: '10px',
        background: 'transparent',
        border: 'none',
        cursor: 'pointer',
    };

    return (
        <div style={progressInfoContainer}>
            <button onClick={onClose} style={closeButton}>
                Close
            </button>
            <div
                style={{
                    marginTop: '20px',
                    fontSize: '12px',
                }}
            >
                {children}
            </div>
        </div>
    );
};

interface ProgressItem {
    name?: string;
    status?: string;
    result?: string;
}

const ProgressItemDisplay = ({ name, status, result }: ProgressItem) => {
    const statusColors: { [key: string]: string } = {
        Success: 'green',
        Failed: 'red',
        Running: 'orange',
        Pending: 'gray',
    };
    const hasStatus = status && Object.keys(statusColors).includes(status);
    // eslint-disable-next-line security/detect-object-injection
    const statusColor = hasStatus ? statusColors[status] : 'black';

    return (
        <div
            style={{
                display: 'grid',
                gridTemplateColumns: 'repeat(3, 1fr)',
                gridTemplateRows: '1fr',
                fontWeight: 'bold',
                gap: '10px',
            }}
        >
            <span>{name}</span>
            <span style={{ textAlign: 'center', color: statusColor }}>{status}</span>
            <span style={{ fontWeight: 'bolder', textAlign: 'center' }}>{result}</span>
        </div>
    );
};

const StreamRefreshInfoCollect = ({
    streamEndpoint,
    refreshActions,
}: {
    streamEndpoint: string;
    refreshActions?: string[];
}) => {
    const [progressItems, setProgressItems] = useState<ProgressItem[]>([]);
    const [isDetailsOpen, setDetailsOpen] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [refreshAction, setRefreshAction] = useState<string>();

    useEffect(() => {
        if (refreshAction) {
            handleRefresh();
        }
    }, [refreshAction]);

    const handleOpenDetails = () => {
        setDetailsOpen(true);
        if (!refreshActions) {
            handleRefresh();
        }
    };

    const handleRefresh = async () => {
        streamRequest({
            endpoint: streamEndpoint,
            action: refreshAction,
            onYield: (data: string) => {
                const no_prefix = data.replace('data: ', '');
                const err = no_prefix.includes('ERR:');
                const split = no_prefix.split('|');
                const items: ProgressItem[] = split.map((item) => {
                    const [name, status, result] = item.split('-');
                    return {
                        name,
                        status,
                        result: err ? 'See Network Tab for log' : result,
                    };
                });
                setProgressItems(items);
            },
            onError: () => {
                setHasError(true);
                setRefreshAction(undefined);
            },

            onComplete: () => {
                // eslint-disable-next-line array-func/prefer-array-from
                const items = [...progressItems];
                const lastItem = items.length > 0 && items[items.length - 1];
                if (lastItem) {
                    lastItem.status = 'Success';
                    setProgressItems(items);
                    setRefreshAction(undefined);
                }
            },
        });
    };

    const handleCloseDetails = () => {
        setProgressItems([]);
        setDetailsOpen(false);
        setHasError(false);
        setRefreshAction(undefined);
    };

    const RefreshButtons = () => {
        return (
            <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
                {refreshActions?.map((action) => (
                    <button
                        key={action}
                        onClick={() => setRefreshAction(action)}
                        style={{
                            padding: '5px',
                            border: '1px solid #ccc',
                            borderRadius: '5px',
                            cursor: 'pointer',
                            backgroundColor: refreshAction === action ? 'lightblue' : 'white',
                        }}
                    >
                        {action}
                    </button>
                ))}
            </div>
        );
    };

    const StatefulDisplay = () => {
        if (hasError) {
            return <span style={{ fontSize: 24, fontWeight: 'bolder' }}>Error occurred</span>;
        } else if (progressItems && progressItems?.length > 0) {
            return (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    {progressItems.map((item, index) => (
                        <ProgressItemDisplay key={index} {...item} />
                    ))}
                </div>
            );
        } else {
            return <span style={{ fontSize: 24, fontWeight: 'bolder' }}>Please wait...</span>;
        }
    };

    return (
        <div style={{ flexDirection: 'column', display: 'flex', gap: 10 }}>
            <IconButton
                tooltip="Refresh worker info collect"
                icon={ModelTraining}
                onClick={() => handleOpenDetails()}
                style={{ marginLeft: 8 }}
            />
            <ProgressDetails isOpen={isDetailsOpen} onClose={handleCloseDetails}>
                {refreshActions && !refreshAction ? <RefreshButtons /> : <StatefulDisplay />}
            </ProgressDetails>
        </div>
    );
};

export default StreamRefreshInfoCollect;
