import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import { StyledLink } from '../../../../reusableComponents/styledItems';
import { SimpleTextDialog } from '../../../../reusableComponents/dialogsSimple';
import { CancelApptOrRequestDialog } from '../ViewMoreOptions';
import RequestNewSitterDialog from '../RequestNewSitterDialog';
import { WhyCancelDialog } from './CancellationDialog';
import moment from 'moment';
import { client, consoleLogInDev } from 'shared';
import { SecondaryButton } from 'library';

/*
BEWARE: THE JOB OBJECT REFERENCED HERE IS A WEIRD COMBO OF APPOINTMENT AND ONGOING REQUEST
The id on the job is the appointment id
*/
class JobCardDialogs extends Component {
    constructor() {
        super();
        this.state = {
            showCancelOnetimeDialog: false,
            showCancelApptOrRequestDialog: false,
            showWhyCancelDialog: false,
            showNewSitterDialog: false,
            reasonDict: {},
            reasonType: '',
            cancelType: '',
        };
    }

    getCancellationReasons = async () => {
        const { job: appointment } = this.props;
        try {
            const reasons = await client('api/reason/' + appointment.id + '/questions/');

            this.setState({ reasonDict: reasons.primary_reasons, reasonType: reasons.reason_type });
        } catch (e) {
            consoleLogInDev(e);
        }
    };

    getCardLinks = () => {
        const { job: appointment } = this.props;

        return (
            <>
                {!appointment.ongoing_request.deactivated ? (
                    <>
                        {appointment.ongoing ? (
                            <SecondaryButton
                                buttonStyle={{ maxWidth: 200 }}
                                onClick={() => this.viewCancelApptOrRequestDialog(true)}
                            >
                                Cancel Job
                            </SecondaryButton>
                        ) : (
                            <SecondaryButton
                                buttonStyle={{ maxWidth: 200 }}
                                onClick={() => {
                                    this.viewCancelOnetimeDialog(true);
                                    this.getCancellationReasons();
                                }}
                            >
                                Cancel Job
                            </SecondaryButton>
                        )}
                    </>
                ) : (
                    <Grid item>
                        <StyledLink
                            color="primary"
                            component="button"
                            variant="subtitle2"
                            underline="always"
                            onClick={() => window.open('mailto:support@join-tandem.com', '_blank')}
                        >
                            Contact to cancel
                        </StyledLink>
                    </Grid>
                )}
            </>
        );
    };

    getJobDate = () => {
        return moment(this.props.job.start_date, 'MM/DD/YYYY hh:mm A').format('ddd, MMM. DD, YYYY');
    };

    getJobTime = () => {
        return moment(this.props.job.start_date, 'MM/DD/YYYY hh:mm A').format('h:mm A');
    };

    viewCancelOnetimeDialog = (nextState) => {
        this.setState({ showCancelOnetimeDialog: nextState });
    };

    handleCancelOnetimeDialog = (cancelJob) => {
        this.viewCancelOnetimeDialog(false);
        this.viewWhyCancelDialog(true);
    };

    getCancelOnetimeDialog = () => {
        const { job: appointment } = this.props;
        const { showCancelOnetimeDialog } = this.state;

        return (
            <SimpleTextDialog
                isOpen={showCancelOnetimeDialog}
                dialogTitle="Cancel Job"
                dialogText={
                    'Your job on ' +
                    this.getJobDate() +
                    ' at ' +
                    this.getJobTime() +
                    ' will be cancelled and your provider will be notified.'
                }
                buttonText="CANCEL"
                onClose={() => this.viewCancelOnetimeDialog(false)}
                onSubmit={(job) => this.handleCancelOnetimeDialog(job)}
                dataObj={appointment}
            />
        );
    };

    viewWhyCancelDialog = (nextState) => {
        this.setState({ showWhyCancelDialog: nextState });
    };

    getWhyCancelDialog = () => {
        const { job: appointment, history } = this.props;
        const { showWhyCancelDialog } = this.state;

        return (
            <WhyCancelDialog
                open={showWhyCancelDialog}
                type="appointment"
                requestNewSitter={() => {
                    this.viewWhyCancelDialog(false);
                    this.viewNewSitterDialog(true);
                }}
                onClose={() => {
                    this.viewWhyCancelDialog(false);
                    this.viewCancelOnetimeDialog(true);
                }}
                job={appointment}
                reasons={this.state.reasonDict}
                reasonType={this.state.reasonType}
                refresh={() => this.props.refresh()}
                history={history}
                cancel={() => {
                    this.viewWhyCancelDialog(false);
                    this.handleCancelOnetimeAppointment();
                }}
            />
        );
    };

    viewNewSitterDialog = (nextState) => {
        this.setState({ showNewSitterDialog: nextState });
    };

    getNewSitterDialog = () => {
        const { job: appointment } = this.props;
        const { showNewSitterDialog, cancelType } = this.state;
        return (
            <RequestNewSitterDialog
                open={showNewSitterDialog}
                onClose={() => {
                    this.viewNewSitterDialog(false);
                }}
                job={appointment}
                type={cancelType === 'cancelAppt' ? 'appointment' : 'request'}
            />
        );
    };

    viewCancelApptOrRequestDialog = (nextState) => {
        this.setState({ showCancelApptOrRequestDialog: nextState });
    };

    handleSkipCancelDialog = async (isSkip) => {
        const { job, refresh } = this.props;
        const urlPath = isSkip ? 'skip-ongoing/' : 'api/cancel-ongoing-day/';

        await client(urlPath + job.id, {
            method: 'POST',
        });

        refresh();
    };

    handleCancelRequest = async () => {
        const { job: appointment, refresh } = this.props;
        await client('api/cancel-ongoing-request/' + appointment.id, { method: 'PATCH' });
        refresh();
    };

    handleCancelOnetimeAppointment = async () => {
        const { job: appointment, refresh } = this.props;
        const isPending = appointment.status === 'NO_PAIRING' || appointment.status === 'PENDING_PAIRING';
        let urlPath = isPending ? 'cancel-all-pairings-for-pending-appt/' : 'cancel-all-pairings-for-approved-appt/';

        await client(urlPath + '?appt=' + appointment.id, {
            method: 'PATCH',
        });

        refresh();
    };

    getSkipCancelDialogs = () => {
        const { job: appointment, history, refresh, request } = this.props;
        const { showCancelApptOrRequestDialog } = this.state;

        return (
            <CancelApptOrRequestDialog
                open={showCancelApptOrRequestDialog}
                job={appointment}
                history={history}
                request={request}
                skip={() => this.handleSkipCancelDialog(true)}
                cancelAppt={() => this.handleSkipCancelDialog(false)}
                cancelRequest={() => this.handleCancelRequest()}
                setOpen={(open) => this.viewCancelApptOrRequestDialog(open)}
                onClose={() => this.viewCancelApptOrRequestDialog(false)}
                requestNewSitter={(cancelType) => {
                    this.setState({ cancelType });
                    this.viewNewSitterDialog(true);
                }}
                refresh={() => refresh()}
            />
        );
    };

    render() {
        const { job: appointment } = this.props;

        if (appointment) {
            return (
                <React.Fragment>
                    {this.getCardLinks()}
                    {this.getCancelOnetimeDialog()}
                    {this.getWhyCancelDialog()}
                    {this.getNewSitterDialog()}
                    {appointment.ongoing && this.getSkipCancelDialogs()}
                </React.Fragment>
            );
        } else {
            return null;
        }
    }
}

export { JobCardDialogs };
