import React from 'react';
import { DialogContent, Grid } from '@material-ui/core';
import { isMobile } from 'react-device-detect';
import { Colors } from '../../../constants';
import { PrimaryButton, SecondaryButton } from '../buttons';
import Text from '../text/Text';
import '@fontsource/work-sans';
import '@fontsource/pt-serif';
import DialogCloseButton from './DialogCloseButton';
import RoundedDialog from './RoundedDialog';

interface IProps {
    isOpen: boolean;
    dialogTitle?: string;
    HeaderImg?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
    children: React.ReactNode;
    buttonText?: string;
    buttonEnabled?: boolean;
    onClose: () => void;
    onSubmit?: () => void;
    onSecondaryButton?: () => void;
    secondaryButtonText?: string;
    closeButton?: boolean;
    webMaxWidth?: string;
    mobileMaxWidth?: string;
    style?: React.CSSProperties;
}
export default function BasicDialog({
    isOpen,
    dialogTitle,
    HeaderImg,
    children,
    buttonText,
    buttonEnabled,
    onClose,
    onSubmit,
    onSecondaryButton,
    secondaryButtonText,
    closeButton,
    mobileMaxWidth,
    webMaxWidth,
    style,
}: IProps) {
    function getButtonStyle() {
        return isMobile ? { ...styles.button, maxWidth: mobileMaxWidth } : { ...styles.button, maxWidth: webMaxWidth };
    }

    return (
        <RoundedDialog open={isOpen} style={style} onClose={onClose}>
            {closeButton ? <DialogCloseButton onClose={onClose} /> : null}
            <DialogContent style={{ ...styles.card, paddingTop: 32 }}>
                {HeaderImg ? <HeaderImg style={{ maxWidth: 140 }} /> : null}
                {dialogTitle ? (
                    <Grid container direction="row">
                        <Grid item xs={12} style={{ paddingBottom: '15px' }}>
                            <Text variant="display">{dialogTitle}</Text>
                        </Grid>
                    </Grid>
                ) : null}
                {children}
                <Grid container item xs={12} justify={onSecondaryButton ? 'space-between' : 'flex-end'}>
                    {onSecondaryButton ? (
                        <span>
                            <SecondaryButton onClick={onSecondaryButton} buttonStyle={getButtonStyle()}>
                                {secondaryButtonText}
                            </SecondaryButton>
                        </span>
                    ) : null}
                    {onSubmit ? (
                        <span style={{ paddingRight: 10 }}>
                            <PrimaryButton onClick={onSubmit} buttonStyle={getButtonStyle()} disabled={!buttonEnabled}>
                                {buttonText}
                            </PrimaryButton>
                        </span>
                    ) : null}
                </Grid>
            </DialogContent>
        </RoundedDialog>
    );
}

const styles = {
    card: {
        paddingBottom: '30px',
        paddingLeft: '30px',
        paddingRight: '30px',
    },
    title: {
        fontFamily: 'Pt Serif',
        color: Colors.darkNavy,
        paddingBottom: '24px',
        fontSize: isMobile ? 22 : 36,
    },
    button: {
        maxWidth: '70%',
        marginTop: '32px',
    },
};
