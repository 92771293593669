import React from 'react';
import { Typography } from '@material-ui/core';
import { SimpleDialog } from '../reusableComponents/dialogsSimple';

const summaryOfRightsUrl = 'https://privacy.evidentid.com/FCRA/pdf-0096-fair-credit-reporting-act.pdf';
const privacyPolicy = 'https://www.evidentid.com/privacy-policy/';

interface FCRADisclosureProps {
    isOpen: boolean;
    onClose: () => void;
}

const FCRADisclosure = ({ isOpen, onClose }: FCRADisclosureProps) => {
    return (
        <SimpleDialog
            isOpen={isOpen}
            onClose={onClose}
            onSubmit={onClose}
            buttonText="I Agree"
            style={{ height: '90%', marginTop: 10 }}
        >
            <Typography style={styles.header}>APPLICANT DISCLOSURE STATEMENT</Typography>
            <Typography style={styles.subHeader}>
                With your application to Tandem to provide services as an independent contractor, employee, or volunteer
                you may have information requested about you from a Consumer Reporting Agency (CRA). This information
                may be obtained in the form of a consumer report.
            </Typography>
            <Typography style={styles.subHeader}>
                These reports may contain information about your character, general reputation, personal characteristics
                and/or mode of living. The types of information that may be obtained include, but are not limited to:
                social security number verifications; address history; criminal records checks; public court records
                checks; driving records checks; and professional licensing/certification checks. This information may be
                obtained from private and/or public records sources, including, as appropriate; governmental agencies,
                courthouses, educational institutions, former employers, or other information sources.
            </Typography>
            <Typography style={styles.header}>APPLICANT AUTHORIZATION OF BACKGROUND INVESTIGATION</Typography>
            <Typography style={styles.subHeader}>
                I have carefully read, and understand, this Authorization form and further acknowledge receipt of the
                separate document entitled{' '}
                <a style={styles.link} href={summaryOfRightsUrl}>
                    “A Summary of Your Rights under the Fair Credit Reporting Act”
                </a>{' '}
                and the “Applicant Disclosure Statement” and certify that I have read and understand both documents. By
                checking the provided box, I consent to the release of consumer reports and/or investigative consumer
                reports (“Background Reports”) prepared by Evident ID Inc. located at 75 5th Street NW Suite 2060
                Atlanta, GA 30308 -- 877-832-5298 -{' '}
                <a style={styles.link} href={privacyPolicy}>
                    Privacy Policy
                </a>
                , to Tandem and its designated representatives and agents for the purpose of determining and maintaining
                my relationship as an independent contractor, employee, or volunteer with Tandem.{' '}
            </Typography>
            <Typography style={styles.subHeader}>
                I understand that if Tandem engages in a relationship with me, my consent will apply, and Tandem may
                obtain Background Reports throughout my relationship with them, if such obtainment is permissible under
                applicable State law and Tandem policy. I also understand that information contained in my application,
                or otherwise disclosed by me may be used when ordering the Background Reports and that nothing herein
                shall be construed as an offer of employment or a guarantee of a relationship with Tandem.
            </Typography>
        </SimpleDialog>
    );
};

const styles = {
    header: {
        fontFamily: 'Work Sans',
        marginBottom: 10,
    },
    subHeader: {
        marginBottom: 10,
    },
    link: {
        textDecorationLine: 'underline',
    },
};

export default FCRADisclosure;
