import React from 'react';

export default function MenuAnchor({
    children,
    open,
    setOpen,
    setAnchorEl,
}: {
    children: React.ReactNode;
    open: boolean;
    setOpen: (open: boolean) => void;
    setAnchorEl: (element: HTMLElement) => void;
}) {
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
        setOpen(!open);
    };
    return <span onClick={handleClick}>{children}</span>;
}
