import React, { Component } from 'react';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import { Grid, Typography, List } from '@material-ui/core';
import BackgroundPaper from '../../../reusableComponents/backgroundPaper';
import ContentPaper from '../../../reusableComponents/contentPaper';
import CustomPagination from '../../../reusableComponents/customPagination';
import { isMobile } from 'react-device-detect';
import { MoreOptions } from '../JobCardMoreOptions';
import { client } from 'shared';
import { Environment } from 'environmentVariables';

class Past extends Component {
    constructor(props) {
        super(props);
        this.state = {
            jobs: [],
            pageCount: 0,
            jobsShown: [],
            pageSize: isMobile ? 3 : 4,
        };
        this.showNewContent = this.showNewContent.bind(this);
    }

    componentDidMount = async () => {
        const { pageSize } = this.state;
        try {
            const jobs = await client('api/nested-pairings/?past', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Token ' + localStorage.getItem('token'),
                },
            });
            const cloneJobs = [...jobs];
            var totalPage = Math.floor((jobs.length + pageSize - 1) / pageSize);
            this.setState({ jobs, pageCount: totalPage, jobsShown: cloneJobs.slice(0, pageSize) });
        } catch (error) {
            if (Environment != 'production') {
                console.log(error);
            }
        }
    };

    getPastJob(job) {
        return (
            <div>
                <Grid item xs={12}>
                    <ContentPaper>
                        <Grid container>
                            <Grid container direction="row" spacing={2}>
                                <Grid item xs={11}>
                                    <Typography variant="subtitle1">
                                        <b>
                                            {moment(job.appointment.start_date, 'MM/DD/YYYY hh:mm A').format(
                                                'ddd, MMM DD, YYYY',
                                            )}
                                        </b>{' '}
                                        {moment(job.appointment.start_date, 'MM/DD/YYYY hh:mm A').format('h:mm A')} -{' '}
                                        {moment(job.appointment.end_date, 'MM/DD/YYYY hh:mm A').format('h:mm A')}
                                    </Typography>
                                </Grid>
                                <Grid item xs={1}>
                                    <MoreOptions
                                        past={true}
                                        oneTime={!job.appointment.ongoing_request.ongoing}
                                        review={job.reviews.length === 0}
                                        jobId={job.appointment.ongoing_request.id}
                                        provider={job.babysitter}
                                        appointmentId={job.appointment.id}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                {job.appointment.ongoing_request.ongoing ? (
                                    <Typography variant="subtitle1">
                                        Recurring every <b>{job.appointment.days_of_week}</b>
                                    </Typography>
                                ) : null}
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="subtitle1">
                                    ${job.appointment.pay}/hour ~ $
                                    {(
                                        (moment(job.appointment.end_date).diff(moment(job.appointment.start_date)) /
                                            3600000) *
                                        job.appointment.pay
                                    ).toFixed(2)}{' '}
                                    total
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="subtitle1">
                                    with {job.babysitter.user.first_name} {job.babysitter.user.last_name}
                                </Typography>
                            </Grid>
                        </Grid>
                    </ContentPaper>
                </Grid>
            </div>
        );
    }

    showNewContent(content) {
        this.setState({ jobsShown: content });
    }

    render() {
        const { jobsShown, pageCount, jobs, pageSize } = this.state;
        return (
            <div style={{ maxWidth: 700 }}>
                <BackgroundPaper style={{ width: '100%', color: 'blue' }}>
                    {jobsShown.length > 0 ? (
                        <div>
                            {jobsShown.map((job, i) => (
                                <List>
                                    <Grid container justify="center" alignContent="center">
                                        {this.getPastJob(job)}
                                    </Grid>
                                </List>
                            ))}
                            <CustomPagination
                                pageCount={pageCount}
                                showNewContent={this.showNewContent}
                                content={jobs}
                                pageSize={pageSize}
                            />
                        </div>
                    ) : (
                        <Typography style={{ marginTop: 5 }} variant="subtitle2">
                            You have no past jobs.
                        </Typography>
                    )}
                </BackgroundPaper>
            </div>
        );
    }
}

export default withRouter(Past);
