import React from 'react';
import { client } from 'shared';
import AccountMenuItem from 'parent-portal/navigation/AccountMenu/AccountMenuItem';
import EditIcon from '@mui/icons-material/Edit';
import { useModalsContext } from 'ModalsContext';
import { Colors } from 'library';
import draftPresetMap from './Drafts/draftPresetMap';

export const EditDraft = ({ jobId, onClose }: { jobId: number; onClose: () => void }) => {
    const { openJobForm } = useModalsContext();

    const clickHandler = () => {
        client(`api/ongoing/${jobId}/get-draft/`).then((draft) => {
            const jobFormPresets = draftPresetMap(draft);
            openJobForm('Edit Draft', jobFormPresets, onClose);
        });
    };

    return (
        <AccountMenuItem
            icon={<EditIcon style={{ color: Colors.darkNavy }} id="more-options-edit-draft" />}
            text={`Edit Draft`}
            action={clickHandler}
        />
    );
};
