import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { Text, Colors, IfRender, BasicDialog } from 'library';
import { NotificationType } from 'models';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { useNotificationContext } from '../NotificationContext/NotificationContext';

const useStyles = makeStyles({
    root: {
        backgroundColor: 'white',
        marginTop: 10,
        marginBottom: 10,
        padding: 10,
        borderRadius: 7,
        cursor: 'pointer',
        transition: 'all .2s ease',
        WebkitTransition: 'all .2s ease',
        MozTransition: 'all .2s ease',
        '&:hover': {
            backgroundColor: Colors.lightGrey,
        },
    },
});

export default function Notification({ notification }: { notification: NotificationType }) {
    const classes = useStyles();

    const { clickNotification, actionMessage, setActionMessage } = useNotificationContext();

    function closeActionDialog() {
        setActionMessage(undefined);
    }

    return (
        <>
            <Grid
                className={classes.root}
                container
                item
                justify="space-between"
                alignItems="center"
                wrap="nowrap"
                onClick={() => {
                    clickNotification(notification);
                }}
            >
                <Grid item>
                    <Grid container alignItems="center" wrap="nowrap">
                        <Grid item></Grid>
                        <Grid item>
                            <Grid container item direction="column">
                                <Text variant="body1">{notification.message}</Text>
                                <Text variant="body2" bold>
                                    {notification.added_at}
                                </Text>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <IfRender condition={!notification.clicked && !notification.marked_as_read}>
                        <FiberManualRecordIcon fontSize="small" color="error" />
                    </IfRender>
                </Grid>
            </Grid>
            <BasicDialog
                closeButton
                buttonEnabled
                onSubmit={closeActionDialog}
                buttonText="OK"
                isOpen={!!actionMessage}
                onClose={closeActionDialog}
            >
                <Text variant="body1">{actionMessage}</Text>
            </BasicDialog>
        </>
    );
}
