import { Grid, styled } from '@mui/material';
import { SizeableRoundedDialog, Colors, Text, PrimaryButton, Link } from 'library';
import React from 'react';
import { useHistory } from 'react-router';
import useMicrodepositVerificationInfo from './useMicrodepositVerficationInfo';

export default function MissingMicrodepositVerificationModal({
    isOpen,
    onClose,
}: {
    isOpen: boolean;
    onClose: () => void;
}) {
    const history = useHistory();
    const { needsSecondVerificationWarning } = useMicrodepositVerificationInfo();

    const linkToPayments = () => {
        onClose();
        history.push('/payments');
    };

    const text = (() => {
        if (needsSecondVerificationWarning) {
            return {
                title: 'Uh-oh! We still need to verify your banking information',
                topText:
                    "Posting additional jobs has been blocked until your micro deposits are submitted. This is for your safety and it's easy, we promise!",
                bottomText: 'If you need any additional help, reach out to your Account Manager in TandemBot.',
            };
        }

        return {
            title: 'Uh-oh! We need to verify your banking information',
            topText: "In order to securely connect your bank account please verify your micro deposits. It's easy!",
            bottomText: '',
        };
    })();

    return (
        <SizeableRoundedDialog closeButton open={isOpen} onClose={onClose} style={{ backgroundColor: Colors.lightRed }}>
            <Container container direction="column">
                <Text variant="h1">
                    {`${text.title} `}
                    <Link style={{ fontSize: 24 }} onClick={linkToPayments}>
                        here
                    </Link>
                </Text>
                <Text variant="body1">{text.topText}</Text>

                <Step container direction="row">
                    <Text color={Colors.turquoise} variant="h1">
                        1.
                    </Text>
                    <Text variant="body1" bold>
                        Log into the bank account used for Tandem.
                    </Text>
                </Step>

                <Step container direction="row">
                    <Text color={Colors.turquoise} variant="h1">
                        2.
                    </Text>
                    <Text variant="body1" bold>
                        Find the two small charges from Tandem, each less than $1.
                    </Text>
                </Step>

                <Step container direction="row">
                    <Text color={Colors.turquoise} variant="h1">
                        3.
                    </Text>
                    <Text variant="body1" bold>
                        Enter the two amounts under the Manual Account Verification on the Payments page and hit Submit.
                    </Text>
                </Step>
                <Text variant="body1">{text.bottomText}</Text>

                <Text variant="body1">
                    *This must be within 10 days of connecting your bank or it will no longer be valid.
                </Text>
                <PrimaryButton onClick={linkToPayments}>Add Micro Deposit</PrimaryButton>
            </Container>
        </SizeableRoundedDialog>
    );
}

const Container = styled(Grid)({
    paddingLeft: 40,
    paddingRight: 40,
    paddingTop: 20,
    gap: 10,
});

const Step = styled(Grid)({
    alignItems: 'center',
    gap: 10,
});
