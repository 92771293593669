import React, { useState } from 'react';
import { Text } from 'library';
import { useJobFormContext } from 'parent-portal/forms/JobFormContext';
import SectionContainer from './SectionContainer';
import EditPayScalesModal from './EditPayScalesModal';
import PayRecommendation from './PayRecommendation';

export default function PayScalesSection() {
    const [editOpen, setEditOpen] = useState(false);
    const { form, recommendations } = useJobFormContext();
    const { pay } = form.fieldStates;
    return (
        <SectionContainer
            error={recommendations.length > 0}
            editModal={<EditPayScalesModal open={editOpen} onClose={() => setEditOpen(false)} />}
            setEditOpen={setEditOpen}
        >
            <Text variant="h2" bold textStyle={styles.header}>
                Pay Scales
            </Text>
            <Text variant="body2">
                ${pay.value.payMin}
                {pay.value.payMin != pay.value.payMax ? ` - $${pay.value.payMax}` : ''}/hr
            </Text>
            <PayRecommendation />
        </SectionContainer>
    );
}

const styles = {
    header: {
        marginBottom: 8,
    },
};
