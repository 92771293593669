import React from 'react';
import { Autocomplete, Grid, TextField } from '@mui/material';
import { Text } from 'library';
import { historyTypes } from '../historyTypes';

export default function LocationHistoryHeader({
    title = 'History',
    setSelectedTypes,
}: {
    title?: string;
    setSelectedTypes: (historyTypes: string[]) => void;
}) {
    return (
        <Grid container direction="row" gap={1} alignItems="center">
            <Text bold>{title}</Text>
            <Autocomplete
                disablePortal
                multiple={true}
                options={historyTypes.map((x) => x)}
                sx={{ width: 300 }}
                renderInput={(params) => <TextField {...params} label="Types" />}
                onChange={(e, value) => setSelectedTypes(value)}
            />
        </Grid>
    );
}
