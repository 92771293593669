import React from 'react';
import { Dialog, DialogTitle, Grid } from '@material-ui/core';
import { PrimaryButton, SecondaryButton, Text } from 'library';

interface ConfirmDialogProps {
    open: boolean;
    title?: string;
    message: string;
    onClose: () => void;
    onConfirm: () => void;
}

const ConfirmDialog = ({ open, title, message, onClose, onConfirm }: ConfirmDialogProps) => {
    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle id="simple-dialog-title">{title}</DialogTitle>
            <Grid container style={{ padding: 10 }}>
                <Grid item style={{ padding: 10, marginBottom: 10 }}>
                    <Text variant="body1">{message}</Text>
                </Grid>
                <Grid container item justify="space-evenly" style={{ marginBottom: 10 }}>
                    <Grid item xs={4}>
                        <SecondaryButton onClick={onClose}>Cancel</SecondaryButton>
                    </Grid>
                    <Grid item xs={4}>
                        <PrimaryButton onClick={onConfirm}>OK</PrimaryButton>
                    </Grid>
                </Grid>
            </Grid>
        </Dialog>
    );
};

export default ConfirmDialog;
