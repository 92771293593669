import { ArrowBack } from '@material-ui/icons';
import Divider from 'parent-portal/forms/components/Divider';
import React from 'react';
import { track } from 'shared';
import AccountMenuItem from './AccountMenuItem';
import usePublicSessionTracking, { PublicSessionEventType } from 'parent-portal/shared/PublicSessionTracking';
import { useUserContext } from 'UserContext';

export default function LogoutMenuItem() {
    const { trackSession } = usePublicSessionTracking('Top Navigation - Profile Picture - Item');
    const { logout } = useUserContext();

    const logoutClicked = () => {
        track(`Profile dropdown clicked: Sign out`);
        trackSession('CLICK', 'Logout', PublicSessionEventType.User);
        logout();
    };

    return (
        <>
            <Divider />
            <AccountMenuItem icon={<ArrowBack color="primary" />} text="Sign Out" action={logoutClicked} />
        </>
    );
}
