import React, { useState } from 'react';
import { BasicDialog, PrimaryButton, Text, TextButton, Colors } from 'library';
import { Grid } from '@material-ui/core';
import { styled } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileCircleQuestion } from '@fortawesome/free-solid-svg-icons';
import { useUserContext } from 'UserContext';
import FloridaTrainingsContent from './FloridaTrainingsContent';
import IllinoisTrainingsContent from './IllinoisTrainingsContent';
import OhioTrainingsContent from './OhioTrainingsContent';

export default function TrainingsModalButton({ initialOpen, onClose }: { initialOpen: boolean; onClose: () => void }) {
    const [isOpen, setIsOpen] = useState(initialOpen);
    const { user } = useUserContext();

    function onCloseHandler() {
        setIsOpen(false);
        onClose();
    }

    function getContent() {
        const primaryAddress = user?.address.find((x) => x.address_type === 'PRIMARY');
        const state = primaryAddress?.state;

        if (state?.toLowerCase() === 'florida') {
            return <FloridaTrainingsContent />;
        } else if (state?.toLowerCase() === 'illinois') {
            return <IllinoisTrainingsContent />;
        }

        return <OhioTrainingsContent />;
    }

    return (
        <Grid>
            <TextButton onClick={() => setIsOpen(true)} buttonStyle={{ width: undefined }}>
                What trainings do all Tandem workers have?
            </TextButton>
            <BasicDialog isOpen={isOpen} onClose={onCloseHandler} closeButton>
                <Grid>
                    <Grid container direction="row" alignItems="center">
                        <FontAwesomeIcon
                            icon={faFileCircleQuestion}
                            style={{
                                width: 30,
                                height: 30,
                                background: Colors.mediumTerraCotta,
                                borderRadius: 30,
                                padding: 10,
                            }}
                            color={Colors.lightTerraCotta}
                        />
                        <Text bold>What trainings do all Tandem workers have?</Text>
                    </Grid>
                    <ModalContainer>{getContent()}</ModalContainer>
                    <PrimaryButton buttonStyle={{ width: undefined, justifySelf: 'flex-end' }} onClick={onCloseHandler}>
                        Close
                    </PrimaryButton>
                </Grid>
            </BasicDialog>
        </Grid>
    );
}

const ModalContainer = styled(Grid)({
    padding: 10,
});
