import React, { useEffect, useState } from 'react';
import { BasicDialog, Colors, IconButton, OutlinedTextInput, PrimaryButton, SecondaryButton, Text } from 'library';
import { client, consoleLogInDev, validateEmail } from 'shared';
import { Edit } from '@mui/icons-material';
import { styled } from '@mui/material';

export default function EditEmailButton({
    userId,
    initialEmail,
    onSave,
}: {
    userId: number;
    initialEmail: string;
    onSave: () => void;
}) {
    const [emailAddress, setEmailAddress] = useState<string>(initialEmail);
    const [isOpen, setIsOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const isEmailValid = validateEmail(emailAddress);

    useEffect(() => {
        setEmailAddress(initialEmail);
    }, [initialEmail]);

    function onSaveClick() {
        if (!emailAddress || !isEmailValid) {
            return;
        }

        setErrorMessage('');

        client(`internal/user/${userId}/`, { body: { username: emailAddress, email: emailAddress }, method: 'PATCH' })
            .then(() => {
                onSave();
                setIsOpen(false);
            })
            .catch((error) => {
                consoleLogInDev(error);
                setErrorMessage(error.email || 'There was an error');
            });
    }

    function onClose() {
        setIsOpen(false);
        setEmailAddress(initialEmail);
        setErrorMessage('');
    }

    return (
        <>
            <IconButton
                onClick={() => setIsOpen(true)}
                icon={Edit}
                backgroundColor={Colors.darkNavy}
                color={Colors.white}
                style={{ marginLeft: 5 }}
            />
            <BasicDialog closeButton isOpen={isOpen} onClose={onClose} style={{ width: '80%' }}>
                <div>
                    <OutlinedTextInput
                        label="Email"
                        onChange={(e) => {
                            setEmailAddress(e.target.value);
                        }}
                        value={emailAddress}
                    />
                </div>
                <ButtonContainer>
                    <SecondaryButton onClick={onClose} buttonStyle={{ marginRight: 10, width: undefined }}>
                        Cancel
                    </SecondaryButton>
                    <PrimaryButton disabled={!isEmailValid} onClick={onSaveClick} buttonStyle={{ width: undefined }}>
                        Save
                    </PrimaryButton>
                    <Text textStyle={{ color: Colors.error }}>{errorMessage}</Text>
                </ButtonContainer>
            </BasicDialog>
        </>
    );
}

const ButtonContainer = styled('div')({
    marginTop: 10,
});
