import React, { Component, useState } from 'react';
import {
    Grid,
    Typography,
    List,
    ListItem,
    IconButton,
    withStyles,
    Popover,
    Dialog,
    DialogContent,
    Radio,
    RadioGroup,
} from '@material-ui/core';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import Button from '../../../reusableComponents/button';
import CloseIcon from '@material-ui/icons/Close';
import Label from '../../../reusableComponents/checkboxLabel';
import DropDown from '../../../library/components/formInputs/Dropdown';
import { WhyCancelDialog, getCancellationReasonsResp } from './EditJobDetailsPage/CancellationDialog';
import RequestNewSitterDialog from './RequestNewSitterDialog';
import { client } from 'shared';
import { Environment } from 'environmentVariables';

/*
BEWARE: THE JOB OBJECT REFERENCED HERE IS A WEIRD COMBO OF APPOINTMENT AND ONGOING REQUEST
*/

const StyledIconButton = withStyles({
    root: {
        padding: 0,
        '&:focus': {
            outline: 'none',
        },
    },
})(IconButton);

class ViewMoreOptions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            job: this.props.job,
            customPayDialogVisible: false,
            editApptOrRequestDialogVisible: false,
            cancelApptOrRequestDialogVisible: false,
            cancelOnetimeApptDialogVisible: false,
            requestNewSitterDialogVisible: false,
            cancelType: '',
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.job !== this.props.job) {
            this.setState({ job: this.props.job });
        }
    }

    async cancelOnetimeAppointment() {
        await client('cancel-all-pairings-for-approved-appt/?appt=' + this.props.job.id, {
            method: 'PATCH',
        });
    }

    async cancelOngoingAppointment() {
        await client('skip-ongoing/' + this.props.job.id, {
            method: 'POST',
        });
    }

    async cancelOngoingAppointmentDay() {
        await client('api/cancel-ongoing-day/' + this.props.job.id, {
            method: 'POST',
        });
    }

    async cancelRequest() {
        await client('api/cancel-ongoing-request/' + this.props.job.id, {
            method: 'PATCH',
        });
    }

    advanceTour = () => {
        const helpers = this.context.tour.helpers;
        const info = helpers.info();
        if (info.index === 5 && info.lifecycle === 'tooltip') {
            helpers.next();
        }
    };

    render() {
        const {
            customPayDialogVisible,
            editApptOrRequestDialogVisible,
            cancelApptOrRequestDialogVisible,
            cancelOnetimeApptDialogVisible,
            requestNewSitterDialogVisible,
            cancelType,
        } = this.state;
        const helpers = this.context.tour.helpers;
        const tour = this.context.tour.open;
        return (
            /* begin popup menu */
            <div className="tour-upcoming-options">
                <PopupState variant="popover" popupId="demo-popup-popover">
                    {(popupState) => (
                        <div>
                            <StyledIconButton {...bindTrigger(popupState)} disableFocusRipple disableRipple>
                                <MoreHorizIcon color="primary" />
                            </StyledIconButton>
                            <Popover
                                onEntered={tour && helpers ? this.advanceTour : null}
                                {...bindPopover(popupState)}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                }}
                            >
                                <List component="nav" className="tour-upcoming-options">
                                    <ListItem
                                        button
                                        onClick={
                                            tour
                                                ? null
                                                : this.props.job.ongoing
                                                  ? () => {
                                                        popupState.close();
                                                        if (this.props.job.start_date) {
                                                            this.setState({ editApptOrRequestDialogVisible: true });
                                                        } else {
                                                            this.props.history.push(
                                                                '/jobs/request/edit/' + this.props.job.id,
                                                            );
                                                        }
                                                    }
                                                  : () => {
                                                        popupState.close();
                                                        this.props.history.push(
                                                            '/jobs/upcoming/edit/' + this.props.job.id,
                                                        );
                                                    }
                                        }
                                    >
                                        <Typography variant="subtitle2">Edit</Typography>
                                    </ListItem>
                                    {this.props.paymentSetup &&
                                        this.props.fromHome &&
                                        (this.props.job.status === 'NO_PAIRING' ||
                                            this.props.job.status === 'PENDING_PAIRING') && (
                                            <ListItem
                                                button
                                                onClick={
                                                    tour
                                                        ? null
                                                        : () => {
                                                              popupState.close();
                                                              this.props.history.push(
                                                                  '/jobs/upcoming/sitters/' +
                                                                      (this.props.job.ongoing
                                                                          ? this.props.job.id +
                                                                            '?appt=' +
                                                                            this.props.job.id
                                                                          : this.props.job.id +
                                                                            '?onetime&appt=' +
                                                                            this.props.job.id),
                                                              );
                                                          }
                                                }
                                            >
                                                <Typography variant="subtitle2">Recommendations</Typography>
                                            </ListItem>
                                        )}
                                    {this.props.job.ongoing && (
                                        <ListItem
                                            button
                                            onClick={
                                                tour
                                                    ? null
                                                    : () => {
                                                          popupState.close();
                                                          this.setState({ customPayDialogVisible: true });
                                                      }
                                            }
                                        >
                                            <Typography variant="subtitle2">Set up a Payment Schedule</Typography>
                                        </ListItem>
                                    )}
                                    <ListItem
                                        button
                                        onClick={
                                            tour
                                                ? null
                                                : this.props.job.ongoing
                                                  ? () => {
                                                        popupState.close();
                                                        this.setState({ cancelApptOrRequestDialogVisible: true });
                                                    }
                                                  : () => {
                                                        popupState.close();
                                                        this.setState({ cancelOnetimeApptDialogVisible: true });
                                                    }
                                        }
                                    >
                                        <Typography variant="subtitle2">
                                            {this.props.job.ongoing && this.props.job.status === 'APPROVED_PAIRING'
                                                ? 'Cancel or Skip'
                                                : 'Cancel'}
                                        </Typography>
                                    </ListItem>
                                    {this.props.paymentSetup && this.props.job.pairings.length > 0 && (
                                        <ListItem
                                            button
                                            onClick={
                                                tour
                                                    ? null
                                                    : () => {
                                                          popupState.close();
                                                          this.setState({ requestNewSitterDialogVisible: true });
                                                      }
                                            }
                                        >
                                            <Typography variant="subtitle2">Request a New Sitter</Typography>
                                        </ListItem>
                                    )}
                                </List>
                            </Popover>
                        </div>
                    )}
                </PopupState>
                {/* end popup menu */}

                {/* begin dialogs */}
                {this.props.job.ongoing && (
                    <CustomPayDialog
                        open={customPayDialogVisible}
                        onClose={() => {
                            this.setState({ customPayDialogVisible: false });
                            this.props.refresh();
                        }}
                        job={this.props.job}
                    />
                )}
                {this.props.job.ongoing && (
                    <div>
                        <EditApptOrRequestDialog
                            open={editApptOrRequestDialogVisible}
                            job={this.props.job}
                            editRequest={() => {
                                this.props.history.push('/jobs/request/edit/' + this.props.job.id);
                            }}
                            editAppt={() => {
                                this.props.history.push('/jobs/upcoming/edit/' + this.props.job.id);
                            }}
                            onClose={() => {
                                this.setState({ editApptOrRequestDialogVisible: false });
                                this.props.refresh();
                            }}
                        />
                        <CancelApptOrRequestDialog
                            open={cancelApptOrRequestDialogVisible}
                            job={this.props.job}
                            history={this.props.history}
                            skip={() => this.cancelOngoingAppointment()}
                            cancelAppt={() => this.cancelOngoingAppointmentDay()}
                            cancelRequest={() => this.cancelRequest()}
                            getReasons={() => this.getCancellationReasons()}
                            onClose={() => {
                                this.setState({ cancelApptOrRequestDialogVisible: false });
                                // this.props.refresh();
                            }}
                            setOpen={(open) => this.setState({ cancelApptOrRequestDialogVisible: open })}
                            requestNewSitter={(cancelType) => {
                                this.setState({ cancelType });
                                this.setState({ requestNewSitterDialogVisible: true });
                            }}
                            refresh={() => this.props.refresh()}
                        />
                    </div>
                )}
                {this.props.paymentSetup && this.props.job.pairings.length > 0 && (
                    <RequestNewSitterDialog
                        open={requestNewSitterDialogVisible}
                        onClose={() => {
                            this.setState({ requestNewSitterDialogVisible: false });
                            this.props.refresh();
                        }}
                        job={this.props.job}
                        type={cancelType === 'cancelAppt' ? 'appointment' : 'request'}
                    />
                )}
            </div>
            /* end dialogs */
        );
    }
}

function CustomPayDialog(props) {
    const { job } = props;
    const [autoConfirmation, setAutoConfirmation] = useState(false);
    const [option, setOption] = useState(job.pay_window !== '' ? job.pay_window : 'DAILY');
    var [day, setDay] = useState('Monday');
    const toggleAuto = async () => {
        let json = null;
        if (option === 'AUTOMATIC') {
            json = {
                automatic: true,
                pay_window: 'AUTOMATIC',
                pay_day: null,
                id: job.id,
            };
        } else if (option === 'WEEKLY') {
            json = {
                automatic: false,
                pay_window: 'WEEKLY',
                pay_day: day.toUpperCase(),
                id: job.id,
            };
        } else if (option === 'MONTHLY') {
            json = {
                automatic: false,
                pay_window: 'MONTHLY',
                pay_day: null,
                id: job.id,
            };
        } else {
            json = {
                automatic: false,
                pay_window: 'DAILY',
                pay_day: null,
                id: job.id,
            };
        }

        try {
            await client('api/ongoing/' + job.id + '/', {
                method: 'PATCH',
                body: json,
            });
            setAutoConfirmation(true);
            props.onClose();
        } catch (error) {
            if (Environment != 'production') {
                console.log(error);
            }
        }
    };

    return (
        <React.Fragment>
            <Dialog
                fullWidth
                maxWidth="xs"
                open={props.open}
                onClose={() => {
                    props.onClose();
                    setAutoConfirmation(false);
                }}
                aria-labelledby="max-width-dialog-title"
            >
                <DialogContent style={{ paddingBottom: '3%' }}>
                    <Grid container direction="row">
                        <Grid item xs={11}>
                            <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                                Select a Payment Window
                            </Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <StyledIconButton
                                onClick={() => {
                                    props.onClose();
                                    setAutoConfirmation(false);
                                }}
                            >
                                <CloseIcon color="error" />
                            </StyledIconButton>
                        </Grid>
                        <Typography variant="subtitle1">
                            Pick a Payment Schedule that works for you and your sitter.
                        </Typography>
                        <Grid item xs={12}>
                            <RadioGroup
                                name="options"
                                value={option}
                                onChange={(e) => {
                                    setOption(e.target.value);
                                }}
                            >
                                <Label
                                    value="DAILY"
                                    control={<Radio color="primary" disableRipple disableFocusRipple />}
                                    label={
                                        <Typography variant="subtitle1">
                                            Confirm Payment at the end of every job
                                        </Typography>
                                    }
                                />
                                <Label
                                    value="WEEKLY"
                                    control={<Radio color="primary" disableRipple disableFocusRipple />}
                                    label={
                                        <Grid container direction="row" alignItems="center">
                                            <Grid item m={7}>
                                                <Typography variant="subtitle1">Weekly Payment on </Typography>
                                            </Grid>
                                            <Grid item m={5}>
                                                <DropDown
                                                    marginLeft
                                                    onChange={(e) => setDay(e.target.value)}
                                                    selected={day}
                                                    style={{ paddingRight: 15 }}
                                                    fields={[
                                                        'Monday',
                                                        'Tuesday',
                                                        'Wednesday',
                                                        'Thursday',
                                                        'Friday',
                                                        'Saturday',
                                                        'Sunday',
                                                    ]}
                                                />
                                            </Grid>
                                        </Grid>
                                    }
                                />
                                <Label
                                    value="MONTHLY"
                                    control={<Radio color="primary" disableRipple disableFocusRipple />}
                                    label={
                                        <Grid container direction="row" alignItems="center">
                                            <Grid item m={7}>
                                                <Typography variant="subtitle1">
                                                    Monthly Payment on the first of the month{' '}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    }
                                />
                                <Label
                                    value="AUTOMATIC"
                                    control={<Radio color="primary" disableRipple disableFocusRipple />}
                                    label={
                                        <Typography variant="subtitle1">
                                            Automatic Payments at the end of every job
                                        </Typography>
                                    }
                                />
                            </RadioGroup>
                            <Button fullWidth onClick={() => toggleAuto()}>
                                {' '}
                                Confirm Payment Schedule
                            </Button>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </React.Fragment>
    );
}

function EditApptOrRequestDialog(props) {
    const { job, editRequest, editAppt } = props;
    const [editType, setEditType] = useState('appointment');
    const abbreviation = {
        Sunday: 'Su',
        Monday: 'M',
        Tuesday: 'Tu',
        Wednesday: 'W',
        Thursday: 'Th',
        Friday: 'F',
        Saturday: 'Sa',
    };
    const days = job.days_of_week;
    const length = days.length;
    const dateAbrvs = days.map((d, i) => {
        return length === i + 1 ? abbreviation[d] : abbreviation[d] + ', ';
    });

    return (
        <div>
            <React.Fragment>
                <Dialog
                    fullWidth
                    open={props.open}
                    onClose={() => {
                        props.onClose();
                    }}
                    aria-labelledby="max-width-dialog-title"
                >
                    <DialogContent style={{ paddingBottom: '3%' }}>
                        <Grid container direction="row">
                            <Grid item xs={11}>
                                <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                                    Edit Recurring Event
                                </Typography>
                            </Grid>
                            <Grid item xs={1}>
                                <StyledIconButton
                                    onClick={() => {
                                        props.onClose();
                                    }}
                                >
                                    <CloseIcon color="error" />
                                </StyledIconButton>
                            </Grid>
                        </Grid>
                        <RadioGroup
                            name="reoccuring"
                            value={editType}
                            onChange={(e) => {
                                setEditType(e.target.value);
                            }}
                            style={{ marginTop: 10 }}
                        >
                            <Label
                                value="appointment"
                                control={<Radio color="primary" disableRipple disableFocusRipple />}
                                label={<Typography variant="subtitle1">Edit this day once</Typography>}
                            />
                            <Label
                                value="request"
                                control={<Radio color="primary" disableRipple disableFocusRipple />}
                                label={
                                    <Typography variant="subtitle1">
                                        Edit the entire request (recurring every {dateAbrvs})
                                    </Typography>
                                }
                            />
                        </RadioGroup>
                        <Button
                            fullWidth
                            style={{ marginTop: 10 }}
                            onClick={() => {
                                editType === 'request' ? editRequest() : editAppt();
                            }}
                            disableFocusRipple
                            disableRipple
                        >
                            Edit
                        </Button>
                    </DialogContent>
                </Dialog>
            </React.Fragment>
        </div>
    );
}

function CancelApptOrRequestDialog(props) {
    const { job, skip, cancelAppt, cancelRequest, history, requestNewSitter, request } = props;
    const [cancelConfirmation, setCancelConfirmation] = useState(false);
    const [whyCancelDialogVisible, setWhyCancelDialogVisible] = useState(false);
    const abbreviation = {
        Sunday: 'Su',
        Monday: 'M',
        Tuesday: 'Tu',
        Wednesday: 'W',
        Thursday: 'Th',
        Friday: 'F',
        Saturday: 'Sa',
    };
    const days = job.ongoing_request ? job.ongoing_request.days_of_week : job.days_of_week;
    const numberOfDaysInRequest = days.length;
    const dateAbrvs = days.map((d, i) => {
        return numberOfDaysInRequest === i + 1 ? abbreviation[d] : abbreviation[d] + ', ';
    });
    // let initialType = 'skip';
    let buttonText = 'Cancel';
    let dialogTitle = 'Cancel Job(s)';
    if (job.status !== 'APPROVED_PAIRING') {
        if (numberOfDaysInRequest === 1) {
            // initialType = 'cancelRequest';
            buttonText = 'Cancel Job';
            dialogTitle = 'Cancel Recurring Event';
        } else {
            // initialType = 'cancelAppt';
        }
    }
    const [cancelType, setCancelType] = useState('');
    const [reasonDict, setReasonDict] = useState({});
    const [reasonType, setReasonType] = useState('');

    const getCancellationReasons = async (props) => {
        let reasons = await getCancellationReasonsResp(props.job.id);
        setReasonDict(reasons.primary_reasons);
        setReasonType(reasons.reason_type);
    };

    return (
        <div>
            <React.Fragment>
                <Dialog
                    fullWidth
                    open={props.open}
                    onClose={() => {
                        props.onClose();
                        setCancelConfirmation(false);
                    }}
                    aria-labelledby="max-width-dialog-title"
                >
                    <DialogContent style={{ paddingBottom: '3%' }}>
                        <Grid container direction="row">
                            <Grid item xs={11}>
                                <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                                    {dialogTitle}
                                </Typography>
                            </Grid>
                            <Grid item xs={1}>
                                <StyledIconButton
                                    onClick={() => {
                                        props.onClose();
                                        setCancelConfirmation(false);
                                    }}
                                >
                                    <CloseIcon color="error" />
                                </StyledIconButton>
                            </Grid>
                        </Grid>
                        <RadioGroup
                            name="reoccuring"
                            value={cancelType}
                            onChange={(e) => {
                                setCancelType(e.target.value);
                            }}
                            style={{ marginTop: 10 }}
                        >
                            {job.status === 'APPROVED_PAIRING' && !request && (
                                <Label
                                    value="skip"
                                    control={<Radio color="primary" disableRipple disableFocusRipple />}
                                    label={<Typography variant="subtitle1">This day</Typography>}
                                />
                            )}
                            {numberOfDaysInRequest > 1 && !request && (
                                <Label
                                    value="cancelAppt"
                                    control={<Radio color="primary" disableRipple disableFocusRipple />}
                                    label={
                                        <Typography variant="subtitle1">
                                            This day and all following {job.days_of_week}s
                                        </Typography>
                                    }
                                />
                            )}
                            {!job.deactivated ? (
                                <Label
                                    value="cancelRequest"
                                    control={<Radio color="primary" disableRipple disableFocusRipple />}
                                    label={<Typography variant="subtitle1">This entire job</Typography>}
                                />
                            ) : null}
                        </RadioGroup>
                        {!cancelConfirmation ? (
                            <Button
                                fullWidth
                                style={{ marginTop: 10 }}
                                onClick={async () => {
                                    if (cancelType === 'skip') {
                                        await skip();
                                        props.onClose();
                                    } else {
                                        setWhyCancelDialogVisible(true);
                                        props.setOpen(false);
                                        getCancellationReasons(props);
                                    }
                                }}
                                disableFocusRipple
                                disableRipple
                                disabled={cancelType === ''}
                            >
                                {buttonText}
                            </Button>
                        ) : (
                            <Button fullWidth style={{ marginTop: 10 }} disabled disableFocusRipple disableRipple>
                                Job(s) Cancelled
                            </Button>
                        )}
                    </DialogContent>
                </Dialog>
            </React.Fragment>
            <WhyCancelDialog
                history={props.history}
                type={cancelType === 'cancelRequest' ? 'request' : 'appointment'}
                open={whyCancelDialogVisible}
                job={job}
                reasons={reasonDict}
                reasonType={reasonType}
                onClose={() => {
                    if (cancelConfirmation) {
                        props.onClose();
                    } else {
                        props.setOpen(true);
                    }
                    setWhyCancelDialogVisible(false);
                    setCancelConfirmation(false);
                }}
                cancel={() => {
                    setCancelConfirmation(true);
                    cancelType === 'cancelAppt' ? cancelAppt() : cancelRequest();
                }}
                refresh={() => props.refresh()}
                // closes the previous dialog and opens up the new requested sitter
                requestNewSitter={() => {
                    requestNewSitter(cancelType);
                    setWhyCancelDialogVisible(false);
                }}
            />
        </div>
    );
}

export { ViewMoreOptions, CancelApptOrRequestDialog };
