import React from 'react';
import { Paper } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

export interface PayPaperProps {
    children?: React.ReactNode;
}

const CardPaper = withStyles({
    root: () => ({
        borderRadius: 18,
        padding: 24,
        width: '100%',
    }),
})(Paper);

const PayPaperVertical = withStyles({
    root: () => ({
        borderRadius: 13,
        paddingBottom: 15,
        paddingTop: 42,
    }),
})(Paper);

const PayPaperHalf = withStyles({
    root: () => ({
        paddingLeft: 16,
        paddingRight: 16,
        marginBottom: 20,
    }),
})(PayPaperVertical);

const PayPaper = withStyles({
    root: () => ({
        paddingLeft: 32,
        paddingRight: 32,
        width: '100%',
        marginBottom: 20,
    }),
})(PayPaperVertical);

const PayBackgroundPaper = ({ children }: PayPaperProps) => {
    return <PayPaper elevation={4}>{children}</PayPaper>;
};

const PayCardPaper = ({ children }: PayPaperProps) => {
    return <CardPaper elevation={4}>{children}</CardPaper>;
};

const PayCardBackgroundPaper = ({ children }: PayPaperProps) => {
    return <PayPaperHalf elevation={4}>{children}</PayPaperHalf>;
};

export { PayBackgroundPaper, PayCardPaper, PayCardBackgroundPaper };
