import React, { useEffect, useState } from 'react';
import { Label } from 'models';
import { Checkbox, TextButton } from 'library';
import { AllLabelsKeys, useAdminAppContext } from '../AdminAppContext';

interface LabelSelectionProps {
    selectedLabels: Label[];
    type: AllLabelsKeys;
    setSelectedLabels: (labels: Label[]) => void;
    columns?: number;
    prioritizedLabels?: string[];
    showViewMore?: boolean;
}

export default function LabelSelection({
    selectedLabels,
    type,
    setSelectedLabels,
    columns,
    prioritizedLabels,
    showViewMore,
}: LabelSelectionProps) {
    const { allLabels } = useAdminAppContext();
    const [orderedLabels, setOrderedLabels] = useState<Label[]>([]);
    const [viewMore, setViewMore] = useState(false);

    useEffect(() => {
        // allLabels can reference an empty object.
        if (!allLabels[type]) {
            return;
        }

        let typeLabels = [...(allLabels[type] as Label[])];
        typeLabels.sort((a, b) => {
            let aPrioritized = prioritizedLabels?.includes(a.text);
            let bPrioritized = prioritizedLabels?.includes(b.text);
            if (aPrioritized && !bPrioritized) return -1;
            else if (bPrioritized && !aPrioritized) return 1;
            else return a.text.localeCompare(b.text);
        });
        setOrderedLabels(typeLabels);
    }, [allLabels, prioritizedLabels, type]);

    function toggleSelectedLabel(label: Label) {
        let index = selectedLabels.indexOf(label);
        let updatedSelections = [...selectedLabels];
        if (index > -1) {
            updatedSelections.splice(index, 1);
        } else {
            updatedSelections.push(label);
        }
        setSelectedLabels(updatedSelections);
    }

    return (
        <div style={{ columns: `${columns || 1}` }}>
            {orderedLabels
                .slice(0, !showViewMore || viewMore ? orderedLabels.length : prioritizedLabels?.length || 5)
                .map((label) => (
                    <Checkbox
                        key={label.id}
                        label={label.text}
                        checked={selectedLabels.includes(label)}
                        onChange={() => toggleSelectedLabel(label)}
                        style={{ display: (columns || 1) > 1 ? 'inherit' : 'flex' }}
                    />
                ))}
            {showViewMore && (
                <TextButton textStyle={{ fontSize: 14 }} onClick={() => setViewMore(!viewMore)}>
                    {viewMore ? 'view less' : 'view more'}
                </TextButton>
            )}
        </div>
    );
}
