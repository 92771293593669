import React, { useState, useEffect } from 'react';
import { Grid, GridJustification, Paper } from '@material-ui/core';
import { Text } from 'library';
import { Colors } from '../../../constants';
import { ConversationRowProps } from './OutreachModels';
import moment from 'moment';

const OutreachConversationRow = ({ data, externalNumber }: ConversationRowProps) => {
    const [flexDirection, setFlexDirection] = useState<GridJustification>('flex-start');
    const [displayStyle, setDisplayStyle] = useState<object>({});
    const [paperStyle, setPaperStyle] = useState<object>();

    useEffect(() => {
        if (data.sender_phone_number !== externalNumber) {
            setFlexDirection('flex-end');
            setDisplayStyle({ textAlign: 'right' });
        } else {
            setFlexDirection('flex-start');
            setDisplayStyle({ textAlign: 'left' });
        }
        if (data.status === 'error') {
            setPaperStyle({ padding: 10, backgroundColor: Colors.lightRed });
        } else if (!data.viewed_at && data.sender_phone_number === externalNumber) {
            setPaperStyle({ padding: 10, backgroundColor: Colors.lightGreen });
        } else {
            setPaperStyle({ padding: 10 });
        }
    }, [data]);

    return (
        <Grid item container justify={flexDirection} style={{ marginBottom: 10 }}>
            <Grid item xs={8} style={displayStyle}>
                <Paper style={paperStyle}>
                    <Text variant="body2">{data.message}</Text>
                    {data.image && (
                        <div>
                            <img
                                src={data.image['image'] ? data.image['image'] : data.image['url']}
                                style={{ maxHeight: 150, maxWidth: 150, display: 'block' }}
                                alt=""
                            ></img>
                            {data.image['url'] ? (
                                <a href={data.image['url']} target="_blank" rel="noreferrer">
                                    View
                                </a>
                            ) : null}
                        </div>
                    )}
                </Paper>
            </Grid>
            {data.error_message ? (
                <Grid item xs={8} style={displayStyle}>
                    <Text variant="body2" color={Colors.red}>
                        {data.error_message}
                    </Text>
                </Grid>
            ) : null}
            <Grid item xs={8} style={displayStyle}>
                <Text variant="body3">{moment(data.added_at).format('MM/DD/YYYY hh:mm A')}</Text>
                {data.viewed_at && data.sender_phone_number !== externalNumber ? (
                    <Text variant="body3" textStyle={{ color: Colors.mediumRed }}>
                        Worker Viewed: {data.viewed_at}
                    </Text>
                ) : null}
            </Grid>
        </Grid>
    );
};

export default OutreachConversationRow;
