import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { withRouter, useHistory } from 'react-router-dom';
import { useUserContext } from '../../UserContext';
import BackgroundPaper from '../../reusableComponents/backgroundPaper';
import PasswordInput from '../../reusableComponents/passwordInput';
import Input from '../../reusableComponents/input';
import Header from '../../reusableComponents/header';
import { client, consoleLogInDev, validateEmail } from 'shared';
import FamilyLandingBackground from './FamilyLanding/FamilyLandingBackground';
import { PrimaryButton } from 'library';

const AbbreviatedSignup = () => {
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const [isEmail, setIsEmail] = useState(true);
    const [isPassword, setIsPassword] = useState(true);
    const [user, setUser] = useState();
    const history = useHistory();

    const { refreshUser } = useUserContext();

    const getUser = () => {
        client('api/users/identity/')
            .then((response) => setUser(response))
            .catch(consoleLogInDev);
    };

    useEffect(getUser, []);

    const validatePassword = () => {
        const valid = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?\d)(?=.*?[#?!@$%^&*-]).{8,}$/;
        const validPassword = valid.test(password);
        setIsPassword(validPassword);
    };

    const checkValidEmail = () => {
        const validEmail = validateEmail(email);
        setIsEmail(validEmail);
    };

    const onChangeText = async (name, e) => {
        if (name === 'email') {
            setEmail(e.target.value.toLocaleLowerCase());
        } else {
            setPassword(e.target.value);
        }
    };

    const signUp = async () => {
        checkValidEmail();
        validatePassword();
        if (isEmail && isPassword) {
            const patchBody = {
                email: email,
                password: password,
            };
            client(`api/users/${user.id}/secondary-user/`, {
                method: 'PATCH',
                body: patchBody,
            })
                .then(() => {
                    refreshUser();
                    history.push('/home');
                })
                .catch(consoleLogInDev);
        }
    };

    return (
        <FamilyLandingBackground>
            <Grid container style={{ height: '75vh' }} justify="center" alignContent="flex-start">
                <Grid item lg={4} md={5} sm={9} xs={10}>
                    <BackgroundPaper style={{ maxHeight: 275, marginBottom: 15 }}>
                        <Grid item container direction="column" justify="center">
                            <Header variant="h5" gutterBottom>
                                Finish Signing Up
                            </Header>
                            <Input
                                fullWidth
                                whiteBackground
                                label="Email"
                                variant="outlined"
                                id="outlined-error-helper-text"
                                value={email}
                                onChange={(e) => onChangeText('email', e)}
                                error={!isEmail}
                                helperText={isEmail ? '' : 'Please enter your email'}
                                inputProps={{ autoCapitalize: 'none' }}
                                fillColor="#ffffff"
                            />
                            <PasswordInput
                                style={{ marginBottom: 10 }}
                                fullWidth
                                noMargin
                                whiteBackground
                                id="password"
                                label="Password"
                                variant="outlined"
                                value={password}
                                onChange={(e) => onChangeText('password', e)}
                                error={!isPassword}
                                helperText="Password must be minimum 8 characters with at least one uppercase letter, one lowercase letter, one digit, and one special character."
                                inputProps={{ autoCapitalize: 'none' }}
                                autoComplete="off"
                            />
                            <PrimaryButton
                                style={{
                                    marginTop: 10,
                                    marginBottom: 10,
                                }}
                                onClick={signUp}
                            >
                                Get Started
                            </PrimaryButton>
                        </Grid>
                    </BackgroundPaper>
                </Grid>
            </Grid>
        </FamilyLandingBackground>
    );
};

export default withRouter(AbbreviatedSignup);
