import EditableInfo from './EditableInfo';
import React, { useEffect, useState } from 'react';
import { IContactInfo } from './models';
import { client, consoleLogInDev } from 'shared';
import { Business, UserContextUser } from 'models';
import { useUserContext } from 'UserContext';
import useContact from './hooks/useContact';
import ContactInfo from 'parent-portal/BusinessLocation/ContactInfo';
import EnterVerificationCode from './EnterVerificationCode';

export default function MainContactInfo({
    user,
    business,
    editing,
    updateEditing,
}: {
    user: UserContextUser;
    business: Business;
    editing: boolean;
    updateEditing: (b: boolean) => void;
}) {
    const { refreshAlreadyLoggedInUser } = useUserContext();
    const [loading, setLoading] = useState(false);
    const [showVerificationModal, setShowVerificationModal] = useState(false);

    const currentContact: IContactInfo = {
        businessName: business.name,
        firstName: user.first_name,
        lastName: user.last_name,
        email: user.email,
        phoneNumber: user.phone_number,
        contactPreference: user.communication_preference,
    };
    const { mainContactInfo, updateMainContactInfo, populateCurrentContactInfo, submissionData } =
        useContact(currentContact);

    useEffect(() => {
        if (!editing) {
            populateCurrentContactInfo();
        }
    }, [user, business, editing]);

    function submitData(additionalSubmitData?: object) {
        return client(`api/users/${user.id}/`, {
            method: 'PATCH',
            body: additionalSubmitData ? { ...submissionData, ...additionalSubmitData } : submissionData,
        });
    }

    function onSubmitSuccess() {
        refreshAlreadyLoggedInUser();
        updateEditing(false);
    }

    function onSave() {
        if (submissionData.email || submissionData.phone_number) {
            setShowVerificationModal(true);
            return;
        }

        save();
    }

    function save() {
        if (mainContactInfo) {
            setLoading(true);
            submitData()
                .then(() => onSubmitSuccess())
                .catch(consoleLogInDev)
                .finally(() => setLoading(false));
        }
    }

    if (!mainContactInfo) return <></>;

    return (
        <EditableInfo
            title="Main Contact Information"
            editing={editing}
            updateEditing={updateEditing}
            save={onSave}
            saveDisabled={
                loading ||
                !Object.keys(currentContact)
                    .map((k) => currentContact[k as keyof IContactInfo] === mainContactInfo[k as keyof IContactInfo])
                    .includes(false)
            }
        >
            <ContactInfo newContact={mainContactInfo} updateContact={updateMainContactInfo} editing={editing} />
            <EnterVerificationCode
                isModalOpen={showVerificationModal}
                onSubmitCode={(additionalSubmitData: object) => submitData(additionalSubmitData)}
                onSubmitSuccess={onSubmitSuccess}
                onClose={() => setShowVerificationModal(false)}
            />
        </EditableInfo>
    );
}
