import React, { useState, KeyboardEvent } from 'react';
import { Grid } from '@material-ui/core';
import { PrimaryButton, Text, SecondaryButton, TextButton } from 'library';
import PublicApplicationInput from '../public_app/PublicApplicationInput';
import { SearchProps } from './OutreachModels';
import OutreachNewMessageModal from './OutreachNewMessageModal';

const DEFAULT_HELPER = 'Enter phone, user id, or last name to search';

const OutreachSearch = ({
    onSearch,
    search,
    senderPhone,
    refreshThreads,
    setSelectedNumber,
    setSearch,
    selectedNumber,
}: SearchProps) => {
    const [sendModalOpen, setSendModalOpen] = useState<boolean>(false);

    function onKeyDownInput(event: KeyboardEvent) {
        if (event.key === 'Enter') {
            onSearch();
        }
    }

    return (
        <Grid container style={{ marginBottom: 10 }}>
            <Grid item container direction="row" alignItems="center">
                <Grid item style={{ marginRight: 10 }}>
                    <PublicApplicationInput
                        label="Phone or Name"
                        value={search}
                        rows={1}
                        onChange={(lbl, e) => setSearch(e.target.value)}
                        onKeyDown={(event: KeyboardEvent) => onKeyDownInput(event)}
                    />
                </Grid>
                <Grid item>
                    <PrimaryButton onClick={() => onSearch()} disabled={!search}>
                        Search
                    </PrimaryButton>
                </Grid>
                <Grid item style={{ marginLeft: 10 }}>
                    <SecondaryButton onClick={() => setSendModalOpen(true)} disabled={!search}>
                        Send New Message
                    </SecondaryButton>
                </Grid>
                <Grid item style={{ marginLeft: 10 }}>
                    <TextButton
                        onClick={() => {
                            onSearch(true);
                        }}
                    >
                        Clear
                    </TextButton>
                </Grid>
            </Grid>
            {!search ? (
                <Grid item>
                    <Text variant="body2">{DEFAULT_HELPER}</Text>
                </Grid>
            ) : null}
            <OutreachNewMessageModal
                open={sendModalOpen}
                onClose={() => {
                    setSendModalOpen(false);
                    if (refreshThreads) {
                        refreshThreads();
                        setSelectedNumber(search);
                    }
                }}
                search={search}
                senderPhone={senderPhone}
                selectedNumber={selectedNumber || ''}
            ></OutreachNewMessageModal>
        </Grid>
    );
};

export default OutreachSearch;
