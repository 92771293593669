import React from 'react';
import ShiftBaseCard from './ShiftCard/ShiftBaseCard';
import { BusinessSlot } from 'models';
import { Colors } from 'library';

export const CalendarColors: { [key: string]: string } = {
    TRIAL_RUN: Colors.lightGreen,
    SUBSTITUTE: Colors.lightTurq,
};

export default function HiredShift({ slot, refresh }: { slot: BusinessSlot; refresh: () => void }) {
    return (
        <>
            {slot.appointments.map((appt) =>
                appt.providers.map((provider) => (
                    <ShiftBaseCard
                        key={provider.provider.id}
                        provider={provider.provider}
                        pay={provider.pay}
                        status={provider.status}
                        provider_id={provider.provider_id}
                        ongoing_request={appt.ongoing_request}
                        slot={slot}
                        refresh={refresh}
                        provider_action_required={provider.provider_action_required}
                        review_score={provider.review_score}
                        needs_center_action={provider.needs_center_action}
                    />
                )),
            )}
        </>
    );
}
