import React from 'react';
import { Colors } from 'library';

export default function Divider({ style }: { style?: React.CSSProperties }) {
    return (
        <div
            style={{ height: 1, backgroundColor: Colors.disabledGrey, marginLeft: '-5%', marginRight: '-5%', ...style }}
        />
    );
}
