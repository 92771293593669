import React, { useState } from 'react';
import { AppliedProvider, Job } from 'models';
import { PrimaryButton } from 'library';
import { client, consoleLogInDev } from 'shared';
import { useUserContext } from 'UserContext';
import HireProviderModal from 'parent-portal/sitters/ProviderPreview/Components/HireProviderDialog/HireProviderModal';
import AuthModal from 'parent-portal/login/AuthModal';

const HireProviderAction = ({
    provider,
    job,
    style,
}: {
    provider: AppliedProvider;
    job: Job;
    style?: React.CSSProperties;
}) => {
    const [showDialog, setShowDialog] = useState(false);
    const [showCompleteProfileDialog, setShowCompleteProfileDialog] = useState(false);
    const { user } = useUserContext();

    const onClick = () => {
        if (!user?.claimed) {
            setShowCompleteProfileDialog(true);
            return;
        }
        let requestObject = {
            job: job.id,
            provider: provider.id,
        };
        client('api/text-olivia-family-started-hire/', {
            body: requestObject,
        }).catch(consoleLogInDev);

        setShowDialog(true);
    };

    function showAppropriateModal() {
        if (user?.claimed && (provider.days_applied?.length || 0) > 0) {
            return (
                <HireProviderModal
                    provider={provider}
                    isOpen={showDialog}
                    onClose={() => {
                        setShowDialog(false);
                    }}
                    job={job}
                    daysAppliedTo={provider.days_applied || []}
                    previouslyFilledDays={job.filled_days}
                    onOpen={() => {
                        setShowDialog(true);
                    }}
                />
            );
        } else if (!user?.claimed && showCompleteProfileDialog) {
            return (
                <AuthModal
                    location="Third Party Signup"
                    open={showCompleteProfileDialog}
                    onComplete={() => {
                        setShowCompleteProfileDialog(false);
                    }}
                    onClose={() => {
                        setShowCompleteProfileDialog(false);
                    }}
                    redirect={false}
                    signup
                />
            );
        } else {
            return null;
        }
    }

    return (
        <>
            <PrimaryButton onClick={onClick} buttonStyle={style}>
                Hire
            </PrimaryButton>
            {showAppropriateModal()}
        </>
    );
};

export default HireProviderAction;
