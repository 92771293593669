import React from 'react';
import { Grid } from '@mui/material';
import { Colors, Text } from 'library';

export default function Holiday({ holidays }: { holidays: { name: string; date: string }[] }) {
    return (
        <>
            {holidays.map((holiday) => (
                <Grid
                    key={holiday.name}
                    style={{
                        backgroundColor: Colors.turquoise,
                        width: '100%',
                        height: 28,
                        padding: 2,
                        borderTop: `1px solid ${Colors.lightGrey}`,
                    }}
                >
                    <Text bold align="center" color={Colors.white}>
                        {holiday.name}
                    </Text>
                </Grid>
            ))}
        </>
    );
}
