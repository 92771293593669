import { IBusinessLocation } from 'models';
import { IBusinessFaqQuestions } from 'parent-portal/home/Models/DashboardData';

interface ILocationFaqs {
    [key: string]: string;
}

export function useLocationRequirements({
    faqQuestions,
    businessLocation,
}: {
    faqQuestions: IBusinessFaqQuestions[];
    businessLocation: IBusinessLocation;
}) {
    const locationFaqsAnswered = {} as ILocationFaqs;

    businessLocation.business.business_faqs
        .filter((faq) => faq.answer)
        .forEach((faq) => {
            locationFaqsAnswered[faq.question.id] = faq.answer;
        });
    businessLocation.business_faqs
        .filter((faq) => faq.answer)
        .forEach((faq) => {
            locationFaqsAnswered[faq.question] = faq.answer;
        });

    const locationFaqsAnsweredSet = new Set(Object.keys(locationFaqsAnswered));

    const unansweredFaqQuestions = faqQuestions.filter((faq) => {
        return !locationFaqsAnsweredSet.has(String(faq.id));
    });

    const locationDetails = [
        { message: 'Photo of location', filled: businessLocation.location_photos?.length > 0 },
        {
            message: 'Administration info',
            filled: businessLocation.location_administration?.some((it) => it.photo?.image && it.name),
        },
        {
            message: 'Capacity of center',
            filled: businessLocation.capacity && businessLocation.capacity > 0,
        },
        {
            message: 'Center website',
            filled: businessLocation.website && businessLocation.website !== '',
        },
        {
            message: 'Center education program type',
            filled: businessLocation.education_type_names?.length > 0,
        },
    ];

    const unfilledDetails = [
        ...locationDetails.filter((it) => !it.filled).map((it) => it.message),
        ...unansweredFaqQuestions.map((it) => it.question),
    ];

    const totalNumberOfDetails = locationDetails.length + faqQuestions.length;
    const percentFilled = Math.round(((totalNumberOfDetails - unfilledDetails.length) / totalNumberOfDetails) * 100);

    return { unfilledDetails, percentFilled };
}
