import { DayOfWeek, DayOfWeekSelections } from 'parent-portal/forms/JobRequestTypes';
import moment from 'moment';

export function checkIfAnyDayIsSelected(days: DayOfWeekSelections) {
    return Object.values(days).some((day) => day.selected === true);
}

export function checkIfAllDaysHaveTime(days: DayOfWeekSelections) {
    const status: boolean[] = Object.keys(days).reduce((res: boolean[], day: string) => {
        const currentDay = days[day as keyof typeof days];
        if (!currentDay.selected) {
            res.push(true);
        } else if (currentDay.start == null || currentDay.end == null) {
            res.push(false);
        } else {
            res.push(checkIfDurationIsValid(currentDay) && checkIfEndTimeIsValid(currentDay));
        }
        return res;
    }, []);

    return !status.includes(false);
}

export function checkIfDurationIsValid(day: DayOfWeek) {
    if (day.start == null || day.end == null) return true;
    return moment(day.end).diff(day.start, 'minutes') > 30;
}

export function checkIfEndTimeIsValid(day: DayOfWeek) {
    if (day.start == null || day.end == null) return true;
    return moment(day.end).isAfter(day.start);
}
