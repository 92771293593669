import { Moment } from 'moment';
import React from 'react';
import FormField from '../../FormField';
import { FormFieldProps } from '../../FormTypes';
import JobDatePicker from '../DateAndTimePage/JobDatePicker';

export default function OneTimeJobDateInput({ jobDate }: { jobDate: FormFieldProps<Moment | null> }) {
    return (
        <FormField>
            <JobDatePicker jobDate={jobDate} title="Date*" />
        </FormField>
    );
}
