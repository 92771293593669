import { useMediaQuery } from '@mui/material';
import React from 'react';
import theme from 'theme';
import DownloadOnGooglePlayButton from './DownloadOnGooglePlayButton';
import DownloadOnTheAppStoreButton from './DownloadOnTheAppStoreButton';

export default function DownloadTheAppButtons({ style }: { style?: React.CSSProperties }) {
    const xsOnly = useMediaQuery(theme.breakpoints.only('xs'));
    return (
        <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', maxWidth: 410, ...style }}>
            <DownloadOnTheAppStoreButton />
            <div style={{ width: xsOnly ? '100%' : 10, height: 10 }} />
            <DownloadOnGooglePlayButton />
        </div>
    );
}
