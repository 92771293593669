import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Colors, Text } from 'library';

export type KebabMenuItems = {
    text: string;
    icon?: JSX.Element;
};

interface MenuProps {
    options: KebabMenuItems[];
    onSelect: (e: string) => void;
    Icon?: any;
    iconColor?: string;
}

export default function LongMenu({ options, onSelect, Icon, iconColor }: MenuProps) {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (e: string) => {
        setAnchorEl(null);
        onSelect(e);
    };

    return (
        <div style={{ zIndex: 1000 }}>
            <IconButton
                style={{ outline: 'none' }}
                aria-label="more"
                id="long-button"
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
            >
                {!!Icon ? (
                    <Icon htmlColor={!!iconColor ? iconColor : Colors.darkNavy} />
                ) : (
                    <MoreVertIcon htmlColor={!!iconColor ? iconColor : Colors.darkNavy} />
                )}
            </IconButton>
            <Menu
                id="long-menu"
                MenuListProps={{
                    'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        minWidth: 100,
                        borderRadius: 6,
                        display: 'flex',
                        flexDirection: 'column',
                    },
                }}
            >
                {options.map((option) => (
                    <MenuItem
                        key={option.text}
                        selected={anchorEl?.innerText === option.text}
                        onClick={() => handleClose(option.text)}
                        divider
                    >
                        {!!option?.icon ? option.icon : null}
                        <Text variant="caption" bold textStyle={{ color: Colors.darkNavy }}>
                            {option.text}
                        </Text>
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
}
