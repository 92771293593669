import React, { useEffect, useState } from 'react';
import { Colors, Text } from 'library';
import { TextField } from '@mui/material';
import { add, set, sub } from 'date-fns';
import { formatMonthYearWith12HourTime } from 'shared/Dates';
import FormField from '../../FormField';

import type { FormFieldProps } from '../../FormTypes';

const MAX_POSITIVE_HOURS = 6;
const MAX_NEGATIVE_HOURS = 24;

export function BusinessLastHireTimePicker({
    lastHireOffsetMinutes,
}: {
    lastHireOffsetMinutes: FormFieldProps<number>;
}) {
    function minutesToHours(minutes?: number) {
        if (minutes === 0) return 0;
        if (!minutes) return;

        return Math.abs(minutes > 0 ? Math.floor(minutes / 60) : Math.ceil(minutes / 60));
    }

    function moduloMinutes(minutes?: number) {
        if (minutes === 0) return 0;
        if (!minutes) return;

        return Math.abs(minutes % 60);
    }

    const initialPositive = lastHireOffsetMinutes.value ? lastHireOffsetMinutes.value > 0 : true;

    const [positive, setPositive] = useState<boolean>(initialPositive);
    const [hours, setHours] = useState<number>(minutesToHours(lastHireOffsetMinutes.value) ?? 4);
    const [minutes, setMinutes] = useState<number>(moduloMinutes(lastHireOffsetMinutes.value) ?? 0);

    function hoursChange(e: React.ChangeEvent<HTMLInputElement>) {
        const changedHours = parseInt(e.target.value);

        if (positive) {
            if (minutes > 0 && changedHours >= MAX_POSITIVE_HOURS) {
                setHours(MAX_POSITIVE_HOURS);
                setMinutes(0);
            } else {
                setHours(changedHours);
            }
        } else {
            if (minutes > 0 && changedHours >= MAX_NEGATIVE_HOURS) {
                setHours(MAX_NEGATIVE_HOURS);
                setMinutes(0);
            } else {
                setHours(changedHours);
            }
        }
    }

    function minutesChange(e: React.ChangeEvent<HTMLInputElement>) {
        const changedMinutes = parseInt(e.target.value);

        if (positive) {
            if (hours < MAX_POSITIVE_HOURS) setMinutes(changedMinutes);
        } else {
            if (hours < MAX_NEGATIVE_HOURS) setMinutes(changedMinutes);
        }
    }

    function onPositiveClick() {
        setPositive(true);

        if (hours >= MAX_POSITIVE_HOURS) {
            setHours(MAX_POSITIVE_HOURS);
            setMinutes(0);
        }
    }

    function onNegativeClick() {
        setPositive(false);
    }

    const tomorrowAtNoon = set(add(new Date(), { days: 1, hours: 0, minutes: 0 }), {
        hours: 12,
        minutes: 0,
        milliseconds: 0,
    });
    const lastHireTime = positive ? add(tomorrowAtNoon, { hours, minutes }) : sub(tomorrowAtNoon, { hours, minutes });

    useEffect(() => {
        lastHireOffsetMinutes.setValue((hours * 60 + minutes) * (positive ? 1 : -1));

        if (positive) {
            if ((minutes > 0 && hours >= MAX_POSITIVE_HOURS) || hours > MAX_POSITIVE_HOURS) {
                lastHireOffsetMinutes.setValid(false);
            }
            lastHireOffsetMinutes.setValid(true);
        } else {
            if ((minutes > 0 && hours >= MAX_NEGATIVE_HOURS) || hours > MAX_NEGATIVE_HOURS) {
                lastHireOffsetMinutes.setValid(false);
            }
            lastHireOffsetMinutes.setValid(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hours, minutes, positive]);

    return (
        <FormField>
            <div style={{ display: 'flex', marginTop: 20 }}>
                <Text textStyle={{ color: Colors.turquoise }} bold>
                    5. What is the latest time that Tandem can place a worker?
                </Text>
            </div>
            <Text textStyle={{ paddingTop: 10, paddingBottom: 10 }} variant="body2">
                This is the latest time Tandem would place a worker if there was a cancellation or the shift went
                unfilled.
            </Text>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <Text
                    variant="h2"
                    bold
                    onClick={onPositiveClick}
                    textStyle={{
                        ...styles.signButton,
                        backgroundColor: positive ? Colors.lightTurq : Colors.lightGrey,
                        color: positive ? Colors.turquoise : Colors.darkGrey,
                        borderTopLeftRadius: 5,
                        borderBottomLeftRadius: 5,
                    }}
                >
                    +
                </Text>
                <Text
                    variant="h2"
                    bold
                    onClick={onNegativeClick}
                    textStyle={{
                        ...styles.signButton,
                        backgroundColor: positive ? Colors.lightGrey : Colors.lightTurq,
                        color: positive ? Colors.darkGrey : Colors.turquoise,
                        borderTopRightRadius: 5,
                        borderBottomRightRadius: 5,
                    }}
                >
                    -
                </Text>
                <TextField
                    id="last-hire-time-picker-hours"
                    variant="standard"
                    placeholder="hh"
                    label="Hours"
                    type="number"
                    value={hours}
                    onChange={hoursChange}
                    sx={styles.inputStyles}
                    InputProps={{
                        inputProps: {
                            max: positive ? MAX_POSITIVE_HOURS : MAX_NEGATIVE_HOURS,
                            min: 0,
                        },
                    }}
                />
                <TextField
                    id="last-hire-time-picker-minutes"
                    variant="standard"
                    placeholder="mm"
                    label="Minutes"
                    type="number"
                    value={minutes}
                    onChange={minutesChange}
                    sx={styles.inputStyles}
                    InputProps={{
                        inputProps: {
                            min: 0,
                            max: 59,
                        },
                    }}
                />
                <Text variant="body1">
                    {positive ? 'after ' : 'before '} {"shift's start time"}
                </Text>
            </div>

            <Text variant="caption" textStyle={{ paddingTop: 10 }}>
                For example, if the shift starts {formatMonthYearWith12HourTime(tomorrowAtNoon)}, then we would not hire
                for it after {formatMonthYearWith12HourTime(lastHireTime)}.
            </Text>
        </FormField>
    );
}

const styles = {
    inputStyles: {
        fontFamily: 'WorkSans-Regular',
        width: 80,
        marginLeft: 2,
        color: Colors.darkNavy,
        borderColor: Colors.darkNavy,
        '& .Mui-focused': {
            color: Colors.darkNavy,
        },
    },
    signButton: {
        cursor: 'pointer',
        width: 30,
        height: 30,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    newTag: {
        color: Colors.terraCotta,
        backgroundColor: Colors.lightTerraCotta,
        padding: '2px 5px',
        border: `1px solid ${Colors.terraCotta}`,
        borderRadius: 5,
        marginLeft: 10,
    },
};
