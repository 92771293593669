import { addDays, nextMonday, previousMonday } from 'date-fns';
import { BusinessJobWithAppointments } from 'models';
import { client } from 'shared';
import { getThisWeeksMonday } from 'shared/Dates';

export interface WorkerShiftsJobs {
    lastWeek: BusinessJobWithAppointments[];
    thisWeek: BusinessJobWithAppointments[];
    nextWeek: BusinessJobWithAppointments[];
}

export async function getUpcomingShifts(providerUserId: number): Promise<WorkerShiftsJobs> {
    const results = await client(`api/admin-jobs/upcoming-provider-shifts/?user_id=${providerUserId}`);
    const thisWeekMonday = getThisWeeksMonday();
    const lastWeekMonday = previousMonday(thisWeekMonday);
    const nextWeekMonday = nextMonday(thisWeekMonday);
    const lastWeekJobs = sortByFirstSlot(filterSlotsByWeek(filterJobsByWeek(results, lastWeekMonday), lastWeekMonday));
    const thisWeekJobs = sortByFirstSlot(filterSlotsByWeek(filterJobsByWeek(results, thisWeekMonday), thisWeekMonday));
    const nextWeekJobs = sortByFirstSlot(filterSlotsByWeek(filterJobsByWeek(results, nextWeekMonday), nextWeekMonday));
    return { lastWeek: lastWeekJobs, thisWeek: thisWeekJobs, nextWeek: nextWeekJobs };
}
function filterJobsByWeek(jobs: BusinessJobWithAppointments[], week: Date) {
    return jobs.filter((job) =>
        job.slots.some((slot) => {
            const slotDate = new Date(slot.start_date);
            return slotDate >= week && slotDate < addDays(week, 7) && slot.appointments.length > 0;
        }),
    );
}
function filterSlotsByWeek(jobs: BusinessJobWithAppointments[], week: Date) {
    return jobs.map((job) => ({
        ...job,
        slots: job.slots.filter(
            (slot) => new Date(slot.start_date) >= week && new Date(slot.start_date) < addDays(week, 7),
        ),
    }));
}

function sortByFirstSlot(jobs: BusinessJobWithAppointments[]) {
    return jobs.sort((a, b) => {
        const aDate = new Date(a.slots[0].start_date);
        const bDate = new Date(b.slots[0].start_date);
        return aDate.getTime() - bDate.getTime();
    });
}
