import React from 'react';
import { Chip } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Colors } from '../../theme/Colors';

type TOnProbationChip = {
    size?: 'small' | 'medium' | undefined;
};

export default function OnProbationChip({ size }: TOnProbationChip) {
    return <StyledChip label="On Probation" size={size} />;
}

const StyledChip = styled(Chip)({
    backgroundColor: Colors.mediumRed,
    color: Colors.white,
    size: (props: TOnProbationChip) => props.size || 'medium',
});
