import React, { Component } from 'react';
import TitleIcon from '@material-ui/icons/Title';
import Card from '../../../home/components/shared/Card';
import { Grid, Checkbox } from '@material-ui/core';
import { isMobile } from 'react-device-detect';
import moment from 'moment';
import 'react-infinite-calendar/styles.css';
import DateFnsUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardTimePicker, KeyboardDateTimePicker } from '@material-ui/pickers';
import Input from '../../../../reusableComponents/input';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import AccessAlarmsIcon from '@material-ui/icons/AccessAlarms';
import LocalHotelIcon from '@material-ui/icons/LocalHotel';
import CreateIcon from '@mui/icons-material/Create';
import Label from '../../../../reusableComponents/checkboxLabel';
import { getKeyByValue, getKeyByCaseInsensitive } from '../../../../shared/Utils';
import Monday from 'assets/icons/monday-blue.svg';
import Tuesday from 'assets/icons/tuesday-blue.svg';
import Wednesday from 'assets/icons/wednesday-blue.svg';
import Thursday from 'assets/icons/thursday-blue.svg';
import Friday from 'assets/icons/friday-blue.svg';
import Saturday from 'assets/icons/saturday-blue.svg';
import Sunday from 'assets/icons/sunday-blue.svg';
import EditPaySchedule from './EditPaySchedule';
import EditPay from './EditPay';
import { JobCardDialogs } from './JobDialogs';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import JobTitleInput from '../../../forms/components/pages/HeadlineDescriptionPage/JobTitleInput';
import ErrorText from '../../../forms/components/ErrorText';
import JobDescriptionInput from '../../../forms/components/pages/HeadlineDescriptionPage/JobDescriptionInput';
import { PrimaryButton, Text } from 'library';
import {
    IOngoingRequest,
    IState,
    IDay,
    IEventNumber,
    IEventString,
    IDayIcons,
    IAppointment,
    UpcomingAppointment,
    IJobTimeOfDay,
    IFormTimeOfDay,
} from './EditJobModels';
import { client, consoleLogInDev } from 'shared';
import EditRequestTimes from './EditRequestTimes';
import EditIndividualDays from './EditIndividualDays';
import { MixPanelKey } from 'environmentVariables';
import { UserContext } from 'UserContext';

var mixpanel = require('mixpanel-browser');
mixpanel.init(MixPanelKey);

const dayIcons: IDayIcons = {
    Monday: Monday,
    Tuesday: Tuesday,
    Wednesday: Wednesday,
    Thursday: Thursday,
    Friday: Friday,
    Saturday: Saturday,
    Sunday: Sunday,
};

const paySchedule = {
    'Confirm Payment at the end of the job': 'DAILY',
    'Weekly Payment': 'WEEKLY',
    // 'Monthly Payment on the first of the month': 'MONTHLY', // not ready for this quite yet
    'Automatic Payment at the end of every job': 'AUTOMATIC',
};

const payDays = {
    Monday: 'MONDAY',
    Tuesday: 'TUESDAY',
    Wednesday: 'WEDNESDAY',
    Thursday: 'THURSDAY',
    Friday: 'FRIDAY',
};

interface IProps extends RouteComponentProps {}

class Edit extends Component<IProps, IState> {
    request: boolean;
    id: string;
    Monday: IDay;
    Tuesday: IDay;
    Wednesday: IDay;
    Thursday: IDay;
    Friday: IDay;
    Saturday: IDay;
    Sunday: IDay;
    PayScheduleIcon: any;
    changed: boolean;
    no_pairings?: boolean;

    constructor(props: IProps) {
        super(props);
        this.request = window.location.href.includes('request');
        this.state = {
            showErrors: false,
            formInfo: { form_times: [], headline: undefined },
            confirmation: false,
            loading: false,
            reload: false,
            showPayDayDropdown: false,
            saveDisabled: true,
            titleValid: true,
            descriptionValid: true,
            showCancelApptOrRequestDialog: false,
            showCancelOnetimeDialog: false,
            jobForCancelling: null,
            individualAppointments: [],
        };
        this.id = (this.props.match.params as any).id;
        this.Monday = require('assets/icons/monday-blue.svg');
        this.Tuesday = require('assets/icons/tuesday-blue.svg');
        this.Wednesday = require('assets/icons/wednesday-blue.svg');
        this.Thursday = require('assets/icons/thursday-blue.svg');
        this.Friday = require('assets/icons/friday-blue.svg');
        this.Saturday = require('assets/icons/saturday-blue.svg');
        this.Sunday = require('assets/icons/sunday-blue.svg');
        this.PayScheduleIcon = require('assets/icons/payment schedule.png');
        this.changed = false;
    }

    setPayStates = (ongoing_request: IOngoingRequest) => {
        if (ongoing_request.pay_is_fixed) {
            this.setState({
                payFixed: ongoing_request.pay,
                originalPayFixed: ongoing_request.pay,
            });
        } else {
            this.setState({
                payMin: ongoing_request.pay || ongoing_request.rate_min,
                payMax: ongoing_request.pay || ongoing_request.rate_max,
                originalPayMin: ongoing_request.pay || ongoing_request.rate_min,
                originalPayMax: ongoing_request.pay || ongoing_request.rate_max,
            });
        }
    };

    componentDidMount = async () => {
        let job: any;
        try {
            if (this.request) {
                job = await client('api/ongoing/' + this.id + '/?appointments=true');
                var no_pairings = true;
                for (var i = 0; i < job.appointments.length; i++) {
                    no_pairings = no_pairings && job.appointments[i].status === 'NO_PAIRING';
                }
                this.no_pairings = no_pairings;
                this.setPayStates(job);
            } else {
                job = await client('api/upcoming-appointments/' + this.id + '/');
                job['ongoing'] = job.ongoing_request && job.ongoing_request.ongoing;
                if (job.ongoing && job.status === 'APPROVED_PAIRING') {
                    // Updating an approved single day of an ongoing request
                    job.ongoing_request.pay = job.pay;
                }
                this.setPayStates(job.ongoing_request);
            }
        } catch (error) {
            consoleLogInDev(error);
        }

        const isOvernight = !moment(job.end_date).isSame(moment(job.start_date), 'day');

        if (this.request) {
            this.getOngoingJobInfo(isOvernight);
        } else {
            this.setState({
                job,
                formInfo: {
                    start: moment(job.start_date),
                    isStart: true,
                    end: moment(job.end_date),
                    isEnd: true,
                    isOvernight: isOvernight,
                    isDuration: true,
                    pay: job.pay,
                    comments: job.family_comments,
                    originalStart: moment(job.start_date),
                    headline: job.headline,
                },
                jobForCancelling: job,
            });
        }
    };

    getOngoingRequestPay = () => {
        const { payMin, payMax, payFixed } = this.state;
        if (payFixed) {
            return payFixed;
        }
        if (payMin === payMax) {
            return payMin;
        }
        return undefined;
    };

    getOngoingJobInfo = async (isOvernight: boolean) => {
        try {
            let jobsResponse: UpcomingAppointment[];
            jobsResponse = await client(`api/upcoming-appointments/?or-id=${this.id}`);
            if (jobsResponse.length > 0) {
                let job = jobsResponse[0].ongoing_request;
                let form_times =
                    job.times_of_day?.map((d: IJobTimeOfDay) => {
                        let [day, value] = Object.entries(d)[0];
                        var info: any = {};
                        info.day = day;
                        info.icon = dayIcons[info.day as keyof IDayIcons];
                        info.start = moment(value.start, 'HH:mm:ss');
                        info.end = moment(value.end, 'HH:mm:ss');
                        info.isStart = true;
                        info.isEnd = true;
                        info.isDuration = true;
                        return info;
                    }) || [];
                var end_date = moment(job.end_date, 'YYYY-MM-DD');
                var start_date = moment(job.start_date, 'YYYY-MM-DD');
                var duration = end_date.diff(start_date, 'days');
                var isDateRange = duration >= 13;

                let payWindow = undefined;
                if (job.pay_window) {
                    payWindow = getKeyByValue(job.pay_window, paySchedule);
                }
                if (job.automatic) {
                    payWindow = Object.keys(paySchedule)[3];
                }
                let payDay = null;
                if (job.pay_day) {
                    payDay = getKeyByCaseInsensitive(job.pay_day, dayIcons);
                } else {
                    payDay = Object.keys(payDays)[0];
                }

                let appts = jobsResponse
                    .filter((appt: UpcomingAppointment) => !!appt.start_date)
                    .map((d: UpcomingAppointment) => ({
                        ...d,
                        date: moment(d.start_date).format('MMM Do'),
                        icon: dayIcons[d.days_of_week as keyof IDayIcons],
                    }));
                appts.sort((a, b) => (moment(a.start_date).isSameOrBefore(moment(b.start_date)) ? -1 : 1));

                this.setState({
                    formInfo: {
                        form_times,
                        isDateRange: isDateRange,
                        isRequestEnd: job.end_date !== null,
                        isRequestStart: job.start_date !== null,
                        isRequestFuture: !job.start_date && !job.end_date && job.start_month !== null,
                        isStart: true,
                        end: job.end_date ? moment(job.end_date, 'YYYY-MM-DD') : undefined,
                        start: job.start_date ? moment(job.start_date, 'YYYY-MM-DD') : undefined,
                        isEnd: true,
                        isOvernight: isOvernight,
                        isDuration: true,
                        pay: job.pay,
                        comments: job.family_comments,
                        payWindow: payWindow,
                        payDay: payDay,
                        headline: job.headline || undefined,
                    },
                    showPayDayDropdown: payWindow === Object.keys(paySchedule)[1],
                    jobForCancelling: jobsResponse[0],
                    individualAppointments: appts,
                });
            }
        } catch (error) {
            consoleLogInDev(error);
        }
    };

    getOngoingRequestPayMinMax = (variable: number | undefined) => {
        const { payMin, payMax } = this.state;
        if (payMin === payMax) {
            return undefined;
        }
        return variable;
    };

    patchAppointment = async (ongoing_request: IOngoingRequest) => {
        const { job } = this.state;
        try {
            await client('api/edit-appointment/' + job?.id, {
                method: 'PATCH',
                body: ongoing_request,
            });
            this.setState({ confirmation: true });
        } catch (error) {
            consoleLogInDev(error);
        }
        this.setState({ loading: false });
    };

    patchOngoingRequest = async (ongoing_request: IOngoingRequest) => {
        const { formInfo, job } = this.state;
        ongoing_request.times_of_day = formInfo?.form_times
            ? formInfo.form_times.map((d) => {
                  const start = d.start.format('HH:mm:ss');
                  const end = d.end.format('HH:mm:ss');
                  return { [d.day]: { start, end } } as any;
              })
            : [];
        if (formInfo?.form_times) {
            ongoing_request.days_of_week = formInfo.form_times.reduce((p: string[], c: IFormTimeOfDay) => {
                return [...p, c.day];
            }, []);
        }
        if (formInfo.start && formInfo.end) {
            ongoing_request['end_date'] = moment(formInfo.end, 'ddd MMM DD YYYY HH:mm:ss').format('YYYY-MM-DD');
            ongoing_request['start_date'] = moment(formInfo.start, 'ddd MMM DD YYYY HH:mm:ss').format('YYYY-MM-DD');
        } else {
            ongoing_request.end_date = null;
            ongoing_request.start_date = null;
        }
        try {
            await client('api/edit-ongoing-request/' + job?.id, {
                method: 'PATCH',
                body: ongoing_request,
            });

            this.setState({ confirmation: true });

            this.getOngoingJobInfo(formInfo?.isOvernight !== undefined ? formInfo.isOvernight : false);
        } catch (error) {
            consoleLogInDev(error);
        }

        this.setState({ loading: false });
    };

    submitRequest = (role: string) => {
        const { formInfo, job, payMin, payMax } = this.state;
        if (!this.formIsValid(role)) {
            this.setState({ showErrors: true });
            return;
        }
        this.setState({ loading: true });
        let start = undefined;
        let end = undefined;
        let startMoment = null;
        let endMoment = null;
        if (formInfo.start && formInfo.end) {
            start = formInfo.start.toISOString();
            startMoment = moment(start);
            end = formInfo.end.toISOString();
            endMoment = moment(end);
        }

        let ongoing: IOngoingRequest = {
            start_date: start,
            end_date: end,
            pay: this.getOngoingRequestPay(),
            rate_min: this.getOngoingRequestPayMinMax(payMin),
            rate_max: this.getOngoingRequestPayMinMax(payMax),
            family_comments: formInfo.comments,
            automatic: formInfo.payWindow === Object.keys(paySchedule)[3],
            pay_day: formInfo.payWindow === Object.keys(paySchedule)[1] ? formInfo?.payDay?.toUpperCase() : undefined,
            pay_window:
                formInfo.payWindow === 'Confirm Payment at the end of the job' ||
                formInfo.payWindow === 'Weekly Payment' ||
                formInfo.payWindow === 'Automatic Payment at the end of every job'
                    ? paySchedule[formInfo.payWindow]
                    : paySchedule['Confirm Payment at the end of the job'],
            headline: formInfo.headline || null,
            start_month: null,
        };

        if (this.request) {
            this.patchOngoingRequest(ongoing);
        } else {
            this.patchAppointment(ongoing);
        }

        if (this.state.confirmation) {
            this.props.history.push('/jobs');
        }
    };

    onStartDateTimeChange = async (d: MaterialUiPickersDate, isOngoing = false) => {
        const { formInfo, job } = this.state;
        let today = new Date();
        formInfo.isStart = d !== null && d.isValid();
        if (d !== null && d.isValid()) {
            formInfo.start = d;
            if (isOngoing && formInfo.originalStart) {
                formInfo?.start
                    ?.year(formInfo.originalStart.year())
                    .month(formInfo.originalStart.month())
                    .date(formInfo.originalStart.date());
            }

            formInfo?.end?.year(d.year()).month(d.month()).date(d.date());
            if (formInfo.isOvernight) {
                formInfo?.end?.add(1, 'day');
            }

            //The reason this is checking pairings is because if you have already hired
            //someone and the job is in the past, you can still edit it to a date in the past.
            //Only APPROVED pairings are populated in the api.
            if (formInfo?.start?.isSame(today, 'day') && d.diff(today) < 0 && job?.pairings?.length === 0) {
                formInfo.isStart = false;
            } else {
                formInfo.isStart = true;
            }
        }
        if (formInfo.isStart && formInfo.isEnd && formInfo.end) {
            let duration = formInfo.end.diff(formInfo.start, 'minutes') + 1;
            formInfo.isDuration = duration >= 30 && formInfo?.end?.isAfter(formInfo.start);
        } else {
            formInfo.isDuration = true;
        }
        this.changed = true;
        this.setState({ formInfo, confirmation: false, loading: false, saveDisabled: false });
    };

    onEndDateChange = async (d: MaterialUiPickersDate) => {
        const { formInfo } = this.state;
        let today = new Date();

        if (d !== null && d.isValid()) {
            formInfo.end = d;

            if (formInfo.end && formInfo.end.isBefore(today, 'day')) {
                formInfo.isRequestEnd = false;
            } else {
                formInfo.isRequestEnd = true;
            }
        } else {
            formInfo.isRequestEnd = false;
        }

        this.setState({ formInfo, confirmation: false, loading: false, saveDisabled: false });
    };

    onStartDateChange = async (d: MaterialUiPickersDate) => {
        const { formInfo } = this.state;
        let today = new Date();

        if (d !== null && d.isValid()) {
            formInfo.start = d;

            if (formInfo?.start?.isBefore(today, 'day')) {
                formInfo.isRequestStart = false;
            } else {
                formInfo.isRequestStart = true;
            }
        } else {
            formInfo.isRequestStart = false;
        }
        this.changed = true;

        this.setState({ formInfo, confirmation: false, loading: false, saveDisabled: false });
    };

    onEndTimeChange(time: MaterialUiPickersDate) {
        const { formInfo } = this.state;
        formInfo.isEnd = time !== null && time.isValid();
        if (time !== null && time.isValid()) {
            let hour = time.hour();
            let minutes = time.minutes();
            let seconds = time.seconds();
            formInfo?.end?.set({ hour: hour, minute: minutes, second: seconds });
            formInfo.isEnd = formInfo?.start?.isBefore(formInfo.end);
        }
        if (formInfo.isStart && formInfo.isEnd && formInfo.end) {
            let duration = formInfo.end.diff(formInfo.start, 'minutes') + 1;
            formInfo.isDuration = duration >= 30;
        } else {
            formInfo.isDuration = true;
        }
        this.changed = true;
        this.setState({ formInfo, saveDisabled: false });
    }

    onTimeChange(day: string, type: string, time: MaterialUiPickersDate) {
        const { formInfo } = this.state;
        var formDay = formInfo?.form_times?.find((i: { day: string }) => {
            return i.day === day;
        });
        if (time !== null && time.isValid()) {
            let hour = time.hour();
            let minutes = time.minutes();
            let seconds = time.seconds();
            if (type === 'start') {
                if (formDay) {
                    formDay.isStart = true;
                }
                formDay?.start.set({ hour: hour, minute: minutes, second: seconds });
            } else {
                formDay?.end.set({ hour: hour, minute: minutes, second: seconds });
                if (formDay?.start) {
                    formDay.isEnd =
                        (formDay?.start.isSame(formInfo.end, 'hour') && formDay.start.isSame(formDay.end, 'minutes')) ||
                        formDay?.start.isBefore(formDay.end);
                }
            }
        } else {
            if (type === 'start' && formDay) {
                formDay.isStart = time !== null && time.isValid();
            } else {
                if (formDay) {
                    formDay.isEnd = time !== null && time.isValid();
                }
            }
        }
        if (formDay?.isStart && formDay.isEnd) {
            let duration = formDay.end.diff(formDay.start, 'minutes') + 1;
            formDay.isDuration = duration >= 30;
        } else {
            if (formDay) {
                formDay.isDuration = true;
            }
        }
        const index = formInfo?.form_times?.findIndex((i) => {
            return i.day === day;
        });
        if (formInfo?.form_times && index && formDay) {
            formInfo.form_times[index] = formDay;
        }
        this.changed = true;
        this.setState({ formInfo, saveDisabled: false });
    }

    onTextChange(value: string, type: string) {
        const { formInfo } = this.state;
        if (type === 'comments') {
            formInfo.comments = value;
        }
        if (type === 'headline') {
            formInfo.headline = value;
        }
        this.changed = true;
        this.setState({ formInfo, saveDisabled: false });
    }

    onOvernightChange() {
        const { formInfo } = this.state;
        formInfo.isOvernight = !formInfo.isOvernight;
        if (formInfo.isOvernight) {
            const end = moment(formInfo.end).toISOString();
            const newEnd = moment(end).add(1, 'days');
            formInfo.end = newEnd;
        } else {
            const end = moment(formInfo.end).toISOString();
            const newEnd = moment(end).subtract(1, 'days');
            formInfo.end = newEnd;
        }
        formInfo.isEnd =
            (formInfo?.start?.isSame(formInfo.end, 'hour') && formInfo?.start?.isSame(formInfo.end, 'minutes')) ||
            formInfo?.start?.isBefore(formInfo.end);
        if (formInfo.isStart && formInfo.isEnd) {
            const duration = formInfo.end.diff(formInfo.start, 'minutes') + 1;
            formInfo.isDuration = duration >= 30;
        } else {
            formInfo.isDuration = true;
        }
        this.changed = true;
        this.setState({ formInfo, saveDisabled: false });
    }

    onPayWindowChange = (event: IEventString) => {
        const { formInfo } = this.state;
        const oldPayWindow = formInfo.payWindow;
        formInfo.payWindow = event.target.value;
        this.changed = true;
        this.setState({
            formInfo,
            showPayDayDropdown: event.target.value === Object.keys(paySchedule)[1],
            saveDisabled: false,
        });
        if (oldPayWindow === 'Weekly Payment' && formInfo.payWindow !== 'Weekly Payment') {
            return true;
        }
    };

    onPayWindowDayChange = (event: IEventString) => {
        const { formInfo } = this.state;
        formInfo.payDay = event.target.value;
        this.changed = true;
        this.setState({ formInfo, saveDisabled: false });
    };

    formIsValid(role: string) {
        const { titleValid, descriptionValid, formInfo, job } = this.state;
        this.setState({ formInfo });
        var request = true;
        if (this.request && formInfo?.form_times) {
            for (var i = 0; i < formInfo.form_times.length; i++) {
                const day = formInfo.form_times[i];
                request = request && day.isStart && day.isEnd && day.isDuration;
            }
            if (!formInfo.isRequestFuture && formInfo.end) {
                var duration = formInfo.end.diff(formInfo.start, 'days');
                formInfo.isDateRange = duration >= 13 || role === 'business_active';
                request =
                    (request && formInfo.isRequestEnd && formInfo.isRequestStart && formInfo.isDateRange) || false;
            }
        }
        // Check for editing a single appointment of a recurring request or a whole request recurring or onetime
        if (!this.request && job?.ongoing) {
            return request && formInfo.isStart && formInfo.isEnd && formInfo.isDuration;
        } else {
            return (
                request && formInfo.isDuration && formInfo.isStart && formInfo.isEnd && titleValid && descriptionValid
            );
        }
    }
    changeRecurringJobDays(newTimes: IFormTimeOfDay[]) {
        const { formInfo } = this.state;
        newTimes = newTimes.map((e) => {
            return { ...e, icon: dayIcons[e.day as keyof IDayIcons] };
        });

        this.setState({ formInfo: { ...formInfo, form_times: newTimes }, saveDisabled: false });
    }

    renderOngoingJobTimes() {
        // Updating times of a single day of an ongoing request
        const { formInfo, confirmation } = this.state;
        return (
            <Grid container direction="row" justify="space-between">
                <Grid container xs={1} justify="center" alignItems="center">
                    <Grid item>
                        <AccessAlarmsIcon color="primary" />
                    </Grid>
                </Grid>
                <Grid container xs={11} alignItems="center">
                    <Grid item style={{ marginLeft: 10 }}>
                        <Grid item xs={12}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardTimePicker
                                    autoOk
                                    disabled={confirmation}
                                    variant="inline"
                                    margin="normal"
                                    id="time-picker"
                                    label={'Start Time - ' + formInfo?.start?.format('ddd, MMM D')}
                                    value={formInfo.start}
                                    onChange={(start) => {
                                        this.onStartDateTimeChange(start, true);
                                    }}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change time',
                                    }}
                                    mask="__:__ _M"
                                    style={{ width: '100%', borderRadius: 10 }}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        {!formInfo.isStart && (
                            <Grid item xs={12}>
                                <ErrorText>Invalid start date and time</ErrorText>
                            </Grid>
                        )}
                    </Grid>
                    <Grid item style={{ marginLeft: 10, marginTop: 5 }}>
                        <Grid item xs={12}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardTimePicker
                                    disabled={confirmation}
                                    mask="__:__ _M"
                                    id="end"
                                    label={'End Time - ' + moment(formInfo.end).format('ddd, MMM D')}
                                    value={formInfo.end}
                                    onChange={(time) => this.onEndTimeChange(time)}
                                    variant="inline"
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        {!formInfo.isEnd && (
                            <Grid item xs={12}>
                                <ErrorText>Invalid end time</ErrorText>
                            </Grid>
                        )}
                    </Grid>
                    {!formInfo.isDuration && (
                        <Grid item xs={12} style={{ marginLeft: 10 }}>
                            <ErrorText>Duration must be at least 30 minutes</ErrorText>
                        </Grid>
                    )}
                </Grid>
            </Grid>
        );
    }

    renderOnetimeJobTimes() {
        const { formInfo, confirmation, job } = this.state;
        const today = new Date();
        return (
            <Grid container direction="row" justify="space-between" alignContent="center">
                <Grid container xs={1} justify="center">
                    <Grid item>
                        <AccessAlarmsIcon color="primary" />
                    </Grid>
                </Grid>
                <Grid container xs={11}>
                    <Grid item style={{ marginLeft: 10 }}>
                        <Grid item xs={12}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDateTimePicker
                                    autoOk
                                    variant="inline"
                                    disabled={confirmation}
                                    label={'Start Date & Time - ' + formInfo?.start?.format('ddd, MMM D')}
                                    value={formInfo.start}
                                    onChange={(date) => this.onStartDateTimeChange(date)}
                                    format="MM/DD/YYYY hh:mm A"
                                    minDate={(job?.pairings?.length || 0) > 0 ? formInfo.originalStart : today}
                                    style={{ width: '100%', borderRadius: 10 }}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        {!formInfo.isStart && (
                            <Grid item xs={12}>
                                <ErrorText>Invalid start date and time</ErrorText>
                            </Grid>
                        )}
                    </Grid>
                    <Grid item style={{ marginLeft: 10 }}>
                        <Grid item xs={12}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardTimePicker
                                    disabled={confirmation}
                                    mask="__:__ _M"
                                    id="end"
                                    label={'End Time - ' + moment(formInfo.end).format('ddd, MMM D')}
                                    value={formInfo.end}
                                    onChange={(time) => this.onEndTimeChange(time)}
                                    variant="inline"
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        {!formInfo.isEnd && (
                            <Grid item xs={12}>
                                <ErrorText>Invalid end time</ErrorText>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
                {!formInfo.isDuration && (
                    <Grid item xs={12} style={{ marginLeft: 10 }}>
                        <ErrorText>Duration must be at least 30 minutes</ErrorText>
                    </Grid>
                )}
            </Grid>
        );
    }

    renderPaymentSchedule() {
        const { confirmation, formInfo, showPayDayDropdown, job } = this.state;
        return (
            <EditPaySchedule
                confirmation={confirmation}
                payWindow={formInfo.payWindow}
                payDay={formInfo.payDay}
                showPayDayDropdown={showPayDayDropdown}
                onPayWindowChange={this.onPayWindowChange}
                onPayWindowDayChange={this.onPayWindowDayChange}
                ongoingRequest={job}
            />
        );
    }

    onPayRangeRateChange = (event: IEventNumber, newValue: number[]) => {
        this.setState({ payMin: newValue[0], payMax: newValue[1], saveDisabled: false });
    };

    onPaySingleRateChange = (event: IEventNumber, newValue: number[]) => {
        this.setState({ payMin: newValue[0], payMax: newValue[0], saveDisabled: false });
    };

    onCurrencyInputChange = (value: number) => {
        let validValue = false;
        if (value) {
            validValue = true;
        }
        this.setState({ payFixed: value, saveDisabled: !validValue });
    };

    getHasHiredProvider = () => {
        const { job } = this.state;
        const hasApprovedPairing = (element: IAppointment) => element.status === 'APPROVED_PAIRING';
        if (job?.appointments?.some(hasApprovedPairing)) {
            return true;
        }
        return false;
    };

    editRecurringAppointmentPay = (isOngoing: boolean, jobStatus: string, alreadyPaid: boolean) => {
        if (!isOngoing || jobStatus !== 'APPROVED_PAIRING' || alreadyPaid || !this.state.payMin || !this.state.payMax) {
            return null;
        }
        return (
            <EditPay
                requestType={'recurring'}
                hasHiredProvider={true}
                payMin={this.state.payMin}
                payMax={this.state.payMax}
                isPayFixed={false}
                onPaySingleRateChange={this.onPaySingleRateChange}
            />
        );
    };

    renderTitle() {
        const { formInfo } = this.state;
        return (
            <Grid container direction="row">
                <Grid container xs={1} justify="center" alignItems="center">
                    <Grid item>
                        <TitleIcon color="primary" />
                    </Grid>
                </Grid>
                <Grid container direction="row" alignItems="center" xs={11}>
                    <Input
                        label="Job Title"
                        fullWidth
                        style={{ marginLeft: 10 }}
                        value={formInfo.headline}
                        error={formInfo.headline ? formInfo.headline.length > 50 : false}
                        helperText={`${50 - (formInfo.headline?.length || 0)}/50 characters remaining`}
                        onChange={(e) => this.onTextChange(e.target.value, 'headline')}
                        multiline
                        InputLabelProps={{ shrink: true }}
                    />
                </Grid>
            </Grid>
        );
    }

    renderEditJob(role: string) {
        const { formInfo, confirmation, job, individualAppointments } = this.state;
        return (
            <div style={{ width: '100%' }}>
                {!job?.ongoing || this.request ? (
                    <Grid container direction="row" justify="space-between" style={{ marginTop: isMobile ? 25 : 15 }}>
                        <Grid container xs={1} justify="center" alignItems="center">
                            <Grid item>
                                <CreateIcon color="inherit" />
                            </Grid>
                        </Grid>
                        <Grid container xs={11} direction="column">
                            <JobTitleInput
                                title={{
                                    value: formInfo.headline || '',
                                    setValue: (title) => this.onTextChange(title, 'headline'),
                                    isValid: true,
                                    setValid: (valid) => this.setState({ titleValid: valid }),
                                }}
                            />
                            <JobDescriptionInput
                                description={{
                                    value: formInfo.comments || '',
                                    setValue: (description) => this.onTextChange(description, 'comments'),
                                    isValid: true,
                                    setValid: (valid) => this.setState({ descriptionValid: valid }),
                                }}
                            />
                        </Grid>
                    </Grid>
                ) : null}
                {this.request && job?.ongoing ? this.renderPaymentSchedule() : null}

                {this.request ? (
                    <EditRequestTimes
                        job={job}
                        confirmation={confirmation}
                        formInfo={formInfo}
                        onStartDateChange={(d: MaterialUiPickersDate) => this.onStartDateChange(d)}
                        onEndDateChange={(d: MaterialUiPickersDate) => this.onEndDateChange(d)}
                        onTimeChange={(day: string, type: string, time: MaterialUiPickersDate) =>
                            this.onTimeChange(day, type, time)
                        }
                        changeRecurringJobDays={(newTimes) => this.changeRecurringJobDays(newTimes)}
                    />
                ) : job?.ongoing ? (
                    this.renderOngoingJobTimes()
                ) : (
                    this.renderOnetimeJobTimes()
                )}
                {!job?.ongoing && (
                    <>
                        <Grid
                            container
                            direction="row"
                            justify="space-between"
                            style={{ marginTop: isMobile ? 25 : 15 }}
                        >
                            <Grid container xs={1} justify="center" alignItems="center">
                                <Grid item>
                                    <LocalHotelIcon color="primary" />
                                </Grid>
                            </Grid>
                            {formInfo.isOvernight !== undefined ? (
                                <Grid container justify="flex-start" alignItems="center" xs={11}>
                                    <Grid item xs={12}>
                                        <Label
                                            label={
                                                <Text variant="body2" textStyle={{ marginLeft: 10 }}>
                                                    The job is overnight/ends past midnight.
                                                </Text>
                                            }
                                            position="start"
                                            control={
                                                <Checkbox
                                                    disabled={confirmation}
                                                    color="primary"
                                                    checked={formInfo.isOvernight}
                                                    value="primary"
                                                    onChange={() => this.onOvernightChange()}
                                                />
                                            }
                                        />
                                    </Grid>
                                </Grid>
                            ) : null}
                        </Grid>
                        <EditPay
                            requestType={'onetime'}
                            isQuickFill={job?.quick_fill}
                            hasHiredProvider={job?.status === 'APPROVED_PAIRING'}
                            payMin={this.state.payMin}
                            payMax={this.state.payMax}
                            payFixed={this.state.payFixed}
                            isPayFixed={job?.ongoing_request?.pay_is_fixed || false}
                            onPaySingleRateChange={this.onPaySingleRateChange}
                            onPayRangeRateChange={this.onPayRangeRateChange}
                            onCurrencyInputChange={this.onCurrencyInputChange}
                        />
                    </>
                )}
                {this.request ? <EditIndividualDays job={job} individualAppointments={individualAppointments} /> : null}
                <Grid container xs={12} justify="flex-end" style={{ marginTop: isMobile || job?.ongoing ? 20 : 15 }}>
                    <Grid item xs={5}>
                        {!confirmation ? (
                            <Grid container direction="row" xs={12} spacing={1}>
                                {job ? (
                                    <Grid item xs={6}>
                                        <JobCardDialogs
                                            job={this.state.jobForCancelling}
                                            refresh={() => this.props.history.push('/jobs')}
                                            history={this.props.history}
                                            request={this.request}
                                        />
                                    </Grid>
                                ) : null}

                                <Grid item xs={6}>
                                    <PrimaryButton
                                        buttonStyle={{ maxWidth: 200 }}
                                        onClick={() => this.submitRequest(role)}
                                        disabled={this.state.saveDisabled || this.state.loading}
                                        loading={this.state.loading}
                                        id="edit-job-details-save"
                                    >
                                        Save
                                    </PrimaryButton>
                                </Grid>
                            </Grid>
                        ) : (
                            <Grid container direction="column">
                                <Text variant="body2" bold={true} textStyle={{ marginBottom: 7 }}>
                                    Changes saved successfully!
                                </Text>
                                <PrimaryButton
                                    buttonStyle={{ maxWidth: 200 }}
                                    onClick={() => this.props.history.push('/jobs')}
                                    id="edit-job-details-return-to-jobs"
                                >
                                    Return to Jobs
                                </PrimaryButton>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </div>
        );
    }

    render() {
        return (
            <UserContext.Consumer>
                {(context) => {
                    const role = context.role;
                    const { job } = this.state;
                    let quickDate = null;
                    if (job) {
                        const startDate = moment(job.start_date);
                        quickDate = startDate.format('M/D');
                    }
                    return (
                        <Grid lg={12} container justify="center" style={{ padding: 10, marginTop: 70 }}>
                            <Grid lg={8} container justify="center" style={{ padding: 10 }}>
                                <Card>
                                    <Grid container lg={12} justify="center" style={{ padding: 30 }}>
                                        <Grid container direction="row">
                                            <Grid item xs={11}>
                                                <Text variant="display">
                                                    Edit {this.request ? 'Recurring Request' : 'Job'} Details
                                                </Text>
                                                {job && job.ongoing && (
                                                    <Text variant="h1" textStyle={{ marginBottom: 20 }}>
                                                        Edit {this.request ? 'the entire job' : quickDate}
                                                    </Text>
                                                )}
                                            </Grid>
                                        </Grid>
                                        {job !== null && <Grid container>{this.renderEditJob(role ?? '')}</Grid>}
                                    </Grid>
                                </Card>
                            </Grid>
                        </Grid>
                    );
                }}
            </UserContext.Consumer>
        );
    }
}

export default withRouter(Edit);
