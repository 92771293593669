import { makeStyles } from '@material-ui/core';
import { Colors } from 'library';

export const useStyles = makeStyles(() => ({
    link: {
        color: Colors.darkNavy,
        fontSize: 14,
        textDecoration: 'underline',
    },
    paymentContainer: {
        backgroundColor: Colors.lightBlue,
        borderRadius: 10,
        padding: 10,
        margin: '10px 0',
        height: 80,
    },
}));

export const styles = {
    avatar: {
        width: 50,
        height: 50,
    },
    defaultAvatar: {
        width: 50,
        height: 50,
        borderRadius: '50%',
    },
    providerRow: {
        paddingBottom: 10,
    },
    providerInfo: {
        display: 'flex',
    },
};
