import React from 'react';
import Slider from '@mui/material/Slider';
import { Colors } from '../../theme';
import { SxProps } from '@mui/material';

export default function TimeSlider({
    min,
    max,
    values,
    onValuesChange,
    onValuesChangeFinish,
    style,
}: {
    min: number;
    max: number;
    values: number | number[];
    onValuesChange: (newValues: number | number[]) => void;
    onValuesChangeFinish?: (newValues: number | number[]) => void;
    style?: SxProps;
}) {
    const getLabelText = (value: number | string) => {
        let totalMinutes = typeof value === 'string' ? parseInt(value) : value;
        let hours = Math.floor(totalMinutes / 60);
        let minutes = totalMinutes % 60;
        let pm = hours >= 12 && hours < 24;
        return `${hours === 0 ? '12' : hours > 12 ? hours - 12 : hours}:${String(minutes).padStart(2, '0')}${
            pm ? 'pm' : 'am'
        }`;
    };

    return (
        <Slider
            min={min || 0}
            max={max || 1440}
            step={15}
            value={values}
            onChange={(event, value) => onValuesChange(value)}
            onChangeCommitted={(event, value) => {
                if (onValuesChangeFinish) onValuesChangeFinish(value);
            }}
            sx={{ ...sliderStyle, ...style }}
            valueLabelDisplay="on"
            valueLabelFormat={getLabelText}
        />
    );
}

const sliderStyle = {
    color: Colors.turquoise,
    height: 8,
    '& .MuiSlider-track': {
        border: 'none',
    },
    '& .MuiSlider-thumb': {
        height: 24,
        width: 24,
        backgroundColor: '#fff',
        border: '2px solid currentColor',
        '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
            boxShadow: 'inherit',
            color: Colors.turquoise,
        },
        '&:before': {
            display: 'none',
        },
    },
    '& .MuiSlider-valueLabel': {
        lineHeight: 1.2,
        fontSize: 12,
        background: 'unset',
        padding: 0,
        width: 32,
        height: 32,
        color: Colors.darkNavy,
        '&.MuiSlider-valueLabelOpen': {
            transform: 'translateY(100%)',
        },
    },
};
