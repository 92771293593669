import React from 'react';
import { Grid } from '@material-ui/core';
import { PrimaryButton, Text } from 'library';
import { useUserContext } from 'UserContext';
import { BookProviderSuccessProps } from './models';

export default function BookProviderSuccess({ providerName, onClose }: BookProviderSuccessProps) {
    return (
        <>
            <Grid container direction="column" item xs={12} alignItems="flex-start">
                <Grid item>
                    <Text variant={'h1'}>Your job has been sent to {providerName}!</Text>
                </Grid>
                <Grid item>
                    <Text variant={'body1'}>You'll be notified when they accept.</Text>
                </Grid>
            </Grid>
            <Grid container direction="row-reverse" style={{ paddingTop: 20 }}>
                <Grid item>
                    <PrimaryButton onClick={onClose}>Close</PrimaryButton>
                </Grid>
            </Grid>
        </>
    );
}
