import {Container, styled} from "@mui/material";
import React from "react";

const MainContainer = styled(Container)({
  paddingTop: 30,
  paddingBottom: 30,
});

const TextContainer = ({ children }: { children: React.ReactNode })  => {
  const StyledContainer = styled(Container)({
    textAlign: 'center',
    paddingTop: 20,
    paddingBottom: 20,
  });

  return (
    <StyledContainer maxWidth="sm">
      {children}
    </StyledContainer>
  );
};

const ButtonContainer = styled(Container)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: 15,
  marginTop: 30,
});

const ImgContainer = styled(Container)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: 15,
  marginBottom: 15,
});

export { MainContainer, TextContainer, ButtonContainer, ImgContainer };
