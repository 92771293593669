import React, { Component } from 'react';
import styled from 'styled-components';
import theme from '../../../theme';
import Grid from '@material-ui/core/Grid';
import DisplayEditButton from './../partials/DisplayEditButton';
import { BrowserView, MobileView } from 'react-device-detect';

const InfoSection = styled.div`
    border-radius: 6px;
    background-color: #f7f7f7;
    margin: 10px;
    padding: 10px;
    color: ${theme.palette.primary.main};
`;

const Category = styled.div`
    margin: 0px;
`;

const CategoryTitle = styled.p`
    color: ${theme.palette.primary.main};
    font-size: 12;
    font-weight: bold;
    margin-left: 20px;
    margin-top: 15px;
`;

const CategoryInfo = styled.div`
    background-color: #ffffff;
    border-radius: 6px;
    font-size: 12px;
    font-weight: regular;
    margin-top: 15px;
    margin-bottom: 15px;
    margin-left: 18px;
    margin-right: 18px;
    padding: 15;
`;

const LeftSide = styled.p`
    float: left;
    margin-bottom: 20px;
    margin-top: 20px;
    margin-left: 20px;
    text-align: left;
    font-size: 14px;
    font-weight: bold;
`;

const RightSide = styled.p`
    margin-left: 20px;
    float: right;
    margin-bottom: 20px;
    margin-top: 20px;
    margin-right: 20px;
    font-size: 14px;
`;

const InfoSeparator = styled.hr`
    width: 96%;
    size: 4;
    margin-top: 4px;
    margin-bottom: 4px;
`;

class AccountDisplay extends Component {
    render() {
        const { user, address, edit_account, primaryPhoneNumber, secondaryPhoneNumber } = this.props;

        return (
            <div>
                <DisplayEditButton
                    onClickHandler={() => {
                        edit_account();
                    }}
                />
                <Grid container justify="center" alignItems="center">
                    <Grid item sm={6} xs={12} container>
                        <InfoSection style={{ width: '95%', marginBottom: 100 }} id="account-info">
                            <Category>
                                <Grid item justify="center">
                                    <CategoryTitle>Contact</CategoryTitle>
                                    <CategoryInfo>
                                        <Grid item container justify="space-between">
                                            <LeftSide>First Name</LeftSide>
                                            <RightSide>{user.first_name}</RightSide>
                                        </Grid>
                                        <InfoSeparator />
                                        <Grid item container justify="space-between">
                                            <LeftSide>Last Name</LeftSide>
                                            <RightSide>{user.last_name}</RightSide>
                                            <InfoSeparator />
                                        </Grid>
                                        <Grid item container justify="space-between">
                                            <LeftSide>Email</LeftSide>
                                            <RightSide>{user.email}</RightSide>
                                            <InfoSeparator />
                                        </Grid>
                                        <BrowserView>
                                            <Grid item container justify="space-between">
                                                <LeftSide>Primary Phone Number</LeftSide>
                                                <RightSide>{primaryPhoneNumber}</RightSide>
                                            </Grid>
                                            {user.secondary_phone_number !== null ? (
                                                <Grid item container justify="space-between">
                                                    <InfoSeparator />
                                                    <LeftSide>Secondary Phone Number</LeftSide>
                                                    <RightSide>{secondaryPhoneNumber}</RightSide>
                                                </Grid>
                                            ) : (
                                                <div></div>
                                            )}
                                        </BrowserView>
                                        <MobileView>
                                            <Grid item container justify="space-between">
                                                <LeftSide>Phone Number</LeftSide>
                                                <RightSide>{primaryPhoneNumber}</RightSide>
                                            </Grid>
                                            {user.secondary_phone_number !== null ? (
                                                <Grid item container justify="space-between">
                                                    <InfoSeparator />
                                                    <LeftSide>Phone Number</LeftSide>
                                                    <RightSide>{secondaryPhoneNumber}</RightSide>
                                                </Grid>
                                            ) : (
                                                <div></div>
                                            )}
                                        </MobileView>
                                    </CategoryInfo>
                                    <InfoSeparator />
                                </Grid>
                                <Grid item justify="center">
                                    <CategoryTitle style={{ marginTop: 20 }}>Address</CategoryTitle>
                                    <CategoryInfo>
                                        <Grid item container justify="space-between">
                                            <LeftSide>Street Address</LeftSide>
                                            <RightSide>{address?.street}</RightSide>
                                            <InfoSeparator />
                                        </Grid>
                                        <Grid item container justify="space-between">
                                            <LeftSide>City</LeftSide>
                                            <RightSide>{address?.city}</RightSide>
                                            <InfoSeparator />
                                        </Grid>
                                        <Grid item container justify="space-between">
                                            <LeftSide>State</LeftSide>
                                            <RightSide>{address?.state}</RightSide>
                                            <InfoSeparator />
                                        </Grid>
                                        <Grid item container justify="space-between">
                                            <LeftSide>Zip Code</LeftSide>
                                            <RightSide>{address?.zip_code}</RightSide>
                                        </Grid>
                                    </CategoryInfo>
                                </Grid>
                            </Category>
                        </InfoSection>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default AccountDisplay;
