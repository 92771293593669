import React, { MouseEvent } from 'react';
import { Text, TextButton, CloseButton } from 'library';
import { IInfoModalDoc } from 'models';
import moment from 'moment';
import { downloadFile } from 'parent-portal/provider-profile/tabs/documents/DocumentShared';

interface Props {
    doc: IInfoModalDoc;
    setActive: (doc: IInfoModalDoc) => void;
    setVerifyModalOpen: (open: boolean) => void;
    setDeleteModalOpen: (open: boolean) => void;
    backgroundColor: string;
}

const DocumentLineItem = ({ doc, setVerifyModalOpen, setActive, setDeleteModalOpen, backgroundColor }: Props) => {
    const expiresOn = doc.expiration_date;
    const hasExpired = expiresOn && moment().isAfter(expiresOn);

    const formatDate = (date: moment.MomentInput) => moment(date).format('MMM DD, YYYY');

    const RelevantDates = () => {
        const Expiration = () => {
            if (!expiresOn) {
                return <></>;
            }

            if (hasExpired) {
                return (
                    <Text variant="caption" bold color="red">
                        Expired on: {formatDate(expiresOn)}
                    </Text>
                );
            } else {
                return (
                    <Text variant="caption" color="green">
                        Expires on: {formatDate(expiresOn)}
                    </Text>
                );
            }
        };

        return (
            <div style={{ display: 'flex', flexDirection: 'row', gap: 4 }}>
                {doc.added_at && <Text variant="caption">Added at: {formatDate(doc.added_at)}</Text>}
                {doc.verified_at && <Text variant="caption"> Verified at: {formatDate(doc.verified_at)}</Text>}
                {doc.ignored_at && <Text variant="caption"> Ignored at: {formatDate(doc.ignored_at)}</Text>}
                <Expiration />
            </div>
        );
    };

    const DeleteButton = () => {
        const canDelete = doc.text !== 'Form 718b';
        return (
            <div>
                {canDelete && (
                    <CloseButton
                        onClose={() => {
                            setDeleteModalOpen(true);
                            setActive(doc);
                        }}
                    />
                )}
            </div>
        );
    };

    const VerifyButton = () => {
        const canVerify = doc.text !== 'FBI/BCI Background Check' && doc.text !== 'Form 718b' && !doc.verified_at;
        return (
            <div>
                {canVerify && (
                    <TextButton
                        onClick={() => {
                            setVerifyModalOpen(true);
                            setActive(doc);
                        }}
                    >
                        Verify
                    </TextButton>
                )}
            </div>
        );
    };

    const FileLink = () => {
        const text = doc.text === 'Florida Form 5131' ? `${doc.text} - Employment History` : doc.text;
        const color = hasExpired ? 'red' : '';
        const download = (e: MouseEvent<HTMLAnchorElement>) => {
            downloadFile(doc.url);
            e.preventDefault();
        };
        if (doc.type === 'download') {
            return (
                <a href={doc.url} onClick={download} style={{ color }}>
                    {text}
                </a>
            );
        }

        return (
            <a target="_blank" rel="noreferrer" href={doc.url} style={{ color }}>
                {text}
            </a>
        );
    };

    return (
        <li key={doc.id}>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    width: '100%',
                    justifyContent: 'space-between',
                    backgroundColor: backgroundColor,
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: 8,
                        alignItems: 'center',
                    }}
                >
                    <FileLink />
                    <RelevantDates />
                    {doc.covered_compliance_requirements && (
                        <Text variant="caption">{doc.covered_compliance_requirements.join(', ')}</Text>
                    )}
                </div>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: 8,
                        alignItems: 'center',
                    }}
                >
                    <VerifyButton />
                    <DeleteButton />
                </div>
            </div>
        </li>
    );
};

export default DocumentLineItem;
