import React, { useState, useMemo, KeyboardEvent } from 'react';
import { Edit, Save, HourglassTop } from '@mui/icons-material';
import { BugReport, BugReportRes } from 'models';
import { Colors, IconButton, OutlinedTextInput, Text } from 'library';
import { client } from 'shared';
import { Divider } from '@mui/material';

export default function BugReportView({
    bugReport,
    refresh,
    hideEdit,
    handleError,
}: {
    bugReport: BugReport;
    refresh?: () => void;
    hideEdit?: boolean;
    handleError: (res?: BugReportRes) => void;
}) {
    const [editing, setEditing] = useState<boolean>(false);
    const [newBugDescription, setNewBugDescription] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);

    const toggleEditing = () => {
        setNewBugDescription(editing ? '' : bugReport.bug_description);
        setEditing(!editing);
    };

    const update = () => {
        setLoading(true);
        client(`api/bug-report/${bugReport.id}/`, {
            method: 'PATCH',
            body: { bug_description: newBugDescription, author_id: bugReport.author.id },
        })
            .then((res: BugReportRes) => {
                refresh && refresh();
                if ((res.db_res && (res.db_res < 200 || res.db_res > 299)) || res.monday_res_update !== 200) {
                    handleError(res);
                }
            })
            .catch(handleError)
            .finally(() => {
                setEditing(false);
                setLoading(false);
            });
    };

    const map = useMemo(() => {
        return {
            Enter: false,
            Meta: false,
        };
    }, []);

    function onKeyEvent(event: KeyboardEvent) {
        if (event.key === 'Enter' || event.key === 'Meta') {
            map[event.key] = event.type === 'keydown';
        }
        if (map['Enter'] && map['Meta'] && newBugDescription && newBugDescription.trim().length > 0) {
            update();
            map['Enter'] = false;
            map['Meta'] = false;
        }
    }

    return (
        <div key={bugReport.id} style={styles.bugReportViewWrap}>
            <Text variant="body2" bold>
                {bugReport.added_at}{' '}
                <span style={styles.bugReportAuthorNameWrap}>
                    ({bugReport.author.first_name} {bugReport.author.last_name}){' '}
                </span>
            </Text>
            <div style={styles.bugReportContentWrap as React.CSSProperties}>
                {editing ? (
                    <OutlinedTextInput
                        style={styles.inputWrap}
                        multiline
                        fullWidth
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setNewBugDescription(e.target.value)}
                        value={newBugDescription}
                        InputProps={{
                            endAdornment: (
                                <IconButton
                                    icon={loading ? HourglassTop : Save}
                                    onClick={!loading ? update : () => null}
                                />
                            ),
                        }}
                        onKeyDown={(event: KeyboardEvent) => onKeyEvent(event)}
                        onKeyUp={(event: KeyboardEvent) => onKeyEvent(event)}
                    />
                ) : (
                    <Text variant="body2">{bugReport.bug_description}</Text>
                )}
                {!hideEdit ? (
                    <div style={styles.editIconWrap as React.CSSProperties}>
                        <IconButton icon={Edit} onClick={toggleEditing} />
                    </div>
                ) : null}
            </div>
            <Divider />
        </div>
    );
}

const styles = {
    bugReportViewWrap: { padding: 10 },
    bugReportAuthorNameWrap: { color: Colors.turquoise },
    bugReportContentWrap: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 4,
    },
    inputWrap: { padding: 10 },
    editIconWrap: { display: 'flex', flexDirection: 'row', alignItems: 'center' },
};
