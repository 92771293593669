/* eslint react-hooks/rules-of-hooks: "off" */

import { useEffect, useState } from 'react';
import { FieldStates, FormFieldProps } from './FormTypes';

export default function useForm<T extends object>(fields: T) {
    const fieldStates = toFormFields(fields);

    function toValues(): T {
        const fieldNames = Object.keys(fieldStates) as (keyof typeof fieldStates)[];
        const valueObjects = fieldNames.map((name) => ({ [name]: fieldStates[name].value }));
        return Object.assign({}, ...valueObjects);
    }

    function reset(fields: T) {
        Object.keys(fields).forEach((fieldName) => {
            fieldStates[fieldName as keyof typeof fieldStates].setValue(fields[fieldName as keyof T]);
            fieldStates[fieldName as keyof typeof fieldStates].setValid(false);
        });
    }

    return { fieldStates, toValues, reset };
}

function toFormFields<T extends object>(fields: T): FieldStates<T> {
    const form: { [key: string]: any } = {};

    Object.keys(fields).forEach((fieldName) => {
        form[fieldName] = createFormField(fields[fieldName as keyof T]);
    });

    return form as FieldStates<T>;
}

function createFormField<Value>(initialValue: Value) {
    const [value, setValue] = useState(initialValue);
    const [isValid, setValid] = useState<boolean>();
    return {
        value,
        setValue,
        isValid,
        setValid,
    } as FormFieldProps<Value>;
}

export function useAlwaysValid(formField: FormFieldProps<any>) {
    useEffect(() => formField.setValid(true), []);
}

export function useFieldValidation(
    formField: FormFieldProps<any>,
    additionalErrorState: boolean = true,
    dependency: Array<any> = [formField.value],
) {
    useEffect(() => formField.setValid(formField.value !== null && additionalErrorState), dependency);
}
