import React, { useState } from 'react';
import { BasicDialog, PrimaryButton, RadioGroup, Text, TextButton, UnderlinedTextInput } from 'library';
import { sendCode } from 'api/VerificationCodeApi';
import { Grid, styled } from '@mui/material';
import { formatPhoneNumber } from 'shared';
import { useUserContext } from 'UserContext';
import ErrorText from 'parent-portal/forms/components/ErrorText';

interface IEditPhoneNumberProps {
    isModalOpen: boolean;
    onSubmitCode: (additionalSubmitData: object) => Promise<object>;
    onSubmitSuccess: () => void;
    onClose: () => void;
}

export default function EnterVerificationCode({
    isModalOpen,
    onSubmitCode,
    onSubmitSuccess,
    onClose,
}: IEditPhoneNumberProps) {
    const { user } = useUserContext();
    const [hasSentCode, setHasSentCode] = useState(false);
    const [channel, setChannel] = useState('sms');
    const [code, setCode] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    function sendVerificationCode() {
        sendCode(channel)
            .then(() => {
                setErrorMessage('');
            })
            .catch(() => {
                setErrorMessage('There was an error sending the verification code');
            })
            .finally(() => {
                setHasSentCode(true);
            });
    }

    function onSubmitClick() {
        setErrorMessage('');
        onSubmitCode({ code, channel })
            .then(() => {
                onSubmitSuccess();
                onCloseModal();
            })
            .catch((error) => {
                if (error.email) {
                    setErrorMessage(error.email.join(' '));
                } else {
                    setErrorMessage(error.message || 'There was an error updating your information');
                }
            });
    }

    function onCloseModal() {
        setChannel('sms');
        setCode('');
        setErrorMessage('');
        setHasSentCode(false);
        onClose();
    }

    function getContentBeforeSendingCode() {
        return (
            <PaddedContainer>
                <Text>
                    To enable editing of your information we will send you a verification code. How would you like to
                    receive it?
                </Text>
                <RadioGroup
                    value={channel}
                    setValue={(val) => setChannel(val)}
                    values={{
                        sms: `Text ${formatPhoneNumber(user?.phone_number)}`,
                        email: `Email ${user?.email}`,
                        call: `Call ${formatPhoneNumber(user?.phone_number)}`,
                    }}
                    horizontal
                />
                <PrimaryButton buttonStyle={{ marginTop: 5 }} onClick={sendVerificationCode}>
                    Next
                </PrimaryButton>
                <ErrorText>{errorMessage}</ErrorText>
            </PaddedContainer>
        );
    }

    function getSentCodeContent() {
        return (
            <PaddedContainer>
                <Text>We sent your verification code. Enter it to save your information.</Text>
                <UnderlinedTextInput label="Code*" value={code} onChange={(val: string) => setCode(val)} boldLabel />
                <PrimaryButton disabled={!code} onClick={onSubmitClick} buttonStyle={{ marginTop: 15 }}>
                    Submit
                </PrimaryButton>
                {hasSentCode && errorMessage && <TextButton onClick={sendVerificationCode}>Resend Code</TextButton>}
                <ErrorText>{errorMessage}</ErrorText>
            </PaddedContainer>
        );
    }

    return (
        <span>
            <BasicDialog isOpen={isModalOpen} onClose={onCloseModal} closeButton={true}>
                <Grid>
                    <Text bold>Edit Phone</Text>
                    {!hasSentCode ? getContentBeforeSendingCode() : getSentCodeContent()}
                </Grid>
            </BasicDialog>
        </span>
    );
}

const PaddedContainer = styled(Grid)({
    padding: 5,
});
