import React from 'react';
import { Colors, OptionButton } from 'library';
import { OnboardingStep, useOnboardingContext } from '../../OnboardingContext';
import { useUserContext } from 'UserContext';
import { AllInclusive } from '@mui/icons-material';

export default function LongTerm() {
    const { goToStep } = useOnboardingContext();
    const { user } = useUserContext();
    const hours = user?.businesses ? user.businesses[0].pricing_plan.trial_run_hours : 120;

    return (
        <OptionButton
            icon={<AllInclusive htmlColor={Colors.turquoise} fontSize="large" />}
            title="Trial Run"
            highlight="Temp-to-permanent hires"
            subtitle={`You would like to hire employees on a long-term basis for full-time or part-time work. Workers will work through Tandem for the first ${hours} hours.`}
            action={() => goToStep(OnboardingStep.Role, 'Do you know the dates')}
            id="get-started-long-term-card"
        />
    );
}
