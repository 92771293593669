import { useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import { client } from 'shared';
import useQueryParams from 'shared/QueryParamsHook';

export default function ProviderRedirect() {
    const { id } = useParams<{ id: string }>();
    const query = useQueryParams();
    const history = useHistory();

    const redirect = async () => {
        if (query.get('user_id')) {
            history.push(`/provider/${query.get('user_id')}`);
        } else if (id) {
            let babysitter = await client(`api/babysitters/${id}/`);
            history.push(`/provider/${babysitter.user.id}/`);
        } else {
            history.push('/home');
        }
    };

    useEffect(() => {
        redirect();
        return;
    }, []);

    return null;
}
