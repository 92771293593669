import { ListItem, withStyles } from '@material-ui/core';
import theme from '../../../../theme';
import { Colors } from '../../../../constants';

const StyledListItem = withStyles({
    root: {
        '&:hover': {
            outline: 'none',
        },
        '&:focus': {
            outline: 'none',
        },
        backgroundColor: Colors.white,
        marginBottom: 10,
        [theme.breakpoints.only('xs')]: {
            paddingBottom: 12,
        },
    },
})(ListItem) as any;

export default StyledListItem;
