import React, { useState } from 'react';
import { Grid, useMediaQuery } from '@material-ui/core';
import { track } from 'shared';
import { Colors, Link, PrimaryButton } from 'library';
import { useUserContext } from 'UserContext';
import { Text } from 'library';
import HelpOutlinedIcon from '@mui/icons-material/HelpOutlined';
import CloseIcon from '@material-ui/icons/Close';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import { useHistory } from 'react-router';
import ConnectPaymentModal from 'parent-portal/payments/Components/ConnectPaymentModal';
import theme from 'theme';

interface ContentType {
    title: string;
    body: string;
}
const PaymentBanner = ({ isOneTime }: { isOneTime: boolean }) => {
    const history = useHistory();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const { user } = useUserContext();
    const [closed, setClosed] = useState<boolean>(false);
    const [carouselPage, setCarouselPage] = useState<number>(0);
    const [openPaySetupModal, setOpenPaySetupModal] = useState(false);
    const paymentSetup = user?.payment_information;
    const isPaymentSetup = user?.customer_id || (!!paymentSetup && paymentSetup?.length > 0);
    const titleText = `Once you hire an applicant, you'll pay them using TandemPay.`;
    const bodyText = isOneTime
        ? 'Found a sitter? Pay them the next time you have a need through their profile.'
        : 'Doing trial runs? Pay your applicant through their profile.';

    const content: ContentType[] = [
        {
            title: `It's time to review and interview applicants.`,
            body: `Don't forget, you haven't set up TandemPay. You'll do this when you hire your provider.`,
        },
        {
            title: `Tandem Tip #1`,
            body: `Pay your applicant for a trial run through their profile.`,
        },
        {
            title: `Tandem Tip #2`,
            body: `Pay your applicants whenever for whatever. Now or later.`,
        },
        {
            title: `Tandem Tip #3`,
            body: `Reimburse your providers through TandemPay. Easiest way to pay for a pizza ever!`,
        },
        {
            title: `Tandem Tip #4`,
            body: `Get the perks of the TandemGuarantee when you hire and pay a provider on Tandem.`,
        },
        {
            title: `Tandem Tip #5`,
            body: `What to do if a provider asks for payment outside TandemPay? Pay is required to use Tandem, tell your provider you'll pay through their profile.`,
        },
    ];

    const navigateAndTrack = () => {
        history.push('/tandempay');
    };
    const openModalAndTrack = () => {
        setOpenPaySetupModal(true);
    };

    return (
        <>
            {!closed ? (
                <div
                    style={{
                        backgroundColor: Colors.lightGreen,
                        marginBottom: 20,
                        padding: 15,
                        paddingLeft: 40,
                        paddingTop: isMobile ? 10 : 20,
                    }}
                >
                    {isPaymentSetup ? (
                        <>
                            <CloseIcon
                                htmlColor={Colors.darkNavy}
                                style={{
                                    position: 'absolute',
                                    top: isMobile ? 64 : 80,
                                    right: 6,
                                    width: 26,
                                    height: 26,
                                    cursor: 'pointer',
                                }}
                                onClick={() => setClosed(true)}
                            />
                            <Text variant="h2" bold textStyle={{ paddingRight: 30 }}>
                                {titleText} <Link onClick={navigateAndTrack}>Tell me more</Link>
                            </Text>
                            <Grid container direction="row" alignItems="center">
                                <Text variant="body2" textStyle={{ marginTop: 5 }}>
                                    <HelpOutlinedIcon fontSize="small" style={{ marginTop: -3 }} /> {bodyText}
                                </Text>
                            </Grid>
                        </>
                    ) : (
                        <>
                            <Grid container direction="column">
                                <Text variant="h2" bold>
                                    {content[carouselPage].title}
                                </Text>
                                {content[carouselPage].body.indexOf('TandemGuarantee') === -1 ? (
                                    <Text variant="body1" textStyle={{ marginTop: 10 }}>
                                        {content[carouselPage].body}{' '}
                                        <Link onClick={openModalAndTrack}>Set up TandemPay</Link>
                                    </Text>
                                ) : (
                                    <Text variant="body1" textStyle={{ marginTop: 10 }}>
                                        Get the perks of the <Link onClick={navigateAndTrack}>TandemGuarantee</Link>{' '}
                                        when you hire and pay a provider on Tandem.{' '}
                                        <Link onClick={openModalAndTrack}>Set up TandemPay</Link>
                                    </Text>
                                )}
                            </Grid>
                            <ArrowCircleRightOutlinedIcon
                                htmlColor={Colors.darkNavy}
                                onClick={() => setCarouselPage(carouselPage !== 4 ? carouselPage + 1 : 0)}
                                style={{
                                    position: 'absolute',
                                    right: 6,
                                    top: isMobile ? 44 : 80,
                                    width: isMobile ? 40 : 50,
                                    height: isMobile ? 40 : 50,
                                }}
                            />
                        </>
                    )}
                </div>
            ) : null}
            <ConnectPaymentModal
                isOpen={openPaySetupModal}
                onClose={() => setOpenPaySetupModal(false)}
                fromRoute={'ApplicantPage'}
            />
        </>
    );
};

export default PaymentBanner;
