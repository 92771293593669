import React from 'react';
import { Chip, Colors, DropDown, IconButton, Text, TextButton } from 'library';
import { CalendarColorType, EventType, ViewType } from './models';
import { Grid } from '@material-ui/core';
import { useMediaQuery } from '@mui/material';
import theme from 'theme';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import DateFnsUtils from '@date-io/date-fns';
import CustomWeekDayRender from './CustomWeekDayRender';
import { CalendarColors } from './CalendarHelpers';

const CalendarColorDisplay: { [key: string]: string } = {
    ongoing: 'Weekly',
    onetime: 'One Time',
    allday: 'All Day',
};

interface CalHeaderProps {
    selectedView: ViewType;
    setSelectedView: (s: ViewType) => void;
    appointments: EventType[];
    calendarInst: any; // calendar ref isn't exported saving the effort
    setCalendarDate: (d: Date) => void;
    calendarDate: Date;
    width?: number;
    overviewBack: () => void;
    includeDatePicker?: boolean;
}

export default function CalendarHeader({
    selectedView,
    setSelectedView,
    appointments,
    calendarInst,
    setCalendarDate,
    calendarDate,
    width,
    overviewBack,
    includeDatePicker = true,
}: CalHeaderProps) {
    const smDown = useMediaQuery(theme.breakpoints.down('sm'));

    function next() {
        calendarInst()?.next();
        setCalendarDate(calendarInst()?.getDate()?.toDate() ?? new Date());
    }

    function setDate(date: any) {
        setCalendarDate(date);
        calendarInst()?.setDate(date);
    }

    function back() {
        calendarInst()?.prev();
        setCalendarDate(calendarInst()?.getDate()?.toDate() ?? new Date());
    }

    return (
        <Grid>
            {smDown ? (
                <Grid xs={12} justify="flex-start" direction="row">
                    <Text onClick={overviewBack} textStyle={{ width: 'unset', cursor: 'pointer' }}>
                        <ArrowBackIosIcon />
                        <Text inline variant="body1" textStyle={{ textDecorationLine: 'underline' }}>
                            Back
                        </Text>
                    </Text>
                </Grid>
            ) : null}
            <Grid
                item
                container
                direction="row"
                justify="space-between"
                style={{
                    marginBottom: 10,
                    width: width ? width - 30 : '85%',
                    alignSelf: 'center',
                    backgroundColor: Colors.white,
                    borderRadius: 18,
                    paddingTop: 20,
                    paddingBottom: 20,
                }}
            >
                <Grid xs={12} md={4} container item>
                    {!smDown ? (
                        <DropDown
                            fields={['month', 'week', 'day']}
                            selected={selectedView}
                            onChange={(event, child) => setSelectedView(event.target.value as ViewType)}
                            style={{ maxWidth: 100, margin: 0, marginLeft: 20 }}
                        />
                    ) : null}
                </Grid>
                {includeDatePicker ? (
                    <Grid xs={12} md={4} direction="row" item container justify="center">
                        <IconButton icon={ArrowBackIosIcon} onClick={back} />
                        <>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <DatePicker
                                    InputProps={{
                                        inputProps: { style: { textAlign: 'center', color: Colors.darkNavy } },
                                    }}
                                    disableToolbar
                                    variant="inline"
                                    format="MM/dd/yyyy"
                                    value={calendarDate}
                                    onChange={setDate}
                                    renderDay={(day, selectedDate, dayInCurrentMonth, dayComponent) => (
                                        <CustomWeekDayRender
                                            date={day}
                                            selectedDate={selectedDate}
                                            appointments={appointments}
                                            dayInCurrentMonth={dayInCurrentMonth}
                                        />
                                    )}
                                />
                            </MuiPickersUtilsProvider>
                            <IconButton icon={ArrowForwardIosIcon} onClick={next} />
                        </>
                    </Grid>
                ) : (
                    <></>
                )}
                <Grid
                    xs={12}
                    md={4}
                    direction="row"
                    item
                    container
                    justify="center"
                    style={{ marginTop: smDown ? 10 : 0 }}
                >
                    {Object.keys(CalendarColorDisplay).map((k, index) => (
                        <Chip
                            label={CalendarColorDisplay[k as keyof CalendarColorType]}
                            style={{ marginRight: index === Object.keys(CalendarColors).length ? 0 : 5 }}
                            backgroundColor={CalendarColors[k as keyof CalendarColorType]}
                        />
                    ))}
                </Grid>
            </Grid>
        </Grid>
    );
}
