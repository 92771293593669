import React, { useState, useEffect } from 'react';
import { Dialog, Grid } from '@material-ui/core';
import { DropDown } from 'library';
import OutreachConversationRow from './OutreachConversationRow';
import { ConversationData } from './OutreachModels';
import { TANDEM_FROM_NUMBERS } from './NewOutreachMessages';
import { refreshConversation } from './OutreachApi';

export default function OtherThreadModal({
    tandemNumber,
    externalNumber,
    open,
    closeModal,
}: {
    tandemNumber: string;
    externalNumber: string;
    open: boolean;
    closeModal: () => void;
}) {
    const [conversation, setConversation] = useState<ConversationData[]>([]);
    const [phoneNumber, setPhoneNumber] = useState<string>(tandemNumber);
    useEffect(() => {
        if (phoneNumber) {
            refreshConversation(phoneNumber, externalNumber, setConversation, false);
        }
    }, [phoneNumber, externalNumber]);
    return (
        <Dialog open={open} onClose={closeModal}>
            <Grid container item style={{ minHeight: 500, minWidth: 500, flexGrow: 1, padding: 20 }}>
                <DropDown
                    title="Tandem Phone Number"
                    placeholder="All"
                    selected={phoneNumber}
                    fields={TANDEM_FROM_NUMBERS}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                />
                <Grid container item style={{ flexGrow: 1, overflow: 'scroll', maxHeight: 400 }}>
                    {conversation.map((item) => {
                        return <OutreachConversationRow key={item.id} data={item} externalNumber={externalNumber} />;
                    })}
                </Grid>
            </Grid>
        </Dialog>
    );
}
