import React from 'react';
import { useProviderProfileContext } from '../../ProviderProfileContext';
import ProviderProfileTitledContainer from '../../ProviderProfileTitledContainer';
import JobTypeAvailability from './JobTypeAvailability';

export default function AvailableFor() {
    const { provider, visibleJobTypes, selectedProfile, setSelectedProfile } = useProviderProfileContext();
    return visibleJobTypes.length > 1 ? (
        <ProviderProfileTitledContainer title="Also available for:">
            {visibleJobTypes.map((interest, index) =>
                interest.id !== selectedProfile?.id ? (
                    <JobTypeAvailability
                        provider={provider}
                        jobTypeInterest={interest}
                        goToJobTypeProfile={setSelectedProfile}
                        last={index === visibleJobTypes.length - 1}
                    />
                ) : null,
            )}
        </ProviderProfileTitledContainer>
    ) : null;
}
