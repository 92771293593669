import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import { Checkbox, Text } from 'library';
import { GeneralDocumentVerifyProps } from '../models';
import { addYears, format, subYears } from 'date-fns';

const DOCUMENT_TYPE_REQUIREMENTS = {
    mandated_reporter_certificate_document: ['issue_date'],
};

export default function GeneralDocumentVerify({
    setExpirationDate,
    requirementOptions,
    updateRequirementOptions,
    setSignedAt,
    documentType,
}: GeneralDocumentVerifyProps) {
    const today = format(new Date(), 'yyyy-MM-dd');
    const twoYearsFromNow = format(addYears(new Date(), 2), 'yyyy-MM-dd');
    const threeYearsAgo = format(subYears(new Date(), 3), 'yyyy-MM-dd');

    const requiredInputs = DOCUMENT_TYPE_REQUIREMENTS[documentType as keyof typeof DOCUMENT_TYPE_REQUIREMENTS] || [
        'expiration_date',
    ];

    const [selectedDate, setSelectedDate] = useState(() => {
        if (requiredInputs.includes('expiration_date')) {
            return twoYearsFromNow;
        }
    });
    const [selectedIssueDate, setSelectedIssueDate] = useState(() => {
        if (requiredInputs.includes('issue_date')) {
            return today;
        }
    });
    const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedDate(event.target.value);
        setExpirationDate(new Date(event.target.value));
    };

    const handleSignedAtChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedIssueDate(event.target.value);
        setSignedAt(new Date(event.target.value));
    };

    return (
        <Grid container item direction="column">
            <Text bold textStyle={{ margin: 5 }}>
                Before verifying, check the following...
            </Text>
            <Text textStyle={{ margin: 5 }}>✔ The document has correct providers name</Text>
            {requirementOptions && (
                <Grid>
                    <Text bold textStyle={{ margin: 15 }}>
                        Select requirements met by document to upload
                    </Text>
                    {requirementOptions.map((req) => (
                        <Checkbox
                            key={req.requirement_type}
                            checked={req.completed}
                            label={req.requirement_type}
                            disabled={req.disabled}
                            onChange={() => updateRequirementOptions(req.requirement_type, !req.completed)}
                        />
                    ))}
                </Grid>
            )}

            {requiredInputs.includes('expiration_date') && (
                <>
                    <Text bold textStyle={{ margin: 15 }}>
                        Optional - Select Date Form Expires
                    </Text>
                    <input
                        type="date"
                        onChange={handleDateChange}
                        min={today}
                        value={selectedDate}
                        defaultValue={twoYearsFromNow}
                    />
                </>
            )}
            {requiredInputs.includes('issue_date') && (
                <>
                    <Text bold textStyle={{ margin: 15 }}>
                        Optional - Select Issue Date For Form
                    </Text>
                    <input
                        type="date"
                        onChange={handleSignedAtChange}
                        min={threeYearsAgo}
                        value={selectedIssueDate}
                        defaultValue={today}
                    />
                </>
            )}
        </Grid>
    );
}
