import React from 'react';
import { Colors, Text } from 'library';
import { Step, StepIcon, StepLabel, Stepper, Grid, styled } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';

export function BusinessSlotRecruitmentStatus({
    recruitmentStatuses,
}: {
    recruitmentStatuses: { title: string; show: boolean }[];
}) {
    const activeRecruitmentStatusIndex = recruitmentStatuses.findIndex((s) => s.show) ?? 0;

    return (
        <Stepper style={{ marginTop: 20 }} activeStep={activeRecruitmentStatusIndex} alternativeLabel>
            {recruitmentStatuses.map((step) => (
                <Step key={step.title}>
                    <StepLabel
                        StepIconComponent={({ active, completed, ...props }) => (
                            <StepIcon
                                {...props}
                                active={active}
                                completed={completed}
                                icon={
                                    active || completed ? (
                                        <ActiveIcon>
                                            <Check />
                                        </ActiveIcon>
                                    ) : (
                                        <UnfilledIcon />
                                    )
                                }
                            />
                        )}
                    >
                        <Text variant="caption">{step.title.toUpperCase()}</Text>
                    </StepLabel>
                </Step>
            ))}
        </Stepper>
    );
}

const Check = styled(CheckIcon)({
    fontSize: 16,
    color: Colors.white,
    fontWeight: 'bold',
});

const ActiveIcon = styled(Grid)({
    height: 25,
    width: 25,
    borderRadius: 100,
    backgroundColor: Colors.turquoise,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
});

const UnfilledIcon = styled(ActiveIcon)({
    backgroundColor: Colors.lightTurq,
    border: `1px solid ${Colors.mediumRed}`,
});
