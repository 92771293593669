import { Badge, styled } from '@mui/material';
import { Colors } from 'library';
import { AdminBusinessLocation } from 'models/AdminBusinessLocation';
import React from 'react';

export default function OpenTasksBadge({
    businessLocation,
    children,
    openModal,
}: {
    businessLocation: AdminBusinessLocation;
    children?: React.ReactNode;
    openModal: () => void;
}) {
    return (
        <StyledBadge
            badgeContent={businessLocation.open_tasks}
            color="error"
            onClick={openModal}
            anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
        >
            {children}
        </StyledBadge>
    );
}

const StyledBadge = styled(Badge)({
    '& .MuiBadge-badge': {
        left: 10,
        top: 10,
        backgroundColor: Colors.mediumRed,
        cursor: 'pointer',
    },
});
