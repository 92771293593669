import React from 'react';
import { Toolbar } from '@material-ui/core';
import NavigationItem from './NavigationItem';
import { NavItem } from './navigationItems';

export default function WebNavigation({
    selectedPage,
    setSelectedPage,
    navigationItems,
}: {
    selectedPage: number | undefined;
    setSelectedPage: (selectedPage: number) => void;
    navigationItems: NavItem[];
}) {
    return (
        <Toolbar style={{ padding: 10, justifyContent: 'center' }}>
            {navigationItems.map((item, index) => (
                <NavigationItem
                    key={item.id}
                    item={item}
                    selected={selectedPage === index}
                    setSelectedPage={setSelectedPage}
                    navigationItems={navigationItems}
                />
            ))}
        </Toolbar>
    );
}
