import React from 'react';
import { Chip, Colors, Text } from 'library';
import { useUserContext } from 'UserContext';

const ChipColors: { [key: string]: string } = {
    TRIAL_RUN: Colors.terraCotta,
    SUBSTITUTE: Colors.turquoise,
};

export default function UnfilledJobHeader({
    headline,
    businessJobType,
}: {
    headline: string;
    businessJobType?: string;
}) {
    const { getDisplayText } = useUserContext();
    return (
        <>
            <Text bold>{headline}</Text>
            {businessJobType ? (
                <Chip
                    textColor={Colors.white}
                    label={getDisplayText(businessJobType.replace('_', ' ').toLowerCase())}
                    style={{
                        textTransform: 'capitalize',
                        marginLeft: 10,
                    }}
                    backgroundColor={ChipColors[businessJobType] ?? Colors.terraCotta}
                />
            ) : null}
        </>
    );
}
