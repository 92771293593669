import React from 'react';
import SpeechBubble from 'assets/icons/tandempay/text_message.svg';
import { Provider } from 'models';
import ModalPage from 'parent-portal/payments/Components/ConnectPaymentModal/ModalPage';
import PageHeader from 'parent-portal/payments/Components/ConnectPaymentModal/PageHeader';
import { Text } from 'library';
import { Grid } from '@material-ui/core';
import { isMobile } from 'react-device-detect';
import WhatToDo from '../components/WhatToDo';
import TadaIcon from 'assets/icons/tandempay/tada-circle.svg';
import NextButton from 'parent-portal/payments/Components/ConnectPaymentModal/NextButton';

interface Props {
    provider: Provider;
    onContinue: () => void;
}

export default function HireSuccess({ provider, onContinue }: Props) {
    return (
        <ModalPage>
            <PageHeader
                iconSource={
                    provider.profile_image && provider.profile_image.image ? (
                        <img src={provider.profile_image.image} alt="profile-pic" />
                    ) : (
                        <TadaIcon />
                    )
                }
                headerText={`${provider.user.first_name} is hired!`}
                subtitleText={`Here's what to expect next.`}
            />

            <Grid container item direction="row" justify="flex-start" style={{ padding: isMobile ? 10 : 30 }}>
                <Text variant="body1">
                    1. You'll get a text (like below) when it's time to confirm the pay for {provider.user.first_name}.
                    <br></br>
                    <br></br>2. You can adjust the pay and add a tip or reimbursement. {provider.user.first_name} will
                    receive the funds immediately.<br></br>
                    <br></br>
                    3. We'll email you a receipt.
                </Text>
                <SpeechBubble style={{ marginTop: isMobile ? 0 : 20, marginLeft: isMobile ? 0 : 250 }} />
                <Grid
                    container
                    item
                    justify="space-between"
                    direction="row"
                    style={{ position: 'absolute', bottom: 10, right: 10 }}
                >
                    <WhatToDo />
                    <NextButton onClick={onContinue} />
                </Grid>
            </Grid>
        </ModalPage>
    );
}
