import React from 'react';
import { Grid, useMediaQuery } from '@mui/material';
import { Colors, Link, Text, WebLink } from 'library';
import Logo from 'assets/images/WhiteLogo.png';
import theme from 'theme';
import { useHistory } from 'react-router';

export default function LogoRow() {
    const smDown = useMediaQuery(theme.breakpoints.down('sm'));
    const history = useHistory();
    return (
        <Grid
            container
            item
            justifyContent={smDown ? 'center' : 'space-between'}
            alignItems="center"
            style={{ marginTop: 30, marginBottom: 30 }}
        >
            <Grid container item xs={12} md={4} justifyContent={smDown ? 'center' : 'flex-start'}>
                <img src={Logo} width={200} alt="logo" />
            </Grid>
            <Grid item xs={12} sm={6} md={4} style={{ marginTop: smDown ? 20 : 0 }}>
                <Text bold textStyle={{ color: Colors.white }}>
                    Have questions?
                </Text>
                {/* TODO: Add here when FAQ page is done */}
                <WebLink style={{ color: Colors.white }} url="https://info.join-tandem.com/business/faq">
                    FAQ
                </WebLink>
                <WebLink style={{ marginTop: 20, color: Colors.white }} url="mailto:support@join-tandem.com">
                    Contact Us
                </WebLink>
            </Grid>
            <Grid item xs={12} sm={6} md={4} style={{ marginTop: smDown ? 20 : 0 }}>
                <Text variant="body1" textStyle={{ color: Colors.white }}>
                    175 S. 3rd St. Suite #PH2, Columbus, Ohio 43215
                </Text>
                <Grid
                    container
                    item
                    direction="row"
                    xs={12}
                    justifyContent="space-between"
                    style={{ marginTop: 20, paddingRight: 60 }}
                >
                    <Link onClick={() => history.push('/privacy-policy')} style={styles.link}>
                        Privacy Policy
                    </Link>
                    <Link onClick={() => history.push('/tandem-terms-of-use')} style={styles.link}>
                        Terms of Use
                    </Link>
                </Grid>
            </Grid>
        </Grid>
    );
}

const styles = {
    link: { fontWeight: 'normal', fontSize: 16, color: Colors.white },
} as { [key: string]: React.CSSProperties };
