import React from 'react';
import { Text } from 'library';
import { styled } from '@mui/material';

export default function LabelWrapper({
    label,
    children,
}: {
    label: string | number | symbol;
    children: React.ReactNode;
}) {
    return (
        <Wrapper>
            <Text variant="body1" bold>
                {label.toString()}
            </Text>
            {children}
        </Wrapper>
    );
}

const Wrapper = styled('div')({
    marginBottom: 10,
});
