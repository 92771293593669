import React, { useState, useEffect } from 'react';
import { Colors } from '../../constants';
import { client, consoleLogInDev, formatE164USPhoneNumber } from 'shared';
import { AsYouType, isValidPhoneNumber } from 'libphonenumber-js';
import { Grid, makeStyles } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';

interface IPhoneProps {
    id?: string;
    label: string;
    onChange: (value: string, display: string, isValid: boolean) => void;
    value: string;
    error: boolean;
    errorText: string;
}

const useStyles = makeStyles({
    input: {
        '&:hover': {
            outline: 'none',
        },
        '&:focus': {
            outline: 'none',
        },
        '&::placeholder': {
            color: Colors.mediumNavy,
        },
        borderBottomColor: Colors.mediumNavy,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        borderWidth: 0,
        borderBottomWidth: 1,
        width: '100%',
    },
    label: {
        fontFamily: 'Work Sans',
        color: Colors.darkNavy,
    },
    errorText: {
        fontFamily: 'Work Sans',
        color: Colors.error,
        fontSize: 12,
        margin: 0,
    },
});

export default function PhoneNumberInput({ label, onChange, value, error, errorText, id }: IPhoneProps) {
    const [lastPhone, setLastPhone] = useState<string>('');
    const classes = useStyles();

    function getPlaceholder() {
        return value ? '' : label;
    }

    function getLabel() {
        return !value ? '' : label;
    }

    const formatter = new AsYouType('US');
    const format = (val: string) => {
        let newValue = formatter.input(val) || val;
        //Fix for not being able to delete area code because (614 formats back to (614)
        let returnVal = newValue === lastPhone ? newValue.slice(0, -1) : newValue;
        setLastPhone(returnVal);
        return returnVal;
    };

    function onPhoneChange(text: string) {
        const phone = formatE164USPhoneNumber(text);
        onChange(phone.toString(), format(text), isValidPhoneNumber(phone.toString()));
    }

    return (
        <Grid container direction="column">
            <Grid item>
                <label className={classes.label}>{getLabel()}</label>
            </Grid>
            <Grid item>
                <input
                    id={id}
                    className={classes.input}
                    type="phone"
                    value={value}
                    onChange={(e) => onPhoneChange(e.target.value)}
                    placeholder={getPlaceholder()}
                    style={{ color: Colors.darkNavy }}
                />
            </Grid>
            {error ? (
                <Grid container item alignItems="center">
                    <Grid item style={{ paddingLeft: 5 }}>
                        <CancelIcon style={{ color: Colors.error, maxHeight: 12 }} />
                    </Grid>
                    <Grid item>
                        <p className={classes.errorText}>{errorText}</p>
                    </Grid>
                </Grid>
            ) : null}
        </Grid>
    );
}
