import React, { useState } from 'react';
import { Provider } from 'models';
import { SecondaryButton, Text, Colors } from 'library';
import { useUserContext } from 'UserContext';
//@ts-ignore
import { FilePreviewerThumbnail } from 'react-file-previewer';
import { track } from 'shared';
import { FileDownload } from '@mui/icons-material';

export default function DownloadResumeAction({ provider }: { provider: Provider }) {
    const [resumeText, setResumeText] = useState('Download Resume');
    const { user } = useUserContext();

    const setText = () => {
        track('Provider Profile Resume Downloaded', { provider });
        setResumeText('Downloaded!');
    };

    return !!user && provider.user.resume ? (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <div
                style={{
                    backgroundColor: Colors.mediumTurq,
                    padding: 20,
                    borderRadius: 18,
                    marginBottom: 20,
                }}
            >
                {provider.user.resume?.mimetype === 'image/jpeg' ? (
                    <img
                        src={provider.user.resume?.document}
                        height={200}
                        alt={`${provider.user.first_name}'s resume`}
                    />
                ) : provider.user.resume?.mimetype === 'application/pdf' ? (
                    <FilePreviewerThumbnail
                        file={{
                            url: provider.user.resume?.document,
                            name: `${provider.user.first_name}${provider.user.last_name}Resume`,
                        }}
                        hideControls
                    />
                ) : (
                    <FileDownload style={{ width: 80, height: 80 }} htmlColor={Colors.darkNavy} />
                )}
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                <SecondaryButton onClick={setText}>
                    <a
                        href={provider.user.resume?.document}
                        target="_blank"
                        onClick={setText}
                        rel="noreferrer"
                        download
                    >
                        <Text variant="body1" bold>
                            {resumeText}
                        </Text>
                    </a>
                </SecondaryButton>
            </div>
        </div>
    ) : null;
}
