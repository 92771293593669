import React, { useState } from 'react';
import { IBusinessLocation } from 'models';
import BusinessLocationCard from 'parent-portal/BusinessLocation/BusinessLocationCard';
import AddBusinessLocationForm from 'parent-portal/BusinessLocation/AddBusinessLocationForm';
import { PrimaryButton, SizeableRoundedDialog } from 'library';
import { Grid, useMediaQuery } from '@material-ui/core';
import theme from 'theme';
import { IBusinessFaqQuestions } from 'parent-portal/home/Models/DashboardData';

export default function CenterLocations({
    businessLocations,
    refreshBusinessLocations,
    faqQuestions,
}: {
    businessLocations: IBusinessLocation[];
    refreshBusinessLocations: () => void;
    faqQuestions: IBusinessFaqQuestions[];
}) {
    const small = useMediaQuery(theme.breakpoints.down('sm'));
    const [addBusinessLocation, setAddBusinessLocation] = useState(false);
    return (
        <>
            <Grid item container style={{ padding: '0px 20px 20px 20px' }} alignContent="center">
                {businessLocations.map((location: IBusinessLocation) => (
                    <BusinessLocationCard key={location.id} businessLocation={location} faqQuestions={faqQuestions} />
                ))}
                <Grid item container style={{ justifyContent: 'right' }}>
                    <PrimaryButton onClick={() => setAddBusinessLocation(true)} buttonStyle={{ maxWidth: 200 }}>
                        Add Location
                    </PrimaryButton>
                </Grid>
            </Grid>
            <SizeableRoundedDialog
                open={addBusinessLocation}
                onClose={() => setAddBusinessLocation(false)}
                closeButton
                style={{ maxWidth: small ? '100%' : 300 }}
            >
                <AddBusinessLocationForm
                    cancel={() => setAddBusinessLocation(false)}
                    complete={() => {
                        refreshBusinessLocations();
                        setAddBusinessLocation(false);
                    }}
                    optionalContact
                />
            </SizeableRoundedDialog>
        </>
    );
}
