import React from 'react';
import { BasicDialog, Text, PrimaryButton } from 'library';

export default function EditDialog({
    title,
    children,
    open,
    onClose,
    isValid,
    hideSubmit,
}: {
    title: string;
    children: React.ReactNode;
    open: boolean;
    onClose: () => void;
    isValid: boolean;
    hideSubmit?: boolean;
}) {
    return (
        <BasicDialog isOpen={open} onClose={onClose} closeButton style={styles.dialog}>
            <Text variant="h1" bold textStyle={styles.header}>
                {title}
            </Text>
            {children}
            {!hideSubmit && (
                <PrimaryButton onClick={onClose} rightAlign disabled={!isValid}>
                    Save
                </PrimaryButton>
            )}
        </BasicDialog>
    );
}

const styles = {
    header: {
        marginBottom: 20,
    },
    dialog: {
        minWidth: '45%',
        padding: 30,
    },
};
