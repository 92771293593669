import React from 'react';
import { Link } from 'library';
import { useHistory } from 'react-router';

export default function TermsOfUse() {
    const history = useHistory();
    return (
        <div style={{ padding: 60, display: 'flex', flex: 1, flexDirection: 'column' }}>
            <Link onClick={() => history.push('/tandem-terms-of-use/provider')} style={{ marginBottom: 20 }}>
                Provider Terms Of Use Agreement
            </Link>
            <Link onClick={() => history.push('/tandem-terms-of-use/center')} style={{ marginBottom: 20 }}>
                Tandem Services Agreement (for Centers)
            </Link>
            <Link onClick={() => history.push('/tandem-terms-of-use/family')} style={{ marginBottom: 20 }}>
                Legacy Family Terms Of Use Agreement
            </Link>
        </div>
    );
}
