import { makeStyles } from '@material-ui/core';
import { Colors } from '../constants';

const cartText = {
    fontFamily: 'Work Sans',
    fontSize: 16,
    color: Colors.navy,
};

export const useStyles = makeStyles({
    background: {
        backgroundColor: Colors.lightTurq,
        padding: 20,
        borderRadius: 5,
        margin: 20,
    },
    cartHeader: {
        fontFamily: 'Work Sans',
        fontWeight: 'bold',
        fontSize: 24,
        color: Colors.navy,
    },
    cartItemRow: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    cartText: {
        ...cartText,
        marginTop: 20,
        marginBottom: 20,
    },
    totalText: {
        ...cartText,
        fontFamily: 'Work Sans',
        fontWeight: 'bold',
        marginTop: 5,
        marginBottom: 5,
        marginLeft: 50,
    },
    feeText: {
        ...cartText,
        marginTop: 5,
        marginBottom: 5,
        marginLeft: 50,
    },
    emptyCartText: {
        fontFamily: 'Work Sans',
        fontSize: 16,
        textAlign: 'center',
        marginTop: 20,
        marginBottom: 20,
        color: Colors.earth,
    },
});
