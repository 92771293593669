import React, { useState } from 'react';
import { Grid, styled } from '@mui/material';
import { Colors, IconButton, SmallIcon, Text } from 'library';
import { AddCircle, Close } from '@material-ui/icons';
import UploadPhotoDialog from './Modals/UploadPhotoDialog';
import { addPhotos, deletePhoto } from '../businessLocationProfileApi';
import { ILocationPhoto } from 'models';
import ConfirmDialog from 'reusableComponents/confirmDialog';
import { consoleLogInDev } from 'shared';

export default function LocationPhotoSection({
    locationId,
    refreshLocation,
    photos,
}: {
    locationId: number;
    refreshLocation: () => void;
    photos: ILocationPhoto[];
}) {
    const [showPhotoDialog, setShowPhotoDialog] = useState(false);
    const [deletingId, setDeletingId] = useState<number>();
    const onPhotoUpload = (files: File[]) => {
        return addPhotos(locationId, files).then(refreshLocation);
    };

    const onDelete = () => {
        if (deletingId) {
            deletePhoto(deletingId)
                .then(() => {
                    refreshLocation();
                    setDeletingId(undefined);
                })
                .catch(consoleLogInDev);
        }
    };

    return (
        <Grid container item direction="column">
            <Text variant="body1" textStyle={{ marginBottom: 10 }} bold>
                Photo Gallery
            </Text>
            <PhotoSection direction="row" container>
                {photos.map((locationPhoto) => (
                    <>
                        <Photo src={locationPhoto.photo.image} key={locationPhoto.id} />
                        <DeleteIcon
                            onClick={() => setDeletingId(locationPhoto.id)}
                            backgroundColor={Colors.darkNavy}
                            color={Colors.white}
                            icon={Close}
                            size={30}
                        />
                    </>
                ))}
                <UploadContainer flexDirection="column" container onClick={() => setShowPhotoDialog(true)}>
                    <Text variant="body1">Photo of building</Text>
                    <Text variant="body2">Will be shown to worker, can help identify center upon arrival</Text>
                    <SmallIcon backgroundColor={Colors.mediumTurq} color={Colors.turquoise} icon={<AddCircle />} />
                </UploadContainer>
            </PhotoSection>
            <UploadPhotoDialog
                isOpen={showPhotoDialog}
                onClose={() => setShowPhotoDialog(false)}
                onUpload={onPhotoUpload}
            />
            <ConfirmDialog
                open={!!deletingId}
                onClose={() => setDeletingId(undefined)}
                onConfirm={onDelete}
                title="Delete"
                message="Are you sure you want to delete this photo?"
            />
        </Grid>
    );
}

const DeleteIcon = styled(IconButton)({
    marginLeft: -30,
    marginTop: -5,
});

const PhotoSection = styled(Grid)({
    gap: 5,
    margin: 10,
});

const Photo = styled('img')({
    height: 150,
    width: 230,
    borderRadius: 10,
});

const UploadContainer = styled(Grid)({
    backgroundColor: Colors.mediumTurq,
    height: 150,
    width: 230,
    borderRadius: 10,
    alignItems: 'center',
    textAlign: 'center',
    justifyContent: 'center',
    padding: 10,
    cursor: 'pointer',
});
