import { DialogProps } from '@mui/material';
import { RoundedDialog } from 'library';
import React from 'react';
import AddressBlocker from './AddressBlocker';

interface IProps extends DialogProps {
    onConfirm: () => void;
    onClose: () => void;
}
export default function AddressBlockerDialog({ onClose, onConfirm, ...props }: IProps) {
    return (
        <RoundedDialog {...props}>
            <AddressBlocker cancelAction={onClose} confirmAction={onConfirm} />
        </RoundedDialog>
    );
}
