import React, { useState } from 'react';
import { TableCell } from '@mui/material';
import { Chip, Colors, Link, Text } from 'library';
import { AdminListProvider, BusinessJobWithAppointments, PayScaleQualification } from 'models';
import AddProvider from '../AddProvider';
import { format12HourTime, formatShortWeekdayMonthDay } from 'shared/Dates';
import SendMatch from './SendMatch';
import JobDescription from 'internal/business/JobDescription';
import SubAvailabilityDialog from '../SubAvailabilityDialog';
import { client } from 'shared';
import { ProviderMatch } from '../substituteModels';
import Row from 'internal/shared/tables/Row';
import BusinessLocationModal from 'internal/business-location/BusinessLocationModal';

interface Shift {
    id: number;
    start: string;
    end: string;
    slots: number;
}

export default function ReverseMatchingRow({
    provider,
    job,
    refreshJobs,
    updateProvider,
}: {
    provider: AdminListProvider;
    job: BusinessJobWithAppointments;
    refreshJobs: () => void;
    updateProvider?: (providerId: number, fields: Partial<AdminListProvider>) => void;
}) {
    const user = provider.user;
    const minRate = job.pay_scales?.length ? job.pay_scales[0] : undefined;
    const [isModalOpen, setIsModalOpen] = useState(false);

    function refreshMatches() {
        if (updateProvider) {
            client(`api/match/?provider_user=${provider.user.id}&outstanding=true`).then((res) => {
                const jobs = new Set(res.map((match: ProviderMatch) => match.appointment.ongoing_request.id));
                if ('id' in provider) updateProvider(provider.id, { outstanding_matches: jobs.size });
            });
        }
    }

    function getShifts(): Shift[] {
        const sorted = job.slots.sort((a, b) => a.start_date.localeCompare(b.start_date));
        return sorted.reduce((allDays, slot) => {
            const sameDayIndex = allDays.findIndex(
                (day) =>
                    day.start ===
                        `${formatShortWeekdayMonthDay(slot.start_date)} ${format12HourTime(slot.start_date)}` &&
                    day.end === format12HourTime(slot.end_date),
            );
            if (sameDayIndex !== -1) {
                // eslint-disable-next-line security/detect-object-injection
                allDays[sameDayIndex].slots++;
            } else {
                allDays.push({
                    id: slot.id,
                    start: `${formatShortWeekdayMonthDay(slot.start_date)} ${format12HourTime(slot.start_date)}`,
                    end: format12HourTime(slot.end_date),
                    slots: 1,
                });
            }
            return allDays;
        }, [] as Shift[]);
    }

    function workerMeetsQualification(worker: AdminListProvider, qualification: PayScaleQualification) {
        return worker.user_qualifications?.includes(
            qualification.name + (qualification.option ? `: ${qualification.option}` : ''),
        );
    }

    return (
        <Row>
            <TableCell>
                <Link onClick={() => setIsModalOpen(true)}>
                    {job.address.business_location?.name} ({job.address.business_location?.id}){' '}
                </Link>
                <Text>
                    {job.headline} ({job.id})
                    <Chip
                        style={{ minHeight: 20, height: 20 }}
                        textColor={Colors.white}
                        backgroundColor={
                            job.business_job_type === 'TRIAL_RUN' ? Colors.mediumTerraCotta : Colors.turquoise
                        }
                        label={job.business_job_type === 'TRIAL_RUN' ? 'TRIAL' : 'SUB'}
                    />
                    {job.pay ? `$${job.pay}` : `$${job.rate_min} - ${job.rate_max}`}/hr
                    <JobDescription job={job} />
                </Text>
                <Text variant="body2" bold>
                    Open shifts
                </Text>
                {getShifts().map((day) => (
                    <Text key={day.id}>
                        {day.start} - {day.end} ({day.slots} slots)
                    </Text>
                ))}
            </TableCell>
            <TableCell>
                <>
                    {minRate ? (
                        minRate.qualifications.map((qualification) => (
                            <Text key={qualification.qualification_id}>
                                {workerMeetsQualification(provider, qualification) ? '✅ ' : '❌ '}
                                {qualification.name}:{' '}
                                {qualification.option ? qualification.option : qualification.value}
                            </Text>
                        ))
                    ) : (
                        <Text>No qualifications</Text>
                    )}
                </>
            </TableCell>
            <TableCell style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
                <AddProvider job={job} refreshJobs={refreshJobs} initialProviderUserId={user.id} />
                <SendMatch job={job} initialProviders={[user]} refreshProvider={refreshMatches} />
                <SubAvailabilityDialog userId={provider.user.id} />
            </TableCell>
            {isModalOpen && job.address.business_location ? (
                <BusinessLocationModal
                    businessLocationId={job.address.business_location.id}
                    open={isModalOpen}
                    onClose={() => setIsModalOpen(false)}
                />
            ) : null}
        </Row>
    );
}
