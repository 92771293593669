import React from 'react';
import { DayOfWeekSelections, DayOfWeek } from 'parent-portal/forms/JobRequestTypes';
import { FormFieldProps } from '../../FormTypes';
import { Grid } from '@mui/material';
import { KeyboardTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import FormField from '../../FormField';
import ErrorText from '../../ErrorText';
import moment from 'moment';
import DateFnsUtils from '@date-io/moment';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { useWidth } from 'reusableComponents/useWidth';
import DayButton from './DayButton';
import { useFieldValidation } from '../../FormHook';
import { checkIfAllDaysHaveTime, checkIfDurationIsValid, checkIfEndTimeIsValid } from './JobTimeValidation';
import { Colors } from 'library';
import { FaClock } from 'react-icons/fa';
import SlotsPicker from './SlotsPicker';

interface JobSameTimeProps {
    daysOfTheWeek: FormFieldProps<DayOfWeekSelections>;
}

export default function JobMultiTimePicker({ daysOfTheWeek }: JobSameTimeProps) {
    const { value, setValue } = daysOfTheWeek;

    const width = useWidth();

    useFieldValidation(daysOfTheWeek, checkIfAllDaysHaveTime(value));

    function setTime(day: string, time: MaterialUiPickersDate, type: string) {
        const updatedDay = {
            ...value[day as keyof typeof value],
        };

        if (type === 'start') {
            updatedDay.start = time;
        } else {
            updatedDay.end = time;
        }

        setValue({
            ...value,
            [day as keyof typeof value]: updatedDay,
        });
    }

    const initialFocusedDateStart = moment().startOf('day').hour(12);
    const initialFocusedDateEnd = moment().startOf('day').hour(15);

    const timePicker = ({ day, dayName }: { day: DayOfWeek; dayName: string }) => {
        const startIsMidnight = day.start?.hour() === 0 && day.start.minute() === 0;
        const endIsMidnight = day.end?.hour() === 0 && day.end.minute() === 0;
        const startIsNoon = day.start?.hour() === 12 && day.start.minute() === 0;
        const endIsNoon = day.end?.hour() === 12 && day.end.minute() === 0;
        return (
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid container alignItems="center" gap={1}>
                    <Grid item xs={12} sm={4} style={{ width: width === 'xs' ? '100%' : undefined }}>
                        <FormField style={{ margin: ['md', 'lg', 'xl'].includes(width) ? 0 : undefined }}>
                            <KeyboardTimePicker
                                id="start"
                                placeholder="Start: 12:00 PM"
                                format={`hh:mm ${startIsMidnight ? '\\mi\\dnig\\ht' : startIsNoon ? 'noon' : 'a'}`}
                                mask="__:__ _M"
                                value={day.start}
                                onChange={(time) => setTime(dayName, time, 'start')}
                                initialFocusedDate={initialFocusedDateStart}
                                variant="inline"
                                style={{ width: '100%' }}
                                keyboardIcon={<FaClock color={Colors.darkNavy} />}
                            />
                        </FormField>
                    </Grid>
                    <Grid item xs={12} sm={4} style={{ width: width === 'xs' ? '100%' : undefined }}>
                        <FormField
                            style={{
                                margin: ['md', 'lg', 'xl'].includes(width) ? 0 : undefined,
                            }}
                        >
                            <KeyboardTimePicker
                                id="end"
                                placeholder="End: 3:00 PM"
                                format={`hh:mm ${endIsMidnight ? '\\mi\\dnig\\ht' : endIsNoon ? 'noon' : 'a'}`}
                                mask="__:__ _M"
                                value={day.end}
                                onChange={(time) => setTime(dayName, time, 'end')}
                                initialFocusedDate={initialFocusedDateEnd}
                                variant="inline"
                                style={{ width: '100%' }}
                                keyboardIcon={<FaClock color={Colors.darkNavy} />}
                            />
                            {!checkIfEndTimeIsValid(day) && <ErrorText>Invalid end time</ErrorText>}

                            <Grid container xs={12}>
                                {!checkIfDurationIsValid(day) && (
                                    <ErrorText>Duration must be at least 30 minutes</ErrorText>
                                )}
                            </Grid>
                        </FormField>
                    </Grid>
                    <Grid item xs={12} sm={2} style={{ marginBottom: 15 }}>
                        <SlotsPicker
                            key={dayName}
                            slotsAvailable={day.slots}
                            setSlotsAvailable={(slots) =>
                                daysOfTheWeek.setValue({
                                    ...daysOfTheWeek.value,
                                    [dayName]: { ...day, slots },
                                })
                            }
                            label="Workers"
                        />
                    </Grid>
                </Grid>
            </MuiPickersUtilsProvider>
        );
    };

    return (
        <Grid container xs={12} direction="column">
            {Object.entries(value).map(([dayName, day]) => {
                return day.selected ? (
                    <Grid container style={{ marginBottom: 30, flexWrap: 'nowrap' }} gap={2}>
                        <Grid item style={{ alignSelf: 'center' }}>
                            <DayButton dayName={dayName} />
                        </Grid>
                        {timePicker({ day: day, dayName: dayName })}
                    </Grid>
                ) : null;
            })}
        </Grid>
    );
}
