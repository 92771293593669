import React, { useState } from 'react';
import { Colors, Text, Highlight, PrimaryButton, SecondaryButton } from 'library';
import { Grid, useMediaQuery } from '@mui/material';
import HomeHeaderImage from 'assets/images/marketing-site/marketing-kids.png';
import theme from 'theme';
import LearnMoreModal from 'parent-portal/public/on-demand/LearnMore/LearnMoreModal';
import { track, useClient } from 'shared';
import { FindButtons } from './FindButtons';
import { HomeFindSection } from './HomeFindSection';
import { StaffNearYou } from './StaffNearYou';

import type { ISpotlightWorker } from 'parent-portal/home/Models/DashboardData';

export function HomeHeader({ signUp }: { signUp: () => void }) {
    const smDown = useMediaQuery(theme.breakpoints.down('sm'));

    const [openLearnMore, setOpenLearnMore] = useState<boolean>(false);
    const [selected, setSelected] = useState<'staff' | 'shifts'>('staff');
    const [zipCode, setZipCode] = useState<string>('');
    const [searchedZipCode, setSearchedZipCode] = useState<string>('');
    const [showStaffSearch, setShowStaffSearch] = useState<boolean>(false);

    const { loading, data, status, send } = useClient<ISpotlightWorker[]>({
        url: `api/contact-info-location/spotlighted-workers-by-zip/?zip_code=${zipCode}`,
        options: { sendImmediately: false },
    });

    function updateZipCode(event: React.ChangeEvent<HTMLInputElement>) {
        if (event.target.value.length <= 5 && !isNaN(Number(event.target.value))) {
            setZipCode(event.target.value.trim());
        }
    }

    function onSearch() {
        setShowStaffSearch(true);
        setSearchedZipCode(zipCode);
        track('Customer Searched for Staff Near Them');

        send();
    }

    return (
        <Grid
            container
            xs={12}
            style={{
                backgroundImage: `url(${HomeHeaderImage})`,
                backgroundSize: smDown ? 'contain' : 'cover',
                paddingLeft: smDown ? 40 : 80,
                paddingRight: smDown ? 40 : 80,
                backgroundRepeat: 'no-repeat',
                backgroundPositionX: 'center',
            }}
        >
            {!showStaffSearch ? (
                <Grid container item justifyContent="space-between" style={{ marginBottom: '15% ' }}>
                    <Grid item container xs={12} md={5} flexDirection="column" style={{ marginTop: '15%' }}>
                        <Text variant="display" bold textStyle={{ marginBottom: 20 }}>
                            <Highlight color={Colors.mediumTurq}>
                                Staff your child care program with compliant, quality workers.
                            </Highlight>
                        </Text>
                        <Grid item container style={{ gap: 20 }} flexDirection="column">
                            <PrimaryButton id="btnCreateBusAccount" buttonStyle={{ width: 200 }} onClick={signUp}>
                                Get Started
                            </PrimaryButton>
                            <SecondaryButton
                                id="btnLeanCreateBusAccount"
                                buttonStyle={{ width: 200 }}
                                onClick={() => setOpenLearnMore(true)}
                            >
                                Learn More
                            </SecondaryButton>
                        </Grid>
                    </Grid>
                    <Grid
                        item
                        container
                        xs={12}
                        md={5}
                        flexDirection="column"
                        style={{
                            marginTop: '15%',
                            backgroundColor: Colors.white,
                            borderRadius: 18,
                            border: `1px solid ${Colors.mediumGrey}`,
                            height: 'max-content',
                            padding: '30px 20px 30px 20px',
                            width: '50%',
                            marginBottom: smDown ? '15%' : 0,
                        }}
                    >
                        <FindButtons selected={selected} setSelected={setSelected} />
                        <HomeFindSection
                            selected={selected}
                            zipCode={zipCode}
                            updateZipCode={updateZipCode}
                            onSearch={onSearch}
                        />
                    </Grid>
                    <LearnMoreModal open={openLearnMore} onClose={() => setOpenLearnMore(false)} />
                </Grid>
            ) : (
                <StaffNearYou
                    signUp={signUp}
                    zipCode={zipCode}
                    searchedZipCode={searchedZipCode}
                    updateZipCode={updateZipCode}
                    onSearch={onSearch}
                    loading={loading}
                    status={status}
                    workers={data}
                />
            )}
        </Grid>
    );
}
