import React from 'react';
import { Colors, IconButton, Text } from 'library';
import { formatLongWeekdayMonthDay } from 'shared/Dates';
import { getDayForNextWeek } from './duplicateJob';
import { Add, Remove } from '@mui/icons-material';
import { TextField } from '@mui/material';
import { DayOfWeekTimesSelection } from './duplicateJobModels';

export default function ScheduledDay({
    day,
    week,
    update,
}: {
    day: DayOfWeekTimesSelection;
    update: (dayToUpdate: DayOfWeekTimesSelection) => void;
    week?: Date;
}) {
    const nextWeekDate = getDayForNextWeek(day.day, week);

    return (
        <>
            <div
                key={Object.keys(day)[0]}
                style={{
                    backgroundColor: day.selected ? Colors.lightBlue : Colors.lightGrey,
                    marginBottom: 10,
                    borderRadius: 18,
                    padding: 20,
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                <Text bold>{formatLongWeekdayMonthDay(nextWeekDate)}</Text>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 10 }}>
                    <TextField
                        label="Start time"
                        type="time"
                        value={day.start}
                        onChange={(e) => update({ ...day, start: e.target.value })}
                        disabled={!day.selected}
                        inputProps={{ step: 60 }}
                    />
                    <TextField
                        label="End time"
                        type="time"
                        value={day.end}
                        onChange={(e) => update({ ...day, end: e.target.value })}
                        disabled={!day.selected}
                        inputProps={{ step: 60 }}
                    />
                    <IconButton
                        icon={day.selected ? Remove : Add}
                        onClick={() => update({ ...day, selected: !day.selected })}
                    />
                </div>
            </div>
        </>
    );
}
