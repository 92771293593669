import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Grid, Typography } from '@material-ui/core';
import { Colors } from 'library';

const LOGO = require('../assets/images/NavyLogo.png');

const ProviderDashboard = () => {
    return (
        <Grid container style={{ justifyContent: 'center', padding: 10 }}>
            <a href="https://www.join-tandem.com" style={{ margin: 20 }} className="navbar-brand">
                <img src={LOGO} alt="tandem-logo" style={{ maxWidth: '175px', margin: 0, padding: 0 }} />
            </a>
            <Grid
                container
                item
                xs={11}
                style={{ backgroundColor: Colors.lightTurq, borderRadius: 18, justifyContent: 'center', padding: 5 }}
            >
                <Grid item xs={11}>
                    <Typography
                        variant="h2"
                        style={{
                            textAlign: 'center',
                            color: Colors.darkNavy,
                            fontSize: 20,
                            marginTop: 24,
                            marginBottom: 12,
                        }}
                    >
                        Provider Dashboard
                    </Typography>
                </Grid>
                <Grid container alignContent="center" style={{ flexDirection: 'column' }}>
                    <Link to="/sitter/payment">Checkout</Link>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default withRouter(ProviderDashboard);
