import { CircularProgress } from '@mui/material';
import { BusinessApplicant } from 'internal/applicants/ApplicantModels';
import { Checkbox, Text } from 'library';
import { BusinessJobWithAppointments, toBookingDate } from 'models';
import React, { useEffect, useState } from 'react';
import BusinessApplicantMenuItem from './BusinessApplicantMenuItem';
import { getProviderBookings } from './matchingListApi';
import { getBusinessApplicants } from 'internal/applicants/businessApplicantApi';

interface MenuBusinessApplicant extends BusinessApplicant {
    bookings?: string[];
}
export default function BusinessApplicantMenu({
    job,
    open,
    refreshJobs,
    week,
}: {
    job: BusinessJobWithAppointments;
    open: boolean;
    refreshJobs: () => void;
    week?: Date;
}) {
    const [applicants, setApplicants] = useState<MenuBusinessApplicant[]>([]);
    const [loading, setLoading] = useState(false);
    const [showIgnored, setShowIgnored] = useState(false);
    const [showApprovedDeclined, setShowApprovedDeclined] = useState(false);

    const fetchApplicants = () => {
        getBusinessApplicants({
            job: job.id,
            show_ignore: showIgnored,
            show_approved_declined: showApprovedDeclined,
        })
            .then((response: BusinessApplicant[]) => {
                const apiApplicants = response as MenuBusinessApplicant[];
                const userIds = response.map((x) => x.applied_user.id);
                getProviderBookings(userIds, job.start_date, job.end_date, 250)
                    .then((bookings) => {
                        apiApplicants.forEach((x) => {
                            x.bookings = bookings.results
                                .filter((y) => y.user_id === x.applied_user.id)
                                .map(toBookingDate);
                        });
                        setApplicants(apiApplicants);
                    })
                    .catch(() => {
                        setApplicants(apiApplicants);
                    })
                    .finally(() => setLoading(false));
            })
            .catch(() => setLoading(false));
    };

    useEffect(() => {
        if (open) {
            setLoading(true);
            fetchApplicants();
        }
    }, [open, job, showIgnored, showApprovedDeclined]);

    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
                <Checkbox checked={showIgnored} label="Show Ignored" onChange={() => setShowIgnored(!showIgnored)} />
                <Checkbox
                    checked={showApprovedDeclined}
                    label="Show Approved/Declined"
                    onChange={() => setShowApprovedDeclined(!showApprovedDeclined)}
                />
            </div>
            {loading ? (
                <CircularProgress style={{ padding: 10 }} />
            ) : applicants.length > 0 ? (
                applicants.map((applicant) => (
                    <BusinessApplicantMenuItem
                        key={applicant.id}
                        applicant={applicant}
                        job={job}
                        refreshJobs={refreshJobs}
                        refreshApplicants={fetchApplicants}
                        week={week}
                        bookingDates={applicant.bookings}
                    />
                ))
            ) : (
                <Text textStyle={{ padding: 10 }}>No applicants found</Text>
            )}
        </div>
    );
}
