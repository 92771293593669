import React from 'react';
import { Grid } from '@material-ui/core';
import { Colors, Text } from 'library';
import Quotes from 'assets/icons/tandempay/quotes.png';
import Courtnee from 'assets/icons/tandempay/courtnee.png';
import Yasmine from 'assets/icons/tandempay/yasmine.png';
import Erandi from 'assets/icons/tandempay/erandi.png';

interface ITestimony {
    boldText: string;
    bodyText: string;
    preText?: string;
    img: string;
    name: string;
    subtitle: string;
}

export default function FamilyTestimony() {
    const data = [
        {
            boldText: `Tandem doesn't have any fees. `,
            bodyText: `I pay my sitter what is comfortable for my budget, and there are no membership costs.`,
            img: Courtnee,
            name: `Courtnee`,
            subtitle: `Columbus mom of 2`,
        },
        {
            boldText: `There's no "I got to go get cash." `,
            bodyText: `Nope. Tandem has built that all into their system.`,
            img: Yasmine,
            name: `Yasmine`,
            subtitle: `Bexley mom of 3`,
        },
        {
            preText: `I know that `,
            boldText: `whatever I'm paying is going 100% to the childcare provider. `,
            bodyText: `And that's very different from any other service out there.`,
            img: Erandi,
            name: `Erandi`,
            subtitle: `Bexley mom of 2`,
        },
    ];

    function renderItem(item: ITestimony) {
        return (
            <Grid container item xs={10} md={3} style={{ marginBottom: 24 }} direction="column">
                <Grid item>
                    <img src={Quotes} alt="Navy double quotes on a light blue background" style={{ width: 50 }} />
                </Grid>
                <Grid container item style={{ paddingTop: 12 }}>
                    <Text variant="body1" textStyle={{ fontSize: 14 }}>
                        {!!item.preText ? (
                            <Text variant="body1" textStyle={{ fontSize: 14 }}>
                                {item.preText}
                            </Text>
                        ) : null}
                        <Text variant="body1" bold textStyle={{ fontSize: 14 }}>
                            {item.boldText}
                        </Text>
                        <Text variant="body1" textStyle={{ fontSize: 14 }}>
                            {item.bodyText}
                        </Text>
                    </Text>
                </Grid>
                <Grid container item direction="row" style={{ paddingTop: 12 }}>
                    <Grid item xs={2}>
                        <img src={item.img} alt="Local Tandem user and parent" style={{ width: 28 }} />
                    </Grid>
                    <Grid container item xs={10} direction="column">
                        <Text variant="body2" textStyle={{ fontSize: 12 }}>
                            {item.name}
                        </Text>
                        <Text variant="body3" textStyle={{ fontSize: 10 }} color={Colors.mediumGrey}>
                            {item.subtitle}
                        </Text>
                    </Grid>
                </Grid>
            </Grid>
        );
    }

    return (
        <Grid container style={{ paddingTop: 24 }} justify="center">
            <Text variant="h1">Over 700 local families pay their care providers through Tandem.</Text>
            <Grid container item direction="row" justify="space-around" style={{ paddingTop: 32 }}>
                {data.map((testimony) => renderItem(testimony))}
            </Grid>
        </Grid>
    );
}
