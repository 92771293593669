import { createContext } from 'react';
import { Business, BusinessLocationBannedWorker, ShiftProviderUser } from 'models';

interface IBannededWorkerModalContext {
    isAdmin: boolean;
    business: Business | { id: number; name: string };
    provider: ShiftProviderUser;
    emptyLocationList: boolean;
    locations: BusinessLocationBannedWorker[];
    businessLocationId?: number;
    triggerClick: boolean;
    setTriggerClick: React.Dispatch<React.SetStateAction<boolean>>;
    setLocations: React.Dispatch<React.SetStateAction<BusinessLocationBannedWorker[]>>;
    setError: React.Dispatch<React.SetStateAction<boolean>>;
    prePrompt: () => Promise<boolean>;
    onSuccess: (preferredWorkers: BusinessLocationBannedWorker[]) => void;
    onClose: () => void;
}

export const BannedWorkerModalContext = createContext<IBannededWorkerModalContext>({
    isAdmin: false,
    business: {} as Business,
    provider: {} as ShiftProviderUser,
    emptyLocationList: false,
    locations: [],
    businessLocationId: 0,
    triggerClick: false,
    setTriggerClick: () => {},
    setLocations: () => {},
    setError: () => {},
    prePrompt: async () => false,
    onSuccess: () => {},
    onClose: () => {},
});
