import React, { useEffect, useState } from 'react';
import JobFormPage from '../JobFormPage';
import { useJobFormContext } from 'parent-portal/forms/JobFormContext';
import { styled } from '@mui/material';
import { Colors, Text } from 'library';
import JobDetailsSection from './components/JobDetailsSection';
import JobSchedulesSection from './components/JobSchedulesSection';
import RequestedWorkersSection from './components/RequestedWorkersSection';
import PayScalesSection from './components/PayScalesSection';
import LocationSection from './components/LocationSection';
import LateCancellationWarning from './components/LateCancellationWarning';
import JobFaqSection from './components/JobFaqSection';

export default function JobSummaryPage({ name }: { name: string }) {
    const { submit, recommendations, ignoreRecommendations, datesConfirmed } = useJobFormContext();
    const [disabled, setDisabled] = useState(true);

    useEffect(() => {
        setDisabled((recommendations.length > 0 && !ignoreRecommendations) || !datesConfirmed);
    }, [recommendations, ignoreRecommendations, datesConfirmed]);

    return (
        <JobFormPage
            title="Review Details"
            onNextClicked={() => submit(name)}
            nextDisabled={disabled}
            nextText="Submit"
            id={name}
        >
            <Text variant="body1">Edit the information to ensure details are correct for your new job post.</Text>
            <ListContainer>
                <JobDetailsSection />
                <JobSchedulesSection />
                <RequestedWorkersSection />
                <Divider />
                <PayScalesSection />
                <Divider />
                <JobFaqSection />
                <Divider />
                <LocationSection />
            </ListContainer>
            <LateCancellationWarning />
        </JobFormPage>
    );
}

const ListContainer = styled('div')({
    margin: '10px -20px 0 -20px',
});

const Divider = styled('div')({
    width: '100%',
    height: 1,
    backgroundColor: Colors.mediumGrey,
});
