import React from 'react';
import { Colors, Text } from 'library';
import { Link } from 'react-router-dom';

import ThanksForFeedback from '../components/ThanksForFeedback';
import { MainContainer, TextContainer } from '../components/Containers';

export default function SuccessfullyUpdatedPreferredWorkers() {
    return (
        <MainContainer>
            <TextContainer>
                <ThanksForFeedback />
            </TextContainer>
            <TextContainer>
                <Text variant="body1">
                    Awesome! We have successfully updated your preferred workers list. Thanks again for the review!.
                </Text>
            </TextContainer>
            <TextContainer>
                <Link to="/home" style={{ color: Colors.darkNavy, textDecoration: 'underline', fontWeight: 'bold' }}>
                    Back home
                </Link>
            </TextContainer>
        </MainContainer>
    );
}
