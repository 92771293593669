import { AdminSlot, IPairing, PagingResult, SubJobAppointment } from 'models';
import { client } from 'shared';

export function getProviderBookings(
    userIds: number | number[],
    startDate: string,
    endDate?: string,
    pageSize?: number,
): Promise<PagingResult<IPairing>> {
    let url = `api/bookings/?users=${userIds}&start_date=${startDate}`;
    if (endDate) {
        url += `&end_date=${endDate}`;
    }

    if (pageSize) {
        url += `&page_size=${pageSize}`;
    }

    return client(url);
}

export function getAdminProviders(queryParams: string) {
    return client(`api/admin-providers/?${queryParams}`);
}

export function allAppointmentsForSlots(slots: AdminSlot[]) {
    return slots.reduce((allAppointments: SubJobAppointment[], slot) => {
        return [...allAppointments, ...slot.appointments];
    }, []);
}

export function markMatchesAsDeclined(matchIds: number[]) {
    return client(`api/match/decline/`, {
        method: 'POST',
        body: { match_ids: matchIds },
    });
}
