import React from 'react';
import { BusinessJobResponse } from '../models';
import { Grid } from '@mui/material';
import { BusinessSlot, ShiftMatch, ShiftJob, ShiftProviderUser } from 'models';
import { formatMinutesFromDate } from 'shared/Dates';
import { TitleDescription } from './TitleDescription';
import { MatchStatusChips } from '../../BusinessCalendar/ShiftCard/MatchStatusChips';
import { getShiftTimes } from '../../BusinessCalendar/ShiftCard/shiftTimesUtils';

export function BusinessSlotSchedule({
    slot,
    job,
    provider,
    recruitmentStatuses,
}: {
    slot: BusinessSlot;
    job: BusinessJobResponse['job_details'] | ShiftJob;
    provider?: ShiftProviderUser;
    recruitmentStatuses: { title: string; show: boolean }[];
}) {
    const recruitmentStatus = recruitmentStatuses.find((s) => s.show)?.title ?? 'Shift Posted';
    const matches = slot.appointments.reduce((x: ShiftMatch[], appt) => [...x, ...(appt.matches ?? [])], []);
    const { shiftStart, shiftEnd, infoText } = getShiftTimes(slot);

    return (
        <Grid item container direction="row" style={{ marginTop: 5, gap: 20 }}>
            <TitleDescription
                title={'Schedule'}
                description={`${formatMinutesFromDate(shiftStart)} - ${formatMinutesFromDate(shiftEnd)}`}
                infoText={infoText}
            />
            <TitleDescription
                title={job.pay ? 'Pay' : 'Pay Scale'}
                description={job.pay ? `${job.pay}/hr` : `${job.rate_min} - ${job.rate_max}/hr`}
            />
            {provider ? null : <TitleDescription title="Confirmed Worker" description={recruitmentStatus} />}
            <MatchStatusChips matches={matches} />
        </Grid>
    );
}
