import React, { useState } from 'react';
import { Text } from 'library';
import { useJobFormContext } from 'parent-portal/forms/JobFormContext';
import SectionContainer from './SectionContainer';
import EditDialog from './EditDialog';
import JobFaqForm from '../../JobFaqPage/JobFaqForm';

export default function JobFaqSection() {
    const [editOpen, setEditOpen] = useState(false);
    const { form, recommendations } = useJobFormContext();
    const { faqs } = form.fieldStates;

    return (
        <SectionContainer
            error={recommendations.length > 0}
            editModal={
                <EditDialog title={`Edit FAQ`} open={editOpen} onClose={() => setEditOpen(false)} isValid={true}>
                    <JobFaqForm faqs={faqs.value} setFaqs={faqs.setValue} />
                </EditDialog>
            }
            setEditOpen={setEditOpen}
        >
            <Text variant="h2" bold textStyle={{ marginBottom: 10 }}>
                Frequently asked questions.
            </Text>
            <Text variant="body2">{faqs.value.length} answered.</Text>
        </SectionContainer>
    );
}
