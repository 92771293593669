import { SmallIcon, Text } from 'library';
import React from 'react';
import { Grid } from '@material-ui/core';

export default function ProviderSummaryDetailRow({
    icon,
    detail,
    italic,
    subDetail,
    bold,
}: {
    icon?: React.ReactNode;
    detail?: string;
    subDetail?: string;
    italic?: boolean;
    bold?: boolean;
}) {
    return (
        <Grid container item xs={12} direction="row" spacing={2} justify="center">
            <Grid container item xs={2} alignItems="center" justify="center">
                {!!icon ? (
                    <Grid item>
                        <SmallIcon icon={icon} style={{ width: 22, height: 22 }} />
                    </Grid>
                ) : null}
            </Grid>
            <Grid container item xs={10}>
                <Grid container item xs={12} alignItems="center">
                    <Text
                        variant={!!subDetail ? 'body2' : 'deprecatedCaption'}
                        textStyle={{
                            fontStyle: italic ? 'italic' : undefined,
                        }}
                        bold={!!subDetail}
                    >
                        {detail}
                    </Text>
                </Grid>
                {!!subDetail ? (
                    <Grid container item xs={12}>
                        {subDetail}
                    </Grid>
                ) : null}
            </Grid>
        </Grid>
    );
}
