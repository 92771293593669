import { Colors, LoadingSpinner, Text } from 'library';
import { BusinessJobWithAppointments } from 'models';
import React from 'react';
import RebookingListMenuItem from './RebookingListMenuItem';
import { useBusinessLocationWorker } from 'internal/business-location/api';
import { styled } from '@mui/material';
import { Grid } from '@material-ui/core';

export default function RebookingList({
    job,
    refreshJobs,
}: {
    job: BusinessJobWithAppointments;
    refreshJobs: () => void;
}) {
    const { status, data } = useBusinessLocationWorker(job.address.business_location?.id);

    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            {status === 'loading' ? (
                <LoadingSpinner />
            ) : (
                <Container direction="row" container>
                    <Section>
                        <Text textStyle={{ color: Colors.turquoise }} bold variant="h2">
                            On Staff:
                        </Text>
                        {data?.on_staff?.length ? (
                            data.on_staff.map((user, index) => (
                                <RebookingListMenuItem
                                    key={user.id}
                                    user={user}
                                    job={job}
                                    refreshJobs={refreshJobs}
                                    rowNum={index}
                                />
                            ))
                        ) : (
                            <EmptyStateText>No on staff workers found</EmptyStateText>
                        )}
                    </Section>

                    <Section>
                        <Text textStyle={{ color: Colors.turquoise }} bold variant="h2">
                            Preferred Workers:
                        </Text>
                        {data?.preferred_workers?.length ? (
                            data.preferred_workers.map((preferred, index) => (
                                <RebookingListMenuItem
                                    key={preferred.user.id}
                                    user={preferred.user}
                                    job={job}
                                    refreshJobs={refreshJobs}
                                    rowNum={index}
                                />
                            ))
                        ) : (
                            <EmptyStateText>No preferred workers found</EmptyStateText>
                        )}
                    </Section>
                </Container>
            )}
        </div>
    );
}
const Section = styled(Grid)({
    flex: 1,
});

const Container = styled(Grid)({
    padding: 50,
    gap: 10,
});

const EmptyStateText = styled(Text)({
    padding: 10,
});
