import React, { useState, useEffect } from 'react';
import { useProviderProfileContext } from 'parent-portal/provider-profile/ProviderProfileContext';
import Review from './Review';
import { Paper } from 'library';
import { Review as ReviewModel } from 'models';
import CustomPagination from 'reusableComponents/customPagination';
import ProfileDivider from 'parent-portal/provider-profile/ProfileDivider';

export default function ProfileProfileReviewsTab() {
    const { provider } = useProviderProfileContext();
    const [visibleReviews, setVisibleReviews] = useState<ReviewModel[]>([]);
    const [pageCount, setPageCount] = useState(0);
    const pageSize = 4;

    const showNewContent = (content: ReviewModel[]) => {
        setVisibleReviews(content);
    };

    useEffect(() => {
        setPageCount(Math.floor(((provider.user.reviews?.length || 0) + pageSize - 1) / pageSize));
        setVisibleReviews(provider.user.reviews?.slice(0, pageSize) || []);
    }, [provider.user.reviews]);

    return (
        <Paper>
            {visibleReviews.map((review, index) => (
                <>
                    <Review review={review} />
                    <ProfileDivider index={index} pageSize={visibleReviews.length} />
                </>
            ))}
            <div style={{ height: 20 }} />
            {(provider.user.reviews?.length || 0) > pageSize && (
                <CustomPagination
                    pageCount={pageCount}
                    content={provider.user.reviews}
                    pageSize={pageSize}
                    showNewContent={showNewContent}
                />
            )}
        </Paper>
    );
}
