import React from 'react';
import { getDayString } from 'parent-portal/forms/day-operations';
import { DayOfWeekSelections, StartEstimateMonthType } from 'parent-portal/forms/JobRequestTypes';
import { FormFieldProps } from '../../FormTypes';
import { Typography } from '@material-ui/core';
import moment, { Moment } from 'moment';

type JobTimes = {
    start: moment.Moment | null;
    end: moment.Moment | null;
};

function getTimeStartEndString(start: moment.Moment, end: moment.Moment) {
    return `${start?.format('h:mm A')} - ${end?.format('h:mm A')}`;
}

function getSameTimeDayString(daysOfTheWeek: FormFieldProps<DayOfWeekSelections>, jobTimes: JobTimes) {
    return (
        <>
            <Typography>
                Every {getDayString(Object.entries(daysOfTheWeek.value).filter(([_, day]) => day.selected))}
            </Typography>
            <Typography>
                From {getTimeStartEndString(jobTimes.start as moment.Moment, jobTimes.end as moment.Moment)}
            </Typography>
        </>
    );
}

function getMultipleTimeDayString(daysOfTheWeek: FormFieldProps<DayOfWeekSelections>) {
    const selectedDays = Object.entries(daysOfTheWeek.value).filter(([_, day]) => day.selected);
    return (
        <>
            {Object.entries(selectedDays).map(([index, [dayName, day]]) => {
                return <Typography>{`${dayName} from ${getTimeStartEndString(day.start, day.end)}`}</Typography>;
            })}
        </>
    );
}

export function getTimeString(
    daysOfTheWeek: FormFieldProps<DayOfWeekSelections>,
    jobTimes: JobTimes,
    sameTimes: boolean,
    startEstimateMonth: StartEstimateMonthType,
    startDate?: Moment | null,
    endDate?: Moment | null,
) {
    return (
        <>
            <Typography>
                {startDate ? `${startDate!.format('ll')} - ${endDate!.format('LL')}` : startEstimateMonth}
            </Typography>
            {sameTimes ? getSameTimeDayString(daysOfTheWeek, jobTimes) : getMultipleTimeDayString(daysOfTheWeek)}
        </>
    );
}
