import React from 'react';
import { Text, Colors } from 'library';
import { Grid, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, styled } from '@mui/material';
import Check from 'assets/images/marketing-site/basic-check-icon.svg';
import Close from 'assets/images/marketing-site/basic-close-icon.svg';
import Logo from 'assets/images/NavyLogo.svg';

const rows = [
    'Fully Compliant Workers',
    'Proprietary Matching System',
    'No Upfront Costs or Contracts',
    'Built for Early Childhood Education',
    'In House Experts',
];
const StyledGrid = styled(Grid)({
    boxShadow: '0px 3px 6px #00000029',
    borderRadius: 18,
    backgroundColor: Colors.white,
    padding: 10,
    maxWidth: '80%',
});
export default function ComparisonTable() {
    return (
        <TableContainer component={StyledGrid}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <Text variant="h2" bold textStyle={{ color: Colors.turquoise }}>
                                How We Compare
                            </Text>
                        </TableCell>
                        <TableCell align="center">
                            <Text variant="h2" bold textStyle={{ textAlign: 'center' }}>
                                Other Job Posting Sites
                            </Text>
                        </TableCell>
                        <TableCell
                            align="center"
                            style={{
                                borderTopWidth: 3,
                                borderLeftWidth: 3,
                                borderRightWidth: 3,
                                borderBottomWidth: 0,
                                borderStyle: 'solid',
                                borderColor: Colors.turquoise,
                            }}
                        >
                            <Text variant="h2" bold>
                                <Logo width={100} height={'auto'} />
                            </Text>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row, i) => (
                        <TableRow key={row} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell component="th" scope="row">
                                <Text variant="body1" bold>
                                    {row}
                                </Text>
                            </TableCell>
                            <TableCell align="center">
                                <Close width={15} height={15} />
                            </TableCell>
                            <TableCell
                                align="center"
                                style={{
                                    borderLeftWidth: 3,
                                    borderRightWidth: 3,
                                    borderBottomWidth: i === rows.length - 1 ? 3 : 0,
                                    borderLeftStyle: 'solid',
                                    borderRightStyle: 'solid',
                                    borderBottomStyle: i === rows.length - 1 ? 'solid' : undefined,
                                    borderLeftColor: Colors.turquoise,
                                    borderRightColor: Colors.turquoise,
                                    borderBottomColor: i === rows.length - 1 ? Colors.turquoise : undefined,
                                }}
                            >
                                <Check width={20} height={20} />
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
