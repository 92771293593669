import React, { CSSProperties, useState } from 'react';
import { Grid } from '@material-ui/core';
import { DropDown } from 'library';
import { isMobile } from 'react-device-detect';
import { PaymentSchedule, PayWindow } from 'parent-portal/forms/JobRequestTypes';

interface PayScheduleProps {
    updatePayWindow: (payWindow: PayWindow) => void;
    updatePayDay: (payDay: PaymentSchedule['payDay']) => void;
    initialPayDay?: PaymentSchedule['payDay'];
    initialPayWindow?: PayWindow;
    style?: CSSProperties;
}

const WEEKDAY_CATEGORIES = [
    { key: 'MONDAY', value: 'Monday' },
    { key: 'TUESDAY', value: 'Tuesday' },
    { key: 'WEDNESDAY', value: 'Wednesday' },
    { key: 'THURSDAY', value: 'Thursday' },
    { key: 'FRIDAY', value: 'Friday' },
    { key: 'SATURDAY', value: 'Saturday' },
    { key: 'SUNDAY', value: 'Sunday' },
];

const paySchedule = [
    { key: 'DAILY', value: 'Confirm Payment at the end of the job' },
    { key: 'WEEKLY', value: 'Weekly Payment' },
    { key: 'AUTOMATIC', value: 'Automatic Payment at the end of every job' },
];

const SetPaySchedule = ({
    initialPayDay,
    initialPayWindow,
    updatePayDay,
    updatePayWindow,
    style,
}: PayScheduleProps) => {
    const [payWindow, setPayWindow] = useState(initialPayWindow ?? 'DAILY');
    const [day, setDay] = useState(initialPayDay ?? 'MONDAY');
    const [showPayDayDropdown, setShowPayDayDropdown] = useState(initialPayWindow == 'WEEKLY');

    const onPayWindowChange = (event: any) => {
        setPayWindow(event.target.value);
        updatePayWindow(event.target.value);
        if (event.target.value === `WEEKLY`) {
            setDay(`MONDAY`);
            updatePayDay(`MONDAY`);
        }
        setShowPayDayDropdown(event.target.value === `WEEKLY`);
    };

    const onPayWindowDayChange = (event: any) => {
        setDay(event.target.value);
        updatePayDay(event.target.value);
    };
    return (
        <Grid container item style={style}>
            <DropDown
                native={isMobile}
                title="Set Pay Window"
                selected={payWindow}
                fields={paySchedule}
                onChange={(e: any) => onPayWindowChange(e)}
            />
            {showPayDayDropdown && (
                <DropDown
                    native={isMobile}
                    title="Day of the Week"
                    selected={day}
                    fields={WEEKDAY_CATEGORIES}
                    onChange={(e: any) => onPayWindowDayChange(e)}
                />
            )}
        </Grid>
    );
};

export default SetPaySchedule;
