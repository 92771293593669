import React, { useState } from 'react';
import { Colors, SizeableRoundedDialog, Text, TextButton } from 'library';
import { useMediaQuery, Grid } from '@mui/material';
import theme from 'theme';
import { BusinessSlot } from 'models';
import { BusinessJobShiftSlot } from '../BusinessJob/components/BusinessJobShiftSlot';
export default function UnfilledShifts({
    slots,
    title,
    refresh,
}: {
    slots: BusinessSlot[];
    title: string;
    refresh: () => void;
}) {
    const [viewShifts, setViewShifts] = useState(false);
    const smDown = useMediaQuery(theme.breakpoints.down('sm'));
    const unfilledShifts = slots.reduce((count: number, slot: BusinessSlot) => {
        if (slot.appointments.length === 0) {
            return count + 1;
        }

        return slot.appointments.filter((appt) => appt.providers.length === 0).length + count;
    }, 0);

    return (
        <Grid item container direction="column" alignContent="center">
            <TextButton buttonStyle={{ backgroundColor: Colors.lightRed }} onClick={() => setViewShifts(true)}>
                {unfilledShifts} unfilled shift{unfilledShifts === 1 ? '' : 's'}
            </TextButton>
            <SizeableRoundedDialog
                open={viewShifts}
                onClose={() => setViewShifts(false)}
                closeButton
                style={{ maxWidth: smDown ? '100%' : '50%' }}
                disableScrollLock={true}
            >
                <Grid item container direction="column" style={{ padding: 20 }}>
                    <Text variant="display" bold>{`Unfilled shifts for ${title}`}</Text>
                    <Grid item container direction="row" style={{ paddingTop: 20, gap: 20 }}>
                        {slots.map((slot) => (
                            <BusinessJobShiftSlot
                                key={slot.id}
                                slot={slot}
                                job={slot.ongoing_request}
                                getJob={refresh}
                                md={slots.length === 1 ? 8 : 5}
                                businessJobType={slot.ongoing_request.business_job_type}
                            />
                        ))}
                    </Grid>
                </Grid>
            </SizeableRoundedDialog>
        </Grid>
    );
}
