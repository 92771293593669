import React, { Component } from 'react';
import {
    Grid,
    Typography,
    Dialog,
    DialogContent,
    withStyles,
    Badge,
    List,
    ListItem,
    ListItemText,
} from '@material-ui/core';
import { BrowserView, MobileView } from 'react-device-detect';
import CloseIcon from '@material-ui/icons/Close';
import ProfileImage from './upcoming/ProfileImage';
import { formatPhoneNumber } from '../../shared/Utils';
import { TROPHY_IMGS } from '../../constants/Trophies';
import Button from '../../reusableComponents/button';
import DropDown from '../../reusableComponents/outlinedDropdown';
import theme from '../../theme';
import StyledDivider from '../../reusableComponents/StyledDivider';
import moment from 'moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/moment';
import { StyledLink, StyledIconButton } from '../../reusableComponents/styledItems';
import apiClient from '../../shared/ApiClient';
import { MixPanelKey } from 'environmentVariables';

var mixpanel = require('mixpanel-browser');
mixpanel.init(MixPanelKey);

const DISMISSED_STATES = ['REJECTED', 'DISMISSED', 'SKIPPED'];

const SCHEDULING_STATES = {
    TO_SCHEDULE: 'I need to schedule',
    IN_PROGRESS: 'Waiting to hear back',
    SCHEDULED: 'Our interview is scheduled',
    COMPLETED: 'Inteview is complete',
    DISMISSED: 'Dismiss this reminder',
};

const COMPLETION_STATES = {
    COMPLETED: 'Inteview is complete',
    REJECTED: 'Remove this applicant',
    DISMISSED: 'Dismiss this reminder',
};

const ErrorText = withStyles({
    root: {
        color: theme.palette.error.main,
    },
})(Typography);

class OngoingDatePicker extends Component {
    constructor(props) {
        super();
        this.state = {
            onDateChange: props.onDateChange,
            startDate: new Date(),
            endDate: moment(new Date(), 'ddd MMM DD YYYY HH:mm:ss').add(28, 'days'),
            validDates: true,
        };
    }

    checkValidDates = (name, value) => {
        let { startDate, endDate } = this.state;
        let validDates = false;
        if (name === 'startDate') {
            startDate = value;
        } else {
            endDate = value;
        }
        if (startDate && endDate) {
            validDates = endDate.diff(startDate, 'days') >= 13;
        }
        this.setState({ validDates });
        return validDates;
    };

    onLocalDateChange = (name, value) => {
        this.setState({ [name]: value });
        this.state.onDateChange(name, value, this.checkValidDates(name, value));
    };

    getDatePickers = () => {
        const { startDate, endDate } = this.state;
        return (
            <Grid container justify="space-between" style={{ marginLeft: 10 }}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid item sm={5} xs={8}>
                        <KeyboardDatePicker
                            disableToolbar
                            variant="inline"
                            format="MM/DD/YYYY"
                            margin="normal"
                            id="date-picker-inline-start"
                            label="Start Date"
                            minDate={new Date()}
                            value={startDate}
                            onChange={(date) => this.onLocalDateChange('startDate', date)}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                        />
                    </Grid>
                    <Grid item sm={5} xs={8} style={{ marginRight: 20 }}>
                        <KeyboardDatePicker
                            disableToolbar
                            variant="inline"
                            format="MM/DD/YYYY"
                            margin="normal"
                            id="date-picker-inline-end"
                            label="End Date"
                            value={endDate}
                            minDate={moment(new Date()).add(14, 'days')}
                            onChange={(date) => this.onLocalDateChange('endDate', date)}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                        />
                    </Grid>
                </MuiPickersUtilsProvider>
            </Grid>
        );
    };

    render() {
        const { validDates } = this.state;
        return (
            <React.Fragment>
                <Grid container xs={12} direction="column">
                    <Grid item>
                        {this.getDatePickers()}
                        {!validDates && (
                            <ErrorText variant="subtitle2">Please enter a date range greater than 2 weeks</ErrorText>
                        )}
                    </Grid>
                </Grid>
            </React.Fragment>
        );
    }
}

export { OngoingDatePicker };
