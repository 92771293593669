import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { FormControlLabel, Checkbox } from '@material-ui/core';
import theme from '../theme';

const StyledFormControlLabel = withStyles({
    root: {
        padding: 0,
        margin: 0,
    },
    label: {
        color: theme.palette.primary.main,
        fontSize: '13px',
        fontWeight: 'bold',
    },
})(FormControlLabel);

export default function CheckboxLabel(props) {
    return (
        <StyledFormControlLabel
            control={props.control}
            label={props.label}
            labelPlacement={props.position}
            value={props.value}
        />
    );
}
