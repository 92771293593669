import React, { useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { Paper } from 'library';
import NotificationSection from './components/NotificationSection/NotificationSection';
import { useNotificationContext } from '../NotificationContext/NotificationContext';
import usePublicSessionTracking from 'parent-portal/shared/PublicSessionTracking';

export default function NotificationPage() {
    const { loadMore, notifications } = useNotificationContext();
    const { usePageLoadTracking } = usePublicSessionTracking('Notifications');

    usePageLoadTracking();

    const handleScroll = () => {
        const bottom = Math.ceil(window.innerHeight + window.scrollY) >= document.documentElement.scrollHeight;

        if (bottom) {
            loadMore();
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, {
            passive: true,
        });

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [notifications]);

    return (
        <Grid container style={{ paddingTop: 100, paddingBottom: 100, justifyContent: 'center' }}>
            <Grid container xs={12} sm={10} md={8} lg={5}>
                <Paper>
                    <NotificationSection showOptions />
                </Paper>
            </Grid>
        </Grid>
    );
}
