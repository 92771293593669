import React, { useState } from 'react';
import { JobChangeRequest } from 'models';
import { Link, Text } from 'library';
import { isSameWeek } from 'date-fns';
import moment from 'moment';

const apptStatusToReadableStatus = {
    APPROVED_PAIRING: 'FILLED',
    COMPLETED: 'FILLED',
    MUTED: 'CLOSED',
    CLOSED: 'CLOSED',
    PENDING_PAIRING: 'OPEN',
    NO_PAIRING: 'OPEN',
    AWAITING_SELECTION: 'OPEN',
} as { [key: string]: string };

export default function JobChangeRequestDetails({
    jobChangeRequest,
    filterWeek,
}: {
    jobChangeRequest: JobChangeRequest;
    filterWeek?: Date;
}) {
    const [showAllTimeChanges, setShowAllTimeChanges] = useState(!filterWeek);
    return (
        <>
            {jobChangeRequest.pay && <Text variant="body2">Pay: ${jobChangeRequest.pay}</Text>}
            {jobChangeRequest.bring_child && <Text variant="body2">Bring Child</Text>}
            {jobChangeRequest.comment && (
                <Text textStyle={{ whiteSpace: 'break-spaces' }} variant="body2">
                    Comment: {jobChangeRequest.comment}
                </Text>
            )}
            {jobChangeRequest.time_changes?.length > 0 ? (
                <>
                    <Text variant="body2">Time Changes:</Text>
                    {jobChangeRequest.time_changes
                        .filter((timeChange) =>
                            filterWeek && !showAllTimeChanges
                                ? isSameWeek(new Date(timeChange.start), filterWeek, { weekStartsOn: 1 })
                                : true,
                        )
                        .map((timeChange) => (
                            <Text key={timeChange.id} variant="caption">
                                <b>Start</b>: {moment(timeChange.start).format('MM/DD h:mm A')} <b>End</b>:{' '}
                                {moment(timeChange.end).format('MM/DD h:mm A')}{' '}
                                <b>{apptStatusToReadableStatus[timeChange.appointment_status]}</b>
                            </Text>
                        ))}
                    {filterWeek && (
                        <Link onClick={() => setShowAllTimeChanges(!showAllTimeChanges)}>
                            Show {showAllTimeChanges ? 'this week' : 'All'}
                        </Link>
                    )}
                </>
            ) : null}
        </>
    );
}
