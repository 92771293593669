import { isAfter } from 'date-fns';
import { TaskListItem } from './matchingInboxModels';

export function isPinned(item: TaskListItem) {
    const lastPin = item.history.find((h) => h.action === 'PIN');
    const lastUnpin = item.history.find((h) => h.action === 'UNPIN');
    return lastPin && (!lastUnpin || isAfter(new Date(lastPin.added_at), new Date(lastUnpin.added_at)));
}

export function isResolved(item: TaskListItem) {
    const lastResolve = item.history.find((h) => h.action === 'RESOLVE');
    const lastReopen = item.history.find((h) => h.action === 'REOPEN');
    return lastResolve && (!lastReopen || isAfter(new Date(lastResolve.added_at), new Date(lastReopen.added_at)));
}
