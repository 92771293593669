import React, { useEffect, useState } from 'react';
import { Text, Colors, IconButton, Checkbox, TimeSlider } from 'library';
import { PostApiTimeslot, TimeslotState } from 'internal/substitutes/substituteModels';
import { format12HourTime, formatShortWeekdayMonthDay, midnightMinutes } from 'shared/Dates';
import { Add, Close, Delete, Edit } from '@mui/icons-material';
import { toggleTimeslotBooked } from 'internal/substitutes/substituteApi';

export default function SubAvailabilityTimeslot({
    slot,
    index,
    updateSlot,
    week,
}: {
    slot: PostApiTimeslot;
    index: number;
    updateSlot: (slot: PostApiTimeslot, index: number, duplicate?: boolean) => void;
    week?: Date;
}) {
    const [editing, setEditing] = useState(false);
    const [sliderValues, setSliderValues] = useState(getValues());

    useEffect(() => {
        setSliderValues(getValues());
    }, [slot]);
    useEffect(() => setEditing(false), [week]);

    function add() {
        let newSlot = { ...slot };
        newSlot.id = -1;
        newSlot.lower = new Date(slot.lower);
        newSlot.upper = new Date(slot.upper);
        newSlot.state = TimeslotState.UNSAVED;
        updateSlot(newSlot, index, slot.state !== TimeslotState.DEFAULT);
    }

    function remove() {
        let newSlot = { ...slot };
        newSlot.state = newSlot.state === TimeslotState.DEFAULT ? TimeslotState.DEFAULT : TimeslotState.DELETED;
        updateSlot(newSlot, index, false);
    }

    function edit() {
        let newSlot = { ...slot };
        newSlot.lower.setHours(Math.floor(sliderValues[0] / 60));
        newSlot.lower.setMinutes(sliderValues[0] % 60);
        newSlot.upper.setHours(Math.floor(sliderValues[1] / 60));
        newSlot.upper.setMinutes(sliderValues[1] % 60);
        newSlot.state = TimeslotState.UNSAVED;
        updateSlot(newSlot, index, false);
    }

    function updateSlider(newValues: number | number[]) {
        if (!(newValues instanceof Array)) return;
        setSliderValues(newValues);
    }

    function getValues() {
        return [
            slot.lower.getHours() * 60 + slot.lower.getMinutes(),
            slot.upper.getHours() * 60 + slot.upper.getMinutes(),
        ];
    }

    return (
        <div
            style={{
                backgroundColor: [TimeslotState.DEFAULT, TimeslotState.DELETED].includes(slot.state)
                    ? Colors.lightGrey
                    : slot.state === TimeslotState.SAVED
                    ? Colors.lightBlue
                    : Colors.lightGreen,
                borderRadius: 18,
                padding: 20,
                marginBottom: 10,
            }}
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                }}
            >
                <Text>
                    {formatShortWeekdayMonthDay(slot.lower)} {format12HourTime(slot.lower)} -{' '}
                    {format12HourTime(slot.upper)}
                </Text>
                <Checkbox
                    label="Booked"
                    style={{ width: '30%' }}
                    checked={slot.booked}
                    onChange={() => {
                        if (slot.state === TimeslotState.SAVED) toggleTimeslotBooked(slot, !slot.booked);
                        let newSlot = { ...slot };
                        newSlot.booked = !slot.booked;
                        newSlot.state =
                            slot.state === TimeslotState.SAVED ? TimeslotState.SAVED : TimeslotState.UNSAVED;
                        updateSlot(newSlot, index, false);
                    }}
                />
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        gap: 10,
                    }}
                >
                    <IconButton icon={Add} onClick={add} />
                    <IconButton icon={Delete} onClick={remove} />
                    <IconButton icon={Edit} onClick={() => setEditing(!editing)} />
                </div>
            </div>
            {editing && (
                <div
                    style={{
                        flexDirection: 'row',
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginTop: 10,
                        alignItems: 'center',
                        gap: 10,
                    }}
                >
                    <TimeSlider
                        min={0}
                        max={midnightMinutes}
                        values={sliderValues}
                        onValuesChange={updateSlider}
                        onValuesChangeFinish={edit}
                    />
                    <IconButton icon={Close} onClick={() => setEditing(false)} />
                </div>
            )}
        </div>
    );
}
