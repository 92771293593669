import React from 'react';
import { Grid, useMediaQuery } from '@material-ui/core';
import { Colors, Text } from 'library';
import theme from 'theme';
import { isPast, isToday } from 'date-fns';
import Holiday from './Holiday';
import { formatDateFromDateObject } from 'shared/Dates';
export default function CalendarHeader({
    d,
    start,
    end,
    holidays,
}: {
    d: Date;
    start: Date;
    end: Date;
    holidays: { name: string; date: string }[];
}) {
    const mdDown = useMediaQuery(theme.breakpoints.down('md'));
    const dateDisabled = isPast(d) && !isToday(d);
    return (
        <>
            <Grid
                container
                item
                direction="column"
                style={{
                    backgroundColor: dateDisabled ? Colors.mediumNavy : Colors.darkNavy,
                    borderBottomWidth: 1,
                    borderBottomColor: Colors.disabledGrey,
                    borderBottomStyle: 'solid',
                    height: 75,
                    borderTopRightRadius: d.getDay() === end.getDay() || mdDown ? 18 : 0,
                    borderTopLeftRadius: d.getDay() === start.getDay() || mdDown ? 18 : 0,
                }}
                alignItems="center"
                justify="center"
            >
                <Text textStyle={{ color: Colors.white }} variant="display">
                    {d.toLocaleDateString('en-US', { day: 'numeric' })}
                </Text>
                <Text textStyle={{ color: Colors.white }} variant="caption">
                    {d.toLocaleDateString('en-US', {
                        weekday: 'short',
                        day: 'numeric',
                        month: 'short',
                    })}
                </Text>
            </Grid>
            <Holiday holidays={holidays.filter((day) => day.date === formatDateFromDateObject(d))} />
        </>
    );
}
