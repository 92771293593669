import { Colors, DraggableDialog, IconButton } from 'library';
import React from 'react';
import TaskList from './TaskList';
import { OpenInNew } from '@mui/icons-material';

export default function TasksDialog({
    isOpen,
    onClose,
    parentRef,
    addTask,
}: {
    isOpen: boolean;
    onClose: () => void;
    parentRef: HTMLElement | null;
    addTask?: boolean;
}) {
    return (
        <DraggableDialog
            isOpen={isOpen}
            onClose={onClose}
            headerColor={Colors.mediumRed}
            dialogTitle="Tasks"
            headerComponent={
                <IconButton
                    icon={OpenInNew}
                    backgroundColor="transparent"
                    style={{ boxShadow: 'none' }}
                    color={Colors.white}
                    onClick={() => window.open('/adminperson/tasks')}
                />
            }
            initialPosition="right"
            anchorElement={parentRef}
        >
            <TaskList isOpen={isOpen} addTask={addTask} />
        </DraggableDialog>
    );
}
