import React, { forwardRef } from 'react';
import { Grid } from '@mui/material';
import { Text, Colors } from 'library';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

type Profile = {
    icon: React.JSX.Element;
    title: string;
    subtitle: string;
    details: {
        title: string;
        content: string;
    }[];
};

const ProfileCard = forwardRef(({ profile }: { profile: Profile }, ref: React.Ref<HTMLInputElement>) => {
    return (
        <Grid
            ref={ref}
            item
            container
            xs={12}
            md={4}
            flexDirection="row"
            style={{
                padding: 40,
                gap: 20,
                borderRadius: 18,
                boxShadow: '0px 3px 6px #00000029',
            }}
        >
            {profile.icon}
            <Grid item container flexDirection="column" xs={10}>
                <Text bold>{profile.title}</Text>
                <Text variant="caption" textStyle={{ textDecorationLine: 'underline' }}>
                    {profile.subtitle}
                </Text>
                {profile.details.map((detail, index) => (
                    <Text key={index} variant="caption" bold>
                        <CheckCircleIcon style={{ color: Colors.darkNavy, fontSize: 12 }} />
                        {detail.title}:
                        <Text variant="caption" inline>
                            {detail.content}
                        </Text>
                    </Text>
                ))}
            </Grid>
        </Grid>
    );
});

export default ProfileCard;
