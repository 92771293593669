export function chatwootScript(document, tagName, baseUrl, websiteToken) {
    window.chatwootSettings = { position: 'right', type: 'expanded_bubble', launcherTitle: 'Need help?' };
    const BASE_URL = baseUrl;
    const g = document.createElement(tagName),
        s = document.getElementsByTagName(tagName)[0];
    g.src = BASE_URL + '/packs/js/sdk.js';
    g.defer = true;
    g.async = true;
    s.parentNode?.insertBefore(g, s);

    g.onload = function () {
        window.chatwootSDK.run({
            websiteToken: websiteToken,
            baseUrl: BASE_URL,
        });
    };
}
