import React, { useEffect, useState } from 'react';
import PaySlider from '../../PaySlider';
import { FormFieldProps } from '../../FormTypes';
import { useFieldValidation } from '../../FormHook';
import { IPayFormFields, PaymentSchedule } from '../../../JobRequestTypes';
import IconRow from './IconRow';
import getPayString from '../PayString';
import SetupPaymentLink from './SetupPaymentLink';

interface IOnetimeRequestPayProps {
    pay: FormFieldProps<IPayFormFields>;
    paymentSchedule: FormFieldProps<PaymentSchedule | undefined>;
}

const RecurringRequestPay: React.FC<IOnetimeRequestPayProps> = ({ pay, paymentSchedule }) => {
    const [payMin, setPayMin] = useState(pay.value.payMin);
    const [payMax, setPayMax] = useState(pay.value.payMax);

    useFieldValidation(pay);

    useEffect(() => {
        pay.setValue({ ...pay.value, payMin, payMax });
    }, [payMin, payMax]);

    function onPayRateChange(event: any, newValue: number | number[]) {
        if (typeof newValue === 'number') {
            setPayMin(newValue);
            setPayMax(newValue);
        } else {
            setPayMin(newValue[0]);
            setPayMax(newValue[1]);
        }
    }

    const payString = getPayString(pay.value, false);
    const payTip = `You'll pay ${payString}. Your provider will receive ${payString}.`;

    return (
        <>
            <PaySlider
                value={[payMin, payMax]}
                onPayRateChange={onPayRateChange}
                payRateTip={'The average rate for recurring jobs is $17'}
                isRange={true}
            />
            <SetupPaymentLink />
            <div style={{ marginTop: 20 }}>
                <IconRow text="Tandem takes no cut." secondaryText={payTip} />
            </div>
        </>
    );
};
export default RecurringRequestPay;
