import React, { useState } from 'react';
import { Grid } from '@mui/material';
import useTrackingCookies from 'parent-portal/shared/EngagementSourceTracking';
import usePublicSessionTracking from 'parent-portal/shared/PublicSessionTracking';
import useSignUpAction from 'parent-portal/login/SignUpAction';
import SubstituteHeader from './components/SubstituteHeader';
import RatioSection from './components/RatioSection';
import LastMinuteSection from './components/LastMinuteSection';
import HowMatchingWorks from './components/HowMatchingWorks';
import DashboardOverview from './components/DashboardOverview';
import PreSignUp from 'parent-portal/signup/PreSignUp/PreSignUp';

export default function Substitutes() {
    useTrackingCookies('/substitutes');
    const [open, setOpen] = useState(false);
    const { usePageLoadTracking } = usePublicSessionTracking('how it works');
    const { SignUpWrapper } = useSignUpAction(undefined);
    usePageLoadTracking();

    function signUp() {
        setOpen(true);
    }

    return (
        <SignUpWrapper businessSignup={true}>
            <Grid xs={12}>
                <SubstituteHeader signUp={signUp} />
                <RatioSection />
                <LastMinuteSection />
                <HowMatchingWorks />
                <DashboardOverview />
                <PreSignUp
                    open={open}
                    smallerVersion={false}
                    onClose={() => setOpen(false)}
                    headerText={'How will you use Tandem?'}
                    showBusinessOption={true}
                    showProviderOption={true}
                />
            </Grid>
        </SignUpWrapper>
    );
}
