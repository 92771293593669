import React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles, withStyles } from '@material-ui/core/styles';

const StyledLine = withStyles({
    root: {
        marginLeft: 4,
        marginTop: 3,
        marginBottom: 3,
    },
})(Grid);

export default function Line(props) {
    return <StyledLine item>{props.children}</StyledLine>;
}
