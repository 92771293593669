import { DraggableDialog } from 'library';
import React from 'react';
import NotesModal, { NotesObjectType } from './NotesModal';

export interface DraggableNotesDialogProps {
    isOpen: boolean;
    onClose: () => void;
    name: string;
    refresh?: () => void;
    objectId: number;
    type: NotesObjectType;
    anchorElement: HTMLElement | null;
    parentOffset?: boolean;
}

export default function DraggableNotesDialog({
    isOpen,
    onClose,
    name,
    objectId,
    type,
    refresh,
    anchorElement,
    parentOffset,
}: DraggableNotesDialogProps) {
    return (
        <DraggableDialog
            dialogTitle={`${name} Notes`}
            onClose={() => {
                onClose();
                refresh && refresh();
            }}
            isOpen={isOpen}
            initialPosition="right"
            anchorElement={anchorElement}
            parentOffset={parentOffset}
        >
            <NotesModal type={type} objectId={objectId} />
        </DraggableDialog>
    );
}
