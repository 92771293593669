import { SizeableRoundedDialog } from 'library';
import React from 'react';
import BusinessLocationTabs from './BusinessLocationTabs';

export default function BusinessLocationModal({
    businessLocationId,
    open,
    onClose,
    initialTab,
}: {
    businessLocationId: number;
    open: boolean;
    onClose: () => void;
    initialTab?: string;
}) {
    return (
        <SizeableRoundedDialog disableEnforceFocus closeButton open={open} onClose={onClose} style={{ width: '80%' }}>
            <BusinessLocationTabs businessLocationId={businessLocationId} initialTab={initialTab} />
        </SizeableRoundedDialog>
    );
}
