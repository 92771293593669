import { LeanUser, PagingResult } from 'models';
import { client, queryStringifyObject } from 'shared';

export enum AutobookAction {
    APPROVED,
    DECLINED,
    IN_REVIEW,
}
export interface AutobookHistory {
    id: number;
    last_updated: string;
    autobook_action: AutobookAction;
    reason: string;
    user: LeanUser;
    job: {
        id: string;
        headline: string;
        location_name: string;
    };
}

export const getAutobookHistory = (page: number, regions: number[]): Promise<PagingResult<AutobookHistory>> => {
    const queryParams = queryStringifyObject({ page, regions });
    return client(`api/autobook-history/?${queryParams}`);
};
