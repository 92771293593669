import React, { useState } from 'react';
import { SubJobAppointment } from 'models';
import { IconButton, Menu } from 'library';
import { Close, Edit, MoreVert } from '@mui/icons-material';
import EditJobModal from './EditJobModal';
import EditPayModal from './EditPayModal';
import { closeAppointment } from './substituteApi';

export default function AppointmentMenu({ appt, refreshJobs }: { appt: SubJobAppointment; refreshJobs: () => void }) {
    const [menuOpen, setMenuOpen] = useState(false);
    const [editOpen, setEditOpen] = useState(false);
    const [editPayOpen, setEditPayOpen] = useState(false);
    const [modalAnchorEl, setModalAnchorEl] = useState<HTMLDivElement | null>(null);
    const approved = appt.pairings.some((pairing) => ['ON_STAFF', 'APPROVED'].includes(pairing.status));

    function closeAll() {
        setEditOpen(false);
        setEditPayOpen(false);
        setMenuOpen(false);
    }

    const options = [
        { text: 'Edit', icon: <Edit />, onClick: () => setEditOpen(!editOpen) },
        { text: 'Edit Pay', icon: <Edit />, disabled: !approved, onClick: () => setEditPayOpen(!editPayOpen) },
        {
            text: 'Close',
            icon: <Close />,
            disabled: approved,
            onClick: () => closeAppointment(appt.id).then(refreshJobs),
        },
    ];

    return (
        <>
            <Menu
                options={options}
                anchor={
                    <div onClick={(event) => setModalAnchorEl(event.currentTarget)}>
                        <IconButton icon={MoreVert} onClick={() => setMenuOpen(!menuOpen)} />
                    </div>
                }
            />
            <EditJobModal
                appt={appt}
                open={editOpen}
                refreshJobs={refreshJobs}
                anchorEl={modalAnchorEl}
                onClose={closeAll}
            />
            <EditPayModal
                appt={appt}
                open={editPayOpen}
                refreshJobs={refreshJobs}
                anchorEl={modalAnchorEl}
                onClose={closeAll}
            />
        </>
    );
}
