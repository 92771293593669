import { Grid, useMediaQuery } from '@mui/material';
import React from 'react';
import { Text } from 'library';
import Header from 'assets/images/marketing-site/WorkerHeader.png';
import theme from 'theme';
import DownloadRow from 'parent-portal/shared/Footer/DownloadRow';

export default function WorkerHeader() {
    const smDown = useMediaQuery(theme.breakpoints.down('sm'));
    return (
        <Grid
            xs={12}
            style={{
                background:
                    'radial-gradient(circle, rgba(232,246,244,0.7539390756302521) 0%, rgba(249,217,220,0.7707457983193278) 100%)',
                paddingLeft: 40,
                paddingTop: 40,
                paddingBottom: 40,
                overflow: 'hidden',
            }}
            item
            container
            flexDirection="row"
            justifyContent="space-between"
        >
            <Grid
                xs={12}
                md={6}
                item
                container
                style={{ height: '100%', padding: 80, gap: 20 }}
                justifyContent="center"
                flexDirection="column"
            >
                <Text variant="display">Child care is hard work. We make it a little bit easier.</Text>

                <Grid item container style={{ gap: 20 }} flexDirection="column">
                    <Text>
                        Work flexible shifts on your time or find your long-term fit. We meet you where you are so you
                        can continue doing what you do best, caring for kids!
                    </Text>
                    <DownloadRow />
                </Grid>
            </Grid>
            <img src={Header} height={450} style={{ marginRight: smDown ? 0 : -100 }} />
        </Grid>
    );
}
