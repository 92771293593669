import React, { Component } from 'react';
import { Dialog, DialogContent, Grid, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Button from './button';
import { StyledIconButton } from './styledItems';
import { Colors } from 'library';

class SimpleDialog extends Component {
    render() {
        const { isOpen, dialogTitle, children, buttonText, onClose, onSubmit, dataObj } = this.props;

        return (
            <React.Fragment>
                <Dialog
                    fullWidth
                    open={isOpen}
                    onClose={() => {
                        onClose();
                    }}
                    aria-labelledby="max-width-dialog-title"
                    PaperProps={{
                        style: { borderRadius: 18 },
                    }}
                >
                    <DialogContent style={{ paddingBottom: '3%', borderRadius: '18px' }}>
                        <Grid container direction="row">
                            <Grid item xs={11}>
                                <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                                    {dialogTitle}
                                </Typography>
                            </Grid>
                            <Grid item xs={1}>
                                <StyledIconButton
                                    onClick={() => {
                                        onClose();
                                    }}
                                >
                                    <CloseIcon style={{ color: Colors.darkNavy }} />
                                </StyledIconButton>
                            </Grid>
                        </Grid>
                        {children}
                        {onSubmit && (
                            <Button
                                fullWidth
                                style={{ marginTop: 10 }}
                                onClick={() => onSubmit(dataObj)}
                                disableFocusRipple
                                disableRipple
                            >
                                {buttonText}
                            </Button>
                        )}
                    </DialogContent>
                </Dialog>
            </React.Fragment>
        );
    }
}

class SimpleTextDialog extends Component {
    render() {
        const { isOpen, dialogTitle, dialogText, buttonText, onClose, onSubmit, dataObj } = this.props;

        return (
            <SimpleDialog
                isOpen={isOpen}
                dialogTitle={dialogTitle}
                buttonText={buttonText}
                onClose={onClose}
                onSubmit={onSubmit}
                dataObj={dataObj}
            >
                <Typography variant="subtitle1">{dialogText}</Typography>
            </SimpleDialog>
        );
    }
}

export { SimpleDialog, SimpleTextDialog };
