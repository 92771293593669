import React, { useEffect } from 'react';
import parse from 'html-react-parser';
import { IconButton } from 'library';
import Grid from '@mui/material/Grid';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import EmailThreadButton from './EmailThreadButton';
import { consoleLogInDev } from 'shared';

const MAX_MSG_LENGTH = 50;
export default function MessageBody({ message, messageId }: { message: string; messageId: string }) {
    const [showFullMessage, setShowFullMessage] = React.useState(false);
    const [msg, setMsg] = React.useState(message);
    const [truncatedMsg, setTruncatedMsg] = React.useState('');

    useEffect(() => {
        if (!message) return;

        const index = message.indexOf('<br>');
        const isRichText = message.includes('<div');

        if (index !== -1) {
            setTruncatedMsg(message.substring(0, index));
            return;
        }
        if (message.length > MAX_MSG_LENGTH && !isRichText) {
            setTruncatedMsg(message.substring(0, MAX_MSG_LENGTH) + '...');
            return;
        }
        setTruncatedMsg(message);
    }, [message]);

    useEffect(() => {
        setMsg(showFullMessage ? message : truncatedMsg);
    }, [showFullMessage, message, truncatedMsg]);

    const handleShowFullMessage = () => {
        setShowFullMessage(!showFullMessage);
    };

    function showEmailBody(emailBody: string) {
        try {
            const pattern = /[\f\n\r\t\v ]{2,}/g;
            const replacement = ' ';

            const normalized = emailBody.replace(pattern, replacement);
            // eslint-disable-next-line regexp/no-unused-capturing-group, regexp/prefer-d
            if (/<([A-Za-z][A-Za-z0-9]*)\b[^>]*>(.*?)<\/\1>/.test(normalized)) {
                return parse(normalized);
            }
            return normalized;
        } catch (error) {
            consoleLogInDev(error);
        }
        return '';
    }

    if (!message) return null;

    return (
        <>
            <div>{showEmailBody(msg)}</div>
            <Grid container spacing={2} style={{ marginTop: '2px' }} alignItems={'center'}>
                <Grid item>
                    <IconButton
                        onClick={handleShowFullMessage}
                        icon={showFullMessage ? ExpandLessIcon : ExpandMoreIcon}
                    />
                </Grid>
                <Grid item>
                    <EmailThreadButton messageId={messageId} />
                </Grid>
            </Grid>
        </>
    );
}
