import React, { useState } from 'react';
import { IBusinessContact, IBusinessLocation } from 'models';
import { Colors, PrimaryButton, SizeableRoundedDialog, Text } from 'library';
import theme from 'theme';
import AddLocationContact from './AddLocationContact';
import BusinessContact from './BusinessContact';
import { Grid, useMediaQuery } from '@mui/material';

export default function LocationContactsSection({
    businessLocation,
    updateLocation,
}: {
    businessLocation: IBusinessLocation;
    updateLocation: (l: IBusinessLocation) => void;
}) {
    const [addBusinessContact, setAddBusinessContact] = useState(false);
    const small = useMediaQuery(theme.breakpoints.down('sm'));
    function updateBusinessContact(c: IBusinessContact, remove?: boolean) {
        if (businessLocation) {
            let contacts = businessLocation.business_contacts;
            let contactIndex = contacts.findIndex((current) => current.id === c.id);
            if (contactIndex !== -1 && !remove) {
                contacts[contactIndex] = c;
                updateLocation({ ...businessLocation, business_contacts: contacts });
            } else if (!remove) {
                updateLocation({ ...businessLocation, business_contacts: [...contacts, c] });
            } else {
                contacts = contacts.filter((con) => con.id !== c.id);
                updateLocation({
                    ...businessLocation,
                    business_contacts: contacts,
                });
            }
        }
    }
    return (
        <Grid
            container
            item
            style={{ backgroundColor: Colors.white, borderRadius: 18, padding: 20 }}
            direction="column"
        >
            <Grid item container direction="row" justifyContent="space-between">
                <Text variant="body1" bold>
                    Location Contacts
                </Text>
            </Grid>
            <Grid item container direction="column" style={{ marginTop: 10 }}>
                {businessLocation.business_contacts.length ? (
                    <Grid item container direction="row">
                        {businessLocation.business_contacts.map((c: IBusinessContact) => (
                            <BusinessContact key={c.id} businessContact={c} updateContact={updateBusinessContact} />
                        ))}
                    </Grid>
                ) : (
                    <Text variant="body1">
                        You currently have no additional contacts for this location. All communication will be sent to
                        the account owner.
                    </Text>
                )}
                <Grid item container style={{ justifyContent: 'right', marginTop: 20 }}>
                    <PrimaryButton onClick={() => setAddBusinessContact(true)} buttonStyle={{ maxWidth: 200 }}>
                        Add Contact
                    </PrimaryButton>
                </Grid>
                <SizeableRoundedDialog
                    open={addBusinessContact}
                    onClose={() => setAddBusinessContact(false)}
                    closeButton
                    style={{ maxWidth: small ? '100%' : 300 }}
                >
                    <AddLocationContact
                        locationId={businessLocation.id}
                        updateContact={(c: IBusinessContact) => {
                            updateBusinessContact(c);
                            setAddBusinessContact(false);
                        }}
                    />
                </SizeableRoundedDialog>
            </Grid>
        </Grid>
    );
}
