import React, { useEffect, useState } from 'react';
import theme from 'theme';
import { useMediaQuery } from '@material-ui/core';
import { PrimaryButton } from 'library';
import DollarIcon from 'assets/icons/tandempay/dollar-icon-circle.svg';
import BankIcon from 'assets/icons/tandempay/bank-circle.svg';
import CreditCardIcon from 'assets/icons/tandempay/credit-card-circle.svg';
import ModalOptionRow from '../ModalOptionRow';
import PageFooter from '../PageFooter';
import PageHeader from '../PageHeader';
import PageContent from '../PageContent';
import ModalPage from '../ModalPage';
import { client } from 'shared';
import { useUserContext } from 'UserContext';

interface IntroPageProps {
    goToConnectBankAccount: () => void;
    goToConnectCreditCard: () => void;
    goToTandemPay: () => void;
    subtitle?: string;
}

export default function IntroPage({
    goToConnectBankAccount,
    goToConnectCreditCard,
    goToTandemPay,
    subtitle,
}: IntroPageProps) {
    const [connectCreditCardActive, setConnectCreditCardActive] = useState(false);
    const [subtitleText, setSubtitleText] = useState(
        "You can connect a bank account to pay Tandem providers. Pay is adjustable at the end of every job. You'll be notified to confirm.",
    );
    const small = useMediaQuery(theme.breakpoints.down('sm'));
    const { role } = useUserContext();

    useEffect(() => {
        client('payment/api/payment-feature/connect-cc-active/').then((response: { is_active: boolean }) => {
            setConnectCreditCardActive(response.is_active);
            if (response.is_active) {
                if (role !== 'business_active') {
                    setSubtitleText(
                        "You can connect a bank account or credit card to pay Tandem providers. Pay is adjustable at the end of every job. You'll be notified to confirm.",
                    );
                } else {
                    setSubtitleText(
                        "You can connect a bank account or credit card to pay your Tandem staff. You will be able to review and confirm all payments before they're sent .",
                    );
                }
            }
        });
    }, []);

    return (
        <ModalPage>
            <PageHeader
                iconSource={<DollarIcon />}
                headerText="Set up TandemPay"
                subtitleText={subtitle ?? subtitleText}
            />
            <PageContent>
                <ModalOptionRow
                    onClick={goToConnectBankAccount}
                    icon={<BankIcon />}
                    titleText="Connect Bank Account"
                    bodyText={
                        !connectCreditCardActive
                            ? 'Paying with your bank account incurs no fees.'
                            : 'We recommend this option. Paying with a bank account incurs no fees.'
                    }
                />
                {!!connectCreditCardActive ? (
                    <ModalOptionRow
                        onClick={goToConnectCreditCard}
                        icon={<CreditCardIcon />}
                        titleText="Connect Credit Card"
                        bodyText="Using a credit card to pay care providers incurs a $0.30 + 2.9% fee by our credit card processor, Stripe."
                    />
                ) : (
                    <></>
                )}
            </PageContent>
            <PageFooter>
                <PrimaryButton
                    textStyle={{ fontWeight: 'bold' }}
                    buttonStyle={{ marginLeft: small ? 5 : 30 }}
                    onClick={goToTandemPay}
                >
                    About TandemPay
                </PrimaryButton>
            </PageFooter>
        </ModalPage>
    );
}
