import { PrimaryButton, Text } from 'library';
import React from 'react';
import { useHistory } from 'react-router';
import { useUserContext } from 'UserContext';
import { Grid } from '@material-ui/core';

export default function PaymentsHeader() {
    const { role } = useUserContext();
    const history = useHistory();

    return (
        <Grid
            container
            item
            justify="center"
            alignContent="center"
            direction="column"
            xs={12}
            style={{ marginBottom: 25 }}
        >
            <Grid item lg={10} md={12} sm={9} xs={12} style={{ marginBottom: 20 }}>
                <Text variant="display">Payments</Text>
            </Grid>
            <Grid item lg={10} md={12} sm={9} xs={12} style={{ marginBottom: 20 }}>
                <Text variant="body1">This is where you can easily manage the payment details for all your jobs.</Text>
            </Grid>
            <Grid container item spacing={4} direction="row" lg={10} md={12} sm={9} xs={11}>
                {role !== 'business_active' ? (
                    <Grid item>
                        <PrimaryButton
                            onClick={() => {
                                history.push('/tandempay');
                            }}
                        >
                            Learn More
                        </PrimaryButton>
                    </Grid>
                ) : null}
            </Grid>
        </Grid>
    );
}
