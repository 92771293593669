import React, { useState } from 'react';
import JobFormPage from '../JobFormPage';
import { Text } from 'library';
import { useJobFormContext } from 'parent-portal/forms/JobFormContext';
import BusinessAddressSelection from './components/BusinessAddressSelection';

export default function BusinessLocationPage({ name }: { name: string }) {
    const { goToNext, form } = useJobFormContext();
    const { address } = form.fieldStates;
    const [isAddingAddress, setIsAddingAddress] = useState(false);

    return (
        <JobFormPage
            title="Select the locations for this job."
            onNextClicked={() => goToNext(name)}
            nextDisabled={!address.value || !address.value.length}
            hideActionButtons={isAddingAddress}
            id={name}
        >
            <div style={{ marginTop: 30 }} />

            <Text textStyle={{ marginTop: 40 }} variant="h2">
                Addresses
            </Text>
            <BusinessAddressSelection isAddingAddress={isAddingAddress} setIsAddingAddress={setIsAddingAddress} />
        </JobFormPage>
    );
}
