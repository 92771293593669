import React from 'react';
import { Grid } from '@mui/material';
import { Text, Colors } from 'library';

export default function LocationSummaryItem({ title, value }: { title: string; value?: number | string }) {
    return (
        <Grid alignItems="center">
            <Text variant="body2" bold textStyle={{ textAlign: 'center' }}>
                {title}
            </Text>
            <Text variant="body1" bold color={Colors.turquoise} textStyle={{ textAlign: 'center' }}>
                {value}
            </Text>
        </Grid>
    );
}
