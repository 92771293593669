import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { BasicDialog, Colors, Text, LoadingIndicator, UnderlineTextInput } from 'library';
import { ILocationAdministration } from 'models';

export default function AddAdministratorDialog({
    onSave,
    onEdit,
    isOpen,
    onClose,
    editing,
    staticRole,
}: {
    onSave: (name: string, role: string) => Promise<void>;
    onEdit: (id: number, name: string, role: string) => Promise<void>;
    isOpen: boolean;
    onClose: () => void;
    editing?: ILocationAdministration;
    staticRole?: string;
}) {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [name, setName] = useState('');
    const [role, setRole] = useState('');

    useEffect(() => {
        setName(editing?.name ?? '');
        setRole(editing?.role ?? '');
    }, [editing]);

    const close = () => {
        setLoading(false);
        setError(false);
        setName('');
        setRole('');
        onClose();
    };

    const save = () => {
        setLoading(true);
        const saveAction = editing ? onEdit(editing.id, name, staticRole ?? role) : onSave(name, staticRole ?? role);
        saveAction
            .then(close)
            .catch((e) => setError(e))
            .finally(() => setLoading(false));
    };

    return (
        <BasicDialog
            isOpen={isOpen}
            onClose={close}
            dialogTitle="Add Administrator"
            closeButton
            buttonText="Save"
            secondaryButtonText="Cancel"
            onSubmit={save}
            onSecondaryButton={close}
            buttonEnabled={!loading}
        >
            {loading ? (
                <LoadingIndicator />
            ) : (
                <Grid container direction="column">
                    {staticRole ? (
                        <Text bold>{staticRole}</Text>
                    ) : (
                        <UnderlineTextInput label="Role" value={role} onChange={(e) => setRole(e.target.value)} />
                    )}
                    <UnderlineTextInput label="Name" value={name} onChange={(e) => setName(e.target.value)} />
                </Grid>
            )}
            {error && <Text color={Colors.error}>Oops! Something went wrong. Please try again.</Text>}
        </BasicDialog>
    );
}
