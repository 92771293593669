import React, { useState, useEffect } from 'react';
import { Text, DownloadTheAppButtons } from 'library';
import { client } from 'shared';
import { useParams } from 'react-router';

interface IndeedJobListing {
    title: string;
    city: string;
    state: string;
    zip: string;
    pay: string;
    description: string;
}

export default function IndeedJobListing() {
    const params = useParams<{ id: string }>();
    const [job, setJob] = useState<IndeedJobListing>({
        title: '',
        city: '',
        state: '',
        zip: '',
        pay: '',
        description: '',
    });

    useEffect(() => {
        client(`api/indeed-jobs/${params.id}`).then(setJob);
    }, [params]);

    return (
        <div style={styles.container}>
            <Text variant="display">{job.title}</Text>
            <Text variant="h1">
                {job.city},{job.state} {job.zip}
            </Text>
            <div style={styles.htmlStyles}>
                <Text variant="h1">{job.pay}</Text>
                <Text variant="h2">Job Type: Child Care</Text>
                <Text variant="h2"> Part Time and Full Time Options available!</Text>
            </div>
            <div dangerouslySetInnerHTML={{ __html: job.description }} style={styles.htmlStyles} />
            <Text textStyle={styles.applyStyle}>
                To finish your application for this job, download the Tandem app on your phone with the links below.
            </Text>
            <DownloadTheAppButtons />
        </div>
    );
}

const styles = {
    container: {
        padding: 50,
        marginBottom: 200,
    },
    htmlStyles: {
        marginTop: 30,
        marginBottom: 30,
    },
    applyStyle: {
        marginBottom: 30,
    },
};
